import { useEffect, useState } from 'react';

const LinkList = ({ list = [] }) => {
  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [title3, setTitle3] = useState('');

  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [list3, setList3] = useState([]);

  useEffect(() => {
    setTitle1(list[0].title);
    setTitle2(list[1].title);
    setTitle3(list[2].title);
    setList1(list[0].list);
    setList2(list[1].list);
    setList3(list[2].list);
  }, [list]);

  return (
    <div className="flex">
      <div className="w-1/3 shrink-0 space-y-[.04rem]">
        <div>{title1}</div>
        <div className="space-y-[.02rem]">
          {list1.map((item, index) => {
            return (
              <div key={index} className="">
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-1/3 shrink-0 space-y-[.04rem]">
        <div>{title2}</div>
        <div className="space-y-[.02rem]">
          {list2.map((item, index) => {
            return (
              <div key={index} className="">
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-1/3 shrink-0 space-y-[.04rem]">
        <div>{title3}</div>
        <div className="space-y-[.02rem]">
          {list3.map((item, index) => {
            return (
              <div key={index} className="">
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LinkList;
