import { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setDialogModalVisible,
  resetActivityData,
  setShowWGDeposit,
  DialogModalChildrenKey,
  setShowPandaWithdraw,
  setShowPandaDeposit,
} from '../redux/reducers/appSlice';
import { isLogin, isControllableSite, getTemplateId } from '../utils/helper';
import { getLangFromPath, toLocalePath } from '../utils/localePath';
import { openUrl } from '../utils/openUrl';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGlobalNavigate } from '../contexts/navigateContext';

const useLink = () => {
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activityStatus, activityData, websiteInitConfig } = useSelector(
    (state) => ({
      activityStatus: state.app.activityStatus,
      activityData: state.app.activityData,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const needLoginDialog = ['RegisterBonus', 'ReferralAchievement', 'WithdrawDeposit', 'GiftMoney', 'RedPacket'];
  const needLoginUrls = [
    '/referral',
    '/betbonus',
    '/mine',
    '/promotion/vip',
    '/promotion/mission',
    '/task',
    '/supportmessage/2',
    '/promotion/rakeback',
    '/promotion/pending',
    '/promotion/history',
    '/promotion/giftmoney',
    '/promotion/redeem',
    '/mysterybonus',
  ];

  /**
   *
   * @param {*} openMode 1:不跳转，2:弹窗，3，站内，4，站外
   * @param {*} newWindow 1:新窗口打开，2:新窗口不打开
   * @param {*} link //地址
   */
  const handleCmsLink = (openMode, newWindow, link) => {
    switch (String(openMode)) {
      case '2':
        {
          const params = new URLSearchParams(link);
          const obj = Object.fromEntries(params.entries());
          const showModal = () => {
            const { modal, ...other } = obj;
            const args = {
              show: DialogModalChildrenKey[obj.modal],
              ...other,
            };
            dispatch(setDialogModalVisible(args));
          };

          if (obj.modal) {
            if (
              needLoginDialog.some((item) => {
                return item.toLowerCase() == obj.modal.toLocaleLowerCase();
              }) &&
              !isLogin()
            ) {
              return dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.LoginBox,
                }),
              );
            }

            if (obj.modal !== 'Redeem') {
              const modalName = String(obj.modal).toLowerCase();
              if (modalName == 'signup' || modalName == 'loginbox') {
                if (isLogin()) {
                  return;
                }
                showModal();
              } else if (modalName == 'WithdrawDeposit'.toLowerCase()) {
                if (['d-template003'].includes(getTemplateId())) {
                  if (obj.walletType == 2) {
                    dispatch(setShowPandaWithdraw(true));
                  } else {
                    dispatch(setShowPandaDeposit(true));
                  }
                } else if (isControllableSite()) {
                  if (obj.walletType == 2) {
                    const { path } = getLangFromPath(window.location.pathname);
                    localStorage.setItem('prev_path', path);
                    navigate(toLocalePath(`/pay/withdrawtabpage/1`));
                  } else {
                    dispatch(setShowWGDeposit(true));
                  }
                } else {
                  showModal();
                }
              } else if (modalName == 'registerbonus') {
                const activityNovice = get(activityData, 'activity_novice');
                if (activityNovice && activityNovice.list.length > 0) {
                  const activityNoviceData = activityNovice.list[0];
                  let money = 0;
                  if (activityNoviceData.activity_rewards) {
                    activityNoviceData.activity_rewards.forEach((item) => {
                      if (item.reward_type === '4') {
                        money += Number(item.max_amount);
                      } else {
                        money += Number(item.amount);
                      }
                    });
                  }
                  const args = {
                    show: DialogModalChildrenKey.RegisterBonus,
                    style: 2,
                    money: money,
                    endTime: activityNoviceData.activity_end_time * 1000,
                    handleClose: () => {
                      dispatch(
                        setDialogModalVisible({
                          show: DialogModalChildrenKey.Default,
                        }),
                      );
                    },
                  };
                  dispatch(setDialogModalVisible(args));
                }
              } else {
                showModal();
              }
            } else {
              if (['d-template003'].includes(getTemplateId())) {
                if (isLogin()) {
                  navigate(toLocalePath(`/promotion/redeem`));
                } else {
                  dispatch(
                    setDialogModalVisible({
                      show: DialogModalChildrenKey.LoginBox,
                    }),
                  );
                }
              } else {
                // const redeemSwitch = get(activityStatus, 'redeem_code_switch');
                // if (redeemSwitch == '0') {
                //   toast.error(t('home.toast025'), {
                //     containerId: 'global',
                //   });
                // } else {
                //   showModal();
                // }
                showModal();
              }
            }
          }
        }
        break;
      case '3':
        {
          if (
            needLoginUrls.some((item) => {
              return String(link.toLocaleLowerCase()).indexOf(item.toLowerCase()) > -1;
            }) &&
            !isLogin()
          ) {
            return dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.LoginBox,
              }),
            );
          }

          if (link == '/mysterybonus') {
            const mysteryBonusData = activityData.activity_mystery_bonus?.list[0];
            const status =
              activityStatus?.activity_mystery_bonus_switch != '1' ||
              (!mysteryBonusData?.can_sign_up && !mysteryBonusData?.sign_up_status);

            if (status) {
              return navigate(toLocalePath('/promotion/events'));
            }
          }

          dispatch(resetActivityData());
          if (String(link).toLowerCase() == '/betbonus' || String(link).toLowerCase() == '/promotion/rakeback') {
            const switchRakeback = get(websiteInitConfig, 'system_config.switch_rakeback');
            if (switchRakeback == 1) {
              navigate(toLocalePath(link));
            }
          } else {
            navigate(toLocalePath(link));
          }
        }
        break;
      case '4':
        openUrl(link, String(newWindow) == '2' ? '_self' : '_blank');
        break;
    }
  };
  return {
    handleCmsLink,
  };
};

export default useLink;
