import { useEffect, memo } from 'react';
// import { useLocation } from 'react-router-dom';
import { useGlobalWebSocket } from '../hooks/websocket/useGlobalWebSocket';
// import { useChatWebSocket } from '../utils/useChatWebSocket';

const GlobalWebSocket = () => {
  useGlobalWebSocket();
  // useChatWebSocket();
  return null;
};

export default memo(GlobalWebSocket);
