import { useEffect, useState } from 'react';
import Tips from '../assets/img/tips.png';
import PtTips from '../assets/img/ptdownloadTips.jpg';
import { isMobileSafari } from '../utils/deviceUtils';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DialogModalChildrenKey, setDialogModalVisible } from '../redux/reducers/appSlice';
import { useDispatch } from 'react-redux';
import { getLangFromPath } from '../utils/localePath';

export default function IOSSafariGuide({ children, className }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tipsFlag, setTipsFlag] = useState(false); //ios底部图片是否展示
  const [tipsFlagAnimation, setTipsFlagAnimation] = useState(false); //ios底部图片是否展示
  const { langKey } = getLangFromPath(window.location.pathname);

  const open = () => {
    setTipsFlag(true);
    // dispatch(setAllowBodyScroll(false));
    document.body.classList.add(['overflow-hidden']);
  };
  const close = () => {
    closeTipsFlag();
    // dispatch(setAllowBodyScroll(true));
    document.body.classList.remove(['overflow-hidden']);
  };

  // iOS下载
  const iOSDownload = () => {
    if (!isMobileSafari()) {
      // 非safari浏览器
      gotoSafariTip();
      copy(window.location.href);
      toast.success(t('home.toast006'), {
        containerId: 'global',
      });
    } else {
      // safari浏览器
      open();
    }
  };

  // 跳转safari提示
  const gotoSafariTip = () => {
    dispatch(
      setDialogModalVisible({
        zIndex: 10002,
        show: DialogModalChildrenKey.Confirm,
        content: t('home.safaritips'),
        confirmCb: () => {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
        },
      }),
    );
  };

  const closeTipsFlag = () => {
    setTipsFlagAnimation(false);
    const _timer = window.setTimeout(() => {
      setTipsFlag(false);
      clearTimeout(_timer);
    }, 300);
  };

  useEffect(() => {
    if (tipsFlag) {
      setTipsFlagAnimation(true);
    }
  }, [tipsFlag]);

  const TipsImg = (lang) => {
    const imgSrc = {
      en: Tips,
      pt: PtTips,
      default: Tips,
    };

    const src = imgSrc[lang] || imgSrc.default;

    return <img src={src} className="m-auto" />;
  };
  return (
    <>
      <div className={className} onClick={iOSDownload}>
        {children}
      </div>
      {tipsFlag ? (
        <>
          <div
            className={`fixed left-[0] right-[0] z-[999999] tips_transition !m-[0] ${
              tipsFlagAnimation ? 'bottom-[0]' : 'bottom-[-380px]'
            }`}
            onClick={close}
          >
            {TipsImg(langKey)}
          </div>
          <div
            className={`fixed top-[0] left-[0] right-[0] bottom-[0] bg-[#000000]/70 tips_msk_transition !m-[0] ${
              tipsFlagAnimation ? ' z-[99999] opacity-[1]' : ' z-[-100] opacity-[0]'
            }`}
            onClick={close}
          ></div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
