import { Route, Routes } from 'react-router-dom';
import { memo } from 'react';

import Home from './home/index';

import ReferralHistory from './referral/history';
import Referral from './referral/index';

import Custom404 from './custom404';
import Icons from './icons';
import Test from './test';
import { isEnvDevelopment } from '../utils/helper';
import { langsConfig } from '../constants/langs';
import GameList from './gameList';
import Promotion from './promotion';
import Events from './promotion/events';
import EventsDetails from './promotion/events/components/index';
import PromotionVip from './promotion/vip';
import Pending from './promotion/pending';
import History from './promotion/history';
import Rakeback from './promotion/rakeback';
import GiftMoney from './promotion/giftmoney';
import Redeem from './promotion/redeem';
// import MobileEmail from './email';
import HelpInfo from './helpInfo';
import Termsofservice from './termsofservice';
import Termsofservice2 from './termsofservice2';
import Sport from './sport';
import Task from './task';
import { shallowEqual, useSelector } from 'react-redux';
import Tournament from './tournament';
import TournamentDetail from './tournamentDetail';
import Mine2 from './mine';
import SupportMessage from './supportMessage';
import Report from './report';
import MyAccountData from './myaccountdata';
import Security from './security';
import MineBonus from './myBonus';
import LayoutTemplate from '../components/layoutTemplate';
import GameSearch from './gameSearch';
import InstallPwa from './installPwa';
import Mission from './promotion/mission';
import PayWithdrawtabpage from './pay/index';
import MysteryBonus from './mysteryBonus';

const Index = () => {
  const { isSportEnabled, switch_rakeback, giftMoneyData } = useSelector(
    (state) => ({
      isSportEnabled: state.app.isSportEnabled,
      switch_rakeback: state.app.websiteInitConfig?.system_config?.switch_rakeback,
      giftMoneyData: state?.app?.activityData?.gift_money,
    }),
    shallowEqual,
  );

  const makeRoute = (langConfig, index, noLocalePath, element) => {
    return (
      <Route
        path={
          langConfig.key.toLowerCase() == langsConfig.initLangKey.toLowerCase()
            ? noLocalePath
            : `/${langConfig.key.toLowerCase()}${noLocalePath}`
        }
        element={element}
        key={index}
      />
    );
  };

  const langs = langsConfig.supportLangList;

  return (
    <LayoutTemplate>
      <Routes>
        {langs.map((o, index) => makeRoute(o, index, '/', <Home />))}
        {/* 我的 */}
        {langs.map((o, index) => makeRoute(o, index, '/mine', <Mine2 />))}
        {/* 站内信 */}
        {/* {langs.map((o, index) => makeRoute(o, index, '/email', <MobileEmail />))} */}
        {/* 客服 */}
        {/* {langs.map((o, index) => makeRoute(o, index, '/support', <Support />))} */}
        {langs.map((o, index) => makeRoute(o, index, '/referral', <Referral />))}
        {langs.map((o, index) => makeRoute(o, index, '/referral/history', <ReferralHistory />))}

        {/* {langs.map((o, index) => makeRoute(o, index, '/tabpromote', <TabPromote />))} */}

        {/* {langs.map((o, index) => makeRoute(o, index, '/promotion/:promotionId', <Promotion />))} */}
        {/* {langs.map((o, index) => makeRoute(o, index, '/promotion', <Promotion />))} */}

        {langs.map((o, index) => (
          <Route path={`/${o.key.toLowerCase()}?/promotion`} element={<Promotion />} key={index}>
            <Route index element={<Events />} />
            <Route path="events" element={<Events />} />
            <Route path="mission" element={<Mission />} />
            <Route path="vip" element={<PromotionVip />} />
            <Route path="pending" element={<Pending />} />
            <Route path="history" element={<History />} />
            <Route path="rakeback" element={<Rakeback />} />
            <Route path="giftmoney" element={<GiftMoney />} />
            <Route path="redeem" element={<Redeem />} />
          </Route>
        ))}

        {langs.map((o, index) => makeRoute(o, index, '/promotion/:promotionId', <EventsDetails />))}
        {langs.map((o, index) => makeRoute(o, index, '/helpInfo', <HelpInfo />))}
        {langs.map((o, index) => makeRoute(o, index, '/termsofservice', <Termsofservice />))}
        {langs.map((o, index) => makeRoute(o, index, '/termsofservice2', <Termsofservice2 />))}
        {langs.map((o, index) => makeRoute(o, index, '/task/:id', <Task />))}
        {langs.map((o, index) => makeRoute(o, index, '/tournament', <Tournament />))}
        {langs.map((o, index) => makeRoute(o, index, '/tournament/:tournamentid', <TournamentDetail />))}
        {langs.map((o, index) => makeRoute(o, index, '/gamesearch', <GameSearch />))}
        {/* supportmessageId: 1 客服 2 站内信 */}
        {langs.map((o, index) => makeRoute(o, index, '/supportmessage/:supportmessageId', <SupportMessage />))}
        {langs.map((o, index) => makeRoute(o, index, '/report/:current', <Report />))}
        {langs.map((o, index) => makeRoute(o, index, '/myaccountdata', <MyAccountData />))}
        {langs.map((o, index) => makeRoute(o, index, '/security', <Security />))}
        {langs.map((o, index) => makeRoute(o, index, '/mybonus', <MineBonus />))}
        {/* {langs.map((o, index) => makeRoute(o, index, '/pay/withdraw', <BrDesignWithdraw />))} */}
        {langs.map((o, index) =>
          makeRoute(o, index, '/pay/withdrawtabpage/:withdrawtabpageId', <PayWithdrawtabpage />),
        )}
        {/* {langs.map((o, index) => makeRoute(o, index, '/pay/withdrawdetails', <BrDesignWidthdrawDetails />))} */}
        {/* {langs.map((o, index) => makeRoute(o, index, '/pay/deposit', <BrDesignDeposit />))} */}

        {/* {rakeback_switch === '1' ? langs.map((o, index) => makeRoute(o, index, '/betbonus', <BettingBonus />)) : <></>} */}
        {/* {langs.map((o, index) => makeRoute(o, index, '/betbonus', <BettingBonus />))} */}
        {langs.map((o, index) => makeRoute(o, index, '/installPwa', <InstallPwa />))}

        {langs.map((o, index) => makeRoute(o, index, '/gamelist', <GameList />))}

        {isSportEnabled ? langs.map((o, index) => makeRoute(o, index, '/sport', <Sport />)) : <></>}

        {langs.map((o, index) => makeRoute(o, index, '/mysterybonus', <MysteryBonus />))}

        {isEnvDevelopment() ? (
          <>
            {langs.map((o, index) => makeRoute(o, index, '/icons', <Icons />))}
            {langs.map((o, index) => makeRoute(o, index, '/testtest', <Test />))}
          </>
        ) : (
          <></>
        )}
        <Route path="*" element={<Custom404 />} />
      </Routes>
    </LayoutTemplate>
  );
};

export default memo(Index);
