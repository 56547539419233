import moment from 'moment';
import cn from 'classnames';
import { useState, useEffect } from 'react';
import Icon, { IconName } from '../../../../../components/icon';
import DialogModal from '../../../../../components/dialogModal';
import { getConfigTimeZoneTimestamp, getShowMoney, isLogin } from '../../../../../utils/helper';
import DatetimePickerBox from '../../../../../components/datetimePicker';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import Loading3 from '../../../../../components/loading3';
import { GetPerformanceList, GetPerformancedirectIndex, GetPerformanceindirectIndex } from '../../../../../api';
import NoData from '../../../../../components/noData';
import { List } from 'react-vant';
import ScrollbarContent from '../../../../../components/scrollbarContent';

const NTemplate001 = () => {
  const { t } = useTranslation();

  const [list, setList] = useState([]);
  const [finished, setFinished] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [isShowloading, setisShowLoading] = useState(false);
  const [visibleData, setVisibleData] = useState({
    visible: false,
    data: null,
    title: '',
    is_direct: '',
    amount: 0,
  });

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleStartChange = (val) => {
    setParams({ ...params, startDate: val });
  };

  const handleEndChange = (val) => {
    setParams({ ...params, endDate: val });
  };

  const getPerformanceData = async () => {
    if (!isLogin()) return;
    setisShowLoading(true);

    try {
      const newParams = {
        page: 1,
        page_size: params.pageSize,
        start_time: Math.floor(params.startDate.getTime() / 1000),
        end_time: Math.floor(params.endDate.getTime() / 1000),
      };

      const res = await GetPerformanceList(newParams);
      if (res.code == 0) {
        setTotalData(res.data.summary);
        setList(res.data.list);

        if (res.data.list.length < params.pageSize) {
          setFinished(true);
        } else {
          setParams({ ...params, page: 1 });
          setFinished(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisShowLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin()) {
      getPerformanceData();
    }
  }, []);

  const onLoad = async () => {
    try {
      setParams({ ...params, page: params.page + 1 });
      const currentPage = params.page + 1;
      const newParams = {
        page: currentPage,
        page_size: params.pageSize,
        start_time: Math.floor(params.startDate.getTime() / 1000),
        end_time: Math.floor(params.endDate.getTime() / 1000),
      };

      const res = await GetPerformanceList(newParams);
      if (res.code == 0) {
        setTotalData(res.data.summary);
        if (res.data.list.length < params.pageSize) {
          setFinished(true);
        } else {
          setList([...list, ...res.data.list]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="bg-[var(--colour-68)] flex flex-col h-full">
      <DialogModal
        shouldCloseOnOverlayClick={false}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setVisibleData({
            visible: false,
          });
        }}
        isOpen={visibleData.visible}
      >
        <DirectIndex
          handleClose={() => {
            setVisibleData({
              visible: false,
            });
          }}
          dateTime={visibleData.data}
          title={visibleData.title}
          is_direct={visibleData.is_direct}
          amount={visibleData.amount}
        />
      </DialogModal>

      <div className="flex flex-col flex-1">
        <div className="mt-[.2rem] p-[.2rem] w-[7.1rem] h-[1.32rem] box-shadow-performance rounded-[.16rem] bg-[var(--colour-69)] mx-auto">
          <div className="flex items-center space-x-[.36rem]">
            <DatetimePickerBox
              startDate={params.startDate}
              endDate={params.endDate}
              rangeCheckClassName="!w-[3rem] bg-[var(--colour-5)]"
              onChangeStartDate={handleStartChange}
              onChangeEndDate={handleEndChange}
              onSubmit={getPerformanceData}
            />

            <div className="space-x-[.1rem] text-[.2rem] font-[500]">
              <span className="text-[var(--colour-44)]">{t('refer.performance_wager')}</span>
              <span className="text-[var(--colour-38)]">
                {totalData && isLogin() ? getShowMoney(totalData.wager_amount, 2, 2) : '0.00'}
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-[.36rem] mt-[.18rem]">
            <div className="space-x-[.1rem] text-[.2rem] font-[500] w-[3rem]">
              <span className="text-[var(--colour-44)]">{t('refer.performance_directwager')}</span>
              <span className="text-[var(--colour-38)]">
                {totalData && isLogin() ? getShowMoney(totalData.direct_wager_amount, 2, 2) : '0.00'}
              </span>
            </div>

            <div className="space-x-[.1rem] text-[.2rem] font-[500]">
              <span className="text-[var(--colour-44)] ">{t('refer.performance_indirectwager')}</span>
              <span className="text-[var(--colour-38)]">
                {totalData && isLogin() ? getShowMoney(totalData.indirect_wager_amount, 2, 2) : '0.00'}
              </span>
            </div>
          </div>
        </div>
        <div className={`w-[7.1rem] mx-auto overflow-y-auto flexAuto hidden-scroll-y`}>
          {isShowloading ? (
            <Loading3 className1="w-full h-full flex items-center justify-center"></Loading3>
          ) : (
            <>
              {list && list.length > 0 ? (
                <List finished={finished} onLoad={onLoad} offset={0} loadingText={<Loading3 className1="w-full" />}>
                  {list.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={cn('h-[1.32rem] p-[.2rem] rounded-[.16rem]', {
                          'box-shadow-performance bg-[var(--colour-69)]': index % 2 != 0,
                        })}
                      >
                        <div className="flex items-center space-x-[.36rem] mt-[.12rem]">
                          <div className="space-x-[.1rem] text-[.2rem] font-[500] w-[3rem]">
                            <span className="text-[var(--colour-44)]">{t('refer.performance_time')}</span>
                            <span className="text-[var(--colour-38)]">
                              {getConfigTimeZoneTimestamp(item.date_time * 1000).format('DD/MM/YYYY')}
                            </span>
                          </div>

                          <div className="space-x-[.1rem] text-[.2rem] font-[500]">
                            <span className="text-[var(--colour-44)]">{t('refer.performance_wager')}</span>
                            <span className="text-[var(--colour-38)]">{getShowMoney(item.wager_amount, 2, 2)}</span>
                          </div>
                        </div>

                        <div className="flex items-center space-x-[.36rem] mt-[.18rem]">
                          <div className="space-x-[.1rem] text-[.2rem] font-[500] w-[3rem]">
                            <span className="text-[var(--colour-44)]">{t('refer.performance_directwager')}</span>
                            <span
                              className={cn({
                                'text-[var(--colour-1)]  border-b-[.01rem] border-[var(--colour-1)] cursor-pointer':
                                  item.direct_wager_amount > 0,
                                'text-[var(--colour-38)] pointer-events-none': item.direct_wager_amount <= 0,
                              })}
                              onClick={() => {
                                setVisibleData({
                                  visible: true,
                                  data: item.date_time,
                                  title: t('refer.performance_directwager'),
                                  is_direct: '1',
                                  amount: item.direct_wager_amount,
                                });
                              }}
                            >
                              {getShowMoney(item.direct_wager_amount, 2, 2)}
                            </span>
                          </div>

                          <div className="space-x-[.1rem] text-[.2rem] font-[500]">
                            <span className="text-[var(--colour-44)] ">{t('refer.performance_indirectwager')}</span>
                            <span
                              className={cn({
                                'text-[var(--colour-1)] border-b-[.01rem] border-[var(--colour-1)] cursor-pointer':
                                  item.indirect_wager_amount > 0,
                                'text-[var(--colour-38)] pointer-events-none': item.indirect_wager_amount <= 0,
                              })}
                              onClick={() => {
                                setVisibleData({
                                  visible: true,
                                  data: item.date_time,
                                  title: t('refer.performance_indirectwager'),
                                  is_direct: '2',
                                  amount: item.indirect_wager_amount,
                                });
                              }}
                            >
                              {getShowMoney(item.indirect_wager_amount, 2, 2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </List>
              ) : (
                <NoData className="w-full h-full" />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const DirectIndex = ({ handleClose = () => {}, dateTime, title, is_direct, amount }) => {
  const { t } = useTranslation();
  const [finished, setFinished] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);

  const getDirectdata = async () => {
    setisLoading(true);
    try {
      let res,
        data = {
          page: 1,
          page_size: 10,
          date_time: dateTime,
        };
      if (is_direct == '1') {
        res = await GetPerformancedirectIndex(data);
      } else if (is_direct == '2') {
        delete data.page;
        delete data.page_size;
        res = await GetPerformanceindirectIndex(data);
      }

      if (res.code == 0) {
        if (res.data.list.length < 10) {
          setFinished(true);
        } else {
          setFinished(false);
        }
        setList(res.data.list);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getDirectdata();
  }, []);

  const onLoad = async () => {
    try {
      setPage(page + 1);
      const currentPage = page + 1;
      const data = {
        page: currentPage,
        page_size: 10,
        date_time: dateTime,
      };

      const res = await GetPerformancedirectIndex(data);

      if (res.code == 0) {
        if (res.data.list.length < 10) {
          setFinished(true);
        } else {
          setList([...list, ...res.data.list]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="w-[6.7rem] h-[10.1rem] rounded-[.16rem] bg-[--colour-35] box-shadow-directIndex border border-[var(--colour-6)] pl-[.22rem] pr-[.28rem] relative flex flex-col">
        <div
          className={cn(
            'active:scale-[.95] transition-all select-none w-[.6rem] h-[.6rem] rounded-[50%] flex justify-center items-center absolute left-[50%] bottom-[-0.86rem] translate-x-[-50%]',
          )}
          onClick={() => {
            handleClose();
          }}
        >
          <Icon name={IconName.SignInClose} />
        </div>

        <div className="w-full flex items-center justify-center text-[var(--colour-10)] text-[.28rem] font-[600] my-[.22rem]">
          {title}
        </div>

        <div className="flex items-center mb-[.22rem] space-x-[.08rem] text-[.2rem] font-[500]">
          <div className="space-x-[.1rem]">
            <span className="text-[var(--colour-44)]">{t('refer.performance_time')}</span>
            <span className="text-[var(--colour-38)]">
              {dateTime ? getConfigTimeZoneTimestamp(dateTime * 1000).format('DD/MM/YYYY') : ''}
            </span>
          </div>
          <div className="space-x-[.1rem]">
            <span className="text-[var(--colour-44)]">{title}</span>
            <span className="text-[var(--colour-38)]">{getShowMoney(amount, 2, 2)}</span>
          </div>
        </div>

        <div className="w-[6.2rem] mx-[auto] flexAuto overflow-y-auto hidden-scroll-y">
          {isLoading ? (
            <Loading3 className1="w-full h-full"></Loading3>
          ) : (
            <>
              {list && list.length ? (
                <>
                  {is_direct == 1 ? (
                    <List finished={finished} onLoad={onLoad} offset={0} loadingText={<Loading3 className1="w-full" />}>
                      {list.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={cn('px-[.2rem] h-[1.36rem] rounded-[.16rem] pt-[.24rem] relative', {
                              'bg-[var(--colour-68)]': index % 2 == 0,
                            })}
                          >
                            <div className="w-[.36rem] h-[.2rem] rounded-[.04rem_0] bg-[var(--colour-25)] flex items-center justify-center text-[var(--colour-2)] text-[.16rem] font-[600] absolute left-0 top-0">
                              V{item.vip_level}
                            </div>

                            <div className="flex items-center space-x-[.24rem]">
                              <div className="w-[2.66rem] space-x-[.2rem] flex items-center">
                                <div className="text-[.2rem] font-[500] text-[var(--colour-38)]">{item.uid}</div>
                                <div
                                  className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]"
                                  onClick={() => {
                                    copy(`${item.uid}`);
                                    toast.success(t('home.toast006'), {
                                      containerId: 'global',
                                    });
                                  }}
                                >
                                  <Icon name={IconName.Copy4}></Icon>
                                </div>
                              </div>

                              <div className="flex items-center text-[.2rem] font-[500] w-[2.74rem] flex-wrap">
                                <div className="text-[var(--colour-44)]">{t('refer.performance_rendimento')}</div>
                                <div className="text-[var(--colour-38)] ml-[.1rem]">
                                  {getShowMoney(item.wager_amount, 2, 2)}
                                </div>
                              </div>
                            </div>

                            <div className="mt-[.1rem] flex space-x-[.24rem]">
                              <div className="w-[2.66rem] flex items-center flex-wrap text-[.2rem] font-[500] leading-[.3rem]">
                                <div>{t('refer.performance_userwin')}</div>
                                <div
                                  className={cn('ml-[.1rem]', {
                                    'text-[var(--colour-24)]': item.user_win_amount < 0,
                                    'text-[var(--colour-25)]': item.user_win_amount >= 0,
                                  })}
                                >
                                  <span>{item.user_win_amount >= 0 ? '+' : ''}</span>
                                  {getShowMoney(item.user_win_amount, 2, 2)}
                                </div>
                              </div>

                              <div className="flex text-[.2rem] font-[500]  w-[2.74rem] flex-wrap">
                                <div className="text-[var(--colour-44)]">{t('refer.performance_bonus')}</div>
                                <div
                                  className={cn('ml-[.1rem]', {
                                    'text-[var(--colour-24)]': item.bonus_amount < 0,
                                    'text-[var(--colour-25)]': item.bonus_amount >= 0,
                                  })}
                                >
                                  <span>{item.bonus_amount >= 0 ? '+' : ''}</span>
                                  {getShowMoney(item.bonus_amount, 2, 2)}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </List>
                  ) : (
                    <>
                      {list.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={cn('px-[.2rem] h-[1.36rem] rounded-[.16rem] pt-[.24rem] relative', {
                              'bg-[var(--colour-68)]': index % 2 == 0,
                            })}
                          >
                            <div className="w-[.36rem] h-[.2rem] rounded-[.04rem_0] bg-[var(--colour-25)] flex items-center justify-center text-[var(--colour-2)] text-[.16rem] font-[600] absolute left-0 top-0">
                              V{item.vip_level}
                            </div>

                            <div className="flex items-center space-x-[.24rem]">
                              <div className="w-[2.66rem] space-x-[.2rem] flex items-center">
                                <div className="text-[.2rem] font-[500] text-[var(--colour-38)]">{item.uid}</div>
                                <div
                                  className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]"
                                  onClick={() => {
                                    copy(`${item.uid}`);
                                    toast.success(t('home.toast006'), {
                                      containerId: 'global',
                                    });
                                  }}
                                >
                                  <Icon name={IconName.Copy4}></Icon>
                                </div>
                              </div>

                              <div className="flex items-center text-[.2rem] font-[500] w-[2.74rem] flex-wrap">
                                <div className="text-[var(--colour-44)]">{t('refer.performance_rendimento')}</div>
                                <div className="text-[var(--colour-38)] ml-[.1rem]">
                                  {getShowMoney(item.wager_amount, 2, 2)}
                                </div>
                              </div>
                            </div>

                            <div className="mt-[.1rem] flex space-x-[.24rem]">
                              <div className="w-[2.66rem] flex items-center flex-wrap text-[.2rem] font-[500] leading-[.3rem]">
                                <div>{t('refer.performance_userwin')}</div>
                                <div
                                  className={cn('ml-[.1rem]', {
                                    'text-[var(--colour-24)]': item.user_win_amount < 0,
                                    'text-[var(--colour-25)]': item.user_win_amount >= 0,
                                  })}
                                >
                                  <span>{item.user_win_amount >= 0 ? '+' : ''}</span>
                                  {getShowMoney(item.user_win_amount, 2, 2)}
                                </div>
                              </div>

                              <div className="flex text-[.2rem] font-[500]  w-[2.74rem] flex-wrap">
                                <div className="text-[var(--colour-44)]">{t('refer.performance_bonus')}</div>
                                <div
                                  className={cn('ml-[.1rem]', {
                                    'text-[var(--colour-24)]': item.bonus_amount < 0,
                                    'text-[var(--colour-25)]': item.bonus_amount >= 0,
                                  })}
                                >
                                  <span>{item.bonus_amount >= 0 ? '+' : ''}</span>
                                  {getShowMoney(item.bonus_amount, 2, 2)}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              ) : (
                <NoData className="w-full h-full" />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NTemplate001;
