import DownloadManager from '../manager/downloadManager';
import { parentLocationReplace } from '../manager/iframeManager';
import { isLocalHost } from './deviceUtils';

/**
 * 下载管理器
 */
class OAuthRedirecting {
  static URL = null;
  static Theme = 'theme-2';
  //未登录
  static NotLoggedInLsKey = 'notLoggedAuthRedirect';
  //已登录
  static AlreadyLoggedLsKey = 'alreadyLoggedAuthRedirect';

  static GetLoginRedirectUrl(url, identity) {
    return `${url}?theme=${this.Theme}&identity=${identity}&redirect_url=${encodeURIComponent(window.location.href.replace('https://m.', 'https://'))}`;
  }

  static Logout() {
    localStorage.removeItem(this.AlreadyLoggedLsKey);
    localStorage.removeItem(this.NotLoggedInLsKey);
  }

  static RemoveNotLoggedInLsKey() {
    localStorage.removeItem(this.NotLoggedInLsKey);
  }

  static ReplaceUrlParams(url) {
    const showUrl = DownloadManager.checkInIframe() ? url : url.replace('https://m.', 'https://');
    if (history.pushState) {
      history.replaceState(null, null, showUrl);
    } else {
      window.location.href = showUrl; // 这会重新加载页面
    }
  }

  static LocationReplace(url) {
    if (DownloadManager.checkInIframe()) {
      parentLocationReplace(url);
    } else {
      window.location.replace(url);
    }
  }

  static GetRedirectUrl(url) {
    return `${url}?theme=${this.Theme}&redirect_url=${encodeURIComponent(window.location.href.replace('https://m.', 'https://'))}`;
  }

  static Login(url, identity) {
    localStorage.setItem(this.AlreadyLoggedLsKey, '1');
    localStorage.setItem(this.NotLoggedInLsKey, '1');
    this.LocationReplace(this.GetLoginRedirectUrl(url, identity));
  }

  static NotLoggedIn(url) {
    //判断是否有跳转过auth，没有跳转过的需要跳转一次
    if (!localStorage.getItem(this.NotLoggedInLsKey)) {
      localStorage.setItem(this.NotLoggedInLsKey, '1');
      this.LocationReplace(this.GetRedirectUrl(url));
    }
  }

  static AlreadyLoggedInNeedRedirect() {
    return !localStorage.getItem(this.AlreadyLoggedLsKey);
  }

  static NotLoggedInNeedRedirect() {
    return !localStorage.getItem(this.NotLoggedInLsKey);
  }

  //用于将已经登录过的用户的identity同步到auth
  static AlreadyLoggedIn(url, identity) {
    if (!localStorage.getItem(this.AlreadyLoggedLsKey)) {
      localStorage.setItem(this.AlreadyLoggedLsKey, '1');
      this.LocationReplace(this.GetLoginRedirectUrl(url, identity));
    }
  }

  static Init(initIdentity, websiteInfo) {
    return new Promise((resolve, reject) => {
      if (websiteInfo && websiteInfo.save_info_domain && !isLocalHost()) {
        OAuthRedirecting.Theme = websiteInfo.website_theme;
        OAuthRedirecting.URL = websiteInfo.save_info_domain;
        if (initIdentity != null) {
          if (OAuthRedirecting.AlreadyLoggedInNeedRedirect()) {
            OAuthRedirecting.AlreadyLoggedIn(websiteInfo.save_info_domain, initIdentity);
          } else {
            resolve();
          }
        } else {
          if (OAuthRedirecting.NotLoggedInNeedRedirect()) {
            OAuthRedirecting.NotLoggedIn(websiteInfo.save_info_domain);
          } else {
            resolve();
          }
        }
      } else {
        resolve();
      }
    });
  }
}

export default OAuthRedirecting;
