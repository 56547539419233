import cn from 'classnames';

const SwitcherItem = ({ text, onClick, isCurrent = false, isRedDot = false }) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'flex-1 text-center text-[.28rem] font-[600] leading-[1.2] p-[0_0_.3rem_0] cursor-pointer relative',
        {
          'text-[var(--colour-13)] border-b border-b-[var(--colour-1)]': isCurrent,
          'text-[var(--colour-14)]': !isCurrent,
        },
      )}
    >
      {text}
      {isRedDot && (
        <span className="w-[.16rem] h-[.16rem] rounded-full bg-[var(--colour-25)] absolute right-[0] top-[-.08rem]"></span>
      )}
    </div>
  );
};

export const TypeSwitcher = ({ list = [], currentIndex, handleClick }) => {
  console.log('ListSwitcher', list);

  return (
    list.length > 0 && (
      <div className="flex pt-[.36rem] bg-[var(--colour-54)]">
        {list.map((item, index) => (
          <SwitcherItem
            key={index}
            text={item?.text}
            isCurrent={currentIndex == index}
            isRedDot={item?.isRedDot == true}
            onClick={() => {
              handleClick(index);
            }}
          />
        ))}
      </div>
    )
  );
};
