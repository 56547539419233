import cn from 'classnames';
import GameItem from '../../../../../components/gameItem';
import NoData from '../../../../../components/noData';
import Loading2 from '../../../../../components/loading2';
import { shallowEqual, useSelector } from 'react-redux';
import { ResponsiveMode } from '../../../../../redux/reducers/appSlice';
import { isUseLayoutMobileHome } from '../../../../../utils/helper';

const GameList = ({ list = [], isLoading }) => {
  // console.log('list', list, 'isLoading', isLoading);

  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: state?.app?.responsiveMode,
    }),
    shallowEqual,
  );

  return isLoading ? (
    <div className="pt-[4rem]">
      <Loading2 />
    </div>
  ) : list.length > 0 ? (
    <div className={cn('games__row', {})}>
      {list.map((item, index) => (
        <GameItem model={item} key={item.id} />
      ))}
    </div>
  ) : (
    <NoData className="pt-[4rem]" />
  );
};

export default GameList;
