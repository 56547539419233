import { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getShowMoney, isBrNTemplateId, isControllableSite, getTemplateId } from '../../utils/helper';
import { get } from 'lodash';
import { GetUserMessageList, PwaGuideReward } from '../../api';
import { t } from 'i18next';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setRefetchActivityData,
  setRefetchVipData,
  setShowWGDeposit,
  setNeedUpdateDepositSuccess,
  setDepositSuccessPwaGuideData,
  setShowPandaDeposit,
} from '../../redux/reducers/appSlice';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import { useVisibilityChange } from '../../hooks/useVisibilityChange';
import { getAppTag, isAndroidAPK } from '../../utils/deviceUtils';

/**
 * 消息组件
 */
const Message = () => {
  const dispatch = useDispatch();
  const [requesting, setRequesting] = useState(false);

  const { visible } = useVisibilityChange();

  const {
    identity,
    depositMsg,
    currencyCode,
    dialogModalKey,
    gameUuid,
    websiteInfo,
    showWGDeposit,
    isGameOpen,
    needShowMystreyBonusPopup,
    showPandaDeposit,
  } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      depositMsg: get(state, 'ws.depositMsg'),
      currencyCode: get(state, 'app.websiteInitConfig.website_info.currency_code'),
      dialogModalKey: get(state, 'app.dialogModalProp.show'),
      dialogModalKey2: get(state, 'app.dialogModalProp2.show'),
      gameUuid: get(state, 'app.gamePopData.gameUuid'),
      websiteInfo: state.app.websiteInitConfig?.website_info,
      showWGDeposit: state.app.showWGDeposit,
      isGameOpen: state.app.isGameOpen,
      needShowMystreyBonusPopup: state.app.needShowMystreyBonusPopup,
      showPandaDeposit: state.app.showPandaDeposit,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!visible) {
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.AC_LeaveWeb);
    }
    console.log(visible ? '[进入前台]' : '[进入后台]');
  }, [visible]);

  // 三种情况获取充值消息列表 1 有身份串 2 有充值ws消息 3 前台
  useEffect(() => {
    if (identity && visible && !requesting) {
      depositMessageListReq();
    }
  }, [identity, depositMsg, visible]);

  // 获取充值消息列表接口
  const depositMessageListReq = () => {
    setRequesting(true);
    GetUserMessageList()
      .then((res) => {
        console.log('[message]: 充值数据', res);
        if (res && res.code == 0) {
          const list = res.result.list || [];
          list.forEach((item, index) => {
            if (item.msg_type == 'user_deposit') {
              depositSuccess(item.msg_body, index, gameUuid, isGameOpen);
            }
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  /**
   * 充值成功
   * @param {data}   msg   数据
   * @param {number} index 下标
   */
  const depositSuccess = async (msg, index, gameUuid, isGameOpen) => {
    const currency = currencyCode;
    const first_charge = msg.first_charge;
    const amount = getShowMoney(msg.amount);
    const bonus = formatBonus(msg.bonus_amount);
    let showPopup = null; // 是否显示pwa 引导弹窗
    let pwaAmount = 0;
    const data = {
      ...msg,
      uid: msg.uid,
      first_charge: msg.first_charge,
      amount,
      transactionId: msg.transaction_id,
      date: msg.action_time,
      gameUuid,
    };

    // 只更新一次
    if (index === 0) {
      // 更新余额
      dispatch(setNeedUpdateBalance({ need: true }));
      // 刷新红包雨, 刷新vip
      let _timer = window.setTimeout(() => {
        dispatch(setRefetchActivityData());
        dispatch(setRefetchVipData());
        window.clearTimeout(_timer);
      }, 2000);

      // h5 和 apk 下弹出充值引导pwa
      if (getAppTag() == '' || (isAndroidAPK() && websiteInfo.download.apk_jump_pwa.switch == 1)) {
        const res = await PwaGuideReward();
        if (res.code == 0) {
          const data = res.data;
          showPopup = data.show_popup;
          pwaAmount = data.amount;
        }
      }
    }

    const handlePopupLogic = () => {
      // showPopup 返回 0:不弹出 1:弹出
      if (showPopup != null && showPopup == '1') {
        if (isGameOpen) {
          dispatch(
            setDepositSuccessPwaGuideData({
              depositData: data,
              content: pwaAmount,
            }),
          );
        } else {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.FirstDepositDownload,
              depositData: data,
              content: pwaAmount,
            }),
          );
        }
      }
    };

    // 显示弹窗
    if (
      dialogModalKey == DialogModalChildrenKey.WithdrawDeposit ||
      (isControllableSite() && showWGDeposit) ||
      (['d-template003'].includes(getTemplateId()) && showPandaDeposit)
    ) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.DepositSuccess,
          zIndex: 9999,
          depositData: data,
          closeCb: () => {
            handlePopupLogic();
          },
        }),
      );

      if (isControllableSite() && showWGDeposit) {
        dispatch(setShowWGDeposit(false));
      }
      if (['d-template003'].includes(getTemplateId()) && showPandaDeposit) {
        dispatch(setShowPandaDeposit(false));
      }
    } else {
      handlePopupLogic();
    }
    dispatch(setNeedUpdateDepositSuccess({ need: true }));

    // 弹toast
    const options = { currency, amount, bonus };
    toast.success(t('home.toast021', options), {
      containerId: 'global',
    });

    // 统计
    AnalyticsManager.rechargeSuccess({ currency, amount, first_charge });
  };

  return <></>;
};

const formatBonus = (bonus) => {
  return bonus > 0 ? '+' + getShowMoney(bonus) : '';
};

export default memo(Message);
