import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LoginByFacebookReq, LoginByGoogleReq, LoginByTelegramReq } from '../api/index-auth';
import { DialogModalChildrenKey, setDialogModalVisible, setGuideToBind } from '../redux/reducers/appSlice';
import { setLoginInfo, setLoginMethod, setLoginType, setUserObj } from '../redux/reducers/userSlice';
import AnalyticsManager, { FirebaseEvents } from '../manager/analyticsManager';
import useInitActivity from './useInitActivity';
import { get } from 'lodash';
import store from '../redux/store';
import PopupManager from '../utils/popupManager';
import { isControllableSite } from '../utils/helper';
import OAuthRedirecting from '../utils/oauthRedirecting';

/**
 * 第三方登录hook
 */
const useLoginThirdParty = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { InitActivity } = useInitActivity();

  const getFormData = () => {
    return {
      invite_code: get(store.getState(), 'user.inviteCode', ''),
      a_id: get(store.getState(), 'user.activityId', ''),
      osType: get(store.getState(), 'user.osType', ''),
    };
  };

  // facebook登录
  const LoginByFacebook = (token) => {
    LoginByFacebookReq({
      token: token,
      ...getFormData(),
    }).then((res) => {
      if (res.code == 0) {
        onLoginSuccess(res.result);
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  // google登录
  const LoginByGoogle = (token) => {
    LoginByGoogleReq({
      token: token,
      ...getFormData(),
    }).then((res) => {
      if (res.code == 0) {
        onLoginSuccess(res.result);
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const LoginByTG = (user) => {
    console.log('[LoginByTG]:', user);

    LoginByTelegramReq({
      json: JSON.stringify(user),
      ...getFormData(),
    }).then((res) => {
      if (res.code == 0) {
        onLoginSuccess(res.result);
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  // 登录成功
  const onLoginSuccess = (result) => {
    dispatch(setLoginMethod(3));
    dispatch(setLoginType(result.type));
    if (result.type == 'signup') {
      PopupManager.setIsRegister();
    } else {
      PopupManager.removeIsRegister();
    }

    //InitActivity(true, result.user_obj).then(() => {
    // 如果手机号和邮箱都没绑定，提示去绑定
    // let userObj = result.user_obj;
    // if (userObj?.mobile_verfied == '0' && userObj?.email_verfied == '0') {
    //   dispatch(setGuideToBind({ showGuideTips: true }));
    // }
    //});
    if (OAuthRedirecting.URL == null) {
      if (result.type == 'signup') {
        AnalyticsManager.registerSuccess(result.user_obj);
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Reg_Success);
      } else {
        AnalyticsManager.loginSuccess();
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Log_Success);
      }

      toast.success(t('home.toast008'), {
        containerId: 'global',
      });
      dispatch(setUserObj(result.user_obj));
      dispatch(
        setLoginInfo({
          identity: result.identity,
        }),
      );
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.Default,
        }),
      );
    } else {
      OAuthRedirecting.Login(OAuthRedirecting.URL, result.identity);
    }
  };

  return {
    LoginByFacebook,
    LoginByGoogle,
    LoginByTG,
    onLoginSuccess,
  };
};

export default useLoginThirdParty;
