import Loading from '../../../../../components/loading2';
import NoData from '../../../../../components/noData';
import LoadingMoreWithText from '../../../../../components/loadingMoreWithText';
import GameItem from '../../../../../components/gameItem';
import { memo } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

const SearchGame = ({
  list = [],
  total = 0,
  loading = false,
  page = 1,
  loadingMore = false,
  showLoadingMore = false,
  handleLoadMore = () => {},
}) => {
  const [reRequest, setreRequest] = useStateIfMounted('');
  const handleFavoriteCallback = () => {
    setreRequest(new Date().getTime());
  };

  return (
    <>
      {loading ? (
        <Loading height="8rem" />
      ) : (
        <>
          {list && list.length > 0 ? (
            <div className="games__row gap-[.16rem] pb-[.3rem]">
              {list &&
                list.length > 0 &&
                list.map((item, index) => (
                  <GameItem model={item} key={item.id} handleFavoriteCallback={handleFavoriteCallback} />
                ))}
            </div>
          ) : (
            <NoData className="py-[3rem]" />
          )}

          {showLoadingMore ? (
            <LoadingMoreWithText
              currentNum={list.length}
              total={total}
              className="mt-[.4rem] mb-[.2rem]"
              showLoading={loadingMore && page > 1}
              handleLoadMore={handleLoadMore}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default memo(SearchGame);
