import GiftMoney from '../../../../../components/giftMoney/giftMoney';

const NTemplate001 = () => {
  return (
    <div className="h-full overflow-y-auto scrollbar-none bg-[var(--colour-35)]">
      <GiftMoney isDialog={false} />
    </div>
  );
};

export default NTemplate001;
