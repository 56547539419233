import cn from 'classnames';
import Loading2 from './loading2';
import NoData from './noData';

const HeadCell = ({ children, className = '', isShow = true }) => {
  return isShow ? <div className={`${className}`}>{children}</div> : <></>;
};

const HeadBox = ({ list, className = '', onClick = () => {} }) => {
  return list && list.length > 0 ? (
    <div className={`${className}`} onClick={onClick}>
      {list.map((item, index) => {
        return (
          <HeadCell className={item?.className} isShow={item?.isShow} key={index}>
            {item?.content}
          </HeadCell>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

const DataCell = ({ children, className = '', isShow = true }) => {
  return isShow ? <div className={`${className}`}>{children}</div> : <></>;
};

const DataLine = ({ list = [], className = '', onClick = () => {} }) => {
  return (
    <div className={`${className}`} onClick={onClick}>
      {list.map((item, index) => {
        return (
          <DataCell isShow={item?.isShow} className={item?.className} key={index}>
            {item?.content}
          </DataCell>
        );
      })}
    </div>
  );
};

const BodyBox = ({ list = [], className = '' }) => {
  return (
    <div className={`${className}`}>
      {list.map((item, index) => {
        return <DataLine list={item?.list} key={index} className={item?.className} onClick={item?.onClick} />;
      })}
    </div>
  );
};

const DataTable = ({
  needScroll = false,
  scrollBoxClassName = '',
  tableClassName = '',
  head = null,
  body = null,
  isLoading = false,
  loadingContent = <Loading2 height="6rem" />,
  noDataContent = <NoData className="py-[2rem]" />,
}) => {
  const list = body?.list || [];

  return (
    <div
      className={cn(`${scrollBoxClassName}`, {
        'overflow-x-auto scrollbar-none': needScroll,
      })}
    >
      <div className={`${tableClassName}`}>
        <HeadBox list={head?.list} className={head?.className} onClick={head?.onClick} />
        {isLoading ? (
          loadingContent
        ) : list.length > 0 ? (
          <BodyBox list={body?.list} className={body?.className} />
        ) : (
          noDataContent
        )}
      </div>
    </div>
  );
};

export default DataTable;
