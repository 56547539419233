import { createRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import { GetActivityGamesReq } from '../../api';
import Loading from '../loading2';
import GameItem from '../gameItem';
import NoData from '../noData';
import LoadingMoreWithText from '../loadingMoreWithText';

const GameList = forwardRef(({ id }, ref) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [elRefs, setElRefs] = useStateIfMounted([]);
  const [list, setList] = useStateIfMounted([]);
  const [total, setTotal] = useStateIfMounted(0);
  const pageSize = 30;
  const [params, setParams] = useStateIfMounted({
    activity_id: undefined,
    page: 1,
    page_size: pageSize,
  });

  useEffect(() => {
    if (params.activity_id) {
      if (params.page == 1) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }
      GetActivityGamesReq(params).then((res) => {
        if (params.page == 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
        if (res.code == 0) {
          const result = res.result;
          if (params.page == 1) {
            setList(result.list);
          } else {
            setList(list.concat(result.list));
          }

          const _pageCount = Math.ceil(result.pages.count / pageSize);
          setTotal(result.pages.count);
          if (params.page < _pageCount) {
            setShowLoadingMore(true);
          } else {
            setShowLoadingMore(false);
          }
        }
      });
    }
  }, [params]);

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  useEffect(() => {
    if (id != 0) {
      setParams({ ...params, page: 1, activity_id: id });
    }
  }, [id]);

  const goToFirstGame = () => {
    if (elRefs.length > 0) {
      elRefs[0].current && elRefs[0].current.handleOnClick();
    }
  };

  useImperativeHandle(ref, () => ({
    goToFirstGame: goToFirstGame,
  }));

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(list.length)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [list.length]);

  return (
    <>
      <p className="text-left w-full font-[400] text-[.24rem]">{t('task.gamesTitle')}</p>
      {loading ? (
        <Loading height="4rem" />
      ) : (
        <>
          {list && list.length > 0 ? (
            <div className="my-[.3rem] bg-[var(--colour-35)] rounded-normal games__row w-full px-[.28rem] py-[.2rem]">
              {list.map((item, index) => (
                <GameItem ref={elRefs[index]} model={item} key={item.id} />
              ))}
            </div>
          ) : (
            <NoData className="py-[2rem]" />
          )}
          <LoadingMoreWithText
            currentNum={list.length}
            total={total}
            className="mb-[.2rem]"
            showLoading={loadingMore && params.page > 1}
            handleLoadMore={handleLoadMore}
          />
          {/* {showLoadingMore ? (
            <LoadMore
              className="mb-[20px] mobile:mb-[10px]"
              onClick={handleLoadMore}
              showLoading={loadingMore && params.page > 1}
            />
          ) : (
            <></>
          )} */}
        </>
      )}
    </>
  );
});

export default GameList;
