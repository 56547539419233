import { get, truncate } from 'lodash';
import store from '../redux/store';
import { GetDownloadPage, GetDownloadLink } from '../api';
import { openUrl } from '../utils/openUrl';
import config from '../constants/config';
import {
  getAppTag,
  isAndroidChannelNative,
  isAndroidNative,
  isMobileSafari,
  isPWA,
  isAndroidAPK,
  isPwaChannelNative,
} from '../utils/deviceUtils';
import isMobile from 'ismobilejs';
import { toast } from 'react-toastify';
import {
  getInviteCode,
  bytesToMegabytes,
  getMobileOperatingSystem,
  isInIframe,
  getIdentity,
  isPCLayout,
} from '../utils/helper';
import axios from 'axios';
import AnalyticsManager, { FirebaseEvents } from './analyticsManager';
import {
  DialogModalChildrenKey,
  setBottomDownloadBoxPromptSwitch,
  setDialogModalVisible,
  setInstallStatus,
} from '../redux/reducers/appSlice';
import i18n from 'i18next';
import { toLocalePath } from '../utils/localePath';

/**
 * 下载管理器
 */
class DownloadManager {
  /**检测是否是https://m.开头，且在iframe里面 */
  static checkInIframe() {
    const ori = window.location.origin.toLowerCase();
    return (ori.startsWith('https://m.') || ori.startsWith('http://m.')) && isInIframe();
  }

  /**
   * 安卓下载
   */
  static androidDownload() {
    const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
    if (websiteInitConfig && websiteInitConfig.website_info.download.pwa.switch == '0') {
      // 下载apk
      this.gotoDownloadPage();
    } else {
      // 检测pwa
      this.checkPwaInstall();
    }
  }

  /**
   * 新手活动判断是否提示用移动设备
   */
  static get showUseMobileTips() {
    return isMobile().android.device;
  }

  /***非移动设备 */
  static get isNotMobile() {
    return !isMobile().any;
  }

  /**
   * 显示安卓下载
   */
  static get showAndroid() {
    return isMobile().android.device && !isPWA() && !isAndroidNative();
  }

  /**
   * 显示iOS下载
   */
  static get showIOS() {
    return isMobile().apple.device && !isPWA() && !this.isIOSNative;
  }

  // ios原生马甲包
  static get isIOSNative() {
    return navigator.userAgent.toLowerCase().indexOf('bra88_native') > -1;
  }

  static get() {
    const isPwaInstalled = localStorage.getItem('pwaInstalled') || '';
    return isPwaInstalled == '1';
  }

  // 检测webapp安装
  static async checkPwaInstall(isGotoDownloadPage = true) {
    if (!isInIframe()) {
      try {
        const webappList = await navigator.getInstalledRelatedApps();
        if (webappList.length > 0) {
          //if (this.isPwaInstalled) {
          // 有webapp,直接跳转
          openUrl(window.location.href);
        } else {
          // 没有webapp,提示安装
          if (window.deferredPrompt) {
            window.deferredPrompt.prompt();
            window.deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                // 处理安装逻辑,暂时不处理
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_InstallButton);
                localStorage.setItem('pwaInstalled', '1');
              }
              window.deferredPrompt = null;
            });
          } else {
            if (isGotoDownloadPage) {
              // 不支持webapp走下载
              this.gotoDownloadPage();
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (isGotoDownloadPage) {
          this.gotoDownloadPage();
        }
      }
    } else {
      if (isGotoDownloadPage) {
        // 不支持webapp走下载
        this.gotoDownloadPage();
      }
    }
  }

  static gotoPWATip() {
    const t = i18n.t;
    store.dispatch(
      setDialogModalVisible({
        zIndex: 10002,
        show: DialogModalChildrenKey.Confirm,
        content: t('home.pwaInstallTips'),
        confirmCb: () => {
          store.dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
        },
      }),
    );
  }

  static async installCheck(isFastDownload = true) {
    try {
      const webappList = await navigator.getInstalledRelatedApps();
      if (webappList.length > 0) {
        // 检测支持pwa & 安装完成 直接跳转pwa
        if (isFastDownload) {
          this.gotoPWATip();
          return openUrl(window.location.href);
        }
      } else {
        // 没有webapp,提示安装
        if (window.deferredPrompt) {
          window.deferredPrompt.prompt();
          window.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              store.dispatch(setInstallStatus(1));
              if (isFastDownload) {
                store.dispatch(
                  setDialogModalVisible({
                    zIndex: 999999,
                    show: DialogModalChildrenKey.FastDownload,
                  }),
                );
              }
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_InstallButton);
              localStorage.setItem('pwaInstalled', '1');
            }
            window.deferredPrompt = null;
          });
        }
      }
    } catch (error) {
      this.gotoDownloadPage();
      console.error(error);
    }
  }

  // 跳转下载界面
  // 可能返回短链接或download路由
  static gotoDownloadPage() {
    const params = { invite_code: getInviteCode() };
    GetDownloadPage(params).then((res) => {
      if (res.code == 0) {
        const { download_page, referral_url } = res.result;
        // 存在referral_url，使用深度链接，不存在使用download_page
        if (referral_url) {
          openUrl(referral_url);
        } else {
          openUrl(config.downloadPage + download_page);
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  }

  static getDownloadLink() {
    const params = { invite_code: getInviteCode() };
    return GetDownloadLink(params);
  }

  //跳转apk地址
  static gotoDownloadLink() {
    this.getDownloadLink().then((res) => {
      if (res.code == 0) {
        const { ios_download_link } = res.result;
        if (String(ios_download_link).startsWith('https')) {
          openUrl(ios_download_link);
        } else {
          openUrl(config.downloadUrl + ios_download_link);
        }
      }
    });
  }

  //获取文件大小
  static getDownloadAppSize() {
    const params = { invite_code: getInviteCode() };
    return new Promise((resolve) => {
      GetDownloadLink(params).then((res) => {
        if (res.code == 0) {
          const { android_download_link } = res.result;
          const fullPath = config.downloadUrl + android_download_link;
          axios
            .head(fullPath)
            .then((response) => {
              const contentLength = response.headers['content-length'];
              resolve(bytesToMegabytes(contentLength));
            })
            .catch((error) => {
              resolve(bytesToMegabytes(0));
            });
        }
      });
    });
  }

  static gotoSafariTip() {
    const t = i18n.t;
    store.dispatch(
      setDialogModalVisible({
        zIndex: 10002,
        show: DialogModalChildrenKey.Confirm,
        content: t('home.safaritips'),
        confirmCb: () => {
          store.dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
        },
      }),
    );
  }

  static gotoHomePage(navigateFn) {
    const t = i18n.t;
    store.dispatch(
      setDialogModalVisible({
        zIndex: 10002,
        show: DialogModalChildrenKey.Confirm,
        content: t('home.tohomepage'),
        confirmCb: () => {
          store.dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
          navigateFn(toLocalePath('/'));
        },
      }),
    );
  }

  static getVestBagVisible(isPermitApk = false) {
    if (window.android || window.Android) {
      //是马甲包
      if (isAndroidAPK()) {
        //官方包直接不显示
        return isPermitApk;
      } else {
        const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig.website_info');
        return websiteInitConfig.download.switch_unofficial_apk_download_show == '1';
      }
    } else if (this.isIOSNative) {
      // ios原生包直接不显示
      return false;
    } else {
      //不是马甲包
      return this.showAndroid || this.showIOS || isPCLayout();
    }
  }

  static isSatisfyBottomDownloadBoxPrompt(checkBottomSwitch = false) {
    const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig.website_info');

    const isDownloadTopTipEnabled =
      websiteInitConfig &&
      websiteInitConfig.download &&
      websiteInitConfig.download.top &&
      websiteInitConfig.download.top.tip &&
      websiteInitConfig.download.top.tip.bottom_title;

    if (isAndroidAPK()) {
      return Boolean(
        isDownloadTopTipEnabled &&
          websiteInitConfig?.download.apk_jump_pwa?.switch == 1 &&
          this.getVestBagVisible(true) &&
          !isPWA(),
      );
    } else {
      // checkBottomSwitch 为true 需要判断 app底部下载开关
      if (checkBottomSwitch) {
        return Boolean(
          isDownloadTopTipEnabled &&
            websiteInitConfig?.download?.bottom?.switch == 1 &&
            this.getVestBagVisible(false) &&
            !isPWA(),
        );
      } else {
        return Boolean(isDownloadTopTipEnabled && this.getVestBagVisible(false) && !isPWA());
      }
    }
  }

  static onApkButtonClick = (userInfo = null) => {
    const identity = getIdentity();
    const state = store.getState();
    const data = get(state, 'app.websiteInitConfig');
    const appInfo = get(state, 'app.androidApkInfo');
    const downloadLadingPageLink = get(state, 'app.downloadLadingPageLink');
    const isVersion8OrHigher = appInfo?.app_versionCode >= 8;

    const openUrlInChromeOrDefault = (url) => {
      if (isPwaChannelNative() || isVersion8OrHigher) {
        window.native.openChrome(url);
      } else {
        openUrl(url);
      }
    };

    if (data?.website_info?.download?.apk_jump_pwa?.switch === 1) {
      const fromApk = isAndroidChannelNative() ? 'Channelapk' : isAndroidNative() ? 'apk' : 'vestTag';

      const buildUrl = (baseUrl, queryParams) => {
        return `${baseUrl}${queryParams ? `?${queryParams}` : ''}`;
      };

      const queryParams = identity ? `identity=${identity}&fromApk=${fromApk}` : `fromApk=${fromApk}`;
      const pwaQueryParams = identity
        ? `identity=${identity}${userInfo ? `&uid=${userInfo.uid}&amount=${userInfo.amount}` : ''}&fromApk=${fromApk}`
        : `${userInfo ? `?uid=${userInfo.uid}&amount=${userInfo.amount}` : ''}&fromApk=${fromApk}`;

      const url =
        data.website_info.download.apk_jump_pwa.jump_method == 1 && downloadLadingPageLink
          ? buildUrl(downloadLadingPageLink, queryParams)
          : buildUrl(`${window.location.origin}/installPwa`, pwaQueryParams);

      const normalizedUrl = url.replace(/^([a-z]+:\/\/)m\./i, '$1');

      openUrlInChromeOrDefault(normalizedUrl);
    }
  };

  static async handleInstallAction(isFastDownload = true, userInfo = null, downLoadApk = true, navigateFn) {
    // 在apk内跳转pwa地址
    if (isAndroidAPK()) {
      return this.onApkButtonClick(userInfo);
    }

    if (getMobileOperatingSystem() == 'iOS' && this.showIOS) {
      if (!isMobileSafari()) {
        return this.gotoSafariTip();
      } else {
        return store.dispatch(
          setDialogModalVisible({
            zIndex: 999999,
            show: DialogModalChildrenKey.IosSafariGuide,
          }),
        );
      }
    } else if (getMobileOperatingSystem() == 'Android' && this.showAndroid) {
      const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
      if (websiteInitConfig.website_info.download.pwa.switch == '0') {
        if (downLoadApk) {
          return this.gotoDownloadPage();
        } else {
          return this.gotoHomePage(navigateFn);
        }
      }

      // 检测是否支持pwa
      let showAddPwa = null;
      if (!isInIframe()) {
        try {
          const webappList = await navigator.getInstalledRelatedApps();
          if (webappList && webappList.length > 0) {
            showAddPwa = true;
          } else {
            showAddPwa = window.deferredPrompt ? true : false;
          }
        } catch (error) {
          showAddPwa = window.deferredPrompt ? true : false;
          console.error('Error getting installed related apps:', error);
        }
      }

      // 不支持pwa 下载apk
      if (showAddPwa && navigator.serviceWorker != null) {
        this.installCheck(isFastDownload);
      } else {
        if (downLoadApk) {
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_AppInstallButton);
          this.gotoDownloadPage();
        } else {
          return this.gotoHomePage(navigateFn);
        }
      }
    } else {
      if (DownloadManager.isNotMobile) {
        return store.dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.PwaguideQRcodeModel,
            zIndex: 999999,
          }),
        );
      }
    }
  }
}

export default DownloadManager;
