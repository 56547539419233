import cn from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import ViewAll from './viewAll';
import ViewAllText from './viewAllText';
import { useTranslation } from 'react-i18next';

const HomeModuleHeader = ({
  img = '',
  title = '',
  isViewAll = false,
  handleViewAll = () => {},
  className = '',
  homeModuleHeaderClassName = '',
  homeModuleHeaderIconClassName = '',
  homeModuleHeaderTextClassName = '',
  boxclassName = '',
  viewAllWithText = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={cn(
          `flex flex-row items-center justify-between relative mb-[.16rem] ${className} ${boxclassName}`,
          {},
        )}
      >
        <div className={`flex flex-row items-center h-[.48rem] ${homeModuleHeaderClassName}`}>
          <div
            className={cn(
              `w-[.4rem] h-[.4rem] mr-[.16rem] flex items-center justify-center ${homeModuleHeaderIconClassName}`,
            )}
          >
            <img className="not-drag" src={img} />
          </div>
          <div className={cn(`text-[.28rem] text-[var(--colour-48)] font-[600] ${homeModuleHeaderTextClassName}`, {})}>
            {title}
          </div>
        </div>
        {isViewAll && (
          <div className="cursor-pointer">
            {viewAllWithText ? <ViewAllText onClick={handleViewAll} /> : <ViewAll onClick={handleViewAll} />}
          </div>
        )}
      </div>
    </>
  );
};

export default HomeModuleHeader;
