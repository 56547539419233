import { useEffect, useState } from 'react';
import Entrance from './entrance';
import Main from './main';
import { ActivityAllReq, PromotionActivitySettle } from '../../../../../api';
import { get } from 'lodash';
import NoData from '../../../../noData';
import Loading2 from '../../../../loading2';
// import { setActivityItemData } from '../../../../../redux/reducers/appSlice';
import { useDispatch } from 'react-redux';
import { isLogin } from '../../../../../utils/helper';
import PopBg2 from '../../../components/popBg2';

export default function Index({
  handleClosePagingButton = () => {},
  showContent: _showContent = 'guide',
  handleClose = () => {},
  data = null,
  isShowCloseBtn = true,
  updateList = () => {},
  isSinglePopup = true,
  handleSetIsFullPage = () => {},
  isLoading = false,
  showStyle,
  handleSetData,
}) {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(_showContent);
  // const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState(_data);

  // const requestActivitySettle = (id) => {
  //   if (id && isLogin()) {
  //     PromotionActivitySettle({
  //       activity_id: id,
  //     });
  //   }
  // };

  // const updateData = () => {
  //   const params = {
  //     data_type: JSON.stringify(['activity_promotional_achievements']),
  //   };

  //   setIsLoading(true);

  //   ActivityAllReq(params).then((res) => {
  //     setIsLoading(false);

  //     if (res.code == 0) {
  //       const activity_promotional_achievements = get(res, 'result.activity_promotional_achievements');
  //       //dispatch(setActivityItemData({ activity_promotional_achievements: activity_promotional_achievements }));
  //       const _data = get(res, 'result.activity_promotional_achievements.list[0]');
  //       if (_data) {
  //         setData(_data || null);
  //         requestActivitySettle(_data?.id);
  //       }
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (!_data) {
  //     updateData();
  //   } else {
  //     requestActivitySettle(_data?.id);
  //   }
  // }, []);

  const showMain = () => {
    setShowContent('main');
    handleClosePagingButton();
    handleSetIsFullPage(true);
  };

  const updateDataAfterClaim = (data) => {
    handleSetData(data);
  };

  const onExpired = () => {
    if (isSinglePopup) {
      handleClose();
    } else {
      updateList();
    }
  };

  // console.log('showContent', showContent);

  return isLoading ? (
    <PopBg2 handleClose={handleClose}>
      <div className="h-full flex items-center justify-center">
        <Loading2 />
      </div>
    </PopBg2>
  ) : data ? (
    showContent == 'guide' ? (
      <Entrance
        data={data}
        handleClose={handleClose}
        isShowCloseBtn={isShowCloseBtn}
        showMain={showMain}
        updateDataAfterClaim={updateDataAfterClaim}
        onExpired={onExpired}
        updateList={updateList}
        isSinglePopup={isSinglePopup}
      />
    ) : showContent === 'main' ? (
      <Main
        data={data}
        handleClose={handleClose}
        isShowCloseBtn={isShowCloseBtn}
        onExpired={onExpired}
        updateList={updateList}
        isSinglePopup={isSinglePopup}
        showStyle={showStyle}
      />
    ) : (
      <PopBg2 handleClose={handleClose}>
        <div className="h-full flex items-center justify-center">
          <NoData showTextLangkey="promotion.eventend" />
        </div>
      </PopBg2>
    )
  ) : (
    <PopBg2 handleClose={handleClose}>
      <div className="h-full flex items-center justify-center">
        <NoData showTextLangkey="promotion.eventend" />
      </div>
    </PopBg2>
  );
}
