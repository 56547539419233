import cn from 'classnames';
import { getPercentage, getShowMoney } from '../../../../../../utils/helper';
import { Fragment } from 'react';

const BonusLine = ({ isShowBg = false, list = [] }) => {
  return (
    <div
      className={cn('h-[1.16rem] flex', {
        'bg-[var(--colour-22)]': isShowBg,
      })}
    >
      {list.map((item, index) => (
        <div
          key={index}
          style={{
            width: `calc(100% / ${list.length})`,
          }}
          className="flex items-center justify-center"
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export const ListTitle = ({ list = [] }) => {
  return (
    <div className="h-[1.1rem] flex bg-[var(--colour-51)] rounded-t-[1px]">
      {list.map((item, index) => (
        <div
          key={index}
          style={{
            width: `calc(100% / ${list.length})`,
          }}
          className="flex items-center justify-center text-center leading-[1]"
        >
          <span className="font-[500] text-[.24rem] text-[var(--colour-14)]">{item}</span>
        </div>
      ))}
    </div>
  );
};

export const LevelBonus = ({ dataList = [] }) => {
  return (
    <div>
      {dataList.map((item, index) => (
        <Fragment key={index}>
          <BonusLine list={item} isShowBg={index % 2 === 0} />
        </Fragment>
      ))}
    </div>
  );
};

export const MonthlyBonus = ({ dataList = [] }) => {
  return (
    <div>
      {dataList.map((item, index) => (
        <Fragment key={index}>
          <BonusLine list={item} isShowBg={index % 2 === 0} />
        </Fragment>
      ))}
    </div>
  );
};

export const WeeklyBonus = ({ dataList = [] }) => {
  return (
    <div>
      {dataList.map((item, index) => (
        <Fragment key={index}>
          <BonusLine list={item} isShowBg={index % 2 === 0} />
        </Fragment>
      ))}
    </div>
  );
};

export const UserProgress = ({ distanceAmount, levelAmount }) => {
  // distanceAmount 用户升级需要的差值: 配置数 - 用户数, <=0 表示已满足 , > 0 表示还差多少
  console.log('UserProgress', distanceAmount, levelAmount);

  const showedUserAmount = distanceAmount > 0 ? levelAmount - distanceAmount : levelAmount;

  return (
    <div className="flex flex-col items-center space-y-[.06rem] text-[.2rem] font-[500] leading-[1]">
      <div className="text-[var(--colour-44)]">{getShowMoney(showedUserAmount)}</div>

      <div className="w-[1.6rem] h-[.16rem] p-[.02rem] bg-[var(--colour-93)] rounded-full">
        <div
          className="h-[.12rem] colour-91 rounded-full"
          style={{
            width: `${getPercentage(showedUserAmount, levelAmount)}%`,
          }}
        ></div>
      </div>

      <div
        className={cn('', {
          'text-[var(--colour-14)]': Number(distanceAmount) > 0,
          'text-[var(--colour-44)]': Number(distanceAmount) <= 0,
        })}
      >{`/${getShowMoney(levelAmount)}`}</div>
    </div>
  );
};

export const LevelAmount = ({ levelAmount }) => {
  return (
    <>
      <span className="text-[var(--colour-14)] text-[.2rem] font-[500]">{getShowMoney(levelAmount)}</span>
    </>
  );
};

export const NeedMoneyAmount = ({ distanceAmount, levelAmount, isShowProgress = false }) => {
  return isShowProgress ? (
    <>
      <UserProgress distanceAmount={distanceAmount} levelAmount={levelAmount} />
    </>
  ) : (
    <>
      <LevelAmount levelAmount={levelAmount} />
    </>
  );
};
