import { useEffect, useState } from 'react';
import cn from 'classnames';
import Icon, { IconName } from '../../../../../components/icon';
import Input from '../../../../../components/input';
import DatetimePickerBox from '../../../../../components/datetimePicker';
import DrawerStatistics from '../../../../../components/drawerStatistics';
// import BetDetails from './components/betDetails';
import { GetAllDataInfo } from '../../../../../api';
import Loading3 from '../../../../../components/loading3';
import NoData from '../../../../../components/noData';
import { getShowMoney, isLogin, getConfigTimeZoneTimestamp } from '../../../../../utils/helper';
import moment from 'moment';
import { List } from 'react-vant';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import ScrollbarContent from '../../../../../components/scrollbarContent';

const BetsItem = ({ showBg = false, item }) => {
  const { t } = useTranslation();

  const handleCopy = (val) => {
    copy(val);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  return (
    <div
      className={cn(
        'rounded-normal p-[.18rem_.28rem_.24rem_.66rem] text-[.2rem] font-[500] leading-[.24rem] relative',
        {
          'bg-[var(--colour-69)] shadow-[0_.04rem_.04rem_0_rgba(0,0,0,0.02)]': showBg,
        },
      )}
    >
      <div className="flex items-center mb-[.08rem]">
        <div
          className="flex items-center w-[3rem] shrink-0 mr-[.2rem]"
          onClick={() => {
            handleCopy(item?.uid);
          }}
        >
          <div className="text-[var(--colour-38)] mr-[.2rem]">{item?.uid}</div>
          <div className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]">
            <Icon name={IconName.Copy4} />
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('referral.alldata_list_1')}</div>
          <div className="text-[var(--colour-38)]">{getShowMoney(item?.wager_amount || 0, 2, 2)}</div>
        </div>
      </div>
      <div className="flex items-center mb-[.1rem]">
        <div className="flex items-center w-[3rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-44)] mr-[.12rem]">
            {getConfigTimeZoneTimestamp(item?.register_time * 1000).format('DD/MM/YYYY HH:mm:ss')}
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('referral.alldata_list_2')}</div>
          <div className="text-[var(--colour-38)]">{getShowMoney(item?.deposit_amount || 0, 2, 2)}</div>
        </div>
      </div>
      <div className="absolute top-[0rem] left-[0rem] bg-[var(--colour-25)] rounded-[.04rem_0] text-[.16rem] font-[600] leading-[.2rem] text-[var(--colour-2)] px-[.08rem]">
        V{item?.vip_level}
      </div>
    </div>
  );
};

const NTemplate001 = () => {
  const { t } = useTranslation();

  const [list, setList] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [detailsVisible, setDetailsVisible] = useState(false); //详情弹窗
  const [isShowloading, setisShowLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    page_size: 10,
    start_date: new Date(),
    end_date: new Date(),
    uid: '',
  });

  const handleStartChange = (val) => {
    setParams({ ...params, start_date: val });
  };

  const handleEndChange = (val) => {
    setParams({ ...params, end_date: val });
  };

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  }

  const getAlldataList = async () => {
    if (!isLogin()) return;
    setisShowLoading(true);
    try {
      const newParams = {
        ...params,
        page: 1,
        start_date: formatDateTime(params.start_date),
        end_date: formatDateTime(params.end_date),
      };

      const res = await GetAllDataInfo(newParams);
      if (res.code == 0) {
        setList(res.data.list);
        setTotalData(res.data.summary);

        if (res.data.list.length < params.page_size) {
          setFinished(true);
        } else {
          setParams({ ...params, page: 1 });
          setFinished(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisShowLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin()) {
      getAlldataList();
    }
  }, []);

  const onLoad = async () => {
    try {
      setParams({ ...params, page: params.page + 1 });
      const currentPage = params.page + 1;
      const newParams = {
        ...params,
        page: currentPage,
        start_date: formatDateTime(params.start_date),
        end_date: formatDateTime(params.end_date),
      };

      const res = await GetAllDataInfo(newParams);
      if (res.code == 0) {
        setTotalData(res.data.summary);
        if (res.data && res.data.list.length > 0) {
          setList([...list, ...res.data.list]);
        }

        setFinished(res.data.list.length < params.page_size);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    // <ScrollbarContent className="bg-[var(--colour-68)] h-full">
    <div className="p-[.2rem_.08rem_.7rem_.08rem] h-full flex flex-col bg-[var(--colour-68)]">
      <div className="flex items-center mb-[.2rem]">
        <DatetimePickerBox
          startDate={params.start_date}
          endDate={params.end_date}
          rangeCheckClassName="!w-[3.6rem] bg-[var(--colour-5)] mr-[.2rem]"
          onChangeStartDate={handleStartChange}
          onChangeEndDate={handleEndChange}
          onSubmit={getAlldataList}
        />

        <div className="flex-1">
          <Input
            value={params.uid}
            onChange={(e) => {
              setParams({ ...params, uid: e.target.value });
            }}
            type="text"
            placeholder={t('referral.myid')}
            inputClassName="m-input"
            innerClassName="!h-[.46rem] !border-transparent !pl-[.22rem] !pr-[.24rem]"
            topClassName={`rounded-[19.98rem] overflow-hidden bg-[var(--colour-5)]`}
            rightInner={
              <div className="w-[.24rem] h-[.24rem] text-[var(--colour-13)]" onClick={getAlldataList}>
                <Icon name={IconName.TagsSearch} />
              </div>
            }
            clearIconClassName="!w-[.24rem] !h-[.24rem]"
          />
        </div>
      </div>
      <div className="overflow-y-auto flexAuto">
        {isShowloading ? (
          <Loading3 className1="w-full h-full"></Loading3>
        ) : (
          <>
            {list && list.length > 0 ? (
              <List finished={finished} onLoad={onLoad} offset={0} loadingText={<Loading3 className1="w-full" />}>
                {list.map((item, index) => {
                  return (
                    <div key={index}>
                      <BetsItem showBg={index % 2 != 0} item={item} />
                    </div>
                  );
                })}
              </List>
            ) : (
              <NoData className="w-full h-full"></NoData>
            )}
          </>
        )}
      </div>

      {/* <BetDetails 
          setShowVerify={setDetailsVisible} 
          showVerify={detailsVisible} 
        /> */}

      {list && list.length > 0 ? (
        <DrawerStatistics>
          <div className="p-[.14rem_.74rem] text-[.2rem] font-[500] leading-[.24rem]">
            <div className="flex items-center mb-[.18rem]">
              <div className="flex items-center w-[3.4rem] shrink-0">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('referral.alldata_bottom_1')}
                </div>
                <div className="text-[var(--colour-38)]">
                  {getShowMoney(totalData?.direct_deposit_amount || 0, 2, 2)}
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('referral.alldata_bottom_2')}
                </div>
                <div className="text-[var(--colour-38)]">
                  {totalData ? totalData.direct_first_deposit_member_num : 0}
                </div>
              </div>
            </div>
            <div className="flex items-center mb-[.14rem]">
              <div className="flex items-center w-[3.4rem] shrink-0">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('referral.alldata_bottom_3')}
                </div>
                <div className="text-[var(--colour-38)]">
                  {getShowMoney(totalData?.indirect_deposit_amount || 0, 2, 2)}
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('referral.alldata_bottom_4')}
                </div>
                <div className="text-[var(--colour-38)]">
                  {totalData ? totalData.indirect_first_deposit_member_num : 0}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center w-[3.4rem] shrink-0">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('referral.alldata_bottom_5')}
                </div>
                <div className="text-[var(--colour-38)]">
                  {getShowMoney(totalData?.total_deposit_amount || 0, 2, 2)}
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('referral.alldata_bottom_6')}
                </div>
                <div className="text-[var(--colour-38)]">
                  {totalData ? totalData.total_first_deposit_member_num : 0}
                </div>
              </div>
            </div>
          </div>
        </DrawerStatistics>
      ) : null}
    </div>
    // </ScrollbarContent>
  );
};

export default NTemplate001;
