import Icon, { IconName } from '../../../../components/icon';
import MenuText from './menuText';
import MenuImg from './menuImg';
import useLangServerConfig from '../../../../hooks/useLangServerConfig';
import { getTemplateId } from '../../../../utils/helper';

export default function Index({
  isChoose = false,
  text = 'Home',
  clickHome = () => {},
  data = {},
  ismagnifyImg = false,
}) {
  const handleClick = () => {
    clickHome();
  };
  const { getServerLangText } = useLangServerConfig();
  return (
    <div
      className={`flex flex-col flex-1 items-center h-full relative ${ismagnifyImg ? 'justify-between pb-[.06rem]' : 'justify-center'}`}
    >
      <span
        className={`block ${ismagnifyImg ? 'w-[.88rem] h-[.88rem] mt-[-0.23rem]' : `${getTemplateId() == 'd-template003' ? 'w-[.56rem] h-[.56rem]' : 'w-[.48rem] h-[.48rem]'}`}`}
        onClick={handleClick}
      >
        {data?.img_source == '1' ? (
          <MenuImg src={isChoose ? data.img_checked : data.img_unchecked} />
        ) : (
          <Icon name={isChoose ? IconName.MenuBarHomeChoose : IconName.MenuBarHomeDefault} />
        )}
      </span>
      <MenuText onClick={handleClick} isChoose={isChoose} ismagnifyImg={ismagnifyImg}>
        {getServerLangText(data?.lang_key)}
      </MenuText>
    </div>
  );
}
