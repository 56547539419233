import PopBg from '../../../../components/popBg';
import ExpirationTime from '../../../../components/expirationTime';
import { useTranslation, Trans } from 'react-i18next';
import Icon, { IconName } from '../../../../../icon';
import DialogModal from '../../../..';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setPromotionPendingUpdateTimestamp,
  setRefetchActivityData,
} from '../../../../../../redux/reducers/appSlice';
import ClaimBtn from '../com/claimBtn';
import RewardRecord from './rewardRecord';
import { getShowMoney, isLightTheme } from '../../../../../../utils/helper';
import { toast } from 'react-toastify';
import { ActivityPromotionalCashbackClaim } from '../../../../../../api';
import AnalyticsManager, { FirebaseEvents } from '../../../../../../manager/analyticsManager';
import Rule from '../../../../../rule';
import BgPopHigher from '../../../../../bgPopHigher';
import DownloadManager from '../../../../../../manager/downloadManager';
import BottomDownload from '../../../../downLoadBtn';
import FullPagePopup from '../../../../../fullpagePopup';
import ScrollWrapper from '../../../../../scrollWrapper';
import DrawerPopup from '../../../../../drawerPopup';
import Invite from '../../../../../invite/index';

const TotalReward = ({ title, money }) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  return (
    <div
      className={cn(
        'w-[6.14rem] h-[1.9rem] bg-[length:100%_100%] bg-no-repeat relative bg-[url(/static/img/cashback/reward-mobile.png)]',
      )}
    >
      <div className="w-full flex justify-center text-center absolute top-[0] text-[.28rem] font-[600] text-[var(--colour-48)]">
        {title}
      </div>
      <div className="w-full flex justify-center text-center absolute top-[.74rem]">{money}</div>
    </div>
  );
};

const InfoItem = ({ name, valueName, value, index }) => {
  return (
    <div
      className={cn('flex items-center justify-between h-[.96rem] rounded-normal px-[.2rem] font-[500] text-[.24rem]', {
        'bg-[var(--colour-34)]': index % 2 == 0,
        'bg-transparent': index % 2 == 1,
      })}
    >
      <div className="text-[var(--colour-44)]">{name}</div>
      <div className="">
        <div className="text-center whitespace-nowrap text-[var(--colour-44)]">{valueName}</div>
        <div className="text-[var(--colour-26)] text-center font-[600]">{value}</div>
      </div>
    </div>
  );
};

const InfoList = ({ list = [] }) => {
  return (
    <div>
      {list.map((item, index) => {
        return <InfoItem key={index} name={item.name} valueName={item.valueName} value={item.value} index={index} />;
      })}
    </div>
  );
};

const InviteBtn = ({ onClick = () => {}, name }) => {
  return (
    <div
      className="cursor-pointer text-[var(--colour-2)] flex items-center justify-center bg-[var(--colour-1)] w-[4.96rem] h-[.68rem] rounded-normal space-x-[.16rem] text-[.28rem] font-[500]"
      onClick={onClick}
    >
      <span>{name}</span>
      <span className="w-[.36rem] h-[.36rem]">
        <Icon name={IconName.Share} />
      </span>
    </div>
  );
};

const MainContent = ({ data, handleClose = () => {}, isShowCloseBtn, updateDataAfterClaim = () => {}, onExpired }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currency_symbol, defaultLang } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });
  const [showRule, setShowRule] = useState(false);
  const [isShowInvite, setIsShowInvite] = useState(false);
  const [isShowRecord, setIsShowRecord] = useState(false);
  const [infoList, setInfoList] = useState([]);

  useEffect(() => {
    const nameList = {
      1: {
        name: t('referral.new_player_recharge_rewards'),
        valueName: t('referral.amount_available'),
      },
      2: {
        name: t('referral.Valid_betting_rewards'),
        valueName: t('referral.amount_available'),
      },
      3: {
        name: t('referral.directly_affiliated'),
        valueName: t('referral.amount_available'),
      },
    };

    if (data?.task_config && Array.isArray(data?.task_config)) {
      const list = data.task_config.map((item) => {
        return {
          name: nameList[item?.task_type]?.name,
          valueName: nameList[item?.task_type]?.valueName,
          value: getShowMoney(item?.claim_reward || 0),
        };
      });
      setInfoList(list);
    }
  }, [data, defaultLang]);

  const totalReward = (() => {
    const list = data?.task_config || [];
    const sumAmount = list.reduce((acc, curr) => {
      return acc + Number(curr.claim_reward || 0);
    }, 0);

    return sumAmount;
  })();

  const onClaim = () => {
    if (Number(totalReward) > 0) {
      ActivityPromotionalCashbackClaim({
        activity_id: data?.id,
      }).then((res) => {
        if (res.code == 0) {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.ClaimBonusResultTip,
              content: (
                <span className="text-[var(--colour-18)] text-[.28rem] font-[600]">
                  <Trans
                    t={t}
                    i18nKey={'promo.claim_bonus_result'}
                    components={[<span className="text-[var(--colour-25)] text-[.4rem]" key={0}></span>]}
                    values={{
                      val: `${currency_symbol}${getShowMoney(res?.result?.amount || 0)}`,
                    }}
                  />
                </span>
              ),
            }),
          );

          updateDataAfterClaim(res?.result?.activity_info || null);
          dispatch(setRefetchActivityData());
          dispatch(setNeedUpdateBalance({ need: true }));
          dispatch(setPromotionPendingUpdateTimestamp());
        } else {
          if (res.code == 1008127) {
            // dispatch(
            //   setBottomDownloadBoxPromptSwitch({
            //     visible: true,
            //     isAni: true,
            //     isForce: true,
            //     downloadText: t('guideWheel.downloadtips'),
            //   }),
            // );

            if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
              setBottomDownloadBoxPromptSwitch({
                visible: true,
                isForce: true,
                downloadText: t('guideWheel.downloadtips'),
              });
            }
          } else {
            toast.error(t(`${res.code}`), {
              containerId: 'global',
            });
          }
        }
      });
    } else {
      setShowRule(true);
    }
  };

  const onShowRecord = () => {
    setIsShowRecord(true);
  };

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <BgPopHigher zIndex={9999} isOpen={showRule} handleClose={() => setShowRule(false)} className={'h-[11.5rem]'}>
        <Rule
          data={data}
          handleShowInviteFriends={() => {
            setShowRule(false);
            setIsShowInvite(true);
          }}
        />
      </BgPopHigher>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      {/* <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: '51',
          },
        }}
        isOpen={isShowInvite}
        handleClose={() => setIsShowInvite(false)}
      >
        <InviteFriend handleClose={() => setIsShowInvite(false)} isShowCloseBtn={false} />
      </DialogModal> */}

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: '51',
          },
        }}
        isOpen={isShowRecord}
        handleClose={() => setIsShowRecord(false)}
      >
        <RewardRecord handleClose={() => setIsShowRecord(false)} activity_id={data?.id} isShowCloseBtn={false} />
      </DialogModal>

      <FullPagePopup isOpen={true}>
        <DrawerPopup
          handleClose={() => {
            setIsShowInvite(false);
          }}
          isOpen={isShowInvite}
          overlayClassName="w-[100%]"
        >
          <Invite
            handleClose={() => {
              setIsShowInvite(false);
            }}
            showType={2}
          />
        </DrawerPopup>
        <div className="h-full bg-[var(--colour-54)] flex flex-col">
          <div className="h-[.96rem] bg-[var(--colour-86)] flex items-center justify-between">
            <div className="h-full w-[1.12rem] flex items-center justify-center cursor-pointer" onClick={handleClose}>
              <div className="rotate-180 w-[.24rem] h-[.24rem] text-[var(--colour-11)]">
                <Icon name={IconName.BannerNext2} />
              </div>
            </div>
            <div className="text-[.36rem] font-[500] leading-[.44rem] text-[var(--colour-48)]">
              {t('referral.cashback')}
            </div>

            <div className="h-full w-[1rem]"></div>
          </div>
          <div className="flex-1">
            <ScrollWrapper
              noRelative={true}
              mr={false}
              className="h-[calc(var(--app-height)-2.6rem)]"
              // isShowOnBottom={isScrollToBottom}
            >
              <div className="relative mb-[.6rem] w-[7.5rem] h-[8.42rem] bg-[url(/static/img/referralcashback_main_bg.png)] bg-no-repeat bg-[length:100%_100%]">
                <div
                  className="absolute w-[1.48rem] h-[.96rem] p-[.08rem] bottom-[-.4rem] left-[50%] translate-x-[-50%] bg-[var(--colour-54)] rounded-[.46rem]"
                  onClick={() => {
                    setIsShowInvite(true);
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBack_Invite);
                  }}
                >
                  <div className="w-[1.32rem] h-[.8rem] colour-80 rounded-[19.98rem] flex items-center justify-center">
                    <div className="w-[.48rem] h-[.44rem] text-[var(--colour-2)]">
                      <Icon name={IconName.ReferralCashbackInvite} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[.32rem] font-[600] leading-[.38rem] text-[var(--colour-24)] text-center mb-[.38rem] px-[.2rem]">
                {t('referral.cashback_tip')}
              </div>

              <div className="mb-[.32rem]">
                <div
                  className="h-[1px]"
                  style={
                    isLightTheme()
                      ? {
                          background:
                            'linear-gradient(270deg, rgba(22, 120, 255, 0.00) 0%, #1678FF 50%, rgba(22, 120, 255, 0.00) 100%)',
                        }
                      : {
                          background:
                            'linear-gradient(270deg, rgba(121, 120, 135, 0.00) 0%, #797887 50%, rgba(121, 120, 135, 0.00) 100%)',
                        }
                  }
                ></div>
                <div
                  className="min-h-[1.38rem] py-[.16rem]"
                  style={
                    isLightTheme()
                      ? {
                          background:
                            'linear-gradient(270deg, rgba(118, 152, 255, 0.00) 0%, #7698FF 50%, rgba(118, 152, 255, 0.00) 100%)',
                        }
                      : {
                          background:
                            'linear-gradient(270deg, rgba(24, 35, 68, 0.00) 0%, #182344 50%, rgba(24, 35, 68, 0.00) 100%)',
                        }
                  }
                >
                  <div className="text-[.2rem] font-[500] leading-[.24rem] text-[var(--colour-38)] text-center mb-[.12rem]">
                    {t('referral.reward_value')}
                  </div>
                  <div className="text-[.44rem] font-[700] leading-[.54rem] text-[var(--colour-58)] text-center">
                    {currency_symbol}
                    <span className="">{getShowMoney(totalReward)}</span>
                  </div>
                </div>
                <div
                  className="h-[1px]"
                  style={
                    isLightTheme()
                      ? {
                          background:
                            'linear-gradient(270deg, rgba(22, 120, 255, 0.00) 0%, #1678FF 50%, rgba(22, 120, 255, 0.00) 100%)',
                        }
                      : {
                          background:
                            'linear-gradient(270deg, rgba(121, 120, 135, 0.00) 0%, #797887 50%, rgba(121, 120, 135, 0.00) 100%)',
                        }
                  }
                ></div>
              </div>
              <div className="px-[.2rem]">
                <div
                  dangerouslySetInnerHTML={{ __html: data?.rule_explain?.activity_rule_explain }}
                  className="text-[.2rem] font-[500] leading-[.36rem] text-[var(--colour-38)] wrap-break-word"
                ></div>
              </div>
            </ScrollWrapper>
          </div>
          <div className="h-[1.52rem] bg-[var(--colour-4)] flex justify-center pt-[.38rem]">
            <div
              className="w-[7.04rem] h-[.94rem] colour-79 rounded-[.08rem] flex items-center justify-center text-[.24rem] font-[600] text-[var(--colour-2)]"
              onClick={() => {
                onClaim();
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBack_Howtoget);
              }}
            >
              {Number(totalReward) > 0 ? t('referral.claim') : t('referral.how_to_get')}
            </div>
          </div>
        </div>
      </FullPagePopup>
    </>
  );
};

export default MainContent;
