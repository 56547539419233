import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DialogModal from '../dialogModal/index';
import Icon, { IconName } from '../icon';
import Button from '../button';
import SvgIcon from '../svgIcon';
import wenIcon from '../../assets/messageIco/Vector.svg';
import ScrollWrapper from '../scrollWrapper';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setNeedUpdateBalance,
  setWelcomeBonusData,
  setRefetchActivityData,
  setPromotionPendingUpdateTimestamp,
  setIsShowPromotionBarGiftMoneyCoin,
} from '../../redux/reducers/appSlice';
import { getShowMoney, isControllableSite, isLogin, isPromotionPath, openHeaderGiftMoney } from '../../utils/helper';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import Close from '../close';
import CoinsImg from '../../assets/img/welcome-bonus/coins.png';
import DoneImg from '../../assets/img/welcome-bonus/done.png';
import cn from 'classnames';
import BonusCountdown from './pageCountDown';
import { ClaimActivityNoviceReq, ActivityAllReq, PromotionActivitySettle } from '../../api';
import BonusBox from '../dialogModal/bounsbox';
import IOSSafariGuide from '../iosSafariGuide';
import DownloadManager from '../../manager/downloadManager';
import useWelcomeBonus from '../../hooks/useWelcomeBonus';
import Close2 from '../close2';
import PopConfirm from '../popConfirm';
import Loading2 from '../loading2';
import NoData from '../noData';
import BigNumber from 'bignumber.js';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import LimitClickWrapper from '../limitClickWrapper';
import { useNavigate } from 'react-router-dom';
import { toLocalePath, toNoLocalePath } from '../../utils/localePath';
import useRouterHelper from '../../hooks/useRouterHelper';
import BottomDownload from '../dialogModal/downLoadBtn';
import { useGlobalNavigate } from '../../contexts/navigateContext';
import { useLocation } from 'react-router-dom';
import { getCenterPosition } from '../../hooks/useMinimizeAnimation';
import GSAPUtils from '../../utils/gsapUtils';

const getShowAmount = (data, currency_symbol) => {
  const { t } = useTranslation();
  if (data.task_status == 10) {
    switch (String(data.reward_type)) {
      case '3':
        return `${t('welcomeBonus.upTo', {
          val: `${currency_symbol} ${getShowMoney(data.amount)}`,
        })}`;
      case '4':
        return `${t('welcomeBonus.upTo', {
          val: `${BigNumber(Number(data.percent)).times(100).toFixed()}%`,
        })}`;
      default:
        return `${currency_symbol} ${getShowMoney(data.amount)}`;
    }
  } else {
    return `${currency_symbol} ${getShowMoney(data.amount)}`;
  }
};

const getGo = (data, handleShowPCTips) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const {} = useSelector((state) => ({}), shallowEqual);
  const { handleClickWallet } = useRouterHelper();

  const handleGo = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_ClaimBonus_GoButton);
    switch (String(data.type)) {
      case '1':
        break;
      case '2':
      case '3': {
        // if (isControllableSite()) {
        //   dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
        //   navigate(toLocalePath(`/security`));
        // } else {
        //   dispatch(
        //     setDialogModalVisible({
        //       zIndex: 10002,
        //       show: DialogModalChildrenKey.AccountSetting,
        //       showBindStyle: data.type == 3 ? 1 : 2,
        //     }),
        //   );
        // }

        if (data?.type == 2) {
          localStorage.setItem('data_show_popup', 'email');
        } else if (data?.type == 3) {
          localStorage.setItem('data_show_popup', 'phone');
        }
        navigate(toLocalePath('/myaccountdata'));
        dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
        break;
      }
      case '4':
        handleClickWallet(1, 1, 10002);

        break;
      case '5':
        if (DownloadManager.showUseMobileTips) {
          DownloadManager.androidDownload();
        } else {
          handleShowPCTips();
        }
        break;
    }
  };
  if (data.type == 5) {
    if (DownloadManager.showIOS) {
      return (
        <IOSSafariGuide className="text-[.24rem] flex items-center justify-center w-[1.52rem] h-[.64rem] bg-[var(--colour-5)] border border-solid border-[var(--colour-1)] text-[var(--colour-3)] rounded-normal font-[700]">
          {t('welcomeBonus.go')}
        </IOSSafariGuide>
      );
    } else {
      return (
        <Button
          onClick={handleGo}
          className="text-[.24rem] w-[1.52rem] h-[.64rem] bg-[var(--colour-5)] border border-solid border-[var(--colour-1)] text-[var(--colour-3)] rounded-normal font-[700]"
        >
          {t('welcomeBonus.go')}
        </Button>
      );
    }
  } else {
    return (
      <Button
        onClick={handleGo}
        className="text-[.24rem] w-[1.52rem] h-[.64rem] bg-[var(--colour-5)] border border-solid border-[var(--colour-1)] text-[var(--colour-3)] rounded-normal font-[700]"
      >
        {t('welcomeBonus.go')}
      </Button>
    );
  }
};

const DepositList = ({ handleClose, list, rewardType, maxAmount }) => {
  const { t } = useTranslation();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  return (
    <>
      <div
        className={`flex flex-col bg-[var(--colour-35)] rounded-normal w-[6.86rem] px-[.32rem] min-h-[6.68rem] pb-[.32rem]`}
      >
        <div className="w-full border-b border-[var(--colour-6)]">
          <div className="flex flew-row justify-between items-center py-[.2rem]">
            <div className="font-[700] text-[var(--colour-48)] text-[.32rem]">
              {t('welcomeBonus_activityRewards.deposit_more')}
            </div>
            <Close2 handleClose={handleClose} className2="text-[var(--colour-46)]" />
          </div>
        </div>

        <div className={`w-full flex items-center font-[500] text-[var(--colour-44)] mt-[.2rem]`}>
          <div className="text-center w-[3rem]">{t('welcomeBonus_activityRewards.deposit_more_1')}</div>
          <div className="flex-1 text-center">{t('welcomeBonus_activityRewards.deposit_more_2')}</div>
        </div>
        <ScrollWrapper className="h-[4.8rem] mt-[.1rem] text-[var(--colour-44)]">
          {list &&
            list.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center h-[.8rem] text-[.32rem] rounded-normal ${
                    index % 2 === 0 ? 'bg-[var(--colour-34)]' : ''
                  }`}
                >
                  <div className="text-center w-[3rem]">{getShowMoney(item.condition)}</div>
                  <div className="flex-1 text-center">
                    {rewardType == '4'
                      ? `${BigNumber(Number(item.value)).times(100).toFixed()}%`
                      : `${websiteInitConfig.website_info.currency_symbol || ''} ${getShowMoney(item.value)}`}
                  </div>
                </div>
              );
            })}
        </ScrollWrapper>
        {rewardType == '4' ? (
          <p className="text-center font-[700] text-[.32rem] mb-[.4rem] mt-[.2rem]">
            {t('welcomeBonus_activityRewards.deposit_more_3', { val: getShowMoney(maxAmount) })}
          </p>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const Item = ({ dataSource, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [data, setData] = useState(dataSource);
  const [depositListVisible, setDepositListVisible] = useState(false);
  const [pcTipsVisible, setPcTipsVisible] = useState(false);
  const [showClaimResult, setShowClaimResult] = useState({ show: false, showAmount: 0 });
  const { InitWelcomeBonus } = useWelcomeBonus();
  const { websiteInitConfig, defaultLang, activityData } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      activityData: state.app.activityData,
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  const handleClaim = (e) => {
    ClaimActivityNoviceReq({ activity_id: id, type: data.type }).then((res) => {
      if (res.code == 0) {
        const result = res.result;
        setData({ ...data, task_status: 22 });
        if (result?.currency == 'gift_money') {
          const element = e.target;
          const positionData = getCenterPosition(element, true);
          if (element) {
            if (isPromotionPath(toNoLocalePath(pathname)) && activityData?.gift_money) {
              const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
              if (giftMoneyLabel) {
                const toPoint = getCenterPosition(giftMoneyLabel, true);
                GSAPUtils.flyCoin(positionData, toPoint, GSAPUtils.COIN_COUNT, () => {
                  dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                });
              }
            } else {
              openHeaderGiftMoney(result?.amount, [positionData]);
            }
          }
        } else {
          setShowClaimResult({ show: true, showAmount: getShowMoney(result.amount) });
        }

        InitWelcomeBonus(false);
        dispatch(setNeedUpdateBalance({ need: true }));
        const _timer = window.setTimeout(() => {
          dispatch(setRefetchActivityData());
          window.clearTimeout(_timer);
        }, 1500);
        dispatch(setPromotionPendingUpdateTimestamp());
      } else if (res.code == 1008127) {
        if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
          setBottomDownloadBoxPromptSwitch({
            visible: true,
            isForce: true,
            downloadText: t('guideWheel.downloadtips'),
          });
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const handleShowPCTips = () => {
    setPcTipsVisible(true);
  };

  const handleShowAllDeposit = () => {
    setDepositListVisible(true);
  };

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowClaimResult({ ...showClaimResult, show: false });
        }}
        style={{
          overlay: {
            zIndex: 10002,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={showClaimResult.show}
      >
        <BonusBox
          children={{ showAmount: showClaimResult.showAmount }}
          handleClose={() => {
            setShowClaimResult({ ...showClaimResult, show: false });
          }}
        />
      </DialogModal>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 10002,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={depositListVisible}
        handleClose={() => {
          setDepositListVisible(false);
        }}
      >
        <DepositList
          handleClose={() => setDepositListVisible(false)}
          rewardType={data.reward_type}
          maxAmount={data.max_amount}
          list={data.type == 4 ? data.rewards_list : []}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <PopConfirm
        zIndex={999991}
        title={t('game.tips')}
        cancelText={t('game.cancel')}
        confirmText={t('game.ok')}
        visible={pcTipsVisible}
        handleClose={() => {
          setPcTipsVisible(false);
        }}
        handleConfirm={() => {
          setPcTipsVisible(false);
        }}
      >
        {t('welcomeBonus.add_home_go')}
      </PopConfirm>
      <div
        className={cn(
          ' flex flex-row items-center justify-between rounded-normal mb-[.16rem] py-[.1rem] px-[.24rem] border border-solid mx-[.32rem]',
          {
            'bg-[var(--colour-34)] border-[transparent]': data.task_status != 22,
            'bg-[var(--colour-35)] border-[var(--colour-1)]': data.task_status == 22,
          },
        )}
      >
        <div className="w-[.76rem] h-[.76rem]">
          <img src={CoinsImg} />
        </div>
        <div className="flex-1 flex flex-col items-start justify-center pr-[.1rem]">
          <div className="text-[var(--colour-49)] font-[500] text-[.28rem] flex items-center justify-start">
            <span>{t(`welcomeBonus_activityRewards.${data.type}`)}</span>
            {data.type == 4 ? (
              <div
                className="flex w-[.32rem] h-[.32rem] ml-[.2rem] active:scale-[.95] transition-all select-none cursor-pointer text-[var(--colour-7)]"
                onClick={handleShowAllDeposit}
              >
                <Icon name={IconName.WidthdrawQuestion2} />
              </div>
            ) : (
              <></>
            )}
          </div>
          <span
            className={cn('font-[500] text-[.28rem] text-[var(--colour-26)]', {
              'line-through': data.task_status == 22,
            })}
          >
            {getShowAmount(data, websiteInitConfig.website_info.currency_symbol || '')}
          </span>
        </div>
        {data.task_status == 10 ? getGo(data, handleShowPCTips) : <></>}
        {data.task_status == 11 ? (
          <div className="text-[.24rem] font-[700] leading-[.64rem] text-[var(--colour-1)] w-[1.52rem]  h-[.64rem] text-center">
            {t('promotion.calculating_result')}
          </div>
        ) : (
          <></>
        )}
        {data.task_status == 21 ? (
          <LimitClickWrapper
            onClick={handleClaim}
            className="text-[.24rem] w-[1.52rem] h-[.64rem] bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal font-[700] flex items-center justify-center"
          >
            {t('welcomeBonus.claim')}
          </LimitClickWrapper>
        ) : (
          <></>
        )}
        {data.task_status == 22 ? (
          <div
            className={cn('h-[.64rem] flex items-center justify-center rounded-normal font-[700] space-x-[.06rem]', {
              'w-[1.52rem]': defaultLang == 'en',
              'w-[2.16rem]': defaultLang != 'en',
            })}
          >
            <span className="w-[.44rem] h-[.44rem] flex items-center justify-center rounded-[50%] bg-[var(--colour-29)]">
              {/* <img src={DoneImg} className="w-full" /> */}
              <span className="text-[var(--colour-2)] block w-[.2rem] h-[.16rem]">
                <Icon name={IconName.Complete} />
              </span>
            </span>
            <span className="text-[.24rem] text-[var(--colour-3)]">{t('welcomeBonus.claimed')}</span>
          </div>
        ) : (
          <></>
        )}
        {data.task_status == 30 ? (
          <div className="text-[.24rem] font-[700] leading-[.64rem] text-[var(--colour-49)] w-[1.52rem]  h-[.64rem] text-center">
            {t('promotion.award_expiration')}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default function ClaimRewards() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getData } = useWelcomeBonus();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const {} = useSelector((state) => ({}), shallowEqual);

  const handleClose = () => {
    dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_ClaimBonus_CloseButton);
  };

  const handleClick = () => {
    dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
  };

  const requestActivitySettle = (id) => {
    if (id && isLogin()) {
      PromotionActivitySettle({
        activity_id: id,
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getData().then((res) => {
      setLoading(false);
      if (res.code == 0) {
        const result = res.result;
        if (result.activity_switch == '1' && result.list && result.list.length > 0) {
          const data = result.list[0];
          setData(data);
          dispatch(setWelcomeBonusData(data));

          // console.log('id', data?.id);
          requestActivitySettle(data?.id);
        } else {
          dispatch(setWelcomeBonusData(null));
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  }, []);

  return (
    <div className=" w-[6.86rem] relative z-[10] bg-[var(--colour-35)] rounded-normal   h-[9.6rem]">
      {loading ? (
        <Loading2 className="py-[1rem]" />
      ) : data ? (
        <>
          <div className=" flex justify-center items-center bg-[var(--colour-45)] absolute w-[.72rem] h-[.72rem] rounded-[.08rem] top-[.2rem] right-[.32rem]">
            <Close onClick={handleClose} />
          </div>
          <div className={`flex flex-row justify-between items-center p-[.36rem]`}>
            <div className="text-[.36rem]  font-[600] text-[var(--colour-48)]">{t('welcomeBonus.title')}</div>
          </div>
          <div className="w-full bg-[var(--colour-6)] h-[1px] "></div>
          <div className="text-[var(--colour-26)] font-[700] uppercase text-center text-[.52rem] mt-[.2rem]">
            {t('welcomeBonus.title')}
          </div>
          <div className="text-[var(--colour-49)] font-[500]  text-center  text-[.28rem]  mt-[.06rem]">
            {t('welcomeBonus.tasksExpired')}&nbsp;
            <BonusCountdown style={2} onComplete={handleClose} date={data?.activity_end_time * 1000} />
          </div>
          <div className="text-[var(--colour-44)]  w-[5.8rem] mx-[auto] font-[400]  text-[.24rem] text-center  mt-[.06rem]">
            {t('welcomeBonus.tips1')}
          </div>
          <ScrollWrapper className="flex flex-col items-center   mt-[.16rem] h-[5.2rem]">
            {data?.activity_rewards &&
              data?.activity_rewards.map((item, index) => <Item key={index} dataSource={item} id={data?.id} />)}
          </ScrollWrapper>
        </>
      ) : (
        <NoData className="py-[1rem]" showTextLangkey="promotion.eventend" />
      )}
    </div>
  );
}
