import { useTranslation } from 'react-i18next';
import { isControllableSite } from '../../utils/helper';

export default function ViewAllText({ onClick, className = '' }) {
  const { t } = useTranslation();

  return isControllableSite() ? (
    <div
      className={`flex flex-row items-center justify-center font-[500] text-[.28rem] leading-[.34rem] text-[var(--colour-14)] ${className}`}
      onClick={onClick}
    >
      <div>{t('home.seeall')}</div>
    </div>
  ) : (
    <div
      className="flex items-center px-[.32rem] h-[.52rem] font-[500] text-[.24rem] bg-[var(--colour-51)]     rounded-normal text-[var(--colour-48)]"
      onClick={onClick}
    >
      <div>{t('home.seeall')}</div>
    </div>
  );
}
