import { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Close from '../../close';
import SwitchWrapper from '../../switchWrapper';
import RegisterPages from '../registerPages/index';
import LoginPages from '../loginPages/index';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import cn from 'classnames';
import { isUseLayoutMobile } from '../../../utils/helper';
import Icon, { IconName } from '../../icon';

/**
 * 登录注册页面
 * @param loginOrSignUp 1 登录(默认) 2 注册
 */
const Entrance1 = ({ handleClose, loginOrSignUp = 1 }) => {
  const { t } = useTranslation();
  const [openType, setOpenType] = useState(loginOrSignUp);

  return (
    <div
      className={cn(
        'bg-[var(--colour-35)] bg-[length:100%_100%] bg-no-repeat text-center relative rounded-normal w-[6.86rem] p-[.32rem_.32rem_.24rem_.32rem]',
      )}
    >
      <div className="active:scale-[.95] transition-all select-none bg-[var(--colour-45)] text-[var(--colour-46)] flex justify-center items-center rounded-normal absolute w-[.72rem] h-[.72rem] right-[.32rem] top-[.32rem]">
        <Close
          onClick={() => {
            handleClose();
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseRegLogButton);
          }}
        />
      </div>

      {openType == 1 ? (
        <LoginPages handleClose={handleClose} changeType={() => setOpenType(2)} />
      ) : openType == 2 ? (
        <RegisterPages
          changeType={() => {
            setOpenType(1);
          }}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Entrance1;
