import { useEffect, memo, useState } from 'react';
import Modal from 'react-modal';
import { useStateIfMounted } from 'use-state-if-mounted';
import { decrementDialogModalCount, incrementDialogModalCount } from '../../redux/reducers/appSlice';
import { getRootCssVariable, isControllableSite } from '../../utils/helper';
import useDialogModalZIndex from '../../hooks/useDialogModalZIndex';
import { useUpdateEffect } from 'ahooks';
import { useDispatch } from 'react-redux';
import { isAnyMobile } from '../../utils/deviceUtils';
import useGameInfo from '../../hooks/useGameInfo';
import { eventEmitter, EmitterEventTypes } from '../../utils/eventEmitter';

const DialogModal = memo((props) => {
  const dispatch = useDispatch();
  const [afterOpenClass, setAfterOpenClass] = useStateIfMounted('entered');
  const { getTopZIndex, removeTopZIndex, initZIndex } = useDialogModalZIndex();
  const [overlayZIndex, setOverlayZIndex] = useState(initZIndex);

  const { isGameOpen } = useGameInfo();
  const [isPlayInGame, setIsPlayInGame] = useState(false);

  const {
    zIndex = undefined, //一般不设置这个值，默认自动生成，除非特殊逻辑。
    children,
    handleClose = () => {},
    onRequestClose,
    style,
    isOpen,
    contentLabel,
    shouldCloseOnOverlayClick,
    overlayClassName,
    className,
    afterClose = () => {},
    handleOverlayClick = () => {},
    turnOnAnimation = true, //是否打开动画
    isZoom20 = false, //是否缩放20%
  } = props;

  const [dialogStyle, setDialogStyle] = useState(style);
  const initialDialogStyle = style;
  function handleAfterOpen() {
    dispatch(incrementDialogModalCount());
  }

  function handleAfterClose() {
    dispatch(decrementDialogModalCount());
    afterClose();
  }

  // 兼容两种关闭回调
  function handleRequestClose() {
    handleClose && handleClose();
    onRequestClose && onRequestClose();
  }

  useEffect(() => {
    if (isOpen) {
      if (!isControllableSite()) {
        setAfterOpenClass(
          `${turnOnAnimation ? 'entered' : 'no-animation-entered'}  ${overlayClassName ? overlayClassName : ''}`,
        );
      } else {
        setAfterOpenClass(
          `${turnOnAnimation ? 'entered-wg' : 'no-animation-entered'}  ${overlayClassName ? (overlayClassName == 'login_signup_bg' ? 'login_signup_bg-wg' : overlayClassName) : ''}`,
        );
      }
      setOverlayZIndex(getTopZIndex());
    } else {
      setAfterOpenClass('');
    }
  }, [isOpen, overlayClassName]);

  useUpdateEffect(() => {
    if (!isOpen && zIndex == undefined) {
      removeTopZIndex();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (isGameOpen) {
        // const width =
        //   isAnyMobile() || (isAnyMobile() && window.innerWidth > window.innerHeight)
        //     ? window.innerWidth
        //     : getRootCssVariable('--theme-max-width', true);
        const width = window.innerWidth;

        setDialogStyle((prevStyle) => ({
          ...prevStyle,
          content: {
            ...prevStyle?.content,
            maxWidth: width,
            width: width,
          },
        }));
      }
    };

    if (isGameOpen) {
      handleResize();
      document.body.style.transform = 'none';
      setIsPlayInGame(true);
    } else {
      document.body.style.transform = 'translateZ(0px)';
      setDialogStyle(initialDialogStyle);
      setIsPlayInGame(false);
    }

    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resizeDelay, () => {
      handleResize();
    });

    return () => {
      unsubscribe();
    };
  }, [isGameOpen]);
  const handleOverlayElementClick = (evt, props) => {
    if (props.onClick) {
      props.onClick();
    }
    if (evt.target === evt.currentTarget) {
      handleOverlayClick();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onAfterOpen={handleAfterOpen}
        onAfterClose={handleAfterClose}
        onRequestClose={handleRequestClose}
        style={{
          ...dialogStyle,
          overlay: {
            ...dialogStyle.overlay,
            zIndex: zIndex != undefined ? zIndex : overlayZIndex,
          },
        }}
        closeOnMaskClick={true}
        contentLabel={contentLabel}
        portalClassName="react-dialog-box"
        overlayClassName={{
          base: `${isPlayInGame ? 'game-underlay' : 'underlay'}`,
          afterOpen: afterOpenClass,
          beforeClose: '',
        }}
        id="react-aria-modal-dialog"
        bodyOpenClassName={null}
        ariaHideApp={false}
        closeTimeoutMS={200}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        overlayElement={(props, contentElement) => (
          <div
            {...props}
            onClick={(evt) => {
              handleOverlayElementClick(evt, props);
            }}
          >
            <div className="blank-place"></div>
            {contentElement}
          </div>
        )}
        className={{
          base: `${className ? className : ''} ${turnOnAnimation ? (isZoom20 ? 'dialog-wrapper-zoom-20' : 'dialog-wrapper') : ''}`,
          afterOpen: afterOpenClass,
          beforeClose: '',
        }}
      >
        {isPlayInGame ? <div className="flex justify-center w-full h-full">{children}</div> : <>{children}</>}
      </Modal>
    </>
  );
});

export default DialogModal;
