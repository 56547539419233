import { memo, useEffect, useRef } from 'react';

import { betbyInitReq } from '../../../../api';
import {
  DialogModalChildrenKey,
  ResponsiveMode,
  setDialogModalVisible,
  setSportEnabled,
} from '../../../../redux/reducers/appSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useAsyncNode } from '../../../../hooks/useAsyncNode';
import Loading from '../../../../components/loading2';
import { isEnvDevelopment, loadScriptAsync } from '../../../../utils/helper';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

/**
 * 体育betby
 * @param
 * @returns
 */

// 开发
const debugSportScriptUrl = 'https://ui.invisiblesport.com/bt-renderer.min.js';
const debugSportBrandId = '2240148667329814528';
// 生产
const sportScriptUrl = 'https://bet999.sptpub.com/bt-renderer.min.js';
const sportBrandId = '2277818841729994752';

// 加载js脚本
let isLoaded = false;
async function loadScriptOnce() {
  if (!isLoaded) {
    console.log('[betby]: loadScriptOnce');
    await loadScriptAsync(isEnvDevelopment() ? debugSportScriptUrl : sportScriptUrl);
    isLoaded = true;
  }
}

/**
 * betby
 */
const Betby = memo(({ token, identity, lang, getToken }) => {
  const dispatch = useDispatch();
  const btRef = useRef();

  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
    }),
    shallowEqual,
  );

  const isMobile = true;

  useEffect(() => {
    console.log('[betby]: initialize');
    const bt = new window.BTRenderer().initialize({
      token,
      lang,
      brand_id: isEnvDevelopment() ? debugSportBrandId : sportBrandId,
      themeName: 'bet999',
      target: document.getElementById('betby'),
      stickyTop: 0,
      betslipZIndex: 60,
      betSlipOffsetTop: 65,
      betSlipOffsetBottom: 50,
      betSlipOffsetRight: 0,
      onLogin: () => {
        showDialogModal(DialogModalChildrenKey.LoginBox);
      },
      onRegister: () => {
        showDialogModal(DialogModalChildrenKey.SignUp);
      },
      onRecharge: () => {
        if (identity) {
          showDialogModal(DialogModalChildrenKey.WithdrawDeposit);
        } else {
          showDialogModal(DialogModalChildrenKey.LoginBox);
        }
      },
      onSessionRefresh: () => {
        // session刷新
        window.location.reload();
      },
      onTokenExpired: () => getToken(),
      onAppInitialized: () => {},
    });
    btRef.current = bt;
    return () => {
      console.log('[betby]: kill');
      btRef.current = undefined;
      bt.kill();
    };
  }, [token, lang]);

  // 更新位置底部位置
  useEffect(() => {
    if (btRef.current) {
      console.log('[betby]: updateOptions');
      btRef.current.updateOptions({
        betSlipOffsetBottom: 50,
      });
    }
  }, [isMobile]);

  const showDialogModal = (key) => {
    dispatch(setDialogModalVisible({ show: key }));
  };

  return <div id="betby" />;
});

/**
 * 体育
 */
const NTemplate001 = () => {
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();

  const { identity, lang, currencyCode } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      lang: get(state, 'user.defaultLang'),
      currencyCode: get(state, 'app.websiteInitConfig.website_info.currency_code'),
    }),
    shallowEqual,
  );

  // 加载betby
  const asyncNode = useAsyncNode(
    async () => {
      const token = await getToken();
      return <Betby identity={identity} token={token} lang={lang} getToken={getToken} />;
    },
    [lang, currencyCode, identity],
    {
      loadingNode: (
        <div className="w-screen h-screen flex justify-center items-center mt-[-1.28rem]">
          <Loading />
        </div>
      ),
    },
  );

  // 获取token接口
  const getToken = async () => {
    await loadScriptOnce();
    if (currencyCode) {
      console.log('[betby]: getToken');
      const res = await betbyInitReq({
        lang,
        currency_in_use: currencyCode,
        currency_in_game: currencyCode,
        is_match_tracker: '1',
        is_cashout: '1',
      });
      if (res.code === 0) {
        return res.result?.token;
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
        // 开关
        if (res.result) {
          checkSportSwitch(res.result);
        }
      }
    }
    return null;
  };

  // 检查体育开关
  const checkSportSwitch = (result) => {
    if (result && result.sport_betby_switch == '0') {
      navigate(toLocalePath('/'));
      dispatch(setSportEnabled(false));
    }
  };

  return <div className="w-full min-h-[var(--app-height)]">{asyncNode}</div>;
};

export default memo(NTemplate001);
