import CryptoJS from 'crypto-js';
/**
 * 加密管理
 */
class CryptojsManager {
  /**
   * AES加密
   */
  static AES(message, secretKey) {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const encryptedText = CryptoJS.AES.encrypt(message, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encryptedText;
  }

  static DecryptAES(str, sKey) {
    const decrypted = CryptoJS.AES.decrypt(str, sKey, {
      iv: sKey,
      mode: CryptoJS.mode.CBC, // CBC算法
      padding: CryptoJS.pad.Pkcs7, //使用pkcs7 进行padding 后端需要注意
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

export default CryptojsManager;
