import Streamer from '../../../assets/img/pliotwheel/wheelSprites/streamer.png';
import Icon, { IconName } from '../../icon';

export default function Index({ title, handleShowExplain = () => {} }) {
  return (
    <div className="w-[4.78rem] h-[1.16rem] mt-[.2rem] relative z-[10]">
      <img src={Streamer} alt="" className="not-drag w-full h-full" />
      <div className="relative text-[.36rem] font-[800] top-[-.98rem] left-[50%] translate-x-[-50%] text-center text-[var(--colour-43)]">
        {title}
      </div>
      <div
        className="hidden absolute cursor-pointer z-[1] w-[.36rem] h-[.36rem] right-[-.64rem] top-[.3rem] active:scale-[.95] transition-all "
        onClick={handleShowExplain}
      >
        <Icon name={IconName.Explain} />
      </div>
    </div>
  );
}
