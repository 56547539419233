import { shallowEqual, useSelector } from 'react-redux';
import SwitchWrapper from './switchWrapper';
import { useRef } from 'react';
import useDivSize from '../hooks/useDivSize';
import TippyPopBox, { Placement } from './tippyPopBox';
import PopChoose, { PopChooseItem } from './popChoose';
import { useStateIfMounted } from 'use-state-if-mounted';
import DropDownInput from './dropDownInput';
import Icon, { IconName } from './icon';
import cn from 'classnames';
import StickyBox from './stickyBox';

export const makeTabKeys = (tabNum = 0) => {
  return Array(tabNum)
    .fill()
    .map((_, index) => `tab-${index}`);
};

const TabItemBoxLeft = ({ text, isActive = false, onClick = () => {}, unusedActiveEl, tabActiveEl }) => {
  return (
    <>
      {tabActiveEl && unusedActiveEl ? (
        <>
          <div onClick={onClick}>{isActive ? tabActiveEl : unusedActiveEl}</div>
        </>
      ) : (
        <>
          <div
            className={cn(
              'w-[184px] h-[64px] flex items-center justify-center rounded-normal text-[20px] font-[600] cursor-pointer text-center',
              {
                'bg-[var(--colour-28)] text-[var(--colour-44)] border border-[var(--colour-6)]': !isActive,
                'bg-[var(--colour-1)] border border-[var(--colour-1)] text-[var(--colour-2)]': isActive,
              },
            )}
            onClick={onClick}
          >
            {text}
          </div>
        </>
      )}
    </>
  );
};

const TabItemBox = ({ item, index, pageList = [], isActive = false }) => {
  return (
    <div key={index} className="flex items-center flex-1">
      <SwitchWrapper
        className="w-full h-[92px] flex items-center justify-center active:scale-[.95] transition-none select-none text-[24px] cursor-pointer font-[700] text-center relative  pb-[.12rem]  px-[35px] leading-[1em] text-[.28rem] h-[.88rem] px-[.04rem]"
        chooseClassName="text-[var(--colour-13)]"
        notChooseClassName="text-[var(--colour-14)]"
        choose={isActive}
        onClick={item.onClick}
      >
        <span>{item.name}</span>
        {isActive ? (
          <div className="absolute left-[0] bottom-[0] w-full bg-[var(--colour-1)] rounded-normal h-[.06rem] "></div>
        ) : (
          <></>
        )}
      </SwitchWrapper>
      {index < pageList.length - 1 && (
        <div className="h-[56px]  w-[.02rem] bg-[var(--colour-6)] h-[.6rem] shrink-0"></div>
      )}
    </div>
  );
};

const TabSwitcherMobile = ({
  page,
  pageList,
  popChooseItemChooseClassName,
  dropDownInputClassName = 'bg-[var(--colour-35)]',
  popChooseClassName = 'bg-[var(--colour-35)]',
  dropDownInputTextClassName = '',
}) => {
  const [filterTypeVisible, setFilterTypeVisible] = useStateIfMounted(false);

  const divRef = useRef(null);
  const { width } = useDivSize(divRef);

  return (
    <>
      <div className="relative">
        <TippyPopBox
          placement={Placement.bottomEnd}
          popChildren={
            <PopChoose
              className={`py-[10px] ${popChooseClassName}`}
              children={pageList.map((item, index) => (
                <PopChooseItem
                  className="h-[40px] flex items-center text-[16px] font-[500] px-[10px] !text-[rgba(255,255,255,0.5)]"
                  key={index}
                  data={item}
                  choose={item.key == page}
                  onClick={() => {
                    setFilterTypeVisible(false);
                    item.onClick();
                  }}
                  widthNumber={width}
                >
                  <span className="">{item.name}</span>
                </PopChooseItem>
              ))}
            />
          }
          visible={filterTypeVisible}
          onClickOutside={() => {
            setFilterTypeVisible(false);
          }}
          offset={[0, 6]}
        >
          <div>
            <DropDownInput
              iconCanRotate={false}
              className={`px-[14px] h-[40px] text-[12px] font-[500] rounded-normal ${dropDownInputClassName}`}
              onClick={() => {
                setFilterTypeVisible(!filterTypeVisible);
              }}
              ref={divRef}
            >
              <div className={dropDownInputTextClassName}>{pageList.find((item) => item.key == page)?.name}</div>
              <div className="flex flex-row items-center justify-right mx-[5px]">
                <span
                  className={cn('w-[8px] h-[16px] block text-primary-content transition', {
                    'rotate-[90deg]': filterTypeVisible,
                  })}
                >
                  <Icon name={IconName.RightArrow2} />
                </span>
              </div>
            </DropDownInput>
          </div>
        </TippyPopBox>
      </div>
    </>
  );
};

const TabSwitcherPC = ({ page, pageList = [] }) => {
  return (
    <div className="flex items-center justify-between">
      {pageList.map((item, index) => {
        return <TabItemBox item={item} index={index} pageList={pageList} key={index} isActive={item.key == page} />;
      })}
    </div>
  );
};

const TabSwitcherMobileSlide = ({ pageList, page, scrollBoxItemClass = '' }) => {
  return (
    <div
      className={`flex overflow-x-auto scrollbar-none border-b border-[var(--colour-6)] space-x-[.46rem] px-[.32rem] bg-[var(--colour-69)]`}
    >
      {pageList.map((item, index) => {
        return (
          <div
            key={index}
            className={`${scrollBoxItemClass}`}
            onClick={() => {
              item.onClick();
            }}
          >
            <div
              className={cn(' text-[.24rem] font-[600] whitespace-nowrap  h-[.7rem] flex items-center justify-center', {
                'font-[700] text-[var(--colour-13)]': item.key == page,
                'font-[400] text-[var(--colour-14)]': item.key != page,
              })}
            >
              {item.name}
            </div>
            {item.key == page ? <div className=" h-[.02rem] w-full rounded-[3px] bg-[var(--colour-1)]"></div> : <></>}
          </div>
        );
      })}
    </div>
  );
};

const TabSwitcher = ({
  page,
  pageList,
  isMobileDropmenu,
  tabPosition,
  dropDownInputTextClassName,
  popChooseItemChooseClassName,
  spaceY,
  isMobileSticky,
  scrollBoxItemClass = '',
}) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  return isMobileDropmenu ? (
    <StickyBox isSticky={isMobileSticky}>
      <TabSwitcherMobileSlide page={page} pageList={pageList} scrollBoxItemClass={scrollBoxItemClass} />
    </StickyBox>
  ) : (
    <StickyBox isSticky={isMobileSticky}>
      <TabSwitcherPC page={page} pageList={pageList} />
    </StickyBox>
  );
};

const TabBox = ({
  page,
  tabPageList,
  tabClassName = 'z-[18]',
  boxClassName = '',
  contentClassName = '',
  isMobileDropmenu = false,
  tabPosition = 'top',
  dropDownInputTextClassName = '',
  popChooseItemChooseClassName = '',
  spaceY = '',
  isMobileSticky = false,
  scrollBoxItemClass = '',
}) => {
  return (
    <div className={cn(`${boxClassName}`)}>
      <div className={cn(`relative ${tabClassName}`)}>
        <TabSwitcher
          page={page}
          pageList={tabPageList}
          isMobileDropmenu={isMobileDropmenu}
          tabPosition={tabPosition}
          dropDownInputTextClassName={dropDownInputTextClassName}
          popChooseItemChooseClassName={popChooseItemChooseClassName}
          spaceY={spaceY}
          isMobileSticky={isMobileSticky}
          scrollBoxItemClass={scrollBoxItemClass}
        />
      </div>

      <div className={cn(`${contentClassName}`)}>{tabPageList.find((item) => item.key === page)?.content}</div>
    </div>
  );
};

export default TabBox;
