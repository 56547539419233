import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { get, cloneDeep } from 'lodash';
import { createPortal } from 'react-dom';
import scrollIntoView from 'scroll-into-view';
import {
  setTagIndex,
  setDrawerMenuVisible,
  setDialogModalVisible,
  DialogModalChildrenKey,
} from '../../../../../../redux/reducers/appSlice';
import { toLocalePath, toNoLocalePath } from '../../../../../../utils/localePath';
import { isLogin, isLightTheme, cmsImgUrl } from '../../../../../../utils/helper';
import useLangServerConfig from '../../../../../../hooks/useLangServerConfig';
import LocalSideIcon from './localSideIcon';
import { IconName } from '../../../../../icon';
import { useGlobalNavigate } from '../../../../../../contexts/navigateContext';

const HeaderHeight = 45; //header高度
const MenuBarHeight = 62; //menubar高度
const TagSearchHeight = 48; //tagsearch高度

const DrawermenuTagSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { pathname } = useLocation();

  const { drawerMenuVisible, tagIndex, identity, websiteInitData, websiteInitConfig } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      tagIndex: state.app.tagIndex,
      identity: get(state, 'user.identity'),
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const { getServerLangText } = useLangServerConfig();

  const isFirstLoadData = useRef(true); //是否是首次数据加载，(websiteInitData数据更新后，会设置选中的index)

  const [list, setList] = useState([]); //处理完之后的数据列表

  const [notClickList] = useState([
    {
      game_type_id: '001',
      needLogin: true,
      isSwitch: true,
      isMask: false,
      isName: 'home.category_001',
      isPrivate: true,
    },
    {
      game_type_id: '003',
      needLogin: true,
      isSwitch: true,
      isMask: false,
      isName: 'home.category_003',
      isPrivate: true,
    },
    // {
    //   game_type_id: '-3',
    //   needLogin: false,
    //   isSwitch: false,
    //   isMask: false,
    //   isName: 'home.drawer_jackpot',
    //   isPrivate: true,
    // },
  ]); //前端固定要加上的三条数据，要和短盘1的首页保持一致

  useEffect(() => {
    if (websiteInitData && websiteInitData.template) {
      const arrData = websiteInitData.template.find((item) => {
        return item.module == 'ColumnData';
      });
      if (arrData) {
        let resData = arrData.config.map((item) => {
          return { ...item, isSwitch: true, isMask: false };
        });

        resData = resData.concat(notClickList);
        setList(resData);

        //第一次数据加载的时候设置默认选中第一个标签
        if (isFirstLoadData.current == true) {
          dispatch(setTagIndex(resData[0].game_type_id));
        }
        isFirstLoadData.current = false;
      }
    }
  }, [websiteInitData]);

  useEffect(() => {
    setList((list) => {
      return list.map((listItem) => {
        return { ...listItem, isMask: false };
      });
    });
  }, [identity, drawerMenuVisible]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const clickItem = async (game_type_id, index) => {
    dispatch(setTagIndex(game_type_id));
    const dom = document.querySelector(`.dom_tagsearch_item_${game_type_id}`);
    if (dom) {
      // scrollIntoView(dom, {
      //   time: 500,
      // });
    }
    //滑动首页页面
    let domItem = document.querySelector(`.dom_${game_type_id}`);
    if (domItem) {
      let topNum = domItem.getBoundingClientRect().top;
      let scrollNum = getScrollPosition().y;
      await delay(10);
      const myEvent = new CustomEvent('overlayScrollerScrollTo', {
        detail: {
          offsetTop: topNum + scrollNum - HeaderHeight + 2 - TagSearchHeight,
          behavior: 'smooth',
          isOffset: true,
        },
      });
      document.dispatchEvent(myEvent);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100));
      findingDom(game_type_id);
    }
  };

  const findingDom = async (game_type_id, index) => {
    let dom = document.querySelector(`.scrollBox`);
    if (dom) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      clickItem(game_type_id, index);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100));
      findingDom(game_type_id, index);
    }
  };

  const clickItemCheckedHome = (game_type_id, index) => {
    navigate(toLocalePath('/'));
    findingDom(game_type_id, index);
  };

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  return (
    <>
      {document.querySelector('.tag_search_portal_target') ? (
        createPortal(
          <div className="flex flex-wrap justify-between">
            {list.slice(0, 10).map((item, index) => {
              return (
                <div
                  key={index}
                  className={cn(
                    '      w-[1.38rem] h-[1.06rem] mb-[.16rem] rounded-normal p-[.19rem] text-[.2rem] font-[500] leading-[.24rem] cursor-pointer active:scale-[.95] transition-all select-none  flex flex-col items-center relative',
                    {
                      'bg-[var(--colour-1)] text-[var(--colour-2)]':
                        tagIndex == item.game_type_id && websiteInitConfig?.website_info.website_theme != 'theme-15',
                      'bg-[var(--colour-16)] text-[var(--colour-14)]':
                        tagIndex != item.game_type_id && websiteInitConfig?.website_info.website_theme != 'theme-15',
                      'bg-menu-selected text-[var(--colour-2)]':
                        tagIndex == item.game_type_id && websiteInitConfig?.website_info.website_theme == 'theme-15',
                      'bg-menu-default text-[var(--colour-14)]':
                        tagIndex != item.game_type_id && websiteInitConfig?.website_info.website_theme == 'theme-15',
                    },
                  )}
                  onClick={() => {
                    if (item.isSwitch) {
                      if (!item.needLogin || (item.needLogin && isLogin())) {
                        if (toNoLocalePath(pathname) === '/') {
                          clickItem(item.game_type_id, index);
                        } else {
                          clickItemCheckedHome(item.game_type_id, index);
                        }

                        handleLinkClick();
                      } else {
                        handleLinkClick();
                        dispatch(
                          setDialogModalVisible({
                            show: DialogModalChildrenKey.LoginBox,
                          }),
                        );
                      }
                    } else {
                      setList(
                        list.map((listItem) => {
                          return item.game_type_id == listItem.game_type_id ? { ...listItem, isMask: true } : listItem;
                        }),
                      );
                    }
                  }}
                >
                  {item.isMask ? (
                    <div className="absolute top-[0] left-[0] right-[0] bottom-[0] bg-[var(--colour-21)] rounded-normal text-[var(--colour-56)]  text-[.16rem] font-[700]  leading-[.2rem] flex items-center justify-center text-center">
                      <div>
                        <div>{t('home.drawer_coming')}</div>
                        <div>{t('home.drawer_soon')}</div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* <div className="w-[.4rem] h-[.4rem] mb-[.04rem]   ">
                    <img
                      className="w-full"
                      src={
                        item.isPrivate
                          ? `/static/layout10002-img/${item.game_type_id}_${tagIndex == item.game_type_id ? '1' : isLightTheme() ? '2_grey' : '2'}.png`
                          : tagIndex == item.game_type_id
                            ? cmsImgUrl(item.icon_selected)
                            : cmsImgUrl(item.icon_unselected)
                      }
                    />
                  </div> */}

                  {item.isPrivate ? (
                    // <div>{item.game_type_id},{tagIndex}</div>
                    <LocalSideIcon
                      isShowSvg={tagIndex != item.game_type_id}
                      svgIconName={item.game_type_id == '001' ? IconName.Favorite : IconName.Rencent}
                      imgSrc={item.game_type_id == '001' ? '/static/img/favorite.png' : '/static/img/rencent.png'}
                    />
                  ) : (
                    <div className="w-[.4rem] h-[.4rem] mb-[.04rem]   ">
                      <img
                        className="w-full"
                        src={
                          tagIndex == item.game_type_id
                            ? cmsImgUrl(item.icon_selected)
                            : cmsImgUrl(item.icon_unselected)
                        }
                      />
                    </div>
                  )}

                  <div className="text-center">
                    {item.isPrivate ? t(item.isName) : getServerLangText(item.lang_key)}
                  </div>
                </div>
              );
            })}
          </div>,
          document.querySelector('.tag_search_portal_target'),
        )
      ) : (
        <></>
      )}
    </>
  );
};
export default DrawermenuTagSearch;

// 获取 当前 滚动条的长度， 水平 && 垂直方向
function getScrollPosition() {
  let x, y;
  if (!!window.pageXOffset) {
    x = window.pageXOffset;
    y = window.pageYOffset;
  } else {
    x = (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    y = (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }
  return { x, y };
}
