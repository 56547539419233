import PopBg2 from '../../../../components/popBg2';
import ExpirationTime from '../../../../components/expirationTime';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../../../redux/reducers/appSlice';
import { getShowMoney, isLogin, isLightTheme } from '../../../../../../utils/helper';
import { Trans, useTranslation } from 'react-i18next';
import ClaimBtn from '../com/claimBtn';
import { useEffect, useState } from 'react';
import AnalyticsManager, { FirebaseEvents } from '../../../../../../manager/analyticsManager';
import LimitClickWrapper from '../../../../../limitClickWrapper';

const GuideMoney = () => {
  return (
    <div className="w-[6rem] h-[6rem] bg-no-repeat bg-[url(/static/img/refer/referralCashback_money.png)] referral_cashback origin-center scale-[0.75] bg-[length:24rem_36rem]"></div>
  );
};

const Info = ({ title, intro, money }) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  return (
    <div
      className={cn(
        'w-[5.64rem] h-[5.88rem] bg-[length:100%_100%] bg-no-repeat relative bg-[url(/static/img/cashback/bg-guide-mobile.png)]',
      )}
    >
      <div className="w-full flex justify-center text-center absolute top-[-.88rem] left-[-.14rem]">
        <GuideMoney />
      </div>
      <div className="w-full flex justify-center text-center absolute top-[.2rem]">{title}</div>
      <div className="flex justify-center text-center absolute abs-x-center top-[1.2rem] w-[4.32rem]">{intro}</div>
      <div className="w-full flex justify-center text-center absolute top-[4.86rem]">{money}</div>
    </div>
  );
};

const GuideContent = ({ data, handleClose = () => {}, isShowCloseBtn, showMain = () => {}, onExpired }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
    }),
    shallowEqual,
  );

  const [totalReward, setTotalReward] = useState(0);
  const [isUpto, setIsUpto] = useState(false);

  useEffect(() => {
    if (data) {
      const list = data?.task_config || [];
      const sumAmount = list.reduce((acc, curr) => {
        return acc + Number(curr.claim_reward || 0);
      }, 0);

      const configAmount = Number(data?.reward_amount_toplimit || 0);

      if (isLogin()) {
        if (sumAmount > 0) {
          setTotalReward(getShowMoney(sumAmount));
          setIsUpto(false);
        } else {
          setTotalReward(getShowMoney(configAmount));
          setIsUpto(true);
        }
      } else {
        setTotalReward(getShowMoney(configAmount));
        setIsUpto(true);
      }
    }
  }, [data]);

  const onClickClaim = () => {
    if (isLogin()) {
      showMain();
    } else {
      handleClose();
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
  };

  useEffect(() => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBackWindow_Popup);
  }, []);

  return (
    <>
      <PopBg2 handleClose={handleClose} className="!h-full pb-[.4rem]">
        <div className="w-full h-full">
          <div
            className="absolute w-full top-[.1rem] text-[.36rem] font-[600] leading-[.44rem] text-[var(--colour-76)] text-center"
            style={{
              textShadow: '0px 5px 4px rgba(179, 73, 10, 0.80), 2px 2px 0px #B3490A',
            }}
          >
            {t('referral.cashback')}
          </div>
          <div className="mb-[.24rem] w-[6.14rem] h-[4.4rem] bg-[url(/static/img/referralcashback_guide_bg.png)] bg-no-repeat bg-[length:100%_100%]"></div>
          <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-24)] text-center mb-[.16rem] px-[.2rem]">
            {t('referral.cashback_tip')}
          </div>
          <div className="mb-[.5rem]">
            <div
              className="h-[1px]"
              style={
                isLightTheme()
                  ? {
                      background:
                        'linear-gradient(270deg, rgba(22, 120, 255, 0.00) 0%, #1678FF 50%, rgba(22, 120, 255, 0.00) 100%)',
                    }
                  : {
                      background:
                        'linear-gradient(270deg, rgba(121, 120, 135, 0.00) 0%, #797887 50%, rgba(121, 120, 135, 0.00) 100%)',
                    }
              }
            ></div>
            <div
              className="py-[.16rem]"
              style={
                isLightTheme()
                  ? {
                      background:
                        'linear-gradient(270deg, rgba(118, 152, 255, 0.00) 0%, #7698FF 50%, rgba(118, 152, 255, 0.00) 100%)',
                    }
                  : {
                      background:
                        'linear-gradient(270deg, rgba(24, 35, 68, 0.00) 0%, #182344 50%, rgba(24, 35, 68, 0.00) 100%)',
                    }
              }
            >
              <div className="text-[.2rem] font-[500] leading-[.24rem] text-[var(--colour-38)] text-center mb-[.08rem]">
                {t('referral.reward_value')}
              </div>
              <div className="text-[.44rem] font-[700] leading-[.54rem] text-[var(--colour-58)] text-center">
                {isUpto ? (
                  <span>
                    <Trans
                      t={t}
                      i18nKey="referral.up_to"
                      components={[<span key={0}></span>, <span key={1}></span>]}
                      values={{
                        currency: currency_symbol,
                        amount: totalReward,
                      }}
                    />
                  </span>
                ) : (
                  <>
                    {currency_symbol}
                    <span className="">{totalReward}</span>
                  </>
                )}
              </div>
            </div>
            <div
              className="h-[1px]"
              style={
                isLightTheme()
                  ? {
                      background:
                        'linear-gradient(270deg, rgba(22, 120, 255, 0.00) 0%, #1678FF 50%, rgba(22, 120, 255, 0.00) 100%)',
                    }
                  : {
                      background:
                        'linear-gradient(270deg, rgba(121, 120, 135, 0.00) 0%, #797887 50%, rgba(121, 120, 135, 0.00) 100%)',
                    }
              }
            ></div>
          </div>
          <LimitClickWrapper
            limitTime={1.5}
            onClick={() => {
              onClickClaim();
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBack_Claim);
            }}
            className="w-[5.38rem] h-[.76rem] rounded-[.12rem] colour-79 text-[.28rem] font-[600] leading-[.76rem] text-[var(--colour-2)] text-center m-auto cursor-pointer"
          >
            {t('referral.claim')}
          </LimitClickWrapper>
        </div>
      </PopBg2>
    </>
  );
};

export default GuideContent;
