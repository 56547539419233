import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-vant';
import useOnclickOutside from 'react-cool-onclickoutside';
import cn from 'classnames';
import { get } from 'lodash';
import { isLightTheme } from '../../../../../utils/helper';
import ScrollWrapper from '../../../../../components/scrollWrapper';
import Icon, { IconName } from '../../../../../components/icon';
import { GetConvidarConfig } from '../../../../../api';
import { getShowMoneyWithUnit } from '../../../../../utils/helper';
import Loading3 from '../../../../../components/loading3';
import ScrollbarContent from '../../../../../components/scrollbarContent';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const { websiteInitConfig, isSportEnabled, currency_code } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      isSportEnabled: get(state, 'app.isSportEnabled'),
      currency_code: state?.app?.websiteInitConfig?.website_info?.currency_code,
    }),
    shallowEqual,
  );

  const [typeList, setTypeList] = useState([]);
  const [data, setData] = useState(null);
  const [showList, setShowList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (websiteInitConfig) {
      let gameTypeList = websiteInitConfig.game_type;
      if (gameTypeList && gameTypeList.length > 0) {
        let list = [];
        if (currency_code == 'PHP') {
          list = gameTypeList;
        } else {
          const filteredArr = gameTypeList.filter((item) => item.id != 6);
          list = filteredArr;
        }

        if (!isSportEnabled) {
          list = list.filter((item) => item.id != 999);
        }
        if (data) {
          list = list.filter((item) => {
            return Object.keys(data).includes(`${item.id}`);
          });
        } else {
          list = [];
        }
        if (list && list.length > 0) {
          setTypeList(list);
          setCurrentId(list[0]['id']);
        }
      }
    }
  }, [websiteInitConfig, currency_code, isSportEnabled, data]);

  useEffect(() => {
    GetConvidarConfig()
      .then((res) => {
        if (res.code == 0) {
          setData(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    for (let key in data) {
      if (key == currentId) {
        setShowList(data[key]);
      }
    }
  }, [currentId, data]);

  const tipRef = useOnclickOutside(() => {
    setVisible(false);
  });
  return (
    <div className="p-[.22rem_.2rem_.4rem_.2rem] h-full bg-[var(--colour-68)] flex">
      {loading ? (
        <Loading3 className1="w-full h-full"></Loading3>
      ) : (
        <>
          <ScrollWrapper noRelative={true} mr={false} className="h-[calc(var(--app-height)-2.6rem)] mr-[.28rem]">
            <div className="flex flex-col">
              {typeList && typeList.length > 0
                ? typeList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={cn(
                          'w-[1.5rem] h-[1.04rem] rounded-normal pt-[.14rem] flex flex-col items-center mb-[.2rem]',
                          {
                            'bg-menu-selected text-[var(--colour-2)]': isLightTheme() && item.id == currentId,
                            'bg-menu-default text-[var(--colour-14)]': isLightTheme() && item.id != currentId,
                            'bg-[var(--colour-1)] text-[var(--colour-2)]': !isLightTheme() && item.id == currentId,
                            'bg-[var(--colour-75)] border border-[var(--colour-6)] shadow-[0_4px_4px_0_rgba(0,0,0,0.08)] text-[var(--colour-14)]':
                              !isLightTheme() && item.id != currentId,
                          },
                        )}
                        onClick={() => {
                          setCurrentId(item.id);
                        }}
                      >
                        <div
                          className={cn('w-[.48rem] h-[.48rem] mb-[.09rem]', {
                            'text-[var(--colour-2)]': item.id == currentId,
                            'text-[var(--colour-14)]': item.id != currentId,
                          })}
                        >
                          <Icon name={`ProportionCategory_${item.id}`} />
                        </div>
                        <div className="text-[.2rem] font-[500] leading-[.24rem]">
                          {t(`refer.proportion_category_${item.id}`)}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </ScrollWrapper>
          <div className="flex-1">
            <div className="h-[1.12rem] bg-[var(--colour-69)] border border-[var(--colour-6)] rounded-normal flex items-center text-[.24rem] font-[400] leading-[.3rem]">
              <div className="w-[33.333%] shrink-0 flex items-center justify-center flex-wrap">
                <div className="text-[var(--colour-38)] mr-[.04rem]">{t('refer.proportion_validos')}</div>

                <Popover
                  visible={visible}
                  className="proportion_popover"
                  teleport={document.body}
                  placement="top"
                  offset={[0, -1]}
                  duration={150}
                  reference={
                    <div
                      ref={tipRef}
                      className="w-[.24rem] h-[.24rem] text-[var(--colour-1)] relative"
                      onClick={() => {
                        setVisible(!visible);
                      }}
                    >
                      <Icon name={IconName.WidthdrawQuestion} />
                    </div>
                  }
                >
                  <div
                    ref={tipRef}
                    className="w-[3.6rem] bonus-tips-bubble2 py-[.12rem] px-[.1rem] bg-[var(--colour-69)] border border-[var(--colour-6)] rounded-[.16rem] text-[.16rem] leading-[.28rem] font-[600] text-[var(--colour-44)]"
                    style={{ filter: 'drop-shadow(0rem .08rem .08rem rgba(0, 0, 0, 0.10))' }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('refer.proportion_tips', {
                          val1: ' ≥ 0',
                          val2: ' ≥ 0',
                        }),
                      }}
                    ></div>
                  </div>
                </Popover>
              </div>
              <div className="w-[33.333%] shrink-0 flex items-center justify-center flex-wrap">
                <div className="text-[var(--colour-38)]">{t('refer.proportion_desempenho')}</div>
                <div className="text-[var(--colour-44)]">{t('refer.proportion_unid', { val1: '10,000' })}</div>
              </div>
              <div className="w-[33.333%] shrink-0 text-center text-[var(--colour-38)]">
                {t('refer.proportion_valor_do_desconto_por', { val1: '10,000' })}
              </div>
            </div>
            <ScrollWrapper noRelative={true} mr={false} className="h-[calc(var(--app-height)-3.72rem)]">
              {showList && showList.length > 0
                ? showList.map((item, index) => {
                    return <ListItem key={index} showBg={index % 2 != 0} item={item} />;
                  })
                : null}
            </ScrollWrapper>
          </div>
        </>
      )}
    </div>
  );
};

export default NTemplate001;

const ListItem = ({ showBg = false, item }) => {
  return (
    <div
      className={cn('h-[.7rem] rounded-normal text-[.2rem] font-[500] leading-[.24rem] flex items-center', {
        'bg-[var(--colour-69)]': showBg,
      })}
    >
      <div className=" w-[33.333%] shrink-0 flex items-center justify-center flex-wrap ">
        <div className="text-[var(--colour-44)]">0</div>
      </div>
      <div className=" w-[33.333%] shrink-0 flex items-center justify-center flex-wrap ">
        <div className="text-[var(--colour-38)]">{getShowMoneyWithUnit(item.wager)}</div>
      </div>
      <div className=" w-[33.333%] shrink-0 flex items-center justify-center flex-wrap ">
        <div className="text-[var(--colour-26)]">{item.ratio}</div>
      </div>
    </div>
  );
};
