import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Icon, { IconName } from './icon';
import FavoritesImg from '../assets/img/favorites.png';
import Favorites2Img from '../assets/img/favorites2.png';
import Button from './button';
import { getOrigin } from '../constants/config';
import { AddFavouritesReq, DelFavouritesReq, GameExitReq, GameInitReq } from '../api/';
import isMobile from 'ismobilejs';
import useOnclickOutside from 'react-cool-onclickoutside';
import Image from './image';
import { setDialogModalVisible, DialogModalChildrenKey, setFavoriteReload } from '../redux/reducers/appSlice';
import { makeGamePath, cmsImgUrl, isUseLayoutMobileHome } from '../utils/helper';
import Tippy from '@tippyjs/react';
import TippyPopBox, { Placement } from './tippyPopBox';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../utils/localePath';
import config from '../constants/config';
import { toast } from 'react-toastify';
import cn from 'classnames';
import useGameInfo from '../hooks/useGameInfo';
import SkeletonBox from './skeletonBox';
import FavoritesMobileImg from '../assets/img/favoritesMobile.png';
import Favorites2MobileImg from '../assets/img/favoritesMobile2.png';
import { get } from 'lodash';

const Mask = () => {
  const { t } = useTranslation();
  return (
    <div
      tabIndex="-1"
      className="absolute top-[0] left-[0] right-[0] bottom-[0] rounded-[12px] bg-[var(--colour-21)] flex flex-col items-center justify-center text-[var(--colour-1)] provider_focus"
    >
      <div className="w-[50px] h-[50px] mb-[6px]">
        <Icon name={IconName.MaintainProvider} />
      </div>
      <div className="text-[12px] font-[700] leading-[15px]">{t('home.drawer_maintenance')}</div>
    </div>
  );
};

const Item = forwardRef(
  (
    {
      model,
      handleFavoriteCallback,
      handleFavoriteNeedCallback = false,
      onClick = () => {},
      iconSize = '',
      innerClassName = '',
      clickProviderItem = () => {},
      game_type_id = '',
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { handleClickGameItem } = useGameInfo();

    const { userObj, isSportEnabled } = useSelector(
      (state) => ({
        userObj: get(state, 'user.userObj'),
        isSportEnabled: state.app.isSportEnabled,
      }),
      shallowEqual,
    );
    const [isFavourites, setIsFavourites] = useState(model.is_favourites);
    const [hadClick, setHadClick] = useState(false);

    useEffect(() => {
      setIsFavourites(model.is_favourites);
    }, [model]);

    const handleFavorite = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (userObj) {
        isFavourites == 1 ? handleDelFavouritesReq() : handleAddFavouritesReq();
      } else {
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.LoginBox,
          }),
        );
      }
    };

    const handleAddFavouritesReq = () => {
      AddFavouritesReq({
        game_uuid: model.uuid,
      }).then((res) => {
        if (res.code == 0) {
          dispatch(setFavoriteReload());
          handleFavoriteNeedCallback ? handleFavoriteCallback() : setIsFavourites(isFavourites == 0 ? 1 : 0);
        }
      });
    };

    const handleDelFavouritesReq = () => {
      DelFavouritesReq({
        game_uuid: model.uuid,
      }).then((res) => {
        if (res.code == 0) {
          dispatch(setFavoriteReload());
          handleFavoriteNeedCallback ? handleFavoriteCallback() : setIsFavourites(isFavourites == 0 ? 1 : 0);
        }
      });
    };

    const handleOnClick = () => {
      clickProviderItem(model);
    };

    const gamePreviewRef = useOnclickOutside(() => {
      setHadClick(false);
    });

    useImperativeHandle(ref, () => ({
      handleOnClick: handleOnClick,
    }));

    return (
      <div className={`game-preview ${iconSize}`} onClick={handleOnClick} ref={gamePreviewRef}>
        <div className="game-preview__inner-overflow">
          <div
            className={cn(`game-preview__inner ${innerClassName}`, {
              'blur-[3px]': hadClick,
            })}
          >
            <div
              className={cn('game-preview__image', {
                'scale-[1.05]': hadClick,
              })}
            >
              {model.open_status == 0 || (game_type_id == '999' && !isSportEnabled && model.open_status == 1) ? (
                <Mask />
              ) : (
                <></>
              )}
              <Image
                className="w-[100%] h-[100%] object-cover"
                src={cmsImgUrl(model.img)}
                fallbackSrc={model.img}
                onError={(currentTarget) => {
                  currentTarget.src = model.img;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

const EmptyItem = ({ iconSize = '', innerClassName = '' }) => {
  return (
    <div className={`game-preview opacity-[0] ${iconSize}`}>
      <div className="game-preview__inner-overflow">
        <div className={`game-preview__inner ${innerClassName}`}></div>
      </div>
    </div>
  );
};

const ProviderItem = forwardRef(
  (
    {
      model,
      handleFavoriteCallback,
      handleFavoriteNeedCallback = false,
      onClick = () => {},
      slidesPerView = 1,
      iconSize = '',
      innerClassName = '',
      clickProviderItem = () => {},
      game_type_id = '',
    },
    ref,
  ) => {
    return model == '' ? (
      <EmptyItem iconSize={iconSize} innerClassName={innerClassName} />
    ) : (
      <Item
        ref={ref}
        model={model}
        onClick={onClick}
        handleFavoriteCallback={handleFavoriteCallback}
        handleFavoriteNeedCallback={handleFavoriteNeedCallback}
        iconSize={iconSize}
        innerClassName={innerClassName}
        clickProviderItem={clickProviderItem}
        game_type_id={game_type_id}
      />
    );
  },
);

export default ProviderItem;
