import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { get } from 'lodash';

import { useSelector, shallowEqual } from 'react-redux';

import EditPassword from './editPassword';
import BindPassword from './bindPassword';
import BindEmail from './bindEmail';
import DialogModal from './index';

import Icon, { IconName } from '../icon';
import Close from '../close';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';
import BlankInput from '../blankInput';
import cn from 'classnames';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600]">{title}</div>
        <div className="active:scale-[.95] transition-all select-none w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full bg-[#fff] bg-opacity-20 h-[.02rem] mt-[.28rem]"></div>
    </>
  );
};

/**
 * 账户设置
 */
const AccountSetting = ({ showBindStyle = 0, handleClose }) => {
  const { t } = useTranslation();
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [showBindPhone, setShowBindPhone] = useState(showBindStyle == 1 ? true : false);
  const [showBindEmail, setShowBindEmail] = useState(showBindStyle == 2 ? true : false);

  const { userObj } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
    }),
    shallowEqual,
  );

  return (
    <div className="my-[.3rem] flex text-primary-content flex-col justify-between bg-[var(--colour-35)] text-center relative pb-[.4rem] px-[.32rem] w-[7.5rem] h-[auto] rounded-normal">
      {/* 账户设置标题 */}
      <FormHeader title={t('account.account_setting')} handleClose={handleClose} />

      {/* 手机号绑定 */}
      <div className={cn('my-[.3rem] rounded-normal text-center p-[.4rem] bg-[var(--colour-51)]')}>
        <div className="flex justify-between ">
          <div className="text-[.28rem] flex items-center">
            <span className="w-[.48rem] h-[.48rem]">
              <Icon name={IconName.Phone}></Icon>
            </span>
            <div className="ml-[.12rem] text-[.28rem]">{t('account.mobile_number')}</div>
          </div>

          {userObj?.mobile_verfied == '0' && userObj?.mobile == '' ? (
            <></>
          ) : (
            <div className="text-[.24rem]">
              <div className="text-[var(--colour-1)]">✓ {t('account.verified')}</div>
            </div>
          )}
        </div>

        <div className="mt-[.3rem] text-[.32rem] text-secondary-content font-[500] text-left">
          {t('account.set_number')}
        </div>
        {userObj?.mobile_verfied == '0' && userObj?.mobile == '' ? (
          <div
            className="mt-[.4rem] flex flex-row px-[.34rem] h-[.92rem] items-center justify-center cursor-auto bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
            onClick={() => setShowBindPhone(true)}
          >
            <div className="text-[.32rem] font-[700]">{t('account.bind')}</div>
          </div>
        ) : (
          <div className="w-[100%] mt-[.2rem] h-[.96rem] leading-[.96rem] text-[.28rem] flex justify-between pl-[.3rem] pr-[.08rem] rounded-normal bg-[var(--colour-51)]">
            <div className="text-[.28rem]">+{userObj?.mobile || '--'}</div>
          </div>
        )}
      </div>
      {/* 邮箱绑定 */}
      <div className={cn('my-[.3rem] rounded-normal text-center p-[.4rem] bg-[var(--colour-51)]')}>
        <div className="flex justify-between ">
          <div className="text-[.28rem] flex items-center">
            <span className="w-[.48rem] h-[.48rem]">
              <Icon name={IconName.Email}></Icon>
            </span>
            <div className="ml-[.12rem] text-[.28rem]">{t('account.email')}</div>
          </div>

          {userObj?.email_verfied == '0' && userObj?.email == '' ? (
            <></>
          ) : (
            <div className="text-[.24rem]">
              <div className="text-[var(--colour-1)]">✓ {t('account.verified')}</div>
            </div>
          )}
        </div>

        <div className="mt-[.3rem] text-[.32rem] text-secondary-content font-[500] text-left">
          {t('account.emailDesc')}
        </div>
        {userObj?.email_verfied == '0' && userObj?.email == '' ? (
          <div
            className="mt-[.4rem] flex flex-row px-[.34rem] h-[.92rem] items-center justify-center cursor-auto bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
            onClick={() => setShowBindEmail(true)}
          >
            <div className="text-[.32rem] font-[700]">{t('account.bind')}</div>
          </div>
        ) : (
          <div className="w-[100%] mt-[.2rem] h-[.96rem] leading-[.96rem] text-[.28rem] flex justify-between pl-[.3rem] pr-[.08rem] rounded-normal bg-[var(--colour-51)]">
            <div className="text-[.28rem]">{userObj?.email || '--'}</div>
          </div>
        )}
      </div>
      {/* 修改密码 */}
      <div className={cn('my-[.3rem] rounded-normal text-center p-[.4rem] bg-[var(--colour-51)]')}>
        <div className="text-[.28rem] flex items-center">
          <span className="w-[.48rem] h-[.48rem]">
            <Icon name={IconName.LoginPwd}></Icon>
          </span>
          <div className="ml-[.12rem] text-[.28rem]">{t('account.password')}</div>
        </div>
        <div className="text-[.24rem] mt-[.3rem] text-secondary-content font-[500] text-left">
          {t('account.passowrd_msg')}
        </div>

        <LimitClickWrapper
          className="mt-[.4rem] flex flex-row px-[.34rem] h-[.92rem] items-center justify-center cursor-auto bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
          onClick={() => {
            setShowEditPassword(true);
          }}
        >
          <ElementWithLoading
            normalEl={
              <>
                <div className="text-[.32rem] font-[700]">{t('account.change')}</div>
              </>
            }
          />
        </LimitClickWrapper>
      </div>

      <BlankInput />

      {/* 绑定手机 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowBindPhone(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={showBindPhone}
      >
        <BindPassword
          handleClose={() => {
            setShowBindPhone(false);
          }}
        />
      </DialogModal>

      {/* 绑定邮箱  */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowBindEmail(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={showBindEmail}
      >
        <BindEmail
          handleClose={() => {
            setShowBindEmail(false);
          }}
        />
      </DialogModal>

      {/* 修改密码 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowEditPassword(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={showEditPassword}
      >
        <EditPassword
          handleClose={() => {
            setShowEditPassword(false);
          }}
        />
      </DialogModal>
    </div>
  );
};
export default AccountSetting;
