import { get } from 'lodash';
import store from '../redux/store';
import { isEnvProduction, executeWithCondition } from '../utils/helper';
import { analyticsReq } from '../api/statistics';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import isMobile from 'ismobilejs';
import { isAndroidNative } from '../utils/deviceUtils';

/**
 * 统计管理器
 */
class AnalyticsManager {
  /**
   * 注册成功
   */
  static registerSuccess(userObj = null) {
    console.log('[analytics]: registerSuccess');

    // kwai注册
    if (this.kwaiPixelId) {
      let kwaiPixelReport = false;
      const kwaiPixelCallback = () => {
        if (window.kwaiq) {
          const instance = window.kwaiq.instance(this.kwaiPixelId);
          instance.track('completeRegistration');
          kwaiPixelReport = true;
          console.error('kwaiq:completeRegistration');
        }
      };
      const conditionFunc = () => {
        return kwaiPixelReport == true;
      };
      executeWithCondition(kwaiPixelCallback, conditionFunc, 300);
    }
    // adjust注册
    if (window.android && window.android.onEventJs) {
      //官方包只调用一次，因为官方包没有做参数处理
      if (isAndroidNative()) {
        window.android.onEventJs('register_success');
      } else {
        window.android.onEventJs('register_success');
        window.android.onEventJs('register');
      }
    }
    //adjust注册（渠道专用）
    if (window.Android && window.Android.onEvent) {
      window.Android.onEvent('register', null);
    }
    // adjust注册 iOS专用
    this.adjustEventToIOS({ event: 'register_success' });
    this.adjustEventToIOS({ event: 'register' });

    // af注册
    recordAFEvent('af_complete_registration');

    // gtag注册
    if (window.gtag) {
      window.gtag('event', 'sign_up', {
        method: 'Google',
      });
    }

    //facebook注册
    if (window.fbq) {
      window.fbq('track', 'CompleteRegistration');
    }

    // tiktok注册
    if (window.ttq) {
      window.ttq.track('CompleteRegistration');
    }

    // bigo注册
    if (window.bge) {
      window.bge('event', 'ec_register', { configId: this.bigoPixelId });
    }

    //dataLayer 注册
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'gtm_sign_up' });
    }

    // stat server
    this.sendLog2Server(EventIds.register, userObj);
  }

  /**
   * 登录成功
   */
  static loginSuccess() {
    console.log('[analytics]: loginSuccess');
    // af登录
    recordAFEvent('af_login');

    // gtag登录
    if (window.gtag) {
      window.gtag('event', 'login', {
        method: 'Google',
      });
    }
  }

  /**
   * 充值成功
   * @param {string} currency       充值币种
   * @param {number} amount         充值金额
   * @param {boolean} first_charge   是否首充
   */
  static rechargeSuccess({ currency, amount, first_charge }) {
    console.log('[analytics]: rechargeSuccess', currency, amount, first_charge);
    if (currency && amount) {
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Pay_Success);
      const data = { currency: currency, value: amount };

      // adjust充值和首充
      if (window.android && window.android.onEventJsRecharge) {
        if (isAndroidNative()) {
          window.android.onEventJsRecharge('recharge_success');
        } else {
          window.android.onEventJsRecharge('recharge_success');
          window.android.onEventJsRecharge('deposit');
        }
        if (first_charge && window.android.onEventJsFirstRecharge) {
          if (isAndroidNative()) {
            window.android.onEventJsFirstRecharge('first_recharge_success');
          } else {
            window.android.onEventJsFirstRecharge('first_recharge_success');
            window.android.onEventJsFirstRecharge('firstDepositArrival');
          }
        }
      }
      // adjust首充 (渠道专用)
      if (first_charge && window.Android && window.Android.onPurchase) {
        window.Android.onPurchase(amount, currency, '');
      }
      // adjust充值和首充 iOS专用
      this.adjustEventToIOS({ event: 'recharge_success', ...data });
      this.adjustEventToIOS({ event: 'deposit', ...data });
      if (first_charge) {
        this.adjustEventToIOS({ event: 'first_recharge_success', ...data });
        this.adjustEventToIOS({ event: 'firstDepositArrival ', ...data });
      }

      // af充值和首充
      recordAFEvent('af_recharge', {
        af_revenue: amount,
        af_currency: currency,
      });
      if (first_charge) {
        recordAFEvent('af_purchase', {
          af_revenue: amount,
          af_currency: currency,
        });
      }

      // gtag充值和首充
      if (window.gtag) {
        window.gtag('event', 'purchase', data);
        if (first_charge) {
          window.gtag('event', 'add_to_cart', data);
        }
      }

      // facebook充值和首充
      if (window.fbq) {
        window.fbq('track', 'Purchase', data);
        if (first_charge) {
          window.fbq('trackCustom', 'firstDepositArrival', data);
          window.fbq('track', 'AddToCart', data);
        }
      }

      // kwai充值和首充
      if (window.kwaiq && this.kwaiPixelId) {
        let instance = window.kwaiq.instance(this.kwaiPixelId);
        instance.track('purchase', data);
        if (first_charge) {
          // TODO：没有标准的首充事件，加购代替首充
          instance.track('addToCart', data);
          instance.track('firstDeposit', data);
        }
      }

      // tiktok充值和首充
      if (window.ttq) {
        window.ttq.track('CompletePayment', data);
        if (first_charge) {
          // TODO：没有标准的首充事件，加购代替首充
          window.ttq.track('AddToCart', data);
        }
      }

      // bigo充值和首充
      if (window.bge) {
        window.bge('event', 'ec_purchase', { configId: this.bigoPixelId });
        if (first_charge) {
          // TODO：没有标准的首充事件，加购代替首充
          window.bge('event', 'ec_add_cart', { configId: this.bigoPixelId });
        }
      }

      //dataLayer充值
      if (window.dataLayer && first_charge) {
        window.dataLayer.push({ event: 'gtm_add_to_cart' });
      }

      // stat server
      this.sendLog2Server(EventIds.recharge);
      if (first_charge) {
        this.sendLog2Server(EventIds.firstRecharge);
      }
    }
  }

  // 发送log到stat server
  static sendLog2Server(eventId, userObjInfo = null) {
    const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig', '');
    const userObj = userObjInfo || get(store.getState(), 'user.userObj', '');
    const parmas = {
      event_id: eventId,
      event_type: this.eventType,
      cid: websiteInitConfig.system_config.cid,
      uid: userObj.uid,
      channel_id: userObj.channel_id,
      sub_channel_id: userObj.sub_channel_id,
      is_prod: isEnvProduction() ? '1' : '0',
      domain: location.hostname,
      pixel_id: this.kwaiPixelId,
    };
    console.log('[analytics]: send stat', parmas);
    analyticsReq(parmas).then((res) => {
      if (res.code == 0) {
        console.log('[analytics]: stat sendlog ok');
      }
    });
  }

  // 发送log到stat server
  static sendExtraInfoLog2Server(eventId, extra_info = null) {
    const parmas = {
      event_id: eventId,
      extra_info: extra_info,
    };
    console.log('[analytics]: send stat', parmas);
    analyticsReq(parmas).then((res) => {
      if (res.code == 0) {
        console.log('[analytics]: stat sendlog ok');
      }
    });
  }

  // 发送adjust事件到IOS
  static adjustEventToIOS(data) {
    try {
      window.webkit.messageHandlers.adjustEventJs.postMessage(data);
    } catch (error) {
      console.log('[analytics]: adjust postMessage to iOS', data);
    }
  }

  static get kwaiPixelId() {
    return get(store.getState(), 'user.kwaiPixelId', '');
  }

  static get bigoPixelId() {
    return get(store.getState(), 'user.bigoPixelId', '');
  }

  static get uid() {
    return get(store.getState(), 'user.userObj.uid', '');
  }

  static get cid() {
    return get(store.getState(), 'app.websiteInitConfig.system_config.cid', '');
  }

  static get channel_id() {
    return get(store.getState(), 'user.userObj.channel_id', '');
  }

  static get sub_channel_id() {
    return get(store.getState(), 'user.userObj.sub_channel_id', '');
  }

  // 事件类型 多个事件使用,分割
  static get eventType() {
    let eventTypeList = [];
    if (window.android || window.Android) {
      eventTypeList.push(EventTypes.adjust);
    }
    if (window.fbq) {
      eventTypeList.push(EventTypes.facebook);
    }
    if (window.kwaiq && this.kwaiPixelId) {
      eventTypeList.push(EventTypes.kwai);
    }
    if (window.ttq) {
      eventTypeList.push(EventTypes.tiktok);
    }

    return eventTypeList.join(',');
  }

  //firebase 打点
  static FirebaseLogEvent(key, json = '') {
    if (window.$analytics) {
      try {
        const analytics = getAnalytics();
        logEvent(analytics, key, {
          bra88_uid: this.uid,
          bra88_cid: this.cid,
          bra88_channel: this.channel_id,
          bra88_sub_channel: this.sub_channel_id,
          bra88_data: json,
        });
      } catch (error) {
        console.log('firebase error:', error);
      }
    }
  }

  //设置用户信息及相关属性
  static FirebaseSetUserInfo(userObj) {
    if (window.$analytics) {
      try {
        const analytics = getAnalytics();
        const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig', '');
        if (userObj) {
          setUserId(analytics, userObj.uid);
          setUserProperties(analytics, {
            bra88_uid: userObj.uid,
            bra88_cid: websiteInitConfig.system_config.cid,
            bra88_channel: userObj.channel_id,
            bra88_sub_channel: userObj.sub_channel_id,
          });
        } else {
          setUserProperties(analytics, {
            bra88_cid: websiteInitConfig.system_config.cid,
          });
        }
      } catch (error) {
        console.log('firebase error:', error);
      }
    }
  }
}

// af事件
const recordAFEvent = (eventName, eventValue = {}) => {
  try {
    // 添加uid
    eventValue.af_uid = AnalyticsManager.uid;
    const url = 'af-event://inappevent?eventName=' + eventName + '&eventValue=' + JSON.stringify(eventValue);
    console.log('[analytics]:', url);
    if (isMobile().android.device) {
      // Android
      window.jsBridge?.postMessage(eventName, JSON.stringify(eventValue));
    } else if (isMobile().apple.device) {
      // iOS
      let iframe = document.createElement('IFRAME');
      iframe.setAttribute('src', url);
      document.documentElement.appendChild(iframe);
      iframe.parentNode.removeChild(iframe);
      iframe = null;
    }
  } catch (error) {
    console.log('[analytics]: recordAFEvent error');
  }
};

// 事件ids
export const EventIds = {
  register: '1',
  recharge: '2',
  firstRecharge: '3',
  login: '4',
  depositQrCodeErr: '5',
  qrCodeCopyError: '6',
};

// 事件types
const EventTypes = {
  adjust: '1',
  facebook: '2',
  kwai: '3',
  tiktok: '4',
};

//firebase 事件类型
export const FirebaseEvents = {
  Popup_Click_CloseRegLogButton: 'Popup_Click_CloseRegLogButton', //点击_注册/登录窗口关闭按钮
  Popup_Click_RegPage: 'Popup_Click_RegPage', //点击_Register页签
  Popup_Click_LogPage: 'Popup_Click_LogPage', //点击_Login页签
  Popup_Click_CloseEventButton: 'Popup_Click_CloseEventButton', //点击_关闭活动弹窗
  Popup_Click_Event1: 'Popup_Click_Event1', //点击_活动弹窗1
  Popup_Click_Event2: 'Popup_Click_Event2', //点击_活动弹窗2
  Popup_Click_RegPage_CreateAccount: 'Popup_Click_RegPage_CreateAccount', //点击_Reg页签注册
  Popup_Click_RegPage_Login: 'Popup_Click_RegPage_Login', //点击_Reg页签登录
  Popup_Click_LogPage_Register: 'Popup_Click_LogPage_Register', //点击_Login页签注册
  Popup_Click_LogPage_Login: 'Popup_Click_LogPage_Login', //点击_Login页签登录
  Popup_Click_Google_Login: 'Popup_Click_Google_Login', //点击_Google登录选项
  Popup_Click_TG_Login: 'Popup_Click_TG_Login', //点击_TG登录选项
  Popup_Click_AnnouncementButton: 'Popup_Click_AnnouncementButton', //点击_公告弹窗
  Popup_Click_CloseAnnouncementButton: 'Popup_Click_CloseAnnouncementButton', //点击_关闭公告弹窗
  Popup_Click_CashRainButton: 'Popup_Click_CashRainButton', //点击_红包雨活动弹窗
  Popup_Click_CloseCashRainButton: 'Popup_Click_CloseCashRainButton', //点击_关闭红包雨活动弹窗
  Popup_Click_SlidingButton: 'Popup_Click_SlidingButton', //点击_轮播弹窗
  Popup_Click_CloseSlidingButton: 'Popup_Click_CloseSlidingButton', //点击_关闭轮播弹窗
  Popup_Click_ContinueReg: 'Popup_Click_ContinueReg', //点击_继续注册
  Popup_Click_CancelReg: 'Popup_Click_CancelReg', //点击_取消注册
  Popup_Click_CloseReg: 'Popup_Click_CloseReg', //点击_关闭此窗口
  Reg_Success: 'Reg_Success', //提示_注册成功
  Reg_Fail: 'Reg_Fail', //提示_注册失败,
  Log_Success: 'Log_Success', //提示_登录成功
  Log_Fail: 'Log_Fail', //提示_登录失败
  Pay_Success: 'Pay_Success', //提示_充值成功
  Pay_Fail: 'Pay_Fail', //提示_充值失败
  WB_Click_CloseButton: 'WB_Click_CloseButton', //点击_WB关闭按钮
  WB_Click_ClaimButton: 'WB_Click_ClaimButton', //点击_WB领取按钮,
  WB_Click_ClaimBonus_CloseButton: 'WB_Click_ClaimBonus_CloseButton', //点击_WB领取页关闭按钮
  WB_Click_ClaimBonus_GoButton: 'WB_Click_ClaimBonus_GoButton', //点击_WB领取页Go按钮
  WB_Click_Depositpage: 'WB_Click_Depositpage', //点击_Deposit页签
  WB_Click_DepositButton: 'WB_Click_DepositButton', //点击_充值页Pay按钮
  WB_Click_WithdrawPage: 'WB_Click_WithdrawPage', //点击_Withdraw页签
  WB_Click_WithdrawButton: 'WB_Click_WithdrawButton', //点击_充值页Withdraw按钮
  WB_Click_PayOption1: 'WB_Click_PayOption1', //点击_充值选项1
  WB_Click_PayOption2: 'WB_Click_PayOption2', //点击_充值选项2
  WB_Click_PayOption3: 'WB_Click_PayOption3', //点击_充值选项3
  WB_Click_PayOption4: 'WB_Click_PayOption4', //点击_充值选项4
  WB_Click_DepositPage_CloseButton: 'WB_Click_DepositPage_CloseButton', //点击_关闭充值页按钮
  WB_Click_PaymentPage_CloseButton: 'WB_Click_PaymentPage_CloseButton', //点击_关闭付款页按钮
  MP_Click_DownloadButton: 'MP_Click_DownloadButton', //点击_下载按钮
  MP_Click_InstallButton: 'MP_Click_InstallButton', //点击_安装按钮
  MP_Click_AppInstallButton: 'MP_Click_AppInstallButton', //点击_app安装按钮
  MP_Click_AddHomeScreenButton: 'MP_Click_AddHomeScreenButton', //点击_添加主屏幕按钮
  MP_Click_CloseDownloadButton: 'MP_Click_CloseDownloadButton', //点击_关闭下载窗口按钮
  MP_Click_PWAButton: 'MP_Click_PWAButton', //点击_PWA
  MP_Click_RegisterButton: 'MP_Click_RegisterButton', //点击_注册按钮
  MP_Click_LoginButton: 'MP_Click_LoginButton', //点击_登录按钮
  MP_Click_DepositButton: 'MP_Click_DepositButton', //点击_充值按钮
  MP_Click_SideMenuButton: 'MP_Click_SideMenuButton', //点击_侧边栏按钮
  SM_Click_HomeButton: 'SM_Click_HomeButton', //点击_Home按钮
  SM_Click_EventBonusButton: 'SM_Click_EventBonusButton', //点击_EventBonus按钮
  SM_Click_ReferralPlanButton: 'SM_Click_ReferralPlanButton', //点击_ReferralPlan按钮
  SM_Click_VipBonusButton: 'SM_Click_VipBonusButton', // 点击_VIPBonus按钮
  SM_Click_PromotionButton: 'SM_Click_PromotionButton', //点击_Promotion按钮
  SM_Click_LogoutButton: 'SM_Click_LogoutButton', //点击_Logout按钮
  MP_Click_Slide: 'MP_Click_Slide', //点击_轮播图
  MP_Click_SearchButton: 'MP_Click_SearchButton', //点击_搜索框
  MP_Click_All: 'MP_Click_All', //点击_All
  MP_Click_: 'MP_Click_', // 点击分类
  MP_Click_Lobby: 'MP_Click_Lobby', //点击_Lobby
  MP_Click_Recently: 'MP_Click_Recently', //点击_Recently
  MP_Click_Favorite: 'MP_Click_Favorite', //点击_Favorite
  MP_Click_WB_Floating: 'MP_Click_WB_Floating', //首页-WB悬浮窗
  MP_Click_GiftMoney_Floating: 'MP_Click_GiftMoney_Floating', //点击_GiftMoney悬浮窗
  MP_Click_CashRain_Floating: 'MP_Click_CashRain_Floating', //点击_红包雨悬浮窗
  MP_Click_FloatingWindow: 'MP_Click_FloatingWindow', //点击_客服悬浮窗
  MP_Click_Buttom_Profile: 'MP_Click_Buttom_Profile', //点击_个人中心
  MP_Click_Buttom_Email: 'MP_Click_Buttom_Email', //点击_收件箱
  MP_Click_Buttom_Promo: 'MP_Click_Buttom_Promo', //点击_促销
  MP_Click_Buttom_Referral: 'MP_Click_Buttom_Referral', //点击_邀请活动
  MP_Click_Buttom_Home: 'MP_Click_Buttom_Home', //点击_主页
  MP_Click_Button_Service: 'MP_Click_Button_Service', //点击_客服
  MP_Click_Button_Wallet: 'MP_Click_Button_Wallet', //点击_钱包
  MP_Click_Button_Sport: 'MP_Click_Button_Sport', //点击_体育
  AC_LeaveWeb: 'AC_LeaveWeb', //行为_离开页面
  MP_Click_Wheel: 'MP_Click_Wheel', //点击_轮盘悬浮窗
  Wheel_Go: 'Wheel_Go', //点击_轮盘go按钮
  Wheel_Claim: 'Wheel_Claim', //点击_轮盘claim按钮
  Wheel_GoMission: 'Wheel_GoMission', //点击_轮盘任务go按钮
  Wheel_ClaimRewards: 'Wheel_ClaimRewards', //点击_轮盘领取奖励按钮
  Wheel_InAppClaimRewards: 'Wheel_InAppClaimRewards', //点击_轮盘app领取按钮
  CashRain_Deposit: 'CashRain_Deposit', //点击_充值按钮
  InGame_Click_DepositButton: 'InGame_Click_DepositButton', //点击_游戏内充值按钮
  Wheel_Close: 'Wheel_Close', //点击_轮盘关闭按钮
  Wheel_Close2: 'Wheel_Close2', //点击_领取页关闭按钮2
  Wheel_CloseMission: 'Wheel_CloseMission', //点击_任务页关闭按钮
  RegWindow_Popup: 'RegWindow_Popup', //注册弹窗弹出
  Click_RegToDepositButton: 'Click_RegToDepositButton', //点击_注册接充值按钮
  Click_CloseRegToDepositButton: 'Click_CloseRegToDepositButton', //点击_关闭注册接充值按钮
  WheelWindow_Popup: 'WheelWindow_Popup', // 引导轮盘弹窗弹出
  CashBackWindow_Popup: 'CashBackWindow_Popup', //CashBack弹窗弹出
  CashBack_Claim: 'CashBack_Claim', //CashBackClaim进入按钮
  CashBack_Invite: 'CashBack_Invite', //CashBack_Invite按钮
  CashBack_Howtoget: 'CashBack_Howtoget', //CashBack_Howtoget按钮
  CashBack_Copy: 'CashBack_Copy', //CashBack_复制按钮
  ReferralBonusWindow_Popup: 'ReferralBonusWindow_Popup', //ReferralBonus弹窗弹出
  ReferralBonus_Claim: 'ReferralBonus_Claim', //ReferralBonusClaim进入按钮
  ReferralBonu_Copy: 'ReferralBonu_Copy', //ReferralBonu_复制按钮
  WB_Click_PayOption_: 'WB_Click_PayOption_', //充值行为的前缀
  Mb_Click_Window_Details: 'Mb_Click_Window_Details', //神秘彩金 点击_弹窗中的details按钮
  Mb_Click_Window_Close: 'Mb_Click_Window_Close', //神秘彩金 点击_弹窗中的关闭按钮
  Mb_Click_Slideshow: 'Mb_Click_Slideshow', //神秘彩金 点击_轮播图图标
  Mb_Click_Signup_Confirm: 'Mb_Click_Signup_Confirm', //神秘彩金 点击_报名成功弹窗的确定按钮
  Mb_Click_Signup_Cancel: 'Mb_Click_Signup_Cancel', //神秘彩金 点击_报名成功弹窗的取消按钮
  Mb_Click_Signup_Close: 'Mb_Click_Signup_Close', //神秘彩金 点击_报名成功弹窗的关闭按钮
  Mb_Click_Window_Deposit1: 'Mb_Click_Window_Deposit1', //神秘彩金 点击_未报名弹窗中充值按钮
  Mb_Click_Window_Deposit2: 'Mb_Click_Window_Deposit2', //神秘彩金 点击_已报名弹窗中充值按钮
  Mb_Click_Details_Deposit: 'Mb_Click_Details_Deposit', //神秘彩金 点击_活动详情页中充值按钮
};

export default AnalyticsManager;
