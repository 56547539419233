import { Tabs } from 'react-vant';
import HeaderBack from '../../../../components/headerBack';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import { toLocalePath } from '../../../../utils/localePath';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import ScrollbarContent from '../../../../components/scrollbarContent';
import SearchInput from './components/searchInput';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useEffect, useRef, useState } from 'react';
import { GameListReq } from '../../../../api';
import { useTranslation } from 'react-i18next';
import FavoritosPng from '../../../../assets/img/favoritos.png';
import PesquisarPng from '../../../../assets/img/pesquisar.png';
import PopularPng from '../../../../assets/img/popular.png';
import RecentePng from '../../../../assets/img/recente.png';
import SearchGame from './components/searchGame';
import PopularGame from './components/popularGame';
import RecentGame from './components/recentGame';
import FavoriteGame from './components/favoriteGame';

const Dtemplate003 = () => {
  const pageSize = 16;
  const tabsRef = useRef(null);
  const checkboxRef = useRef(null);
  const { t } = useTranslation();
  const { h } = useWindowHeight(0.96);
  const navigate = useGlobalNavigate();
  const [lineWidth, setLineWidth] = useState(0);
  const [list, setlist] = useStateIfMounted([]);
  const defaultActive = 1;
  const [currentIndex, setCurrentIndex] = useState(defaultActive);
  const [loading, setloading] = useStateIfMounted(false);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [total, setTotal] = useStateIfMounted(0);

  const [params, setParams] = useStateIfMounted({
    source: '',
    sourceName: 'ALL',
    category: '',
    search: '',
    page: 1,
    page_size: pageSize,
    isInput: true,
  });

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1, isInput: false });
  };

  const updateLineWidth = () => {
    const tabsElement = tabsRef.current;
    if (!tabsElement) return;

    const lineWidth = (tabsElement.offsetWidth - 20) / 4;
    setLineWidth(lineWidth);
  };

  useEffect(() => {
    updateLineWidth();
  }, []);

  const handleSearch = () => {
    if (params.search != '') {
      setParams({ ...params, page: 1, isInput: false });
    }
  };

  const handleBack = () => {
    navigate(toLocalePath('/'));
  };

  const handleClick = (e) => {
    const _index = e.index;
    setCurrentIndex(_index);
  };

  const getGameList = async () => {
    if (params.page == 1) {
      setloading(true);
    } else {
      setLoadingMore(true);
    }

    try {
      const res = await GameListReq(params);
      if (res.code == 0) {
        const result = res.result;

        if (params.page == 1) {
          setlist(result.list);
        } else {
          const list2 = list.concat(result.list);
          setlist(list2);
        }

        const _pageCount = Math.ceil(result.pages.count / pageSize);
        setTotal(result.pages.count);
        if (params.page < _pageCount) {
          setShowLoadingMore(true);
        } else {
          setShowLoadingMore(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setloading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (!params.isInput) {
      if (checkboxRef.current) {
        setCurrentIndex(0);
        checkboxRef.current.scrollTo(0);
      }
      getGameList();
    }
  }, [params]);

  const tabsList = [
    {
      name: t('gamelist.pesquisar'),
      img: PesquisarPng,
    },
    {
      name: t('gamelist.popular'),
      img: PopularPng,
    },
    {
      name: t('home.category_003'),
      img: RecentePng,
    },

    {
      name: t('home.category_001'),
      img: FavoritosPng,
    },
  ];

  return (
    <>
      <HeaderBack title={t('gamelist.pesquisar')} titleClassName="!text-[.36rem] !font-[600]" onClick={handleBack} />

      <ScrollbarContent className="w-full pt-[.16rem]" style={{ height: `${h}rem` }}>
        <div className="px-[.2rem]">
          <SearchInput
            className="!h-[.8rem] !rounded-[.12rem]"
            placeholder={'Buscar juegos'}
            value={params.search}
            onClickSearch={handleSearch}
            onSearchChange={(ev) => {
              setParams({ ...params, search: ev.target.value, isInput: true });
            }}
          />
        </div>

        <div
          className="mt-[.16rem] px-[.2rem] w-full h-[1.04rem] game_search-tabs border-b border-[var(--colour-6)]"
          ref={tabsRef}
        >
          <Tabs
            ref={checkboxRef}
            defaultActive={defaultActive}
            lineWidth={lineWidth}
            onClickTab={handleClick}
            background="var(--colour-68)"
            titleActiveColor="var(--colour-13)"
            titleInactiveColor="var(--colour-14)"
          >
            {tabsList.map((item, index) => (
              <Tabs.TabPane
                key={index}
                title={
                  <div className="flex items-center">
                    <img className="w-[.4rem] not-drag" src={item.img} alt="" />
                    <div
                      className={` text-[.24rem] font-[600] ${index == currentIndex ? 'text-[var(--colour-13)]' : 'text-[var(--colour-14)]'}`}
                    >
                      {item.name}
                    </div>
                  </div>
                }
              ></Tabs.TabPane>
            ))}
          </Tabs>
        </div>

        <div className="mt-[.14rem] w-full px-[.16rem]">
          {currentIndex == 0 ? (
            <SearchGame
              list={list}
              total={total}
              loading={loading}
              page={params.page}
              loadingMore={loadingMore}
              handleLoadMore={handleLoadMore}
              showLoadingMore={showLoadingMore}
            />
          ) : null}

          {currentIndex == 1 ? <PopularGame /> : null}

          {currentIndex == 2 ? <RecentGame /> : null}

          {currentIndex == 3 ? <FavoriteGame /> : null}
        </div>
      </ScrollbarContent>
    </>
  );
};

export default Dtemplate003;
