import BaseTemplate from '../../baseTemplate';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DrawerMenu from '../../../menu/drawerMenu/normal/template001';
import { toNoLocalePath } from '../../../../utils/localePath';
import { useEffect, useState } from 'react';
import ScrollbarContent from '../../../scrollbarContent';
import Footer from '../../../../components/footer';

export default function NTemplate002({ children }) {
  const { drawerMenuVisible, showHeaderAndFooter } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      showHeaderAndFooter: state.app.showHeaderAndFooter,
    }),
    shallowEqual,
  );
  const { pathname } = useLocation();
  const [drawerLeftMenuVisible, setDrawerLeftMenuVisible] = useState(false); //侧导航是否显示 ，默认关闭
  useEffect(() => {
    if (drawerMenuVisible.visible || toNoLocalePath(pathname).indexOf('/sport') > -1) {
      setDrawerLeftMenuVisible(false);
    } else {
      setDrawerLeftMenuVisible(true);
    }
  }, [drawerMenuVisible, pathname]);

  return (
    <BaseTemplate>
      <DrawerMenu drawerLeftMenuVisible={drawerLeftMenuVisible} />
      <main className="h-[100%]">
        {children}
        {showHeaderAndFooter ? <Footer /> : null}
      </main>
    </BaseTemplate>
  );
}
