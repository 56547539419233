import copy from 'copy-to-clipboard';
import { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ShareManager from '../../../../../manager/shareManager';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../../redux/reducers/appSlice';
import Icon, { IconName } from '../../../../icon';
import { get } from 'lodash';
import { isLogin, getReferralLink, isShareString, getShareIconName } from '../../../../../utils/helper';
import QRCode from '../../../../../components/qrCode';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import cn from 'classnames';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import { useDeepCompareEffect } from 'ahooks';

const QRCodeBox = ({ qrCodeValue }) => {
  const { t } = useTranslation();

  const {} = useSelector((state) => ({}), shallowEqual);

  const qrCodeRef = useRef(null);

  const handleClick = () => {
    try {
      domtoimage.toPng(qrCodeRef.current).then((imgUrl) => {
        saveAs(imgUrl, 'qr-code.png');
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={cn(
        'border rounded-normal cursor-pointer px-[.12rem] py-[.16rem] border-[var(--colour-6)] bg-[var(--colour-35)]',
      )}
      onClick={handleClick}
    >
      <div
        className={cn('bg-[white] flex items-center justify-center shrink-0 w-[1.6rem] h-[1.6rem] p-[.1rem]')}
        ref={qrCodeRef}
      >
        <QRCode value={qrCodeValue} remSize={1.5} />
      </div>
      <div
        className={cn('font-[500] leading-[1em] w-[1.6rem] break-all mt-[.12rem] text-[.24rem] text-[var(--colour-1)]')}
      >
        {t('referral.click')}
      </div>
    </div>
  );
};

function InviteLink({ money = 0, peopleNum = 0, directReportNum = 0, isAnyRunningTask = true }) {
  const shareNames = {
    WhatsappShare: 'WhatsApp',
    FacebookShare: 'Facebook',
    TelegramShare: 'Telegram',
    TwitterShare: 'Twitter',
    MoreShare: 'More',
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { inviteCode, defaultLang, referral_url, websiteInitConfig, userObj } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.userObj.invite_code', ''),
      defaultLang: state.user.defaultLang,
      referral_url: get(state, 'user.userObj.referral_url', ''),
      websiteInitConfig: state.app.websiteInitConfig,
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  const [shareList, setShareList] = useState([]);
  const [shareLink, setShareLink] = useState('');

  useEffect(() => {
    if (websiteInitConfig?.website_info?.share_method) {
      setShareList(websiteInitConfig?.website_info?.share_method);
    }
  }, [websiteInitConfig]);

  const onCopyLink = () => {
    copy(shareLink);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  const onCopyCode = () => {
    copy(inviteCode);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  const handleShare = (res) => {
    let str = res.toLowerCase();
    return ShareManager.share(str, shareLink, websiteInitConfig?.website_info?.website_desc);
  };

  const showLogin = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
  };

  useDeepCompareEffect(() => {
    setShareLink(getReferralLink());
  }, [userObj]);

  return (
    <>
      <div
        className={cn(
          'w-full rounded-normal font-[500] bg-[var(--colour-34)] text-[var(--colour-44)] p-[.2rem] text-[.24rem]',
        )}
      >
        <div className="leading-[.3rem] text-[var(--colour-48)] text-[.28rem]">
          <span className="text-[var(--colour-1)]">
            {t('referral.upTo', {
              val: money,
            })}
          </span>
          <span className="ml-[.1rem]">{t('referral.inviteForMoreRewards')}</span>
        </div>
        {userObj != null ? (
          <>
            <div className={cn('flex space-x-[.32rem] mt-[.1rem]')}>
              <div className="shrink-0">
                <QRCodeBox qrCodeValue={shareLink} />
              </div>
              <div className="flex-1">
                <div className="flex items-center justify-between text-[.2rem] mb-[.12rem]">
                  <div className="text-[var(--colour-48)]">{t('referral.yourLink')}</div>
                  {/* <span>
                    {t('referral.direct_report')} {directReportNum}
                  </span> */}
                </div>
                <div
                  className={cn(
                    'border border-[var(--colour-6)] flex rounded-normal p-[.16rem_.28rem_.16rem_.2rem] justify-between bg-[var(--colour-35)] items-center',
                  )}
                >
                  <div
                    className={cn(
                      'flex justify-start items-center overflow-x-auto overflow-y-hidden scroll_none leading-[1em] flex-1 w-[2.9rem] break-all',
                    )}
                  >
                    {shareLink}
                  </div>
                  <div
                    className={cn(
                      'flex justify-center items-center active:scale-[.95] transition-all w-[.64rem] h-[.64rem] cursor-pointer select-none shrink-0 bg-[var(--colour-1)] rounded-normal',
                    )}
                    onClick={() => {
                      onCopyLink();
                      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonu_Copy);
                    }}
                  >
                    <div className={cn('w-[.32rem] h-[.32rem] text-[var(--colour-2)]')}>
                      <Icon name={IconName.FillCopy} />
                    </div>
                  </div>
                </div>
                <div className={cn('flex py-[.16rem] justify-between p-[.16rem_.24rem_.16rem_0]')}>
                  <div
                    className={cn(
                      'flex justify-start items-center overflow-x-auto overflow-y-hidden scroll_none leading-[1em] flex-1 w-[2.9rem] break-all text-[.2rem]',
                    )}
                  >
                    <span className="">{t('referral.invitation_code')}:&nbsp;</span>
                    <span className="text-[var(--colour-48)]">{inviteCode}</span>
                  </div>
                  <div
                    className={cn(
                      'flex justify-center items-center active:scale-[.95] transition-all  w-[.64rem] h-[.64rem] cursor-pointer select-none shrink-0 bg-[var(--colour-1)] rounded-normal',
                    )}
                    onClick={() => {
                      onCopyCode();
                      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonu_Copy);
                    }}
                  >
                    <div className={cn('w-[.32rem] h-[.32rem] text-[var(--colour-2)]')}>
                      <Icon name={IconName.FillCopy} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={cn(
                'flex mt-[.24rem] space-x-[.2rem] border-b border-[var(--colour-6)] p-[0_.24rem_.2rem_.24rem]',
              )}
            >
              {shareList.map((item, index) => {
                return isShareString(item) && !ShareManager.showMoreShare ? null : (
                  <div
                    key={index}
                    onClick={() => handleShare(item)}
                    className="flex flex-col items-center space-y-[.1rem] cursor-pointer"
                  >
                    <div className={cn('flex justify-center items-center active:scale-[.95] w-[.64rem] h-[.64rem]')}>
                      <Icon name={getShareIconName(item)} />
                    </div>
                    <span className="text-[.16rem] font-[500] text-[var(--colour-36)]">{shareNames[item]}</span>
                  </div>
                );
              })}
            </div>
            <div className="text-[.24rem] font-[500] pt-[.18rem] text-[var(--colour-44)]">
              {isAnyRunningTask ? (
                <Trans
                  t={t}
                  i18nKey="referral.valid_invititation"
                  components={[<span className="text-[var(--colour-24)]" key={0}></span>]}
                  values={{
                    val: peopleNum,
                  }}
                />
              ) : (
                t('referral.all_complete')
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center mt-[.3rem]">
              <div
                className="flex justify-center items-center h-[.52rem] active:scale-[.95] transition-all select-none text-center text-[.24rem] font-[500] cursor-pointer px-[.2rem] bg-[var(--colour-1)] rounded-normal text-[var(--colour-2)]"
                onClick={showLogin}
              >
                {t('referral.CreateInviteCodeNow')}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default InviteLink;
