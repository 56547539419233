import Header from './header';
export default function Index({
  children,
  title = '',
  handleBack = () => {},
  rightTitle = '',
  handleOnRightTitle = () => {},
  headerClassName = '',
  headerTitleClassName = '',
}) {
  return (
    <div className="h-[calc(var(--app-height)-0.9rem)] bg-[var(--colour-35)] rounded-tl-[0.16rem] rounded-tr-[0.16rem] w-[100%] relative">
      <div className="absolute h-[0.2rem] w-[100%] left-[0] top-[0] rounded-tl-[0.16rem] rounded-tr-[0.16rem] border border-[var(--colour-6)] border-b-[0]"></div>
      <Header
        handleBack={handleBack}
        title={title}
        rightTitle={rightTitle}
        handleOnRightTitle={handleOnRightTitle}
        className={headerClassName}
        titleClassName={headerTitleClassName}
      />
      {children}
    </div>
  );
}
