import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { get, cloneDeep } from 'lodash';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setAutoScrollToTagGameKey,
  setReloadWebsiteInitData,
} from '../../../../redux/reducers/appSlice';
import { isLogin, removeLoadingPage, isControllableSite } from '../../../../utils/helper';
import cn from 'classnames';
import HomeSwiperBanner from '../../components/homeBanner';
import ContainerWrapper from '../../components/containerWrapper';
import HomeTagSearch from './components/tagSearch';
import HomeProvider from '../../../../components/homeProvider';
import Tournament from '../../../../components/tournament';
import CategoryGame from '../../../../components/categoryGame';
import RecentlyListAll from '../../../../components/recentlyListAll';
import HomeGameList from '../../../../components/homeGameList';
import Jackpot from '../../../../components/jackpot';
import HomeLinkBox from '../../../../components/homeLinkBox';
import useLangServerConfig from '../../../../hooks/useLangServerConfig';

export default function NTemplate001() {
  const dispatch = useDispatch();

  const isFirstLoadRef = useRef(true);
  const { defaultLang, identity, needCheckedLobby, tourRedDot, websiteInitData, websiteInitConfig, uid } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
      identity: state.user.identity,
      needCheckedLobby: state.app.needCheckedLobby,
      tourRedDot: get(state, 'app.activityStatus.activity_tournament'),
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
      uid: get(state, 'user.userObj.uid', ''),
    }),
    shallowEqual,
  );
  const recentGameListIds = localStorage.getItem(`recentGameList_${uid}`);
  const { getServerLangText } = useLangServerConfig();
  const [isTagSearchAutoScroll, setIsTagSearchAutoScroll] = useState(false); //是否需要tagSearch滚动到选中的item
  const [tagsIndex, setTagsIndex] = useState('000'); //当前游戏类型
  const [isProvider, setIsProvider] = useState(false); //是否根据provider筛选游戏
  const [providerSelect, setProviderSelect] = useState(''); //当前选中的游戏厂商
  const [isSearch, setIsSearch] = useState(false); //是否展示搜索结果
  const [searchVal, setSearchVal] = useState(''); //搜索的value值
  const [viewAllList, setViewAllList] = useState([]); //显示view按钮的类别

  const [moduleConfigList, setModuleConfigList] = useState([]); //处理完之后的排序列表
  const [bannerList, setBannerList] = useState([]); //banner数据列表

  useEffect(() => {
    if (tourRedDot == '1') {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.TourClaim, zIndex: 10000 }));
    }
  }, [tourRedDot]);

  useEffect(() => {
    if (websiteInitData && websiteInitData.template && websiteInitConfig.provider_list) {
      let data = cloneDeep(websiteInitData.template);
      const providerList = websiteInitConfig.provider_list;
      const categoryList = Array.from(websiteInitConfig.category_list || []);
      data.forEach((item, index) => {
        //tagsearch 特殊处理
        if (item.module == 'HomeTagSearch') {
          item.config = item.config.filter((item) => {
            return (
              categoryList.findIndex((category) => category.category_id == item.category_id) > -1 ||
              (!identity
                ? ['000', '002'].includes(item.category_id)
                : ['000', '001', '002', '003'].includes(item.category_id))
            );
          });

          const _viewAllList = [];
          item.config.forEach((item) => {
            _viewAllList.push(item.category_id);
          });
          setViewAllList(_viewAllList);
        }

        //homeProvider特殊处理
        if (item.module == 'HomeProvider') {
          const homeProviderConfig = [];
          Array.from(item.config.list).forEach((configItem) => {
            const providerIndex = providerList.findIndex((provider) => provider.source == configItem.source);
            if (providerIndex > -1) {
              configItem.name = providerList[providerIndex].name;
              homeProviderConfig.push(configItem);
            }
          });
          item.config.list = homeProviderConfig;
        }
        data[index] = item;
      });

      setModuleConfigList(data);
    }
    if (websiteInitData && websiteInitData.home_banner) {
      setBannerList(websiteInitData.home_banner);
    }
  }, [websiteInitData, websiteInitConfig, identity]);

  const updateList = () => {
    dispatch(setReloadWebsiteInitData());
  };

  const tagsResIndex = (res) => {
    setProviderSelect('');
    setIsProvider(false);
    setSearchVal('');
    setIsSearch(false);
    if (isLogin() && res === '000' && isFirstLoadRef.current == false) {
      updateList();
    }
    isFirstLoadRef.current = false;
  };

  const homeProviderRes = (res) => {
    setTagsIndex('002');
    setSearchVal('');
    setIsSearch(false);
    setProviderSelect(res);
    setIsProvider(true);
    setIsTagSearchAutoScroll(true);
  };

  const tagsSearchRes = (res) => {
    setTagsIndex('002');
    setProviderSelect('');
    setIsProvider(false);
    setSearchVal(res);
    setIsSearch(true);
    setIsTagSearchAutoScroll(true);
  };

  useEffect(() => {
    if (!identity) {
      if (tagsIndex == '001' || tagsIndex == '003') {
        setTagsIndex('000');
        tagsResIndex('000');
      }
    }
  }, [identity]);

  useEffect(() => {
    if (needCheckedLobby.key != 0) {
      tagsResIndex('000');
      setTagsIndex('000');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [needCheckedLobby]);

  const handleCategoryGameViewAll = (id) => {
    dispatch(setAutoScrollToTagGameKey(id));
  };

  useEffect(() => {
    if (isTagSearchAutoScroll) {
      setIsTagSearchAutoScroll(false);
      dispatch(setAutoScrollToTagGameKey(tagsIndex));
    }
  }, [tagsIndex, isTagSearchAutoScroll]);

  const handleClickGameCategory = (catetoryId) => {
    setTagsIndex(catetoryId);
    tagsResIndex(catetoryId);
  };

  return (
    <>
      <div className={cn('flex flex-col space-y-[.24rem] pt-[.14rem]')}>
        <div>
          {bannerList.length > 0 ? (
            websiteInitConfig?.website_info?.website_banner_type == '3' ? (
              <div className="flex flex-col space-y-[.24rem]">
                <HomeSwiperBanner bannerType="3" swiperList={bannerList} />
              </div>
            ) : (
              <ContainerWrapper ispb={false} isborderBottom={false}>
                <HomeSwiperBanner
                  bannerType={websiteInitConfig?.website_info?.website_banner_type}
                  swiperList={bannerList}
                />
              </ContainerWrapper>
            )
          ) : (
            <></>
          )}
          <div className={cn('flex flex-col space-y-[.5rem] py-[.24rem]')}>
            {moduleConfigList.map((item, index) => {
              switch (item.module) {
                case 'HomeTagSearch':
                  return item.config && item.config.length > 0 ? (
                    <ContainerWrapper key={index} ispb={false} isborderBottom={false}>
                      <HomeTagSearch
                        tagsArr={item.config}
                        tagsIndex={tagsIndex}
                        setTagsIndex={setTagsIndex}
                        tagsResIndex={tagsResIndex}
                        tagsSearchRes={tagsSearchRes}
                        handleCategoryGameViewAll={handleCategoryGameViewAll}
                      />
                    </ContainerWrapper>
                  ) : null;

                case 'HomeProvider':
                  return tagsIndex === '000' && item.config.list.length > 0 ? (
                    <ContainerWrapper key={index}>
                      <HomeProvider
                        config={item.config}
                        list={item.config.list || []}
                        homeProviderRes={homeProviderRes}
                      />
                    </ContainerWrapper>
                  ) : null;
                case 'Tournament':
                  return tagsIndex === '000' && !isProvider && !isSearch ? (
                    <Tournament
                      className="container px-[.32rem]"
                      key={index}
                      tourCardData={item?.data || []}
                      titleImgPath={item?.config?.icon}
                      title={getServerLangText(item?.config?.lang_key)}
                    />
                  ) : null;

                case 'CategoryGameList':
                  // {
                  /* 筛选条件: allgames && 非厂商筛选 && 非搜索结果展示*/
                  // }
                  return tagsIndex === '000' &&
                    !isProvider &&
                    !isSearch &&
                    ((item.config.category_id != '003' && item.data.length > 0) ||
                      (item.config.category_id == '003' && recentGameListIds)) ? (
                    <ContainerWrapper key={`${index}-${item.config.category_id}`}>
                      <CategoryGame
                        config={item.config}
                        list={item.data || []}
                        viewAll={viewAllList.includes(item.config.category_id)}
                        setTagsIndex={setTagsIndex}
                        tourCardData={null}
                        handleViewAllCallback={() => {
                          handleCategoryGameViewAll(item.config.category_id);
                        }}
                        homeProviderRes={homeProviderRes}
                      />
                    </ContainerWrapper>
                  ) : null;

                // case 'Jackpot':
                //   // 只在大厅显示jackpot
                //   return (
                //     ['000'].includes(tagsIndex) && (
                //       <ContainerWrapper key={index}>
                //         <div className="flex justify-center">
                //           <Jackpot isDebug={false} handleClickGameCategory={handleClickGameCategory} />
                //         </div>
                //       </ContainerWrapper>
                //     )
                //   );

                case 'HomeAd':
                  // 只在大厅显示HomeLinkBox
                  return ['000'].includes(tagsIndex) && <HomeLinkBox key={index} />;
              }
            })}
            {tagsIndex === '003' ? (
              <ContainerWrapper>
                <RecentlyListAll />
              </ContainerWrapper>
            ) : null}
            {tagsIndex !== '003' &&
            (tagsIndex !== '000' ||
              (tagsIndex === '002' && isProvider && !isSearch) ||
              (tagsIndex === '002' && !isProvider && isSearch)) ? (
              <ContainerWrapper>
                <HomeGameList
                  tagsIndex={tagsIndex}
                  providerSelect={providerSelect}
                  isProvider={isProvider}
                  searchVal={searchVal}
                  isSearch={isSearch}
                  setTagsIndex={setTagsIndex}
                />
              </ContainerWrapper>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
