import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import LimitClickWrapper from '../../../../../components/limitClickWrapper';

const PlayButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <LimitClickWrapper
      className={cn(
        'w-[2.68rem] h-[.7rem] rounded-[.1rem] flex items-center justify-center text-[.28rem] font-[500] text-[var(--colour-2)] mx-auto mt-[.22rem] mb-[.28rem] bg-[var(--colour-1)] cursor-pointer',
        {},
      )}
      onClick={onClick}
    >
      {t('mystery_bonus.go_to_play')}
    </LimitClickWrapper>
  );
};

export default PlayButton;
