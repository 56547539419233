import Icon, { IconName } from '../../../icon';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../redux/reducers/appSlice';

const RedeemEntrance = ({ handleLinkClick = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  const click = () => {
    handleLinkClick();

    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Redeem,
      }),
    );
  };

  return (
    <div
      className={`w-full h-[.86rem] rounded-normal mb-[.16rem] px-[.32rem] flex items-center text-[.24rem] font-[500] text-[var(--colour-14)] cursor-pointer ${websiteInitConfig?.website_info.website_theme == 'theme-15' ? 'bg-menu-default' : ' bg-[var(--colour-16)]'}`}
      onClick={click}
    >
      <div className="text-[var(--colour-14)] mr-[.32rem] w-[.48rem] h-[.48rem] shrink-0">
        <Icon name={IconName.Redeem} />
      </div>
      <div className="leading-[1]">{t('redeem.redeem_code')}</div>
    </div>
  );
};

export default RedeemEntrance;
