import { useEffect, useState } from 'react';
import TippyPopBox, { Placement } from './tippyPopBox';
import { DatetimePicker } from 'react-vant';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DatetimePickerBox = ({
  mode = 'range',
  minDate = new Date(new Date().getTime() - 40 * 24 * 60 * 60 * 1000),
  maxDate = new Date(),
  singleCheckClassName = '',
  date = new Date(),
  onChange = () => {},
  rangeCheckClassName = '',
  format = 'DD/MM/YYYY',
  startDate = new Date(),
  endMinDate = startDate,
  endMaxDate = new Date(),
  endDate = new Date(),
  onChangeStartDate = () => {},
  onChangeEndDate = () => {},
  onSubmit = () => {},
}) => {
  return (
    <>
      {mode == 'range' ? (
        <RangeDatetimePicker
          format={format}
          minDate={minDate}
          maxDate={maxDate}
          endMinDate={endMinDate}
          endMaxDate={endMaxDate}
          startDate={startDate}
          endDate={endDate}
          onSubmit={onSubmit}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          rangeCheckClassName={rangeCheckClassName}
        />
      ) : (
        <SingleDatetimePicker
          format={format}
          minDate={minDate}
          maxDate={maxDate}
          onSubmit={onSubmit}
          singleCheckClassName={singleCheckClassName}
          date={date}
          onChange={onChange}
        />
      )}
    </>
  );
};

const RangeDatetimePicker = ({
  minDate,
  maxDate,
  format,
  rangeCheckClassName,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  onSubmit,
  endMinDate,
  endMaxDate,
}) => {
  const { t } = useTranslation();

  const [visableDate, setVisableDate] = useState(false);
  const h = 0.64 + 'rem';
  const zIndex = '99';
  const handleClose = () => {
    setVisableDate(false);
  };

  useEffect(() => {
    const NewstartDate = new Date(startDate).getTime();
    const NewendDate = new Date(endDate).getTime();

    if (NewstartDate > NewendDate) {
      onChangeEndDate(startDate);
    }
  }, [startDate]);

  return (
    <>
      <TippyPopBox
        zIndex={zIndex}
        visible={visableDate}
        onClickOutside={handleClose}
        offset={[-50, 1]}
        placement={Placement.bottomEnd}
        popChildren={
          <div className="w-[6.98rem] h-[4.8rem] rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-35)] datePicker-box-shadow mx-auto flex items-center">
            <div className="w-[3.48rem] h-full py-[.16rem] flex flex-col items-center relative justify-between">
              <div className="text-[var(--colour-40)] text-[.24rem] font-[700] ">{t('refer.startdate')}</div>

              <div className="w-full mt-[.3rem] overflow-hidden">
                <DatetimePicker
                  itemHeight={h}
                  className="datePicker-box rangeDate"
                  type="date"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={startDate}
                  onChange={onChangeStartDate}
                />
              </div>

              <div
                className="w-[1.2rem] h-[.5rem] text-[var(--colour-3)] text-[.2rem] font-[400] border border-[--colour-1] bg-[--colour-5] rounded-[.16rem] flex items-center justify-center active:scale-[.95] transition-all select-none"
                onClick={handleClose}
              >
                {t('refer.cancel')}
              </div>
            </div>

            <div className="w-[.02rem] h-[3.12rem] bg-[var(--colour-6)]"></div>

            <div className="w-[3.48rem] h-full py-[.16rem] flex flex-col items-center relative justify-between">
              <div className="text-[var(--colour-40)] text-[.24rem] font-[700]">{t('refer.enddate')}</div>
              <div className="w-full mt-[.3rem] overflow-hidden">
                <DatetimePicker
                  itemHeight={h}
                  className="datePicker-box rangeDate"
                  type="date"
                  minDate={endMinDate}
                  maxDate={endMaxDate}
                  value={endDate}
                  onChange={onChangeEndDate}
                />
              </div>

              <div
                className="w-[1.2rem] h-[.5rem] text-[var(--colour-2)] text-[.2rem] font-[400] bg-[--colour-1] rounded-[.16rem] flex items-center justify-center active:scale-[.95] transition-all select-none"
                onClick={() => {
                  onSubmit && onSubmit();
                  handleClose && handleClose();
                }}
              >
                {t('refer.confirm')}
              </div>
            </div>
          </div>
        }
      >
        <div
          onClick={() => {
            setVisableDate(!visableDate);
          }}
          className={`w-[3.6rem] h-[.5rem] flex items-center justify-center border border-[var(--colour-6)] bg-[var(-colour-5)] rounded-[.4rem] text-[var(--colour-44)] ${rangeCheckClassName}`}
        >
          <div className="text-[.2rem] font-[500]">{moment(startDate).format(`${format}`)}</div>
          <div className="mx-[.18rem]">-</div>
          <div className="text-[.2rem] font-[500]">{moment(endDate).format(`${format}`)}</div>
        </div>
      </TippyPopBox>
    </>
  );
};

const SingleDatetimePicker = ({ minDate, maxDate, singleCheckClassName, date, onChange, format, onSubmit }) => {
  const [visableDate, setVisableDate] = useState(false);
  const handleClose = () => {
    setVisableDate(false);
  };
  const { t } = useTranslation();
  const zIndex = '99';

  return (
    <>
      <TippyPopBox
        zIndex={zIndex}
        visible={visableDate}
        onClickOutside={handleClose}
        offset={[-50, 1]}
        placement={Placement.bottomEnd}
        popChildren={
          <div className="w-[3.36rem] h-[4.8rem] rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-35)] datePicker-box-shadow mx-auto flex items-center">
            <div className="w-[2.48rem] h-full py-[.16rem] mx-auto relative flex flex-col justify-between items-center">
              <div className="text-[var(--colour-40)] text-[.24rem] font-[700]">{t('refer.datetime')}</div>

              <div className="w-full mt-[.3rem] overflow-hidden">
                <DatetimePicker
                  itemHeight="0.64rem"
                  className="datePicker-box singleDate"
                  type="date"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={date}
                  onChange={onChange}
                />
              </div>

              <div
                className="w-[1.2rem] h-[.5rem] text-[var(--colour-2)] text-[.2rem] font-[400] bg-[--colour-1] rounded-[.16rem] flex items-center justify-center active:scale-[.95] transition-all select-none "
                onClick={() => {
                  onSubmit && onSubmit();
                  handleClose && handleClose();
                }}
              >
                {t('refer.confirm')}
              </div>
            </div>
          </div>
        }
      >
        <div
          onClick={() => {
            setVisableDate(!visableDate);
          }}
          className={`w-[2.1rem] h-[.5rem] flex items-center justify-center border border-[var(--colour-6)] bg-[var(-colour-5)] rounded-[.4rem] text-[var(--colour-44)] ${singleCheckClassName}`}
        >
          <div className="text-[.2rem] font-[500]">{moment(date).format(`${format}`)}</div>
        </div>
      </TippyPopBox>
    </>
  );
};

export default DatetimePickerBox;
