import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { ModifyUserReq } from '../api/index';
import { isLogin } from './helper';

let pushApp = null;

const setFcmToken = async () => {
  if (window.__fcmToken) {
    return;
  }
  const messaging = getMessaging(pushApp);
  try {
    getToken(messaging)
      .then(async (currentToken) => {
        if (currentToken) {
          console.log('FCM 令牌:', currentToken);
          // // 在此处将令牌发送到您的服务器以便保存
          if (window.__fcmToken != currentToken) {
            window.__fcmToken = currentToken;
            if (isLogin()) {
              ModifyUserReq({
                push_message_token: currentToken,
              });
            }
          }
        } else {
          console.log('没有获取到 FCM 令牌');
          // 请求通知权限
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            console.log('通知权限已授予');
            await setFcmToken();
          } else if (permission === 'denied') {
            console.log('通知权限被拒绝');
          } else {
            console.log('通知权限请求被忽略');
          }
        }
      })
      .catch((err) => {
        console.log('getToken error:' + err);
      });
  } catch (error) {
    console.log('获取 FCM 令牌时出错:', error);
  }
};

/**
 * 初始化 Firebase Push 消息
 */
const initializeFirebaseMessaging = async (firebaseConfig) => {
  const isSup = await isSupported();
  if (!isSup) {
    return;
  }
  if (pushApp != null) {
    await setFcmToken();
    return;
  }

  pushApp = initializeApp(firebaseConfig, 'pushApp');
  const UrlFirebaseConfig = JSON.stringify(firebaseConfig);
  const firebaseUrl = `/firebase-messaging-sw.js`;
  const registerFirebase = async () => {
    // 注册 Service Worker
    const registration = await navigator.serviceWorker.register(firebaseUrl, {
      scope: '/firebase-cloud-messaging-push-scope',
    });
    registration.update();
    console.log('Service Worker registered with scope:', registration.scope);
    setFcmToken();
    // 处理前台消息
    const messaging = getMessaging(pushApp);
    onMessage(messaging, (payload) => {
      console.log('收到前台消息:', payload);
    });
  };

  if ('serviceWorker' in navigator) {
    try {
      //判断当前配置和之前配置是否一致，不一致取消注册并重新注册
      const lastFirebaseConfig = localStorage.getItem('firebaseConfigUrl');
      if (lastFirebaseConfig && lastFirebaseConfig != btoa(JSON.stringify(firebaseConfig))) {
        //首先取消注册
        navigator.serviceWorker.getRegistration(firebaseUrl).then(async (registration) => {
          if (registration) {
            registration.unregister().then(async (boolean) => {
              if (boolean) {
                console.log('service workder unergistered successfully');
                registerFirebase();
              } else {
                registerFirebase();
              }
            });
          } else {
            registerFirebase();
          }
        });
      } else {
        registerFirebase();
        localStorage.setItem('firebaseConfigUrl', btoa(JSON.stringify(firebaseConfig)));
      }
    } catch (error) {
      console.log('Service Worker 注册失败:', error);
    }
  }
};

export default initializeFirebaseMessaging;
