import { get } from 'lodash';
import { memo } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

/**
 * GTM
 */
const GTM = () => {
  const gtmId = useSelector((state) => get(state, 'app.websiteInitConfig.website_info.gtm_id', ''));

  return gtmId ? (
    <Helmet>
      <script>{`
         (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');
      `}</script>
      <noscript>{`
        <iframe
          src={https://www.googletagmanager.com/ns.html?id=${gtmId}}
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
        `}</noscript>
    </Helmet>
  ) : (
    <></>
  );
};

export default memo(GTM);
