import Close from '../../../assets/img/bulletinClose.png';
import cn from 'classnames';
import { forwardRef } from 'react';
import Icon, { IconName } from '../../icon';
import ScrollbarContent from '../../scrollbarContent';

const Index = ({
  isCenter = false,
  children,
  allowScroll = false,
  handleClose = () => {},
  isShowCloseBtn = true,
  className = '',
  viewportClassName = '',
}) => {
  return allowScroll ? (
    <div
      className={`py-[.56rem] w-[var(--theme-max-width)] h-[18rem] bg-[var(--colour-35)] relative select-none flex flex-col justify-center ${className}`}
      style={{
        height: `${window.innerHeight}px`,
      }}
    >
      {isShowCloseBtn && (
        <div
          className="w-[.52rem] h-[.48rem] cursor-pointer absolute right-[.08rem] top-[.08rem] text-[var(--colour-46)]"
          onClick={handleClose}
        >
          <Icon name={IconName.Close} />
        </div>
      )}
      <ScrollbarContent viewportClassName={viewportClassName} className="mx-[.12rem] flex justify-center">
        {children}
      </ScrollbarContent>
    </div>
  ) : (
    <div
      className={cn(
        `adaptive-width  h-full bg-[var(--colour-35)] rounded-normal relative select-none py-[.56rem] flex flex-col justify-center ${className}`,
        {
          'flex items-center justify-center': isCenter,
        },
      )}
      style={{
        height: `${window.innerHeight}px`,
      }}
    >
      {isShowCloseBtn && (
        <div
          className="w-[.52rem] h-[.48rem] cursor-pointer absolute right-[.08rem] top-[.08rem]"
          onClick={handleClose}
        >
          <img src={Close} alt="" className="not-drag" />
        </div>
      )}
      <div className="overflow-x-hidden">{children}</div>
    </div>
  );
};

export default Index;
