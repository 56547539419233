import React, { useEffect, useRef, useState } from 'react';
import { Tabs } from 'react-vant';
import HeaderBack from '../../../../components/headerBack';
import useGoBack from '../../../../hooks/useGoBack';
import { getQueryString, isInIframe } from '../../../../utils/helper';
import Promote from '../../promote';
import MyData from '../../myData';
import AllData from '../../allData';
import Performance from '../../performance';
import Commission from '../../commission';
import DirectData from '../../directData';
import DirectBetting from '../../directBetting';
import DirectFinance from '../../directFinance';
import DirectReceive from '../../directReceive';
import Proportion from '../../proportion';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../../../../utils/localePath';
import { useBodyOverflow } from '../../../../hooks/useBodyOverflow';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import useDraggableScroll from '../../../../hooks/useDraggableScroll';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const Referral = () => {
  useBodyOverflow(true);
  const location = useLocation();
  const { h } = useWindowHeight();
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const goBack = useGoBack();
  const [lineWidth, setLineWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const tabsRef = useRef(null);
  const checkboxRef = useRef(null);

  const list = [
    {
      key: 0,
      name: t('refer.invite_link'),
      currentValue: 'promote',
      component: Promote,
    },
    {
      key: 1,
      name: t('refer.myData'),
      currentValue: 'myData',
      component: MyData,
    },
    {
      key: 2,
      name: t('refer.allData'),
      currentValue: 'allData',
      component: AllData,
    },
    {
      key: 3,
      name: t('refer.performance'),
      currentValue: 'performance',
      component: Performance,
    },
    {
      key: 4,
      name: t('refer.commission'),
      currentValue: 'commission',
      component: Commission,
    },
    {
      key: 5,
      name: t('refer.directData'),
      currentValue: 'directData',
      component: DirectData,
    },
    {
      key: 6,
      name: t('refer.directBetting'),
      currentValue: 'directBetting',
      component: DirectBetting,
    },
    {
      key: 7,
      name: t('refer.directFinance'),
      currentValue: 'directFinance',
      component: DirectFinance,
    },
    {
      key: 8,
      name: t('refer.directReceive'),
      currentValue: 'directReceive',
      component: DirectReceive,
    },
    {
      key: 9,
      name: t('refer.proportion'),
      currentValue: 'proportion',
      component: Proportion,
    },
  ];

  const [defaultActive, setDefaultActive] = useState(() => {
    const current = getQueryString('current');
    if (current) {
      const _index = list.findIndex((item) => item.currentValue === current);
      return _index;
    }
    return 0;
  });

  const updateLineWidth = () => {
    const tabsElement = tabsRef.current;
    if (!tabsElement) return;

    const activeTab = tabsElement.querySelector('.rv-tab--active');
    if (!activeTab) return;

    const lineWidth = activeTab.offsetWidth - 24;
    setLineWidth(lineWidth);
  };

  useDraggableScroll(document.querySelector('.rv-tabs__nav'), [], false);

  const handleClick = (e) => {
    setCurrentIndex(e.index);
    navigate(toLocalePath(`/referral?current=${list[e.index].currentValue}`), {}, false);
  };

  const handleScroll = (currentName = '') => {
    if (checkboxRef.current) {
      const index = list.findIndex((item) => item.currentValue === currentName);
      if (index != -1) {
        setCurrentIndex(index);
        checkboxRef.current.scrollTo(index);
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const current = searchParams.get('current') || 'promote';
    handleScroll(current);
  }, [location]);

  useEffect(() => {
    updateLineWidth();
  }, [currentIndex]);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.swiper.disable();
    }
  }, []);

  return (
    <div className="flex flex-col h-full">
      <HeaderBack
        title={t('home.Referral')}
        onClick={() => {
          goBack(true);
        }}
      />

      <div className="tabs_box" ref={tabsRef}>
        <Tabs
          defaultActive={defaultActive}
          ref={checkboxRef}
          sticky
          swipeable
          onClickTab={(e) => {
            handleClick(e);
          }}
          background="var(--colour-35)"
          titleActiveColor="var(--colour-13)"
          titleInactiveColor="var(--colour-14)"
          lineWidth={lineWidth}
          lineHeight={'.02rem'}
          color="var(--colour-1)"
        >
          {list.map((item, index) => (
            <Tabs.TabPane key={index} title={item.name} titleClass="!text-[.24rem] font-[400] !h-[.7rem]">
              <div style={{ height: `${h}rem` }}>
                <item.component handleScroll={handleScroll} />
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Referral;
