import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

const PendingItem = ({ name, money, date, source, countDown, button, isShowCountDown = false }) => {
  const { t } = useTranslation();

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
    }),
    shallowEqual,
  );

  return (
    <div
      className={cn(
        'h-[1.44rem] rounded-[.12rem] flex flex-col justify-between p-[.16rem_.2rem] leading-[1] bg-[var(--colour-69)] text-[var(--colour-41)] text-[.2rem] font-[500]',
        {},
      )}
    >
      <div className="flex justify-between">
        <div className="text-[.22rem]">{name}</div>
        <div>{button}</div>
      </div>
      <div className="flex justify-between">
        <div>
          <div className="text-[var(--colour-26)]">
            {currency_symbol} {money}
          </div>
          <div className="flex space-x-[.2rem] items-center">
            <div className="">{date}</div>
            <div className="">Source: {source}</div>
          </div>
        </div>
        {isShowCountDown && <div className="">{countDown}</div>}
      </div>
    </div>
  );
};

export default PendingItem;
