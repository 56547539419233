import { useState } from 'react';
import Icon, { IconName } from '../icon';
import { langIconName } from '../../utils/helper';
import { changeLang } from '../../utils/localePath';
import { langsConfig } from '../../constants/langs';
import { useSelector, shallowEqual } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import useOnclickOutside from 'react-cool-onclickoutside';

const Language = ({ className = '', handleLinkClick = () => {} }) => {
  const [visible, setVisible] = useState(false);

  const animation = useSpring({
    opacity: visible ? 1 : 0,
    display: visible ? 'block' : 'none',
    config: { duration: 200 },
  });

  const { defaultLang } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const onClickLang = (item) => {
    setVisible(false);
    changeLang(item.key);
  };

  const handleClickLangButton = () => {
    setVisible(!visible);
  };

  const tipRef = useOnclickOutside(() => {
    setVisible(false);
  });

  return (
    <div className="relative">
      <div
        className={`w-[.4rem] h-[.4rem] text-[var(--colour-2)] cursor-pointer ${className}`}
        onClick={handleClickLangButton}
        ref={tipRef}
      >
        <Icon name={IconName.Language} />
      </div>

      <animated.div
        ref={tipRef}
        style={animation}
        className="w-[3.98rem] bg-[var(--colour-4)] absolute right-0 top-[.8rem] rounded-[.08rem] px-[.3rem]"
      >
        {langsConfig.supportLangList.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                onClickLang(item);
                handleLinkClick();
              }}
              className="flex items-center gap-[.12rem] min-h-[1rem] cursor-pointer"
            >
              <div className="w-[.4rem] h-[.4rem]">
                <Icon name={langIconName(item.key)} />
              </div>

              <div
                className={`text-[.24rem] font-[500] ${item.key == defaultLang ? 'text-[var(--colour-2)]' : 'text-[var(--colour-33)]'}`}
              >
                {item.name}
              </div>
            </div>
          );
        })}
      </animated.div>
    </div>
  );
};

export default Language;
