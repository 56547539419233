import { useState, useEffect, createRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cn from 'classnames';
import scrollIntoView from 'scroll-into-view';
import { toLocalePath } from '../../../../../../utils/localePath';
import Icon, { IconName } from '../../../../../../components/icon';
import AccountForReceipt from './accountForReceipt';
import RequestWithdrawal from './requestWithdrawal';
import ServiceRecord from './serviceRecord';
import useHandleLogic from '../../../../../../hooks/useHandleLogic';
import { PaymentAccWithdrawConfig } from '../../../../../../api/newDepositWithdraw';
import Loading from '../../../../../../components/loading2';
import WithdrawPassword from '../index';
import { setUserObj } from '../../../../../../redux/reducers/userSlice';
import { useGlobalNavigate } from '../../../../../../contexts/navigateContext';

export default function Index() {
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { userObj } = useSelector(
    (state) => ({
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  const { handleClickManMadeInPage } = useHandleLogic();
  const { withdrawtabpageId } = useParams();
  const [elRefs, setElRefs] = useState([]);

  const [loading, setLoading] = useState(true);
  const [needSwitchPassword, setNeedSwitchPassword] = useState(true); //是否进入设置提现密码界面
  const [configData, setConfigData] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(false); //更新了默认提现账户和提现账户添加后，进入体现主页面需要更新config配置
  const [prevPath] = useState(() => {
    let str = localStorage.getItem('prev_path');
    localStorage.removeItem('prev_path');
    return str;
  });

  const [tabList] = useState([
    {
      id: '1',
      text: 'withdraw_tab_request_withdrawal',
      // inner: <RequestWithdrawal />,
    },
    {
      id: '2',
      text: 'withdraw_tab_account_for_receipt',
      // inner: <AccountForReceipt />,
    },
    {
      id: '3',
      text: 'withdraw_tab_service_record',
      // inner: <ServiceRecord />,
    },
  ]);

  const updateConfig = () => {
    PaymentAccWithdrawConfig()
      .then((res) => {
        if (res.code == 0) {
          const data = res.data;
          setConfigData(data);
          setNeedSwitchPassword(
            data.payment.switch_withdraw_password == '0'
              ? false
              : data.payment.is_set_withdraw_password == '1'
                ? false
                : true,
          );
          dispatch(
            setUserObj({
              ...userObj,
              balance: data.user_obj.balance,
              locked_balance: data.user_obj.locked_balance,
            }),
          );
          setNeedUpdate(false);
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    updateConfig();
  }, []);

  useEffect(() => {
    if (needUpdate && withdrawtabpageId == '1') {
      updateConfig();
    }
  }, [needUpdate, withdrawtabpageId]);

  useEffect(() => {
    if (tabList) {
      setElRefs((elRefs) =>
        Array(tabList.length)
          .fill('')
          .map((_, i) => elRefs[i] || createRef()),
      );
    }
  }, [tabList]);

  useEffect(() => {
    let _timer = null;
    _timer = window.setTimeout(() => {
      scrollToItem(withdrawtabpageId);
    }, 0);
    return () => {
      window.clearTimeout(_timer);
    };
  }, [withdrawtabpageId, tabList.length, elRefs.length]);

  const scrollToItem = (withdrawtabpageId) => {
    if (tabList && tabList.length > 0) {
      const gameTabIndex = Array.from(tabList).findIndex((item) => {
        return item.id == withdrawtabpageId;
      });
      if (gameTabIndex > -1) {
        if (elRefs[gameTabIndex] && elRefs[gameTabIndex].current) {
          scrollIntoView(elRefs[gameTabIndex].current, {
            time: 500,
          });
        }
      }
    }
  };
  const clickBack = () => {
    if (prevPath) {
      navigate(toLocalePath(`${prevPath}`));
    } else {
      navigate(toLocalePath('/'));
    }
  };

  return loading ? (
    <Loading className="h-full min-h-[var(--app-height)]" />
  ) : needSwitchPassword ? (
    <WithdrawPassword setNeedSwitchPassword={setNeedSwitchPassword} />
  ) : (
    <div className="bg-[var(--colour-68)] h-full flex flex-col">
      <header className="h-[.76rem] w-full">
        <div className="header-box">
          <div
            className={`relative h-[.9rem] w-full border-b border-[var(--colour-6)] flex items-center justify-between bg-[var(--colour-35)] pr-[.24rem]`}
          >
            <div className="flex items-center mr-[.16rem] px-[.24rem] h-full" onClick={clickBack}>
              <div className="w-[.32rem] h-[.32rem] text-[var(--colour-11)] rotate-180">
                <Icon name={IconName.BannerNext2} />
              </div>
            </div>
            <div className="flex-1 h-full overflow-x-auto flex scrollbar-none">
              {tabList.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    ref={elRefs[index]}
                    className={cn('whitespace-nowrap leading-[.88rem] text-[.24rem] font-[500] border-b', {
                      'ml-[.4rem]': index != 0,
                      'text-[var(--colour-13)] border-[var(--colour-1)]': withdrawtabpageId == item.id,
                      'text-[var(--colour-14)] border-transparent': withdrawtabpageId != item.id,
                    })}
                    onClick={() => {
                      navigate(toLocalePath(`/pay/withdrawtabpage/${item.id}`));
                    }}
                  >
                    {t(`deposit-withdraw-wg.${item.text}`)}
                  </div>
                );
              })}
            </div>
            <div
              className="w-[.32rem] h-[.32rem] text-[var(--colour-1)] ml-[.2rem]"
              onClick={() => {
                handleClickManMadeInPage();
              }}
            >
              <Icon name={IconName.ManMade} />
            </div>
          </div>
        </div>
      </header>

      {/* {tabList.find((item) => item.id == withdrawtabpageId).inner} */}
      {withdrawtabpageId == '1' ? (
        <RequestWithdrawal configData={configData} updateConfig={updateConfig} />
      ) : withdrawtabpageId == '2' ? (
        <AccountForReceipt configData={configData} setNeedUpdate={setNeedUpdate} />
      ) : (
        <ServiceRecord />
      )}
    </div>
  );
}
