import { isBrNTemplateId } from '../../../utils/helper';
import NTemplate001 from './normal/template001';
import DTemplate001 from './design/template001';
import DTemplate003 from './design/template003';
import { isAndroidAPK } from '../../../utils/deviceUtils';
import RedirectToPwa from './redirectToPwa';
import { useSelector, shallowEqual } from 'react-redux';
import { getTemplateId } from '../../../utils/helper';

const RenderCom = ({ handleClose = () => {}, bottomDownloadBoxPromptSwitch }) => {
  switch (getTemplateId()) {
    case 'n-template001':
    case 'n-template002':
      return <NTemplate001 handleClose={handleClose} downloaddata={bottomDownloadBoxPromptSwitch} />;
    case 'd-template003':
      return <DTemplate003 handleClose={handleClose} downloaddata={bottomDownloadBoxPromptSwitch} />;

    case 'd-template001':
    case 'd-template002':
      return <DTemplate001 handleClose={handleClose} downloaddata={bottomDownloadBoxPromptSwitch} />;
  }
};

const PwafooterModal = ({ handleClose = () => {}, bottomDownloadBoxPromptSwitch }) => {
  const { download } = useSelector(
    (state) => ({
      download: state.app.websiteInitConfig?.website_info?.download,
    }),
    shallowEqual,
  );
  return (
    <>
      {isAndroidAPK() && download.apk_jump_pwa.switch == 1 ? (
        <RedirectToPwa handleClose={handleClose} />
      ) : (
        <>
          <RenderCom handleClose={handleClose} downloaddata={bottomDownloadBoxPromptSwitch} />
        </>
      )}
    </>
  );
};

export default PwafooterModal;
