import moment from 'moment';
import { get } from 'lodash';
import { Autoplay } from 'swiper/modules';
import Loading2 from '../../../loading2';
import Icon, { IconName } from '../../../icon';
import ScrollWrapper from '../../../scrollWrapper';
import { useTranslation } from 'react-i18next';
import { makeTabKeys } from '../../../tabBox';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setAnnouncementhidePopup, setAnnouncementhidePopupNextTime } from '../../../../redux/reducers/userSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import cn from 'classnames';
import NoData from '../../../noData';
import useLink from '../../../../hooks/useLink';

const Dtemplate003 = ({ handleClose, notificationData, isCloseAnnouncement }) => {
  let tabKeys = makeTabKeys(0);
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [notificationList, setNotificationList] = useState([]);

  const dispatch = useDispatch();
  const { announcementhidePopup, announcementhidePopupNextTime } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
      announcementhidePopup: state.user.announcementhidePopup || 0,
      announcementhidePopupNextTime: state.user.announcementhidePopupNextTime || '0',
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (notificationData && notificationData.length > 0) {
      tabKeys = makeTabKeys(notificationData.length);
      const data = notificationData.map((item, index) => {
        return {
          ...item,
          key: tabKeys[index],
          isShow: true,
          name: item.title || '',
          title: item.title || '',
          content: (
            <div>
              <ScrollWrapper
                className={`overflow-y-auto h-[7.28rem] rounded-[.24rem] select-none wrap-break-word`}
                mr={false}
              >
                <div ref={containerRef}>
                  <div dangerouslySetInnerHTML={{ __html: item.content || '' }}></div>
                </div>
              </ScrollWrapper>
            </div>
          ),
          link: item.link || '',
        };
      });

      setNotificationList(data);
    }
  }, [notificationData]);

  useEffect(() => {
    if (notificationList.length > 0) {
      setPage(notificationList[0]?.key);
    }
  }, [notificationList]);

  const [page, setPage] = useState();

  const handleNoReminderToday = () => {
    const newChecked = announcementhidePopup === 0 ? 1 : 0;
    dispatch(setAnnouncementhidePopup(newChecked));
    if (newChecked === 1) {
      const endTime = moment(new Date()).add(1, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x');
      dispatch(setAnnouncementhidePopupNextTime(endTime));
    } else {
      dispatch(setAnnouncementhidePopupNextTime('0'));
    }
  };

  useEffect(() => {
    if (new Date().getTime() >= announcementhidePopupNextTime) {
      dispatch(setAnnouncementhidePopup(0));
      dispatch(setAnnouncementhidePopupNextTime('0'));
    }
  }, []);

  return (
    <>
      <div>
        <>
          <div className={cn('w-[6.7rem] rounded-[.24rem] relative select-none bg-[var(--colour-52)] h-[7.28rem]')}>
            {notificationList && notificationList.length > 0 ? (
              <>
                {page ? (
                  <Swiper
                    slidesPerView={1}
                    ref={swiperRef}
                    loop={notificationList.length > 1}
                    spaceBetween={12}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    modules={[Autoplay]}
                    speed={2000}
                    onSlideChange={(data) => {
                      setCurrentIndex(data.realIndex);
                    }}
                  >
                    {notificationList &&
                      notificationList.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className={'max-h-[7.28rem] overflow-y-auto'}>{item.content}</div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                ) : (
                  <>
                    <div className="w-full h-full flex items-center justify-center mt-[-0.8rem]">
                      <Loading2 />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <NoData className="w-full h-full"></NoData>
              </>
            )}
          </div>
        </>

        <div className="mt-[.3rem] flex items-center justify-center">
          <div className="flex w-auto items-center justify-center gap-[.18rem] px-[.2rem] bg-[var(--colour-27)] border border-[var(--colour-51)] rounded-[.08rem] py-[.16rem]">
            <div
              className={cn(
                'w-[.52rem] text-[var(--colour-2)] h-[.52rem] rounded-[.08rem] border border-[var(--colour-6)] cursor-pointer flex items-center justify-center',
                {
                  'colour-79': announcementhidePopup !== 0,
                  'bg-[var(--colour-2)]': announcementhidePopup === 0,
                },
              )}
              onClick={handleNoReminderToday}
            >
              {announcementhidePopup === 0 ? (
                <></>
              ) : (
                <>
                  <div className="w-[.36rem] h-[.36rem]">
                    <Icon name={IconName.AnnouncementChecked}></Icon>
                  </div>
                </>
              )}
            </div>
            <div className="text-[.24rem] font-[400] select-none text-[var(--colour-72)]">{t('home.warnTxt')}</div>
          </div>
        </div>

        <div
          className={cn(
            'active:scale-[.95] transition-all select-none w-[.6rem] h-[.6rem] border border-solid border-[var(--colour-41)] rounded-[50%] flex justify-center items-center absolute left-[50%] bottom-[-0.8rem] translate-x-[-50%] text-[var(--colour-41)]',
          )}
          onClick={() => {
            handleClose();
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseAnnouncementButton);
          }}
        >
          <Icon name={IconName.Close} />
        </div>
      </div>
    </>
  );
};

export default Dtemplate003;
