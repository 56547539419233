import { QRCodeCanvas } from 'qrcode.react';
import { remToPx } from '../../utils/helper';
import { eventEmitter, EmitterEventTypes } from '../../utils/eventEmitter';
import { useState, useEffect } from 'react';

export default function Index({ value = '', remSize = 1.6, level = 'L' }) {
  const [size, setSize] = useState(remToPx(remSize));
  const resize = () => {
    setSize(remToPx(remSize));
  };
  useEffect(() => {
    resize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      resize();
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return <QRCodeCanvas value={value} size={size} level={level} className="mx-auto" />;
}
