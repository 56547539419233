const Title = ({ text1 = 11, text2 = 22, text3 = 33, onClick }) => {
  return (
    <div className="space-y-[.28rem]">
      <div className="text-[.56rem] font-[600] text-[var(--colour-48)]">{text1}</div>
      <div className="space-x-[.32rem] flex">
        <div className="text-[.24rem] font-[600] text-[var(--colour-41)]">{text2}</div>
        <div className="text-[.24rem] font-[600] text-[var(--colour-26)] cursor-pointer" onClick={onClick}>
          {text3}
        </div>
      </div>
    </div>
  );
};

export default Title;
