import Title from '../../components/title';
import LoginThirdParty from '../../components/loginThirdParty';
import LoginForm from './loginForm';
import { useTranslation } from 'react-i18next';

const LoginPage3 = ({ handleClose, changeType }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="p-[.32rem_.32rem]">
        <Title
          text1={t('login-signup.login_your_account')}
          text2={t("login-signup.don't_have")}
          text3={t('login-signup.register_t003')}
          onClick={changeType}
        />
        <LoginForm handleClose={handleClose} changeType={changeType} />
        <div className="mt-[.48rem]">
          <LoginThirdParty />
        </div>
      </div>
    </>
  );
};

export default LoginPage3;
