import Countdown, { zeroPad } from 'react-countdown';

const TwoNumber = ({ text = '', index, length, className2 }) => {
  return (
    <>
      <span className={className2}>
        {text}
        {index < length - 1 ? ':' : ''}
      </span>
    </>
  );
};

const TourCountdown = ({
  date = Date.now(),
  className = 'font-[600] space-x-[.3rem] text-[.36rem]',
  className2 = 'w-[.48rem] min-w-[.48rem] max-w-[.48rem]',
  onComplete = () => {},
}) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const list = completed
      ? ['00', '00', '00', '00']
      : [zeroPad(days, 2), zeroPad(hours, 2), zeroPad(minutes, 2), zeroPad(seconds, 2)];

    return list.map((item, index) => (
      <TwoNumber key={index} text={item} index={index} length={list.length} className2={className2} />
    ));
  };

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <Countdown date={date} onComplete={onComplete} renderer={renderer} />
    </div>
  );
};

export default TourCountdown;
