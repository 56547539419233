import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import AppDownloadAndroid from './appDownloadAndroid';
import DownloadManager from '../../../../manager/downloadManager';
import { cmsImgUrl, getTemplateId } from '../../../../utils/helper';
import TippyPopBox, { Placement } from '../../../tippyPopBox';
import { get } from 'lodash';

const Layout1 = ({ onlyIcon, websiteInitConfig }) => {
  const { t } = useTranslation();
  const [codeFlag, setCodeFlag] = useState(false); //是否展开
  const mouseEnterDownload = () => {
    setCodeFlag(true);
  };
  const mouseLeaveDownload = () => {
    setCodeFlag(false);
  };

  return onlyIcon ? (
    <TippyPopBox
      visible={codeFlag}
      placement={Placement.right}
      offset={[90, 8]}
      popChildren={
        <div
          className={`w-[4.8rem] rounded-normal bg-[var(--colour-35)] overflow-hidden cursor-default`}
          style={{ boxShadow: '0rem .12rem .2rem rgba(0,0,0,0.6)' }}
          onMouseEnter={mouseEnterDownload}
          onMouseLeave={mouseLeaveDownload}
        >
          <div className="text-[var(--colour-8)] text-center pt-[.28rem] w-[4.8rem] mb-[.2rem]">
            {t('home.drawer_download_text1')}
          </div>
          <div className="w-[4.8rem] flex justify-center">
            <div className="w-[2.72rem] mb-[.2rem]">
              <img src={cmsImgUrl(websiteInitConfig.website_info.app_download_qr_code)} />
            </div>
          </div>
          <div className="mb-[.34rem] w-[4.8rem] text-center">{t('home.drawer_download_text2')}</div>
        </div>
      }
    >
      <div
        className={`w-[.8rem] h-[.8rem] mb-[.16rem] rounded-normal cursor-pointer flex justify-center items-center bg-[var(--colour-35)] relative ${
          codeFlag ? 'bg-[var(--colour-35)]' : ''
        }`}
        onMouseEnter={mouseEnterDownload}
        onMouseLeave={mouseLeaveDownload}
      >
        <div className="w-[.44rem] h-[.44rem]">
          <Icon name={IconName.DrawDownLoad} />
        </div>
        <div className="absolute top-0] left-[.8rem] w-[.2rem] h-[.8rem] cursor-default"></div>
      </div>
    </TippyPopBox>
  ) : DownloadManager.showAndroid ? (
    <AppDownloadAndroid />
  ) : (
    <></>
  );
};

const Layout2 = ({ isShow = true, websiteInitConfig }) => {
  const { t } = useTranslation();
  const [downloadFlag, setDownloadFlag] = useState(false); //下载浮框是否展示
  const mouseEnterDownload = () => {
    setDownloadFlag(true);
  };

  const mouseLeaveDownload = () => {
    setDownloadFlag(false);
  };

  return (
    <TippyPopBox
      visible={downloadFlag}
      placement={Placement.bottom}
      offset={[22, 17]}
      popChildren={
        <div
          className={`w-[4.8rem] rounded-normal bg-[var(--colour-35)] overflow-hidden cursor-default`}
          style={{ boxShadow: '0rem .12rem .2rem rgba(0,0,0,0.6)' }}
          onMouseEnter={mouseEnterDownload}
          onMouseLeave={mouseLeaveDownload}
        >
          <div className="text-[var(--colour-8)] w-full text-center pt-[.28rem] mb-[.2rem]">
            {t('home.drawer_download_text1')}
          </div>
          <div className="w-full flex justify-center">
            <div className="w-[2.72rem] mb-[.2rem]">
              <img src={cmsImgUrl(websiteInitConfig.website_info.app_download_qr_code)} />
            </div>
          </div>
          <div className="mb-[.34rem] w-full text-center text-[var(--colour-49)]">
            {t('home.drawer_download_text2')}
          </div>
        </div>
      }
    >
      {isShow ? (
        <div
          className={`h-[.56rem] cursor-pointer flex justify-between items-center relative ${
            downloadFlag ? 'text-[var(--colour-3)]' : 'text-[var(--colour-14)]'
          }`}
          onMouseEnter={mouseEnterDownload}
          onMouseLeave={mouseLeaveDownload}
        >
          <div className="flex items-center mr-[2.08rem]">
            <div className="w-[.44rem] h-[.44rem] mr-[.16rem]">
              <Icon name={IconName.DrawDownLoad} />
            </div>
            <div className="text-[.28rem] font-[700] text_nowrap">{t('home.drawer_download')}</div>
          </div>
          <div
            className={`w-[.22rem] h-[.14rem] transition-chat ${
              downloadFlag ? 'text-[var(--colour-46)]' : 'text-[var(--colour-11)] rotate-[-90deg]'
            }`}
          >
            <Icon name={IconName.ChatArraw} />
          </div>

          <div className="absolute bottom-[-0.34rem] h-[.34rem] left-0] w-full cursor-default"></div>
        </div>
      ) : (
        <div
          className={`w-[.8rem] h-[.8rem] rounded-normal cursor-pointer flex justify-center items-center bg-[var(--colour-35)] relative ${
            downloadFlag ? 'bg-[var(--colour-35)]' : ''
          }`}
          onMouseEnter={mouseEnterDownload}
          onMouseLeave={mouseLeaveDownload}
        >
          <div className="w-[.44rem] h-[.44rem]">
            <Icon name={IconName.DrawDownLoad} />
          </div>
          <div className="absolute bottom-[-0.34rem] h-[.34rem] left-0] w-full cursor-default"></div>
        </div>
      )}
    </TippyPopBox>
  );
};

const AppDownloadCode = ({ layout = null, isShow = true, onlyIcon = false }) => {
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
    }),
    shallowEqual,
  );
  const getLayout = () => {
    return layout ? layout : getTemplateId();
  };

  return <Layout1 onlyIcon={onlyIcon} websiteInitConfig={websiteInitConfig} />;
};

export default AppDownloadCode;
