import { useState, useEffect, memo, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { setDrawerMenuVisible } from '../../../../../redux/reducers/appSlice';
import { isControllableSite } from '../../../../../utils/helper';
import { useTranslation } from 'react-i18next';
import 'react-tooltip/dist/react-tooltip.css';
import EventBonus from '../../../items/eventBonus';
import VipBonus from '../../../items/vipBonus';
import ReferralEarn from '../../../items/referralEarn';
import BettingBonus from '../../../items/bettingBonus';
import GiftMoney from '../../../items/giftMoney';
import Promotions from '../../../items/promotions';
import AppDownloadCode from '../../../items/appDownloadCode';
import Language from '../../../items/language';
import Service from '../../../items/service';
import useHandleLogic from '../../../../../hooks/useHandleLogic';
import LeftMenu from '../../../leftMenu/leftMenu';
import DrawerMenuBlog from '../../../items/blog';
import { Popup } from 'react-vant';
import Agent from '../../../items/agent';
import ActivityBtnList from '../../../items/activityBtnList';
import { eventEmitter, EmitterEventTypes } from '../../../../../utils/eventEmitter';
import RedeemEntrance from '../../../items/redeem';

const NTemplate001 = ({ drawerLeftMenuVisible = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    drawerMenuVisible,
    windowInnerWidth,
    defaultLang,
    disconnect,
    downloadBoxPromptSwitch,
    websiteInitConfig,
    redeem_code_switch,
  } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      windowInnerWidth: state.app.windowInnerWidth,
      defaultLang: state.user.defaultLang,
      disconnect: state.app.disconnect,
      downloadBoxPromptSwitch: state.app.downloadBoxPromptSwitch,
      websiteInitConfig: state.app.websiteInitConfig,
      redeem_code_switch: state?.app?.activityStatus?.redeem_code_switch,
    }),
    shallowEqual,
  );

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  const refLeftMenu = useRef(null);
  const refInnerLeftMenu = useRef(null);
  const [hasInnerScroll, setHasInnerScroll] = useState(false);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const handleResize = () => {
    if (refLeftMenu.current) {
      const { scrollHeight, clientHeight } = refLeftMenu.current;
      setHasInnerScroll(scrollHeight > clientHeight);
    }
  };

  useEffect(() => {
    handleResize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      handleResize();
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleSetScrollbarWidth = () => {
    if (refLeftMenu.current && refInnerLeftMenu.current) {
      const _scrollbarWidth = refLeftMenu.current.offsetWidth - refInnerLeftMenu.current.offsetWidth;
      setScrollbarWidth(_scrollbarWidth);
    }
  };

  useEffect(() => {
    handleSetScrollbarWidth();
  }, [hasInnerScroll]);

  return (
    <>
      <Popup
        overlayStyle={{ zIndex: 19 }}
        teleport={document.getElementById('root')}
        style={{ height: '100%', zIndex: 103 }}
        visible={drawerMenuVisible.visible}
        position="left"
        onClose={handleLinkClick}
      >
        <aside
          className={cn(
            'left-menu-container w-[var(--left-menu-width)] pt-[var(--header-box-height)] bg-[var(--colour-27)] box-shadow-8',
          )}
        >
          <div
            ref={refLeftMenu}
            className={cn('left-menu-container__outer1 w-full scrollbar-none p-[.14rem_.14rem_.24rem_.14rem]')}
            style={{ scrollbarColor: 'auto' }}
          >
            <div
              ref={refInnerLeftMenu}
              className={cn('left-menu-container__inner !static', {
                'pt-[var(--disconnect-height)]': disconnect,
                'pt-[var(--top-download-box-prompt-height)]': downloadBoxPromptSwitch,
              })}
            >
              <div
                className={cn('flex flex-col font-[600] text-[.2rem] min-h-[100%] mb-[1rem] text-[var(--colour-14)]')}
              >
                {redeem_code_switch == '1' && <RedeemEntrance />}
                {websiteInitConfig?.website_info?.switch_referral == '1' && <Agent handleLinkClick={handleLinkClick} />}
                <ActivityBtnList handleLinkClick={handleLinkClick} />

                <AppDownloadCode layout="n-template001" />
                <Language layout="n-template001" handleLinkClick={handleLinkClick} />
                <DrawerMenuBlog layout="n-template001" handleLinkClick={handleLinkClick} />
                <Service handleLinkClick={handleLinkClick} />
              </div>
            </div>
          </div>
        </aside>
      </Popup>
      {/* <LeftMenu drawerLeftMenuVisible={drawerLeftMenuVisible} /> */}
    </>
  );
};

export default memo(NTemplate001);
