import React, { Fragment, useEffect, useRef, useState } from 'react';
import HeaderBack from '../../../../components/headerBack';
import useGoBack from '../../../../hooks/useGoBack';
import Promote from '../../promote';
import MyData from '../../myData';
import AllData from '../../allData';
import Performance from '../../performance';
import Commission from '../../commission';
import DirectData from '../../directData';
import DirectBetting from '../../directBetting';
import DirectFinance from '../../directFinance';
import DirectReceive from '../../directReceive';
import Proportion from '../../proportion';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../../../../utils/localePath';
import { useBodyOverflow } from '../../../../hooks/useBodyOverflow';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import useDraggableScroll from '../../../../hooks/useDraggableScroll';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const Referral = () => {
  useBodyOverflow(true);
  const location = useLocation();
  const { h } = useWindowHeight(1);
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const goBack = useGoBack();
  const [currentIndex, setCurrentIndex] = useState(0);

  const tabsRef = useRef(null);

  const list = [
    {
      key: 0,
      name: t('refer.invite_link'),
      currentValue: 'promote',
      component: Promote,
    },
    {
      key: 1,
      name: t('refer.myData'),
      currentValue: 'myData',
      component: MyData,
    },
    {
      key: 2,
      name: t('refer.allData'),
      currentValue: 'allData',
      component: AllData,
    },
    {
      key: 3,
      name: t('refer.performance'),
      currentValue: 'performance',
      component: Performance,
    },
    {
      key: 4,
      name: t('refer.commission'),
      currentValue: 'commission',
      component: Commission,
    },
    {
      key: 5,
      name: t('refer.directData'),
      currentValue: 'directData',
      component: DirectData,
    },
    {
      key: 6,
      name: t('refer.directBetting'),
      currentValue: 'directBetting',
      component: DirectBetting,
    },
    {
      key: 7,
      name: t('refer.directFinance'),
      currentValue: 'directFinance',
      component: DirectFinance,
    },
    {
      key: 8,
      name: t('refer.directReceive'),
      currentValue: 'directReceive',
      component: DirectReceive,
    },
    {
      key: 9,
      name: t('refer.proportion'),
      currentValue: 'proportion',
      component: Proportion,
    },
  ];

  useDraggableScroll(tabsRef);

  const handleClick = (index) => {
    setCurrentIndex(index);
    navigate(toLocalePath(`/referral?current=${list[index].currentValue}`), {}, false);
  };

  const handleScroll = (currentName = '') => {
    const index = list.findIndex((item) => item.currentValue === currentName);
    if (index != -1) {
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const current = searchParams.get('current') || 'promote';
    handleScroll(current);
  }, [location]);

  useEffect(() => {
    if (tabsRef.current) {
      const tabContainer = tabsRef.current;
      const tabItems = tabContainer.querySelectorAll('.referral-item');
      const targetTab = tabItems[currentIndex];
      const targetTabWidth = targetTab.offsetWidth;
      const targetTabOffsetLeft = targetTab.offsetLeft;
      const containerWidth = tabContainer.offsetWidth;
      const containerScrollLeft = tabContainer.scrollLeft;
      const scrollAmount = targetTabOffsetLeft + targetTabWidth / 2 - containerWidth / 2 - containerScrollLeft;

      tabContainer.scrollLeft += scrollAmount;
    }
  }, [currentIndex]);

  return (
    <div className="flex flex-col h-full">
      <HeaderBack
        title={t('home.Referral')}
        onClick={() => {
          goBack(true);
        }}
      />

      <div className="flex flex-col" style={{ height: `${h}rem` }}>
        <div
          className="flex items-center px-[.2rem] overflow-x-auto hidden-scroll-y my-[.24rem] min-h-[.58rem] space-x-[.2rem]"
          ref={tabsRef}
        >
          {list.map((item, index) => {
            return (
              <div
                key={index}
                className={`${index == currentIndex ? 'colour-79' : 'bg-[var(--colour-35)]'} rounded-[.16rem] whitespace-nowrap h-[.58rem] w-auto flex items-center justify-center px-[.25rem] cursor-pointer active:scale-[.95] transition-all select-none referral-item text-[.22rem] font-[600] text-[var(--colour-2)]`}
                onClick={() => {
                  handleClick(index);
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>

        {list.map((item, index) => {
          return (
            <Fragment key={index}>
              {currentIndex == index && (
                <div className="flex-1 overflow-y-auto">
                  <item.component handleScroll={handleScroll} />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Referral;
