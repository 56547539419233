import { useDispatch } from 'react-redux';
import { getPromotionClaimedCount } from '../api';
import { setPromotionClaimedCount } from '../redux/reducers/appSlice';

const usePromotionClaimedCount = () => {
  const dispatch = useDispatch();
  // mode 1 定时调用 2 手动调用
  const InitPromotionClaimedCount = async (mode = 2) => {
    try {
      const res = await getPromotionClaimedCount({
        mode,
      });
      if (res.code === 0) {
        const data = res.data;

        const count = [
          data.giftmoney || 0,
          data.pending || 0,
          data.rakeback || 0,
          data.vip || 0,
          data.mission || 0,
          ...(data.events ? Object.values(data.events) : []),
        ].reduce((acc, value) => acc + value, 0);

        if (data.events) {
          const eventsSum = Object.values(data.events).reduce((acc, value) => acc + value, 0);
          dispatch(
            setPromotionClaimedCount({
              data,
              events: eventsSum > 99 ? '99+' : eventsSum,
              total: count > 99 ? '99+' : count,
            }),
          );
        } else {
          dispatch(
            setPromotionClaimedCount({
              data,
              eventTotal: 0,
              total: count > 99 ? '99+' : count,
            }),
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { InitPromotionClaimedCount };
};

export default usePromotionClaimedCount;
