import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { cmsImgUrl } from '../../../../../utils/helper';
import Icon, { IconName } from '../../../../../components/icon';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig';
import InstallButton from './installButton';

const IncompleteRecharge = ({ fromApk = '' }) => {
  const { t } = useTranslation();
  const { getServerLangText } = useLangServerConfig();
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  return (
    <div className="px-[.48rem] pt-[.4rem]">
      <div className="pl-[.02rem] flex items-start gap-[.2rem]">
        <img className="w-[1.16rem] h-[1.16rem]" src={cmsImgUrl(websiteInfo['android_chrome_192x192'])} />

        <div className="mt-[-0.1rem]">
          <div className="text-[.36rem] font-[700] text-[var(--colour-18)]">{websiteInfo.website_name || ''}</div>

          <div className="text-[.2rem] font-[400] text-[var(--colour-18)]">
            {getServerLangText(websiteInfo.download.top.tip.bottom_title) || ''}
          </div>

          <div className="mt-[.36rem]">
            <div className="text-[var(--colour-31)] text-[.36rem] font-[700]">{t('home.version')}</div>
          </div>

          <div className="mt-[.1rem] flex items-center gap-[.12rem]">
            <div className="w-[.36rem] h-[.36rem] text-[var(--colour-26)]">
              <Icon name={IconName.Fire}></Icon>
            </div>
            <div className="text-[.28rem] font-[400] text-[var(--colour-18)]">{t('home.more_game')}</div>
          </div>

          <div className="mt-[.1rem] flex items-center gap-[.12rem]">
            <div className="w-[.36rem] h-[.36rem] text-[var(--colour-26)]">
              <Icon name={IconName.Fire}></Icon>
            </div>
            <div className="text-[.28rem] font-[400] text-[var(--colour-18)]">{t('home.more_rewards')}</div>
          </div>

          <div className="mt-[.1rem] flex items-center gap-[.12rem]">
            <div className="w-[.36rem] h-[.36rem] text-[var(--colour-26)]">
              <Icon name={IconName.Fire}></Icon>
            </div>
            <div className="text-[.28rem] font-[400] text-[var(--colour-18)]">{t('home.faster_connection')}</div>
          </div>
        </div>
      </div>

      <div className="mt-[.6rem]">
        <InstallButton fromApk={fromApk} />
      </div>

      <div className="mt-[.6rem] text-[var(--colour-31)] text-[.36rem] font-[700]">{t('home.toinstall')}</div>

      <div className="mt-[.32rem] mx-auto text-[.28rem] font-[500] text-[var(--colour-18)] leading-[.3rem] space-y-[.2rem]">
        <div>1. {t('home.downloadpwaTip1')}</div>
        <div>2. {t('home.downloadpwaTip2')}</div>
        <div>3. {t('home.downloadpwaTip3')}</div>
        <div>4. {t('home.downloadpwaTip4')}</div>
      </div>

      <div className="mt-[.7rem] mx-auto rounded-[.16rem] bg-[#F5F6F4] p-[.06rem_.16rem_.12rem_.16rem] mb-[.22rem]">
        <div className="text-[.28rem] font-[600] text-[var(--colour-31)] flex items-center gap-[.1rem]">
          <div className="w-[.28rem] h-[.28rem] text-[var(--colour-25)]">
            <Icon name={IconName.PwaWarning} />
          </div>
          <div>{t('home.friendly')}</div>
        </div>
        <div className="text-[.2rem] font-[500] text-[var(--colour-18)]">{t('home.installit')}</div>
      </div>
    </div>
  );
};

export default IncompleteRecharge;
