import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Close from '../close';
import CodeBox from '../../views/pay/br/design/withdraw/components/codeBox';
import ResultTips from '../resultTips';
import useHandleLogic from '../../hooks/useHandleLogic';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';
import ErrorTip from '../../views/pay/br/design/withdraw/components/errorTip';

const EnterPassword = ({ handleClose = () => {}, clickVerifyPassword = () => {}, isConfirmPassword = false }) => {
  const { t } = useTranslation();
  const { handleClickManMadeInPage } = useHandleLogic();
  const [showTips, setShowTips] = useState(false);

  const [password, setPassword] = useState('');
  const [showErrorTip, setShowErrorTip] = useState(false);

  const handleCodeChange = (codeStr) => {
    setPassword(codeStr);
    if (codeStr.length < 6) {
      setShowErrorTip(true);
    } else {
      setShowErrorTip(false);
    }
  };

  return (
    <div className="relative h-[auto] p-[.33rem_.32rem_.34rem_.32rem] w-[6.86rem] rounded-normal bg-[var(--colour-35)] border border-[var(--colour-6)]">
      <div className="text-[.32rem] font-[400] leading-[.38rem] text-[var(--colour-48)] text-center mb-[.49rem]">
        {t('deposit-withdraw-wg.withdraw_enter_password')}
      </div>

      <div className="active:scale-[.95] transition-all select-none w-[.58rem] h-[.58rem] border border-[var(--colour-56)] flex justify-center items-center rounded-[50%] absolute left-[50%] bottom-[-0.88rem] translate-x-[-50%]">
        <Close onClick={handleClose} className="!w-[.58rem] !h-[.58rem] text-[var(--colour-56)]" />
      </div>

      <div className="mb-[.58rem] relative">
        <CodeBox
          title={t('deposit-withdraw-wg.withdraw_withdrawal_password')}
          handleCodeChange={handleCodeChange}
          className=""
          headerClassName="mb-[.16rem]"
        />
        {showErrorTip ? <ErrorTip /> : null}
      </div>
      <div className="flex justify-between mb-[.62rem]">
        <div className="text-[.24rem] font-[500] text-[var(--colour-41)] leading-[.3rem]">
          {t('deposit-withdraw-wg.withdraw_enter_password_text1')}
        </div>
        <div
          className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-13)] whitespace-nowrap ml-[.2rem]"
          onClick={() => {
            handleClickManMadeInPage();
          }}
        >
          {t('deposit-withdraw-wg.withdraw_enter_password_forgot_password')}
        </div>
      </div>
      <LimitClickWrapper
        className="active:scale-[.95] transition-all select-none w-full h-[.7rem] rounded-normal bg-[var(--colour-1)] text-[.24rem] font-[500] text-[var(--colour-2)] cursor-pointer flex items-center justify-center"
        onClick={() => {
          if (isConfirmPassword) return;
          if (password.length < 6) {
            return setShowErrorTip(true);
          }
          clickVerifyPassword(password);
        }}
      >
        <ElementWithLoading
          isLoading={isConfirmPassword}
          normalEl={<div className="">{t('deposit-withdraw-wg.withdraw_enter_password_next')}</div>}
        />
      </LimitClickWrapper>

      <ResultTips setShowVerify={setShowTips} showVerify={showTips} content={'Online customer service is not open.'} />
    </div>
  );
};
export default EnterPassword;
