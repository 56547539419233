import NTemplate001 from './normal/template001';
import DTemplate003 from './design/template003';
import { getTemplateId } from '../../../utils/helper';

const Index = function () {
  const templateId = getTemplateId();
  // console.log('tid', templateId);

  return templateId == 'd-template003' ? <DTemplate003 /> : <NTemplate001 />;
};

export default Index;
