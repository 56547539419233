import cn from 'classnames';
import { Fragment, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import HeaderBack from '../../../../components/headerBack';
import ReportAccount from './components/reportAccount';
import ReportBets from './components/reportBets';
import Report from './components/report';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import useGoback from '../../../../hooks/useGoBack';

const DTemplate003 = ({ page = '', showPositionNum, setCurrentIndex }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { current } = useParams();
  const navigate = useGlobalNavigate();
  const [from, setFrom] = useState(location.state?.from || '/mine');

  const tabList = [
    {
      label: t('report.account'),
      component: ReportAccount,
    },
    {
      label: t('report.bets'),
      component: ReportBets,
    },
    {
      label: t('report.title'),
      component: Report,
    },
  ];

  const handleClick = (index) => {
    navigate(toLocalePath(`/report/${index}`));
  };

  return (
    <div className="!px-[0px] container bg-[var(--colour-34)] h-full">
      <HeaderBack
        title={t('report.title')}
        onClick={() => {
          if (from.includes('report')) {
            navigate('/mine');
          } else {
            navigate(from);
          }
        }}
        className={`!bg-[var(--colour-86)]`}
      />

      <div className="h-[.72rem] w-full">
        <div
          className={`w-full fixed z-[10] left-0 top-[.9rem] h-[.72rem] border-b-[.02rem] border-[var(--colour-6)] flex items-center gap-[.52rem] px-[.24rem] bg-[var(--colour-35)]`}
        >
          {tabList &&
            tabList.map((item, index) => {
              return (
                <div
                  className={cn('h-full text-[.24rem] relative font-[500] flex items-center cursor-pointer', {
                    'text-[var(--colour-13)]': page != '' ? showPositionNum == index : current == index,
                    'text-[var(--colour-14)]': !(page != '' ? showPositionNum == index : current == index),
                  })}
                  key={index}
                  onClick={() => {
                    if (page == '') {
                      handleClick(index);
                    } else {
                      setCurrentIndex(index);
                    }
                  }}
                >
                  {item.label}
                  {(page != '' && showPositionNum == index) || (page == '' && current == index) ? (
                    <>
                      <div className="absolute w-full h-[.02rem] bg-[var(--colour-1)] bottom-0 left-0"></div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {tabList &&
        tabList.map((item, index) => {
          const shouldRenderByPage = page == '' ? current == index : showPositionNum == index;
          return (
            shouldRenderByPage &&
            item.component && (
              <Fragment key={index}>
                <item.component />
              </Fragment>
            )
          );
        })}
    </div>
  );
};

export default DTemplate003;
