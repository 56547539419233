import { useState, useRef, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Tabs } from 'react-vant';
import RecentlyListAll from '../../components/recentlyListAll';
import HomeGameList from '../../components/homeGameList';
import { useNavigate, useLocation } from 'react-router-dom';
import { toLocalePath, toNoLocalePath } from '../../utils/localePath';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  setMobileTagIndex,
  setPpopularIndex,
  setDrawerMenuVisible,
  setDialogModalVisible,
  DialogModalChildrenKey,
  setReloadWebsiteInitData,
} from '../../redux/reducers/appSlice';
import Icon, { IconName } from '../icon';
import { cmsImgUrl, isLogin, isLightTheme } from '../../utils/helper';
import { createPortal } from 'react-dom';
import { get } from 'lodash';
import { useStateIfMounted } from 'use-state-if-mounted';
import GameItem from '../gameItem';
import NoData from '../noData';
import LoadMoreText from '../loadMoreText';
import ViewAllText from '../homeModuleHeader/viewAllText';
import ProviderItem from '../providerItem';
import useLangServerConfig from '../../hooks/useLangServerConfig';
import { useGlobalNavigate } from '../../contexts/navigateContext';
import StickyBox from 'react-sticky-box';

const HeaderHeight = 43; //header高度
const MenuBarHeight = 62; //menubar高度

const GameListProvider = ({ setTagsIndex }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const { mobileTagIndex, popularIndex, websiteInitData, websiteInitConfig, isSportEnabled } = useSelector(
    (state) => ({
      mobileTagIndex: state.app.mobileTagIndex,
      popularIndex: state.app.popularIndex,
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
      isSportEnabled: state.app.isSportEnabled,
    }),
    shallowEqual,
  );

  const innerRef = useRef(null);
  const [myMobileTagIndex, setMyMobileTagIndex] = useState('1011'); //父组件的默认值也是1011
  const [isAbsolute, setIsAbsolute] = useState(false); //粘性盒子是否使用absolute定位
  const [providerList, setProviderList] = useState([]); //保存provider列表
  const [absoluteTopNum, setAbsoluteTopNum] = useState(HeaderHeight);
  const { getServerLangText } = useLangServerConfig();
  useEffect(() => {
    if (websiteInitData && websiteInitData.template) {
      const data = websiteInitData.template.find((item) => {
        return item.module == 'ColumnData';
      });
      if (data) {
        setDataList(data.config);
        dispatch(setMobileTagIndex(data.config[0].game_type_id));
      }
    }
    if (websiteInitConfig.provider_list) {
      setProviderList(websiteInitConfig.provider_list);
    }
  }, [websiteInitData, websiteInitConfig]);

  const [elRefs, setElRefs] = useState([]);
  const [distance, setDistance] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);

  const [dataList, setDataList] = useState([]);

  const [popularList, setPoprlarList] = useState([
    { category_id: '1011' },
    { category_id: '003' },
    { category_id: '001' },
  ]);

  //这里需要设置组件内的状态确保是最新值
  useEffect(() => {
    setMyMobileTagIndex(mobileTagIndex);
  }, [mobileTagIndex]);

  const handleScroll = () => {
    let dom = document.querySelector('.footer_dom');
    if (dom) {
      let obj = dom.getBoundingClientRect();
      let dom_sticky = document.querySelector('.dom_sticky');

      if (dom_sticky) {
        // header高度 +  stickyClassName的 pt.1rem + 左侧列表的高度
        let topNum = HeaderHeight + 5 + dom_sticky.getBoundingClientRect().height;
        let absoluteTopNum = topNum - obj.top + 14;
        if (obj.top < topNum + 14) {
          setIsAbsolute(true);
          setAbsoluteTopNum(absoluteTopNum);
        } else {
          setIsAbsolute(false);
        }

        let domList = [];
        domList = dataList.map((item) => {
          return document.querySelector(`.dom_${item.game_type_id}`);
        });

        let viewDom = domList.find((item) => {
          return isElementPartiallyInViewport(item);
        });
        if (viewDom) {
          dispatch(setMobileTagIndex(viewDom.id));
        }
      }
    }
  };

  useEffect(() => {
    const handleScrollWrapper = (e) => {
      handleScroll();
    };
    document.addEventListener('overlayScrollerCustomScroll', handleScrollWrapper);
    return () => document.removeEventListener('overlayScrollerCustomScroll', handleScrollWrapper);
  }, [dataList]);

  useEffect(() => {
    if (popularList) {
      setElRefs((elRefs) =>
        Array(popularList.length)
          .fill('')
          .map((_, i) => elRefs[i] || createRef()),
      );
    }
  }, [popularList]);

  useEffect(() => {
    if (popularList && popularList.length > 0 && popularIndex) {
      let resIndex = popularList.findIndex((item) => {
        return item.category_id == popularIndex;
      });

      if (resIndex > -1 && elRefs[resIndex] && elRefs[resIndex].current) {
        setCurrentWidth(elRefs[resIndex].current.offsetWidth);
        setDistance(elRefs[resIndex].current.getBoundingClientRect().left - 85 - 10 - 10); //左侧的padding + 外边盒子的padding + 'space-x-[.2rem]'
      }
    }
  }, [popularList, popularIndex, elRefs.length]);

  return dataList.length > 0 ? (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <StickyBox offsetTop={5}>
        <div className="w-[1.68rem] dom_sticky">
          {dataList.map((item, index) => {
            return (
              <div
                key={index}
                className={cn(
                  'w-full h-[1.04rem] rounded-[.16rem] text-[.24rem] font-[500] leading-[.3rem] flex items-center flex-col pt-[.16rem] pl-[.08rem] pr-[.04rem]  mb-[.2rem]',
                  {
                    'bg-[var(--colour-1)] text-[var(--colour-2)]':
                      myMobileTagIndex == item.game_type_id &&
                      websiteInitConfig?.website_info.website_theme != 'theme-15',
                    'bg-[var(--colour-16)] text-[var(--colour-14)]':
                      !(myMobileTagIndex == item.game_type_id) &&
                      websiteInitConfig?.website_info.website_theme != 'theme-15',
                    'bg-menu-selected text-[var(--colour-2)]':
                      myMobileTagIndex == item.game_type_id &&
                      websiteInitConfig?.website_info.website_theme == 'theme-15',
                    'bg-menu-default text-[var(--colour-14)]':
                      !(myMobileTagIndex == item.game_type_id) &&
                      websiteInitConfig?.website_info.website_theme == 'theme-15',
                  },
                )}
                onClick={() => {
                  dispatch(setMobileTagIndex(item.game_type_id));
                  let dom = document.querySelector(`.dom_${item.game_type_id}`);
                  let topNum = dom.getBoundingClientRect().top;
                  const offsetTop = topNum - HeaderHeight - 20;
                  const myEvent = new CustomEvent('overlayScrollerScrollTo', {
                    detail: {
                      offsetTop: offsetTop,
                      isOffset: true,
                    },
                  });
                  document.dispatchEvent(myEvent);
                }}
              >
                <div className="w-[.48rem] h-[.48rem]">
                  <img
                    className="w-full"
                    src={
                      myMobileTagIndex == item.game_type_id
                        ? cmsImgUrl(item.icon_selected)
                        : cmsImgUrl(item.icon_unselected)
                    }
                  />
                </div>
                <div className="text-center mt-[-0.06rem]">{getServerLangText(item.lang_key)}</div>
              </div>
            );
          })}
        </div>
      </StickyBox>
      <div ref={innerRef} className="flex-1 pl-[.16rem]">
        {/* hot */}
        <div
          id={`${dataList[0].game_type_id}`}
          className={`dom_${dataList[0].game_type_id} check_dom_loading_completed`}
        >
          {/* 标签筛选 */}
          <div className="border-b border-solid border-[var(--colour-6)] game_list_provider_tabs">
            <Tabs
              color="var(--colour-1)" //选中下划线颜色
              titleActiveColor="var(--colour-13)" //文字选中颜色
              titleInactiveColor="var(--colour-14)" //文字默认颜色
              background={isLightTheme() ? 'var(--colour-4)' : 'transparent'} //背景色
              lineWidth={currentWidth} //下划线宽度
              lineHeight=".04rem" //下划线高度
              onClickTab={(e) => {
                dispatch(setPpopularIndex(popularList[e.index].category_id));
                if (popularList[e.index].category_id == '1011') {
                  dispatch(setReloadWebsiteInitData());
                }
              }}
            >
              {popularList.map((item, index) => (
                <Tabs.TabPane
                  key={index}
                  title={<span ref={elRefs[index]}>{t(`home.category_${item.category_id}`)}</span>}
                  titleClass="text-[.24rem] font-[500] leading-[.3rem]"
                ></Tabs.TabPane>
              ))}
            </Tabs>
          </div>

          <div className="pt-[.16rem]">
            {popularIndex == '1011' ? (
              dataList[0]['type'] == 'game_list' ? (
                <GameList list={dataList[0]['data']} data={dataList[0]} isSportEnabled={isSportEnabled} />
              ) : (
                <ProviderList list={dataList[0]['data']} item={dataList[0]} isSportEnabled={isSportEnabled} />
              )
            ) : popularIndex == '001' ? (
              <>
                <HomeGameList
                  tagsIndex={popularIndex}
                  providerSelect={''}
                  isProvider={false}
                  searchVal={''}
                  isSearch={false}
                  setTagsIndex={setTagsIndex}
                  useNewNoData={true}
                  loadMoreWithText={true}
                  lang_key_title={t('home.category_001')}
                />
              </>
            ) : popularIndex == '003' ? (
              <>
                <RecentlyListAll useNewNoData={true} loadMoreWithText={true} lang_key_title={t('home.category_003')} />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {dataList.map((item, index) => {
          return index == 0 ? (
            <div key={index}></div>
          ) : item.type == 'game_list' ? (
            <div key={index}>
              <Line />
              <GameList list={item['data']} data={item} isShowHeader={true} isSportEnabled={isSportEnabled} />
            </div>
          ) : (
            <div key={index}>
              <Line />
              <ProviderList list={item['data']} item={item} isSportEnabled={isSportEnabled} isShowHeader={true} />
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};
export default GameListProvider;

function toBtoa(obj) {
  return btoa(JSON.stringify({ icon_selected: obj.icon_selected, icon_unselected: obj.icon_unselected }));
}

//根据source去列表寻找name
function getProviderName(source, list) {
  let res = list.find((item) => {
    return item.source == source;
  });
  if (res) {
    return res.name;
  } else {
    return '';
  }
}

// 获取 当前 滚动条的长度， 水平 && 垂直方向
function getScrollPosition() {
  let x, y;
  if (!!window.pageXOffset) {
    x = window.pageXOffset;
    y = window.pageYOffset;
  } else {
    x = (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    y = (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }
  return { x, y };
}

//是否在可视区域

function isElementPartiallyInViewport(element) {
  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  const topVisible = rect.top >= HeaderHeight && rect.top <= windowHeight - MenuBarHeight;
  const bottomVisible = rect.bottom - HeaderHeight >= 0 && rect.bottom <= windowHeight - MenuBarHeight;
  const otherVisible = rect.top - HeaderHeight <= 0 && rect.bottom + MenuBarHeight >= windowHeight;
  return topVisible || bottomVisible || otherVisible;
}

//假loadmore数据列表
const GameList = ({ list, data, isShowHeader = false, isSportEnabled }) => {
  const { t } = useTranslation();
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [showList, setShowList] = useStateIfMounted([]);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const { getServerLangText } = useLangServerConfig();
  const [pageSize] = useStateIfMounted(() => {
    return data.category_id == '1011' ? 15 : 6;
  });
  const [total, setTotal] = useStateIfMounted(0);
  const [currentNum, setCurrentNum] = useStateIfMounted(0);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (list && showList) {
      if (showList.length < list.length) {
        setShowLoadingMore(true);
      } else {
        setShowLoadingMore(false);
      }
    }
  }, [showList, list]);

  useEffect(() => {
    if (list && list.length > 0) {
      setTotal(list.length);
      setCurrentNum(currentPage * pageSize);
      setShowList(list.slice(0, currentPage * pageSize));
    }
  }, [currentPage, list, pageSize]);

  const navigate = useGlobalNavigate();
  const handleProviderClick = (item) => {
    //是体育
    let url = `/gamelist?id=${item.game_type_id}&source='999'&sourceName=${getServerLangText(item.lang_key)}&img=${toBtoa(item)}`;
    if (item.game_type_id == '999') {
      if (isSportEnabled) {
        url = '/sport';
      } else {
        return;
      }
    }
    navigate(toLocalePath(url));
  };

  return (
    <>
      <div id={`${data.game_type_id}`} className={`${isShowHeader ? `pt-[.16rem] dom_${data.game_type_id}` : ''}`}>
        <>
          {isShowHeader ? (
            <div className="flex items-center justify-between mb-[.16rem] pr-[.14rem]">
              <div className="flex items-center">
                <div className="w-[.52rem] mr-[.08rem]">
                  <img className="w-full" src={cmsImgUrl(data.icon_selected)} />
                </div>
                <div className="text-[.32rem] font-[500] leading-[.38rem] text-[var(--colour-10)]">
                  {getServerLangText(data.lang_key)}
                </div>
              </div>

              <ViewAllText
                onClick={() => {
                  handleProviderClick(data);
                }}
                className="!text-[.24rem] !font-[500] !leading-[.3rem] !text-[var(--colour-48)]"
              />
            </div>
          ) : null}
          {showList && showList.length > 0 ? (
            <div className={cn('games__row !justify-around pr-[.14rem]')}>
              {showList &&
                showList.length > 0 &&
                showList.map((item, index) => (
                  <GameItem
                    model={item}
                    key={item.id}
                    handleFavoriteCallback={() => {}} //不是收藏页签不用调用方法
                    handleFavoriteNeedCallback={false} //不是收藏页签，可以写死
                  />
                ))}
            </div>
          ) : (
            <NoData className="py-[.4rem]" />
          )}
          {showLoadingMore ? (
            <LoadMoreText
              className={`mt-[.16rem]`}
              onClick={handleLoadMore}
              showLoading={false}
              currentNum={currentNum}
              total={total}
              lang_key_title={getServerLangText(data.lang_key)}
            />
          ) : (
            <></>
          )}
        </>
      </div>
    </>
  );
};

//厂商列表
const ProviderList = ({ list, item, isSportEnabled, isShowHeader = false }) => {
  const navigate = useGlobalNavigate();
  const { getServerLangText } = useLangServerConfig();
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [showList, setShowList] = useStateIfMounted([]);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [pageSize] = useStateIfMounted(() => {
    return item.category_id == '1011' ? 15 : 6;
  });
  const [total, setTotal] = useStateIfMounted(0);
  const [currentNum, setCurrentNum] = useStateIfMounted(0);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (list && showList) {
      if (showList.length < list.length) {
        setShowLoadingMore(true);
      } else {
        setShowLoadingMore(false);
      }
    }
  }, [showList, list]);

  useEffect(() => {
    if (list && list.length > 0) {
      setTotal(list.length);
      setCurrentNum(currentPage * pageSize);
      setShowList(list.slice(0, currentPage * pageSize));
    }
  }, [currentPage, list, pageSize]);

  const handleProviderClick = (item, dataItem) => {
    //是体育
    let url = `/gamelist?id=${item.game_type_id}&source=${dataItem.source}&sourceName=${getServerLangText(item.lang_key)}&img=${toBtoa(item)}`;
    if (item.game_type_id == '999') {
      if (isSportEnabled) {
        url = '/sport';
      } else {
        return;
      }
    }
    navigate(toLocalePath(url));
  };

  const handleProviderClickHeader = (item) => {
    //是体育
    let url = `/gamelist?id=${item.game_type_id}&source='999'&sourceName=${getServerLangText(item.lang_key)}&img=${toBtoa(item)}`;
    if (item.game_type_id == '999') {
      if (isSportEnabled) {
        url = '/sport';
      } else {
        return;
      }
    }
    navigate(toLocalePath(url));
  };

  return (
    <div id={`${item.game_type_id}`} className={`pt-[.16rem] dom_${item.game_type_id}`}>
      {isShowHeader ? (
        <div className="flex items-center justify-between mb-[.16rem] pr-[.14rem]">
          <div className="flex items-center">
            <div className="w-[.52rem] mr-[.08rem]">
              <img className="w-full" src={cmsImgUrl(item.icon_selected)} />
            </div>
            <div className="text-[.32rem] font-[500] leading-[.38rem] text-[var(--colour-10)]">
              {getServerLangText(item.lang_key)}
            </div>
          </div>
          <ViewAllText
            onClick={() => {
              handleProviderClickHeader(item);
            }}
            className="!text-[.24rem] !font-[500] !leading-[.3rem] !text-[var(--colour-48)]"
          />
        </div>
      ) : null}

      {showList && showList.length > 0 ? (
        <div className={cn('games__row !justify-around pr-[.14rem]')}>
          {showList &&
            showList.length > 0 &&
            showList.map((dataItem, index) => (
              <ProviderItem
                key={`${dataItem.source}-${dataItem.game_id}-${dataItem.img}`}
                model={dataItem}
                clickProviderItem={() => {
                  if (dataItem.open_status == 1) {
                    handleProviderClick(item, dataItem);
                  }
                }}
                game_type_id={item.game_type_id}
              />
            ))}
        </div>
      ) : (
        <NoData className="py-[.4rem]" />
      )}
      {showLoadingMore ? (
        <LoadMoreText
          className={`mt-[.16rem]`}
          onClick={handleLoadMore}
          showLoading={false}
          currentNum={currentNum}
          total={total}
          lang_key_title={getServerLangText(item.lang_key)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

function Line() {
  return <div className="w-full h-[.02rem] bg-[var(--colour-6)] mt-[.3rem] mb-[.2rem]"></div>;
}
