import { isIPhone, isPWA } from '../../utils/deviceUtils';
import { useEffect, useState } from 'react';
import DialogModal from '../dialogModal';
import UnofficialPwaGuide from '../dialogModal/unofficialPwaGuide';
import { useSelector, shallowEqual } from 'react-redux';

const TopPopup = () => {
  const [visible, setVisible] = useState(false);

  const { channelConfig } = useSelector(
    (state) => ({
      channelConfig: state.app.websiteInitConfig.channel_config,
    }),
    shallowEqual,
  );

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const value = localStorage.getItem('unofficialpwaTag');
    if (isPWA() && channelConfig?.pwa_popup_switch == '1' && value == '2' && !isIPhone()) {
      setVisible(true);
    }
  }, [channelConfig]);

  return (
    <DialogModal
      zIndex="40001"
      shouldCloseOnOverlayClick={channelConfig?.pwa_popup_mode == '2' ? true : false}
      noentered={true}
      style={{
        content: {
          display: 'inline-block',
          textAlign: 'left',
          top: '0px',
          maxWidth: '100%',
          cursor: 'default',
          outline: '0px',
          verticalAlign: 'middle',
          zIndex: '49',
          borderRadius: '.2rem',
        },
      }}
      handleClose={handleClose}
      isOpen={visible}
    >
      <UnofficialPwaGuide handleClose={handleClose} />
    </DialogModal>
  );
};

export default TopPopup;
