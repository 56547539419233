import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Input from '../../input';
import LimitClickWrapper from '../../limitClickWrapper';
import Icon, { IconName } from '../../icon';
import { toast } from 'react-toastify';
import { setDialogModalVisible, DialogModalChildrenKey, setLoginStatus } from '../../../redux/reducers/appSlice';
import { setLoginInfo, setLoginMethod, setLoginType, setUserObj } from '../../../redux/reducers/userSlice';
import { useStateIfMounted } from 'use-state-if-mounted';
import ThirdPartyLogin from '../components/loginThirdParty';
import { Trans, useTranslation } from 'react-i18next';
import { RegisterReq, RegisterReqNew } from '../../../api/index-auth';
import { useNavigate } from 'react-router-dom';
import ElementWithLoading from '../../elementWithLoading';
import InputBySendCode from '../../inputBySendCode2';
import {
  setVipLevelLogin,
  TextPlaces,
  UserCache,
  isControllableSite,
  getRegisterMethodsSort,
  TextPlacesName,
  setInputNum,
} from '../../../utils/helper';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { get } from 'lodash';
import InputByPhoneOrEmail, { useCaseList } from '../inputByPhoneOrEmail';
import useInitActivity from '../../../hooks/useInitActivity';
import TocTip from '../tocTip';
import moment from 'moment';
import cn from 'classnames';
import InputDate from '../../inputDate';
import useRegister from '../../../hooks/useRegister';
import SwitchWrapper from '../../switchWrapper';
import useHandleLogic from '../../../hooks/useHandleLogic';
import OnlineService from '../onlineService';
import InputErrorTips from '../../inputErrorTips';
import { useUpdateEffect } from 'ahooks';
// import CaptchaInput from '../loginForm/captchaInput';
import {
  removeSpecialSymbols,
  deleteCPF,
  formatCPF,
  isValidCPF,
  phoneVerify,
  phoneVerifyRegex,
  phoneVerifyRegex2,
} from '../../../utils/inputHelper';

const LastNameInput = ({ handleLastNameChange = () => {}, value = '', className = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Input
        value={value}
        onChange={handleLastNameChange}
        type="text"
        placeholder={t('account.Last name')}
        innerClassName="!h-[.8rem] !border-transparent"
        topClassName={`rounded-normal overflow-hidden border-solid bg-[var(--colour-34)]`}
      />
    </div>
  );
};

const FirstNameInput = ({ handleFirstNameChange = () => {}, value = '', className = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Input
        value={value}
        onChange={handleFirstNameChange}
        type="text"
        placeholder={t('account.First name')}
        innerClassName="!h-[.8rem] !border-transparent"
        topClassName={`rounded-normal overflow-hidden border-solid bg-[var(--colour-34)]`}
      />
    </div>
  );
};

const Form = ({ changeLogin, handleClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    inviteCode,
    activityId,
    osType,
    advertisingId,
    loginStatus,
    websiteInitConfig,
    userDetailSwitch,
    dialogModalProp,
  } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.inviteCode', ''),
      activityId: get(state, 'user.activityId', ''),
      osType: get(state, 'user.osType', ''),
      advertisingId: get(state, 'user.advertisingId', ''),
      loginStatus: state.app.loginStatus,
      websiteInitConfig: state.app.websiteInitConfig,
      userDetailSwitch: false,
      dialogModalProp: state.app.dialogModalProp,
    }),
    shallowEqual,
  );

  const {
    hasBeenSent,
    requesting,
    inputType,
    sendCodeformData,
    formData,
    codeLoading,
    setInputType,
    setHasBeenSent,
    setSendCodeformData,
    handleSendCode,
    handleSubmit,
    setFormData,
    handleReReqCaptcha,
    captchaInitConfig,
  } = useRegister();

  const [showInput1Error, setShowInput1Error] = useState(false);
  const [showInput2Error, setShowInput2Error] = useState(false);
  const [showInputNameError, setShowInputNameError] = useState(false);
  const [showInputCpfError, setShowInputCpfError] = useState(false);
  const [showInviteCode, setShowInviteCode] = useState(false);
  const [inputTypeList, setInputTypeList] = useStateIfMounted([]);
  const emailRegisterEnable = false;

  const [showNameCpf, setShowNameCpf] = useState(false);
  const [cpfErrorType, setCpfErrorType] = useState(6);
  const [phoneErrorType, setPhoneErrorType] = useState(7); //手机号红色提示

  const readCache = false;

  const phoneValue = useRef(readCache ? UserCache.getPhone() : '');
  const emailValue = useRef(readCache ? UserCache.getEmail() : '');
  const userNameValue = useRef(readCache ? UserCache.getUserName() : '');

  let intervalId = null;
  const totalSendCodeCountdown = 60;
  const [sendCodeCountdown, setSendCodeCountdown] = useStateIfMounted(totalSendCodeCountdown);

  useEffect(() => {
    if (hasBeenSent) {
      intervalId = setInterval(() => {
        setSendCodeCountdown((prev) => {
          return prev - 1 <= 0 ? 0 : prev - 1;
        });
      }, 1000);
      return () => window.clearInterval(intervalId);
    } else {
      setSendCodeCountdown(totalSendCodeCountdown);
    }
    return () => {
      window.clearInterval(intervalId);
    };
  }, [hasBeenSent]);

  useEffect(() => {
    if (sendCodeCountdown <= 0) {
      window.clearInterval(intervalId);
      setHasBeenSent(false);
    }
  }, [sendCodeCountdown]);

  useEffect(() => {
    if (inputType == UserCache.LoginMethodValue.Email) {
      emailValue.current = formData.mobile;
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      phoneValue.current = formData.mobile;
    } else if (inputType == UserCache.LoginMethodValue.UserName) {
      userNameValue.current = formData.mobile;
    }
  }, [formData.mobile]);

  const onChangeRegText = (val) => {
    setFormData({ ...formData, mobile: val });
    setSendCodeformData({ ...sendCodeformData, mobile: val });
  };

  useEffect(() => {
    if (inputType == UserCache.LoginMethodValue.Email) {
      onChangeRegText(emailValue.current);
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      onChangeRegText(phoneValue.current);
    } else if (inputType == UserCache.LoginMethodValue.UserName) {
      onChangeRegText(userNameValue.current);
    }
  }, [inputType]);

  useUpdateEffect(() => {
    handleCheckInput1(formData.mobile);
  }, [formData.mobile]);

  const handlePasswordChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/[^0-9a-zA-Z]/g, '').slice(0, 24);
    setFormData({ ...formData, password: v2 });
    handleCheckInput2(v2);
  };

  const handleNameChange = (ev) => {
    const v = ev.target.value;
    let v2 = removeSpecialSymbols(TextPlacesName(v, 100));
    setFormData({ ...formData, name: v2 });
    handleCheckInputName(v2);
  };

  const handleCpfChange = (event) => {
    const cpfAllowedCharacters = /[^0-9.-]+/g;
    const value = event.target.value.replace(cpfAllowedCharacters, '');
    const lastValue = formData.cpf;
    if (String(value).length < String(lastValue).length) {
      //如果是删除
      // const cpf = deleteCPF(lastValue);
      const cpf = String(value) != '' ? deleteCPF(lastValue) : '';

      setFormData({ ...formData, cpf: cpf });
      handleCheckInputCpf(cpf.replace(/[\.-]/g, ''));
    } else {
      let cpf = formatCPF(value.replace(/[\.-]/g, ''));
      const maxLength = 11 + 3;
      if (cpf.length > maxLength) {
        // 截断输入的值
        cpf = cpf.slice(0, maxLength);
      }
      setFormData({ ...formData, cpf: cpf });
      handleCheckInputCpf(cpf.replace(/[\.-]/g, ''));
    }
  };

  const handleSetInputType = (t) => {
    setInputType(t);
  };

  const onChangeCryCode = (ev) => {
    setSendCodeformData({ ...sendCodeformData, cryCode: ev.target.value });
    setFormData({ ...formData, cryCode: ev.target.value });
  };

  const handleBirthdayChange = (date) => {
    setFormData({
      ...formData,
      birthDate: moment(date).format('YYYY/MM/DD'),
    });
  };
  const handleFirstNameChange = (event) => {
    const firstName = event.target.value;
    setFormData({
      ...formData,
      firstName,
    });
  };
  const handleLastNameChange = (event) => {
    const lastName = event.target.value;
    setFormData({
      ...formData,
      lastName,
    });
  };

  useEffect(() => {
    const defaultInputTypes = getRegisterMethodsSort();
    const registerMethodsSort =
      defaultInputTypes.length > 0 ? defaultInputTypes : [`${UserCache.LoginMethodValue.UserName}`];
    const showTypeList = registerMethodsSort.map((method) => {
      return {
        key: method,
        icon: (() => {
          switch (method) {
            case UserCache.LoginMethodValue.Phone:
              return IconName.Phone;
            case UserCache.LoginMethodValue.Email:
              return IconName.Email;
            case UserCache.LoginMethodValue.UserName:
              return IconName.LoginAccount;
          }
        })(),
        show: (() => {
          return true;
        })(),
        text: (() => {
          switch (method) {
            case UserCache.LoginMethodValue.Phone:
              return t('login-signup.phone');
            case UserCache.LoginMethodValue.Email:
              return t('login-signup.email');
            case UserCache.LoginMethodValue.UserName:
              return t('login-signup.Username');
          }
        })(),
      };
    });

    setInputTypeList(showTypeList);

    // if (!UserCache.getLoginMethod()) {
    const firstShow = showTypeList[0];
    setInputType(firstShow.key);
    // }

    if (
      websiteInitConfig?.system_config?.register_config?.platform &&
      websiteInitConfig?.system_config?.register_config?.platform.length > 0
    ) {
      let findPlatform = websiteInitConfig?.system_config?.register_config?.platform.find((item) => {
        return item.method == 1;
      });
      if (findPlatform && findPlatform.required_cpf_name == 1) {
        setShowNameCpf(true);
      }
    }
  }, [websiteInitConfig?.system_config?.register_config?.platform]);

  const handleCheckInput1 = (value) => {
    if (inputType == UserCache.LoginMethodValue.UserName) {
      const regex = /^[a-zA-Z0-9]{6,24}$/;
      if (!regex.test(value)) {
        setShowInput1Error(true);
      } else {
        setShowInput1Error(false);
      }
    } else {
      handleCheckEmailOrPhone(value);
    }
  };

  const handleCheckInput2 = (value) => {
    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(value)) {
      setShowInput2Error(true);
    } else {
      setShowInput2Error(false);
    }
  };

  const handleCheckInputName = (value) => {
    const regex = /^(?!.* {2,})[^'"\n\r\t\\]*[\s]?[^'"\n\r\t\\]*$/;
    if (!regex.test(value) || value == '') {
      setShowInputNameError(true);
    } else {
      setShowInputNameError(false);
    }
  };

  const handleCheckInputCpf = (value) => {
    if (value == '') {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else if (value.length < 11 || !/^\d+$/.test(value)) {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else {
      setShowInputCpfError(false);
    }
  };

  const handleCheckEmailOrPhone = (value) => {
    //邮箱手机号注册才做失去焦点的判断。
    if (inputType == UserCache.LoginMethodValue.Email) {
      if (value == '') {
        setShowInput1Error(true);
      } else {
        setShowInput1Error(false);
      }
    }
    if (websiteInitConfig?.website_info?.country_code == '55') {
      if (inputType == UserCache.LoginMethodValue.Phone) {
        if (value == '') {
          setPhoneErrorType(7);
          setShowInput1Error(true);
        } else if (!phoneVerify(value)) {
          setPhoneErrorType(8);
          setShowInput1Error(true);
        } else {
          setShowInput1Error(false);
        }
      }
    } else {
      if (value == '') {
        setShowInput1Error(true);
      } else {
        setShowInput1Error(false);
      }
    }
  };

  const handleInput1Blur = (evt) => {
    handleCheckEmailOrPhone(evt.target.value);
  };

  const handleInput1Focus = (evt) => {
    handleCheckInput1(evt.target.value);
  };

  const handleInput2Focus = (evt) => {
    handleCheckInput2(evt.target.value);
  };

  const handleInputNameFocus = (evt) => {
    handleCheckInputName(evt.target.value);
  };
  const handleInputCpfFocus = (evt) => {
    handleCheckInputCpf(evt.target.value.replace(/[\.-]/g, ''));
  };
  const handleInputNameBlur = (evt) => {
    let v = evt.target.value;
    v = v.trim();
    setFormData({ ...formData, name: v });
    handleCheckInputName(v);
  };
  const handleInputCpfBlur = (evt) => {
    let v = evt.target.value;
    const isTrueCPF = isValidCPF(v);
    if (v.replace(/[\.-]/g, '') == '') {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else if (v.replace(/[\.-]/g, '').length < 11 || !/^\d+$/.test(v.replace(/[\.-]/g, ''))) {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else if (!isTrueCPF) {
      setCpfErrorType(5);
      setShowInputCpfError(true);
    } else {
      setShowInputCpfError(false);
    }
  };

  const handleCheckForm = () => {
    let returnValue = true;
    if (inputType == UserCache.LoginMethodValue.Email || inputType == UserCache.LoginMethodValue.UserName) {
      if (formData.mobile == '') {
        setShowInput1Error(true);
        returnValue = false;
      } else {
        setShowInput1Error(false);
      }
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      if (websiteInitConfig?.website_info?.country_code == '55') {
        if (formData.mobile == '') {
          setPhoneErrorType(7);
          setShowInput1Error(true);
          returnValue = false;
        } else if (!phoneVerify(formData.mobile)) {
          setPhoneErrorType(8);
          setShowInput1Error(true);
          returnValue = false;
        } else {
          setShowInput1Error(false);
        }
      } else {
        if (formData.mobile == '') {
          setShowInput1Error(true);
          returnValue = false;
        } else {
          setShowInput1Error(false);
        }
      }
    }

    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(formData.password)) {
      setShowInput2Error(true);
      returnValue = false;
    } else {
      setShowInput2Error(false);
    }

    if (showNameCpf) {
      const regex2 = /^(?!.* {2,})[^'"\n\r\t\\]*[\s]?[^'"\n\r\t\\]*$/;
      if (!regex2.test(formData.name) || formData.name == '') {
        setShowInputNameError(true);
        returnValue = false;
      } else {
        setShowInputNameError(false);
      }

      if (formData.cpf == '') {
        setCpfErrorType(6);
        setShowInputCpfError(true);
        returnValue = false;
      } else if (formData.cpf.replace(/[\.-]/g, '').length < 11 || !/^\d+$/.test(formData.cpf.replace(/[\.-]/g, ''))) {
        setCpfErrorType(6);
        setShowInputCpfError(true);
        returnValue = false;
      } else if (!isValidCPF(formData.cpf)) {
        setCpfErrorType(5);
        setShowInputCpfError(true);
        returnValue = false;
      } else {
        setShowInputCpfError(false);
      }
    }

    if (showInput1Error || showInput2Error || showInputNameError || showInputCpfError) {
      returnValue = false;
    }

    return returnValue;
  };

  useEffect(() => {
    handleReReqCaptcha();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="font-[700] text-[var(--colour-48)] text-left text-[.32rem] leading-[.64rem]">
        {t('home.signup')}
      </div>

      {inputTypeList.length > 1 ? (
        <div className="flex flex-row items-center justify-start w-full border-b border-[rgba(255,255,255,0.20)] text-[.28rem] mt-[.24rem]">
          {inputTypeList.map((item, index) => {
            return (
              <SwitchWrapper
                key={index}
                className={cn('flex-1 text-center flex items-center justify-center cursor-pointer h-[.68rem]', {
                  'login-menu-item ': index != 0,
                })}
                choose={inputType == item.key}
                notChooseClassName="text-[var(--colour-14)]"
                chooseClassName="login-menu-item-choose text-[var(--colour-13)]"
                onClick={() => {
                  setInputType(item.key);
                  handleReReqCaptcha();
                }}
              >
                {item.text}
              </SwitchWrapper>
            );
          })}
        </div>
      ) : (
        <div className="mb-[.08rem]"></div>
      )}
      <div className="mt-[.24rem]">
        <Input
          onBlur={handleInput1Blur}
          onFocus={handleInput1Focus}
          leftInner={
            <div className="flex items-center ">
              <span className="w-[.48rem] h-[.48rem] mr-[.24rem] block text-[var(--colour-37)]">
                <Icon
                  name={
                    inputType == UserCache.LoginMethodValue.Email
                      ? IconName.Email
                      : inputType == UserCache.LoginMethodValue.Phone
                        ? IconName.Phone
                        : IconName.LoginAccount
                  }
                />
              </span>
              {inputType == UserCache.LoginMethodValue.Phone ? (
                <div className="text-[var(--colour-38)] font-[500] text-[.28rem] mx-[.16rem]">
                  +{websiteInitConfig.website_info.country_code || ''}
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          value={formData.mobile}
          onChange={(ev) => {
            if (inputType == UserCache.LoginMethodValue.Phone) {
              const val = ev.target.value;
              let regx = websiteInitConfig?.website_info?.country_code == '55' ? phoneVerifyRegex : phoneVerifyRegex2;
              if (regx.test(val)) {
                setFormData({ ...formData, mobile: val });
                setSendCodeformData({ ...sendCodeformData, mobile: val });
                handleCheckInput1(val);
              }
            }
            if (['email', 'userName'].includes(inputType)) {
              const val = ev.target.value;
              setFormData({ ...formData, mobile: val });
              setSendCodeformData({ ...sendCodeformData, mobile: val });
              handleCheckInput1(val);
            }
          }}
          placeholder={t(
            inputType == UserCache.LoginMethodValue.Email
              ? 'login-signup.Fill in your email'
              : inputType == UserCache.LoginMethodValue.Phone
                ? 'login-signup.phonenumber'
                : 'login-signup.fillin-username',
          )}
          type="text"
          innerClassName="!border-transparent !h-[.7rem]"
          topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
        />
        <InputErrorTips
          showTips={showInput1Error}
          tipsType={
            inputType == UserCache.LoginMethodValue.Email
              ? 1
              : inputType == UserCache.LoginMethodValue.Phone
                ? websiteInitConfig?.website_info?.country_code == '55'
                  ? phoneErrorType
                  : 1
                : 0
          }
        />
      </div>
      <div className="">
        <Input
          onFocus={handleInput2Focus}
          leftInner={
            <div className="flex items-center ">
              <span className="w-[.48rem] h-[.48rem] mr-[.24rem] block text-[var(--colour-37)]">
                <Icon name={IconName.LoginPwd} />
              </span>
            </div>
          }
          value={formData.password}
          onChange={handlePasswordChange}
          type="password"
          placeholder={t('login-signup.fill_in_the_password')}
          innerClassName="!border-transparent !h-[.7rem]"
          topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
        />
        <InputErrorTips showTips={showInput2Error} tipsType={2} />
      </div>
      {showNameCpf ? (
        <>
          <div className="">
            <Input
              onFocus={handleInputNameFocus}
              leftInner={
                <div className="flex items-center ">
                  <span className="w-[.48rem] h-[.48rem] mr-[.24rem] block text-[var(--colour-37)]">
                    <Icon name={IconName.LoginAccount} />
                  </span>
                </div>
              }
              onBlur={handleInputNameBlur}
              value={formData.name}
              onChange={handleNameChange}
              type="text"
              placeholder={t('login-signup.fill_in_the_name')}
              innerClassName="!border-transparent !h-[.7rem]"
              topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
            />
            <InputErrorTips showTips={showInputNameError} tipsType={4} />
          </div>

          <div className="">
            <Input
              onFocus={handleInputCpfFocus}
              leftInner={
                <div className="flex items-center ">
                  <span className="w-[.48rem] h-[.48rem] mr-[.24rem] block text-[var(--colour-37)]">
                    <Icon name={IconName.Cpf} />
                  </span>
                </div>
              }
              onBlur={handleInputCpfBlur}
              value={formData.cpf}
              onChange={handleCpfChange}
              type="text"
              placeholder={t('login-signup.fill_in_the_cpf')}
              innerClassName="!border-transparent !h-[.7rem]"
              topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
            />
            <InputErrorTips showTips={showInputCpfError} tipsType={cpfErrorType} />
          </div>
        </>
      ) : null}

      {showInviteCode && (
        <div className="mb-[0.46rem]">
          <Input
            leftInner={
              <div className="flex items-center">
                <span className="block w-[.48rem] h-[.48rem] mr-[.24rem] text-[var(--colour-37)]">
                  <Icon name={IconName.RegisterCode} />
                </span>
              </div>
            }
            type="text"
            value={formData.invite_code}
            onChange={(ev) => {
              let val = TextPlaces(ev.target.value, 40);
              setFormData({ ...formData, invite_code: val });
            }}
            placeholder={t('login-signup.invite')}
            innerClassName="!border-transparent !h-[.7rem]"
            topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
          />
        </div>
      )}

      {([UserCache.LoginMethodValue.UserName].includes(inputType) &&
        captchaInitConfig?.register_user_validation_mode == 'image') ||
      ([UserCache.LoginMethodValue.Phone].includes(inputType) &&
        captchaInitConfig?.register_phone_validation_mode == 'image') ? (
        <div className="mb-[0.46rem]">
          <Input
            leftInner={
              <div className="flex items-center">
                <span className="w-[24px] h-[24px] block mr-[12px] text-[var(--colour-37)]">
                  <Icon name={IconName.RegisterCode} />
                </span>
              </div>
            }
            value={sendCodeformData.cryCode}
            onChange={onChangeCryCode}
            type="text"
            placeholder={t('login-signup.fill_captcha')}
            innerClassName="!h-[40px] !border-transparent"
            topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
            rightInner={
              <div>
                <img
                  src={captchaInitConfig?.data}
                  alt="captcha"
                  onClick={handleReReqCaptcha}
                  className="cursor-pointer w-[100px]"
                />
              </div>
            }
          />
        </div>
      ) : null}

      {[UserCache.LoginMethodValue.Phone].includes(inputType) &&
      websiteInitConfig.system_config.registerCode == 1 &&
      (captchaInitConfig?.register_phone_validation_mode == 'geetest' ||
        captchaInitConfig?.register_phone_validation_mode == 'image') ? (
        <div className="mb-[0.24rem]">
          <InputBySendCode
            placeholder={t('home.menu_code')}
            value={formData.code}
            leftInner={
              <div className="flex items-center ">
                <span className="w-[24px] h-[24px] block mr-[12px] select-none text-[var(--colour-37)]">
                  <Icon name={IconName.RegisterCode} />
                </span>
              </div>
            }
            onChange={(ev) => {
              let val = TextPlaces(ev.target.value, 8);

              setFormData({ ...formData, code: val });
            }}
            hasBeenSent={hasBeenSent}
            handleSendCode={handleSendCode}
            countdownDone={() => setHasBeenSent(false)}
            sendCodeCountdown={sendCodeCountdown}
            requesting={codeLoading}
            innerClassName="!h-[40.2px]"
            propoClassName="rounded-normal bg-[var(--colour-34)] overflow-hidden"
          />
        </div>
      ) : null}

      {userDetailSwitch && (
        <InputDate
          initDate={formData.birthDate}
          handleDateChange={handleBirthdayChange}
          className="mt-[.16rem]"
          topClassName="rounded-normal bg-[var(--colour-34)] border-solid"
          innerClassName="!h-[.8rem] !border-transparent"
          leftInner={<span className="">{t('account.dateofbirth')}</span>}
          dateInputClassName1="text-[.32rem]"
          dateInputClassName2={'w-[.4rem] h-[.4rem]'}
        />
      )}

      {userDetailSwitch && (
        <FirstNameInput
          handleFirstNameChange={handleFirstNameChange}
          value={formData.firstName}
          className="mt-[.16rem]"
        />
      )}

      {userDetailSwitch && (
        <LastNameInput handleLastNameChange={handleLastNameChange} value={formData.lastName} className="mt-[.16rem]" />
      )}

      {!showInviteCode && (
        <div
          className="cursor-pointer text-left text-[var(--colour-41)] font-[500] text-[.24rem] leading-[.48rem]"
          onClick={() => setShowInviteCode(true)}
        >
          {t('account.click_fill_invitecode')}
        </div>
      )}

      <div className="flex items-center text-[var(--colour-41)] mt-[.24rem] text-[.24rem]">
        <div
          className={cn(
            `active:scale-[.95] transition-all text-[var(--colour-2)] select-none w-[.36rem] h-[.36rem] rounded-[.08rem] flex items-center justify-center border border-[var(--colour-6)] cursor-pointer`,
            {
              'bg-[var(--colour-1)]': formData.fast_track_is_send == '1',
              'bg-[var(--colour-42)]': formData.fast_track_is_send != '1',
            },
          )}
          onClick={() =>
            setFormData({
              ...formData,
              fast_track_is_send: formData.fast_track_is_send == '1' ? '0' : '1',
            })
          }
        >
          {formData.fast_track_is_send == '1' ? (
            <span className="w-[.48rem] h-[.16rem]">
              <span className="w-[.24rem] h-[.16rem]">
                <Icon name={IconName.Checked2} />
              </span>
            </span>
          ) : (
            <div className="w-[.48rem] h-[.32rem]"></div>
          )}
        </div>
        <TocTip className={'ml-[.16rem] flex-1'} />
      </div>
      <LimitClickWrapper
        className={cn(
          'active:scale-[.95] text-[var(--colour-2)] bg-[var(--colour-1)] transition-all select-none mt-[.24rem] px-[.34rem] h-[.8rem] flex flex-row items-center justify-center rounded-normal',
        )}
        onClick={() => {
          if (!handleCheckForm()) {
            return;
          }
          if (!requesting) {
            handleSubmit(inputType);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_RegPage_CreateAccount);
          }
        }}
      >
        <ElementWithLoading
          isLoading={requesting}
          normalEl={
            <>
              <div className="font-semibold text-[.32rem]">{t('login-signup.create')}</div>
            </>
          }
        />
      </LimitClickWrapper>

      <p className="mt-[.24rem] mb-[.04rem]">
        <span className="font-[500] text-[var(--colour-41)] text-[.24rem]">{t('login-signup.already')}</span>
        <span
          className={`cursor-pointer text-[var(--colour-41)] text-[.28rem] ml-[.24rem]`}
          onClick={() => {
            changeLogin();
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_RegPage_Login);
          }}
        >
          {t('login-signup.login')}
        </span>
      </p>

      <ThirdPartyLogin />
      <OnlineService click={handleClose} />
    </div>
  );
};

const RegisterForm = ({ handleClose, changeType }) => {
  return (
    <>
      <div className="flex flex-row h-[100%] w-[100%]">
        <div className="h-[100%] w-[100%]">
          <Form
            handleClose={handleClose}
            changeLogin={() => {
              changeType();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
