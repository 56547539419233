import i18next from 'i18next';
import { langsConfig } from '../constants/langs';
import store from '../redux/store';
import { setDefaultLang } from '../redux/reducers/userSlice';
import { createBrowserHistory } from 'history';
import { getCurrentLang, getQueryString, urlWithQueryString } from './helper';

export function getLangFromPath(pathname) {
  const maybeLang = pathname.split('/')[1];
  const found = langsConfig.supportLangList.find(
    (item) => item.key.toLocaleLowerCase() == maybeLang.toLocaleLowerCase(),
  );

  if (found) {
    return {
      langKey: found.key,
      path: pathname.replace(`/${maybeLang}`, ''),
    };
  } else {
    return {
      langKey: langsConfig.initLangKey,
      path: pathname,
    };
  }
}

export function _toLocalePath(path) {
  // 把无语言的路由path转成带语言的， /vip -> /ja/vip
  let newpath = path;

  const { langKey } = getLangFromPath(window.location.pathname);

  if (langKey) {
    if (langKey != langsConfig.initLangKey) {
      newpath = `/${langKey.toLowerCase()}${path}`;
    }
  }

  return newpath;
}

export function toLocalePath(path, withPreviewParams = true) {
  if (withPreviewParams) {
    const isPreview = getQueryString('preview');
    if (isPreview) {
      let previewParams = {
        preview: isPreview,
      };
      const website_template_id = getQueryString('website_template_id');
      if (website_template_id) {
        previewParams = {
          ...previewParams,
          website_template_id: website_template_id,
        };
      }
      const theme = getQueryString('theme');
      if (theme) {
        previewParams = {
          ...previewParams,
          theme: theme,
        };
      }
      const absoluteUrl = new URL(path, window.location.origin);
      const paramsToRemove = ['preview', 'website_template_id', 'theme'];
      // 循环移除指定的参数
      paramsToRemove.forEach((param) => {
        absoluteUrl.searchParams.delete(param);
      });
      const newPath = absoluteUrl.pathname + absoluteUrl.search;
      return urlWithQueryString(_toLocalePath(newPath), previewParams);
    } else {
      return _toLocalePath(path);
    }
  } else {
    return _toLocalePath(path);
  }
}

export function toNoLocalePath(testPath) {
  // 从testPath 里获取无语言的路由路径，如 /vip -> /vip, /ja/vip -> /vip, /ja/ -> /
  // testPath, 例如 window.location.pathname

  const { path } = getLangFromPath(testPath);

  return path;
}

export const pathnameContainLang = (pathName) => {
  const { langKey } = getLangFromPath(pathName);
  return langKey !== langsConfig.initLangKey;
};

export const changeLang = (targetStr) => {
  const target = targetStr.toLowerCase();
  const isLangKey = langsConfig.supportLangList.some((item) => item.key.toLowerCase() === target);
  if (!isLangKey) {
    return;
  }

  const currentLang = getCurrentLang().toLowerCase();
  if (target !== currentLang) {
    i18next.changeLanguage(targetStr);
    store.dispatch(setDefaultLang(targetStr));
    window.document.documentElement.lang = targetStr;
  }

  const { langKey: urlLangKey } = getLangFromPath(window.location.pathname);
  if (target !== urlLangKey.toLowerCase()) {
    const history = createBrowserHistory();
    if (target === langsConfig.initLangKey.toLowerCase()) {
      const url = window.location.href.replace(`/${currentLang}`, '');
      history.replace(url);
    } else {
      const urlNoLang = window.location.href.replace(`/${currentLang}`, '');
      const url = urlNoLang.replace(window.location.origin, `${window.location.origin}/${target}`);
      history.replace(url);
    }
  }
};
