import cn from 'classnames';

export default function ItemBox1({ selected = false, click = () => {}, children }) {
  return (
    <div
      onClick={click}
      className={cn(
        'w-[2.12rem] h-[.78rem] rounded-[.12rem] flex items-center justify-center truncate text-[.32rem] ',
        {
          'bg-[var(--colour-51)] text-[var(--colour-38)] font-[500]': !selected,
          'colour-79 shadow-[0rem_.08rem_.08rem_0rem_rgba(0,0,0,0.25)] text-[var(--colour-2)] font-[600]': selected,
        },
      )}
    >
      {children}
    </div>
  );
}
