import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../utils/localePath';
import Icon, { IconName } from '../icon';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const Search = ({ className = '' }) => {
  const navigate = useGlobalNavigate();

  return (
    <div
      className={`w-[.32rem] h-[.32rem] text-[var(--colour-7)] ${className}`}
      onClick={() => {
        navigate(toLocalePath(`/gamesearch?source=&sourceName=ALL`));
      }}
    >
      <Icon name={IconName.Search2} />
    </div>
  );
};
export default Search;
