import moment from 'moment';
import cn from 'classnames';
import { useState, useEffect } from 'react';
import Icon, { IconName } from '../../../../../components/icon';
import DialogModal from '../../../../../components/dialogModal';
import { getConfigTimeZoneTimestamp, getShowMoney, isLogin } from '../../../../../utils/helper';
import DatetimePickerBox from '../../../../../components/datetimePicker';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { GetConvidarReport, GetConvidarIndex } from '../../../../../api';
import Loading3 from '../../../../../components/loading3';
import Nodata from '../../../../../components/noData';
import { useSelector, shallowEqual } from 'react-redux';
import ScrollbarContent from '../../../../../components/scrollbarContent';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [isShowloading, setisShowLoading] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [visibleData, setVisibleData] = useState({
    visible: false,
    data: null,
    title: '',
    is_direct: '1',
    amount: 0,
  });

  const [params, setParams] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleStartChange = (val) => {
    setParams({ ...params, startDate: val });
  };

  const handleEndChange = (val) => {
    setParams({ ...params, endDate: val });
  };

  const getCommissionList = async () => {
    if (!isLogin()) return;
    setisShowLoading(true);
    try {
      const newParams = {
        start_time: Math.floor(params.startDate.getTime() / 1000),
        end_time: Math.floor(params.endDate.getTime() / 1000),
      };

      const res = await GetConvidarReport(newParams);
      if (res.code == 0) {
        setTotalData(res.data[0]);
        setList(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisShowLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin()) {
      getCommissionList();
    }
  }, []);

  return (
    <div className="bg-[var(--colour-68)] flex flex-col h-full">
      <DialogModal
        shouldCloseOnOverlayClick={false}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setVisibleData({
            visible: false,
          });
        }}
        isOpen={visibleData.visible}
      >
        <DirectIndex
          handleClose={() => {
            setVisibleData({
              visible: false,
            });
          }}
          dateTime={visibleData.data}
          title={visibleData.title}
          is_direct={visibleData.is_direct}
          amount={visibleData.amount}
        />
      </DialogModal>

      <div className="flex flex-col flex-1">
        <div className="mt-[.2rem] p-[.2rem] w-[7.1rem] h-[1.32rem] box-shadow-performance rounded-[.16rem] bg-[var(--colour-69)] mx-auto">
          <div className="flex items-center space-x-[.36rem]">
            <DatetimePickerBox
              startDate={params.startDate}
              endDate={params.endDate}
              rangeCheckClassName="!w-[3rem] bg-[var(--colour-5)]"
              onChangeStartDate={handleStartChange}
              onChangeEndDate={handleEndChange}
              onSubmit={getCommissionList}
            />

            <div className="space-x-[.1rem] text-[.2rem] font-[500]">
              <span className="text-[var(--colour-44)] ">{t('refer.comissoes_indirect')}</span>
              <span className="text-[var(--colour-26)]">
                {totalData && isLogin() ? <>{getShowMoney(totalData.total_amount, 2, 2)}</> : '0.00'}
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-[.36rem] mt-[.18rem]">
            <div className="space-x-[.1rem] text-[.2rem] font-[500] w-[3rem]">
              <span className="text-[var(--colour-44)]">{t('refer.comissoes_direct')}</span>
              <span className="text-[var(--colour-26)]">
                {totalData && isLogin() ? <>{getShowMoney(totalData.direct_amount, 2, 2)}</> : '0.00'}
              </span>
            </div>

            <div className="space-x-[.1rem] text-[.2rem] font-[500]">
              <span className="text-[var(--colour-44)]">{t('refer.comissoes_total')}</span>
              <span className="text-[var(--colour-26)]">
                {totalData && isLogin() ? <>{getShowMoney(totalData.indirect_amount, 2, 2)}</> : '0.00'}
              </span>
            </div>
          </div>
        </div>
        <div className={`w-[7.1rem] mx-auto overflow-y-auto flexAuto hidden-scroll-y`}>
          {isShowloading ? (
            <Loading3 className1="w-full h-full flex items-center justify-center" />
          ) : (
            <>
              {list && list.length > 1 ? (
                <>
                  {list.map((item, index) => {
                    if (index != 0) {
                      return (
                        <div
                          key={index}
                          className={cn('h-[1.32rem] p-[.2rem] rounded-[.16rem]', {
                            'box-shadow-performance bg-[var(--colour-69)]': index % 2 == 0,
                          })}
                        >
                          <div className="flex items-center space-x-[.36rem] mt-[.12rem]">
                            <div className="space-x-[.1rem] text-[.2rem] font-[500] w-[3rem]">
                              <span className="text-[var(--colour-44)]">{t('refer.comissoes_time')}</span>
                              <span className="text-[var(--colour-38)]">
                                {getConfigTimeZoneTimestamp(item.date_time * 1000).format('DD/MM/YYYY')}
                              </span>
                            </div>

                            <div className="space-x-[.1rem] text-[.2rem] font-[500]">
                              <span className="text-[var(--colour-44)] ">{t('refer.comissoes_indirect')}</span>
                              <span className="text-[var(--colour-26)]">{getShowMoney(item.total_amount, 2, 2)}</span>
                            </div>
                          </div>

                          <div className="flex items-center space-x-[.36rem] mt-[.18rem]">
                            <div className="space-x-[.1rem] text-[.2rem] font-[500] w-[3rem]">
                              <span className="text-[var(--colour-44)]">{t('refer.comissoes_direct')}</span>
                              <span
                                className={cn('text-[var(--colour-26)]', {
                                  'border-b-[.01rem] border-[var(--colour-26)] cursor-pointer': item.direct_amount > 0,
                                  'pointer-events-none': item.direct_amount <= 0,
                                })}
                                onClick={() => {
                                  setVisibleData({
                                    visible: true,
                                    data: item.date_time,
                                    title: t('refer.comissoes_direct'),
                                    is_direct: '1',
                                    amount: getShowMoney(item.direct_amount, 2, 2),
                                  });
                                }}
                              >
                                {getShowMoney(item.direct_amount, 2, 2)}
                              </span>
                              <span className="text-[var(--colour-38)]">{`(${item.direct_count})`}</span>
                            </div>

                            <div className="space-x-[.1rem] text-[.2rem] font-[500]">
                              <span className="text-[var(--colour-44)] ">{t('refer.comissoes_total')}</span>
                              <span
                                className={cn('text-[var(--colour-26)]', {
                                  'border-b-[.01rem] border-[var(--colour-26)] cursor-pointer':
                                    item.indirect_amount > 0,
                                  'pointer-events-none': item.indirect_amount <= 0,
                                })}
                                onClick={() => {
                                  setVisibleData({
                                    visible: true,
                                    data: item.date_time,
                                    title: t('refer.comissoes_total'),
                                    is_direct: '2',
                                    amount: getShowMoney(item.indirect_amount, 2, 2),
                                  });
                                }}
                              >
                                {getShowMoney(item.indirect_amount, 2, 2)}
                              </span>
                              <span className="text-[var(--colour-38)]">{`(${item.indirect_count})`}</span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              ) : (
                <Nodata className="h-full w-full" />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const DirectIndex = ({ handleClose = () => {}, dateTime, title, is_direct }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [list, setList] = useState([]);

  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const getGameName = (val) => {
    const list = websiteInitConfig.game_type;
    const _find = list.find((item) => {
      return item.id == val;
    });

    return _find ? _find.game_type : '';
  };

  const getconvidarIndex = async () => {
    setisLoading(true);
    try {
      const data = {
        date_time: dateTime,
        is_direct: is_direct,
      };
      const res = await GetConvidarIndex(data);
      if (res.code == 0) {
        setCount(res.data.count);
        setList(res.data.list);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getconvidarIndex();
  }, []);

  return (
    <>
      <div className="w-[6.7rem] h-[10.1rem] rounded-[.16rem] bg-[--colour-35] box-shadow-directIndex border border-[var(--colour-6)] pl-[.22rem] pr-[.28rem] relative flex flex-col">
        <div
          className={cn(
            'active:scale-[.95] transition-all select-none w-[.6rem] h-[.6rem] rounded-[50%] flex justify-center items-center absolute left-[50%] bottom-[-0.86rem] translate-x-[-50%]',
          )}
          onClick={() => {
            handleClose();
          }}
        >
          <Icon name={IconName.SignInClose} />
        </div>

        <div className="w-full flex items-center justify-center text-[var(--colour-10)] text-[.28rem] font-[600] my-[.22rem]">
          {title}
        </div>

        <div className="flex items-center mb-[.22rem] space-x-[.08rem] text-[.2rem] font-[500]">
          <div className="space-x-[.1rem]">
            <span className="text-[var(--colour-44)]">{t('refer.comissoes_time')}</span>
            <span className="text-[var(--colour-38)]">
              {dateTime ? getConfigTimeZoneTimestamp(dateTime * 1000).format('DD/MM/YYYY') : ''}
            </span>
          </div>
          <div className="space-x-[.1rem]">
            <span className="text-[var(--colour-44)]">
              {is_direct == '1' ? t('refer.commission_count1') : t('refer.commission_count2')}
            </span>
            <span className="text-[var(--colour-38)]">{count || 0}</span>
          </div>
        </div>

        <div className="w-[6.2rem] mx-[auto] flexAuto overflow-y-auto hidden-scroll-y">
          {isLoading ? (
            <Loading3 className1="w-full h-full"></Loading3>
          ) : (
            <>
              {list && list.length > 0 ? (
                <div>
                  {list.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={cn('px-[.2rem] min-h-[1.36rem] rounded-[.16rem] pt-[.24rem] relative', {
                          'bg-[var(--colour-68)]': index % 2 == 0,
                        })}
                      >
                        <div className="w-[.36rem] h-[.2rem] rounded-[.04rem_0] bg-[var(--colour-25)] flex items-center justify-center text-[var(--colour-2)] text-[.16rem] font-[600]">
                          V{item.vip_level}
                        </div>

                        <div className="flex items-center space-x-[.24rem]">
                          <div className="min-w-[2.66rem] w-[2.66rem] space-x-[.2rem] flex items-center">
                            <div className="text-[.2rem] font-[500] text-[var(--colour-38)]">{item.from_uid}</div>
                            <div
                              className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]"
                              onClick={() => {
                                copy(`${item.from_uid}`);
                                toast.success(t('home.toast006'), {
                                  containerId: 'global',
                                });
                              }}
                            >
                              <Icon name={IconName.Copy4}></Icon>
                            </div>
                          </div>

                          <div className="space-x-[.1rem] flex items-center text-[.2rem] font-[500]">
                            <div className="text-[var(--colour-44)]">{t('refer.comissoes_wager')}</div>
                            <div className="text-[var(--colour-38)]">{getShowMoney(item.wager, 2, 2)}</div>
                          </div>
                        </div>

                        <div className="mt-[.1rem] flex space-x-[.24rem]">
                          {is_direct == '1' ? (
                            <div className="w-[2.66rem] text-[.2rem] font-[500] leading-[.2rem]">
                              <span>{t('refer.comissoes_radio')}</span>
                              <span
                                className={cn('ml-[.1rem]', {
                                  'text-[var(--colour-24)]': item.profit < 0,
                                  'text-[var(--colour-25)]': item.profit > 0,
                                })}
                              >
                                {item.ratio / 100} %
                              </span>
                            </div>
                          ) : (
                            <div className="w-[2.66rem] leading-[.2rem] space-x-[.1rem] flex items-center text-[.2rem] font-[500]">
                              <div className="text-[var(--colour-44)]">{t('refer.comissoes_gameType')}</div>
                              <div className="text-[var(--colour-38)]">{getGameName(item.game_type)}</div>
                            </div>
                          )}

                          <div className="space-x-[.1rem] flex items-center text-[.2rem] font-[500]">
                            <div className="text-[var(--colour-44)]">{t('refer.comissoes_amount')}</div>
                            <div className="text-[var(--colour-26)]">{getShowMoney(item.amount, 2, 2)}</div>
                          </div>
                        </div>

                        {is_direct == '1' ? (
                          <div className="space-x-[.1rem] flex items-center text-[.2rem] font-[500]">
                            <div className="text-[var(--colour-44)]">{t('refer.comissoes_gameType')}</div>
                            <div className="text-[var(--colour-38)]">{getGameName(item.game_type)}</div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Nodata className="w-full h-full" />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NTemplate001;
