import { useTranslation, Trans } from 'react-i18next';
import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Icon, { IconName } from './icon';
import LimitClickWrapper from './limitClickWrapper';
import Loading from './loading2';
import { isLightTheme } from '../utils/helper';
import cn from 'classnames';
// import LoadMore from './loadMore';

// const LoadMoreTextOld = ({
//   onClick,
//   className,
//   showLoading = true,
//   currentNum = 0,
//   total = 0,
//   lang_key_title = '',
//   i18nKey = 'home.loadmore1',
// }) => {
//   const { t } = useTranslation();
//   return showLoading ? (
//     <div className={`w-[1.6rem] mx-auto h-[.6rem] ${className}`}>
//       <Loading isAbsolute={false} height="100%" />
//     </div>
//   ) : (
//     <LimitClickWrapper
//       className={`mx-auto text-center font-[500] text-[.24rem] leading-[.3rem] ${className}`}
//       onClick={onClick}
//     >
//       <div
//         className={cn('mb-[.04rem]', {
//           'text-[var(--colour-14)]': !isLightTheme(),
//           'text-[var(--colour-15)]': isLightTheme(),
//         })}
//       >
//         <Trans
//           t={t}
//           i18nKey={i18nKey}
//           values={{
//             val: currentNum,
//             val2: total,
//             val3: lang_key_title,
//           }}
//         />
//       </div>
//       <div
//         className={cn(' flex items-center justify-center', {
//           'text-[var(--colour-10)]': !isLightTheme(),
//           'text-[var(--colour-48)]': isLightTheme(),
//         })}
//       >
//         <div className="mr-[.04rem]">{t('home.loadmore2')}</div>
//         <div
//           className={cn('w-[.14rem] h-[.14rem]', {
//             'text-[var(--colour-15)]': isLightTheme(),
//           })}
//         >
//           <Icon name={IconName.DubbleArrow} />
//         </div>
//       </div>
//     </LimitClickWrapper>
//   );
// };

const LoadMoreText = ({
  onClick,
  className,
  showLoading = true,
  currentNum = 0,
  total = 0,
  lang_key_title = '',
  i18nKey = 'home.loadmore1',
  type = 'default', // default 为默认样式,  mysteryBonus 为神秘彩金样式
}) => {
  // console.log('type', type);
  const { t } = useTranslation();

  const getStyle1 = () => {
    if (type == 'default') {
      if (isLightTheme()) {
        return {
          color: 'var(--colour-15)',
        };
      } else {
        return {
          color: 'var(--colour-14)',
        };
      }
    } else if (type == 'mysteryBonus') {
      if (isLightTheme()) {
        return {
          color: 'var(--colour-15)',
        };
      } else {
        return {
          color: 'var(--colour-15)',
        };
      }
    }
  };
  const getStyle2 = () => {
    if (type == 'default') {
      if (isLightTheme()) {
        return {
          color: 'var(--colour-48)',
        };
      } else {
        return {
          color: 'var(--colour-10)',
        };
      }
    } else if (type == 'mysteryBonus') {
      if (isLightTheme()) {
        return {
          color: 'var(--colour-1)',
        };
      } else {
        return {
          color: 'var(--colour-1)',
        };
      }
    }
  };
  const getStyle3 = () => {
    if (type == 'default') {
      if (isLightTheme()) {
        return {
          color: 'var(--colour-15)',
        };
      } else {
        return {
          color: 'var(--colour-10)',
        };
      }
    } else if (type == 'mysteryBonus') {
      if (isLightTheme()) {
        return {
          color: 'var(--colour-1)',
        };
      } else {
        return {
          color: 'var(--colour-1)',
        };
      }
    }
  };

  return showLoading ? (
    <div className={`w-[1.6rem] mx-auto h-[.6rem] ${className}`}>
      <Loading isAbsolute={false} height="100%" />
    </div>
  ) : (
    <LimitClickWrapper
      className={`mx-auto text-center font-[500] text-[.24rem] leading-[.3rem] ${className}`}
      onClick={onClick}
    >
      <div className={cn('mb-[.04rem]', {})} style={getStyle1()}>
        <Trans
          t={t}
          i18nKey={i18nKey}
          values={{
            val: currentNum,
            val2: total,
            val3: lang_key_title,
          }}
        />
      </div>
      <div className={cn(' flex items-center justify-center', {})}>
        <div className="mr-[.04rem]" style={getStyle2()}>
          {t('home.loadmore2')}
        </div>
        <div className={cn('w-[.14rem] h-[.14rem]', {})} style={getStyle3()}>
          <Icon name={IconName.DubbleArrow} />
        </div>
      </div>
    </LimitClickWrapper>
  );
};

export default LoadMoreText;
