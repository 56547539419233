// {
//   "type": "rank_reward", // VIP 升级
//   "status": 1, // 0:无效 1:未领取 2:已领取
//   "time": "", // 可领取奖励时间
//   "id": 41478, // 可领取奖励记录ID
//   "money": 10100000 // 可领取奖励额度
// },
// {
//   "type": "weekly_reward", // 周俸
//   "status": 0, // 0:无效 1:未领取 2:已领取
//   "time": 1728382583, // 可领取时间
//   "id": 0, // 可领取奖励记录ID
//   "money": 1000000 // 可领取奖励额度
// },
// {
//   "type": "monthly_reward", // 月俸
//   "status": 1, // 0:无效 1:未领取 2:已领取
//   "time": 1729530000, // 可领取奖励时间
//   "id": 44140, // 可领取奖励记录ID
//   "money": 6400000 // 可领取奖励额度
// }

import { useTranslation } from 'react-i18next';
import ButtonBox from './buttonBox';
import { el } from 'date-fns/locale';
import moment from 'moment';

const ButtonClaimWeekly = ({ data, handleClaimWeekly, elementId }) => {
  const { t } = useTranslation();

  const type = data?.type;
  const status = data?.status;
  const time = data?.time;
  const claimId = data?.id;
  const amount = data?.amount;

  console.log('claimId weekly', claimId);

  const getButtonData = () => {
    const blank = {
      isEnable: false,
      text: '',
      onClick: () => {},
      elementId: '',
    };

    switch (status) {
      case 0: {
        const getLeftDays = (futureTimestampInSeconds) => {
          const now = moment();
          const future = moment(futureTimestampInSeconds * 1000);
          const daysDiff = Math.max(0, future.diff(now, 'days', true));

          // console.log('daysDiff', daysDiff, typeof daysDiff)

          return daysDiff;
        };

        return {
          isEnable: false,
          text: t('vip.days_left', {
            val: Math.ceil(getLeftDays(time)),
          }),
          onClick: () => {},
          elementId,
        };
      }

      case 1: {
        if (amount > 0) {
          return {
            isEnable: true,
            text: <span className="text-[var(--colour-2)]">{t('vip.claim_weekly_bonus')}</span>,
            onClick: handleClaimWeekly,
            elementId,
          };
        } else {
          return {
            isEnable: false,
            text: <span className="text-[var(--colour-2)]">{t('vip.no_claim_amount')}</span>,
            onClick: () => {},
            elementId,
          };
        }
      }

      case 2: {
        return {
          isEnable: false,
          text: <span className="text-[var(--colour-2)]">{t('vip.received')}</span>,
          onClick: () => {},
          elementId,
        };
      }

      default: {
        return blank;
      }
    }
  };

  console.log(data, getButtonData());

  return (
    <ButtonBox
      isEnable={getButtonData().isEnable}
      text={getButtonData().text}
      onClick={getButtonData().onClick}
      elementId={getButtonData().elementId}
    />
  );
};

export default ButtonClaimWeekly;
