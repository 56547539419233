import { useState } from 'react';
import Close from '../../close';
import RegisterPages from '../registerPages/index';
import LoginPages from '../loginPages/index';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import cn from 'classnames';
import { isUseLayoutMobile } from '../../../utils/helper';
import Icon, { IconName } from '../../icon';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
/**
 * 登录注册页面
 * @param loginOrSignUp 1 登录(默认) 2 注册
 */
const Entrance2 = ({ handleClose, loginOrSignUp = 1 }) => {
  const { t } = useTranslation();
  const [openType, setOpenType] = useState(loginOrSignUp);
  const specialLayout = ['fil', 'pt'];

  const { defaultLang } = useSelector(
    (state) => ({
      defaultLang: get(state, 'user.defaultLang'),
    }),
    shallowEqual,
  );

  return (
    <div
      className={cn('bg-[var(--colour-35)] text-center relative rounded-normal w-[6.86rem]', {
        'mt-[-0.46rem] px-[.32rem] py-[.3rem]': isUseLayoutMobile(),
        'p-[.32rem_.32rem_.24rem_.32rem]': !isUseLayoutMobile(),
      })}
    >
      <div
        className={cn(
          'active:scale-[.95] transition-all select-none rounded-[50%] flex justify-center items-center absolute left-[50%] translate-x-[-50%] text-[var(--colour-46)] w-[.6rem] h-[.6rem] bottom-[-0.88rem]',
        )}
        onClick={() => {
          handleClose();
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseRegLogButton);
        }}
      >
        <Icon name={IconName.SignInClose} />
      </div>

      <div
        className={`flex items-center ${specialLayout.includes(defaultLang) ? 'w-[6.3rem] mx-auto' : 'pl-[.72rem] pr-[.88rem]'} justify-between h-[.6rem] mb-[.2rem]`}
      >
        <div
          className={`flex items-center gap-[.0512rem] cursor-pointer h-full ${specialLayout.includes(defaultLang) ? 'justify-center w-[3rem]' : ''} ${openType == 2 ? 'text-[var(--colour-1)] border-b border-[var(--colour-1)]' : 'text-[var(--colour-14)] border-b border-[transparent]'}`}
          onClick={() => {
            setOpenType(2);
          }}
        >
          <div className="w-[.26rem] h-[.24rem]">
            <Icon name={IconName.Registeraccount}></Icon>
          </div>
          <div className="font-[500] text-[.26rem]">{t('login-signup.signup2_pop_register')}</div>
        </div>

        <div
          className={`flex items-center gap-[.0732rem] cursor-pointer h-full  ${specialLayout.includes(defaultLang) ? 'justify-center w-[3rem]' : ''} ${openType == 1 ? 'text-[var(--colour-1)]  border-b border-[var(--colour-1)]' : 'text-[var(--colour-14)] border-b border-[transparent]'}`}
          onClick={() => {
            setOpenType(1);
          }}
        >
          <div className="w-[.24rem] h-[.24rem]">
            <Icon name={IconName.Newlogin}></Icon>
          </div>
          <div className={cn('font-[500] text-[.26rem] text-center')}>{t('login-signup.signup2_pop_login')}</div>
        </div>
      </div>

      {openType == 1 ? (
        <LoginPages handleClose={handleClose} changeType={() => setOpenType(2)} isShowTitle={false} />
      ) : openType == 2 ? (
        <RegisterPages
          isShowTitle={false}
          changeType={() => {
            setOpenType(1);
          }}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Entrance2;
