import Icon, { IconName } from '../../../../../icon';
import cn from 'classnames';

export default function Index({
  handleBack = () => {},
  title = '',
  rightTitle = '',
  handleOnRightTitle = () => {},
  withBottomBorder = false,
  className = '',
  titleClassName = '',
}) {
  return (
    <div
      className={cn(`h-[.9rem] flex items-center justify-between pr-[.3rem] relative ${className}`, {
        'border-b border-[var(--colour-6)]': withBottomBorder,
      })}
    >
      <span className="px-[.3rem] h-full flex items-center" onClick={handleBack}>
        <span className="inline-block w-[.16rem] h-[.24rem] text-[var(--colour-11)] rotate-180">
          <Icon name={IconName.BannerNext2} />
        </span>
      </span>
      <span
        className={`absolute abs-center text-center text-[.3rem] font-[500] text-[var(--colour-48)] ${titleClassName}`}
      >
        {title}
      </span>
      {rightTitle != '' ? (
        <span
          className="text-[.24rem] font-[400] leading-[.3rem] text-[var(--colour-13)] mt-[-0.18rem]"
          onClick={handleOnRightTitle}
        >
          {rightTitle}
        </span>
      ) : null}
    </div>
  );
}
