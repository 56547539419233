import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { ResponsiveMode } from '../../../../../../redux/reducers/appSlice';
import cn from 'classnames';
import { useEffect, useRef } from 'react';
import useDraggableScroll from '../../../../../../hooks/useDraggableScroll';
import { isPCLayout } from '../../../../../../utils/helper';

const DateBtn = ({ name, onClick = () => {}, isActive = false }) => {
  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
    }),
    shallowEqual,
  );

  return (
    <div
      onClick={onClick}
      className={cn(
        'shrink-0 flex items-center rounded-full justify-center h-[.5rem] font-[500] text-[.2rem] cursor-pointer text-center leading-[1] whitespace-nowrap px-[.32rem] tab-item',
        {
          'text-[var(--colour-44)] bg-[var(--colour-5)] border border-[var(--colour-6)] flex-1': !isActive,
          'text-[var(--colour-2)] bg-[var(--colour-1)] border border-[var(--colour-1)] flex-1': isActive,
        },
      )}
    >
      {name}
    </div>
  );
};

const DateBtnList = ({ list = [], currentBtn }) => {
  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
    }),
    shallowEqual,
  );

  const tabRef = useRef(null);

  useEffect(() => {
    if (tabRef.current) {
      const tabContainer = tabRef.current;
      const tabItems = tabContainer.querySelectorAll('.tab-item');
      const targetTab = tabItems[currentBtn];

      if (targetTab) {
        const targetTabWidth = targetTab.offsetWidth;
        const targetTabOffsetLeft = targetTab.offsetLeft;
        const containerWidth = tabContainer.offsetWidth;
        const containerScrollLeft = tabContainer.scrollLeft;
        const scrollAmount = targetTabOffsetLeft + targetTabWidth / 2 - containerWidth / 2 - containerScrollLeft;

        tabContainer.scrollLeft += scrollAmount;
      }
    }
  }, [currentBtn, tabRef]);

  useDraggableScroll(tabRef);

  return (
    <div
      className={cn('overflow-auto scrollbar-none select-none', {
        'w-[calc(var(--theme-max-width)-.64rem)]': isPCLayout(),
        'w-[calc(100vw-.64rem)]': !isPCLayout(),
      })}
      ref={tabRef}
    >
      <div className={cn('flex space-x-[.08rem]')}>
        {list.map((item, index) => (
          <DateBtn key={index} name={item?.name} onClick={item?.onClick} isActive={index == currentBtn} />
        ))}
      </div>
    </div>
  );
};

export default DateBtnList;
