import Close from '../close2';
import { useRef, useEffect, useState } from 'react';
import { cmsImgUrl, getShowMoney } from '../../utils/helper';
import { useSpring, animated } from 'react-spring';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Icon, { IconName } from '../icon';
import ProgressierOperatio from '../ProgressierOperation';
import DownloadManager from '../../manager/downloadManager';
import { openUrl } from '../../utils/openUrl';
import { useTranslation } from 'react-i18next';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setDepositSuccessPwaGuideData,
  setExitGameTime,
} from '../../redux/reducers/appSlice';
import { getAppTag, isAndroidAPK, isAnyMobile, isPWA } from '../../utils/deviceUtils';
import { useClickAway } from 'ahooks';
import { eventEmitter, EmitterEventTypes } from '../../utils/eventEmitter';
import DialogModal from '.';
import PwaguideQRcodeModel from './pwaguideQRcode';

const FirstDepositDownload = ({ handleClose = () => {}, depositData, content = 0 }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { websiteInfo, installStatus } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      installStatus: state.app.installStatus,
    }),
    shallowEqual,
  );

  const ref = useRef(null);
  const clickAwayRef = useRef(null);
  const contentReadyRef = useRef(false);
  const [isShowGuideQrcode, setisShowGuideQrcode] = useState(false);
  const initialPosition = { transform: 'translateY(100%)' };
  const [isForcedGuidance, setisForcedGuidance] = useState(false);

  const [styles, setSpring] = useSpring(() => ({
    from: initialPosition,
    to: { transform: 'translateY(0%)' },
    config: { duration: 150 },
    onRest: () => {
      contentReadyRef.current = true;
    },
  }));

  const handleAnimationEnd = () => {
    handleClose();
    contentReadyRef.current = false;
  };

  const startClosingAnimation = () => {
    setSpring({
      from: { transform: 'translateY(0%)' },
      to: initialPosition,
      onRest: handleAnimationEnd,
      config: { duration: 150 },
    });

    dispatch(setDepositSuccessPwaGuideData(null));
    dispatch(setExitGameTime(0));
  };

  const resize = () => {
    if (ref.current) {
      ref.current.style.height = `${window.innerHeight}px`;
    }
  };

  const handleClick = () => {
    if (DownloadManager.isNotMobile) {
      setisShowGuideQrcode(true);
    } else {
      DownloadManager.handleInstallAction(false);
    }
  };

  const handleStartAppClick = async () => {
    if (isPWA()) {
      return window.location.reload();
    }

    dispatch(
      setDialogModalVisible({
        zIndex: 10002,
        show: DialogModalChildrenKey.Confirm,
        content: t('home.pwaInstallTips'),
        confirmCb: () => {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
        },
      }),
    );

    openUrl(window.location.href);
  };

  useEffect(() => {
    resize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      resize();
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!websiteInfo) return;

    const pwaConfig = websiteInfo?.download?.pwa?.pwa_reward_conf;
    const isPopupModeH5Enabled = pwaConfig?.popup_mode_h5 === '1';
    const isPopupModeApkEnabled = pwaConfig?.popup_mode_apk === '1';

    if ((getAppTag() === '' && isPopupModeH5Enabled) || (isAndroidAPK() && isPopupModeApkEnabled)) {
      setisForcedGuidance(true);
    }
  }, [websiteInfo]);

  useClickAway(() => {
    if (contentReadyRef.current == true && !isForcedGuidance) {
      startClosingAnimation();
    }
  }, clickAwayRef);
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setisShowGuideQrcode(false);
        }}
        isOpen={isShowGuideQrcode}
      >
        <PwaguideQRcodeModel
          handleClose={() => {
            setisShowGuideQrcode(false);
          }}
        />
      </DialogModal>

      <div
        className={`${isAnyMobile() ? 'w-[100vw]' : 'w-[var(--theme-max-width)]'} relative overflow-hidden z-[10000]`}
        ref={ref}
      >
        <animated.div
          style={{ ...styles, position: 'absolute', bottom: 0, left: 0 }}
          className="w-full bg-[var(--colour-56)]"
          ref={clickAwayRef}
        >
          <div
            className="w-full h-full relative bg-download pt-[2.92rem]"
            style={{
              backgroundPosition: 'center top',
              backgroundImage: `url(${cmsImgUrl(websiteInfo?.download?.pwa?.pwa_reward_conf?.bg)})`,
            }}
          >
            {!isForcedGuidance ? (
              <Close
                handleClose={startClosingAnimation}
                className="w-[.52rem] h-[.52rem] rounded-[.16rem] bg-[var(--colour-45)] absolute right-[.14rem] top-[.14rem] text-[var(--colour-1)]"
              />
            ) : null}

            <div className="text-[var(--colour-31)] text-[.32rem] font-[700] text-center">{t('home.discover')}</div>

            <div className="mt-[.1rem] w-[6.82rem] h-[1.26rem] mx-auto text-[.24rem] font-[500] text-[var(--colour-18)]">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('home.deposit_first', {
                    currency: `${websiteInfo?.currency_symbol}${getShowMoney(content)}`,
                    uid: depositData.uid,
                  }),
                }}
              ></span>
            </div>

            <div className="mt-[.02rem] w-[6.78rem] h-[1.08rem] mx-auto flex gap-[.32rem] items-center text-[.28rem] font-[500] text-[var(--colour-18)] bg-[#F5F6F4] py-[.1rem] pl-[.22rem] rounded-[.16rem]">
              <img className="w-[.88rem] h-[.88rem]" src={cmsImgUrl(websiteInfo['android_chrome_192x192'])} />
              <div>{websiteInfo.website_name || ''}</div>
            </div>

            <div className="mt-[.34rem]">
              {isAndroidAPK() && websiteInfo.download.apk_jump_pwa.switch == 1 ? (
                <Button
                  onClick={() => {
                    DownloadManager.handleInstallAction(false, {
                      uid: depositData.uid,
                      amount: getShowMoney(content),
                    });
                  }}
                  icon={IconName.Rocket}
                >
                  <span className="font-[700] text-[.36rem] text-[var(--colour-2)]">{t('home.update')}</span>
                </Button>
              ) : (
                <>
                  {installStatus === 0 && (
                    <Button onClick={handleClick} icon={IconName.PwaDownload}>
                      {t('home.instalar')}
                    </Button>
                  )}
                  {installStatus === 1 && <ProgressierOperatio className="!w-[6.78rem]" />}
                  {installStatus === 2 && <Button onClick={handleStartAppClick}>{t('home.start_app')}</Button>}
                </>
              )}
            </div>

            <div className="mt-[.24rem] text-[.28rem] font-[600] text-[var(--colour-31)] w-[6.82rem] mx-auto">
              {t('home.toinstall')}
            </div>

            <div className="mt-[.12rem] w-[6.82rem] mx-auto text-[.24rem] font-[500] text-[var(--colour-18)] leading-[.3rem]">
              <div>1. {t('home.downloadpwaTip1')}</div>
              <div>2. {t('home.downloadpwaTip2')}</div>
              <div>3. {t('home.downloadpwaTip3')}</div>
              <div>4. {t('home.downloadpwaTip4')}</div>
            </div>

            <div className="mt-[.4rem] w-[6.78rem] mx-auto rounded-[.16rem] bg-[#F5F6F4] p-[.06rem_.16rem_.12rem_.16rem] mb-[.22rem]">
              <div className="text-[.28rem] font-[600] text-[var(--colour-31)] flex items-center gap-[.1rem]">
                <div className="w-[.28rem] h-[.28rem] text-[var(--colour-25)]">
                  <Icon name={IconName.PwaWarning} />
                </div>
                <div>{t('home.friendly')}</div>
              </div>
              <div className="text-[.2rem] font-[500] text-[var(--colour-18)]">{t('home.installit')}</div>
            </div>
          </div>
        </animated.div>
      </div>
    </>
  );
};

const Button = ({ onClick, children, icon }) => (
  <div
    className="w-[6.78rem] h-[.84rem] rounded-[.16rem] bg-[var(--colour-1)] text-[var(--colour-2)] mx-auto text-[.28rem] font-[700] flex items-center justify-center relative active:scale-[.95] transition-all select-none"
    onClick={onClick}
  >
    {icon && (
      <div className="w-[.52rem] h-[.52rem] text-[--colour-2] absolute left-[.42rem] abs-y-center">
        <Icon name={icon} />
      </div>
    )}
    {children}
  </div>
);

export default FirstDepositDownload;
