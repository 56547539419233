import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import DatetimePickerBox from '../../../../../components/datetimePicker';
import DrawerStatistics from '../../../../../components/drawerStatistics';
import SubordinatesDetails from '../../components/subordinatesDetails';
import Input from '../../../../../components/input';
import Icon, { IconName } from '../../../../../components/icon';
import { GetDirectList, GetDirectUid } from '../../../../../api';
import {
  getDateFormatStr5,
  getShowMoney,
  setInputValue,
  isLogin,
  getConfigTimeZoneTimestamp,
} from '../../../../../utils/helper';
import { List } from 'react-vant';
import Loading3 from '../../../../../components/loading3';
import NoData from '../../../../../components/noData';
import ScrollbarContent from '../../../../../components/scrollbarContent';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const [subordinatesVisible, setSubordinatesVisible] = useState(false); //下属详情弹窗

  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    startDate: new Date(),
    endDate: new Date(),
  });
  const [inputVal, setInputVal] = useState(''); //uid输入框
  const [list, setList] = useState([]);
  const [totalInfo, setTotalInfo] = useState(null);
  const [finished, setFinished] = useState(false);
  const [id, setId] = useState('');
  const [paramStartDate, setParamStartDate] = useState('');
  const [paramEndDate, setParamEndDate] = useState('');

  const getDirectData = (page) => {
    setLoading(true);
    const newParams = {
      page: page ? page : params.page,
      page_size: params.pageSize,
      start_date: getDateFormatStr5(params.startDate.getTime()),
      end_date: getDateFormatStr5(params.endDate.getTime()),
    };
    GetDirectList(newParams)
      .then((res) => {
        if (res.code == 0) {
          setList(res.data.list);
          setTotalInfo(res.data.total_info);
          setParamStartDate(res.data.start_date);
          setParamEndDate(res.data.end_date);
          if (res.data.list.length < params.pageSize) {
            setFinished(true);
          } else {
            setFinished(false);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDirectDataWithUid = (page) => {
    setLoading(true);
    const newParams = {
      page: page ? page : params.page,
      page_size: params.pageSize,
      start_date: getDateFormatStr5(params.startDate.getTime()),
      end_date: getDateFormatStr5(params.endDate.getTime()),
      uid: inputVal,
    };
    GetDirectUid(newParams)
      .then((res) => {
        if (res.code == 0) {
          setList(res.data.list);
          setTotalInfo(res.data.total_info);
          setParamStartDate(res.data.start_date);
          setParamEndDate(res.data.end_date);
          if (res.data.list.length < params.pageSize) {
            setFinished(true);
          } else {
            setFinished(false);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadData = async () => {
    if (!isLogin()) return;
    if (inputVal) {
      try {
        setParams({ ...params, page: params.page + 1 });
        const currentPage = params.page + 1;
        const newParams = {
          page: currentPage,
          page_size: params.pageSize,
          start_date: getDateFormatStr5(params.startDate.getTime()),
          end_date: getDateFormatStr5(params.endDate.getTime()),
          uid: inputVal,
        };
        let res = await GetDirectUid(newParams);
        if (res.code == 0) {
          setTotalInfo(res.data.total_info);
          setParamStartDate(res.data.start_date);
          setParamEndDate(res.data.end_date);
          if (res.data && res.data.list && res.data.list.length > 0) {
            setList([...list, ...res.data.list]);
          }
          if (res.data && res.data.list && res.data.list.length < params.pageSize) {
            setFinished(true);
          } else {
            setFinished(false);
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        setParams({ ...params, page: params.page + 1 });
        const currentPage = params.page + 1;
        const newParams = {
          page: currentPage,
          page_size: params.pageSize,
          start_date: getDateFormatStr5(params.startDate.getTime()),
          end_date: getDateFormatStr5(params.endDate.getTime()),
        };
        let res = await GetDirectList(newParams);
        if (res.code == 0) {
          setTotalInfo(res.data.total_info);
          setParamStartDate(res.data.start_date);
          setParamEndDate(res.data.end_date);
          if (res.data && res.data.list && res.data.list.length > 0) {
            setList([...list, ...res.data.list]);
          }

          if (res.data && res.data.list && res.data.list.length < params.pageSize) {
            setFinished(true);
          } else {
            setFinished(false);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getData = (page) => {
    if (isLogin()) {
      if (inputVal) {
        getDirectDataWithUid(page);
      } else {
        getDirectData(page);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleStartChange = (val) => {
    setParams({ ...params, startDate: val });
  };

  const handleEndChange = (val) => {
    setParams({ ...params, endDate: val });
  };

  const handleClick = (uid) => {
    setId(uid);
    setSubordinatesVisible(true);
  };

  const handleClickModel = (uid) => {
    setSubordinatesVisible(false);
    setId(uid);
    setSubordinatesVisible(true);
  };

  return (
    <div className="p-[.2rem_.08rem_.7rem_.08rem] bg-[var(--colour-68)] h-full flex flex-col">
      <div className="flex items-center mb-[.2rem]">
        <DatetimePickerBox
          startDate={params.startDate}
          endDate={params.endDate}
          rangeCheckClassName="!w-[3.6rem] bg-[var(--colour-5)] mr-[.2rem]"
          onChangeStartDate={handleStartChange}
          onChangeEndDate={handleEndChange}
          onSubmit={() => {
            setParams({ ...params, page: 1 });
            getData(1);
          }}
        />

        <div className="flex-1">
          <Input
            value={inputVal}
            onChange={(e) => {
              let val = setInputValue(e.target.value);
              setInputVal(val);
            }}
            type="text"
            placeholder={t('refer.myId')}
            innerClassName="!h-[.46rem] !border-transparent !pl-[.22rem] !pr-[.24rem]"
            topClassName={`rounded-[19.98rem] overflow-hidden bg-[var(--colour-5)]`}
            inputClassName="m-input"
            rightInner={
              loading ? (
                <div className="w-[.24rem] h-[.24rem] text-[var(--colour-13)] search-loading">
                  <Icon name={IconName.SearchLoading} />
                </div>
              ) : (
                <div
                  className="w-[.24rem] h-[.24rem] text-[var(--colour-13)] cursor-pointer"
                  onClick={() => {
                    setParams({ ...params, page: 1 });
                    getData(1);
                  }}
                >
                  <Icon name={IconName.TagsSearch} />
                </div>
              )
            }
            clearIconClassName="!w-[.24rem] !h-[.24rem]"
          />
        </div>
      </div>
      <div className="flex-1 overflow-y-auto scrollbar-none">
        {loading ? (
          <Loading3 className1="w-full h-full flex items-center justify-center"></Loading3>
        ) : (
          <>
            {list && list.length > 0 ? (
              <List finished={finished} onLoad={loadData} offset={0} loadingText={<Loading3 className1="w-full" />}>
                {list.map((item, index) => {
                  return <DataItem key={index} showBg={index % 2 == 0} data={item} handleClick={handleClick} />;
                })}
              </List>
            ) : (
              <>
                <NoData className="w-full h-full" />
              </>
            )}
          </>
        )}
      </div>

      <SubordinatesDetails
        setShowVerify={setSubordinatesVisible}
        showVerify={subordinatesVisible}
        id={id}
        paramStartDate={paramStartDate}
        paramEndDate={paramEndDate}
        handleClickModel={handleClickModel}
      />
      <DrawerStatistics>
        <div className="p-[.14rem_.74rem] text-[.2rem] font-[500] leading-[.24rem]">
          <div className="flex items-center mb-[.18rem]">
            <div className="flex items-center w-[3.4rem] shrink-0">
              <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                {t('refer.direct_data_numero_de_inscritos')}
              </div>
              <div className="text-[var(--colour-38)]">{totalInfo?.invitee_user_count || 0}</div>
            </div>
            <div className="flex items-center">
              <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                {t('refer.direct_data_numero_total_de_recargas')}
              </div>
              <div className="text-[var(--colour-38)]">{totalInfo?.invitee_deposit_count || 0}</div>
            </div>
          </div>
          <div className="flex items-center mb-[.14rem]">
            <div className="flex items-center w-[3.4rem] shrink-0">
              <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                {t('refer.direct_data_primeiros_depositos')}
              </div>
              <div className="text-[var(--colour-38)]">{totalInfo?.invitee_register_deposit_count || 0}</div>
            </div>
            <div className="flex items-center">
              <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                {t('refer.direct_data_deposito')}
              </div>
              <div className="text-[var(--colour-38)]">
                {getShowMoney(totalInfo?.invitee_deposit_amount || 0, 2, 2)}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center w-[3.4rem] shrink-0">
              <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                {t('refer.direct_data_apostas_validas')}
              </div>
              <div className="text-[var(--colour-38)]">{getShowMoney(totalInfo?.invitee_wager_amount || 0, 2, 2)}</div>
            </div>
          </div>
        </div>
      </DrawerStatistics>
    </div>
  );
};

export default NTemplate001;

const DataItem = ({ showBg = false, data, handleClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'rounded-normal p-[.18rem_.28rem_.22rem_.66rem] text-[.2rem] font-[500] leading-[.24rem] relative',
        {
          'bg-[var(--colour-69)] shadow-[0_.04rem_.04rem_0_rgba(0,0,0,0.02)]': showBg,
        },
      )}
    >
      <div className="flex items-center mb-[.08rem]">
        <div className="flex items-center w-[3rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-38)] mr-[.2rem]">{data?.uid || ''}</div>
          <div
            className="w-[.28rem] h-[.28rem] text-[var(--colour-1)] cursor-pointer"
            onClick={() => {
              copy(`${data?.uid || ''}`);
              toast.success(t('home.toast006'), {
                containerId: 'global',
              });
            }}
          >
            <Icon name={IconName.Copy4} />
          </div>
        </div>
        <div
          className="flex items-center flex-1"
          onClick={() => {
            if (Number(data?.total_direct_members) > 0) {
              handleClick(data.uid);
            }
          }}
        >
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_data_subordinados_dele')}</div>
          {Number(data?.total_direct_members) > 0 ? (
            <div
              className="text-[var(--colour-1)] underline cursor-pointer"
              onClick={() => {
                handleClick(data.uid);
              }}
            >
              {data?.total_direct_members || 0}
            </div>
          ) : (
            <div className="text-[var(--colour-38)]">-</div>
          )}
        </div>
      </div>
      <div className="flex items-center mb-[.1rem]">
        <div className="flex items-center w-[3rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-44)] mr-[.12rem]">{t('refer.direct_data_deposito')}</div>
          <div className="text-[var(--colour-38)]">{getShowMoney(data?.deposit_amount || 0, 2, 2)}</div>
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_data_data_de_cadastro')}</div>
          <div className="text-[var(--colour-38)]">
            {getConfigTimeZoneTimestamp(data?.create_time * 1000).format('DD/MM/YYYY')}
          </div>
        </div>
      </div>

      <div className="flex items-center mb-[.1rem]">
        <div className="flex items-center w-[3rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-44)] mr-[.12rem]">{t('refer.direct_data_apostas_validas')}</div>
          <div className="text-[var(--colour-38)]">{getShowMoney(data?.wager_amount || 0, 2, 2)}</div>
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_data_data_de_login')}</div>
          <div className="text-[var(--colour-38)]">
            {getConfigTimeZoneTimestamp(data?.last_login_time * 1000).format('DD/MM/YYYY')}
          </div>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex items-center w-[3rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-44)] mr-[.12rem]">{t('refer.direct_data_atual')}</div>
          {data?.online ? (
            <div className="text-[var(--colour-24)]">{t('refer.direct_data_online')}</div>
          ) : (
            <div className="text-[var(--colour-38)]">
              {t('refer.direct_data_offline_vezes', { val: data?.login_times || 0 })}
            </div>
          )}
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_data_estado')}</div>
          {data?.status == 1 ? (
            <div className="text-[var(--colour-24)]">{t('refer.direct_data_normal')}</div>
          ) : (
            <div className="text-[var(--colour-25)]">{t('refer.direct_data_levantamento_nao_permitido')}</div>
          )}
        </div>
      </div>

      <div className="absolute top-[0rem] left-[0rem] bg-[var(--colour-25)] rounded-[.04rem_0] text-[.16rem] font-[600] leading-[.2rem] text-[var(--colour-2)] px-[.06rem]">
        V{data?.vip_level || 0}
      </div>
    </div>
  );
};
