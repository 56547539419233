import { Fragment, memo, useEffect, useState } from 'react';
import { GetGameListCategoryGroupbytype } from '../../../../../api';
import HomeGameList from './homeGameList';
import { useSelector, shallowEqual } from 'react-redux';
import Loading2 from '../../../../../components/loading2';
import NoData from '../../../../../components/noData';

const CategoryGame = ({ tagsIndex }) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [keys, setKeys] = useState([]);

  const { websiteInitData } = useSelector(
    (state) => ({
      websiteInitData: state.app.websiteInitData,
    }),
    shallowEqual,
  );

  const getGameList = async (id) => {
    setList([]);
    setIsLoading(true);
    try {
      const res = await GetGameListCategoryGroupbytype({
        categoryId: id,
      });

      if (res.code == 0) {
        const result = res.result;

        setList(result.list);
        setKeys(Object.keys(result.list));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getCategortGameTitle = (key, key1) => {
    const data = websiteInitData.template;
    const arr = data.find((item) => {
      return item.module == 'ValidGameType';
    });

    if (arr && arr.config && arr.config[key] && arr.config[key][key1]) {
      return arr.config[key][key1];
    } else {
      return '';
    }
  };

  useEffect(() => {
    getGameList(tagsIndex);
  }, [tagsIndex]);

  return (
    <div key={tagsIndex}>
      {isLoading ? (
        <Loading2 className="py-[4rem]" />
      ) : (
        <>
          {keys && keys.length > 0 ? (
            <>
              {keys.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <HomeGameList
                      list={list[item]}
                      id={item}
                      row={3}
                      className="mt-[.32rem]"
                      isLoading={false}
                      icon={getCategortGameTitle(item, 'icon')}
                      title={getCategortGameTitle(item, 'lang_key')}
                    />
                  </Fragment>
                );
              })}
            </>
          ) : (
            <NoData className="py-[2rem]" />
          )}
        </>
      )}
    </div>
  );
};

export default memo(CategoryGame);
