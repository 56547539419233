import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Effect = {
  none: 'none',
  blur: 'blur',
};

const Image = ({ src, className, fallbackSrc, onError, effect = Effect.none }) => {
  const [errored, setErrored] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);
  const handleOnError = ({ currentTarget }) => {
    if (!errored) {
      setImgSrc(fallbackSrc);
      setErrored(true);
      onError(currentTarget);
    }
  };

  return (
    <LazyLoadImage
      src={imgSrc}
      effect={effect}
      placeholderSrc={src}
      className={`${className}`}
      onError={handleOnError}
      threshold={400}
      alt=""
    />
  );
};

export default Image;
