import DialogModal from '../..';
import { toast } from 'react-toastify';
import Loading from '../../../loading2';
import { Guideclaim, Guideturntable } from '../../../../api';
import Icon, { IconName } from '../../../icon';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, Fragment } from 'react';
import { getShowMoney } from '../../../../utils/helper';
import LimitClickWrapper from '../../../limitClickWrapper';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import useLangServerConfig from '../../../../hooks/useLangServerConfig';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setRefetchActivityData,
  setscheduleNum,
  setPromotionPendingUpdateTimestamp,
} from '../../../../redux/reducers/appSlice';
import BottomDownload from '../../downLoadBtn';

import Close from '../../../../assets/img/bulletinClose.png';
import giftboxSplite from '../../../../assets/img/pliotwheel/giftboxSplite.png';
import LuckyWheelRule from '../../luckyWheelRule';
import { isControllableSite } from '../../../../utils/helper';
import { toLocalePath } from '../../../../utils/localePath';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import useRouterHelper from '../../../../hooks/useRouterHelper';
import DownloadManager from '../../../../manager/downloadManager';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const Guide = ({
  taskStatus,
  wheelRule = {},
  activityID = '',
  pendingLog,
  noviceTaskConfig = [],
  handleClose = () => {},
  taskConditionDisplaySwitch,
}) => {
  const [singleItem, setsingleItem] = useState({}); // 展示单个任务
  const [progressBar, setprogressBar] = useState('0'); // 展示多个任务 计算进度条
  const [imgLoading, setImgloading] = useState(false);
  const [ruleIsOpen, setRuleIsOpen] = useState(false); // 是否展示论轮盘规则
  const [fulfilTask, setfulfilTask] = useState(false); // 任务全部完成 true
  const [isShowAlltask, setisShowAlltask] = useState(false);
  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  const { identity, websiteInfo, wheelBonusAmount } = useSelector(
    (state) => ({
      identity: state.user.identity,
      websiteInfo: state.app.websiteInitConfig?.website_info,
      wheelBonusAmount: state.app.wheelBonusAmount,
    }),
    shallowEqual,
  );

  const { handleClickWallet } = useRouterHelper();

  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const { getServerLangText } = useLangServerConfig();

  const WheelBonusCloseCb = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WheelWindow_Popup);
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.PilotWheel,
      }),
    );
  };
  const handleDraw = async () => {
    if (pendingLog?.id == 0) return;

    try {
      const res = await Guideclaim({ id: pendingLog.id });
      if (res.code === 0) {
        const result = res.data || {};
        handleClose();
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.WheelBonus,
            content: result.amount || 0,
            closeCb: WheelBonusCloseCb,
            zIndex: 999999,
            overlayClassName: 'wheelBonusMoney',
            modalType: result.currency == 'gift_money' ? 'wheel' : '',
          }),
        );

        dispatch(setNeedUpdateBalance({ need: true }));
        dispatch(setPromotionPendingUpdateTimestamp());
      } else if (res.code === 1008127) {
        // return dispatch(
        //   setBottomDownloadBoxPromptSwitch({
        //     visible: true,
        //     isAni: true,
        //     isForce: true,
        //     downloadText: t('guideWheel.downloadtips'),
        //   }),
        // );

        if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
          setBottomDownloadBoxPromptSwitch({
            visible: true,
            isForce: true,
            downloadText: t('guideWheel.downloadtips'),
          });
        }
      } else {
        if (res) {
          toast.error(t(`${res.code}`), {
            containerId: 'global',
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleGo = (type) => {
    switch (String(type)) {
      case '1':
        {
          //handleClose && handleClose();
          handleClickWallet(1, 1, 99999);
        }
        break;
      case '2': {
        handleClose && handleClose();
        navigate(toLocalePath('/'));
        break;
      }
      case '3': {
        // dispatch(
        //   setBottomDownloadBoxPromptSwitch({
        //     visible: true,
        //     isAni: true,
        //     isForce: true,
        //   }),
        // );

        if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
          setBottomDownloadBoxPromptSwitch({
            visible: true,
            isForce: true,
            downloadText: '',
          });
        }
        break;
      }
      case '4':
      case '5':
        if (isControllableSite()) {
          navigate(toLocalePath(`/security`));
          handleClose && handleClose();
        } else {
          // handleClose && handleClose();
          dispatch(
            setDialogModalVisible({
              zIndex: 99999,
              show: DialogModalChildrenKey.AccountSetting,
              showBindStyle: type == 4 ? 1 : 2,
            }),
          );
        }

        break;
    }
  };
  useEffect(() => {
    if (noviceTaskConfig && noviceTaskConfig.length > 0) {
      let count = 0;
      const total = noviceTaskConfig.length;
      const _len = noviceTaskConfig.filter((item) => {
        if (item.status == '2') {
          count += Number(item.schedule);
          return item;
        }
      }).length;

      // 任务全部完成
      if (total === _len) {
        dispatch(setscheduleNum(0));
        return setfulfilTask(true);
      } else {
        // 1 显示多种任务 0 展示单个任务
        if (taskConditionDisplaySwitch === 1) {
          setfulfilTask(false);
          dispatch(setscheduleNum(count));
          return setprogressBar(count);
        } else if (taskConditionDisplaySwitch === 0) {
          dispatch(setscheduleNum(0));
          setfulfilTask(false);
          const item = noviceTaskConfig.find((item) => {
            return item.status === '1';
          });
          setsingleItem(item);
        }
      }
    }
  }, [noviceTaskConfig]);

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => setisShowAlltask(false)}
        isOpen={isShowAlltask}
      >
        <TaskBox
          handleGo={(type) => {
            handleGo(type);
          }}
          list={noviceTaskConfig}
          progressBar={progressBar}
          handleClose={() => setisShowAlltask(false)}
          novicePilotRewards={getShowMoney(wheelBonusAmount) || '0'}
        />
      </DialogModal>

      <DialogModal
        handleClose={() => {
          setRuleIsOpen(false);
        }}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={ruleIsOpen}
      >
        <LuckyWheelRule
          lotteryHelp={wheelRule?.activity_rule_explain}
          handleClose={() => {
            setRuleIsOpen(false);
          }}
        />
      </DialogModal>

      {imgLoading ? (
        <div className="w-full h-full flex items-center justify-center mt-[-0.56rem]">
          <Loading></Loading>
        </div>
      ) : (
        <div className="select-none flex justify-center flex-col items-center relative mt-[.22rem]">
          <div className="w-[4.78rem] relative z-[10] h-[1.16rem]">
            <div className=" bg-no-repeat bg-[url(../assets/img/pliotwheel/guideSprites/MobileguideSprites.png)] bg-[length:6.04rem_9rem] w-[4.8rem] h-[1.18rem] bg-[-0.2rem_-0.2rem]"></div>

            <div className="text-[.36rem] font-[800] relative top-[-1rem] left-[50%] translate-x-[-50%] text-center text-[var(--colour-43)]">
              {t('guideWheel.spin_to_win')}
            </div>
          </div>

          <div className="w-[4.36rem] text-center text-[var(--colour-48)] text-[.24rem] font-[500]">
            {wheelRule.activity_title || ''}
          </div>

          <div className="relative mt-[2.1rem] w-full">
            <div
              className="absolute cursor-pointer z-[1] w-[.36rem] h-[.36rem] right-[.66rem] top-[-3.34rem] active:scale-[.95] transition-all text-[var(--colour-7)]"
              onClick={() => setRuleIsOpen(true)}
            >
              <Icon name={IconName.Explain} />
            </div>

            <div
              className="pilot_giftBox bg-no-repeat w-[6rem] h-[3.68rem] absolute z-[15] left-[.4rem] top-[-2.7rem] bg-[length:24rem_14.72rem]"
              style={{ backgroundImage: `url(${giftboxSplite})` }}
            ></div>

            <div className="bg-no-repeat  absolute left-[50%] translate-x-[-50%] top-[-2.2rem] bg-[url(../assets/img/pliotwheel/guideSprites/MobileguideSprites.png)] bg-[length:6.04rem_9rem] w-[5.64rem] h-[3.06rem] bg-[-0.2rem_-5.74rem]"></div>

            <div className=" m-[0_auto] bg-no-repeat bg-[url(../assets/img/pliotwheel/guideSprites/MobileguideSprites.png)] bg-[length:6.04rem_9rem] w-[3.32rem] h-[1.56rem] bg-[-0.2rem_-1.78rem]"></div>

            <div className="w-[5.08rem] z-[7] absolute left-[50%] translate-x-[-50%] bottom-[-0.7rem] select-none">
              <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/guideSprites/MobileguideSprites.png)] bg-[length:6.04rem_9rem] w-[5.12rem] h-[1.6rem] bg-[-0.2rem_-3.74rem]"></div>

              <div className="text-[var(--colour-43)] absolute z-[8] text-[.44rem] font-[700] min-w-[6.62rem] flex items-center justify-center left-[50%] translate-x-[-50%] bottom-[.36rem] select-none">
                {websiteInfo.currency_symbol || ''}
                <span className="">{`${getShowMoney(wheelBonusAmount)}`}</span>
              </div>
            </div>
          </div>

          {taskConditionDisplaySwitch === 0 && !fulfilTask && identity ? (
            <>
              <LimitClickWrapper
                className="mt-[1.06rem] w-[6.22rem] h-[.92rem] bg-[var(--colour-34)] rounded-[.16rem] flex items-center p-[.16rem] border border-[var(--colour-6)]"
                onClick={() => {}}
              >
                <div className="leading-[.56rem] w-[3.94rem] h-full truncate ml-[.2rem] text-[.24rem] font-[500] text-[var(--colour-44)]">
                  {getServerLangText(singleItem.lang_key)}
                </div>

                <div
                  className="text-[var(--colour-2)] w-[1.72rem] h-[.6rem] flex items-center  justify-center rounded-normal cursor-pointer active:scale-[.95] transition-all select-none bg-[var(--colour-1)] text-[.28rem] font-[600]"
                  onClick={() => {
                    handleGo(singleItem.task_type);
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_GoMission);
                  }}
                >
                  {t('guideWheel.go')}
                </div>
              </LimitClickWrapper>
            </>
          ) : (
            <></>
          )}

          {taskConditionDisplaySwitch === 1 && !fulfilTask && identity ? (
            <>
              <div className="mt-[.96rem] flex flex-col items-center">
                <div className="flex items-center">
                  <div className="w-[4.34rem] h-[.32rem] bg-pliotWheel_bar rounded-[.16rem] relative overflow-hidden">
                    <div
                      className="h-full rounded-[.14rem] absolute left-[.04rem] top-[0]"
                      style={{
                        background: 'linear-gradient(180deg, #FFE485 0%, #FFF59A 19.5%, #D97F2B 85%, #FFD66B 100%)',
                        width: `${progressBar}%`,
                      }}
                    ></div>
                  </div>
                  <div className="ml-[.24rem] text-[.28rem] font-[500] text-[var(--colour-48)]">{progressBar}%</div>
                </div>

                <LimitClickWrapper
                  className="mt-[.34rem] flex items-center font-[600] text-[.28rem] justify-center w-[3.24rem] h-[.68rem] rounded-normal cursor-pointer active:scale-[.95] transition-all select-none bg-[var(--colour-1)] text-[var(--colour-2)]"
                  onClick={() => {
                    setisShowAlltask(true);
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_Claim + progressBar);
                  }}
                >
                  {t('guideWheel.claim')}
                </LimitClickWrapper>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* 未登录显示领取按钮 */}
          {!identity ? (
            <LimitClickWrapper
              className=" mt-[.9rem]   w-[3.24rem] h-[.68rem] rounded-normal flex items-center justify-center bg-[var(--colour-1)] text-[var(--colour-2)] font-[600]  text-[.28rem] cursor-pointer active:scale-[.95] transition-all select-none"
              onClick={() => {
                dispatch(
                  setDialogModalVisible({
                    show: DialogModalChildrenKey.LoginBox,
                  }),
                );
              }}
            >
              {t('guideWheel.claim')}
            </LimitClickWrapper>
          ) : null}

          {/* 完成所有任务  */}
          {identity && fulfilTask ? (
            <>
              {taskStatus && taskStatus == 11 ? (
                <div className=" mt-[.9rem]   w-[3.24rem] h-[.68rem] flex items-center justify-center text-[var(--colour-48)] font-[600]  text-[.32rem]">
                  {t('promotion.calculating_result')}
                </div>
              ) : (
                <LimitClickWrapper
                  className=" mt-[.9rem]   w-[3.24rem] h-[.68rem] rounded-normal flex items-center justify-center bg-[var(--colour-1)] text-[var(--colour-2)] font-[600]  text-[.28rem] cursor-pointer active:scale-[.95] transition-all select-none"
                  onClick={() => {
                    handleDraw();
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_Claim);
                  }}
                >
                  {t('guideWheel.claim')}
                </LimitClickWrapper>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

const TaskBox = ({ handleClose, list, progressBar, handleGo = () => {}, novicePilotRewards }) => {
  const { t } = useTranslation();
  const { getServerLangText } = useLangServerConfig();

  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  return (
    <>
      <div className="w-[6.86rem] h-[9.84rem] bg-[var(--colour-35)] rounded-[.16rem] relative pt-[.52rem] pb-[.4rem] flex flex-col items-center">
        <div
          className="w-[.52rem] h-[.48rem] cursor-pointer absolute right-0 top-[-0.6rem]"
          onClick={() => {
            handleClose();
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_CloseMission);
          }}
        >
          <img src={Close} alt="" className="not-drag" />
        </div>
        <div className="text-[var(--colour-48)] text-[.4rem] text-center font-[500]">{t('guideWheel.text1')}</div>
        <div className="mt-[.34rem] flex items-center text-[var(--colour-1)] text-[.8rem] font-[700]">
          <div className="w-[1.6rem] h-[1.6rem]">
            <Icon name={IconName.Refer} />
          </div>
          {websiteInfo.currency_symbol || ''}
          {novicePilotRewards}
        </div>

        <div className="w-[6.22rem] min-h-[1px] mt-[.38rem]" style={{ background: 'rgba(255,255,255,0.50)' }}></div>

        <div className="flex items-center mt-[.52rem]">
          <div className="w-[5.36rem] h-[.32rem] bg-pliotWheel_bar rounded-[.16rem] relative overflow-hidden">
            <div
              className="h-full rounded-[.14rem] absolute left-[.04rem] top-[0]"
              style={{
                background: 'linear-gradient(180deg, #FFE485 0%, #FFF59A 19.5%, #D97F2B 85%, #FFD66B 100%)',
                width: `${progressBar}%`,
              }}
            ></div>
          </div>
          <div className="ml-[.26rem] text-[.28rem] font-[500] text-[var(--colour-48)]">{progressBar}%</div>
        </div>

        <div className="mt-[.52rem] space-y-[.24rem] overflow-y-auto  hidden-scroll-y">
          {list &&
            list.map((item, index) => {
              return (
                <Fragment key={index}>
                  <div className="w-[6.22rem] h-[.92rem] bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-[.16rem] flex items-center p-[.16rem]">
                    <div className="w-[3.98rem] leading-[.56rem] h-full truncate mr-[.2rem] text-[.24rem] font-[500]">
                      {getServerLangText(item.lang_key)}
                    </div>

                    <div
                      className={`${item.status === '2' ? '' : ' rounded-normal cursor-pointer active:scale-[.95] transition-all select-none bg-[var(--colour-1)] text-[var(--colour-2)] text-[.28rem] font-[600]'} w-[1.68rem] h-[.6rem] flex items-center justify-center`}
                    >
                      {item.status === '2' ? (
                        <div className="w-[.48rem] h-[.48rem]">
                          <Icon name={IconName.PilotWheelCheck} />
                        </div>
                      ) : (
                        <>
                          <LimitClickWrapper
                            className="w-full h-full flex items-center justify-center"
                            onClick={() => {
                              handleGo(item.task_type);
                              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_GoMission);
                            }}
                          >
                            {t('guideWheel.go')}
                          </LimitClickWrapper>
                        </>
                      )}
                    </div>
                  </div>
                </Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Guide;
