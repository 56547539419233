import Icon, { IconName } from '../../../../../components/icon';
import Input from '../../../../../components/input';

const SearchInput = ({ value, onSearchChange, onClickSearch, placeholder = '' }) => {
  return (
    <div
      className={`w-full bg-[var(--colour-69)] rounded-[.12rem] border border-[var(--colour-6)] flex items-center h-[.8rem]`}
    >
      <Input
        isShowClearIcon={false}
        rightInner={
          <div className="flex items-center ">
            <span
              className="w-[.32rem] h-[.32rem] block text-[var(--colour-2)] cursor-pointer ml-[.08rem]"
              onClick={onClickSearch}
            >
              <Icon name={IconName.TagsSearch} />
            </span>
          </div>
        }
        value={value}
        onChange={onSearchChange}
        placeholder={placeholder}
        innerClassName="!pl-[.26rem] !pr-[.4rem]"
        topClassName={`w-full border-none`}
        inputClassName="!text-[.24rem] placeholder:!text-[.24rem] placeholder:!text-[var(--colour-14)] !text-[var(--colour-2)]"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClickSearch();
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
