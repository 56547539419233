import Icon, { IconName } from '../../icon';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { get } from 'lodash';
import cn from 'classnames';
import useHandleLogic from '../../../hooks/useHandleLogic';
import layout3LetterIcon from '../../../assets/layout3-img/letter.png';
import { setDrawerMenuVisible } from '../../../redux/reducers/appSlice';
import { isControllableSite } from '../../../utils/helper';

export default function PrivateLette() {
  const dispatch = useDispatch();
  const { userObj, unReadCount, defaultLang, needUpdateBalance, identity, showSupportNew, drawerMenuVisible } =
    useSelector(
      (state) => ({
        unReadCount: get(state, 'app.unReadCount'),
        userObj: get(state, 'user.userObj'),
        defaultLang: get(state, 'user.defaultLang'),
        needUpdateBalance: state.app.needUpdateBalance,
        showSupportNew: state.app.showSupportNew,
        identity: state.user.identity,
        drawerMenuVisible: state.app.drawerMenuVisible,
      }),
      shallowEqual,
    );
  const { handleClickVector } = useHandleLogic();

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  return (
    <div
      className={cn(
        'w-[.96rem] h-[.96rem] flex justify-center items-center cursor-pointer active:scale-[.95] transition-all select-none bg-[var(--colour-34)] rounded-normal text-[var(--colour-37)]',
        {
          'rounded-normal hidden': !isControllableSite(),
          '!w-[.6rem] !h-[.6rem] !ml-[.16rem]': isControllableSite(),
        },
      )}
      onClick={() => {
        handleClickVector();
        handleLinkClick();
      }}
    >
      {!isControllableSite() ? (
        <div className="w-[.44rem] h-[.36rem] relative">
          <Icon name={IconName.Vector} />
          {unReadCount > 0 ? (
            <div className="w-[.2rem] h-[.2rem] rounded-full bg-red-dot flex justify-center items-center font-[600] absolute -right-[.1rem] -top-[.1rem]"></div>
          ) : (
            <></>
          )}
        </div>
      ) : isControllableSite() ? (
        <div className="w-[.6rem] h-[.6rem] rounded-normal flex items-center justify-center bg-[var(--colour-34)] relative">
          <div className={cn('w-[.36rem] h-[.36rem] text-[var(--colour-37)]')}>
            <Icon name={IconName.Email2} />
          </div>
          {unReadCount > 0 ? (
            <div className="w-[.2rem] h-[.2rem] rounded-full bg-red-dot flex justify-center items-center font-[600] absolute -right-[.1rem] -top-[.1rem]"></div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="w-[.48rem] h-[.48rem] relative">
          <img className="not-drag" src={layout3LetterIcon} />
          {unReadCount > 0 ? (
            <div className="w-[.2rem] h-[.2rem] rounded-full bg-red-dot flex justify-center items-center font-[600] absolute -right-[.1rem] -top-[.1rem]"></div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
