import { useState, useEffect, memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { GameListGameTypeReq } from '../../../../../api';
import GameItem from '../../../../../components/gameItem';
import NoData from '../../../../../components/noData';
import { useStateIfMounted } from 'use-state-if-mounted';
import Loading from '../../../../../components/loading2';
import LoadMore from '../../../../../components/loadMore';
import { isUseLayoutMobileHome } from '../../../../../utils/helper';
import cn from 'classnames';
import { get } from 'lodash';

const pageSize = window.innerWidth <= 576 ? 12 : 28;
const layoutMobilePageSize = 9;
export const page_size = isUseLayoutMobileHome() ? layoutMobilePageSize : pageSize;

const DHomeGameList = ({ typyID }) => {
  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
    }),
    shallowEqual,
  );

  const [list, setList] = useStateIfMounted([]);
  const [reRequest, setreRequest] = useStateIfMounted('');
  const [loading, setLoading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [showLoadMore, setShowLoadMore] = useStateIfMounted(false);

  const [params, setParams] = useState({
    game_type_id: typyID, //游戏有效分类id 必传
    source: '', // 厂商编号  可空
    with_source: 1, //  1表示返回对应game_type_id厂商列表, 0返回空列表 可空
    page: 1, // 页码  可空(默认1)
    page_size: page_size, // 每页条数 可空（默认10）
    search: '', // 搜索游戏名字, 搜索用全部厂商

    refreshNow: false, // 是否刷新
  });

  useEffect(() => {
    setParams({ ...params, game_type_id: typyID, page: 1 });
  }, [typyID]);

  useEffect(() => {
    if (params.page == 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    const getGameList = () => {
      const actionList = [
        {
          // 游戏分类
          test: () => true,
          action: () => {
            if (params.game_type_id) {
              GameListGameTypeReq(params).then((res) => {
                if (params.page == 1) {
                  setLoading(false);
                } else {
                  setLoadingMore(false);
                }
                if (res.code == 0) {
                  const result = res.result;
                  if (params.page == 1) {
                    setList(result.game_list);
                  } else {
                    const list2 = list.concat(result.game_list);
                    setList(list2);
                  }

                  const _pageCount = Math.ceil(result.pages.total / page_size);

                  if (params.page < _pageCount) {
                    setShowLoadMore(true);
                  } else {
                    setShowLoadMore(false);
                  }
                }
              });
            }
          },
        },
      ];

      const found = actionList.find((item) => item.test());
      found && found.action();
    };

    const timeId = setTimeout(getGameList, 1000);

    return () => {
      clearTimeout(timeId);
    };
  }, [params, reRequest]);

  const handleFavoriteCallback = () => {
    setreRequest(new Date().getTime());
  };

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  return (
    <>
      <div className={`${isUseLayoutMobileHome() && responsiveMode == 'mobile' ? '' : 'pb-[24px]'} `}>
        {loading ? (
          <Loading height="200px" />
        ) : (
          <>
            {list && list.length > 0 ? (
              <div
                className={cn('games__row', {
                  '!grid-cols-[65px_65px_65px] !justify-around': isUseLayoutMobileHome() && responsiveMode == 'mobile',
                })}
              >
                {list &&
                  list.length > 0 &&
                  list.map((item, index) => (
                    <GameItem
                      model={item}
                      key={item.id}
                      handleFavoriteCallback={handleFavoriteCallback}
                      handleFavoriteNeedCallback={typyID == '001'}
                    />
                  ))}
              </div>
            ) : (
              <NoData className="py-[100px]" />
            )}
            {showLoadMore ? (
              <LoadMore
                className={isUseLayoutMobileHome() && responsiveMode == 'mobile' ? 'mt-[8px]' : 'mt-[40px]'}
                onClick={handleLoadMore}
                showLoading={loadingMore && params.page > 1}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(DHomeGameList);
