import { useState, memo, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../../../../utils/localePath';
import { openUrl } from '../../../../utils/openUrl';
import {
  cmsImgUrl,
  isControllableSite,
  isUseLayoutMobile,
  isLightTheme,
  getTemplateId,
  executeWithCondition,
  is777BetIO,
} from '../../../../utils/helper';
import { get } from 'lodash';
import cn from 'classnames';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import sevenBetLicenseLogo from '../../../../assets/img/7b-license.png';
import { GetLicenseStamp } from '../../../../api';
import useFooterData from '../../../../hooks/useFooterData';

const Help = ({ helpList = [], setFlag = () => {}, flag }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.style.height = flag ? listRef.current.scrollHeight + 'px' : '0px';
    }
  }, [flag]);

  const clickItem = (item) => {
    navigate(toLocalePath(`/helpInfo?id=${item.id}&type=${item.type}&name=${encodeURIComponent(item.title)}`));
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-[.16rem]" onClick={() => setFlag(!flag)}>
        <div className={cn('font-[600] text-[.28rem] leading-[.4rem] text-[var(--colour-14)]')}>{t('home.help')}</div>
        <div
          className={cn(
            `w-[.18rem] h-[.1rem] transition-chat text-[var(--colour-11)] ${flag ? '' : ' rotate-[-90deg]'}`,
          )}
        >
          <Icon name={IconName.HelpRight} />
        </div>
      </div>
      <div
        className={cn(`font-[600] text-[.28rem] text-[var(--colour-18)] leading-[.4rem] overflow-hidden duration-300`)}
        ref={listRef}
      >
        {helpList &&
          helpList.length > 0 &&
          helpList.map((item, index) => {
            return (
              <div
                key={item.id}
                className={cn('h-[.48rem] mb-[.1rem] flex items-center pl-[.2rem]', {
                  'justify-center mb-[0rem] pl-[0rem] h-[.94rem] border-b border-solid border-[var(--colour-6)] font-[700] text-[.24rem]':
                    isControllableSite(),
                })}
                onClick={() => clickItem(item)}
              >
                {item.title}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const LicenseWrapper = ({ item }) => {
  const { websiteLicenseSwitch } = useSelector(
    (state) => ({
      websiteLicenseSwitch: get(state, 'app.websiteInitConfig.website_info.website_license_switch', '0'),
    }),
    shallowEqual,
  );

  const getLicenseUrl = (stamp) => {
    return `https://verification.anjouangaming.org/validate?domain=777bet.io&seal_id=0b1207891ee5f574d6caff2f9cd168e9d068e6b634c47b1623f63cee3e91fefb483091a7cf41e8dfefd8ad49831fe475&stamp=${stamp}`;
  };

  const [licenseUrl, setLicenseUrl] = useState('/');

  useEffect(() => {
    if (websiteLicenseSwitch == '1') {
      if (is777BetIO()) {
        let execLicenseFunInit = false;
        const execLicenseFun = () => {
          if (window.anj_642c95b7_58fc_45e3_8343_3f2514c6bbab) {
            window.anj_642c95b7_58fc_45e3_8343_3f2514c6bbab.init();
            execLicenseFunInit = true;
          }
        };
        const conditionFunc = () => {
          return execLicenseFunInit == true;
        };
        executeWithCondition(execLicenseFun, conditionFunc, 300);
      } else {
        GetLicenseStamp().then((res) => {
          if (res.code == 0) {
            const stamp = res.result.stamp;
            setLicenseUrl(getLicenseUrl(stamp));
          }
        });
      }
    }
  }, [websiteLicenseSwitch]);

  return websiteLicenseSwitch == '1' ? (
    is777BetIO() ? (
      <div
        id="anj-642c95b7-58fc-45e3-8343-3f2514c6bbab"
        data-anj-seal-id="642c95b7-58fc-45e3-8343-3f2514c6bbab"
        data-anj-image-size="50"
        data-anj-image-type="basic-small"
      ></div>
    ) : (
      <a href={licenseUrl} target="_blank" rel="nonoopener">
        <img src={sevenBetLicenseLogo} className="h-[50px]" alt="" />
      </a>
    )
  ) : (
    <span
      className={cn({
        'cursor-pointer': item.url != '',
      })}
      onClick={() => {
        if (item.url) {
          openUrl(item.url);
        }
      }}
    >
      <img src={cmsImgUrl(item.img)} className="h-[1rem]" alt="" />
    </span>
  );
};

const Line = () => {
  return <div className="bg-[var(--colour-6)] w-full h-[1px]"></div>;
};
const Sign = ({ data = [] }) => {
  return (
    <div className="flex items-center space-x-[.56rem]">
      {data.map((item, index) => {
        return item.key == 'license' ? (
          <LicenseWrapper item={item} key={index} />
        ) : (
          <span
            key={index}
            className={cn({
              'cursor-pointer': item.url != '',
            })}
            onClick={() => {
              if (item.url) {
                openUrl(item.url);
              }
            }}
          >
            <img src={cmsImgUrl(item.img)} className="h-[1rem]" alt="" />
          </span>
        );
      })}
    </div>
  );
};

const Text = ({ text = null }) => {
  return text != null ? (
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      className="font-[400] text-[.24rem] leading-[.36rem] text-secondary-content wrap-break-word"
    ></div>
  ) : null;
};
const FollowList = ({ list = [] }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={`flex ${['d-template001'].includes(getTemplateId()) ? 'space-x-[.3rem]' : 'space-x-[.2rem]'}`}>
        {list.length > 0 &&
          list.map((item, index) => {
            return (
              <div key={index} className={`w-[.88rem] h-[.88rem] flex justify-center items-center cursor-pointer`}>
                {item.url === '' ? (
                  <img className="w-full" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                ) : (
                  <a
                    onClick={() => {
                      openUrl(item.url, '_blank');
                    }}
                  >
                    <img className="w-full" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                  </a>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const ProviderListMo = () => {
  return (
    <div className={`${['d-template001'].includes(getTemplateId()) ? 'py-[.32rem]' : 'mb-[.32rem]'}`}>
      <div className="flex mb-[.16rem] justify-center">
        <div className="">
          <img className="w-[1.7rem]" src={`/static/img/footer/provider/1pg${isLightTheme() ? '_2' : ''}.png`} />
        </div>
        <div className="">
          <img className="w-[1.7rem]" src="/static/img/footer/provider/2jili.png" />
        </div>
        <div className="">
          <img className="w-[1.7rem]" src={`/static/img/footer/provider/3evo${isLightTheme() ? '_2' : ''}.png`} />
        </div>
        <div className="">
          <img className="w-[1.7rem]" src="/static/img/footer/provider/4jdb.png" />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="">
          <img className="w-[1.7rem]" src="/static/img/footer/provider/5fc.png" />
        </div>
        <div className="">
          <img className="w-[1.7rem]" src={`/static/img/footer/provider/6pp${isLightTheme() ? '_2' : ''}.png`} />
        </div>
        <div className="">
          <img className="w-[1.7rem]" src={`/static/img/footer/provider/7yb${isLightTheme() ? '_2' : ''}.png`} />
        </div>
      </div>
    </div>
  );
};

const NTemplate001 = () => {
  const [flag, setFlag] = useState(false);
  const { footerData } = useSelector((state) => ({
    footerData: get(state, 'app.websiteInitData.footer'),
  }));

  const { followList, licenseList, text, helpList } = useFooterData(footerData);

  return (
    <>
      <footer className="footer_dom p-[.32rem] bg-[var(--colour-17)]">
        <>
          <div className="mb-[.28rem]">
            <Help flag={flag} setFlag={setFlag} helpList={helpList} />
          </div>

          {isUseLayoutMobile() && !flag ? (
            <div className="my-[.32rem]">
              <Line />
            </div>
          ) : (
            <></>
          )}

          {isControllableSite() ? <ProviderListMo /> : null}

          {followList.length > 0 && (
            <>
              <FollowList list={followList} />
              {isUseLayoutMobile() ? (
                <div className="my-[.32rem]"></div>
              ) : (
                <div className="my-[.32rem]">
                  <Line />
                </div>
              )}
            </>
          )}

          {licenseList.length > 0 && (
            <div className="mb-[.32rem]">
              <Sign data={licenseList} />
            </div>
          )}
          <div>
            <Text text={text} />
          </div>
        </>
      </footer>
    </>
  );
};

export default memo(NTemplate001);
