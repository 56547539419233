import { useState } from 'react';
import Icon, { IconName } from '../../../../../../components/icon';
import PasswordInput from '../../../../../../components/passwordInput/passwordInput';

export default function CodeBox({ title, handleCodeChange, className = '', headerClassName = '' }) {
  const [isShowNum, setIsShowNum] = useState(false);
  return (
    <div className={className}>
      <div className={`flex items-center justify-between ${headerClassName}`}>
        <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-48)]">{title}</div>
        <div
          className="w-[.48rem] h-[.48rem] flex items-center justify-center"
          onClick={() => {
            setIsShowNum(!isShowNum);
          }}
        >
          {isShowNum ? (
            <div className="text-[var(--colour-1)] w-[.48rem] h-[.48rem]">
              <Icon name={IconName.NewShowEye} />
            </div>
          ) : (
            <div className="text-[var(--colour-6)] w-[.48rem] h-[.48rem]">
              <Icon name={IconName.NewhideEye} />
            </div>
          )}
        </div>
      </div>

      <div className="password_code">
        <PasswordInput
          mask={!isShowNum}
          type="number"
          length={6}
          onChange={(e) => {
            handleCodeChange(e);
          }}
        />
      </div>
    </div>
  );
}
