import moment from 'moment';
import cn from 'classnames';
import DialogModal from '../..';
import Close from '../../../close';
import Loading2 from '../../../loading2';
import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';
import LuckyWheelRule from '../../luckyWheelRule';
import { Guidedraw, ActivityGuideDraw, ActivityGuideLog, Guideturntable } from '../../../../api';
import { useRef, useState, useEffect } from 'react';
import { getShowMoney, isLogin } from '../../../../utils/helper';
import LimitClickWrapper from '../../../limitClickWrapper';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setSpinguideWheelStatus } from '../../../../redux/reducers/userSlice';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setguideWheelSwitch,
  setNeedUpdateBalance,
  setRefetchActivityData,
  setwheelBonusAmount,
} from '../../../../redux/reducers/appSlice';

import Light1 from '../../../../assets/img/pliotwheel/lights_03.png';
import Light2 from '../../../../assets/img/pliotwheel/lights_04.png';
import Light3 from '../../../../assets/img/pliotwheel/lights_05.png';
import Light4 from '../../../../assets/img/pliotwheel/lights_06.png';
import WheelHighLight from '../../../../assets/img/pliotwheel/saoguang.webp';
import { toast } from 'react-toastify';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';

const lights = [Light1, Light2, Light3, Light4];
const Wheel = ({
  type,
  wheelRule,
  drawNum = 0,
  setisFinish,
  activityID = '',
  novicePilotSwitch,
  wheelRewardList = [],
}) => {
  const wheelWrapper = useRef(null);
  const [maxnum, setMaxnum] = useState(0); // 计算最大奖励值
  const [onStart, setOnstart] = useState(false);
  const [imgLoading, setImgloading] = useState(false);
  const [sweepLight, setSweepLight] = useState(false); // 扫光特效
  const [ruleIsOpen, setRuleIsOpen] = useState(false);
  const [recordIsOpen, setRecordIsOpen] = useState(false);
  const [fingerVisible, setFingerVisible] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // 灯光闪烁效果
  const [questionMakePosition, setquestionMakePosition] = useState([]); // 轮盘问号位置

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  // useEffect(() => {
  //   let loadedCount = 0;

  //   const images = [WheelHighLight];

  //   const imagesToLoad = images.length;

  //   images.forEach((src) => {
  //     const img = new Image();
  //     img.onload = () => {
  //       loadedCount++;
  //       if (loadedCount === imagesToLoad) setImgloading(false);
  //     };
  //     img.onerror = () => {
  //       loadedCount++;
  //       if (loadedCount === imagesToLoad) setImgloading(false);
  //     };
  //     img.src = src;
  //   });

  //   return () => {};
  // }, []);

  const getRandomElementFromArray = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };

  const formatString = (str) => {
    if (str === '?') {
      return '??????';
    } else {
      const floatNum = parseFloat(str);
      const intPartLen = Math.floor(floatNum).toString().length;

      if (intPartLen >= 6) {
        return str;
      } else {
        const paddingZeros = Math.min(Math.max(6 - intPartLen, 0), 100);
        const formattedNum = floatNum.toFixed(paddingZeros);
        return formattedNum;
      }
    }
  };

  useEffect(() => {
    let count = 0;
    const timer = window.setInterval(() => {
      setFingerVisible((prev) => !prev);
      count++;

      if (count > 4) {
        window.clearInterval(timer);
        setFingerVisible(false);
      }
    }, 2000);

    return () => {
      window.clearInterval(timer);
    };
  }, []);

  const [list, setList] = useState([]);

  useEffect(() => {
    if (wheelRewardList && wheelRewardList.length) {
      const arr = [];
      wheelRewardList.forEach((item) => {
        if (item.amount == '?') {
          arr.push(Number(item.tap_position));
        }
      });

      setquestionMakePosition(arr);

      const data = wheelRewardList.map((_) => ({
        text1: _.amount === '?' ? formatString(_.amount) : formatString(getShowMoney(_.amount)),
        position: _.tap_position,
        amount: _.amount,
      }));

      setList(data);

      const amountData = data.map((item) => {
        return item.amount;
      });

      const filteredArr = amountData.filter((item) => !isNaN(Number(item)));

      const max = Math.max(...filteredArr);
      setMaxnum(getShowMoney(max));
    }
  }, [wheelRewardList]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % lights.length);
    }, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const WheelBonusCloseCb = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WheelWindow_Popup);
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.PilotWheel,
      }),
    );
  };

  const executeSequence = async (amount, currency) => {
    await delay(5500);
    setSweepLight(true);
    await delay(400);
    setSweepLight(false);
    await delay(600);
    setOnstart(false);
    setisFinish(true);
    dispatch(setSpinguideWheelStatus('1'));

    if (type == 'Count') {
      dispatch(setNeedUpdateBalance({ need: true }));
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.WheelBonus,
          content: amount || 0,
          closeCb: WheelBonusCloseCb,
          zIndex: 999999,
          overlayClassName: 'wheelBonusMoney',
          modalType: currency == 'gift_money' ? 'wheel' : '',
        }),
      );
    }
  };

  const handleWheelStart = async () => {
    if (onStart || list.length <= 0) return;
    if (type == 'Count' && drawNum == 0) {
      if (isLogin()) {
        return toast.error(t('home.toast028'), {
          containerId: 'global',
        });
      } else {
        return dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.LoginBox,
          }),
        );
      }
    }
    const value = 3600; // 整10圈
    try {
      let res;
      // Guide 调用 引导轮盘
      if (type == 'Guide') {
        res = await Guidedraw({ activity_id: activityID });
      } else if (type == 'Count') {
        res = await ActivityGuideDraw({ activity_id: activityID });
      }
      if (res?.code === 0) {
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_Go);
        if (res.result) {
          const tapPosition = res.result.tapPosition;
          const amount = res.result.amount;
          const currency = res.result.currency;

          if (type == 'Guide') {
            dispatch(setwheelBonusAmount(amount));
          }

          if (isLogin()) {
            if (type == 'Count' && res.result.activity_info.draw_num > 0) {
              dispatch(setguideWheelSwitch(true));
            } else {
              dispatch(setguideWheelSwitch(false));
            }
          }

          let _findIndex;
          const index = list.findIndex((item) => {
            return item.position == tapPosition;
          });

          const position = index + 1;
          if (questionMakePosition.includes(position)) {
            _findIndex = index;
          } else {
            const tapAmount = list[tapPosition - 1].amount;
            if (tapAmount == amount) {
              _findIndex = index;
            } else {
              _findIndex = getRandomElementFromArray(questionMakePosition) - 1;
            }
          }

          if (wheelWrapper.current) {
            setOnstart(true);
            wheelWrapper.current.style.animation = 'none';
            wheelWrapper.current.style.animationPlayState = 'paused';

            wheelWrapper.current.style.transition = 'initial';
            wheelWrapper.current.style.transform = `rotate(${0}deg)`;

            setTimeout(() => {
              requestAnimationFrame(() => {
                const deg = value - _findIndex * 30;
                wheelWrapper.current.style.transition = 'transform 5s';
                wheelWrapper.current.style.transform = `rotate(${deg}deg)`;
              });
            }, 100);

            executeSequence(amount, currency);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setRuleIsOpen(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={ruleIsOpen}
      >
        <LuckyWheelRule
          lotteryHelp={wheelRule?.activity_rule_explain}
          handleClose={() => {
            setRuleIsOpen(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setRecordIsOpen(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={recordIsOpen}
      >
        <LuckyWheelRecord
          activityID={activityID || ''}
          handleClose={() => {
            setRecordIsOpen(false);
          }}
        />
      </DialogModal>
      {imgLoading ? (
        <div className="flex w-full h-full items-center justify-center mt-[-0.56rem]">
          <Loading2></Loading2>
        </div>
      ) : (
        <div
          className={`select-none flex justify-center flex-col items-center relative ${novicePilotSwitch != 0 ? 'mt-[.36rem]' : ''}`}
        >
          <div className="w-[4.8rem] relative z-[10]">
            {/* <img src={Streamer} alt="" className="not-drag" /> */}
            <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[4.8rem] h-[1.18rem] bg-[-0.2rem_-0.2rem] bg-[length:7.2rem_31.04rem]"></div>

            <div className="relative text-[.36rem] font-[900] top-[-1rem] left-[50%] translate-x-[-50%] text-center text-[var(--colour-43)]">
              {t('guideWheel.spin_to_win')}
            </div>
          </div>

          {novicePilotSwitch == 0 && type == 'Count' ? (
            <>
              <div
                className="absolute cursor-pointer z-[15] w-[.4rem] h-[.4rem] right-[.32rem] top-[0rem] active:scale-[.95] transition-all text-[var(--colour-7)]"
                onClick={() => setRuleIsOpen(true)}
              >
                <Icon name={IconName.Explain} />
              </div>

              <div
                className="absolute cursor-pointer z-[15] w-[.4rem] h-[.4rem] right-[.32rem] top-[.6rem] active:scale-[.95] transition-all text-[var(--colour-7)]"
                onClick={() => setRecordIsOpen(true)}
              >
                <Icon name={IconName.Record} />
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="absolute top-[-0.14rem] left-[50%] translate-x-[-50%] z-[1] select-none">
            <div className="w-[6.8rem]">
              <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[6.8rem] h-[6.78rem] bg-[-0.2rem_-24.06rem] bg-[length:7.2rem_31.04rem]"></div>
            </div>

            <div
              className={cn(
                'w-[4.44rem] absolute top-[.98rem] left-[17%] translate-x-[-50%] z-[2] select-none scale-[1.15]',
              )}
              ref={wheelWrapper}
              style={{
                transform: 'rotate(90deg)',
                transition: 'transform 5s ease-in-out',
                animation: 'frashenTurn2 10s linear infinite',
              }}
            >
              <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[4.44rem] h-[4.44rem] bg-[-0.2rem_-3.78rem] bg-[length:7.2rem_31.04rem]"></div>
              {list &&
                list.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="spin-item wheel_text-family z-[100] absolute w-[1.76rem] top-[44.2%] left-[45%] origin-[-0.5rem_center] ml-[.72rem] text-primary-content flex items-center justify-center h-[.53rem]"
                      style={{
                        opacity: '1',
                        transform: `${`rotate(${index * (360 / list.length)}deg)`}`,
                      }}
                    >
                      <span className={`text-[#fff] font-[700] text-[.28rem]`}>{item.text1 || ''}</span>
                    </div>
                  );
                })}
            </div>

            <div className="w-[4.76rem] absolute top-[.74rem] left-[50%] translate-x-[-50%] z-[3] select-none">
              <div className="bg-no-repeat  bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[4.76rem] h-[4.76rem] bg-[-0.2rem_-18.9rem] bg-[length:7.2rem_31.04rem]"></div>
            </div>

            <div
              className={cn(
                'w-[5.26rem] z-[4] absolute left-[15.6%] translate-x-[-50%] top-[.82rem] cursor-pointer select-none',
                {
                  'pilot_wheel-arrow': !onStart,
                },
              )}
              style={{ transform: 'scale(1)' }}
            >
              <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[4.74rem] h-[4.74rem] bg-[-0.2rem_-8.62rem] bg-[length:7.2rem_31.04rem]"></div>
            </div>

            <div className="h-[5.26rem] w-[5.26rem] z-[50] absolute left-[50%] translate-x-[-50%] top-[1rem] select-none">
              <LimitClickWrapper
                className={`absolute z-[1000] w-[1.2rem] h-[1.2rem] top-[30%] left-[39.5%] translate-[-50%] font-[900] text-[.32rem] ${`${!onStart ? 'cursor-pointer' : ''}`} flex items-center justify-center bg-[transparent] border-none`}
                onClick={handleWheelStart}
              ></LimitClickWrapper>
            </div>

            {onStart || !fingerVisible ? (
              <></>
            ) : (
              <div className="not-drag absolute w-[2.6rem] top-[1.98rem] left-[52.5%] translate-x-[-50%] z-[10] cursor-pointer">
                {/* <img src={step1Src} alt="" className="not-drag" /> */}
                <img src={'/static/img/step_1.webp'} alt="" className="not-drag" />
              </div>
            )}

            <div className="w-[4.74rem] z-[3] absolute left-[50%] translate-x-[-50%] top-[.8rem] select-none">
              <div className="bg-no-repeat  bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[4.74rem] h-[4.74rem] bg-[-0.2rem_-13.76rem] bg-[length:7.2rem_31.04rem]"></div>
            </div>

            {sweepLight ? (
              <div className="w-[2rem] z-[2] brightness-150 absolute left-[68%] translate-x-[-50%] top-[2.3rem] select-none">
                <img src={WheelHighLight} alt="" className="not-drag" />
              </div>
            ) : (
              <></>
            )}

            <div className="w-[5.1rem] h-[5.1rem] z-[5] select-none  absolute left-[50%] translate-x-[-50%] top-[.56rem]">
              <img src={lights[currentImageIndex]} alt="" className="not-drag w-full" />
            </div>

            <div className="w-[5.12rem] z-[7] absolute left-[50%] translate-x-[-50%] bottom-[.32rem] select-none">
              <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[5.12rem] h-[1.6rem] bg-[-0.2rem_-1.78rem] bg-[length:7.2rem_31.04rem]"></div>

              <div className="absolute z-[8] text-[.4rem] font-[900] min-w-[4.86rem] flex items-center justify-center left-[50%] translate-x-[-50%] select-none bottom-[.46rem]">
                {novicePilotSwitch != 0 ? (
                  <>
                    <span
                      className="text-[#ffffff]"
                      dangerouslySetInnerHTML={{
                        __html: t('guideWheel.up_to', {
                          currency: websiteInitConfig?.website_info?.currency_symbol,
                          amount: maxnum,
                        }),
                      }}
                    ></span>
                  </>
                ) : (
                  <>
                    <div className="font-[600] text-[.28rem] mb-[.08rem] text-[#fff]">
                      {t('guideWheel.number_spin')} :<span className="text-[#FFE600] ml-[.14rem]">{drawNum || 0}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function LuckyWheelRecord({ handleClose, activityID }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    ActivityGuideLog({ activity_id: activityID || '' }).then((res) => {
      setIsLoading(false);
      if (res.code == 0) {
        setList(res.result.log_list);
      }
    });
  }, []);

  return (
    <div className="overflow-hidden rounded-normal relative bg-[var(--colour-35)] w-[7.5rem] h-[5.48rem] px-[.32rem]">
      <div className="flex justify-between items-center h-[.72rem]">
        <div className="text-[var(--colour-48)] font-[700] text-[.24rem]">{t('lucky-wheel.record')}</div>
        <div className="ml-[.24rem] flex justify-center items-center absolute border border-[var(--colour-6)] rounded-small w-[.4rem] h-[.4rem] right-[.32rem] top-[.16rem] bg-[var(--colour-45)]">
          <Close onClick={handleClose} />
        </div>
      </div>
      <div className="h-[1px] bg-[var(--colour-6)]"></div>
      <div className="relative pt-[.28rem]">
        <div className="grid grid-cols-3 text-tab font-[400] text-center text-[.24rem] leading-[.3rem]">
          <div>{t('lucky-wheel.time')}</div>
          <div>{t('guideWheel.surname')}</div>
          <div>{t('guideWheel.winning')}</div>
        </div>
        <div className="pt-[.18rem] p-[0rem]">
          {isLoading ? (
            <>
              <Loading2 height="4rem" />
            </>
          ) : (
            <>
              <div className="overflow-y-auto luckyWheel_scroll h-[3.98rem]">
                {list && list.length > 0 ? (
                  <>
                    {list.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className=" grid grid-cols-3 text-center text-primary-content text-[.28rem] font-[500] leading-[.38rem] mb-[.06rem]"
                        >
                          <div>{moment(item.create_time * 1000).format(`MM-DD HH:mm`) || ''}</div>
                          <div className="">{item.nickname || ''}</div>
                          <div className="text-[var(--colour-26)]">{getShowMoney(item.amount)}</div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="text-primary-content font-[400] text-center text-[.28rem] leading-[3.78rem]">
                    {t('lucky-wheel.no_record')}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="absolute bottom-[0] w-full h-[1.4rem] list-bg-linear"></div>
      </div>
    </div>
  );
}

export default Wheel;
