/*
 * @Author: micheal
 * @Date: 2023-08-25 11:47:14
 * @LastEditors: milo
 * @LastEditTime: 2024-12-06 11:08:55
 */
import Input from './input';
import { useTranslation } from 'react-i18next';
import Loading2 from './loading2';
const SendButton = ({ children, onClick, className }) => {
  return (
    <div
      className={`h-[65%] cursor-pointer flex items-center justify-center w-[2rem] text-[.28rem] leading-[.28rem] font-[700] rounded-normal bg-[var(--colour-1)] ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const InputBySendCode2 = ({
  value,
  onChange,
  hasBeenSent = false,
  handleSendCode,
  placeholder,
  leftInner = '',
  leftOuter = '',
  requesting = false,
  innerClassName = '',
  propoClassName = '',
  sendCodeCountdown = 0,
}) => {
  const { t } = useTranslation();

  return (
    <Input
      leftInner={leftInner}
      leftOuter={leftOuter}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      rightInner={
        requesting ? (
          <SendButton className="not-allowed text-[var(--colour-2)]" onClick={() => {}}>
            <Loading2 innerClass="only-border" />
          </SendButton>
        ) : hasBeenSent ? (
          <SendButton className="not-allowed text-[var(--colour-2)]" onClick={() => {}}>
            {sendCodeCountdown}
          </SendButton>
        ) : (
          <SendButton onClick={handleSendCode} className="text-[var(--colour-2)] text-[.28rem] ml-[.08rem]">
            {t('login-signup.send')}
          </SendButton>
        )
      }
      innerClassName={`!border-transparent !h-[.96rem] ${innerClassName}`}
      topClassName={propoClassName}
    />
  );
};

export default InputBySendCode2;
