import { useTranslation } from 'react-i18next';
import LimitClickWrapper from './limitClickWrapper';
import img_button_page_left_pc from '/static/img/vip/img_button_page_left_pc.png';
import img_button_page_right_pc from '/static/img/vip/img_button_page_right_pc.png';
import cn from 'classnames';
import { isUseLayoutMobile } from '../utils/helper';
import Icon, { IconName } from './icon';

const disableOpacityClassName = 'opacity-[0.3] cursor-not-allowed';
const enableOpacityClassName = 'opacity-[1] cursor-pointer';

const LoadMore = ({ handleClickMore, page, maxPage }) => {
  const { t } = useTranslation();

  const hasMore = page < maxPage;

  return (
    <div className="w-full flex justify-center items-center mt-[.4rem] mb-[.4rem]">
      <LimitClickWrapper
        onClick={hasMore ? handleClickMore : () => {}}
        className={cn(
          'rounded-normal w-[2.4rem] h-[.8rem] flex items-center justify-center border border-[var(--colour-6)] bg-[var(--colour-35)] text-[var(--colour-46)]',
          {
            [disableOpacityClassName]: !hasMore,
            [enableOpacityClassName]: hasMore,
          },
        )}
      >
        {t('home.loadmore')}
      </LimitClickWrapper>
    </div>
  );
};

const TurnPage = ({ page, handleClickPrev, handleClickNext, maxPage }) => {
  const hasNext = page < maxPage;
  const hasPrev = page > 1;

  return (
    <div className="w-full flex justify-center items-center mt-[.4rem] mb-[.4rem]">
      <LimitClickWrapper
        onClick={hasPrev ? handleClickPrev : () => {}}
        className={cn(
          'w-[.48rem] h-[.48rem] flex justify-center items-center bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal',
          {
            [disableOpacityClassName]: !hasPrev,
            [enableOpacityClassName]: hasPrev,
          },
        )}
      >
        <div className="w-[.24rem] h-[.24rem] rotate-180">
          <Icon name={IconName.BannerNext2}></Icon>
        </div>
      </LimitClickWrapper>
      <div className={cn('px-[.24rem] text-[.24rem] font-[500] text-[var(--colour-1)]')}>{page}</div>
      <LimitClickWrapper
        onClick={hasNext ? handleClickNext : () => {}}
        className={cn(
          'w-[.48rem] h-[.48rem] flex justify-center items-center bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal',
          {
            [disableOpacityClassName]: !hasNext,
            [enableOpacityClassName]: hasNext,
          },
        )}
      >
        <div className="w-[.24rem] h-[.24rem]">
          <Icon name={IconName.BannerNext2}></Icon>
        </div>
      </LimitClickWrapper>
    </div>
  );
};

const Pager = ({
  page = 1,
  handleClickPrev,
  handleClickNext,
  isTurnPage = true,
  handleClickMore = () => {},
  maxPage = 1,
}) => {
  return isTurnPage ? (
    <TurnPage page={page} handleClickPrev={handleClickPrev} handleClickNext={handleClickNext} maxPage={maxPage} />
  ) : (
    page < maxPage && <LoadMore handleClickMore={handleClickMore} page={page} maxPage={maxPage} />
  );
};

export default Pager;
