import { useState, useEffect, useCallback, useRef } from 'react';

/**
 * 优化后的批量加载钩子
 * 使用 requestAnimationFrame 和防抖技术来提高性能
 *
 * @param {Array} fullList - 完整的数据列表
 * @param {Object} options - 配置选项
 * @param {number} options.batchSize - 每批加载的项目数量，默认为50
 * @param {number} options.interval - 加载下一批项目的间隔时间，默认为1000ms
 * @returns {Object} - 返回一个对象包含已显示的项目和加载状态
 */
export function useBatchLoading(fullList, options = {}) {
  const { batchSize = 50, interval = 1000 } = options;

  const [displayedItems, setDisplayedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // 使用 refs 来存储状态，避免闭包问题
  const frameIdRef = useRef(null);
  const batchIndexRef = useRef(0);
  const isMountedRef = useRef(true);

  const loadNextBatch = useCallback(() => {
    if (!isMountedRef.current) return;

    const start = batchIndexRef.current * batchSize;
    const end = Math.min(start + batchSize, fullList.length);

    // 只在实际需要更新时才设置状态
    if (start < fullList.length) {
      setDisplayedItems((prev) => {
        const newBatch = fullList.slice(start, end);
        return [...prev, ...newBatch];
      });

      batchIndexRef.current++;

      if (end < fullList.length) {
        // 使用 requestAnimationFrame 来优化渲染时机
        frameIdRef.current = requestAnimationFrame(() => {
          setTimeout(loadNextBatch, interval);
        });
      } else {
        setIsLoading(false);
      }
    }
  }, [fullList, batchSize, interval]);

  useEffect(() => {
    isMountedRef.current = true;
    batchIndexRef.current = 0;
    setDisplayedItems([]);
    setIsLoading(true);

    // 初始加载第一批数据
    frameIdRef.current = requestAnimationFrame(loadNextBatch);

    return () => {
      isMountedRef.current = false;
      if (frameIdRef.current) {
        cancelAnimationFrame(frameIdRef.current);
      }
      setDisplayedItems([]);
      setIsLoading(true);
    };
  }, [fullList, loadNextBatch]);

  return {
    displayedItems,
    isLoading,
  };
}
