import { createSlice } from '@reduxjs/toolkit';

export const webSocketSlice = createSlice({
  name: 'webSocketSlice',
  initialState: {
    isSubscribed: false, // 是否已经订阅
    subscribeError: false, // 订阅出错
    isKickout: false, // 是否被踢
    isIpLimit: false, // 是否ip限制
    depositMsg: null, // 充值消息
    giftMoneyMsg: null, //giftMoney消息
  },
  reducers: {
    setIsSubscribed: (state, { payload }) => {
      state.isSubscribed = payload;
    },
    setSubscribeError: (state, { payload }) => {
      state.subscribeError = payload;
    },
    setIsKickout: (state, { payload }) => {
      state.isKickout = payload;
    },
    setIsIpLimit: (state, { payload }) => {
      state.isIpLimit = payload;
    },
    setDepositMsg: (state, { payload }) => {
      state.depositMsg = payload;
    },
    setGiftMoneyMsg: (state, { payload }) => {
      state.giftMoneyMsg = payload;
    },
  },
});

export const { setIsSubscribed, setSubscribeError, setIsKickout, setIsIpLimit, setDepositMsg, setGiftMoneyMsg } =
  webSocketSlice.actions;

export default webSocketSlice.reducer;
