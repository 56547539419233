import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CodeBox from './codeBox';
import Close from '../../../../close';

export default function ConfirmWithdrawPassword({
  handleClose = () => {},
  submitCallback = () => {},
  amount = 0,
  fee = 0,
}) {
  const { t } = useTranslation();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state?.app?.websiteInitConfig,
    }),
    shallowEqual,
  );

  const onSubmit = (codeStr) => {
    submitCallback(codeStr);
  };

  return (
    <div className="w-[6.86rem] p-[.41rem_.2rem_.8rem_.2rem] rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-35)] relative">
      <div
        className="absolute right-[.32rem] top-[.24rem] flex items-center justify-center w-[.72rem] h-[.72rem]"
        onClick={() => handleClose()}
      >
        <Close className="text-[var(--colour-48)]" />
      </div>
      <div className="text-[.32rem] font-[400] leading-[.38rem] text-[var(--colour-48)] mb-[.45rem] text-center">
        {t('deposit-withdraw-panda.withdraw_confirm_password_title')}
      </div>
      <div className="text-[.28rem] font-[500] leading-[.34rem] text-[var(--colour-38)] mb-[.2rem] text-center">
        {t('deposit-withdraw-panda.withdraw_confirm_password_tip')}
      </div>
      <div className="text-[.4rem] font-[500] leading-[.48rem] text-[var(--colour-26)] mb-[.32rem] text-center">
        {websiteInitConfig?.website_info?.currency_symbol || ''}
        {amount}
      </div>
      <div className="pt-[.32rem] border-t border-dashed border-[var(--colour-6)]">
        <CodeBox
          title={t('deposit-withdraw-panda.withdraw_confirm_password_text')}
          onSubmit={onSubmit}
          headerRightInner={
            <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-38)]">
              {websiteInitConfig?.website_info?.currency_symbol || ''}
              {fee}
            </div>
          }
        />
      </div>
    </div>
  );
}
