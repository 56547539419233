import PopBg from '../../../../components/popBg';
import ExpirationTime from '../../../../components/expirationTime';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../../../redux/reducers/appSlice';
import { getShowMoney, isLogin } from '../../../../../../utils/helper';
import { Trans, useTranslation } from 'react-i18next';
import ClaimBtn from '../com/claimBtn';
import { useEffect, useState } from 'react';
import AnalyticsManager, { FirebaseEvents } from '../../../../../../manager/analyticsManager';

const GuideMoney = () => {
  return (
    <div className="w-[6rem] h-[6rem] bg-no-repeat bg-[url(/static/img/refer/referralCashback_money.png)] referral_cashback origin-center scale-[0.75] bg-[length:24rem_36rem]"></div>
  );
};

const Info = ({ title, intro, money }) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  return (
    <div
      className={cn(
        'w-[5.64rem] h-[5.88rem] bg-[length:100%_100%] bg-no-repeat relative bg-[url(/static/img/cashback/bg-guide-mobile.png)]',
      )}
    >
      <div className="w-full flex justify-center text-center absolute top-[-.88rem] left-[-.14rem]">
        <GuideMoney />
      </div>
      <div className="w-full flex justify-center text-center absolute top-[.2rem]">{title}</div>
      <div className="flex justify-center text-center absolute abs-x-center top-[1.2rem] w-[4.32rem]">{intro}</div>
      <div className="w-full flex justify-center text-center absolute top-[4.86rem]">{money}</div>
    </div>
  );
};

const GuideContent = ({ data, handleClose = () => {}, isShowCloseBtn, showMain = () => {}, onExpired }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
    }),
    shallowEqual,
  );

  const [totalReward, setTotalReward] = useState(0);
  const [isUpto, setIsUpto] = useState(false);

  useEffect(() => {
    if (data) {
      const list = data?.task_config || [];
      const sumAmount = list.reduce((acc, curr) => {
        return acc + Number(curr.claim_reward || 0);
      }, 0);

      const configAmount = Number(data?.reward_amount_toplimit || 0);

      if (isLogin()) {
        if (sumAmount > 0) {
          setTotalReward(getShowMoney(sumAmount));
          setIsUpto(false);
        } else {
          setTotalReward(getShowMoney(configAmount));
          setIsUpto(true);
        }
      } else {
        setTotalReward(getShowMoney(configAmount));
        setIsUpto(true);
      }
    }
  }, [data]);

  const onClickClaim = () => {
    if (isLogin()) {
      showMain();
    } else {
      handleClose();
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
  };

  useEffect(() => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBackWindow_Popup);
  }, []);

  return (
    <>
      <PopBg isCenter={true} handleClose={handleClose} isShowCloseBtn={isShowCloseBtn}>
        <div className="flex flex-col w-full">
          <ExpirationTime
            startTime={data?.activity_start_time ? data.activity_start_time : Math.floor(Date.now() / 1000)}
            endTime={data?.activity_end_time ? data.activity_end_time : Math.floor(Date.now() / 1000)}
            handleClose={onExpired}
          />
          <div className="pt-[.22rem] flex justify-center">
            <Info
              title={
                <span className={cn('font-[800] text-[.36rem] text-[var(--colour-43)]')}>{t('referral.cashback')}</span>
              }
              intro={
                <span className="text-[var(--colour-48)] font-[500] text-[.24rem]">{t('referral.cashback_tip')}</span>
              }
              money={
                <span className="font-[800] text-[.4rem] text-[var(--colour-43)]">
                  {isUpto ? (
                    <span>
                      <Trans
                        t={t}
                        i18nKey="referral.up_to"
                        components={[<span key={0}></span>, <span key={1}></span>]}
                        values={{
                          currency: currency_symbol,
                          amount: totalReward,
                        }}
                      />
                    </span>
                  ) : (
                    <>
                      {currency_symbol}
                      <span className="">{totalReward}</span>
                    </>
                  )}
                </span>
              }
            />
          </div>
          <div className="pt-[.18rem] flex justify-center">
            <ClaimBtn
              onClick={() => {
                onClickClaim();
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBack_Claim);
              }}
              btnName={t('referral.claim')}
            />
          </div>
        </div>
      </PopBg>
    </>
  );
};

export default GuideContent;
