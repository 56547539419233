import { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { removeLoadingPage } from '../../utils/helper';
import { getTemplateId } from '../../utils/helper';
import NTemplate001 from './normal/template001';
import NTemplate002 from './normal/template002';
import DTemplate001 from './design/template001';
import DTemplate002 from './design/template002';
import DTemplate003 from './design/template003';
import { removeParentIfmLoading } from '../../manager/iframeManager';

export default function LayoutTemplate({ children }) {
  const { websiteInitData, websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (websiteInitData && websiteInitConfig) {
      removeLoadingPage();
      removeParentIfmLoading();
    }
  }, [websiteInitData, websiteInitConfig]);

  switch (getTemplateId()) {
    case 'n-template001':
      return <NTemplate001>{children}</NTemplate001>;
    case 'n-template002':
      return <NTemplate002>{children}</NTemplate002>;
    case 'd-template001':
      return <DTemplate001>{children}</DTemplate001>;
    case 'd-template002':
      return <DTemplate002>{children}</DTemplate002>;
    case 'd-template003':
      return <DTemplate003>{children}</DTemplate003>;
    default:
      return <>{children}</>;
  }
}
