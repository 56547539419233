import Button from '../../../button';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Icon, { IconName } from '../../../icon';
import { get } from 'lodash';
// import ScrollWrapper from './scrollWrapper';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import ShareManager from '../../../../manager/shareManager';
import { SendPhoneList } from '../../../../api';
import isMobile from 'ismobilejs';
import NoData from '../../../noData';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import { getReferralLink, getShareIconName, isShareString } from '../../../../utils/helper';
import { useDeepCompareEffect } from 'ahooks';

const Invite = ({ data, boxClassname = '' }) => {
  const { t } = useTranslation();
  const { websiteInitConfig, inviteCode, userObj } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      inviteCode: get(state, 'user.userObj.invite_code', ''),
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  const [shareList, setShareList] = useState([]);
  const [smsList, setSmsList] = useState([]);
  const [sendIndex, setSendIndex] = useState(0);
  const [activeId, setActiveId] = useState('');
  const [shareLink, setShareLink] = useState('');
  useEffect(() => {
    const id = get(data, 'id');
    if (id) {
      setActiveId(id);
    }
  }, [data]);

  useEffect(() => {
    SendPhoneList().then((res) => {
      if (res.code == 0) {
        setSmsList(res.result.list);
      }
    });
  }, []);

  useEffect(() => {
    if (websiteInitConfig?.website_info?.share_method) {
      setShareList(websiteInitConfig?.website_info?.share_method);
    }
  }, [websiteInitConfig]);

  const handleShare = (res) => {
    let str = res.toLowerCase();
    return ShareManager.share(str, shareLink, websiteInitConfig?.website_info?.website_desc);
  };

  const onCopyLink = () => {
    copy(shareLink);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  const handleSendWhatsAPP = (index) => {
    ShareManager.shareWhatsAppSMS(smsList[index], shareLink, websiteInitConfig?.website_info?.website_desc);
    const nextIndex = sendIndex + 1 > smsList.length - 1 ? 0 : sendIndex + 1;
    setSendIndex(nextIndex);
  };

  const handleSendSms = () => {
    if (smsList.length > 0) {
      ShareManager.shareSMS(smsList, shareLink, websiteInitConfig?.website_info?.website_desc);
    }
  };

  useDeepCompareEffect(() => {
    setShareLink(getReferralLink());
  }, [userObj]);

  return (
    <>
      <div
        className={`flex flex-col rounded-normal bg-[var(--colour-35)] font-[500] px-[.4rem] m-[1.4rem_.32rem_0_.32rem] text-[.28rem] p-[.5rem_.4rem_.4rem_.4rem] text-[var(--colour-48)] ${boxClassname}`}
      >
        <div className="">{t('fission.invite_title')}</div>
        <div className="flex mt-[.2rem] space-x-[.48rem]">
          {shareList.map((item, index) => {
            return isShareString(item) && !ShareManager.showMoreShare ? null : (
              <div
                key={index}
                className="rounded-full overflow-hidden w-[.6rem] h-[.6rem] flex justify-center items-center cursor-pointer drop-shadow-[1px_2px_5px_rgba(0,0,0,0.2)] active:scale-[.95] "
                onClick={() => handleShare(item)}
              >
                <Icon name={getShareIconName(item)} />
              </div>
            );
          })}
        </div>
        <div className="mt-[.4rem]">{t('fission.invite_title2')}</div>
        <div
          className={
            'mt-[.2rem] flex w-full h-[.64rem] text-[.24rem] bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-normal'
          }
        >
          <div className="flex w-full justify-start items-center text-center mx-[.2rem] overflow-x-auto overflow-y-hidden scroll_none text_nowrap text-[var(--colour-44)]">
            {shareLink}
          </div>
          <div
            className={
              'flex justify-center items-center active:scale-[.95] transition-all w-[.64rem] h-[.64rem] cursor-pointer select-none shrink-0 rounded-normal text-[var(--colour-2)] bg-[var(--colour-1)]'
            }
            onClick={() => {
              onCopyLink();
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBack_Copy);
            }}
          >
            <div className="w-[.32rem] h-[.32rem]">
              <Icon name={IconName.FillCopy} />
            </div>
          </div>
        </div>

        <div className="mt-[.28rem]">{t('fission.invite_title3')}</div>

        <div className="bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-small mt-[.2rem] p-[0_.52rem_.1rem_.52rem]">
          {smsList.length > 0 ? (
            <>
              <div className="h-[2.3rem] grid grid-cols-2 relative my-[.4rem] text-[.24rem] text-[var(--colour-44)]">
                {smsList.map((item, index) => (
                  <span key={index} className={`text-center ${sendIndex > index ? 'text-secondary-content' : ''}`}>
                    {item}
                  </span>
                ))}
                <span className="absolute w-[1px] h-[2.26rem] abs-center bg-[var(--colour-6)]"></span>
              </div>
              <div className="flex flex-col items-center jusitfy-center text-[#000000] space-y-[.2rem]">
                <Button
                  className="w-[5rem] h-[.8rem] rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)] flex flex-row items-center justify-center space-x-[.32rem] font-[600] text-[.24rem]"
                  onClick={() => handleSendWhatsAPP(sendIndex)}
                >
                  <span>{t('fission.invite_btn1')}</span>
                  <span className="w-[.48rem] h-[.48rem]">
                    <Icon name={IconName.WhatsApp} />
                  </span>
                </Button>

                {isMobile().apple.device || isMobile().android.device ? (
                  <Button
                    className="w-[5rem] h-[.8rem] rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)] flex flex-row items-center justify-center space-x-[.32rem] font-[600] text-[.24rem]"
                    onClick={handleSendSms}
                  >
                    <span>{t('fission.invite_btn2')}</span>
                    <span className="w-[.48rem] h-[.48rem]">
                      <Icon name={IconName.Sms} />
                    </span>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <>
              <NoData className={'h-[4.8rem]'} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Invite;
