import moment from 'moment';
import { get } from 'lodash';
import Loading2 from '../../../loading2';
import Icon, { IconName } from '../../../icon';
import ScrollWrapper from '../../../scrollWrapper';
import { useTranslation } from 'react-i18next';
import TabBox, { makeTabKeys } from '../../../tabBox';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import LimitClickWrapper from '../../../limitClickWrapper';
import { useEffect, useState, useRef, Fragment } from 'react';
import { isControllableSite } from '../../../../utils/helper';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setAnnouncementhidePopup, setAnnouncementhidePopupNextTime } from '../../../../redux/reducers/userSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import cn from 'classnames';
import NoData from '../../../noData';
import Close from '../../../close';
import useLink from '../../../../hooks/useLink';
import useDraggableScroll from '../../../../hooks/useDraggableScroll';

const Dtemplate001 = ({ handleClose, notificationData, isCloseAnnouncement }) => {
  let tabKeys = makeTabKeys(0);
  const { t } = useTranslation();
  const tabRef = useRef(null);
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [page, setPage] = useState();
  const [notificationList, setNotificationList] = useState([]);
  const { handleCmsLink } = useLink();
  const ActiveClassName =
    'relative w-[5.16rem] h-[1.36rem] bg-[var(--colour-52)] flex items-center pl-[.32rem] rounded-[.24rem_0_0_.24rem] text-[.32rem] font-[500] text-[var(--colour-1)] overflow-hidden cursor-pointer';

  const NoneactiveClassName =
    'relative w-[4.16rem] h-[1.36rem] rounded-[.24rem] text-[.32rem] font-[500] bg-[var(--colour-52)] pl-[.32rem] flex items-center cursor-pointer text-[var(--colour-14)]';

  const bgFillClassName = '';

  const _txtClassName = 'w-[3.2rem] whitespace-nowrap overflow-hidden text-ellipsis';

  useDraggableScroll(tabRef, [notificationData, page]);
  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  const activeIcon = (
    <div className="absolute right-[1.2rem] top-[50%] translate-y-[-50%] w-[.34rem] h-[.34rem]">
      <Icon name={IconName.Announcementarrow1} />
    </div>
  );

  const NoneActiveIcon = (
    <div className="absolute right-[.2rem] top-[50%] translate-y-[-50%] w-[.34rem] h-[.34rem]">
      <Icon name={IconName.Announcementarrow} />
    </div>
  );

  const dispatch = useDispatch();
  const { announcementhidePopup, announcementhidePopupNextTime } = useSelector(
    (state) => ({
      announcementhidePopup: state.user.announcementhidePopup || 0,
      announcementhidePopupNextTime: state.user.announcementhidePopupNextTime || '0',
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (notificationData && notificationData.length > 0) {
      tabKeys = makeTabKeys(notificationData.length);
      const data = notificationData.map((item, index) => {
        return {
          ...item,
          key: tabKeys[index],
          isShow: true,
          name: item.title || '',
          onClick: () => {
            onSwitchTab(index);
          },
          title: item.title || '',
          tabActiveEl: (
            <div className={ActiveClassName}>
              <div className={_txtClassName}>{item.title || ''}</div>
              <div className={bgFillClassName}></div>
              {activeIcon}
            </div>
          ),
          unusedActiveEl: (
            <div className={NoneactiveClassName}>
              <div className={_txtClassName}>{item.title || ''}</div>
              {NoneActiveIcon}
            </div>
          ),
          content: (
            <div>
              <ScrollWrapper className={`overflow-y-auto h-[5.32rem] wrap-break-word`} mr={false}>
                <div ref={containerRef}>
                  <div dangerouslySetInnerHTML={{ __html: item.content || '' }}></div>
                </div>
              </ScrollWrapper>
            </div>
          ),
          mobileClick: () => {
            if (isCloseAnnouncement) {
              handleClose && handleClose();
            }
            handleCmsLink(item.open_mode, item.new_window, item.link);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_AnnouncementButton + (index + 1));
          },
          link: item.link || '',
        };
      });

      setNotificationList(data);
    }
  }, [notificationData]);

  useEffect(() => {
    if (notificationList.length > 0) {
      setPage(notificationList[0]?.key);
    }
  }, [notificationList]);

  const onSwipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
      scrollToTop();
    }
  };

  const onSwipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
      scrollToTop();
    }
  };

  const onSwitchTab = (n) => {
    scrollToTop();
    if (n == 0) {
      setPage(tabKeys[0]);
    } else {
      setPage(tabKeys[n]);
    }
  };

  const handleNoReminderToday = () => {
    const newChecked = announcementhidePopup === 0 ? 1 : 0;
    dispatch(setAnnouncementhidePopup(newChecked));
    if (newChecked === 1) {
      const endTime = moment(new Date()).add(1, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x');
      dispatch(setAnnouncementhidePopupNextTime(endTime));
    } else {
      dispatch(setAnnouncementhidePopupNextTime('0'));
    }
  };

  useEffect(() => {
    if (new Date().getTime() >= announcementhidePopupNextTime) {
      dispatch(setAnnouncementhidePopup(0));
      dispatch(setAnnouncementhidePopupNextTime('0'));
    }
  }, []);

  useEffect(() => {
    if (tabRef.current) {
      const tabContainer = tabRef.current;
      const tabItems = tabContainer.querySelectorAll('.tab-item');
      const targetTab = tabItems[currentIndex];
      const targetTabWidth = targetTab.offsetWidth;
      const targetTabOffsetLeft = targetTab.offsetLeft;
      const containerWidth = tabContainer.offsetWidth;
      const containerScrollLeft = tabContainer.scrollLeft;
      const scrollAmount = targetTabOffsetLeft + targetTabWidth / 2 - containerWidth / 2 - containerScrollLeft;

      tabContainer.scrollLeft += scrollAmount;
    }
  }, [currentIndex]);
  return (
    <>
      <div className="relative">
        <>
          <div className={cn('w-[6.86rem] rounded-[.24rem] relative select-none')}>
            {notificationList && notificationList.length > 1 ? (
              <>
                <div
                  className="w-[.44rem] h-[.44rem] bg-[var(--colour-30)] rounded-[50%] absolute bottom-[2.38rem] left-[.08rem] z-[10] flex items-center justify-center active:scale-[.95] transition-all"
                  onClick={() => {
                    onSwipePrev();
                  }}
                >
                  <div className="w-[.2rem] h-[.2rem] rotate-180 text-[var(--colour-71)]">
                    <Icon name={IconName.BannerNext2} />
                  </div>
                </div>

                <div
                  className="w-[.44rem] h-[.44rem] bg-[var(--colour-30)] text-[var(--colour-2)] rounded-[50%] absolute  bottom-[2.38rem] right-[.08rem] z-[10] flex items-center justify-center active:scale-[.95] transition-all"
                  onClick={() => {
                    onSwipeNext();
                  }}
                >
                  <div className="w-[.2rem] h-[.2rem] text-[var(--colour-71)]">
                    <Icon name={IconName.BannerNext2} />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {notificationList && notificationList.length > 0 ? (
              <>
                {page ? (
                  <>
                    <div className="w-[6.86rem] h-[6.1rem] rounded-[.16rem] bg-[var(--colour-35)]">
                      <div
                        className="px-[.2rem] flex items-center gap-[.1398rem] overflow-x-auto hidden-scroll-y h-[.76rem]"
                        ref={tabRef}
                      >
                        {notificationList.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={`min-w-[1.4rem] h-full flex gap-[.08rem] items-center ${currentIndex == index ? 'text-[var(--colour-1)] border-b border-[var(--colour-1)]' : 'text-[var(--colour-14)] border-b border-[transparent]'} relative tab-item`}
                              onClick={() => {
                                if (swiperRef.current) {
                                  if (notificationList.length > 1) {
                                    swiperRef.current.swiper.slideToLoop(index, 200, false);
                                  } else {
                                    swiperRef.current.swiper.slideTo(index + 1, 200, false);
                                  }
                                }
                              }}
                            >
                              <div className="w-[.28rem] h-[.28rem]">
                                <Icon name={IconName.Broadcast} />
                              </div>

                              <div className="text-[.22rem] font-[400] w-[1.04rem] h-[.56rem] flex items-center">
                                <span className="text-clamp-2 leading-[.28rem] ">{item.name}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="w-full h-[.02rem] bg-[var(--colour-6)]"></div>

                      <Swiper
                        slidesPerView={1}
                        ref={swiperRef}
                        loop={notificationList.length > 1}
                        spaceBetween={12}
                        speed={300}
                        onSlideChange={(data) => {
                          setCurrentIndex(data.realIndex);
                        }}
                      >
                        {notificationList &&
                          notificationList.map((item, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="w-full h-[5.32rem] overflow-hidden rounded-[0_0_.16rem_.16rem]">
                                  {item.content}
                                </div>
                              </SwiperSlide>
                            );
                          })}
                      </Swiper>
                    </div>

                    {notificationList[currentIndex].is_show_button == 1 ? (
                      <div className="w-full h-[1.4rem] flex items-center justify-center absolute left-0 bottom-[-1.4rem]">
                        <div
                          className="w-[6.22rem] h-[.84rem] rounded-[.16rem] bg-[var(--colour-1)] active:scale-[.95] transition-all select-none flex items-center justify-center text-[var(--colour-2)] text-[.36rem] font-[400] cursor-pointer"
                          onClick={() => {
                            const item = notificationList[currentIndex];
                            handleCmsLink(item.open_mode, item.new_window, item.link);

                            if (isCloseAnnouncement) {
                              handleClose && handleClose();
                            }

                            AnalyticsManager.FirebaseLogEvent(
                              FirebaseEvents.Popup_Click_AnnouncementButton + (currentIndex + 1),
                            );
                          }}
                        >
                          <span className="w-[5rem] truncate text-center">
                            {notificationList[currentIndex]?.button_text || ''}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={cn(
                        'w-[.6rem] h-[.6rem] rounded-[50%] flex justify-center items-center absolute left-[50%] translate-x-[-50%]',
                        {
                          'bottom-[-2.86rem]': notificationList[currentIndex].is_show_button == 1,
                          'bottom-[-1.46rem]': notificationList[currentIndex].is_show_button != 1,
                        },
                      )}
                      onClick={() => {
                        handleClose();
                        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseAnnouncementButton);
                      }}
                    >
                      <Icon name={IconName.SignInClose} />
                    </div>

                    <div
                      className={cn('absolute abs-x-center w-full flex items-center justify-center gap-[.18rem]', {
                        'bottom-[-0.56rem]': notificationList[currentIndex].is_show_button != 1,
                        'bottom-[-1.96rem]': notificationList[currentIndex].is_show_button == 1,
                      })}
                    >
                      <div
                        className={cn(
                          'w-[.36rem] text-[var(--colour-2)] h-[.36rem] rounded-[.08rem] border border-[var(--colour-6)] cursor-pointer flex items-center justify-center',
                          {
                            'bg-[var(--colour-1)]': announcementhidePopup !== 0,
                            'bg-[var(--colour-42)]': announcementhidePopup === 0,
                          },
                        )}
                        onClick={handleNoReminderToday}
                      >
                        {announcementhidePopup === 0 ? (
                          <></>
                        ) : (
                          <>
                            <div className="w-[.2rem] h-[.2rem]">
                              <Icon name={IconName.AnnouncementChecked}></Icon>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="text-[.24rem] font-[400] select-none text-[var(--colour-72)]">
                        {t('home.warnTxt')}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full h-full flex items-center justify-center mt-[-0.8rem]">
                      <Loading2 />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <NoData className="w-full h-full"></NoData>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Dtemplate001;
