export const env = import.meta.env.MODE;

// const theme = import.meta.env.VITE_THEME;
// const siteId = import.meta.env.VITE_SITE_ID;
const devHost = import.meta.env.VITE_DEV_HOST;
const statUrl = import.meta.env.VITE_STAT_URL;

// console.log('siteId', siteId);
// console.log('theme', theme);
console.log(devHost ? `devHost: ${devHost}` : `devHost: unknown`);

const common = {
  responsiveWidth: 1005, //响应式切换手机布局的宽度
  serverType: {
    website: 2000,
    sport: 9001,
  },
  gameForbiddenPath: '/gameforbidden',
  baseURL: getOrigin('website', `https://website.${devHost}.com`),
  authURL: getOrigin('auth', `https://auth.${devHost}.com`),
  WSSURL: getOrigin('gw', `wss://gw.${devHost}.com`, 'wss') + '/ws',
  // TelegramBotName: getBotName('br3600_com_bot'),
  webDevImg: getOrigin('img', `https://img.${devHost}.com`),
  downloadPage: getOrigin('', `https://${devHost}.com`), // 下载页面
  downloadUrl: getOrigin('img', `https://img.${devHost}.com`), // 下载地址 使用img二级域名
  payURL: getOrigin('cpay', `https://cpay.${devHost}.com`), // 支付URL
  spayURL: getOrigin('spay', `https://spay.${devHost}.com`), // 支付URL
  payDomain: getDomain(),
  statUrl: statUrl, // 统计地址
  // theme: theme || '',
  // siteId: siteId || '',
};

const config = {
  development: {
    ...common,
  },

  test: {
    ...common,
  },

  production: {
    ...common,
  },
};

const envKey = env.includes('development')
  ? 'development'
  : env.includes('test')
    ? 'test'
    : env.includes('production')
      ? 'production'
      : undefined;

const configData = envKey ? config[envKey] : null;
export default configData;
console.log('baseURL', configData.baseURL);
console.log('authURL', configData.authURL);
console.log('WSSURL', configData.WSSURL);
console.log('webDevImg', configData.webDevImg);
console.log('downloadPage', configData.downloadPage);
console.log('downloadUrl', configData.downloadUrl);

export function isLocalServer() {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return true;
  } else {
    return /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(hostname);
  }
}

function replaceUrl(url) {
  return url.replace(/^(?:www\.|m\.|)/, 'cpay.');
}

// 从url中获取domain （需要传给server）
function getDomain() {
  if (isLocalServer()) {
    return `cpay.${devHost}.com`;
  }
  return replaceUrl(window.location.hostname);
}

export function getOrigin(firstPart, devOrigin, protocol) {
  let origin = window.location.origin;

  const protocolPrefix = origin.match(/^[a-z]+:/i);
  if (protocolPrefix && origin.startsWith(`${protocolPrefix[0]}//m.`)) {
    origin = origin.replace('m.', '');
  }

  const result = isLocalServer()
    ? devOrigin
    : origin.replace(/([^:]*):\/\/([^\.]*)(.*)/, (m, p1, p2, p3) => {
        const dotCount = p3.split('').filter((c) => c === '.');

        if (dotCount.length === 2) {
          return `${protocol ? protocol : p1}://${firstPart}${p3}`;
        } else {
          if (firstPart) {
            return `${protocol ? protocol : p1}://${firstPart}.${p2}${p3}`;
          } else {
            return `${protocol ? protocol : p1}://${p2}${p3}`;
          }
        }
      });

  // console.log('isLocalServer', isLocalServer(), result);

  return result;
}

// const replaceAll = (str, find, replace) => {
//   return str.replace(new RegExp(find, 'g'), replace);
// };

// export function getBotName(devBotName) {
//   const result = isLocalServer() ? devBotName : replaceAll(window.location.hostname, '.', '_') + '_bot';

//   console.log('isLocalServer', isLocalServer(), 'botName', result);

//   return result;
// }
