import { useEffect } from 'react';
import GatewayManager from '../../api/gateway/GatewayManager';
import { ReadyState } from 'react-use-websocket';
import { useReceiveMessage } from './useReceiveMessage';
import config from '../../constants/config';
import { setIsSubscribed, setSubscribeError } from '../../redux/reducers/wsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

const serverType = config.serverType.website;
const loginCmd = 1001; // 登录cmd
const heartbeatCmd = 1000; // 心跳cmd
const heartbeatIntv = 10000; // 心跳间隔

let heartbeatTimer = null;

export function useServerType({ lastMessage, readyState }) {
  const dispatch = useDispatch();

  const { identity, isSubscribed } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      isSubscribed: get(state, 'ws.isSubscribed'),
    }),
    shallowEqual
  );

  // 发送心跳
  const sendHeartbeat = () => {
    stopHeartbeat();
    heartbeatTimer = setTimeout(() => {
      // console.log('[ws] ping');
      GatewayManager.sendGameData4JsonObj(heartbeatCmd, {}, serverType);
    }, heartbeatIntv);
  };

  // 关闭心跳
  const stopHeartbeat = () => {
    clearTimeout(heartbeatTimer);
  };

  useReceiveMessage({
    serverType,
    lastMessage,
    sendHeartbeat,
  });

  useEffect(() => {
    switch (readyState) {
      case ReadyState.OPEN:
        if (!isSubscribed) {
          // 未订阅情况
          if (identity) {
            // 1 登录网关,并订阅游戏服
            console.log('[ws] subscribe:', serverType);
            GatewayManager.loginGateway(identity);
            GatewayManager.subscribe(serverType);
            dispatch(setSubscribeError(false));
          }
        } else {
          // 已订阅情况
          if (identity) {
            // 2 发送心跳, 登录游戏服
            sendHeartbeat();
            console.log('[ws] login:', serverType);
            GatewayManager.sendGameData4JsonObj(loginCmd, { identity });
          } else {
            // 3 取消订阅
            GatewayManager.unsubscribe(serverType);
            dispatch(setIsSubscribed(false));
            console.log('[ws] unsubscribe:', serverType);
          }
        }
        break;
      case ReadyState.CLOSED:
        dispatch(setIsSubscribed(false));
        dispatch(setSubscribeError(false));
        break;
      default:
        break;
    }
    return () => {
      stopHeartbeat(); // 关闭心跳
    };
  }, [readyState, isSubscribed, identity]);
}
