export const settleButtonType = {
  claimable: 'claimable', // 可领取
  received: 'received', // 已领取
  expired: 'expired', // 已过期
  pending: 'pending', // 结算中
  notYet: 'notYet', // 未到领奖时间
  notAttend: 'notAttend', // 未报名
};

export const depositLevelType = {
  level_no: 'level_no', // 未到第一档
  level_max: 'level_max', // 已到最高档
  level_other: 'level_other', // 到达其他最高档之前的档位
};
