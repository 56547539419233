import InstallHeaderPng from '../../../../assets/img/installAppHeader.png';
import CompletedRecharge from './components/completedRecharge';
import IncompleteRecharge from './components/incompleteRecharge';
import { getQueryString } from '../../../../utils/helper';

const NTemplate001 = () => {
  const uid = getQueryString('uid');
  const amount = getQueryString('amount');
  const fromApk = getQueryString('fromApk');

  return (
    <div className="w-full h-full flex flex-col">
      <img className="w-full not-drag" src={InstallHeaderPng} alt="" />
      <div className="bg-[#fff] flex-1">
        {uid && amount ? (
          <CompletedRecharge uid={uid} amount={amount} fromApk={fromApk} />
        ) : (
          <IncompleteRecharge fromApk={fromApk} />
        )}
      </div>
    </div>
  );
};

export default NTemplate001;
