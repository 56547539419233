import cn from 'classnames';
import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderBack from '../../../../components/headerBack';
import ReportAccount from './components/reportAccount';
import ReportBets from './components/reportBets';
import Report from './components/report';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../../../../utils/localePath';
import { useSelector, shallowEqual } from 'react-redux';
import { get } from 'lodash';
import { ResponsiveMode } from '../../../../redux/reducers/appSlice';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const NTemplate001 = ({ page = '', showPositionNum, setCurrentIndex }) => {
  const { t } = useTranslation();
  const { current } = useParams();
  const navigate = useGlobalNavigate();

  const tabList = [
    {
      label: t('report.account'),
      component: ReportAccount,
    },
    {
      label: t('report.bets'),
      component: ReportBets,
    },
    {
      label: t('report.title'),
      component: Report,
    },
  ];

  const handleClick = (index) => {
    navigate(toLocalePath(`/report/${index}`));
  };

  return (
    <div className="!px-[0px] container bg-[var(--colour-34)] h-full">
      <HeaderBack
        title={t('report.title')}
        onClick={() => {
          navigate(toLocalePath('/mine'));
        }}
      />

      <div className="h-[.72rem] w-full">
        <div
          className={cn(
            'w-full fixed z-[10] left-0 top-[.9rem] h-[.72rem] border-b-[.02rem] border-[var(--colour-6)] flex items-center gap-[.52rem] px-[.24rem] bg-[var(--colour-35)]',
          )}
        >
          {tabList &&
            tabList.map((item, index) => {
              return (
                <div
                  className={cn('h-full text-[.24rem] relative font-[500] flex items-center cursor-pointer', {
                    'text-[var(--colour-13)]': page != '' ? showPositionNum == index : current == index,
                    'text-[var(--colour-14)]': !(page != '' ? showPositionNum == index : current == index),
                  })}
                  key={index}
                  onClick={() => {
                    if (page == '') {
                      handleClick(index);
                    } else {
                      setCurrentIndex(index);
                    }
                  }}
                >
                  {item.label}
                  {(page != '' && showPositionNum == index) || (page == '' && current == index) ? (
                    <>
                      <div className="absolute w-full h-[.02rem] bg-[var(--colour-1)] bottom-0 left-0"></div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {tabList &&
        tabList.map((item, index) => {
          const shouldRenderByPage = page == '' ? current == index : showPositionNum == index;
          return (
            shouldRenderByPage &&
            item.component && (
              <Fragment key={index}>
                <item.component />
              </Fragment>
            )
          );
        })}
    </div>
  );
};

export default NTemplate001;
