import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setActivityStatus,
  DialogModalChildrenKey,
  setDialogModalVisible,
  FissionDialogShowFrom,
} from '../redux/reducers/appSlice';
import { GetActivityStatusReq, Guideturntable } from '../api';
import { useEffect, useState } from 'react';
import { toLocalePath } from '../utils/localePath';
import useInitActivity from './useInitActivity';
import AnalyticsManager, { FirebaseEvents } from '../manager/analyticsManager';
import { getAnyActivitySwitch } from '../utils/helper';
import { useGlobalNavigate } from '../contexts/navigateContext';

const useActivityStatus = () => {
  const activityListKeys = [
    'activity_task_switch',
    'activity_relief_money_switch',
    'activity_tournament_switch',
    'redeem_code_switch',
    'activity_contract_switch',
    'activity_novice_guide_tournament_switch',
  ];
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();
  const { activityStatus, guideWheelSwitch, identity } = useSelector(
    (state) => ({
      activityStatus: state.app.activityStatus,
      guideWheelSwitch: state.app.guideWheelSwitch,
      identity: state.user.identity,
    }),
    shallowEqual,
  );

  const [isShowRedDot, setIsShowRedDot] = useState(false);
  const [isShowEntrance, setIsShowEntrance] = useState(false);
  const [actListKeys, setActListKeys] = useState([]);
  const { ShowFissionMainPage } = useInitActivity();
  useEffect(() => {
    if (activityStatus) {
      if (identity) {
        if (
          activityStatus.turntable_lottery == '1' ||
          activityStatus.activity_task == '1' ||
          activityStatus.activity_relief_money == '1' ||
          activityStatus.activity_tournament == '1' ||
          guideWheelSwitch
        ) {
          setIsShowRedDot(true);
        } else {
          setIsShowRedDot(false);
        }
      } else {
        setIsShowRedDot(false);
      }

      if (getAnyActivitySwitch(activityStatus)) {
        setIsShowEntrance(true);
      } else {
        setIsShowEntrance(false);
      }
      let list = [];
      for (let i = 0; i < activityListKeys.length; i++) {
        if (activityStatus[activityListKeys[i]] && activityStatus[activityListKeys[i]] == '1') {
          list.push(activityListKeys[i]);
        }
      }
      setActListKeys(list);
    }
  }, [activityStatus, guideWheelSwitch, identity]);

  const InitActivityStatus = async () => {
    const res = await GetActivityStatusReq();
    if (res.code == 0) {
      const result = res.result;
      dispatch(setActivityStatus(result));
    }
  };

  const handleShowRedeem = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Redeem,
      }),
    );
  };

  const handleToUrl = (url) => {
    navigate(toLocalePath(url));
  };

  //打开闯关活动
  const handleShowTask = () => {
    handleToUrl('/task/1');
  };

  //打开救济金活动
  const handleShowReliefMoney = () => {
    handleToUrl('/task/2');
  };

  //去锦标赛
  const handleShowTournament = () => {
    navigate(toLocalePath('/tournament'));
  };

  //打开裂变活动
  const handleShowFission = () => {
    ShowFissionMainPage(FissionDialogShowFrom.eventBonus, true);
  };

  const handleShowguideWheel = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WheelWindow_Popup);
    // Guideturntable().then((res) => {
    //   if (res.code == 0) {
    //     dispatch(
    //       setDialogModalVisible({
    //         show: DialogModalChildrenKey.PilotWheel,
    //         content: res.result,
    //       }),
    //     );
    //   }
    // });
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.PilotWheel,
      }),
    );
  };

  const handleEntranceOnClick = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.SM_Click_EventBonusButton);
    navigate('/promotion/events', { state: { from: null } }, false);
    // if (actListKeys.length > 0) {
    //   if (actListKeys.length > 1) {
    //     dispatch(
    //       setDialogModalVisible({
    //         show: DialogModalChildrenKey.EventBonus,
    //       }),
    //     );
    //   } else {
    //     const key = actListKeys[0];
    //     switch (key) {
    //       case 'activity_task_switch':
    //         handleShowTask();
    //         break;
    //       case 'activity_relief_money_switch':
    //         handleShowReliefMoney();
    //         break;
    //       case 'activity_tournament_switch':
    //         handleShowTournament();
    //         break;
    //       case 'redeem_code_switch':
    //         handleShowRedeem();
    //         break;
    //       case 'activity_contract_switch':
    //         handleShowFission();
    //         break;
    //       case 'activity_novice_guide_tournament_switch':
    //         handleShowguideWheel();
    //         break;
    //     }
    //   }
    // }
  };

  const handleActivityDetailGoBack = () => {
    // if (actListKeys.length > 0) {
    //   if (actListKeys.length > 1) {
    //     dispatch(
    //       setDialogModalVisible({
    //         show: DialogModalChildrenKey.EventBonus,
    //       }),
    //     );
    //   } else {
    //     handleToUrl('/');
    //   }
    // } else {
    //   handleToUrl('/');
    // }
    navigate('/promotion/events', { state: { from: null } }, false);
  };

  return {
    isShowRedDot,
    isShowEntrance,
    InitActivityStatus,
    handleEntranceOnClick,
    handleShowTask,
    handleShowReliefMoney,
    handleActivityDetailGoBack,
    handleShowTournament,
    handleShowRedeem,
    handleShowFission,
    handleShowguideWheel,
  };
};

export default useActivityStatus;
