import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'overlayscrollbars/overlayscrollbars.css';
import './styles/index.scss';
import { ErrorBoundary } from 'react-error-boundary';
import '@vant/touch-emulator';

import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';
import { Flip, ToastContainer, Bounce } from 'react-toastify';
import ErrorFallback from './components/errorFallback';
import ScriptTag from './script';
import { Fragment, useEffect } from 'react';
import { NavigateProvider } from './contexts/navigateContext';
import { toNoLocalePath } from './utils/localePath';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://c3ff8168fbb8c92118019c2823e55b0c@sentry.api-interface.com/3',
});

// import { createWeb3Modal } from '@web3modal/wagmi/react';
// import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

// import { WagmiProvider } from 'wagmi';
// import { arbitrum, mainnet } from 'wagmi/chains';
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// 0. Setup queryClient
// const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
// const projectId = '4563ae4ab2f43bd4c40ea3938180e625';

// 2. Create wagmiConfig
// const wagmiConfig = defaultWagmiConfig({
//   chains: [mainnet, arbitrum],
//   projectId,
//   metadata: {
//     name: 'Web3Modal React Example',
//     description: 'Web3Modal React Example',
//     url: '',
//     icons: [],
//   },
// });

// 3. Create modal
// createWeb3Modal({
//   wagmiConfig: wagmiConfig,
//   projectId,
//   themeMode: 'light',
//   themeVariables: {
//     '--w3m-color-mix': '#00DCFF',
//     '--w3m-color-mix-strength': 20,
//   },
// });

// import VConsole from 'vconsole';
// const vConsole = new VConsole();
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyAX7teXOl3IIuGnIuR0CP_jM1mPV8fMp1w',
//   authDomain: 'br3600-f2a1e.firebaseapp.com',
//   projectId: 'br3600-f2a1e',
//   storageBucket: 'br3600-f2a1e.appspot.com',
//   messagingSenderId: '232656934154',
//   appId: '1:232656934154:web:05aa91aa336e2581ac16f3',
//   measurementId: 'G-FRN6M8F0PS',
// };

// 测试通知按钮 （iOS由于用户交互才能触发通知权限,可以在任意用户交互后请求权限）
const testNotificationButton = () => {
  const button = document.createElement('button');
  button.textContent = 'iOS初始化通知';
  button.style.position = 'fixed';
  button.style.bottom = '10px';
  button.style.left = '10px';
  button.style.zIndex = '10000';
  button.style.backgroundColor = '#007bff'; // 底色
  button.style.cursor = 'pointer'; // 小手状态

  button.addEventListener('click', async () => {
    //initializeFirebaseMessaging();
  });

  document.body.appendChild(button);
};

const AppWrapper = () => {
  return (
    <>
      <BrowserRouter>
        <NavigateProvider defaultState={{ from: toNoLocalePath(location.pathname) }}>
          <ScrollToTop />
          <App />
        </NavigateProvider>
      </BrowserRouter>

      <ToastContainer
        enableMultiContainer
        containerId={'global'}
        style={{ zIndex: '999999' }}
        position="top-center"
        autoClose={5000}
        limit={3}
        transition={Flip}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      ></ToastContainer>
      <ToastContainer
        toastClassName="task_toastify__toast"
        bodyClassName="task_Toastify__toast-body"
        enableMultiContainer
        containerId={'task'}
        style={{ zIndex: '102' }}
        position="top-left"
        autoClose={3000}
        limit={1}
        transition={Bounce}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      ></ToastContainer>
      <ToastContainer
        toastClassName="task_toastify__toast"
        bodyClassName="task_Toastify__toast-body"
        enableMultiContainer
        containerId={'taskFold'}
        style={{ zIndex: '102' }}
        position="top-left"
        autoClose={3000}
        limit={1}
        transition={Bounce}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      ></ToastContainer>
      <ToastContainer
        className="task_mobile_toastify__container"
        toastClassName="task_mobile_toastify__toast"
        bodyClassName="task_Toastify__toast-body"
        enableMultiContainer
        containerId={'taskMobile'}
        style={{ zIndex: '999999' }}
        position="top-center"
        autoClose={3000}
        closeButton={false}
        limit={1}
        transition={Bounce}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      ></ToastContainer>
      <ToastContainer
        className="tip_toastify__container"
        toastClassName="tip__toastify__toast"
        bodyClassName="tip_Toastify__toast-body"
        enableMultiContainer
        containerId={'tip'}
        style={{ zIndex: '999999' }}
        position="top-center"
        autoClose={3000}
        closeButton={false}
        limit={1}
        transition={Flip}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      ></ToastContainer>
    </>
  );
};

const Root = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistStore(store)} onBeforeLift={initI18n}> */}
        <PersistGate loading={null} persistor={persistStore(store)}>
          <AppWrapper />
          <ScriptTag />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Fragment>
//     <WagmiProvider config={wagmiConfig}>
//       <QueryClientProvider client={queryClient}>
//         <Root />
//       </QueryClientProvider>
//     </WagmiProvider>
//   </Fragment>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Fragment>
    <Root />
  </Fragment>,
);
