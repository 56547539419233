import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../../components/icon';
import Input from '../../../../../components/input';

const SearchBox = ({ value, onSearchChange, onClickSearch, placeholder = '' }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-[var(--colour-35)] rounded-full border border-[var(--colour-6)] flex items-center h-[.5rem]">
      <Input
        rightInner={
          <div className="flex items-center ">
            <span
              className="w-[.32rem] h-[.32rem] block text-[var(--colour-7)] cursor-pointer ml-[.08rem]"
              onClick={onClickSearch}
            >
              <Icon name={IconName.TagsSearch} />
            </span>
          </div>
        }
        value={value}
        onChange={onSearchChange}
        placeholder={placeholder}
        topClassName={`w-full border-none`}
        inputClassName="!text-[.2rem] placeholder:!text-[.2rem]"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClickSearch();
          }
        }}
      />
    </div>
  );
};

export default SearchBox;
