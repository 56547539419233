const ImageLoad = ({ src, className = 'w-full', onError }) => {
  const handleOnError = ({ currentTarget }) => {
    if (onError) {
      onError(currentTarget);
    } else {
      currentTarget.style.height = '0';
      currentTarget.style.width = '0';
    }
  };

  return <img src={src} className={`${className}`} onError={handleOnError} alt="" />;
};

export default ImageLoad;
