import { shallowEqual, useSelector } from 'react-redux';
import { getShowMoney } from '../utils/helper';

const MoneyWithCurrencyAndSign = ({ ammount = 0 }) => {
  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
    }),
    shallowEqual,
  );

  return (
    <>
      {Number(ammount) > 0 ? '+' : Number(ammount) < 0 ? '-' : ''}
      {currency_symbol}
      {getShowMoney(Number(ammount) >= 0 ? Number(ammount) : -Number(ammount))}
    </>
  );
};

export default MoneyWithCurrencyAndSign;
