import React, { FC, useState, useEffect, useRef } from 'react';
import Icon, { IconName } from '../icon';
import cn from 'classnames';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setAllowBodyScroll, setNeedUpdateBalance, setShowInbox, setUnReadCount } from '../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { InboxList, MsgRead, MsgReadALL, MsgClaim } from '../../api';
// import moment from 'moment';
// import DialogModal from '../components/dialogModal';
import LimitClickWrapper from '../limitClickWrapper';
// import ElementWithLoading from '../../components/elementWithLoading';
import { toast } from 'react-toastify';
import NoData from '../noData';
// import { CSSTransition } from 'react-transition-group';
import Close from '../close';
import { format } from 'date-fns';
import { getShowMoney, isControllableSite, pxToRem, openHeaderGiftMoney } from '../../utils/helper';
import Loading2 from '../loading2';
import { toLocalePath } from '../../utils/localePath';
import { useGlobalNavigate } from '../../contexts/navigateContext';
import { getCenterPosition } from '../../hooks/useMinimizeAnimation';

const MailItem = ({ mailObj, isinPage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { unReadCount, activityStatus } = useSelector(
    (state) => ({
      unReadCount: state.app.unReadCount,
      activityStatus: state.app.activityStatus,
    }),
    shallowEqual,
  );

  // "read_mark": 0 //"0：未读";1:已读
  // "reward_mark": 0 //0：未领取；1：已领取；2：没有奖励项
  const [read_mark, setReadMark] = useState(mailObj.read_mark);
  const [flag, setFlag] = useState(false); //信封是否展开
  const [h, setH] = useState(0); //内容高度
  const [reward_mark, setReward_mark] = useState(mailObj.reward_mark);
  const reward_config = (() => {
    let config = null;
    if (mailObj.reward_config == '') {
      return config;
    }

    try {
      config = JSON.parse(mailObj.reward_config);
    } catch (e) {
      console.log('parse err reward_config', mailObj.reward_config);
    }
    return config;
  })();
  const HRef = useRef(null);
  useEffect(() => {
    if (mailObj.content && HRef) {
      HRef.current.innerHTML = mailObj.content;
      setH(reward_mark == 2 ? pxToRem(HRef.current.offsetHeight) : pxToRem(HRef.current.offsetHeight) + 1.28);
    }
  }, [mailObj]);
  const clickFlag = () => {
    setFlag(!flag);
    if (read_mark === 0) {
      MsgRead({ id: mailObj.id }).then((res) => {
        if (res.code === 0) {
          setReadMark(1);
          dispatch(setUnReadCount(unReadCount - 1));
        }
      });
    }
  };

  const handleToastSuccess = () => {
    toast.success(t('home.toast005'), {
      containerId: 'global',
    });
  };

  const clickReceive = (e) => {
    if (reward_mark == 0) {
      MsgClaim({ id: mailObj.id }).then((res) => {
        if (res.code === 0) {
          if (res.result?.currency == 'gift_money') {
            if (activityStatus && activityStatus.gift_money_menu_show_switch == '1') {
              const element = e.target;
              const positionData = [getCenterPosition(element, true)];
              if (element) {
                openHeaderGiftMoney(res.result?.amount, positionData);
              }
            } else {
              handleToastSuccess();
            }
          } else {
            handleToastSuccess();
          }
          dispatch(setNeedUpdateBalance({ need: true }));
          setReward_mark(1);
        }
      });
    }
  };

  return (
    <div className="mb-[.52rem]">
      <div className="flex">
        <div className="h-[.48rem] px-[.2rem] flex items-center justify-center text-[var(--colour-44)] text-[.24rem] font-[400] bg-[var(--colour-35)]">
          {format(new Date(Number(mailObj.start_timestamp) * 1000), 'yyyy-MM-dd HH:mm:ss')}
        </div>
        <div
          className="border-b-[.489rem] border-solid border-b-[var(--colour-35)]"
          style={{
            height: '0',
            width: '0',
            borderRight: '.32rem solid transparent',
          }}
        ></div>
      </div>
      <div className="flex p-[.2rem_.16rem] rounded-normal !rounded-tl-none !rounded-bl-none !rounded-br-none  space-x-[.2rem] bg-[var(--colour-35)]">
        <div
          className={cn(
            'w-[.88rem] h-[.88rem] shrink-0 flex items-center justify-center rounded-[50%] bg-[var(--colour-47)]',
          )}
        >
          {read_mark == 1 ? (
            <span className="w-[.52rem] h-[.48rem]">
              <Icon name={IconName.SiteMailReaded} />
            </span>
          ) : (
            <span className="w-[.52rem] h-[.48rem]">
              <Icon name={IconName.SiteMailNoRead} />
            </span>
          )}
        </div>
        <div className="flex-1">
          <div className="text-[.28rem] font-[700] leading-[.34rem] pt-[.04rem] mb-[.1rem] text-[var(--colour-44)]">
            {mailObj.title}
          </div>
          <div
            className="height-auto overflow-hidden"
            style={flag ? { height: `${h}rem` } : { height: '.3rem', textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            <div className="text-[.24rem] font-[400] leading-[.3rem] relative  overflow-hidden max-w-[5.46rem] text-[var(--colour-38)]">
              {mailObj.content}
              <div ref={HRef} className="absolute w-full opacity-[0] z-[-99]"></div>
            </div>
            {reward_mark == 0 || reward_mark == 1 ? (
              <div className="flex items-center justify-between p-[.16rem_.12rem_.16rem_.16rem] mt-[.24rem] rounded-normal bg-[var(--colour-34)]">
                <div className="flex items-center">
                  <img src="/static/img/goldCoin.png" className="w-[.72rem]" />
                  <span className="text-[.32rem] text-[var(--colour-38)] font-[700]">
                    {getShowMoney(reward_config?.amount)}
                  </span>
                </div>
                <LimitClickWrapper
                  className={cn(
                    'w-[2rem] h-[.68rem] flex items-center justify-center text-[.28rem] font-[700] active:scale-[.95] transition-all select-none rounded-normal bg-[var(--colour-35)]',
                    {
                      'cursor-pointer text-[var(--colour-3)]': reward_mark == 0,
                      'text-[var(--colour-44)]': reward_mark != 0,
                    },
                  )}
                  onClick={clickReceive}
                >
                  {reward_mark == 0 ? t('notificaitons.receive') : t('notificaitons.received')}
                </LimitClickWrapper>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="h-[.6rem] flex items-center justify-between px-[.2rem] rounded-normal !rounded-tl-none !rounded-tr-none bg-[var(--colour-47)] relative overflow-hidden">
        {reward_mark == 0 || reward_mark == 1 ? (
          <div className="flex items-center z-[1]">
            <div className="h-[.4rem] flex items-center justify-center rounded-[.12rem] px-[.16rem] space-x-[.38rem] bg-[var(--colour-28)]">
              <img src="/static/img/goldCoin.png" className="w-[.36rem]" />
              <span className="text-[.24rem] text-[var(--colour-38)] font-[400]">
                {getShowMoney(reward_config?.amount)}
              </span>
            </div>
          </div>
        ) : (
          <span></span>
        )}
        <div className="flex items-center cursor-pointer z-[1]" onClick={clickFlag}>
          <span
            className={`mr-[.1rem] font-[700] text-[.24rem] ${flag ? 'text-[var(--colour-38)]' : 'text-[var(--colour-44)]'}`}
          >
            {flag ? t('notificaitons.fold') : t('notificaitons.unfold')}
          </span>
          {flag ? (
            <span className="w-[.22rem] h-[.14rem] text-[var(--colour-38)] rotate-180 transition-chat">
              <Icon name={IconName.ChatArraw} />
            </span>
          ) : (
            <span className="w-[.22rem] h-[.14rem] text-[var(--colour-11)] transition-chat">
              <Icon name={IconName.ChatArraw} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default function inBox({ isShowClose = true, isinPage = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const location = useLocation();
  const { pathname } = location;

  const {} = useSelector((state) => ({}), shallowEqual);

  const [refresh, setRefresh] = useState(false);

  // 列表数据
  const [isLoading, setIsLoading] = useState(true);
  const [msgList, setMsgList] = useState([]);

  const [requesting, setRequesting] = useState(false);

  // show List
  useEffect(() => {
    // read_status 0:未读；1：已读；其他：所有数据（注：消息最多返回50条）
    setIsLoading(true);
    InboxList().then((res) => {
      setIsLoading(false);
      if (res.code == 0) {
        setMsgList(res.result.list);
        dispatch(setUnReadCount(res.result.list.filter((item) => item.read_mark == 0).length));
      }
    });
  }, []);

  const handlePcClose = () => {
    dispatch(setShowInbox(false));
  };

  const handleMobileClose = () => {
    navigate(-1);
  };

  const onClickClose = () => {
    handleMobileClose();
  };

  return (
    <div className="">
      <div className="z-[105] fixed top-[0] left-[0] bottom-[0] w-full hidden" onClick={handlePcClose}></div>
      <div className={cn('w-full border border-solid border-transparent')}>
        {!(String(pathname).toLowerCase().indexOf('/email') > -1) && isShowClose ? (
          <div className="w-full min-h-[1rem] bg-[var(--colour-35)] flex items-center justify-between pl-[.32rem] pr-[.2rem]">
            <div className="text-[.4rem] font-[600] text-[var(--colour-48)]">{t('home.menu_email')}</div>

            <div
              className="w-[.68rem] h-[.68rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal"
              onClick={onClickClose}
            >
              <Close />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={cn('scrollbar-none')}>
          <div>
            {isLoading ? (
              <div className="py-[4rem]">
                <Loading2 />
              </div>
            ) : msgList?.length > 0 ? (
              msgList.map((item, index) => <MailItem mailObj={item} key={index} isinPage={isinPage} />)
            ) : (
              <NoData className="py-[4rem]" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
