import { useSelector, shallowEqual } from 'react-redux';
import { get } from 'lodash';
import { LiveChatWidget } from '@livechat/widget-react';

const ScriptTag = () => {
  const { siteLiveChatInfo } = useSelector(
    (state) => ({
      siteLiveChatInfo: get(state, 'app.websiteInitConfig.website_info.site_live_chat_info', null),
    }),
    shallowEqual,
  );
  const onVisibilityChanged = (data) => {
    switch (data.visibility) {
      case 'maximized':
        break;
      case 'minimized':
        window.LiveChatWidget && window.LiveChatWidget.call('hide');
        break;
      case 'hidden':
        break;
    }
  };

  return siteLiveChatInfo && siteLiveChatInfo.status == '1' ? (
    <LiveChatWidget license={siteLiveChatInfo.code} visibility="hidden" onVisibilityChanged={onVisibilityChanged} />
  ) : null;
};

export default ScriptTag;
