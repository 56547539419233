import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useEffect, useRef, useState, createRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, FreeMode } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { get } from 'lodash';
import Sticky from 'react-sticky-el';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  DialogModalChildrenKey,
  ResponsiveMode,
  setDialogModalVisible,
} from '../../../../../../redux/reducers/appSlice';
import { isNative } from '../../../../../../utils/deviceUtils';
import { getTemplateId, cmsImgUrl, isBrNTemplateId, isPCLayout } from '../../../../../../utils/helper';
import SkeletonBox from '../../../../../../components/skeletonBox';
import AnalyticsManager, { FirebaseEvents } from '../../../../../../manager/analyticsManager';
import DownloadManager from '../../../../../../manager/downloadManager';
import scrollIntoView from 'scroll-into-view';
import Icon, { IconName } from '../../../../../../components/icon';
import Input from '../../../../../../components/input';
import { useInView } from 'react-intersection-observer';
import { setAutoScrollToTagGameKey } from '../../../../../../redux/reducers/appSlice';
import useLangServerConfig from '../../../../../../hooks/useLangServerConfig';
import useDraggableScroll from '../../../../../../hooks/useDraggableScroll';

const MobileDownloadBtn = () => {
  const dispatch = useDispatch();
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const onClick = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_DownloadButton);
    if (isBrNTemplateId()) {
      DownloadManager.handleInstallAction();
    } else {
      if (isPCLayout()) {
        return dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.PwaguideQRcodeModel,
            zIndex: 999999,
          }),
        );
      } else {
        DownloadManager.androidDownload();
      }
    }
  };

  return (
    <div className="space-x-[.2rem] ml-[.08rem] shrink-0 flex">
      <div className="w-[.02rem] h-[.76rem] bg-[var(--colour-6)] shrink-0"></div>
      <span
        onClick={onClick}
        className="w-[.8rem]"
        style={{
          animation: 'breathing 1.5s linear infinite',
        }}
      >
        <img src="/static/img/mobileDownload.png" className="w-[.8rem]" />
      </span>
    </div>
  );
};

const getAnalyticsManagerName = (id) => {
  if (id == '000') {
    return FirebaseEvents.MP_Click_Lobby;
  } else if (id == '001') {
    return FirebaseEvents.MP_Click_Favorite;
  } else if (id == '002') {
    return FirebaseEvents.MP_Click_All;
  } else if (id == '003') {
    return FirebaseEvents.MP_Click_Recently;
  } else {
    return FirebaseEvents.MP_Click_ + id;
  }
};

const SearchBox = ({
  isShowFullInput = false,
  setIsShowFullInput = () => {},
  tagsArr,
  tagsSearchRes,
  handleHideSearchBox = () => {},
  isShowFlexSearch = false,
  isMobileShowDownload = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
    }),
    shallowEqual,
  );
  const [value, setValue] = useState(''); //搜索框的值
  const fitValue = (val) => {
    setValue(val);
  };
  const change = (e) => {
    setValue(e.target.value);
  };
  const blur = () => {
    tagsSearchRes(value);
  };

  const handleKeyDown = (ev) => {
    if (ev.keyCode == 13) {
      tagsSearchRes(value);
    }
  };

  const clickSearchIcon = () => {
    if (!isShowFullInput && !isMobileShowDownload) {
      setIsShowFullInput(true);
    } else {
      if (value !== '') {
        tagsSearchRes(value);
      }
    }
  };

  const { getServerLangText } = useLangServerConfig();

  return tagsArr.length > 0 ? (
    <SkeletonBox
      props={{
        containerClassName: cn(`h-[.92rem] flex items-center justify-center]`, {
          'rounded-normal w-[.8rem]': isShowFlexSearch,
          'rounded-[30px] order-first': !isShowFlexSearch,
        }),
        className: 'h-full',
      }}
      loading={tagsArr[0] == null}
    >
      <div
        className={cn(`flex items-center justify-center border border-[var(--colour-6)]`, {
          'pr-[.36rem] w-full': isShowFullInput,
          'w-[.8rem] h-[.76rem]': !isShowFullInput && !isMobileShowDownload,
          'rounded-normal bg-[var(--colour-34)]': isShowFlexSearch,
          'rounded-[30px] bg-[var(--colour-34)] order-first': !isShowFlexSearch,
          'mt-[.16rem] w-full p-[0_.32rem_0_.24rem]': isMobileShowDownload,
        })}
      >
        {isShowFullInput ? (
          <span
            onClick={() => {
              setIsShowFullInput(false);
            }}
            className="ml-[.24rem] w-[.32rem] h-[.32rem] flex items-center justify-center cursor-pointer"
          >
            <Icon name={IconName.SearchArrow} />
          </span>
        ) : null}
        <div
          className={cn({
            'flex-1': isShowFullInput,
            'w-[0]': !isShowFullInput && !isMobileShowDownload,
            'w-full': isMobileShowDownload,
          })}
        >
          <Input
            type="text"
            value={value}
            onChange={change}
            onKeyDown={handleKeyDown}
            placeholder={t('home.try_slot')}
            inputClassName={`!text-[.28rem]`}
            topClassName="border-none"
            innerClassName={cn('!h-[.96rem] !px-[.1rem]')}
            onFocus={() => {
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_SearchButton);
            }}
          />
        </div>
        <div className="w-[.32rem] h-[.32rem] cursor-pointer text-[var(--colour-7)]" onClick={clickSearchIcon}>
          <Icon name={IconName.TagsSearch} />
        </div>
      </div>
    </SkeletonBox>
  ) : null;
};

const TagsList = ({
  isShowFullInput = false,
  tagsArr,
  tagsIndex,
  setTagsIndex,
  searchFlag,
  tagsResIndex,
  tagsSearchRes,
  isShowFlexSearch = false,
  isMobileShowDownload = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const refScroller = useRef(null);
  const [elRefs, setElRefs] = useState([]);
  const { getServerLangText } = useLangServerConfig();
  const { responsiveMode, autoScrollToTagGameKey } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
      autoScrollToTagGameKey: get(state, 'app.autoScrollToTagGameKey'),
    }),
    shallowEqual,
  );
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useDraggableScroll(refScroller);

  useEffect(() => {
    let _timer = null;
    if (autoScrollToTagGameKey != '0' && inView) {
      _timer = window.setTimeout(() => {
        scrollToItem(autoScrollToTagGameKey);
      }, 0);
    }
    return () => {
      window.clearTimeout(_timer);
    };
  }, [autoScrollToTagGameKey, tagsArr.length, elRefs.length, inView]);

  const scrollToItem = (gameKey) => {
    if (tagsArr && tagsArr.length > 0) {
      const gameTabIndex = Array.from(tagsArr).findIndex((item) => {
        return item.category_id == gameKey;
      });
      if (gameTabIndex > -1) {
        if (elRefs[gameTabIndex] && elRefs[gameTabIndex].current) {
          scrollIntoView(elRefs[gameTabIndex].current, {
            time: 500,
          });
          if (tagsIndex != gameKey) {
            setTagsIndex(gameKey);
            tagsResIndex(gameKey);
          }
          dispatch(setAutoScrollToTagGameKey('0'));
        }
      }
    }
  };

  useEffect(() => {
    if (tagsArr) {
      setElRefs((elRefs) =>
        Array(tagsArr.length)
          .fill('')
          .map((_, i) => elRefs[i] || createRef()),
      );
    }
  }, [tagsArr]);

  return (
    <div
      ref={ref}
      className={cn('flex', {
        'w-[2rem] flex-1': !isShowFullInput && !isMobileShowDownload,
        hidden: isShowFullInput,
        'mr-[.16rem]': !isMobileShowDownload,
      })}
    >
      <div className={cn('overflow-x-auto flex scrollbar-none select-none')} ref={refScroller}>
        {tagsArr &&
          tagsArr.map((item, index) => (
            <SkeletonBox
              props={{ containerClassName: 'grow-[1] flex h-[.92rem] relative mr-[.16rem]', className: 'w-[1.6rem]' }}
              key={index}
              loading={item == null}
            >
              <span ref={elRefs[index]} className="grow-[1] flex h-[.92rem] relative" key={index}>
                {index !== 0 && <div className="w-[.02rem] h-[.76rem] bg-[var(--colour-6)] mx-[.08rem]"></div>}
                <div
                  onClick={() => {
                    AnalyticsManager.FirebaseLogEvent(getAnalyticsManagerName(item.category_id));
                    setTagsIndex(item.category_id);
                    tagsResIndex(item.category_id);
                  }}
                  className={`h-[.76rem] px-[.22rem] text-[.24rem] font-[600] flex items-center flex-col grow-[1] whitespace-nowrap ${
                    tagsIndex === item.category_id ? 'text-[var(--colour-1)]' : 'text-tab'
                  } ${index == 0 ? 'pl-[.3rem]' : ''}`}
                >
                  <span className={cn('w-[.32rem] h-[.32rem] mb-[.08rem]')}>
                    <img className="h-full not-drag" src={cmsImgUrl(item.icon)} />
                  </span>
                  <span className="leading-[1em] text-center">{getServerLangText(item.lang_key)}</span>
                  {tagsIndex == item.category_id ? (
                    <div
                      className={`absolute w-full bg-[var(--colour-1)] h-[.04rem] left-[.08rem] bottom-[0rem] rounded-normal`}
                    ></div>
                  ) : (
                    <></>
                  )}
                </div>
              </span>
            </SkeletonBox>
          ))}
      </div>
      {isMobileShowDownload && <MobileDownloadBtn />}
    </div>
  );
};

export default function Index({ tagsArr, tagsIndex, setTagsIndex, searchFlag, tagsResIndex, tagsSearchRes }) {
  const { websiteInitConfig, responsiveMode } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      responsiveMode: state.app.responsiveMode,
    }),
    shallowEqual,
  );

  const [isFixedToggle, setIsFixedToggle] = useStateIfMounted(false);
  const [positionRefTop, setPositionRefTop] = useStateIfMounted(0);
  const [searchBoxVisible, setSearchBoxVisible] = useStateIfMounted(false);
  const [isShowFullInput, setIsShowFullInput] = useStateIfMounted(false);
  const [isMobileShowDownload, setIsMobileShowDownload] = useStateIfMounted(false);

  useEffect(() => {
    const isShow = websiteInitConfig?.website_info?.download?.switch_app_download_category_button == '1' && !isNative();
    setIsMobileShowDownload(isShow);
  }, [websiteInitConfig]);

  const positionRef = useRef(null);
  const handleOnFixedToggle = (isFixed) => {
    setIsFixedToggle(isFixed);
  };
  useEffect(() => {
    if (isFixedToggle) {
      window.scrollTo({
        top: positionRefTop,
        behavior: 'smooth',
      });
    }
    console.log('tagsIndex', tagsIndex, typeof tagsIndex);
  }, [tagsIndex]);

  useEffect(() => {
    if (positionRef && positionRef.current) {
      setPositionRefTop(positionRef.current.getBoundingClientRect().top);
    }
  }, []);

  const isShowFlexSearch = true;

  return (
    <Sticky
      positionRecheckInterval={200}
      onFixedToggle={handleOnFixedToggle}
      stickyClassName="tags-list-sticky"
      topOffset={-64}
    >
      <div ref={positionRef}></div>
      <div
        className={cn('w-full relative', {
          'flex flex-row justify-between items-start': !isMobileShowDownload,
        })}
      >
        <TagsList
          isShowFullInput={isShowFullInput}
          tagsArr={tagsArr}
          tagsIndex={tagsIndex}
          setTagsIndex={setTagsIndex}
          searchFlag={searchFlag}
          tagsResIndex={tagsResIndex}
          tagsSearchRes={tagsSearchRes}
          isShowFlexSearch={isShowFlexSearch}
          isMobileShowDownload={isMobileShowDownload}
        />
        {isMobileShowDownload && ['000', '001'].includes(tagsIndex) ? (
          <></>
        ) : (
          <>
            <SearchBox
              isShowFullInput={isShowFullInput}
              setIsShowFullInput={setIsShowFullInput}
              tagsArr={tagsArr}
              tagsSearchRes={tagsSearchRes}
              isShowFlexSearch={isShowFlexSearch}
              isMobileShowDownload={isMobileShowDownload}
            />
          </>
        )}
      </div>
    </Sticky>
  );
}
