import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setMystreyBonusPopupShowStatus } from '../../redux/reducers/appSlice';
import Icon, { IconName } from '../icon';
import MysteryBonusTipsImg from '../../assets/img/mysteryBonusTipsImg.webp';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import { useGlobalNavigate } from '../../contexts/navigateContext';
import { toLocalePath } from '../../utils/localePath';

function MysteryBonusTips({ handleClose = () => {} }) {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMystreyBonusPopupShowStatus(true));
    return () => {
      dispatch(setMystreyBonusPopupShowStatus(false));
    };
  }, []);

  const handleConfirmClick = () => {
    handleClose();
    navigate(toLocalePath('/mysterybonus'));
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Signup_Confirm);
  };

  const handleCancelClick = () => {
    handleClose();
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Signup_Cancel);
  };

  const handleCloseClick = () => {
    handleClose();
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Signup_Close);
  };

  return (
    <div className="w-[5.96rem] rounded-[.16rem] bg-[var(--colour-35)] p-[1rem_.2rem_.22rem_.2rem] relative">
      <div
        className="active:scale-[.95] transition-all select-none w-[.6rem] h-[.6rem] rounded-[50%] flex justify-center items-center absolute left-[50%] bottom-[-0.92rem] translate-x-[-50%] cursor-pointer"
        onClick={handleCloseClick}
      >
        <Icon name={IconName.SignInClose} />
      </div>
      <div className="absolute w-[2.78rem] h-[2.78rem] left-[50%] translate-x-[-50%] top-[-1.46rem]">
        <img className="w-full" src={MysteryBonusTipsImg} />
      </div>
      <div className="mb-[.62rem] text-[.28rem] font-[500] leading-[.34rem] text-[var(--colour-10)] text-center">
        {t('mystery_bonus.mystery_bonus_popup_signup_success_tip')}
      </div>
      <div className="flex items-center flex-wrap justify-between">
        <div
          className="min-w-[2.68rem] h-[.7rem] px-[.1rem] rounded-[.1rem] border border-[var(--colour-1)] text-[.24rem] font-[500] flex items-center justify-center text-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none"
          onClick={handleCancelClick}
        >
          {t('home.cancelar')}
        </div>
        <div
          className="ml-auto min-w-[2.68rem] h-[.7rem] px-[.1rem] rounded-[.1rem] bg-[var(--colour-1)] text-[.24rem] font-[500] flex items-center justify-center text-[var(--colour-2)] cursor-pointer active:scale-[.95] transition-all select-none"
          onClick={handleConfirmClick}
        >
          {t('home.confirm')}
        </div>
      </div>
    </div>
  );
}
export default MysteryBonusTips;
