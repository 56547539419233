import { shallowEqual, useSelector } from 'react-redux';
import { cmsImgUrl } from '../../../utils/helper';
import Icon, { IconName } from '../../icon';

const LoginRegisterWrapper = ({ children, handleClose }) => {
  const { imgSrc } = useSelector(
    (state) => ({
      imgSrc: state?.app?.websiteInitConfig?.website_info?.website_logo_list[0]?.app_logo_path,
    }),
    shallowEqual,
  );

  return (
    <div className="w-[7.5rem] h-[var(--app-height)] bg-[var(--colour-21)] flex flex-col">
      <div className="h-[1.86rem] flex justify-between">
        <div className="p-[.12rem_0_0_.44rem]">
          <img src={cmsImgUrl(imgSrc)} className="not-drag h-[.82rem] w-auto" />
        </div>
        <div className="p-[.24rem_.32rem_0_0]">
          <div
            className="w-[.56rem] h-[.56rem] bg-[rgba(255,255,255,0.1)] rounded-full flex justify-center items-center cursor-pointer"
            onClick={handleClose}
          >
            <div className="text-[var(--colour-46)] w-[.2rem] h-[.2rem]">
              <Icon name={IconName.Close2} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex-1 rounded-t-[.4rem] bg-[var(--colour-35)]"
        style={{
          boxShadow: `0px 2px 0px 0px var(--colour-64) inset`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default LoginRegisterWrapper;
