import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { get, cloneDeep } from 'lodash';
import cn from 'classnames';
import Line from '../../../../line';
import LimitClickWrapper from '../../../../limitClickWrapper';
import ElementWithLoading from '../../../../elementWithLoading';
import CheckBox from '../../../../checkBox';
import { useState } from 'react';
import ScrollWrapper from '../../../../scrollWrapper';
import FullPagePopup from '../../../../fullpagePopup';
import DepositDetail from '../../design/deposit/components/depositDetail';
import { DepositInfo, DepositAction } from '../../../../../api/index';
import AnalyticsManager, { FirebaseEvents, EventIds } from '../../../../../manager/analyticsManager';
import Loading from '../../../../loading2';
import Input from '../../../../input';
import DepositQR from '../../../../wallet/component/depositQR';
import DialogModal from '../../../../dialogModal';
import Close from '../../../../close';
import DrawerPopup from '../../../../../components/drawerPopup';
import BrDesignDepositRecord from './depositRecordPanda';
import {
  pointToComma,
  putPatton,
  putChangeText,
  getShowMoneyNumber,
  commaToPoint,
  cutDecimal,
  putShowMoney,
  TextPlaces1,
  setLocalStorageWithUID,
  getLocalStorageWithUID,
  formatCpf,
} from '../../../../../utils/helper';
import usePayConfig from '../../../../../hooks/usePayConfig';
import ConfirmTips from '../../../../confirmTips';
import {
  deleteCPF,
  formatCPF,
  restrictToNumbers,
  restrictToName,
  isValidCPF,
  limitInputLength,
  removeSpecialSymbols,
} from '../../../../../utils/inputHelper';
import { useUpdateEffect } from 'ahooks';

import Header from '../components/header';
import ItemBox1 from '../components/itemBox1';
import ItemBox2 from '../components/itemBox2';
import DepositRecordPanda from './depositRecordPanda';

export default function DepositPanda({ handleClose = () => {} }) {
  const { t } = useTranslation();
  const { currency_default_location, deposit_fiat_bouns_open_switch } = usePayConfig();
  const { websiteInfo, currency_code, websiteInitDataPrompt } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      websiteInitDataPrompt: state.app.websiteInitData?.prompt,
      currency_code: get(state, 'app.websiteInitConfig.website_info.currency_code') || '',
    }),
    shallowEqual,
  );
  const [bonusTipsVisible, setBonusTipsVisible] = useState(false); //气泡显示
  const [depositDetailVisible, setDepositDetailVisible] = useState(false);
  const [isOpenDepositRecord, setIsOpenisOpenDepositRecord] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fiatList, setFiatList] = useState([]); //法币channel列表
  const [result, setResult] = useState(null);
  const [channel, setChannel] = useState(0); //记录支付通道
  const [amountList, setAmountList] = useState([]);
  const [chooseAmount, setChooseAmount] = useState(100);

  const [bonusConfig, setBonusConfig] = useState({});
  const [bonus, setBonus] = useState(false);
  const [withBonus, setWithBonus] = useState(true); // 是否选中活动
  const [requesting, setRequesting] = useState(false);

  //点击pay后的相关弹窗状态
  const [rechargeUrl, setRechargeUrl] = useState('');
  const [confirmed, setConfirmed] = useState(false); // 弹出dialog 确认支付金额
  const [qrcodeURL, setQrcodeURL] = useState(''); // qrcodeURL
  const [isOpenQrcode, setIsOpenQrcode] = useState(false); // 打开邀请码
  const [isOpen, setIsOpen] = useState(false);
  const [depositTips, setDepositTips] = useState({});

  const [depositList, setDepositList] = useState([]);

  //充值限额配置
  const [limitAmount, setLimitAmount] = useState(0);
  const [limitRequiredContent, setLimitRequiredContent] = useState([]);
  const [isShowConentList, setIsShowContentList] = useState(false); //输入框金额是否大于配置的limit_amount金额
  const [isScrollToBottom, setIsScrollToBottom] = useState(false);

  const [defaultValueConfig, setDefaultValueConfig] = useState(null); //name和cpf输入框的默认显示
  const getDepositListValue = (name) => {
    const obj = depositList.find((item) => {
      return item.name === name;
    });
    return obj ? obj.value : '';
  };

  const getLimitRequiredListValue = (name) => {
    const obj = limitRequiredContent.find((item) => {
      return item.key === name;
    });
    return obj ? obj.value : '';
  };

  const savePayDepositInfoKey = 'pay_deposit_info';
  const savePayInfo = (list) => {
    if (list.length > 0) {
      const saveObj = {};
      for (let i = 0; i < list.length; i++) {
        const name = String(list[i].name).toLowerCase();
        if (name == 'cpf' || name == 'name') {
          saveObj[name] = list[i].value;
        }
      }
      setLocalStorageWithUID(savePayDepositInfoKey, JSON.stringify(saveObj));
    }
  };

  const getCacheValue = (key) => {
    const str = getLocalStorageWithUID(savePayDepositInfoKey);
    if (str) {
      const obj = JSON.parse(str);
      return obj[key];
    } else {
      return '';
    }
  };

  const getConfigValue = (key) => {
    if (defaultValueConfig && defaultValueConfig[key]) {
      if (key == 'cpf') {
        return formatCpf(defaultValueConfig[key]);
      } else {
        return defaultValueConfig[key];
      }
    } else {
      return '';
    }
  };

  //校验detailinfo的字段,true为校验通过，false为校验失败
  const validationDetailInfo = (jsonObj) => {
    if (jsonObj) {
      if (String(jsonObj.name).toLowerCase() == 'cpf') {
        const isTrueCPF = isValidCPF(jsonObj.value);
        if (isTrueCPF) {
          return true;
        } else {
          toast.error(t('deposit-withdraw-wg.addpix_cpf3'), {
            containerId: 'global',
          });
          return false;
        }
      }
    }
    return true;
  };

  // 定义一个处理值变化的函数
  const handleValueChange = (name, newValue) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedDepositList = depositList.map((item) => {
      if (item.name === name) {
        return { ...item, value: newValue };
      }
      return item;
    });

    // 更新状态
    setDepositList(updatedDepositList);
  };

  // 定义一个处理值变化的函数
  const handleValueChange1 = (array, newValue) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedDepositList = array.map((item) => {
      return { ...item, value: getConfigValue(item.name) || getCacheValue(item.name) || newValue };
    });

    // 更新状态
    setDepositList(updatedDepositList);
  };

  // 定义一个处理值变化的函数
  const newHandleValueChange = (key, newValue) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedLimitContentList = limitRequiredContent.map((item) => {
      if (item.key === key) {
        return { ...item, value: newValue };
      }
      return item;
    });

    // 更新状态
    setLimitRequiredContent(updatedLimitContentList);
  };

  // 定义一个处理值变化的函数
  const newHandleValueChange1 = (array) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedLimitContentList = array.map((item) => {
      return { key: item, value: getConfigValue(item) || getCacheValue(item) || '' };
    });

    // 更新状态
    setLimitRequiredContent(updatedLimitContentList);
  };

  // 处理数组
  const ChangeDataArray = (data) => {
    var arrayFiat = [];
    if (data && data.deposit_list && data.deposit_list.length > 0) {
      // 重新组建数组
      data.deposit_list.map((item) => {
        if (item.type == 'fiat') {
          arrayFiat.push(item);
        }
      });
      setFiatList(arrayFiat);

      if (arrayFiat && arrayFiat.length > 0) {
        AnalyticsManager.FirebaseLogEvent(
          FirebaseEvents.WB_Click_PayOption_ + `${arrayFiat[0].provider}` + '_' + `${arrayFiat[0].network}`,
        );
      }
    }
  };

  useEffect(() => {
    DepositInfo()
      .then((res) => {
        if (res.code == 0) {
          // 查看是否拥有任务
          if (res && res.result && res.result.bonus && res.result.bonus.config) {
            try {
              const config = JSON.parse(res.result.bonus.config);
              if (config.bonus_ratio) {
                if (deposit_fiat_bouns_open_switch == 0) {
                  if (currency_default_location == 1) {
                    setWithBonus(true);
                  } else {
                    setWithBonus(false);
                  }
                }
                setBonus(true);
                setBonusConfig(config);
              } else {
                setWithBonus(false);
              }
            } catch {
              setWithBonus(false);
            }
          } else {
            setBonus(false);
            setWithBonus(false);
          }
          ChangeDataArray(res.result);
          setResult(res.result);
          setDefaultValueConfig(res.result?.user_deposit_brl);
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    //钱包点击法币按钮执行的有关setWithBonus的逻辑,在这里直接加上
    if (deposit_fiat_bouns_open_switch == 0) {
      setWithBonus(false);
    } else {
      if (bonus) {
        setWithBonus(true);
      } else {
        setWithBonus(false);
      }
    }
  }, [deposit_fiat_bouns_open_switch, bonus]);

  useEffect(() => {
    if (websiteInitDataPrompt) {
      setDepositTips(websiteInitDataPrompt.walletDepositTips);
    }
  }, [websiteInitDataPrompt]);

  useEffect(() => {
    if (fiatList && fiatList.length > 0) {
      const array = JSON.parse(fiatList[channel].deposit_amount_list);
      setAmountList(array);
    }
  }, [fiatList, channel]);

  const changeAmount = (result, channel) => {
    const depositChannel = result && result.length > 0 && result[channel];
    if (depositChannel) {
      const deposit_amount_list = JSON.parse(depositChannel.deposit_amount_list);
      const default_deposit_amount = depositChannel.default_deposit_amount;

      if (deposit_amount_list.length > 0) {
        if (deposit_amount_list.some((item) => Number(item) == Number(default_deposit_amount))) {
          setChooseAmount(Number(default_deposit_amount));
        } else {
          setChooseAmount(deposit_amount_list[0]);
        }
      }
    }

    if (result && result.length > 0) {
      try {
        handleValueChange1(JSON.parse(result[channel].deposit_fields), '');
      } catch {
        setDepositList([]);
      }
    }
  };

  //切换channel选中相应的amount
  useEffect(() => {
    changeAmount(fiatList, channel);
  }, [channel, fiatList]);

  useEffect(() => {
    if (fiatList && fiatList.length > 0) {
      setLimitAmount(fiatList[channel]?.limit_amount);
      if (fiatList[channel]?.limit_required_content) {
        newHandleValueChange1(JSON.parse(fiatList[channel]?.limit_required_content));
      } else {
        setLimitRequiredContent([]);
      }
    }
  }, [channel, fiatList]);

  //当前输入金额和配置的充值限额比较
  useEffect(() => {
    if (Number(putShowMoney(commaToPoint(chooseAmount))) >= Number(limitAmount)) {
      setIsShowContentList(true);
    } else {
      setIsShowContentList(false);
    }
  }, [chooseAmount, limitAmount]);

  const handleClickDepositRecord = () => {
    setIsOpenisOpenDepositRecord(true);
  };

  const handleCloseDepositRecord = () => {
    setIsOpenisOpenDepositRecord(false);
  };

  //充值
  const DospoitClick = () => {
    if (!commaToPoint(chooseAmount)) {
      return toast.error(t('deposit-withdraw.deposit_submit'), {
        containerId: 'global',
      });
    }
    if (Number(commaToPoint(chooseAmount)) < Number(getShowMoneyNumber(fiatList[channel].deposit_min))) {
      return toast.error(
        t('deposit-withdraw.deposit_submit1', {
          min_amount: getShowMoneyNumber(fiatList[channel].deposit_min),
          currency_symbol: websiteInfo.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    if (Number(commaToPoint(chooseAmount)) > Number(getShowMoneyNumber(fiatList[channel].deposit_max))) {
      return toast.error(
        t('deposit-withdraw.deposit_submit2', {
          max_amount: getShowMoneyNumber(fiatList[channel].deposit_max),
          currency_symbol: websiteInfo.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    // 判断是否必填
    var detail_info = [];

    if (depositList && depositList.length > 0) {
      for (let i = 0; i < depositList.length; i++) {
        var item = depositList[i];
        if (!item.value) {
          toast.error(t('deposit-withdraw.please_enter', { name: item.placeholder }), {
            containerId: 'global',
          });
          return;
        } else {
          if (validationDetailInfo(item)) {
            detail_info.push({
              name: item.name,
              value: item.value,
            });
          } else {
            return;
          }
        }
      }
    }

    if (Number(limitAmount) > 0 && isShowConentList && limitRequiredContent && limitRequiredContent.length > 0) {
      for (let i = 0; i < limitRequiredContent.length; i++) {
        var contentItem = limitRequiredContent[i];
        if (!contentItem.value || contentItem.value.trim() == '') {
          toast.error(t('deposit-withdraw.please_enter', { name: contentItem.key }), {
            containerId: 'global',
          });
          return;
        } else {
          if (validationDetailInfo(contentItem)) {
            detail_info.push({
              name: contentItem.key,
              value: contentItem.value.trim(),
            });
          } else {
            return;
          }
        }
      }
    }

    setRequesting(true);
    const tempDetailInfo = cloneDeep(detail_info);
    //对提交到服务端的detail_info进行处理
    for (let i = 0; i < detail_info.length; i++) {
      if (String(detail_info[i].name).toLowerCase() == 'cpf') {
        detail_info[i].value = detail_info[i].value.replace(/[\.-]/g, '');
      }
    }

    const formData = {
      id: fiatList[channel].id,
      amount: putShowMoney(commaToPoint(chooseAmount)),
      with_bonus: withBonus ? 1 : 0,
      detail_info: detail_info.length > 0 ? JSON.stringify(detail_info) : '',
    };

    DepositAction({ ...formData })
      .then((res) => {
        if (res.code == 0) {
          savePayInfo(tempDetailInfo);
          var as_qrcode = false;
          if (result && fiatList && fiatList.length > 0) {
            as_qrcode = fiatList[channel].as_qrcode;
          }

          if (res.result && res.result.redirect_url) {
            setRechargeUrl(res.result.redirect_url);
            if (currency_code == 'COP') {
              setConfirmed(true);
            } else {
              if (as_qrcode && res.result.redirect_url != res.result.qrcode_url && res.result.qrcode_url != '') {
                setQrcodeURL(res.result.qrcode_url);
                // setIsOpenQrcode(true);
                setDepositDetailVisible(true);
                if (!res.result.qrcode_url) {
                  //上报到我们自己的打点服务器
                  AnalyticsManager.sendExtraInfoLog2Server(EventIds.depositQrCodeErr, JSON.stringify(res));
                }
              } else {
                setIsOpen(true);
              }
            }
          } else if (res.result && !res.result.redirect_url && res.result.qrcode_url) {
            setQrcodeURL(res.result.qrcode_url);
            // setIsOpenQrcode(true);
            setDepositDetailVisible(true);
          }
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  // 改变金额
  const DeposintAmount = (item) => {
    if (currency_code == 'COP') {
      if (item >= 1000 && item < 1000000) {
        return (
          <>
            <span>{String(Number(item) / 1000)}</span>
            <span className="text-[.24rem]">Mil</span>
          </>
        );
      } else if (item >= 1000000) {
        return (
          <>
            <span>{String(Number(item) / 1000000)}</span>
            <span className="text-[.24rem]">Millón</span>
          </>
        );
      }
    }
    return item;
  };

  const BounsMoney = () => {
    return Number(commaToPoint(chooseAmount)) * Number(bonusConfig.bonus_ratio) <
      Number(getShowMoneyNumber(bonusConfig.bonus_max))
      ? Number(cutDecimal(Number(commaToPoint(chooseAmount)) * bonusConfig.bonus_ratio))
      : Number(cutDecimal(getShowMoneyNumber(bonusConfig.bonus_max)));
  };

  // 获取对应的key
  const findReward = (rewardList, rechargeAmount, type = 1) => {
    let closestReward = null;
    let closestDifference = Infinity;

    for (let reward of rewardList) {
      let condition = Number(getShowMoneyNumber(reward.reward_condition));
      if (rechargeAmount >= condition && rechargeAmount - condition < closestDifference) {
        closestDifference = rechargeAmount - condition;
        closestReward = reward;
      }
    }
    if (closestReward) {
      if (type == 1) {
        return getShowMoneyNumber(closestReward.reward_value);
      } else {
        return closestReward.reward_value;
      }
    } else {
      return null;
    }
  };

  // 法币right
  const RightMessageCor = () => {
    if (bonusConfig.reward_type == 1) {
      let findReward1 = findReward(bonusConfig.rewards_list, commaToPoint(chooseAmount));
      if (findReward1) {
        return (
          <div className={`text-[.24rem] font-[500] text-[var(--colour-2)]`}>
            Extra+ {websiteInfo.currency_symbol || ''}
            {pointToComma(findReward1)}
          </div>
        );
      } else {
        return <></>;
      }
    } else if (bonusConfig.reward_type == 2) {
      let findReward1 = findReward(bonusConfig.rewards_list, commaToPoint(chooseAmount), 2);
      if (findReward1) {
        return (
          <div className={`text-[.24rem] font-[500] text-[var(--colour-2)]`}>
            Extra+ {websiteInfo.currency_symbol || ''}
            {pointToComma(
              cutDecimal(Number(commaToPoint(chooseAmount)) * Number(findReward1)) >
                Number(getShowMoneyNumber(bonusConfig.bonus_max))
                ? getShowMoneyNumber(bonusConfig.bonus_max)
                : cutDecimal(Number(commaToPoint(chooseAmount)) * Number(findReward1)),
            )}
          </div>
        );
      } else {
        return <></>;
      }
    }

    if (Number(commaToPoint(chooseAmount)) >= Number(getShowMoneyNumber(bonusConfig.deposit_min))) {
      return (
        <div className={`text-[.24rem] font-[500] text-[var(--colour-2)]`}>
          Extra+ {websiteInfo.currency_symbol || ''}
          {pointToComma(BounsMoney())}
        </div>
      );
    }
  };

  const getMaxRewardTpe2 = (rewardList) => {
    let maxRewardValue = 0;
    let maxRewardObject = null;

    rewardList.forEach((obj) => {
      const rewardValue = Number(obj.reward_value);
      if (rewardValue > maxRewardValue) {
        maxRewardValue = rewardValue;
        maxRewardObject = obj;
      }
    });
    return maxRewardObject;
  };

  const getMaxReward = (rewardList) => {
    let maxRewardValue = 0;
    let maxRewardObject = null;

    rewardList.forEach((obj) => {
      const rewardValue = Number(obj.reward_value);
      if (rewardValue > maxRewardValue) {
        maxRewardValue = rewardValue;
        maxRewardObject = obj;
      }
    });
    return maxRewardObject;
  };

  // type 1 2 获取number
  const getBonusNumberCoy = () => {
    if (bonusConfig.reward_type == 1) {
      return pointToComma(getShowMoneyNumber(Number(getMaxReward(bonusConfig.rewards_list)?.reward_value)));
    }
  };

  // 内容
  const getTextForBonus = () => {
    if (bonusConfig) {
      if (bonusConfig.reward_type == 1) {
        return result.bonus.participate_type != 'one_time'
          ? t('deposit-withdraw.daily_first_type', { val: getBonusNumberCoy() })
          : t('deposit-withdraw.first_deposit_type', { val: getBonusNumberCoy() });
      } else if (bonusConfig.reward_type == 2) {
        return result.bonus.participate_type != 'one_time'
          ? t('deposit-withdraw.daily_first_type', {
              val: `${Number(Number(getMaxRewardTpe2(bonusConfig.rewards_list)?.reward_value * 100).toFixed(2))}%`,
            })
          : t('deposit-withdraw.first_deposit_type', {
              val: `${Number(Number(getMaxRewardTpe2(bonusConfig.rewards_list)?.reward_value * 100).toFixed(2))}%`,
            });
      }
      return result.bonus.participate_type != 'one_time'
        ? t('deposit-withdraw.daily_first', {
            val: `${Number(Number(Number(bonusConfig.bonus_ratio) * 100).toFixed(2))}%`,
          })
        : t('deposit-withdraw.first_deposit', {
            val: `${Number(Number(Number(bonusConfig.bonus_ratio) * 100).toFixed(2))}%`,
          });
    }
  };

  const moneyListTextTop = (amount, bonusConfig) => {
    if (bonusConfig.reward_type == 1) {
      const reward = findReward(bonusConfig.rewards_list, amount);
      if (reward) {
        return pointToComma(reward);
      } else {
        return 0;
      }
    } else if (bonusConfig.reward_type == 2) {
      const reward = findReward(bonusConfig.rewards_list, amount, 2);
      if (reward) {
        return pointToComma(
          cutDecimal(
            Number(amount) * reward > Number(getShowMoneyNumber(bonusConfig.bonus_max))
              ? Number(getShowMoneyNumber(bonusConfig.bonus_max))
              : Number(amount) * reward,
          ),
        );
      } else {
        return 0;
      }
    } else {
      if (Number(amount) >= Number(getShowMoneyNumber(bonusConfig.deposit_min))) {
        return pointToComma(
          (Number(amount) * bonusConfig.bonus_ratio).toFixed(0) < Number(getShowMoneyNumber(bonusConfig.bonus_max))
            ? Number(cutDecimal(Number(amount) * bonusConfig.bonus_ratio))
            : Number(cutDecimal(Number(getShowMoneyNumber(bonusConfig.bonus_max)))),
        );
      } else {
        return 0;
      }
    }
  };

  const handleCustomInputKeyDown = (event, name) => {
    if (String(name).toLowerCase() == 'cpf') {
      restrictToNumbers(event);
    } else if (String(name).toLowerCase() == 'name') {
      restrictToName(event);
      limitInputLength(event, 100);
    }
  };

  const handleCustomInputValueChange = (key, value, isLimitRequired) => {
    if (isLimitRequired) {
      newHandleValueChange(key, value);
    } else {
      handleValueChange(key, value);
    }
  };

  const handleCustomInputChange = (event, name, isLimitRequired = false) => {
    if (String(name).toLowerCase() == 'cpf') {
      const cpfAllowedCharacters = /[^0-9.-]+/g;
      const value = event.target.value.replace(cpfAllowedCharacters, '');
      const lastValue = isLimitRequired ? getLimitRequiredListValue(name) : getDepositListValue(name);
      if (String(value).length < String(lastValue).length) {
        //如果是删除
        // const cpf = deleteCPF(lastValue);
        const cpf = String(value) != '' ? deleteCPF(lastValue) : '';
        handleCustomInputValueChange(name, cpf, isLimitRequired);
      } else {
        let cpf = formatCPF(value.replace(/[\.-]/g, ''));
        const maxLength = 11 + 3;
        if (cpf.length > maxLength) {
          // 截断输入的值
          cpf = cpf.slice(0, maxLength);
        }
        handleCustomInputValueChange(name, cpf, isLimitRequired);
      }
    } else if (String(name).toLowerCase() == 'name') {
      let newVal = removeSpecialSymbols(TextPlaces1(event.target.value, 45));
      handleCustomInputValueChange(name, newVal, isLimitRequired);
    } else {
      let newVal = TextPlaces1(event.target.value, 45);
      handleCustomInputValueChange(name, newVal, isLimitRequired);
    }
  };

  useUpdateEffect(() => {
    setIsScrollToBottom(true);
  }, [fiatList, amountList, depositList, limitRequiredContent]);

  const handleClickBackBtn = () => {
    handleClose();
  };

  const handleClickRecordBtn = () => {
    setIsOpenisOpenDepositRecord(true);
  };

  return (
    <>
      {/* iframe */}
      <FullPagePopup isOpen={depositDetailVisible}>
        <DepositDetail
          handleBack={() => setDepositDetailVisible(false)}
          amount={commaToPoint(chooseAmount)}
          qcURL={qrcodeURL}
          network={fiatList[channel]?.network || ''}
        />
      </FullPagePopup>
      {/* 提现记录 */}
      <FullPagePopup isOpen={isOpenDepositRecord}>
        <DepositRecordPanda handleCloseDepositRecord={handleCloseDepositRecord} />
      </FullPagePopup>
      <div className="h-full flex flex-col bg-[var(--colour-54)]">
        <Header
          title={t('deposit-withdraw.deposit')}
          clickBackBtn={handleClickBackBtn}
          showRecordBtn={true}
          clickRecordBtn={handleClickRecordBtn}
        />
        {loading ? (
          <Loading className="flex-1" />
        ) : (
          <div className="px-[.28rem] pb-[.4rem]">
            <ScrollWrapper
              noRelative={true}
              mr={false}
              className="h-[calc(var(--app-height)-1.36rem)]" //header高度 + 底部padding20px
              isShowOnBottom={isScrollToBottom}
            >
              <div className="h-[1.18rem] pt-[.5rem] border-b border-[var(--colour-6)] flex">
                <div className="pl-[.32rem] pr-[.4rem] border-b border-[var(--colour-67)] text-[.28rem] font-[500] leading-[.34rem] text-[var(--colour-13)]">
                  {t('deposit-withdraw-wg.deposit_title_switch')}
                </div>
              </div>
              <Line />
              {/* channel列表 */}
              <div className="my-[.28rem] grid grid-cols-3 gap-y-[.2rem]">
                {fiatList && fiatList.length > 0
                  ? fiatList.map((item, index) => {
                      return (
                        <ItemBox1
                          key={item.id}
                          click={() => {
                            AnalyticsManager.FirebaseLogEvent(
                              FirebaseEvents.WB_Click_PayOption_ + `${item.provider}` + '_' + `${item.network}`,
                            );
                            setChannel(index);
                          }}
                          selected={channel == index}
                        >
                          {item.deposit_name}
                        </ItemBox1>
                      );
                    })
                  : null}
              </div>
              <Line />

              <Input
                leftOuter={
                  <div className="flex items-center">
                    <div className="text-[.24rem] font-[600] leading-[.3rem] text-[var(--colour-38)] block mr-[.06rem] ml-[.2rem]">
                      {websiteInfo.currency_symbol || ''}
                    </div>
                  </div>
                }
                rightInner={withBonus && bonus && RightMessageCor()}
                type="number"
                onChange={(val) => {
                  let newVal = '';
                  if (currency_code == 'COP') {
                    newVal = putPatton(val.target.value);
                  } else {
                    newVal = putChangeText(val.target.value);
                  }
                  setChooseAmount(newVal);
                }}
                value={chooseAmount}
                placeholder={t('deposit-withdraw.max_amount_enter', {
                  min:
                    fiatList && fiatList.length > 0 && fiatList[channel] && fiatList[channel].deposit_min
                      ? pointToComma(getShowMoneyNumber(fiatList[channel].deposit_min))
                      : pointToComma(getShowMoneyNumber(0)),
                  max:
                    fiatList && fiatList.length > 0 && fiatList[channel] && fiatList[channel].deposit_max
                      ? pointToComma(getShowMoneyNumber(fiatList[channel].deposit_max))
                      : pointToComma(getShowMoneyNumber(0)),
                })}
                innerClassName="!h-[.96rem] !pl-[0rem] !pr-[.36rem] !border-transparent"
                topClassName="rounded-[.12rem] overflow-hidden bg-[var(--colour-22)] border border-transparent mt-[.32rem]"
                inputClassName="wg-deposit-input !text-[.24rem] !text-[var(--colour-2)]"
              />

              <div className="mt-[.24rem]">
                {/* amount列表 */}
                <div className="grid grid-cols-3 gap-y-[.24rem]">
                  {amountList && amountList.length > 0
                    ? amountList.map((item, index) => {
                        return (
                          <ItemBox2
                            key={index}
                            click={() => {
                              setChooseAmount(item);
                            }}
                            selected={chooseAmount == item}
                            bubbleBoxInner={
                              withBonus && bonus && Number(commaToPoint(moneyListTextTop(item, bonusConfig))) > 0 ? (
                                <>+ {moneyListTextTop(item, bonusConfig)}</>
                              ) : null
                            }
                          >
                            {pointToComma(DeposintAmount(item))}
                          </ItemBox2>
                        );
                      })
                    : null}

                  {/* <ItemBox bubbleBoxInfo="+5%" className="w-[1.6rem] h-[.8rem] justify-center" selected={false}>
                    PIX-VIP
                  </ItemBox> */}
                </div>
              </div>

              {withBonus && bonus && (
                <div
                  className={`w-[100%] border border-[var(--colour-67)] colour-87 mt-[.24rem] h-[.76rem] text-[.24rem] font-[500] leading-[.72rem] flex justify-between px-[.18rem] text-[var(--colour-44)] rounded-[.12rem]`}
                >
                  <div className="">{result?.bonus?.participate_type && getTextForBonus()}</div>
                  <div className="">
                    {result?.bonus?.user_participate_num}/{result?.bonus?.participate_num}
                  </div>
                </div>
              )}

              {depositList &&
                depositList.length > 0 &&
                depositList.map((item) => (
                  <div key={item.name}>
                    <div className="mt-[.24rem] text-[var(--colour-38)] ">
                      <Input
                        onKeyDown={(event) => {
                          handleCustomInputKeyDown(event, item.name);
                        }}
                        leftOuter={
                          <div className="flex items-center">
                            <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-44)] block mr-[.32rem] ml-[.2rem]">
                              {item.name || ''}
                            </div>
                          </div>
                        }
                        type="text"
                        onChange={(event) => {
                          handleCustomInputChange(event, item.name, false);
                        }}
                        // onChange={(val) => {
                        //   let newVal = TextPlaces1(val.target.value, 45);

                        //   handleValueChange(item.name, newVal);
                        // }}
                        value={item.value}
                        placeholder={
                          item.name == 'cpf'
                            ? t('deposit-withdraw-wg.please_enter')
                            : t('deposit-withdraw.please_enter', { name: item.placeholder })
                        }
                        innerClassName="!h-[.8rem] !pl-[.13rem] !pr-[.32rem] !border-transparent"
                        topClassName="rounded-[.08rem] overflow-hidden bg-[var(--colour-22)] border border-[var(--colour-6)] mt-[.24rem]"
                        inputClassName="wg-deposit-input !text-[.24rem]"
                      />
                    </div>
                  </div>
                ))}

              {Number(limitAmount) > 0 && isShowConentList && limitRequiredContent && limitRequiredContent.length > 0
                ? limitRequiredContent.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="mt-[.24rem] text-[var(--colour-38)] ">
                          <Input
                            onKeyDown={(event) => {
                              handleCustomInputKeyDown(event, item.key);
                            }}
                            leftOuter={
                              <div className="flex items-center">
                                <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-44)] block mr-[.32rem] ml-[.2rem]">
                                  {item.key || ''}
                                </div>
                              </div>
                            }
                            type="text"
                            onChange={(event) => {
                              handleCustomInputChange(event, item.key, true);
                            }}
                            // onChange={(val) => {
                            //   let newVal = TextPlaces1(val.target.value, 45);

                            //   newHandleValueChange(item.key, newVal);
                            // }}
                            value={item.value}
                            placeholder={
                              item.key == 'cpf'
                                ? t('deposit-withdraw-wg.please_enter')
                                : t('deposit-withdraw.please_enter', { name: item.key })
                            }
                            innerClassName="!h-[.8rem] !pl-[.13rem] !pr-[.32rem] !border-transparent"
                            topClassName="rounded-[.08rem] overflow-hidden bg-[var(--colour-22)] border border-[var(--colour-6)] mt-[.24rem]"
                            inputClassName="wg-deposit-input !text-[.24rem]"
                          />
                        </div>
                      </div>
                    );
                  })
                : null}

              {/* <div className="mt-[.32rem] flex items-center justify-start space-x-[.24rem]">
                <CheckBox handleClick={handleCheckWithBonus} className="w-[.64rem] h-[.64rem]" checked={checkedWithBonus} />
                <span className="text-[.28rem] font-[500] text-[var(--colour-10)]">With bonus</span>
              </div> */}

              {bonus ? (
                <div className="flex items-center mt-[.24rem] text-[.24rem] font-[500] leading-[.36rem] text-[var(--colour-72)]">
                  <CheckBox
                    handleClick={() => {
                      setWithBonus(!withBonus);
                    }}
                    className="w-[.36rem] h-[.36rem]"
                    checked={withBonus}
                  />

                  <div className={`ml-[.16rem]`}>{t('deposit-withdraw.with_bonus')}</div>
                </div>
              ) : (
                <></>
              )}
              {/* <LimitClickWrapper
                onClick={handlePay}
                className="mt-[.32rem] h-[.96rem] rounded-[.16rem] bg-[var(--colour-1)] text-[var(--colour-2)] text-[.32rem] font-[700] flex items-center justify-center"
              >
                Pay
              </LimitClickWrapper> */}

              <div className="flex items-center justify-center mt-[.38rem]">
                <LimitClickWrapper
                  className="active:scale-[.95] transition-all select-none flex flex-row px-[.34rem] min-w-[5.38rem] h-[.78rem] items-center justify-center cursor-auto rounded-[.2rem] colour-79 shadow-[0rem_.08rem_.08rem_0rem_rgba(0,0,0,0.25)]"
                  onClick={() => {
                    if (!requesting) {
                      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_DepositButton);

                      // wbClick('WB_Click_DepositButton');
                      DospoitClick();
                    }
                  }}
                >
                  <ElementWithLoading
                    isLoading={requesting}
                    normalEl={
                      <div className="font-[600] text-[.3rem] text-[var(--colour-2)]">{t('deposit-withdraw.pay')}</div>
                    }
                  />
                </LimitClickWrapper>
              </div>
            </ScrollWrapper>
          </div>
        )}
      </div>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={isOpen}
        handleClose={() => {
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          setIsOpen(false);
        }}
      >
        <Recharge
          handleClose={() => {
            setIsOpen(false);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          }}
          rechargeUrl={rechargeUrl}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={isOpenQrcode}
        handleClose={() => {
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          setIsOpenQrcode(false);
        }}
      >
        <DepositQR
          handleClose={() => {
            setIsOpenQrcode(false);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          }}
          amount={commaToPoint(chooseAmount)}
          qcURL={qrcodeURL}
          network={fiatList[channel]?.network || ''}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={confirmed}
        handleClose={() => {
          setConfirmed(false);
        }}
      >
        <RechargeTrue
          depositTips={depositTips}
          amount={commaToPoint(chooseAmount)}
          rechargeUrl={rechargeUrl}
          bonusConfig={bonusConfig}
          withBonus={withBonus}
          bonus={bonus}
          t={t}
          currency_symbol={websiteInfo.currency_symbol || ''}
          handleClose={() => setConfirmed(false)}
        />
      </DialogModal>

      <ConfirmTips type="deposit" />
    </>
  );
}

const Recharge = ({ handleClose, rechargeUrl }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
  }, []);

  const onIframeLoad = () => {
    setLoading(false);
  };
  return (
    <div className="w-[7.4rem] min-h-[95vh]  bg-[var(--colour-35)] pb-[.32rem]">
      <div className={`flex flex-row justify-between items-center mt-[.3rem] mb-[.2rem] mr-[.3rem]`}>
        <div className="text-[.36rem] font-[600]"></div>
        <div
          style={{ color: '#fff' }}
          className="w-[.72rem] h-[.72rem] flex justify-center items-center rounded-[.08rem] bg-[var(--colour-45)]"
        >
          <Close onClick={handleClose} />
        </div>
      </div>
      {loading ? (
        <div className="w-full relative pt-[4rem]">
          <Loading />
        </div>
      ) : null}
      <iframe
        className={`w-full h-[83vh] bg-[#fff]  ${loading ? 'hidden' : 'block'}`}
        src={rechargeUrl}
        onLoad={onIframeLoad}
        frameBorder="0"
        scrolling="yes"
        allow="accelerometer; autoplay; camera; gyroscope; payment"
      ></iframe>
    </div>
  );
};

// 区块充值
// 充值确定弹窗
const RechargeTrue = ({
  handleClose,
  depositTips,
  rechargeUrl,
  t,
  amount,
  currency_symbol,
  withBonus,
  bonus,
  bonusConfig,
}) => {
  return (
    <>
      <div className="w-[7rem] rounded-normal  bg-[var(--colour-34)] pt-[.04rem] px-[.32rem] pb-[.32rem]">
        <div className={`flex flex-row justify-between items-center mt-[.3rem] mb-[.2rem] mr-[.3rem]`}>
          <div className="text-[.32rem] font-[700]  text-[var(--colour-36)]">
            {t('deposit-withdraw.deposit_amount')}
          </div>
          <div
            style={{ color: '#fff' }}
            className="w-[.72rem] h-[.72rem] flex justify-center items-center rounded-[.08rem] "
          >
            <Close onClick={handleClose} />
          </div>
        </div>
        <div className="w-full h-[.02rem] mt-[.28rem] bg-[var(--colour-6)]"></div>
        <div className="flex items-center flex-col	mt-[.4rem]">
          <div className="text-[.36rem] font-[700] text-[var(--colour-38)]">
            {currency_symbol || ''} {amount}
          </div>
          {withBonus && bonus && Number(amount) >= Number(getShowMoneyNumber(bonusConfig.deposit_min)) && (
            <div
              className={cn('h-[.32rem] font-[600] text-[.22rem]  w-[100%] text-[var(--colour-44)] text-center', {})}
            >
              {Number(amount) >= Number(getShowMoneyNumber(bonusConfig.deposit_min)) ? (
                <>
                  (+ {currency_symbol || ''} {}
                  {(Number(amount) * bonusConfig.bonus_ratio).toFixed(0) <
                  Number(getShowMoneyNumber(bonusConfig.bonus_max))
                    ? Number(cutDecimal(Number(amount) * bonusConfig.bonus_ratio))
                    : Number(cutDecimal(Number(getShowMoneyNumber(bonusConfig.bonus_max))))}
                  )
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center justify-between gap-15">
          <LimitClickWrapper
            className="mt-[.38rem] flex-1 mb-[.4rem] active:scale-[.95]  transition-all select-none  flex  px-[.5rem] h-[.92rem] items-center justify-center cursor-auto rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)]"
            onClick={handleClose}
          >
            <ElementWithLoading
              normalEl={
                <div className="font-[700] justify-center text-[.28rem] flex items-center h-[.92rem]">
                  {t('account.cancel')}
                </div>
              }
            />
          </LimitClickWrapper>
          <LimitClickWrapper
            className="mt-[.38rem] flex-1 mb-[.4rem] active:scale-[.95]  transition-all select-none  flex  px-[.5rem] h-[.92rem] items-center justify-center cursor-auto rounded-normal border border-1 border-[var(--colour-1)] text-center bg-[var(--colour-5)] text-[var(--colour-3)]"
            onClick={() => {
              window.open(rechargeUrl);
            }}
          >
            <ElementWithLoading
              normalEl={
                <div className="font-[700] justify-center text-[.28rem] flex items-center h-[.92rem] text-center w-full">
                  {t('account.confirm')}
                </div>
              }
            />
          </LimitClickWrapper>
        </div>

        {depositTips && depositTips.title ? (
          <div className="text-left text-secondary-content">
            <div className="font-[700] text-[var(--colour-36)]">{t('deposit-withdraw.deposit_notes')}</div>
            <div
              className="font-[500] text-[.24rem]"
              dangerouslySetInnerHTML={{ __html: depositTips.title || '' }}
            ></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
