import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import {
  getUserPhoto,
  omitStr,
  isLogin,
  getTemplateId,
  getShowMoney,
  formatCpf,
  getThemeUserPhoto,
} from '../../../../utils/helper';
import DialogModal from '../../../../components/dialogModal';
import UserInfoDialog from '../../../../components/dialogModal/userinfoDialog';
import Icon, { IconName } from '../../../../components/icon';
import BindPassword from '../../../../components/dialogModal/bindPassword';
import HeaderBack from '../../../../components/headerBack';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import Binding from '../../components/binding';
import Badge from '../../../mine/normal/template001/components/badge';
import BindEmail from '../../../../components/dialogModal/bindEmail';
import { GetProfileData } from '../../../../api';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { userObj, websiteInitConfig } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const [isOpenHead, setIsOpenHead] = useState(false);
  const [showBindPhone, setShowBindPhone] = useState(false); //绑定手机
  const [showBindPopup, setShowBindPopup] = useState(false); //绑定name & cpf
  const [showBindEmail, setShowBindEmail] = useState(false); //邮箱绑定
  const [bindData, setBindData] = useState(null); //手机、name、cpf、邮箱的绑定信息

  useEffect(() => {
    const popupKey = localStorage.getItem('data_show_popup');
    if (popupKey == 'phone') {
      setShowBindPhone(true);
    } else if (popupKey == 'cpf') {
      setShowBindPopup(true);
    } else if (popupKey == 'email') {
      setShowBindEmail(true);
    }
    return () => {
      localStorage.removeItem('data_show_popup');
    };
  }, []);

  const upDataList = () => {
    GetProfileData().then((res) => {
      if (res.code == 0) {
        setBindData(res.data);
      }
    });
  };

  useEffect(() => {
    upDataList();
  }, []);

  const clickPhoneCondition = () => {
    return bindData?.phone?.status == 10;
  };

  const clickCpfCondition = () => {
    return bindData?.cpf?.status == 10 && !bindData?.cpf?.bind_value;
  };
  const clickEmailCondition = () => {
    return bindData?.email?.status == 10;
  };

  return (
    <div className="!px-[0px] container bg-[var(--colour-34)] h-full min-h-[var(--app-height)]">
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsOpenHead(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={isOpenHead}
      >
        <UserInfoDialog
          handleClose={() => {
            setIsOpenHead(false);
          }}
          isShowNickname={false}
        />
      </DialogModal>

      {/* 绑定手机 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowBindPhone(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showBindPhone}
      >
        <BindPassword
          handleClose={() => {
            setShowBindPhone(false);
          }}
          bindSuccessCallback={() => {
            upDataList();
          }}
        />
      </DialogModal>

      {/* 绑定 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowBindPopup(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showBindPopup}
      >
        <Binding
          handleClose={() => {
            setShowBindPopup(false);
          }}
          bindSuccessCallback={() => {
            upDataList();
          }}
        />
      </DialogModal>

      {/* 绑定邮箱  */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowBindEmail(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showBindEmail}
      >
        <BindEmail
          handleClose={() => {
            setShowBindEmail(false);
          }}
          bindSuccessCallback={() => {
            upDataList();
          }}
        />
      </DialogModal>

      <HeaderBack
        title={t('account.data')}
        onClick={() => navigate(toLocalePath('/mine'))}
        className={`${getTemplateId() == 'd-template003' ? '!bg-[--colour-86]' : ''}`}
      />

      <div className="p-[.24rem]">
        <div className="flex items-center mb-[.44rem]">
          <div
            className="w-[1.08rem] h-[1.08rem] overflow-hidden  relative cursor-pointer mr-[.08rem]"
            onClick={() => {
              setIsOpenHead(true);
            }}
          >
            {getTemplateId() == 'd-template003' ? (
              <img
                src={getThemeUserPhoto(userObj?.icon || 1)}
                alt=""
                className={cn({
                  'rounded-[.16rem]': ['d-template001'].includes(getTemplateId()),
                })}
              />
            ) : (
              <img
                src={getUserPhoto(userObj?.icon || 1)}
                alt=""
                className={cn({
                  'rounded-[.16rem]': ['d-template001'].includes(getTemplateId()),
                })}
              />
            )}
            <div className="absolute right-[0px] bottom-[0px] rounded-[.12rem_0rem_.12rem_0rem] h-[.32rem] px-[.06rem] bg-[var(--colour-59)]">
              <div className="leading-[.32rem] text-[.2rem] font-[700] italic text-[var(--colour-58)] whitespace-nowrap">
                VIP {userObj ? userObj.vip_level : '0'}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-[.24rem] font-[500] text-[var(--colour-41)] leading-[.3rem] mb-[.12rem]">
              {userObj ? userObj.nickname : ''}
            </div>
            <div className="text-[.24rem] font-[500] leading-[.34rem] flex items-center">
              <div className="text-[var(--colour-41)]">ID:</div>
              <div className="text-[var(--colour-40)]">{userObj?.uid ? omitStr(userObj.uid, 17) : '--'}</div>
              <div
                onClick={() => {
                  copy(`${userObj?.uid || ''}`);
                  toast.success(t('home.toast006'), {
                    containerId: 'global',
                  });
                }}
                className="cursor-pointer ml-[.08rem] flex items-center justify-center"
              >
                <span className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]">
                  <Icon name={IconName.Copy4}></Icon>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* name */}
        <div className="bg-[var(--colour-35)] w-full h-[.72rem] rounded-[.12rem] border border-solid border-[var(--colour-6)] flex items-center px-[.22rem] mb-[.5rem]">
          <div className="w-[.24rem] h-[.24rem] text-[var(--colour-37)] mr-[.24rem]">
            <Icon name={IconName.Useraccount} />
          </div>
          <div className="text-[var(--colour-38)] text-[.2rem] font-[500]">{userObj ? userObj.username : ''}</div>
        </div>

        {/* phone */}
        <div
          className="bg-[var(--colour-35)] w-full h-[.72rem] rounded-[.12rem] border border-solid border-[var(--colour-6)] flex items-center justify-between px-[.22rem] mb-[.5rem]"
          onClick={() => {
            if (clickPhoneCondition()) {
              setShowBindPhone(true);
            }
          }}
        >
          <div className="flex items-center">
            <div className="w-[.24rem] h-[.24rem] text-[var(--colour-37)] mr-[.24rem]">
              <Icon name={IconName.Phone3} />
            </div>
            <div className="text-[var(--colour-38)] text-[.2rem] font-[500]">
              {bindData?.phone?.bind_value
                ? '+' +
                  String(
                    bindData?.phone?.bind_value.slice(
                      0,
                      websiteInitConfig?.website_info?.country_code
                        ? websiteInitConfig?.website_info?.country_code?.length
                        : 0,
                    ),
                  ) +
                  '-' +
                  String(
                    bindData?.phone?.bind_value.slice(
                      websiteInitConfig?.website_info?.country_code
                        ? websiteInitConfig?.website_info?.country_code?.length
                        : 0,
                      bindData?.phone?.bind_value.length,
                    ),
                  )
                : ''}
            </div>
          </div>

          <UnboundBtn
            condition={clickPhoneCondition()}
            value={bindData?.phone?.bind_value}
            showBadge={bindData?.phone?.show_tips == 1}
            num={bindData?.phone?.reward_amount}
          />
        </div>

        {/* name & cpf */}
        <div
          className="bg-[var(--colour-35)] w-full h-[.72rem] rounded-[.12rem] border border-solid border-[var(--colour-6)] flex items-center justify-between px-[.22rem] mb-[.5rem]"
          onClick={() => {
            if (clickCpfCondition()) {
              setShowBindPopup(true);
            }
          }}
        >
          <div className="flex items-center">
            <div className="w-[.24rem] h-[.24rem] text-[var(--colour-37)] mr-[.24rem]">
              <Icon name={IconName.Cpf2} />
            </div>
            <div className="text-[var(--colour-38)] text-[.2rem] font-[500] leading-[.28rem]">
              {bindData?.cpf?.name ? (
                <div>
                  {t('home.name')}: {bindData?.cpf?.name}
                </div>
              ) : null}
              {bindData?.cpf?.bind_value ? (
                <div>
                  {t('home.cpf')}: {formatCpf(bindData?.cpf?.bind_value)}
                </div>
              ) : null}
            </div>
          </div>
          <UnboundBtn
            condition={clickCpfCondition()}
            value={bindData?.cpf?.bind_value}
            showBadge={bindData?.cpf?.show_tips == 1}
            num={bindData?.cpf?.reward_amount}
          />
        </div>

        {/* email */}
        <div
          className="bg-[var(--colour-35)] w-full h-[.72rem] rounded-[.12rem] border border-solid border-[var(--colour-6)] flex items-center justify-between px-[.22rem] mb-[.5rem]"
          onClick={() => {
            if (clickEmailCondition()) {
              setShowBindEmail(true);
            }
          }}
        >
          <div className="flex items-center">
            <div className="w-[.24rem] h-[.24rem] text-[var(--colour-37)] mr-[.24rem]">
              <Icon name={IconName.Vector} />
            </div>
            <div className="text-[var(--colour-38)] text-[.2rem] font-[500]">{bindData?.email?.bind_value || ''}</div>
          </div>
          <UnboundBtn
            condition={clickEmailCondition()}
            value={bindData?.email?.bind_value}
            showBadge={bindData?.email?.show_tips == 1}
            num={bindData?.email?.reward_amount}
          />
        </div>
      </div>
    </div>
  );
};
export default NTemplate001;

const UnboundBtn = ({ condition = false, value = '', showBadge = false, num = 0 }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center relative">
      {condition && value ? (
        <>
          <span className="text-[var(--colour-1)] text-[.2rem] font-[500]">{t('account.unverified')}</span>
          <div className="w-[.12rem] h-[.22rem] text-[var(--colour-38)] ml-[.16rem]">
            <Icon name={IconName.RightArrow3} />
          </div>
        </>
      ) : condition ? (
        <>
          <span className="text-[var(--colour-1)] text-[.2rem] font-[500]">{t('account.unbound')}</span>
          <div className="w-[.12rem] h-[.22rem] text-[var(--colour-38)] ml-[.16rem]">
            <Icon name={IconName.RightArrow3} />
          </div>
        </>
      ) : null}

      {showBadge ? (
        <Badge
          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
          className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.34rem] !right-[-0.26rem] flex items-center"
          text={getShowMoney(num || 0)}
        ></Badge>
      ) : null}
    </div>
  );
};
