import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import DatetimePickerBox from '../../../../../components/datetimePicker';
import DrawerStatistics from '../../../../../components/drawerStatistics';
import Input from '../../../../../components/input';
import Icon, { IconName } from '../../../../../components/icon';
import { GetRoundDetail } from '../../../../../api';
import { getDateFormatStr5, getShowMoney, setInputValue, isLogin } from '../../../../../utils/helper';
import { List } from 'react-vant';
import Loading3 from '../../../../../components/loading3';
import NoData from '../../../../../components/noData';
import DirectBettingDetails from '../../components/directBettingDetails';
import BetDetails from '../../components/betDetails';
import ScrollbarContent from '../../../../../components/scrollbarContent';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const [detailsVisible, setDetailsVisible] = useState(false); //详情弹窗
  const [subordinatesVisible, setSubordinatesVisible] = useState(false); //下属详情弹窗

  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    startDate: new Date(),
    endDate: new Date(),
    inputVal: '',
  });
  const [list, setList] = useState([]);
  const [totalInfo, setTotalInfo] = useState(null);
  const [finished, setFinished] = useState(false);
  const [id, setId] = useState('');
  const [paramStartDate, setParamStartDate] = useState('');
  const [paramEndDate, setParamEndDate] = useState('');

  const getData = (page) => {
    if (!isLogin()) return;
    setLoading(true);
    const newParams = {
      page: page ? page : params.page,
      page_size: params.pageSize,
      start_date: getDateFormatStr5(params.startDate.getTime()),
      end_date: getDateFormatStr5(params.endDate.getTime()),
      uid: params.inputVal,
    };
    GetRoundDetail(newParams)
      .then((res) => {
        if (res.code == 0) {
          setList(res.data.list);
          setTotalInfo(res.data.summary);
          setParamStartDate(res.data.start_date);
          setParamEndDate(res.data.end_date);
          if (res.data.list.length < params.pageSize) {
            setFinished(true);
          } else {
            setFinished(false);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const loadData = async () => {
    if (!isLogin()) return;
    try {
      setParams({ ...params, page: params.page + 1 });
      const currentPage = params.page + 1;
      const newParams = {
        page: currentPage,
        page_size: params.pageSize,
        start_date: getDateFormatStr5(params.startDate.getTime()),
        end_date: getDateFormatStr5(params.endDate.getTime()),
        uid: params.inputVal,
      };
      let res = await GetRoundDetail(newParams);
      if (res.code == 0) {
        setTotalInfo(res.data.summary);
        setParamStartDate(res.data.start_date);
        setParamEndDate(res.data.end_date);
        if (res.data && res.data.list && res.data.list.length > 0) {
          setList([...list, ...res.data.list]);
        }

        if (res.data && res.data.list && res.data.list.length < params.pageSize) {
          setFinished(true);
        } else {
          setFinished(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleStartChange = (val) => {
    setParams({ ...params, startDate: val });
  };

  const handleEndChange = (val) => {
    setParams({ ...params, endDate: val });
  };

  const handleClick = (uid) => {
    setId(uid);
    setDetailsVisible(true);
  };

  const handleClickSubordinados = (uid) => {
    setId(uid);
    setSubordinatesVisible(true);
  };

  const handleClickModel = (uid) => {
    setSubordinatesVisible(false);
    setId(uid);
    setDetailsVisible(true);
  };
  const handleClickModelSubordinados = (uid) => {
    setSubordinatesVisible(false);
    setId(uid);
    setSubordinatesVisible(true);
  };

  return (
    <div className="p-[.2rem_.08rem_.7rem_.08rem] bg-[var(--colour-68)] h-full flex flex-col">
      <div className="flex items-center mb-[.2rem]">
        <DatetimePickerBox
          startDate={params.startDate}
          endDate={params.endDate}
          rangeCheckClassName="!w-[3.6rem] bg-[var(--colour-5)] mr-[.2rem]"
          onChangeStartDate={handleStartChange}
          onChangeEndDate={handleEndChange}
          onSubmit={() => {
            setParams({ ...params, page: 1 });
            getData(1);
          }}
        />

        <div className="flex-1">
          <Input
            value={params.inputVal}
            onChange={(e) => {
              let val = setInputValue(e.target.value);
              setParams({ ...params, inputVal: val });
            }}
            type="text"
            placeholder={t('refer.myId')}
            innerClassName="!h-[.46rem] !border-transparent !pl-[.22rem] !pr-[.24rem]"
            topClassName={`rounded-[19.98rem] overflow-hidden bg-[var(--colour-5)]`}
            inputClassName="m-input"
            rightInner={
              loading ? (
                <div className="w-[.24rem] h-[.24rem] text-[var(--colour-13)] search-loading">
                  <Icon name={IconName.SearchLoading} />
                </div>
              ) : (
                <div
                  className="w-[.24rem] h-[.24rem] text-[var(--colour-13)] cursor-pointer"
                  onClick={() => {
                    setParams({ ...params, page: 1 });
                    getData(1);
                  }}
                >
                  <Icon name={IconName.TagsSearch} />
                </div>
              )
            }
            clearIconClassName="!w-[.24rem] !h-[.24rem]"
          />
        </div>
      </div>
      <div className="flex-1 overflow-y-auto scrollbar-none">
        {loading ? (
          <Loading3 className1="w-full h-full flex items-center justify-center"></Loading3>
        ) : (
          <>
            {list && list.length > 0 ? (
              <List finished={finished} onLoad={loadData} offset={0} loadingText={<Loading3 className1="w-full" />}>
                {list.map((item, index) => {
                  return (
                    <BetsItem
                      key={index}
                      showBg={index % 2 == 0}
                      data={item}
                      handleClick={handleClick}
                      handleClickSubordinados={handleClickSubordinados}
                    />
                  );
                })}
              </List>
            ) : (
              <NoData className="w-full h-full" />
            )}
          </>
        )}
      </div>

      <DirectBettingDetails
        setShowVerify={setSubordinatesVisible}
        showVerify={subordinatesVisible}
        id={id}
        paramStartDate={paramStartDate}
        paramEndDate={paramEndDate}
        handleClickModel={handleClickModel}
        handleClickModelSubordinados={handleClickModelSubordinados}
      />

      <BetDetails setShowVerify={setDetailsVisible} id={id} showVerify={detailsVisible} paramEndDate={paramEndDate} />
      {list && list.length > 0 ? (
        <DrawerStatistics>
          <div className="p-[.14rem_.74rem] text-[.2rem] font-[500] leading-[.24rem]">
            <div className="flex items-center mb-[.18rem]">
              <div className="flex items-center w-[3.4rem] shrink-0">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('refer.direct_betting_aposta_valida_direta')}
                </div>
                <div className="text-[var(--colour-38)]">{getShowMoney(totalInfo?.direct_wager_amount || 0, 2, 2)}</div>
              </div>
              <div className="flex items-center">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('refer.direct_betting_V/D_diretas')}
                </div>
                {Number(totalInfo?.direct_user_win_amount) > 0 ? (
                  <div className="text-[var(--colour-25)]">
                    +{getShowMoney(totalInfo?.direct_user_win_amount || 0, 2, 2)}
                  </div>
                ) : (
                  <div className="text-[var(--colour-24)]">
                    {getShowMoney(totalInfo?.direct_user_win_amount || 0, 2, 2)}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center mb-[.14rem]">
              <div className="flex items-center w-[3.4rem] shrink-0">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('refer.direct_betting_outras_apostas_eficazes')}
                </div>
                <div className="text-[var(--colour-38)]">
                  {getShowMoney(totalInfo?.indirect_wager_amount || 0, 2, 2)}
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('refer.direct_betting_outras_V/D')}
                </div>
                {totalInfo?.indirect_user_win_amount > 0 ? (
                  <div className="text-[var(--colour-25)]">
                    +{getShowMoney(totalInfo?.indirect_user_win_amount || 0, 2, 2)}
                  </div>
                ) : (
                  <div className="text-[var(--colour-24)]">
                    {getShowMoney(totalInfo?.indirect_user_win_amount || 0, 2, 2)}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center w-[3.4rem] shrink-0">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('refer.direct_betting_total_de_apostas_validas')}
                </div>
                <div className="text-[var(--colour-38)]">{getShowMoney(totalInfo?.total_wager_amount || 0, 2, 2)}</div>
              </div>
              <div className="flex items-center">
                <div className="w-[1.8rem] shrink-0 text-[var(--colour-44)] mr-[.14rem]">
                  {t('refer.direct_betting_total_de_V/D')}
                </div>
                {Number(totalInfo?.total_user_win_amount) > 0 ? (
                  <div className="text-[var(--colour-25)]">
                    +{getShowMoney(totalInfo?.total_user_win_amount || 0, 2, 2)}
                  </div>
                ) : (
                  <div className="text-[var(--colour-24)]">
                    {getShowMoney(totalInfo?.total_user_win_amount || 0, 2, 2)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </DrawerStatistics>
      ) : null}
    </div>
  );
};

export default NTemplate001;

const BetsItem = ({ showBg = false, data, handleClickSubordinados = () => {}, handleClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'rounded-normal p-[.18rem_.28rem_.24rem_.66rem] text-[.2rem] font-[500] leading-[.24rem] relative',
        {
          'bg-[var(--colour-69)] shadow-[0_.04rem_.04rem_0_rgba(0,0,0,0.02)]': showBg,
        },
      )}
    >
      <div className="flex items-center mb-[.08rem]">
        <div className="flex items-center w-[3rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-38)] mr-[.2rem]">{data?.uid || ''}</div>
          <div
            className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]"
            onClick={() => {
              copy(`${data?.uid || ''}`);
              toast.success(t('home.toast006'), {
                containerId: 'global',
              });
            }}
          >
            <Icon name={IconName.Copy4} />
          </div>
        </div>
        <div
          className="flex items-center flex-1"
          onClick={() => {
            if (Number(data?.direct_member_num) > 0) {
              handleClickSubordinados(data.uid);
            }
          }}
        >
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_data_subordinados_dele')}</div>
          {Number(data?.direct_member_num) > 0 ? (
            <div className="text-[var(--colour-1)] underline cursor-pointer">{data?.direct_member_num || 0}</div>
          ) : (
            <div className="text-[var(--colour-38)]">-</div>
          )}
        </div>
      </div>
      <div className="flex items-center mb-[.1rem]">
        <div className="flex items-center w-[3rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-44)] mr-[.12rem]">{t('refer.direct_data_apostas_validas')}</div>
          {Number(data?.wager_amount) > 0 ? (
            <div
              className="text-[var(--colour-1)] underline cursor-pointer"
              onClick={() => {
                handleClick(data.uid);
              }}
            >
              {getShowMoney(data?.wager_amount || 0, 2, 2)}
            </div>
          ) : (
            <div className="text-[var(--colour-38)]">{getShowMoney(data?.wager_amount || 0, 2, 2)}</div>
          )}
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_betting_total_de_V/D')}</div>
          {Number(data?.user_win_amount) > 0 ? (
            <div className="text-[var(--colour-25)]">+{getShowMoney(data?.user_win_amount || 0, 2, 2)}</div>
          ) : (
            <div className="text-[var(--colour-24)]">{getShowMoney(data?.user_win_amount || 0, 2, 2)}</div>
          )}
        </div>
      </div>
      <div className="text-[var(--colour-38)]">{t('refer.direct_betting_vezes', { val: data?.bet_num || 0 })}</div>
      <div className="absolute top-[0rem] left-[0rem] bg-[var(--colour-25)] rounded-[.04rem_0] text-[.16rem] font-[600] leading-[.2rem] text-[var(--colour-2)] px-[.08rem]">
        V{data?.vip_level || 0}
      </div>
    </div>
  );
};
