import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../icon';

export default function ErrorTip({ text = '' }) {
  const { t } = useTranslation();
  return (
    <div className={`flex items-center text-[var(--colour-25)] h-[.4rem] absolute bottom-[-0.52rem]`}>
      <span className="w-[.2rem] h-[.2rem] inline-block mr-[.04rem] shrink-0">
        <Icon name={IconName.ErrorTip} />
      </span>
      <div className="text-[.2rem] font-[500] leading-[.24rem]">
        {text ? text : t('deposit-withdraw-wg.withdraw_password_numbers')}
      </div>
    </div>
  );
}
