import Invite from '../../../../../invite';
import PopBg from '../../../../components/popBg';

const InviteFriend = ({ handleClose = () => {}, isShowCloseBtn = true }) => {
  return (
    <>
      <PopBg handleClose={handleClose} isShowCloseBtn={isShowCloseBtn}>
        <Invite boxClassname="!border-0 !bg-[transparent] !m-[0] !text-[.28rem]" />
      </PopBg>
    </>
  );
};

export default InviteFriend;
