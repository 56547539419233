import cn from 'classnames';
import BaseTemplate from '../../baseTemplate';
import DrawerMenu from '../../../menu/drawerMenu/normal/template001';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Footer from '../../../../components/footer';
import ScrollbarContent from '../../../scrollbarContent';

export default function NTemplate001({ children }) {
  const { drawerMenuVisible, showHeaderAndFooter, showMobileMenuBar } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      showHeaderAndFooter: state.app.showHeaderAndFooter,
      showMobileMenuBar: state.app.showMobileMenuBar,
    }),
    shallowEqual,
  );
  const { pathname } = useLocation();
  const [drawerLeftMenuVisible, setDrawerLeftMenuVisible] = useState(false); //侧导航是否显示 ，默认关闭

  return (
    <BaseTemplate>
      <DrawerMenu drawerLeftMenuVisible={drawerLeftMenuVisible} />
      <main className="h-[100%]">
        {children}
        {showHeaderAndFooter ? <Footer /> : null}
      </main>
    </BaseTemplate>
  );
}
