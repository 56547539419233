import { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import content_en from '../../../../html/toc-en-2.html?raw';
import content_pt from '../../../../html/toc-pt-2.html?raw';
import HtmlDownload from '../../../../components/htmlDownload';

const NTemplate001 = () => {
  const { defaultLang } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const [termscontent, settermscontent] = useState('');

  useEffect(() => {
    switch (defaultLang) {
      // case 'ja':
      //   settermscontent(content_ja);
      //   break;
      // case 'vi':
      //   settermscontent(content_vi);
      //   break;
      case 'pt':
        settermscontent(content_pt);
        break;
      default:
        settermscontent(content_en);
        break;
    }
  }, [defaultLang]);

  return (
    <div className="container px-[.32rem]">
      <HtmlDownload htmlString={termscontent} />
    </div>
  );
};

export default NTemplate001;
