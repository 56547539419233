import { useSelector, shallowEqual } from 'react-redux';
import cn from 'classnames';
import { cmsImgUrl, getShowMoney, isLogin } from '../../utils/helper';
import { get } from 'lodash';
import PrizePool from './prizePool';
import TournamentCountdown from './tournamentCountdown';
import JoinBtn from './joinBtn';
import MoreBtn from './moreBtn';
import Board from './board';
import { useNavigate } from 'react-router-dom';
import HomeModuleHeader from '../homeModuleHeader';
import { toLocalePath } from '../../utils/localePath';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { GetWebsiteTournament } from '../../api';
import { useGlobalNavigate } from '../../contexts/navigateContext';

function Content({ tourCardData, updateList }) {
  const navigate = useGlobalNavigate();

  const { uid } = useSelector(
    (state) => ({
      uid: get(state, 'user.userObj.uid'),
    }),
    shallowEqual,
  );

  const [boardList, setBoardList] = useState([]);

  useEffect(() => {
    const userRankList = tourCardData?.ranking || [];
    const prizeConfigList = tourCardData?.activity?.activity_rewards || [];

    if (prizeConfigList.length > 0) {
      const lastRankConfig = prizeConfigList.reduce((pre, cur) => {
        return Number(pre.end_tap_position) > Number(cur.end_tap_position) ? pre : cur;
      });

      const lastRank = Number(lastRankConfig.end_tap_position);

      const list = Array(lastRank)
        .slice(0, 10)
        .fill()
        .map((_, index) => {
          const rank = index + 1;
          const user = userRankList.find((item) => Number(item?.ranking_num) == rank);
          const prize = prizeConfigList.find(
            (item) => rank >= Number(item?.tap_position) && rank <= Number(item?.end_tap_position),
          );

          return {
            rank,
            icon: user?.icon,
            nickname: user?.nickname,
            prize: prize?.balance,
            isMe: isLogin() && user?.uid == uid,
          };
        });

      setBoardList(list);
    }
  }, [tourCardData]);

  const onClickMore = () => {
    navigate(toLocalePath('/tournament'));
  };

  return (
    <div className={cn('rounded-normal overflow-hidden', {})}>
      <PrizePool
        money={getShowMoney(tourCardData?.activity.activity_claim_ceiling_amount || 0)}
        bgImgUrl={cmsImgUrl(get(tourCardData, 'activity.rule_explain.activity_img'))}
        onClick={onClickMore}
      />
      <div className="bg-[var(--colour-35)] p-[.28rem]">
        <div className="">
          <Board list={boardList} />
        </div>
        <div className="mt-[.26rem]">
          <TournamentCountdown
            date={
              get(tourCardData, 'activity.activity_end_time')
                ? get(tourCardData, 'activity.activity_end_time') * 1000
                : Date.now()
            }
            onComplete={updateList}
          />
        </div>
        <div className="h-full flex items-center justify-center space-x-[.2rem] mt-[.26rem]">
          <JoinBtn onClick={onClickMore} />
          <MoreBtn onClick={onClickMore} />
        </div>
      </div>
    </div>
  );
}

const Index = ({ titleImgPath, title, className = '' }) => {
  const navigate = useGlobalNavigate();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const [tourCardData, setTourCardData] = useState([]);

  const handleViewAll = () => {
    navigate(toLocalePath('/tournament'));
  };

  const updateList = () => {
    GetWebsiteTournament().then((res) => {
      if (res.code == 0) {
        const data = res.result.tournament;
        setTourCardData(data);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  return tourCardData.length > 0 ? (
    <div className={cn(`overflow-hidden ${className}`)}>
      <HomeModuleHeader
        className=""
        img={cmsImgUrl(titleImgPath)}
        title={title}
        isViewAll={true}
        handleViewAll={handleViewAll}
      />
      <div>
        {tourCardData.length > 1 ? (
          <>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              speed={1000}
              loop={true}
              modules={[Autoplay]}
            >
              {tourCardData.map((data, index) => (
                <SwiperSlide key={index}>
                  <Content tourCardData={data} updateList={updateList} key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : tourCardData.length == 1 ? (
          <>
            <Content tourCardData={tourCardData[0]} updateList={updateList} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : null;
};

export default Index;
