import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Icon, { IconName } from '../../components/icon';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

const Icons = ({}) => {
  const dispatch = useDispatch();
  let icons = [];
  for (let icon in IconName) {
    icons.push(icon);
  }
  const onCopyIconName = (item) => {
    copy(`IconName.${item}`);
    toast.success(`IconName: ${item}`, {
      containerId: 'global',
    });
  };
  return (
    <div className="flex flex-wrap">
      {icons.map((item, index) => (
        <div className="flex flex-col items-center justify-center m-[0.2rem] space-y-[0.1rem]">
          <span
            key={index}
            className="w-[0.5rem] h-[0.5rem] block float-left cursor-pointer"
            onClick={() => {
              onCopyIconName(item);
            }}
          >
            <Icon name={item} />
          </span>
          <span>{item}</span>
        </div>
      ))}
    </div>
  );
};

export default Icons;
