import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { t } from 'i18next';
import cn from 'classnames';
import { get, cloneDeep } from 'lodash';
import BigNumber from 'bignumber.js';
import Icon, { IconName } from '../../../../../components/icon';
import { GetPromotionRakebackConfig, PromotionRakebackClaim } from '../../../../../api';
import Loading3 from '../../../../../components/loading3';
import {
  getShowMoney,
  commaToPoint,
  isLogin,
  isControllableSite,
  getConfigTimeZoneTimestamp,
} from '../../../../../utils/helper';
import usePromotionClaimedCount from '../../../../../hooks/usePromotionClaimedCount';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setNeedUpdateBalance,
} from '../../../../../redux/reducers/appSlice';
import NoData from '../../../../../components/noData';
import CountdownBox from '../../../../../components/countdownBox';

const NTemplate001 = () => {
  const dispatch = useDispatch();
  const { onScroll } = useOutletContext();
  const { InitPromotionClaimedCount } = usePromotionClaimedCount();
  const { websiteInfo, websiteInitConfig, currency_code, isSportEnabled, userObj, currency_offset } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      websiteInitConfig: state.app.websiteInitConfig,
      currency_code: state?.app?.websiteInitConfig?.website_info?.currency_code,
      isSportEnabled: get(state, 'app.isSportEnabled'),
      userObj: get(state, 'user.userObj'),
      currency_offset: state?.app?.websiteInitConfig?.system_config?.currency_offset,
    }),
    shallowEqual,
  );

  const [loading, setLoading] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [data, setData] = useState([]);
  const [vaildWager, setVaildWager] = useState(0); //vaild Wager
  const [currentLevel, setCurrentLevel] = useState(); //列表显示wager反水的情况下，记录当前所在的档位
  const [available, setAvailable] = useState(0); //列表的Available显示
  const [totalTomorrowNum, setTotalTomorrowNum] = useState(0); //明天可领

  useEffect(() => {
    if (websiteInitConfig) {
      let gameTypeList = websiteInitConfig.game_type;
      if (gameTypeList && gameTypeList.length > 0) {
        let list = [];
        if (currency_code == 'PHP') {
          list = gameTypeList;
        } else {
          const filteredArr = gameTypeList.filter((item) => item.id != 6);
          list = filteredArr;
        }

        if (!isSportEnabled) {
          list = list.filter((item) => item.id != 999);
        }

        if (list && list.length > 0) {
          setTypeList(list);
          setCurrentId(list[0]['id']);
        }
      }
    }
  }, [websiteInitConfig, currency_code, isSportEnabled]);

  const upData = (typeId) => {
    setLoading(true);
    GetPromotionRakebackConfig({ game_type: `${typeId}` })
      .then((res) => {
        if (res.code == 0) {
          let data = cloneDeep(res.data);
          let config = data.config.map((item, index) => {
            return { ...item, id: index };
          });
          data = { ...data, config: config };
          setData(data);

          //筛选当前type类型的list数据
          let currentListItem = data.list.find((item) => {
            return item.game_type == typeId;
          });
          if (currentListItem) {
            setVaildWager(currentListItem.wager);
          } else {
            setVaildWager(0);
          }

          let sortConfig = cloneDeep(data.config);
          sortConfig = sortConfig.sort((a, b) => b.wager - a.wager);

          let currentConfig = null;
          if (data.rakeback_type == 1) {
            currentConfig = data.config.find((item) => {
              return item.vip_level == userObj?.vip_level;
            });
          } else {
            currentConfig = sortConfig.find((item) => {
              return currentListItem?.wager >= item.wager;
            });
            setCurrentLevel(currentConfig?.id);
          }

          if (currentConfig && currentListItem) {
            setAvailable(
              BigNumber(currentListItem.wager)
                .times(BigNumber(currentConfig?.ratio[currentListItem.game_type]).div(10000).toFixed())
                .toFixed(),
            );
          } else {
            setAvailable(0);
          }

          let tomorrowNum = 0;
          let itemConfig = null;
          data.list.forEach((listItem) => {
            if (data.rakeback_type == 1) {
              //显示vip
              itemConfig = data.config.find((item) => {
                return item.vip_level == userObj?.vip_level;
              });
            } else {
              //显示wager
              itemConfig = sortConfig.find((item) => {
                return listItem?.wager >= item.wager;
              });
            }

            if (itemConfig) {
              //有当前所处的档位
              tomorrowNum = BigNumber(tomorrowNum)
                .plus(
                  BigNumber(listItem.wager)
                    .times(BigNumber(itemConfig?.ratio[listItem.game_type]).div(10000).toFixed())
                    .toFixed(),
                )
                .toFixed();
            }
          });

          setTotalTomorrowNum(tomorrowNum);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickReceive = () => {
    PromotionRakebackClaim().then((res) => {
      if (res.code == 0) {
        upData(currentId);
        InitPromotionClaimedCount();
        if (res?.data?.amount) {
          //领取成功弹窗
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.BonusBox,
              content: { showAmount: getShowMoney(res?.data?.amount || 0) },
            }),
          );
          //更新余额
          dispatch(setNeedUpdateBalance({ need: true }));
        }
      }
    });
  };

  useEffect(() => {
    if (currentId) {
      upData(currentId);
    }
  }, [currentId]);

  const getIsEnough = (val, enoughNum = 0.01) => {
    const isEnough = BigNumber(val).isGreaterThanOrEqualTo(BigNumber(enoughNum).times(currency_offset));
    // console.log(val,enoughNum,'isEnough', isEnough, currency_offset);
    return isEnough;
  };

  //receive按钮是否可点击
  const receiveRequireMent = () => {
    if (data) {
      // return data?.receive_all_status == 1 && Number(commaToPoint(getShowMoney(data?.rakeback || 0))) >= 0.01;
      const isEnough = getIsEnough(data?.rakeback || 0);
      return data?.receive_all_status == 1 && isEnough;
    } else {
      return false;
    }
  };

  //是否显示倒计时
  const getIsShowCountdown = () => {
    if (data && data?.settlement_time_start) {
      return Date.now() < data?.settlement_time_start * 1000;
    } else {
      return false;
    }
  };

  //列表的ratio显示
  const showRatio = (rationConfig) => {
    for (let key in rationConfig) {
      if (key == currentId) {
        return rationConfig[key] ? rationConfig[key] / 100 : 0;
      }
    }
  };

  return (
    <div className="h-full bg-[var(--colour-68)] p-[.2rem_.2rem_.16rem_.2rem] text-[.2rem] font-[500] leading-[.24rem] flex flex-col">
      <div className="flex items-center justify-between mb-[.24rem]">
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.04rem]">{t('promotion.available_for_redemption_today')}</div>
          <div className="text-[var(--colour-38)]">
            {data?.settlement_status == 1 ? t('promotion.calculating') : getShowMoney(data?.rakeback || 0)}
          </div>
        </div>
        {Number(totalTomorrowNum) > 0 ? (
          <div className="flex items-center">
            <div className="text-[var(--colour-44)] mr-[.04rem]">{t('promotion.you_can_get_it_tomorrow')}</div>
            <div className="text-[var(--colour-26)]">{getShowMoney(totalTomorrowNum || 0)}</div>
          </div>
        ) : null}
      </div>

      <div className="flex-1 flex">
        {/* 左侧列表 */}
        <div className="flex flex-col mr-[.2rem]">
          <div className="max-h-[calc(var(--app-height)-4.7rem)] overflow-y-auto scrollbar-none">
            {typeList && typeList.length > 0
              ? typeList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={cn(
                        'w-[1.5rem] h-[1.04rem] rounded-[.16rem] pt-[.14rem] flex flex-col items-center mb-[.2rem]',
                        {
                          'bg-[var(--colour-1)]': item.id == currentId && websiteInfo.website_theme != 'theme-15',
                          'border border-[var(--colour-6)] bg-[var(--colour-75)] shadow-[0_.08rem_.08rem_0_rgba(0,0,0,0.08)]':
                            item.id != currentId && websiteInfo.website_theme != 'theme-15',
                          'bg-menu-selected': item.id == currentId && websiteInfo.website_theme == 'theme-15',
                          'bg-menu-default': item.id != currentId && websiteInfo.website_theme == 'theme-15',
                        },
                      )}
                      onClick={() => {
                        setCurrentId(item.id);
                      }}
                    >
                      <div
                        className={`w-[.48rem] h-[.48rem] mb-[.09rem] ${item.id == currentId ? 'text-[var(--colour-2)]' : 'text-[var(--colour-14)]'}`}
                      >
                        <Icon name={`ProportionCategory_${item.id}`} />
                      </div>
                      <div
                        className={`${item.id == currentId ? 'text-[var(--colour-2)] text-[.24rem] leading-[.3rem]' : 'text-[var(--colour-14)]'}`}
                      >
                        {t(`refer.proportion_category_${item.id}`)}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          <div className="pt-[.2rem] pb-[.32rem] flex flex-col items-center">
            <div
              className={`w-[1.2rem] py-[.13rem] rounded-[6px] text-[.2rem] font-[500] leading-[.24rem] text-[var(--colour-2)] flex  flex-col items-center justify-center mb-[.2rem] text-center ${receiveRequireMent() ? 'bg-[var(--colour-29)]' : 'bg-[var(--colour-42)]'}`}
              onClick={() => {
                if (receiveRequireMent()) {
                  handleClickReceive();
                }
              }}
            >
              {t('promotion.receive_all')}
              <div>
                {!receiveRequireMent() && getIsShowCountdown() ? (
                  <CountdownBox
                    date={getConfigTimeZoneTimestamp(data?.settlement_time_start * 1000 || 0).valueOf()}
                    onComplete={() => {
                      upData(currentId);
                    }}
                    renderer={({ completed, formatted }) => {
                      return !completed ? (
                        <div className="">
                          <span className="">
                            {Number(formatted.days) > 0 && <span>{formatted.days} Days </span>}
                            <span>{formatted.hours}:</span>
                            <span>{formatted.minutes}:</span>
                            <span>{formatted.seconds}</span>
                          </span>
                        </div>
                      ) : (
                        <div className="">
                          <div>00:00:00</div>
                        </div>
                      );
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div
              className={`w-[1.2rem] py-[.13rem] rounded-[6px] text-[.2rem] font-[500] leading-[.24rem] text-[var(--colour-2)] flex items-center justify-center text-center ${websiteInfo.website_theme == 'theme-15' ? 'bg-menu-selected' : 'bg-[var(--colour-1)]'}`}
              onClick={() => {
                if (isLogin()) {
                  onScroll('/promotion/history');
                } else {
                  dispatch(
                    setDialogModalVisible({
                      show: DialogModalChildrenKey.LoginBox,
                    }),
                  );
                }
              }}
            >
              {t('promotion.history')}
            </div>
          </div>
        </div>

        {/* 右侧列表 */}
        <div className="flex-1 flex flex-col">
          <div className="h-[.56rem] rounded-[.12rem] border border-[var(--colour-6)] bg-[var(--colour-69)] pl-[.34rem] flex items-center mb-[.12rem]">
            <div className="text-[var(--colour-44)] mr-[.04rem]">{t('promotion.vaild_wager')}</div>
            <div className="text-[var(--colour-38)]">{getShowMoney(vaildWager || 0)}</div>
          </div>
          <div className="flex items-center text-[var(--colour-44)] text-center mb-[.04rem]">
            <div className="w-[33.33%] shrink-0	">
              {data?.rakeback_type == 1 ? t('promotion.vip_level') : t('promotion.vaild_bet_amount')}
            </div>
            <div className="w-[33.33%] shrink-0	">{t('promotion.gear_tatio')}</div>
            <div className="w-[33.33%] shrink-0	">{t('promotion.available')}</div>
          </div>
          <div className="flexAuto overflow-y-auto scrollbar-none">
            {loading ? (
              <Loading3 className1="w-full h-full"></Loading3>
            ) : data?.config && data?.config.length > 0 ? (
              data.config.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={cn(`rounded-[.08rem] h-[.44rem] flex items-center text-center`, {
                      '!bg-[var(--colour-57)]':
                        data?.rakeback_type == 1 ? userObj?.vip_level == item.vip_level : currentLevel == item.id,
                      'bg-[var(--colour-34)]': index % 2 == 0,
                    })}
                  >
                    <div className="w-[33.33%] shrink-0	text-[var(--colour-38)] flex items-center justify-center">
                      <span className="relative">
                        {data?.rakeback_type == 1 ? `VIP ${item.vip_level}` : `>=${getShowMoney(item.wager)}`}
                        {(data?.rakeback_type == 1 ? userObj?.vip_level == item.vip_level : currentLevel == item.id) ? (
                          <div
                            className={`absolute top-[0rem] w-[.2rem] h-[.24rem] text-[var(--colour-1)] ${data?.rakeback_type == 1 ? 'right-[-0.38rem]' : 'right-[-0.28rem]'}`}
                          >
                            <Icon name={IconName.CurrentLevel} />
                          </div>
                        ) : null}
                      </span>
                    </div>

                    <div className="w-[33.33%] shrink-0	text-[var(--colour-38)]">{`${showRatio(item.ratio)}%`}</div>
                    <div className="w-[33.33%] shrink-0	text-[var(--colour-26)]">
                      {(data?.rakeback_type == 1 ? userObj?.vip_level == item.vip_level : currentLevel == item.id)
                        ? getShowMoney(available || 0)
                        : getShowMoney(0)}
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData className="h-full" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NTemplate001;
