import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Icon, { IconName } from '../../../../icon';
import LimitClickWrapper from '../../../../limitClickWrapper';
import { getShowMoney, isControllableSite, isUseLayoutMobile } from '../../../../../utils/helper';
import { setDrawerMenuVisible } from '../../../../../redux/reducers/appSlice';
import cn from 'classnames';
import headerCountryImg from '../../../../../assets/img/headerCountry.png';
import TippyPopBox, { Placement } from '../../../../tippyPopBox';
import PopChoose, { PopChooseItem } from '../../../../popChoose';
import { useState } from 'react';

export default function DHeaderWallet({ clickFreshen, balanceLoad, clickWallet, handleGoToWithdraw }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userObj, drawerMenuVisible } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      drawerMenuVisible: state.app.drawerMenuVisible,
    }),
    shallowEqual,
  );

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  return userObj ? (
    <div className={cn('flex items-center', {})}>
      <div
        className={cn(
          'min-w-[1.46rem] h-[.4rem]  border border-solid border-[var(--colour-6)] rounded-[.5rem] space-x-[.14rem] cursor-pointer text-[var(--colour-23)] pl-[.02rem] pr-[.1rem] ',
        )}
      >
        <LimitClickWrapper
          className={cn('flex items-center flex-1 justify-between h-full !flex-row')}
          onClick={clickFreshen}
        >
          <div className="w-[.32rem] h-[.32rem] mr-[.14rem]">
            {/* <img className="w-full h-full" src={headerCountryImg} /> */}
            <Icon name={IconName.HeaderCountry} />
          </div>
          <div className="h-full mr-[.04rem]">
            <div
              className={cn('font-[400] text-[.24rem] leading-[.3rem] underline', {
                'text-[#FF7A00]': isUseLayoutMobile(),
                'text-[var(--colour-23)]': !isUseLayoutMobile(),
              })}
            >
              {balanceLoad ? t('home.loading') : getShowMoney(userObj?.balance, 2, 2)}
            </div>
          </div>

          <div
            className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]"
            style={balanceLoad ? { animation: 'frashenTurn 0.5s linear infinite' } : {}}
          >
            <Icon name={IconName.Frshen} />
          </div>
        </LimitClickWrapper>
      </div>
      <LayoutMobileWallet
        handleLinkClick={handleLinkClick}
        clickWallet={clickWallet}
        handleGoToWithdraw={() => {
          handleGoToWithdraw();
        }}
      />
    </div>
  ) : (
    <></>
  );
}

function LayoutMobileWallet({ handleLinkClick, clickWallet, handleGoToWithdraw }) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [list] = useState([
    {
      id: 1,
      textKey: 'withdraw',
      click: handleGoToWithdraw,
    },
  ]);

  return (
    <TippyPopBox
      placement={Placement.bottomEnd}
      popChildren={
        <PopChoose
          className={`min-w-[2.06rem] min-h-[.76rem] px-[.2rem] bg-[var(--colour-69)] border border-[var(--colour-6)]`}
          children={list.map((item, index) => (
            <PopChooseItem
              className={`text-[.24rem] font-[500] leading-[.76rem] !text-[var(--colour-10)] text-center`}
              key={index}
              data={item}
              onClick={() => {
                item.click();
                handleLinkClick();
              }}
            >
              <span className="flex-1">{t(`deposit-withdraw.${item.textKey}`)}</span>
            </PopChooseItem>
          ))}
        />
      }
      visible={visible}
      onClickOutside={() => {
        setVisible(false);
      }}
      offset={[0, 4]}
    >
      <div className="min-w-[2.06rem] h-[.52rem] rounded-[.12rem] bg-[var(--colour-1)] ml-[.1rem] flex items-center cursor-pointer">
        <div
          className="text-[var(--colour-2)] text-[.24rem] font-[500] text-center flex-1 px-[.12rem] leading-[.52rem]"
          onClick={() => {
            clickWallet();
            handleLinkClick();
          }}
        >
          {t('home.wallet_deposit')}
        </div>

        <div
          className="flex items-center"
          onClick={() => {
            setVisible(visible ? false : true);
            handleLinkClick();
          }}
        >
          <div className="w-[.02rem] h-[.24rem] bg-[var(--colour-2)]"></div>
          <div
            className={cn('w-[.14rem] h-[.1rem] text-[var(--colour-2)] mx-[.12rem]', {
              'rotate-[180deg]': visible,
            })}
          >
            <Icon name={IconName.HeaderWalletArrow} />
          </div>
        </div>
      </div>
    </TippyPopBox>
  );
}
