import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';
import Icon, { IconName } from '../../../../../../components/icon';

//"type": "1",// 类型(1:全民代理,2:无限极差)
function ReferPicture({ type = '0' }) {
  const { t } = useTranslation();

  return type == '1' ? (
    <div className="rounded-normal p-[.32rem_.2rem_.88rem] bg-[var(--colour-69)]">
      <div className="text-[var(--colour-36)] font-[500] text-[.32rem] leading-[.38rem] mb-[.24rem] text-center">
        {t('referral.incomeModel')}
      </div>
      <div className="text-[.24rem] font-[400] leading-[.3rem] text-[var(--colour-37)]">
        <div className="text-center mb-[.28rem]">{t('refer.income_model')}</div>
        <div className="w-[.76rem] h-[.76rem] m-auto mb-[.2rem]">
          <AvatarType1 />
        </div>
        <div className="text-center mb-[.12rem]">{t('refer.you')}</div>
        <div className="flex items-center justify-center mb-[.48rem]">
          <div>{t('refer.wager_rebates')}</div>
          <div className="mx-[.4rem] flex items-center">
            <div className="w-[.24rem] h-[.4rem] mr-[.04rem]">
              <Icon name={IconName.ArrowsTop} />
            </div>
            <div className="w-[.24rem] h-[.4rem] rotate-180">
              <Icon name={IconName.ArrowsTop} />
            </div>
          </div>
          <div>{t('refer.refer_members')}</div>
        </div>
        {/* level 1 */}
        <div className="flex items-center justify-center mb-[.44rem]">
          <div className="relative">
            <AvatarType1 num={2} />
            <div className="absolute top-[.8rem] left-[-.3rem]">
              <SplitLine />
            </div>
          </div>
          <div className="w-[2.62rem] h-[.76rem] pt-[.12rem] mx-[.02rem]">
            <div className="text-center mb-[.04rem]">{t('refer.level')} 1</div>
            <DashedLine length={15} />
          </div>
          <div className="relative">
            <AvatarType1 num={2} />
            <div className="absolute top-[.8rem] left-[-.3rem]">
              <SplitLine />
            </div>
          </div>
        </div>
        {/* level 2 */}
        <div className="flex items-center justify-center mb-[.44rem]">
          <div className="flex items-center justify-center">
            <div className="relative">
              <AvatarType1 num={3} />
              <div className="absolute top-[.8rem] left-[-.1rem]">
                <SplitLine type={2} />
              </div>
            </div>
            <div className="w-[.46rem] h-[.76rem] pt-[.46rem] mx-[.07rem]">
              <DashedLine length={3} />
            </div>
            <AvatarType1 num={4} />
          </div>
          <div className="w-[1.18rem] h-[.76rem] pt-[.12rem] mx-[.04rem]">
            <div className="text-center mb-[.04rem]">{t('refer.level')} 2</div>
            <DashedLine length={7} />
          </div>
          <div className="flex items-center justify-center">
            <AvatarType1 num={3} />
            <div className="w-[.46rem] h-[.76rem] pt-[.46rem] mx-[.07rem]">
              <DashedLine length={3} />
            </div>
            <div className="relative">
              <AvatarType1 num={4} />
              <div className="absolute top-[.8rem] right-[-.1rem]">
                <SplitLine type={3} />
              </div>
            </div>
          </div>
        </div>
        {/* level 3 */}
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center">
            <AvatarType1 num={5} />
            <div className="w-[.46rem] h-[.76rem] pt-[.46rem] mx-[.07rem]">
              <DashedLine length={3} />
            </div>
            <AvatarType1 num={6} />
          </div>
          <div className="w-[2.08rem] h-[.76rem] pt-[.12rem] mx-[.07rem]">
            <div className="text-center mb-[.04rem]">{t('refer.level')} 3</div>
            <DashedLine length={12} />
          </div>
          <div className="flex items-center justify-center">
            <AvatarType1 num={5} />
            <div className="w-[.46rem] h-[.76rem] pt-[.46rem] mx-[.07rem]">
              <DashedLine length={3} />
            </div>
            <AvatarType1 num={6} />
          </div>
        </div>
      </div>
    </div>
  ) : type == '2' ? (
    <div className="w-[6.82rem] m-auto">
      <TextBox isHeaderBox={true} className="w-[6.82rem] min-h-[3.12rem] m-auto p-[.2rem_.36rem_.2rem_.64rem]">
        <div className="flex items-center">
          <div className="shrink-0 mr-[.3rem]">
            <AvatarType2 text="A" />
          </div>
          <div className="flex-1">
            <div>{t('refer.refer_picture_title_1')}</div>
            <div>
              <Trans
                t={t}
                i18nKey="refer.refer_picture_title_2"
                components={[<span className="text-[var(--colour-36)]" key={0}></span>]}
              />
            </div>
            <div>{t('refer.refer_picture_title_3')}</div>
            <div>
              <Trans
                t={t}
                i18nKey="refer.refer_picture_title_4"
                components={[<span className="text-[var(--colour-36)]" key={0}></span>]}
              />
            </div>
            <div>{t('refer.refer_picture_title_5')}</div>
            <div>
              <Trans
                t={t}
                i18nKey="refer.refer_picture_title_6"
                components={[<span className="text-[var(--colour-36)]" key={0}></span>]}
              />
            </div>
          </div>
        </div>
      </TextBox>
      <div className="relative">
        <div className="w-[7.09rem] h-[5.29rem] absolute top-[.15rem] left-[-0.14rem]">
          <Icon name={IconName.ReferPictureArrow} />
        </div>
        <div className="pt-[1.4rem] relative flex items-center justify-between">
          <TextBox className="absolute left-[0rem] top-[.48rem] w-[1.94rem] p-[.02rem]">
            <Trans
              t={t}
              i18nKey="refer.refer_picture_text_1"
              components={[<span className="text-[var(--colour-36)]" key={0}></span>]}
            />
          </TextBox>
          <TextBox className="absolute right-[-0.18rem] top-[.48rem] w-[1.94rem] p-[.02rem]">
            <Trans
              t={t}
              i18nKey="refer.refer_picture_text_2"
              components={[<span className="text-[var(--colour-36)]" key={0}></span>]}
            />
          </TextBox>
          <AvatarContent text1="B1" text2="15" text3="500" AvatarNum={2} AvatarText="B1" />
          <AvatarContent text1="B2" text2="90" text3="3000" AvatarNum={3} AvatarText="B2" />
          <AvatarContent text1="B3" text2="60" text3="2000" AvatarNum={2} AvatarText="B3" />
        </div>
        <div className="pt-[.96rem] relative flex items-center justify-between">
          <TextBox className="absolute right-[1.32rem] top-[.12rem] w-[1.84rem] p-[.02rem_.1rem]">
            <Trans
              t={t}
              i18nKey="refer.refer_picture_text_3"
              components={[<span className="text-[var(--colour-36)]" key={0}></span>]}
            />
          </TextBox>
          <AvatarContent text1="C1" text2="10" text3="1000" AvatarNum={4} AvatarText="C1" />
          <AvatarContent text1="C2" text2="20" text3="2000" AvatarNum={5} AvatarText="C2" />
          <AvatarContent text1="C3" text2="600" text3="20000" AvatarNum={4} AvatarText="C3" />
        </div>
      </div>
    </div>
  ) : null;
}

export default ReferPicture;

const AvatarType1 = ({ num = 1 }) => {
  return (
    <div
      className={cn(
        'w-[.76rem] h-[.76rem] bg-[url(/static/img/referPictureType1.png)] bg-no-repeat bg-[length:4.56rem_.76rem]',
        {
          'bg-[0_0]': num == 1,
          'bg-[-.76rem_0]': num == 2,
          'bg-[-1.52rem_0]': num == 3,
          'bg-[-2.28rem_0]': num == 4,
          'bg-[-3.04rem_0]': num == 5,
          'bg-[-3.8rem_0]': num == 6,
        },
      )}
    ></div>
  );
};

const DashedLine = ({ length = 1 }) => {
  return (
    <div className="flex items-center space-x-[.08rem]">
      {Array(length)
        .fill('')
        .map((item, index) => {
          return <div key={index} className="w-[.1rem] h-[1px] bg-[var(--colour-37)]"></div>;
        })}
    </div>
  );
};

const SplitLine = ({ type = 1 }) => {
  return (
    <div className="w-[1.38rem] h-[.36rem]">
      <div
        className={cn('w-[1px] h-[.16rem] bg-[var(--colour-37)]', {
          'm-auto': type == 1,
          'ml-[.46rem]': type == 2,
          'ml-[.9rem]': type == 3,
        })}
      ></div>
      <div className="flex">
        <div className="w-[1px] h-[.2rem] bg-[var(--colour-37)]"></div>
        <div className="flex-1 h-[1px] bg-[var(--colour-37)]"></div>
        <div className="w-[1px] h-[.2rem] bg-[var(--colour-37)]"></div>
      </div>
    </div>
  );
};

const AvatarType2 = ({ num = 1, text = '' }) => {
  return (
    <div
      className={cn(
        'w-[.92rem] h-[.92rem] bg-[url(/static/img/referPictureType2.png)] bg-no-repeat bg-[length:4.6rem_.92rem] rounded-[50%] border bg-[var(--colour-2)] relative',
        {
          'bg-[0_0] border-[var(--colour-26)]': num == 1,
          'bg-[-0.92rem_0] border-[var(--colour-1)]': num == 2,
          'bg-[-1.84rem_0] border-[var(--colour-1)]': num == 3,
          'bg-[-2.76rem_0] border-[var(--colour-24)]': num == 4,
          'bg-[-3.68rem_0] border-[var(--colour-24)]': num == 5,
        },
      )}
    >
      <div
        className={cn(
          'absolute right-[0rem] bottom-[-0.02rem] w-[.3rem] h-[.3rem] rounded-[50%] text-[.16rem] leading-[.3rem] text-[var(--colour-2)] text-center',
          {
            'bg-[var(--colour-26)]': num == 1,
            'bg-[var(--colour-1)]': num == 2 || num == 3,
            'bg-[var(--colour-24)]': num == 4 || num == 5,
          },
        )}
      >
        {text}
      </div>
    </div>
  );
};

const TextBox = ({ children, isHeaderBox = false, className = '' }) => {
  return (
    <div
      className={`bg-[var(--colour-69)] border border-[var(--colour-37)] font-[400] text-[var(--colour-37)] ${isHeaderBox ? 'rounded-[.2rem] text-[.2rem] leading-[.38rem]' : 'rounded-[.12rem] text-[.18rem] leading-[.2rem] text-center'}  ${className}`}
    >
      {children}
    </div>
  );
};

const AvatarContent = ({ text1 = '', text2 = '', text3 = '', AvatarNum = 1, AvatarText = '' }) => {
  const { t } = useTranslation();
  return (
    <div className="w-[2.1rem] h-[2.3rem] flex flex-col justify-between items-center">
      <TextBox className="w-[1.7rem] min-h-[.72rem] p-[.16rem_.04rem]">
        <Trans
          t={t}
          i18nKey="refer.refer_picture_text_4"
          components={[<span className="text-[var(--colour-36)]" key={0}></span>]}
          values={{
            val: text1,
            val2: text2,
          }}
        />
      </TextBox>
      <TextBox className="w-[2.1rem] h-[1.22rem] p-[.5rem_.04rem_.04rem_.04rem] relative">
        <Trans
          t={t}
          i18nKey="refer.refer_picture_text_5"
          components={[<span className="" key={0}></span>]}
          values={{
            val: text3,
          }}
        />
        <div className="absolute top-[-0.44rem] left-[50%] translate-x-[-50%]">
          <AvatarType2 num={AvatarNum} text={AvatarText} />
        </div>
      </TextBox>
    </div>
  );
};
