import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

const SwitchWrapper = ({ children,
    className,
    chooseClassName,
    notChooseClassName = '',
    choose,
    onClick }) => {

    return (
        <div className={`${className} ${choose ? chooseClassName : notChooseClassName}`} onClick={onClick}>
            {children}
        </div>
    )
}

export default SwitchWrapper;