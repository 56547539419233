import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';

import Close from '../close';
import Input from '../input';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';

import { toast } from 'react-toastify';

import Icon, { IconName } from '../icon';
import { SendCodeByEmailReq, BindEmailReq } from '../../api';
import { CaptchaInitReq, EmailSend } from '../../api/index-auth';
import handleInitGeetest4 from '../../hooks/useGeetestInit';

import InputBySendCode from '../inputBySendCode3';

import { UserCache, checkEmail } from '../../utils/helper';
import { setNeedLogout } from '../../redux/reducers/appSlice';
import { useDispatch } from 'react-redux';
import PopConfirm from '../popConfirm';

/**
 * 绑定邮箱
 */

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>
        <div className="w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full h-[1px] mt-[.28rem] bg-[var(--colour-6)]"></div>
    </>
  );
};

const BindEmail = ({ handleClose = () => {}, bindSuccessCallback = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hasBeenSent, setHasBeenSent] = useState(false);

  const [codeLoading, setCodeLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [captchaInitConfig, setCaptchaInitConfig] = useState(null);

  const [sendCodeformData, setSendCodeformData] = useStateIfMounted({
    email: '',
    captcha: '', // 接口返回的验证码
    cryCode: '', // 用户输入的验证码
  });

  const [formData, setFormData] = useStateIfMounted({
    email: '',
    password: '',
    code: '',
  });

  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    updateCaptcha();
  }, []);

  const updateCaptcha = () => {
    setCaptchaImg('');
    CaptchaInitReq().then((res) => {
      if (res.code == 0) {
        setSendCodeformData({ ...sendCodeformData, captcha: res.result.captcha, cryCode: '' });
        setCaptchaImg(res.result.data);
        setCaptchaInitConfig(res.result);
      }
    });
  };

  const handlePasswordChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/[^0-9a-zA-Z]/g, '').slice(0, 24);
    setFormData({ ...formData, password: v2 });
  };

  const onEmailChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/\s+/g, '');
    setFormData({ ...formData, email: v2 });
    setSendCodeformData({ ...sendCodeformData, email: v2 });
  };

  const onChangeCryCode = (ev) => {
    setSendCodeformData({ ...sendCodeformData, cryCode: ev.target.value });
  };

  const sendCodeRequestType = async (params) => {
    if (captchaInitConfig?.bind_email_validation_mode == 'geetest') {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        captchaInitConfig?.bind_email_risk_type,
        setCodeLoading,
      );

      return EmailSend({
        ...params,
        ...captchaObj,
      });
    } else {
      return EmailSend({
        ...params,
      });
    }
  };

  // 发送code码
  const handleSendCode = async () => {
    // 检测邮箱
    if (!checkEmail(formData.email)) {
      return toast.error(t('1008013'), {
        containerId: 'global',
      });
    }

    if (captchaInitConfig?.bind_email_validation_mode == 'image') {
      if (sendCodeformData.cryCode === '') {
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    if (codeLoading) {
      return;
    }

    setCodeLoading(true);

    try {
      sendCodeRequestType({
        ...sendCodeformData,
      }).then((res) => {
        if (res.code == 0) {
          setHasBeenSent(true);
          setShowConfirm(true);
          toast.success(t('home.toast033'), {
            containerId: 'global',
          });
        } else {
          updateCaptcha();
          toast.error(res.msg, {
            containerId: 'global',
          });
          setHasBeenSent(false);
        }

        setCodeLoading(false);
      });
    } catch (err) {
      return setCodeLoading(false);
    }
  };

  // 提交按钮事件
  const handleSubmit = async () => {
    // 检测邮箱
    if (!checkEmail(formData.email)) {
      return toast.error(t('1008013'), {
        containerId: 'global',
      });
    }
    // 检测密码
    if (formData.password.length < 8) {
      return toast.error(t('1008024'), {
        containerId: 'global',
      });
    }

    // 检测验证码
    if (!formData.code) {
      return toast.error(t('login-signup.vericication_code'), {
        containerId: 'global',
      });
    }

    setRequesting(true);
    BindEmailReq({ ...formData })
      .then((res) => {
        if (res.code == 0) {
          toast.success(t('home.toast020'), {
            containerId: 'global',
          });
          bindSuccessCallback();
          UserCache.setEmail(formData.email, true);
          dispatch(setNeedLogout({ showLoginBox: true }));
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
          setHasBeenSent(false);
          setFormData({ ...formData, code: '' });
          updateCaptcha();
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <div className="flex flex-col justify-between text-center relative pb-[.4rem] my-[.3rem] w-[7.4rem] h-[auto] rounded-normal bg-[var(--colour-35)]">
        <div className="">
          <div className="px-[.32rem]">
            <FormHeader title={t('account.bind')} handleClose={handleClose} />
          </div>
          {/* 列表 */}
          <div className="px-[.32rem]">
            <div className="flex flex-col mt-[.2rem]">
              {/* 邮箱 */}
              <div className="overflow-hidden">
                <Input
                  leftInner={
                    <div className="flex items-center">
                      <span className="block w-[.4rem] h-[.4rem] mr-[.2rem] text-[var(--colour-37)]">
                        <Icon name={IconName.Email} />
                      </span>
                    </div>
                  }
                  type="text"
                  value={formData.email}
                  onChange={onEmailChange}
                  placeholder={t('login-signup.Fill in your email')}
                  innerClassName="!border-transparent !h-[.96rem]"
                  topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                />
              </div>
              {/* 密码 */}
              <div className="mt-[.2rem]">
                <Input
                  leftInner={
                    <div className="flex items-center">
                      <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] text-[var(--colour-37)]">
                        <Icon name={IconName.LoginPwd} />
                      </span>
                    </div>
                  }
                  value={formData.password}
                  onChange={handlePasswordChange}
                  type="password"
                  placeholder={t('login-signup.fill_in_the_password')}
                  innerClassName="!border-transparent !h-[.96rem]"
                  topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                />
              </div>

              {captchaInitConfig?.bind_email_validation_mode == 'geetest' ? (
                <>
                  {/* 验证码 */}
                  <div className="mt-[.2rem]">
                    {/* inviteCode || showInviteCode ?  */}

                    <InputBySendCode
                      placeholder={t('login-signup.enter_verification_code')}
                      value={formData.code}
                      leftInner={
                        <div className="flex items-center">
                          <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] active:scale-[.95] transition-all select-none text-[var(--colour-37)]">
                            <Icon name={IconName.RegisterCode} />
                          </span>
                        </div>
                      }
                      onChange={(ev) => {
                        setFormData({ ...formData, code: ev.target.value });
                      }}
                      hasBeenSent={hasBeenSent}
                      handleSendCode={handleSendCode}
                      countdownDone={() => setHasBeenSent(false)}
                      requesting={codeLoading}
                      propoClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                    />
                  </div>
                </>
              ) : captchaInitConfig?.bind_email_validation_mode == 'image' ? (
                <>
                  {/* 图形验证码 */}
                  <div className="mt-[.2rem]">
                    <Input
                      leftInner={
                        <div className="flex items-center">
                          <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] text-[var(--colour-37)]">
                            <Icon name={IconName.RegisterCode} />
                          </span>
                        </div>
                      }
                      value={sendCodeformData.cryCode}
                      onChange={onChangeCryCode}
                      type="text"
                      placeholder={t('login-signup.fill_captcha')}
                      innerClassName="!border-transparent !h-[.96rem]"
                      topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                      rightInner={
                        <div>
                          <img
                            src={captchaImg}
                            alt="captcha"
                            onClick={updateCaptcha}
                            className="cursor-pointer w-[2rem] ml-[.08rem]"
                          />
                        </div>
                      }
                    />
                  </div>
                  {/* 验证码 */}
                  <div className="mt-[.2rem]">
                    {/* inviteCode || showInviteCode ?  */}

                    <InputBySendCode
                      placeholder={t('login-signup.enter_verification_code')}
                      value={formData.code}
                      leftInner={
                        <div className="flex items-center">
                          <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] active:scale-[.95] transition-all select-none text-[var(--colour-37)]">
                            <Icon name={IconName.RegisterCode} />
                          </span>
                        </div>
                      }
                      onChange={(ev) => {
                        setFormData({ ...formData, code: ev.target.value });
                      }}
                      hasBeenSent={hasBeenSent}
                      handleSendCode={handleSendCode}
                      countdownDone={() => setHasBeenSent(false)}
                      requesting={codeLoading}
                      propoClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                    />
                  </div>
                </>
              ) : (
                <div className="mt-[.2rem]">
                  {/* inviteCode || showInviteCode ?  */}

                  <InputBySendCode
                    placeholder={t('login-signup.enter_verification_code')}
                    value={formData.code}
                    leftInner={
                      <div className="flex items-center">
                        <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] active:scale-[.95] transition-all select-none text-[var(--colour-37)]">
                          <Icon name={IconName.RegisterCode} />
                        </span>
                      </div>
                    }
                    onChange={(ev) => {
                      setFormData({ ...formData, code: ev.target.value });
                    }}
                    hasBeenSent={hasBeenSent}
                    handleSendCode={handleSendCode}
                    countdownDone={() => setHasBeenSent(false)}
                    requesting={codeLoading}
                    propoClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                  />
                </div>
              )}

              <LimitClickWrapper
                className="active:scale-[.95] transition-all select-none mt-[.4rem] flex flex-row px-[.34rem] h-[.92rem] items-center  justify-center cursor-auto bg-[var(--colour-1)] rounded-normal text-[var(--colour-2)]"
                onClick={() => {
                  if (!requesting) {
                    handleSubmit();
                  }
                }}
              >
                <ElementWithLoading
                  isLoading={requesting}
                  normalEl={
                    <>
                      <div className="font-[700] text-[.32rem]">{t('account.submint')}</div>
                    </>
                  }
                />
              </LimitClickWrapper>
            </div>
          </div>
        </div>
      </div>

      <PopConfirm
        zIndex={100001}
        visible={showConfirm}
        handleClose={handleConfirmClose}
        handleConfirm={handleConfirmClose}
      >
        {t('account.email_bind_tips')}
      </PopConfirm>
    </>
  );
};
export default BindEmail;
