import { useEffect, useState } from 'react';

const useFooterData = (footerData) => {
  const [followList, setFollowList] = useState([]);
  const [licenseList, setLicenseList] = useState([]);
  const [text, setText] = useState('');
  const [helpList, setHelpList] = useState([]);

  // console.log('footerData', footerData);

  useEffect(() => {
    if (footerData?.footer_contact && footerData?.footer_contact.length > 0) {
      setFollowList(footerData?.footer_contact.filter((item) => item.img != ''));
    }
    if (footerData?.footer_site_icon && footerData?.footer_site_icon.length > 0) {
      setLicenseList(footerData?.footer_site_icon.filter((item) => item.img != ''));
    }
    if (footerData?.footer_statement?.content) {
      setText(footerData.footer_statement.content);
    }
    if (footerData?.footer_help) {
      setHelpList(footerData.footer_help);
    }
  }, [footerData]);

  return {
    followList,
    licenseList,
    text,
    helpList,
  };
};

export default useFooterData;
