import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import { isLogin } from '../../../../utils/helper';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../redux/reducers/appSlice';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const HistoryBonus = ({ handleLinkClick = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const clickHistory = () => {
    if (isLogin()) {
      navigate(toLocalePath('/promotion/history'));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }

    handleLinkClick();
  };

  return (
    <div
      onClick={clickHistory}
      className="active:scale-[.95] transition-all w-[1.26rem] h-[.82rem] rounded-normal cursor-pointer bg-[url(../assets/img/drawerMenuHistoryBg.png)] bg-no-repeat bg-[length:100%_100%] p-[.08rem]"
    >
      <div className="font-[600] text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow">
        <div>{t('home.history')}</div>
      </div>
    </div>
  );
};

export default HistoryBonus;
