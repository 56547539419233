export const getMaxIndex = (num, numList = []) => {
  let _index = -1;

  if (numList.length > 0) {
    numList.forEach((item, index) => {
      if (Number(num) >= Number(item)) {
        _index = index;
      }
    });
  }

  return _index;
};
