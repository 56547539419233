import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import cn from 'classnames';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../../../../utils/localePath';
import Email from '../../../../components/email/email';
import { Dtemplate003Support } from '../../../../components/supportNew3';
import { isLogin } from '../../../../utils/helper';
import LimitClickWrapper from '../../../../components/limitClickWrapper';
import HeaderBack from '../../../../components/headerBack';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';

const NTemplate001 = () => {
  const { h } = useWindowHeight(1.7);
  const { supportmessageId } = useParams();
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const { handleBack } = usePageNavigation();

  const { unReadCount, userObj } = useSelector(
    (state) => ({
      unReadCount: get(state, 'app.unReadCount'),
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  const [list, setList] = useState([
    {
      id: '1',
      text: 'support',
      need_login: false,
      showunReadCount: false,
    },
    {
      id: '2',
      text: 'message',
      need_login: true,
      showunReadCount: true,
    },
  ]);

  return (
    <div className="container !px-[0px] bg-[var(--colour-34)] min-h-[var(--app-height)]">
      <HeaderBack
        title={t('account.message_center')}
        onClick={() => {
          handleBack();
        }}
        className={`!bg-[--colour-86]`}
      />
      <div className="">
        <div className="h-[.74rem] px-[.24rem] flex border-b border-solid border-[var(--colour-6)] bg-[var(--colour-35)]">
          {list.map((item) => {
            return (
              <div key={item.id} className="relative mr-[.52rem]">
                <LimitClickWrapper
                  className={cn(
                    'leading-[.76rem] text-[.24rem] font-[500] active:scale-[.95] transition-all select-none cursor-pointer',
                    {
                      'text-[var(--colour-13)]': supportmessageId == item.id,
                      'text-[var(--colour-14)]': supportmessageId != item.id,
                      hidden: item.need_login && !isLogin(),
                    },
                  )}
                  onClick={() => {
                    navigate(toLocalePath(`/supportmessage/${item.id}`));
                  }}
                >
                  {t(`account.${item.text}`)}
                </LimitClickWrapper>
                <div
                  className={cn('absolute bottom-[0px] w-full h-[.02rem]', {
                    'bg-[var(--colour-1)]': supportmessageId == item.id,
                    'bg-transparent': supportmessageId != item.id,
                  })}
                ></div>

                {item.showunReadCount && userObj != null && unReadCount > 0 ? (
                  <div className="min-w-[.4rem] h-[.24rem] whitespace-nowrap text-[.18rem] font-[500] leading-[.22rem] text-[var(--colour-43)] rounded-[.12rem_.12rem_.12rem_0rem] absolute bg-[var(--colour-25)] text-center px-[.1rem] top-[0rem] left-[97%] flex items-center justify-center">
                    <div>{unReadCount}</div>
                    <div className="w-[0rem] h-[0rem] border-[.04rem] border-solod border-transparent border-t-2 border-t-[var(--colour-25)] border-l-2 border-l-[var(--colour-25)] absolute bottom-[-0.06rem] left-[0rem]"></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
        <div className="">
          {supportmessageId == '1' ? (
            <div className="pb-[.52rem]">
              <Dtemplate003Support isShowClose={false} />
            </div>
          ) : (
            <div className="pt-[.2rem] px-[.32rem] overflow-y-auto hidden-scroll-y" style={{ height: `${h}rem` }}>
              <Email isShowClose={false} isinPage={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NTemplate001;
