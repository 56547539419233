import { useEffect } from 'react';
import DialogModal from './dialogModal';
import Icon, { IconName } from './icon';
function ResultTips({ setShowVerify, showVerify, content, type = 'error' }) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
        overlayClassName="!bg-[transparent] pointer-events-none"
      >
        <TipInner content={content} type={type} setShowVerify={setShowVerify}></TipInner>
      </DialogModal>
    </>
  );
}
function TipInner({ content, type, setShowVerify }) {
  useEffect(() => {
    let timer = null;
    timer = setTimeout(() => {
      setShowVerify(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className="w-[6.86rem] bg-[var(--colour-21)] rounded-normal p-[.32rem_.16rem_.36rem_.32rem] flex items-center">
      <div className="w-[.4rem] h-[.4rem] mr-[.22rem]">
        {type == 'error' ? (
          <div className="w-[.4rem] h-[.4rem]">
            <Icon name={IconName.ErrorTip3} />
          </div>
        ) : (
          <div className="w-[.4rem] h-[.4rem]">
            <Icon name={IconName.SuccessTip2} />
          </div>
        )}
      </div>
      <div className="text-[var(--colour-43)] text-[.28rem] font-[500] leading-[.34rem] flex-1">{content}</div>
    </div>
  );
}
export default ResultTips;
