const TwoFaButton = ({ text = '', onClick = () => {}, className = '' }) => {
  return (
    <div
      className={`cursor-pointer flex justify-center items-center bg-[var(--colour-1)] rounded-btn ${className}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default TwoFaButton;
