import { useState, useEffect, memo, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cn from 'classnames';
import {
  DialogModalChildrenKey,
  setAutoScrollToTagGameKey,
  setDialogModalVisible,
  setDrawerMenuVisible,
} from '../../../../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import 'react-tooltip/dist/react-tooltip.css';
import { Popup } from 'react-vant';
import LogoBox from '../../../../logoBox';
import HeaderSidebarSwitch from '../../../../header/headerSidebarSwitch';
import { cmsImgUrl, isLogin } from '../../../../../utils/helper';
import Suporte from '../../../../../assets/img/suporte1.png';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../../utils/localePath';
import useLink from '../../../../../hooks/useLink';
import useHandleLogic from '../../../../../hooks/useHandleLogic';

const DTemplate003 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const { handleCmsLink } = useLink();
  const { handleClickManMadeInPage } = useHandleLogic();
  const [sidebarPublicityList, setSidebarPublicityList] = useState([]);

  const navigate = useNavigate();
  const { drawerMenuVisible, websiteInitData } = useSelector(
    (state) => ({
      websiteInitData: state.app.websiteInitData,
      drawerMenuVisible: state.app.drawerMenuVisible,
      disconnect: state.app.disconnect,
      downloadBoxPromptSwitch: state.app.downloadBoxPromptSwitch,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (websiteInitData?.template) {
      const data = websiteInitData.template;
      const HomeTagSearchdata = data.find((item, index) => {
        return item.module == 'HomeTagSearch';
      });

      if (HomeTagSearchdata) {
        setList(HomeTagSearchdata.config);
      }

      const SidebarPublicitydata = data.find((item, index) => {
        return item.module == 'SidebarPublicity';
      });

      if (SidebarPublicitydata) {
        setSidebarPublicityList(SidebarPublicitydata.config);
      }
    }
  }, [websiteInitData]);

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  const refLeftMenu = useRef(null);
  const refInnerLeftMenu = useRef(null);

  const handleClick = () => {
    handleClickManMadeInPage();
    handleLinkClick();
  };

  return (
    <>
      <Popup
        overlayStyle={{ zIndex: 104 }}
        teleport={document.getElementById('root')}
        style={{ height: '100%', zIndex: 104 }}
        visible={drawerMenuVisible.visible}
        position="left"
        onClose={handleLinkClick}
      >
        <aside
          className={cn(
            'left-menu-container w-[var(--left-menu-width)] pt-[var(--header-box-height)] bg-[transparent]',
          )}
        >
          <div
            ref={refLeftMenu}
            className={cn(
              'left-menu-container__outer1 w-full scrollbar-none pl-[.32rem] pr-[.4rem] rounded-[0_.4rem_.4rem_0] bg-[var(--colour-54)]',
            )}
            style={{ scrollbarColor: 'auto' }}
          >
            <div ref={refInnerLeftMenu} className={cn('left-menu-container__inner !static')}>
              <div className="flex items-center">
                <HeaderSidebarSwitch className="mr-[.32rem] text-[var(--colour-2)]" />
                <LogoBox className="!h-[.82rem]" isShowFirstLogo={true} />
              </div>

              <div className="mt-[.12rem] w-[3.54rem] h-[4.72rem] bg-[var(--colour-30)] rounded-[0_.16rem_.16rem_0] py-[.14rem]">
                <div className="h-full overflow-y-auto custom-scrollbar pl-[.2rem] py-[.26rem] small-container">
                  {list && list.length > 0 ? (
                    <>
                      {list.map((item, index) => {
                        return item.category_id != '001' && item.category_id != '003' ? (
                          <div
                            key={index}
                            onClick={() => {
                              handleLinkClick();
                              dispatch(setAutoScrollToTagGameKey(item.category_id));
                            }}
                            className={`flex items-center cursor-pointer ${index == list.length - 1 ? '' : 'mb-[.32rem]'}`}
                          >
                            <div className="w-[.52rem] h-[.52rem] flex items-center justify-center mr-[.12rem]">
                              <img src={cmsImgUrl(item.icon)} alt="" />
                            </div>
                            <div className="text-[var(--colour-13)] text-[.24rem] font-[600]">
                              {item.lang_key_title}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </>
                  ) : null}
                </div>
              </div>

              <div
                className="mt-[.28rem] w-[3.54rem] h-[.78rem] bg-[var(--colour-51)] rounded-[.16rem] flex items-center justify-center cursor-pointer active:scale-[.95] transition-all select-none text-[var(--colour-2)] text-[.24rem] font-[600]"
                onClick={() => {
                  if (isLogin()) {
                    navigate(toLocalePath(`/report/1`), { state: { from: '/' } });
                  } else {
                    dispatch(
                      setDialogModalVisible({
                        show: DialogModalChildrenKey.LoginBox,
                      }),
                    );
                  }
                  handleLinkClick();
                }}
              >
                {t('home.drawer_betRecords')}
              </div>

              <div className="mt-[.36rem] space-y-[.28rem]">
                {sidebarPublicityList && sidebarPublicityList.length > 0 ? (
                  <>
                    {sidebarPublicityList.map((item, index) => {
                      return item.status == 1 ? (
                        <div
                          key={index}
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            handleCmsLink(item.open_mode, item.new_window, item.link);
                            handleLinkClick();
                          }}
                        >
                          <img src={cmsImgUrl(item.icon)} className="w-[.52rem]" alt="" />
                          <div className="ml-[.12rem] text-[var(--colour-13)] text-[.24rem] font-[600]">
                            {item.title}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div
                className="w-[3.54rem] h-[.86rem] rounded-[.16rem] colour-85 flex items-center mt-[.48rem] relative pl-[.28rem] pr-[.18rem] justify-between cursor-pointer active:scale-[.95] transition-all select-none mb-[.4rem]"
                onClick={handleClick}
              >
                <div className="w-[.04rem] h-[.54rem] colour-84 absolute left-0 abs-y-center"></div>

                <div className="leading-[.26rem]">
                  <div className="text-[var(--colour-2)] text-[.24rem] font-[600]">{t('account.support')}</div>
                  <div className="text-[var(--colour-41)] text-[.2rem] font-[600]">24/7</div>
                </div>

                <div className="w-[.52rem] h-[.52rem]">
                  <img src={Suporte} alt="" className="not-drag" />
                </div>
              </div>
            </div>
          </div>
        </aside>
      </Popup>
    </>
  );
};

export default memo(DTemplate003);
