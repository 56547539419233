import { getShowMoney, getPercentage, cmsImgUrl, openGiftMoneyDetail, isLogin } from '../../../utils/helper';
import { useStateIfMounted } from 'use-state-if-mounted';
import DragBtnRight from '../../dragBtnRight';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  DialogModalChildrenKey,
  setRefetchActivityData,
  setDialogModalVisible,
} from '../../../redux/reducers/appSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { useEffect, useState } from 'react';
import ActivityCountDown from '../../dialogModal/components/activityCountDown';
import ClaimBtn from '../components/claimbtn';
import { isAnyMobile } from '../../../utils/deviceUtils';
import cn from 'classnames';
import useGameInfo from '../../../hooks/useGameInfo';
import { useLocation } from 'react-router-dom';
import { toNoLocalePath } from '../../../utils/localePath';
import DialogModal from '../../dialogModal';
import GiftMoney from '../../giftMoney/giftMoney';

export default function Index({ isdrag = false, isFromGame = false, index = null, EntranceDisplayLocation }) {
  const { userClaimTotalMoney, giftMoneyMsg, activityStatus, activityData } = useSelector(
    (state) => ({
      giftMoneyMsg: state.ws.giftMoneyMsg,
      userClaimTotalMoney: state.app.userClaimTotalMoney,
      activityStatus: state.app.activityStatus,
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isGameOpen } = useGameInfo();
  const { pathname } = useLocation();
  const [progress, setProgress] = useStateIfMounted(0);
  const [imgpath, setImgpath] = useStateIfMounted('');
  const [visible, setVisible] = useState(false);
  const [giftMoneyMoveTargetClass, setgiftMoneyMoveTargetClass] = useStateIfMounted('');

  const handleSaveClass = (className = '') => {
    setVisible(true);
    setgiftMoneyMoveTargetClass(className);
  };

  const handleClick = () => {
    // dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
    openGiftMoneyDetail(isGameOpen, toNoLocalePath(pathname), handleSaveClass);
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_GiftMoney_Floating);
  };
  const handleCountDownComplete = () => {
    dispatch(setRefetchActivityData());
  };

  useEffect(() => {
    if (giftMoneyMsg != null) {
      setProgress(getPercentage(giftMoneyMsg.user_wager_money, giftMoneyMsg.total_wager_money));
    } else {
      setProgress(0);
    }
  }, [giftMoneyMsg]);

  const getActivityImage = (arr, index) => {
    const item = arr.find((item) => item.index == index);
    return item ? cmsImgUrl(item.icon) : '';
  };

  useEffect(() => {
    if (activityStatus != null) {
      const activityWheelShowIconV2 = Array.from(activityStatus.activity_wheel_show_icon_v2 || []);
      const imgpath = getActivityImage(activityWheelShowIconV2, -1);
      setImgpath(imgpath);
    }
  }, [activityStatus]);

  const isfullScreen = isFromGame && !isAnyMobile();

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={false}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setVisible(false);
        }}
        isOpen={visible}
      >
        <GiftMoney
          isFromGame={isFromGame}
          giftMoneyMoveTargetClass={giftMoneyMoveTargetClass}
          handleClose={() => {
            setVisible(false);
          }}
        />
      </DialogModal>

      <DragBtnRight
        className1={`${isfullScreen ? `w-[150px] h-[150px]` : `w-[1.5rem] h-[1.5rem]`} ${isFromGame ? 'giftMoney_game-dragBox' : 'giftMoney_dragBox'}`}
        onClick={handleClick}
        isFromGame={isFromGame}
        className={`activty-absolute-bottom-${index + 1}  ${index != null && EntranceDisplayLocation == '0' && !isFromGame ? 'activity-item' : ''} ${EntranceDisplayLocation == '0' || isFromGame ? 'pc:right-[16px] mobile:right-[.08rem]' : 'pc:left-[4px] mobile:left-[.08rem]'}`}
        isLimitedInParentHeight={false}
        isdrag={isdrag}
      >
        <div
          className={cn('flex items-center justify-center relative', {
            'w-[150px] h-[150px]': isfullScreen,
            'w-[1.5rem] h-[1.5rem]': !isfullScreen,
          })}
        >
          <div
            className={cn({
              'scale-[.5]': !isfullScreen,
            })}
          >
            <div
              className={cn('flex flex-col items-center justify-center relative', {
                'w-[150px] h-[150px]': isfullScreen,
                'w-[3rem] h-[3rem]': !isfullScreen,
              })}
            >
              <img src={imgpath} alt="" className="not-drag absolute left-0 top-0 w-full" />

              {giftMoneyMsg != null ? (
                <>
                  {giftMoneyMsg.status == 2 ? (
                    <>
                      <ClaimBtn
                        txtColor="event_bonus_title1"
                        className={cn(
                          'bg-EntranceClaim_btn flex items-center justify-center absolute abs-x-center bottom-[0]',
                          {
                            'rounded-[4px]': isfullScreen,
                            'rounded-[.08rem]': !isfullScreen,
                          },
                        )}
                      >
                        {t('guideWheel.claim')}
                      </ClaimBtn>
                    </>
                  ) : (
                    <>
                      <div
                        className={cn(
                          'flex items-center justify-center font-[700] text-[16px] text-[var(--colour-48)] absolute abs-x-center bottom-0',
                          {
                            'w-[128px] h-[24px]': isfullScreen,
                            'w-[2.56rem]  h-[.48rem] ': !isfullScreen,
                          },
                        )}
                      >
                        <ActivityCountDown onComplete={handleCountDownComplete} endTime={giftMoneyMsg.expires_time} />
                      </div>
                      {progress != 0 ? (
                        <div
                          className={cn('bg-[rgba(53,33,36,0.80)] overflow-hidden absolute abx-x-center ', {
                            'w-[120px]  h-[10px] rounded-[10px] p-[1px] bottom-[25px]': isfullScreen,
                            'w-[2.4rem] h-[.2rem] rounded-[.2rem] p-[.02rem] bottom-[.5rem]': !isfullScreen,
                          })}
                        >
                          <div
                            className="rounded-[4px] h-full"
                            style={{
                              background:
                                'linear-gradient(180deg, #FFE485 0%, #FFF59A 19.5%, #D97F2B 85%, #FFD66B 100%)',
                              width: `${progress}%`,
                            }}
                          ></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {userClaimTotalMoney != 0 ? (
                        <>
                          <span
                            className={cn(
                              'giftMoneyBonus font-[700] flex justify-center text-center absolute abs-x-center',
                              {
                                'text-[24px]  bottom-[40px]': isfullScreen,
                                'text-[.48rem] bottom-[.8rem]': !isfullScreen,
                              },
                            )}
                            data-text={getShowMoney(userClaimTotalMoney)}
                          >
                            {getShowMoney(userClaimTotalMoney)}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </DragBtnRight>
    </>
  );
}
