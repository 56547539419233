import { useTranslation } from 'react-i18next';
import DialogModal from './dialogModal';
import LimitClickWrapper from './limitClickWrapper';
import Icon, { IconName } from './icon';
import { useSelector, shallowEqual } from 'react-redux';

function Tips({ setShowVerify, showVerify, headTxt, contTxt, btn1 = null, btn2 = null, cb1 = null, cb2 = null }) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
      >
        <ReferralInvit7
          handleClose={() => setShowVerify(false)}
          headTxt={headTxt}
          contTxt={contTxt}
          btn1Name={btn1}
          btn2Name={btn2}
          cb1={cb1}
          cb2={cb2}
        ></ReferralInvit7>
      </DialogModal>
    </>
  );
}
function ReferralInvit7({ handleClose, headTxt, contTxt, btn1Name = null, btn2Name = null, cb1 = null, cb2 = null }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={`flex flex-col w-[6.86rem] h-[4.8rem] rounded-normal bg-[var(--colour-35)]`}>
        <div className="flex flew-row mt-[.4rem] ml-[.4rem] w-[6.22rem] h-[.72rem] justify-center border-b-[.02rem] border-[var(--colour-6)]">
          <div className="flex flew-row w-[6.22rem]  justify-center ">
            <div className="flex w-[6.22rem] text-[.32rem] font-[700] text-[var(--colour-48)]">{headTxt}</div>
            <div
              className="flex items-center justify-center w-[.72rem] h-[.72rem] -mt-[.2rem] text-iconInBtn rounded-normal"
              onClick={() => handleClose()}
            >
              <div className="w-[.3rem] h-[.3rem]">
                <Icon name={IconName.Close2} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-[6.22rem] mt-[.4rem] h-[1.02rem] justify-center items-center  ml-[.2rem]">
          <div className="flex w-[6.22rem] justify-center items-center text-[.28rem]  ml-[.2rem] text-[var(--colour-40)]">
            {contTxt}
          </div>
        </div>

        <div className="flex flex-row w-[5.66rem] mt-[.7rem] justify-between ml-[.56rem]">
          <LimitClickWrapper
            className="flex w-[2.6rem] h-[.8rem] justify-center items-center text-center text-[.28rem] cursor-pointer select-none bg-[var(--colour-1)] text-[var(--colour-2)] rounded-btn "
            onClick={() => {
              cb1 && cb1();
            }}
          >
            {btn1Name}
          </LimitClickWrapper>
          <LimitClickWrapper
            className="flex justify-center items-center text-center text-[.28rem] w-[2.6rem] h-[.8rem] cursor-pointer select-none bg-[var(--colour-5)] border border-[var(--colour-1)] text-[var(--colour-3)]  rounded-btn"
            onClick={() => {
              cb2 && cb2();
            }}
          >
            {btn2Name}
          </LimitClickWrapper>
        </div>
      </div>
    </>
  );
}
export default Tips;
