import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from './button';
import Icon, { IconName } from './icon';
import { useUpdateEffect } from 'ahooks';
import { restrictToMaxPoint } from '../utils/inputHelper';
import { preventDefault, formatNumber, isDef } from './passwordInput/utils';
// import { getTemplateId } from '../utils/helper';

const Input = ({
  type = 'text',
  placeholder,
  rightInner = '',
  rightOuter = '',
  value,
  onChange,
  leftInner = '',
  leftOuter = '',
  readOnly = false,
  innerClassName = '',
  topClassName = '',
  disabled = false,
  inputClassName = '',
  wrapperClassName = '',
  onKeyDown = () => {},
  onFocus = () => {},
  onBlur = () => {},
  islightrightIcon = false,
  rightIconClassName = '',
  showEyeIconName = '',
  hideEyeIconName = '',
  passWordBeforeInner = '',
  btnClassName = '',
  maxlength = 1000000,
  onCompositionStart = () => {},
  onCompositionEnd = () => {},
  onInput = () => {},
  isShowClearIcon = true,
  clearIconClassName = '',
  onKeyPress = () => {},
  maxPoint = 2, //保留最大小数位，有两位禁止输入
  onOverlimit = () => {},
}) => {
  const inputRef = useRef(null);
  const inputShowTypeRef = useRef(true);
  const compositionStartRef = useRef(false);
  const [inputShowType, setInputShowType] = useState('text');
  const [inputShowEyeVisible, setInputShowEyeVisible] = useState(true);
  const [isFoucsed, setIsFoucsed] = useState(false);

  const handleOnClick = () => {};

  const handleClearClick = () => {
    // 清空input的输入
    if (onChange) {
      const event = {
        target: {
          value: '',
        },
      };
      onChange(event);
    }
  };

  const handleValueChange = (e) => {
    let finalValue = e?.currentTarget?.value;

    if (!compositionStartRef.current) {
      if (isDef(maxlength) && finalValue.length > +maxlength) {
        finalValue = finalValue.slice(0, maxlength);
        onOverlimit();
      }

      if (type === 'number' || type === 'digit') {
        const isNumber = type === 'number';
        finalValue = formatNumber(finalValue, isNumber, isNumber);
      }
    }
    const event = {
      target: {
        value: finalValue,
      },
    };
    onChange(event);
  };

  const handOnBlur = (evt) => {
    setIsFoucsed(false);
    onBlur(evt);
  };

  useEffect(() => {
    if (type == 'password') {
      if (String(value).length == 0 || String(value).trim().length == 0) {
        setInputShowType('text');
      } else {
        setInputShowType(inputShowTypeRef.current ? 'password' : 'text');
      }
    }
  }, [value, type]);

  useUpdateEffect(() => {
    inputShowTypeRef.current = inputShowEyeVisible;
    setInputShowType(inputShowEyeVisible ? 'password' : 'text');
  }, [inputShowEyeVisible]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || +e.charCode === 13) {
      preventDefault(e);
    }
    onKeyPress?.(e);
  };

  useEffect(() => {
    const handleFocus = (evt) => {
      const timer = setTimeout(() => {
        setIsFoucsed(true);
        onFocus(evt);
      }, 200); // 0.2秒延时,解决iphone safari 光标缩小的bug
      // 清理函数
      return () => {
        clearTimeout(timer);
      };
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
    }

    // 清理事件监听器
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
      }
    };
  }, []);

  const handleOnKeyDown = (e) => {
    onKeyDown(e);
    if (type == 'number') {
      restrictToMaxPoint(e, maxPoint);
    }
  };

  const getInputType = (type) => {
    switch (type) {
      case 'password':
        return inputShowType;
      case 'digit':
        return 'tel';
      case 'number':
        return 'text';
      default:
        return 'text';
    }
  };

  const getInputMode = (type) => {
    switch (type) {
      case 'number':
        return 'decimal';
      case 'digit':
        return 'numeric';
      default:
        return 'text';
    }
  };

  return (
    <div className={`flex flex-row items-center border border-[var(--colour-6)] ${topClassName}`}>
      {leftOuter != '' ? leftOuter : <></>}
      <div className={`input-wrapper  input_md ${wrapperClassName}`}>
        <div className={`input-inner space-x-[.16rem] ${innerClassName}`}>
          {leftInner ? leftInner : <></>}
          <input
            ref={inputRef}
            onInput={onInput}
            inputMode={getInputMode(type)}
            hidden
            autoComplete="new-password"
            disabled={disabled}
            readOnly={readOnly}
            className={`input-tag font-[600] text-[.28rem] ${inputClassName}`}
            value={value}
            onChange={handleValueChange}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            type={getInputType(type)}
            onClick={handleOnClick}
            onKeyDown={handleOnKeyDown}
            onBlur={handOnBlur}
            maxLength={maxlength}
            onCompositionStart={(e) => {
              compositionStartRef.current = true;
              onCompositionStart(e);
            }}
            onCompositionEnd={(e) => {
              compositionStartRef.current = false;
              onCompositionEnd(e);
            }}
          />

          {passWordBeforeInner != '' ? passWordBeforeInner : <></>}

          {isShowClearIcon && value.length > 0 && !disabled && isFoucsed && (
            <span
              className={`block w-[.32rem] h-[.32rem] text-[var(--colour-37)] ${clearIconClassName}`}
              onClick={handleClearClick}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              <Icon name={IconName.InputClear} />
            </span>
          )}

          {type == 'password' ? (
            <Button
              className={`border-none ml-[.14rem] ${btnClassName}`}
              onClick={() => {
                setInputShowEyeVisible(!inputShowEyeVisible);
              }}
            >
              <span
                className={`w-[.3rem] h-[.3rem] block ${islightrightIcon ? `${inputShowEyeVisible ? 'text-[var(--colour-6)]' : 'text-[var(--colour-1)]'}` : 'text-[var(--colour-38)]'} ${rightIconClassName}`}
              >
                <Icon
                  name={
                    inputShowEyeVisible
                      ? showEyeIconName
                        ? `${showEyeIconName}`
                        : IconName.ShowEye
                      : hideEyeIconName
                        ? `${hideEyeIconName}`
                        : IconName.HideEye
                  }
                />
              </span>
            </Button>
          ) : (
            <></>
          )}
          {rightInner != '' ? rightInner : <></>}
        </div>
      </div>
      {rightOuter != '' ? rightOuter : <></>}
    </div>
  );
};

export default Input;
