import LimitClickWrapper from '../../../../../limitClickWrapper';
const ClaimBtn = ({ btnName, onClick = () => {} }) => {
  return (
    <LimitClickWrapper
      limitTime={1.5}
      onClick={onClick}
      className="font-[600] cursor-pointer bg-[var(--colour-1)] text-[var(--colour-2)] flex items-center justify-center rounded-normal text-[.28rem] w-[3.24rem] h-[.68rem]"
    >
      {btnName}
    </LimitClickWrapper>
  );
};

export default ClaimBtn;
