import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import cn from 'classnames';
import { get } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { isLogin, getUserPhoto, getShowMoney } from '../../utils/helper';
import { toLocalePath } from '../../utils/localePath';
import { GetWebsiteTournament } from '../../api';
import Icon, { IconName } from '../icon';
import RankingImg1 from '../../assets/img/tournamentRanking0.png';
import RankingImg2 from '../../assets/img/tournamentRanking1.png';
import RankingImg3 from '../../assets/img/tournamentRanking2.png';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const TournamentCountdown = ({ date = Date.now(), onComplete = () => {} }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const list = completed
      ? ['00', '00', '00', '00']
      : [zeroPad(days, 2), zeroPad(hours, 2), zeroPad(minutes, 2), zeroPad(seconds, 2)];

    return list.map((item, index) => (
      <div key={index} className="flex items-center text-[.2rem] font-[700] leading-[.24rem] text-[#FFF]">
        {[1, 2, 3].includes(index) && <span className="mx-[.02rem]">:</span>}
        <div key={index}>
          {String(item)
            .split('')
            .map((item2, index2) => (
              <span key={index2}>{item2}</span>
            ))}
        </div>
      </div>
    ));
  };
  return (
    <div className="flex items-center">
      <Countdown
        date={date}
        onComplete={() => {
          setTimeout(() => {
            onComplete();
          }, 1000 * 2);
        }}
        renderer={renderer}
      />
    </div>
  );
};

const RankingItem = ({ img = '', nickname, iconNum = 1, isMe = false }) => {
  return (
    <div
      className="w-[3.36rem] h-[.4rem] mb-[.06rem] bg-no-repeat bg-[length:100%_100%] pl-[.86rem] flex items-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      {nickname ? (
        <div className="w-[.4rem] h-[.4rem] mr-[.08rem]">
          <img src={getUserPhoto(iconNum)} className="w-full h-full" />
        </div>
      ) : (
        <div className="w-[.4rem] h-[.4rem] mr-[.08rem] flex justify-center items-center text-[.16rem] font-[600] text-[#FFF]">
          --
        </div>
      )}
      {nickname ? (
        <div className="text-[.16rem] font-[600] leading-[.2rem] flex items-center">
          <div className={`mr-[.08rem] max-w-[1.6rem] truncate ${isMe ? 'text-[#FEFFBA]' : 'text-[#FFF]'}`}>
            {nickname}
          </div>
          {isMe ? (
            <div className="w-[.168rem] h-[.18rem] text-[#FEFFBA] shrink-0">
              <Icon name={IconName.Registeruser} />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

function Content({ tourCardData, updateList }) {
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const { uid, currency_symbol } = useSelector(
    (state) => ({
      uid: get(state, 'user.userObj.uid'),
      currency_symbol: get(state, 'app.websiteInitConfig.website_info.currency_symbol') || '',
    }),
    shallowEqual,
  );

  const [boardList, setBoardList] = useState([]);
  const [images] = useState([RankingImg1, RankingImg2, RankingImg3]);

  useEffect(() => {
    const userRankList = tourCardData?.ranking || [];
    const prizeConfigList = tourCardData?.activity?.activity_rewards || [];

    if (prizeConfigList.length > 0) {
      const lastRankConfig = prizeConfigList.reduce((pre, cur) => {
        return Number(pre.end_tap_position) > Number(cur.end_tap_position) ? pre : cur;
      });

      const lastRank = Number(lastRankConfig.end_tap_position);

      let list = Array(lastRank)
        .slice(0, 10)
        .fill()
        .map((_, index) => {
          const rank = index + 1;
          const user = userRankList.find((item) => Number(item?.ranking_num) == rank);
          const prize = prizeConfigList.find(
            (item) => rank >= Number(item?.tap_position) && rank <= Number(item?.end_tap_position),
          );

          return {
            rank,
            icon: user?.icon,
            nickname: user?.nickname,
            prize: prize?.balance,
            isMe: isLogin() && user?.uid == uid,
          };
        });
      list = list.slice(0, 3);
      setBoardList(list);
    }
  }, [tourCardData]);

  const onClickMore = () => {
    navigate(toLocalePath('/tournament'));
  };

  return (
    <div
      className="w-full pt-[27.855%] overflow-hidden relative bg-[url(../assets/img/tournamentBg.png)] bg-no-repeat bg-[length:100%_100%]"
      onClick={onClickMore}
    >
      <div className="absolute inset-0 pl-[.16rem]">
        <div className="pt-[.06rem] flex items-center mb-[.1rem]">
          <div className="w-[.36rem] h-[.36rem] mr-[.08rem]">
            <Icon name={IconName.TournamentTitleIcon} />
          </div>
          <div className="text-[.2rem] font-[600] leading-[.24rem] text-[#FFF] tournament_text w-[6rem] truncate pt-[.04rem]">
            {tourCardData?.activity?.rule_explain?.activity_title || ''}
          </div>
        </div>
        <div className="flex">
          <div className="w-[3.36rem]">
            {boardList && boardList.length > 0
              ? boardList.map((item, index) => {
                  return (
                    <RankingItem
                      key={index}
                      img={images[index]}
                      nickname={item?.nickname}
                      iconNum={item?.icon}
                      isMe={item?.isMe}
                    />
                  );
                })
              : null}
          </div>
          <div className="pt-[.02rem] pl-[.02rem]">
            <div className="w-[2.92rem] h-[.76rem] rounded-[.08rem] border-[.008rem] border-[#FEE277] bg-[#FED434] box-shadow-tournament overflow-hidden mb-[.08rem]">
              <div className="h-[.24rem] bg-tournament pl-[.192rem] leading-[.24rem] text-[.12rem] font-[400] text-[#FFF]">
                {t('promo.prize_pool')}
              </div>
              <div
                className={`pl-[.18rem] pt-[.072rem] font-[900] leading-[.3rem] text-[#232624] w-[1.8rem] truncate ${String(getShowMoney(tourCardData?.activity.activity_claim_ceiling_amount || 0)).length > 7 ? 'text-[.2rem]' : 'text-[.24rem]'}`}
              >
                {currency_symbol}
                {getShowMoney(tourCardData?.activity.activity_claim_ceiling_amount || 0)}
              </div>
            </div>
            <div className="pl-[.2rem]">
              <div className="text-[.12rem] font-[400] leading-[.14rem] text-[#FFF]">{t('tour.finishIn')}</div>
              <TournamentCountdown
                date={
                  get(tourCardData, 'activity.activity_end_time')
                    ? get(tourCardData, 'activity.activity_end_time') * 1000
                    : Date.now()
                }
                onComplete={updateList}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-[url(../assets/img/tournamentBox.png)] bg-no-repeat bg-[length:100%_100%]"></div>
    </div>
  );
}

const DTournament = ({ className = '' }) => {
  const [tourCardData, setTourCardData] = useState([]);

  const updateList = () => {
    GetWebsiteTournament().then((res) => {
      if (res.code == 0) {
        const data = res.result.tournament;
        setTourCardData(data);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  return tourCardData.length > 0 ? (
    <div className={cn(`overflow-hidden ${className}`)}>
      <div>
        {tourCardData.length > 1 ? (
          <>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              speed={1000}
              loop={true}
              modules={[Autoplay]}
            >
              {tourCardData.map((data, index) => (
                <SwiperSlide key={index}>
                  <Content tourCardData={data} updateList={updateList} key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : tourCardData.length == 1 ? (
          <>
            <Content tourCardData={tourCardData[0]} updateList={updateList} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : null;
};

export default DTournament;
