import { createSlice } from '@reduxjs/toolkit';
import { get, truncate } from 'lodash';

export const FissionDialog = {
  guide: 'guide',
  step: 'step',
  mainPage: 'mainPage',
  tipSpin: 'tipSpin',
};

export const FissionDialogShowFrom = {
  init: 'init',
  eventBonus: 'eventBonus',
  step: 'step',
  banner: 'banner',
};

export const ResponsiveMode = {
  pc: 'pc',
  mobile: 'mobile',
};

export const DialogModalChildrenKey = {
  Default: '',
  LoginBox: 'LoginBox',
  SignUp: 'SignUp',
  RegisterSuccess: 'RegisterSuccess',
  PlayerMusic: 'PlayerMusic',
  ResetPwd: 'ResetPwd',
  BindEmail: 'BindEmail',
  BindPhone: 'BindPhone',
  DisplayInFiat: 'DisplayInFiat',
  WithdrawDeposit: 'WithdrawDeposit',
  Rakeback: 'Rakeback',
  StakingSubscribe: 'StakingSubscribe',
  StakingRedeem: 'StakingRedeem',
  KYC: 'KYC',
  Qrcode: 'Qrcode',
  Confirm: 'Confirm',
  TwoFa: 'TwoFa',
  GoogleAuthenticator: 'GoogleAuthenticator',
  UserInfo: 'UserInfo',
  B9c: 'B9c',
  FreeBox: 'FreeBox',
  Wheel: 'Wheel',
  PromoCodeDetail: 'PromoCodeDetail',
  PromoCodeRedeem: 'PromoCodeRedeem',
  GameProvider: 'GameProvider',
  Referral: 'Referral',
  Task: 'Task',
  FairCheck: 'FairCheck',
  GamehelpMes: 'GamehelpMes',
  B9f: 'B9f',
  DepositFiatTip: 'DepositFiatTip',
  GamePop: 'GamePop',
  VipUpgrade: 'VipUpgrade',
  BonusBox: 'BonusBox',
  InBox: 'InBox',
  EventBonus: 'EventBonus',
  TourClaim: 'TourClaim',
  GiftMoney: 'GiftMoney',
  AccountSetting: 'AccountSetting',
  WelcomeBonus: 'WelcomeBonus',
  RegisterBonus: 'RegisterBonus',
  Redeem: 'Redeem',
  DepositSuccess: 'DepositSuccess',
  Bulletin: 'Bulletin',
  PilotWheel: 'PilotWheel',
  WheelBonus: 'WheelBonus',
  ClaimBonusResultTip: 'ClaimBonusResultTip',
  RedPacket: 'RedPacket',
  ReferralCashback: 'ReferralCashback',
  ReferralAchievement: 'ReferralAchievement',
  FastDownload: 'FastDownload',
  FirstDepositDownload: 'FirstDepositDownload',
  IosSafariGuide: 'IosSafariGuide',
  RewardPwa: 'RewardPwa',
  PwaguideQRcodeModel: 'PwaguideQRcodeModel',
  Fission: 'Fission',
  BottomDownloadBoxPrompt: 'BottomDownloadBoxPrompt',
  MysteryBonusTips: 'MysteryBonusTips',
};

export const DialogModalConfigList = [
  {
    key: DialogModalChildrenKey.Default,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.LoginBox,
    needInUrl: true,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.SignUp,
    needInUrl: true,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.ResetPwd,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.BindEmail,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.BindPhone,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.DisplayInFiat,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.WithdrawDeposit,
    needInUrl: true,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.Rakeback,
    needInUrl: true,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.StakingSubscribe,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.StakingRedeem,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.KYC,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.Qrcode,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.Confirm,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.TwoFa,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.GoogleAuthenticator,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.UserInfo,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.B9c,
    needInUrl: true,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.FreeBox,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.PromoCodeDetail,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.PromoCodeRedeem,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.GameProvider,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.Referral,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.Introduce,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.Task,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.FairCheck,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.GamehelpMes,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.B9f,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.DepositFiatTip,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.GamePop,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.VipUpgrade,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.BonusBox,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.InBox,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.EventBonus,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.TourClaim,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.GiftMoney,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.WelcomeBonus,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.RegisterBonus,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.AccountSetting,
    needInUrl: false,
    needLogin: true,
  },
  {
    key: DialogModalChildrenKey.Bulletin,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.PilotWheel,
    needInUrl: false,
    needLogin: false,
  },
  {
    key: DialogModalChildrenKey.WheelBonus,
    needInUrl: false,
    needLogin: false,
  },
];

const initialState = {
  // drawerMenuVisible: window.innerWidth >= config.responsiveWidth, //当前抽屉菜单状态(false不显示，true显示)
  drawerMenuVisible: {
    isAnimation: false,
    visible: false,
  }, //当前抽屉菜单状态(false不显示，true显示)
  responsiveMode: window.innerWidth < 1005 ? ResponsiveMode.mobile : ResponsiveMode.pc,
  wideMode: false, //是否宽屏模式
  dialogModalProp: {
    zIndex: 50, //蒙层层级
    closeCb: () => {}, //关闭之后的回调
    show: DialogModalChildrenKey.Default,
    closeWithBack: false, //关闭的时候是否要返回
    confirmCb: () => {}, //确认弹出框的确认按钮回调
    content: '', //确认提示的内容
    confirmCancelCb: () => {}, //确认弹出框的取消按钮回调
    shouldCloseOnOverlayClick: true, //点击遮罩是否关闭弹框
    overlayClassName: '', //要应用于叠加层的字符串className
    withdraw_enable: 1, // 钱包是否允许提现
    isFromGame: false, //是否来自游戏
    depositData: {
      id: '',
      amount: '',
      date: '',
    }, // 充值成功弹窗数据
    handleOverlayClick: () => {},
    downloaddata: {
      visible: false,
      isForce: false, // 是否强制弹窗
      downloadText: '',
    }, //安卓下载框是否显示
    giftMoneyMoveTargetClass: '',
    modalType: null,
  },
  favoriteReload: 0, //刷新左侧收藏数量
  updateBalance: {
    //游戏通知平台更新余额显示
    currency: '',
    balance: 0,
  },
  updateGameHeight: {
    //游戏通知高度更新
    height: 0,
  },
  updateGameHelprules: '', // 游戏 帮助信息
  windowInnerWidth: 0,
  inGameType: -1, // 在游戏界面的游戏类型，0为第三方，1为自研，-1为暂时未知状态
  msgUnreadReload: 0, //刷新未读消息数量
  allowBodyScroll: true, //是否允许body滚动
  bodyScrollTop: 0, // 记录body滚动的距离
  showSearchPanel: false, //显示搜索面板
  unReadCount: 0, //未读消息数量
  showNotification: false, // 是否显示通知消息面板
  forbidden: false, //是否禁止ip访问
  isMaintain: false, //是否网站维护中
  isInSport: false, // 是否在体育中
  inSportPage: 1, // sport页面 0:sport 1:live
  openBetslip: false, // 是否打开betslip
  betslipNum: 0, // betslip数量
  needUpdateBalance: {
    need: false,
    key: 0,
    data: null,
  }, // 网站通知网址需要更新余额显示
  exchangeList: [], // 汇率列表
  allBetList: [], // 下注消息 all
  bigBetList: [], // 下注消息 big
  myBetList: [], // 下注消息 my
  contentMask: {
    show: false,
    handleClose: () => {},
    href: '',
  }, // 是否显示内容区遮罩
  timeLimitBonus: {
    //新用户限时bonus浮框显示的数据
    reward_rate: '',
    endTime: '',
  },
  zoomNum: 1, //缩放比例
  disconnect: false, // 监听网络是否断开
  taskPushInfo: null, //推送的任务完成的消息
  showTaskFixedMenuType: false, //是否显示task入口icon放大动画
  loginStatus: false, //登录状态
  withDrawChange: false, // gengxinyue
  taskUnClaimData: {
    userAcheNum: 0,
    userTaskNum: 0,
  },
  taskOpenTitleType: undefined,
  openWheel: 0, //打开的转盘 0为普通轮盘 or 1为任务lunpan
  fairCheckData: [], //公平验证数据
  ipCountry: undefined, // 请求接口的ip对应国家的编码, 例: JP, BR
  inFreebox: false, //  当Free box 可领取 状态为true
  inStarwheel: false, //  当Star wheel 可转的时候 状态为true
  gamePopData: {
    // 游戏弹窗数据
    gameUrl: '',
    gameHtml: '',
    source: 0,
    gameUuid: '',
  },
  showPromo: true, //首页是否展示promotion图片弹框
  globalLoadingFlag: false, //全局loading开关
  dialogModalCount: 0, // 弹窗数量
  showInbox: false, //站内信开关
  showSupportNew: false, //客服开关
  soundFlag: false, //音乐开关
  showHeaderUserinfo: false, //是否展示用户信息下拉框
  needUpdateRecently: {
    need: false,
    key: 0,
  }, // 网站通知网址需要更新rencently列表
  needCheckedLobby: {
    need: false,
    key: 0,
  }, //点击底部导航的home键切换到lobby标签
  maintainInfo: null, //网站维护信息
  isSportEnabled: false, //体育是否可用
  gameExitTimestamp: -1, // 游戏初始化失败，需要调用游戏退出时，更新这个时间戳
  fbBtn: '', // fb的按钮 透传给cpay
  downloadBoxPromptSwitch: false, //安卓下载框是否显示
  showDownloadBoxPrompt: true, //是否是第一次进入网站并显示安卓下载提示框
  bottomDownloadBoxPromptSwitch: {
    visible: false,
    isAni: false,
    isForce: false, // 是否强制弹窗
    downloadText: '',
  }, //安卓下载框是否显示
  bottomShowDownloadBoxPrompt: false, //是否是第一次进入网站并显示安卓下载提示框
  activityStatus: null,
  //{
  // activity_task: '0', //活动task (0关1开)
  // activity_task_switch: '0', //活动task 开关(0关1开)
  // turntable_lottery: '0', //转盘抽奖 (0关1开)
  // activity_relief_money_switch: '0', //活动救济金开关(0关1开)
  // activity_relief_money: '0', //活动救济金(0关1开)
  // activity_tournament: '0', //锦标赛(0关1开)
  // activity_tournament_switch: '0', //锦标赛(0关1开)
  // redeem_code_switch: '0', // 兑换码开关
  // activity_contract_switch: '0', //裂变活动开关
  // activity_wheel_show_icon: [], // 活动轮播
  // activity_novice_guide_tournament_switch: '0', // 新手引导轮盘总开关
  //},
  scheduleNum: 0,
  audioPlayerStatus: {
    isPlaying: false,
    musicName: '',
    playMode: 'loop',
    timeTick: '00:00',
    timeCount: '00:00',
    currentIndex: 0,
    range: 0,
    loading: false,
  },
  autoScrollToTagGameKey: '0',
  reloadCashRain: 0,
  needLogout: {
    // 需要退出登录。 showLoginBox：是否弹出登录弹窗
    showLoginBox: false,
    timestamp: 0,
  },
  guideToBind: {
    // 引导去绑定。 showGuideTips:是否弹出引导提示
    showGuideTips: false,
    timestamp: 0,
  },
  welcomeBonusData: null,
  fixedMenuCoordinateShowNumbers: 0,
  fixedMenuCoordinateGiftMoney: {
    show: false,
    top: -1000,
  },
  fixedMenuCoordinateWelcomeBonus: {
    show: false,
    top: -1000,
  },
  fixedMenuCoordinateCashRain: {
    show: false,
    top: -1000,
  },
  userClaimTotalMoney: 0,
  fissionDialog: {
    show: false,
    content: FissionDialog.guide,
    from: FissionDialogShowFrom.init,
  },
  langServerData: [],
  footerData: [],
  layoutData: null,
  showTipsBeforeLeaveRegister: false,
  guideWheelSwitch: false, // 轮盘是否奖励 是否可以领取
  wheelBonusAmount: 0, // 轮盘奖励金额
  activityData: null, //首次加载的活动数据
  refetchActivityData: 0, //重新获取活动数据
  mobileTagIndex: '1011', //侧导航标签列表选中
  popularIndex: '1011', //侧导航标签列表选中
  tagIndex: '000', //layout10002标签选中
  refetchVipData: 0, //重新获取vip数据
  websiteInitData: null, //网站初始化数据
  reloadWebsiteInitData: 0, // 重新请求网站初始化数据
  websiteInitConfig: null, //网站初始化配置
  showHeaderAndFooter: false,
  showMobileMenuBar: false,
  androidApkInfo: null,
  installStatus: 0, // 安装状态(0未安装, 1安装中, 2安装成功)
  visibleBackTopBtn: false, // 是否显示返回顶部按钮
  showWGDeposit: false, //是否显示WG模板的充值面板
  showPandaDeposit: false, //是否显示熊猫模板的充值面板
  showPandaWithdraw: false, //是否显示熊猫模板的提现面板
  promotionClaimedCount: null, // promotion 红点
  promotionPendingUpdateTimestamp: 0, // 非0时，更新promotion的pending列表
  downloadLadingPageLink: '',
  showHeaderGiftMoney: false, //是否显示顶部的giftmoney
  headerGiftMoneyBalance: 0, //顶部的giftmoney金额
  needUpdateGiftMoney: {
    need: false,
    key: 0,
    data: null,
    fromPoint: null,
  }, // 需要更新giftmoney金额
  activitySwiperSlideKey: '',
  isShowPromotionBarGiftMoneyCoin: false, // 是否显示promotion导航条里giftmoney上的旋转金币
  needUpdateDepositSuccess: {
    need: false,
    key: 0,
  }, // 网站通知充值成功
  depositSuccessPwaGuideData: null, // 弹出充值成功弹窗的pwa引导数据
  exitGameTime: 0, // 退出游戏时间戳
  isGameOpen: false, // 游戏是否是打开状态
  mystreyBonusPopupShowStatus: false, // 是否已经弹出成功报名神秘彩金活动弹窗
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDrawerMenuVisible: (state, { payload }) => {
      state.drawerMenuVisible = payload;
      if (payload) {
        window.___drawerMenuIsFold = false;
      } else {
        window.___drawerMenuIsFold = true;
      }
    },
    setDialogModalVisible: (state, { payload }) => {
      if (
        payload.show &&
        (payload.show === DialogModalChildrenKey.LoginBox || payload.show === DialogModalChildrenKey.SignUp)
      ) {
        payload.overlayClassName = 'login_signup_bg';
      }
      if (payload.show === DialogModalChildrenKey.Default) {
        state.dialogModalProp = { ...state.dialogModalProp, show: DialogModalChildrenKey.Default };
      } else {
        state.dialogModalProp = payload;
      }
    },
    setLoginStatus: (state, { payload }) => {
      state.loginStatus = payload;
    },
    setWithDrawChange: (state, { payload }) => {
      state.withDrawChange = payload;
    },
    setResponsiveMode: (state, { payload }) => {
      state.responsiveMode = payload;
    },
    setWideMode: (state, { payload }) => {
      state.wideMode = payload;
    },
    setFavoriteReload: (state, { payload }) => {
      state.favoriteReload = new Date().getTime();
    },
    setUpdateBalance: (state, { payload }) => {
      state.updateBalance = payload;
    },
    setUpdateGameHeight: (state, { payload }) => {
      state.updateGameHeight = payload;
    },
    setUpdateGameHelprules: (state, { payload }) => {
      state.updateGameHelprules = payload;
    },
    setInGameType: (state, { payload }) => {
      state.inGameType = payload;
    },
    setMsgUnreadReload: (state, { payload }) => {
      state.msgUnreadReload = new Date().getTime();
    },
    setWindowInnerWidth: (state, { payload }) => {
      state.windowInnerWidth = payload;
    },
    setAllowBodyScroll: (state, { payload }) => {
      if (!payload) {
        state.bodyScrollTop = document.body.scrollTop + document.documentElement.scrollTop;
      }
      state.allowBodyScroll = payload;
    },
    setShowSearchPanel: (state, { payload }) => {
      state.showSearchPanel = payload;
      if (payload) {
        state.bodyScrollTop = document.body.scrollTop + document.documentElement.scrollTop;
        state.allowBodyScroll = false;
      } else {
        state.allowBodyScroll = true;
      }
    },
    setUnReadCount: (state, { payload }) => {
      state.unReadCount = payload;
    },
    setShowNotification: (state, { payload }) => {
      state.showNotification = payload;
    },
    setForbidden: (state, { payload }) => {
      state.forbidden = payload;
    },
    setIsMaintain: (state, { payload }) => {
      state.isMaintain = payload;
    },
    setInSport: (state, { payload }) => {
      state.isInSport = payload;
    },
    setInSportPage: (state, { payload }) => {
      state.inSportPage = payload;
    },
    setOpenBetslip: (state, { payload }) => {
      state.openBetslip = payload;
    },
    setBetslipNum: (state, { payload }) => {
      state.betslipNum = payload;
    },
    setNeedUpdateBalance: (state, { payload }) => {
      const need = payload.need;
      state.needUpdateBalance.need = need;
      state.needUpdateBalance.key = need ? Date.now() : 0;
      state.needUpdateBalance.data = payload.data || null;
    },
    setExchangeList: (state, { payload }) => {
      state.exchangeList = payload || [];
    },
    setAllBetList: (state, { payload }) => {
      state.allBetList = payload || [];
    },
    setBigBetList: (state, { payload }) => {
      state.bigBetList = payload || [];
    },
    setMyBetList: (state, { payload }) => {
      state.myBetList = payload || [];
    },
    setContentMask: (state, { payload }) => {
      state.contentMask = payload;
    },
    setTimeLimitBonus: (state, { payload }) => {
      state.timeLimitBonus = payload;
    },
    setZoomNum: (state, { payload }) => {
      state.zoomNum = payload;
    },
    setdisconnect: (state, { payload }) => {
      state.disconnect = payload;
    },
    setTaskPushInfo: (state, { payload }) => {
      state.taskPushInfo = payload;
    },
    setShowTaskFixedMenuType: (state, { payload }) => {
      state.showTaskFixedMenuType = payload;
    },
    setTaskUnClaimData: (state, { payload }) => {
      state.taskUnClaimData = payload;
    },
    setTaskOpenTitleType: (state, { payload }) => {
      state.taskOpenTitleType = payload;
    },
    setopenWheel: (state, { payload }) => {
      state.openWheel = payload;
    },
    setFairCheckData: (state, { payload }) => {
      state.fairCheckData = payload;
    },
    setIpCountry: (state, { payload }) => {
      state.ipCountry = payload;
    },
    setinfreebox: (state, { payload }) => {
      state.inFreebox = payload;
    },
    setinStarwheel: (state, { payload }) => {
      state.inStarwheel = payload;
    },
    setGamePopData: (state, { payload }) => {
      state.gamePopData = payload;
    },
    setShowPromo: (state, { payload }) => {
      state.showPromo = payload;
    },
    setGlobalLoadingFlag: (state, { payload }) => {
      state.globalLoadingFlag = payload;
    },
    incrementDialogModalCount: (state) => {
      state.dialogModalCount++;
    },
    decrementDialogModalCount: (state) => {
      state.dialogModalCount--;
    },
    setShowInbox: (state, { payload }) => {
      state.showInbox = payload;
    },
    setShowSupportNew: (state, { payload }) => {
      state.showSupportNew = payload;
    },
    setSoundFlag: (state, { payload }) => {
      state.soundFlag = payload;
    },
    setShowHeaderUserinfo: (state, { payload }) => {
      state.showHeaderUserinfo = payload;
    },
    setNeedUpdateRecently: (state, { payload }) => {
      const need = payload.need;
      state.needUpdateRecently.need = need;
      state.needUpdateRecently.key = need ? Date.now() : 0;
    },
    setNeedCheckedLobby: (state, { payload }) => {
      const need = payload.need;
      state.needCheckedLobby.need = need;
      state.needCheckedLobby.key = need ? Date.now() : 0;
    },
    setMaintainInfo: (state, { payload }) => {
      state.maintainInfo = payload;
    },
    setSportEnabled: (state, { payload }) => {
      state.isSportEnabled = payload;
    },
    setGameExitTimestamp: (state, { payload }) => {
      state.gameExitTimestamp = payload;
    },
    setFbBtn: (state, { payload }) => {
      state.fbBtn = payload;
    },
    setDownloadBoxPromptSwitch: (state, { payload }) => {
      state.downloadBoxPromptSwitch = payload;
    },
    setShowDownloadBoxPrompt: (state, { payload }) => {
      state.showDownloadBoxPrompt = payload;
    },
    setBottomDownloadBoxPromptSwitch: (state, { payload }) => {
      state.bottomDownloadBoxPromptSwitch = payload;
    },
    setBottomShowDownloadBoxPrompt: (state, { payload }) => {
      state.bottomShowDownloadBoxPrompt = payload;
    },
    setActivityStatus: (state, { payload }) => {
      state.activityStatus = payload;
    },
    setaudioPlayerStatus: (state, { payload }) => {
      state.audioPlayerStatus = payload;
    },
    setAutoScrollToTagGameKey: (state, { payload }) => {
      state.autoScrollToTagGameKey = payload;
    },
    setReloadCashRain: (state) => {
      state.reloadCashRain = new Date().getTime();
    },
    setNeedLogout: (state, { payload }) => {
      state.needLogout.showLoginBox = payload?.showLoginBox || false;
      state.needLogout.timestamp = Date.now();
    },
    setGuideToBind: (state, { payload }) => {
      state.guideToBind.showGuideTips = payload?.showGuideTips || false;
      state.guideToBind.timestamp = Date.now();
    },
    setWelcomeBonusData: (state, { payload }) => {
      state.welcomeBonusData = payload;
    },
    setFixedMenuCoordinateGiftMoney: (state, { payload }) => {
      state.fixedMenuCoordinateGiftMoney = payload;
    },
    setFixedMenuCoordinateWelcomeBonus: (state, { payload }) => {
      state.fixedMenuCoordinateWelcomeBonus = payload;
    },
    setFixedMenuCoordinateCashRain: (state, { payload }) => {
      state.fixedMenuCoordinateCashRain = payload;
    },
    setFixedMenuCoordinateShowNumbers: (state, { payload }) => {
      state.fixedMenuCoordinateShowNumbers = payload;
    },
    setUserClaimTotalMoney: (state, { payload }) => {
      state.userClaimTotalMoney = payload;
    },
    setFissionDialog: (state, { payload }) => {
      state.fissionDialog = payload;
    },
    setLangServerData: (state, { payload }) => {
      state.langServerData = payload;
    },
    setFooterData: (state, { payload }) => {
      state.footerData = payload;
    },
    setLayoutData: (state, { payload }) => {
      state.layoutData = payload;
    },
    setShowTipsBeforeLeaveRegister: (state, { payload }) => {
      state.showTipsBeforeLeaveRegister = payload;
    },
    setguideWheelSwitch: (state, { payload }) => {
      state.guideWheelSwitch = payload;
    },
    setscheduleNum: (state, { payload }) => {
      state.scheduleNum = payload;
    },
    setActivityData: (state, { payload }) => {
      state.activityData = payload;
    },
    setActivityItemData: (state, { payload }) => {
      state.activityData = { ...state.activityData, ...payload };
    },
    resetActivityData: (state, { payload }) => {
      state.activityData = { ...state.activityData, isSetAutoPopup: false };
    },
    setRefetchActivityData: (state, { payload }) => {
      state.refetchActivityData = new Date().getTime();
    },
    setwheelBonusAmount: (state, { payload }) => {
      state.wheelBonusAmount = payload;
    },
    setMobileTagIndex: (state, { payload }) => {
      state.mobileTagIndex = payload;
    },
    setPpopularIndex: (state, { payload }) => {
      state.popularIndex = payload;
    },
    setTagIndex: (state, { payload }) => {
      state.tagIndex = payload;
    },
    setRefetchVipData: (state, { payload }) => {
      state.refetchVipData = new Date().getTime();
    },
    setWebsiteInitData: (state, { payload }) => {
      state.websiteInitData = payload;
    },
    setWebsiteInitConfig: (state, { payload }) => {
      state.websiteInitConfig = payload;
    },
    setAndroidApkInfo: (state, { payload }) => {
      state.androidApkInfo = payload;
    },
    setShowHeaderAndFooter: (state, { payload }) => {
      state.showHeaderAndFooter = payload;
    },
    setShowMobileMenuBar: (state, { payload }) => {
      state.showMobileMenuBar = payload;
    },
    setInstallStatus: (state, { payload }) => {
      state.installStatus = payload;
    },
    setvisibleBackTopBtn: (state, { payload }) => {
      state.visibleBackTopBtn = payload;
    },
    setReloadWebsiteInitData: (state, { payload }) => {
      state.reloadWebsiteInitData = new Date().getTime();
    },
    setShowWGDeposit: (state, { payload }) => {
      state.showWGDeposit = payload;
    },
    setShowPandaDeposit: (state, { payload }) => {
      state.showPandaDeposit = payload;
    },
    setShowPandaWithdraw: (state, { payload }) => {
      state.showPandaWithdraw = payload;
    },
    setPromotionClaimedCount: (state, { payload }) => {
      state.promotionClaimedCount = payload;
    },
    setPromotionPendingUpdateTimestamp: (state, { payload }) => {
      state.promotionPendingUpdateTimestamp = payload == 0 ? 0 : Date.now();
    },
    setDownloadLadingPageLink: (state, { payload }) => {
      state.downloadLadingPageLink = payload;
    },
    setShowHeaderGiftMoney: (state, { payload }) => {
      state.showHeaderGiftMoney = payload;
    },
    setActivitySwiperSlideKey: (state, { payload }) => {
      state.activitySwiperSlideKey = payload;
    },
    setNeedUpdateGiftMoney: (state, { payload }) => {
      const need = payload.need;
      state.needUpdateGiftMoney.need = need;
      state.needUpdateGiftMoney.key = need ? Date.now() : 0;
      state.needUpdateGiftMoney.data = payload.data || null;
      state.needUpdateGiftMoney.fromPoint = payload.fromPoint || null;

      state.headerGiftMoneyBalance = payload.data || 0;
      // state.headerGiftMoneyBalance = Number(state.headerGiftMoneyBalance) + (Number(payload.data) || 0);
    },

    setIsShowPromotionBarGiftMoneyCoin: (state, { payload }) => {
      state.isShowPromotionBarGiftMoneyCoin = payload;
    },
    setDepositSuccessPwaGuideData: (state, { payload }) => {
      state.depositSuccessPwaGuideData = payload;
    },
    setExitGameTime: (state, { payload }) => {
      state.exitGameTime = payload;
    },
    setisGameOpen: (state, { payload }) => {
      state.isGameOpen = payload;
    },

    setMystreyBonusPopupShowStatus: (state, { payload }) => {
      state.mystreyBonusPopupShowStatus = payload;
    },

    setNeedUpdateDepositSuccess: (state, { payload }) => {
      const need = payload.need;
      state.needUpdateDepositSuccess.need = need;
      state.needUpdateDepositSuccess.key = need ? Date.now() : 0;
    },
  },
});

export const {
  setDrawerMenuVisible,
  setDialogModalVisible,
  setResponsiveMode,
  setWideMode,
  setFavoriteReload,
  setUpdateBalance,
  setUpdateGameHeight,
  setInGameType,
  setMsgUnreadReload,
  setWindowInnerWidth,
  setAllowBodyScroll,
  setShowSearchPanel,
  setUnReadCount,
  setShowNotification,
  setForbidden,
  setIsMaintain,
  setInSport,
  setNeedUpdateBalance,
  setExchangeList,
  setAllBetList,
  setBigBetList,
  setMyBetList,
  setInSportPage,
  setOpenBetslip,
  setBetslipNum,
  setContentMask,
  setTimeLimitBonus,
  setZoomNum,
  setdisconnect,
  setTaskPushInfo,
  setShowTaskFixedMenuType,
  setTaskUnClaimData,
  setTaskOpenTitleType,
  setopenWheel,
  setUpdateGameHelprules,
  setFairCheckData,
  setIpCountry,
  setinfreebox,
  setinStarwheel,
  setLoginStatus,
  setWithDrawChange,
  setGamePopData,
  setShowPromo,
  setGlobalLoadingFlag,
  incrementDialogModalCount,
  decrementDialogModalCount,
  setShowInbox,
  setShowSupportNew,
  setSoundFlag,
  setShowHeaderUserinfo,
  setNeedUpdateRecently,
  setNeedCheckedLobby,
  setMaintainInfo,
  setSportEnabled,
  setGameExitTimestamp,
  setFbBtn,
  setDownloadBoxPromptSwitch,
  setShowDownloadBoxPrompt,
  setActivityStatus,
  setAutoScrollToTagGameKey,
  setReloadCashRain,
  setNeedLogout,
  setGuideToBind,
  setWelcomeBonusData,
  setFixedMenuCoordinateWelcomeBonus,
  setFixedMenuCoordinateGiftMoney,
  setFixedMenuCoordinateCashRain,
  setFixedMenuCoordinateShowNumbers,
  setUserClaimTotalMoney,
  setFissionDialog,
  setLangServerData,
  setFooterData,
  setLayoutData,
  setGameTypeList,
  setShowTipsBeforeLeaveRegister,
  setBottomDownloadBoxPromptSwitch,
  setBottomShowDownloadBoxPrompt,
  setguideWheelSwitch,
  setActivityData,
  setActivityItemData,
  setRefetchActivityData,
  setscheduleNum,
  setaudioPlayerStatus,
  setwheelBonusAmount,
  setMobileTagIndex,
  setPpopularIndex,
  setTagIndex,
  setRefetchVipData,
  setWebsiteInitData,
  setWebsiteInitConfig,
  setAndroidApkInfo,
  setShowHeaderAndFooter,
  setShowMobileMenuBar,
  setReloadWebsiteInitData,
  setInstallStatus,
  setvisibleBackTopBtn,
  setShowWGDeposit,
  setShowPandaDeposit,
  setShowPandaWithdraw,
  resetActivityData,
  setPromotionClaimedCount,
  setPromotionPendingUpdateTimestamp,
  setDownloadLadingPageLink,
  setShowHeaderGiftMoney,
  setNeedUpdateGiftMoney,
  setActivitySwiperSlideKey,
  setIsShowPromotionBarGiftMoneyCoin,
  setNeedUpdateDepositSuccess,
  setDepositSuccessPwaGuideData,
  setExitGameTime,
  setisGameOpen,
  setMystreyBonusPopupShowStatus,
} = appSlice.actions;

export default appSlice.reducer;
