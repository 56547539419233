import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AnalyticsManager, { FirebaseEvents } from '../manager/analyticsManager';
import { DialogModalChildrenKey, setDialogModalVisible } from '../redux/reducers/appSlice';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toLocalePath } from '../utils/localePath';
import useRouterHelper from './useRouterHelper';
import { getTemplateId, isLogin } from '../utils/helper';
import { useGlobalNavigate } from '../contexts/navigateContext';

// activity_novice_guide 引导轮盘 同下
// activity_novice_guide_turntable 引导轮盘（手动领取）
// activity_promotional_achievements 推广成就（手动领取）
// activity_promotional_cashback 推广返现（手动领取）
// activity_novice 迎新活动（手动领取）
// deposit_bonus_1 首充（手动领取，自动发放）
// deposit_bonus_2 每日充值（手动领取，自动发放）
// invitation_conquest 邀请成就（手动领取）
// invitation_bonus  存款反现（手动领取，自动发放）
// activity_tournament 锦标赛（手动领取）
// activity_bonus 闯关（手动领取）
// activity_relief_bonus 救援金（手动领取）
// rakeback      返水（手动领取）
// gift_money_claimed         gift_money（手动领取）
// rank_reward          vip升级（手动领取）
// weekly_reward          周俸（手动领取）
// monthly_reward        月俸 （手动领取）
// activity_red_packet        现金雨
// activity_redeem_code        兑换码
// mission_binding_cpf    任务
// mission_binding_phone  任务
// mission_binding_mail   任务
// activity_mystery_bonus   神秘彩金

const useActivityJump = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const { onScroll } = useOutletContext();

  const { defaultLang, identity, activityData } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
      identity: state?.user?.identity,
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );

  const { handleClickWallet } = useRouterHelper();

  const activityKeyList = {
    activity_novice_guide: 'activity_novice_guide',
    activity_novice_guide_turntable: 'activity_novice_guide_turntable',
    activity_promotional_achievements: 'activity_promotional_achievements',
    activity_promotional_cashback: 'activity_promotional_cashback',
    activity_novice: 'activity_novice',
    deposit_bonus_1: 'deposit_bonus_1',
    deposit_bonus_2: 'deposit_bonus_2',
    invitation_conquest: 'invitation_conquest',
    invitation_bonus: 'invitation_bonus',
    activity_tournament: 'activity_tournament',
    activity_bonus: 'activity_bonus',
    activity_relief_bonus: 'activity_relief_bonus',
    rakeback: 'rakeback',
    gift_money_claimed: 'gift_money_claimed',
    rank_reward: 'rank_reward',
    weekly_reward: 'weekly_reward',
    monthly_reward: 'monthly_reward',
    activity_red_packet: 'activity_red_packet',
    activity_redeem_code: 'activity_redeem_code',
    mission_binding_cpf: 'mission_binding_cpf',
    mission_binding_phone: 'mission_binding_phone',
    mission_binding_mail: 'mission_binding_mail',
    activity_mystery_bonus: 'activity_mystery_bonus',
  };

  const claimableActivityKeyList = [
    activityKeyList.activity_novice_guide,
    activityKeyList.activity_novice_guide_turntable,
    activityKeyList.activity_promotional_achievements,
    activityKeyList.activity_novice,
    activityKeyList.deposit_bonus_1,
    activityKeyList.deposit_bonus_2,
    activityKeyList.invitation_conquest,
    activityKeyList.invitation_bonus,
    activityKeyList.activity_tournament,
    activityKeyList.activity_bonus,
    activityKeyList.activity_relief_bonus,
    activityKeyList.activity_mystery_bonus,
  ];

  const jumpActivityKeyList = [
    activityKeyList.rakeback,
    activityKeyList.gift_money_claimed,
    activityKeyList.rank_reward,
    activityKeyList.weekly_reward,
    activityKeyList.monthly_reward,
    activityKeyList.activity_promotional_cashback,
    activityKeyList.activity_red_packet,
    activityKeyList.activity_redeem_code,
    activityKeyList.mission_binding_cpf,
    activityKeyList.mission_binding_phone,
    activityKeyList.mission_binding_mail,
  ];

  const [activityList, setActivityList] = useState([]);

  const showCloseTip = (key) => {
    console.log('showCloseTip', key);

    const confirmCb = () => {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
    };

    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Confirm,
        confirmCb,
        content: <span className="">{t('account.coming_soon')}</span>,
        cancelText: t('game.cancel'),
        confirmText: t('game.ok'),
        isConfirmBtnPrimary: false,
      }),
    );
  };

  useEffect(() => {
    setActivityList([
      {
        key: activityKeyList.activity_novice_guide,
        name: t('promotion.name_activity_novice_guide'),
        source: t('promotion.source_activity_novice_guide'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_novice_guide);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_novice_guide);
          } else {
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.PilotWheel,
              }),
            );
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WheelWindow_Popup);
          }
        },
      },
      {
        key: activityKeyList.activity_novice_guide_turntable,
        name: t('promotion.name_activity_novice_guide_turntable'),
        source: t('promotion.source_activity_novice_guide_turntable'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_novice_guide_turntable);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_novice_guide_turntable);
          } else {
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.PilotWheel,
              }),
            );
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WheelWindow_Popup);
          }
        },
      },
      {
        key: activityKeyList.activity_promotional_achievements,
        name: t('promotion.name_activity_promotional_achievements'),
        source: t('promotion.source_activity_promotional_achievements'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_promotional_achievements);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_promotional_achievements);
          } else {
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.ReferralAchievement,
              }),
            );
          }
        },
      },
      {
        key: activityKeyList.activity_promotional_cashback,
        name: t('promotion.name_activity_promotional_cashback'),
        source: t('promotion.source_activity_promotional_cashback'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_promotional_cashback);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_promotional_cashback);
          } else {
            dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.ReferralCashback }));
          }
        },
      },
      {
        key: activityKeyList.activity_novice,
        name: t('promotion.name_activity_novice'),
        source: t('promotion.source_activity_novice'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_novice);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_novice);
          } else {
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.WelcomeBonus,
              }),
            );
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_WB_Floating);
          }
        },
      },
      // promotion里的跳转使用onScroll, 外面的使用navigate
      {
        key: activityKeyList.deposit_bonus_1,
        name: t('promotion.name_deposit_bonus_1'),
        source: t('promotion.source_deposit_bonus_1'),
        onJump: (isExpired) => {
          console.log(activityKeyList.deposit_bonus_1);

          if (isExpired) {
            showCloseTip(activityKeyList.deposit_bonus_1);
          } else {
            // onScroll('/promotion/events');
            handleClickWallet();
          }
        },
      },
      {
        key: activityKeyList.deposit_bonus_2,
        name: t('promotion.name_deposit_bonus_2'),
        source: t('promotion.source_deposit_bonus_2'),
        onJump: (isExpired) => {
          console.log(activityKeyList.deposit_bonus_2);

          if (isExpired) {
            showCloseTip(activityKeyList.deposit_bonus_2);
          } else {
            // onScroll('/promotion/events');
            handleClickWallet();
          }
        },
      },
      {
        key: activityKeyList.invitation_conquest,
        name: t('promotion.name_invitation_conquest'),
        source: t('promotion.source_invitation_conquest'),
        onJump: (isExpired) => {
          console.log(activityKeyList.invitation_conquest);

          if (isExpired) {
            showCloseTip(activityKeyList.invitation_conquest);
          } else {
            // onScroll('/promotion/events');
            navigate(toLocalePath(`/referral`));
          }
        },
      },
      {
        key: activityKeyList.invitation_bonus,
        name: t('promotion.name_invitation_bonus'),
        source: t('promotion.source_invitation_bonus'),
        onJump: (isExpired) => {
          console.log(activityKeyList.invitation_bonus);
          // onScroll('/promotion/events');

          if (isExpired) {
            showCloseTip(activityKeyList.invitation_bonus);
          } else {
            onScroll('/promotion/events');
          }
        },
      },
      {
        key: activityKeyList.activity_tournament,
        name: t('promotion.name_activity_tournament'),
        source: t('promotion.source_activity_tournament'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_tournament);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_tournament);
          } else {
            navigate(toLocalePath('/tournament'));
          }
        },
      },
      {
        key: activityKeyList.activity_bonus,
        name: t('promotion.name_activity_bonus'),
        source: t('promotion.source_activity_bonus'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_bonus);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_bonus);
          } else {
            navigate(toLocalePath('/task/1'));
          }
        },
      },
      {
        key: activityKeyList.activity_relief_bonus,
        name: t('promotion.name_activity_relief_bonus'),
        source: t('promotion.source_activity_relief_bonus'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_relief_bonus);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_relief_bonus);
          } else {
            navigate(toLocalePath('/task/2'));
          }
        },
      },
      {
        key: activityKeyList.rakeback,
        name: t('promotion.name_rakeback'),
        source: t('promotion.source_rakeback'),
        onJump: (isExpired) => {
          console.log(activityKeyList.rakeback);

          if (isExpired) {
            showCloseTip(activityKeyList.rakeback);
          } else {
            if (identity) {
              onScroll('/promotion/rakeback');
            } else {
              dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
            }
          }
        },
      },
      {
        key: activityKeyList.gift_money_claimed,
        name: t('promotion.name_gift_money_claimed'),
        source: t('promotion.source_gift_money_claimed'),
        onJump: (isExpired) => {
          console.log(activityKeyList.gift_money_claimed);

          if (isExpired) {
            showCloseTip(activityKeyList.gift_money_claimed);
          } else {
            if (identity) {
              onScroll('/promotion/giftmoney');
            } else {
              dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
            }
          }
        },
      },
      {
        key: activityKeyList.rank_reward,
        name: t('promotion.name_rank_reward'),
        source: t('promotion.source_rank_reward'),
        onJump: (isExpired) => {
          console.log(activityKeyList.rank_reward);

          if (isExpired) {
            showCloseTip(activityKeyList.rank_reward);
          } else {
            onScroll('/promotion/vip');
          }
        },
      },
      {
        key: activityKeyList.weekly_reward,
        name: t('promotion.name_weekly_reward'),
        source: t('promotion.source_weekly_reward'),
        onJump: (isExpired) => {
          console.log(activityKeyList.weekly_reward);

          if (isExpired) {
            showCloseTip(activityKeyList.weekly_reward);
          } else {
            onScroll('/promotion/vip');
          }
        },
      },
      {
        key: activityKeyList.monthly_reward,
        name: t('promotion.name_monthly_reward'),
        source: t('promotion.source_monthly_reward'),
        onJump: (isExpired) => {
          console.log(activityKeyList.monthly_reward);

          if (isExpired) {
            showCloseTip(activityKeyList.monthly_reward);
          } else {
            onScroll('/promotion/vip');
          }
        },
      },
      {
        key: activityKeyList.activity_red_packet,
        name: t('promotion.name_activity_red_packet'),
        source: t('promotion.source_activity_red_packet'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_red_packet);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_red_packet);
          } else {
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_CashRain_Floating);

            if (!isLogin()) {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.LoginBox,
                }),
              );
            } else {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.RedPacket,
                  overlayClassName: `${getTemplateId() != 'd-template003' ? '!bg-[rgba(0,0,0,0)]' : ''}`,
                }),
              );
            }
          }
        },
      },
      {
        key: activityKeyList.activity_redeem_code,
        name: t('promotion.name_activity_redeem_code'),
        source: t('promotion.source_activity_redeem_code'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_redeem_code);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_redeem_code);
          } else {
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.Redeem,
              }),
            );
          }
        },
      },
      {
        key: activityKeyList.mission_binding_cpf,
        name: t('promotion.name_mission_binding_cpf'),
        source: t('promotion.source_mission_binding_cpf'),
        onJump: (isExpired) => {
          console.log(activityKeyList.mission_binding_cpf);

          if (isExpired) {
            showCloseTip(activityKeyList.mission_binding_cpf);
          } else {
            onScroll('/promotion/mission');
          }
        },
      },
      {
        key: activityKeyList.mission_binding_phone,
        name: t('promotion.name_mission_binding_phone'),
        source: t('promotion.source_mission_binding_phone'),
        onJump: (isExpired) => {
          console.log(activityKeyList.mission_binding_phone);

          if (isExpired) {
            showCloseTip(activityKeyList.mission_binding_phone);
          } else {
            onScroll('/promotion/mission');
          }
        },
      },
      {
        key: activityKeyList.mission_binding_mail,
        name: t('promotion.name_mission_binding_mail'),
        source: t('promotion.source_mission_binding_mail'),
        onJump: (isExpired) => {
          console.log(activityKeyList.mission_binding_mail);

          if (isExpired) {
            showCloseTip(activityKeyList.mission_binding_mail);
          } else {
            onScroll('/promotion/mission');
          }
        },
      },
      {
        key: activityKeyList.activity_mystery_bonus,
        name: t('promotion.name_activity_mystery_bonus'),
        source: t('promotion.source_activity_mystery_bonus'),
        onJump: (isExpired) => {
          console.log(activityKeyList.activity_mystery_bonus);

          if (isExpired) {
            showCloseTip(activityKeyList.activity_mystery_bonus);
          } else {
            navigate(toLocalePath('/mysterybonus'));
          }
        },
      },
    ]);
  }, [defaultLang]);

  console.log('activityList', activityList.length);

  const getActivityName = (key) => {
    const found = activityList.find((item) => item.key === key);
    return found?.name || '';
  };

  const getActivitySource = (key) => {
    const found = activityList.find((item) => item.key === key);
    return found?.source || '';
  };

  const getJumpHandler = (key, isExpired) => {
    const found = activityList.find((item) => item.key === key);
    if (found) {
      return () => {
        found.onJump(isExpired);
      };
    } else {
      return () => {};
    }
  };

  return {
    activityList,
    activityKeyList,
    getActivityName,
    getActivitySource,
    getJumpHandler,
    claimableActivityKeyList,
    jumpActivityKeyList,
  };
};

export default useActivityJump;
