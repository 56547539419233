import Icon, { IconName } from '../../icon';

const Header = ({ title = '', handleClick }) => {
  return (
    <div className="w-full h-[.96rem] absolute top-[0] left-[0] bg-[var(--colour-86)]">
      <div className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-[.36rem] font-[500] text-[var(--colour-48)] w-[3.44rem] text-center truncate">
        {title}
      </div>
      <div className="absolute left-[.44rem] top-[50%] translate-y-[-50%]">
        <div className="w-[.12rem] h-[.22rem] rotate-180 cursor-pointer text-[var(--colour-11)]" onClick={handleClick}>
          <Icon name={IconName.BannerNext2}></Icon>
        </div>
      </div>
    </div>
  );
};

const popBgFullScreen2 = ({ title = '', children, handleClose = () => {}, className = '' }) => {
  return (
    <div
      className={`pt-[.96rem] w-[var(--theme-max-width)] bg-[var(--colour-54)] relative select-none flex flex-col ${className}`}
      style={{
        height: `${window.innerHeight}px`,
      }}
    >
      <Header title={title} handleClick={handleClose} />
      <div className="flex-1 h-[0] overflow-y-scroll scrollbar-none">{children}</div>
    </div>
  );
};

export default popBgFullScreen2;
