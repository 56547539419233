import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ElementWithLoading from '../../elementWithLoading';
import Icon, { IconName } from '../../icon';
import { useNavigate } from 'react-router-dom';
import DialogModal from '../../dialogModal/index';
import ForgotPassword from '../forgotPassword';
import cn from 'classnames';
import Input from '../../input';
import LimitClickWrapper from '../../limitClickWrapper';
import LoginThirdParty from '../components/loginThirdParty';
import InputByPhoneOrEmail, { useCaseList } from '../inputByPhoneOrEmail';
import TocTip from '../tocTip';
import { TwoFa } from '../../dialogModal/TwoFa';
import ExtraUserInfo from '../extraUserInfo';
import Enter2FACode from '../../enter2FACode';
import useLogin from '../../../hooks/useLogin';
import CaptchaInput from './captchaInput';
import { TextPlaces, UserCache, checkEmail, isUseLayoutMobile, isControllableSite } from '../../../utils/helper';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import useHandleLogic from '../../../hooks/useHandleLogic';
import OnlineService from '../onlineService';
import InputErrorTips from '../../inputErrorTips';

const LoginMobilePart = ({ handleClose, changeType, isShowTitle = true }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputFocused, setInputFocused] = useState(false);
  const [inputFocused1, setInputFocused1] = useState(false);
  const { inviteCode, osType, advertisingId, loginStatus, dialogModalProp } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.inviteCode', ''),
      osType: get(state, 'user.osType', ''),
      advertisingId: get(state, 'user.advertisingId', ''),
      loginStatus: state.app.loginStatus,
      dialogModalProp: state.app.dialogModalProp,
    }),
    shallowEqual,
  );

  const {
    requesting,
    uuIdentity,
    isRememberPwd,
    loginType,
    captchaInitConfig,
    setRequesting,
    formData,
    is2FaOpen,
    setIs2FaOpen,
    isForgotPasswordOpen,
    setIsForgotPasswordOpen,
    handleLoginReq,
    setLoginType,
    handleAnyLoginReq,
    setFormData,
    setIsRememberPwd,
    handleReReqCaptcha,
    handleSubmit2FaCode,
  } = useLogin();
  const { handleClickManMade } = useHandleLogic();

  useEffect(() => {
    handleReReqCaptcha();
    setLoginType(UserCache.LoginMethodValue.Any);
  }, []);

  const [showInput1Error, setShowInput1Error] = useState(false);
  const [showInput2Error, setShowInput2Error] = useState(false);

  // 登录按钮事件
  const handleSubmit = () => {
    if (!formData.account) {
      return toast.info(t('1008008'), {
        containerId: 'global',
      });
    }
    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }

    if (captchaInitConfig?.user_login_validation_mode == 'image') {
      if (formData.cryCode === '') {
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    try {
      setRequesting(true);
      handleAnyLoginReq();
    } catch (error) {
      console.log('eerror', error);
      setRequesting(false);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleCheckInput2 = (value) => {
    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(value)) {
      setShowInput2Error(true);
    } else {
      setShowInput2Error(false);
    }
  };

  const handleCheckEmailOrPhone = (value) => {
    if (value == '') {
      setShowInput1Error(true);
    } else {
      setShowInput1Error(false);
    }
  };

  const handleInput1Blur = (evt) => {
    setInputFocused(false);
    handleCheckEmailOrPhone(evt.target.value);
  };

  const handleCheckForm = () => {
    let returnValue = true;
    if (formData.account == '') {
      setShowInput1Error(true);
      returnValue = false;
    } else {
      setShowInput1Error(false);
    }

    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(formData.password)) {
      setShowInput2Error(true);
      returnValue = false;
    } else {
      setShowInput2Error(false);
    }

    if (showInput1Error || showInput2Error) {
      returnValue = false;
    }

    return returnValue;
  };

  const handleInput2Focus = (evt) => {
    setInputFocused1(true);
    handleCheckInput2(evt.target.value);
  };

  return (
    <div className="flex flex-col">
      {isShowTitle ? (
        <>
          <div className="flex items-center gap-[.0732rem] justify-center">
            <div className="text-[var(--colour-1)] w-[.24rem] h-[.24rem]">
              <Icon name={IconName.Newlogin}></Icon>
            </div>
            <div className={cn('font-[500] text-[var(--colour-1)] text-center text-[.26rem]')}>{t('home.login')}</div>
          </div>

          <div className="h-[1px] bg-[var(--colour-1)] flex mx-auto w-[1.6rem] mt-[.22rem]"></div>
        </>
      ) : (
        <></>
      )}
      <div className="font-[500] flex justify-start text-[var(--colour-48)] text-[.22rem]">
        {t('login-signup.support_mobile_account2')}
      </div>
      <div className={`mt-[.1rem]`}>
        <Input
          onBlur={handleInput1Blur}
          leftOuter={
            <div className="flex items-center">
              <span className={`block text-[var(--colour-37)] mr-[.056rem] ml-[.2rem] w-[.22rem] h-[.22rem]`}>
                <span>
                  <Icon name={IconName.Registeruser} />
                </span>
              </span>

              <div className={`mr-[.056rem] text-[.2rem] w-[.1rem] font-[400] text-[var(--colour-25)]`}>
                {formData.account.length <= 0 ? <>*</> : ''}
              </div>
            </div>
          }
          type="text"
          value={formData.account}
          onChange={(ev) => {
            const val = ev.target.value;
            setFormData({ ...formData, account: val });
            handleCheckEmailOrPhone(val);
          }}
          inputClassName={`!font-[400] placeholder:!font-[400] !text-[.22rem] placeholder:!text-[.22rem]`}
          placeholder={`${t('login-signup.phone')}/${t('login-signup.email')}/${t('login-signup.Username')}`}
          innerClassName={`!p-[0_.16rem_0_0] !h-[.7rem]`}
          topClassName={`overflow-hidden rounded-[.16rem] !bg-[transparent] border ${inputFocused ? '!border-[var(--colour-1)]' : '!border-[var(--colour-6)]'}`}
          onFocus={() => {
            setInputFocused(true);
          }}
          rightInner={
            formData.account.length > 0 && inputFocused ? (
              <>
                <div
                  onMouseDown={handleMouseDown}
                  className="w-[.32rem] h-[.32rem] text-[var(--colour-37)]"
                  onClick={() => {
                    setFormData({ ...formData, account: '' });
                  }}
                >
                  <Icon name={IconName.InputClear}></Icon>
                </div>
              </>
            ) : null
          }
          isShowClearIcon={false}
        />

        {showInput1Error ? (
          <InputErrorTips
            className={`!my-[.12rem] !h-[.22rem] !space-x-[.08rem]`}
            showTips={showInput1Error}
            tipsType={1}
          />
        ) : (
          <></>
        )}
      </div>
      {!showInput1Error ? <div className="w-full h-[.2rem]"></div> : <></>}

      <div>
        <Input
          btnClassName="!ml-[.08rem]"
          hideEyeIconName={IconName.NewShowEye}
          showEyeIconName={IconName.NewhideEye}
          rightIconClassName={'!w-[.48rem] !h-[.48rem]'}
          islightrightIcon={true}
          onFocus={handleInput2Focus}
          leftOuter={
            <div className="flex items-center">
              <span className={`w-[.192rem] h-[.24rem] mr-[.088rem] ml-[.2rem] block  text-[var(--colour-37)]`}>
                <span>
                  <Icon name={IconName.Registerlock} />
                </span>
              </span>
              <div className={`mr-[.04rem] text-[.2rem] w-[.1rem] font-[400] text-[var(--colour-25)]`}>
                {formData.password.length <= 0 ? <>*</> : ''}
              </div>
            </div>
          }
          type="password"
          value={formData.password}
          onChange={(ev) => {
            let val = TextPlaces(ev.target.value, 24);
            setFormData({ ...formData, password: val });
            handleCheckInput2(val);
          }}
          inputClassName={`!font-[400] placeholder:!font-[400] !text-[.22rem] placeholder:!text-[.22rem]`}
          placeholder={t('login-signup.fill_in_the_password')}
          innerClassName={`!h-[.7rem] !p-[0_.16rem_0_0]`}
          topClassName={`overflow-hidden rounded-[.16rem] bg-[transparent] border ${inputFocused1 ? '!border-[var(--colour-1)]' : '!border-[var(--colour-6)]'}`}
          onBlur={() => {
            setInputFocused1(false);
          }}
          passWordBeforeInner={
            formData.password.length > 0 && inputFocused1 ? (
              <>
                <div
                  onMouseDown={handleMouseDown}
                  className="text-[var(--colour-37)] w-[.32rem] h-[.32rem]"
                  onClick={() => {
                    setFormData({ ...formData, password: '' });
                  }}
                >
                  <Icon name={IconName.InputClear}></Icon>
                </div>
              </>
            ) : null
          }
          isShowClearIcon={false}
        />

        {showInput2Error ? (
          <InputErrorTips
            className={`!mt-[.12rem] !mb-[.06rem] !h-[.22rem] !space-x-[.08rem]`}
            showTips={showInput2Error}
            tipsType={2}
          />
        ) : (
          <></>
        )}
      </div>

      {!showInput2Error ? <div className="w-full h-[.2rem]"></div> : <></>}
      {captchaInitConfig?.user_login_validation_mode ==
      'geetest' ? null : captchaInitConfig?.user_login_validation_mode == 'image' ? (
        <CaptchaInput
          iconClassName={`!w-[.3rem] !h-[.3rem] !mr-[.12rem]`}
          innerClassName={`!bg-[transparent] !border !border-[var(--colour-6)] !h-[.7rem] !px-[.2rem] !rounded-[.16rem]`}
          inputClassName={`!font-[400] placeholder:!font-[400] placeholder:!text-[.22rem] !text-[.22rem]`}
          topClassName={`!bg-[transparent] border !border-[var(--colour-6)] rounded-[.16rem]`}
          imgClassName="w-[1.7rem] ml-[.08rem]"
          captchaInfo={captchaInitConfig}
          value={formData.cryCode}
          onChange={(ev) => {
            setFormData({ ...formData, cryCode: ev.target.value });
          }}
          handleReReqCaptcha={handleReReqCaptcha}
        />
      ) : null}

      <div
        className={`flex items-center ${!showInput2Error && !captchaInitConfig ? 'mt-[.2rem]' : 'mt-[.28rem]'} justify-between`}
      >
        <div className="flex items-center">
          <div
            className={`active:scale-[.95] transition-all select-none w-[.36rem] h-[.36rem] rounded-[.08rem] flex items-center justify-center border border-[var(--colour-6)] cursor-pointer ${
              isRememberPwd == '2' ? 'bg-[var(--colour-1)]' : 'bg-[var(--colour-42)]'
            }`}
            onClick={() => setIsRememberPwd(isRememberPwd == '1' ? '2' : '1')}
          >
            {isRememberPwd == '2' ? (
              <span className="text-[var(--colour-2)] w-[.24rem] h-[.16rem]">
                <Icon name={IconName.Checked2} />
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className={`flex-1 text-left font-[500] text-[var(--colour-40)] ml-[.08rem] text-[.22rem]`}>
            {t('login-signup.remember_me')}
          </div>
        </div>
      </div>

      <LimitClickWrapper
        className={cn(
          'text-[var(--colour-2)] bg-[var(--colour-1)] flex flex-row items-center justify-center px-[.34rem] !h-[.7rem] mt-[.24rem] rounded-[.16rem]',
          {
            'opacity-[.7] pointer-events-none': requesting,
            'active:scale-[.95]  transition-all select-none cursor-pointer ': !requesting,
          },
        )}
        onClick={() => {
          if (!handleCheckForm()) {
            return;
          }
          if (!requesting) {
            handleSubmit();
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_LogPage_Login);
          }
        }}
      >
        {requesting ? (
          <div className="w-[.3rem] h-[.42rem] mr-[.2rem] text-[var(--colour-2)] anticon">
            <Icon name={IconName.loadingMp3} />
          </div>
        ) : null}
        <div className={`font-[400] text-[.24rem]`}>{t('home.login')}</div>
      </LimitClickWrapper>

      {isUseLayoutMobile() ? (
        <div className="flex items-center justify-between pl-[.82rem] pr-[.94rem] mt-[.2rem] mb-[.11rem]">
          <span className="font-[500] text-[var(--colour-1)] cursor-pointer text-[.22rem]" onClick={handleClickManMade}>
            {t('home.service')}
          </span>

          <div className="text-right">
            <span
              className={`cursor-pointer active:scale-[.95] transition-all select-none text-[var(--colour-1)] text-[.22rem] font-[500]`}
              onClick={() => {
                setIsForgotPasswordOpen(true);
              }}
            >
              {t('login-signup.forgot')}?
            </span>
          </div>
        </div>
      ) : (
        <div className="flex items-center mt-[.24rem] mb-[.04rem]">
          <div className="font-[500] text-[var(--colour-1)] leading-[.34rem] mr-[.24rem] text-[.22rem]">
            {t("login-signup.don't_have")}
          </div>

          <div className="text-right">
            <span
              className={`cursor-pointer active:scale-[.95] transition-all select-none text-[var(--colour-1)] text-[.22rem] font-[500]`}
              onClick={() => {
                setIsForgotPasswordOpen(true);
              }}
            >
              {t('login-signup.forgot')}?
            </span>
          </div>
        </div>
      )}
      <LoginThirdParty />

      {/* <OnlineService click={handleClose} /> */}

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isForgotPasswordOpen}
        handleClose={() => {
          setIsForgotPasswordOpen(false);
        }}
      >
        <ForgotPassword
          handleClose={() => {
            setIsForgotPasswordOpen(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIs2FaOpen(false);
        }}
        style={{
          overlay: {
            zIndex: 999999,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={is2FaOpen}
      >
        <Enter2FACode
          handleClose={() => setIs2FaOpen(false)}
          onClickButton={handleSubmit2FaCode}
          uuIdentity={uuIdentity}
        />
      </DialogModal>
    </div>
  );
};

const LoginForm = ({ handleClose, changeType, isShowTitle }) => {
  return (
    <div className="flex flex-col">
      <LoginMobilePart handleClose={handleClose} changeType={changeType} isShowTitle={isShowTitle} />
    </div>
  );
};

export default LoginForm;
