import { forwardRef, useImperativeHandle, useRef } from 'react';
import cn from 'classnames';
import Close from '../close';
import Button from '../button';
import { useTranslation } from 'react-i18next';

const SuccessTip = forwardRef(
  ({ handleClose, title = '', content = '', moneySymbol = '', moneyAmount = '', handleClaim = () => {} }, ref) => {
    const innerRef = useRef(null);

    useImperativeHandle(ref, () => ({
      getInnerRef: () => innerRef.current,
    }));

    const { t } = useTranslation();

    const containerClass = cn(
      'relative w-[6.86rem] h-[4.56rem] rounded-normal bg-[var(--colour-35)]',
      'bg-[url(../assets/img/redeem/award_bg.png)] bg-no-repeat bg-[length:100%_100%]',
    );

    const contentWrapperClass = cn('absolute flex flex-col abs-x-center', {
      'top-[.42rem]': !!moneyAmount,
      'top-[1.8rem] text-[.44rem] font-[700]': !moneyAmount,
    });

    const paragraphClass = cn(
      'font-[500] text-center text-[.24rem] w-[5rem] mx-[auto] mt-[.48rem] text-[var(--colour-49)] leading-[1]',
      {
        'mt-[.16rem]': !!moneyAmount,
        'mt-[.8rem]': !moneyAmount,
      },
    );

    return (
      <>
        <div className={containerClass}>
          <div className={contentWrapperClass}>
            <div className="font-[700] mx-[auto] text-[.44rem] text-[var(--colour-48)] leading-[1]">{title}</div>
            {moneyAmount && (
              <div className="font-[700] mx-[auto] text-[var(--colour-26)] pt-[.54rem] text-[.72rem]">
                {`${moneySymbol} ${moneyAmount}`}
              </div>
            )}
            <p className={paragraphClass}>{content}</p>
            <Button
              ref={innerRef}
              onClick={(e) => handleClaim(e)}
              className="w-[3.96rem] h-[.64rem] bg-[var(--colour-1)] mx-auto font-[700] rounded-normal text-[.24rem] mt-[.2rem] text-[var(--colour-2)]"
            >
              {t('redeem.ok')}
            </Button>
          </div>
          <div className="absolute right-[.32rem] top-[.16rem] w-[.4rem] h-[.4rem] flex justify-center items-center rounded-small bg-[var(--colour-45)]">
            <Close onClick={handleClose} />
          </div>
        </div>
      </>
    );
  },
);

export default SuccessTip;
