import { useEffect, useState } from 'react';
import { getConfigTimeZoneTimestamp, getShowMoney } from '../../../../../utils/helper';
import NoData from '../../../../../components/noData';
import HistoryItem from './com/historyItem';
import Loading2 from '../../../../../components/loading2';
import HistoryMenu from './com/historyMenu';
import BonusAmount from '../../../pending/normal/template001/com/bonusAmount';
import useDropMenu from '../../../../../hooks/useDropMenu';
import { List } from 'react-vant';
import Loading3 from '../../../../../components/loading3';
import moment from 'moment';
import { GetPromotionHistory } from '../../../../../api';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useActivityJump from '../../../../../hooks/useActivityJump';

const fakeList = Array(10)
  .fill()
  .map(() => {
    return {
      name: 'Daily Betting Reward',
      money: 200,
      date: '01/07/24',
      source: (
        <span
          className="text-[var(--colour-1)] text-[.2rem] font-[500] cursor-pointer"
          onClick={() => {
            alert('click');
          }}
        >
          Activity award
        </span>
      ),
    };
  });

const NTemplate001 = () => {
  const { t } = useTranslation();

  const { defaultLang } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const { getActivityName, getJumpHandler, getActivitySource } = useActivityJump();

  const typeKeys = {
    all: -1,
    received: 1,
    distributed: 0,
  };

  const initParams = {
    page: 1,
    page_size: 10,
    behaviour_type: typeKeys.all,
    start_time: new Date(),
    end_time: new Date(),
  };

  const [list, setList] = useState([]);
  const [params, setParams] = useState(initParams);
  const [isLoading, setIsLoading] = useState(false);
  const [isListFinished, setIsListFinished] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [dateList, setDateList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const { isMenuVisible, currentMenu, onClickItem, onClickOutside, onClickMenu } = useDropMenu();
  const {
    isMenuVisible: isMenuVisible2,
    currentMenu: currentMenu2,
    onClickItem: onClickItem2,
    onClickOutside: onClickOutside2,
    onClickMenu: onClickMenu2,
  } = useDropMenu({ defaultMenu: -1 });

  useEffect(() => {
    if (!isLoading && !isListFinished) {
      updateList();
    }
  }, [params]);

  useEffect(() => {
    setDateList([
      {
        key: 0,
        name: t('promotion.today'),
        onClick: () => {
          onClickItem(0);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              start_time: new Date(),
              end_time: new Date(),
            };
          });
        },
      },
      {
        key: 1,
        name: t('promotion.yesterday'),
        onClick: () => {
          onClickItem(1);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              start_time: moment().subtract(1, 'days').toDate(),
              end_time: moment().subtract(1, 'days').toDate(),
            };
          });
        },
      },
      {
        key: 2,
        name: t('promotion.last_7_days'),
        onClick: () => {
          onClickItem(2);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              start_time: moment().subtract(7, 'days').toDate(),
              end_time: new Date(),
            };
          });
        },
      },
      {
        key: 3,
        name: t('promotion.last_15_days'),
        onClick: () => {
          onClickItem(3);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              start_time: moment().subtract(15, 'days').toDate(),
              end_time: new Date(),
            };
          });
        },
      },
      {
        key: 4,
        name: t('promotion.last_30_days'),
        onClick: () => {
          onClickItem(4);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              start_time: moment().subtract(30, 'days').toDate(),
              end_time: new Date(),
            };
          });
        },
      },
    ]);

    setTypeList([
      {
        key: typeKeys.all,
        name: t('promotion.type_all'),
        onClick: () => {
          onClickItem2(typeKeys.all);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              behaviour_type: typeKeys.all,
            };
          });
        },
      },
      {
        key: typeKeys.received,
        name: t('promotion.type_received'),
        onClick: () => {
          onClickItem2(typeKeys.received);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              behaviour_type: typeKeys.received,
            };
          });
        },
      },
      {
        key: typeKeys.distributed,
        name: t('promotion.type_distributed'),
        onClick: () => {
          onClickItem2(typeKeys.distributed);
          setIsListFinished(false);
          setList([]);
          setParams((params) => {
            return {
              ...params,
              page: 1,
              behaviour_type: typeKeys.distributed,
            };
          });
        },
      },
    ]);
  }, [defaultLang]);

  const updateList = () => {
    const request = (args) => {
      console.log('request args', args);
      setIsLoading(true);

      // setTimeout(() => {
      //   if (params.page == 1) {
      //     setList(fakeList);
      //   } else {
      //     setList(list.concat(fakeList));
      //     if (params.page >= 4) {
      //       setIsListFinished(true);
      //     }
      //   }
      //   setIsLoading(false);
      // }, 1000);

      GetPromotionHistory(args)
        .then((res) => {
          if (res.code == 0) {
            const data = res.data;
            const _list = data?.list || [];

            setList(params.page == 1 ? _list : list.concat(_list));
            setIsListFinished(_list.length < params.page_size);
            setBonus(data?.total_amount || 0);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const args = {
      ...params,
      start_time: moment(params.start_time).startOf('day').valueOf(),
      end_time: moment(params.end_time).endOf('day').valueOf(),
    };

    request(args);
  };

  const handleBottomReached = () => {
    if (!isListFinished && !isLoading) {
      console.log('handleBottomReached');
      setParams({ ...params, page: params.page + 1 });
    }
  };

  const getTypeName = (type) => {
    const found = typeList.find((item) => item.key == type);
    return found ? found.name : '';
  };

  const getIsExpired = (expiredTime) => {
    if (expiredTime == 0) {
      return false;
    } else if (expiredTime == -1) {
      return true;
    } else {
      const endTimeStamp = expiredTime * 1000;
      return Date.now() > endTimeStamp;
    }
  };

  return (
    <div className="h-full px-[.24rem] bg-[var(--colour-68)] flex flex-col">
      <div className="shrink-0 flex justify-between p-[.32rem_0_.28rem]">
        <div className="flex space-x-[.2rem]">
          <HistoryMenu
            isMenuVisible={isMenuVisible}
            list={dateList}
            currentMenu={currentMenu}
            onClickMenu={onClickMenu}
            onClickOutside={onClickOutside}
          />
          <HistoryMenu
            isMenuVisible={isMenuVisible2}
            list={typeList}
            currentMenu={currentMenu2}
            onClickMenu={onClickMenu2}
            onClickOutside={onClickOutside2}
          />
        </div>
        {isLoading && params.page == 1 ? null : <BonusAmount amount={getShowMoney(bonus)} />}
      </div>
      <div className="shrink-0 grow h-[0] overflow-y-auto scrollbar-none">
        {isLoading && params.page == 1 ? (
          <Loading3 className1="w-full h-full" />
        ) : list.length == 0 ? (
          <NoData className="h-full" />
        ) : (
          <>
            <List
              finished={isListFinished}
              onLoad={handleBottomReached}
              offset={0}
              loadingText={<Loading3 className1="w-full" />}
            >
              {list.map((item, index) => (
                <HistoryItem
                  key={index}
                  name={getActivityName(item.behaviour)}
                  money={getShowMoney(item.amount)}
                  date={getConfigTimeZoneTimestamp(item.create_timestamp).format('YYYY-MM-DD')}
                  source={getActivitySource(item.behaviour)}
                  isHasBg={index % 2 == 0}
                  typeName={getTypeName(item.behaviour_type)}
                  handleSourceClick={getJumpHandler(item.behaviour, getIsExpired(item.expiration_time))}
                />
              ))}
            </List>
          </>
        )}
      </div>
    </div>
  );
};

export default NTemplate001;
