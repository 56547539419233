import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useTranslation } from 'react-i18next';
import GameItem from '../../../../components/gameItem';
import Loading from '../../../../components/loading2';
import { GameListReq } from '../../../../api';
import NoData from '../../../../components/noData';
import { getQueryString } from '../../../../utils/helper';
import { toLocalePath } from '../../../../utils/localePath';
import HeaderBack from '../../../../components/headerBack';
import SearchBox from '../../../gameList/normal/template001/com/searchBox';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import ScrollbarContent from '../../../../components/scrollbarContent';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import LoadingMoreWithText from '../../../../components/loadingMoreWithText';

const Ntemplate001 = () => {
  const { t } = useTranslation();
  const { h } = useWindowHeight(0.9);
  const pageSize = window.innerWidth <= 576 ? 18 : 28;

  const navigate = useGlobalNavigate();
  const [list, setlist] = useStateIfMounted([]);
  const [reRequest, setreRequest] = useStateIfMounted('');
  const [loading, setloading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [total, setTotal] = useStateIfMounted(0);

  const [params, setParams] = useStateIfMounted({
    source: '',
    sourceName: '',
    category: '',
    search: '',
    page: 1,
    page_size: pageSize,
    isInput: false,
  });

  const handleSearch = () => {
    setParams({ ...params, page: 1, isInput: false });
  };

  const handleFavoriteCallback = () => {
    setreRequest(new Date().getTime());
  };

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1, isInput: false });
  };

  const handleBack = () => {
    navigate(toLocalePath('/'));
  };

  const getGameList = async () => {
    if (params.page == 1) {
      setloading(true);
    } else {
      setLoadingMore(true);
    }

    const Source = getQueryString('source');
    const SourceName = getQueryString('sourceName');
    const Category = getQueryString('category');

    if (params.source == '' && Source) {
      params.source = Source;
    }

    if (params.sourceName == '' && SourceName) {
      params.sourceName = SourceName;
    }

    if (params.category == '' && Category) {
      params.category = Category;
    }

    try {
      const res = await GameListReq(params);
      if (res.code == 0) {
        const result = res.result;

        if (params.page == 1) {
          setlist(result.list);
        } else {
          const list2 = list.concat(result.list);
          setlist(list2);
        }

        const _pageCount = Math.ceil(result.pages.count / pageSize);
        setTotal(result.pages.count);
        if (params.page < _pageCount) {
          setShowLoadingMore(true);
        } else {
          setShowLoadingMore(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setloading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (!params.isInput) {
      getGameList();
    }
  }, [params]);

  return (
    <>
      <div className="flex flex-col m-[0_auto] max-w-[10rem] bg-[var(--colour-9)] home_bg_texture">
        <HeaderBack title={params.sourceName} onClick={handleBack} />
        <ScrollbarContent className="pb-[.2rem] pt-[.2rem] px-[.2rem]" style={{ height: `${h}rem` }}>
          <SearchBox
            placeholder={t('home.gamesearch_placeholder')}
            value={params.search}
            onClickSearch={handleSearch}
            onSearchChange={(ev) => {
              setParams({ ...params, search: ev.target.value, isInput: true });
            }}
          />

          <div className="mt-[.2rem]">
            {loading ? (
              <Loading height="4rem" />
            ) : (
              <>
                {list && list.length > 0 ? (
                  <div className="games__row !gap-x-[.28rem] !gap-y-[.28rem]">
                    {list &&
                      list.length > 0 &&
                      list.map((item, index) => (
                        <GameItem
                          model={item}
                          key={item.id}
                          handleFavoriteCallback={handleFavoriteCallback}
                          //   handleFavoriteNeedCallback={false}
                        />
                      ))}
                  </div>
                ) : (
                  <NoData className="py-[2rem]" />
                )}

                {showLoadingMore ? (
                  // <LoadMore className="mt-[.4rem]" onClick={handleLoadMore} showLoading={loadingMore} />

                  <LoadingMoreWithText
                    currentNum={list.length}
                    total={total}
                    className="mt-[.4rem] mb-[.2rem]"
                    showLoading={loadingMore && params.page > 1}
                    handleLoadMore={handleLoadMore}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </ScrollbarContent>
      </div>
    </>
  );
};

export default Ntemplate001;
