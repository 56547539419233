import Input from './input';
import { useState } from 'react';
import DatePickerBox from './datePickerBox';
import Icon, { IconName } from './icon';
import moment from 'moment';
import cn from 'classnames';

const DateCustomInput = ({
  date,
  onClick = () => {},
  dateInputClassName1 = 'text-[.32rem]',
  dateInputClassName2 = 'w-[.4rem] h-[.4rem]',
}) => {
  return (
    <div className="flex items-center cursor-pointer space-x-[.2rem]" onClick={onClick}>
      <span
        className={cn(dateInputClassName1, {
          'text-secondary-content': !date,
        })}
      >
        {date ? moment(date).format('YYYY/MM/DD') : '--/--/----'}
      </span>
      <span className={dateInputClassName2}>
        <Icon name={IconName.NewDate} />
      </span>
    </div>
  );
};

const DateSelector = ({
  date,
  handleDateChange = () => {},
  dateInput = null,
  dateInputClassName1,
  dateInputClassName2,
}) => {
  const [localDate, setLocalDate] = useState(date);

  const onChange = (date) => {
    setLocalDate(date);
    handleDateChange(date);
  };

  const onClick = () => {};

  return (
    <DatePickerBox
      selected={localDate}
      onChange={onChange}
      customInput={
        dateInput ? (
          dateInput
        ) : (
          <DateCustomInput
            date={localDate}
            onClick={onClick}
            dateInputClassName1={dateInputClassName1}
            dateInputClassName2={dateInputClassName2}
          />
        )
      }
    />
  );
};

const InputDate = ({
  handleDateChange = () => {},
  initDate,
  className = '',
  topClassName = '',
  innerClassName = '',
  inputClassName = '',
  leftInner = null,
  dateInput = null,
  dateInputClassName1,
  dateInputClassName2,
}) => {
  return (
    <div className={className}>
      <Input
        leftInner={leftInner ? leftInner : <></>}
        onChange={handleDateChange}
        disabled={true}
        rightInner={
          <DateSelector
            date={initDate}
            handleDateChange={handleDateChange}
            dateInput={dateInput}
            dateInputClassName1={dateInputClassName1}
            dateInputClassName2={dateInputClassName2}
          />
        }
        topClassName={topClassName}
        innerClassName={innerClassName}
        inputClassName={inputClassName}
        wrapperClassName="!overflow-visible"
      />
    </div>
  );
};

export default InputDate;
