import Icon, { IconName } from '../../../icon';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const Agent = ({ handleLinkClick = () => {} }) => {
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  const click = () => {
    handleLinkClick();

    if (websiteInitConfig?.website_info?.switch_referral == '1') {
      navigate(toLocalePath('/referral'));
    }
  };

  return (
    <div
      className={`w-full h-[.86rem] rounded-normal mb-[.16rem] px-[.32rem] flex items-center text-[.24rem] font-[500] text-[var(--colour-14)] cursor-pointer ${websiteInitConfig?.website_info.website_theme == 'theme-15' ? 'bg-menu-default' : ' bg-[var(--colour-16)]'}`}
      onClick={click}
    >
      <div className="text-[var(--colour-14)] mr-[.32rem] w-[.48rem] h-[.48rem]">
        <Icon name={IconName.DrawerMenuAgent} />
      </div>
      <div>{t('home.agent')}</div>
    </div>
  );
};

export default Agent;
