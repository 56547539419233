export function getFinishedPeopleTotalNum(taskList = []) {
  // is_claimed: '0'：不可领奖；'1'：可领奖；'2'：已领奖
  // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,

  let num = 0;
  taskList.forEach((item) => {
    num += Number(item.finish_people_num);
  });
  return num;
}

export function getCurrentTaskFinishPeopleNum(taskList = []) {
  // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,

  const unclaimableTaskList = taskList.filter((item) => [10, 11].includes(Number(item.task_status)));

  if (unclaimableTaskList[0]) {
    const num = unclaimableTaskList[0]?.finish_people_num || 0;
    return Number(num);
  } else {
    return 0;
  }
}

export const isRunningTaskExit = (taskList = []) => {
  // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,

  return taskList.some((item) => Number(item.task_status) == 10);
};
