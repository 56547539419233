import { Fragment, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getPercentage, getShowMoney, isControllableSite, isLogin } from '../../../../../utils/helper';
import { GetReferralInviteList, GetReferralMydata, GetReferralMydataTotal } from '../../../../../api';
import cn from 'classnames';
import { ResponsiveMode } from '../../../../../redux/reducers/appSlice';
import Icon, { IconName } from '../../../../../components/icon';
import StepBoxList from '../../../../../components/stepBoxList';
import DialogModal from '../../../../../components/dialogModal';
import Close2 from '../../../../../components/close2';
import DataItemList from './components/dataItemList';
import DateBtnList from './components/dateBtnList';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../../utils/localePath';
import ScrollbarContent from '../../../../../components/scrollbarContent';
import { useGlobalNavigate } from '../../../../../contexts/navigateContext';
import StepBoxListResponsive from '../../../../../components/stepBoxListResponsive';

const fakeList = [
  {
    title: '邀请人数',
    value: '100',
  },
  {
    title: '邀请人数',
    value: '100',
  },
  {
    title: '邀请人数',
    value: '100',
  },
  {
    title: '邀请人数',
    value: '100',
  },
  {
    title: '邀请人数',
    value: '100',
  },
  {
    title: '邀请人数',
    value: '100',
  },
];

const fakeStepList = Array(6)
  .fill()
  .map((_, index) => ({
    text1: `${index + 1}people`,
    text2: '2000',
    mode: ['default', 'opened', 'done'][Math.floor(Math.random() * 3)],
    progressPercent: Math.floor(Math.random() * 100),
    onClick: () => {
      alert(index);
    },
  }))
  .concat([
    {
      text1: 'Coming Soon',
      text2: '',
      mode: 'default',
      progressPercent: 0,
      onClick: null,
    },
  ]);

function BonusItem({ index, item }) {
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  const { t } = useTranslation();
  var item2 = JSON.parse(item.config);

  return (
    <>
      <div
        className={`flex items-center h-[.8rem] text-[.32rem] rounded-normal text-[var(--colour-38)] ${index % 2 === 0 ? 'bg-[var(--colour-34)]' : ''}`}
      >
        <div className="w-[3rem] text-center">{item2.completed_goal_children_count}</div>
        <div className="flex-1 text-center">
          {websiteInitConfig?.website_info?.currency_symbol || ''}
          {item2 && getShowMoney(item2.amount)}
        </div>
      </div>
    </>
  );
}

function BonusList({ handleClose, list = [] }) {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`flex flex-col w-[6.86rem] px-[.32rem] min-h-[6.68rem] rounded-normal pb-[.32rem] bg-[var(--colour-35)]`}
      >
        <div className="w-full border-b border-[var(--colour-6)]">
          <div className="flex flew-row justify-between items-center py-[.2rem]">
            <div className="text-[.32rem] font-[700] text-[var(--colour-48)]">{t('referral.invitationBonus')}</div>
            <Close2
              handleClose={handleClose}
              className="bg-[var(--colour-45)] rounded-normal"
              className2="text-[var(--colour-46)]"
            />
          </div>
        </div>

        <div className={`w-full flex items-center mt-[.2rem] font-[500] text-[var(--colour-14)]`}>
          <div className="w-[3rem] text-center">{t('referral.invitesNum')}</div>
          <div className="flex-1 text-center">{t('referral.rewards')}</div>
        </div>

        <div className={'mt-10 '}>
          {list.map((item, index) => {
            return <BonusItem index={index} item={item} key={index} />;
          })}
        </div>
      </div>
    </>
  );
}

const DataCard = ({
  title = '',
  list = [],
  onClick = () => {},
  hideMore = false,
  differentiateColors = false,
  isHasTopLine = true,
}) => {
  const { t } = useTranslation();

  const { responsiveMode } = useSelector((state) => ({
    responsiveMode: state.app.responsiveMode,
  }));

  return (
    <div className={cn('rounded-normal font-[500] text-[.24rem]')}>
      {isHasTopLine && <div className={cn('h-[.02rem] bg-[var(--colour-6)]')}></div>}
      <div className="flex items-center justify-end relative cursor-pointer h-[.6rem]" onClick={onClick}>
        <div className="font-[500] text-[var(--colour-49)] text-[.24rem] absolute left-[50%] -translate-x-1/2">
          {title}
        </div>
        {!hideMore && (
          <div className={cn('text-[.28rem] text-[var(--colour-11)] flex items-center')}>
            <div className="text-[var(--colour-11)] w-[.24rem] h-[.24rem] rotate-[270deg]">
              <Icon name={IconName.ChatArraw} />
            </div>
          </div>
        )}
      </div>
      <div className={cn('h-[.02rem] bg-[var(--colour-6)]')}></div>
      <div className="flex p-[.18rem_.32rem]">
        {list.map((item, index) => (
          <Fragment key={index}>
            {index > 0 && !item.hideDividerLine && (
              <div className={cn('shrink-0 w-[.02rem] mx-[.2rem] bg-[var(--colour-6)]')} />
            )}
            <div className="flex-1 flex items-center flex-col h-[.96rem] justify-between" key={index}>
              {item.name && (
                <div className="text-[var(--colour-44)] text-center leading-[1em] break-word h-[.72rem] flex items-center text-[.22rem] font-[500]">
                  {item.name}
                </div>
              )}
              <div
                className={cn('text-[.24rem] font-[500]', {
                  'flex-1': !item.name,
                  'text-[var(--colour-38)]': !differentiateColors,
                  'text-[var(--colour-26)]': differentiateColors,
                })}
              >
                {item.value}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

const NTemplate001 = ({ handleScroll }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();

  const { responsiveMode, defaultLang, identity } = useSelector(
    (state) => ({
      responsiveMode: state.app.responsiveMode,
      defaultLang: state.user.defaultLang,
      identity: state.user.identity,
    }),
    shallowEqual,
  );

  const initCurrentBtn = 0;

  const initParams = {
    // start_date: new Date(),
    // end_date: new Date(),
    start_date: moment().subtract(1, 'days').toDate(),
    end_date: moment().subtract(1, 'days').toDate(),
    refreshNow: true,
  };

  const [currentBtn, setCurrentBtn] = useState(initCurrentBtn);
  const [btnList, setBtnList] = useState([]);
  const [params, setParams] = useState(initParams);
  const [timeDataList, setTimeDataList] = useState([]);
  const [myData, setMyData] = useState(null);
  const [cardDataList, setCardDataList] = useState([]);
  const [myDataTotal, setMyDataTotal] = useState(null);
  const [taskList, setTaskList] = useState([]);
  const [bonus, seBonus] = useState('');
  const [bonusText, seBonusText] = useState('');
  const [isShowBonusList, setIsShowBonusList] = useState(false);
  const [bonusList, setBonusList] = useState([]);

  useEffect(() => {
    getRefData();
    updateMyDataTotal();
  }, []);

  useEffect(() => {
    setBtnList([
      { name: t('referral.yesterday'), onClick: onClickYesterday },
      { name: t('referral.today'), onClick: onClickToday },
      { name: t('referral.thisweek'), onClick: onClickThisWeek },
      { name: t('referral.lastweek'), onClick: onClickLastWeek },
      { name: t('refer.thismonth'), onClick: onClickThisMonth },
    ]);
  }, [defaultLang]);

  useEffect(() => {
    if (params.refreshNow) {
      const arg = {
        ...params,
        start_date: moment(params.start_date).format('YYYYMMDD'),
        end_date: moment(params.end_date).format('YYYYMMDD'),
      };

      updateMyData(arg);
    }
  }, [params]);

  useEffect(() => {
    setTimeDataList([
      {
        title: t('referral.add_direct_members'),
        value: myData?.total_direct_user_count || 0,
      },
      {
        title: t('referral.first_deposit_count'),
        value: myData?.first_deposit_count || 0,
      },
      {
        title: t('referral.deposit_user_count_2'),
        value: myData?.deposit_user_count || 0,
      },
      {
        title: t('referral.total_deposit_amount'),
        value: getShowMoney(myData?.total_deposit_amount || 0, 2, 2),
      },
      {
        title: t('referral.total_wager_amount'),
        value: getShowMoney(myData?.total_wager_amount || 0, 2, 2),
      },
      {
        title: t('referral.total_referral_amount'),
        value: getShowMoney(myData?.total_referral_amount || 0, 2, 2),
        differentiateColors: true,
      },
    ]);
  }, [myData, defaultLang]);

  useEffect(() => {
    const data = myDataTotal;

    setCardDataList([
      {
        title: t('referral.member'),
        list: [
          { name: t('referral.total_number_of_team'), value: data?.total_number_of_team || 0 },
          { name: t('referral.total_direct_members'), value: data?.total_direct_members || 0 },
          { name: t('referral.others'), value: data?.others || 0 },
        ],
        onClick: () => {
          navigate(toLocalePath('/referral?current=directData'));
          handleScroll('directData');
        },
      },
      {
        title: t('referral.valid_wager'),
        list: [
          {
            name: t('referral.total_direct_valid_wager'),
            value: getShowMoney(data?.total_team_valid_wager || 0, 2, 2),
          },
          {
            name: t('referral.direct_wager'),
            value: getShowMoney(data?.total_direct_valid_wager || 0, 2, 2),
          },
          {
            name: t('referral.other_wager'),
            value: getShowMoney(data?.other_team_valid_wager || 0, 2, 2),
          },
        ],
        onClick: () => {
          navigate(toLocalePath('/referral?current=performance'));
          handleScroll('performance');
        },
      },
      {
        title: t('referral.commission1'),
        list: [
          { name: t('referral.TotalCommission'), value: getShowMoney(data?.total_commission || 0, 2, 2) },
          {
            name: t('referral.commission_direct'),
            value: getShowMoney(data?.total_betting_commission || 0, 2, 2),
          },
          { name: t('referral.other_commission'), value: getShowMoney(data?.other_commission || 0, 2, 2) },
        ],
        onClick: () => {
          navigate(toLocalePath('/referral?current=commission'));
          handleScroll('commission');
        },
        differentiateColors: true,
      },
    ]);
  }, [myDataTotal, defaultLang]);

  useEffect(() => {
    if (bonus) {
      const finishedNum = Number(bonus?.finished_num || 0);
      const list = bonus?.list || [];

      const _taskList = list.map((item) => {
        const totalNum = Number(JSON.parse(item.config)?.completed_goal_children_count || 1);
        const amount = Number(JSON.parse(item.config)?.amount || 0);

        return {
          text1: `${totalNum} ${t('referral.people')}`,
          text2: getShowMoney(amount),
          mode: getMode(Number(finishedNum) >= Number(totalNum)),
          progressPercent: getPercentage(finishedNum, totalNum),
          onClick: null,
        };
      });

      const comingList = (() => {
        const comingCount = _taskList.length % 4 == 0 ? 0 : 4 - (_taskList.length % 4);
        return Array(comingCount)
          .fill()
          .map(() => {
            return {
              text1: t('referral.coming_soon'),
              text2: '',
              mode: 'comingSoon',
              progressPercent: 0,
              onClick: null,
            };
          });
      })();

      setTaskList(_taskList.concat(comingList));
    }
  }, [bonus, defaultLang]);

  const getRefData = () => {
    if (!isControllableSite()) {
      GetReferralInviteList({
        identity,
      }).then((res) => {
        if (res.code === 0) {
          seBonus(res.result.bonus);
          seBonusText(res.result.bonus_text);
          setBonusList(res.result.bonus.list);
        }
      });
    }
  };

  const updateMyData = (args) => {
    if (isLogin()) {
      GetReferralMydata(args).then((res) => {
        if (res.code === 0) {
          setMyData(res.data);
        }
      });
    }
  };

  const updateMyDataTotal = () => {
    if (isLogin()) {
      GetReferralMydataTotal().then((res) => {
        if (res.code === 0) {
          setMyDataTotal(res.data);
        }
      });
    }
  };

  const onClickToday = () => {
    setCurrentBtn(1);

    const args = {
      ...params,
      start_date: new Date(),
      end_date: new Date(),
      refreshNow: true,
    };
    setParams(args);
  };

  const onClickYesterday = () => {
    setCurrentBtn(0);

    const args = {
      ...params,
      start_date: moment().subtract(1, 'days').toDate(),
      end_date: moment().subtract(1, 'days').toDate(),
      refreshNow: true,
    };
    setParams(args);
  };

  const onClickThisWeek = () => {
    setCurrentBtn(2);

    const args = {
      ...params,
      start_date: moment().startOf('isoWeek').toDate(),
      end_date: new Date(),
      refreshNow: true,
    };

    setParams(args);
  };

  const onClickLastWeek = () => {
    setCurrentBtn(3);

    const start = moment().startOf('isoWeek').subtract(1, 'weeks');
    const end = start.clone().add(6, 'days');

    const args = {
      ...params,
      start_date: start.toDate(),
      end_date: end.toDate(),
      refreshNow: true,
    };

    setParams(args);
  };

  const onClickThisMonth = () => {
    setCurrentBtn(4);
    const args = {
      ...params,
      start_date: moment().startOf('month').toDate(),
      end_date: new Date(),
      refreshNow: true,
    };
    setParams(args);
  };

  const getMode = (isComplate) => {
    return isComplate ? 'done' : 'default';
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={isShowBonusList}
        handleClose={() => setIsShowBonusList(false)}
      >
        <BonusList handleClose={() => setIsShowBonusList(false)} list={bonusList}></BonusList>
      </DialogModal>

      <ScrollbarContent className="bg-[var(--colour-68)] h-full overflow-y-auto">
        <div className="bg-[var(--colour-35)] p-[.2rem_.32rem] border-b border-[var(--colour-6)]">
          <DateBtnList list={btnList} currentBtn={currentBtn} />
        </div>

        <div className="p-[.26rem_.32rem_0_.32rem]">
          <DataItemList list={timeDataList} style={2} />
        </div>

        <div
          className={cn('m-[.2rem_.32rem_0_.32rem] rounded-normal bg-[var(--colour-69)]', {})}
          style={{
            boxShadow: '0rem .04rem .08rem 0rem rgba(0, 0, 0, 0.04)',
          }}
        >
          <div className="text-[.28rem] font-[500] text-[var(--colour-48)] h-[.8rem] px-[.2rem] flex items-center">
            {t('referral.data_overview')}
          </div>
          <div className="h-[.02rem] bg-[var(--colour-6)]"></div>
          <div className={cn('p-[0_.2rem]')}>
            {cardDataList.map((item, index) => {
              return (
                <DataCard
                  key={index}
                  title={item.title}
                  list={item.list}
                  onClick={item.onClick}
                  hideMore={item.hideMore}
                  differentiateColors={item.differentiateColors}
                  isHasTopLine={index != 0}
                />
              );
            })}
          </div>
        </div>

        {!isControllableSite() && taskList.length > 0 && (
          <div className="px-[.28rem] bg-[var(--colour-69)] rounded-[var(--rounded-normal)] m-[.2rem_.32rem_0_.32rem]">
            <div className="py-[.2rem] flex flex-row justify-start items-center text-center mt-[.2rem]">
              <div className="text-[.32rem] font-[500] text-[var(--colour-48)]">{t('referral.invitationalAward')}</div>
              <div
                className="flex w-[.32rem] h-[.32rem] text-[var(--colour-7)] ml-[.2rem] active:scale-[.95] transition-all select-none cursor-pointer"
                onClick={() => setIsShowBonusList(true)}
              >
                <Icon name={IconName.WidthdrawQuestion2} />
              </div>
            </div>
            <div className={cn('h-[.02rem] bg-[var(--colour-6)]')}></div>
            <div className={cn('mt-[.2rem] p-[.1rem_0] rounded-normal')}>
              <div className="p-[.4rem_0_.24rem] mr-[-.12rem]">
                <StepBoxListResponsive list={taskList} />
              </div>
              <div className="flex mt-[.02rem] text-[var(--colour-40)] text-[.2rem] justify-center text-center w-full">
                {bonusText}
              </div>
            </div>
          </div>
        )}
      </ScrollbarContent>
    </>
  );
};

export default NTemplate001;
