import { useTranslation, Trans } from 'react-i18next';
import { getHostName } from '../../../../../utils/helper';

const CopyRight = () => {
  const { t } = useTranslation();

  return (
    <div className={'text-[var(--colour-15)] font-[500] text-[.18rem] leading-[1.6em] text-center'}>
      <Trans
        t={t}
        i18nKey="footer.copyright"
        components={[<span className="" key={0}></span>, <span className="" key={1}></span>]}
        values={{
          val: getHostName(),
          val1: new Date().getFullYear(),
        }}
      />
    </div>
  );
};

export default CopyRight;
