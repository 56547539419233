import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import Input from '../../../components/input';
import Icon, { IconName } from '../../../components/icon';
import Close from '../../../components/close';
import { removeSpecialSymbols, deleteCPF, formatCPF, isValidCPF } from '../../../utils/inputHelper';
import { TextPlaces1 } from '../../../utils/helper';
import { GetCpfBind } from '../../../api';
import LimitClickWrapper from '../../../components/limitClickWrapper';
import ElementWithLoading from '../../../components/elementWithLoading';

const Binding = ({ handleClose = () => {}, bindSuccessCallback = () => {} }) => {
  const { t } = useTranslation();
  const [requesting, setRequesting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    cpf: '',
  });

  //true为校验通过，false为校验失败
  const validation = (value) => {
    if (value) {
      const isTrueCPF = isValidCPF(value);
      if (isTrueCPF) {
        return true;
      } else {
        toast.error(t('deposit-withdraw-wg.addpix_cpf3'), {
          containerId: 'global',
        });
        return false;
      }
    }
    return true;
  };

  const changeName = (event) => {
    let newVal = removeSpecialSymbols(TextPlaces1(event.target.value, 45));
    setFormData({ ...formData, name: newVal });
  };
  const changeCpf = (event) => {
    const cpfAllowedCharacters = /[^0-9.-]+/g;
    const value = event.target.value.replace(cpfAllowedCharacters, '');
    const lastValue = formData.cpf;
    if (String(value).length < String(lastValue).length) {
      //如果是删除
      // const cpf = deleteCPF(lastValue);
      const cpf = String(value) != '' ? deleteCPF(lastValue) : '';
      setFormData({ ...formData, cpf: cpf });
    } else {
      let cpf = formatCPF(value.replace(/[\.-]/g, ''));
      const maxLength = 11 + 3;
      if (cpf.length > maxLength) {
        // 截断输入的值
        cpf = cpf.slice(0, maxLength);
      }
      setFormData({ ...formData, cpf: cpf });
    }
  };

  const handleClick = () => {
    if (!formData.name) {
      return toast.error(t('account.name_required'), {
        containerId: 'global',
      });
    }
    if (!formData.cpf) {
      return toast.error(t('account.cpf_required'), {
        containerId: 'global',
      });
    } else if (!validation(formData.cpf)) {
      return;
    }

    const submitFormData = {
      ...cloneDeep(formData),
      cpf: formData.cpf.replace(/[\.-]/g, ''),
    };

    setRequesting(true);
    GetCpfBind(submitFormData)
      .then((res) => {
        if (res.code == 0) {
          toast.success(t('home.toast020'), {
            containerId: 'global',
          });
          handleClose();
          bindSuccessCallback();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };
  return (
    <div className="w-[6.86rem] bg-[var(--colour-35)] border border-[var(--colour-6)] rounded-[.16rem] p-[.33rem_.3rem_.46rem_.3rem] relative">
      <div className="mb-[.32rem]">
        <div className="mb-[.15rem] text-[var(--colour-48)] text-[.32rem] font-[400] leading-[.38rem] text-center">
          {t('account.binding')}
        </div>
        <div className="h-[1px] bg-[var(--colour-6)]"></div>
      </div>
      <div className="mb-[.48rem]">
        <div className="mb-[.2rem]">
          <Input
            leftInner={
              <div className="w-[.32rem] h-[.32rem] mr-[.2rem] text-[var(--colour-37)]">
                <Icon name={IconName.Account2} />
              </div>
            }
            value={formData.name}
            onChange={(event) => {
              changeName(event);
            }}
            placeholder={t('account.please_enter_name')}
            type="text"
            innerClassName="!border-transparent !h-[.7rem] !pl-[.22rem]"
            topClassName={`rounded-normal overflow-hidden bg-[var(--colour-35)] border border-[var(--colour-6)]`}
          />
        </div>
        <div>
          <Input
            leftInner={
              <div className="w-[.32rem] h-[.32rem] mr-[.2rem] text-[var(--colour-37)]">
                <Icon name={IconName.AccountCard} />
              </div>
            }
            value={formData.cpf}
            onChange={(event) => {
              changeCpf(event);
            }}
            placeholder={t('account.invalid_CPF_will_affect_transactions')}
            type="text"
            innerClassName="!border-transparent !h-[.7rem] !pl-[.22rem]"
            topClassName={`rounded-normal overflow-hidden bg-[var(--colour-35)] border border-[var(--colour-6)]`}
          />
        </div>
      </div>
      <LimitClickWrapper
        className="h-[.7rem] bg-[var(--colour-1)] rounded-[.16rem] cursor-pointer active:scale-[.95] transition-all select-none text-center text-[var(--colour-2)] text-[.24rem] font-[500] leading-[.7rem] flex items-center justify-center"
        onClick={() => {
          if (requesting) return;
          handleClick();
        }}
      >
        <ElementWithLoading isLoading={requesting} normalEl={<div className=""> {t('account.binding')}</div>} />
      </LimitClickWrapper>
      <div className="absolute top-[.17rem] right-[.27rem] select-none w-[.6rem] h-[.6rem]">
        <Close onClick={handleClose} className="!w-[.6rem] !h-[.6rem] text-[var(--colour-48)]" />
      </div>
    </div>
  );
};

export default Binding;
