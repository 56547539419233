import { get } from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setDrawerMenuVisible } from '../../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import useHandleLogic from '../../../hooks/useHandleLogic';
import cn from 'classnames';
import { isUseLayoutMobile } from '../../../utils/helper';

const LayoutMobile = ({ click, className = '' }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`h-[.56rem] px-[.16rem] flex justify-center items-center active:scale-[.95] transition-all select-none text-[.24rem] font-[400] bg-[var(--colour-1)] rounded-normal cursor-pointer text-[var(--colour-2)] ${className}`}
      onClick={click}
    >
      <div>{t('home.login')}</div>
    </div>
  );
};

export default function Index({ className = '' }) {
  const { userObj, unReadCount, defaultLang, needUpdateBalance, identity, showSupportNew, drawerMenuVisible } =
    useSelector(
      (state) => ({
        unReadCount: get(state, 'app.unReadCount'),
        userObj: get(state, 'user.userObj'),
        defaultLang: get(state, 'user.defaultLang'),
        needUpdateBalance: state.app.needUpdateBalance,
        showSupportNew: state.app.showSupportNew,
        identity: state.user.identity,
        drawerMenuVisible: state.app.drawerMenuVisible,
      }),
      shallowEqual,
    );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleGoLogin } = useHandleLogic();

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  const click = () => {
    handleGoLogin();
    handleLinkClick();
  };

  return isUseLayoutMobile() ? (
    <LayoutMobile click={click} className={className} />
  ) : (
    <div
      className={cn(
        `h-[.8rem] flex justify-center items-center active:scale-[.95] transition-all select-none cursor-pointer w-[1.6rem] rounded-normal bg-[var(--colour-1)]`,
      )}
      onClick={click}
    >
      <div className="font-[600] text-[.28rem] leading-[1em] text-center text-[var(--colour-2)]">{t('home.login')}</div>
    </div>
  );
}
