/*
 * @Author: milo
 * @Date: 2023-08-25 12:59:04
 * @LastEditors: milo
 * @LastEditTime: 2024-11-27 17:47:56
 */
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from './icon';
import useGoBack from '../hooks/useGoBack';

export default function Back({ className = '', onClick = null, text = '' }) {
  const { t } = useTranslation();
  const goBack = useGoBack();
  return (
    <div
      className={`inline-flex items-center cursor-pointer bg-[var(--colour-45)] h-[.52rem] rounded-normal px-[.2rem] ${className}`}
      onClick={() => {
        onClick == null ? goBack() : onClick();
      }}
    >
      <span className="text-[.24rem] font-[500] text-[var(--colour-46)]">{text}</span>
    </div>
  );
}
