import NTemplate001 from './normal/template001';
import DTemplate003 from './design/template003';

export default function Index({ data, boxClassname = '', handleClose = () => {}, showType = 1 }) {
  switch (showType) {
    case 2:
      return <DTemplate003 data={data} boxClassname={boxClassname} handleClose={handleClose} />;
    default:
      return <NTemplate001 data={data} boxClassname={boxClassname} handleClose={handleClose} />;
  }
}
