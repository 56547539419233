export function getHourMinute(time) {
  // 转换为式分秒
  let h = parseInt((time / 60 / 60) % 24);
  h = h < 10 ? '0' + h : h;
  let m = parseInt((time / 60) % 60);
  m = m < 10 ? '0' + m : m;
  // let s = parseInt(time % 60);
  // s = s < 10 ? '0' + s : s;
  // 作为返回值返回
  return [h, m];
}

export function banfenb(num, p = 0) {
  return (num * 100).toFixed(2).replace(/\.?0+$/, '');
}

export const getFengeFu = (vipLevel) => {
  if (vipLevel == 0) return ['#DD8151', 'text-vipbonus-0']; //gradient(180deg, #D0A882 0%, #9B583D 100%);
  if (vipLevel == 1) return ['#948CB9', 'text-vipbonus-1']; //gradient(180deg, #B4A8D1 0%, #7974A5 100%)
  if (vipLevel == 2) return ['#CE993D', 'text-vipbonus-2']; //gradient(180deg, #EACB68 0%, #B8711B 100%)
  if (vipLevel == 3) return ['#47B2C8', 'text-vipbonus-3']; //gradient(180deg, #74C4E7 0%, #23A4B0 100%);
  if (vipLevel == 4) return ['#4275D2', 'text-vipbonus-4']; //radient(180deg, #6787EA 0%, #1B62B9 100%);
  if (vipLevel == 5) return ['#733FCF', 'text-vipbonus-5']; //gradient(180deg, #A668EA 0%, #451BB8 100%);
  if (vipLevel == 6) return ['#CB41C7', 'text-vipbonus-6']; //gradient(180deg, #EA67D7 0%, #AD1BB8 100%);
  if (vipLevel == 7) return ['#D44653', 'text-vipbonus-7']; //gradient(180deg, #EA6A69 0%, #B91B3A 100%);
  if (vipLevel == 8) return ['#DD8151', 'text-vipbonus-8']; //gradient(180deg, #F0B47E 0%, #C94C22 100%);
  if (vipLevel == 9) return ['#62C3B7', 'text-vipbonus-9']; //gradient(180deg, #89E3E1 0%, #359F87 100%);
  if (vipLevel == 10) return ['#9A68C7', 'text-vipbonus-10']; //gradient(180deg, #BB85DA 0%, #6336A7 100%);
  if (vipLevel == 11) return ['#948CB9', 'text-vipbonus-1']; //gradient(180deg, #B4A8D1 0%, #7974A5 100%)
  if (vipLevel == 12) return ['#CE993D', 'text-vipbonus-2']; //gradient(180deg, #EACB68 0%, #B8711B 100%)
  if (vipLevel == 13) return ['#47B2C8', 'text-vipbonus-3']; //gradient(180deg, #74C4E7 0%, #23A4B0 100%);
  if (vipLevel == 14) return ['#4275D2', 'text-vipbonus-4']; //radient(180deg, #6787EA 0%, #1B62B9 100%);
  if (vipLevel == 15) return ['#733FCF', 'text-vipbonus-5']; //gradient(180deg, #A668EA 0%, #451BB8 100%);
  if (vipLevel == 16) return ['#CB41C7', 'text-vipbonus-6']; //gradient(180deg, #EA67D7 0%, #AD1BB8 100%);
  if (vipLevel == 17) return ['#D44653', 'text-vipbonus-7']; //gradient(180deg, #EA6A69 0%, #B91B3A 100%);
  if (vipLevel == 18) return ['#DD8151', 'text-vipbonus-8']; //gradient(180deg, #F0B47E 0%, #C94C22 100%);
  if (vipLevel == 19) return ['#62C3B7', 'text-vipbonus-9']; //gradient(180deg, #89E3E1 0%, #359F87 100%);
  if (vipLevel == 20) return ['#9A68C7', 'text-vipbonus-10']; //gradient(180deg, #BB85DA 0%, #6336A7 100%);
};

export const getImg = (num) => {
  return `/static/img/vip2/Viplevel${num}.png`;
};
