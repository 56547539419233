import React, { useState } from 'react';
import Icon, { IconName } from './icon';
import Input from './input';
import cn from 'classnames';
import { isNumber } from 'lodash';

export const PopChooseItem = ({
  data,
  choose = false,
  children,
  onClick,
  className = '',
  chooseClassName = 'rounded-normal bg-[var(--colour-1)] !text-[var(--colour-2)]',
  notChooseClassName = '',
  style = {},
  widthNumber = null,
}) => {
  const allStyle = {
    ...style,
    ...(isNumber(widthNumber) && widthNumber > 0 ? { width: `${widthNumber}px` } : {}),
  };

  return (
    <li
      onClick={() => {
        onClick(data);
      }}
      className={cn(`flex flex-row items-center cursor-pointer text-[var(--colour-44)] ${className}`, {
        // 'rounded-normal bg-[var(--colour-51)]': choose,
        [chooseClassName]: choose,
        [notChooseClassName]: !choose,
      })}
      style={allStyle}
    >
      {children}
    </li>
  );
};

const PopChoose = ({
  allowSearch = false,
  searchCallback = () => {},
  className = '',
  scrollBarWrapperClassName = '',
  children,
  style = {},
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchValueChange = (ev) => {
    const val = ev.target.value;
    setSearchValue(val);
    searchCallback(val);
  };

  return (
    <div className={`rounded-normal scrollbar-box-container ${className}`} style={style}>
      {allowSearch ? (
        <>
          <div className="flex flex-col mx-[.1rem]">
            <Input
              innerClassName="bg-[var(--colour-51)]"
              value={searchValue}
              onChange={handleSearchValueChange}
              leftInner={
                <span className="w-[.4rem] h-[.4rem] block cursor-pointer mr-[.2rem]">
                  <Icon name={IconName.Search} />
                </span>
              }
            />
          </div>
          <div className="bg-[#222222] h-[1px] my-[.2rem]"></div>
        </>
      ) : (
        <></>
      )}
      <div className={scrollBarWrapperClassName}>
        <div className="scrollbar-box-outer">
          <ul className="scrollbar-box-inner">{children}</ul>
        </div>
      </div>
    </div>
  );
};

export default PopChoose;
