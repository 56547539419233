import { useState, useEffect, memo, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Input from '../../input';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../icon';
import LimitClickWrapper from '../../limitClickWrapper';
import ElementWithLoading from '../../elementWithLoading';
import {
  getShowMoneyNumber,
  putChangeText,
  putShowMoney,
  TextPlaces1,
  cutDecimal,
  pointToComma,
  commaToPoint,
  currentTimeToEndTimeDiff,
  putChangeTextCorty,
  putPatton,
  getLocalStorageWithUID,
  setLocalStorageWithUID,
  formatCpf,
  CopyText,
} from '../../../utils/helper';
import { DepositInfo, DepositAction, ExchangeRate } from '../../../api/index';
import Loading2 from '../../loading2';
import { toast } from 'react-toastify';
import DialogModal from '../../dialogModal/index';
import Close from '../../close';
import AnalyticsManager, { FirebaseEvents, EventIds } from '../../../manager/analyticsManager';

import HotPng from '../../../assets/img/hot.png';
// import Down from '../../../assets/img/down1.png';
import Countdown from 'react-countdown';
import { cloneDeep } from 'lodash';
import DepositQR from './depositQR';
import QRCode from '../../../components/qrCode';
import usePayConfig from '../../../hooks/usePayConfig';
import ConfirmTips from '../../confirmTips';
import {
  deleteCPF,
  formatCPF,
  restrictToNumbers,
  restrictToName,
  isValidCPF,
  limitInputLength,
  removeSpecialSymbols,
} from '../../../utils/inputHelper';
import { useUpdateEffect } from 'ahooks';
import scrollIntoView from 'scroll-into-view';
import usePay from '../../../hooks/usePay';

import cn from 'classnames';
var dataCount = 0;
var timer = null;

const Deposit = memo(({ handleGiftMoneyChange = () => {}, params, isFullscreen = false }) => {
  const [dateTime, setDateTime] = useState(0);
  // tab 设置 fait 还是 数字货币
  const [faitSet, setFaitSet] = useState(0);

  const CountDowns = () => <Countdown zeroPadTime={2} date={dateTime} renderer={renderer} />;

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, []);
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      if (dataCount == 0) {
        setTimeout(() => {
          if (!refresh) {
            setTimeout(() => {
              setRefrensh(false);
            }, 1500);

            setRefrensh(true);
          }
          getExchangeRateData();
        }, 150);

        dataCount++;
      } else {
        if (!timer) {
          dataCount++;
          timer = setInterval(() => {
            if (!refresh) {
              setTimeout(() => {
                setRefrensh(false);
              }, 1500);
              setRefrensh(true);
            }

            getExchangeRateData();
          }, 6000);
        }
      }

      return <span className="text-[var(--colour-41)]">(00:00)</span>;
    } else {
      dataCount = 0;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }

      return (
        <span className="text-[var(--colour-41)]">
          ({minutes < 10 ? String(0) + String(minutes) : minutes}:{seconds < 10 ? String(0) + String(seconds) : seconds}
          )
        </span>
      );
    }
  };
  const {
    gift_money_menu_show_switch,
    currency_default_location,
    deposit_fiat_bouns_open_switch,
    deposit_crypto_bouns_open_switch,
    switch_wallet_crypto_deposit,
  } = usePayConfig();
  const { websiteInitData } = useSelector(
    (state) => ({
      websiteInitData: state.app.websiteInitData?.prompt,
    }),
    shallowEqual,
  );

  const initConfig = params;

  const [network, setNetWork] = useState(null);

  const [loadingShow, setLoadingShow] = useState(true);

  const [result, setResult] = useState(null);

  const [bonus, setBonus] = useState(false);

  const [bonusConfig, setBonusConfig] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  const [rechargeUrl, setRechargeUrl] = useState('');

  const [depositTips, setDepositTips] = useState({});

  // 打开邀请码
  const [isOpenQrcode, setIsOpenQrcode] = useState(false);

  const [depositList, setDepositList] = useState([]);

  // 费率
  const [exchangeRate, setExchangeRate] = useState([]);

  //  内容
  const [fiatList, setFiatList] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);

  //充值限额配置
  const [limitAmount, setLimitAmount] = useState(0);
  const [limitRequiredContent, setLimitRequiredContent] = useState([]);
  const [isShowConentList, setIsShowContentList] = useState(false); //输入框金额是否大于配置的limit_amount金额

  const [defaultValueConfig, setDefaultValueConfig] = useState(null); //name和cpf输入框的默认显示

  const getDepositListValue = (name) => {
    const obj = depositList.find((item) => {
      return item.name === name;
    });
    return obj ? obj.value : '';
  };

  const getLimitRequiredListValue = (name) => {
    const obj = limitRequiredContent.find((item) => {
      return item.key === name;
    });
    return obj ? obj.value : '';
  };

  const savePayDepositInfoKey = 'pay_deposit_info';
  const savePayInfo = (list) => {
    if (list.length > 0) {
      const saveObj = {};
      for (let i = 0; i < list.length; i++) {
        const name = String(list[i].name).toLowerCase();
        if (name == 'cpf' || name == 'name') {
          saveObj[name] = list[i].value;
        }
      }
      setLocalStorageWithUID(savePayDepositInfoKey, JSON.stringify(saveObj));
    }
  };

  const getCacheValue = (key) => {
    const str = getLocalStorageWithUID(savePayDepositInfoKey);
    if (str) {
      const obj = JSON.parse(str);
      return obj[key];
    } else {
      return '';
    }
  };

  const getConfigValue = (key) => {
    if (defaultValueConfig && defaultValueConfig[key]) {
      if (key == 'cpf') {
        return formatCpf(defaultValueConfig[key]);
      } else {
        return defaultValueConfig[key];
      }
    } else {
      return '';
    }
  };

  //校验detailinfo的字段,true为校验通过，false为校验失败
  const validationDetailInfo = (jsonObj) => {
    if (jsonObj) {
      if (String(jsonObj.name).toLowerCase() == 'cpf') {
        const isTrueCPF = isValidCPF(jsonObj.value);
        if (isTrueCPF) {
          return true;
        } else {
          toast.error(t('deposit-withdraw-wg.addpix_cpf3'), {
            containerId: 'global',
          });
          return false;
        }
      }
    }
    return true;
  };

  // 获取相等的到汇率
  const getExchangeRate = () => {
    if (exchangeRate && exchangeRate.length > 0) {
      const result = exchangeRate.find(
        (item) => item.crypto === cryptoList[channel].currency && item.fiat === initConfig.currency_code,
      );
      return result ? cutDecimal(Number(Number(result.deposit_rate).toFixed(4))) : 0;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (exchangeRate && exchangeRate.length > 0 && cryptoList && cryptoList.length > 0) {
      if (cryptoList[channel]) {
        const result = exchangeRate.find(
          (item) => item.crypto === cryptoList[channel].currency && item.fiat === initConfig.currency_code,
        );
        if (result) {
          let time = currentTimeToEndTimeDiff('', result.expire_timestamp);
          time = time * 1000;
          setDateTime(Date.now() + time);
        }
      }
    }
  }, [exchangeRate, cryptoList]);

  const getExchangeRateData = () => {
    ExchangeRate().then((res) => {
      if (res.code == 0) {
        if (res.result) {
          setExchangeRate(res.result.list);
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  //动画
  const [refresh, setRefrensh] = useState(false);
  // 定义一个处理值变化的函数
  const handleValueChange = (name, newValue) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedDepositList = depositList.map((item) => {
      if (item.name === name) {
        return { ...item, value: newValue };
      }
      return item;
    });

    // 更新状态
    setDepositList(updatedDepositList);
  };

  // 定义一个处理值变化的函数
  const handleValueChange1 = (array, newValue) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedDepositList = array.map((item) => {
      return { ...item, value: getConfigValue(item.name) || getCacheValue(item.name) || newValue };
    });

    // 更新状态
    setDepositList(updatedDepositList);
  };

  // 定义一个处理值变化的函数
  const newHandleValueChange = (key, newValue) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedLimitContentList = limitRequiredContent.map((item) => {
      if (item.key === key) {
        return { ...item, value: newValue };
      }
      return item;
    });

    // 更新状态
    setLimitRequiredContent(updatedLimitContentList);
  };

  // 定义一个处理值变化的函数
  const newHandleValueChange1 = (array) => {
    // 使用map方法创建一个新的depositList数组，将新值分配给指定的"name"属性
    const updatedLimitContentList = array.map((item) => {
      return { key: item, value: getConfigValue(item) || getCacheValue(item) || '' };
    });

    // 更新状态
    setLimitRequiredContent(updatedLimitContentList);
  };

  // 处理数组
  const ChangeDataArray = (data) => {
    var arrayFiat = [];
    var arrayCrypto = [];
    if (data && data.deposit_list && data.deposit_list.length > 0) {
      // 重新组建数组
      data.deposit_list.map((item) => {
        if (item.type == 'crypto') {
          arrayCrypto.push(item);
        } else {
          arrayFiat.push(item);
        }
      });
      setFiatList(arrayFiat);
      // 如果数组有就开始遍历
      if (arrayCrypto && arrayCrypto.length > 0) {
        if (switch_wallet_crypto_deposit != 1) {
          setCryptoList([]);
        } else {
          // 创建一个空对象，用于存储相同"currency"的对象
          const mergedData = arrayCrypto.reduce((acc, item) => {
            const existingItem = acc.find((accItem) => accItem.currency === item.currency);
            if (existingItem) {
              existingItem.networks.push(item);
            } else {
              item.networks = [item];
              acc.push(item);
            }
            return acc;
          }, []);
          if (mergedData && mergedData[0] && mergedData[0].networks && mergedData[0].networks.length > 0) {
            setNetWork(mergedData[0].networks[0]);
          }

          // 新数据
          if (currency_default_location == 1) {
            setDepositType(2);
            if (mergedData && mergedData.length > 0) {
              if (mergedData[0].networks && mergedData[0].networks.length > 0) {
                let item = mergedData[0].networks[0];

                AnalyticsManager.FirebaseLogEvent(
                  FirebaseEvents.WB_Click_PayOption_ +
                    `${item.provider}` +
                    '_' +
                    `${item.network}` +
                    '_' +
                    `${item.currency}`,
                );

                // wbClick('WB_Click_PayOption_' + item.provider + '_' + item.network + '_' + item.currency);
              }
            }
            setFaitSet(currency_default_location);
          } else {
            if (arrayFiat && arrayFiat.length > 0) {
              AnalyticsManager.FirebaseLogEvent(
                FirebaseEvents.WB_Click_PayOption_ + `${arrayFiat[0].provider}` + '_' + `${arrayFiat[0].network}`,
              );

              // wbClick('WB_Click_PayOption_' + arrayFiat[0].provider + '_' + arrayFiat[0].network);
            }
          }
          getExchangeRateData();
          setCryptoList(mergedData);
        }
      }
    }
  };

  useEffect(() => {
    handleGiftMoneyChange(gift_money_menu_show_switch);
  }, [gift_money_menu_show_switch]);
  // 地址列表
  const [cryptoAddress, setCryptoAddress] = useState([]);
  useEffect(() => {
    DepositInfo()
      .then((res) => {
        if (res.code == 0) {
          // 查看是否拥有任务
          if (res && res.result && res.result.bonus && res.result.bonus.config) {
            try {
              const config = JSON.parse(res.result.bonus.config);
              if (config.bonus_ratio) {
                if (deposit_fiat_bouns_open_switch == 0) {
                  if (currency_default_location == 1) {
                    setWithBonus(true);
                  } else {
                    setWithBonus(false);
                  }
                }
                setBonus(true);
                setBonusConfig(config);
              } else {
                setWithBonus(false);
              }
            } catch {
              setWithBonus(false);
            }
          } else {
            setBonus(false);
            setWithBonus(false);
          }
          ChangeDataArray(res.result);
          if (res && res.result && res.result.wallet_crypto_address && res.result.wallet_crypto_address.length > 0) {
            setCryptoAddress(res.result.wallet_crypto_address);
          }

          setResult(res.result);
          setDefaultValueConfig(res.result?.user_deposit_brl);
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setLoadingShow(false);
      });
  }, []);

  useEffect(() => {
    if (websiteInitData) {
      setDepositTips(websiteInitData.walletDepositTips);
    }
  }, [websiteInitData]);

  // 记录支付通道
  const [channel, setChannel] = useState(0);
  // 记录充值钱数
  const [chooseAmount, setChooseAmount] = useState(100);
  // 多语言
  const { t } = useTranslation();
  // 是否选中活动
  const [withBonus, setWithBonus] = useState(true);

  // qrcodeURL
  const [qrcodeURL, setQrcodeURL] = useState('');

  const [requesting, setRequesting] = useState(false);

  const changeAmount = (result, channel, num) => {
    if (num == 1) {
      const depositChannel = result && result.length > 0 && result[channel];
      if (depositChannel) {
        const deposit_amount_list = JSON.parse(depositChannel.deposit_amount_list);
        const default_deposit_amount = depositChannel.default_deposit_amount;

        if (deposit_amount_list.length > 0) {
          if (deposit_amount_list.some((item) => Number(item) == Number(default_deposit_amount))) {
            setChooseAmount(Number(default_deposit_amount));
          } else {
            setChooseAmount(deposit_amount_list[0]);
          }
        }
      }

      if (result && result.length > 0) {
        try {
          handleValueChange1(JSON.parse(result[channel].deposit_fields), '');
        } catch {
          setDepositList([]);
        }
      }
    } else {
      // 区块链
      if (result) {
        var deposit_amount_list = [];
        var default_deposit_amount = '';
        try {
          deposit_amount_list = JSON.parse(result.deposit_amount_list);
          default_deposit_amount = result.default_deposit_amount;
        } catch {}

        if (deposit_amount_list.length > 0) {
          if (deposit_amount_list.some((item) => Number(item) == Number(default_deposit_amount))) {
            setChooseAmount(Number(default_deposit_amount));
          } else {
            setChooseAmount(deposit_amount_list[0], channel);
          }
        }
      }

      if (result) {
        try {
          handleValueChange1(JSON.parse(result.deposit_fields), '');
        } catch {
          setDepositList([]);
        }
      }
    }
  };
  const [depositType, setDepositType] = useState(1);

  //切换channel选中相应的amount
  useEffect(() => {
    if (depositType == 1) {
      changeAmount(fiatList, channel, 1);
    } else {
      changeAmount(network, channel, 2);
    }
  }, [channel, depositType, fiatList, network]);

  useEffect(() => {
    if (depositType == 2) {
      if (cryptoList && cryptoList.length > 0) {
        setLimitAmount(cryptoList[channel]?.limit_amount);
        if (cryptoList[channel]?.limit_required_content) {
          newHandleValueChange1(JSON.parse(cryptoList[channel]?.limit_required_content));
        } else {
          setLimitRequiredContent([]);
        }
      }
    } else {
      if (fiatList && fiatList.length > 0) {
        setLimitAmount(fiatList[channel]?.limit_amount);
        if (fiatList[channel]?.limit_required_content) {
          newHandleValueChange1(JSON.parse(fiatList[channel]?.limit_required_content));
        } else {
          setLimitRequiredContent([]);
        }
      }
    }
  }, [channel, depositType, fiatList, cryptoList]);

  //当前输入金额和配置的充值限额比较
  useEffect(() => {
    if (Number(putShowMoney(commaToPoint(chooseAmount))) >= Number(limitAmount)) {
      setIsShowContentList(true);
    } else {
      setIsShowContentList(false);
    }
  }, [chooseAmount, limitAmount]);

  //充值
  const DospoitClick = () => {
    if (!commaToPoint(chooseAmount)) {
      return toast.error(t('deposit-withdraw.deposit_submit'), {
        containerId: 'global',
      });
    }
    if (Number(commaToPoint(chooseAmount)) < Number(getShowMoneyNumber(fiatList[channel].deposit_min))) {
      return toast.error(
        t('deposit-withdraw.deposit_submit1', {
          min_amount: getShowMoneyNumber(fiatList[channel].deposit_min),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    if (Number(commaToPoint(chooseAmount)) > Number(getShowMoneyNumber(fiatList[channel].deposit_max))) {
      return toast.error(
        t('deposit-withdraw.deposit_submit2', {
          max_amount: getShowMoneyNumber(fiatList[channel].deposit_max),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    // 判断是否必填
    var detail_info = [];
    if (depositList && depositList.length > 0) {
      for (let i = 0; i < depositList.length; i++) {
        var item = depositList[i];
        if (!item.value) {
          toast.error(t('deposit-withdraw.please_enter', { name: item.placeholder }), {
            containerId: 'global',
          });
          return;
        } else {
          if (validationDetailInfo(item)) {
            detail_info.push({
              name: item.name,
              value: item.value,
            });
          } else {
            return;
          }
        }
      }
    }

    if (Number(limitAmount) > 0 && isShowConentList && limitRequiredContent && limitRequiredContent.length > 0) {
      for (let i = 0; i < limitRequiredContent.length; i++) {
        var contentItem = limitRequiredContent[i];
        if (!contentItem.value || contentItem.value.trim() == '') {
          toast.error(t('deposit-withdraw.please_enter', { name: contentItem.key }), {
            containerId: 'global',
          });
          return;
        } else {
          if (validationDetailInfo(contentItem)) {
            detail_info.push({
              name: contentItem.key,
              value: contentItem.value.trim(),
            });
          } else {
            return;
          }
        }
      }
    }
    setRequesting(true);

    const tempDetailInfo = cloneDeep(detail_info);
    //对提交到服务端的detail_info进行处理
    for (let i = 0; i < detail_info.length; i++) {
      if (String(detail_info[i].name).toLowerCase() == 'cpf') {
        detail_info[i].value = detail_info[i].value.replace(/[\.-]/g, '');
      }
    }

    const formData = {
      id: fiatList[channel].id,
      amount: putShowMoney(commaToPoint(chooseAmount)),
      with_bonus: withBonus ? 1 : 0,
      detail_info: detail_info.length > 0 ? JSON.stringify(detail_info) : '',
    };

    DepositAction({ ...formData })
      .then((res) => {
        if (res.code == 0) {
          savePayInfo(tempDetailInfo);
          var as_qrcode = false;
          if (result && fiatList && fiatList.length > 0) {
            as_qrcode = fiatList[channel].as_qrcode;
          }

          if (res.result && res.result.redirect_url) {
            setRechargeUrl(res.result.redirect_url);
            if (initConfig.currency_code == 'COP') {
              setConfirmed(true);
            } else {
              if (as_qrcode && res.result.redirect_url != res.result.qrcode_url && res.result.qrcode_url != '') {
                setQrcodeURL(res.result.qrcode_url);
                setIsOpenQrcode(true);
                if (!res.result.qrcode_url) {
                  //上报到我们自己的打点服务器
                  AnalyticsManager.sendExtraInfoLog2Server(EventIds.depositQrCodeErr, JSON.stringify(res));
                }
              } else {
                setIsOpen(true);
              }
            }
          } else if (res.result && !res.result.redirect_url && res.result.qrcode_url) {
            setQrcodeURL(res.result.qrcode_url);
            setIsOpenQrcode(true);
          }
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  // 内容
  const getTextForBonus = () => {
    if (bonusConfig) {
      if (bonusConfig.reward_type == 1) {
        return result.bonus.participate_type != 'one_time'
          ? t('deposit-withdraw.daily_first_type', { val: getBonusNumberCoy() })
          : t('deposit-withdraw.first_deposit_type', { val: getBonusNumberCoy() });
      } else if (bonusConfig.reward_type == 2) {
        return result.bonus.participate_type != 'one_time'
          ? t('deposit-withdraw.daily_first_type', {
              val: `${Number(Number(getMaxRewardTpe2(bonusConfig.rewards_list)?.reward_value * 100).toFixed(2))}%`,
            })
          : t('deposit-withdraw.first_deposit_type', {
              val: `${Number(Number(getMaxRewardTpe2(bonusConfig.rewards_list)?.reward_value * 100).toFixed(2))}%`,
            });
      }
      return result.bonus.participate_type != 'one_time'
        ? t('deposit-withdraw.daily_first', {
            val: `${Number(Number(Number(bonusConfig.bonus_ratio) * 100).toFixed(2))}%`,
          })
        : t('deposit-withdraw.first_deposit', {
            val: `${Number(Number(Number(bonusConfig.bonus_ratio) * 100).toFixed(2))}%`,
          });
    }
  };

  // type 1 2 获取number
  const getBonusNumberCoy = () => {
    if (bonusConfig.reward_type == 1) {
      return pointToComma(getShowMoneyNumber(Number(getMaxReward(bonusConfig.rewards_list)?.reward_value)));
    }
  };

  const getMaxReward = (rewardList) => {
    let maxRewardValue = 0;
    let maxRewardObject = null;

    rewardList.forEach((obj) => {
      const rewardValue = Number(obj.reward_value);
      if (rewardValue > maxRewardValue) {
        maxRewardValue = rewardValue;
        maxRewardObject = obj;
      }
    });
    return maxRewardObject;
  };

  const getMaxRewardTpe2 = (rewardList) => {
    let maxRewardValue = 0;
    let maxRewardObject = null;

    rewardList.forEach((obj) => {
      const rewardValue = Number(obj.reward_value);
      if (rewardValue > maxRewardValue) {
        maxRewardValue = rewardValue;
        maxRewardObject = obj;
      }
    });
    return maxRewardObject;
  };
  //虚拟币
  const RightMessageQukuai = () => {
    if (bonusConfig.reward_type == 1) {
      let findReward = findRewardChange(bonusConfig.rewards_list, commaToPoint(chooseAmount), 1, getExchangeRate());
      if (findReward) {
        return (
          <div className={`text-[.28rem] text-[var(--colour-38)]`}>
            Extra+ {initConfig.currency_symbol || ''}
            {pointToComma(findReward)}
          </div>
        );
      } else {
        return <></>;
      }
    } else if (bonusConfig.reward_type == 2) {
      let findReward1 = findRewardChange(bonusConfig.rewards_list, commaToPoint(chooseAmount), 2, getExchangeRate());
      if (findReward1) {
        return (
          <div className={`text-[.28rem] text-[var(--colour-38)]`}>
            Extra+ {initConfig.currency_symbol || ''}
            {pointToComma(
              cutDecimal(Number(commaToPoint(chooseAmount)) * Number(findReward1) * Number(getExchangeRate())) >
                Number(getShowMoneyNumber(bonusConfig.bonus_max))
                ? getShowMoneyNumber(bonusConfig.bonus_max)
                : cutDecimal(Number(commaToPoint(chooseAmount)) * Number(findReward1) * Number(getExchangeRate())),
            )}
          </div>
        );
      } else {
        return <></>;
      }
    }
    if (
      Number(commaToPoint(chooseAmount)) >=
      Number(Number(getShowMoneyNumber(bonusConfig.deposit_min, 5)) * (1 / Number(getExchangeRate())))
    ) {
      return (
        <div className={`text-[.28rem] text-[var(--colour-38)]`}>
          Extra+ {initConfig.currency_symbol || ''}
          {pointToComma(cutDecimal(BounsMoneyType2()))}
        </div>
      );
    }
  };
  // 法币right
  const RightMessageCor = () => {
    if (bonusConfig.reward_type == 1) {
      let findReward1 = findReward(bonusConfig.rewards_list, commaToPoint(chooseAmount));
      if (findReward1) {
        return (
          <div className={`text-[.28rem] text-[var(--colour-38)]`}>
            Extra+ {initConfig.currency_symbol || ''}
            {pointToComma(findReward1)}
          </div>
        );
      } else {
        return <></>;
      }
    } else if (bonusConfig.reward_type == 2) {
      let findReward1 = findReward(bonusConfig.rewards_list, commaToPoint(chooseAmount), 2);
      if (findReward1) {
        return (
          <div className={`text-[.28rem] text-[var(--colour-38)]`}>
            Extra+ {initConfig.currency_symbol || ''}
            {pointToComma(
              cutDecimal(Number(commaToPoint(chooseAmount)) * Number(findReward1)) >
                Number(getShowMoneyNumber(bonusConfig.bonus_max))
                ? getShowMoneyNumber(bonusConfig.bonus_max)
                : cutDecimal(Number(commaToPoint(chooseAmount)) * Number(findReward1)),
            )}
          </div>
        );
      } else {
        return <></>;
      }
    }

    if (Number(commaToPoint(chooseAmount)) >= Number(getShowMoneyNumber(bonusConfig.deposit_min))) {
      return (
        <div className={`text-[.28rem] text-[var(--colour-38)]`}>
          Extra+ {initConfig.currency_symbol || ''}
          {pointToComma(BounsMoney())}
        </div>
      );
    }
  };
  const BounsMoney = () => {
    return Number(commaToPoint(chooseAmount)) * Number(bonusConfig.bonus_ratio) <
      Number(getShowMoneyNumber(bonusConfig.bonus_max))
      ? Number(cutDecimal(Number(commaToPoint(chooseAmount)) * bonusConfig.bonus_ratio))
      : Number(cutDecimal(getShowMoneyNumber(bonusConfig.bonus_max)));
  };

  const BounsMoneyType2 = () => {
    return Number(Number(commaToPoint(chooseAmount)) * Number(bonusConfig.bonus_ratio)) * Number(getExchangeRate()) <
      Number(getShowMoneyNumber(bonusConfig.bonus_max, 5))
      ? Number(
          Number(
            Number(Number(commaToPoint(chooseAmount)) * Number(bonusConfig.bonus_ratio)) * Number(getExchangeRate()),
          ).toFixed(6),
        )
      : getShowMoneyNumber(bonusConfig.bonus_max, 5);
  };

  // 获取对应的key
  const { handleCopyWithErrorReport } = usePay();
  // 区块类型
  // 选择内容address
  const textAddress = () => {
    if (cryptoAddress && cryptoAddress.length > 0 && network) {
      const addressAddress = cryptoAddress.find((addressItem) => addressItem.network === network.network);
      return addressAddress?.address || '';
    } else {
      return '';
    }
  };

  const changeText1 = (ethereumAddress) => {
    if (ethereumAddress) {
      return ethereumAddress;
    } else {
      return ethereumAddress;
    }
  };
  const GetFeeForCry1 = () => {
    if (
      withBonus &&
      bonus &&
      Number(commaToPoint(chooseAmount)) >= Number(getShowMoneyNumber(bonusConfig.deposit_min, 5))
    ) {
      return cutDecimal(
        Number(
          Number(Number(Number(Number(getExchangeRate()) * Number(commaToPoint(chooseAmount))).toFixed(4))).toFixed(3),
        ),
      );
    } else {
      return cutDecimal(Number(Number(Number(getExchangeRate()) * Number(commaToPoint(chooseAmount))).toFixed(4)));
    }
  };

  // 弹出dialog 确认支付金额
  const [confirmed, setConfirmed] = useState(false);

  const handleCustomInputKeyDown = (event, name) => {
    if (String(name).toLowerCase() == 'cpf') {
      restrictToNumbers(event);
    } else if (String(name).toLowerCase() == 'name') {
      restrictToName(event);
      limitInputLength(event, 100);
    }
  };

  const handleCustomInputValueChange = (key, value, isLimitRequired) => {
    if (isLimitRequired) {
      newHandleValueChange(key, value);
    } else {
      handleValueChange(key, value);
    }
  };

  const handleCustomInputChange = (event, name, isLimitRequired = false) => {
    if (String(name).toLowerCase() == 'cpf') {
      const cpfAllowedCharacters = /[^0-9.-]+/g;
      const value = event.target.value.replace(cpfAllowedCharacters, '');
      const lastValue = isLimitRequired ? getLimitRequiredListValue(name) : getDepositListValue(name);
      if (String(value).length < String(lastValue).length) {
        //如果是删除
        // const cpf = deleteCPF(lastValue);
        const cpf = String(value) != '' ? deleteCPF(lastValue) : '';
        handleCustomInputValueChange(name, cpf, isLimitRequired);
      } else {
        let cpf = formatCPF(value.replace(/[\.-]/g, ''));
        const maxLength = 11 + 3;
        if (cpf.length > maxLength) {
          // 截断输入的值
          cpf = cpf.slice(0, maxLength);
        }
        handleCustomInputValueChange(name, cpf, isLimitRequired);
      }
    } else if (String(name).toLowerCase() == 'name') {
      let newVal = removeSpecialSymbols(TextPlaces1(event.target.value, 45));
      handleCustomInputValueChange(name, newVal, isLimitRequired);
    } else {
      let newVal = TextPlaces1(event.target.value, 45);
      handleCustomInputValueChange(name, newVal, isLimitRequired);
    }
  };

  const endRef = useRef(null);
  const hadScrollToBottom = useRef(false);
  let ScrollerTimer = null;
  const handleScrollToBottom = () => {
    if (endRef && !hadScrollToBottom.current) {
      ScrollerTimer = window.setTimeout(() => {
        if (ScrollerTimer != null) {
          clearTimeout(ScrollerTimer);
          ScrollerTimer = null;
        }
        scrollIntoView(endRef.current, {
          time: 500,
          validTarget: function (target, parentsScrolled) {
            return target !== window && ~target.className.indexOf('auto-scrollable');
          },
        });
      }, 300);
      hadScrollToBottom.current = true;
    }
  };
  useUpdateEffect(() => {
    handleScrollToBottom();
  }, [fiatList, cryptoList, depositList, limitRequiredContent]);

  useEffect(() => {
    if (!(fiatList && fiatList.length > 0 && cryptoList && cryptoList.length > 0)) {
      if (fiatList && fiatList.length > 0) {
        setDepositType(1);
        setChannel(0);
        if (deposit_fiat_bouns_open_switch == 0) {
          setWithBonus(false);
        } else {
          if (bonus) {
            setWithBonus(true);
          } else {
            setWithBonus(false);
          }
        }
      } else if (cryptoList && cryptoList.length > 0) {
        setDepositType(2);
        setChannel(0);
        if (deposit_crypto_bouns_open_switch == 0) {
          setWithBonus(false);
        } else {
          if (bonus) {
            setWithBonus(true);
          } else {
            setWithBonus(false);
          }
        }
      }
    }
  }, [fiatList, cryptoList]);

  return (
    <>
      {loadingShow ? (
        <Loading2 className="h-full" />
      ) : (
        result &&
        ((fiatList && fiatList.length > 0) || (cryptoList && cryptoList.length > 0)) && (
          <div className={`pb-[.4rem] ${isFullscreen ? '' : 'w-[6.56rem]'}`}>
            <div className="w-full max-w-full overflow-x-auto scrollbar-none">
              {fiatList && fiatList.length > 0 && cryptoList && cryptoList.length > 0 ? (
                <div className="mt-[.4rem]">
                  <div className="text-[.26rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.currency_type')}
                  </div>
                  <div className={`flex gap-[.1rem] flex-wrap mt-[.2rem] ${faitSet == 1 && 'flex-row-reverse'}`}>
                    <div
                      onClick={() => {
                        setDepositType(1);
                        setChannel(0);
                        if (fiatList && fiatList.length > 0) {
                          AnalyticsManager.FirebaseLogEvent(
                            FirebaseEvents.WB_Click_PayOption_ +
                              `${fiatList[0].provider}` +
                              '_' +
                              `${fiatList[0].network}`,
                          );

                          // wbClick('WB_Click_PayOption_' + fiatList[0].provider + '_' + fiatList[0].network);
                        }

                        if (deposit_fiat_bouns_open_switch == 0) {
                          setWithBonus(false);
                        } else {
                          if (bonus) {
                            setWithBonus(true);
                          } else {
                            setWithBonus(false);
                          }
                        }
                      }}
                      className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center flex-1 h-[.8rem] rounded-normal ${
                        depositType == 1
                          ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                          : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                      }`}
                    >
                      <span className={`ml-[.04rem] text-[.24rem] font-[700]`}>{t('deposit-withdraw.fiat')}</span>
                    </div>
                    <div
                      onClick={() => {
                        setDepositType(2);
                        if (cryptoList && cryptoList.length > 0) {
                          if (cryptoList[0].networks && cryptoList[0].networks.length > 0) {
                            var item = cryptoList[0].networks[0];
                            setNetWork(item);
                            AnalyticsManager.FirebaseLogEvent(
                              FirebaseEvents.WB_Click_PayOption_ +
                                `${item.provider}` +
                                '_' +
                                `${item.network}` +
                                '_' +
                                `${item.currency}`,
                            );

                            // wbClick('WB_Click_PayOption_' + item.provider + '_' + item.network + '_' + item.currency);
                          }
                        }
                        setChannel(0);
                        if (deposit_crypto_bouns_open_switch == 0) {
                          setWithBonus(false);
                        } else {
                          if (bonus) {
                            setWithBonus(true);
                          } else {
                            setWithBonus(false);
                          }
                        }
                      }}
                      className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center flex-1 h-[.8rem] rounded-normal ${
                        depositType == 2
                          ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                          : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                      }`}
                    >
                      <span className={`ml-[.04rem] text-[.24rem] font-[700]`}>{t('deposit-withdraw.crypto')}</span>
                    </div>
                  </div>
                </div>
              ) : null}
              {depositType == 2 && (
                <div>
                  <div className="relative">
                    <div className="flex gap-[.1rem] flex-wrap mt-[.2rem]">
                      {cryptoList && cryptoList.length > 0 ? (
                        cryptoList.map((item, index) => (
                          <div key={index}>
                            {/* {item.default_deposit_recommend == 1 && <img className="absolute h-[.6rem]" src={HotPng} />} */}
                            <div
                              key={item.id}
                              onClick={() => {
                                setChannel(index);
                                if (item && item.networks && item.networks.length > 0) {
                                  setNetWork(item.networks[0]);
                                  AnalyticsManager.FirebaseLogEvent(
                                    FirebaseEvents.WB_Click_PayOption_ +
                                      `${item.networks[0].provider}` +
                                      '_' +
                                      `${item.networks[0].network}` +
                                      '_' +
                                      `${item.networks[0].currency}`,
                                  );

                                  // wbClick(
                                  //   'WB_Click_PayOption_' +
                                  //     item.network[0].provider +
                                  //     '_' +
                                  //     item.network[0].network +
                                  //     '_' +
                                  //     item.networks[0].currency,
                                  // );
                                }
                              }}
                              className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center w-[1.56rem] h-[.8rem] rounded-normal ${
                                channel == index
                                  ? 'border border-[var(--colour-1)] bg-[var(--colour-5)] text-[var(--colour-3)]'
                                  : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                              }`}
                            >
                              <span className="w-[.32rem] h-[.32rem] block">
                                {item.currency == 'USDT' && <Icon name={IconName.USDT} />}
                                {item.currency == 'BTC' && <Icon name={IconName.BTC} />}
                                {item.currency == 'ETH' && <Icon name={IconName.ETH} />}
                                {item.currency == 'TRX' && <Icon name={IconName.TRX} />}
                                {item.currency == 'USDC' && <Icon name={IconName.USDC} />}
                                {item.currency == 'BRZ' && <Icon name={IconName.BRZ} />}
                              </span>

                              <span className={`ml-[.04rem] text-[.24rem] font-[700]`}>{item.currency}</span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {depositType != 2 && (
                <div>
                  <div className="text-[.26rem] mt-[.4rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.select_channel')}
                  </div>

                  <div className="relative">
                    <div className="flex gap-[.1rem] flex-wrap mt-[.2rem]">
                      {fiatList && fiatList.length > 0 ? (
                        fiatList.map((item, index) => (
                          <div
                            key={item.id}
                            onClick={() => {
                              AnalyticsManager.FirebaseLogEvent(
                                FirebaseEvents.WB_Click_PayOption_ + `${item.provider}` + '_' + `${item.network}`,
                              );

                              // wbClick('WB_Click_PayOption' + item.provider + '_' + item.network);
                              setChannel(index);
                            }}
                            className={`relative flex cursor-pointer active:scale-[.95] select-none items-center justify-center w-[2.1rem] h-[.8rem] rounded-normal ${
                              channel == index
                                ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                                : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                            }   ${'item_width'}`}
                          >
                            {item.default_deposit_recommend == 1 && (
                              <img className="absolute h-[.6rem] left-[0] top-[0]" src={HotPng} />
                            )}

                            <span className={`font-[700] text-[.26rem]`}>{item.deposit_name}</span>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {depositType != 2 && (
                <MoneyList
                  result={fiatList}
                  setChooseAmount={setChooseAmount}
                  chooseAmount={chooseAmount}
                  withBonus={withBonus}
                  bonus={bonus}
                  bonusConfig={bonusConfig}
                  channel={channel}
                  t={t}
                  initConfig={initConfig}
                />
              )}
              {depositType == 2 && (
                <MoneyListCrypo
                  result={network}
                  rate={getExchangeRate()}
                  setChooseAmount={setChooseAmount}
                  chooseAmount={chooseAmount}
                  withBonus={withBonus}
                  bonus={bonus}
                  bonusConfig={bonusConfig}
                  channel={channel}
                  t={t}
                  initConfig={initConfig}
                />
              )}
              {/* input 区块链充值 */}
              {depositType == 2 && cryptoList && cryptoList.length > 0 && (
                <div>
                  <div className="text-[.26rem] mt-[.32rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.crypto_amount')}
                    <span className="text-[var(--colour-25)]"> *</span>
                  </div>
                  <div className="mt-[.2rem] text-[var(--colour-38)]">
                    <Input
                      maxPoint={5}
                      leftOuter={
                        <div className="flex items-center">
                          <div className="text-[.32rem] font-[700] block mr-[.2rem] ml-[.28rem] text-[var(--colour-3)]">
                            {cryptoList[channel].currency || ''}
                          </div>
                        </div>
                      }
                      type="number"
                      onChange={(val) => {
                        let newVal = putChangeTextCorty(val.target.value);
                        setChooseAmount(newVal);
                      }}
                      value={chooseAmount}
                      placeholder={t('deposit-withdraw.max_amount_enter', {
                        min: getShowMoneyNumber(cryptoList[channel].deposit_min),
                        max: getShowMoneyNumber(cryptoList[channel].deposit_max),
                      })}
                      inputClassName="!text-[var(--colour-3)]"
                      innerClassName="!h-[.92rem] !border-transparent"
                      topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                    />
                  </div>
                  <div className="flex justify-center">
                    <div className="w-[.66rem] h-[.66rem] text-[var(--colour-14)] mt-[.44rem]">
                      <Icon name={IconName.WithdrawArrow}></Icon>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-[.26rem] text-left font-[500] text-[var(--colour-41)] self-end">
                      {t('deposit-withdraw.converted_amout')}
                    </div>
                    <div>
                      <div className={`text-right text-[var(--colour-41)] font-[500] text-[.26rem]`}>
                        {t('deposit-withdraw.deposit_rate')}:
                      </div>
                      <div className="text-[.26rem] flex items-center justify-end">
                        <CountDowns />
                        <span
                          className={`w-[.36rem] h-[.36rem] text-ref font-[500] ${refresh == true && 'runHi'} ml-[.06rem] text-[var(--colour-1)] cursor-pointer`}
                          onClick={() => {
                            if (refresh) {
                              return;
                            }
                            setRefrensh(true);
                            getExchangeRateData();
                            setTimeout(() => {
                              setRefrensh(false);
                            }, 1500);
                          }}
                        >
                          <Icon name={IconName.Refresh} />
                        </span>

                        <span className="ml-[.04rem] text-left text-[var(--colour-41)] font-[500]">
                          1 {cryptoList[channel].currency} ≈ {initConfig.currency_symbol || ''}{' '}
                          {pointToComma(getExchangeRate())}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[.2rem] text-[var(--colour-38)]">
                    <Input
                      disabled
                      leftOuter={
                        <div className="flex items-center">
                          <div className="text-[.32rem] opacity-50 font-[700] block mr-[.2rem] ml-[.28rem]">
                            {initConfig.currency_symbol || ''}
                          </div>
                        </div>
                      }
                      rightInner={withBonus && bonus && Number(getExchangeRate()) != 0 && RightMessageQukuai()}
                      type="text"
                      value={pointToComma(GetFeeForCry1())}
                      inputClassName="opacity-50"
                      placeholder={t('deposit-withdraw.enter_amount')}
                      innerClassName="!h-[.92rem] !border-transparent"
                      topClassName=" rounded-normal  overflow-hidden bg-[var(--colour-34)] border border-[var(--colour-6)]"
                    />
                  </div>
                </div>
              )}

              {/* 确认金额 */}
              {depositType != 2 && (
                <div className="mt-[.32rem]">
                  <div className="text-[var(--colour-38)]">
                    <Input
                      leftOuter={
                        <div className="flex items-center">
                          <div className="text-[.32rem] font-[700] block m-[0_.25rem_0_.32rem]">
                            {initConfig.currency_symbol || ''}
                          </div>
                        </div>
                      }
                      rightInner={withBonus && bonus && RightMessageCor()}
                      type="number"
                      onChange={(val) => {
                        let newVal = '';
                        if (initConfig.currency_code == 'COP') {
                          newVal = putPatton(val.target.value);
                        } else {
                          newVal = putChangeText(val.target.value);
                        }
                        setChooseAmount(newVal);
                      }}
                      value={chooseAmount}
                      placeholder={t('deposit-withdraw.max_amount_enter', {
                        min: pointToComma(getShowMoneyNumber(fiatList[channel] ? fiatList[channel].deposit_min : 0)),
                        max: pointToComma(getShowMoneyNumber(fiatList[channel] ? fiatList[channel].deposit_max : 0)),
                      })}
                      innerClassName="!h-[.7rem] !pl-0 !border-transparent"
                      topClassName="rounded-normal overflow-hidden bg-[var(--colour-34)] border border-[var(--colour-6)]"
                    />
                  </div>
                </div>
              )}

              {withBonus && bonus && (
                <div
                  className={`w-[100%] border border-[var(--colour-1)] bg-[var(--colour-34)] mt-[.16rem] h-[.7rem] leading-[.7rem] text-[.28rem] flex justify-between px-[.32rem] text-[var(--colour-38)] rounded-normal`}
                >
                  <div className="text-[.28rem] font-[500]">{result?.bonus?.participate_type && getTextForBonus()}</div>
                  <div className="text-[.28rem] font-[500]">
                    {result?.bonus?.user_participate_num}/{result?.bonus?.participate_num}
                  </div>
                </div>
              )}

              {depositType != 2 &&
                depositList &&
                depositList.length > 0 &&
                depositList.map((item) => (
                  <div key={item.name}>
                    <div className="mt-[.16rem] text-[var(--colour-38)]">
                      <Input
                        leftOuter={
                          <div className="flex items-center">
                            <div className="text-[.32rem] font-[700] m-[0_.25rem_0_.32rem] flex items-center">
                              <div>{item.name}</div>
                              <div className="text-[var(--colour-25)] font-[400]">*</div>
                            </div>
                          </div>
                        }
                        onKeyDown={(event) => {
                          handleCustomInputKeyDown(event, item.name);
                        }}
                        type="text"
                        onChange={(event) => {
                          handleCustomInputChange(event, item.name, false);
                        }}
                        value={item.value}
                        placeholder={
                          item.name == 'cpf'
                            ? t('deposit-withdraw-wg.please_enter')
                            : t('deposit-withdraw.please_enter', { name: item.placeholder })
                        }
                        innerClassName="!h-[.7rem] !pl-0 !border-transparent"
                        topClassName="rounded-normal overflow-hidden bg-[var(--colour-34)] border border-[var(--colour-6)]"
                      />
                    </div>
                  </div>
                ))}

              {Number(limitAmount) > 0 && isShowConentList && limitRequiredContent && limitRequiredContent.length > 0
                ? limitRequiredContent.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="mt-[.16rem] text-[var(--colour-38)]">
                          <Input
                            leftOuter={
                              <div className="flex items-center">
                                <div className="text-[.32rem] font-[700] m-[0_.25rem_0_.32rem] flex items-center">
                                  <div>{item.key}</div>
                                  <div className="text-[var(--colour-25)] font-[400]">*</div>
                                </div>
                              </div>
                            }
                            type="text"
                            onKeyDown={(event) => {
                              handleCustomInputKeyDown(event, item.key);
                            }}
                            onChange={(event) => {
                              handleCustomInputChange(event, item.key, true);
                            }}
                            value={item.value}
                            placeholder={
                              item.key == 'cpf'
                                ? t('deposit-withdraw-wg.please_enter')
                                : t('deposit-withdraw.please_enter', { name: item.key })
                            }
                            innerClassName="!h-[.7rem] !pl-0 !border-transparent"
                            topClassName="rounded-normal overflow-hidden bg-[var(--colour-34)] border border-[var(--colour-6)]"
                          />
                        </div>
                      </div>
                    );
                  })
                : null}

              {initConfig.fb_btn && (
                <LimitClickWrapper
                  className="active:scale-[.95] transition-all select-none mt-[.3rem] flex flex-row px-[.34rem] h-[.92rem] items-center justify-center cursor-auto rounded-normal bg-[var(--colour-1)]"
                  onClick={() => {
                    CopyText(`${window.location.href}`);
                  }}
                >
                  <ElementWithLoading
                    normalEl={
                      <div className="font-[700] text-[.32rem] text-[var(--colour-2)]">Copy URL (FB Pixel)</div>
                    }
                  />
                </LimitClickWrapper>
              )}

              {depositType != 2 && (
                <LimitClickWrapper
                  className="active:scale-[.95] transition-all select-none mt-[.3rem] flex flex-row px-[.34rem] h-[.92rem] items-center justify-center cursor-auto rounded-normal bg-[var(--colour-1)]"
                  onClick={() => {
                    if (!requesting) {
                      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_DepositButton);

                      // wbClick('WB_Click_DepositButton');
                      DospoitClick();
                    }
                  }}
                >
                  <ElementWithLoading
                    isLoading={requesting}
                    normalEl={
                      <div className="font-[700] text-[.32rem] text-[var(--colour-2)]">{t('deposit-withdraw.pay')}</div>
                    }
                  />
                </LimitClickWrapper>
              )}

              {depositType != 2 && bonus ? (
                <div className="flex items-center mt-[.3rem] text-[.24rem] font-[400] text-primary-content">
                  <div
                    className={cn(
                      'active:scale-[.95] transition-all select-none w-[.48rem] h-[.48rem] flex items-center justify-center  border-transparent rounded-small cursor-pointer',
                      {
                        'bg-[var(--colour-1)]': withBonus,
                        'bg-[var(--colour-6)] border border-[var(--colour-42)]': !withBonus,
                      },
                    )}
                    onClick={() => setWithBonus(!withBonus)}
                  >
                    {withBonus ? (
                      <span className={`w-[.24rem] h-[.16rem] text-[var(--colour-2)]`}>
                        <Icon name={IconName.Checked2} />
                      </span>
                    ) : (
                      <div className="w-[.4rem] h-[.32rem]"></div>
                    )}
                  </div>

                  <div className={`text-[.24rem] ml-[.16rem] font-[500] text-[var(--colour-40)]`}>
                    {t('deposit-withdraw.with_bonus')}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* network */}
              {depositType == 2 && cryptoList && cryptoList.length > 0 && (
                <div>
                  <div className="text-[.26rem] mt-[.32rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.network')}
                  </div>
                  <div className="relative mb-[.6rem">
                    <div className="flex gap-[.1rem] flex-wrap mt-[.2rem]">
                      {cryptoList[channel] &&
                      cryptoList[channel].networks &&
                      cryptoList[channel].networks.length > 0 ? (
                        cryptoList[channel].networks.map((item, index) => (
                          <div key={index}>
                            <div
                              key={item.id}
                              onClick={() => {
                                // wbClick(
                                //   'WB_Click_PayOption_' + item.provider + '_' + item.network + '_' + item.currency,
                                // );

                                AnalyticsManager.FirebaseLogEvent(
                                  FirebaseEvents.WB_Click_PayOption_ +
                                    `${item.provider}` +
                                    '_' +
                                    `${item.network}` +
                                    '_' +
                                    `${item.currency}`,
                                );

                                setNetWork(item);
                              }}
                              className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center w-[2.1rem] h-[.8rem] rounded-normal ${
                                network.network == item.network
                                  ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                                  : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                              } `}
                            >
                              <span className={`font-[700] text-opacity-20 text-[.26rem]`}>{item.deposit_name}</span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {/* address 二维码 */}
                  <div className="flex mt-[.32rem] justify-between items-center">
                    <div className="text-[.24rem] flex-1 font-[500] text-left text-[var(--colour-44)]">
                      {t('deposit-withdraw.netWork_msg', { network: network.network })}
                    </div>
                    <div
                      className="w-[2rem] h-[2rem] flex items-center justify-center"
                      style={{ background: 'white', borderRadius: '.1rem', padding: '.12rem', marginLeft: '.2rem' }}
                    >
                      <QRCode value={textAddress()} remSize={1.8} />
                    </div>
                  </div>

                  {/* address  */}
                  <div className="text-[.26rem] mt-[-.2rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.address')}
                  </div>
                  <div
                    onClick={() => {
                      // copy(`${textAddress()}`);
                      // toast.success(t('home.toast006'), {
                      //   containerId: 'global',
                      // });
                      handleCopyWithErrorReport(textAddress());
                    }}
                    className="flex justify-between mt-[.16rem] px-[.3rem] items-center overflow-hidden bg-[var(--colour-34)] rounded-normal h-[.92rem] cursor-pointer"
                  >
                    <div className="flex items-center justify-between " style={{ width: '100%' }}>
                      <div
                        className="text-[.24rem] underline font-[700] block textCal text-[var(--colour-38)]"
                        style={{
                          wordWrap: 'break-word',
                          textAlign: 'left',
                        }}
                      >
                        {changeText1(textAddress())}
                      </div>
                      <div className=" text-[.24rem]">
                        <span className="w-[.44rem] h-[.44rem] block text-[var(--colour-44)]">
                          <Icon name={IconName.CopyZ} />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="text-[.24rem] text-left mt-[.08rem] text-[var(--colour-1)]">
                    {t('deposit-withdraw.depostMsgShow', {
                      num: getShowMoneyNumber(network.deposit_min, 5),
                      symbol: network.currency,
                    })}
                  </div>
                </div>
              )}

              {depositTips && depositTips.title ? (
                <div className="text-left mt-[.2rem] text-secondary-content">
                  <div className="font-[700] text-[var(--colour-49)]">{t('deposit-withdraw.deposit_notes')}</div>
                  <div
                    className="font-[500] text-[.24rem]"
                    dangerouslySetInnerHTML={{ __html: depositTips.title || '' }}
                  ></div>
                </div>
              ) : (
                <></>
              )}
              <div ref={endRef} />
            </div>
          </div>
        )
      )}

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 10004,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: '50',
          },
        }}
        isOpen={isOpen}
        handleClose={() => {
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          setIsOpen(false);
        }}
      >
        <Recharge
          handleClose={() => {
            setIsOpen(false);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          }}
          rechargeUrl={rechargeUrl}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 10004,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: '50',
          },
        }}
        isOpen={isOpenQrcode}
        handleClose={() => {
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          setIsOpenQrcode(false);
        }}
      >
        <DepositQR
          handleClose={() => {
            setIsOpenQrcode(false);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_PaymentPage_CloseButton);
          }}
          amount={commaToPoint(chooseAmount)}
          qcURL={qrcodeURL}
          network={fiatList[channel]?.network || ''}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 10004,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: '50',
          },
        }}
        isOpen={confirmed}
        handleClose={() => {
          setConfirmed(false);
        }}
      >
        <RechargeTrue
          depositTips={depositTips}
          amount={commaToPoint(chooseAmount)}
          rechargeUrl={rechargeUrl}
          bonusConfig={bonusConfig}
          withBonus={withBonus}
          bonus={bonus}
          t={t}
          initConfig={initConfig}
          handleClose={() => setConfirmed(false)}
        />
      </DialogModal>

      <ConfirmTips type="deposit" />
    </>
  );
});

export default Deposit;
// 区块充值
// 充值确定弹窗
const RechargeTrue = ({
  handleClose,
  depositTips,
  rechargeUrl,
  t,
  amount,
  initConfig,
  withBonus,
  bonus,
  bonusConfig,
}) => {
  return (
    <>
      <div className="w-[7rem] rounded-normal  bg-[var(--colour-34)] p-[.04rem_.32rem_.32rem_.32rem]">
        <div className={`flex flex-row justify-between items-center m-[.3rem_.3rem_.2rem_0]`}>
          <div className="text-[.32rem]  font-[700]  text-[var(--colour-36)]">
            {t('deposit-withdraw.deposit_amount')}
          </div>
          <div
            style={{ color: '#fff' }}
            className="w-[.72rem] h-[.72rem] flex justify-center items-center rounded-small"
          >
            <Close onClick={handleClose} />
          </div>
        </div>
        <div className="w-full h-[1px] mt-[.26rem] bg-[var(--colour-6)]"></div>
        <div className="flex items-center flex-col mt-[.4rem]">
          <div className="text-[.36rem] font-[700] text-[var(--colour-38)]">
            {initConfig.currency_symbol || ''} {amount}
          </div>
          {withBonus && bonus && Number(amount) >= Number(getShowMoneyNumber(bonusConfig.deposit_min)) && (
            <div
              className={cn('h-[.32rem] font-[600] text-[.22rem]  w-[100%] text-[var(--colour-44)] text-center', {})}
            >
              {Number(amount) >= Number(getShowMoneyNumber(bonusConfig.deposit_min)) ? (
                <>
                  (+ {initConfig.currency_symbol || ''} {}
                  {(Number(amount) * bonusConfig.bonus_ratio).toFixed(0) <
                  Number(getShowMoneyNumber(bonusConfig.bonus_max))
                    ? Number(cutDecimal(Number(amount) * bonusConfig.bonus_ratio))
                    : Number(cutDecimal(Number(getShowMoneyNumber(bonusConfig.bonus_max))))}
                  )
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center justify-between gap-15">
          <LimitClickWrapper
            className="mt-[.38rem] flex-1 mb-[.4rem] active:scale-[.95]  transition-all select-none flex px-[.5rem] h-[.92rem] items-center justify-center cursor-auto rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)]"
            onClick={handleClose}
          >
            <ElementWithLoading
              normalEl={
                <div className="font-[700] justify-center text-[.28rem] flex items-center h-[.92rem]">
                  {t('account.cancel')}
                </div>
              }
            />
          </LimitClickWrapper>
          <LimitClickWrapper
            className="mt-[.38rem] flex-1 mb-[.4rem] active:scale-[.95]  transition-all select-none flex px-[.5rem] h-[.92rem] items-center justify-center cursor-auto rounded-normal border border-1 border-[var(--colour-1)] text-center bg-[var(--colour-5)] text-[var(--colour-3)]"
            onClick={() => {
              window.open(rechargeUrl);
            }}
          >
            <ElementWithLoading
              normalEl={
                <div className="font-[700] justify-center text-[.28rem] flex items-center h-[.92rem] text-center w-full">
                  {t('account.confirm')}
                </div>
              }
            />
          </LimitClickWrapper>
        </div>

        {depositTips && depositTips.title ? (
          <div className="text-left text-secondary-content">
            <div className="font-[700] text-[var(--colour-36)]">{t('deposit-withdraw.deposit_notes')}</div>
            <div
              className="font-[500] text-[.24rem]"
              dangerouslySetInnerHTML={{ __html: depositTips.title || '' }}
            ></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

// 修改内容

const Recharge = ({ handleClose, rechargeUrl }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
  }, []);

  const onIframeLoad = () => {
    setLoading(false);
  };
  return (
    <div className="w-[7.4rem] min-h-[95vh] bg-[var(--colour-35)] pb-[.32rem]">
      <div className={`flex flex-row justify-between items-center mt-[.3rem] mb-[.2rem] mr-[.3rem]`}>
        <div className="text-[.36rem] font-[600]"></div>
        <div
          style={{ color: '#fff' }}
          className="w-[.72rem] h-[.72rem] flex justify-center items-center rounded-small bg-[var(--colour-45)]"
        >
          <Close onClick={handleClose} />
        </div>
      </div>
      {loading ? (
        <div className="w-full relative pt-[4rem]">
          <Loading2 />
        </div>
      ) : null}
      <iframe
        className={`w-full h-[83vh] bg-[#fff]  ${loading ? 'hidden' : 'block'}`}
        src={rechargeUrl}
        onLoad={onIframeLoad}
        frameBorder="0"
        scrolling="yes"
        allow="accelerometer; autoplay; camera; gyroscope; payment"
      ></iframe>
    </div>
  );
};

// 改变金额
const DeposintAmount = (item, initConfig) => {
  if (initConfig.currency_code == 'COP') {
    if (item >= 1000 && item < 1000000) {
      return (
        <>
          <span>{String(Number(item) / 1000)}</span>
          <span className="text-[.24rem]">Mil</span>
        </>
      );
    } else if (item >= 1000000) {
      return (
        <>
          <span>{String(Number(item) / 1000000)}</span>
          <span className="text-[.24rem]">Millón</span>
        </>
      );
    }
  }
  return item;
};

// 选择1还是选择2 1正常充值 2区块链充值
const findRewardChange = (rewardList, rechargeAmount, type = 1, rate) => {
  let closestReward = null;
  let closestDifference = Infinity;
  rechargeAmount = rechargeAmount * rate;

  for (let reward of rewardList) {
    let condition = Number(getShowMoneyNumber(reward.reward_condition));
    if (rechargeAmount >= condition && rechargeAmount - condition < closestDifference) {
      closestDifference = rechargeAmount - condition;
      closestReward = reward;
    }
  }

  if (closestReward) {
    if (type == 1) {
      return getShowMoneyNumber(closestReward.reward_value);
    } else {
      return closestReward.reward_value;
    }
  } else {
    return null;
  }
};

// 获取对应的key
const findReward = (rewardList, rechargeAmount, type = 1) => {
  let closestReward = null;
  let closestDifference = Infinity;

  for (let reward of rewardList) {
    let condition = Number(getShowMoneyNumber(reward.reward_condition));
    if (rechargeAmount >= condition && rechargeAmount - condition < closestDifference) {
      closestDifference = rechargeAmount - condition;
      closestReward = reward;
    }
  }
  if (closestReward) {
    if (type == 1) {
      return getShowMoneyNumber(closestReward.reward_value);
    } else {
      return closestReward.reward_value;
    }
  } else {
    return null;
  }
};

const moneyCrypo = (amount, bonusConfig, rate, initConfig) => {
  if (bonusConfig.reward_type == 1) {
    const reward = findRewardChange(bonusConfig.rewards_list, amount, 1, rate, initConfig);
    if (reward) {
      return (
        <>
          +{initConfig.currency_symbol || ''} {pointToComma(reward)}
        </>
      );
    } else {
      return <></>;
    }
  } else if (bonusConfig.reward_type == 2) {
    const reward = findRewardChange(bonusConfig.rewards_list, amount, 2, rate);
    if (reward) {
      return <>+{cutDecimal(reward * 100)}%</>;
    } else {
      return <></>;
    }
  } else {
    if (Number(amount) >= Number(getShowMoneyNumber(bonusConfig.deposit_min, 5)) * (1 / Number(rate))) {
      return <>+{Number(Number(Number(bonusConfig.bonus_ratio) * 100).toFixed(2)) + '%'}</>;
    } else {
      return <></>;
    }
  }
};

const moneyListTextTop = (amount, bonusConfig) => {
  if (bonusConfig.reward_type == 1) {
    const reward = findReward(bonusConfig.rewards_list, amount);
    if (reward) {
      return <>+ {pointToComma(reward)}</>;
    } else {
      return <></>;
    }
  } else if (bonusConfig.reward_type == 2) {
    const reward = findReward(bonusConfig.rewards_list, amount, 2);
    if (reward) {
      return (
        <>
          +{' '}
          {pointToComma(
            cutDecimal(
              Number(amount) * reward > Number(getShowMoneyNumber(bonusConfig.bonus_max))
                ? Number(getShowMoneyNumber(bonusConfig.bonus_max))
                : Number(amount) * reward,
            ),
          )}
        </>
      );
    } else {
      return <></>;
    }
  } else {
    if (Number(amount) >= Number(getShowMoneyNumber(bonusConfig.deposit_min))) {
      return (
        <>
          +
          {pointToComma(
            (Number(amount) * bonusConfig.bonus_ratio).toFixed(0) < Number(getShowMoneyNumber(bonusConfig.bonus_max))
              ? Number(cutDecimal(Number(amount) * bonusConfig.bonus_ratio))
              : Number(cutDecimal(Number(getShowMoneyNumber(bonusConfig.bonus_max)))),
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
};

const MoneyList = ({
  result,
  setChooseAmount,
  chooseAmount,
  withBonus,
  bonus,
  bonusConfig,
  channel,
  t,
  initConfig,
}) => {
  if (result && result.length > 0) {
    try {
      const array = JSON.parse(result[channel].deposit_amount_list);
      if (array && array.length <= 0) {
        return <></>;
      } else {
        return (
          <div>
            <div className="text-[.26rem] mt-[.4rem] text-left font-[500] text-[var(--colour-41)]">
              {t('deposit-withdraw.select_moeny')}
            </div>
            <div className="flex gap-[.1rem] flex-wrap mt-[.2rem]">
              {/* 确认金额 */}

              {array.length > 0 &&
                array.map((item, index) => (
                  <div
                    onClick={() => {
                      setChooseAmount(item);
                    }}
                    key={index}
                    className={cn(
                      `active:scale-[.95] transition-all select-none flex-col cursor-pointer items-center w-[2.1rem] h-[1.12rem] rounded-normal overflow-hidden  ${'item_width'}`,
                      {
                        'bg-[var(--colour-1)] text-[var(--colour-2)]':
                          (chooseAmount == item && withBonus) || (chooseAmount == item && !withBonus),
                        'bg-[var(--colour-34)] text-[var(--colour-10)]': chooseAmount != item,
                      },
                    )}
                  >
                    {withBonus && bonus && (
                      <div
                        className={cn(
                          'h-[.32rem] text-[.24rem] w-[100%] bg-[var(--colour-61)] text-[var(--colour-60)] font-[500]',
                        )}
                      >
                        {moneyListTextTop(item, bonusConfig)}
                      </div>
                    )}

                    <div
                      className={cn(`w-[100%] font-[700] text-[.36rem]`, {
                        'h-[1.12rem] leading-[1.12rem]': !withBonus,
                        'h-[.8rem] leading-[.8rem]': withBonus,
                        '!text-[.28rem]': initConfig.currency_code == 'COP',
                      })}
                    >
                      <span className="font-[500]">{initConfig.currency_symbol || ''}</span>
                      {pointToComma(DeposintAmount(item, initConfig))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      }
    } catch {
      return <></>;
    }
  } else {
    return <></>;
  }
};

const MoneyListCrypo = ({
  result,
  rate,
  setChooseAmount,
  chooseAmount,
  withBonus,
  bonus,
  bonusConfig,
  channel,
  t,
  initConfig,
}) => {
  if (result) {
    try {
      const array = JSON.parse(result.deposit_amount_list);
      if (array && array.length <= 0) {
        return <></>;
      } else {
        return (
          <div>
            <div className="text-[.26rem] mt-[.4rem] text-left font-[500] text-[var(--colour-41)]">
              {t('deposit-withdraw.select_moeny')}
            </div>
            <div className="flex gap-[.1rem] flex-wrap mt-[.2rem]">
              {/* 确认金额 */}

              {array.length > 0 &&
                array.map((item, index) => (
                  <div
                    onClick={() => {
                      setChooseAmount(item);
                    }}
                    key={index}
                    className={cn(
                      `active:scale-[.95] transition-all select-none flex-col cursor-pointer items-center w-[2.1rem] h-[1.12rem] rounded-normal overflow-hidden ${'item_width'}`,
                      {
                        'bg-[var(--colour-1)] text-[var(--colour-2)]':
                          (chooseAmount == item && withBonus) || (chooseAmount == item && !withBonus),
                        'bg-[var(--colour-34)] text-[var(--colour-10)]': chooseAmount != item,
                      },
                    )}
                  >
                    {withBonus && bonus && (
                      <div
                        className={cn(
                          'h-[.32rem] text-[.24rem] w-[100%] font-[500] bg-[var(--colour-61)] text-[var(--colour-60)]',
                          //    {
                          //   'w-[70%] ml-[30%] bg-award-bg text-right pr-10': initConfig.gradient.includes(
                          //     initConfig.theme,
                          //   ),
                        )}
                      >
                        {moneyCrypo(item, bonusConfig, rate, initConfig)}
                      </div>
                    )}

                    <div
                      className={cn(`w-[100%] font-[700] text-[.36rem]`, {
                        'h-[1.12rem] leading-[1.12rem]': !withBonus,
                        'h-[.8rem] leading-[.8rem]': withBonus,
                      })}
                    >
                      {pointToComma(item)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      }
    } catch {
      return <></>;
    }
  } else {
    return <></>;
  }
};
