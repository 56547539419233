import React, { FC, useState, useEffect } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Close from '../close';
import { setLoginInfo, setUserObj } from '../../redux/reducers/userSlice';

import { ModifyUserReq } from '../../api/index';
import { getTemplateId, getThemeUserPhoto, getUserPhoto, isControllableSite } from '../../utils/helper';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';
import Input from '../input';
import { toast } from 'react-toastify';

import cn from 'classnames';
import Icon, { IconName } from '../icon';

const FormHeader = ({ title, handleClose, className = '', showType }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>
        <div className="w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
          <Close
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      </div>

      <div className={cn('w-full h-[.02rem] mt-[.28rem] bg-[var(--colour-6)]')}></div>
    </>
  );
};

const UserInfoDialog = ({ handleClose, isShowNickname = true }) => {
  const dispatch = useDispatch();
  const { userObj } = useSelector(
    (state) => ({
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );
  const { t } = useTranslation();

  const [requesting, setRequesting] = useState(false);

  const [formData, setFormData] = useState({
    nickname: userObj?.nickname || '',
    icon: userObj?.icon || '',
  });

  let userPhotos = [];
  for (let i = 1; i < 13; i++) {
    userPhotos.push(i);
  }

  const handleChangeUserPhoto = () => {
    setRequesting(true);
    ModifyUserReq({
      ...formData,
    })
      .then((res) => {
        if (res.code == 0) {
          toast.success(t('home.toast004'), {
            containerId: 'global',
          });
          dispatch(setUserObj(res.result.userObj));
          handleClose();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleNicknameChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/[^0-9a-zA-Z]/g, '').slice(0, 24);
    setFormData({ ...formData, nickname: v2 });
  };

  return (
    <div className="my-[.3rem] flex flex-col justify-between text-center relative px-[.48rem] pb-[.4rem] w-[7.5rem] h-[auto] rounded-normal bg-[var(--colour-35)]">
      <div className="">
        <div className="px-[.32rem]">
          <FormHeader title={t('account.change_username')} handleClose={handleClose} />
        </div>

        {/* 列表 */}
        <div className="px-[.32rem] text-left text-[.32rem]">
          {isShowNickname ? (
            <>
              <div className="text-[.24rem] my-[.16rem] secondary-title font-[500] text-left">
                {t('account.username')}
              </div>
              <div className="mt-[.2rem]">
                <Input
                  leftInner={
                    <div className="flex items-center">
                      <span className="block mr-[.2rem] w-[.3rem] !h-[.3rem]">
                        <Icon name={IconName.ChangeName} />
                      </span>
                    </div>
                  }
                  type="text"
                  value={formData.nickname}
                  onChange={handleNicknameChange}
                  placeholder={t('account.enter_nickname')}
                  innerClassName="!h-[.96rem] text-[.28rem] !border-transparent"
                  topClassName="border-none overflow-hidden rounded-normal bg-[var(--colour-51)]"
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="text-[.24rem] my-[.16rem] mt-[.3rem] text-[var(--colour-14)] font-[500] text-left">
            {t('account.avatar')}
          </div>
          <div className="flex  flex-wrap justify-between mt-[.3rem] mb-[-0.4rem] ">
            {userPhotos.map((item) => (
              <div
                key={item}
                onClick={() => {
                  setFormData({ ...formData, icon: item });
                }}
                className={cn(
                  'mb-[.4rem] cursor-pointer w-[1.4rem] h-[1.4rem] active:scale-[.95] transition-all select-none',
                  {
                    'user-photo-choose after:w-[1.4rem] after:h-[1.4rem]': formData.icon == item,
                    '': formData.icon != item,
                  },
                )}
              >
                {getTemplateId() == 'd-template003' ? (
                  <div
                    className={cn('border-[2px] rounded-[.12rem]', {
                      'border-[var(--colour-1)]': formData.icon == item,
                      'border-[transparent]': formData.icon != item,
                    })}
                  >
                    <img className="not-drag rounded-[.12rem]" src={getThemeUserPhoto(item)} alt="" />
                  </div>
                ) : (
                  <div
                    className={cn({
                      'rounded-[50%] border-2 border-solid border-[var(--colour-1)]': formData.icon == item,
                    })}
                  >
                    <img src={getUserPhoto(item)} alt="" />
                  </div>
                )}
                {formData.icon == item ? (
                  <>
                    <div className="absolute z-[100] right-[0rem] bottom-[0rem] w-[.48rem] h-[.48rem] rounded-[50%] overflow-hidden flex justify-center items-center bg-[var(--colour-1)]">
                      <div className="w-[.32rem] h-[.32rem] text-[var(--colour-2)]">
                        <Icon name={IconName.Checked2}></Icon>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>

          <LimitClickWrapper
            className={`active:scale-[.95] cursor-pointer transition-all select-none mt-[.4rem] flex flex-row px-[.34rem] h-[.92rem] items-center  justify-center rounded-normal text-[var(--colour-2)] ${getTemplateId() == 'd-template003' ? 'colour-79' : 'bg-[var(--colour-1)]'}`}
            onClick={() => {
              if (!requesting) {
                handleChangeUserPhoto();
              }
            }}
          >
            <ElementWithLoading
              isLoading={requesting}
              normalEl={
                <>
                  <div className="text-[.32rem] font-[700]">{t('account.save')}</div>
                </>
              }
            />
          </LimitClickWrapper>
        </div>
      </div>
    </div>
  );
};
export default UserInfoDialog;
