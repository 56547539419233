import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ActivityShowOrder } from '../api';
import useWelcomeBonus from './useWelcomeBonus';
import { showFissionMainPage } from '../utils/helper';
import { useState } from 'react';

const useInitActivity = () => {
  const dispatch = useDispatch();
  const GetActivityShowOrder = async () => {
    return await ActivityShowOrder();
    //"activity_info": {}, //显示该类型的数据
    //"show": 6 //1:task活动，2:救济金Relief money，3:锦标赛，4:轮盘抽奖，5:红包，6:新人活动，7:兑换码，8:裂变
  };

  const ShowFissionMainPage = (from, checkLogin = false) => {
    showFissionMainPage(from, checkLogin);
  };

  return {
    GetActivityShowOrder,
    ShowFissionMainPage,
  };
};

export default useInitActivity;
