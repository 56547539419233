import Countdown from 'react-countdown';

const CountdownBox = ({ date, onComplete, renderer, zeroPadTime = 2, zeroPadDays = 0 }) => {
  return (
    <Countdown
      date={date}
      onComplete={onComplete}
      renderer={renderer}
      zeroPadTime={zeroPadTime}
      zeroPadDays={zeroPadDays}
    />
  );
};

export default CountdownBox;
