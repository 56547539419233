import { combineReducers } from 'redux';
import user from './userSlice';
import app from './appSlice';
import ws from './wsSlice';
import chat from './chatSlice';

export default combineReducers({
  user,
  app,
  ws,
  chat,
});
