import DownloadManager from '../manager/downloadManager';
import CryptojsManager from '../manager/cryptojsManager';
import isMobile from 'ismobilejs';

/**是否是手机端 */
export function isAnyMobile() {
  return isMobile().any;
}

/**是否是iphone */
export function isIPhone() {
  return isMobile().apple.device;
}

/**
 * 是否手机safari
 * @returns
 */
export function isMobileSafari() {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.indexOf('applewebkit') > -1 &&
    userAgent.indexOf('mobile') > -1 &&
    userAgent.indexOf('safari') > -1 &&
    -1 === userAgent.indexOf('linux') &&
    -1 === userAgent.indexOf('android') &&
    -1 === userAgent.indexOf('chrome') &&
    -1 === userAgent.indexOf('ios') &&
    -1 === userAgent.indexOf('browser')
  );
}

// 判断是否在 WebView 中
export function isInWebView() {
  return navigator.userAgent && navigator.userAgent.includes('wv');
}

// 是否自己的android apk
export function isAndroidNative() {
  return navigator.userAgent && navigator.userAgent.indexOf('Android_Native') != -1;
}

// 是否自己的android pwa渠道apk
export function isAndroidChannelNative() {
  return (
    navigator.userAgent &&
    navigator.userAgent.indexOf('Android_Native') != -1 &&
    navigator.userAgent.indexOf('Android_pwa') != -1
  );
}

// 是否是 pwa渠道包
export function isPwaChannelNative() {
  return navigator.userAgent && navigator.userAgent.indexOf('Android_pwa') != -1;
}

// 是否android apk
export function isAndroidAPK() {
  //第一个是正常的安卓包，第二个是咱们自己的官方包。第三第四是打点的安卓马甲包。
  return isInWebView() || isAndroidNative() || window.android || window.Android;
}

// 是否facebook浏览器
export function isFacebookApp() {
  return navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV');
}

// 是否pwa
export function isPWA() {
  return (
    window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone
    // || document.referrer.includes('android-app://')
  );
}

//是否自有android原生壳或者PWA
export function isAndroidOrPWA() {
  return isAndroidAPK() || isPWA();
}

//是否是原生native
export function isNative() {
  return isAndroidAPK() || isPWA() || DownloadManager.isIOSNative;
}

// 是否本地
export function isLocalHost() {
  return location.host.indexOf('localhost') != -1 || location.host.indexOf('127.0.0.1') != -1;
}

// 获取标识
export function getAppTag() {
  //pwa,apk,vest_bag
  if (isPWA()) {
    return 'pwa';
  } else if (isInWebView() || isAndroidNative()) {
    return 'apk';
  } else if (window.android || window.Android || DownloadManager.isIOSNative) {
    return 'vest_bag';
  } else {
    return '';
  }
}

//获取设备信息，isAndroidNative，判断官方包的逻辑要在马甲包之前。
export function getDeviceInfo(isStr = true) {
  const obj = {
    userAgent: navigator.userAgent, //字符串
    androidVestBag: window.android || window.Android ? 1 : 0,
    isPWA: isPWA() ? 1 : 0,
  };
  return isStr ? JSON.stringify(obj) : obj;
}

export function getCryptoDeviceInfo(secretKey) {
  return CryptojsManager.AES(getDeviceInfo(), secretKey);
}
