// import React, { FC, useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import Close from '../close';
// import Button from '../button';
// import Input from '../input';
import LimitClickWrapper from '../limitClickWrapper';
// import Icon, { IconName } from '../icon';
// import { toast } from 'react-toastify';
// import { setDialogModalVisible, DialogModalChildrenKey } from '../../redux/reducers/appSlice';
// import { setLoginInfo, setUserObj } from '../../redux/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const Confirm = ({
  handleClose,
  handleCancel,
  handleConfirm,
  children,
  disableClose,
  title = 'Tips',
  cancelText = 'Cancel',
  confirmText = 'OK',
  isConfirmBtnPrimary = true, //确定按钮是否首要按钮
}) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-[var(--colour-35)] text-center relative overflow-hidden w-[6.86rem] rounded-normal">
        <div className="h-[100%] p-[.32rem] pb-[.6rem] ">
          <div className="flex flex-row justify-between items-center">
            <div className={cn('font-semibold text-[var(--colour-48)] text-[.4rem]')}>{title}</div>
            {disableClose ? <></> : <Close onClick={handleClose} className="bg-[var(--colour-45)] rounded-normal" />}
          </div>
          <div className="w-full bg-[var(--colour-6)] h-[1px] mt-[.32rem] mb-[.6rem]"></div>
          <div className="text-[var(--colour-40)]">{children}</div>
          <div className="flex flex-row justify-center text-[var(--colour-2)]">
            {handleCancel ? (
              <LimitClickWrapper
                className={`flex flex-row items-center cursor-pointer justify-center w-[2.88rem] mt-[.64rem]  h-[.88rem] mx-[.1rem] active:scale-[.95] transition-all select-none rounded-normal border border-1 border-[var(--colour-1)] ${
                  isConfirmBtnPrimary
                    ? 'bg-[var(--colour-5)] text-[var(--colour-3)]'
                    : 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                }`}
                onClick={handleCancel}
              >
                <div className="font-[700] text-[.32rem]">{cancelText}</div>
              </LimitClickWrapper>
            ) : (
              <></>
            )}

            {handleConfirm ? (
              <LimitClickWrapper
                className={`flex flex-row w-[2.88rem] mt-[.64rem] h-[.88rem] mx-[.1rem]  items-center text-[var(--colour-2)] cursor-pointer justify-center active:scale-[.95] transition-all select-none rounded-normal  border border-1 border-[var(--colour-1)] ${
                  isConfirmBtnPrimary
                    ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                    : 'bg-[var(--colour-5)] text-[var(--colour-3)]'
                }`}
                onClick={handleConfirm}
              >
                <div className="font-[700] text-[.32rem]">{confirmText}</div>
              </LimitClickWrapper>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
