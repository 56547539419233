import { get, cloneDeep } from 'lodash';
import config from '../constants/config';
import store from '../redux/store';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { UserinfoReq } from '../api';
import { setUserObj } from '../redux/reducers/userSlice';
import { langsConfig } from '../constants/langs';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  FissionDialogShowFrom,
  ResponsiveMode,
  resetActivityData,
  setShowWGDeposit,
  setShowHeaderGiftMoney,
  setNeedUpdateGiftMoney,
  setActivitySwiperSlideKey,
} from '../redux/reducers/appSlice';
import { toLocalePath } from './localePath';
import { openUrl } from './openUrl';
import Icon, { IconName } from '../components/icon';
import { toast } from 'react-toastify';
import i18n, { t } from 'i18next';
import { toNoLocalePath, getLangFromPath } from './localePath';
import DownloadManager from '../manager/downloadManager';
import { parentLocationReload } from '../manager/iframeManager';
import GSAPUtils from './gsapUtils';
import { isIPhone, isPWA } from './deviceUtils';
import copy from 'copy-to-clipboard';

export const env = import.meta.env.MODE;

const defaultMoneyShowPrecission = 2;

//是否是在pc上面显示手机端布局
export function isPCLayout() {
  return getRootCssVariable('--theme-max-width', true) != '100%';
}

/***是否是777bet.io这个站，用于牌照逻辑判断 */
export function is777BetIO() {
  return window.location.hostname == '777bet.io';
}

export function isInIframe() {
  return window.self !== window.top;
}

export function getParentUrl() {
  let url = null;
  if (parent !== window) {
    try {
      url = parent.location.origin;
    } catch (e) {
      url = document.referrer;
    }
  }
  return url;
}

export function getHostName() {
  if (window.location.origin.indexOf('https://m.') > -1) {
    return window.location.origin.replace('https://m.', '');
  } else {
    return location.hostname;
  }
}

export function getReferralLink(activeId = '') {
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  const userObj = get(store.getState(), 'user.userObj', null);
  const inviteCode = userObj ? userObj.invite_code : '';
  let shareLinkUrl = `${window.location.origin.replace('https://m.', 'https://')}`;
  const randomDomains = Array.from(websiteInitConfig?.website_info?.website_referral_random_domains || []);
  if (randomDomains && randomDomains.length > 0) {
    const randomIndex = Math.floor(Math.random() * randomDomains.length);
    shareLinkUrl = randomDomains[randomIndex];
  }
  const shareLinkParams = userObj
    ? `?id=${inviteCode}&currency=${websiteInitConfig?.website_info?.currency_code}&type=2`
    : `?currency=${websiteInitConfig?.website_info?.currency_code}&type=2`;
  let shareLink = `${shareLinkUrl}${shareLinkParams}`;
  if (activeId) {
    shareLink += `&a_id=${activeId}`;
  }
  return shareLink;
}

//keepDecimal 补齐小数点后面几位，0为不补齐
export function getShowMoney(val, decimalCount = defaultMoneyShowPrecission, keepDecimal = 0) {
  decimalCount = Number(decimalCount);
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig?.system_config?.currency_offset) {
    let result = BigNumber(val)
      .div(websiteInitConfig.system_config.currency_offset)
      .toFixed(18)
      .replace(/\.?0+$/, '');

    let value = cutDecimal(result, decimalCount, keepDecimal);
    return websiteInitConfig?.website_info?.currency_code == 'BRL' ? value.replace('.', ',') : value;
  } else {
    return '';
  }
}

//keepDecimal 补齐小数点后面几位，0为不补齐
export function getShowMoneyWithUnit(val, decimalCount = defaultMoneyShowPrecission, keepDecimal = 0, unit = 10000) {
  decimalCount = Number(decimalCount);
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig?.system_config?.currency_offset) {
    let result = BigNumber(val)
      .div(websiteInitConfig.system_config.currency_offset)
      .toFixed(18)
      .replace(/\.?0+$/, '');

    let value = cutDecimal(result, decimalCount, keepDecimal);
    value = BigNumber(value).div(unit).toString();
    return websiteInitConfig?.website_info?.currency_code == 'BRL' ? value.replace('.', ',') : value;
  } else {
    return '';
  }
}

export function getShowMoneyNumber(val, decimalCount = defaultMoneyShowPrecission, keepDecimal = 0) {
  decimalCount = Number(decimalCount);
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig?.system_config?.currency_offset) {
    let result = BigNumber(val)
      .div(websiteInitConfig.system_config.currency_offset)
      .toFixed(18)
      .replace(/\.?0+$/, '');

    return cutDecimal(result, decimalCount, keepDecimal);
  } else {
    return '';
  }
}

export function cutDecimal(val, decimalCount = 2, keepDecimal = 0) {
  decimalCount = Number(decimalCount);
  keepDecimal = Number(keepDecimal);
  const str = String(val);
  const index = str.indexOf('.');

  if (index == -1) {
    // 如果没有小数点，根据 keepDecimal 补零
    return keepDecimal == 0 ? str : Number(str).toFixed(keepDecimal);
  } else {
    if (decimalCount == 0) {
      // 如果 decimalCount 为 0，直接返回整数部分
      return str.slice(0, index);
    } else {
      // 截取小数部分，并根据需要补零
      const decimalPart = str.slice(index + 1);
      const truncatedDecimal = decimalPart.slice(0, decimalCount);
      const paddedDecimal = truncatedDecimal.padEnd(decimalCount, '0'); // 补零
      return str.slice(0, index + 1) + paddedDecimal;
    }
  }
}

export function getShowMoneyWithConfigDecimal(val) {
  const state = store.getState();
  const configDecimalPlace = state?.app?.websiteInitConfig?.system_config?.currency_decimal_point_precision;
  return getShowMoney(val, configDecimalPlace);
}

export const makeTabKeys = (tabNum = 0) => {
  return Array(tabNum)
    .fill()
    .map((_, index) => `tab-${index}`);
};

export function TextPlaces(val, num = 13) {
  let temp = val.toString();
  temp = temp.slice(0, num);

  return temp;
}

// 清理输入字符串，只保留数字和句点。
// 格式化字符串，确保只有一个句点，并且小数点后最多两位。
// 限制字符串的最大长度。
// 处理特殊情况，避免字符串以句点结尾。
// num: 可选参数，默认值为 13，表示最终返回字符串的最大长度。
// 用到的地方：wg钱包的充值，7b钱包充值，提现记录，
export function putChangeText(val, num = 13) {
  let temp = val.toString();
  // 将字符串中的所有中文句号 。 替换为英文句点 .
  temp = temp.replace(/。/g, '.');

  // 移除非数字和句点的字符
  temp = temp.replace(/[^\d.]/g, '');

  // 移除开头的句点
  temp = temp.replace(/^\./g, '');

  // 将连续的多个句点替换为单个句点
  temp = temp.replace(/\.{2,}/g, '');

  // 保留第一个句点，删除其余句点
  // 先将第一个句点替换为临时标记 $#$。 然后删除所有剩余的句点。 最后将临时标记 $#$ 恢复为句点。
  temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');

  // 限制小数点后最多两位
  // 使用正则表达式 ^(\-)*(\d+)\.(\d\d).*$ 匹配字符串：
  // (\-)*: 匹配可选的负号。
  // (\d+): 匹配小数点前的整数部分。
  // \.(\d\d): 匹配小数点后的两位数字。
  // .*: 匹配剩余部分。
  // 将匹配到的负号、整数部分和小数点后两位保留，其余部分删除。
  temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

  temp = temp.slice(0, num);

  // 当输入长度等于 num 且最后一个字符是句点时，则删除该句点。
  if (val.length == num) {
    let newVal = temp.substring(num - 1, num);
    if (newVal === '.') {
      temp = temp.slice(0, num - 1);
    }
  }

  return temp;
}

export function putShowMoney(val) {
  // const value = (Number(val) / config.moneyOffset);
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig?.system_config?.currency_offset) {
    let value = BigNumber(val)
      .times(websiteInitConfig?.system_config?.currency_offset)
      .toFixed(18)
      .replace(/\.?0+$/, '');
    value = Number(value).toFixed(4);
    value = parseFloat(value);
    return value;
  } else {
    return '';
  }

  //   return isFormat ? getFormatMoney(value, max_len) : value;
}

// export function getServerMoney(val) {
//   return Number(val) * config.moneyOffset;
// }

export function getRoundCeilFixed(value, keepNum = 2) {
  // num 小数点后保留keepNum位，如果keepNum位后面非0，keepNum位加1
  // 1.123 -> 1.12 + 0.01; 1.191 -> 1.19 + 0.01
  const moneyStr = String(value);
  //如果是小数
  if (moneyStr.indexOf('.') > -1) {
    const str1 = moneyStr.split('.')[0];
    const str2 = moneyStr.split('.')[1];

    if (str2.length <= keepNum) {
      return moneyStr;
    } else {
      // 保留位后面的数字
      const after = str2[keepNum];
      const notAdded = `${str1}.${str2.substring(0, keepNum)}`;
      if (after === '0') {
        return notAdded;
      } else {
        const zeros = Array(keepNum - 1)
          .fill()
          .map(() => 0)
          .join('');
        const added = `0.${zeros}1`;
        return String(BigNumber(notAdded).plus(added).toFixed());
      }
    }
  } else {
    //如果是整数
    return moneyStr;
  }
}

export function getFormatMoney(value, max_len = 9) {
  const moneyStr = String(value);
  // const max_len = 9; // 小数点左右加起来显示的最多位数
  //如果是小数
  if (moneyStr.indexOf('.') > -1) {
    const str1 = moneyStr.split('.')[0];
    const str2 = moneyStr.split('.')[1];
    if (str1.length >= max_len) {
      return str1;
    } else {
      if (str2.length > max_len - str1.length) {
        return `${str1}.${str2.substring(0, max_len - str1.length)}`;
      } else {
        let digits = [];
        for (let i = 0; i < max_len - str1.length - str2.length; i++) {
          digits.push('0');
        }
        return `${str1}.${str2}${digits.join('')}`;
      }
    }
  } else {
    //如果是整数
    if (moneyStr.length >= max_len) {
      return moneyStr;
    } else {
      let digits = [];
      for (let i = 0; i < max_len - moneyStr.length; i++) {
        digits.push('0');
      }
      return `${moneyStr}.${digits.join('')}`;
    }
  }
}

export function getUserPhoto(icon = '1') {
  return `/static/img/avatar/${icon}.png`;
}

export function getThemeUserPhoto(icon = '1', theme = 'theme18') {
  return `/static/img/avatar/${theme}/${icon}.png`;
}

export function getSupportPhoto(icon) {
  icon = icon || '1';
  // return `/static/img/userPhoto/${icon}.png`;
  return `/static/img/support/${icon}.png`;
}

export function getSelfGameIcon(gameId) {
  const list = [
    {
      gameId: '1001',
      name: 'CRASH',
      iconName: 'Crashgame',
    },
    {
      gameId: '1016',
      name: 'Plinko 3',
      iconName: 'Plinko_3game',
    },
    {
      gameId: '1015',
      name: 'CAVE',
      iconName: 'Cavegame',
    },
    {
      gameId: '1004',
      name: 'TOWER',
      iconName: 'Towergame',
    },
    {
      gameId: '1005',
      name: 'MINES',
      iconName: 'Minesgame',
    },
    {
      gameId: '1007',
      name: 'SIDES',
      iconName: 'Sidesgame',
    },
    {
      gameId: '1002',
      name: 'PLINKO',
      iconName: 'Plinkogame',
    },
    {
      gameId: '1003',
      name: 'DICE',
      iconName: 'Dicegame',
    },
    {
      gameId: '1006',
      name: 'WHEEL',
      iconName: 'Wheelgame',
    },
    {
      gameId: '1008',
      name: 'KENO',
      iconName: 'Kenogame',
    },
    {
      gameId: '1010',
      name: 'HILO',
      iconName: 'Hilogame',
    },
    {
      gameId: '1013',
      name: "HOLD'EM",
      iconName: 'Hold_emgame',
    },
    {
      gameId: '1012',
      name: 'BLACKJACK',
      iconName: 'Blackjackgame',
    },
    {
      gameId: '1011',
      name: 'BACCARAT',
      iconName: 'Baccaratgame',
    },
    {
      gameId: '1009',
      name: 'ROULETTE',
      iconName: 'Roulettegame',
    },
    {
      gameId: '1024',
      name: 'COINFILP',
      iconName: 'Coinflipgame',
    },
    {
      gameId: '1017',
      name: 'LIMBO',
      iconName: 'Limbogame',
    },
  ];

  const found = list.find((item) => item.gameId === String(gameId));

  return found ? found.iconName : '';
}

export function getMoneyIconName(currencyName) {
  // 根据接口返回的货币名生成货币图标
  // <span className='w-[20px] h-[20px] block'>
  //  <Icon name={getMoneyIconName(currency)} />
  // </span>
  // name: 接口返回的货币名， iconName: icon.jsx 里 IconName 里对应的key
  // 未找到返回空图标

  const list = [
    {
      name: 'BNB',
      iconName: 'BNB',
    },
    {
      name: 'TRX',
      iconName: 'Tron',
    },
    {
      name: 'ETH',
      iconName: 'Ethereum',
    },
    {
      name: 'BTC',
      iconName: 'Bitcoin',
    },
    {
      name: 'USDT',
      iconName: 'USDT',
    },

    {
      name: 'USDC',
      iconName: 'USDC',
    },
    {
      name: 'B9C',
      iconName: 'B9C',
    },
    {
      name: 'B9C_LOCK',
      iconName: 'B9C',
    },
    {
      name: 'B9F',
      iconName: 'B9F',
    },

    {
      name: 'USD',
      iconName: 'USD',
    },
    {
      name: 'EUR',
      iconName: 'EUR',
    },
    {
      name: 'BRL',
      iconName: 'BRL',
    },
    {
      name: 'KRW',
      iconName: 'KRW',
    },
    {
      name: 'JPY',
      iconName: 'JPY',
    },
    {
      name: 'PHP',
      iconName: 'PHP',
    },
    {
      name: 'VND',
      iconName: 'VND',
    },
  ];

  const found = list.find((item) => item.name === currencyName);

  return found ? found.iconName : '';
}

/**获取法币符号 */
export function getFiatSymbol(name) {
  switch (name) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'PHP':
      return '₱';
    case 'BRL':
      return 'R$';
    case 'COP':
      return '$';
    case 'INR':
      return '₨';
    case 'VND':
      return '₫';
    case 'RUB':
      return '₽';
    case 'PKR':
      return '₨';
    case 'JPY':
      return '¥';
    case 'AUD':
      return '$';
    case 'KPW':
      return '₩';
    case 'CAD':
      return '$';
    case 'KRW':
      return '₩';
    default:
      return '';
  }
}

/**获取法币icon */
export function getFiatIconName(currencyName) {
  // 根据接口返回的货币名生成货币图标
  // <span className='w-[20px] h-[20px] block'>
  //  <Icon name={getMoneyIconName(currency)} />
  // </span>
  // name: 接口返回的货币名， iconName: icon.jsx 里 IconName 里对应的key
  // 未找到返回空图标

  const list = [
    {
      name: 'USD',
      iconName: 'USD',
    },
    {
      name: 'EUR',
      iconName: 'EUR',
    },
    {
      name: 'JPY',
      iconName: 'USD',
    },
    {
      name: 'BRL',
      iconName: 'BRL',
    },
    {
      name: 'CAD',
      iconName: 'CAD',
    },
    {
      name: 'INR',
      iconName: 'INR',
    },
    {
      name: 'GBP',
      iconName: 'GBP',
    },
    {
      name: 'THB',
      iconName: 'THB',
    },
    {
      name: 'MXN',
      iconName: 'MXN',
    },
    {
      name: 'IDR',
      iconName: 'IDR',
    },
    {
      name: 'PHP',
      iconName: 'PHP',
    },
  ];

  const found = list.find((item) => item.name === currencyName);

  return found ? found.iconName : '';
}

export function UserNickname(nickname, num = 10) {
  if (nickname.length <= num) {
    return nickname;
  }
  let str = nickname.substring(0, num);
  return str + '...';
}

export function isLogin() {
  const userObj = get(store.getState(), 'user.userObj', null);
  // setVipLevelLogin(userObj.vip_level, userObj.uid || -1);
  return userObj !== null;
}

export function getIdentity() {
  const identity = get(store.getState(), 'user.identity', null);
  return identity;
}

export function getDateFormatStr(timestamp) {
  return moment(timestamp).format('MM/DD hh:mm');
}

export function getDateFormatStr2(timestamp) {
  // return moment(/^\d+$/.test(String(timestamp)) ? timestamp : new Date(timestamp)).format('YYYY-MM-DD HH:mm:ss');
  return moment(/^\d+$/.test(String(timestamp)) ? timestamp : new Date(timestamp)).format('MM-DD HH:mm:ss');
}

export function getDateFormatStr3(timestamp) {
  return moment(timestamp).format('MM/DD HH:mm');
}

export function getDateFormatStr4(timestamp) {
  return moment(/^\d+$/.test(String(timestamp)) ? timestamp : new Date(timestamp)).format('DD/MM/YYYY HH:mm:ss');
}
export function getDateFormatStr5(timestamp, format = 'YYYYMMDD') {
  return moment(/^\d+$/.test(String(timestamp)) ? timestamp : new Date(timestamp)).format(format);
}
//设置输入只现实数字
export function setInputNum(value, ispoint = false) {
  let value2 = value.replace(/[^\d]/g, '');

  return value2;
}

export function setInputValue(value) {
  let value2 = value.replace(/[^0-9a-zA-Z]/g, '');

  return value2;
}
export const getCurrentLang = () => {
  const currentLang = get(store.getState(), 'user.defaultLang');

  return currentLang;

  // if (langsConfig.supportLangList.find((item) => item.key === currentLang)) {
  //   return currentLang;
  // } else {
  //   return langsConfig.initLangKey;
  // }
};

// 获取inviteCode
export const getInviteCode = () => {
  const inviteCode = get(store.getState(), 'user.inviteCode', '');
  return inviteCode;
};

/**获取语言表信息 */
export const getLangMsg = (key) => {
  return i18n.isInitialized ? t(`${key}`) : key;
  // const currentLang = getCurrentLang();
  // const found = langsConfig.supportLangList.find((item) => item.key === currentLang);
  // return found && found.translation && found.translation[String(key)]
  //   ? found.translation[String(key)]
  //   : `not config language table with ${currentLang} and ${key}`;
};

/*是否允许全屏 */
export const isFullscreenEnabled = () => {
  return (
    document.fullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled ||
    false
  );
};

export function updateUserInfo() {
  UserinfoReq().then((res) => {
    if (res.code === 0) {
      store.dispatch(setUserObj(res.result.user_obj));
      // mySetUserId(res.result.user_obj.uid);
    } else {
      // mySetUserId('');
    }
  });
}

export function isEnvDevelopment() {
  return env.includes('development');
}

export function isEnvTest() {
  return env.includes('test');
}

export function isEnvProduction() {
  return env.includes('production');
}

export function cleanStrToNumber(str = '', integerLength = 8, decimalLength = 8) {
  let result = str.replace(/[^\d.]/g, '');

  if (result.startsWith('.')) {
    result = result.slice(1);
  }

  const charArr = result.split('');

  if (charArr.filter((char) => char === '.').length > 1) {
    result = charArr.reduce((acc, curr) => {
      if (curr !== '.') {
        return `${acc}${curr}`;
      } else {
        if (acc.includes('.')) {
          return acc;
        } else {
          return `${acc}${curr}`;
        }
      }
    }, '');
  }

  if (result.includes('.')) {
    const dotIndex = result.indexOf('.');
    result = `${result.substring(0, dotIndex).slice(0, integerLength)}.${result
      .substring(dotIndex + 1)
      .slice(0, decimalLength)}`;
  } else {
    result = result.slice(0, integerLength);
  }

  return result;
}

export function exchangeRateTransfer(amount, fromCurrency, toCurrency, exchangeList = []) {
  const found = exchangeList.find((item) => item.currency === fromCurrency && item.fiat === toCurrency);

  return found ? Number(found.rate) * Number(amount) : 0;
}

export function getLinkName(name) {
  let result = '';
  switch (name) {
    case 'BSC':
      result = 'BSC(BEP20)';
      break;
    case 'ERC':
      result = 'ETH(ERC20)';
      break;
    case 'TRC':
      result = 'TRON(TRC20)';
      break;
    default:
      result = name;
      break;
  }
  return result;
}

export function nameToUrlParam(name = '') {
  return name.trim().split(' ').join('-');
}

export function makeGamePath(name = '', provider = '') {
  return `/game/${name.trim().toLowerCase().split(' ').join('~')}-by-${provider
    .trim()
    .toLowerCase()
    .split(' ')
    .join('~')}`;
}

//保留字符串头部和尾部
export function omitStr(str, len = 15) {
  if (str.length <= len) {
    return str;
  }
  let res = str.slice(0, 4) + '...' + str.slice(-4);
  return res;
}

export const setVipLevelLogin = (vipLevel, uid = 0) => {
  //   console.log('保存shuju:', vipLevel, uid);
  localStorage.setItem('vipLevel_' + uid, vipLevel);
};
export const getVipLevelLogin = (uid = 0) => {
  return localStorage.getItem('vipLevel_' + uid) || 0;
};

export const getTitle = (str) => {
  let arr = str.split(' ');
  let _length = arr.length;
  for (let i = 0; i < _length; i++) {
    arr[i] = arr[i][0].toUpperCase() + arr[i].substring(1, arr[i].length).toLowerCase();
    str = arr.join(' ');
  }
  return str;
};

export function getMaxNumStr(num, max = 99) {
  if (Number(num) > max) {
    return '99+';
  } else {
    return String(num);
  }
}

export const getDateFomat = (time) => {
  if (time.split('-').length > 1) {
    return time;
  }
  var t = new Date();
  t.setTime(time * 1000);
  let year = t.getFullYear();
  let month = t.getMonth() + 1;
  let day = t.getDate();
  // 补0
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  return year + '-' + month + '-' + day;
};

/****打开裂变活动 */
export const showFissionMainPage = (from, checkLogin = false) => {
  if (checkLogin && !isLogin()) {
    store.dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
    return;
  }

  store.dispatch(
    setDialogModalVisible({
      show: DialogModalChildrenKey.Fission,
    }),
  );

  // ActivityShowOrder().then((res) => {
  //   if (res.code == 0) {
  //     const showType = Number(res.result.show);
  //     const result = res.result;
  //     if (showType == 8 && result.activity_info && result.activity_info.id > 0 && result.activity_info.status == 0) {
  //       store.dispatch(
  //         setFissionDialog({
  //           show: true,
  //           content: FissionDialog.step,
  //           from: FissionDialogShowFrom.init,
  //         }),
  //       );
  //     } else {
  //       store.dispatch(
  //         setFissionDialog({
  //           show: true,
  //           content: FissionDialog.mainPage,
  //           from: from,
  //         }),
  //       );
  //     }
  //   }
  // });
};

//删除url地址栏指定参数
export const removeUrlParameter = (url, parameter) => {
  // 创建 URL 对象
  const urlObj = new URL(url);
  // 获取 URL 的搜索参数
  const params = new URLSearchParams(urlObj.search);
  // 删除指定的参数
  params.delete(parameter);
  // 更新 URL 对象的搜索部分
  urlObj.search = params.toString();
  // 返回新的 URL
  return urlObj.toString();
};

/**
 * 根据url获取查询参数
 * @param  url   地址
 * @name   name  参数名
 * @returns  参数值
 */
export const getUrlQueryString = (url, name) => {
  const search = new URLSearchParams(new URL(url).search);
  return search.get(name);
};

export const cmsImgUrl = (imgPath) => {
  return config.webDevImg + imgPath;
};

export const onCmslinkClick = (link, navigateFn, activityStatus = null, activityData = null) => {
  if (link.startsWith('/')) {
    store.dispatch(resetActivityData());
    navigateFn(toLocalePath(link));
  } else if (link.startsWith('?')) {
    const params = new URLSearchParams(link);
    const obj = Object.fromEntries(params.entries());

    const showModal = () => {
      const { modal, ...other } = obj;
      const args = {
        show: DialogModalChildrenKey[obj.modal],
        ...other,
      };
      store.dispatch(setDialogModalVisible(args));
    };

    if (obj.modal) {
      if (obj.modal !== 'Redeem') {
        const modalName = String(obj.modal).toLowerCase();
        if (modalName == 'fission') {
          showFissionMainPage(FissionDialogShowFrom.banner, true);
        } else if (modalName == 'signup') {
          if (isLogin()) {
            return;
          }
          showModal();
        } else if (modalName == 'WithdrawDeposit'.toLowerCase()) {
          if (!isLogin()) {
            return store.dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.LoginBox,
              }),
            );
          }
          if (isControllableSite()) {
            if (obj.walletType == 2) {
              const { path } = getLangFromPath(window.location.pathname);
              localStorage.setItem('prev_path', path);
              navigateFn(toLocalePath(`/pay/withdrawtabpage/1`));
            } else {
              store.dispatch(setShowWGDeposit(true));
            }
          } else {
            showModal();
          }
        } else if (modalName == 'registerbonus') {
          const activityNovice = get(activityData, 'activity_novice');
          if (activityNovice && activityNovice.list.length > 0) {
            const activityNoviceData = activityNovice.list[0];
            let money = 0;
            if (activityNoviceData.activity_rewards) {
              activityNoviceData.activity_rewards.forEach((item) => {
                if (item.reward_type === '4') {
                  money += Number(item.max_amount);
                } else {
                  money += Number(item.amount);
                }
              });
            }
            const args = {
              show: DialogModalChildrenKey.RegisterBonus,
              style: 2,
              money: money,
              endTime: activityNoviceData.activity_end_time * 1000,
              handleClose: () => {
                store.dispatch(
                  setDialogModalVisible({
                    show: DialogModalChildrenKey.Default,
                  }),
                );
              },
            };
            store.dispatch(setDialogModalVisible(args));
          }
        } else {
          showModal();
        }
      } else {
        const redeemSwitch = get(activityStatus, 'redeem_code_switch');
        if (redeemSwitch == '0') {
          toast.error(t('home.toast025'), {
            containerId: 'global',
          });
        } else {
          showModal();
        }
      }
    }
  } else if (link.startsWith('https://') || link.startsWith('http://')) {
    openUrl(link);
  }
};

/**
 * 根据不同的打开模式处理链接
 *
 * @param {string} link - 需要处理的链接
 * @param {string} openMode - 链接的打开模式，决定了如何处理链接
 * @param {Function} navigateFn - 导航函数，用于在应用内导航
 */
export const getCmsLink = (
  link,
  openMode,
  navigateFn,
  activityStatus = null,
  activityData = null,
  websiteInitConfig = null,
) => {
  switch (openMode) {
    case '0': {
      // 如果链接是站内链接（以 '/' 开头），则使用导航函数进行导航
      if (link.startsWith('/')) {
        if (String(link).toLowerCase() == '/betbonus' || String(link).toLowerCase() == '/promotion/rakeback') {
          const switchRakeback = get(websiteInitConfig, 'system_config.switch_rakeback');
          if (switchRakeback == 1) {
            navigateFn(toLocalePath(link));
          }
        } else {
          navigateFn(toLocalePath(link));
        }
      }
      break;
    }
    case '1': {
      // 如果链接是https或http链接，并且应在当前窗口打开，则使用openUrl函数打开链接
      if (link.startsWith('https://') || link.startsWith('http://')) {
        openUrl(link, '_self');
      }
      break;
    }
    case '2': {
      // 如果链接是https或http链接，并且应在新窗口打开，则使用openUrl函数打开链接
      if (link.startsWith('https://') || link.startsWith('http://')) {
        openUrl(link);
      }
      break;
    }
    case '3': {
      if (link.startsWith('?')) {
        const params = new URLSearchParams(link);
        const obj = Object.fromEntries(params.entries());

        const showModal = () => {
          const { modal, ...other } = obj;
          const args = {
            show: DialogModalChildrenKey[obj.modal],
            ...other,
          };
          store.dispatch(setDialogModalVisible(args));
        };

        if (obj.modal) {
          if (obj.modal !== 'Redeem') {
            const modalName = String(obj.modal).toLowerCase();
            if (modalName == 'fission') {
              showFissionMainPage(FissionDialogShowFrom.banner, true);
            } else if (modalName == 'signup') {
              if (isLogin()) {
                return;
              }
              showModal();
            } else if (modalName == 'WithdrawDeposit'.toLowerCase()) {
              if (!isLogin()) {
                return store.dispatch(
                  setDialogModalVisible({
                    show: DialogModalChildrenKey.LoginBox,
                  }),
                );
              }
              if (isControllableSite()) {
                if (obj.walletType == 2) {
                  const { path } = getLangFromPath(window.location.pathname);
                  localStorage.setItem('prev_path', path);
                  navigateFn(toLocalePath(`/pay/withdrawtabpage/1`));
                } else {
                  store.dispatch(setShowWGDeposit(true));
                }
              } else {
                showModal();
              }
            } else if (modalName == 'registerbonus') {
              const activityNovice = get(activityData, 'activity_novice');
              if (activityNovice && activityNovice.list.length > 0) {
                const activityNoviceData = activityNovice.list[0];
                let money = 0;
                if (activityNoviceData.activity_rewards) {
                  activityNoviceData.activity_rewards.forEach((item) => {
                    if (item.reward_type === '4') {
                      money += Number(item.max_amount);
                    } else {
                      money += Number(item.amount);
                    }
                  });
                }
                const args = {
                  show: DialogModalChildrenKey.RegisterBonus,
                  style: 2,
                  money: money,
                  endTime: activityNoviceData.activity_end_time * 1000,
                  handleClose: () => {
                    store.dispatch(
                      setDialogModalVisible({
                        show: DialogModalChildrenKey.Default,
                      }),
                    );
                  },
                };
                store.dispatch(setDialogModalVisible(args));
              }
            } else {
              showModal();
            }
          } else {
            const redeemSwitch = get(activityStatus, 'redeem_code_switch');
            if (redeemSwitch == '0') {
              toast.error(t('home.toast025'), {
                containerId: 'global',
              });
            } else {
              showModal();
            }
          }
        }
      }
      break;
    }
  }
};

// 异步加载JS脚本
export function loadScriptAsync(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    document.head.append(script);
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
  });
}

export function isSportEnabled() {
  const userObj = get(store.getState(), 'app.', null);
  // setVipLevelLogin(userObj.vip_level, userObj.uid || -1);
  return userObj !== null;
}

/**
 * Appends the parameters provided to the url as query string parameters.
 *
 * If the url already has a query the new parameters will be appended.
 */
export function urlWithQueryString(url, params) {
  const qs = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  if (!qs.length) {
    return url;
  }

  if (url.indexOf('?') === -1) {
    return `${url}?${qs}`;
  } else {
    return `${url}&${qs}`;
  }
}

export const getPercentage = (num, totalNum) => {
  if (Number(num) >= Number(totalNum)) {
    return 100;
  } else {
    if (isNaN(Number(num) / Number(totalNum))) {
      return 0;
    } else {
      return Math.floor((Number(num) / Number(totalNum)) * 1000) / 10;
    }
  }
};

export const getTourPrize = (rank, rewardList = []) => {
  const found = rewardList.find(
    (item) => Number(rank) >= Number(item.tap_position) && Number(rank) <= Number(item.end_tap_position),
  );
  return found ? getShowMoney(found.balance) : '--';
};

/**
 * 判断邮箱格式是否正确
 * @returns boolean
 */
export const checkEmail = (email) => {
  const emailOk = /\S+@\S+\.\S+/.test(email);
  return emailOk;
};

//用户登录、注册，手机号邮箱缓存
export class UserCache {
  static #ls_anyAccount_key = 'any_account';
  static #ls_phone_key = 'account';
  static #ls_email_key = 'account_email';
  static #ls_userName_key = 'account_userName';
  static #ls_login_method = 'login_method';
  static LoginMethodList = ['phone', 'email', 'userName', 'any'];
  static LoginMethodValue = {
    Phone: UserCache.LoginMethodList[0],
    Email: UserCache.LoginMethodList[1],
    UserName: UserCache.LoginMethodList[2],
    Any: UserCache.LoginMethodList[3],
  };
  static set = (key, value) => {
    localStorage.setItem(key, value);
  };
  static get = (key) => {
    return localStorage.getItem(key) || '';
  };
  static remove = (key) => {
    localStorage.removeItem(key);
  };
  static setAnyAccount = (value) => {
    this.set(this.#ls_anyAccount_key, value);
  };
  static setPhone = (value, isSwitchLoginMethod = false) => {
    this.set(this.#ls_phone_key, value);
    if (isSwitchLoginMethod) {
      this.setLoginMethod(this.LoginMethodValue.Phone);
    }
  };
  static getPhone = () => {
    return this.get(this.#ls_phone_key);
  };
  static getAnyAccount = () => {
    return this.get(this.#ls_anyAccount_key);
  };
  static setEmail = (value, isSwitchLoginMethod = false) => {
    this.set(this.#ls_email_key, value);
    if (isSwitchLoginMethod) {
      this.setLoginMethod(this.LoginMethodValue.Email);
    }
  };
  static getEmail = () => {
    return this.get(this.#ls_email_key);
  };
  static setUserName = (value, isSwitchLoginMethod = false) => {
    this.set(this.#ls_userName_key, value);
    if (isSwitchLoginMethod) {
      this.setLoginMethod(this.LoginMethodValue.UserName);
    }
  };
  static getUserName = () => {
    return this.get(this.#ls_userName_key);
  };
  static removePhone = () => {
    this.remove(this.#ls_phone_key);
  };
  static removeEmail = () => {
    this.remove(this.#ls_email_key);
  };
  static removeUserName = () => {
    this.remove(this.#ls_userName_key);
  };
  static removeAnyAccount = () => {
    this.remove(this.#ls_anyAccount_key);
  };
  static setLoginMethod = (value) => {
    this.set(this.#ls_login_method, value);
  };
  static getLoginMethod = () => {
    return this.get(this.#ls_login_method);
  };
}

export const langIconName = (str) => {
  const list = [
    {
      key: 'en',
      name: IconName.LangEnglish,
    },
    {
      key: 'pt',
      name: IconName.LangProtugues,
    },
    {
      key: 'fil',
      name: IconName.LangFilipino,
    },
    {
      key: 'ja',
      name: IconName.LangJapanese,
    },
    {
      key: 'vi',
      name: IconName.LangVi,
    },
    {
      key: 'th',
      name: IconName.LangTh,
    },
    {
      key: 'ms',
      name: IconName.LangMs,
    },
    {
      key: 'hi',
      name: IconName.LangHi,
    },
    {
      key: 'es',
      name: IconName.LangEs,
    },
    {
      key: 'id',
      name: IconName.LangId,
    },
  ];

  const found = list.find((item) => item.key === str);

  return found ? found.name : IconName.LangDefault;
};

export const getNationalFlagIconNameFromCurrencyCode = (currencyCode) => {
  switch (currencyCode) {
    case 'BRL':
      return IconName.LangProtugues;

    case 'JPY':
      return IconName.LangJapanese;

    case 'PHP':
      return IconName.LangFilipino;

    default:
      return IconName.LangProtugues;
  }
};

export function getQueryString(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substring(1).match(reg);
  return r == null ? null : decodeURIComponent(r[2]);
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function Random(min, max) {
  //min ≤ r ≤ max
  return Math.round(Math.random() * (max - min)) + min;
}

//结束剩余的秒
export function getCountdownStr(endTimeSecond) {
  const result = [];
  var d = parseInt(endTimeSecond / 60 / 60 / 24);
  d = d < 10 ? '0' + d : d;
  var h = parseInt((endTimeSecond / 60 / 60) % 24);
  h = h < 10 ? '0' + h : h;
  var m = parseInt((endTimeSecond / 60) % 60);
  m = m < 10 ? '0' + m : m;
  var s = parseInt(endTimeSecond % 60);
  s = s < 10 ? '0' + s : s;
  if (d != 0) {
    result.push(`${d}d`);
  } else {
    result.push('');
  }
  result.push(`${h}:`);
  result.push(`${m}:`);
  result.push(`${s}`);
  return result;
}

export function GetLayoutChildrenKeys(json, moduleName, currentLayout, responsiveMode) {
  if (json[currentLayout] && json[currentLayout][moduleName]) {
    return json[currentLayout][moduleName].mobile;
  }
  return [];
}

// export function getMoney(gold, xs = 0) {
//   return getShowMoney(gold, 2);
// }

export function GetLayoutChildrenKeysByServer(json, moduleName, currentLayout, responsiveMode, serverConfig = []) {
  if (serverConfig.length == 0) {
    return [];
  }
  let defaultLayouts = [];
  if (json[currentLayout] && json[currentLayout][moduleName]) {
    defaultLayouts = json[currentLayout][moduleName].mobile;
  }
  const result = Array.from(defaultLayouts).concat(serverConfig);
  result.sort((a, b) => {
    return a.sort - b.sort;
  });
  return result;
}

export function isDateWithin30Days(dateToCheck = new Date()) {
  const thrityDasAgo = moment().subtract(31, 'days');
  const now = moment();
  return moment(dateToCheck).isBetween(thrityDasAgo, now);
}

export function isDateAfterAnother(dateToCheck, anotherDate) {
  return moment(dateToCheck).startOf('day').isAfter(moment(anotherDate).startOf('day'));
}

export function bytesToMegabytes(bytes) {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2); // 保留两位小数
}

/***获取模版id */
export function getTemplateId(templateId = null) {
  const websiteInitConfig = templateId || get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig && websiteInitConfig.website_info) {
    //br-n-template001
    const websiteTemplateId = websiteInitConfig.website_info.website_template_id;
    if (websiteTemplateId.split('-').length == 3) {
      return String(`${websiteTemplateId.split('-')[1]}-${websiteTemplateId.split('-')[2]}`).toLowerCase();
    } else {
      return '';
    }
  } else {
    return '';
  }
}

/**
 * 判断给定的模板ID是否是特定的'br-n'类型
 *
 * 此函数首先尝试检查传入的templateId参数是否为'br-n'类型的模板ID，
 * 如果未传入templateId或传入的值为null，则尝试从应用的状态中获取网站初始化配置中的模板ID。
 *
 * @param {string|null} templateId - 模板ID，如果未提供则尝试从应用状态中获取
 * @returns {boolean} 如果模板ID是'br-n'类型，则返回true；否则返回false
 */
export function isBrNTemplateId(templateId = null) {
  const websiteInitConfig = templateId || get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig && websiteInitConfig.website_info) {
    const websiteTemplateId = websiteInitConfig.website_info.website_template_id;
    return websiteTemplateId.startsWith('br-n');
  } else {
    return false;
  }
}

/** 是否是可控盘(仿wg) */
export function isControllableSite(templateId = null) {
  const websiteInitConfig = templateId || get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig && websiteInitConfig.website_info) {
    //br-n-template001
    const websiteTemplateId = websiteInitConfig.website_info.website_template_id;
    if (websiteTemplateId.split('-').length == 3) {
      return String(`${websiteTemplateId.split('-')[1]}`).toLowerCase() == 'd';
    } else {
      return false;
    }
  } else {
    return false;
  }
}

//获取任一活动开关状态
export function getAnyActivitySwitch(activityStatus) {
  return (
    activityStatus.activity_task_switch == '1' ||
    activityStatus.activity_relief_money_switch == '1' ||
    activityStatus.activity_tournament_switch == '1' ||
    activityStatus.redeem_code_switch == '1' ||
    activityStatus.activity_contract_switch == '1' ||
    activityStatus.activity_novice_guide_tournament_switch == '1'
  );
}

//获取当前的有效红包
export function getRedPacketObj(cashRainList = []) {
  if (cashRainList && cashRainList.length > 0) {
    let list = [];
    for (let i = 0; i < cashRainList.length; i++) {
      const _cashRainObj = cloneDeep(cashRainList[i]);
      const limitStartTime = _cashRainObj.red_packet_start_time * 1000 - new Date().getTime();
      const limitEndTime = _cashRainObj.red_packet_End_time * 1000 - new Date().getTime();
      if (limitEndTime > 0) {
        _cashRainObj.started = limitStartTime >= 0 ? false : true;
        _cashRainObj.showTime = moment(_cashRainObj.red_packet_start_time * 1000).format(`HH:mm`);
        list.push(_cashRainObj);
      }
    }

    list.sort(function (a, b) {
      return a.red_packet_start_time - b.red_packet_start_time;
    });

    list.sort(function (a, b) {
      if (a.started && b.started) {
        return b.is_grad - a.is_grad;
      } else {
        return 0;
      }
    });

    list.sort(function (a, b) {
      if (a.started && b.started && a.is_grad == b.is_grad) {
        return b.id - a.id;
      } else {
        return 0;
      }
    });
    if (list.length > 0) {
      return list[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getComputedStyleObj(element) {
  if (element && window.getComputedStyle(element)) {
    return window.getComputedStyle(element);
  } else {
    return null;
  }
}

export function getCssValue(element, propertyName) {
  const obj = getComputedStyleObj(element);
  if (obj) {
    return obj.getPropertyValue(propertyName);
  } else {
    return null;
  }
}

/** 手机模式下首页是否使用上下布局 */
export function isUseLayoutMobile() {
  const layoutList = ['d-template001', 'd-template002', 'd-template003'];

  return layoutList.includes(getTemplateId());
}

/** 手机模式下首页是否使用左右布局 */
export function isUseLayoutMobileHome() {
  const layoutList = ['d-template002'];

  return layoutList.includes(getTemplateId());
}

/** 是否是浅色主题 ，更换一些图片*/
export function isLightTheme() {
  const theme = get(store.getState(), 'app.websiteInitConfig.website_info.website_theme');

  const themeList = ['theme-15'];

  return themeList.includes(theme);
}

export function removeLoadingPage() {
  const rootLoadingBox = document.getElementById('root-loading-box');
  if (rootLoadingBox) {
    rootLoadingBox.remove();
  }
}

/**
 * 判断路由是否包含隐藏元素。
 *
 * 该函数用于确定给定的路由路径是否属于一组特定的路径之一，这些路径在渲染时不应显示头部、底部或 footer。
 * 主要用于在单页应用中动态调整页面布局。
 *
 * @param {string} pathname 浏览器当前的路由路径。
 * @returns {boolean} 如果路径属于不需要显示头部和底部的路径之一，则返回 true，否则返回 false。
 */
export function isRouteWithHiddenElements(pathname) {
  if (!pathname) {
    return false;
  }

  const noHeaderAndBottomAndFooterPaths = [
    '/gamesearch',
    '/report',
    '/supportmessage',
    '/myaccountdata',
    '/security',
    '/mybonus',
    '/pay/withdraw',
    '/promotion/events',
    '/promotion/mission',
    '/promotion',
    '/promotion/vip',
    '/promotion/pending',
    '/promotion/history',
    '/promotion/rakeback',
    '/promotion/giftmoney',
    '/promotion/redeem',
    '/referral',
    '/installPwa',
    '/mysterybonus',
  ];

  const followWGPath = ['/referral'];

  if (['/mine', '/gamelist'].includes(pathname)) {
    return true;
  } else {
    const basePath = pathname.split(/[?#]/)[0];
    return noHeaderAndBottomAndFooterPaths.some((prefix) => basePath.startsWith(prefix));
  }
}

export function getIsShowMobileMenu(pathname) {
  // if (!pathname) {
  //   return false;
  // }

  const hiddenPathList = [
    '/gamesearch',
    '/report',
    '/supportmessage',
    '/myaccountdata',
    '/security',
    '/mybonus',
    '/gamelist',
    '/pay/withdraw',
    '/promote',
    '/myData',
    '/allData',
    '/performance',
    '/commission',
    '/directData',
    '/directBetting',
    '/directFinance',
    '/directReceive',
    '/history',
    '/referral',
    '/installPwa',
    '/mysterybonus',
  ];

  if (pathname.includes('promotion')) {
    const pathParts = pathname.split('/');
    if (
      pathParts.length >= 3 &&
      pathParts[2] != 'events' &&
      pathParts[2] != 'mission' &&
      pathParts[2] != 'vip' &&
      pathParts[2] != 'pending' &&
      pathParts[2] != 'history' &&
      pathParts[2] != 'rakeback' &&
      pathParts[2] != 'giftmoney' &&
      pathParts[2] != 'redeem'
    ) {
      return false;
    }
  }
  const basePath = pathname.split(/[?#]/)[0];
  return !hiddenPathList.some((path) => basePath.startsWith(path));
}

export function getIsFullPageShowDisconnectInfo(pathname) {
  if (!pathname) {
    return false;
  }

  if (toNoLocalePath(pathname).indexOf('/mine') > -1 && isControllableSite()) {
    return true;
  } else {
    return false;
  }
}

//获取指定时区的时间戳
export function getConfigTimeZoneTimestamp(timestamp) {
  const offset = get(store.getState(), 'app.websiteInitConfig.basic.time_zone.offset');
  if (offset) {
    return moment.utc(timestamp).utcOffset(offset / 60);
  } else {
    return moment(timestamp);
  }
}

//返回最近一个月
export function isDateInRangeLastMonth(date) {
  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);
  return date >= lastMonth && date <= today;
}

/**
 * 计算从当前时间到指定结束时间的时间差。
 *
 * 此函数使用moment.js库的unix()方法获取当前时间的Unix时间戳，
 * 并将其用作计算时间差的起始时间。然后，它计算出从当前时间到指定结束时间的秒数差。
 *
 * @param {number} endTime - 指定的结束时间的Unix时间戳。
 * @returns {number} 返回从当前时间到结束时间的秒数差。
 */
export function currentTimeToEndTimeDiff(statime, endtime) {
  statime = moment().unix();
  return Number(endtime) - Number(statime);
}

export function pointToComma(val) {
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig?.website_info?.currency_code == 'BRL') {
    var amount = String(val);
    amount = amount.replace('.', ',');
    return amount;
  }
  return val;
}

export function commaToPoint(val) {
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  if (websiteInitConfig?.website_info?.currency_code == 'BRL') {
    var amount = String(val);
    amount = amount.replace(',', '.');
    return amount;
  }
  return val;
}

export function TextPlaces1(val, num = 13) {
  let temp = val.toString();
  // temp = temp.replace(/\s/g,'');
  temp = temp.replace(/\t/g, '');
  temp = temp.replace(/\r\n/g, '');
  temp = temp.replace(/\n/g, '');
  temp = temp.replace(/[\\*&%$!@#、……=+]/g, '');
  temp = temp.slice(0, num);

  return temp;
}

export function TextPlacesName(val, num = 13) {
  let temp = val.toString();
  // temp = temp.replace(/\s/g,'');
  temp = temp.replace(/\t/g, '');
  temp = temp.replace(/\r\n/g, '');
  temp = temp.replace(/\n/g, '');
  temp = temp.replace(/[\\*&%$!@#、……=+]/g, '');
  temp = temp.replace(/ {2,}/g, ' ');
  temp = temp.slice(0, num);

  return temp;
}

export function putChangeTextCorty(val, num = 13) {
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  let temp = val.toString();
  temp = temp.replace(/。/g, '.');
  if (websiteInitConfig?.website_info?.currency_code == 'BRL') {
    temp = temp.replace(/[^\d.,]/g, ''); // 允许数字、逗号和小数点之外的所有字符
    let firstCommaIndex = temp.indexOf(',');
    if (firstCommaIndex != -1) {
      temp = temp.replace('.', '');
    }
    // 如果第一个
    if (val.length == 1) {
      temp = temp.replace(',', '');
    }

    // 将第一个逗号之后的所有逗号替换为空字符串
    temp = temp.substring(0, firstCommaIndex + 1) + temp.substring(firstCommaIndex + 1).replace(/,/g, '');
  } else {
    temp = temp.replace(/[^\d.]/g, '');
  }
  temp = temp.replace(/^\./g, '');
  temp = temp.replace(/\.{2,}/g, '');
  temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  temp = temp.replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, '$1$2.$3');
  temp = temp.slice(0, num);
  if (val.length == num) {
    let newVal = temp.substring(num - 1, num);
    if (newVal === '.') {
      temp = temp.slice(0, num - 1);
    }
  }
  return temp;
}

export function putPatton(val, num = 13) {
  let temp = val.toString();
  temp = temp.replace(/[^\d]/g, ''); // 仅保留数字字符
  temp = temp.slice(0, num); // 保留指定位数的数字字符
  temp = temp.replace(/[^\d.]/g, '');
  temp = temp.replace(/^\./g, '');
  return temp;
}

export function isValidERCAddress(address) {
  const ercAddressPattern = /^0x[0-9a-fA-F]{40}$/;
  return ercAddressPattern.test(address);
}

export function isValidTRXAddress(address) {
  const trxAddressPattern = /^T[0-9A-HJ-NP-Za-km-z]{33}$/;
  return trxAddressPattern.test(address);
}

/**
 * 获取当前或上一周的起止日期。
 *
 * 该函数用于根据当前日期确定一周的起止日期。如果指定参数isPreviousWeek为true，则返回上一周的起止日期；
 * 否则返回当前周的起止日期。
 *
 * @param {boolean} isPreviousWeek - 指定是否获取上一周的范围。默认为false，表示获取当前周的范围。
 * @returns {Object} 返回一个对象，包含本周的起止日期。
 * @property {Date} startDate - 一周的起始日期。
 * @property {Date} endDate - 一周的结束日期。
 */
export const getWeekRange = (isPreviousWeek = false, isMidnight = false) => {
  const today = moment();
  const todayIsWeekday = today.day();

  let startOfWeek;
  if (todayIsWeekday === 0) {
    startOfWeek = today.clone().add(1, 'days');
  } else {
    startOfWeek = today.clone().subtract(todayIsWeekday - 1, 'days');
  }

  if (isPreviousWeek) {
    startOfWeek = startOfWeek.subtract(1, 'weeks');
  }

  const endOfWeek = startOfWeek.clone().add(6, 'days');

  if (isMidnight) {
    return {
      startDate: startOfWeek.startOf('day').toDate(),
      endDate: endOfWeek.startOf('day').toDate(),
    };
  } else {
    return {
      startDate: startOfWeek.toDate(),
      endDate: endOfWeek.toDate(),
    };
  }
};

/**
 * @description 判断是安卓还是iOS
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 检测 iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  // 检测 Android
  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  return 'unknown';
};
//获取注册配置信息、method为1则正常盘注册，method为2则为wg手机号用户名注册
export function getRegisterConfig(method = '1') {
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  const platform = Array.from(websiteInitConfig?.system_config?.register_config?.platform || []);
  if (platform.length > 0) {
    return platform.find((item) => {
      return item.method == method && item.status == '1';
    });
  } else {
    return null;
  }
}

//获取排序后的注册方法
export function getRegisterMethodsSort() {
  const platform = getRegisterConfig('1');
  if (platform != null) {
    const option = platform.option;
    // 按值排序
    const sortedObj = Object.fromEntries(
      Object.keys(option)
        .sort((a, b) => Number(option[b]) - Number(option[a]))
        .map((key) => [key, option[key]]),
    );
    const registerMethodsSort = [];
    for (let obj in sortedObj) {
      if (sortedObj[obj] != '0') {
        if (obj == 'phone_method') {
          registerMethodsSort.push('phone');
        } else if (obj == 'username_method') {
          registerMethodsSort.push('userName');
        }
      }
    }
    return registerMethodsSort;
  } else {
    return [];
  }
}

//获取当前注册方式是否开启
export function getRegisterMethodsEnable(method, key) {
  const enableValue = getRegisterMethodsOption(method, key);
  if (enableValue != null) {
    return enableValue != '0';
  } else {
    return false;
  }
}

//获取当前注册方法配置option
export function getRegisterMethodsOption(method, key) {
  const platform = getRegisterConfig(method);
  if (platform != null) {
    const option = platform.option;
    if (Object.keys(option).includes(key)) {
      return option[key];
    } else {
      return null;
    }
  } else {
    return null;
  }
}

//获取第三方登录的配置信息
export function getRegisterThirdpart() {
  const websiteInitConfig = get(store.getState(), 'app.websiteInitConfig');
  return websiteInitConfig?.system_config?.register_config?.thirdpart || null;
}

export function replaceWithMobile(text) {
  // 定义匹配域名的正则表达式
  const domainRegex = /https?:\/\/(?:www\.)?(.+?)\//;

  // 替换域名部分为 "m.${domain}"
  return text.replace(domainRegex, (match, domain) => {
    return `//m.${domain}/`;
  });
}

export function hasNestedProperty(obj, propertyPath) {
  if (!propertyPath) {
    return false;
  }

  const props = propertyPath.split('.');
  let currentObj = obj;

  for (const prop of props) {
    if (currentObj.hasOwnProperty(prop)) {
      currentObj = currentObj[prop];
    } else {
      return false;
    }
  }

  return true;
}
export function formatCpf(str) {
  let res = str.split('').map((item, index) => {
    return [2, 5].includes(index) ? `${item}.` : index == 8 ? `${item}-` : item;
  });
  return res ? res.join().replace(/,/g, '') : '';
}

/**
 * 将像素值转换为rem值
 *
 * 此函数的主要目的是在CSS中使用时，将像素单位的值转换为rem单位的值这样可以提高页面的响应式设计能力，
 * 因为rem单位是相对于根元素的字体大小的这样可以使得元素的大小随着根元素字体大小的变化而变化，从而实现
 * 全局的响应式效果
 *
 * @param {number} pxValue - 需要转换的像素值
 * @returns {number} - 转换后的rem值
 */
export function pxToRem(pxValue) {
  // 获取根元素的字体大小，这里通过获取计算样式来获取实际的字体大小
  const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

  // 将像素值除以根元素的字体大小，得到rem值
  return parseFloat((pxValue / fontSize).toFixed(2));
}

/**
 * 将rem值转换为像素值
 *
 * @param {number} remValue - 需要转换的rem值
 * @returns {number} - 转换后的像素值
 */
export function remToPx(remValue) {
  // 获取根元素的字体大小
  const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

  // 将rem值乘以根元素的字体大小，得到px值
  return parseFloat((remValue * fontSize).toFixed(2));
}

/**
 * 获取根元素的CSS变量值
 *
 * 此函数用于从根元素（html元素）中获取指定的CSS变量（--variableName）的值
 * 它可以选择性地返回带单位的值或不带单位的数值
 *
 * @param {string} variableName - CSS变量的名称，例如 '--color' 或 '--size'
 * @param {boolean} returnWithUnit - （可选）默认为 false 如果设置为 true，返回带单位的值；否则返回不带单位的数值
 * @returns {string|number} - 根据 returnWithUnit 参数，返回 CSS 变量的带单位值或不带单位的数值
 */
export function getRootCssVariable(variableName, returnWithUnit = false) {
  const root = document.documentElement;
  const computedStyle = window.getComputedStyle(root);
  const valueWithUnit = computedStyle.getPropertyValue(variableName);
  if (returnWithUnit) {
    return valueWithUnit;
  } else {
    const valueWithoutUnit = parseFloat(valueWithUnit);
    return valueWithoutUnit;
  }
}

/**
 * 获取根元素的CSS变量值
 *
 * 该函数用于从根元素（html元素）中获取指定的CSS变量的值。
 * 它可以选择性地返回带单位的值或不带单位的数值。
 *
 * @param {string} cssVariableName - CSS变量的名称，例如 '--colour-4'
 * @returns {string} - 返回CSS变量的值，去除前后空白字符
 */
export const getRootCssVariableTrimmed = (cssVariableName = '') => {
  const rootElement = document.documentElement;
  const computedStyle = window.getComputedStyle(rootElement);
  const cssVariableValueWithUnit = computedStyle.getPropertyValue(cssVariableName);
  return cssVariableValueWithUnit.trim();
};

export function getGeetestLang() {
  const currentLang = getCurrentLang();
  const GeetestLangObj = {
    en: 'eng',
    ja: 'jpn',
    fil: 'fil',
    th: 'tha',
    vi: 'vie',
    es: 'spa-us',
    pt: 'pon',
    ms: 'mal',
    hi: 'him',
  };
  return GeetestLangObj[currentLang] || 'eng';
}

export function PageReload() {
  if (DownloadManager.checkInIframe()) {
    parentLocationReload();
  } else {
    window.location.reload();
  }
}

export function executeWithCondition(callback, conditionFunc, interval) {
  const timerId = setInterval(() => {
    callback();
    if (conditionFunc()) {
      clearInterval(timerId);
      console.error('定时器已取消');
    }
  }, interval);
}

//保存带用户uid的localstorage信息
export function setLocalStorageWithUID(key, value) {
  const userObj = get(store.getState(), 'user.userObj', null);
  let lsKey = key;
  if (userObj != null) {
    lsKey = `${lsKey}_${userObj.uid}`;
  }
  localStorage.setItem(lsKey, value);
}

//获取带用户uid的localstorage信息
export function getLocalStorageWithUID(key) {
  const userObj = get(store.getState(), 'user.userObj', null);
  let lsKey = key;
  if (userObj != null) {
    lsKey = `${lsKey}_${userObj.uid}`;
  }
  return localStorage.getItem(lsKey) || '';
}

export function PopupFilterMissionData(list = []) {
  let data = list.filter((item) => {
    return [10, 11, 20, 21].includes(Number(item?.user_progress?.status));
  });
  data = data.filter((item) => {
    return item.popup_display ? item.popup_display.includes(getIdentity() ? 2 : 1) : false;
  });
  return data;
}

export function pageFilterMissionData(list = []) {
  let data = list.filter((item) => {
    return [10, 11, 20, 21].includes(Number(item?.user_progress?.status));
  });

  return data;
}

export function openHeaderGiftMoney(money = 0, fromPoint = [], handleClose = null) {
  const activityStatus = get(store.getState(), 'app.activityStatus', null);

  if (activityStatus && activityStatus.gift_money_menu_show_switch == '1') {
    store.dispatch(setShowHeaderGiftMoney(true));
    store.dispatch(setNeedUpdateGiftMoney({ need: true, data: money, fromPoint }));

    if (handleClose) {
      setTimeout(() => {
        handleClose();
      }, GSAPUtils.COIN_FLY_DURATION * 1000);
    }
  } else {
    handleClose && handleClose();
  }
}

export function openGiftMoneyDetail(isPlayingGame = false, pathname, innerOpenCallback = null) {
  const GiftMoneyIndex = -1;
  const activityStatus = get(store.getState(), 'app.activityStatus', null);
  const activityData = get(store.getState(), 'app.activityData', null);
  const giftMoneyData = activityData?.gift_money;

  const hasActivityType = (arr, index) => {
    return arr.some((item) => item.index == index && item.type == 1);
  };

  const activityWheelShowIconV2 = Array.from(activityStatus.activity_wheel_show_icon_v2 || []);
  const GiftMoneySwiper = hasActivityType(activityWheelShowIconV2, GiftMoneyIndex);

  let determineTargetClass = '';
  if (giftMoneyData) {
    if (pathname == '/') {
      determineTargetClass = isPlayingGame
        ? 'giftMoney_game-dragBox'
        : GiftMoneySwiper
          ? 'swiper_drag'
          : 'giftMoney_dragBox';
    } else {
      determineTargetClass = isPlayingGame ? 'giftMoney_game-dragBox' : '';
    }
  }

  if (isLogin()) {
    store.dispatch(setActivitySwiperSlideKey('GiftMoney'));
    store.dispatch(setShowHeaderGiftMoney(false));
    store.dispatch(setShowWGDeposit(false));
    if (innerOpenCallback) {
      innerOpenCallback(determineTargetClass);
    } else {
      store.dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.GiftMoney,
          isFromGame: false,
          giftMoneyMoveTargetClass: determineTargetClass,
        }),
      );
    }
  } else {
    store.dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
  }
}

export function isShareString(str = '') {
  return str.toLowerCase() == 'share';
}

export const getShareIconName = (str) => {
  if (str == 'share') {
    return 'MoreShare';
  } else {
    const name = `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}Share`;
    return name;
  }
};

export function isElementVisibleInParent(childElement, parentElement) {
  const childRect = childElement.getBoundingClientRect();
  const parentRect = parentElement.getBoundingClientRect();

  // 检查子元素的边界是否在父元素的边界内
  return (
    childRect.top >= parentRect.top &&
    childRect.left >= parentRect.left &&
    childRect.bottom <= parentRect.bottom &&
    childRect.right <= parentRect.right
  );
}

export const promotionPaths = [
  '/promotion/events',
  '/promotion/vip',
  '/promotion/pending',
  '/promotion/giftmoney',
  '/promotion/rakeback',
  '/promotion/history',
  '/promotion/redeem',
  '/promotion/mission',
];

export const isPromotionPath = (path) => {
  return promotionPaths.includes(path);
};

export function setHtmlStyleAttribute(key, value) {
  const htmlElement = document.querySelector('html');
  if (htmlElement) {
    htmlElement.style.setProperty(key, value);
  }
}

export function getScrollPosition() {
  let x, y;
  if (!!window.pageXOffset) {
    x = window.pageXOffset;
    y = window.pageYOffset;
  } else {
    x = (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    y = (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }
  return { x, y };
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getVipImg = (level) => {
  if (level == 0 || level == 1 || level == 2) {
    return `/static/img/theme18/vip/vip1.png`;
  } else if (level == 3 || level == 4 || level == 5) {
    return `/static/img/theme18/vip/vip2.png`;
  } else if (level == 6 || level == 7 || level == 8) {
    return `/static/img/theme18/vip/vip3.png`;
  } else if (level == 9 || level == 10 || level == 11) {
    return `/static/img/theme18/vip/vip4.png`;
  } else if (level == 12 || level == 13 || level == 14) {
    return `/static/img/theme18/vip/vip5.png`;
  } else if (level == 15 || level == 16 || level == 17) {
    return `/static/img/theme18/vip/vip6.png`;
  } else {
    return `/static/img/theme18/vip/vip7.png`;
  }
};
export function isValidMillisecondTimestamp(value) {
  // 如果值是字符串，尝试转换为数字
  if (typeof value === 'string') {
    // 检查字符串是否为纯数字
    if (!/^\d+$/.test(value)) {
      return false;
    }
    value = Number(value);
  }

  // 检查是否为数字
  if (typeof value !== 'number' || isNaN(value)) {
    return false;
  }

  // 检查是否为整数
  if (!Number.isInteger(value)) {
    return false;
  }

  // 检查是否为毫秒级时间戳（13位数字）
  const isMillisecondTimestamp = value > 1e12 && value < 1e14; // 13位毫秒级

  // 检查时间戳是否大于最小时间戳
  const minTimestamp = new Date('1970-01-01').getTime(); // 最小时间戳
  return isMillisecondTimestamp && value >= minTimestamp;
}

export function CopyText(text, option = {}) {
  return new Promise((resolve, reject) => {
    if (copy(text, option)) {
      toast.success(t('home.toast006'), {
        containerId: 'global',
      });
      resolve();
    } else {
      toast.error(t('home.toast036'), {
        containerId: 'global',
      });
      reject();
    }
  });
}
