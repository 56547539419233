import Icon, { IconName } from '../icon';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ProgressierOperatio from '../ProgressierOperation';
import { openUrl } from '../../utils/openUrl';
import { useTranslation } from 'react-i18next';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../redux/reducers/appSlice';
import { isPWA } from '../../utils/deviceUtils';
import configData from '../../constants/config';

const FastDownload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { installStatus } = useSelector(
    (state) => ({
      installStatus: state.app.installStatus,
    }),
    shallowEqual,
  );

  const handleClick = async () => {
    if (isPWA()) {
      return window.location.reload();
    }

    dispatch(
      setDialogModalVisible({
        zIndex: 10002,
        show: DialogModalChildrenKey.Confirm,
        content: t('home.pwaInstallTips'),
        confirmCb: () => {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
        },
      }),
    );

    openUrl(window.location.href);
  };

  return (
    <div className="w-[6.86rem] h-[4rem] bg-[var(--colour-35)] rounded-[.16rem] pt-[.18rem]">
      <div className="flex gap-[.18rem] items-center justify-center">
        <div className="w-[.46rem] h-[.52rem] text-[var(--colour-26)]">
          <Icon name={IconName.Fast}></Icon>
        </div>

        <div className="text-[var(--colour-48)] text-[.48rem] font-[700] text-center leading-[.4rem]">
          {t('home.fast_download')}
        </div>
      </div>

      <div className="w-[3.58rem] h-[.48rem] mt-[.32rem] mx-auto rounded-[.1229rem] border-[.0102rem] border-[var(--colour-6)] bg-[--colour-34] text-[.28rem] font-[400] leading-[.48rem] flex justify-center">
        {t('home.faster')}
      </div>

      <div className="mt-[.32rem] mx-auto w-[2.9rem] h-[.56rem] rounded-[.32rem] border-[.0102rem] border-[var(--colour-59)] bg-[--colour-34] gap-[.06rem] flex justify-center">
        <div className="w-[.3rem] h-[.34rem] text-[var(--colour-24)] mt-[.1rem]">
          <Icon name={IconName.Activated}></Icon>
        </div>
        <div className="text-[.36rem] font-[400]">{t('home.activated')}</div>
      </div>

      <div className="mt-[.36rem] ">
        {installStatus == 2 ? (
          <div
            className="mx-auto w-[5.18rem] h-[.84rem] rounded-[.16rem] bg-[--colour-1] flex items-center justify-center text-[.28rem] font-[700] text-[--colour-2]"
            onClick={handleClick}
          >
            {t('home.start_app')}
          </div>
        ) : (
          <>{installStatus == 1 ? <ProgressierOperatio /> : <></>}</>
        )}
      </div>
    </div>
  );
};

export default FastDownload;
