import cn from 'classnames';
import { useState } from 'react';
import Icon, { IconName } from '../icon';

const TextBox = ({ content = '', title = 'title' }) => {
  const [showAll, setShowAll] = useState(false);
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="w-full flex flex-col rounded-normal bg-[var(--colour-34)] text-[var(--colour-38)] overflow-hidden mb-[.2rem] transition-all duration-1000">
      <div
        className="bg-[var(--colour-47)] flex flex-row items-center justify-between h-[.6rem] px-[.24rem] rounded-normal cursor-pointer"
        onClick={handleShowAll}
      >
        <span className="text-[.24rem]">{title}</span>
        <span
          className={cn('cursor-pointer text-[var(--colour-46)] transition-all w-[.16rem] h-[.32rem]', {
            '-rotate-[180deg]': showAll,
          })}
        >
          <Icon name={IconName.ChatArraw} />
        </span>
      </div>

      {showAll && content ? (
        <div
          className="font-[400] py-[.3rem] text-[.24rem] px-[.24rem]"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextBox;
