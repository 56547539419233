import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import Tippy from '@tippyjs/react/headless';

export const Placement = {
  // default
  top: 'top',
  // full list:
  topStart: 'top-start',
  topEnd: 'top-end',
  right: 'right',
  rightStart: 'right-start',
  rightEnd: 'right-end',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
  left: 'left',
  leftStart: 'left-start',
  leftEnd: 'left-end',
  // choose the side with most space
  auto: 'auto',
  autoStart: 'auto-start',
  autoEnd: 'auto-end',
};

const TippyPopBox = ({
  children,
  placement,
  visible,
  popChildren,
  onClickOutside,
  offset,
  isTenSion = true,
  zIndex = 9999,
}) => {
  const dispatch = useDispatch();
  // const {
  //     children,
  //     placement,
  //     visible,
  //     popChildren,
  //     onClickOutside,
  //     offset
  // } = props

  const config = { tension: isTenSion ? 300 : 0 };
  const initialStyles = { opacity: 0 };
  const [styles, setSpring] = useSpring(() => initialStyles);

  function onMount() {
    setSpring({
      opacity: 1,
      onRest: () => {},
      config,
    });
  }

  function onHide({ unmount }) {
    setSpring({
      ...initialStyles,
      onRest: unmount,
      config: { ...config, clamp: true },
    });
  }
  return (
    <Tippy
      placement={placement}
      animation={true}
      onMount={onMount}
      onHide={onHide}
      onClickOutside={onClickOutside}
      interactive={true}
      visible={visible}
      zIndex={zIndex}
      render={(attrs) => (
        <animated.div {...attrs} style={styles}>
          {popChildren}
        </animated.div>
      )}
      offset={offset}
    >
      {children}
    </Tippy>
  );
};

export default TippyPopBox;
