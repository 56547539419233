import { useTranslation } from 'react-i18next';
import CountdownBox from '../../../../../../components/countdownBox';

const PendingCountdown = ({ date, onComplete, isHideCanceded = false }) => {
  const { t } = useTranslation();

  const renderer = ({ completed, formatted }) => {
    return !completed ? (
      <div className="text-[var(--colour-26)] text-[.16rem] font-[500]">
        <div className="text-right">
          <span className="">
            {Number(formatted.days) > 0 && <span>{formatted.days} Days </span>}
            <span>{formatted.hours}:</span>
            <span>{formatted.minutes}:</span>
            <span>{formatted.seconds}</span>
          </span>
        </div>
        {!isHideCanceded && <div className="text-right">{t('promotion.canceled')}</div>}
      </div>
    ) : (
      <div className="text-[var(--colour-26)] text-[.2rem] font-[500] text-right">
        <div>00:00:00</div>
        {!isHideCanceded && <div>{t('promotion.canceled')}</div>}
      </div>
    );
  };

  return <CountdownBox date={date} onComplete={onComplete} renderer={renderer} />;
};

export default PendingCountdown;
