import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { GetProviderListReq } from '../api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { cmsImgUrl, isControllableSite } from '../utils/helper';
import { toLocalePath } from '../utils/localePath';
import { useNavigate } from 'react-router-dom';
import Icon, { IconName } from './icon';
import { useStateIfMounted } from 'use-state-if-mounted';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import HomeModuleHeader from './homeModuleHeader';
import useLangServerConfig from '../hooks/useLangServerConfig';
import { useGlobalNavigate } from '../contexts/navigateContext';

const Item = ({ item = null, clickItem = () => {} }) => {
  const navigate = useGlobalNavigate();
  const {} = useSelector((state) => ({}), shallowEqual);

  return item != null ? (
    <div className={cn(`flex justify-center items-center p-[.1rem] h-[.64rem] rounded-normal bg-[var(--colour-28)]`)}>
      <img
        src={`${cmsImgUrl(item.icon)}`}
        alt={item.name}
        onClick={() => {
          if (item.name.toLowerCase() == 'betby') {
            navigate(toLocalePath('/sport'));
          } else {
            clickItem(item);
          }
        }}
        className="cursor-pointer"
      />
    </div>
  ) : null;
};

const ColumnSwiper = ({ mobileConfig, pcConfig, rows = 1, column = 1, list = [], gap, clickItem = () => {} }) => {
  const [columnList, setColumnList] = useStateIfMounted([]);
  const [repeatedList, setRepeatedList] = useStateIfMounted([]);
  const [realRows, setRealRows] = useStateIfMounted(0);
  const [isLoop, setIsLoop] = useStateIfMounted(false);
  const {} = useSelector((state) => ({}), shallowEqual);

  const getRepeatedArray = (originalArray, targetLength) => {
    if (originalArray && originalArray.length > 0) {
      return Array(targetLength)
        .fill()
        .map((_, index) => originalArray[index % originalArray.length]);
    } else {
      return [];
    }
  };

  useEffect(() => {
    const _realRows = Math.ceil(list.length / mobileConfig.maxColumns);
    setRealRows(rows < _realRows ? rows : _realRows);
  }, [column, rows, list.length]);

  useEffect(() => {
    if (realRows > 0) {
      if (list.length > mobileConfig.supplementNum) {
        const pageTotal = realRows * column;
        const maxSlides = Math.ceil(list.length / pageTotal);
        setRepeatedList(getRepeatedArray(list, maxSlides * pageTotal));
      } else {
        setRepeatedList(list);
      }
    }
  }, [realRows, column, list.length]);

  useEffect(() => {
    if (realRows > 0) {
      const pageTotal = realRows * column;
      const maxSlides = Math.ceil(repeatedList.length / pageTotal);
      let columnList = [];
      for (let i = 0; i < maxSlides; i++) {
        let pageArr = Array.from(repeatedList).slice(pageTotal * i, pageTotal * i + pageTotal);
        if (pageArr.length > 0) {
          columnList.push(pageArr);
        }
      }
      if (maxSlides > 1) {
        setIsLoop(true);
      } else {
        setIsLoop(false);
      }
      setColumnList(columnList);
    }
  }, [realRows, column, repeatedList.length]);

  return (
    <Swiper
      slidesPerView={1}
      speed={1000}
      loop={isLoop}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      grabCursor={true}
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={gap}
    >
      {columnList.map((columnItem, columnIndex) => (
        <SwiperSlide key={columnIndex}>
          <div className="grid h-full" style={{ gap: gap, gridTemplateColumns: `repeat(${column}, 1fr)` }}>
            {columnItem.map((item, index) => (
              <Item item={item} clickItem={clickItem} key={index} />
            ))}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default function HomeProvider({ list, homeProviderRes, config }) {
  const pcConfig = {
    maxColumns: 7,
    supplementNum: 3, //超过这个数值才进行补充，否则直接显示
  };
  const mobileConfig = {
    maxColumns: 4,
    supplementNum: 2,
  };
  const { t } = useTranslation();
  const { getServerLangText } = useLangServerConfig();

  const clickItem = (item) => {
    homeProviderRes(item.name);
  };

  return list.length > 0 ? (
    <div>
      <div className={cn({})}>
        {list.length > 0 && (
          <HomeModuleHeader title={getServerLangText(config.lang_key)} img={cmsImgUrl(config.icon)} isViewAll={false} />
        )}
        <div>
          {list.length > 0 && (
            <ColumnSwiper
              pcConfig={pcConfig}
              mobileConfig={mobileConfig}
              rows={config.max_rows}
              column={mobileConfig.maxColumns}
              list={list}
              gap={10}
              clickItem={clickItem}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
}
