import Icon, { IconName } from '../../../icon';
import MenuText from './menuText';
import MenuImg from './menuImg';
import useLangServerConfig from '../../../../hooks/useLangServerConfig';
import { shallowEqual, useSelector } from 'react-redux';
import Badge from '../../../../views/mine/normal/template001/components/badge';
import { getTemplateId } from '../../../../utils/helper';

export default function Index({
  isChoose = false,
  text = 'Promotion',
  clickPromo = () => {},
  data = {},
  ismagnifyImg = false,
}) {
  const { promotionClaimedCount } = useSelector(
    (state) => ({
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );

  const handleClick = () => {
    clickPromo();
  };
  const { getServerLangText } = useLangServerConfig();

  return (
    <div
      className={`flex flex-col flex-1 items-center h-full relative ${ismagnifyImg ? 'justify-between pb-[.06rem]' : 'justify-center'}`}
    >
      <span
        className={`block relative ${ismagnifyImg ? 'w-[.88rem] h-[.88rem] mt-[-0.23rem]' : `${getTemplateId() == 'd-template003' ? 'w-[.56rem] h-[.56rem]' : 'w-[.48rem] h-[.48rem]'}`}`}
        onClick={handleClick}
      >
        {promotionClaimedCount && promotionClaimedCount.total && promotionClaimedCount.total > 0 ? (
          <Badge
            textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
            className="!min-w-[.3rem] h-[.3rem] !top-[-0.2rem] left-[80%] flex items-center"
            text={promotionClaimedCount.total}
          ></Badge>
        ) : null}

        {data?.img_source == '1' ? (
          <MenuImg src={isChoose ? data.img_checked : data.img_unchecked} />
        ) : (
          <Icon name={isChoose ? IconName.MenuBarPromotionChoose : IconName.MenuBarPromotionDefault} />
        )}
      </span>
      <MenuText onClick={handleClick} isChoose={isChoose} ismagnifyImg={ismagnifyImg}>
        {getServerLangText(data?.lang_key)}
      </MenuText>
    </div>
  );
}
