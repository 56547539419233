import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from 'react-vant';
import { toast } from 'react-toastify';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import DialogModal from '../../../../components/dialogModal';
import Close from '../../../../components/close';
import Icon, { IconName } from '../../../../components/icon';
import TippyPopBox, { Placement } from '../../../../components/tippyPopBox';
import PopChoose, { PopChooseItem } from '../../../../components/popChoose';
import { GetRoundDetailByDay } from '../../../../api';
import DatetimePickerBox from '../../../../components/datetimePicker';
import {
  getDateFormatStr5,
  getDateFormatStr4,
  getShowMoney,
  getConfigTimeZoneTimestamp,
} from '../../../../utils/helper';
import Loading3 from '../../../../components/loading3';
import NoData from '../../../../components/noData';
import { get } from 'lodash';

function BetDetails({ setShowVerify, showVerify, id = '', paramEndDate = '' }) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
      >
        <DetailsInner handleClose={() => setShowVerify(false)} id={id} paramEndDate={paramEndDate} />
      </DialogModal>
    </>
  );
}
function DetailsInner({ handleClose, id, paramEndDate }) {
  const { t } = useTranslation();
  const { websiteInitConfig, currency_code, isSportEnabled } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      isSportEnabled: get(state, 'app.isSportEnabled'),
      currency_code: state?.app?.websiteInitConfig?.website_info?.currency_code,
    }),
    shallowEqual,
  );

  const [isShow, setIsShow] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [summeryData, setSummeryData] = useState(null);
  const [finished, setFinished] = useState(false);

  const [params, setParams] = useState({
    uid: id,
    game_type: '',
    search_date: new Date(`${paramEndDate.slice(0, 4)}-${paramEndDate.slice(4, 6)}-${paramEndDate.slice(6)}`),
    page: 1,
    page_size: 10,
  });

  useEffect(() => {
    if (websiteInitConfig) {
      let gameTypeList = websiteInitConfig.game_type;
      if (gameTypeList && gameTypeList.length > 0) {
        let arr = [];
        if (currency_code == 'PHP') {
          arr = gameTypeList;
        } else {
          const filteredArr = gameTypeList.filter((item) => item.id != 6);
          arr = filteredArr;
        }

        if (!isSportEnabled) {
          const _filter = arr.filter((item) => item.id != 999);
          arr = _filter;
        }

        setTypeList([{ id: '' }, ...arr]);
      }
    }
  }, [websiteInitConfig]);

  const getFilterTypeStr = () => {
    let res = typeList.find((item) => {
      return item.id == params.game_type;
    });
    return res ? (res.id == '' ? t('home.all') : t(`refer.proportion_category_${res.id}`)) : '';
  };

  const getData = (page, game_type) => {
    setLoading(true);
    const newParams = {
      page: page ? page : params.page,
      page_size: params.page_size,
      uid: params.uid,
      game_type: game_type ? String(game_type) : game_type == '' ? '' : String(params.game_type),
      search_date: getDateFormatStr5(new Date(moment(params.search_date).format()).getTime()),
    };
    GetRoundDetailByDay(newParams)
      .then((res) => {
        if (res.code == 0) {
          setList(res.data.list);
          setSummeryData(res.data.summery);
          if (res.data.list.length < params.page_size) {
            setFinished(true);
          } else {
            setFinished(false);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadData = async () => {
    try {
      setParams({ ...params, page: params.page + 1 });
      const currentPage = params.page + 1;
      const newParams = {
        page: currentPage,
        page_size: params.page_size,
        uid: params.uid,
        game_type: params.game_type,
        search_date: getDateFormatStr5(new Date(moment(params.search_date).format()).getTime()),
      };
      let res = await GetRoundDetailByDay(newParams);
      if (res.code == 0) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          setList([...list, ...res.data.list]);
        }

        if (res.data && res.data.list && res.data.list.length < params.page_size) {
          setFinished(true);
        } else {
          setFinished(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="bg-[var(--colour-35)] border border-solid border-[var(--colour-6)] shadow-[0_.12rem_.12rem_0_rgba(0,0,0,0.1)] w-[6.7rem] h-[10.1rem] rounded-normal p-[.2rem_.2rem_.96rem_.2rem] relative flex flex-col">
      <div className="absolute left-0 bottom-0 w-full h-[.96rem] rounded-[0rem_0rem_.14rem_.14rem] bg-[var(--colour-35)] pl-[.4rem] pt-[.24rem] box-shadow-directBetting">
        <div className="flex space-x-[.58rem] text-[.2rem] font-[500]">
          <div className="flex space-x-[.1rem] w-[2.32rem] leading-[.22rem] pt-[2px]">
            <div className="text-[var(--colour-44)]">{t('refer.direct_betting_summery1')}</div>
            <div className="text-[var(--colour-38)]">
              {summeryData ? <>{getShowMoney(summeryData.total_wager_amount, 2, 2)}</> : '0.00'}
            </div>
          </div>

          <div>
            <div className="flex items-center space-x-[.1rem] min-h-[.24rem]">
              <div className="text-[var(--colour-44)] min-w-[1.6rem]">{t('refer.direct_betting_summery2')}</div>
              <div className="text-[var(--colour-38)] min-w-[1.5rem] w-[1.5rem] text-wrap leading-[.2rem]">
                {summeryData ? <>{summeryData.total_bet_num}</> : '0'}
              </div>
            </div>

            <div className="flex items-center space-x-[.1rem] w-[2.32rem] min-h-[.24rem]">
              <div className="text-[var(--colour-44)] min-w-[1.6rem]">{t('refer.direct_betting_summery3')}</div>
              <div
                className={`${summeryData && summeryData.total_user_win_amount > 0 ? 'text-[var(--colour-25)]' : 'text-[var(--colour-24)]'}  min-w-[1.5rem]  w-[1.5rem] text-wrap leading-[.2rem]`}
              >
                <span>{summeryData && summeryData.total_user_win_amount > 0 ? '+ ' : ''}</span>
                {summeryData ? <>{getShowMoney(summeryData.total_user_win_amount, 2, 2)}</> : '0.00'}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-[.62rem] h-[.62rem] rounded-[50%] border border-[var(--colour-56)] flex items-center justify-center absolute bottom-[-0.86rem] left-[50%] translate-x-[-50%]"
        onClick={() => handleClose()}
      >
        <Close className="!w-[.62rem] !h-[.62rem] !text-[var(--colour-56)]" />
      </div>
      <div className="text-[.28rem] font-[600] leading-[.34rem] text-[var(--colour-10)] text-center mb-[.24rem]">
        {t('refer.direct_betting_detalhes_da')}
      </div>
      <div className="flex items-center justify-between mb-[.2rem]">
        <div>
          <TippyPopBox
            placement={Placement.bottomStart}
            popChildren={
              <PopChoose
                scrollBarWrapperClassName=""
                className={`min-w-[2.12rem] py-[.32rem] bg-[var(--colour-69)] border border-[var(--colour-6)] shadow-[0_.04rem_.08rem_0_rgba(0,0,0,0.1)]`}
                children={typeList.map((item, index) => (
                  <PopChooseItem
                    className={`text-[.2rem] font-[500] leading-[.24rem] px-[.2rem] ${index != 0 ? 'mt-[.36rem]' : ''} `}
                    key={index}
                    data={item}
                    choose={params.game_type == item.id}
                    chooseClassName="!text-[var(--colour-1)]"
                    onClick={() => {
                      setIsShow(false);
                      setParams({ ...params, page: 1, game_type: item.id });
                      getData(1, item.id);
                    }}
                  >
                    <span className="">
                      {item.id == '' ? t('home.all') : t(`refer.proportion_category_${item.id}`)}
                    </span>
                  </PopChooseItem>
                ))}
              />
            }
            visible={isShow}
            onClickOutside={() => {
              setIsShow(false);
            }}
            offset={[0, 10]}
          >
            <div
              className={cn(
                'pl-[.2rem] pr-[.16rem] h-[.5rem] bg-[var(--colour-35)] border rounded-[19.98rem] flex items-center',
                {
                  'border-[var(--colour-1)]': isShow,
                  'border-[var(--colour-6)]': !isShow,
                },
              )}
              onClick={() => {
                setIsShow(isShow ? false : true);
              }}
            >
              <div className="text-[.2rem] font-[400] leading-[.24rem] text-[var(--colour-44)] mr-[.7rem]">
                {getFilterTypeStr()}
              </div>
              <div
                className={cn('w-[.14rem] h-[.24rem] text-[var(--colour-11)]', {
                  'rotate-[-90deg]': isShow,
                  'rotate-[90deg]': !isShow,
                })}
              >
                <Icon name={IconName.BannerNext2} />
              </div>
            </div>
          </TippyPopBox>
        </div>

        <DatetimePickerBox
          date={params.search_date}
          rangeCheckClassName="!w-[2.1rem] bg-[var(--colour-5)]"
          onSubmit={() => {
            setParams({ ...params, page: 1 });
            getData(1);
          }}
          onChange={(e) => {
            setParams({ ...params, search_date: e });
          }}
          mode={'single'}
        />
      </div>
      <div className="flex-1 overflow-y-auto scrollbar-none">
        {loading ? (
          <Loading3 className1="w-full h-full flex items-center justify-center"></Loading3>
        ) : (
          <>
            {list && list.length > 0 ? (
              <List finished={finished} onLoad={loadData} offset={0} loadingText={<Loading3 className1="w-full" />}>
                {list.map((item, index) => {
                  return <DetailsItem key={index} showBg={index % 2 == 0} data={item} />;
                })}
              </List>
            ) : (
              <NoData className="w-full h-full" />
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default BetDetails;

const DetailsItem = ({ showBg = false, data }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn('p-[.24rem_.2rem_.2rem_.2rem] text-[.2rem] font-[500] leading-[.24rem] rounded-normal relative', {
        'bg-[var(--colour-68)]': showBg,
      })}
    >
      <div className="flex items-center mb-[.08rem]">
        <div className="w-[2.7rem] shrink-0 mr-[.2rem] flex items-center">
          <div className="text-[var(--colour-38)] mr-[.2rem]">{data?.uid || ''}</div>
          <div
            className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]"
            onClick={() => {
              copy(`${data?.uid || ''}`);
              toast.success(t('home.toast006'), {
                containerId: 'global',
              });
            }}
          >
            <Icon name={IconName.Copy4} />
          </div>
        </div>
        <div className="text-[var(--colour-38)]">
          {getConfigTimeZoneTimestamp((data?.bet_timestamp || 0) * 1000).format('DD/MM/YYYY HH:mm:ss')}
        </div>
      </div>
      <div className="flex mb-[.1rem]">
        <div className="w-[2.7rem] shrink-0 mr-[.2rem] flex flex-wrap">
          <div className="text-[var(--colour-44)]">{t('refer.direct_betting_plataforma_de_jogo')}</div>
          <div className="text-[var(--colour-38)]">{data?.provider_game_type || ''}</div>
        </div>
        <div className="flex items-center flex-wrap">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_betting_nome_do_jogo')}</div>
          <div className="text-[var(--colour-38)]">{data?.game_name || ''}</div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-[2.7rem] shrink-0 mr-[.2rem] flex items-center">
          <div className="text-[var(--colour-44)] mr-[.04rem]">{t('refer.direct_data_apostas_validas')}</div>
          <div className="text-[var(--colour-38)]">{getShowMoney(data?.wager_amount || 0, 2, 2)}</div>
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_betting_V/D_dos_membros')}</div>
          {Number(data?.user_win_amount) > 0 ? (
            <div className="text-[var(--colour-25)]">+{getShowMoney(data?.user_win_amount || 0, 2, 2)}</div>
          ) : (
            <div className="text-[var(--colour-24)]">{getShowMoney(data?.user_win_amount || 0, 2, 2)}</div>
          )}
        </div>
      </div>
      <div className="absolute top-[0rem] left-[0rem] bg-[var(--colour-25)] rounded-[.04rem_0rem] px-[.06rem] text-[.16rem] font-[600] leading-[.2rem] text-[var(--colour-2)]">
        V{data?.vip_level || 0}
      </div>
    </div>
  );
};
