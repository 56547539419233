import Icon from '../../../../../icon';

const LocalSideIcon = ({ isShowSvg = true, svgIconName, imgSrc = '' }) => {
  return isShowSvg ? (
    <div className="w-[.4rem] h-[.4rem] text-[var(--colour-14)]">
      <Icon name={svgIconName} />
    </div>
  ) : (
    <div className="w-[.4rem] h-[.4rem]">
      <img src={imgSrc} className="w-full" />
    </div>
  );
};

export default LocalSideIcon;
