import cn from 'classnames';
import Search from '../../search';
import Language from '../../language';
import LogoBox from '../../../logoBox';
import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';
import { getShowMoney } from '../../../../utils/helper';
import HeaderSidebarSwitch from '../../headerSidebarSwitch';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import useRouterHelper from '../../../../hooks/useRouterHelper';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setDrawerMenuVisible } from '../../../../redux/reducers/appSlice';
import { langsConfig } from '../../../../constants/langs';

export default function DTemplate003() {
  const dispatch = useDispatch();
  const { userObj, drawerMenuVisible } = useSelector(
    (state) => ({
      userObj: state.user.userObj,
      drawerMenuVisible: state.app.drawerMenuVisible,
    }),
    shallowEqual,
  );

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  return (
    <>
      <div className={cn('flex flex-col items-center header-box transition-[top]')}>
        <div
          className={`w-full flex flex-row items-center relative bg-[var(--colour-9)] h-[var(--header-box-height)] px-[.32rem]`}
        >
          <HeaderSidebarSwitch className="mr-[.32rem] text-[var(--colour-2)]" />

          <LogoBox className="!h-[.82rem]" isShowFirstLogo={true} />

          {userObj == null ? (
            <Unauthenticated handleLinkClick={handleLinkClick} />
          ) : (
            <Authenticated userObj={userObj} handleLinkClick={handleLinkClick} />
          )}
        </div>
      </div>
    </>
  );
}

const Authenticated = ({ userObj = null, handleLinkClick = () => {} }) => {
  const { handleClickWallet } = useRouterHelper();

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
    }),
    shallowEqual,
  );

  return (
    <div className={`flex items-center flex-1 justify-end`}>
      <div className="text-[var(--colour-23)] text-[.24rem] font-[500]">
        <span className="mr-[.04rem]">{currency_symbol}</span>
        <span>{getShowMoney(userObj?.balance, 2, 2)}</span>
      </div>

      <div
        className="w-[.74rem] h-[.5rem] colour-80 ml-[.12rem] rounded-small flex items-center justify-center active:scale-[.95] transition-all select-none cursor-pointer"
        onClick={() => {
          handleClickWallet();
        }}
      >
        <div className="w-[.28rem] h-[.28rem] text-[var(--colour-2)]">
          <Icon name={IconName.HaderWallet}></Icon>
        </div>
      </div>

      <Search className="!w-[.4rem] !h-[.4rem] !ml-[.2rem] !text-[var(--colour-2)] cursor-pointer" />

      {langsConfig.supportLangList.length > 1 ? (
        <Language className="ml-[.2rem]" handleLinkClick={handleLinkClick} />
      ) : null}
    </div>
  );
};

const Unauthenticated = ({ handleLinkClick = () => {} }) => {
  const { t } = useTranslation();
  const { handleGoLogin, handleGoRegister } = useHandleLogic();

  return (
    <div className="flex items-center flex-1 justify-end ml-[.24rem]">
      <div
        className="uppercase w-[1.02rem] h-[.46rem] colour-79 rounded-[.12rem]  text-[.23rem] text-[var(--colour-2)] font-[700] flex items-center justify-center cursor-pointer active:scale-[.95] transition-all select-none login_text"
        onClick={() => {
          handleGoLogin();
          handleLinkClick();
        }}
      >
        {t('login-signup.login_t003')}
      </div>

      <div
        className="uppercase w-[1.24rem] h-[.46rem] colour-80 rounded-[.12rem] text-[.226rem] text-[var(--colour-2)] font-[700] flex items-center justify-center cursor-pointer ml-[.2rem] active:scale-[.95] transition-all select-none register_text"
        onClick={() => {
          handleGoRegister();
          handleLinkClick();
        }}
      >
        {t('login-signup.register_t003')}
      </div>

      <Search className="!w-[.4rem] !h-[.4rem] !ml-[.2rem] !text-[var(--colour-2)] cursor-pointer" />

      {langsConfig.supportLangList.length > 1 ? (
        <Language className="ml-[.2rem]" handleLinkClick={handleLinkClick} />
      ) : null}
    </div>
  );
};
