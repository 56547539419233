import { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import LimitClickWrapper from '../../components/limitClickWrapper';
import { setUserObj } from '../../redux/reducers/userSlice';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import Button from '../../components/button';
import Icon, { IconName } from '../../components/icon';
import QRCode from '../../components/qrCode';
import Close from '../close';
import { GetGoogleSecretReq, BindGoogleSecretReq } from '../../api/index';
import { useTranslation } from 'react-i18next';
import AuthCode from 'react-auth-code-input';
import { get } from 'lodash';
import cn from 'classnames';

const VerifyCode = ({ handleCodeChange = () => {}, handleSubmit = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div className="items-center">
      <div className="">
        <AuthCode
          autoFocus={false}
          placeholder="0"
          containerClassName="flex flex-row items-center justify-between "
          inputClassName={`w-[.88rem] h-[1.2rem] leading-[1.2rem] text-center border-0 border-b-[.08rem] font-[600] text-[1rem] authCode-input`}
          onChange={handleCodeChange}
          allowedCharacters="numeric"
        />
      </div>
      <LimitClickWrapper
        className="flex flex-row items-center cursor-pointer justify-center w-full active:scale-[.95] transition-all select-none mt-[.32rem] h-[.96rem] text-[.32rem] font-[700] bg-[var(--colour-1)] rounded-normal text-[var(--colour-2)]"
        onClick={handleSubmit}
      >
        <div className="font-semibold">{t('login-signup.submit')}</div>
      </LimitClickWrapper>
    </div>
  );
};

const SecretKey = ({ qrCodeValue, authKey }) => {
  const { t } = useTranslation();

  const {} = useSelector((state) => ({}), shallowEqual);

  const handleCopy = () => {
    copy(authKey);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  return (
    <div className="flex flex-row justify-center items-center space-x-[.28rem]">
      <div className="bg-[white] shrink-0 w-[2rem] h-[2rem] p-[.1rem] flex items-center jusitfy-center">
        <QRCode value={qrCodeValue} />
      </div>
      <div className="">
        <div className="bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-normal flex flex-row items-center justify-center h-[.64rem] space-x-[.2rem]">
          <div className="text-[var(--colour-44)] font-[600] truncate max-w-[2.4rem] text-[.24rem]">{authKey}</div>
          <span className="cursor-pointer text-[var(--colour-44)] w-[.38rem] h-[.38rem]" onClick={handleCopy}>
            <Icon name={IconName.Copy} />
          </span>
        </div>
        <div className="text-[var(--colour-14)] text-left mt-[.2rem] text-[.24rem]">{t('account.2fa_desc3')}</div>
      </div>
    </div>
  );
};

const DownloadButtons = ({ handleDownloadIOS = () => {}, handleDownloadAndroid = () => {} }) => {
  return (
    <div className="flex flex-row justify-center items-center space-x-[.28rem]">
      <Button
        className="flex flex-row items-center justify-center w-[2.48rem] h-[.66rem] rounded-[.18rem]"
        onClick={handleDownloadIOS}
      >
        <span className="">
          <img src="/static/img/Newappstoroe.png" alt="" />
        </span>
      </Button>
      <Button
        className="flex flex-row items-center justify-center w-[2.48rem] h-[.66rem] rounded-[.18rem]"
        onClick={handleDownloadAndroid}
      >
        <span className="">
          <img src="/static/img/Newandroid.png" alt="" />
        </span>
      </Button>
    </div>
  );
};

const StepBox = ({ text, text1, text2, content, className = '' }) => {
  return (
    <div className={className}>
      <div className="leading-[110%] text-[var(--colour-1)] text-[.24rem] font-[600]">{text}</div>
      <div className="text-[var(--colour-49)] font-[500] leading-[110%] mt-[.08rem] text-[.24rem]">{text1}</div>
      {text2 && (
        <div className="font-[400] leading-[110%] mt-[.16rem] text-[.28rem] text-[var(--colour-14)]">{text2}</div>
      )}
      <div className="mt-[.2rem]">{content}</div>
    </div>
  );
};

export default function GoogleAuthenticator({ handleClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userObj } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
    }),
    shallowEqual,
  );

  const [qrCodeValue, setQrCodeValue] = useState('');
  const [formData, setFormData] = useState({
    google_auth_key: '',
    code: '',
  });

  useEffect(() => {
    GetGoogleSecretReq().then((res) => {
      if (res.code == 0) {
        setFormData({ ...formData, google_auth_key: res.result.invite_code });
      }
    });
  }, []);

  useEffect(() => {
    setQrCodeValue(`otpauth://totp/${userObj.uid}?secret=${formData.google_auth_key}&issuer=${location.origin}`);
  }, [formData.google_auth_key]);

  const handleSubmit = () => {
    BindGoogleSecretReq(formData).then((res) => {
      if (res.code == 0) {
        toast.success(t('home.toast003'), {
          containerId: 'global',
        });
        setFormData({ ...formData, code: '' });
        res?.result?.user_obj && dispatch(setUserObj(res.result.user_obj));
        handleClose();
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const downloadAndroid = () => {
    window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2');
  };
  const downloadIOS = () => {
    window.open('https://apps.apple.com/cn/app/google-authenticator/id388497605');
  };

  const handleCodeChange = (codeStr) => {
    setFormData({ ...formData, code: codeStr });
  };

  return (
    <div className={cn('rounded-normal relative w-[6.86rem] h-[12.8rem] bg-[var(--colour-35)]')}>
      <Close
        onClick={handleClose}
        className="bg-[var(--colour-45)] rounded-normal absolute top-[.2rem] right-[.2rem]"
      />
      <div className="px-[.32rem]">
        <div className="flex items-center h-[1.12rem] text-[.32rem] font-[700] text-[var(--colour-48)]">
          {t('account.2fa_enable')}
        </div>
        <div className="h-[1px] bg-[var(--colour-6)]"></div>

        <StepBox
          className="mt-[.36rem]"
          text={t('account.2fa_step1')}
          text1={t('account.2fa_title1')}
          text2={t('account.2fa_desc1')}
          content={<DownloadButtons handleDownloadAndroid={downloadAndroid} handleDownloadIOS={downloadIOS} />}
        />

        <StepBox
          className="mt-[.36rem]"
          text={t('account.2fa_step2')}
          text1={t('account.2fa_title2')}
          text2={t('account.2fa_desc2')}
          content={<SecretKey qrCodeValue={qrCodeValue} authKey={formData.google_auth_key} />}
        />

        <StepBox
          className="mt-[.36rem]"
          text={t('account.2fa_step3')}
          text1={t('account.2fa_title3')}
          content={<VerifyCode code={formData.code} handleCodeChange={handleCodeChange} handleSubmit={handleSubmit} />}
        />
      </div>
    </div>
  );
}
