import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Countdown, { zeroPad } from 'react-countdown';
// import useActivityStatus from '../../hooks/useActivityStatus';

const TwoNumber = ({ item = '', className2, index, length }) => {
  const { t } = useTranslation();
  const _txtClass =
    'min-w-[.32rem] min-h-[.48rem] rounded-[.08rem] flex items-center justify-center gap-[.04rem] text-[#fff]';

  function removeLeadingZero(str) {
    str = String(str);
    return str.replace(/^0+/, '');
  }

  const SplitTime = (time) => {
    if (time.length === 1) {
      return (
        <div key={`${item.text}-single`} className={`${_txtClass} pilotWheelTime_text1`}>
          {time}
        </div>
      );
    } else {
      const arr = time.split('');
      return (
        arr &&
        arr.map((item, idx) => {
          const uniqueKey = `${item}-${idx}`;
          return (
            <Fragment key={uniqueKey}>
              <div className={`${_txtClass} pilotWheelTime_text1`}>{item}</div>
            </Fragment>
          );
        })
      );
    }
  };

  return (
    <>
      <div className={`${className2} items-center gap-[.04rem]`}>
        {item.text == 'Days' ? (
          <>
            <div className="w-[1.6rem] h-[.48rem] rounded-[.08rem] pilotWheelTime_text1 text-[.32rem] font-[700] flex items-center justify-center">
              {Number(removeLeadingZero(item.time) >= 99 ? '99' : removeLeadingZero(item.time))} {t('guideWheel.day')}
            </div>
          </>
        ) : (
          <>
            {SplitTime(item.time)}
            <div className="mr-[.04rem]">{index < length - 1 ? ':' : ''}</div>
          </>
        )}
      </div>
    </>
  );
};

const TimeCountdown = ({ date = Date.now(), className = '', className2 = '', onComplete = () => {} }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let list = [];

    if (completed) {
      list = ['00', '00', '00', '00'];
    } else {
      const daysItem = {
        text: 'Days',
        time: zeroPad(days, 2),
      };

      if (parseInt(daysItem.time) >= 1) {
        return (
          <div className={className}>
            <TwoNumber item={daysItem} className2={className2} />
          </div>
        );
      } else {
        const list = [
          {
            text: 'Hours',
            time: zeroPad(hours, 2),
          },
          {
            text: 'MINUTES',
            time: zeroPad(minutes, 2),
          },
          {
            text: 'SECONDS',
            time: zeroPad(seconds, 2),
          },
        ];

        return list.map((item, index) => (
          <TwoNumber key={index} item={item} index={index} length={list.length} className2={className2} />
        ));
      }
    }
  };

  return (
    <>
      <div className={`flex items-center justify-center select-none ${className}`}>
        <Countdown date={date} onComplete={onComplete} renderer={renderer} />
      </div>
    </>
  );
};

const expirationTime = ({ startTime = Date.now(), endTime = '', onComplete = () => {} }) => {
  // const { InitActivityStatus } = useActivityStatus();

  const getCountdown = () => {
    const now = Date.now();
    const start = startTime;
    const end = endTime * 1000;

    if (now < start) {
      return {
        date: start,
      };
    } else if (now >= start && now < end) {
      return {
        date: end,
      };
    } else {
      return {
        date: Date.now(),
      };
    }
  };

  const getOnComplete = () => {
    const now = Date.now();
    const end = endTime * 1000;

    if (now >= end) {
      return () => {};
    } else {
      return () => {
        onComplete && onComplete();
      };
    }
  };

  return (
    <>
      {!endTime ? (
        <></>
      ) : (
        <TimeCountdown
          date={getCountdown().date}
          className="text-[.32rem] font-[700]"
          className2="flex"
          onComplete={getOnComplete()}
        />
      )}
    </>
  );
};
export default expirationTime;
