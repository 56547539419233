import { useState } from 'react';

import Input from '../../../../input';

export default function InputHighLight({
  type = 'text',
  value = '',
  onChange = () => {},
  placeholder = '',
  className = '',
}) {
  const [isFocused, setIsFoucsed] = useState(false);

  return (
    <>
      <div className={`border-b border-[var(--colour-6)] relative ${className}`}>
        <Input
          type={type}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          innerClassName="!h-[.86rem] !border-transparent !px-[0px]"
          topClassName="bg-transparent overflow-hidden border border-transparent"
          inputClassName="!text-[.24rem] !font-[600] !text-[var(--colour-2)] placeholder:!text-[var(--colour-41)]"
          onFocus={() => {
            setIsFoucsed(true);
          }}
          onBlur={() => {
            setIsFoucsed(false);
          }}
        />
        <div
          className={`absolute bottom-[0px] w-full h-[.04rem] bg-[var(--colour-67)] ${isFocused ? 'scale-[1]' : 'scale-[0]'}`}
          style={{ transition: 'transform 200ms, -webkit-transform 200ms' }}
        ></div>
      </div>
    </>
  );
}
