import { get } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import GameItem from '../../../../../components/gameItem';
import NoData from '../../../../../components/noData';
import { useSelector, shallowEqual } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';
import Loading from '../../../../../components/loading2';
import { GetRecentListReq } from '../../../../../api';
import LoadingMoreWithText from '../../../../../components/loadingMoreWithText';
import { isLogin } from '../../../../../utils/helper';

const RecentGame = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useStateIfMounted(true);
  const [reRequest, setreRequest] = useStateIfMounted('');
  const pageSize = 12;
  const [visibleItems, setVisibleItems] = useState(pageSize);

  const { uid } = useSelector(
    (state) => ({
      uid: get(state, 'user.userObj.uid', ''),
    }),
    shallowEqual,
  );

  const handleFavoriteCallback = () => {
    setreRequest(new Date().getTime());
  };

  const getRecentList = async () => {
    try {
      const res = await GetRecentListReq(uid);
      const result = res.result;
      setList(result.list);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin()) {
      getRecentList();
    } else {
      setLoading(false);
    }
  }, []);

  const displayedItems = useMemo(() => {
    return list.slice(0, visibleItems);
  }, [list, visibleItems]);

  const handleLoadMore = async () => {
    setVisibleItems((prev) => prev + pageSize);
  };

  const hasMore = visibleItems < list.length;

  return (
    <>
      {loading ? (
        <Loading className="h-[8rem]" />
      ) : (
        <>
          {displayedItems && displayedItems.length > 0 ? (
            <div className="games__row gap-[.16rem] pb-[.3rem]">
              {displayedItems &&
                displayedItems.length > 0 &&
                displayedItems.map((item) => (
                  <GameItem model={item} key={item.id} handleFavoriteCallback={handleFavoriteCallback} />
                ))}
            </div>
          ) : (
            <NoData className="py-[3rem]" />
          )}
        </>
      )}

      {hasMore ? (
        <LoadingMoreWithText
          className={`my-[.4rem]`}
          currentNum={displayedItems.length}
          total={list.length}
          handleLoadMore={handleLoadMore}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default RecentGame;
