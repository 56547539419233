import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';

const Network = () => {
  const { t } = useTranslation();

  return (
    <div className="h-[.58rem] px-[.14rem] mb-[.2rem] flex items-center">
      <div className="mr-[.16rem]">
        <div className="m-auto w-[.26rem] h-[.3rem] mb-[.04rem]">
          <Icon name={IconName.DrawerMenuNetwork} />
        </div>
        <div className="text-[.2rem] leading-[.24rem] font-[700] text-[var(--colour-29)]">21MS</div>
      </div>
      <div className="text-[var(--colour-14)] text-[.2rem] font-[500]">{t('home.drawer_linha')}</div>
    </div>
  );
};

export default Network;
