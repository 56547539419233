import { useTranslation } from 'react-i18next';

const ClaimBonusResult = ({ content = '', handleClose = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div className="w-[6.54rem] h-[5.06rem] rounded-[.4rem] bg-[#fff] relative">
      <div
        className="h-[1.94rem] rounded-t-[.4rem] relative"
        style={{
          background: 'linear-gradient(270deg, #C290FF 0%, #77D7FF 100%)',
        }}
      ></div>

      <img src="/static/img/claimBonusResultBg.png" className="w-[6.54rem] absolute left-[0rem] top-[-.28rem]" />

      <div className="absolute left-[0] right-[0] top-[2.3rem] bg-[transparent] px-[.28rem] flex flex-col items-center">
        <div className={`text-[var(--colour-33)] text-center`}>{content}</div>
        <div
          className="mt-[.58rem] w-[2.12rem] h-[.68rem] bg-[var(--colour-26)] rounded-[6px] flex items-center justify-center text-center leading-[1] text-[.24rem] font-[600] text-[var(--colour-2)] cursor-pointer"
          onClick={handleClose}
        >
          {t('promo.confirm')}
        </div>
      </div>
    </div>
  );
};

export default ClaimBonusResult;
