import { useState, useEffect } from 'react';
import { langsConfig } from '../../../../constants/langs';
import { useSelector, shallowEqual } from 'react-redux';
import { langIconName } from '../../../../utils/helper';
import { changeLang } from '../../../../utils/localePath';
import Icon, { IconName } from '../../../icon';
import TippyPopBox, { Placement } from '../../../tippyPopBox';
import LimitClickWrapper from '../../../limitClickWrapper';
import { Popover } from 'react-vant';
import useOnclickOutside from 'react-cool-onclickoutside';

const LayoutMobile = ({ langName, defaultLang, handleLinkClick }) => {
  const [languageFlag, setLanguageFlag] = useState(false); //语言列表是否展开
  const [headerRect, setHeaderRect] = useState(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      setHeaderRect(entry.contentRect);
    });
    if (document.querySelector('.header-box')) {
      resizeObserver.observe(document.querySelector('.header-box'));
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleClickLangButton = () => {
    setLanguageFlag(!languageFlag);
  };

  const onClickLang = (item) => {
    setLanguageFlag(false);
    changeLang(item.key);
  };

  const tipRef = useOnclickOutside(() => {
    setLanguageFlag(false);
  });

  return (
    <Popover
      visible={languageFlag}
      teleport={document.body}
      placement="right"
      className="language_popover"
      reference={
        <div ref={tipRef}>
          <LimitClickWrapper
            className="px-[.14rem] mb-[.2rem] flex flex-row items-center justify-between cursor-pointer"
            onClick={handleClickLangButton}
            limitTime={0.3}
          >
            <div className={`flex items-center delay_bg`}>
              <div className="w-[.4rem] h-[.4rem] mr-[.08rem] block">
                <Icon name={langIconName(defaultLang)} />
              </div>
              <div className={`text-[.2rem] font-[500] text-[var(--colour-14)]`}>{langName}</div>
            </div>
            <span
              className={`w-[.14rem] h-[.2rem] transition-chat ${
                languageFlag ? 'text-[var(--colour-46)] rotate-[0deg]' : 'rotate-[90deg] text-[var(--colour-11)]'
              }`}
            >
              <Icon name={IconName.RightArrow} layout="layout-3" />
            </span>
          </LimitClickWrapper>
        </div>
      }
    >
      <div
        ref={tipRef}
        className={`w-[3.2rem] rounded-normal overflow-hidden cursor-default bg-[var(--colour-28)] border border-[var(--colour-6)]`}
      >
        <div className="w-[3.2rem] px-[.14rem] mb-[.14rem]">
          {langsConfig.supportLangList.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex items-center space-x-[.22rem] pl-[.18rem] h-[1.14rem] cursor-pointer ${
                  item.key === defaultLang
                    ? ''
                    : 'hover_text_color_transtion  text-[var(--colour-14)] hover:text-[var(--colour-48)]'
                } ${index !== 0 ? 'border-t border-solid border-[var(--colour-6)]' : ''}`}
                onClick={() => {
                  onClickLang(item);
                  setLanguageFlag(false);
                  handleLinkClick();
                }}
              >
                <div className="w-[.44rem] h-[.44rem]">{<Icon name={langIconName(item.key)} />}</div>
                <div
                  className={`text-[.28rem] font-[500] font-interMedium ${item.key === defaultLang ? 'text-[var(--colour-1)]' : ''}`}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Popover>
  );
};

const Language = ({ onlyIcon = false, handleLinkClick }) => {
  const { defaultLang } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );
  const [langName, setLangName] = useState(''); //选中语言的name
  useEffect(() => {
    const langs = langsConfig.supportLangList;
    const found = langs.find((item) => item.key === defaultLang);
    setLangName(found ? found.name : langs.find((item) => item.key == langsConfig.initLangKey)?.name);
  }, [defaultLang]);

  return (
    <LayoutMobile langName={langName} defaultLang={defaultLang} onlyIcon={onlyIcon} handleLinkClick={handleLinkClick} />
  );
};

export default Language;
