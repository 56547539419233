import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Icon, { IconName } from './icon';

const Close = ({ onClick, className = '' }) => {
  const dispatch = useDispatch();

  return (
    <div onClick={onClick} className={`cursor-pointer w-[.68rem] h-[.68rem] text-[var(--colour-46)] ${className}`}>
      <Icon name={IconName.Close} />
    </div>
  );
};

export default Close;
