import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../../components/icon';
import cn from 'classnames';
import Close from '../../../../../components/close';
import { useEffect, useState } from 'react';
import Loading3 from '../../../../../components/loading3';
import ScrollWrapper from '../../../../../components/scrollWrapper';
import GameItem from '../../../../../components/gameItem';
import useGameInfo from '../../../../../hooks/useGameInfo';
import LoadingMoreWithText from '../../../../../components/loadingMoreWithText';
import NoData from '../../../../../components/noData';
import { GetActivityGamesReq } from '../../../../../api';

const Header = ({ title, handleClose, showBack = false, handleBack, showQuestion = false, handleShowQuestion }) => {
  return (
    <>
      <div className="flex flex-row items-center justify-between py-[.2rem]">
        <div className="flex flex-row items-center justify-start space-x-[.26rem]">
          {showBack ? (
            <span className="w-[.2rem] h-[.28rem] cursor-pointer text-[var(--colour-11)]" onClick={handleBack}>
              <Icon name={IconName.NewBack}></Icon>
            </span>
          ) : (
            <></>
          )}
          <span className={cn('font-[700] text-[.32rem] text-[var(--colour-48)]')}>{title}</span>
          {showQuestion ? (
            <span
              className="w-[.4rem] h-[.4rem] cursor-pointer active:scale-[.95] transition-all select-none text-[var(--colour-7)]"
              onClick={handleShowQuestion}
            >
              <Icon name={IconName.WidthdrawQuestion2} />
            </span>
          ) : (
            <></>
          )}
        </div>
        {true && (
          <div className="active:scale-[.95] transition-all select-none w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
            <Close onClick={handleClose} />
          </div>
        )}
      </div>
      <div className="w-full bg-[var(--colour-6)] bg-opacity-20 h-[1px] mb-[.22rem]"></div>
    </>
  );
};

const MysteryBonusGameList = ({ handleClose, handleBack, activity_id }) => {
  const { t } = useTranslation();

  const { ConfirmCloseGame } = useGameInfo();
  const pageSize = 12;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isShowLoadMore, setIsShowLoadMore] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    activity_id: activity_id,
    page: 1,
    page_size: pageSize,
  });

  useEffect(() => {
    if (activity_id) {
      if (params.page == 1) {
        setIsLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      GetActivityGamesReq(params).then((res) => {
        if (params.page == 1) {
          setIsLoading(false);
        } else {
          setIsLoadingMore(false);
        }

        if (res.code == 0) {
          const result = res.result;
          if (params.page == 1) {
            setList(result.list);
          } else {
            const list2 = list.concat(result.list);
            setList(list2);
          }
          setTotal(result.pages.count);
          const _pageCount = Math.ceil(result.pages.count / pageSize);
          if (params.page < _pageCount) {
            setIsShowLoadMore(true);
          } else {
            setIsShowLoadMore(false);
          }
        }
      });
    }
  }, [params]);

  const handleGameItemClick = () => {
    handleClose && handleClose();
    ConfirmCloseGame();
  };

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  return (
    <div className="bg-[var(--colour-35)] rounded-normal p-[0_.16rem_.64rem_.16rem] w-[6.86rem]">
      <Header
        handleBack={handleBack}
        handleClose={handleClose}
        showBack={true}
        title={t('giftMoney.gamesToUnlockBonuses')}
      />

      {isLoading ? (
        <Loading3 className1="h-[7.6rem]" />
      ) : (
        <>
          {list && list.length > 0 ? (
            <ScrollWrapper
              mr={true}
              className={cn('w-full flex flex-col rounded-normal p-[.2rem_.12rem]', {
                'h-[7.6rem]': true,
              })}
            >
              <div className="gamess__row_3">
                {list.map((item, index) => (
                  <GameItem model={item} key={item.id} onClick={handleGameItemClick} />
                ))}
              </div>
              {isShowLoadMore && (
                <LoadingMoreWithText
                  className={`my-[.4rem]`}
                  currentNum={list.length}
                  total={total}
                  handleLoadMore={handleLoadMore}
                  showLoading={isLoadingMore && params.page > 1}
                />
              )}
            </ScrollWrapper>
          ) : (
            <NoData className="py-[2rem]" />
          )}
        </>
      )}
    </div>
  );
};

export default MysteryBonusGameList;
