import { isControllableSite, getTemplateId } from '../../utils/helper';
import DTemplate001 from './design/template001';
import DTemplate003 from './design/template003';
import NTemplate001 from './normal/template001';

const RenderTemplte = () => {
  return isControllableSite() ? (
    <>{getTemplateId() == 'd-template003' ? <DTemplate003 /> : <DTemplate001 />}</>
  ) : (
    <NTemplate001 />
  );
};

export default function () {
  return <RenderTemplte />;
}
