import { shallowEqual, useSelector } from 'react-redux';
import useActivityStatus from '../../../../hooks/useActivityStatus';
import { useTranslation } from 'react-i18next';
import Tips from '../tips';
import layout3GiftImg from '../../../../assets/layout3-img/gift.png';
import Badge from '../../../../views/mine/normal/template001/components/badge';

const Layout5 = ({ onlyIcon, handleEntranceOnClick }) => {
  const { t } = useTranslation();

  const { promotionClaimedCount } = useSelector(
    (state) => ({
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );

  return onlyIcon ? (
    <>
      <Tips anchorId="overEvent">
        <div className="uppercase text-[var(--colour-48)]">
          {t('task.event')} {t('task.bonus')}
        </div>
      </Tips>
      <div
        onClick={handleEntranceOnClick}
        id="overEvent"
        style={{ background: 'linear-gradient(180deg, #55FAE3 0%, #7099F5 50.04%, #693CEC 101.1%)' }}
        className={`w-[.8rem] h-[.8rem] mb-[.16rem] rounded-normal cursor-pointer flex justify-center items-center relative`}
      >
        <img src={layout3GiftImg} className="not-drag w-[.6rem]" />
        {(promotionClaimedCount?.events || 0) > 0 ? (
          <Badge
            textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
            className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.15rem] !right-[-.04rem] flex items-center"
            text={promotionClaimedCount?.events || 0}
          ></Badge>
        ) : null}
      </div>
    </>
  ) : (
    <div
      onClick={handleEntranceOnClick}
      className="active:scale-[.95] transition-all w-[1.26rem] h-[.82rem] mb-[.08rem] rounded-normal cursor-pointer bg-[url(../assets/img/drawerMenuEventsBg.png)] bg-no-repeat bg-[length:100%_100%] p-[.08rem] relative"
    >
      <div className="font-[600] text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow">
        <div>{t('home.event')}</div>
      </div>
      {(promotionClaimedCount?.events || 0) > 0 ? (
        <Badge
          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
          className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.15rem] !right-[-0.04rem] flex items-center"
          text={promotionClaimedCount?.events || 0}
        ></Badge>
      ) : null}
    </div>
  );
};

const EventBonus = ({ onlyIcon = false, handleLinkClick = () => {} }) => {
  const { handleEntranceOnClick, isShowEntrance } = useActivityStatus();

  const clickEvent = () => {
    handleEntranceOnClick();
    handleLinkClick();
  };

  return isShowEntrance ? <Layout5 onlyIcon={onlyIcon} handleEntranceOnClick={clickEvent} /> : <></>;
};

export default EventBonus;
