import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../utils/localePath';
import { removeLoadingPage } from '../../utils/helper';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const Maintain = () => {
  const navigate = useGlobalNavigate();
  const { maintainInfo, isMaintain } = useSelector(
    (state) => ({
      maintainInfo: state.app.maintainInfo,
      isMaintain: state.app.isMaintain,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!isMaintain) {
      navigate(toLocalePath('/'));
    } else {
      removeLoadingPage();
    }
  }, [isMaintain]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="px-[.08rem]">
        <img src={'/static/img/maintain.png'} />
      </div>

      {maintainInfo && maintainInfo.website_flag_text && (
        <div
          className="font-[400] max-w-[14rem] text-center mt-[.4rem] text-[.36rem] px-[.3rem] leading-[1]"
          dangerouslySetInnerHTML={{ __html: maintainInfo.website_flag_text }}
        ></div>
      )}
    </div>
  );
};

export default Maintain;
