import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DialogModal from '../dialogModal/index';
import Icon, { IconName } from '../icon';
import Button from '../button';
import ScrollWrapper from '../scrollWrapper';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../redux/reducers/appSlice';
import { getShowMoney } from '../../utils/helper';
import { useTranslation, Trans } from 'react-i18next';
import Close from '../close';
import cn from 'classnames';
import Countdown from 'react-countdown';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import LimitClickWrapper from '../limitClickWrapper';

const TimeSpan = ({ part1 = '00', part2 = 'D' }) => {
  return (
    <div className="  bg-[url(../assets/img/welcome-bonus/countdownItem.png)] bg-no-repeat bg-[length:100%_100%] relative w-[.5rem] h-[.5rem]">
      {part1}
      <div className="absolute  w-full font-[400]   text-[var(--colour-48)] text-[.24rem] leading-[.3rem] top-[.58rem]">
        {part2}
      </div>
    </div>
  );
};

export default function WelcomeTips({ style = 2, money, endTime, handleClose = () => {} }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const close = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_CloseButton);
    handleClose();
  };

  const handleClaim = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.WelcomeBonus,
      }),
    );
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_ClaimButton);
  };

  return (
    <div className={cn('relative   w-[7.5rem] h-[10.68rem] bg-[var(--colour-35)]  pt-[.4rem]')}>
      <div className="  m-auto bg-[url(../assets/img/welcome-bonus/welcomeBonusTitleBg.png)] bg-no-repeat bg-[length:100%_100%] w-[5.48rem] h-[1.32rem]">
        <div className="font-[600]   text-[var(--colour-43)] text-center  text-[.4rem] pt-[.2rem] leading-[.7rem]">
          {t('welcomeBonus.rewards')}
        </div>
      </div>
      <div className=" m-auto font-[600]   text-[var(--colour-26)] text-center  w-[4.1rem] text-[.24rem] leading-[.3rem] mt-[-0.08rem] mb-[.06rem]">
        {t('welcomeBonus.registerTips')}
      </div>
      <div className="flex items-center justify-center bg-[var(--colour-34)]    rounded-[.16rem] mx-[.3rem] p-[.2rem_0_.48rem]">
        <span className="   w-[.4rem] h-[.4rem] mr-[.1rem] text-[var(--colour-48)]">
          <Icon name={IconName.Countdown} />
        </span>
        <div className="font-[400]   text-[var(--colour-48)]  text-[.24rem] leading-[.3rem] mr-[.26rem]">
          {t('welcomeBonus.tasksExpired')}
        </div>
        <div>
          <Countdown
            date={Number(endTime)}
            zeroPadTime={2}
            onComplete={() => {}}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              const dayTimeStr = (dayTime, max) => {
                if (max !== undefined && dayTime > max) {
                  dayTime = max;
                }
                return `${dayTime < 10 ? '0' : ''}${dayTime}`;
              };
              return (
                <div className="flex items-center justify-center font-[600]   text-[var(--colour-73)] text-center text-[.28rem] leading-[.5rem]">
                  <TimeSpan part1={dayTimeStr(days, 99)} part2="D" />
                  <div className=" mx-[.12rem] text-[var(--colour-48)]">:</div>
                  <TimeSpan part1={dayTimeStr(hours, 99)} part2="H" />
                  <div className=" mx-[.12rem] text-[var(--colour-48)]">:</div>
                  <TimeSpan part1={dayTimeStr(minutes, 99)} part2="M" />
                  <div className=" mx-[.12rem] text-[var(--colour-48)]">:</div>
                  <TimeSpan part1={dayTimeStr(seconds, 99)} part2="S" />
                </div>
              );
            }}
          />
        </div>
      </div>

      <div
        className={cn(
          '  m-auto bg-[url(../assets/img/welcome-bonus/welcomeBonusBox.png)] bg-no-repeat   w-[6.86rem] h-[6.86rem] bg-[length:20.58rem_27.44rem] mt-[-1.1rem] welcomeBonusBox_mo',
        )}
      ></div>

      <div className="  bg-[url(../assets/img/welcome-bonus/welcomeBonusReward.png)] bg-no-repeat bg-[length:100%_100%]  flex items-center justify-center w-[7.5rem] h-[.92rem] mt-[-1.2rem]">
        <span className="   w-[1.16rem] h-[1.16rem] mr-[.08rem]">
          <Icon name={IconName.Refer} />
        </span>
        <div className="font-[700]  text-[var(--colour-43)] text-[.48rem]">
          {`${websiteInitConfig.website_info.currency_symbol || ''} ${getShowMoney(money)}`}
        </div>
      </div>

      <LimitClickWrapper
        limitTime={1}
        onClick={handleClaim}
        className="  bg-[var(--colour-1)] font-[600]   text-center rounded-normal m-auto cursor-pointer  w-[4rem] h-[.84rem] text-[.32rem] leading-[.84rem] mt-[.4rem] text-[var(--colour-2)]"
      >
        {t('welcomeBonus.claim')}
      </LimitClickWrapper>
      <div className="absolute     bg-[#ffffff]/5 rounded-[.08rem] flex justify-center items-center w-[.52rem] h-[.48rem] top-[.2rem] right-[.32rem]">
        <Close onClick={close} className="!text-[var(--colour-46)]" />
      </div>
    </div>
  );
}
