import AnalyticsManager, { EventIds } from '../manager/analyticsManager';
import { CopyText } from '../utils/helper';

function usePay() {
  const handleCopyWithErrorReport = (text) => {
    CopyText(text).catch(() => {
      AnalyticsManager.sendExtraInfoLog2Server(EventIds.qrCodeCopyError, text);
    });
  };
  return { handleCopyWithErrorReport };
}

export default usePay;
