import PasswordInput from '../../../../passwordInput/passwordInput';

export default function CodeBox({
  title,
  handleCodeChange = () => {},
  onSubmit = () => {},
  className = '',
  headerRightInner = null,
}) {
  return (
    <div className={className}>
      <div className="flex items-center justify-between mb-[.24rem]">
        <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-13)]">{title}</div>
        {headerRightInner ? <div>{headerRightInner}</div> : null}
      </div>

      <div className="password_code_panda">
        <PasswordInput
          mask={true}
          type="number"
          length={6}
          onChange={(e) => {
            handleCodeChange(e);
          }}
          onSubmit={(e) => {
            onSubmit(e);
          }}
        />
      </div>
    </div>
  );
}
