export const deleteCPF = (cpf) => {
  if (cpf.endsWith('-')) {
    cpf = cpf.slice(0, -2); // 删除符号 - 和前一个字符
  } else if (cpf.endsWith('.')) {
    cpf = cpf.slice(0, -2); // 删除符号 . 和前一个字符
  } else {
    cpf = cpf.slice(0, -1); // 删除最后一个字符
  }
  return cpf;
};

// CPF格式化处理函数
export const formatCPF = (cpf) => {
  // 插入符号 . 在第3和第6位，插入符号 - 在第9位
  if (cpf.length >= 3) {
    cpf = cpf.slice(0, 3) + '.' + cpf.slice(3);
  }
  if (cpf.length > 7) {
    cpf = cpf.slice(0, 7) + '.' + cpf.slice(7);
  }
  if (cpf.length > 11) {
    cpf = cpf.slice(0, 11) + '-' + cpf.slice(11);
  }

  return cpf;
};

//通过keydown事件控制只允许输入数字
export const restrictToNumbers = (event) => {
  // 允许的键：数字键和控制键（如 Backspace、Delete、Arrow keys等）
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

  // 检查是否是数字键
  if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
    event.preventDefault(); // 阻止输入
  }
};

//通过keydown事件控制name的不允许输入的字符
export const restrictToName = (event) => {
  // 定义禁止输入的字符
  const forbiddenKeys = ["'", '"', '\n', '\r', '\t', '\\'];
  // 检查按下的键是否在禁止列表中
  if (forbiddenKeys.includes(event.key)) {
    event.preventDefault(); // 阻止输入
  }
};

//通过keydown事件控制number类型输入框的小数点位数
export const restrictToMaxPoint = (event, maxPoint = 2) => {
  // 允许的键：数字键和控制键（如 Backspace、Delete、Arrow keys等）
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
  // 检查是否小数点后面有两位
  const allowInput = (val) => {
    const v = String(val);
    if (v.indexOf('.') > -1) {
      if (v.substring(v.indexOf('.') + 1, v.length).length > maxPoint - 1) return false;
      else {
        return true;
      }
    } else {
      return true;
    }
  };
  if (!allowInput(event.target.value) && !allowedKeys.includes(event.key)) {
    event.preventDefault(); // 阻止输入
  }
};

//校验cpf规则
export const isValidCPF = (cpf) => {
  // 移除非数字字符
  cpf = cpf.replace(/\D/g, '');
  // 检查长度
  if (cpf.length !== 11) return false;
  // 检查是否是所有数字相同
  if (/^(\d)\1{10}$/.test(cpf)) return false;
  // 计算第一个校验位
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * (10 - i);
  }
  let firstCheckDigit = (sum * 10) % 11;
  if (firstCheckDigit === 10 || firstCheckDigit === 11) {
    firstCheckDigit = 0;
  }
  // 计算第二个校验位
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf[i]) * (11 - i);
  }
  let secondCheckDigit = (sum * 10) % 11;
  if (secondCheckDigit === 10 || secondCheckDigit === 11) {
    secondCheckDigit = 0;
  }

  // 校验位比较
  return cpf[9] == firstCheckDigit && cpf[10] == secondCheckDigit;
};

//限制字数
export const limitInputLength = (event, maxLength) => {
  const input = event.target;
  // 检查当前输入长度
  if (
    input.value.length >= maxLength &&
    event.key !== 'Backspace' &&
    event.key !== 'Delete' &&
    event.key !== 'ArrowLeft' &&
    event.key !== 'ArrowRight' &&
    event.key !== 'Tab'
  ) {
    event.preventDefault(); // 阻止输入
  }
};

export const removeSpecialSymbols = (inputString) => {
  const charactersToRemove = /['"\n\r\t\\]/g;
  const result = inputString.replace(charactersToRemove, '');
  return result;
};

//巴西用户手机号校验
export const phoneVerifyRegex = /^[1-9]\d{0,10}$|^$/;
export const phoneVerify = (str) => {
  const regex = /^\d{2}9\d{8}$/;
  return regex.test(str);
};

//非巴西用户手机号校验规则
export const phoneVerifyRegex2 = /^[1-9]\d{0,17}$|^$/;
