import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon, { IconName } from '../../../icon';
import { toLocalePath } from '../../../../utils/localePath';
import Tips from '../tips';
import { get } from 'lodash';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const DrawerSportSwitch = ({ onlyIcon }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const { isSportEnabled } = useSelector(
    (state) => ({
      isSportEnabled: get(state, 'app.isSportEnabled'),
    }),
    shallowEqual,
  );

  return isSportEnabled ? (
    onlyIcon ? (
      <>
        <Tips anchorId="overSports" className="text-[var(--colour-48)]">
          {t('home.sport')}
        </Tips>
        <div
          onClick={() => {
            navigate(toLocalePath('/sport'));
          }}
          id="overSports"
          className={`w-[.8rem] h-[.8rem] mb-[.16rem] rounded-normal cursor-pointer flex justify-center items-center bg-[var(--colour-35)] relative hover:bg-[var(--colour-35)]`}
        >
          <span className="w-[.48rem] h-[.48rem]">
            <Icon name={IconName.DrawerSports} />
          </span>
        </div>
      </>
    ) : (
      <div
        className="flex items-center cursor-pointer mb-[.2rem] pl-[.14rem] "
        onClick={() => {
          navigate(toLocalePath('/sport'));
        }}
      >
        <span className="block text-[var(--colour-12)] w-[.4rem] h-[.4rem] mr-[.08rem]">
          <Icon name={IconName.Sports} />
        </span>
        <div className="text-[var(--colour-14)] font-[500] text-[.2rem] leading-[.24rem]">{t('home.sport')}</div>
      </div>
    )
  ) : (
    <></>
  );
};

export default DrawerSportSwitch;
