import { useSelector, shallowEqual } from 'react-redux';
import DragBtnRight from '../../dragBtnRight';
import useHandleLogic from '../../../hooks/useHandleLogic';
import { cmsImgUrl } from '../../../utils/helper';
import { openUrl } from '../../../utils/openUrl';

const TgfbEntrance = ({ data, index }) => {
  const { handleClickManMadeInPage } = useHandleLogic();
  const {} = useSelector((state) => ({}), shallowEqual);
  return (
    <>
      <DragBtnRight
        className={`fixed absolute-bottom-${index + 1} ${data.EntranceDisplayLocation == '0' ? 'z-[100] left-[.32rem]' : 'right-[.32rem] z-[9999] thirdparty-item'}`}
        isLimitedInParentHeight={false}
        isdrag={false}
        onClick={() => {
          if (data.key == 'customer_service') {
            handleClickManMadeInPage();
          } else {
            if (data.link) {
              openUrl(data.link);
            }
          }
        }}
      >
        <div className="w-[.96rem] h-[.96rem] overflow-hidden flex items-center justify-center relative">
          {data.key == 'tg' && data.bottom_text != '' ? (
            <>
              <img src={cmsImgUrl(data.img_dollar)} className="w-[.96rem] h-[.96rem]" alt="" />

              <div className="min-h-[.18rem] w-[.92rem] bg-tgEntrance absolute abs-x-center bottom-0 text-[.12rem] font-[900] text-center">
                {data.bottom_text}
              </div>
            </>
          ) : (
            <>
              <img src={cmsImgUrl(data.img)} className="w-[.96rem] h-[.96rem]" alt="" />
            </>
          )}
        </div>
      </DragBtnRight>
    </>
  );
};

export default TgfbEntrance;
