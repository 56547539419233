import Close from '../../close2';
import { useEffect, useState, useRef } from 'react';
import { cmsImgUrl, getShowMoney } from '../../../utils/helper';
import { useSpring, animated } from 'react-spring';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Icon, { IconName } from '../../icon';
import { useTranslation } from 'react-i18next';
import useLangServerConfig from '../../../hooks/useLangServerConfig';
import DownloadManager from '../../../manager/downloadManager';
import LimitClickWrapper from '../../limitClickWrapper';
import { GetDownloadPage } from '../../../api';
import { setDownloadLadingPageLink } from '../../../redux/reducers/appSlice';
import Loading from '../../loading2';
import { eventEmitter, EmitterEventTypes } from '../../../utils/eventEmitter';

const RedirectToPwa = ({ handleClose = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );
  const { getServerLangText } = useLangServerConfig();

  const ref = useRef(null);
  const [isLoading, setisLoading] = useState(true);
  const clickAwayRef = useRef(null);
  const [isClickable, setIsClickable] = useState(true);
  const initialPosition = { transform: 'translateY(100%)' };
  const [styles, setSpring] = useSpring(() => ({
    from: initialPosition,
    to: { transform: 'translateY(0%)' },
    config: { duration: 150 },
  }));

  const handleAnimationEnd = () => {
    setIsClickable(true);
    handleClose();
  };

  const startClosingAnimation = () => {
    if (isClickable) {
      setIsClickable(false);
      setSpring({
        from: { transform: 'translateY(0%)' },
        to: initialPosition,
        onRest: handleAnimationEnd,
        config: { duration: 150 },
      });
    }
  };

  const resize = () => {
    if (ref.current) {
      ref.current.style.height = `${window.innerHeight}px`;
    }
  };

  useEffect(() => {
    resize();

    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      resize();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const getLandingPageLink = async () => {
    try {
      const res = await GetDownloadPage();
      if (res.code == 0) {
        dispatch(setDownloadLadingPageLink(res.result.download_landing_page_link));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getLandingPageLink();
  }, []);

  return (
    <div
      className="adaptive-width relative overflow-hidden z-[10000]"
      ref={ref}
      id="download-modal"
      onClick={(e) => {
        if (e.target.id == 'download-modal') {
          startClosingAnimation();
        }
      }}
    >
      <animated.div
        style={{ ...styles, position: 'absolute', bottom: 0, left: 0 }}
        className="w-full bg-[var(--colour-56)]"
        ref={clickAwayRef}
      >
        <div
          className="w-full h-full relative bg-download pt-[2.92rem]"
          style={{
            backgroundPosition: 'center top',
            backgroundImage: `url(${cmsImgUrl(websiteInfo?.download?.pwa?.pwa_reward_conf?.bg)})`,
          }}
        >
          <Close
            handleClose={startClosingAnimation}
            className="w-[.52rem] h-[.52rem] rounded-[.16rem] bg-[var(--colour-45)] absolute right-[.14rem] top-[.14rem] text-[var(--colour-1)]"
          />

          <div className="mt-[.02rem] w-[6.78rem] mx-auto flex  gap-[.32rem] text-[.28rem] font-[500]">
            <img className="w-[.88rem] h-[.88rem]" src={cmsImgUrl(websiteInfo['android_chrome_192x192'])} />
            <div>
              <div className="text-[.36rem] font-[700] text-[var(--colour-18)] mt-[-0.08rem]">
                {websiteInfo.website_name || ''}
              </div>
              <div className="font-[400] max-h-[2.2rem] overflow-y-auto text-[.2rem] text-[var(--colour-18)] mt-[-0.08rem]">
                {getServerLangText(websiteInfo.download.top.tip.bottom_title) || ''}
              </div>

              <div className="mt-[.2rem]">
                <div className="text-[var(--colour-31)] text-[.36rem] font-[700]">{t('home.version')}</div>
              </div>

              <div className="mt-[.1rem] flex items-center gap-[.12rem]">
                <div className="w-[.36rem] h-[.36rem] text-[var(--colour-26)]">
                  <Icon name={IconName.Fire}></Icon>
                </div>
                <div className="text-[.28rem] font-[400] text-[var(--colour-18)]">{t('home.more_game')}</div>
              </div>

              <div className="mt-[.1rem] flex items-center gap-[.12rem]">
                <div className="w-[.36rem] h-[.36rem] text-[var(--colour-26)]">
                  <Icon name={IconName.Fire}></Icon>
                </div>
                <div className="text-[.28rem] font-[400] text-[var(--colour-18)]">{t('home.more_rewards')}</div>
              </div>

              <div className="mt-[.1rem] flex items-center gap-[.12rem]">
                <div className="w-[.36rem] h-[.36rem] text-[var(--colour-26)]">
                  <Icon name={IconName.Fire}></Icon>
                </div>
                <div className="text-[.28rem] font-[400] text-[var(--colour-18)]">{t('home.faster_connection')}</div>
              </div>
            </div>
          </div>

          <div className="mt-[.28rem]">
            <Button
              isLoading={isLoading}
              icon={IconName.Rocket}
              onClick={() => {
                if (isLoading) return;
                DownloadManager.handleInstallAction(false, null);
              }}
            >
              {isLoading ? (
                <Loading />
              ) : (
                <span className="font-[700] text-[.36rem] text-[var(--colour-2)]">{t('home.update')}</span>
              )}
            </Button>
          </div>

          <div className="mt-[.24rem] text-[.28rem] font-[600] text-[var(--colour-31)] w-[6.82rem] mx-auto">
            {t('home.toinstall')}
          </div>

          <div className="mt-[.12rem] w-[6.82rem] mx-auto text-[.24rem] font-[500] text-[var(--colour-18)] leading-[.3rem]">
            <div>1. {t('home.downloadpwaTip1')}</div>
            <div>2. {t('home.downloadpwaTip2')}</div>
            <div>3. {t('home.downloadpwaTip3')}</div>
            <div>4. {t('home.downloadpwaTip4')}</div>
          </div>

          <div className="mt-[.4rem] w-[6.78rem] mx-auto rounded-[.16rem] bg-[#F5F6F4] p-[.06rem_.16rem_.12rem_.16rem] mb-[.22rem]">
            <div className="text-[.28rem] font-[600] text-[var(--colour-31)] flex items-center gap-[.1rem]">
              <div className="w-[.28rem] h-[.28rem] text-[var(--colour-25)]">
                <Icon name={IconName.PwaWarning} />
              </div>
              <div>{t('home.friendly')}</div>
            </div>
            <div className="text-[.2rem] font-[500] text-[var(--colour-18)]">{t('home.installit')}</div>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

const Button = ({ onClick, children, icon }) => (
  <LimitClickWrapper
    className="w-[6.78rem] h-[.84rem] rounded-[.16rem] bg-[var(--colour-1)] text-[var(--colour-2)] mx-auto text-[.28rem] font-[700] flex items-center justify-center relative active:scale-[.95] transition-all select-none gap-[.16rem]"
    onClick={onClick}
  >
    {icon && (
      <div className="w-[.52rem] h-[.52rem] text-[--colour-2]">
        <Icon name={icon} />
      </div>
    )}
    {children}
  </LimitClickWrapper>
);

export default RedirectToPwa;
