import cn from 'classnames';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import NoData from '../../../../../components/noData';
import Loading from '../../../../../components/loading2';
import DatePickerBox from '../../../../../components/datePickerBox';
import Icon, { IconName } from '../../../../../components/icon';
import TippyPopBox, { Placement } from '../../../../../components/tippyPopBox';
import PopChoose, { PopChooseItem } from '../../../../../components/popChoose';
import DropDownInput from '../../../../../components/dropDownInput';
import { useStateIfMounted } from 'use-state-if-mounted';
import useDivSize from '../../../../../hooks/useDivSize';
import { getShowMoney, isControllableSite, isDateInRangeLastMonth, pxToRem } from '../../../../../utils/helper';
import { useTranslation } from 'react-i18next';
import { getReportAccountdata } from '../../../../../api';
import LimitClickWrapper from '../../../../../components/limitClickWrapper';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import WidthdrawRecordDetail from '../../../../../components/dialogModal/widthdrawRecordDetail';
import DialogModal from '../../../../../components/dialogModal';
import { eventEmitter, EmitterEventTypes } from '../../../../../utils/eventEmitter';

const ReportAccount = () => {
  const { t } = useTranslation();
  const divRef = useRef(null);
  const [isloading, setisLoading] = useState(true);
  const excessheight = 0.72 + 0.9 + 1.1;
  const { width } = useDivSize(divRef);
  const [pageCount, setPageCount] = useStateIfMounted(0);
  const [h, setH] = useState(pxToRem(window.innerHeight) - excessheight);
  const [filterTypeVisible, setFilterTypeVisible] = useStateIfMounted(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  const { websiteInitConfig, responsiveMode } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      responsiveMode: state?.app?.responsiveMode,
    }),
    shallowEqual,
  );

  const list = [
    {
      name: t('report.allkinds'),
      key: 0,
      value: 'all',
      onClick: () => {
        setCurrentType(0);
        setParams({ ...params, type: 'all', page: 1 });
      },
    },
    // {
    //   name: t('report.capitalexchange'),
    //   key: 1,
    //   value: 'funds_exchange',
    //   onClick: () => {
    //     setCurrentType(1);
    //     setParams({ ...params, type: 'funds_exchange', page: 1 });
    //   },
    // },
    {
      name: t('report.vipDeposit'),
      key: 2,
      value: 'member_recharge',
      onClick: () => {
        setCurrentType(2);
        setParams({ ...params, type: 'member_recharge', page: 1 });
      },
    },
    {
      name: t('report.vipWithdraw'),
      key: 3,
      value: 'member_withdraw',
      onClick: () => {
        setCurrentType(3);
        setParams({ ...params, type: 'member_withdraw', page: 1 });
      },
    },
    {
      name: t('report.activity'),
      key: 4,
      value: 'activity',
      onClick: () => {
        setCurrentType(4);
        setParams({ ...params, type: 'activity', page: 1 });
      },
    },
    {
      name: t('report.kickback'),
      key: 5,
      value: 'rebate',
      onClick: () => {
        setCurrentType(5);
        setParams({ ...params, type: 'rebate', page: 1 });
      },
    },
    {
      name: t('report.vipBonus'),
      key: 6,
      value: 'vip_rewards',
      onClick: () => {
        setCurrentType(6);
        setParams({ ...params, type: 'vip_rewards', page: 1 });
      },
    },
    {
      name: t('report.topuooffer'),
      key: 7,
      value: 'recharge_offers',
      onClick: () => {
        setCurrentType(7);
        setParams({ ...params, type: 'recharge_offers', page: 1 });
      },
    },
    {
      name: t('report.referralBonus'),
      key: 8,
      value: 'referral_bonus',
      onClick: () => {
        setCurrentType(8);
        setParams({ ...params, type: 'referral_bonus', page: 1 });
      },
    },
    {
      name: t('report.other'),
      key: 9,
      value: 'other',
      onClick: () => {},
      notFilter: true,
    },
  ];

  const [currentType, setCurrentType] = useState(list[0].key);
  const [data, setdata] = useState([]);
  const [totalinfo, setTotalInfo] = useState();

  const [params, setParams] = useState({
    page: 1,
    page_size: 10,
    date: new Date(),
    type: list[0].value,
  });

  const onDateChange = (time) => {
    setParams({ ...params, date: time, page: 1 });
  };

  const getReportList = async () => {
    setisLoading(true);
    try {
      const res = await getReportAccountdata({
        ...params,
        date: moment(params.date).format(`YYYY-MM-DD`),
      });

      if (res) {
        if (res.code == 0) {
          setdata(res.result.list);
          setTotalInfo(res.result.total_info);
          const _pageCount = Math.ceil(res.result.pages.count / params.page_size);
          setPageCount(_pageCount);
        } else {
          setdata([]);
          setTotalInfo(null);
          setPageCount(0);
          toast.error(t(`${res.code}`), {
            containerId: 'global',
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };

  const findItem = (type) => {
    const _find = list.find((item) => {
      return item.value == type;
    });

    return _find ? _find.name : '';
  };

  const setNextpage = () => {
    if (params.page == pageCount || pageCount == 0) return;
    setParams({ ...params, page: params.page + 1 });
  };

  const setPrevpage = () => {
    if (params.page <= 1 || pageCount == 0) return;
    setParams({ ...params, page: params.page - 1 });
  };

  const statusReturn = (status, approval_result) => {
    if (status == 3) {
      if (approval_result == -1) {
        return t('deposit-withdraw.reject');
      } else {
        return t('deposit-withdraw.success');
      }
    }
  };

  useEffect(() => {
    getReportList();
  }, [params]);

  const handleResize = () => {
    setH(pxToRem(window.innerHeight) - excessheight);
  };

  useEffect(() => {
    handleResize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      handleResize();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={isOpen}
      >
        <WidthdrawRecordDetail
          Data={selectItem}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      </DialogModal>

      <div className="w-full">
        <div style={{ height: `${h}rem` }}>
          <div className={cn('w-full h-full py-[.2rem] px-[.24rem] flex flex-col')}>
            <div className="flex items-center gap-[.2rem]">
              <div className="h-[.5rem] w-[1.98rem] text-[.2rem] font-[400] flex justify-between pl-[.2rem] rounded-normal relative bg-[var(--colour-35)] border border-[var(--colour-6)] text-[var(--colour-44)]">
                <div className="text-[.2rem] flex items-center">
                  <DatePickerBox
                    filterDate={isDateInRangeLastMonth}
                    selected={params.date}
                    onChange={onDateChange}
                    onCalendarOpen={() => {
                      setFilterTypeVisible(false);
                    }}
                    customInput={
                      <div className="flex items-center cursor-pointer ">
                        <div>{moment(params.date).format('YYYY-MM-DD')}</div>

                        <div className="w-[.24rem] h-[.24rem] absolute abs-y-center right-[.2rem]">
                          <Icon name={IconName.Newdate1} />
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="text-tab z-[1]">
                <TippyPopBox
                  visible={filterTypeVisible}
                  onClickOutside={() => {
                    setFilterTypeVisible(false);
                  }}
                  offset={[0, 6]}
                  placement={Placement.bottomEnd}
                  zIndex={18}
                  popChildren={
                    <PopChoose
                      style={{ width: `${width}px` }}
                      className="bg-[var(--colour-35)] border border-[var(--colour-6)] py-[10px] max-h-[6rem] overflow-y-auto scrollbar-none"
                      children={Array.from(list)
                        .filter((item) => item.notFilter == undefined)
                        .map((item, index) => (
                          <PopChooseItem
                            className="flex items-center px-[.2rem] py-[.1rem]"
                            key={index}
                            data={item}
                            choose={item.key == currentType}
                            widthNumber={width}
                            onClick={() => {
                              setFilterTypeVisible(false);
                              item.onClick();
                            }}
                          >
                            <span className={`text-[.2rem] font-[400] wrap-break-word`}>{item.name}</span>
                          </PopChooseItem>
                        ))}
                    ></PopChoose>
                  }
                >
                  <div>
                    <DropDownInput
                      ref={divRef}
                      iconCanRotate={false}
                      className="h-[.5rem] min-w-[2.4rem] text-[.2rem] font-[400] w-[auto] rounded-normal relative px-[.2rem] bg-[var(--colour-35)] border border-[var(--colour-6)]"
                      onClick={() => {
                        setFilterTypeVisible(!filterTypeVisible);
                      }}
                    >
                      <div className="text-[var(--colour-44)]">
                        {list.find((item) => item.key == currentType)?.name}
                      </div>
                      <span
                        className={cn('w-[.18rem] h-[.18rem] block text-[var(--colour-11)] transition', {
                          'rotate-[90deg]': filterTypeVisible,
                        })}
                      >
                        <Icon name={IconName.RightArrow3} />
                      </span>
                    </DropDownInput>
                  </div>
                </TippyPopBox>
              </div>
            </div>

            {isloading ? (
              <Loading className="h-full flex-1" />
            ) : (
              <>
                {data && data.length > 0 ? (
                  <>
                    <div
                      className={cn('mt-[.2rem] flex-1 flex flex-col items-center overflow-y-auto scrollbar-none')}
                      style={{ height: `${h - 2}rem` }}
                    >
                      {data &&
                        data.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={cn(
                                'w-full min-h-[1.2rem] flex flex-col justify-center px-[.2rem] rounded-small',
                                {
                                  'bg-[var(--colour-35)]': index % 2 == 0,
                                },
                              )}
                            >
                              <div className="flex justify-between items-center">
                                <div className="text-[var(--colour-44)] text-[.2rem] font-[400] flex justify-between">
                                  <div className={`${item.behaviour_type != 'member_withdraw' ? '' : 'w-[.88rem]'}`}>
                                    {findItem(item.behaviour_type)}
                                  </div>
                                  <div
                                    className={cn('ml-[.24rem] w-[.8rem] text-right', {
                                      hidden: item.behaviour_type != 'member_withdraw',
                                    })}
                                  >
                                    {[0, 4].includes(item.status) ? (
                                      <span className="text-[var(--colour-25)] whitespace-nowrap">
                                        {t('deposit-withdraw.fail')}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    {[1, 2].includes(item.status) ? (
                                      <span className="text-[var(--colour-26)] whitespace-nowrap">
                                        {t('account.pending')}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    {item.status == 3 ? (
                                      <span
                                        className={`whitespace-nowrap  ${
                                          item.approval_result == -1 ||
                                          item.behaviour == 'withdraw_rollback' ||
                                          item.behaviour == 'bonus_withdraw_rollback'
                                            ? 'text-[var(--colour-25)]'
                                            : 'text-[var(--colour-24)]'
                                        }`}
                                      >
                                        {item.behaviour == 'withdraw_rollback' ||
                                        item.behaviour == 'bonus_withdraw_rollback' ? (
                                          <>{t('deposit-withdraw.rollback')}</>
                                        ) : (
                                          <>{statusReturn(item.status, item.approval_result)}</>
                                        )}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div
                                    className={cn(
                                      'ml-[.9rem] cursor-pointer gradient_underline text-[var(--colour-1)] border-b border-[var(--colour-1)]',
                                      {
                                        hidden: item.behaviour_type != 'member_withdraw',
                                      },
                                    )}
                                    onClick={() => {
                                      setSelectItem(item);
                                      setIsOpen(true);
                                    }}
                                  >
                                    {t('account.details')}
                                  </div>
                                </div>
                                <div className="text-[.24rem] font-[500] text-[var(--colour-38)]">
                                  {item?.amount ? getShowMoney(item?.amount) : '--'}
                                </div>
                              </div>

                              <div className="flex justify-between items-center text-[.2rem] font-[400] text-[var(--colour-44)]">
                                <div className="whitespace-nowrap">
                                  {moment(item.create_timestamp).format('YYYY/MM/DD HH:mm:ss')}
                                </div>
                                <div className="flex items-center">
                                  <div className="w-[3.78rem] truncate">{item.transaction_id}</div>
                                  <div
                                    className={cn(
                                      'w-[.28rem] h-[.28rem] cursor-pointer text-[var(--colour-1)] ml-[.2rem]',
                                    )}
                                    onClick={() => {
                                      copy(item.transaction_id);
                                      toast.success(t('home.toast006'), {
                                        containerId: 'global',
                                      });
                                    }}
                                  >
                                    <Icon name={IconName.Copy4}></Icon>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <>
                    <NoData
                      isShowIcon={isControllableSite() ? false : true}
                      className="h-full gap-[.2rem] flex-1"
                      imgStyle="!w-[2rem] !h-[2rem]"
                      className1={isControllableSite() ? '!mt-[14px]' : ''}
                    />
                  </>
                )}
              </>
            )}

            {data && data.length > 0 ? (
              <div className="w-full flex justify-center items-center mt-[.2rem]">
                <LimitClickWrapper
                  onClick={setPrevpage}
                  className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
                >
                  <div className="w-[.24rem] h-[.24rem] rotate-180">
                    <Icon name={IconName.BannerNext2}></Icon>
                  </div>
                </LimitClickWrapper>
                <div className="px-[.24rem] text-[.24rem] font-[500] text-[var(--colour-1)]">{params.page}</div>
                <LimitClickWrapper
                  onClick={setNextpage}
                  className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
                >
                  <div className="w-[.24rem] h-[.24rem]">
                    <Icon name={IconName.BannerNext2}></Icon>
                  </div>
                </LimitClickWrapper>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <footer className="w-full h-[1.02rem]">
          <div className="fixed bg-[var(--colour-35)] bottom-0 w-full h-[1.02rem] left-0 px-[.24rem] py-[.1rem]">
            <div className="flex leading-[.26rem]">
              <div className="flex text-[.2rem] font-[400] w-[50%]">
                <div className="w-[1.52rem] line-clamp-2 break-all text-[var(--colour-38)]">{t('report.total')}</div>

                <div className="text-[var(--colour-24)] ml-[.4rem] flex-1 w-[1.48rem] line-clamp-2 break-all">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''} </span>
                  {totalinfo ? getShowMoney(totalinfo.deposit_amount) : '0'}
                </div>
              </div>

              <div className="flex text-[.2rem] font-[400] flex-1 ml-[.08rem]">
                <div className="w-[1.52rem] line-clamp-2 break-all text-[var(--colour-38)]">{t('report.total1')}</div>
                <div className="text-[var(--colour-25)] flex-1 w-[1.48rem] line-clamp-2 break-all ml-[.4rem]">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''} </span>
                  {totalinfo ? getShowMoney(totalinfo.withdraw_amount) : '0'}
                </div>
              </div>
            </div>

            <div className="flex leading-[.26rem] mt-[.08rem]">
              <div className="flex text-[.2rem] font-[400] w-[50%]">
                <div className="w-[1.52rem] line-clamp-2 break-all text-[var(--colour-38)]">{t('report.total2')}</div>

                <div className="text-[var(--colour-26)] ml-[.4rem] flex-1 w-[1.48rem] line-clamp-2 break-all">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''} </span>
                  {totalinfo ? getShowMoney(totalinfo.total_amount) : '0'}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default ReportAccount;
