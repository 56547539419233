import { get } from 'lodash';
import { Fragment, useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useLoginThirdParty from '../../../hooks/useLoginThirdParty';
import { isAndroidNative } from '../../../utils/deviceUtils';
import FacebookManager from '../../../manager/facebookManager';
import Icon, { IconName } from '../../icon';
import GoogleManager from '../../../manager/googleManager';
import { TLoginButton, TLoginButtonSize } from 'react-telegram-auth';
import { isWeb3Browser } from '../../../hooks/useMetaMask';
import { walletLogin } from '../../../utils/wallet';
import { WalletConnect } from '../walletConnect';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { getRegisterThirdpart } from '../../../utils/helper';
import { isUseLayoutMobile, getRegisterConfig } from '../../../utils/helper';

const WidgetLogin = ({ children, className = '', onClick }) => {
  return (
    <div
      className={`${getRegisterConfig(2) != null ? 'rounded-[50%]' : 'rounded-[.2rem]'} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const LoginThirdParty = memo(() => {
  const { t } = useTranslation();

  const { LoginByFacebook, onLoginSuccess } = useLoginThirdParty();
  const { telegram_bot_name } = useSelector(
    (state) => ({
      telegram_bot_name: get(state, 'app.websiteInitConfig.website_info.telegram_bot_name') || '',
    }),
    shallowEqual,
  );

  const [snsLoginMethod, setSnsLoginMethod] = useState([]);

  const handleLoginByFacebook = () => {
    if (isAndroidNative()) {
      //走原生
      window.native.login_facebook();
      return;
    }

    // web版本facebook登录
    FacebookManager.login((token) => {
      if (token) {
        LoginByFacebook(token);
      } else {
        toast.error(t('1008068'), {
          containerId: 'global',
        });
      }
    });
  };

  const handleLoginByGoogle = () => {
    GoogleManager.login();
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_Google_Login);
  };

  const handleLoginByMetaMask = async () => {
    if (!isWeb3Browser()) {
      toast.error(t('home.toast012'), {
        containerId: 'global',
      });
    }

    walletLogin()
      .then((res) => {
        if (res.code == 0) {
          onLoginSuccess(res.result);
        }
      })
      .catch((err) => {
        toast.error(err, {
          containerId: 'global',
        });
      });
  };

  const initData = () => {
    const thirdPartData = getRegisterThirdpart();
    const thirdPartLoginMethodsSort = [];
    if (thirdPartData != null) {
      const sortedObj = Object.fromEntries(
        Object.keys(thirdPartData)
          .sort((a, b) => Number(thirdPartData[b].sort) - Number(thirdPartData[a].sort))
          .map((key) => [key, thirdPartData[key]]),
      );
      for (let obj in sortedObj) {
        if (sortedObj[obj].status == '1') {
          thirdPartLoginMethodsSort.push(obj);
        }
      }
    }
    setSnsLoginMethod(thirdPartLoginMethodsSort);
  };

  useEffect(() => {
    initData();
    if (isAndroidNative()) {
      window.login_facebook = (code, token) => {
        window.native.log('login_facebook code = ' + code + '  ' + token);
        if (code == 0) {
          LoginByFacebook(token);
        } else {
          toast.error(token, {
            containerId: 'global',
          });
        }
      };
    }
    return () => {
      if (isAndroidNative()) {
        window.login_facebook = null;
        //window.removeListener('login_facebook');
      }
    };
  }, []);

  const LoginFb = () => {
    return (
      <WidgetLogin className="flex flex-row items-center justify-center cursor-pointer" onClick={handleLoginByFacebook}>
        {getRegisterConfig(2) != null ? (
          <span className={`w-[.7rem] h-[.7rem]`}>
            <Icon id="facebookButton" name={IconName.FacebookWG} />
          </span>
        ) : (
          <span className={`w-[.8rem] h-[.8rem]`}>
            <Icon id="facebookButton" name={IconName.Facebook} />
          </span>
        )}
      </WidgetLogin>
    );
  };

  const LoginGG = () => {
    return (
      <WidgetLogin className="flex flex-row items-center justify-center cursor-pointer" onClick={handleLoginByGoogle}>
        {getRegisterConfig(2) != null ? (
          <span className={`w-[.7rem] h-[.7rem]`}>
            <Icon id="googleButton" name={IconName.GoogleWG} />
          </span>
        ) : (
          <span className={`w-[.8rem] h-[.8rem]`}>
            <Icon id="googleButton" name={IconName.Gmail} />
          </span>
        )}
      </WidgetLogin>
    );
  };

  const LoginTG = () => {
    return (
      <WidgetLogin
        className="flex flex-row items-center justify-center cursor-pointer relative"
        onClick={() => {
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_TG_Login);
        }}
      >
        <div className="absolute left-0 top-0 w-[.92rem] h-[.92rem] cursor-pointer opacity-[0.01] overflow-hidden">
          <TLoginButton
            botName={telegram_bot_name}
            buttonSize={TLoginButtonSize.Large}
            lang="en"
            usePic={false}
            cornerRadius={20}
            onAuthCallback={useLoginThirdParty().LoginByTG}
            requestAccess={'write'}
          />
        </div>

        {getRegisterConfig(2) != null ? (
          <span className={`w-[.7rem] h-[.7rem]`}>
            <Icon id="tgButton" name={IconName.TelegramWG} />
          </span>
        ) : (
          <span className={`w-[.8rem] h-[.8rem]`}>
            <Icon id="tgButton" name={IconName.Telegram} />
          </span>
        )}
      </WidgetLogin>
    );
  };

  const LoginMetaMask = () => {
    return (
      <WidgetLogin className="flex flex-row items-center justify-center cursor-pointer" onClick={handleLoginByMetaMask}>
        {getRegisterConfig(2) != null ? (
          <span className={`w-[.7rem] h-[.7rem]`}>
            <Icon name={IconName.MetamaskWG} />
          </span>
        ) : (
          <span className={`w-[.8rem] h-[.8rem]`}>
            <Icon name={IconName.MetaMask} />
          </span>
        )}
      </WidgetLogin>
    );
  };

  const selectLogin = (method) => {
    switch (method) {
      case 'facebook':
        return <LoginFb />;
      case 'google':
        return <LoginGG />;
      case 'telegram': {
        return telegram_bot_name == '' ? null : <LoginTG />;
      }
      case 'metaMask': {
        return <LoginMetaMask />;
      }
      default:
        return null;
    }
  };

  const isMetaMaskLoginEnable = true;

  return snsLoginMethod && snsLoginMethod.length > 0 ? (
    <>
      <div className={`flex flex-row items-center justify-center mt-[.16rem]`}>
        <div className={`bg-[var(--colour-6)] h-[1px] w-[.7rem] my-[.12rem]`}></div>
        <div
          className={`${isUseLayoutMobile() ? 'font-[500] text-[.22rem]' : 'text-[.24rem]'} text-[var(--colour-41)] mx-[.2rem]`}
        >
          {t('login-signup.or_with_login')}
        </div>
        <div className={`bg-[var(--colour-6)] h-[1px] w-[.7rem]`}></div>
      </div>

      <div
        className={`flex flex-row items-center justify-center ${isUseLayoutMobile() ? 'mt-[.2rem]' : 'mt-[.16rem]'} m-[0_auto] w-[5.12rem] space-x-[.4rem]`}
      >
        {[...snsLoginMethod, ...(isMetaMaskLoginEnable && false ? ['metaMask'] : [])].map((method, index) => (
          <Fragment key={index}>{selectLogin(method)}</Fragment>
        ))}
        {/* <WalletConnect></WalletConnect> */}
      </div>
    </>
  ) : (
    <></>
  );
});

export default LoginThirdParty;
