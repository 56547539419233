import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../../components/icon';
import { PaymentWithdrawView } from '../../../../../api/newDepositWithdraw';
import { getShowMoney, getDateFormatStr4 } from '../../../../../utils/helper';
import Loading from '../../../../../components/loading2';
import DialogModal from '../../../../dialogModal';
import Close from '../../../../close';

export default function WidthdrawDetails({ setShowVerify, showVerify, id = '' }) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
      >
        <TipInner handleClose={() => setShowVerify(false)} id={id}></TipInner>
      </DialogModal>
    </>
  );
}

function TipInner({ handleClose = () => {}, id }) {
  const { t } = useTranslation();

  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state?.app?.websiteInitConfig,
    }),
    shallowEqual,
  );

  useEffect(() => {
    PaymentWithdrawView({ transaction_id: `${id}` })
      .then((res) => {
        if (res.code == 0) {
          setInfo(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);

  const statusReturn = (status, approval_result) => {
    if (status == 3) {
      if (approval_result == -1) {
        return t('deposit-withdraw.reject');
      } else {
        return t('deposit-withdraw.success');
      }
    }
  };

  return (
    <div className="bg-[var(--colour-35)] border border-solid border-[var(--colour-6)] overflow-hidden w-[6.86rem] rounded-[.16rem] p-[.41rem_.2rem_.42rem_.2rem] relative">
      <div className="text-[.32rem] font-[400] leading-[.38rem] text-[var(--colour-48)] text-center">
        {t('deposit-withdraw-wg.withdraw_details_title')}
      </div>
      <div
        className="flex w-[.72rem] h-[.72rem] rounded-normal absolute right-[.32rem] top-[.24rem] items-center justify-center"
        onClick={() => handleClose()}
      >
        <Close className="text-[var(--colour-48)]" />
      </div>
      {loading ? (
        <Loading height="7.24rem" />
      ) : (
        <>
          <div className="pt-[.45rem] pb-[.4rem] border-b border-dashed border-[var(--colour-6)]">
            {[1, 2].includes(info?.status) ? (
              <div className="flex flex-col items-center">
                <div className="w-[1.28rem] h-[1.28rem] text-[var(--colour-26)] mb-[.2rem]">
                  <Icon name={IconName.DetailsLoading} />
                </div>
                <div className="text-[var(--colour-26)] text-[.24rem] font-[500] leading-[.3rem] mb-[.24rem]">
                  {t('account.pending')}
                </div>
                <div className="flex items-center">
                  <div className="text-[.44rem] font-[500] leading-[.54rem] text-[var(--colour-10)] mr-[.16rem]">
                    <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>
                    {getShowMoney(info?.amount)}
                  </div>
                  <div
                    className="text-[var(--colour-13)] w-[.36rem] h-[.36rem]"
                    onClick={() => {
                      copy(`${getShowMoney(info?.amount)}`);
                      toast.success(t('home.toast006'), {
                        containerId: 'global',
                      });
                    }}
                  >
                    <Icon name={IconName.Copy4} />
                  </div>
                </div>
              </div>
            ) : [0, 4].includes(info?.status) ? (
              <div className="flex flex-col items-center">
                <div className="w-[1.28rem] h-[1.28rem] text-[var(--colour-25)] mb-[.2rem]">
                  <Icon name={IconName.DetailsError} />
                </div>
                <div className="text-[var(--colour-25)] text-[.24rem] font-[500] leading-[.3rem] mb-[.24rem]">
                  {t('deposit-withdraw.fail')}
                </div>
                <div className="flex items-center">
                  <div className="text-[.44rem] font-[600] leading-[.54rem] text-[var(--colour-10)]">
                    <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>
                    {getShowMoney(info?.amount)}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                {info?.approval_result == -1 ||
                info?.behaviour == 'withdraw_rollback' ||
                info?.behaviour == 'bonus_withdraw_rollback' ? (
                  <div className="w-[1.28rem] h-[1.28rem] text-[var(--colour-25)] mb-[.2rem]">
                    <Icon name={IconName.DetailsError} />
                  </div>
                ) : (
                  <div className="w-[1.28rem] h-[1.28rem] text-[var(--colour-24)] mb-[.2rem]">
                    <Icon name={IconName.SuccessTip} />
                  </div>
                )}

                <div
                  className={`text-[.24rem] font-[500] leading-[.3rem] mb-[.24rem] ${
                    info?.approval_result == -1 ||
                    info?.behaviour == 'withdraw_rollback' ||
                    info?.behaviour == 'bonus_withdraw_rollback'
                      ? 'text-[var(--colour-25)]'
                      : 'text-[var(--colour-24)]'
                  }`}
                >
                  {info?.behaviour == 'withdraw_rollback' || info?.behaviour == 'bonus_withdraw_rollback' ? (
                    <>{t('deposit-withdraw.rollback')}</>
                  ) : (
                    <>{statusReturn(info?.status, info?.approval_result)}</>
                  )}
                </div>

                <div className="flex items-center">
                  <div className="text-[.44rem] font-[600] leading-[.54rem] text-[var(--colour-10)]">
                    <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>
                    {getShowMoney(info?.amount || 0)}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="pt-[.36rem]">
            <div className="flex items-center justify-between text-[.24rem] font-[500] leading-[.3rem] mb-[.18rem]">
              <div className="text-[var(--colour-14)] whitespace-nowrap">
                {t('deposit-withdraw-wg.withdraw_details_transaction_type')}
              </div>
              <div className="text-[var(--colour-38)] max-w-[2.6rem]">
                {t('deposit-withdraw-wg.withdraw_details_withdraw')}
              </div>
            </div>

            <div className="flex items-center justify-between text-[.24rem] font-[500] leading-[.3rem] mb-[.18rem]">
              <div className="text-[var(--colour-14)] whitespace-nowrap">
                {t('deposit-withdraw-wg.withdraw_details_deposit_method')}
              </div>
              <div className="text-[var(--colour-38)] max-w-[2.6rem] flex items-center">
                <div className="w-[.28rem] h-[.28rem] mr-[.08rem]">
                  <Icon name={IconName.Pix2} />
                </div>
                <div>PIX</div>
              </div>
            </div>

            <div className="flex items-center justify-between text-[.24rem] font-[500] leading-[.3rem] mb-[.18rem]">
              <div className="text-[var(--colour-14)] whitespace-nowrap">
                {t('deposit-withdraw-wg.withdraw_details_creation_time')}
              </div>
              <div className="text-[var(--colour-38)] max-w-[2.6rem]">{getDateFormatStr4(info?.create_time)}</div>
            </div>

            {info?.transaction_id ? (
              <div className="flex items-center justify-between text-[.24rem] font-[500] leading-[.3rem]">
                <div className="text-[var(--colour-14)] whitespace-nowrap">
                  {t('deposit-withdraw-wg.withdraw_details_request_number')}
                </div>
                <div className="text-[var(--colour-38)] max-w-[2.6rem] flex items-center">
                  <div className="mr-[.08rem] w-[2.2rem] truncate">{info?.transaction_id}</div>
                  <div
                    className="w-[.32rem] h-[.32rem] text-[var(--colour-13)]"
                    onClick={() => {
                      copy(`${info?.transaction_id}`);
                      toast.success(t('home.toast006'), {
                        containerId: 'global',
                      });
                    }}
                  >
                    <Icon name={IconName.Copy4} />
                  </div>
                </div>
              </div>
            ) : null}

            {info?.reason ? (
              <div className="flex items-center justify-between text-[.24rem] font-[500] leading-[.3rem] mt-[.56rem]">
                <div className="text-[var(--colour-14)] whitespace-nowrap">
                  {t('deposit-withdraw-wg.withdraw_details_reason_for_cancellation')}
                </div>
                <div className="text-[var(--colour-38)] max-w-[2.6rem] text-right">{info?.reason}</div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
