import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { getIdentity, getShowMoney } from '../../../../../../utils/helper';
import { useDispatch } from 'react-redux';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../../../redux/reducers/appSlice';
import VipLevelPicture from '../../../../../../components/vipLevelPicture';
import LimitClickWrapper from '../../../../../../components/limitClickWrapper';

const ClaimAllButton = ({ onClick, isClaimable = false, claimAllButtonElementId }) => {
  const { t } = useTranslation();

  // return (
  //   <div
  //     className={cn(
  //       'w-[1.54rem] h-[.58rem] rounded-[.12rem] flex items-center justify-center text-center leading-[1] text-[var(--colour-2) text-[.24rem] font-[600] text-[var(--colour-2)]',
  //       {
  //         'colour-80 cursor-pointer': isClaimable,
  //         'bg-[var(--colour-42)]': !isClaimable,
  //       },
  //     )}
  //     style={{
  //       boxShadow: '0px .08rem .08rem 0px rgba(0, 0, 0, 0.25)',
  //     }}
  //     onClick={onClick}
  //     id={claimAllButtonElementId}
  //   >
  //     {t('giftMoney.claimAll')}
  //   </div>
  // );

  return (
    <LimitClickWrapper
      className={cn(
        'w-[1.54rem] h-[.58rem] rounded-[.12rem] flex items-center justify-center text-center leading-[1] text-[var(--colour-2) text-[.24rem] font-[600] text-[var(--colour-2)]',
        {
          'colour-80 cursor-pointer': isClaimable,
          'bg-[var(--colour-42)]': !isClaimable,
        },
      )}
      style={{
        boxShadow: '0px .08rem .08rem 0px rgba(0, 0, 0, 0.25)',
      }}
      onClick={onClick}
      id={claimAllButtonElementId}
    >
      {t('giftMoney.claimAll')}
    </LimitClickWrapper>
  );
};

const HistoryButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onScroll } = useOutletContext();

  const handleClick = (e) => {
    if (getIdentity()) {
      onScroll('/promotion/history');
    } else {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
  };

  return (
    <div
      className="w-[1.54rem] h-[.58rem] colour-91 rounded-[.12rem] flex items-center justify-center text-center leading-[1] text-[var(--colour-2) text-[.24rem] font-[600] cursor-pointer"
      style={{
        boxShadow: '0px .08rem .08rem 0px rgba(0, 0, 0, 0.25)',
      }}
      onClick={handleClick}
    >
      {t('promotion.history')}
    </div>
  );
};

const UserVipInfo = ({
  currentVipNum,
  nextVipNum,
  depositAmount,
  wagerNum,
  isUserLevelMax = false,
  handleClaimAllClick,
  isCanClaimAll,
  claimAllButtonElementId,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center h-[1.54rem] w-full rounded-[.16rem] bg-[var(--colour-52)] relative p-[0_.2rem_0_.22rem]">
      <span className="absolute left-[0] top-[0] bg-[var(--colour-36)] text-[#fff] text-[.18rem] font-[600] flex items-center justify-center px-[.04rem] rounded-[.04rem]">
        {t('vip.my_vip')}
      </span>
      <div>
        <VipLevelPicture vipLevel={currentVipNum >= 0 ? currentVipNum : ''} />
      </div>
      <div className="flex-1 m-[0_.12rem_0_.16rem] space-y-[.06rem] text-[.24rem] font-[600] leading-[1]">
        {isUserLevelMax ? (
          <>
            <div className="text-[var(--colour-41)]">{t('vip.highestLevel')}</div>
          </>
        ) : (
          <>
            <div className="text-[var(--colour-41)]">
              <Trans
                t={t}
                i18nKey={'vip.to_vip'}
                components={[<span className="text-[var(--colour-60)]" key={0}></span>]}
                values={{
                  val: `VIP${nextVipNum}`,
                }}
              />
            </div>
            <div className="text-[var(--colour-41)]">
              <Trans
                t={t}
                i18nKey={'vip.to_vip_deposit'}
                components={[<span className="text-[var(--colour-44)]" key={0}></span>]}
                values={{
                  val: `${getShowMoney(depositAmount)}`,
                }}
              />
            </div>
            <div className="text-[var(--colour-41)]">
              <Trans
                t={t}
                i18nKey={'vip.to_vip_wager'}
                components={[<span className="text-[var(--colour-44)]" key={0}></span>]}
                values={{
                  val: `${getShowMoney(wagerNum)}`,
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="space-y-[.16rem]">
        <ClaimAllButton
          isClaimable={isCanClaimAll}
          onClick={handleClaimAllClick}
          claimAllButtonElementId={claimAllButtonElementId}
        />
        <HistoryButton />
      </div>
    </div>
  );
};

export default UserVipInfo;
