import Icon, { IconName } from '../../../../../components/icon';
import { useTranslation } from 'react-i18next';
import DownloadManager from '../../../../../manager/downloadManager';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ProgressierOperatio from '../../../../../components/ProgressierOperation';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../../redux/reducers/appSlice';
import { openUrl } from '../../../../../utils/openUrl';
import { isPWA } from '../../../../../utils/deviceUtils';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../../utils/localePath';

const InstallButton = ({ fromApk = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { installStatus } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      installStatus: state.app.installStatus,
    }),
    shallowEqual,
  );

  const handleClick = () => {
    let downLoadApk = true;

    if (fromApk == 'Channelapk' || fromApk == 'apk') {
      downLoadApk = false;
    } else {
      downLoadApk = true;
    }

    DownloadManager.handleInstallAction(false, null, downLoadApk, navigate);
  };

  const handleStartAppClick = async () => {
    if (isPWA()) {
      return window.location.reload();
    }

    dispatch(
      setDialogModalVisible({
        zIndex: 10002,
        show: DialogModalChildrenKey.Confirm,
        content: t('home.pwaInstallTips'),
        confirmCb: () => {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
        },
      }),
    );

    openUrl(window.location.href);
  };

  return (
    <>
      {installStatus === 0 && (
        <Button onClick={handleClick} icon={IconName.Rocket}>
          {t('home.update')}
        </Button>
      )}
      {installStatus === 1 && <ProgressierOperatio className="!w-[6.78rem]" />}
      {installStatus === 2 && <Button onClick={handleStartAppClick}>{t('home.start_app')}</Button>}
    </>
  );
};

const Button = ({ onClick, children, icon }) => (
  <div
    className="w-[6.78rem] h-[.84rem] rounded-[.16rem] bg-[var(--colour-1)] text-[var(--colour-2)] mx-auto text-[.28rem] font-[700] flex items-center justify-center relative active:scale-[.95] transition-all select-none gap-[.18rem]"
    onClick={onClick}
  >
    {icon && (
      <div className="w-[.52rem] h-[.52rem] text-[--colour-2]">
        <Icon name={icon} />
      </div>
    )}
    {children}
  </div>
);

export default InstallButton;
