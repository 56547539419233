import { useEffect, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setUpdateGameHelprules,
  setFairCheckData,
  setUpdateGameHeight,
} from '../../redux/reducers/appSlice';
import AnalyticsManager from '../../manager/analyticsManager';
import useRouterHelper from '../../hooks/useRouterHelper';

/**
 * 游戏消息
 * @returns
 */
const GameMsg = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { handleClickWallet } = useRouterHelper();

  // 监听回调
  const iframeCallback = (rs) => {
    if (rs.data) {
      switch (rs.data.action) {
        case 'showRecharge':
          {
            handleClickWallet(1, 0);
          }
          break;
        case 'goBack':
          {
            handleClose && handleClose();
          }
          break;
        case 'updateGameHeight':
          {
            const data = rs.data.data;
            if (data) {
              dispatch(setUpdateGameHeight(data));
            }
          }
          break;
        case 'showHelp': // 显示帮助
          {
            const data = rs.data.data;
            if (data) {
              dispatch(setUpdateGameHelprules(data));
              dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GamehelpMes }));
            }
          }
          break;
        case 'showProvable': //显示公平性验证
          {
            dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.FairCheck }));
          }
          break;
        case 'updateProvable': // 更新公平性验证
          {
            const data = rs.data.data;
            if (data) {
              console.log('updateProvable', data);
              dispatch(setFairCheckData(data));
            }
          }
          break;
        case 'analytics':
          AnalyticsManager.FirebaseLogEvent(rs.data.key, rs.data.json);
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', iframeCallback);
    return () => {
      window.removeEventListener('message', iframeCallback);
    };
  }, []);

  return <></>;
};

export default memo(GameMsg);
