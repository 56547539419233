import React from 'react';
import Icon, { IconName } from '../../../../icon';
import cn from 'classnames';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import DownloadManager from '../../../../../manager/downloadManager';
import { setDownloadBoxPromptSwitch, setShowDownloadBoxPrompt } from '../../../../../redux/reducers/appSlice';
import { isPWA, isAndroidNative } from '../../../../../utils/deviceUtils';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import { isControllableSite, cmsImgUrl, isPCLayout } from '../../../../../utils/helper';
import { useDeepCompareEffect } from 'ahooks';
import { setpwaRoundID } from '../../../../../redux/reducers/userSlice';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig';

export default function NTemplate001() {
  const dispatch = useDispatch();
  const { download, downloadBoxPromptSwitch, showDownloadBoxPrompt, disconnect, websiteInfo, pwaRoundID } = useSelector(
    (state) => ({
      downloadBoxPromptSwitch: state.app.downloadBoxPromptSwitch,
      showDownloadBoxPrompt: state.app.showDownloadBoxPrompt,
      disconnect: state.app.disconnect,
      pwaRoundID: state.user.pwaRoundID,
      download: state.app.websiteInitConfig?.website_info?.download,
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const { getServerLangText } = useLangServerConfig();

  // 安卓下载
  const androidDownload = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_DownloadButton);
    DownloadManager.handleInstallAction();
  };

  //点击关闭按钮
  const clickClose = () => {
    dispatch(setShowDownloadBoxPrompt(false));
    if (!localStorage.getItem('pwaguide_box_prompt_num')) {
      localStorage.setItem('pwaguide_box_prompt_num', '1');
    } else {
      localStorage.setItem(
        'pwaguide_box_prompt_num',
        String(Number(localStorage.getItem('pwaguide_box_prompt_num')) + 1),
      );
    }
  };

  const getPriorityStyle = () => {
    const button = download?.top?.custom_pic?.button;
    const buttonColor = download?.top?.custom_pic?.button_color;

    if (button) {
      return '1';
    } else if (buttonColor) {
      return '2';
    } else {
      return '3';
    }
  };

  const getVestBagVisible = () => {
    if (window.android || window.Android) {
      //是马甲包
      if (isAndroidNative()) {
        //官方包直接不显示
        return false;
      } else {
        return download?.switch_unofficial_apk_download_show == '1';
      }
    } else if (DownloadManager.isIOSNative) {
      // ios原生包直接不显示
      return false;
    } else {
      //不是马甲包
      return DownloadManager.showAndroid || DownloadManager.showIOS || isPCLayout();
    }
  };

  //修改提示框显示状态
  useDeepCompareEffect(() => {
    const roundID = download?.pwa?.pwa_reward_conf.round;
    if (pwaRoundID == '') {
      dispatch(setpwaRoundID(roundID || ''));
    } else {
      if (roundID != pwaRoundID) {
        dispatch(setpwaRoundID(roundID || ''));
        localStorage.setItem('pwaguide_box_prompt_num', 0);
      }
    }

    dispatch(
      setDownloadBoxPromptSwitch(
        Boolean(
          download?.top?.switch == '1' &&
            getVestBagVisible() &&
            !isPWA() &&
            Number(localStorage.getItem('pwaguide_box_prompt_num')) < 5 &&
            showDownloadBoxPrompt &&
            !disconnect,
        ),
      ),
    );
    return () => {
      dispatch(setDownloadBoxPromptSwitch(false));
    };
  }, [download, showDownloadBoxPrompt, disconnect]);

  return (
    <div
      style={{
        background: download?.top?.custom_pic?.bg_color ? `${download?.top?.custom_pic?.bg_color}` : ``,
      }}
      className={cn(
        `w-full overflow-hidden select-none flex items-center justify-between relative transition-all pl-[.26rem] pr-[.2rem] ${!download.top.custom_pic.bg_color ? `${websiteInfo.website_theme == 'theme-15' ? '!bg-[var(--colour-2)]' : '!bg-[var(--colour-1)]'}` : ''}`,
        {
          'h-[var(--top-download-box-prompt-height)]  border-b-[.01rem] border-solid border-[var(--colour-6)]':
            downloadBoxPromptSwitch,
          'h-[0rem]': !downloadBoxPromptSwitch,
        },
      )}
    >
      <div
        className={cn(
          `w-[.2rem] h-[.2rem] ${websiteInfo.website_theme == 'theme-15' ? ' text-[var(--colour-1)]' : ' text-[var(--colour-2)]'}`,
        )}
        onClick={clickClose}
      >
        <Icon name={IconName.Close2} />
      </div>
      <div
        className={cn('relative h-full ml-[.26rem]')}
        style={{
          wordWrap: 'break-word',
          wordBreak: 'normal',
          width: '70.77%',
        }}
      >
        {download?.top?.custom_pic?.bg ? (
          <img className="absolute left-0 abs-y-center w-full" src={cmsImgUrl(download?.top?.custom_pic?.bg)} alt="" />
        ) : null}
        <div
          style={{ wordWrap: 'break-word', wordBreak: 'normal' }}
          className={`leading-[0.24rem] absolute  w-full left-0 abs-y-center z-[10] text-[.24rem] font-[500] text-center ${websiteInfo.website_theme == 'theme-15' ? ' text-[var(--colour-1)]' : ' text-[var(--colour-2)]'}`}
        >
          {getServerLangText(download?.top?.tip?.title)}
        </div>
      </div>
      <div className="flex items-center">
        <div
          className={cn(
            `w-[1.28rem] cursor-pointer !bg-[length:100%_100%] text-center font-[700] ml-[.32rem] mr-[.2rem] flex justify-center items-center select-none ${websiteInfo.website_theme == 'theme-15' ? 'text-[var(--colour-2)]' : 'text-[var(--colour-1)]'}  h-[.46rem] text-[1rem] rounded-[.08rem] ${getPriorityStyle() == '3' ? `${websiteInfo.website_theme == 'theme-15' ? '!bg-[var(--colour-1)]' : '!bg-[var(--colour-34)]'} ` : ''}`,
          )}
          onClick={androidDownload}
          style={{
            // animation: 'breathing 1.5s linear infinite',
            background:
              getPriorityStyle() == '1'
                ? `url(${cmsImgUrl(download?.top?.custom_pic?.button)}) no-repeat center center`
                : `${getPriorityStyle() == '2' ? `${download?.top?.custom_pic?.button_color}` : 'inherit'}`,
          }}
        >
          <span className="text-[.18em]">{getServerLangText(download?.top?.tip?.button_name)}</span>
        </div>
      </div>
    </div>
  );
}
