import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';
import DownloadManager from '../../../../manager/downloadManager';

const AppDownloadAndroid = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    DownloadManager.androidDownload();
  };

  return (
    <div
      className={`flex flex-row items-center justify-start cursor-pointer px-[.14rem] mb-[.2rem]`}
      onClick={handleClick}
    >
      <span className="block text-[var(--colour-12)] w-[.4rem] h-[.4rem] mr-[.08rem] ">
        <Icon name={IconName.DrawerDownload2} />
      </span>
      <span className="text-[var(--colour-14)] text-[.2rem] leading-[.24rem] relative font-[500]">
        {t('home.drawer_download')}
      </span>
    </div>
  );
};

export default AppDownloadAndroid;
