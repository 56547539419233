import { useState, useEffect } from 'react';
import Input from '../../input';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../icon';
import LimitClickWrapper from '../../limitClickWrapper';
import ElementWithLoading from '../../elementWithLoading';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../redux/reducers/appSlice';
import DialogModal from '../../dialogModal';
import WidthdrawQuestion from './widthdrawQuestion';
import WidthdrawRecord from './widthdrawRecord';
import TippyPopBox, { Placement } from '../../tippyPopBox';
import PopChoose, { PopChooseItem } from '../../popChoose';
import DropDownInput from '../../dropDownInput';
import cn from 'classnames';
import { ExchangeRate, WithdrawCrypto } from '../../../api/index';
import Loading2 from '../../loading2';
import { toast } from 'react-toastify';
import PayLogDialog from './payLogDialog';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import CheckBox from '../../checkBox';
import usePayConfig from '../../../hooks/usePayConfig';
import { setUserObj } from '../../../redux/reducers/userSlice';

import {
  pointToComma,
  commaToPoint,
  getShowMoneyNumber,
  putChangeText,
  putShowMoney,
  TextPlaces,
  isValidERCAddress,
  isValidTRXAddress,
  cutDecimal,
  currentTimeToEndTimeDiff,
  hasNestedProperty,
} from '../../../utils/helper';
// import config from '../../constants/config';
import { get } from 'lodash';
import BigNumber from 'bignumber.js';
// import globalConfig from '../../../constants/config';
// import Down from '../../../assets/img/down1.png';
import Countdown from 'react-countdown';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { PaymentDirectWithdrawBrlSubmitByAmount, PaymentDirectWithdrawConfig } from '../../../api/newDepositWithdraw';
import ConfirmTips from '../../confirmTips';

var dataCount = 0;
var timer = null;

const WithdrawBRL = ({
  handleGiftMoneyChange = () => {},
  OpenGiftMoneyDialog = () => {},
  withdrawConfigParams,
  isDebug = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userObj, websiteInitConfig } = useSelector(
    (state) => ({
      userObj: state.user.userObj,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const initConfig = withdrawConfigParams;

  const [loadingShow, setLoadingShow] = useState(true);
  // const [result, setResult] = useState(null);
  // const [faitSet, setFaitSet] = useState(0);
  const [dateTime, setDateTime] = useState(0);

  const [withdrawConfig, setWithdrawConfig] = useState(null);
  const [withdrawName, setWithdrawName] = useState('');
  const [withdrawMethodList, setWithdrawMethodList] = useState([]);
  const [currentWithdrawMethod, setCurrentWithdrawMethod] = useState(null);

  const [submitForm, setSubmitForm] = useState({
    // 新提交字段
    method: '', // 支付方式
    name: '', // 姓名
    cpf: '', // 巴西个人身份识别号码
    phone: '', // 手机号(method=phone时此字段必传)
    email: '', // 邮箱(method=email时此字段必传)
    amount: '', // 提现金额
    config_id: '', // 配置id
    cnpj: '', // 巴西企业身份识别号码(method=cnpj时此字段必传)
    evp: '', // 随机密钥（PIX 系统为帐户生成的数字）或虚拟支付地址(method=evp时此字段必传)
  });

  const [requesting, setRequesting] = useState(false);
  // 提现消息
  const [isOpen, setIsOpen] = useState(false);
  // 提现record
  const [isOpenRecord, setIsOpenRecord] = useState(false);

  //提现消息msg
  const [payLog, setPayLog] = useState(false);
  const [cryptoAddress, setCryptoAddress] = useState('');

  // const [channelList, setChannelList] = useState([]);
  const [network, setNetWork] = useState(null);
  const [crypto, setCrypto] = useState(null);
  // const [channel, setChannel] = useState(null);
  const [withdraw_fee, setWithdraw_fee] = useState('');
  // const [bonus_withdraw_fee, setBonus_withdraw_fee] = useState('');
  const [historyList, setHistoryList] = useState([]);
  const [curHistory, setCurHistory] = useState([]);
  const [nameVisible, setNameVisible] = useState(false);

  const [isPixTypeVisible, setIsPixTypeVisible] = useState(false);
  const [refresh, setRefrensh] = useState(false);
  const [cryptoList, setCryptoList] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [widthdrawType, setWidthdrawType] = useState(1); // 1法币 2数字币
  const [currentHistory, setCurrentHistory] = useState(null);
  const [decimal, setDecimal] = useState(2);
  const [isEnableCryptoWithdraw, setIsEnableCryptoWithdraw] = useState(false);

  const CountDowns = () => <Countdown zeroPadTime={2} date={dateTime} renderer={renderer} />;

  useEffect(() => {
    if (websiteInitConfig) {
      if (hasNestedProperty(websiteInitConfig, 'system_config.currency_decimal_point_precision')) {
        setDecimal(websiteInitConfig.system_config.currency_decimal_point_precision);
      }
      setIsEnableCryptoWithdraw(websiteInitConfig?.website_info?.switch_wallet_crypto_withdraw == '1');

      if (websiteInitConfig?.website_info?.switch_wallet_crypto_withdraw == '1') {
        // currency_default_location 提现页面默认货币（0:法币,1:虚拟币）
        if (websiteInitConfig?.website_info?.currency_default_location == '1') {
          setWidthdrawType(2);
        }
      }
    }
  }, [websiteInitConfig]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, []);

  useEffect(() => {
    // getInitData();
    upDateWithdrawConfig();
  }, []);

  const { gift_money_menu_show_switch } = usePayConfig();
  useEffect(() => {
    handleGiftMoneyChange(gift_money_menu_show_switch);
  }, [gift_money_menu_show_switch]);

  useEffect(() => {
    if (withdrawConfig) {
      dispatch(
        setUserObj({
          ...userObj,
          balance: withdrawConfig.user_obj.balance,
          locked_balance: withdrawConfig.user_obj.locked_balance,
        }),
      );

      setWithdraw_fee(withdrawConfig?.withdraw_fee);

      // setBonus_withdraw_fee(withdrawConfig?.bonus_withdraw_fee);

      const _withdrawName = 'PIX'; //get(withdrawConfig, 'withdraw_method[0].withdraw_config.withdraw_name');
      if (_withdrawName) {
        setWithdrawName(_withdrawName);
      }

      // const _withdrawMethodList = withdrawConfig?.withdraw_method;
      // if (_withdrawMethodList.length > 0) {
      //   setWithdrawMethodList(_withdrawMethodList);
      //   const _currentWithdrawMethod = _withdrawMethodList[0];
      //   setCurrentWithdrawMethod(_currentWithdrawMethod);
      //   setSubmitForm({
      //     ...submitForm,
      //     method: _currentWithdrawMethod.method,
      //     config_id: _currentWithdrawMethod.withdraw_config.id,
      //   });
      // }

      const _withdrawMethodList = withdrawConfig?.withdraw_amount_limit;
      if (_withdrawMethodList.length > 0) {
        setWithdrawMethodList(_withdrawMethodList);
        const _currentWithdrawMethod = _withdrawMethodList[0];
        setCurrentWithdrawMethod(_currentWithdrawMethod);
        setSubmitForm({
          ...submitForm,
          method: _currentWithdrawMethod.method,
          config_id: 0,
        });
      }

      if (isEnableCryptoWithdraw) {
        if (withdrawConfig?.crypto_configs && withdrawConfig?.crypto_configs.length > 0) {
          const mergedData = withdrawConfig.crypto_configs.reduce((acc, item) => {
            const existingItem = acc.find((accItem) => accItem.currency === item.currency);
            if (existingItem) {
              existingItem.networks.push(item);
            } else {
              item.networks = [item];
              acc.push(item);
            }
            return acc;
          }, []);

          setCryptoList(mergedData);

          getExchangeRateData();

          setCrypto(mergedData[0]);

          if (mergedData[0] && mergedData[0].networks && mergedData[0].networks.length > 0) {
            setNetWork(mergedData[0].networks[0]);
          }
        }
      }

      if (withdrawConfig.withdraw_account && withdrawConfig.withdraw_account.length > 0) {
        setHistoryList(withdrawConfig.withdraw_account);
      }
    }
  }, [withdrawConfig]);

  useEffect(() => {
    if (historyList.length > 0) {
      if (currentWithdrawMethod) {
        const _curHistory = historyList.filter((item) => item.method == currentWithdrawMethod.method);
        if (_curHistory.length > 0) {
          const firstHistory = _curHistory[0];
          setCurHistory(_curHistory);
          setCurrentHistory(firstHistory);
          setSubmitForm({
            ...submitForm,
            name: firstHistory.name,
            cpf: firstHistory.cpf,
            email: firstHistory.email,
            phone: firstHistory.phone,
            cnpj: firstHistory.cnpj,
            evp: firstHistory.evp,
          });
        } else {
          setCurHistory([]);
          setCurrentHistory(null);
          setSubmitForm({
            ...submitForm,
            name: '',
            cpf: '',
            email: '',
            phone: '',
            cnpj: '',
            evp: '',
          });
        }
      }
    }
  }, [historyList, currentWithdrawMethod]);

  // useEffect(() => {
  //   if (channel && historyList) {
  //     setCurHistory(historyList[channel.network.toLowerCase()] || []);
  //     if (historyList[channel.network.toLowerCase()] && historyList[channel.network.toLowerCase()].length > 0) {
  //       setSubmitForm({
  //         ...submitForm,
  //         name: historyList[channel.network.toLowerCase()][0].name,
  //         cpf: historyList[channel.network.toLowerCase()][0].cpf,
  //         dict_key: historyList[channel.network.toLowerCase()][0].cpf,
  //       });
  //     }
  //   }
  // }, [channel, historyList]);

  useEffect(() => {
    if (exchangeRate && exchangeRate.length > 0 && cryptoList && cryptoList.length > 0) {
      const result = exchangeRate.find(
        (item) => item.crypto === crypto?.currency && item.fiat === initConfig.currency_code,
      );
      if (result) {
        var time = currentTimeToEndTimeDiff('', result.expire_timestamp);
        time = time * 1000;
        setDateTime(Date.now() + time);
      }
    }
  }, [exchangeRate, cryptoList]);

  if (isDebug) {
    console.log('withdrawConfig', withdrawConfig);
    console.log('cryptoList', cryptoList);
    console.log('crypto', crypto);
    console.log('isEnableCryptoWithdraw', isEnableCryptoWithdraw);
    console.log('netWork', network);
    console.log('withdrawMethodList', withdrawMethodList);
    console.log('currentWithdrawMethod', currentWithdrawMethod);
    console.log('widthdrawType', widthdrawType);
    console.log('historyList', historyList);
    console.log('curHistory', curHistory);
    console.log('currentHistory', currentHistory);
    console.log('default withdraw type', websiteInitConfig?.website_info?.currency_default_location);
  }

  const cpfRegx = /^[0-9]{11}$/;
  const cpfInputRegx = /^[0-9]{0,11}$/;
  const emailRegx = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  const phoneRegx = /^[0-9]{11}$/;
  const phoneInputRegx = /^[0-9]{0,11}$/;
  const cnpjRegx = /^[0-9]{14}$/;
  const cnpjInputRegx = /^[0-9]{0,14}$/;
  const evpRegx = /^.{36}$/;
  const evpInputRegx = /^.{0,36}$/;

  // const getInitData = () => {
  //   setLoadingShow(true);

  //   WithdrawInit()
  //     .then((res) => {
  //       setLoadingShow(true);

  //       if (res.code == 0) {
  //         dispatch(setWithDrawChange(!withdrawChange));

  //         const result = res.result;
  //         setResult(result);
  //         dispatch(
  //           setUserObj({
  //             ...result.user_obj,
  //           }),
  //         );

  //         setWithdraw_fee(result.withdraw_fee);
  //         setBonus_withdraw_fee(result.bonus_withdraw_fee);
  //         setHistoryList(result.history);
  //         handleGiftMoneyChange(result.gift_money_menu_show_switch);
  //         if (result.gift_money_menu_show_switch == 1) {
  //           setGiftShow(true);
  //         } else {
  //           setGiftShow(false);
  //         }

  //         ChangeDataArray(result);
  //       } else {
  //         toast.error(res.msg, {
  //           containerId: 'global',
  //         });
  //       }
  //     })
  //     .finally(() => {
  //       setLoadingShow(false);
  //     });
  // };

  const upDateWithdrawConfig = () => {
    setLoadingShow(true);

    PaymentDirectWithdrawConfig()
      .then((res) => {
        setLoadingShow(false);

        if (res.code == 0) {
          setWithdrawConfig(res.data);
        }
      })
      .finally(() => {
        setLoadingShow(false);
      });
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      if (dataCount == 0) {
        setTimeout(() => {
          if (!refresh) {
            setTimeout(() => {
              setRefrensh(false);
            }, 1500);

            setRefrensh(true);
          }
          getExchangeRateData();
        }, 150);

        dataCount++;
      } else {
        if (!timer) {
          dataCount++;
          timer = setInterval(() => {
            if (!refresh) {
              setTimeout(() => {
                setRefrensh(false);
              }, 1500);
              setRefrensh(true);
            }

            getExchangeRateData();
          }, 6000);
        }
      }

      return <span className="text-[var(--colour-41)]">(00:00)</span>;
    } else {
      // Render a countdown
      dataCount = 0;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }

      return (
        <span className="text-[var(--colour-41)]">
          ({minutes < 10 ? String(0) + String(minutes) : minutes}:{seconds < 10 ? String(0) + String(seconds) : seconds}
          )
        </span>
      );
    }
  };

  const handleWithdrawCyorp = () => {
    let banlace = moneyReturn();
    var rate1 = getExchangeRate();
    if (rate1 <= 0) {
      toast.info(t('deposit-withdraw.incorrect_amount'), {
        containerId: 'global',
      });
      return;
    }
    if (!commaToPoint(submitForm.amount)) {
      toast.info(t('deposit-withdraw.enter_amount'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(commaToPoint(submitForm.amount)) > Number(banlace)) {
      toast.info(t('deposit-withdraw.amount_exceeds'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(commaToPoint(submitForm.amount)) < Number(getShowMoneyNumber(network?.withdraw_min || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit1', {
          min_amount: getShowMoneyNumber(network?.withdraw_min || 0),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    if (Number(commaToPoint(submitForm.amount)) > Number(getShowMoneyNumber(network?.withdraw_max || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit2', {
          max_amount: getShowMoneyNumber(network?.withdraw_max || 0),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }
    if (!cryptoAddress) {
      toast.info(t('deposit-withdraw.enter_wallet'), {
        containerId: 'global',
      });
      return;
    }

    if (network.network.toLowerCase().indexOf('trc') != -1) {
      if (!isValidTRXAddress(cryptoAddress)) {
        toast.info(t('deposit-withdraw.end_address123'), {
          containerId: 'global',
        });
        return;
      }
    } else if (network.network.toLowerCase().indexOf('erc') != -1) {
      if (!isValidERCAddress(cryptoAddress)) {
        toast.info(t('deposit-withdraw.end_address123'), {
          containerId: 'global',
        });
        return;
      }
    }

    setRequesting(true);

    WithdrawCrypto({
      network: network.network,
      pay_id: network.id,
      crypto_currency: crypto?.currency,
      crypto_address: cryptoAddress,
      crypto_network: network.network,
      amount: putShowMoney(commaToPoint(submitForm.amount)),
      // currency: submitForm.currency,
      currency: '1',
    })
      .then((res) => {
        if (res.code == 0) {
          setPayLog(true);
          // getInitData();
          upDateWithdrawConfig();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const getSubmitFormFields = (obj) => {
    //后面这个是必须字段，其他都不需要，如果带了，就会提示参数错误
    //method=cpf   method,name,cpf,amount
    //method=phone   method,name,cpf,amount,phone
    //method=email   method,name,cpf,amount,email
    //method=cnpj   method,name,cpf,amount,cnpj
    //method=evp    method,name,cpf,amount,evp
    // method: '', // 支付方式
    // name: '', // 姓名
    // cpf: '', // 巴西个人身份识别号码
    // phone: '', // 手机号(method=phone时此字段必传)
    // email: '', // 邮箱(method=email时此字段必传)
    // amount: '', // 提现金额
    // config_id: '', // 配置id
    // cnpj: '', // 巴西企业身份识别号码(method=cnpj时此字段必传)
    // evp: '', // 随机密钥（PIX 系统为帐户生成的数字）或虚拟支付地址(method=evp时此字段必传)
    const commonObj = {
      method: obj.method,
      name: String(obj.name).trim(),
      cpf: obj.cpf,
      amount: putShowMoney(commaToPoint(obj.amount || 0)),
    };
    switch (String(obj.method).toLowerCase()) {
      case 'cpf':
        return commonObj;
      case 'phone':
        return { ...commonObj, phone: obj.phone };
      case 'email':
        return { ...commonObj, email: obj.email };
      case 'cnpj':
        return { ...commonObj, cnpj: obj.cnpj };
      case 'evp':
        return { ...commonObj, evp: obj.evp };
    }
  };

  const handleWithdraw = () => {
    let banlace = moneyReturn();

    if (!commaToPoint(submitForm.amount)) {
      toast.info(t('deposit-withdraw.enter_amount'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(commaToPoint(submitForm.amount)) > Number(banlace)) {
      toast.info(t('deposit-withdraw.amount_exceeds'), {
        containerId: 'global',
      });
      return;
    }

    // if (Number(commaToPoint(submitForm.amount)) < Number(getShowMoneyNumber(channel?.withdraw_min || 0))) {
    if (Number(commaToPoint(submitForm.amount)) < Number(getShowMoneyNumber(currentWithdrawMethod?.min || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit1', {
          min_amount: getShowMoneyNumber(currentWithdrawMethod?.min || 0),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    // if (Number(commaToPoint(submitForm.amount)) > Number(getShowMoneyNumber(channel?.withdraw_max || 0))) {
    if (Number(commaToPoint(submitForm.amount)) > Number(getShowMoneyNumber(currentWithdrawMethod?.max || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit2', {
          max_amount: getShowMoneyNumber(currentWithdrawMethod?.max || 0),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    if (!submitForm.name || submitForm.name.trim() == '') {
      toast.info(t('deposit-withdraw.enter_username'), {
        containerId: 'global',
      });
      return;
    }

    // if (/^[a-zA-Z ,.]{1,100}$/.test(submitForm.name) === false) {
    //   toast.info(t('deposit-withdraw.enter_username'), {
    //     containerId: 'global',
    //   });
    //   return;
    // }

    if (!cpfRegx.test(submitForm.cpf)) {
      toast.info(t('1008033'), {
        containerId: 'global',
      });
      return;
    }

    if (currentWithdrawMethod.method == 'email') {
      if (!emailRegx.test(submitForm.email)) {
        toast.info(t('1008013'), {
          containerId: 'global',
        });
        return;
      }
    }

    if (currentWithdrawMethod.method == 'phone') {
      if (!phoneRegx.test(submitForm.phone)) {
        toast.info(t('1008016'), {
          containerId: 'global',
        });
        return;
      }
    }

    if (currentWithdrawMethod.method == 'cnpj') {
      if (!cnpjRegx.test(submitForm.cnpj)) {
        toast.info(t('1008034'), {
          containerId: 'global',
        });
        return;
      }
    }

    if (currentWithdrawMethod.method == 'evp') {
      if (!evpRegx.test(submitForm.evp)) {
        toast.info(t('deposit-withdraw.evp_format_error'), {
          // toast.info('evp format error', {
          containerId: 'global',
        });
        return;
      }
    }

    console.log('submitForm', submitForm);

    setRequesting(true);

    // WithdrawAction({
    PaymentDirectWithdrawBrlSubmitByAmount({
      ...getSubmitFormFields(submitForm),
    })
      .then((res) => {
        if (res.code == 0) {
          setPayLog(true);
          // getInitData();
          upDateWithdrawConfig();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const moneyReturn = () => {
    const balance = get(userObj, 'balance');
    const locked_balance = get(userObj, 'locked_balance');

    if (balance) {
      const val2 = getShowMoneyNumber(BigNumber(balance).minus(locked_balance).toFixed());
      return val2 <= 0 ? 0 : Number(cutDecimal(val2, decimal));
    } else {
      return 0;
    }
  };

  const getFee = () => {
    // if (channel) {
    //   let fee;
    //   if (currency == 1) {
    //     fee = withdraw_fee;
    //   } else {
    //     fee = bonus_withdraw_fee;
    //   }

    //   if (commaToPoint(submitForm.amount)) {
    //     let valFee = String(Number(commaToPoint(commaToPoint(submitForm.amount))) * Number(fee));
    //     return Math.floor(Number(valFee) * 100) / 100;
    //   } else {
    //     return '0';
    //   }
    // } else {
    //   return '0';
    // }

    // let fee;
    // if (currency == 1) {
    //   fee = withdraw_fee;
    // } else {
    //   fee = bonus_withdraw_fee;
    // }

    let fee = withdraw_fee;

    if (commaToPoint(submitForm.amount)) {
      let valFee = String(Number(commaToPoint(commaToPoint(submitForm.amount))) * Number(fee));
      return Math.floor(Number(valFee) * 100) / 100;
    } else {
      return '0';
    }
  };

  // const pixTypeList = [
  //   {
  //     name: 'CPF',
  //     value: 'CPF',
  //   },
  //   {
  //     name: 'Email',
  //     value: 'EMAIL',
  //   },
  //   {
  //     name: 'Phone',
  //     value: 'PHONE',
  //   },
  // ];

  const getExchangeRate = () => {
    if (exchangeRate && exchangeRate.length > 0) {
      const result = exchangeRate.find(
        (item) => item.crypto === crypto?.currency && item.fiat === initConfig.currency_code,
      );
      return result ? Number(Number(result.withdraw_rate).toFixed(4)) : 0;
    } else {
      return 0;
    }
  };

  const getExchangeRateData = () => {
    ExchangeRate().then((res) => {
      if (res.code == 0) {
        if (res.result) {
          setExchangeRate(res.result.list);
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  // const ChangeDataArray = (data) => {
  //   var arrayFiat = [];
  //   var arrayCrypto = [];
  //   if (data && data.withdraw_list && data.withdraw_list.length > 0) {
  //     // 重新组建数组
  //     data.withdraw_list.map((item) => {
  //       if (item.type == 'crypto') {
  //         arrayCrypto.push(item);
  //       } else {
  //         arrayFiat.push(item);
  //       }
  //     });

  //     if (arrayFiat && arrayFiat.length > 0) {
  //       const firstChannel = arrayFiat[0];
  //       if (firstChannel) {
  //         setChannel(firstChannel);
  //       }

  //       setChannelList(arrayFiat);
  //     }

  //     // 如果数组有就开始遍历
  //     if (arrayCrypto && arrayCrypto.length > 0) {
  //       // 创建一个空对象，用于存储相同"currency"的对象
  //       if (data.wallet_crypto_switch != '1') {
  //         setCryptoList([]);
  //       } else {
  //         const mergedData = arrayCrypto.reduce((acc, item) => {
  //           const existingItem = acc.find((accItem) => accItem.currency === item.currency);
  //           if (existingItem) {
  //             existingItem.networks.push(item);
  //           } else {
  //             item.networks = [item];
  //             acc.push(item);
  //           }
  //           return acc;
  //         }, []);
  //         setCryptoList(mergedData);

  //         // 新数据
  //         if (data.currency_default_location == 1) {
  //           setWidthdrawType(2);
  //           setFaitSet(data.currency_default_location);
  //         }
  //         getExchangeRateData();
  //         setCrypto(mergedData[0]);
  //         if (mergedData[0] && mergedData[0].networks && mergedData[0].networks.length > 0) {
  //           setNetWork(mergedData[0].networks[0]);
  //         }
  //       }
  //     }
  //   }
  // };

  // 选择1还是选择2 1正常充值 2区块链充值

  const getAmountFee = () => {
    // gas fee转金额
    var gasPrice = Number(getShowMoneyNumber(network?.fee || 0, 6)) * getExchangeRate();

    if (Number(gasPrice) + Number(getFee()) >= Number(commaToPoint(submitForm?.amount || 0))) {
      return 0;
    } else {
      // 手续费内容 汇率 1usdt = 多少代币
      var rate = Number(1 / getExchangeRate());
      // 金额
      var amountFee = Number(Number(commaToPoint(submitForm?.amount || 0)) - gasPrice - Number(getFee()));
      return Number(Number(rate * amountFee).toFixed(6));
    }
  };

  const handlePixTypeChange = (item) => {
    setCurrentWithdrawMethod(item);
    setIsPixTypeVisible(false);
    setSubmitForm({
      ...submitForm,
      method: item.method,
      config_id: 0,
    });
  };

  const handleChangeAmount = (ev) => {
    setSubmitForm({
      ...submitForm,
      amount: putChangeText(ev.target.value),
    });
  };

  const handleNameChange = (ev) => {
    let val = ev.target.value;

    // val = val.replace(/[^a-zA-Z ,.]+/g, '');
    // val = val.slice(0, 100);
    val = val.slice(0, 40);

    setSubmitForm({
      ...submitForm,
      name: val,
    });
  };

  const handleEmailChange = (ev) => {
    setSubmitForm({
      ...submitForm,
      email: ev.target.value,
    });
  };

  const handlePhoneChange = (ev) => {
    const val = ev.target.value;

    if (phoneInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        phone: val,
      });
    }
  };

  const handleCpfChange = (ev) => {
    const val = ev.target.value;

    if (cpfInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        cpf: val,
      });
    }
  };

  const handleCnpjChange = (ev) => {
    const val = ev.target.value;

    if (cnpjInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        cnpj: val,
      });
    }
  };

  const handleEvpChange = (ev) => {
    const val = ev.target.value;

    if (evpInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        evp: val,
      });
    }
  };

  const handleSelectHistory = (item) => {
    setSubmitForm({
      ...submitForm,
      name: item.name,
      cpf: item.cpf,
      email: item.email,
      phone: item.phone,
      cnpj: item.cnpj,
      evp: item.evp,
    });

    setNameVisible(false);

    setCurrentHistory(item);
  };

  useEffect(() => {
    if (!(currentWithdrawMethod != null && isEnableCryptoWithdraw && cryptoList.length > 0)) {
      if (currentWithdrawMethod != null) {
        setWidthdrawType(1);
      } else if (isEnableCryptoWithdraw && cryptoList.length > 0) {
        setWidthdrawType(2);
      }
    }
  }, [currentWithdrawMethod, isEnableCryptoWithdraw, cryptoList]);

  return (
    <>
      <DialogModal
        onRequestClose={() => {
          setIsOpen(false);
        }}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '50',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isOpen}
      >
        <WidthdrawQuestion
          decimalPoit={decimal}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsOpenRecord(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '50',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isOpenRecord}
      >
        <WidthdrawRecord
          handleClose={() => {
            setIsOpenRecord(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
          setPayLog(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={payLog}
      >
        <PayLogDialog
          handleClose={() => {
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.Default,
              }),
            );
            setPayLog(false);
          }}
        />
      </DialogModal>
      <ConfirmTips type="withdraw" />

      {loadingShow ? (
        <Loading2 className="h-full" />
      ) : (
        // result && ((channelList && channelList.length > 0) || (cryptoList && cryptoList.length > 0)) && (
        (currentWithdrawMethod || (isEnableCryptoWithdraw && crypto)) && (
          <>
            <div className="mt-[.4rem] pb-[.4rem] w-full max-w-full overflow-x-auto scrollbar-none">
              <div className="mt-[.2rem] flex justify-between items-center ">
                <div className="flex justify-between flex-1 h-[.92rem] px-[.3rem] items-center text-[.28rem] border border-[var(--colour-6)] bg-[var(--colour-34)] rounded-normal">
                  <div className="text-[.28rem] text-[var(--colour-44)]">{t('deposit-withdraw.withdrawable')}</div>
                  <div className="text-[.28rem] text-[var(--colour-38)]">{pointToComma(moneyReturn())}</div>
                </div>

                {/* {currency == 1 && ( */}
                <span
                  className="active:scale-[.95] transition-all select-none cursor-pointer ml-[.3rem] w-[.4rem] h-[.4rem] text-[var(--colour-7)]"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <Icon name={IconName.WidthdrawQuestion} />
                </span>
                {/* )} */}
              </div>

              {websiteInitConfig?.website_info?.switch_gift_money_menu_show == '1' && (
                <>
                  <div
                    className={`cursor-pointer text-[.24rem] mt-[.32rem] text-left font-[500] underline underline-offset-4 text-[var(--colour-41)]`}
                    onClick={() => {
                      OpenGiftMoneyDialog();
                    }}
                  >
                    {t('account.fund')}
                  </div>
                  <div className=" mt-[.2rem] text-[var(--colour-3)]">
                    <Input
                      leftOuter={
                        <div className="flex items-center">
                          <div className="text-[.32rem] font-[700] block m-[0_.2rem_0_.28rem] text-[var(--colour-38)]">
                            {initConfig?.currency_symbol || ''}
                          </div>
                        </div>
                      }
                      type="text"
                      disabled
                      value={pointToComma(getShowMoneyNumber(withdrawConfig?.user_claim_money?.total_money || 0))}
                      placeholder={t('deposit-withdraw.enter_amount')}
                      innerClassName="!h-[.92rem] !border-transparent"
                      topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                      inputClassName="text-[var(--colour-38)]"
                    />
                  </div>
                </>
              )}

              {/* {cryptoList && cryptoList.length > 0 && channelList && channelList.length > 0 && ( */}
              {currentWithdrawMethod && isEnableCryptoWithdraw && crypto && (
                <div className=" mt-[.4rem]">
                  {/* {cryptoList && cryptoList.length > 0 && channelList && channelList.length > 0 && ( */}
                  <div className=" text-[.26rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.currency_type')}
                  </div>
                  {/* )} */}
                  <div
                    className={cn(`flex gap-[.1rem]  flex-wrap mt-[.2rem]`, {
                      'flex-row-reverse':
                        isEnableCryptoWithdraw && websiteInitConfig?.website_info?.currency_default_location == '1',
                    })}
                  >
                    <div
                      onClick={() => {
                        setWidthdrawType(1);
                      }}
                      className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center flex-1  h-[.8rem] rounded-normal ${
                        widthdrawType == 1
                          ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                          : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                      } `}
                    >
                      <span className={`ml-[.04rem] text-[.24rem] font-[700]`}>{t('deposit-withdraw.fiat')}</span>
                    </div>
                    <div
                      onClick={() => {
                        setWidthdrawType(2);
                      }}
                      className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center flex-1  h-[.8rem] rounded-normal ${
                        widthdrawType == 2
                          ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                          : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                      }`}
                    >
                      <span className={`ml-[.04rem] text-[.24rem] font-[700]`}>{t('deposit-withdraw.crypto')}</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="  text-[.26rem] mt-[.4rem] text-left font-[500] text-[var(--colour-41)]">
                {t('deposit-withdraw.amount')}
              </div>

              <div className=" mt-[.2rem]">
                <Input
                  leftOuter={
                    <div className="flex items-center">
                      <div className="block text-[.28rem] m-[0_.2rem_0_.28rem] text-[var(--colour-38)]">
                        {initConfig.currency_symbol || ''}
                      </div>
                    </div>
                  }
                  type="number"
                  onChange={handleChangeAmount}
                  value={submitForm.amount}
                  placeholder={
                    widthdrawType == 1
                      ? t('deposit-withdraw.input_amount', {
                          min: pointToComma(getShowMoneyNumber(currentWithdrawMethod?.min || 0)),
                          max: pointToComma(getShowMoneyNumber(currentWithdrawMethod?.max || 0)),
                        })
                      : t('deposit-withdraw.input_amount', {
                          min: pointToComma(getShowMoneyNumber(network?.withdraw_min || 0)),
                          max: pointToComma(getShowMoneyNumber(network?.withdraw_max || 0)),
                        })
                  }
                  innerClassName=" !h-[.92rem] !border-transparent"
                  topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                  inputClassName="!text-[var(--colour-38)]"
                />
                <span className="  w-[.4rem] h-[.4rem]"></span>
              </div>

              <div className=" mt-[.2rem] text-left font-[500]">
                <div className=" text-[.24rem] text-[var(--colour-1)]">
                  {t('deposit-withdraw.withdrawal_fee')}: {initConfig.currency_symbol || ''} {pointToComma(getFee())}
                </div>
                {widthdrawType == 1 && (
                  <div className="  text-[.26rem] mt-[.1rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('home.payment')}
                  </div>
                )}
              </div>

              {widthdrawType == 1 && (
                <div>
                  <div className=" mt-[.2rem] flex flex-row items-center border border-[var(--colour-6)] bg-[var(--colour-34)] rounded-normal">
                    <div className="flex items-center flex-wrap-wrap  px-[.28rem] w-full overflow-x-scroll hidden-scroll-y">
                      {withdrawName && (
                        <div
                          className={cn(
                            `flex items-center justify-start   h-[.92rem] text-[.24rem] font-[400] min-w-[33.1%]`,
                          )}
                        >
                          <CheckBox checked={true} className="  w-[.48rem] h-[.48rem] cursor-pointer" />

                          <div className={`  text-[.24rem] ml-[.16rem] font-[500] text-left text-[var(--colour-3)]`}>
                            {withdrawName}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                      {t('deposit-withdraw.type')}
                    </div>

                    <div className=" mt-[.2rem]">
                      <div className="flex bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-normal">
                        <TippyPopBox
                          placement={Placement.bottomEnd}
                          popChildren={
                            <PopChoose
                              className={`w-[6.52rem]  overflow-y-auto  max-h-[5rem] scrollbar-none border border-[var(--colour-6)] bg-[var(--colour-34)]  py-[.14rem] z-[-1] aotuWdith`}
                              children={withdrawMethodList.map((item, index) => (
                                <PopChooseItem
                                  className="!h-[.92rem]  flex cursor-pointer items-center  text-[.24rem] font-[500] "
                                  chooseClassName=""
                                  key={index}
                                  data={item}
                                  choose={currentWithdrawMethod?.method == item.method}
                                  onClick={() => handlePixTypeChange(item)}
                                >
                                  <span
                                    className={cn(
                                      ` pl-[.2rem] !h-[.92rem]  w-full flex items-center justify-start rounded-normal  text-[.28rem] font-[500] `,
                                      {
                                        'text-[var(--colour-38)]': currentWithdrawMethod?.method == item.method,
                                        'text-[var(--colour-44)]': currentWithdrawMethod?.method != item.method,
                                      },
                                    )}
                                  >
                                    {item.method.toUpperCase()}
                                  </span>
                                </PopChooseItem>
                              ))}
                            />
                          }
                          visible={isPixTypeVisible}
                          onClickOutside={() => {
                            setIsPixTypeVisible(false);
                          }}
                          offset={[0, 2]}
                        >
                          <div
                            className="w-full   h-[.92rem] px-[.28rem] flex items-center justify-between cursor-pointer"
                            onClick={() => setIsPixTypeVisible(!isPixTypeVisible)}
                          >
                            <div className="text-[.28rem] font-[500] text-[var(--colour-38)]">
                              {currentWithdrawMethod?.method.toUpperCase()}
                            </div>
                            <div
                              className={cn('  w-[.36rem] h-[.18rem] block transition text-[var(--colour-11)]', {
                                'rotate-[180deg]': isPixTypeVisible,
                              })}
                            >
                              <Icon name={IconName.SingleArrow} />
                            </div>
                          </div>
                        </TippyPopBox>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className=" text-[.26rem]  mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                      {t('deposit-withdraw.beneficiary_name')} <span className="text-[var(--colour-25)]">*</span>
                    </div>

                    <div className=" mt-[.2rem]">
                      <div className="flex bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-normal">
                        <div className="flex-1">
                          <Input
                            type="text"
                            value={submitForm.name}
                            onChange={handleNameChange}
                            placeholder={t('deposit-withdraw.enter_username')}
                            innerClassName=" !h-[.92rem] !border-transparent"
                            topClassName={cn(`border-none overflow-hidden`, {
                              '!rounded-r-none': curHistory.length > 0,
                            })}
                          />
                        </div>

                        {curHistory.length > 0 && (
                          <div>
                            <TippyPopBox
                              placement={Placement.bottomEnd}
                              popChildren={
                                <PopChoose
                                  className={`w-[6.56rem]    max-h-[5rem] py-[.14rem] overflow-y-auto scrollbar-none border border-[var(--colour-6)] bg-[var(--colour-34)] aotuWdith`}
                                  children={curHistory.map((item, index) => (
                                    <PopChooseItem
                                      className="!h-[.92rem]  flex cursor-pointer items-center  text-[.24rem] font-[500] "
                                      chooseClassName="text-[var(--colour-44)]"
                                      key={index}
                                      data={item}
                                      // choose={submitForm.dict_key == item.dict_key}
                                      choose={item.id == currentHistory.id}
                                      // onClick={() => {
                                      //   setSubmitForm({
                                      //     ...submitForm,
                                      //     name: item.name,
                                      //     cpf: item.cpf,
                                      //     dict_key: item.cpf,
                                      //   });
                                      //   setNameVisible(false);
                                      // }}
                                      onClick={() => handleSelectHistory(item)}
                                    >
                                      <span
                                        className={` pl-[.2rem] !h-[.92rem]  w-full flex items-center justify-start rounded-normal ${item.id == currentHistory.id ? 'text-[var(--colour-2)] bg-[var(--colour-1)]' : 'text-[var(--colour-44)]'}`}
                                      >
                                        {item.name}
                                      </span>
                                    </PopChooseItem>
                                  ))}
                                />
                              }
                              visible={nameVisible}
                              onClickOutside={() => {
                                setNameVisible(false);
                              }}
                              offset={[0, 10]}
                            >
                              <div className="pr-[0.28rem]">
                                <DropDownInput
                                  iconCanRotate={true}
                                  iconRotate={nameVisible}
                                  className={`w-full  !h-[.92rem]  text-[.24rem] font-[500] cursor-pointer !justify-center rounded-normal !rounded-l-none`}
                                  onClick={() => {
                                    setNameVisible(!nameVisible);
                                  }}
                                >
                                  <div className="flex flex-row items-center justify-right">
                                    <span
                                      className={cn('w-[.36rem] h-[.18rem] block transition text-[var(--colour-11)]', {
                                        'rotate-[180deg]': nameVisible,
                                      })}
                                    >
                                      <Icon name={IconName.SingleArrow} />
                                    </span>
                                  </div>
                                </DropDownInput>
                              </div>
                            </TippyPopBox>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {currentWithdrawMethod?.method == 'email' && (
                    <div>
                      <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                        {t('deposit-withdraw.email')} <span className="text-[var(--colour-25)]">*</span>
                      </div>

                      <div className=" mt-[.2rem]">
                        <Input
                          value={submitForm.email}
                          onChange={handleEmailChange}
                          placeholder={t('deposit-withdraw.enter_email')}
                          innerClassName=" !h-[.92rem] !border-transparent"
                          topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                        />
                        <span className="  w-[.4rem] h-[.4rem]"></span>
                      </div>
                    </div>
                  )}

                  {currentWithdrawMethod?.method == 'phone' && (
                    <div>
                      <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                        {t('deposit-withdraw.phone')} <span className="text-[var(--colour-25)]">*</span>
                      </div>

                      <div className="mt-[.2rem]">
                        <Input
                          value={submitForm.phone}
                          onChange={handlePhoneChange}
                          placeholder={t('deposit-withdraw.enter_phone')}
                          innerClassName=" !h-[.92rem] !border-transparent"
                          topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                        />
                        <span className="  w-[.4rem] h-[.4rem]"></span>
                      </div>
                    </div>
                  )}

                  {currentWithdrawMethod?.method == 'cnpj' && (
                    <div>
                      <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                        {'CNPJ'} <span className="text-[var(--colour-25)]">*</span>
                      </div>

                      <div className=" mt-[.2rem]">
                        <Input
                          value={submitForm.cnpj}
                          onChange={handleCnpjChange}
                          placeholder={t('deposit-withdraw.enter_cnpj')}
                          innerClassName=" !h-[.92rem] !border-transparent"
                          topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                        />
                        <span className="  w-[.4rem] h-[.4rem]"></span>
                      </div>
                    </div>
                  )}

                  {currentWithdrawMethod?.method == 'evp' && (
                    <div>
                      <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                        {'EVP'} <span className="text-[var(--colour-25)]">*</span>
                      </div>

                      <div className="mt-[.2rem]">
                        <Input
                          value={submitForm.evp}
                          onChange={handleEvpChange}
                          placeholder={t('deposit-withdraw.enter_evp')}
                          innerClassName=" !h-[.92rem] !border-transparent"
                          topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                        />
                        <span className="  w-[.4rem] h-[.4rem]"></span>
                      </div>
                    </div>
                  )}

                  <div>
                    <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                      {'CPF'}
                      <span className="text-[var(--colour-25)]"> *</span>
                    </div>

                    <div className=" mobile">
                      <Input
                        value={submitForm.cpf}
                        onChange={handleCpfChange}
                        placeholder={t('deposit-withdraw.enter_cpf')}
                        innerClassName=" !h-[.92rem] !border-transparent"
                        topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                      />
                      <span className="  w-[.4rem] h-[.4rem]"></span>
                    </div>
                  </div>

                  <div className="  text-[.24rem] mt-[.2rem] font-[500] text-left text-[var(--colour-44)]">
                    {t('deposit-withdraw.make_sure_account')}
                  </div>

                  <div
                    onClick={() => {
                      setIsOpenRecord(true);
                    }}
                    className="cursor-pointer font-[700] text-left   text-[.3rem] mt-[.1rem]"
                  >
                    <span className="text-[var(--colour-49)]">{t('deposit-withdraw.record')}</span>
                  </div>
                </div>
              )}

              {/* 区块链 */}
              {widthdrawType == 2 && (
                <div className=" mt-[-.36rem]">
                  <div className="flex justify-center mt-[.26rem]">
                    <div className="  w-[.66rem] h-[.66rem] text-[var(--colour-14)]">
                      <Icon name={IconName.WithdrawArrow}></Icon>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="flex gap-[.1rem]  flex-wrap  mt-[.2rem]">
                      {cryptoList && cryptoList.length > 0 ? (
                        cryptoList.map((item, index) => (
                          <div key={index}>
                            <div
                              key={item.id}
                              onClick={() => {
                                setCrypto(item);
                                if (item && item.networks && item.networks.length > 0) {
                                  setNetWork(item.networks[0]);
                                }
                              }}
                              className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center w-[1.56rem]   h-[.8rem] rounded-normal ${
                                crypto.id == item.id
                                  ? 'border border-[var(--colour-1)] bg-[var(--colour-5)] text-[var(--colour-3)]'
                                  : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                              } `}
                            >
                              <span className="  w-[.32rem] h-[.32rem] block">
                                {item.currency == 'USDT' && <Icon name={IconName.USDT} />}
                                {item.currency == 'BTC' && <Icon name={IconName.BTC} />}
                                {item.currency == 'ETH' && <Icon name={IconName.ETH} />}
                                {item.currency == 'TRX' && <Icon name={IconName.TRX} />}
                                {item.currency == 'USDC' && <Icon name={IconName.USDC} />}
                                {item.currency == 'BRZ' && <Icon name={IconName.BRZ} />}
                              </span>

                              <span className={`ml-[.04rem] text-[.24rem] font-[700]`}>{item.currency}</span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="text-left   mt-[.32rem] text-[.26rem] font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.withdraw_rate')}:
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-[.26rem] flex items-center">
                      <span
                        className={`  w-[.36rem] h-[.36rem] text-[var(--colour-1)] font-[500] cursor-pointer ${refresh == true && 'runHi'}`}
                        onClick={() => {
                          setRefrensh(true);
                          getExchangeRateData();
                          setTimeout(() => {
                            setRefrensh(false);
                          }, 1500);
                        }}
                      >
                        <Icon name={IconName.Refresh} />
                      </span>
                      <span className="text-left text-[var(--colour-41)] font-[500]  m-[0_.06rem_0_.04rem]">
                        1 {crypto?.currency} ≈ {initConfig.currency_symbol || ''}{' '}
                        {pointToComma(cutDecimal(getExchangeRate()))} <CountDowns />
                      </span>
                    </div>
                    <div className=" text-[.26rem] text-left font-[500] text-[var(--colour-41)]">
                      -{Number(getShowMoneyNumber(network?.fee || 0, 6))} {crypto?.currency || ''} (Gas Fee)
                    </div>
                  </div>
                  <div className="flex justify-between    h-[.92rem] mt-[.16rem] px-[.3rem] items-center border border-[var(--colour-6)] bg-[var(--colour-34)] overflow-hidden rounded-normal">
                    <div className="flex items-center">
                      <div className="text-[.28rem] opacity-50 font-[700] block text-[var(--colour-44)]">
                        {t('deposit-withdraw.converted_crypto_amount')}
                      </div>
                    </div>

                    <div className={` text-[.24rem] text-[var(--colour-38)]`}>
                      {getExchangeRate() == 0 ? 0 : pointToComma(cutDecimal(getAmountFee()), 5)} {crypto?.currency}
                    </div>
                  </div>
                  <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.network')}
                  </div>
                  <div className="relative  mb-[.6rem]">
                    <div className="flex gap-[.1rem]  flex-wrap  mt-[.2rem]">
                      {crypto && crypto.networks && crypto.networks.length > 0 ? (
                        crypto.networks.map((item, index) => (
                          <div key={index}>
                            <div
                              key={item.id}
                              onClick={() => {
                                setNetWork(item);
                              }}
                              className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center w-[2.1rem]   h-[.8rem] rounded-normal ${
                                item.network == network.network
                                  ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                                  : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                              } `}
                            >
                              <span className={`text-[.26rem] font-[700] `}>{item.withdraw_name}</span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.enter_wallet')} <span className="text-[var(--colour-25)]"> *</span>
                  </div>
                  <div className=" mt-[.2rem]">
                    <Input
                      type="text"
                      onChange={(ev) => {
                        setCryptoAddress(TextPlaces(ev.target.value, 80));
                      }}
                      value={cryptoAddress}
                      placeholder={t('deposit-withdraw.enter_wallet')}
                      innerClassName=" !h-[.92rem] !border-transparent"
                      topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                    />
                    <span className="  w-[.4rem] h-[.4rem]"></span>
                  </div>
                  <div className="  text-[.24rem] mt-[.2rem] font-[500] text-left text-[var(--colour-44)]">
                    {t('deposit-withdraw.wallet_msg1')}
                  </div>
                  <div
                    onClick={() => {
                      setIsOpenRecord(true);
                    }}
                    className="cursor-pointer font-[700] text-left   text-[.3rem] mt-[.1rem]"
                  >
                    <span className="text-[var(--colour-49)]">{t('deposit-withdraw.record')}</span>
                  </div>
                </div>
              )}

              <LimitClickWrapper
                className="active:scale-[.95] transition-all select-none flex flex-row    mt-[.3rem] px-[.34rem] h-[.92rem] items-center cursor-pointer justify-center cursor-auto rounded-normal bg-[var(--colour-1)]"
                onClick={() => {
                  if (!requesting) {
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_WithdrawButton);
                    if (widthdrawType == 1) {
                      handleWithdraw();
                    } else {
                      handleWithdrawCyorp();
                    }
                  }
                }}
              >
                <ElementWithLoading
                  isLoading={requesting}
                  normalEl={<div className="font-[700] text-[var(--colour-2)]">{t('deposit-withdraw.withdraw')}</div>}
                />
              </LimitClickWrapper>
            </div>
          </>
        )
      )}
    </>
  );
};

export default WithdrawBRL;
