import cn from 'classnames';

const LinkItem = ({ name, onClick }) => {
  return (
    <div className={cn('text-[var(--colour-48)] font-[500] text-[.24rem] leading-[1.5em]', {})}>
      <span
        onClick={onClick}
        className={cn('', {
          'cursor-pointer': !!onClick,
        })}
      >
        {name}
      </span>
    </div>
  );
};

export default LinkItem;
