import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import Tips from '../tips';
import { get } from 'lodash';
import { isLogin, isControllableSite } from '../../../../utils/helper';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../redux/reducers/appSlice';

const Layout5 = ({ click, onlyIcon }) => {
  const { t } = useTranslation();
  const { unReadCount, userObj } = useSelector(
    (state) => ({
      unReadCount: get(state, 'app.unReadCount'),
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  return onlyIcon ? (
    <>
      <Tips anchorId="overMessage" className="text-[var(--colour-48)]">
        {t('home.menu_email')}
      </Tips>
      <div
        onClick={click}
        id="overMessage"
        className={`w-[.8rem] h-[.8rem] mb-[.16rem]  rounded-normal cursor-pointer flex justify-center items-center bg-[var(--colour-35)] relative hover:bg-[var(--colour-35)]`}
      >
        <span className="relative w-[.48rem] h-[.48rem]">
          <Icon name={IconName.Email2} />
          {userObj != null && unReadCount > 0 ? (
            <div className="w-[.16rem] h-[.16rem] right-[-0.06rem] top-[-0.04rem] rounded-full bg-red-dot flex justify-center items-center font-[600] absolute"></div>
          ) : (
            <></>
          )}
        </span>
      </div>
    </>
  ) : (
    <div className="flex flex-row items-center justify-start cursor-pointer px-[.12rem] mb-[.48rem]" onClick={click}>
      <span className="w-[.52rem] h-[.52rem] mr-[.16rem] block relative text-[var(--colour-12)]">
        <Icon name={IconName.Email2} />
        {userObj != null && unReadCount > 0 ? (
          <div className="w-[.16rem] h-[.16rem] right-[-0.06rem] top-[-0.04rem] rounded-full bg-red-dot flex justify-center items-center font-[600] absolute"></div>
        ) : (
          <></>
        )}
      </span>
      <span className="text-[var(--colour-14)] relative text-[.28rem] leading-[.52rem] font-[700]">
        {t('home.menu_email')}
      </span>
    </div>
  );
};
const LayoutMobile = ({ click }) => {
  const { t } = useTranslation();
  const { unReadCount, userObj } = useSelector(
    (state) => ({
      unReadCount: get(state, 'app.unReadCount'),
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  return (
    <div className="flex flex-row items-center justify-start cursor-pointer px-[.14rem] mb-[.2rem]" onClick={click}>
      <span className="w-[.4rem] h-[.4rem] mr-[.08rem] block relative text-[var(--colour-12)]">
        <Icon name={IconName.Email2} />
        {userObj != null && unReadCount > 0 ? (
          <div className="w-[.16rem] h-[.16rem] right-[-0.06rem] top-[-0.04rem] rounded-full bg-red-dot flex justify-center items-center font-[600] absolute"></div>
        ) : (
          <></>
        )}
      </span>
      <span className="text-[var(--colour-14)] text-[.2rem] leading-[.24rem] relative font-[500]">
        {t('home.menu_email')}
      </span>
    </div>
  );
};

const Message = ({ onlyIcon, handleLinkClick = () => {} }) => {
  const dispatch = useDispatch();
  const { handleClickVector } = useHandleLogic();

  const click = () => {
    if (isLogin()) {
      handleClickVector();
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }

    handleLinkClick();
  };

  return isControllableSite() ? (
    <LayoutMobile click={click} onlyIcon={onlyIcon} />
  ) : (
    <Layout5 click={click} onlyIcon={onlyIcon} />
  );
};

export default Message;
