import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setActivityStatus,
  DialogModalChildrenKey,
  setDialogModalVisible,
  setWelcomeBonusData,
} from '../redux/reducers/appSlice';
import { ActivityNoviceListReq } from '../api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { toLocalePath } from '../utils/localePath';
import { getAppTag } from '../utils/deviceUtils';
import { find } from 'lodash';

const useWelcomeBonus = () => {
  const dispatch = useDispatch();
  const getData = async () => {
    return await ActivityNoviceListReq({
      is_app: getAppTag(),
      page: 1,
      page_size: 9999,
    });
  };

  const showRegisterBonusTips = (userObj = null) => {
    return new Promise((resolve) => {
      const ls_key = `welcomeBonusTips_${userObj.uid}`;
      if (!localStorage.getItem(ls_key)) {
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.RegisterBonus,
            style: 1,
          }),
        );
        localStorage.setItem(ls_key, '1');
      } else {
        resolve();
      }
    });
  };

  const checkShowTips = (data, userObj = null) => {
    return new Promise((resolve) => {
      if (data.activity_rewards && data.activity_rewards.length > 0 && data.activity_end_time) {
        let money = 0;
        data.activity_rewards.forEach((item) => {
          if (item.reward_type === '4') {
            money += Number(item.max_amount);
          } else {
            money += Number(item.amount);
          }
        });
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.RegisterBonus,
            style: 2,
            money: money,
            endTime: data.activity_end_time * 1000,
          }),
        );
        const ls_key = `welcomeBonusTips_${userObj.uid}`;
        localStorage.setItem(ls_key, '1');
      }
    });
  };

  const initData = async (isCheck = false, userObj = null) => {
    return new Promise(async (resolve) => {
      const res = await getData();
      if (res) {
        if (res.code == 0) {
          const result = res.result;
          if (result.activity_switch == '1' && result.list && result.list.length > 0) {
            const data = result.list[0];
            dispatch(setWelcomeBonusData(data));
            if (isCheck) {
              checkShowTips(data, userObj).then(() => {
                resolve();
              });
            }
          } else {
            if (isCheck) {
              resolve();
            }
            dispatch(setWelcomeBonusData(null));
          }
        } else {
          resolve();
          toast.error(res.msg, {
            containerId: 'global',
          });
          dispatch(setWelcomeBonusData(null));
        }
      } else {
        resolve();
      }
    });
  };

  const InitWelcomeBonus = async (isCheck = false, userObj = null) => {
    return new Promise((resolve) => {
      if (isCheck) {
        const _timer = window.setTimeout(() => {
          window.clearTimeout(_timer);
          initData(isCheck, userObj).then(() => {
            resolve();
          });
        }, 1000);
      } else {
        return initData(isCheck, userObj);
      }
    });
  };

  return {
    getData,
    InitWelcomeBonus,
  };
};

export default useWelcomeBonus;
