import LoadMore from '../loadMore';
import LoadMoreText from '../loadMoreText';
import { useStateIfMounted } from 'use-state-if-mounted';
import GameItem from '../gameItem';
import ProviderItem from '../providerItem';
import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

export default function Index({
  maxRows,
  data,
  pageTotal,
  gap,
  isClickProvider = false,
  clickProviderItem = () => {},
  loadMoreWithText = false,
  lang_key_title = '',
  gameListGridClassName = '',
  game_type_id = '',
}) {
  const rowTotalNumbers = 3;
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [showList, setShowList] = useStateIfMounted([]);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [pageSize, setPageSize] = useStateIfMounted(rowTotalNumbers);
  const [total, setTotal] = useStateIfMounted(0);
  const [currentNum, setCurrentNum] = useStateIfMounted(0);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    if (data && showList) {
      if (showList.length < data.length) {
        setShowLoadingMore(true);
      } else {
        setShowLoadingMore(false);
      }
    }
  }, [showList, data]);

  useEffect(() => {
    if (data && data.length > 0) {
      setTotal(data.length);
      setCurrentNum(currentPage * pageSize);
      setShowList(data.slice(0, currentPage * pageSize));
    }
  }, [currentPage, data, pageSize]);

  useEffect(() => {
    setPageSize(rowTotalNumbers * maxRows);
  }, [maxRows]);
  return (
    <>
      {showList && showList.length > 0 ? (
        <div
          className={`grid h-full ${gameListGridClassName}`}
          style={{ gap: gap, gridTemplateColumns: `repeat(${pageTotal}, 1fr)` }}
        >
          {showList.map((item, index) =>
            isClickProvider ? (
              <ProviderItem
                key={`${item.source}-${item.game_id}-${item.img}`}
                model={item}
                clickProviderItem={clickProviderItem}
                game_type_id={game_type_id}
              />
            ) : (
              <GameItem key={item.id} model={item} />
            ),
          )}
        </div>
      ) : null}
      {showLoadingMore ? (
        loadMoreWithText ? (
          <LoadMoreText
            className={`mt-[.32rem]`}
            onClick={handleLoadMore}
            showLoading={false}
            currentNum={currentNum}
            total={total}
            lang_key_title={lang_key_title}
          />
        ) : (
          <LoadMore className={`mt-[.3rem]`} onClick={handleLoadMore} showLoading={false} />
        )
      ) : null}
    </>
  );
}
