import { useEffect, useState, useRef, Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import DragBtnRight from '../dragBtnRight';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cmsImgUrl, isUseLayoutMobile, pxToRem } from '../../utils/helper';
import GiftMoney from '../activityEntrance/giftMoney';
import WelcomeBonus from '../activityEntrance/welcomeBonus';
import CashRain from '../activityEntrance/cashRain';
import GuideWheelentrance from '../activityEntrance/guideWheelentrance';
import PromoteAchievements from '../activityEntrance/promoteAchievements';
import ReferralCashback from '../activityEntrance/referralCashback';
import MysteryBonus from '../activityEntrance/mysteryBonus';
import { Autoplay } from 'swiper/modules';
import cn from 'classnames';
import TgfbEntrance from '../activityEntrance/tgfbEntrance';
import { useUpdateEffect } from 'ahooks';

export default function Index() {
  const [fixedMenus, setFixedMenus] = useState([]);
  const [swiperMenus, setSwiperMenus] = useState([]);
  const [tgFbFixedEntranceConfig, setTgFbFixedEntranceConfig] = useState([]);
  const swiperRef = useRef(null);
  const [position, setPosition] = useState();

  const swiperRealIndexRef = useRef(0); //轮播当前下标索引
  const { activityData, activityStatus, visibleBackTopBtn, activitySwiperSlideKey } = useSelector(
    (state) => ({
      activityStatus: state.app.activityStatus,
      activityData: state.app.activityData,
      visibleBackTopBtn: state.app.visibleBackTopBtn,
      activitySwiperSlideKey: state.app.activitySwiperSlideKey,
    }),
    shallowEqual,
  );

  const getActivityImage = (arr, index) => {
    const item = arr.find((item) => item.index == index);
    return item ? cmsImgUrl(item.icon) : '';
  };

  const hasActivityType = (arr, index) => {
    return arr.some((item) => item.index == index && item.type == 1);
  };

  const getMenuIndex = (arr, index) => {
    return arr.findIndex((item) => item.index == index);
  };

  useEffect(() => {
    if (activityData != null && activityStatus != null) {
      const _fixedMenus = [];
      const _swiperMenus = [];
      const _showFixedMenus = [];
      // activity_wheel_show_icon_v2
      //index
      // -1: 礼品金
      //  0: 活动九宫格
      //  1: 任务活动
      //  2: 救济金
      //  3: 锦标赛
      //  4: 轮盘抽奖
      //  5: 红包
      //  6: 新人活动
      //  7: 兑换码
      //  8: 裂变活动
      //  9: 新人引导轮盘抽奖
      // 10: 推广成就
      // 11: 推广返现
      // 12: 神秘彩金
      // type  0:表示该活动图标以浮框形式展示  1:表示该活动图标参与轮播展示

      const activityWheelShowIconV2 = Array.from(activityStatus.activity_wheel_show_icon_v2 || []);
      const websiteTgFbEntranceConfig = activityStatus.website_tg_fb_entrance_config;
      const EntranceDisplayLocation = websiteTgFbEntranceConfig.entrance_display_location; // 0 websiteTgFbEntranceConfig 左侧 && activityWheelShowIconV2 右侧
      setPosition(EntranceDisplayLocation);

      // 筛选出status为1
      const _tgFbEntranceConfig = Object.entries(websiteTgFbEntranceConfig)
        .map(([key, value]) => {
          if (value.status === '1') {
            return { key, ...value, EntranceDisplayLocation: EntranceDisplayLocation };
          }
          return { key, ...value };
        })
        .filter((item) => item.status === '1');

      setTgFbFixedEntranceConfig(_tgFbEntranceConfig);

      const mysteryBonusData = activityData.activity_mystery_bonus?.list[0];
      const isShowmysteryEntrance =
        activityStatus?.activity_mystery_bonus_switch == '1' &&
        (mysteryBonusData?.can_sign_up || mysteryBonusData?.sign_up_status);

      if (isShowmysteryEntrance) {
        const mysteryBonusDataIndex = 12;
        const imgpath = getActivityImage(activityWheelShowIconV2, mysteryBonusDataIndex);
        const mysteryBonusEntranceSwiper = hasActivityType(activityWheelShowIconV2, mysteryBonusDataIndex);
        const index = getMenuIndex(activityWheelShowIconV2, mysteryBonusDataIndex);
        if (mysteryBonusEntranceSwiper) {
          _swiperMenus.push({
            com: <MysteryBonus isdrag={false} imgpath={imgpath} />,
            index: index,
            key: 'MysteryBonus',
          });
        } else {
          _fixedMenus.push({
            key: 'MysteryBonus',
            imgpath: imgpath,
            index: index,
          });
        }
      }

      // 引导轮盘
      const activityNoviceGuideTournament = activityData.activity_novice_guide_tournament;
      if (
        activityNoviceGuideTournament &&
        activityNoviceGuideTournament.list &&
        activityNoviceGuideTournament.list.length > 0
      ) {
        const wheelIndex = 9;
        const imgpath = getActivityImage(activityWheelShowIconV2, wheelIndex);
        const wheelEntranceSwiper = hasActivityType(activityWheelShowIconV2, wheelIndex);
        const index = getMenuIndex(activityWheelShowIconV2, wheelIndex);
        if (wheelEntranceSwiper) {
          _swiperMenus.push({
            com: <GuideWheelentrance isdrag={false} imgpath={imgpath} />,
            index: index,
            key: 'GuideWheelentrance',
          });
        } else {
          _fixedMenus.push({
            key: 'GuideWheelentrance',
            imgpath: imgpath,
            index: index,
          });
        }
      }

      // 推广成就
      const activityPromotionalAchievements = activityData.activity_promotional_achievements;
      if (
        activityPromotionalAchievements &&
        activityPromotionalAchievements.list &&
        activityPromotionalAchievements.list.length > 0
      ) {
        const PromoteAchievementsIndex = 10;
        const imgpath = getActivityImage(activityWheelShowIconV2, PromoteAchievementsIndex);
        const PromoteAchievementsSwiper = hasActivityType(activityWheelShowIconV2, PromoteAchievementsIndex);
        const index = getMenuIndex(activityWheelShowIconV2, PromoteAchievementsIndex);
        if (PromoteAchievementsSwiper) {
          _swiperMenus.push({
            com: <PromoteAchievements isdrag={false} imgpath={imgpath} />,
            index: index,
            key: 'PromoteAchievements',
          });
        } else {
          _fixedMenus.push({
            key: 'PromoteAchievements',
            imgpath: imgpath,
            index: index,
          });
        }
      }

      // 推广返现
      const activityPromotionalCashback = activityData.activity_promotional_cashback;
      if (
        activityPromotionalCashback &&
        activityPromotionalCashback.list &&
        activityPromotionalCashback.list.length > 0
      ) {
        const ReferralCashbackIndex = 11;
        const imgpath = getActivityImage(activityWheelShowIconV2, ReferralCashbackIndex);
        const ReferralCashbackSwiper = hasActivityType(activityWheelShowIconV2, ReferralCashbackIndex);
        const index = getMenuIndex(activityWheelShowIconV2, ReferralCashbackIndex);
        if (ReferralCashbackSwiper) {
          _swiperMenus.push({
            com: <ReferralCashback isdrag={false} imgpath={imgpath} />,
            index: index,
            key: 'ReferralCashback',
          });
        } else {
          _fixedMenus.push({
            key: 'ReferralCashback',
            imgpath: imgpath,
            index: index,
          });
        }
      }
      // gift Money
      const giftMoneyData = activityData.gift_money;
      if (giftMoneyData) {
        const GiftMoneyIndex = -1;
        const imgpath = getActivityImage(activityWheelShowIconV2, GiftMoneyIndex);
        const GiftMoneySwiper = hasActivityType(activityWheelShowIconV2, GiftMoneyIndex);
        const index = getMenuIndex(activityWheelShowIconV2, GiftMoneyIndex);
        if (GiftMoneySwiper) {
          _swiperMenus.push({
            com: <GiftMoney isdrag={false} imgpath={imgpath} />,
            index: index,
            key: 'GiftMoney',
          });
        } else {
          _fixedMenus.push({
            key: 'GiftMoney',
            imgpath: imgpath,
            index: index,
          });
        }
      }
      // 迎新奖励
      if (activityData.activity_novice && activityData.activity_novice.list) {
        const activityNovice = activityData.activity_novice.list;
        if (activityNovice.length > 0) {
          const WelcomeBonusIndex = 6;
          const imgpath = getActivityImage(activityWheelShowIconV2, WelcomeBonusIndex);
          const WelcomeBonusSwiper = hasActivityType(activityWheelShowIconV2, WelcomeBonusIndex);
          const index = getMenuIndex(activityWheelShowIconV2, WelcomeBonusIndex);
          if (WelcomeBonusSwiper) {
            _swiperMenus.push({
              com: <WelcomeBonus isdrag={false} imgpath={imgpath} />,
              index: index,
              key: 'WelcomeBonus',
            });
          } else {
            _fixedMenus.push({
              key: 'WelcomeBonus',
              imgpath: imgpath,
              index: index,
            });
          }
        }
      }
      // 红包
      if (activityData.activity_red_packet && activityData.activity_red_packet.list) {
        const activityRedPacket = activityData.activity_red_packet.list;
        if (activityRedPacket.length > 0) {
          const CashRainIndex = 5;
          const imgpath = getActivityImage(activityWheelShowIconV2, CashRainIndex);
          const CashRainSwiper = hasActivityType(activityWheelShowIconV2, CashRainIndex);
          const index = getMenuIndex(activityWheelShowIconV2, CashRainIndex);
          if (CashRainSwiper) {
            _swiperMenus.push({
              com: <CashRain isdrag={false} imgpath={imgpath} />,
              index: index,
              key: 'CashRain',
            });
          } else {
            _fixedMenus.push({
              key: 'CashRain',
              imgpath: imgpath,
              index: index,
            });
          }
        }
      }

      _swiperMenus.sort((a, b) => {
        return a.index - b.index;
      });
      _fixedMenus.sort((a, b) => {
        return a.index - b.index;
      });
      const _showSwiperMenus = [];
      for (let i = 0; i < _swiperMenus.length; i++) {
        _showSwiperMenus.push({ com: _swiperMenus[i].com, key: _swiperMenus[i].key });
      }
      setSwiperMenus(_showSwiperMenus);
      for (let i = 0; i < _fixedMenus.length; i++) {
        switch (_fixedMenus[i].key) {
          case 'GuideWheelentrance':
            _showFixedMenus.push(
              <GuideWheelentrance
                isdrag={true}
                index={i}
                imgpath={_fixedMenus[i].imgpath}
                EntranceDisplayLocation={EntranceDisplayLocation}
              />,
            );
            break;
          case 'PromoteAchievements':
            _showFixedMenus.push(
              <PromoteAchievements
                isdrag={true}
                index={i}
                imgpath={_fixedMenus[i].imgpath}
                EntranceDisplayLocation={EntranceDisplayLocation}
              />,
            );
            break;
          case 'ReferralCashback':
            _showFixedMenus.push(
              <ReferralCashback
                isdrag={true}
                index={i}
                imgpath={_fixedMenus[i].imgpath}
                EntranceDisplayLocation={EntranceDisplayLocation}
              />,
            );
            break;
          case 'GiftMoney':
            _showFixedMenus.push(
              <GiftMoney
                isdrag={true}
                index={i}
                imgpath={_fixedMenus[i].imgpath}
                EntranceDisplayLocation={EntranceDisplayLocation}
              />,
            );
            break;
          case 'WelcomeBonus':
            _showFixedMenus.push(
              <WelcomeBonus
                isdrag={true}
                index={i}
                imgpath={_fixedMenus[i].imgpath}
                EntranceDisplayLocation={EntranceDisplayLocation}
              />,
            );
            break;
          case 'CashRain':
            _showFixedMenus.push(
              <CashRain
                isdrag={true}
                index={i}
                imgpath={_fixedMenus[i].imgpath}
                EntranceDisplayLocation={EntranceDisplayLocation}
              />,
            );
            break;
          case 'MysteryBonus':
            _showFixedMenus.push(
              <MysteryBonus
                isdrag={true}
                index={i}
                imgpath={_fixedMenus[i].imgpath}
                EntranceDisplayLocation={EntranceDisplayLocation}
              />,
            );
            break;
        }
      }
      setFixedMenus(_showFixedMenus);
    }
  }, [activityData, activityStatus]);

  const onRealIndexChange = (evt) => {
    swiperRealIndexRef.current = evt.activeIndex;
  };

  function updateBottom(elements, scrollEl, visibleBackTopBtn) {
    if (!elements || !scrollEl) return;

    const boxHeightPx = Number(scrollEl.offsetHeight);
    const boxHeightRem = pxToRem(boxHeightPx);

    if (visibleBackTopBtn) {
      elements.forEach((item) => {
        const currentBottom = window.getComputedStyle(item).getPropertyValue('bottom');
        const currentBottomPx = parseFloat(currentBottom.replace(/px$/, ''));
        const currentBottomRem = pxToRem(currentBottomPx);

        item.style.bottom = `calc(${currentBottomRem}rem + ${boxHeightRem}rem)`;
      });
    } else {
      elements.forEach((item) => {
        const currentBottom = window.getComputedStyle(item).getPropertyValue('bottom');
        const currentBottomPx = parseFloat(currentBottom.replace(/px$/, ''));
        const currentBottomRem = pxToRem(currentBottomPx);

        item.style.bottom = `calc(${currentBottomRem}rem - ${boxHeightRem}rem)`;
      });
    }
  }

  useUpdateEffect(() => {
    const elements = document.querySelectorAll('.activity-item');
    const elements1 = document.querySelectorAll('.thirdparty-item');
    const scrollEl = document.querySelector('.scrollTop_box');

    if (position == '0') {
      updateBottom(elements, scrollEl, visibleBackTopBtn);
    } else if (position == '1') {
      updateBottom(elements1, scrollEl, visibleBackTopBtn);
    }
  }, [visibleBackTopBtn]);

  useEffect(() => {
    if (swiperRef.current) {
      if (activitySwiperSlideKey == '') {
        swiperRef.current.swiper.autoplay.start();
      } else {
        const slides = swiperRef.current.swiper.slides;
        const realItemIndex = slides.findIndex((item) => {
          return item.dataset.name == activitySwiperSlideKey;
        });
        if (realItemIndex != -1) {
          const realIndex = slides[realItemIndex].dataset.swiperSlideIndex;
          if (realItemIndex != -1) {
            swiperRef.current.swiper.slideToLoop(realIndex, 0, false);
            swiperRef.current.swiper.autoplay.stop();
          }
        }
      }
    }
  }, [activitySwiperSlideKey]);

  return (
    <>
      {/* 固定定位 无法拖拽 */}
      {tgFbFixedEntranceConfig && tgFbFixedEntranceConfig.length > 0 ? (
        <>
          {tgFbFixedEntranceConfig.map((item, index) => {
            return (
              <Fragment key={index}>
                <TgfbEntrance data={item} index={index} />
              </Fragment>
            );
          })}
        </>
      ) : (
        <></>
      )}
      {/* 单个活动拖拽 */}
      {fixedMenus &&
        fixedMenus.map((item, index) => {
          return <Fragment key={index}>{item}</Fragment>;
        })}
      {/* 轮播拖拽 */}
      {swiperMenus && position != undefined && swiperMenus.length > 0 ? (
        <DragBtnRight
          className1="swiper_drag"
          className={cn(`${position == '0' ? 'right-[.08rem]' : 'left-[.08rem]'}`, {
            // visibleBackTopBtn 返回顶部按钮是否显示
            'bottom-[var(--menubar-height)]': isUseLayoutMobile() && (!visibleBackTopBtn || position == '1'),
            'bottom-[var(--activity-bottom)]': isUseLayoutMobile() && visibleBackTopBtn && position == '0',

            'bottom-[1.5rem]':
              (!isUseLayoutMobile() && (!visibleBackTopBtn || position == '1')) ||
              (!isUseLayoutMobile() && visibleBackTopBtn && position == '0'),
          })}
          position={position}
          isLimitedInParentHeight={false}
          draggableAreaID={'draggableArea'}
        >
          <>
            <div
              id="draggableArea"
              className={`cursor-move w-[.48rem] h-[.48rem] bg-[url(../assets/img/drag.png)] bg-no-repeat bg-[length:100%_100%] absolute top-0 ${position == '0' ? 'left-[-0.48rem]' : 'right-[-0.48rem]'} z-[100]`}
            ></div>
            <div className="w-[1.5rem] h-[1.5rem] overflow-hidden">
              <Swiper
                onRealIndexChange={onRealIndexChange}
                ref={swiperRef}
                slidesPerView={1}
                autoplay={
                  swiperMenus && swiperMenus.length > 1
                    ? {
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      }
                    : false
                }
                modules={[Autoplay]}
                loop={true}
                spaceBetween={12}
                touchStartPreventDefault={false}
              >
                {swiperMenus &&
                  swiperMenus.map((item, index) => {
                    return (
                      <SwiperSlide data-name={item.key} key={index}>
                        {item.com}
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </>
        </DragBtnRight>
      ) : null}
    </>
  );
}
