import { memo } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

/**
 * bigo的Pixel统计
 * 支持从url获取pixelId
 */
const BigoPixel = () => {
  const bigoPixelId = useSelector((state) => state.user.bigoPixelId);

  return bigoPixelId ? (
    <Helmet>
      <script>{`
      window.bgdataLayer = window.bgdataLayer || [];
      function bge(){bgdataLayer.push(arguments);}
      bge('init', '${bigoPixelId}');
        `}</script>
      <script async src={`https://api.imotech.video/ad/events.js?pixel_id=${bigoPixelId}`}></script>
    </Helmet>
  ) : (
    <></>
  );
};

export default memo(BigoPixel);
