import { useEffect, useState } from 'react';
import GameItem from '../../../../../components/gameItem';
import NoData from '../../../../../components/noData';
import { useSelector, shallowEqual } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';

const PopularGame = () => {
  const [list, setList] = useState([]);
  const [reRequest, setreRequest] = useStateIfMounted('');

  const { websiteInitData } = useSelector(
    (state) => ({
      websiteInitData: state.app.websiteInitData,
    }),
    shallowEqual,
  );

  const handleFavoriteCallback = () => {
    setreRequest(new Date().getTime());
  };

  useEffect(() => {
    if (websiteInitData?.template) {
      const data = websiteInitData.template;
      for (let i = 0; i < data.length; i++) {
        if (data[i].module == 'CategoryGameList') {
          const config = data[i].config;
          if (config.category_id == '1011') {
            setList(data[i].data);
          }
        }
      }
    }
  }, [websiteInitData]);

  return (
    <>
      {list && list.length > 0 ? (
        <div className="games__row gap-[.16rem] pb-[.3rem]">
          {list &&
            list.length > 0 &&
            list.map((item) => <GameItem model={item} key={item.id} handleFavoriteCallback={handleFavoriteCallback} />)}
        </div>
      ) : (
        <NoData className="py-[3rem]" />
      )}
    </>
  );
};

export default PopularGame;
