import { isFacebookApp, isLocalHost } from '../utils/deviceUtils';
import { loadScriptAsync } from '../utils/helper';

/**
 * fb管理器
 */

const FBApi = 'https://connect.facebook.net/en_US/sdk.js';

class FacebookManager {
  static inited = false; // 已初始化
  static connected = false; // 已连接

  /**
   * 初始化 async
   * @param {string} appId
   */
  static async init(appId) {
    if (window.FB || isLocalHost()) return;
    await loadScriptAsync(FBApi);
    if (window.FB && appId) {
      window.FB.init({
        appId: appId,
        xfbml: true,
        version: 'v16.0',
      });
      this.inited = true;
      console.log('[fb] inited', appId);
    }
  }

  /**
   * 获取登录状态
   * facebookApp会执行页面跳转，需要获取登录状态
   * @param  callback 回调
   */
  static getLoginStatus(callback) {
    if (!this.inited || !isFacebookApp()) return;
    window.FB.getLoginStatus((response) => {
      console.log('[fb] getLoginStatus', response);
      if (response.status === 'connected') {
        this.connected = true;
        const { accessToken } = response.authResponse;
        callback && callback(accessToken);
      } else {
        callback && callback(null);
      }
    });
  }

  /**
   * 登录FB
   * @param  callback 回调
   */
  static login(callback) {
    if (!this.inited) {
      console.log('[fb] login 未初始化完成');
      return;
    }
    window.FB.login((response) => {
      console.log('[fb] login', response);
      if (response.status === 'connected') {
        this.connected = true;
        const { accessToken } = response.authResponse;
        callback && callback(accessToken);
      } else {
        callback && callback(null);
      }
    });
  }

  /**
   * 退出登录
   * facebookApp里退出登录之后无法重新登录,所以不退出
   */
  static logout() {
    if (!this.connected || isFacebookApp()) return;
    window.FB.logout((response) => {
      this.connected = false;
      console.log('[fb] logout', response);
    });
  }
}

export default FacebookManager;
