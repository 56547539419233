import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
import SwitchWrapper from '../../../../components/switchWrapper';
import RedDot from '../../../../components/redDot';
import TournamentItem from '../../../../components/tour/tournamentItem';
import { useStateIfMounted } from 'use-state-if-mounted';
import { GetTournamentListReq } from '../../../../api';
import LoadMore from '../../../../components/loadMore';
import Loading from '../../../../components/loading2';
import NoData from '../../../../components/noData';
import Back from '../../../../components/back';
import useActivityStatus from '../../../../hooks/useActivityStatus';

const NTemplate001 = () => {
  const { t } = useTranslation();

  const { activityStatus, identity } = useSelector(
    (state) => ({
      activityStatus: state.app.activityStatus,
      identity: state.user.identity,
    }),
    shallowEqual,
  );

  const { handleActivityDetailGoBack } = useActivityStatus();

  const tablist = [
    {
      title: t('tour.active'),
      tourStatus: 2,
    },
    {
      title: t('tour.upcoming'),
      tourStatus: 1,
    },
    {
      title: t('tour.finished'),
      tourStatus: 3,
    },
  ];

  const [num, setNum] = useStateIfMounted(get(activityStatus, 'activity_tournament') == '1' ? 2 : 0);
  const [loading, setLoading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const pageSize = 6;
  const [params, setParams] = useStateIfMounted({
    activity_type_id: 3, // 3:锦标赛
    page: 1,
    page_size: pageSize,
    status: tablist[num].tourStatus, // 1：待生效；2：进行中； 3：结束
  });
  const [list, setList] = useStateIfMounted([]);

  const fetchServerTourData = () => {
    if (params.page == 1) {
      setList([]);
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    GetTournamentListReq(params).then((res) => {
      if (params.page == 1) {
        setLoading(false);
      } else {
        setLoadingMore(false);
      }

      if (res.code == 0) {
        const result = res.result;
        if (params.page == 1) {
          setList(result.list);
        } else {
          setList(list.concat(result.list));
        }

        const _pageCount = Math.ceil(result.pages.count / pageSize);

        if (params.page < _pageCount) {
          setShowLoadingMore(true);
        } else {
          setShowLoadingMore(false);
        }
      }
    });
  };

  useEffect(() => {
    fetchServerTourData();
  }, [params, identity]);

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  return (
    <div className="container px-[.32rem]">
      <div className="mx-auto mt-[.38rem]">
        <div className="text-center text-[var(--colour-48)] relative font-[600] text-[.24rem]">
          <Back className="absolute left-[0px]" text={`←${t('task.back')}`} onClick={handleActivityDetailGoBack} />
          {t('tour.tour')}
        </div>

        <div className="flex items-center justify-between mt-[.28rem]">
          {tablist &&
            tablist.map((tab, index) => {
              return (
                <SwitchWrapper
                  key={index}
                  className="w-[33.33%] h-[.52rem] relative cursor-pointer whitespace-nowrap flex justify-center items-center select-none text-[.24rem] font-[600]"
                  notChooseClassName="text-tab"
                  chooseClassName="text-[var(--colour-1)]"
                  choose={num === index}
                  onClick={() => {
                    if (num != index) {
                      setNum(index);
                      setParams({
                        ...params,
                        status: tab.tourStatus,
                        page: 1,
                      });
                    }
                  }}
                >
                  <div>{tab.title}</div>
                  {num === index && (
                    <div className="absolute left-[0px] bottom-[0px] w-full bg-[var(--colour-1)] rounded-btn h-[.04rem]"></div>
                  )}
                  {index !== tablist.length - 1 && (
                    <div className="absolute right-[0] top-[.08rem] bottom-[.08rem] w-[1px] bg-[var(--colour-6)]" />
                  )}
                  {index == 2 && get(activityStatus, 'activity_tournament') == '1' ? (
                    <RedDot className="absolute -right-[.04rem] -top-[.14rem]" />
                  ) : (
                    <></>
                  )}
                </SwitchWrapper>
              );
            })}
        </div>

        {loading ? (
          <div className="h-[6rem] flex items-center justify-center">
            <Loading isAbsolute={false} />
          </div>
        ) : list.length > 0 ? (
          <div className="mb-[.8rem] mt-[.28rem] grid grid-cols-1 gap-[.8rem]">
            {list.map((item) => {
              return <TournamentItem key={item.id} data={item} update={fetchServerTourData} />;
            })}
          </div>
        ) : (
          <NoData className="py-[2.22rem]" showTextLangkey="promotion.eventend" />
        )}
        {showLoadingMore ? (
          <LoadMore className="mb-[.2rem]" onClick={handleLoadMore} showLoading={loadingMore && params.page > 1} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default NTemplate001;
