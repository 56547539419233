const Loading3 = ({ className = '', className1 = '' }) => {
  return (
    <div className={`com__box flex items-center justify-center w-full space-x-[.1rem] ${className1}`}>
      <div className={`loading ${className}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className="text-[.24rem] font-[500]">Loading...</div>
    </div>
  );
};

export default Loading3;
