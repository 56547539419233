import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Forbidden from '../views/forbidden';
import { langsConfig } from '../constants/langs';
import Download from '../views/download';
import ViewIndex from '../views';

const AppRouter = () => {
  const langs = langsConfig.supportLangList;
  const makeRoute = (langConfig, index, noLocalePath, element) => {
    return (
      <Route
        path={
          langConfig.key.toLowerCase() == langsConfig.initLangKey.toLowerCase()
            ? noLocalePath
            : `/${langConfig.key.toLowerCase()}${noLocalePath}`
        }
        element={element}
        key={index}
      />
    );
  };

  return (
    <Routes>
      {langs.map((o, index) => makeRoute(o, index, '/forbidden', <Forbidden />))}
      {langs.map((o, index) => makeRoute(o, index, '/download', <Download />))}
      <Route path="*" element={<ViewIndex />} />
    </Routes>
  );
};

export default memo(AppRouter);
