import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setInstallStatus } from '../redux/reducers/appSlice';
const ProgressierOperatio = ({ className = '' }) => {
  const [installProgress, setInstallProgress] = useState(0);

  const dispatch = useDispatch();
  const { installStatus } = useSelector(
    (state) => ({
      installStatus: state.app.installStatus,
    }),
    shallowEqual,
  );

  useEffect(() => {
    let timer = null;
    if (installStatus == 1) {
      timer = setInterval(() => {
        if (installProgress < 1) {
          setInstallProgress((prevProgress) => {
            if (prevProgress + 0.01 >= 1) {
              dispatch(setInstallStatus(2));
              clearInterval(timer);
              return 1;
            } else {
              return prevProgress + 0.01;
            }
          });
        }
      }, 120);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [installStatus]);

  return (
    <div className={`mx-auto w-[259px] h-[42px] rounded-[8px] bg-[--colour-42] relative ${className}`}>
      <div
        className={`absolute left-0 top-0 w-[20px] h-full bg-[--colour-1] ${installProgress < 1 ? 'rounded-[8px_0_0_8px]' : 'rounded-[8px]'}`}
        style={{ width: `${(installProgress * 100).toFixed(0)}%` }}
      />
      <div className="absolute abs-center text-[14px] font-[400] text-[--colour-43]">
        {(installProgress * 100).toFixed(0)}%
      </div>
    </div>
  );
};

export default ProgressierOperatio;
