import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetWebHelpInfo } from '../../../../api';
import Loading from '../../../../components/loading2';
import HtmlDownload from '../../../../components/htmlDownload';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

export default function NTemplate001() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const showName = decodeURIComponent(searchParams.get('name'));
  const navigate = useGlobalNavigate();
  useEffect(() => {
    let type = searchParams.get('type');
    let id = searchParams.get('id');
    setLoading(true);
    GetWebHelpInfo({ footer_type: type, footer_id: id }).then((res) => {
      if (res.code === 0) {
        const result = res.result;
        setLoading(false);
        setIsMenu(result.menu);
        setList(result.menu ? result.content : [result.content]);
      }
    });
  }, [searchParams]);

  const clickFaqItem = (item) => {
    navigate(toLocalePath(`/helpInfo?id=${item.id}&type=${item.type}&name=${encodeURIComponent(item.title)}`));
  };

  return (
    <div className="container px-[.32rem]">
      {loading ? (
        <Loading height="400px" />
      ) : isMenu ? (
        <div className="pt-[.12rem]">
          <div className="font-[700] text-[.6rem] leading-[.72rem] mb-[.24rem] text-center text-[var(--colour-48)]">
            {showName}
          </div>
          <div className="m-auto flex flex-wrap w-full justify-between">
            {list.length > 0 &&
              list.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="bg-text flex justify-center items-center font-[500px] text-primary-content cursor-pointer w-[2rem] h-[1.2rem] text-[.24rem] mr-[0rem] mb-[.4rem] border border-solid border-[var(--colour-6)] text-center"
                    onClick={() => clickFaqItem(item)}
                  >
                    {item.title}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div>
          {list.length > 0 &&
            list.map((item, index) => {
              return <HtmlDownload key={index} htmlString={item.content} />;
            })}
        </div>
      )}
    </div>
  );
}
