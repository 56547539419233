import cn from 'classnames';
import Loading from './loading2';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { jsPDF } from 'jspdf';
import downloadImg from '../assets/img/help-download.png';
import printImg from '../assets/img/help-print.png';
import html2canvas from 'html2canvas';

const PrintButton = ({ handlePrint }) => {
  return <img src={printImg} className={cn('cursor-pointer w-[.6rem] h-[.6rem]')} onClick={handlePrint} />;
};

const DownloadButton = ({ handleDownload }) => {
  return <img src={downloadImg} className={cn('cursor-pointer w-[.6rem] h-[.6rem]')} onClick={handleDownload} />;
};

const DocButtonList = ({ handleDownload, handlePrint, showPrint }) => {
  return (
    <div className={cn('absolute top-[0] right-[0] flex items-center justify-center z-[10] space-x-[.24rem]')}>
      {showPrint && <PrintButton handlePrint={handlePrint}></PrintButton>}
      <DownloadButton handleDownload={handleDownload}></DownloadButton>
    </div>
  );
};
const HtmlDownload = ({ htmlString, contentClassName = '', showPrint = true }) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  const exportContentRef = useRef(null);

  const [generatePdf, setGeneratePdf] = useState(false);

  function handlePrint(htmlString) {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(htmlString);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    // printWindow.close();
  }

  const exportPage = async (element, callback = () => {}) => {
    // 这个dom元素是要导出pdf的div容器
    html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      dpi: 120, // 图片清晰度问题
      background: '#FFFFFF', //如果指定的div没有设置背景色会默认成黑色,这里是个坑
    }).then((canvas) => {
      //未生成pdf的html页面高度
      var leftHeight = canvas.height;

      var a4Width = 595.28;
      var a4Height = 841.89; //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
      //一页pdf显示html页面生成的canvas高度;
      var a4HeightRef = Math.floor((canvas.width / a4Width) * a4Height);

      //pdf页面偏移
      var position = 0;

      var pageData = canvas.toDataURL('image/jpeg', 1.0);

      var pdf = new jsPDF('p', 'pt', 'a4'); //A4纸，纵向
      var index = 1,
        canvas1 = document.createElement('canvas'),
        height;
      pdf.setDisplayMode('fullwidth', 'continuous', 'FullScreen');

      var pdfName = 'exported-document';
      function createImpl(canvas) {
        if (leftHeight > 0) {
          index++;

          var checkCount = 0;
          if (leftHeight > a4HeightRef) {
            var i = position + a4HeightRef;
            for (i = position + a4HeightRef; i >= position; i--) {
              var isWrite = true;
              for (var j = 0; j < canvas.width; j++) {
                var c = canvas.getContext('2d').getImageData(j, i, 1, 1).data;

                if (c[0] != 0xff || c[1] != 0xff || c[2] != 0xff) {
                  isWrite = false;
                  break;
                }
              }
              if (isWrite) {
                checkCount++;
                if (checkCount >= 10) {
                  break;
                }
              } else {
                checkCount = 0;
              }
            }
            height = Math.round(i - position) || Math.min(leftHeight, a4HeightRef);
            if (height <= 0) {
              height = a4HeightRef;
            }
          } else {
            height = leftHeight;
          }

          canvas1.width = canvas.width;
          canvas1.height = height;

          var ctx = canvas1.getContext('2d');
          ctx.drawImage(canvas, 0, position, canvas.width, height, 0, 0, canvas.width, height);

          if (position != 0) {
            pdf.addPage();
          }
          pdf.addImage(
            canvas1.toDataURL('image/jpeg', 1.0),
            'JPEG',
            10,
            10,
            a4Width,
            (a4Width / canvas1.width) * height,
          );
          leftHeight -= height;
          position += height;
          if (leftHeight > 0) {
            setTimeout(createImpl, 500, canvas);
          } else {
            pdf.save(pdfName + '.pdf');
            callback();
          }
        }
      }

      //当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < a4HeightRef) {
        pdf.addImage(pageData, 'JPEG', 0, 0, a4Width, (a4Width / canvas.width) * leftHeight);
        pdf.save(pdfName + '.pdf');
        callback();
      } else {
        try {
          pdf.deletePage(0);
          setTimeout(createImpl, 500, canvas);
        } catch (err) {
          // console.log(err);
        }
      }
    });
  };

  const handleDownload = () => {
    const element = exportContentRef.current;
    exportPage(element, () => {
      setGeneratePdf(false);
    });
  };

  useEffect(() => {
    if (generatePdf) {
      handleDownload();
    }
  }, [generatePdf]);

  return (
    <div className="relative">
      <div
        className={cn(`${contentClassName} pt-[.6rem]`, {
          'text-[black] px-[.6rem] text-justify pdf-avoid-break': generatePdf,
        })}
        ref={exportContentRef}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      ></div>
      <DocButtonList
        handleDownload={() => {
          setGeneratePdf(true);
        }}
        handlePrint={() => handlePrint(htmlString)}
        showPrint={showPrint}
      ></DocButtonList>
      {generatePdf ? (
        <div className="absolute w-[100%] h-[100%] left-[0] top-[0] bg-[var(--bg-body)] z-[9]">
          <Loading height="8rem" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HtmlDownload;
