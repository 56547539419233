import { isBrNTemplateId } from '../../../utils/helper';
import NTemplate001 from './normal/template001';
import DTemplate001 from './design/template001';
import { getTemplateId } from '../../../utils/helper';

const DownloadPrompt = () => {
  switch (getTemplateId()) {
    case 'n-template001':
    case 'n-template002':
    case 'd-template003':
      return <NTemplate001 />;

    case 'd-template001':
    case 'd-template002':
      return <DTemplate001 />;
  }
};

export default DownloadPrompt;
