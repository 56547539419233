import axios from 'axios';
import { store } from '../redux/store';
import qs from 'qs';
import globalConfig from '../constants/config';
import { setLoginInfo, setUserObj } from '../redux/reducers/userSlice';
import { setForbidden, setIsMaintain, setMaintainInfo, setNeedLogout } from '../redux/reducers/appSlice';
import { get } from 'lodash';
import { getCurrentLang, getLangMsg, getHostName } from '../utils/helper';
import { getCryptoDeviceInfo } from './deviceUtils';
const AppVersion = import.meta.env.PACKAGE_VERSION;

// identity 失效code
const identityExpireList = [1001001, 1001002, 1001004, 1000401];

// 维护code
export const maintainCode = 1000001;

// create an axios instance
export const service = axios.create({
  timeout: 50000, // request timeout
  transformRequest: [
    function (data) {
      if (data instanceof FormData) {
        return data;
      }
      data = qs.stringify(data);
      return data;
    },
  ],
});

export default service;

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.baseURL = globalConfig.baseURL;
    const identity = get(store.getState(), 'user.identity');
    const secretKey = get(
      store.getState(),
      'app.websiteInitConfig.website_info.website_activity_guide_turntable_device_secret_key',
    );
    // if (String(config.method).toLowerCase() == 'post') {
    //     config.data = qs.stringify(config.data, { indices: false });
    // }
    if (secretKey) {
      const device = getCryptoDeviceInfo(secretKey);
      config.headers = {
        ...config.headers,
        device,
      };
    }
    if (identity) {
      config.headers = {
        ...config.headers,
        identity,
      };
    }

    config.params = {
      ...config.params,
      lang: getCurrentLang(),
      domain: getHostName(),
      version: AppVersion,
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// respone interceptor
service.interceptors.response.use(
  (response) => {
    const code = response.data.code;
    const errMsg = getLangMsg(code);
    if (response.data) {
      response.data.msg = errMsg ? errMsg : response.data.code;
    }
    if (code == 0) {
      // const data = response.data;
      // if (data.result && data.result.user_obj && data.result.user_obj.Uid && data.result.user_obj.WalletAddress) {
      //     store.dispatch(setUserObj(data.result.user_obj));
      // }
      store.dispatch(setIsMaintain(false));
    } else {
      //identity失效
      if (identityExpireList.includes(code)) {
        store.dispatch(setNeedLogout());
        // toast.info(t('home.toast018'));
      } else if (code == 1000008) {
        //ip限制访问
        store.dispatch(setForbidden(true));
      } else if (code == maintainCode) {
        // maintaining
        store.dispatch(setIsMaintain(true));

        const result = response.data.result;
        if (result) {
          store.dispatch(setMaintainInfo(result));
        }
      }
    }

    // 访问国家编码，如 JP BR
    // if (response && response.headers && response.headers['ip-country']) {
    //   store.dispatch(setIpCountry(response.headers['ip-country']));
    //   response.data.__ipcountry = response.headers['ip-country'];
    // }

    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const service2 = axios.create({
  timeout: 50000, // request timeout
  transformRequest: [
    function (data) {
      if (data instanceof FormData) {
        return data;
      }
      data = qs.stringify(data);
      return data;
    },
  ],
});

// request interceptor
service2.interceptors.request.use(
  (config) => {
    config.baseURL = globalConfig.authURL;
    const identity = get(store.getState(), 'user.identity');
    // if (String(config.method).toLowerCase() == 'post') {
    //     config.data = qs.stringify(config.data, { indices: false });
    // }
    if (identity) {
      // config.params = {
      //     ...config.params,
      //     identity
      // };
      config.headers = {
        ...config.headers,
        identity,
      };
    }

    config.params = {
      ...config.params,
      lang: getCurrentLang(),
      domain: getHostName(),
      version: AppVersion,
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// respone interceptor
service2.interceptors.response.use(
  (response) => {
    const code = response.data.code;
    const errMsg = getLangMsg(code);
    if (response.data) {
      response.data.msg = errMsg ? errMsg : response.data.code;
    }
    if (code == 0) {
      // const data = response.data;
      // if (data.result && data.result.user_obj && data.result.user_obj.Uid && data.result.user_obj.WalletAddress) {
      //     store.dispatch(setUserObj(data.result.user_obj));
      // }
      store.dispatch(setIsMaintain(false));
    } else {
      //identity失效
      if (identityExpireList.includes(code)) {
        store.dispatch(setUserObj(null));
        store.dispatch(setLoginInfo(null));
      } else if (code == 1000008) {
        //ip限制访问
        store.dispatch(setForbidden(true));
      } else if (code == maintainCode) {
        // maintaining
        store.dispatch(setIsMaintain(true));

        const result = response.data.result;
        if (result) {
          store.dispatch(setMaintainInfo(result));
        }
      }
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 统计service
export const serviceStat = axios.create({
  timeout: 50000, // request timeout
  transformRequest: [
    function (data) {
      if (data instanceof FormData) {
        return data;
      }
      data = qs.stringify(data);
      return data;
    },
  ],
});

// request interceptor
serviceStat.interceptors.request.use(
  (config) => {
    config.baseURL = globalConfig.statUrl;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// respone interceptor
serviceStat.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);
