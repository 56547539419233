import { useEffect, useState } from 'react';
import DialogModal from '../dialogModal';
import PwaReward from '../dialogModal/downLoadBtn/components/reward';
import { PwaGuideReward } from '../../api';
import { isPWA } from '../../utils/deviceUtils';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

export default function PwareceiveAward() {
  const { identity } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
    }),
    shallowEqual,
  );

  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const ReceiveReward = async () => {
    try {
      const res = await PwaGuideReward();
      if (res.code == 0) {
        if (res.data.show_popup == '1') {
          setVisible(true);
          setData(res.data);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isPWA() && identity) {
      ReceiveReward();
    }
  }, [identity]);

  return (
    <DialogModal
      zIndex="40000"
      shouldCloseOnOverlayClick={true}
      noentered={true}
      style={{
        content: {
          display: 'inline-block',
          textAlign: 'left',
          top: '0px',
          maxWidth: '100%',
          cursor: 'default',
          outline: '0px',
          verticalAlign: 'middle',
          zIndex: '49',
          borderRadius: '.2rem',
        },
      }}
      handleClose={handleClose}
      isOpen={visible}
    >
      <PwaReward content={data} handleClose={handleClose} />
    </DialogModal>
  );
}
