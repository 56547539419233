import { useRef } from 'react';
import DropDownInput from '../../../../../../components/dropDownInput';
import PopChoose, { PopChooseItem } from '../../../../../../components/popChoose';
import TippyPopBox, { Placement } from '../../../../../../components/tippyPopBox';
import useDivSize from '../../../../../../hooks/useDivSize';
import Icon, { IconName } from '../../../../../../components/icon';
import cn from 'classnames';

const HistoryMenu = ({
  isMenuVisible = false,
  onClickOutside = () => {},
  list = [],
  currentMenu,
  onClickMenu = () => {},
}) => {
  const divRef = useRef(null);
  const { width } = useDivSize(divRef);

  return (
    <TippyPopBox
      visible={isMenuVisible}
      onClickOutside={onClickOutside}
      offset={[0, 6]}
      placement={Placement.bottomEnd}
      zIndex={18}
      popChildren={
        <PopChoose
          style={{ width: `${width}px` }}
          className="bg-[var(--colour-35)] border border-[var(--colour-6)] py-[.2rem] max-h-[6rem] overflow-y-auto scrollbar-none"
          children={Array.from(list).map((item, index) => (
            <PopChooseItem
              className="flex items-center p-[.1rem_.2rem]"
              key={index}
              data={item}
              choose={item.key == currentMenu}
              widthNumber={width}
              onClick={item.onClick}
            >
              <span className={`text-[.2rem] font-[400]`}>{item.name}</span>
            </PopChooseItem>
          ))}
        ></PopChoose>
      }
    >
      <div>
        <DropDownInput
          ref={divRef}
          iconCanRotate={false}
          className="w-auto min-w-[2rem] h-[.5rem] text-[.2rem] font-[400] rounded-normal relative px-[.2rem] bg-[var(--colour-35)] border border-[var(--colour-6)]"
          onClick={onClickMenu}
        >
          <div className="text-[var(--colour-44)]">{list.find((item) => item.key == currentMenu)?.name}</div>
          <span
            className={cn('w-[.18rem] h-[.18rem] block text-[var(--colour-11)] transition', {
              'rotate-[90deg]': isMenuVisible,
            })}
          >
            <Icon name={IconName.RightArrow3} />
          </span>
        </DropDownInput>
      </div>
    </TippyPopBox>
  );
};

export default HistoryMenu;
