import { isShareString } from '../utils/helper';
import { openUrl } from '../utils/openUrl';
import isMobile from 'ismobilejs';

/**
 * 分享管理器
 */
class ShareManager {
  /**
   * 分享
   * @param {string} provider 分享方式
   * @param {string} url      分享链接
   * @param {string} text     分享内容
   */
  static share(provider, url, text) {
    // 更多分享
    if (isShareString(provider)) {
      this.showMoreShare &&
        navigator.share({
          title: document.title,
          text: text,
          url: url,
        });
      return;
    }

    // 编码
    text = encodeURIComponent(text);
    url = encodeURIComponent(url);

    const links = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`,
      whatsapp: `https://wa.me/?text=${text}%20%20${url}`,
      telegram: `https://t.me/share/url?url=${url}&text=${text}`,
      twitter: `https://twitter.com/intent/tweet?url=${url}&text=${text}`,
    };

    if (links[provider]) {
      openUrl(links[provider]);
    }
  }

  /**
   * 分享 SMS
   * @param {array} mobiles   手机号数组
   * @param {string} url      分享链接
   * @param {string} text     分享内容
   */
  static shareSMS(mobiles, url, text) {
    const smsText = encodeURIComponent(text + ' ' + url);
    if (isMobile().android.device) {
      openUrl(`sms:${mobiles.join('; ')};?body=${encodeURIComponent(smsText)}`);
    } else {
      openUrl(`sms:/open?addresses=${mobiles.join(',')}&body=${smsText}`);
    }
  }

  /**
   * 分享 Whatsapp SMS
   * @param {array} mobile    手机号
   * @param {string} url      分享链接
   * @param {string} text     分享内容
   */
  static shareWhatsAppSMS(mobile, url, text) {
    text = encodeURIComponent(text);
    url = encodeURIComponent(url);

    const link = `https://wa.me/${mobile}?text=${text}%20%20${url}`;
    openUrl(link);
  }

  /**
   * 是否显示更多分享 getter
   * @returns boolean
   */
  static get showMoreShare() {
    return navigator.share && navigator.canShare;
  }
}

export default ShareManager;
