import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

const initialState = {
  chatOnlineUserNum: 0,
  chatListData: [],
  chatIconUnReadCount: 0, //顶部图标显示的未读消息数量
  chatInputHeightOffset: 0, // 输入框高度变化的偏移量
  chatUnReadAtCount: 0, //未读被at的数量
  chatUnReadCount: 0, // 未读取消息数量
  chatLastMessageReadIndex: 0, //最后一条已读消息的索引
  chatByAtUnReadIndex: 0, //被at最近的索引
  loginUserInfo: null,
  chatAtUserList: [], //chat被at的用户列表
  chatAtSearchUserList: [], //搜索关键字的chat被at的用户列表
  chatInputFoucs: false, //safari input 获取焦点处理逻辑
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatListData: (state, { payload }) => {
      state.chatListData = payload;
    },
    setChatInputHeightOffset: (state, { payload }) => {
      state.chatInputHeightOffset = payload;
    },
    setChatOnlineUserNum: (state, { payload }) => {
      state.chatOnlineUserNum = payload;
    },
    setChatLoginUserInfo: (state, { payload }) => {
      state.loginUserInfo = payload;
    },
    putChatListData: (state, { payload }) => {
      const list = Array.from(state.chatListData).concat(Array.from(payload));
      state.chatListData = list;
    },
    setChatUnReadAtCount: (state, { payload }) => {
      state.chatUnReadAtCount = payload;
    },
    setChatUnReadCount: (state, { payload }) => {
      state.chatUnReadCount = payload;
    },
    setChatLastMessageReadIndex: (state, { payload }) => {
      state.chatLastMessageReadIndex = payload;
    },
    setChatAtUserList: (state, { payload }) => {
      state.chatAtUserList = payload;
    },
    putChatAtUserList: (state, { payload }) => {
      state.chatAtUserList = uniqBy(Array.from(state.chatAtUserList).concat(Array.from(payload)), 'uid');
    },
    setChatAtSearchUserList: (state, { payload }) => {
      state.chatAtSearchUserList = payload;
    },
    setChatByAtUnReadIndex: (state, { payload }) => {
      state.chatByAtUnReadIndex = payload;
    },
    setChatIconUnReadCount: (state, { payload }) => {
      state.chatIconUnReadCount = payload;
    },
    setChatInputFoucs: (state, { payload }) => {
      state.chatInputFoucs = payload;
    },
  },
});

export const {
  setChatListData,
  putChatListData,
  setChatInputHeightOffset,
  setChatOnlineUserNum,
  setChatLoginUserInfo,
  setChatUnReadAtCount,
  setChatUnReadCount,
  setChatLastMessageReadIndex,
  setChatAtUserList,
  putChatAtUserList,
  setChatAtSearchUserList,
  setChatByAtUnReadIndex,
  setChatIconUnReadCount,
  setChatInputFoucs,
} = chatSlice.actions;

export default chatSlice.reducer;
