import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { useDispatch } from 'react-redux';
import { setShowSupportNew } from '../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';

import LimitClickWrapper from '../components/limitClickWrapper';
import Close from './close';
import { CustomerList } from '../api';
import { getSupportPhoto } from '../utils/helper';
import Image from './image';
import useOnclickOutside from 'react-cool-onclickoutside';
import { openUrl } from '../utils/openUrl';
import SupportPng from '../assets/img/supportphoto.png';

export default function SupportNew() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClose = () => {
    dispatch(setShowSupportNew(false));
  };

  const ref = useOnclickOutside(() => {
    handleClose();
  });

  return (
    <div className="">
      <div className="fixed inset-0 hidden z-[104]" onClick={handleClose}></div>
      <div
        ref={ref}
        className={cn(
          'fixed pb-[14px] border border-solid border-transparent flex flex-col bg-[var(--colour-9)] inset-0 z-[9999999]',
        )}
      >
        <div className="w-full min-h-[1rem] bg-[var(--colour-35)] flex items-center justify-between pl-[.32rem] pr-[.2rem]">
          <div className="text-[.4rem] font-[600] text-[var(--colour-48)]">{t('home.service')}</div>
          <div className="w-[.68rem] h-[.68rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
            <Close onClick={handleClose} />
          </div>
        </div>

        <Dtemplate003Support />
      </div>
    </div>
  );
}

export function Dtemplate003Support() {
  const { t } = useTranslation();

  const [CustomerListData, setCustomerListData] = useState(null);

  useEffect(() => {
    CustomerList().then((res) => {
      if (res.code == 0) {
        setCustomerListData(res.result.list);
      }
    });
  }, []);

  const getSupportText = (num) => {
    if (num == 1) {
      return 'Livechat';
    } else if (num == 2) {
      return 'Telegram';
    } else if (num == 3) {
      return 'Facebook';
    } else if (num == 4) {
      return 'Message';
    } else if (num == 5) {
      return 'Whatsapp';
    } else if (num == 6) {
      return 'Tiktok';
    } else if (num == 7) {
      return 'Instagram';
    } else {
      return '';
    }
  };

  return (
    <div className="relative px-[.32rem] pt-[.2rem] scrollbar-none overflow-y-auto">
      <div className="w-full mx-auto flex flex-col">
        <div className="w-full min-h-[1.56rem] rounded-[.24rem] border border-[var(--colour-6)] bg-[var(--colour-35)] p-[.16rem_.12rem_.24rem_.16rem] flex space-x-[.16rem] mb-[.2rem]">
          <div className="w-[1.04rem] h-[1.04rem]">
            <img className="not-drag w-full" src={SupportPng} alt="" />
          </div>

          <div className="flex-1">
            <div className="text-[var(--colour-48)] text-[.3rem] font-[600]">Apoio online 24/7</div>
            <div className="text-[var(--colour-49)] text-[.24rem] font-[500]">
              Servico ao cliente disponivel para ajudar a solucionar problemas e responder a duvidas apresentadas.
            </div>
          </div>
        </div>

        <div className="space-y-[.2rem]">
          {CustomerListData &&
            CustomerListData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="w-full h-[1.14rem] rounded-[.24rem] border border-[var(--colour-6)] bg-[var(--colour-35)] flex items-center pl-[.16rem]"
                >
                  <Image
                    src={getSupportPhoto(item.icon)}
                    className="w-[.78rem] h-[.78rem]"
                    fallbackSrc={getSupportPhoto(1)}
                    onError={(currentTarget) => {
                      currentTarget.parentNode.children[0].srcset = getSupportPhoto(1);
                    }}
                  />

                  <div className="flex-1 flex items-center justify-between pr-[.16rem] pl-[.22rem]">
                    <div>
                      <div className="text-[var(--colour-40)] text-[.24rem] font-[500]">
                        {getSupportText(item.icon)}
                      </div>
                      <div className="text-[var(--colour-14)] text-[.22rem] font-[500]">{item.name}</div>
                    </div>

                    <LimitClickWrapper
                      onClick={() => {
                        if (item.url) {
                          openUrl(item.url);
                        }
                      }}
                      className="w-[1.64rem] h-[.58rem] cursor-pointer rounded-[.16rem] colour-79 flex items-center justify-center text-[var(--colour-2)] text-[.24rem] font-[600] active:scale-[.95] transition-all select-none"
                    >
                      Contato
                    </LimitClickWrapper>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
