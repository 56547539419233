import { useEffect, useState, useRef } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export default function ScrollbarContent({ children, className = '', style = {}, viewportClassName = '' }) {
  const scrollRef = useRef(null);
  const handleScroll = (e) => {
    const myEvent = new CustomEvent('overlayScrollerCustomScroll', {
      detail: e,
    });
    document.dispatchEvent(myEvent);
  };

  const handleScrollTo = (e) => {
    const { offsetTop, isOffset = false, behavior = 'auto' } = e.detail;
    const osInstance = scrollRef.current.osInstance();
    const { viewport } = osInstance.elements();
    const { scrollLeft, scrollTop } = viewport; // Get scroll offset
    viewport.scrollTo({ top: isOffset ? scrollTop + offsetTop : offsetTop, behavior: behavior }); // Set scroll offset
  };

  useEffect(() => {
    document.addEventListener('overlayScrollerScrollTo', handleScrollTo);
    return () => document.removeEventListener('overlayScrollerScrollTo', handleScrollTo);
  }, []);

  useEffect(() => {
    const osInstance = scrollRef.current.osInstance();
    const { viewport } = osInstance.elements();
    if (viewport) {
      viewport.setAttribute('class', `${viewportClassName}`);
    }
  }, [viewportClassName]);

  return (
    <OverlayScrollbarsComponent
      options={{
        overflow: {
          x: 'hidden',
        },
      }}
      ref={scrollRef}
      events={{
        scroll: handleScroll,
      }}
      className={className}
      style={style}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
}
