import { memo } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

/**
 * Meta的Pixel统计
 * 支持从url获取pixelId
 */
const MetaPixel = () => {
  const pixelId = useSelector((state) => state.user.pixelId);

  return pixelId ? (
    <Helmet>
      <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');
          fbq('track', 'ViewContent');
        `}</script>
      <noscript>{`
          <img
            height="1"
            width="1"
            style="display:none" 
            src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"
          />
        `}</noscript>
      <noscript>{`
          <img
            height="1"
            width="1"
            style="display:none" 
            src="https://www.facebook.com/tr?id=${pixelId}&ev=ViewContent&noscript=1"
          />
        `}</noscript>
    </Helmet>
  ) : (
    <></>
  );
};

export default memo(MetaPixel);
