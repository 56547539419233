import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import { isLogin } from '../../../../utils/helper';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../redux/reducers/appSlice';
import Badge from '../../../../views/mine/normal/template001/components/badge';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const PendingBonus = ({ handleLinkClick = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const { promotionClaimedCount } = useSelector(
    (state) => ({
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );

  const clickPending = () => {
    if (isLogin()) {
      navigate(toLocalePath('/promotion/pending'));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }

    handleLinkClick();
  };

  return (
    <div
      onClick={clickPending}
      className="active:scale-[.95] transition-all w-[1.26rem] h-[.82rem] mb-[.08rem] rounded-normal cursor-pointer bg-[url(../assets/img/drawerMenuPendingBg.png)] bg-no-repeat bg-[length:100%_100%] p-[.08rem] relative"
    >
      <div className="font-[600] text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow">
        <div>{t('home.pending')}</div>
      </div>
      {(promotionClaimedCount?.data?.pending || 0) > 0 ? (
        <Badge
          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
          className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.15rem] !right-[-0.04rem] flex items-center"
          text={promotionClaimedCount?.data?.pending || 0}
        ></Badge>
      ) : null}
    </div>
  );
};

export default PendingBonus;
