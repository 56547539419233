import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Icon, { IconName } from '../icon';
import LimitClickWrapper from '../limitClickWrapper';
import { getShowMoney, isControllableSite, isUseLayoutMobile } from '../../utils/helper';
import layout3PlusImg from '../../assets/layout3-img/plus.png';
import { setDialogModalVisible, DialogModalChildrenKey, setDrawerMenuVisible } from '../../redux/reducers/appSlice';
import cn from 'classnames';
import TippyPopBox, { Placement } from '../tippyPopBox';

export default function HeaderWallet({ clickFreshen, balanceLoad, clickWallet }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userObj, websiteInitConfig, drawerMenuVisible } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      websiteInitConfig: state.app.websiteInitConfig,
      drawerMenuVisible: state.app.drawerMenuVisible,
    }),
    shallowEqual,
  );

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  return userObj ? (
    <div className={cn('flex items-center', {})}>
      <div
        className={cn(
          'min-w-[3.36rem] h-[.8rem] flex justify-between items-center space-x-[.14rem] cursor-pointer bg-[var(--colour-34)] text-[var(--colour-23)] rounded-normal',
          {
            'pl-[.1rem]': !isControllableSite(),
            'px-[.16rem]': isControllableSite(),
            '!h-[.6rem] !min-w-[2.52rem]': isControllableSite() && !isUseLayoutMobile(),
            '!h-[.6rem]': isControllableSite(),
            '!h-[.56rem] !min-w-[2.04rem] !bg-[var(--colour-22)] !rounded-[.5rem] border-2 border-solid border-[var(--colour-1)]':
              isUseLayoutMobile(),
          },
        )}
      >
        <LimitClickWrapper
          className={cn('flex items-center flex-1 justify-between h-full', {
            'flex-row-reverse': isControllableSite(),
            '!flex-row': isUseLayoutMobile(),
          })}
          onClick={clickFreshen}
        >
          <div
            className={cn('flex items-center', {
              'space-x-[.08rem]': !isControllableSite(),
              'mr-[.1rem]': isControllableSite(),
            })}
          >
            <div className="font-[700] text-[.24rem]">{websiteInitConfig.website_info.currency_symbol || ''}</div>
            <div className="font-[400] text-[.24rem]">
              {balanceLoad ? t('home.loading') : getShowMoney(userObj?.balance, 2, 2)}
            </div>
          </div>
          <div
            className="text-[var(--colour-1)] w-[.28rem] h-[.28rem]"
            style={balanceLoad ? { animation: 'frashenTurn 0.5s linear infinite' } : {}}
          >
            <Icon name={!isControllableSite() ? IconName.Freshen : IconName.Frshen} />
          </div>
        </LimitClickWrapper>
        {!isUseLayoutMobile() ? (
          <div
            className="flex items-center space-x-[.18rem] active:scale-[.95] transition-all select-none"
            onClick={clickWallet}
          >
            <div
              className={cn(
                'text-[var(--colour-2)] relative w-[.72rem] h-[.72rem] flex justify-center items-center bg-[var(--colour-1)] rounded-normal',
                {
                  '!w-[.6rem] !h-[.6rem]': isControllableSite(),
                },
              )}
            >
              <div className="w-[.4rem] h-[.32rem]">
                <Icon name={IconName.Wallet} />
              </div>
              <WalletTips />
            </div>
          </div>
        ) : null}
      </div>
      {isUseLayoutMobile() ? (
        <LayoutMobileWallet handleLinkClick={handleLinkClick} clickWallet={clickWallet} />
      ) : isControllableSite() ? (
        <div
          className="w-[1.28rem] h-[.6rem] rounded-normal bg-[var(--colour-1)] text-center leading-[.6rem] text-[.24rem] font-[500] ml-[.16rem] text-[var(--colour-2)]"
          onClick={clickWallet}
        >
          {t('home.wallet_deposit')}
        </div>
      ) : isControllableSite() ? (
        <div
          className="w-[.6rem] h-[.6rem] rounded-normal flex items-center justify-center primary_bg ml-[.16rem]"
          onClick={clickWallet}
        >
          <div className="w-[.32rem] h-[.32rem] text-[var(--text-primary-btn)]">
            <Icon name={IconName.Wallet} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
}

function WalletTips({}) {
  const { initData } = useSelector(
    (state) => ({
      initData: state.app.websiteInitData?.prompt,
    }),
    shallowEqual,
  );

  return initData?.walletPromotionTips.title ? (
    <div
      className="absolute min-w-[1.48rem] h-[.4rem] px-[.1rem] rounded-normal bg-[var(--colour-58)] z-[1] top-[-.32rem] drop-shadow-[0_.08rem_.08rem_rgba(0,0,0,0.25)] flex items-center"
      style={{
        animation: 'wallet_tips 1.5s linear infinite',
        transformOrigin: 'bottom',
      }}
    >
      <div className="text-[var(--colour-36)] text-[.24rem] font-[600] whitespace-nowrap">
        {initData?.walletPromotionTips.title}
      </div>
      <div className="absolute header-wallet-tip left-[50%] top-[.4rem] translate-x-[-50%]"></div>
    </div>
  ) : null;
}

function LayoutMobileWallet({ handleLinkClick, clickWallet }) {
  const { t } = useTranslation();
  return (
    <div
      className="min-w-[1.4rem] h-[.56rem] rounded-normal bg-[var(--colour-1)] ml-[.16rem] px-[.2rem] flex justify-center items-center cursor-pointer"
      onClick={() => {
        clickWallet();
        handleLinkClick();
      }}
    >
      <div className="text-[var(--colour-2)] text-[.24rem] font-[500]">{t('home.wallet_deposit')}</div>
    </div>
  );
}
