import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * 自定义钩子用于页面导航
 * 它帮助我们追踪用户来自的页面路径，并提供了一种方式来返回到那个路径
 *
 * @returns {Object} 返回一个对象，其中包含一个用于后退导航的函数
 */
export const usePageNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const originPathRef = useRef(null);

  useEffect(() => {
    if (!originPathRef.current && location.state?.from) {
      originPathRef.current = location.state.from;
    }
  }, [location]);

  const handleBack = () => {
    if (originPathRef.current) {
      navigate(originPathRef.current);
    } else {
      navigate('/');
    }
  };

  return {
    handleBack,
  };
};
