import { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';
import { get } from 'lodash';
import Icon, { IconName } from '../../../../../../components/icon';
import Input from '../../../../../../components/input';
import CodeBox from '../components/codeBox';
import LimitClickWrapper from '../../../../../../components/limitClickWrapper';
import WidthdrawQuestion from '../../../../../../components/wallet/component/widthdrawQuestion';
import ElementWithLoading from '../../../../../../components/elementWithLoading';
import DialogModal from '../../../../../../components/dialogModal';
import ResultTips from '../../../../../../components/resultTips';
import TextTips from '../../../../../../components/textTips';
import InputErrorTips from '../components/inputErrorTips';
import TippyPopBox, { Placement } from '../../../../../../components/tippyPopBox';
import PopChoose, { PopChooseItem } from '../../../../../../components/popChoose';
import { PaymentAccWithdrawSubmit } from '../../../../../../api/newDepositWithdraw';
import {
  pointToComma,
  getShowMoneyNumber,
  putChangeText,
  commaToPoint,
  cutDecimal,
  putShowMoney,
  formatCpf,
} from '../../../../../../utils/helper';
import { toLocalePath } from '../../../../../../utils/localePath';
import BigNumber from 'bignumber.js';
import ErrorTip from '../components/errorTip';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../../../redux/reducers/appSlice';
import ToastTip from '../../../../../../components/toastTip';
import usePayConfig from '../../../../../../hooks/usePayConfig';
import ConfirmTips from '../../../../../../components/confirmTips';
import { useGlobalNavigate } from '../../../../../../contexts/navigateContext';
import { eventEmitter, EmitterEventTypes } from '../../../../../../utils/eventEmitter';

export default function RequestWithdrawal({ configData = null, updateConfig = () => {} }) {
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { websiteInfo, currency_code, responsiveMode, userObj } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      currency_code: get(state, 'app.websiteInitConfig.website_info.currency_code') || '',
      responsiveMode: get(state, 'app.responsiveMode'),
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );
  const { gift_money_menu_show_switch, currency_decimal_point_precision } = usePayConfig();
  const [isShow, setIsShow] = useState(false); //下拉框是否展开
  const [showInputErorTip, setShowInputErrorTip] = useState(false); //输入框红色信息提示
  const [isOpen, setIsOpen] = useState(false); //WidthdrawQuestion弹窗是否展示
  const [isShowResultTips, setIsShowResultTips] = useState(false); //弹窗是否展示
  const [isShowTextTips, setIsShowTextTips] = useState(false); //弹窗是否展示

  const [accountList, setAccountList] = useState([]); //提现账户列表
  const [requesting, setRequesting] = useState(false);
  const [renderTime, setRenderTime] = useState(''); //让code组件重新渲染
  const [showErrorTip, setShowErrorTip] = useState(false);
  const withdrawAmountLimit = useRef([]);

  const [params, setParams] = useState({
    account_id: '',
    password: '',
    amount: '',
    config_id: '',
    isCanUse: '',
    min_num: '',
    max_num: '',
  });

  const getWithdrawAmountLimitObj = (method) => {
    let obj = null;
    const list = withdrawAmountLimit.current;
    for (let i = 0; i < list.length; i++) {
      if (String(list[i].method).toLowerCase() == String(method).toLowerCase()) {
        obj = list[i];
      }
    }
    return obj;
  };

  const handleWithdrawMethedSet = (item) => {
    const amountLimitObj = getWithdrawAmountLimitObj(item.method);
    if (item['withdraw_config']) {
      setParams({
        ...params,
        account_id: item.id,
        config_id: item['withdraw_config'].id,
        isCanUse: item.is_can_use,
        min_num: amountLimitObj?.min || 0,
        max_num: amountLimitObj?.max || 0,
      });
    } else {
      setParams({
        ...params,
        account_id: item.id,
        config_id: '',
        isCanUse: item.is_can_use,
        min_num: amountLimitObj?.min || 0,
        max_num: amountLimitObj?.max || 0,
      });
    }
  };

  useEffect(() => {
    if (
      configData &&
      configData.payment &&
      configData.payment.withdraw_account &&
      configData.payment.withdraw_account.length > 0 &&
      configData.withdraw_amount_limit &&
      configData.withdraw_amount_limit.length > 0
    ) {
      withdrawAmountLimit.current = configData.withdraw_amount_limit;
      const list = configData.payment.withdraw_account.filter((item) => {
        return item.is_can_use == 1;
      });
      if (list && list.length > 0) {
        setAccountList(list);
        handleWithdrawMethedSet(list[0]);
      }
    }
  }, [configData]);

  const getFilterTypeStr = () => {
    let res = accountList.find((item) => {
      return item.id == params.account_id;
    });
    return res ? `${res.network} ${showCpf(res[res.method])} ${String(res.method).toUpperCase()}` : '';
  };

  const showCpf = (str) => {
    return `(****${str.slice(-4)})`;
  };

  const handleCodeChange = (codeStr) => {
    setParams({ ...params, password: codeStr });
    if (codeStr.length < 6) {
      setShowErrorTip(true);
    } else {
      setShowErrorTip(false);
    }
  };

  const goAccuntListPage = () => {
    navigate(toLocalePath(`/pay/withdrawtabpage/2`));
  };

  const handleClickConfirm = () => {
    let banlace = moneyReturn();

    if (!commaToPoint(params.amount)) {
      toast.error(<ToastTip type="error" content={t('deposit-withdraw.enter_amount')} />, {
        containerId: 'tip',
        icon: false,
      });

      return;
    }

    if (Number(commaToPoint(params.amount)) > Number(banlace)) {
      toast.error(<ToastTip type="error" content={t('deposit-withdraw.amount_exceeds')} />, {
        containerId: 'tip',
        icon: false,
      });

      return;
    }

    if (Number(commaToPoint(params.amount)) < Number(getShowMoneyNumber(params.min_num || 0))) {
      return toast.error(
        <ToastTip
          type="error"
          content={t('deposit-withdraw.deposit_submit1', {
            min_amount: getShowMoneyNumber(params.min_num || 0),
            currency_symbol: websiteInfo.currency_symbol || '',
          })}
        />,
        {
          containerId: 'tip',
          icon: false,
        },
      );
    }

    if (Number(commaToPoint(params.amount)) > Number(getShowMoneyNumber(params.max_num || 0))) {
      return toast.error(
        <ToastTip
          type="error"
          content={t('deposit-withdraw.deposit_submit2', {
            max_amount: getShowMoneyNumber(params.max_num || 0),
            currency_symbol: websiteInfo.currency_symbol || '',
          })}
        />,
        {
          containerId: 'tip',
          icon: false,
        },
      );
    }

    if (configData?.payment.switch_withdraw_password == '1' && params.password.length < 6) {
      return setShowErrorTip(true);
    }

    //选中的账户无效
    if (params.isCanUse != 1) {
      return toast.error(<ToastTip type="error" content={t('home.toast030')} />, {
        containerId: 'tip',
        icon: false,
      });
    }

    setRequesting(true);
    PaymentAccWithdrawSubmit({ ...params, amount: putShowMoney(commaToPoint(params.amount)) })
      .then((res) => {
        if (res.code == 0) {
          toast.error(<ToastTip type="success" content={t('deposit-withdraw-wg.withdraw_success_tips')} />, {
            containerId: 'tip',
            icon: false,
          });

          updateConfig();

          setParams((params) => {
            return { ...params, password: '', amount: '' };
          });
          setRenderTime(new Date().getTime());
        } else {
          toast.error(<ToastTip type="error" content={res.msg} />, {
            containerId: 'tip',
            icon: false,
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const moneyReturn = () => {
    const balance = get(userObj, 'balance');
    const locked_balance = get(userObj, 'locked_balance');

    if (balance) {
      const val2 = getShowMoneyNumber(BigNumber(balance).minus(locked_balance).toFixed());
      return val2 <= 0 ? 0 : Number(cutDecimal(val2, currency_decimal_point_precision || 2));
    } else {
      return 0;
    }
  };

  const handleChangeshowErrorTip = (val) => {
    if (Number(commaToPoint(val)) < Number(getShowMoneyNumber(params.min_num || 0))) {
      setShowInputErrorTip(true);
    } else {
      setShowInputErrorTip(false);
    }
  };

  const OpenGiftMoneyDialog = () => {
    dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
  };

  const divRef = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (accountList && accountList.length > 0) {
      const updateWidth = () => {
        if (divRef.current) {
          setWidth(divRef.current.offsetWidth);
        }
      };

      updateWidth();

      const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
        updateWidth();
      });
      return () => {
        unsubscribe();
      };
    }
  }, [accountList]);

  return (
    <div className="pb-[.64rem] text-[.24rem] font-[400] flex-1 flex flex-col justify-between">
      <DialogModal
        onRequestClose={() => {
          setIsOpen(false);
        }}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: '50',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isOpen}
      >
        <WidthdrawQuestion
          decimalPoit={currency_decimal_point_precision || 2}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      </DialogModal>

      <ConfirmTips type="withdraw" />

      <TextTips
        setShowVerify={setIsShowTextTips}
        showVerify={isShowTextTips}
        headerText={t('deposit-withdraw-wg.withdraw_processing_rate_rules')}
        contentText={t('deposit-withdraw-wg.withdraw_processing_rate_rules_text1')}
        contentText2={t('deposit-withdraw-wg.withdraw_processing_rate_rules_text2')}
      />

      <div>
        <div className="py-[.2rem] bg-[var(--colour-35)]">
          <div className="px-[.24rem] flex items-center mb-[.16rem]">
            <div className="leading-[.3rem] text-[var(--colour-18)] mr-[.2rem] min-w-[1.58rem]">
              {t('deposit-withdraw-wg.withdraw_withdrawable')}
            </div>
            <div className="leading-[.3rem] text-[var(--colour-26)] mr-[.24rem]">{pointToComma(moneyReturn())}</div>
            <div
              className="w-[.32rem] h-[.32rem] text-[var(--colour-7)]"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <Icon name={IconName.WidthdrawQuestion2} />
            </div>
          </div>
          {gift_money_menu_show_switch == 1 ? (
            <div className="px-[.24rem] flex items-center">
              <div className="leading-[.3rem] text-[var(--colour-18)] mr-[.2rem] min-w-[1.58rem]">
                {t('deposit-withdraw-wg.withdraw_gift_money')}
              </div>
              <div
                className="leading-[.3rem] text-[var(--colour-13)] mr-[.24rem] underline"
                onClick={() => {
                  OpenGiftMoneyDialog();
                }}
              >
                {pointToComma(getShowMoneyNumber(configData?.user_claim_money?.total_money || 0))}
              </div>
            </div>
          ) : null}
        </div>
        <div className="border-t border-b border-[var(--colour-6)] bg-[var(--colour-68)] p-[.4rem_.24rem_.22rem_.24rem] mb-[.32rem]">
          {accountList && accountList.length > 0 ? (
            <>
              <div className="flex items-center justify-between mb-[.4rem] pr-[.16rem]">
                <TippyPopBox
                  placement={Placement.bottomEnd}
                  popChildren={
                    <PopChoose
                      scrollBarWrapperClassName=""
                      className={`py-[.38rem] bg-[var(--colour-34)] border border-[var(--colour-6)]`}
                      children={accountList.map((item, index) => (
                        <PopChooseItem
                          className={`text-[.24rem] font-[500] leading-[.3rem] pl-[.2rem] ${index != 0 ? 'mt-[.36rem]' : ''} `}
                          key={index}
                          data={item}
                          choose={params.account_id == item.id}
                          chooseClassName="!text-[var(--colour-1)]"
                          widthNumber={width}
                          onClick={() => {
                            handleWithdrawMethedSet(item);
                            setIsShow(false);
                          }}
                        >
                          <span className="">
                            {item.network} {showCpf(item[item.method])} {String(item.method).toUpperCase()}
                          </span>
                        </PopChooseItem>
                      ))}
                    />
                  }
                  visible={isShow}
                  onClickOutside={() => {
                    setIsShow(false);
                  }}
                  offset={[0, 10]}
                >
                  <div
                    ref={divRef}
                    className={cn(
                      'h-[.72rem] rounded-normal bg-[var(--colour-35)] border pl-[.24rem] pr-[.2rem] flex items-center justify-between flex-1',
                      {
                        'border-[var(--colour-1)]': isShow,
                        'border-[var(--colour-6)]': !isShow,
                      },
                    )}
                    onClick={() => {
                      setIsShow(isShow ? false : true);
                    }}
                  >
                    <div className="flex items-center">
                      <div className="w-[.24rem] h-[.24rem] mr-[.24rem]">
                        <Icon name={IconName.Pix2} />
                      </div>
                      <div className="text-[.24rem] font-[500] text-[var(--colour-37)] leading-[.34rem]">
                        {getFilterTypeStr()}
                      </div>
                    </div>
                    <div
                      className={cn('w-[.14rem] h-[.24rem] text-[var(--colour-11)]', {
                        'rotate-[-90deg]': isShow,
                        'rotate-[90deg]': !isShow,
                      })}
                    >
                      <Icon name={IconName.BannerNext2} />
                    </div>
                  </div>
                </TippyPopBox>
                <div className="w-[.44rem] h-[.32rem] text-[var(--colour-13)] ml-[.2rem]" onClick={goAccuntListPage}>
                  <Icon name={IconName.PixSetting} />
                </div>
              </div>
              <div>
                <Input
                  leftInner={
                    <div className="flex items-center">
                      <span className="text-[.2rem] font-[500] leading-[.28rem] mr-[.24rem] text-[var(--colour-38)]">
                        {websiteInfo.currency_symbol || ''}
                      </span>
                    </div>
                  }
                  rightInner={
                    <div
                      className="text-[.24rem] font-[500] leading-[.34rem] text-[var(--colour-13)]"
                      onClick={() => {
                        let num = Math.min(Number(moneyReturn()), Number(getShowMoneyNumber(params.max_num || 0)));
                        handleChangeshowErrorTip(num);
                        setParams({ ...params, amount: num });
                      }}
                    >
                      {t('deposit-withdraw-wg.withdraw_amount_all')}
                    </div>
                  }
                  type="number"
                  value={params.amount}
                  onChange={(ev) => {
                    let val = putChangeText(ev.target.value);
                    setParams({ ...params, amount: val });
                    handleChangeshowErrorTip(val);
                  }}
                  onFocus={(ev) => {
                    let dom = ev.target.parentNode.parentNode.parentNode;
                    dom.style.borderColor = 'var(--colour-1)';
                  }}
                  onBlur={(ev) => {
                    let dom = ev.target.parentNode.parentNode.parentNode;
                    dom.style.borderColor = 'var(--colour-6)';
                    let val = putChangeText(ev.target.value);
                    handleChangeshowErrorTip(val);
                  }}
                  placeholder={t('deposit-withdraw.input_amount', {
                    min: pointToComma(getShowMoneyNumber(params.min_num || 0)),
                    max: pointToComma(getShowMoneyNumber(params.max_num || 0)),
                  })}
                  innerClassName="!h-[.72rem] !border-transparent !px-[.24rem]"
                  topClassName={`rounded-normal overflow-hidden bg-[var(--colour-35)]`}
                  inputClassName="tap-highlight-transparent placeholder:!text-[var(--colour-61)]"
                />
                <InputErrorTips
                  showTips={showInputErorTip}
                  text={
                    <Trans
                      t={t}
                      i18nKey="deposit-withdraw-wg.withdraw_amount_error_tip"
                      values={{
                        val: pointToComma(getShowMoneyNumber(params.min_num || 0)),
                      }}
                    />
                  }
                />
              </div>
              <div
                className="mt-[.22rem] mb-[.1rem] text-[.24rem] font-[500] leading-[.3rem]"
                onClick={() => {
                  setIsShowTextTips(true);
                }}
              >
                <span className="text-[var(--colour-41)] mr-[.08rem]">
                  <Trans
                    t={t}
                    i18nKey="deposit-withdraw-wg.withdraw_amount_tip1"
                    values={{
                      val1: `${websiteInfo.currency_symbol || ''} ${BigNumber(Number(params.amount))
                        .times(configData?.withdraw_fee || 0)
                        .toFixed()}`,
                      val2: `${websiteInfo.currency_symbol || ''} ${BigNumber(Number(params.amount))
                        .minus(
                          BigNumber(Number(params.amount))
                            .times(configData?.withdraw_fee || 0)
                            .toFixed(),
                        )
                        .toFixed()}`,
                    }}
                  />
                </span>
                <span className="text-[var(--colour-26)]">{t('deposit-withdraw-wg.withdraw_amount_tip2')}</span>
              </div>
            </>
          ) : (
            <>
              <div
                className="w-full h-[.72rem] bg-[var(--colour-35)] border border-[var(--colour-6)] px-[.22rem] flex items-center justify-between rounded-normal mb-[.4rem]"
                onClick={goAccuntListPage}
              >
                <div className="flex items-center">
                  <div className="w-[.24rem] h-[.24rem] text-[var(--colour-13)] mr-[.24rem]">
                    <Icon name={IconName.Wallet2} />
                  </div>
                  <div className="text-[.24rem] font-[500] leading-[.34rem] text-[var(--colour-61)]">
                    {t('deposit-withdraw-wg.withdraw_add_account')}
                  </div>
                </div>
                <div className="w-[.12rem] h-[.22rem] text-[var(--colour-11)]">
                  <Icon name={IconName.BannerNext2} />
                </div>
              </div>
              <div
                className="w-full h-[.72rem] bg-[var(--colour-35)] border border-[var(--colour-6)] px-[.22rem] flex items-center rounded-normal mb-[.38rem]"
                onClick={goAccuntListPage}
              >
                <div className="text-[.2rem] font-[500] leading-[.28rem] text-[var(--colour-38)] mr-[.24rem]">
                  {websiteInfo.currency_symbol || ''}
                </div>
                <div className="text-[.24rem] font-[500] leading-[.34rem] text-[var(--colour-61)]">
                  {t('deposit-withdraw-wg.withdraw_please_add_account_for_withdrawal_first')}
                </div>
              </div>
            </>
          )}
        </div>
        {configData?.payment.switch_withdraw_password == '1' ? (
          <div className="px-[.24rem] mb-[.94rem] relative">
            <CodeBox
              key={renderTime}
              title={t('deposit-withdraw-wg.withdraw_text1')}
              handleCodeChange={handleCodeChange}
              className=""
              headerClassName="mb-[.16rem]"
            />
            {showErrorTip ? <ErrorTip /> : null}
          </div>
        ) : null}

        <div className="px-[.24rem]">
          <LimitClickWrapper
            className={cn(
              'w-full h-[.7rem] rounded-normal text-[.24rem] font-[500] text-[var(--colour-2)] flex items-center justify-center active:scale-[.95] transition-all select-none bg-[var(--colour-1)] cursor-pointer',
            )}
            onClick={() => {
              if (!requesting) {
                handleClickConfirm();
              }
            }}
          >
            <ElementWithLoading
              isLoading={requesting}
              normalEl={<div className="">{t('deposit-withdraw-wg.withdraw_confirm_withdrawal')}</div>}
            />
          </LimitClickWrapper>
        </div>
      </div>
    </div>
  );
}
