import React, { useState, useRef, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import GameItem from './gameItem';
import { Link } from 'react-router-dom';
import Icon, { IconName } from './icon';
import Button from './button';
import NoData from './noData';
import { cmsImgUrl, nameToUrlParam, isControllableSite } from '../utils/helper';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { toLocalePath } from '../utils/localePath';
import { Grid, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import HomeTourCard from './tour/homeTourCard';
import ViewAll from '../components/home/viewAll';
import HomeProvider from './homeProvider';
import { useStateIfMounted } from 'use-state-if-mounted';
import MultipleRowsSwiper from './multipleRowsSwiper';
import CategoryGamelistWithLoadMore from '../components/categoryGameWithLoadMore';
import HomeModuleHeader from './homeModuleHeader';
import useLangServerConfig from '../hooks/useLangServerConfig';
import { eventEmitter, EmitterEventTypes } from '../utils/eventEmitter';
import Loading2 from './loading2';
import { get } from 'lodash';
import { GetRecentListReq } from '../api';

const GameList = ({
  maxRows,
  list,
  gap,
  pageTotal,
  isClickProvider = false,
  clickProviderItem = () => {},
  loadMoreWithText = false,
  lang_key_title = '',
  gameListGridClassName = '',
  game_type_id = '',
}) => {
  return (
    <div className="relative">
      {list.length === 0 ? (
        <NoData className="py-[.9rem]" />
      ) : maxRows > 1 && isControllableSite() ? (
        <CategoryGamelistWithLoadMore
          maxRows={maxRows}
          data={list}
          gap={gap}
          pageTotal={pageTotal}
          isClickProvider={isClickProvider}
          clickProviderItem={clickProviderItem}
          loadMoreWithText={loadMoreWithText}
          lang_key_title={lang_key_title}
          gameListGridClassName={gameListGridClassName}
          game_type_id={game_type_id}
        />
      ) : (
        <MultipleRowsSwiper
          list={list}
          gap={gap}
          rows={maxRows}
          column={pageTotal}
          isClickProvider={isClickProvider}
          clickProviderItem={clickProviderItem}
        />
      )}
    </div>
  );
};

const GameListWithTourCardData = ({ maxRows, list, gap, pageTotal, tourCardData, updateTour }) => {
  const [remainingList, setRemainingList] = useStateIfMounted([]);
  const [firstRowList, setFirstRowList] = useStateIfMounted([]);
  const [tourCardDataWidth, setTourCardDataWidth] = useStateIfMounted('533px');
  const [tourCardScaleY, setTourCardScaleY] = useStateIfMounted(1);
  const firstRowTourCardOccupyTotal = 3;
  const listRef = useRef(null);

  const { drawerMenuVisible } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (tourCardData) {
      if (Array.from(list).length > firstRowTourCardOccupyTotal) {
        const firstRowCount = pageTotal - firstRowTourCardOccupyTotal;
        setFirstRowList(Array.from(list).slice(0, firstRowCount));
        setRemainingList(Array.from(list).slice(firstRowCount));
      } else {
        setFirstRowList(list);
      }
    } else {
      setFirstRowList([]);
      setRemainingList(list);
    }
  }, [list, tourCardData]);

  const handleResize = () => {
    if (listRef && listRef.current) {
      const _tourCardDataWidth =
        (listRef.current.getBoundingClientRect().width / (maxRows == 1 ? 4 : pageTotal)) * 3 - 16;
      setTourCardDataWidth(`${_tourCardDataWidth}px`);
      setTourCardScaleY(_tourCardDataWidth / 533);
    }
  };

  useEffect(() => {
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      handleResize();
    });
    return () => {
      unsubscribe();
    };
  }, [remainingList]);

  useEffect(() => {
    const _timer = window.setTimeout(() => {
      handleResize();
      window.clearTimeout(_timer);
    }, 250);
    return () => window.clearTimeout(_timer);
  }, [drawerMenuVisible.visible]);

  return (
    <div className="relative">
      {list.length === 0 ? (
        <NoData className="py-[.9rem]" />
      ) : maxRows == 1 ? (
        <>
          <div className="flex items-center">
            <div className="w-[calc(100%_-_549px)]" ref={listRef}>
              <div>
                <Swiper spaceBetween={gap} slidesPerView={4} slidesPerGroup={4}>
                  {list.map((item, index) => (
                    <SwiperSlide key={item.id}>
                      <GameItem key={item.id} model={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <HomeTourCard
              style={{ width: tourCardDataWidth, transform: `scaleY(${tourCardScaleY})` }}
              tourCardData={tourCardData}
              updateTour={updateTour}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center w-full h-full" style={{ gap: gap }}>
          {firstRowList && firstRowList.length > 0 ? (
            <div className="flex flex-row items-center w-full" style={{ gap: gap }} ref={listRef}>
              <div className="flex-1">
                <div
                  className="grid h-full"
                  style={{ gap: gap, gridTemplateColumns: `repeat(${pageTotal - firstRowTourCardOccupyTotal}, 1fr)` }}
                >
                  {firstRowList.map((item, index) => (
                    <GameItem model={item} key={item.id} />
                  ))}
                </div>
              </div>
              <HomeTourCard
                style={{ width: tourCardDataWidth, transform: `scaleY(${tourCardScaleY})` }}
                boxClassName="w-full ml-0"
                tourCardData={tourCardData}
                updateTour={updateTour}
              />
            </div>
          ) : null}
          {remainingList && remainingList.length > 0 ? (
            <div className="flex-1 w-full h-full">
              <MultipleRowsSwiper
                list={remainingList}
                flex={'column'}
                gap={gap}
                rows={tourCardData && maxRows > 1 ? maxRows - 1 : maxRows}
                column={pageTotal}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const CategoryGame = ({
  config,
  list,
  className = '',
  viewAll = false,
  autoPage = false,
  autoPlay = false,
  setTagsIndex,
  handleViewAllCallback = () => {},
  staticIcon = false,
  clickViewAllUseId = '', //点击viewall切换选中状态使用的字段
  isClickProvider = false,
  clickProviderItem = () => {},
  handleProviderClickHeader = () => {},
  homeModuleHeaderClassName = '',
  homeModuleHeaderIconClassName = '',
  homeModuleHeaderTextClassName = '',
  boxclassName = '',
  loadMoreWithText = false,
  viewAllWithText = false,
  clickViewAllJumpPage = false,
  gameListGridClassName = '',
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uid } = useSelector(
    (state) => ({
      uid: get(state, 'user.userObj.uid', ''),
    }),
    shallowEqual,
  );
  const splideRef = useRef(null);
  const maxColumns = [3, 5, 7]; //最多列数字
  const [gap, setGap] = useStateIfMounted(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useStateIfMounted(0);
  const [pageTotal, setPageTotal] = useStateIfMounted(maxColumns[0]);
  const { getServerLangText } = useLangServerConfig();
  const [isLoading, setIsLoading] = useState(config.category_id == '003' ? true : false);
  const [dataList, setDataList] = useState(config.category_id == '003' ? [] : list);
  const handleNext = () => {
    if (currentPage < maxPage - 1) {
      setCurrentPage((page) => {
        return page + 1;
      });
    } else {
      setCurrentPage(0);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((page) => {
        return page - 1;
      });
    } else {
      setCurrentPage(maxPage - 1);
    }
  };

  useEffect(() => {
    if (list && list.length > 0) {
      setMaxPage(Math.ceil(list.length / pageTotal));
    } else {
      setCurrentPage(0);
      setMaxPage(0);
    }
  }, [list, pageTotal]);

  useEffect(() => {
    if (!autoPage) return;

    let id;
    if (maxPage > 1) {
      id = setTimeout(handleNext, 6000);
    }

    return () => {
      clearTimeout(id);
    };
  }, [maxPage, autoPage, currentPage]);
  useEffect(() => {
    if (!autoPlay) return;
    let intv;
    if (maxPage > 1) {
      intv = setInterval(handleNext, 6000);
    }

    return () => {
      clearInterval(intv);
    };
  }, [maxPage, currentPage]);

  useEffect(() => {
    const goIndex = currentPage * 1;
    if (splideRef.current) {
      splideRef.current.go(goIndex);
    }
  }, [currentPage]);

  const handleViewAll = () => {
    if (clickViewAllJumpPage) {
      handleProviderClickHeader();
    } else {
      if (clickViewAllUseId) {
        setTagsIndex(config[clickViewAllUseId]);
      } else {
        setTagsIndex(config.category_id);
      }
      handleViewAllCallback();
    }
  };

  useEffect(() => {
    if (config.category_id == '003') {
      GetRecentListReq(uid).then((res) => {
        setIsLoading(false);
        if (res.code === 0) {
          const result = res.result;
          setDataList(result.list);
        }
      });
    }
  }, [config]);

  return (
    <>
      <div className={cn('flex flex-col')}>
        <HomeModuleHeader
          title={getServerLangText(config.lang_key)}
          img={staticIcon ? cmsImgUrl(config.icon_selected) : cmsImgUrl(config.icon)}
          isViewAll={viewAll}
          handleViewAll={handleViewAll}
          homeModuleHeaderClassName={homeModuleHeaderClassName}
          homeModuleHeaderIconClassName={homeModuleHeaderIconClassName}
          homeModuleHeaderTextClassName={homeModuleHeaderTextClassName}
          boxclassName={boxclassName}
          viewAllWithText={viewAllWithText}
        />
        {isLoading ? (
          <Loading2 />
        ) : (
          <GameList
            maxRows={config.max_rows}
            list={dataList}
            gap={gap}
            pageTotal={pageTotal}
            isClickProvider={isClickProvider}
            clickProviderItem={clickProviderItem}
            loadMoreWithText={loadMoreWithText}
            lang_key_title={getServerLangText(config.lang_key)}
            game_type_id={config.game_type_id}
            gameListGridClassName={gameListGridClassName}
          />
        )}
      </div>
    </>
  );
};

export default CategoryGame;
