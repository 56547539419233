import Icon, { IconName } from '../../../icon';
import useFooterData from '../../../../hooks/useFooterData';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import SocialList from '../template001/components/socialList';
import { cmsImgUrl, getInviteCode, getMobileOperatingSystem, isPCLayout } from '../../../../utils/helper';
import { useTranslation } from 'react-i18next';
import { openUrl } from '../../../../utils/openUrl';
import DownloadManager from '../../../../manager/downloadManager';
import LimitClickWrapper from '../../../limitClickWrapper';
import { isAndroidAPK, isMobileSafari, isPWA } from '../../../../utils/deviceUtils';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../redux/reducers/appSlice';
import { GetDownloadPage } from '../../../../api';
import config from '../../../../constants/config';
import { toast } from 'react-toastify';

const DTemplate003 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getVestBagVisible = () => {
    if (window.android || window.Android) {
      //是马甲包
      if (isAndroidAPK()) {
        return false;
      } else {
        return false;
      }
    } else if (DownloadManager.isIOSNative) {
      // ios原生包直接不显示
      return false;
    } else {
      //不是马甲包
      return DownloadManager.showAndroid || DownloadManager.showIOS || isPCLayout();
    }
  };

  const handleIosClick = () => {
    // ios_download_link 是否为空。
    // 如果为空，检测是否是Safari浏览器，是的情况引导添加屏幕，不是弹出提示到Safari浏览器。
    // 如果不为空，正常跳转ios_download_link。
    if (getMobileOperatingSystem() == 'iOS' && DownloadManager.showIOS) {
      DownloadManager.getDownloadLink().then((res) => {
        if (res.code == 0) {
          const { ios_download_link } = res.result;
          if (String(ios_download_link).startsWith('https')) {
            openUrl(ios_download_link);
          } else {
            if (isMobileSafari()) {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.IosSafariGuide,
                }),
              );
            } else {
              DownloadManager.gotoSafariTip();
            }
          }
        }
      });
    } else if (DownloadManager.isNotMobile) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.PwaguideQRcodeModel,
        }),
      );
    }
  };

  const handleAndroidClick = () => {
    // 检测download_page 是否为空。
    // 如果为空，检测是否支持pwa,支持情况下安装pwa,不支持下载apk。
    if (getMobileOperatingSystem() == 'Android' && DownloadManager.showAndroid) {
      const params = { invite_code: getInviteCode() };
      GetDownloadPage(params).then(async (res) => {
        if (res.code == 0) {
          const { download_page } = res.result;
          if (download_page) {
            openUrl(config.downloadPage + download_page);
          } else {
            // 检测是否支持pwa
            let showAddPwa = null;

            try {
              const webappList = await navigator.getInstalledRelatedApps();
              if (webappList && webappList.length > 0) {
                showAddPwa = true;
              } else {
                showAddPwa = window.deferredPrompt ? true : false;
              }
            } catch (error) {
              showAddPwa = window.deferredPrompt ? true : false;
              console.error('Error getting installed related apps:', error);
            }

            if (showAddPwa && navigator.serviceWorker != null) {
              DownloadManager.installCheck(false);
            } else {
              const path = config.downloadPage + '/download';
              openUrl(path);
            }
          }
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      });
    } else if (DownloadManager.isNotMobile) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.PwaguideQRcodeModel,
        }),
      );
    }
  };

  const { footerData } = useSelector(
    (state) => ({
      footerData: state.app.websiteInitData?.footer,
    }),
    shallowEqual,
  );

  const { followList, text } = useFooterData(footerData);

  return (
    <div className="mt-[.2rem] w-full pb-[1.26rem]">
      {getVestBagVisible() && !isPWA() ? (
        <div className="w-full h-[7.08rem] bg-[url(../assets/img/footerBg.png)] bg-no-repeat bg-[length:100%_100%] pl-[.32rem] pt-[1.8rem]">
          <div className="text-[var(--colour-82)] text-[.46rem] font-[700]">{t('footer.title')}</div>

          <div className="mt-[.48rem] text-[var(--colour-38)] text-[.24rem] font-[500]">{t('footer.description')}</div>

          <div className="mt-[.72rem] flex items-center gap-[.24rem]">
            <Button
              icon={IconName.Apple}
              text={t('footer.ios')}
              className="w-[.44rem] h-[.44rem]"
              onClick={handleIosClick}
            />
            <Button
              icon={IconName.Android}
              text={t('footer.android')}
              className="w-[.52rem] h-[.52rem]"
              onClick={handleAndroidClick}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div>
        {followList.length > 0 ? (
          <div className="mt-[.58rem]">
            <SocialList
              className="!mt-0 justify-center space-x-[.28rem]"
              list={followList.map((item, index) => (
                <>
                  <div key={index} className={`cursor-pointer`}>
                    {item.url === '' ? (
                      <img className="h-[.5rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                    ) : (
                      <a
                        onClick={() => {
                          openUrl(item.url, '_blank');
                        }}
                      >
                        <img className="h-[.5rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                      </a>
                    )}
                  </div>
                </>
              ))}
            />
          </div>
        ) : null}
      </div>

      <div className="mt-[.58rem] w-full h-[1px] bg-[var(--colour-6)] px-[.28rem]"></div>

      <Text text={text} />
    </div>
  );
};

const Text = ({ text = null }) => {
  return text != null ? (
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      className="font-[600] text-[.2rem] leading-[.36rem] text-secondary-content wrap-break-word px-[.28rem] pt-[.58rem]"
    ></div>
  ) : null;
};

const Button = ({ icon = '', text = '', className = '', onClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <LimitClickWrapper
      className="w-[3.28rem] h-[.78rem] bg-[var(--colour-2)] rounded-[.16rem] flex items-center justify-center gap-[.08rem] cursor-pointer active:scale-[.95] transition-all select-none"
      onClick={onClick}
    >
      <div className={`${className}`}>
        <Icon name={icon}></Icon>
      </div>
      <div className="flex flex-col justify-center leading-[.24rem]">
        <div className="text-[var(--colour-54)] text-[.16rem] font-[500]">{t('footer.app')}</div>
        <div className="text-[var(--colour-66)] text-[.2rem] font-[500]">{text}</div>
      </div>
    </LimitClickWrapper>
  );
};

export default DTemplate003;
