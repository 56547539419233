import { useState, useEffect, Fragment } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TLoginButton, TLoginButtonSize } from 'react-telegram-auth';
import { get } from 'lodash';

import TippyPopBox, { Placement } from '../tippyPopBox';
import Icon, { IconName } from '../icon';
import LimitClickWrapper from '../limitClickWrapper';

import { isLogin, cmsImgUrl, langIconName, getRegisterThirdpart, isLightTheme } from '../../utils/helper';
import useActivityStatus from '../../hooks/useActivityStatus';
import { toLocalePath, changeLang } from '../../utils/localePath';
import useHandleLogic from '../../hooks/useHandleLogic';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../redux/reducers/appSlice';
import { langsConfig } from '../../constants/langs';
import { isAndroidNative } from '../../utils/deviceUtils';
import FacebookManager from '../../manager/facebookManager';
import useLoginThirdParty from '../../hooks/useLoginThirdParty';
import GoogleManager from '../../manager/googleManager';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const MenuBtn = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const { websiteInitConfig, switch_rakeback, footerData, telegram_bot_name, activityStatus } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      switch_rakeback: state.app.websiteInitConfig?.system_config?.switch_rakeback,
      footerData: get(state, 'app.websiteInitData.footer'),
      telegram_bot_name: get(state, 'app.websiteInitConfig.website_info.telegram_bot_name') || '',
      activityStatus: state.app.activityStatus,
    }),
    shallowEqual,
  );

  const showLogin = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
  };
  const { handleClickVector, faqData, handleClickManMadeInPage } = useHandleLogic();

  const [visible, setVisible] = useState(false);

  const [socialList, setSocialList] = useState([]); //媒体链接数据
  const [snsLoginMethod, setSnsLoginMethod] = useState([]); //三方登录数据
  const [menuList] = useState([
    {
      type: 'events',
      icon: IconName.MenuEvents,
      icon2: IconName.MenuEvents2,
      text: 'more_events',
      click: () => {},
    },
    // {
    //   type: 'default',
    //   icon: IconName.MenuVip,
    //   icon2: IconName.MenuVip2,
    //   text: 'more_vip',
    //   click: () => {
    //     if (isLogin()) {
    //       navigate(toLocalePath('/vip'));
    //     } else {
    //       showLogin();
    //     }
    //   },
    // },
    {
      type: 'bettingbonus', // switch_rakeback === '1',
      icon: IconName.MenuRakeBack,
      icon2: IconName.MenuRakeBack2,
      text: 'more_rakeback',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath('/promotion/rakeback'));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuSecurity,
      icon2: IconName.MenuSecurity2,
      text: 'more_security',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath(`/security`));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuData,
      icon2: IconName.MenuData2,
      text: 'more_data',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath(`/myaccountdata`));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuAccount,
      icon2: IconName.MenuAccount2,
      text: 'more_account',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath('/report/0'));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuBets,
      icon2: IconName.MenuBets2,
      text: 'more_bets',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath('/report/1'));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuReport,
      icon2: IconName.MenuReport2,
      text: 'more_report',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath('/report/2'));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuPending,
      icon2: IconName.MenuPending2,
      text: 'more_pending',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath('/promotion/pending'));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuWithdraw,
      icon2: IconName.MenuWithdraw2,
      text: 'more_withdraw',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath(`/pay/withdrawtabpage/2`));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'default',
      icon: IconName.MenuHistory,
      icon2: IconName.MenuHistory2,
      text: 'more_history',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath('/promotion/history'));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'giftmoney',
      icon: IconName.MenuGiftMoney,
      icon2: IconName.MenuGiftMoney2,
      text: 'more_giftmoney',
      click: () => {
        if (isLogin()) {
          navigate(toLocalePath('/promotion/giftmoney'));
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'blog', // websiteInitConfig.website_info.website_blog && websiteInitConfig.website_info.website_blog != '',
      icon: IconName.MenuBlog,
      text: 'more_blog',
      click: () => {
        window.open(websiteInitConfig.website_info.website_blog, '_blank');
      },
    },
    {
      type: 'default',
      icon: IconName.MenuMessage,
      text: 'more_message',
      click: () => {
        if (isLogin()) {
          handleClickVector();
        } else {
          showLogin();
        }
      },
    },
    {
      type: 'faq', //faqData,
      icon: IconName.MenuFaq,
      text: 'more_faq',
      click: () => {},
    },
    {
      type: 'default',
      icon: IconName.MenuService,
      text: 'more_service',
      click: () => {
        handleClickManMadeInPage();
      },
    },
    {
      type: 'lang',
      icon: IconName.MenuMessage,
      text: 'English',
      click: () => {},
    },
  ]);
  const [showList, setShowList] = useState([]); //最后展示的数据

  useEffect(() => {
    if (footerData?.footer_contact) {
      setSocialList(
        footerData.footer_contact.map((item) => {
          return {
            type: 'social',
            img: item.img,
            title: item.title,
            url: item.url,
          };
        }),
      );
    }
  }, [footerData]);

  useEffect(() => {
    const thirdPartData = getRegisterThirdpart();
    let thirdPartLoginMethodsSort = [];
    if (thirdPartData != null) {
      const sortedObj = Object.fromEntries(
        Object.keys(thirdPartData)
          .sort((a, b) => Number(thirdPartData[b].sort) - Number(thirdPartData[a].sort))
          .map((key) => [key, thirdPartData[key]]),
      );
      for (let obj in sortedObj) {
        if (sortedObj[obj].status == '1') {
          thirdPartLoginMethodsSort.push(obj);
        }
      }
      thirdPartLoginMethodsSort = thirdPartLoginMethodsSort.map((item) => {
        return { type: 'loginThirdParty', text: item };
      });
    }
    setSnsLoginMethod(thirdPartLoginMethodsSort);
  }, []);

  useEffect(() => {
    let arr = [];
    arr = menuList.filter((item) => {
      return (
        item.type == 'default' ||
        (item.type == 'bettingbonus' && switch_rakeback === '1') ||
        (item.type == 'blog' &&
          websiteInitConfig.website_info.website_blog &&
          websiteInitConfig.website_info.website_blog != '') ||
        (item.type == 'faq' && faqData) ||
        (item.type == 'giftmoney' && activityStatus && activityStatus.gift_money_menu_show_switch == '1') ||
        item.type == 'lang' ||
        item.type == 'events'
      );
    });
    arr = [...arr, ...socialList, ...snsLoginMethod];
    setShowList(arr);
  }, [socialList, snsLoginMethod, menuList, switch_rakeback, faqData, websiteInitConfig, activityStatus]);

  const selectLogin = (method) => {
    switch (method) {
      case 'facebook':
        return isLogin() ? null : <LoginFb />;
      case 'google':
        return isLogin() ? null : <LoginGG />;
      case 'telegram': {
        return isLogin() ? null : telegram_bot_name == '' ? null : <LoginTG />;
      }

      default:
        return null;
    }
  };

  return (
    <TippyPopBox
      placement={Placement.bottomEnd}
      popChildren={
        <div
          style={{ boxShadow: '0rem .08rem .08rem 0rem rgba(0, 0, 0, 0.10)' }}
          className="w-[5.76rem] p-[.16rem] bg-[var(--colour-4)] border border-[var(--colour-6)] rounded-normal grid grid-cols-5"
        >
          {showList.map((item, index) => {
            switch (item.type) {
              case 'lang':
                return <LanguageItem key={index} />;
              case 'social':
                return <SocialItem key={index} item={item} />;
              case 'loginThirdParty':
                return <Fragment key={index}>{selectLogin(item.text)}</Fragment>;
              case 'bettingbonus':
              case 'blog':
              case 'faq':
              case 'events':
              case 'giftmoney':
              case 'default':
                return (
                  <DefaultItem
                    key={index}
                    icon={!isLightTheme() && item.icon2 ? item.icon2 : item.icon}
                    text={item.text}
                    click={item.click}
                    type={item.type}
                  />
                );
            }
          })}
        </div>
      }
      visible={visible}
      onClickOutside={() => {
        setVisible(false);
      }}
      offset={[0, 3]}
    >
      <div>
        {/* {visible ? (
          <div
            className="fixed inset-0 z-[9998]"
            onClick={() => {
              setVisible(false);
            }}
          ></div>
        ) : null} */}
        <div
          className="pt-[.18rem] pb-[.1rem] flex flex-col items-center min-w-[1.08rem]"
          onClick={() => {
            setVisible(visible ? false : true);
          }}
        >
          <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
            <Icon name={isLightTheme() ? IconName.Menu : IconName.Menu2} />
          </div>
          <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)]">{t('home.more')}</div>
        </div>
      </div>
    </TippyPopBox>
  );
};
export default MenuBtn;

const DefaultItem = ({ icon = '', text = '', click = () => {}, type = '' }) => {
  const { t } = useTranslation();
  const { handleEntranceOnClick } = useActivityStatus();
  const { handleClickFaq } = useHandleLogic();
  return (
    <div
      className="w-[1.08rem] h-[1rem] flex flex-col items-center pt-[.18rem]"
      onClick={() => {
        if (type == 'faq') {
          handleClickFaq();
        } else if (type == 'events') {
          handleEntranceOnClick();
        } else {
          click();
        }
      }}
    >
      <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
        <Icon name={icon} />
      </div>
      <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)] text-center">
        {t(`home.${text}`)}
      </div>
    </div>
  );
};
const SocialItem = ({ item }) => {
  return (
    <div className="w-[1.08rem] h-[1rem] flex flex-col items-center pt-[.18rem]">
      <div className="w-[.52rem] h-[.52rem]">
        {item.url === '' ? (
          <img className="h-[.52rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
        ) : (
          <a href={`${item.url}`} target="_blank">
            <img className="h-[.52rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
          </a>
        )}
      </div>
      <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)] text-center">{item.title}</div>
    </div>
  );
};

const LanguageItem = () => {
  const { defaultLang } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const [languageFlag, setLanguageFlag] = useState(false); //语言列表是否展开
  const [langName, setLangName] = useState(''); //选中语言的name

  useEffect(() => {
    const langs = langsConfig.supportLangList;
    const found = langs.find((item) => item.key === defaultLang);
    setLangName(found ? found.name : langs.find((item) => item.key == langsConfig.initLangKey)?.name);
  }, [defaultLang]);

  const handleClickLangButton = () => {
    setLanguageFlag(!languageFlag);
  };

  const onClickLang = (item) => {
    setLanguageFlag(false);
    changeLang(item.key);
  };
  return (
    <div>
      <TippyPopBox
        visible={languageFlag}
        placement={Placement.left}
        offset={[0, 0]}
        onClickOutside={() => {
          setLanguageFlag(false);
        }}
        popChildren={
          <div
            className={`w-[2.12rem] rounded-normal overflow-hidden cursor-default bg-[var(--colour-28)] border border-[var(--colour-6)]`}
            style={{ boxShadow: '0rem .08rem .08rem 0rem rgba(0, 0, 0, 0.06)' }}
          >
            <div className="w-full p-[.16rem_.2rem] flex justify-between flex-wrap">
              {langsConfig.supportLangList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`w-[.72rem] h-[.8rem] flex flex-col items-center pt-[.08rem] ${
                      item.key === defaultLang
                        ? ''
                        : 'hover_text_color_transtion  text-[var(--colour-14)] hover:text-[var(--colour-48)]'
                    } `}
                    onClick={() => {
                      onClickLang(item);
                      setLanguageFlag(false);
                    }}
                  >
                    <div className="w-[.44rem] h-[.44rem] mb-[.04rem]">{<Icon name={langIconName(item.key)} />}</div>
                    <div
                      className={`text-[.16rem] font-[500] leading-[.2rem] font-interMedium ${item.key === defaultLang ? 'text-[var(--colour-1)]' : ''}`}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        }
      >
        <div>
          <LimitClickWrapper
            className="w-[1.08rem] h-[1rem] flex flex-col items-center pt-[.18rem]"
            onClick={handleClickLangButton}
            limitTime={0.3}
          >
            <div className="w-[.52rem] h-[.52rem]">
              <Icon name={langIconName(defaultLang)} />
            </div>
            <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)] text-center">
              {langName}
            </div>
          </LimitClickWrapper>
        </div>
      </TippyPopBox>
    </div>
  );
};

const LoginFb = () => {
  const { t } = useTranslation();
  const { LoginByFacebook } = useLoginThirdParty();
  const handleLoginByFacebook = () => {
    if (isAndroidNative()) {
      //走原生
      window.native.login_facebook();
      return;
    }

    // web版本facebook登录
    FacebookManager.login((token) => {
      if (token) {
        LoginByFacebook(token);
      } else {
        toast.error(t('1008068'), {
          containerId: 'global',
        });
      }
    });
  };
  return (
    <div className="w-[1.08rem] h-[1rem] flex flex-col items-center pt-[.18rem]" onClick={handleLoginByFacebook}>
      <div className="w-[.52rem] h-[.52rem]">
        <Icon name={IconName.MenuFb} />
      </div>
      <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)] text-center">
        {t('home.more_login_fb')}
      </div>
    </div>
  );
};

const LoginGG = () => {
  const { t } = useTranslation();
  const handleLoginByGoogle = () => {
    GoogleManager.login();
  };
  return (
    <div className="w-[1.08rem] h-[1rem] flex flex-col items-center pt-[.18rem]" onClick={handleLoginByGoogle}>
      <div className="w-[.52rem] h-[.52rem]">
        <Icon name={IconName.MenuGg} />
      </div>
      <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)] text-center">
        {t('home.more_login_gg')}
      </div>
    </div>
  );
};

const LoginTG = () => {
  const { t } = useTranslation();
  const { telegram_bot_name } = useSelector(
    (state) => ({
      telegram_bot_name: get(state, 'app.websiteInitConfig.website_info.telegram_bot_name') || '',
    }),
    shallowEqual,
  );
  return (
    <div className="w-[1.08rem] h-[1rem] flex flex-col items-center pt-[.18rem] relative">
      <div className="absolute left-0 top-0 w-[.92rem] h-[.92rem] cursor-pointer opacity-[0.01] overflow-hidden">
        <TLoginButton
          botName={telegram_bot_name}
          buttonSize={TLoginButtonSize.Large}
          lang="en"
          usePic={false}
          cornerRadius={20}
          onAuthCallback={useLoginThirdParty().LoginByTG}
          requestAccess={'write'}
        />
      </div>

      <div className="w-[.52rem] h-[.52rem]">
        <Icon name={IconName.MenuTg} />
      </div>
      <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)] text-center">
        {t('home.more_login_tg')}
      </div>
    </div>
  );
};
