import Cookies from 'js-cookie';
import { service2 as request } from '../utils/request';
import { service2 as request2 } from '../utils/requestAuthNew';
import { getHostName } from '../utils/helper';

export function GetCaptchaReq(params = null) {
  return request({
    url: '/captcha',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function LoginReq(params = null) {
  return request({
    url: '/login',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      domain: getHostName(),
    },
  });
}

export function LoginByMobileReq(params = null) {
  return request({
    url: '/loginbymobile',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}

export function LoginByGoogleReq(params = null) {
  return request({
    url: '/loginbygoogle',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}

export function LoginByFacebookReq(params = null) {
  return request({
    url: '/loginbyfacebook',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}

// 第三方登录 telegram
export function LoginByTelegramReq(params = null) {
  return request({
    url: '/loginbytelegram',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}

// 谷歌验证接口
export function LoginGoogleAuthReq(params = null) {
  return request({
    url: '/logingoogleauth',
    method: 'post',
    data: params,
  });
}

export function GetWalletSignStrReq(params = null) {
  return request({
    url: '/getWalletSignStr',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 钱包登录
export function LoginbywalletReq(params = null) {
  return request({
    url: '/loginbywallet',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}

// 登录注册是否需要图形验证码
export function LogininitReq(params = null) {
  return request({
    url: '/logininit',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//第三方geetest验证码接口
export function CaptchaGeetestReq(params = null) {
  return request({
    url: '/captcha/geetest',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//退出登录
export function Logout() {
  return request({
    url: '/logout',
    method: 'post',
  });
}

// 是否需要图形验证码
export function CaptchaInitReq(params = null) {
  return request({
    url: '/captcha/init',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 发送短信验证码(除绑定邮箱方式)
export function SmsSend(params = null) {
  return request2({
    url: '/sms/send',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 绑定邮箱方式下发送短信验证码
export function EmailSend(params = null) {
  return request2({
    url: '/email/send',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function LoginAnyReq(params = null) {
  return request2({
    url: '/v2/loginAny',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      domain: getHostName(),
    },
  });
}

export function RegisterReqBind(params = null) {
  return request2({
    url: '/v2/register/bind',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}

export function RegisterReqNew(params = null) {
  return request2({
    url: '/v2/registerbymobile',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}
export function RegisterReq(params = null) {
  return request2({
    url: '/v2/register',
    method: 'post',
    data: {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      btag: Cookies.get('btag'),
      domain: getHostName(),
    },
  });
}
