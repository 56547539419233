import React, { FC, useState, useRef, useEffect } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Close from '../../close';
// import DialogModal from '../index2';
import DialogModal from '../../dialogModal';
import NoData from '../../noData';
import { toast } from 'react-toastify';
import Icon, { IconName } from '../../icon';

import { WithdrawList } from '../../../api/index';
import LimitClickWrapper from '../../limitClickWrapper';
import Loading2 from '../../loading2';

import WidthdrawRecordDetail from './widthdrawRecordDetail';
import { getDateFormatStr2, getShowMoneyNumber, pointToComma } from '../../../utils/helper';
import { format } from 'date-fns';
import { get } from 'lodash';
import cn from 'classnames';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>
        <div className=" w-[.72rem] h-[.72rem] flex justify-center items-center  rounded-[.08rem] text-[var(--colour-2)]  bg-[var(--colour-45)] ">
          <Close onClick={handleClose} />
        </div>
      </div>
      <div className="w-full h-[1px] mt-[.28rem] bg-[var(--colour-6)]"></div>
    </>
  );
};

const widthdrawRecord = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const {} = useSelector((state) => ({}), shallowEqual);

  const [showLoading, setShowLoading] = useState(false);

  const [recordData, setRecordData] = useState([]);

  const [selectItem, setSelectItem] = useState({});

  const [isLastPage, setIsLastPage] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const click_page = (addPage) => {
    if (showLoading || (addPage > 0 && isLastPage)) {
      return;
    }
    let newPage = page + addPage;
    if (newPage < 1) {
      return;
    }

    WithdrawListReq(newPage);
  };

  const WithdrawListReq = (pageNum) => {
    // const start_date = format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd');
    // const end_date = format(new Date(), 'yyyy-MM-dd');
    const date = format(new Date(), 'yyyy-MM-dd');

    setShowLoading(true);
    WithdrawList({ page: pageNum, page_size: pageSize, date })
      .then((res) => {
        setShowLoading(false);
        // console.log('BetBonusRecordReq', res);
        if (res.code == 0) {
          if (res.result) {
            let data_page = res.result.search.page;
            let data_pageSize = res.result.search.page_size;
            let data_list = res.result.withdraw_list;
            if (!data_list || data_list.length < data_pageSize) {
              setIsLastPage(true);
            } else {
              setIsLastPage(false);
            }

            if (data_list && data_list.length > 0) {
              setRecordData(data_list);
              setPage(data_page);
            }
          }
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    WithdrawListReq(page);
  }, []);

  const statusReturn = (status, approval_result) => {
    if (status == 3) {
      if (approval_result == -1) {
        return t('deposit-withdraw.reject');
      } else {
        return t('deposit-withdraw.success');
      }
    }
  };

  return (
    <div className=" my-[.3rem] flex flex-col justify-between text-center relative  w-[7rem]  p-[0_.48rem_.4rem_.48rem] rounded-normal bg-[var(--colour-35)]">
      <FormHeader title={'Record'} handleClose={handleClose} />
      {/* 列表 */}
      <div className="flex text-[.2rem]  h-[.8rem]  text-center items-center leading-[1em] gap-[.06rem] text-[var(--colour-14)]">
        <div className="flex-1">ID</div>
        <div className="flex-1">{t('deposit-withdraw.amount')}</div>
        <div className="flex-1">{t('deposit-withdraw.time')}</div>
        <div className="flex-1">{t('deposit-withdraw.status')}</div>
        <div className="flex-1">{t('deposit-withdraw.details')}</div>
      </div>
      <div className="h-[1px] mx-[.32rem]  borderColor-bg"></div>

      {showLoading && <Loading2 className=" h-[6.64rem]" />}
      {(!recordData || recordData.length == 0) && !showLoading ? (
        <NoData className=" h-[7.34rem]" />
      ) : (
        <>
          {recordData && !showLoading ? (
            <>
              <div className=" h-[6.44rem] mt-[.2rem]">
                {recordData.map((item, index) => (
                  <div
                    key={item.id}
                    className={cn(
                      `flex text-[.2rem]  h-[.8rem]  text-center items-center px-[.16rem]   gap-[.08rem] rounded-normal`,
                      {
                        'bg-[var(--colour-34)]': index % 2 == 0,
                      },
                    )}
                  >
                    <div className="flex-1 text-[var(--colour-38)]">{item.id}</div>
                    <div className="flex-1 text-[var(--colour-38)]">
                      {pointToComma(getShowMoneyNumber(item.amount))}
                    </div>
                    <div className={`flex-1 text-[var(--colour-38)] text-[.2rem]`}>
                      {getDateFormatStr2(item.create_time)}
                    </div>
                    {[1, 2].includes(item.status) && (
                      <div className="flex-1 text-[var(--colour-26)]">{t('account.pending')}</div>
                    )}
                    {item.status == 3 && (
                      <div
                        className={`flex-1 ${item.approval_result == -1 ? 'text-[var(--colour-25)]' : 'text-[var(--colour-24)]'}`}
                      >
                        {statusReturn(item.status, item.approval_result)}
                      </div>
                    )}

                    {[0, 4].includes(item.status) && (
                      <div className="flex-1 text-[var(--colour-25)]">{t('deposit-withdraw.fail')}</div>
                    )}
                    <div className="flex-1">
                      <div
                        className={` h-[.6rem] flex items-center justify-center leading-[1em] cursor-pointer rounded-normal`}
                        onClick={() => {
                          setSelectItem(item);
                          setIsOpen(true);
                        }}
                      >
                        {t('deposit-withdraw.more')}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <div className="w-full flex justify-center items-center  mt-[.4rem]">
        <LimitClickWrapper
          onClick={() => click_page(-1)}
          className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer   bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
        >
          <div className="w-[.24rem] h-[.24rem]   rotate-180">
            <Icon name={IconName.BannerNext2}></Icon>
          </div>
        </LimitClickWrapper>
        <div className="px-[.24rem]  text-[.24rem]  font-[500] text-[var(--colour-1)] flex items-center">{page}</div>
        <LimitClickWrapper
          onClick={() => click_page(1)}
          className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer   bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
        >
          <div className="w-[.24rem] h-[.24rem]  ">
            <Icon name={IconName.BannerNext2}></Icon>
          </div>
        </LimitClickWrapper>
      </div>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isOpen}
      >
        <WidthdrawRecordDetail
          Data={selectItem}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      </DialogModal>
    </div>
  );
};
export default widthdrawRecord;
