/*
 * @Author: milo
 * @Date: 2023-08-25 11:47:14
 * @LastEditors: milo
 * @LastEditTime: 2024-10-15 13:50:38
 */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Input from './input';
import { useTranslation } from 'react-i18next';
import Loading2 from './loading2';
const SendButton = ({ children, onClick, className }) => {
  return (
    <div
      className={`h-[65%] cursor-pointer flex items-center justify-center w-[2rem] text-[.28rem] leading-[.28rem] font-[700] rounded-normal bg-[var(--colour-1)] ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const InputBySendCode = ({
  value,
  onChange,
  hasBeenSent = false,
  handleSendCode,
  countdownDone = () => {},
  placeholder,
  leftInner = '',
  leftOuter = '',
  requesting = false,
  innerClassName = '',
  propoClassName = '',
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let intervalId = null;
  const totalCountdown = 60;
  const [countdown, setCountdown] = useState(totalCountdown);
  useEffect(() => {
    if (hasBeenSent) {
      intervalId = setInterval(() => {
        setCountdown((prev) => {
          return prev - 1 <= 0 ? 0 : prev - 1;
        });
      }, 1000);
      return () => window.clearInterval(intervalId);
    } else {
      setCountdown(totalCountdown);
    }
    return () => {
      window.clearInterval(intervalId);
    };
  }, [hasBeenSent]);

  useEffect(() => {
    if (countdown <= 0) {
      window.clearInterval(intervalId);
      countdownDone();
    }
  }, [countdown]);
  return (
    <Input
      leftInner={leftInner}
      leftOuter={leftOuter}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      rightInner={
        requesting ? (
          <SendButton className="not-allowed text-[var(--colour-2)]" onClick={() => {}}>
            <Loading2 innerClass="only-border" />
          </SendButton>
        ) : hasBeenSent ? (
          <SendButton className="not-allowed text-[var(--colour-2)]" onClick={() => {}}>
            {countdown}
          </SendButton>
        ) : (
          <SendButton onClick={handleSendCode} className="text-[var(--colour-2)] text-[.28rem] ml-[.08rem]">
            {t('login-signup.send')}
          </SendButton>
        )
      }
      innerClassName={`!border-transparent !h-[.96rem] ${innerClassName}`}
      topClassName={propoClassName}
    />
  );
};

export default InputBySendCode;
