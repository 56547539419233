import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setGlobalLoadingFlag } from '../redux/reducers/appSlice';
import Loading from './loading2';
export default function GlobalLoadingMask() {
  const dispatch = useDispatch();
  const { globalLoadingFlag } = useSelector(
    (state) => ({
      globalLoadingFlag: state.app.globalLoadingFlag,
    }),
    shallowEqual,
  );

  useEffect(() => {
    let timer = null;
    if (globalLoadingFlag) {
      timer = setTimeout(() => {
        dispatch(setGlobalLoadingFlag(false));
      }, 10000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [globalLoadingFlag]);

  //全局弹框z-index 为 9999
  return (
    <>
      {globalLoadingFlag ? (
        <div className="fixed inset-0 z-[10000] flex justify-center items-center">
          <div className="rounded-normal bg-[#000000]/60 flex justify-center items-center w-[2.4rem] h-[2.4rem]">
            <Loading scale="1.5" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
