import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DialogModal from '../dialogModal';
import Icon, { IconName } from '../icon';
import useLangServerConfig from '../../hooks/useLangServerConfig';

const DepositFrequencyKey = 'popup_frequency_config_deposit';
const withdrawFrequencyKey = 'popup_frequency_config_withdraw';

const ConfirmTips = ({ type = '' }) => {
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const [visible, setVisible] = useState(false);

  const getTomorrowTS = () => {
    return moment(new Date()).add(1, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x');
  };

  useEffect(() => {
    let popupFrequency =
      type == 'deposit'
        ? websiteInfo?.website_pay_tips?.charge?.popup_frequency
        : websiteInfo?.website_pay_tips?.withdraw?.popup_frequency;
    let localstorageKey = type == 'deposit' ? DepositFrequencyKey : withdrawFrequencyKey;

    if (popupFrequency == 1) {
      //不弹出
      setVisible(false);
    } else if (popupFrequency == 2) {
      //高频弹窗
      setVisible(true);
    } else if (popupFrequency == 3) {
      //每日一次

      if (localStorage.getItem(localstorageKey)) {
        let config = JSON.parse(localStorage.getItem(localstorageKey));
        let configTime = config.ts || 0;

        if (new Date().getTime() >= configTime) {
          //超过上次记录的明天的时间戳，直接显示。
          setVisible(true);
          setLocalStorageConfig(localstorageKey, 'daily');
        } else {
          //没有超过上次记录的明天的时间戳
          setVisible(false);
        }
      } else {
        //没配置代表没有弹出过一次
        setVisible(true);
        setLocalStorageConfig(localstorageKey, 'daily');
      }
    } else if (popupFrequency == 4) {
      //只弹一次
      if (localStorage.getItem(localstorageKey)) {
        let config = JSON.parse(localStorage.getItem(localstorageKey));
        if (config.once) {
          //为true代表已经弹出过一次了
          setVisible(false);
        } else {
          //为false或者没配置过代表没有弹出过一次
          setVisible(true);
          setLocalStorageConfig(localstorageKey, 'once');
        }
      } else {
        //没配置代表没有弹出过一次
        setVisible(true);
        setLocalStorageConfig(localstorageKey, 'once');
      }
    }
  }, [type, websiteInfo]);

  const setLocalStorageConfig = (localstorageKey, key) => {
    if (localStorage.getItem(localstorageKey)) {
      let config = JSON.parse(localStorage.getItem(localstorageKey));
      if (key == 'daily') {
        config = { ...config, ts: getTomorrowTS() };
      } else if (key == 'once') {
        config = { ...config, [key]: true };
      }

      localStorage.setItem(localstorageKey, JSON.stringify(config));
    } else {
      let config = null;
      if (key == 'daily') {
        config = { ts: getTomorrowTS() };
      } else if (key == 'once') {
        config = { [key]: true };
      }

      localStorage.setItem(localstorageKey, JSON.stringify(config));
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={visible}
        handleClose={handleClose}
      >
        <ConfirmBox handleClose={handleClose} type={type} />
      </DialogModal>
    </>
  );
};

export default ConfirmTips;

const ConfirmBox = ({ handleClose = () => {}, type = '' }) => {
  const { t } = useTranslation();
  const { getServerLangText } = useLangServerConfig();

  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );
  return (
    <>
      <div className="w-[6.86rem] p-[.35rem_.32rem_.5rem_.32rem] rounded-[.16rem] bg-[var(--colour-35)] border border-[var(--colour-6)]">
        <div className="flex justify-between items-center mb-[.15rem] pr-[.21rem]">
          <div className="text-[.32rem] font-[400] leading-[.38rem] text-[var(--colour-48)]">
            {t('home.game_leave_confirm_title')}
          </div>
          <div
            className="w-[.3rem] h-[.3rem] text-[var(--colour-48)] cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            <Icon name={IconName.Close2} />
          </div>
        </div>
        <div className="w-full h-[1px] bg-[var(--colour-6)] mb-[.62rem]"></div>
        <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-41)] text-center max-h-[6rem] overflow-y-auto">
          {type == 'deposit'
            ? getServerLangText(websiteInfo?.website_pay_tips?.charge?.lang_key)
            : getServerLangText(websiteInfo?.website_pay_tips?.withdraw?.lang_key)}
        </div>
        <div
          className="active:scale-[.95] transition-all select-none h-[.7rem] bg-[var(--colour-1)] rounded-[.16rem] flex items-center justify-center mt-[.88rem] cursor-pointer"
          onClick={() => {
            handleClose();
          }}
        >
          <div className="text-[.24rem] font-[500] leading-[.24rem] text-[var(--colour-2)]">
            {t('deposit_success.confirm')}
          </div>
        </div>
      </div>
    </>
  );
};

export function resetDepositWithdrawFrequencyConfig() {
  if (localStorage.getItem(DepositFrequencyKey)) {
    let config = JSON.parse(localStorage.getItem(DepositFrequencyKey));
    config = { ...config, once: false, ts: 0 };
    localStorage.setItem(DepositFrequencyKey, JSON.stringify(config));
  }

  if (localStorage.getItem(withdrawFrequencyKey)) {
    let config = JSON.parse(localStorage.getItem(withdrawFrequencyKey));
    config = { ...config, once: false, ts: 0 };
    localStorage.setItem(withdrawFrequencyKey, JSON.stringify(config));
  }
}
