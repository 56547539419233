import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderBack from '../../../../../components/headerBack';
import { toLocalePath } from '../../../../../utils/localePath';
import LimitClickWrapper from '../../../../../components/limitClickWrapper';
import ElementWithLoading from '../../../../../components/elementWithLoading';
import ResultTips from '../../../../../components/resultTips';
import CodeBox from './components/codeBox';
import { PaymentWithdrawSetPassword } from '../../../../../api/newDepositWithdraw';
import ErrorTip from './components/errorTip';
import ToastTip from '../../../../../components/toastTip';
import { useGlobalNavigate } from '../../../../../contexts/navigateContext';

export default function Index({ setNeedSwitchPassword }) {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const [showTips, setShowTips] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [params, setParams] = useState({
    password: '',
    confirm_password: '',
  });
  const [showErrorTip1, setShowErrorTip1] = useState(false);
  const [showErrorTip2, setShowErrorTip2] = useState(false);
  const [errorText1, setErrorText1] = useState('');
  const [errorText2, setErrorText2] = useState('');
  const toastErrorId = useRef(null);
  const toastSuccessId = useRef(null);

  const handleCodeChange = (codeStr) => {
    setParams({ ...params, password: codeStr });
    changeError1(codeStr);
  };

  const handleConfirmCodeChange = (codeStr) => {
    setParams({ ...params, confirm_password: codeStr });
    changeError2(codeStr);
  };

  const handleClickConfirm = () => {
    if (
      params.password == '' ||
      params.password.length < 6 ||
      areAllDigitsSame(params.password) ||
      isContinuityNum(params.password)
    ) {
      return changeError1(params.password);
    }

    if (
      params.confirm_password == '' ||
      params.confirm_password.length < 6 ||
      areAllDigitsSame(params.confirm_password) ||
      isContinuityNum(params.confirm_password)
    ) {
      return changeError2(params.confirm_password);
    }

    if (params.password !== params.confirm_password) {
      // setShowErrorTip1(true);
      // setErrorText1(t('deposit-withdraw-wg.withdraw_password_error3'));

      setShowErrorTip2(true);
      setErrorText2(t('deposit-withdraw-wg.withdraw_password_error3'));
      return;
    }
    setRequesting(true);
    PaymentWithdrawSetPassword(params)
      .then((res) => {
        if (res.code == 0) {
          toast.dismiss(toastErrorId.current);
          toastSuccessId.current = toast.error(
            <ToastTip type="success" content={t('deposit-withdraw-wg.password_set_success')} />,
            {
              containerId: 'tip',
              icon: false,
            },
          );
          // navigate(toLocalePath(`/pay/withdrawtabpage/1`));
          setNeedSwitchPassword(false);
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const changeError1 = (val) => {
    if (val == '') {
      setShowErrorTip1(true);
      setErrorText1(t('deposit-withdraw-wg.withdraw_password_error1'));
    } else if (val.length < 6) {
      setShowErrorTip1(true);
      setErrorText1(t('deposit-withdraw-wg.withdraw_password_numbers'));
    } else if (areAllDigitsSame(val) || isContinuityNum(val)) {
      setShowErrorTip1(true);
      setErrorText1(t('deposit-withdraw-wg.withdraw_password_error2'));
    } else {
      setShowErrorTip1(false);
    }
    if (val.length == 6 && params.confirm_password == 6 && val == params.confirm_password) {
      if (!(areAllDigitsSame(val) || isContinuityNum(val))) {
        setShowErrorTip1(false);
        setShowErrorTip2(false);
      }
    }
  };
  const changeError2 = (val) => {
    if (val == '') {
      setShowErrorTip2(true);
      setErrorText2(t('deposit-withdraw-wg.withdraw_password_error1'));
    } else if (val.length < 6) {
      setShowErrorTip2(true);
      setErrorText2(t('deposit-withdraw-wg.withdraw_password_numbers'));
    } else if (areAllDigitsSame(val) || isContinuityNum(val)) {
      setShowErrorTip2(true);
      setErrorText2(t('deposit-withdraw-wg.withdraw_password_error2'));
    } else {
      setShowErrorTip2(false);
    }
    if (val.length == 6 && params.password.length == 6 && val == params.password) {
      if (!(areAllDigitsSame(val) || isContinuityNum(val))) {
        setShowErrorTip1(false);
        setShowErrorTip2(false);
      }
    }
  };

  useEffect(() => {
    toastErrorId.current = toast.error(
      <ToastTip type="info" content={t('deposit-withdraw-wg.withdraw_password_tips')} />,
      {
        containerId: 'tip',
        icon: false,
      },
    );
    return () => {
      toast.dismiss(toastErrorId.current);
    };
  }, []);

  return (
    <div className="bg-[var(--colour-68)] h-full flex flex-col">
      <HeaderBack
        title={t('deposit-withdraw-wg.withdraw_password_title')}
        titleClassName="!font-[400]"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="pt-[.24rem] pb-[1.34rem] text-[.24rem] font-[500] leading-[.3rem] flex-1 flex flex-col justify-between">
        <div className="px-[.24rem]">
          <div className="text-[var(--colour-24)] text-center mb-[.24rem]">
            {t('deposit-withdraw-wg.withdraw_password_text1')}
          </div>
          <div className="font-[700] text-[var(--colour-48)] mb-[.4rem]">
            {t('deposit-withdraw-wg.withdraw_password_text2')}
          </div>
          <div className="mb-[.8rem] relative">
            <CodeBox
              title={t('deposit-withdraw-wg.withdraw_password_text3')}
              handleCodeChange={handleCodeChange}
              className=""
              headerClassName="mb-[.16rem]"
            />
            {showErrorTip1 ? <ErrorTip text={errorText1} /> : null}
          </div>

          <div className="mb-[.8rem] relative">
            <CodeBox
              title={t('deposit-withdraw-wg.withdraw_password_text4')}
              handleCodeChange={handleConfirmCodeChange}
              className=""
              headerClassName="mb-[.16rem]"
            />
            {showErrorTip2 ? <ErrorTip text={errorText2} /> : null}
          </div>
          <div className="text-[var(--colour-26)]">{t('deposit-withdraw-wg.withdraw_password_text5')}</div>
        </div>
        <div
          className="fixed bottom-[0rem] left-[0rem] w-full h-[1.28rem] bg-[var(--colour-4)] px-[.24rem] flex items-center"
          style={{ boxShadow: '0rem -0.04rem .08rem 0rem rgba(0, 0, 0, 0.10)' }}
        >
          <LimitClickWrapper
            className="active:scale-[.95] transition-all select-none w-full h-[.7rem] rounded-normal bg-[var(--colour-1)] cursor-pointer flex items-center justify-center"
            onClick={() => {
              if (!requesting) {
                handleClickConfirm();
              }
            }}
          >
            <ElementWithLoading
              isLoading={requesting}
              normalEl={<div className="font-[500] text-[.24rem] text-[var(--colour-2)]">{t('home.confirm')}</div>}
            />
          </LimitClickWrapper>
        </div>
      </div>
    </div>
  );
}

//判断一串数字是否是相同的，相同返回true
function areAllDigitsSame(str) {
  for (let i = 1; i < str.length; i++) {
    if (str[i] != str[0]) {
      return false;
    }
  }
  return true;
}

//判断一串数字是否是连续的,连续返回true
const isContinuityNum = (num) => {
  let array = [];
  if (num instanceof Array) {
    array = [...num];
  } else {
    array = Array.from(num.toString()); //转换为数组
  }

  let res = array[1] - array[0];
  if (Math.abs(res) != 1) {
    return false;
  }
  let isContinuation = true;
  for (let i = 1; i < array.length; i++) {
    if (array[i] - array[i - 1] != res) {
      isContinuation = false;
    }
  }

  return isContinuation;
};
