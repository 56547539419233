import { useTranslation } from 'react-i18next';
import DialogModal from './dialogModal';
import Close from './close';
function Tips2({
  setShowVerify,
  showVerify,
  headTxt,
  btn1 = null,
  btn2 = null,
  cb1 = null,
  cb2 = null,
  transerAmount,
}) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
      >
        <ReferralInvit7
          handleClose={() => setShowVerify(false)}
          headTxt={headTxt}
          transerAmount={transerAmount}
          btn1Name={btn1}
          btn2Name={btn2}
          cb1={cb1}
          cb2={cb2}
        ></ReferralInvit7>
      </DialogModal>
    </>
  );
}
function ReferralInvit7({
  handleClose,
  headTxt,
  btn1Name = null,
  btn2Name = null,
  cb1 = null,
  cb2 = null,
  transerAmount,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-[var(--colour-35)] text-center relative overflow-hidden w-[6.86rem] h-[4rem] rounded-normal">
        <div className="flex flew-row mt-[.4rem]  ml-[.4rem] w-[6.2rem] h-[.72rem] justify-center">
          <div className="flex flew-row w-[6.2rem]  justify-center ">
            <div className="flex w-[6.22rem] text-[.32rem] font-[700] text-[var(--colour-48)]">{headTxt}</div>
            <div
              className="flex w-[.72rem] h-[.72rem] -mt-10 rounded-normal bg-[var(--colour-45)] text-[var(--colour-46)]"
              onClick={() => handleClose()}
            >
              <Close />
            </div>
          </div>
        </div>
        <div className=" h-[.02rem] bg-[var(--colour-6)] w-[6.2rem] m-auto"></div>
        <div className="flex w-[6.1rem] mt-[.4rem] h-[1.2rem] justify-center items-center ml-[.4rem]">
          <div className="flex justify-center items-center text-center text-[.28rem] relative w-[7.5rem] h-[.8rem] bg-[var(--colour-34)] border border-[var(--colour-6)] cursor-pointer select-none">
            <div className="h-[1rem] flex flex-row mt-[.2rem]">
              <div className="flex w-[.8rem] h-[.8rem] bg-[url(../assets/messageIco/coins.png)]  "></div>
              <div className=" text-left mt-[.2rem] text-[var(--colour-38)]">
                {'x '}
                {transerAmount}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-[6.86rem] text-[.24rem] font-[400] text-[var(--colour-49)] text-center justify-center mt-[.4rem]">
          {t('vip.ClickContinue')}
        </div>
      </div>
    </>
  );
}
export default Tips2;
