import DownloadPrompt from '../header/downloadPrompt';
import TopTips from '../topTips';

export default function TopFixed({ showDownloadPrompt = false }) {
  return (
    <div className="top-fixed-box">
      <TopTips />
      {showDownloadPrompt ? <DownloadPrompt /> : null}
    </div>
  );
}
