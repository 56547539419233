import { useNavigate, useLocation } from 'react-router-dom';
import { toLocalePath, toNoLocalePath } from '../utils/localePath';
import { useGlobalNavigate } from '../contexts/navigateContext';

const useGoBack = () => {
  const navigate = useGlobalNavigate();
  const location = useLocation();
  const fromPathName = location.state?.from;
  //是否直接返回来源页面
  const goBack = (isGoToFromPath = false) => {
    if (fromPathName) {
      if (isGoToFromPath) {
        navigate(toLocalePath(toNoLocalePath(fromPathName)), { state: { from: null } }, false);
      } else {
        navigate(-1);
      }
    } else {
      navigate(toLocalePath('/'));
    }
  };
  return goBack;
};

export default useGoBack;
