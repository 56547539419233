import { useTranslation } from 'react-i18next';
import Tips from '../tips';
import Icon, { IconName } from '../../../icon';
import { isLogin, isControllableSite } from '../../../../utils/helper';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import RedDot from '../../../redDot';
import { useNavigate } from 'react-router-dom';
import GiftMoneyEntrance from './giftMoneyEntrance';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../redux/reducers/appSlice';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const GiftMoney = ({ layout = null, onlyIcon = false, isShow = true, handleLinkClick = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { giftMoneyMsg, activityStatus, userObj } = useSelector(
    (state) => ({
      giftMoneyMsg: state.ws.giftMoneyMsg,
      activityStatus: state.app.activityStatus,
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  const handleClick = () => {
    // dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney }));
    if (isLogin()) {
      navigate(toLocalePath('/promotion/giftmoney'));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };

  return onlyIcon ? (
    activityStatus && activityStatus.gift_money_menu_show_switch == '1' ? (
      <>
        <Tips anchorId="overGiftMoney" className="text-[var(--colour-48)]">
          {t('giftMoney.name')}
        </Tips>
        <div
          onClick={handleClick}
          id="overGiftMoney"
          className={`w-[.8rem] h-[.8rem] mb-[.16rem] rounded-normal cursor-pointer flex justify-center items-center bg-[var(--colour-35)] relative hover:bg-[var(--colour-35)]`}
        >
          {isControllableSite() ? (
            <span className="w-[.52rem] h-[.52rem] bg-[url(../assets/layout3-img/giftMoney.png)] bg-no-repeat bg-[length:100%_100%]"></span>
          ) : (
            <div className="w-[.44rem] h-[.44rem]">
              <Icon name={IconName.GiftMoney} />
            </div>
          )}
          {userObj != null && giftMoneyMsg != null && giftMoneyMsg.status == 2 ? (
            <RedDot className="absolute top-[-0.08rem] right-[-0.08rem]" />
          ) : (
            <></>
          )}
        </div>
      </>
    ) : null
  ) : (
    <GiftMoneyEntrance layout={layout} isShow={isShow} handleClick={handleClick} handleLinkClick={handleLinkClick} />
  );
};

export default GiftMoney;
