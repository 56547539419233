import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Back from '../../../../components/back';
import TournamentItem from '../../../../components/tour/tournamentItem';
import { shallowEqual, useSelector } from 'react-redux';
import Icon, { IconName } from '../../../../components/icon';
import GameList from '../../../../components/tour/gameList';
import TextBox from '../../../../components/tour/textBox';
import { GetTourInfoReq } from '../../../../api';
import { useStateIfMounted } from 'use-state-if-mounted';
import { get } from 'lodash';
import { getShowMoney, getTourPrize } from '../../../../utils/helper';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const RankList = ({ list = [], prizeConfigList = [] }) => {
  const { t } = useTranslation();

  const { websiteInitConfig, uid } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      uid: get(state, 'user.userObj.uid'),
    }),
    shallowEqual,
  );

  const isMe = (data) => data && uid && uid == data.uid;

  const showName = (data) => {
    if (data) {
      if (data.nickname.length <= 10) {
        return data.nickname;
      } else {
        return `${data.nickname.slice(0, 10).slice(0, -3)}***`;
      }
    } else {
      return '-';
    }
  };

  return (
    <div className="font-[400] text-[.24rem] text-[var(--colour-38)]">
      <div className="flex items-center border-b border-[var(--colour-6)] px-[.12rem] pb-[.12rem]">
        <div className="w-[40%] pl-[.44rem]">{t('tour.player')}</div>
        <div className="w-[40%] text-center">{t('tour.points')}</div>
        <div className="w-[20%] text-right">{t('tour.prize')}</div>
      </div>
      {list.map((item, index) => {
        return (
          <div
            key={index}
            className={`flex items-center h-[.48rem] px-[.12rem] ${index % 2 == 1 ? 'bg-[var(--colour-34)]' : ''}`}
          >
            <div className={`w-[40%] flex items-center ${isMe(item) ? 'text-[var(--colour-1)]' : ''}`}>
              <div className="w-[.44rem]">
                {index < 3 ? <img src={`/static/img/tour/cup${index + 1}.png`} className="w-[.44rem]" /> : <></>}
              </div>
              <div>{index + 1}.</div>
              <div>{showName(item)}</div>
              {isMe(item) && (
                <span className="ml-[.1rem] w-[.24rem] h-[.24rem]">
                  <Icon name={IconName.Useraccount} />
                </span>
              )}
            </div>
            <div className="w-[40%] text-center text-[var(--colour-26)]">
              {item ? getShowMoney(item.total_bet_amount) : '-'}
            </div>
            <div className="w-[20%] text-right text-[var(--colour-24)]">
              {getTourPrize(index + 1, prizeConfigList) == '--' ? (
                '--'
              ) : (
                <>
                  {websiteInitConfig?.website_info?.currency_symbol}
                  {getTourPrize(index + 1, prizeConfigList)}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const NTemplate001 = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { identity } = useSelector(
    (state) => ({
      identity: state.user.identity,
    }),
    shallowEqual,
  );

  const { tournamentid } = useParams();
  const [loading, setLoading] = useStateIfMounted(true);
  const [result, setResult] = useStateIfMounted(null);
  const [rankList, setRankList] = useStateIfMounted([]);
  const [prizeConfigList, setPrizeConfigList] = useStateIfMounted([]);

  const getDetail = () => {
    setLoading(true);
    GetTourInfoReq({ activity_id: tournamentid }).then((res) => {
      setLoading(false);
      if (res.code == 0) {
        setResult(res.result);

        const prizeConfigList = get(res.result, 'info.activity_rewards', []);
        setPrizeConfigList(prizeConfigList);
        const list = get(res.result, 'ranking_list') || [];

        const newList = Array(10)
          .fill(null)
          .map((item, index) => {
            return list[index] || item;
          })
          .filter((item, index) => {
            return getTourPrize(index + 1, prizeConfigList) !== '--';
          });

        setRankList(newList);
      }
    });
  };

  useEffect(() => {
    getDetail(tournamentid);
  }, [identity]);

  return (
    <div className="container px-[.32rem]">
      <div className="mx-auto mt-[.38rem]">
        <Back onClick={() => navigate('/tournament')} text={`←${t('promo.back')}`} />

        {loading ? (
          <></>
        ) : (
          <>
            <div className="flex mt-[.28rem] flex-col space-y-[.24rem]">
              <div className="">
                <TournamentItem
                  data={get(result, 'info', null)}
                  showMyData={true}
                  needClick={false}
                  showRedDot={false}
                  myData={get(result, 'user_ranking_info', null)}
                  update={getDetail}
                />
              </div>
              <div className="">
                <RankList list={rankList} prizeConfigList={prizeConfigList} />
              </div>
            </div>

            <div className="mt-[.2rem] text-[.24rem] font-[400] text-[var(--colour-25)]">{t('tour.boardTip')}</div>

            <div className="mt-[.32rem]">
              <TextBox title={t('tour.toc')} content={get(result, 'info.rule_explain.activity_rule_explain', '')} />
            </div>
          </>
        )}

        <div className="mt-[.3rem] text-[var(--colour-38)]">
          <GameList id={get(result, 'info.id', '')} />
        </div>
      </div>
    </div>
  );
};

export default NTemplate001;
