import cn from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { cmsImgUrl, getCurrentLang, getQueryString, isLogin } from '../../../../../utils/helper.js';
import { toLocalePath } from '../../../../../utils/localePath.js';
import NoData from '../../../../../components/noData';
import Loading from '../../../../../components/loading3';
import { GetPromotionEventsList } from '../../../../../api/index.js';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig.js';
import useLink from '../../../../../hooks/useLink.js';
import Icon, { IconName } from '../../../../../components/icon.jsx';
import moment from 'moment';
import Badge from '../../../../mine/normal/template001/components/badge.jsx';
import { useGlobalNavigate } from '../../../../../contexts/navigateContext.jsx';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../../redux/reducers/appSlice.js';

const DTemplate003 = () => {
  const ref = useRef(null);
  const { handleCmsLink } = useLink();
  const { getServerLangText } = useLangServerConfig();
  const { onScroll } = useOutletContext();
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [TabContent, setTabContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVal, setCurrentVal] = useState(0);
  const [currentKey, setCurrentKey] = useState(null);
  const [langKeyIcon, setLangKeyIcon] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(getQueryString('scrollTop') || 0);

  const { websiteInfo, promotionClaimedCount } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );

  const handleClick = (index, data) => {
    setScrollPosition(0);
    setCurrentVal(index);
    setCurrentKey(data);
    navigate(toLocalePath(`/promotion/events?current=${index}`));
  };

  const handleGoDetail = (item) => {
    const scrollTop = ref.current?.scrollTop || 0;

    switch (item.jump_mode) {
      case 1:
      case 3: {
        navigate(toLocalePath(`/promotion/${item.id}?fromIndex=${currentVal}&scrollTop=${scrollTop}`));
        break;
      }
      case 2: {
        handleCmsLink(item.open_mode, item.new_window, item.link);
        break;
      }
    }
  };

  const getEventsList = async () => {
    setIsLoading(true);
    try {
      const res = await GetPromotionEventsList({
        lang: getCurrentLang(),
      });

      if (res.code == 0) {
        const current = getQueryString('current');
        const index = current != null ? current : currentVal;

        setList(res.data.data);
        setLangKeyIcon(res.data.langkey_icon);
        setCurrentVal(index);
        setCurrentKey(res.data.langkey_icon[index]?.language_key);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: scrollPosition,
      });
    }
  }, [ref, scrollPosition, TabContent]);

  useEffect(() => {
    getEventsList();
  }, []);

  useEffect(() => {
    if (list) {
      const data = [list][0];
      const allKey = 'GameType_Fixed_9999'; //写死的all分类
      if (data[allKey]) {
        setTabContent(data[allKey]);
      }
    }
  }, [currentKey]);

  const getTotalEventCount = (item) => {
    let count = 0;

    if (list && promotionClaimedCount?.data?.events) {
      const data = [list][0];
      const eventsdata = promotionClaimedCount.data.events;
      for (const key in data) {
        if (item && key == item) {
          const arr = data[key];
          for (let i = 0; i < arr.length; i++) {
            if (eventsdata[arr[i].id] != undefined) {
              count += eventsdata[arr[i].id];
            }
          }
        }
      }
    }

    return count;
  };

  const getEventClaimCount = (id) => {
    let count = 0;

    if (promotionClaimedCount?.data?.events) {
      const eventsdata = promotionClaimedCount.data.events;
      count = eventsdata[id] ? eventsdata[id] : 0;
    }

    return count;
  };

  const LocalIcon = ({ iconKey }) => {
    console.log('iconKey', iconKey);

    switch (iconKey) {
      case 'GameType_Fixed_1': {
        return <Icon name={IconName.Gametype_1} />;
      }
      case 'GameType_Fixed_2': {
        return <Icon name={IconName.Gametype_2} />;
      }
      case 'GameType_Fixed_3': {
        return <Icon name={IconName.Gametype_3} />;
      }
      case 'GameType_Fixed_4': {
        return <Icon name={IconName.Gametype_4} />;
      }
      case 'GameType_Fixed_5': {
        return <Icon name={IconName.Gametype_5} />;
      }
      case 'GameType_Fixed_6': {
        return <Icon name={IconName.Gametype_6} />;
      }
      case 'GameType_Fixed_9999': {
        return <Icon name={IconName.Gametype_9999} />;
      }
      default:
        return <></>;
    }
  };

  const isLocalIcon = (key) => {
    const localKeys = [
      'GameType_Fixed_1',
      'GameType_Fixed_2',
      'GameType_Fixed_3',
      'GameType_Fixed_4',
      'GameType_Fixed_5',
      'GameType_Fixed_6',
      'GameType_Fixed_9999',
    ];
    return localKeys.includes(key);
  };

  const EventIcon = ({ iconKey, isActive, remoteImgSrcActive, remoteImgSrcInactive }) => {
    const isLocal = isLocalIcon(iconKey);

    return isLocal ? (
      <span
        className={cn('w-[.32rem] h-[.32rem] not-drag block shrink-0', {
          'text-[var(--colour-2)]': isActive,
          'text-[var(--colour-33)]': !isActive,
        })}
      >
        <LocalIcon iconKey={iconKey} />
      </span>
    ) : (
      <img className="w-[.32rem] not-drag shrink-0" src={isActive ? remoteImgSrcActive : remoteImgSrcInactive} alt="" />
    );
  };

  const handleClickHistory = () => {
    if (!isLogin()) {
      return dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
    onScroll('/promotion/history');
  };

  return (
    <>
      <div className="flex w-full h-full bg-[var(--colour-68)]">
        {isLoading ? (
          <Loading className="w-full h-full"></Loading>
        ) : langKeyIcon == null || langKeyIcon?.length == 0 ? (
          <NoData className="w-full h-full"></NoData>
        ) : (
          <>
            <div
              className="w-[7rem] pt-[.2rem] flex flex-col gap-[.2rem] h-full overflow-y-auto overflow-x-hidden  hidden-scroll-y m-auto"
              ref={ref}
            >
              {TabContent &&
                TabContent.length > 0 &&
                TabContent.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        handleGoDetail(item);
                      }}
                      key={index}
                      className="cursor-pointer w-[6.9rem] h-[2.3rem] rounded-[.2rem] relative"
                    >
                      <div
                        className={`w-[6.9rem] h-[2.3rem] rounded-[.2rem] flex items-center justify-center bg-no-repeat bg-[length:100%_100%] ${getEventClaimCount(item.id) > 0 ? 'promotion-events-shiny' : ''}`}
                        style={{ backgroundImage: `url(${cmsImgUrl(item.TitleImg)})` }}
                      ></div>
                      {getEventClaimCount(item.id) > 0 ? (
                        <div className="w-[.28rem] h-[.28rem] bg-[var(--colour-25)] rounded-[19.98rem] absolute top-[-0.08rem] right-[-0.08rem]"></div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DTemplate003;
