import { useTranslation } from 'react-i18next';
import React from 'react';
import LimitClickWrapper from './limitClickWrapper';
import Loading from './loading2';

const LoadMore = ({ onClick, className, showLoading = true }) => {
  const { t } = useTranslation();
  return showLoading ? (
    <div className={`w-[80px] mx-auto h-[.6rem] ${className}`}>
      <Loading isAbsolute={false} height="100%" />
    </div>
  ) : (
    <LimitClickWrapper
      className={`flex items-center justify-center mx-auto cursor-pointer w-[2.06rem] h-[.6rem] rounded-normal bg-[var(--colour-35)] text-[var(--colour-48)] border border-[var(--colour-6)] ${className}`}
      onClick={onClick}
    >
      <div className="font-[500] text-[.24rem]">{t('home.Loadmore')}</div>
    </LimitClickWrapper>
  );
};

export default LoadMore;
