import React, { useState, useEffect, useRef } from 'react';
import { delay, remToPx } from '../../../../../utils/helper';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';
import Icon, { IconName } from '../../../../../components/icon';
import GameList from './gamelist';
import GameHeader from './gameHeader';
import Loading2 from '../../../../../components/loading2';
import scrollIntoView from 'scroll-into-view';

const HomeGameList = ({ list, className = '', id, icon = '', title = '', isLoading = false, row = 4 }) => {
  const gap = 11;
  const h = remToPx(1.5);
  const ref = useRef(null);
  const count = row == 4 ? 16 : 12;
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useStateIfMounted(1);
  const [isShowLoadMore, setIsShowLoadMore] = useState(false);
  const [paginatedList, setPaginatedList] = useState([]);
  const [swiperAnimation, setSwiperAnimation] = useState(false); // 是否执行轮播图过渡动画

  const handleClick = async () => {
    if (isShowLoadMore) {
      setCurrentPage(0);
      setIsShowLoadMore(false);
      setSwiperAnimation(false);

      const elms = document.querySelector('.tags_sticky');

      await delay(50);
      if (ref.current) {
        let offset = 0;
        if (elms) {
          offset = h;
        }
        scrollIntoView(ref.current, {
          time: 500,
          align: {
            top: 0,
            topOffset: offset,
          },
        });
      }
    } else {
      setCurrentPage(0);
      setIsShowLoadMore(true);
      setSwiperAnimation(false);
    }
  };

  useEffect(() => {
    if (list && list.length > 0) {
      setPaginatedList(list.slice(count));
      setMaxPage(Math.ceil(list.length / count));
    } else {
      setCurrentPage(0);
      setMaxPage(1);
    }
  }, [list]);

  return (
    <>
      <div className={cn(`flex flex-col bg-[var(--colour-4)] rounded-[.08rem] pb-[.26rem] ${className}`)} ref={ref}>
        {isLoading ? (
          <Loading2 className="py-[4rem]" />
        ) : (
          <>
            <GameHeader
              id={id}
              icon={icon}
              title={title}
              total={list.length}
              setCurrentPage={setCurrentPage}
              maxPage={maxPage}
              currentPage={currentPage}
              isShowLoadMore={isShowLoadMore}
              setSwiperAnimation={setSwiperAnimation}
            />
            <div className="px-[.18rem] pb-[.04rem]">
              <GameList
                list={list}
                gap={gap}
                row={row}
                column={4}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                isShowLoadMore={isShowLoadMore}
                paginatedList={paginatedList}
                swiperAnimation={swiperAnimation}
              />
            </div>

            {list && list.length > count ? (
              <>
                <div className="w-full pt-[.26rem] flex flex-col items-center">
                  <div className="text-[var(--colour-40)] text-[.22rem] font-[600] text-center">
                    <Trans
                      t={t}
                      i18nKey={'home.loadmore1'}
                      values={{
                        val: isShowLoadMore ? list.length : count,
                        val2: list.length,
                        val3: '',
                      }}
                    />
                  </div>
                  <div
                    className={`text-[var(--colour-1)] text-[.22rem] font-[600] text-center flex items-center cursor-pointer`}
                    onClick={handleClick}
                  >
                    {isShowLoadMore ? t('home.packitallup') : t('home.expandAll')}
                    <div className="w-[.2rem] h-[.2rem] bg-[var(--colour-1)] ml-[.04rem] rounded-[50%] flex items-center justify-center">
                      <div
                        className={`w-[.1rem] h-[.1rem] text-[var(--colour-4)] transition-all  ${isShowLoadMore ? 'rotate-[270deg]' : 'rotate-[90deg]'}`}
                      >
                        <Icon name={IconName.BannerNext2}></Icon>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default HomeGameList;
