import { memo, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import GameIfm from '../game/gameIfm';
import Icon, { IconName } from '../icon';
import {
  setExitGameTime,
  setFairCheckData,
  setisGameOpen,
  setNeedUpdateBalance,
  setUpdateGameHeight,
} from '../../redux/reducers/appSlice';
import { GameExitReq } from '../../api';
import GameMsg from '../game/gameMsg';
import GiftMoney from '../activityEntrance/giftMoney';
import useGameInfo from '../../hooks/useGameInfo';
import DragBtnRight from '../dragBtnRight';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import useRouterHelper from '../../hooks/useRouterHelper';
import { Dialog } from 'react-vant';
import { eventEmitter, EmitterEventTypes } from '../../utils/eventEmitter';
import useDialogModalZIndex from '../../hooks/useDialogModalZIndex';
import { setHtmlStyleAttribute } from '../../utils/helper';
import Confirm from './confirm';
import DialogModal from '.';

/**
 * 游戏弹出层
 */
let gameExitTimer = null;

const GamePop = () => {
  const dispatch = useDispatch();
  const { identity, gameUrl, gameHtml, source, gameExitTimestamp } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      gameUrl: get(state, 'app.gamePopData.gameUrl', ''),
      gameHtml: get(state, 'app.gamePopData.gameHtml', ''),
      source: get(state, 'app.gamePopData.source', 0),
      gameExitTimestamp: get(state, 'app.gameExitTimestamp', -1),
    }),
    shallowEqual,
  );
  const [dialogWidth, setDialogWidth] = useState(window.innerWidth);
  const [isGameOpen, setGameOpen] = useState(false); // 游戏是否开启
  const [gameExitNum, setGameExitNum] = useState(0);
  const { getTopZIndex, removeTopZIndex, initZIndex } = useDialogModalZIndex();
  const [overlayZIndex, setOverlayZIndex] = useState(initZIndex);
  const [isButtonEnable, setIsButtonEnable] = useState(false);

  useEffect(() => {
    if (gameUrl || gameHtml) {
      setGameOpen(true);
      dispatch(setisGameOpen(true));
    } else {
      setGameOpen(false);
      dispatch(setisGameOpen(false));
    }
  }, [gameUrl, gameHtml]);

  // 登录和游戏关闭时检测退出状态
  useEffect(() => {
    if (identity && !isGameOpen) {
      startCheckGameExit();
    }
    return () => {
      clearGameExitTimer();
    };
  }, [isGameOpen, identity]);

  useEffect(() => {
    if (identity && gameExitTimestamp > 0) {
      startCheckGameExit();
    }
    return () => {
      clearGameExitTimer();
    };
  }, [gameExitTimestamp]);

  const startCheckGameExit = () => {
    checkGameExit();
    gameExitTimer = setInterval(() => {
      checkGameExit();
    }, 5000);
  };

  // 检测超过3次，停止
  useEffect(() => {
    if (gameExitNum > 3) {
      clearGameExitTimer();
    }
  }, [gameExitNum]);

  // 检测游戏退出
  const checkGameExit = () => {
    GameExitReq()
      .then((res) => {
        if (res.code === 0) {
          clearGameExitTimer();
        }
      })
      .finally(() => {
        dispatch(setNeedUpdateBalance({ need: true })); // 更新余额
        setGameExitNum((count) => count + 1);
      });
  };

  // 清除定时器
  const clearGameExitTimer = () => {
    setGameExitNum(0);
    clearInterval(gameExitTimer);
  };

  const handleResize = () => {
    setDialogWidth(window.innerWidth);
  };

  useEffect(() => {
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resizeDelay, () => {
      handleResize();
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // 1. **500ms延时**：用于设置游戏的z-index，giftmoney内可以切换游戏,避免`giftmoney`动画结束（450ms）后导致的弹窗层级错乱。
  // 2. **600ms延时**：确保在游戏的层级设置完成之前，点击游戏内弹窗层级不会错乱。
  useEffect(() => {
    let timeoutId, timer;

    if (isGameOpen) {
      timeoutId = setTimeout(() => {
        setOverlayZIndex(getTopZIndex());
      }, 500);

      timer = setTimeout(() => {
        setIsButtonEnable(true);
      }, 600);
    } else {
      setIsButtonEnable(false);
    }

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timer);
    };
  }, [isGameOpen]);

  useEffect(() => {
    setHtmlStyleAttribute('--game-pop-index', overlayZIndex);
  }, [overlayZIndex]);

  const handleClosed = () => {
    removeTopZIndex();
  };

  return (
    <>
      <Dialog
        overlayStyle={{
          zIndex: overlayZIndex,
        }}
        onClosed={handleClosed}
        width={dialogWidth}
        visible={isGameOpen}
        className="game-pop"
        showCancelButton={false}
        showConfirmButton={false}
      >
        <GameContent gameUrl={gameUrl} gameHtml={gameHtml} source={source} isButtonEnable={isButtonEnable} />
      </Dialog>
    </>
  );
};

/**
 * 游戏内容
 * @param gameUrl  游戏地址
 * @param gameHtml 游戏页面
 * @param source   游戏来源
 */
let gameNotifyHeight = 0; // 游戏通知的高度

const GameContent = ({ gameUrl, gameHtml, source, isButtonEnable }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleClickWallet } = useRouterHelper();
  const [visible, setVisible] = useState(false);

  const gameRef = useRef(null);
  const { ConfirmCloseGame } = useGameInfo();

  const { height, websiteInfo, activityStatus } = useSelector(
    (state) => ({
      height: get(state, 'app.updateGameHeight.height', 0),
      websiteInfo: get(state, 'app.websiteInitConfig.website_info'),
      activityStatus: state.app.activityStatus,
    }),
    shallowEqual,
  );

  const [isShowIcon, setIsShowIcon] = useState(false);

  useEffect(() => {
    updateIframeHeight();

    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resizeDelay, () => {
      handleResize();
    });

    const itv = window.setInterval(() => {
      setIsShowIcon((prev) => !prev);
    }, 5 * 1000);

    return () => {
      unsubscribe();
      // 还原高度和公平验证
      dispatch(setUpdateGameHeight({ height: 0 }));
      dispatch(setFairCheckData([]));
      gameNotifyHeight = 0;

      window.clearInterval(itv);
    };
  }, []);

  // 自研游戏高度变化
  useEffect(() => {
    if (source == 0 && height > 0) {
      gameNotifyHeight = height;
      updateIframeHeight();
    }
  }, [height]);

  // 屏幕高度变化
  const handleResize = () => {
    updateIframeHeight();
  };

  // 更新iframe高度
  const updateIframeHeight = () => {
    if (gameRef.current) {
      if (gameNotifyHeight > window.innerHeight) {
        gameRef.current.style.height = `${gameNotifyHeight}px`; // 游戏通知高度
      } else {
        gameRef.current.style.height = `${window.innerHeight}px`; // 屏幕高度
      }
    }
  };

  // 点击返回按钮
  const clickBtn = () => {
    if (isButtonEnable) {
      setVisible(true);
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      ConfirmCloseGame();
      setVisible(false);
      dispatch(setExitGameTime(Date.now()));
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const clickDeposit = () => {
    if (isButtonEnable) {
      handleClickWallet(1, 0, 9999);
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.InGame_Click_DepositButton);
    }
  };

  const iconBoxCss = `absolute rounded-normal flex justify-center items-center cursor-pointer text-center bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal transition-all duration-700 break-all leading-[1em] text-[.24rem]`;

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={false}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setVisible(false);
        }}
        isOpen={visible}
      >
        <Confirm
          handleClose={() => {
            setVisible(false);
          }}
          handleConfirm={() => {
            ConfirmCloseGame();
            setVisible(false);
            dispatch(setExitGameTime(Date.now()));
          }}
          handleCancel={() => {
            setVisible(false);
          }}
          title={t('game.tips')}
          cancelText={t('game.cancel')}
          confirmText={t('game.ok')}
          isConfirmBtnPrimary={false}
        >
          {t('home.game_leave_confirm_text')}
        </Confirm>
      </DialogModal>

      <GameMsg handleClose={clickBtn} />

      <div className="w-screen relative" ref={gameRef}>
        {gameUrl || gameHtml ? <GameIfm iframeUrl={gameUrl} iframeHtml={gameHtml} /> : <></>}
        <DragBtnRight
          onClick={clickBtn}
          className="left-[.4rem] top-[.4rem]"
          className1="w-[.8rem] h-[.8rem]"
          isdrag={true}
          isFromGame={true}
        >
          <div className="bg-[var(--colour-1)] text-[var(--colour-2)] flex justify-center items-center cursor-pointer w-[.8rem] h-[.8rem] rounded-normal">
            <div className="w-[.26rem] h-[.42rem]">
              <Icon name={IconName.GameBack} />
            </div>
          </div>
        </DragBtnRight>
        {activityStatus &&
        isButtonEnable &&
        activityStatus.gift_money_menu_show_switch == '1' &&
        activityStatus.gift_money_show_game_switch == '1' ? (
          <GiftMoney isFromGame={true} isdrag={true} index={2} />
        ) : (
          <></>
        )}

        {websiteInfo && websiteInfo.switch_deposit_show_game == '1' && (
          <DragBtnRight
            isFromGame={true}
            onClick={clickDeposit}
            className1="w-[1.2rem] h-[.8rem]"
            right={'.4rem'}
            top={'.4rem'}
            isLimitedInParentHeight={false}
          >
            <div className="w-[1.2rem] h-[.8rem] relative">
              <div className={`w-[1.2rem] h-[.8rem] ${iconBoxCss} ${isShowIcon ? 'opacity-100' : 'opacity-0'}`}>
                <div className="w-[.42rem] h-[.42rem]">
                  <Icon name={IconName.Wallet} />
                </div>
              </div>
              <div className={`w-[1.2rem] h-[.8rem] ${iconBoxCss} ${isShowIcon ? 'opacity-0' : 'opacity-100'}`}>
                {t('home.wallet_deposit')}
              </div>
            </div>
          </DragBtnRight>
        )}
      </div>
    </>
  );
};

export default memo(GamePop);
