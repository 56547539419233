import { useEffect, useRef, useState } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DownloadManager from '../../../../../manager/downloadManager';
import { cmsImgUrl, getMobileOperatingSystem } from '../../../../../utils/helper';
import cn from 'classnames';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig';
import IOSSafariGuide from '../../../iosSafariGuide';
import Button from '../../../../button';
import { useSpring, animated } from 'react-spring';
import DialogModal from '../../..';
import Loading from '../../../../loading2';
import { eventEmitter, EmitterEventTypes } from '../../../../../utils/eventEmitter';

export default function NTemplate001({ handleClose, downloaddata }) {
  const { t } = useTranslation();
  const { download } = useSelector(
    (state) => ({
      download: state.app.websiteInitConfig?.website_info?.download,
    }),
    shallowEqual,
  );
  const [isClickable, setIsClickable] = useState(true);
  const [isShowIOSguide, setisShowIOSguide] = useState(false);

  const [bottomDownloadBoxPromptSwitch, setbottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  const [iosDownloadLink, setIosDownloadLink] = useStateIfMounted('');
  const [fileSize, setFileSize] = useStateIfMounted(0);

  const { getServerLangText } = useLangServerConfig();

  const ref = useRef(null);
  const initialPosition = { transform: 'translateY(100%)' };

  const [styles, setSpring] = useSpring(() => ({
    from: initialPosition,
    to: { transform: 'translateY(0%)' },
    config: { duration: 200 },
  }));

  useEffect(() => {
    setbottomDownloadBoxPromptSwitch(downloaddata);
  }, [downloaddata]);

  const handleAnimationEnd = () => {
    handleClose && handleClose();

    setbottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });

    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_CloseDownloadButton);
    setIsClickable(true);
  };

  const startClosingAnimation = () => {
    if (isClickable) {
      setIsClickable(false);
      setSpring({
        from: { transform: 'translateY(0%)' },
        to: initialPosition,
        onRest: handleAnimationEnd,
        config: { duration: 200 },
      });
    }
  };

  const resize = () => {
    if (ref.current) {
      ref.current.style.height = `${window.innerHeight}px`;
    }
  };

  useEffect(() => {
    resize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      resize();
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const [isloading, setisLoading] = useState(false);

  useEffect(() => {
    setisLoading(true);

    Promise.all([
      DownloadManager.showAndroid || (DownloadManager.isNotMobile && bottomDownloadBoxPromptSwitch.isForce == true)
        ? DownloadManager.getDownloadAppSize()
        : Promise.resolve(),
      DownloadManager.showIOS ? DownloadManager.getDownloadLink() : Promise.resolve(),
    ])
      .then((results) => {
        const [fileSizeResult, downloadLinkResult] = results;

        if (fileSizeResult !== undefined) {
          setFileSize(fileSizeResult);
        }

        if (downloadLinkResult !== undefined) {
          if (downloadLinkResult.code == 0) {
            const { ios_download_link } = downloadLinkResult.result;
            setIosDownloadLink(ios_download_link);
          }
        }

        setisLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setisLoading(false);
      });
  }, []);

  const handleClick = () => {
    DownloadManager.handleInstallAction();
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setisShowIOSguide(false);
        }}
        isOpen={isShowIOSguide}
      >
        <IOSSafariGuide
          handleClose={() => {
            setisShowIOSguide(false);
          }}
        />
      </DialogModal>

      <div
        className="adaptive-width relative overflow-hidden"
        ref={ref}
        id="download-Nromal-modal-animation"
        onClick={(e) => {
          if (e.target.id == 'download-Nromal-modal-animation') {
            startClosingAnimation();
          }
        }}
      >
        <animated.div
          style={{ ...styles, position: 'absolute', bottom: 0, left: 0 }}
          className={cn('h-[3.02rem] flex items-center justify-center w-full bg-[#FFFFFF]')}
        >
          <div className="w-[7.5rem] mx-auto flex flex-col items-center justify-center px-[.32rem] space-y-[.3rem] space-x-[.24rem] select-none">
            <div className="flex flex-row items-center justify-center space-x-[.24rem] w-full">
              <img className="w-[1.36rem] h-[1.36rem]" src={cmsImgUrl(download['icon_192x192'])} />
              <p className="text-[.28rem] font-[500] flex-1 text-[var(--colour-18)] leading-[.33rem] break-words line-clamp-4">
                {bottomDownloadBoxPromptSwitch?.downloadText
                  ? bottomDownloadBoxPromptSwitch.downloadText
                  : download?.top?.tip?.bottom_title
                    ? getServerLangText(download?.top?.tip?.bottom_title)
                    : ''}
              </p>
            </div>

            <div className="flex items-center w-full">
              {isloading ? (
                <Loading className="w-full h-[.84rem]" />
              ) : (
                <>
                  {getMobileOperatingSystem() == 'iOS' && DownloadManager.showIOS && iosDownloadLink != '' ? (
                    <div className="flex flex-row items-end justify-center space-x-[.24rem]">
                      <Button
                        onClick={() => {
                          DownloadManager.gotoDownloadLink();
                        }}
                        className="bg-[var(--colour-1)] text-[var(--colour-2)] min-w-[3.44rem] px-[.1rem] h-[.84rem] rounded-[.16rem]"
                      >
                        {t('home.drawer_download_text2')}
                        {fileSize > 0 ? `(${fileSize}M)` : ''}
                      </Button>

                      <div
                        onClick={() => {
                          setisShowIOSguide(true);
                        }}
                        className="rounded-normal min-w-[3.44rem] h-[.84rem] px-[.1rem] bg-[var(--colour-5)] text-[var(--colour-3)] border border-solid border-[var(--colour-1)] flex items-center justify-center"
                      >
                        {t('home.add_to_home_screen')}
                      </div>
                    </div>
                  ) : (
                    <div className="w-full h-[.84rem] flex items-center justify-end gap-[.16rem]">
                      <div
                        onClick={startClosingAnimation}
                        className="w-[2.12rem] h-full rounded-[.16rem] text-[.28rem] font-[500] text-[var(--colour-3)] border border-[var(--colour-1)] bg-[var(--colour-5)] flex items-center justify-center cursor-pointer active:scale-[.95] transition-all select-none"
                      >
                        {t('home.cancelar')}
                      </div>
                      <div
                        onClick={handleClick}
                        className="w-[2.12rem] h-full rounded-[.16rem] text-[.28rem] font-[500] text-[var(--colour-2)] bg-[var(--colour-1)] flex items-center justify-center cursor-pointer active:scale-[.95] transition-all select-none"
                      >
                        {t('home.instalar')}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </animated.div>
      </div>
    </>
  );
}
