import { service as request } from '../utils/request';
import { serviceNew as requestNew } from '../utils/requestNew';
import { getHostName } from '../utils/helper';

//充值记录
export function DepositList(params = null) {
  return request({
    url: '/deposit/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//提现记录
export function WithdrawList(params = null) {
  return request({
    url: '/withdraw/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//提交充值
export function WithdrawCrypto(params = null) {
  return request({
    url: '/v1/withdraw/crypto',
    method: 'post',
    data: {
      ...params,
    },
  });
}
//提交充值
export function WithdrawAction(params = null) {
  return request({
    url: '/v1/withdraw/action',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 客服接口
export function WithdrawInfo(params = null) {
  return request({
    url: '/withdraw/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//提交充值
export function DepositAction(params = null) {
  return request({
    url: '/v1/deposit/action',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 客服接口
export function CustomerList(params = null) {
  return request({
    url: '/userCenter/customerList',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//充值信息
export function DepositInfo(params = null) {
  return request({
    url: '/deposit/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 修改密码
export function ResetPassword(params = null) {
  return request({
    url: '/reset/passwd',
    method: 'post',
    params: {
      ...params,
    },
  });
}
// 全部已读
export function MsgReadALL(params = null) {
  return request({
    url: '/message/read/all',
    method: 'post',
    params: {
      ...params,
    },
  });
}
// 已读
export function MsgRead(params = null) {
  return request({
    url: '/message/read',
    method: 'post',
    params: {
      ...params,
    },
  });
}
// 领取消息奖励
export function MsgClaim(params = null) {
  return request({
    url: '/message/claim',
    method: 'post',
    params: {
      ...params,
    },
  });
}
// 站内信列表
export function InboxList(params = null) {
  return request({
    url: '/message/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 个人用户信息
export function AccountInfo(params = null) {
  return request({
    url: '/userCenter/accountInfo',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 自研游戏初始化
export function OriginalGameInit(params = null) {
  return request({
    url: '/game/original/init',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 切换是否接受fasttrack消息
export function SetFastTrackIsSend(params = null) {
  return request({
    url: '/fast/track',
    method: 'post',
    params: {
      ...params,
    },
  });
}

// 记录app跳转到web时候带的参数
export function AppToWeb(params = null) {
  return request({
    url: '/app/to/website',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 使用PromoCode
export function UsePromoCodeReq(params = null) {
  return request({
    url: '/promotion/code/use',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 获取BounsByPromoCode
export function CheckPromoCodeReq(params = null) {
  return request({
    url: '/promotion/code',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function CategoryListReq(params = null) {
  return request({
    url: '/category/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 用户详情
export function UserinfoReq(params = null) {
  return request({
    url: '/user/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// stake列表(资产)
export function GetAllStakeListReq(params = null) {
  return request({
    url: '/stake/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// stake列表（Finances）
export function GetUserStakeListReq(params = null) {
  return request({
    url: '/stake/user/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// stake 购买
export function StakePurchaseReq(params = null) {
  return request({
    url: '/stake/purchase',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// stake 赎回
export function StakeWithdrawReq(params = null) {
  return request({
    url: '/stake/withdraw',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 领取每日任务
export function ClaimTaskDailyReq(params = null) {
  return request({
    url: '/taskdaily/receive',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 放弃每日任务
export function WaiveTaskDailyRewardsReq(params = null) {
  return request({
    url: '/taskdaily/waive',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 领奖每日任务
export function ClaimTaskDailyRewardsReq(params = null) {
  return request({
    url: '/taskdaily/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetTaskDailyListReq(params = null) {
  return request({
    url: '/taskdaily/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GameListReq(params = null) {
  return request({
    url: '/game/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GameListGameTypeReq(params = null) {
  return request({
    url: '/game/list/gametype',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GameInfoReq(params = null) {
  return request({
    url: '/game/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GameInitReq(params = null) {
  return request({
    url: '/game/init',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GetProviderListReq(params = null) {
  return request({
    url: '/provider/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 通过邀请码获得渠道信息
export function ChannelRefferralCodeReq(params = null) {
  return request({
    url: '/channel/refferral/code',
    method: 'get',
    params: {
      ...params,
      domain: getHostName(),
    },
  });
}

//获得vip列表
export function VipLevelListReq(params = null) {
  return request({
    url: '/vip/level/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//我的vip
export function MyVipLevelInfoReq(params = null) {
  return request({
    url: '/vip/level/my/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//领取VIP级别奖励金额
export function VipLevelClaimReq(params = null) {
  return request({
    url: '/vip/level/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//领取推荐余额奖金
export function MyBalanceClaimReq(params = null) {
  return request({
    url: '/referrals/balance/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//获取推荐统计数据
export function GetTotalReferralsReq(params = null) {
  return request({
    url: '/referrals',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//获取推荐统计列表
export function GetReferralsListReq(params = null) {
  return request({
    url: '/referrals/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

/**
 * 比赛列表
 * @param {page:页数,page_size:条数,status:int	状态,  0:未开始 1:进行中 2:结束} params
 */
// export function GetTournamentListReq(params = null) {
//   return request({
//     url: '/tournament/list',
//     method: 'get',
//     params: {
//       ...params,
//     },
//   });
// }
/**
 * 比赛报名
 * @param {tour_id：锦标赛id} params
 */
// export function TournamentSigninReq(params = null) {
//   return request({
//     url: '/tournament/signin',
//     method: 'post',
//     data: {
//       ...params,
//     },
//   });
// }

/**
 * Tournament榜单
 * @param {tour_id：锦标赛id} params
 */
// export function GetTournamentLeaderboardReq(params = null) {
//   return request({
//     url: '/tournament/leaderboard',
//     method: 'get',
//     params: {
//       ...params,
//     },
//   });
// }

/**
 * Tournament详情
 * @param {tour_id：锦标赛id} params
 */
export function GetRournamentDetailReq(params = null) {
  return request({
    url: '/tournament/detail',
    method: 'get',
    params: {
      ...params,
    },
  });
}

/**
 * 比赛报名
 * @param {tour_id：锦标赛id} params
 */
export function TournamentPrizeClaimReq(params = null) {
  return request({
    url: '/tournament/prize/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//游戏收藏列表
export function GetFavouritesListReq(params = null) {
  return request({
    url: '/favourites/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//资金列表
export function GetFundListReq(params = null) {
  return request({
    url: '/fund/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//最近玩游戏列表
export function GetRecentListReq(uid) {
  const recentGameListIds = localStorage.getItem(`recentGameList_${uid}`);
  if (recentGameListIds) {
    const recentGameListArr = Array.from(JSON.parse(recentGameListIds));
    return GameListReq({
      uuid: recentGameListArr.join(','),
    });
  } else {
    return new Promise((resolve) => {
      resolve({
        code: 0,
        result: {
          list: [],
          pages: {
            count: 0,
          },
        },
      });
    });
  }
}

//下注列表
export function GetBettingListReq(params = null) {
  return request({
    url: '/betting/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 获取推荐余额数据
export function MyBalanceInfoReq(params = null) {
  return request({
    url: '/referrals/balance',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 私人消息列表
export function GetNotificationListReq(params = null) {
  return request({
    url: '/notification/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 私人消息标记为已读
export function MarkNotificationAllReq(params = null) {
  return request({
    url: '/notification/read/all',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 系统消息列表
export function GetMsgListReq(params = null) {
  return request({
    url: '/platform/message/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 私人消息标记为已读
export function MarkMsgAllReq(params = null) {
  return request({
    url: '/platform/message/read/all',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 添加游戏收藏
export function AddFavouritesReq(params = null) {
  return request({
    url: '/favourites/add',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 删除游戏收藏
export function DelFavouritesReq(params = null) {
  return request({
    url: '/favourites/remove',
    method: 'post',
    data: {
      ...params,
    },
  });
}

/**
 *
 * @param {id} params
 */
export function GetPromotionDetailReq(params = null) {
  return request({
    url: '/promotion/detail',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//获得汇率列表B9
export function GetExchangeRateListReq(params = null) {
  return request({
    url: '/exchange/rate/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//获取demo链接
export function GameDemoInitReq(params = null) {
  return request({
    url: '/game/init/demo',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//获取用户余额接口
export function GetUserBalanceReq(params = null) {
  return request({
    url: '/user/balance',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 读系统消息
export function ReadMsgReq(params = null) {
  return request({
    url: '/platform/message/read',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 读私人消息
export function ReadNotificationReq(params = null) {
  return request({
    url: '/notification/read',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 查看kyc认证信息
export function GetUserKycInfoReq(params = null) {
  return request({
    url: '/kyc/user/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// KYC认证1
export function SetKycLevel1Req(params = null) {
  return request({
    url: '/kyc/step1',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// KYC认证2
export function SetKycLevel2Req(params = null) {
  return request({
    url: '/kyc/step2',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// KYC认证3
export function SetKycLevel3Req(params = null) {
  return request({
    url: '/kyc/step3',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// KYC认证4
export function SetKycLevel4Req(params = null) {
  return request({
    url: '/kyc/step4',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 上传图片
export function UploadFileReq(params = null) {
  return request({
    url: '/kyc/uploadfile',
    method: 'post',
    data: params,
  });
}

// 获取提现配置的接口
export function GetWithdrawConfigReq(params = null) {
  return request({
    url: '/withdraw/config',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 提币B9
export function WithdrawReq(params = null) {
  return request({
    url: '/withdraw',
    method: 'post',
    data: params,
  });
}

// 存币地址配置
export function GetDepositConfigReq(params = null) {
  return request({
    url: '/deposit/config',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 未读消息数量 作废
// export function GetAllUnreadCountReq(params = null) {
//   return request({
//     url: '/notification/unread/count',
//     method: 'get',
//     params: {
//       ...params
//     }
//   })
// }

// 获取系统配置的接口
export function GetSystemConfigReq(params = null) {
  return request({
    url: '/system/config',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// B9cSwapReq
export function B9cSwapReq(params = null) {
  return request({
    url: '/b9c/swap',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// B9cWheelDraw
export function B9cWheelDraw(params = null) {
  return request({
    url: '/lucky/wheel/draw',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// B9cWheelDraw 积分
export function B9cWheelStarsDraw(params = null) {
  return request({
    url: '/lucky/wheel/draw/stars',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// google 图形验证用到的key
export function GetGoogleSiteKeyReq(params = null) {
  return request({
    url: '/google/sitekey',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function SendCodeByEmailReq(params = null) {
  return request({
    url: '/email/code/send',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function BindEmailReq(params = null) {
  return request({
    url: '/email/bind',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function ResetPasswdReq(params = null) {
  return request({
    url: '/reset/passwd',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 修改用户信息
export function ModifyUserReq(params = null) {
  return request({
    url: '/user/modify',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function SendCodeByMobileReq(params = null) {
  return request({
    url: '/mobile/code/send',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function FindPasswdByEmailReq(params = null) {
  return request({
    url: '/email/password/change',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function FindPasswdByMobileReq(params = null) {
  return request({
    url: '/mobile/password/change',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function BindMobileReq(params = null) {
  return request({
    url: '/mobile/bind',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 绑定Google二次验证密钥B9
export function BindGoogleSecretReq(params = null) {
  return request({
    url: '/google/secret/bind',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 获取绑定Google二次验证密钥
export function GetGoogleSecretReq(params = null) {
  return request({
    url: '/google/secret',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 解除绑定Google二次验证密钥B9
export function UnbindGoogleSecretReq(params = null) {
  return request({
    url: '/google/secret/unbind',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//toppay 提现
export function FiatToppayWithdraw(params = null) {
  return request({
    url: '/fiat/toppay/withdraw',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//toppay 充值
export function FiatToppayCharge(params = null) {
  return request({
    url: '/fiat/toppay/charge',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//hippopay 充值
export function FiatHippoPayCharge(params = null) {
  return request({
    url: '/fiat/hippopa/charge',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//hippopay 提现
export function FiatHippoPayWithdraw(params = null) {
  return request({
    url: '/fiat/hippopa/withdraw',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 自研游戏推荐游戏列表
export function GetProviderRecommend(params = null) {
  return request({
    url: '/game/recommendation/provider',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 用户反馈信息

export function FeedbackReq(params = null) {
  return request({
    url: '/footer/feedback',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 获取任务成就列表
export function GetTaskachvList(params = null) {
  return request({
    url: '/taskachv/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 领取任务奖励
export function TaskachvTaskClaim(params = null) {
  return request({
    url: '/taskachv/task/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 领取成就奖励
export function TaskachvAcheClaim(params = null) {
  return request({
    url: '/taskachv/ache/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//获取积分商品列表
export function StarsgoodsList(params = null) {
  return request({
    url: '/starsgoods/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 兑换积分商品
export function StarsgoodsExchange(params = null) {
  return request({
    url: '/starsgoods/exchange',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//获取积分商品数量
export function GetTaskachvClaimNum(params = null) {
  return request({
    url: '/taskachv/claim/num',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//首页活动列表数据
export function HomePromotionDataReq(params = null) {
  return request({
    url: '/promotion/home',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//活动列表数据
export function PromotionDataReq(params = null) {
  return request({
    url: '/promotion/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//首页活动详情数据
export function PromotionDetailReq(params = null) {
  return request({
    url: '/promotion/detail',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//BetBonus标签
export function BetBonusReq(params = null) {
  return request({
    url: '/betting/bonus',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//BetBonus Record标签 参数 {page:1}
export function BetBonusRecordReq(params = null) {
  return request({
    url: '/betting/record',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ClaimBettingBonusV2(params = null) {
  return request({
    url: '/claim/betting/bonus/v2',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//==============================新接口

export function GetReferralInviteList(params = null) {
  return request({
    url: '/referral/invite',
    method: 'get',
    params: {
      ...params,
    },
  });
}
export function GetReferralTransfer(params = null) {
  return request({
    url: '/referral/transfer',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// http://website.678pub.com/referral/refer
// request:
// 	start_date 	开始日期
// 	end_date 	结束日期
export function GetReferralReferList(params = null) {
  return request({
    url: '/referral/refer',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// http://website.678pub.com/referral/member

// 	request:
// 		level 		referral 级别（0:全部 1、2、3级）。 接受值范围 0-3
// 		uid 		指定搜索子渠道的 uid 和级别无关
// 		page 		页码默认 1

export function GetReferralMemberList(params = null) {
  return request({
    url: '/referral/member',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GetReferralMemberListV2(params = null) {
  return request({
    url: '/referral/member/v2',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// http://website.678pub.com/referral/bonus
// request:
// 		behavier 	对应行为
// 					1、值：   "betting_bonus"       // 下注返佣
// 							 "invitation_conquest" // 邀请成就
// 							 "invitation_bonus"    // 邀请奖励
// 							 "bonus_withdraw"      // 佣金提现
// 							 "bonus_transfer"      // 佣金划转
// 					2、不传包含上面无相
// 		page 		页码默认 1

export function GetReferralBonusList(params = null) {
  return request({
    url: '/referral/bonus',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ReferralInviteTotal(params = null) {
  return request({
    url: '/referral/invite/total',
    method: 'get',
    params: {
      ...params,
    },
  });
}
export function ReferralMyData(params = null) {
  return request({
    url: '/referral/my/data',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ReferralDirectFinance(params = null) {
  return request({
    url: '/referral/direct/finance',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ReferralAllData(params = null) {
  return request({
    url: '/referral/all/data',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ReferralDirectWager(params = null) {
  return request({
    url: '/referral/direct/wager',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// referral 领取佣金
export function ReferralCommissionClaim(params = null) {
  return request({
    url: '/referral/commission/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// export function GetReVipLevelList(params = null) {
//   return request({
//     url: '/vip/level/list',
//     method: 'get',
//     params: {
//       ...params,
//     },
//   });
// }

export function GetReVipMyLevelInfo(params = null) {
  return request({
    url: '/vip/level/my/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// id
// 1
// 是	Text
// id (vip列表中返回值vip_bonus_info中id)
// bonus_type
// weekly_reward
// 是	Text
// weekly_reward(周奖励) rank_reward(升级奖励) monthly_reward(月奖励)  (vip列表中返回值vip_bonus_info中type)
export function GetReVipMyLevelClaim(params = null) {
  return requestNew({
    url: '/promotion/vip/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function PromotionVipClaimAll(params = null) {
  return requestNew({
    url: '/promotion/vip/claim/all',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetInitConfig(params = null) {
  return request({
    url: '/web/config',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GetWebHelpInfo(params = null) {
  return request({
    url: '/footer/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 获取下载页面
export function GetDownloadPage(params = null) {
  return request({
    url: '/download/page',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 获取下载链接
export function GetDownloadLink(params = null) {
  return request({
    url: '/download/link',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 获取用户消息列表
export function GetUserMessageList(params = null) {
  return request({
    url: '/message/user/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// betby体育初始化
export function betbyInitReq(params = null) {
  return request({
    url: '/sport/betby/init',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GameExitReq(params = null) {
  return request({
    url: 'game/exit',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//活动类型是否可以领取
export function GetActivityStatusReq() {
  return request({
    url: 'activity/status',
    method: 'get',
  });
}

//活动领取奖励
export function ClaimActivityReq(params = null) {
  return request({
    url: 'activity/task/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//救援金领取奖励
export function ClaimReliefReq(params = null) {
  return request({
    url: 'activity/relief/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//锦标赛领取奖励
export function ClaimTournamentReq(params = null) {
  return request({
    url: 'activity/tournament/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//活动领取所有奖励
export function ClaimAllActivityReq(params = null) {
  return request({
    url: 'activity/task/claim/all',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//获取任务列表
export function GetActivityListReq(params = null) {
  return request({
    url: 'activity/task/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//获取锦标赛列表
export function GetTournamentListReq(params = null) {
  return request({
    url: 'activity/tournament/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//获取活动游戏列表
export function GetActivityGamesReq(params = null) {
  return request({
    url: 'activity/game',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 锦标赛报名 activity/tournament/entername
export function JoinTourReq(params = null) {
  return request({
    url: 'activity/tournament/entername',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//获取锦标赛详情
export function GetTourInfoReq(params = null) {
  return request({
    url: 'activity/tournament/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//获取红包雨列表
export function GetCashRainListReq(params = null) {
  return request({
    url: 'activity/red/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//领取活动红包
export function ClaimCashRainReq(params = null) {
  return request({
    url: 'activity/red/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//giftMoney 列表
export function GiftMoneyListReq(params = null) {
  return request({
    url: 'gift/money/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//giftMoney 游戏列表
export function GiftMoneyGameListReq(params = null) {
  return request({
    url: 'gift/money/games',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//giftMoney 放弃
export function GiftMoneyCancelReq(params = null) {
  return request({
    url: 'gift/money/cancel',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//giftMoney 状态
export function GiftMoneyStatusReq(params = null) {
  return request({
    url: 'gift/money/status',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//giftMoney 领奖(单个)
export function GiftMoneyClaimReq(params = null) {
  return request({
    url: 'gift/money/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//giftMoney 领奖(全部)
export function GiftMoneyClaimAllReq(params = null) {
  return request({
    url: 'gift/money/claim/all',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//新人活动列表
export function ActivityNoviceListReq(params = null) {
  return request({
    url: 'activity/novice/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//新人活动领取
export function ClaimActivityNoviceReq(params = null) {
  return request({
    url: 'activity/novice/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 兑换码 兑换
export function RedeemCodeClaimReq(params = null) {
  return request({
    url: '/redeem/code/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 裂变 发消息手机号列表
export function SendPhoneList(params = null) {
  return request({
    url: '/send/phone/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 裂变 活动展示顺序
export function ActivityShowOrder(params = null) {
  return request({
    url: '/activity/show/order',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 裂变 裂变活动列表
export function ActivityContractList(params = null) {
  return request({
    url: '/activity/contract/list',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 裂变 裂变活动抽奖
export function ActivityContractDraw(params = null) {
  return request({
    url: '/activity/contract/draw',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 裂变 裂变活动领取奖励
export function ActivityContractClaim(params = null) {
  return request({
    url: '/activity/contract/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 裂变 领取奖励列表
export function ActivityContractReport(params = null) {
  return request({
    url: '/activity/contract/report',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 裂变 领取抽奖列表
export function ActivityContractReportWt(params = null) {
  return request({
    url: '/activity/contract/report/wt',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function LayoutMobileApiReq(params = null) {
  return request({
    url: '/game/list/categorys',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function LangConfigReq(params = null) {
  return request({
    url: '/layout/lang',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 新手引导 转盘
export function Guideturntable(params = null) {
  return request({
    url: '/activity/guide/turntable',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 新手引导 转盘 抽奖
export function Guidedraw(params = null) {
  return request({
    url: '/activity/guide/draw',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 新手引导 转盘 领奖
export function Guideclaim(params = null) {
  return requestNew({
    url: '/promotion/pending/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 正常轮盘
export function ActivityGuideDraw(params = null) {
  return request({
    url: '/activity/guide/turntable/draw',
    method: 'post',
    params: {
      ...params,
    },
  });
}

export function ActivityGuideLog(params = null) {
  return request({
    url: '/activity/guide/turntable/claim/log',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ActivityAllReq(params = null) {
  return request({
    url: '/activity/all',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ActivityPromotionalCashbackLog(params = null) {
  return request({
    url: '/activity/promotional/cashback/log',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ActivityPromotionalCashbackClaim(params = null) {
  return request({
    url: '/activity/promotional/cashback/claim',
    method: 'post',
    params: {
      ...params,
    },
  });
}

export function ActivityPromotionalAchievementsClaim(params = null) {
  return request({
    url: '/activity/promotional/achievements/claim',
    method: 'post',
    params: {
      ...params,
    },
  });
}

// 用户报表
export function getReportAccountdata(params = null) {
  return request({
    url: '/report/account',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 下注报表
export function getReportBetsdata(params = null) {
  return request({
    url: '/report/bets',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 下注统计报表
export function getReportBetsTotaldata(params = null) {
  return request({
    url: '/report/bets/total',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function ExchangeRate(params = null) {
  return request({
    url: '/wallet/exchange/rate',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//网站初始化配置
export function GetWebsiteInit(params = null) {
  return request({
    url: '/website/init',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//网站初始化数据
export function GetWebsiteInitLang(params = null) {
  return request({
    url: '/website/init/lang',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//锦标赛数据接口
export function GetWebsiteTournament(params = null) {
  return request({
    url: '/website/tournament',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//网站初始化数据预览模式
export function GetWebsitePreviewInitLang(params = null) {
  return request({
    url: '/website/init/lang/template',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// jackpot钱数列表
export function GetJackpotInfo(params = null) {
  return request({
    url: '/jackpot/info',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// pwa 引导领取奖励
export function PwaGuideReward(params = null) {
  return request({
    url: '/pwa/guide/reward',
    method: 'post',
    params: {
      ...params,
    },
  });
}

//获取跑马灯数据
export function GetMarquee(params = null) {
  return requestNew({
    url: '/marquee',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//获取反佣报表
export function GetConvidarReport(params = null) {
  return requestNew({
    url: '/referral/v2/convidar/report',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetConvidarIndex(params = null) {
  return requestNew({
    url: '/referral/v2/convidar/index',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetPerformanceList(params = null) {
  return requestNew({
    url: '/referral/performance/index',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetPerformancedirectIndex(params = null) {
  return requestNew({
    url: '/referral/performance/directIndex',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetPerformanceindirectIndex(params = null) {
  return requestNew({
    url: '/referral/performance/indirectIndex',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetclaimHistory(params = null) {
  return requestNew({
    url: '/referral/v2/invite/claimHistory',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetDirectList(params = null) {
  return requestNew({
    url: '/referral/v2/direct/list',
    method: 'post',
    data: {
      ...params,
    },
  });
}
export function GetDirectUid(params = null) {
  return requestNew({
    url: '/referral/v2/direct/uid',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetRoundDetail(params = null) {
  return requestNew({
    url: '/referral/v2/roundDetail',
    method: 'post',
    data: {
      ...params,
    },
  });
}
export function GetRoundDetailByDay(params = null) {
  return requestNew({
    url: '/referral/v2/roundDetailByDay',
    method: 'post',
    data: {
      ...params,
    },
  });
}
export function GetDirectFinanceList(params = null) {
  return requestNew({
    url: '/referral/v2/direct/financeList',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetDirectFinanceSummary(params = null) {
  return requestNew({
    url: '/referral/v2/direct/financeSummary',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetDirectBonus(params = null) {
  return requestNew({
    url: '/referral/directBonus/index',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetDirectBonusSummary(params = null) {
  return requestNew({
    url: '/referral/directBonus/summary',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetConvidarConfig(params = null) {
  return requestNew({
    url: '/referral/v2/convidar/config',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetReferralInviteInfo(params = null) {
  return requestNew({
    url: '/referral/v2/invite/info',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function ReferralInviteClaim(params = null) {
  return requestNew({
    url: '/referral/v2/invite/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetReferralMydata(params = null) {
  return requestNew({
    url: '/referral/v2/mydata',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetReferralMydataTotal(params = null) {
  return requestNew({
    url: '/referral/v2/mydata/total',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetAllDataInfo(params = null) {
  return requestNew({
    url: '/referral/v2/allData',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetPromotionVipList(params = null) {
  return requestNew({
    url: '/promotion/vip/list',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetPromotionEventsList(data = null) {
  return requestNew({
    url: '/promotion/events/list',
    method: 'post',
    data,
  });
}

export function GetPromotionHistory(params = null) {
  return requestNew({
    url: '/promotion/history/index',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetPromotionRakebackConfig(params = null) {
  return requestNew({
    url: '/promotion/rakeback/config',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function PromotionRakebackClaim(params = null) {
  return requestNew({
    url: '/promotion/rakeback/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetPromotionPendingList(params = null) {
  return requestNew({
    url: '/promotion/pending/list',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function PromotionPendingClaim(params = null) {
  return requestNew({
    url: '/promotion/pending/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function getPromotionClaimedCount(params = null) {
  return requestNew({
    url: '/promotion/claimed/count',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function PromotionActivitySettle(params = null) {
  return requestNew({
    url: '/promotion/activity/settle',
    method: 'post',
    data: {
      ...params,
    },
  });
}

/***获取7B牌照stamp */
export function GetLicenseStamp(params = null) {
  return requestNew({
    url: '/license/stamp',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function GetCpfBind(params = null) {
  return requestNew({
    url: '/cpf/bind',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetProfileData(params = null) {
  return requestNew({
    url: '/profile/data',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetMissionList(params = null) {
  return requestNew({
    url: '/mission/list',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function MissionClaim(params = null) {
  return requestNew({
    url: '/mission/claim',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetActivityMysteryList(params = null) {
  return requestNew({
    url: '/activity/mystery/list',
    method: 'post',
    data: {
      ...params,
    },
  });
}

export function GetGameListCategoryGroupbytype(params = null) {
  return requestNew({
    url: '/game/list/category/groupbytype',
    method: 'get',
    params: {
      ...params,
    },
  });
}
