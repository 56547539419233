import LoadMore from '../loadMore';
import LoadMoreText from '../loadMoreText';
import { isControllableSite } from '../../utils/helper';

export default function LoadingMoreWithText({
  className = '',
  handleLoadMore = () => {},
  showLoading = false,
  currentNum = 1,
  total = 1,
  lang_key_title = '',
  type = 'default', // default 为默认样式, mysteryBonus 为神秘彩金样式
  i18nKey = 'home.loadmore1',
  isDiffTemplate = true, // 是否区分长短盘，如果不区分，只用LoadMoreText
}) {
  return (
    total > currentNum &&
    (isDiffTemplate ? (
      isControllableSite() ? (
        <LoadMoreText
          className={className}
          onClick={handleLoadMore}
          showLoading={showLoading}
          currentNum={currentNum}
          total={total}
          lang_key_title={lang_key_title}
          type={type}
          i18nKey={i18nKey}
        />
      ) : (
        <LoadMore className={className} onClick={handleLoadMore} showLoading={showLoading} />
      )
    ) : (
      <LoadMoreText
        className={className}
        onClick={handleLoadMore}
        showLoading={showLoading}
        currentNum={currentNum}
        total={total}
        lang_key_title={lang_key_title}
        type={type}
        i18nKey={i18nKey}
      />
    ))
  );
}
