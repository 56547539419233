const FooterText = ({ text = '' }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      className="break-all text-[var(--colour-15)] font-[500] text-[.2rem] leading-[1.6em] text-center"
    ></div>
  );
};

export default FooterText;
