import { useEffect, useState } from 'react';
import Guide from './guide/guideContent';
import Main from './main/mainContent';
import { ActivityAllReq } from '../../../../../api';
import { get } from 'lodash';
import NoData from '../../../../noData';
import PopBg from '../../../components/popBg';
import Loading2 from '../../../../loading2';
import { useDispatch } from 'react-redux';

export default function Index({
  handleClosePagingButton = () => {},
  showContent: _showContent = 'guide',
  handleClose = () => {},
  data: _data = null,
  isShowCloseBtn = true,
  isSinglePopup = true,
  updateList = () => {},
}) {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(_showContent);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(_data);

  const updateData = () => {
    const params = {
      data_type: JSON.stringify(['activity_promotional_cashback']),
    };

    setIsLoading(true);

    ActivityAllReq(params).then((res) => {
      setIsLoading(false);

      if (res.code == 0) {
        const activity_promotional_cashback = get(res, 'result.activity_promotional_cashback');
        //dispatch(setActivityItemData({ activity_promotional_cashback: activity_promotional_cashback }));
        const _data = get(res, 'result.activity_promotional_cashback.list[0]');
        if (_data) {
          setData(_data || null);
        }
      }
    });
  };

  useEffect(() => {
    if (!_data) {
      updateData();
    }
  }, []);

  const showMain = () => {
    setShowContent('main');
    handleClosePagingButton();
  };

  const updateDataAfterClaim = (data) => {
    setData(data);
  };

  const onExpired = () => {
    if (isSinglePopup) {
      handleClose();
    } else {
      updateList();
    }
  };

  return isLoading ? (
    <PopBg handleClose={handleClose}>
      <div className="h-full flex items-center justify-center">
        <Loading2 />
      </div>
    </PopBg>
  ) : data ? (
    showContent == 'guide' ? (
      <Guide
        data={data}
        handleClose={handleClose}
        isShowCloseBtn={isShowCloseBtn}
        showMain={showMain}
        onExpired={onExpired}
      />
    ) : showContent === 'main' ? (
      <Main
        data={data}
        handleClose={handleClose}
        isShowCloseBtn={isShowCloseBtn}
        updateDataAfterClaim={updateDataAfterClaim}
        onExpired={onExpired}
      />
    ) : (
      <PopBg handleClose={handleClose}>
        <div className="h-full flex items-center justify-center">
          <NoData showTextLangkey="promotion.eventend" />
        </div>
      </PopBg>
    )
  ) : (
    <PopBg handleClose={handleClose}>
      <div className="h-full flex items-center justify-center">
        <NoData showTextLangkey="promotion.eventend" />
      </div>
    </PopBg>
  );
}
