import { Guideturntable } from '../../../api';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WheelCountDown from '../../dialogModal/components/activityCountDown';
import LimitClickWrapper from '../../limitClickWrapper';
import EntrancePng from '../../../assets/img/pliotwheel/entrance.png';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setguideWheelSwitch,
  setRefetchActivityData,
  setscheduleNum,
} from '../../../redux/reducers/appSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import DragBtnRight from '../../dragBtnRight';
import Claimbtn from '../components/claimbtn';

const WheelEntrace = ({ isdrag = false, className = '', index = null, imgpath = '', EntranceDisplayLocation }) => {
  const { t } = useTranslation();
  const [result, setresult] = useState([]);
  const [noviceTaskConfig, setnoviceTaskConfig] = useState([]);

  const dispatch = useDispatch();

  const { identity, SpinguideWheelStatus, guideWheelSwitch, scheduleNum, activityData } = useSelector(
    (state) => ({
      identity: state.user.identity,
      SpinguideWheelStatus: state.user.SpinguideWheelStatus,
      guideWheelSwitch: state.app.guideWheelSwitch,
      scheduleNum: state.app.scheduleNum,
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );

  const handleClick = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.PilotWheel,
      }),
    );
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Wheel);
  };

  useEffect(() => {
    if (
      activityData &&
      activityData.hasOwnProperty('activity_novice_guide_tournament') &&
      activityData.activity_novice_guide_tournament &&
      activityData.activity_novice_guide_tournament.hasOwnProperty('list') &&
      activityData.activity_novice_guide_tournament.list.length > 0
    ) {
      const wheelObj = activityData.activity_novice_guide_tournament.list[0];

      setresult(wheelObj);
      setnoviceTaskConfig(wheelObj.novice_task_config);
    }
  }, [activityData, identity]);

  useEffect(() => {
    if (noviceTaskConfig && noviceTaskConfig.length > 0 && result && result.novice_pilot_switch != 0) {
      let count = 0;
      const total = noviceTaskConfig.length;
      const _len = noviceTaskConfig.filter((item) => {
        if (item.status == '2') {
          count += Number(item.schedule);
          return item;
        }
      }).length;
      if (total === _len) {
        dispatch(setscheduleNum(0));
        dispatch(setguideWheelSwitch(true));
      } else {
        if (result.task_condition_display_switch === 1) {
          dispatch(setguideWheelSwitch(false));
          dispatch(setscheduleNum(count));
        } else if (result.task_condition_display_switch === 0) {
          dispatch(setscheduleNum(0));
          dispatch(setguideWheelSwitch(false));
        }
      }
    } else {
      if (result) {
        if (result.novice_pilot_switch == 0 && result.draw_num > 0) {
          dispatch(setguideWheelSwitch(true));
        } else {
          dispatch(setguideWheelSwitch(false));
        }
      }
    }
  }, [noviceTaskConfig, result]);

  const onComplete = () => {
    dispatch(setRefetchActivityData());
  };

  return (
    <DragBtnRight
      className={`activty-absolute-bottom-${index + 1} ${index != null && EntranceDisplayLocation == '0' ? 'activity-item' : ''} ${EntranceDisplayLocation == '0' ? 'right-[.08rem]' : 'left-[.08rem]'}`}
      isLimitedInParentHeight={false}
      isdrag={isdrag}
      className1="w-[1.5rem] h-[1.5rem]"
      onClick={handleClick}
    >
      <div className={`w-full flex justify-center ${className || ''}`}>
        <div className={`w-[1.5rem] h-[1.5rem] flex flex-col items-center justify-center cursor-pointer relative`}>
          <div className="scale-[.5]">
            <div className={`w-[3rem] h-[3rem] flex flex-col items-center justify-center cursor-pointer relative`}>
              <img src={imgpath} alt="" className="not-drag absolute left-0 top-0 w-full" />

              {identity && guideWheelSwitch ? (
                <Claimbtn
                  txtColor="event_bonus_title1"
                  className="rounded-[.08rem] bg-EntranceClaim_btn flex items-center justify-center absolute abs-x-center bottom-[0]"
                >
                  {t('guideWheel.claim')}
                </Claimbtn>
              ) : (
                <>
                  {result &&
                  result.novice_pilot_switch != 0 &&
                  identity &&
                  scheduleNum != 0 &&
                  result.task_condition_display_switch == 1 ? (
                    <div
                      className="absolute left-[50%] translate-x-[-50%] w-[2.4rem] h-[.2rem] rounded-[.1rem] top-[1.86rem] p-[.02rem] overflow-hidden"
                      style={{ background: 'rgba(61, 41, 43, 0.80)' }}
                    >
                      <div
                        className="rounded-[.08rem] h-full"
                        style={{
                          background: 'linear-gradient(180deg, #FFE485 0%, #FFF59A 19.5%, #D97F2B 85%, #FFD66B 100%)',
                          width: `${scheduleNum || 0}%`,
                        }}
                      ></div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="absolute abs-x-center bottom-0">
                    {result && result.activity_end_time ? (
                      <WheelCountDown onComplete={onComplete} endTime={result.activity_end_time} />
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DragBtnRight>
  );
};

export default WheelEntrace;
