import DialogModal from '../dialogModal/';
import { useSpring, animated } from 'react-spring';

export default function Index({
  shouldCloseOnOverlayClick = true,
  overlayClassName = '',
  handleOverlayClick = () => {},
  isOpen = true,
  handleAfterClose = () => {},
  handleClose = () => {},
  children,
}) {
  const styles = useSpring({
    marginTop: isOpen ? '0' : '100vh',
    config: { tension: 200 },
    width: '100%',
  });

  return (
    <DialogModal
      turnOnAnimation={false}
      handleOverlayClick={handleOverlayClick}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={overlayClassName}
      noentered={true}
      style={{
        overlay: {
          zIndex: 9997,
        },
        content: {
          display: 'inline-block',
          textAlign: 'left',
          top: '0px',
          maxWidth: '100%',
          cursor: 'default',
          outline: '0px',
          verticalAlign: 'bottom',
          zIndex: 50,
          borderRadius: '.16rem',
        },
      }}
      isOpen={isOpen}
      afterClose={handleAfterClose}
      handleClose={handleClose}
    >
      <animated.div style={styles}>{children}</animated.div>
    </DialogModal>
  );
}
