import { useTranslation, Trans } from 'react-i18next';
import ScrollWrapper from './scrollWrapper';
import ShareBtn from './shareBtn';

const Rule = ({ data, handleShowInviteFriends }) => {
  const { t } = useTranslation();

  return (
    <>
      {data && data.rule_explain ? (
        <div className="flex flex-col h-[8.62rem] rounded-normal border border-[var(--colour-6)] bg-[var(--colour-34)] m-[.8rem_.32rem_0_.32rem]">
          <p className="h-[1.2rem] leading-[1.2rem] text-[.36rem] font-[500] text-center border-b border-[var(--colour-6)] whitespace-nowrap overflow-hidden text-[var(--colour-48)]">
            {data.rule_explain.activity_title}
          </p>
          <ScrollWrapper className="w-full h-[7.8rem] wrap-break-word p-[.4rem_0_.4rem_.4rem] text-[.32rem] text-[var(--colour-44)]">
            <div
              dangerouslySetInnerHTML={{
                __html: data.rule_explain.activity_rule_explain,
              }}
            ></div>
          </ScrollWrapper>
        </div>
      ) : (
        <></>
      )}
      <div className="mx-[.32rem]">
        <ShareBtn className="w-full mx-[auto] mt-[.52rem] h-[.86rem]" onClick={handleShowInviteFriends} textKey={2} />
      </div>
    </>
  );
};

export default Rule;
