import { useEffect, useState } from 'react';
import cn from 'classnames';
import LoadingMoreWithText from '../../../../../components/loadingMoreWithText';
import { useTranslation } from 'react-i18next';
import { getShowMoney } from '../../../../../utils/helper';
import { shallowEqual, useSelector } from 'react-redux';

const ListItem = ({ depositNum, showedBonus, list = [], isCurrentDepositIndex = false, currentWagerIndex }) => {
  return (
    <div className="h-[.66rem] flex items-center text-[.2rem] font-[500] text-[var(--colour-44)] border-b border-[var(--colour-6)] p-[.12rem_0]">
      <div className="w-[50%] shrink-0 text-center">{depositNum}</div>
      <div className="w-[50%] shrink-0 text-center">{showedBonus}</div>
      {/* <div className="space-y-[.08rem] flex-1">
        {list.length > 0 &&
          list.map((item, index) => (
            <SubConfig
              key={index}
              isCurrent={isCurrentDepositIndex && index == currentWagerIndex}
              text1={item?.wagerNum}
              text2={item?.bonusNum}
            />
          ))}
      </div> */}
    </div>
  );
};

const Divider = () => {
  return <div className="w-[1px] h-[.4rem] bg-[var(--colour-6)] shrink-0"></div>;
};

const Item = ({ text = '', className = '' }) => {
  return (
    <div className={`text-[.2rem] font-[500] text-center shrink-0 px-[.04rem] text-[var(--colour-49)] ${className}`}>
      {text}
    </div>
  );
};

const ListHead = () => {
  const { t } = useTranslation();

  return (
    <div className="h-[.66rem] border-b border-[var(--colour-6)] flex items-center">
      <Item text={t('mystery_bonus.depost')} className="w-[49.9%]" />
      <Divider />
      {/* <Item text={t('mystery_bonus.wager')} className="w-[34.9%]" />
      <Divider /> */}
      <Item text={t('mystery_bonus.bonus')} className="w-[49.9%]" />
    </div>
  );
};

const BonusLevelList = ({ list: listAll = [], firstPageSize = 3, currentDepositIndex, currentWagerIndex }) => {
  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
    }),
    shallowEqual,
  );

  const pageSize = 3;

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowLoadMore, setIsShowLoadMore] = useState(false);

  useEffect(() => {
    const _list = listAll.slice(0, firstPageSize);
    setList(_list);
    setCurrentPage(1);
    setIsShowLoadMore(listAll.length > _list.length);
  }, [listAll, firstPageSize]);

  const handleLoadMore = () => {
    const _currentPage = currentPage + 1;
    setCurrentPage(_currentPage);

    const _list = listAll.slice(0, firstPageSize + pageSize * (_currentPage - 1));
    setList(_list);
    setIsShowLoadMore(listAll.length > _list.length);
  };

  const getShowedBonus = (from, to) => {
    return `${currency_symbol} ${getShowMoney(from, 2, 2)} ~ ${getShowMoney(to, 2, 2)}`;
  };

  return (
    <>
      <ListHead />

      {list.length > 0 &&
        list.map((item, index) => {
          return (
            <ListItem
              key={index}
              depositNum={`${currency_symbol} ${getShowMoney(item?.deposit_amount, 2, 2)}`}
              showedBonus={getShowedBonus(item?.min_display_amount, item?.max_display_amount)}
              // list={item?.list}
              // isCurrentDepositIndex={currentDepositIndex == index}
              // currentWagerIndex={currentWagerIndex}
            />
          );
        })}

      {isShowLoadMore && (
        <div className="pt-[.26rem]">
          <LoadingMoreWithText
            handleLoadMore={handleLoadMore}
            currentNum={list.length}
            total={listAll.length}
            type="mysteryBonus"
            i18nKey="home.loadmore3"
            isDiffTemplate={false}
          />
        </div>
      )}
    </>
  );
};

export default BonusLevelList;
