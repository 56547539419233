import { useState, useEffect } from 'react';
import Icon, { IconName } from './icon';
const ToastTip = ({ type = '', content = '' }) => {
  const [widthNum, setWidthNum] = useState(0);

  useEffect(() => {
    if (content) {
      setWidthNum(getRichTextWidth(content) + 10);
    }
  }, [content]);
  return (
    <div className="max-w-[6.86rem] bg-[var(--colour-21)] rounded-normal p-[.32rem_.16rem_.36rem_.32rem] flex items-center m-auto">
      <div className="w-[.4rem] h-[.4rem] mr-[.22rem]">
        {type == 'error' ? ( //红色感叹号
          <div className="w-[.4rem] h-[.4rem]">
            <Icon name={IconName.ErrorTip3} />
          </div>
        ) : type == 'err' ? ( //红色叉子
          <div className="w-[.4rem] h-[.4rem] bg-[var(--colour-25)] rounded-[50%] flex items-center justify-center">
            <div className="w-[.16rem] h-[.16rem] text-[var(--colour-43)]">
              <Icon name={IconName.Close2} />
            </div>
          </div>
        ) : type == 'success' ? ( //绿色对勾
          <div className="w-[.4rem] h-[.4rem]">
            <Icon name={IconName.SuccessTip2} />
          </div>
        ) : type == 'info' ? ( //黄色感叹号
          <div className="w-[.4rem] h-[.4rem]">
            <Icon name={IconName.ToastInfo} />
          </div>
        ) : null}
      </div>
      <div
        className="text-[var(--colour-43)] text-[.28rem] font-[500] leading-[.34rem] flex-1"
        style={{
          width: `${widthNum}px`,
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default ToastTip;

const getRichTextWidth = (content) => {
  // 创建一个临时的div元素用于渲染富文本
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.top = '-999999px';
  tempDiv.style.visibility = 'hidden';
  const tempSpan = document.createElement('span');
  tempSpan.className = 'inline-block whitespace-nowrap';
  tempSpan.style.fontSize = '0.28rem';
  tempSpan.style.fontWeight = '500';
  tempSpan.style.lineHeight = '0.34rem';
  tempSpan.innerHTML = content;
  tempDiv.appendChild(tempSpan);

  // 将临时元素添加到document.body中
  document.body.appendChild(tempDiv);

  // 获取宽度
  const width = tempDiv.offsetWidth;
  document.body.removeChild(tempDiv);
  // 清理：从document中移除临时元素
  return width;
};
