import { useState, useEffect } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Close from '../../close';
import Icon, { IconName } from '../../icon';
import ProgressBar from './progressBar';
import { AccountInfo } from '../../../api/index';
import { cutDecimal, getShowMoneyNumber, pointToComma } from '../../../utils/helper';
import { setUserObj } from '../../../redux/reducers/userSlice';
import { toast } from 'react-toastify';
// import globalConfig from '../../../constants/config';

const FormHeader = ({ title, handleClose, className = '', showType }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center  mt-[.3rem] ${className}`}>
        <div className=" text-[.32rem] font-[700] text-[var(--colour-48)]">{title}</div>
        <div className="  w-[.72rem] h-[.72rem] flex justify-center items-center rounded-small text-[var(--colour-2)] bg-[var(--colour-45)]">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full h-[1px]  mt-[.28rem] bg-[var(--colour-6)]"></div>
    </>
  );
};

const widthdrawQuestion = ({ handleClose, decimalPoit = 2 }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { websiteInitConfig, websiteInitData } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      websiteInitData: state.app.websiteInitData?.prompt,
    }),
    shallowEqual,
  );

  const [depositTips, setDepositTips] = useState({});

  useEffect(() => {
    if (websiteInitData) {
      setDepositTips(websiteInitData.walletDepositTips);
    }
  }, [websiteInitData]);

  useEffect(() => {
    AccountInfo().then((res) => {
      if (res.code == 0) {
        setData(res.result);
        dispatch(setUserObj(res.result.userObj));
      }
    });
  }, []);

  const [Data, setData] = useState({});

  return (
    <div className="  p-[0_.08rem_.4rem_.08rem]  m-[.3rem_.16rem] flex flex-col justify-between text-center relative  w-auto h-[auto] rounded-normal bg-[var(--colour-35)]">
      <div className="">
        <div className="px-[.32rem] ">
          <FormHeader title={t('deposit-withdraw.withdrawal_instructions')} handleClose={handleClose} />
        </div>

        <div className=" p-[.24rem]  m-[.3rem_0_0_0] w-[100%] rounded-normal">
          <div className="flex items-center px-[.2rem]">
            <div className="">
              <div className={` text-[.4rem] font-[700] text-left text-[var(--colour-36)]`}>
                {t('account.withdrawal')}
              </div>
              <div
                className={`w-[2.4rem]  h-[2.4rem]   mt-[.2rem] flex flex-col justify-center items-center rounded-normal bg-[var(--colour-35)] border border-[var(--colour-6)]`}
              >
                <span className={`  w-[.84rem] h-[.7rem] text-[var(--colour-37)]`}>
                  <Icon name={IconName.WalltMsg} />
                </span>
                <div className={`text-attention font-[700]   text-[.28rem] mt-[.06rem] text-[var(--colour-38)]`}>
                  {websiteInitConfig.website_info.currency_symbol || ''}{' '}
                  {Data?.withdrawInfo?.withdraw_available_balance
                    ? pointToComma(
                        Number(
                          cutDecimal(
                            Number(getShowMoneyNumber(Data.withdrawInfo.withdraw_available_balance)),
                            decimalPoit,
                          ),
                        ),
                      )
                    : 0}
                </div>
                <div className={`  text-[.2rem] mt-[.04rem] text-[var(--colour-44)]`}>{t('account.withdrawable')}</div>
              </div>
            </div>
            <div className=" ml-[.24rem] flex:1">
              <div className="flex items-center justify-between">
                <div className={`text-attention  text-[.24rem] text-left text-[var(--colour-20)]`}>
                  {Data?.withdrawInfo?.current_total_wager
                    ? pointToComma(Number(getShowMoneyNumber(Data.withdrawInfo.current_total_wager)))
                    : 0}
                  /
                  {Data?.withdrawInfo?.next_total_wager
                    ? pointToComma(
                        Number(cutDecimal(Number(getShowMoneyNumber(Data.withdrawInfo.next_total_wager))), decimalPoit),
                      )
                    : 0}
                </div>
              </div>
              <div className="flex  items-center mt-[.24rem] ">
                <ProgressBar
                  num={Data?.withdrawInfo?.current_total_wager || 0}
                  totalNum={Data?.withdrawInfo?.next_total_wager || 0}
                />
                <span className={`  text-[.2rem] ml-[.08rem] text-[var(--colour-10)]`}>
                  {Data?.withdrawInfo?.next_total_wager && Data?.withdrawInfo?.next_total_wager != 0
                    ? pointToComma(
                        Number(
                          (Number(Data?.withdrawInfo?.current_total_wager).toFixed(2) /
                            Number(Data?.withdrawInfo?.next_total_wager)) *
                            100,
                        ).toFixed(2),
                      )
                    : 0}
                  %
                </span>
              </div>
              <div className={` text-[.26rem] font-[500] text-left mt-[.2rem]  text-[var(--colour-44)]`}>
                {t('account.play_unlock')}
              </div>
              <div
                className={`w-[100%] mt-[.26rem]       text-center rounded-normal border border-[var(--colour-6)] bg-[var(--colour-35)]`}
              >
                <div className=" text-[.26rem] text-[var(--colour-44)]">{t('account.amount_unlocked')}</div>
                <div className="text-[.32rem]  font-[500] text-[var(--colour-38)]">
                  {websiteInitConfig.website_info.currency_symbol || ''}{' '}
                  {Data?.withdrawInfo?.locked_balance
                    ? pointToComma(
                        Number(cutDecimal(Number(getShowMoneyNumber(Data.withdrawInfo.locked_balance)), decimalPoit)),
                      )
                    : 0}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 底部信息 */}

        <div className="px-[.32rem]  text-secondary-content  text-[.24rem]">
          <div className="text-left  mt-[.24rem]" dangerouslySetInnerHTML={{ __html: depositTips?.title || '' }}></div>
        </div>
      </div>
    </div>
  );
};
export default widthdrawQuestion;
