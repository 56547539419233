import { useState } from 'react';

const useDropMenu = ({ defaultMenu = 0 } = {}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(defaultMenu);

  const onClickOutside = () => {
    setIsMenuVisible(false);
  };

  const onClickMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const onClickItem = (menu) => {
    setCurrentMenu(menu);
    setIsMenuVisible(false);
  };

  return {
    isMenuVisible,
    currentMenu,
    setCurrentMenu,
    setIsMenuVisible,
    onClickMenu,
    onClickItem,
    onClickOutside,
  };
};

export default useDropMenu;
