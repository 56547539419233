import BannerPc1 from '../../../../components/home/bannerPc1';
import BannerPc4 from '../../../../components/home/bannerPc4';
import BannerMobile from '../../../../components/home/bannerMobile';
import BannerPc2 from '../../../../components/home/bannerPc2';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
import ReactDOM from 'react-dom';
import { ResponsiveMode } from '../../../../redux/reducers/appSlice';

export default function HomeSwiperBanner({ swiperList = [], bannerType }) {
  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: state.app.responsiveMode,
    }),
    shallowEqual,
  );

  const paddingList = (list = [], n = 1) => {
    if (list.length == 0 || list.length >= n) {
      return list;
    } else {
      const num = Math.ceil((n - list.length) / list.length);
      const list2 = Array(num)
        .fill()
        .map(() => list)
        .flat();
      const result = [...list, ...list2].slice(0, n);
      return result;
    }
  };

  return (
    <div className={`w-full m-auto`}>
      {swiperList.length > 0 ? <BannerMobile swiperList={swiperList} bannerStyle={bannerType} /> : null}
    </div>
  );
}
