import DragBtnRight from '../../dragBtnRight';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../utils/localePath';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getShowMoney, isLogin } from '../../../utils/helper';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../redux/reducers/appSlice';
import cn from 'classnames';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useEffect } from 'react';
import { cmsImgUrl } from '../../../utils/helper';
import Claimbtn from '../components/claimbtn';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';

const MysteryBonus = ({ isdrag = false, index = null, EntranceDisplayLocation, isFromGame = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imgpath, setImgpath] = useStateIfMounted('');
  const { currency_symbol, activityData, activityStatus } = useSelector(
    (state) => ({
      activityStatus: state.app.activityStatus,
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );
  const handleClick = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Slideshow);

    if (!isLogin()) {
      return dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }

    navigate(toLocalePath(`/mysterybonus`));
  };

  const getActivityImage = (arr, index) => {
    const item = arr.find((item) => item.index == index);
    return item ? cmsImgUrl(item.icon) : '';
  };

  useEffect(() => {
    if (activityStatus != null) {
      const activityWheelShowIconV2 = Array.from(activityStatus.activity_wheel_show_icon_v2 || []);
      const imgpath = getActivityImage(activityWheelShowIconV2, 12);
      setImgpath(imgpath);
    }
  }, [activityStatus]);

  const rewardAmount = activityData?.activity_mystery_bonus?.list?.[0]?.reward_amount || 0;
  const rewardAmountExists = activityData?.activity_mystery_bonus?.list?.[0]?.reward_amount_exists || false; // 是否有可领取的奖励

  return (
    <>
      <DragBtnRight
        className1={`w-[1.5rem] h-[1.5rem]`}
        className={`activty-absolute-bottom-${index + 1} }  ${index != null && EntranceDisplayLocation == '0' ? 'activity-item' : ''} ${EntranceDisplayLocation == '0' ? 'right-[.08rem]' : 'left-[.08rem]'}`}
        onClick={handleClick}
        isLimitedInParentHeight={false}
        isdrag={isdrag}
      >
        <div className={cn('flex items-center justify-center w-[1.5rem] h-[1.5rem]')}>
          <div className="scale-[.5]">
            <div className={cn('relative w-[3rem] h-[3rem]')}>
              <img src={imgpath} alt="" className="not-drag absolute left-0 top-0 w-full" />

              {rewardAmountExists ? (
                <Claimbtn
                  txtColor="mystreyBonus_title"
                  className="rounded-[.05rem] bg-mysteryBonus_btn flex items-center justify-center absolute abs-x-center bottom-[0]"
                >
                  {t('guideWheel.claim')}
                </Claimbtn>
              ) : null}

              <div
                className={cn(
                  'absolute w-full flex flex-col items-center abs-x-center bottom-[.75rem] leading-[.42rem] font-[800] text-[.4rem]',
                )}
              >
                <div className="mysteryBonus_text" data-text={`${currency_symbol}${getShowMoney(rewardAmount)}`}>
                  {currency_symbol}
                  {getShowMoney(rewardAmount)}
                </div>
                <div className="mysteryBonus_text" data-text={t('mystery_bonus.max')}>
                  {t('mystery_bonus.max')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DragBtnRight>
    </>
  );
};

export default MysteryBonus;
