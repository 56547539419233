import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { get } from 'lodash';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';

import Icon, { IconName } from '../../icon';
import DialogModal from '../index';
import LoginRegister from '../../login/entrance/index';
import MysteryBonusGameList from '../../../views/mysteryBonus/normal/template001/components/mysteryBonusGameList';
import ImageLoad from '../../imageLoad';

import useRouterHelper from '../../../hooks/useRouterHelper';
import { useGlobalNavigate } from '../../../contexts/navigateContext';
import { toLocalePath } from '../../../utils/localePath';
import { cmsImgUrl, isLogin, getShowMoney } from '../../../utils/helper';
import { ActivityAllReq } from '../../../api';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';

const MysteryBonus = ({ handleClose = () => {}, data }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { userObj, currency_symbol, mystreyBonusPopupShowStatus } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      mystreyBonusPopupShowStatus: state.app.mystreyBonusPopupShowStatus,
    }),
    shallowEqual,
  );

  const { handleClickWallet } = useRouterHelper();

  const [showLogin, setShowLogin] = useState(false);
  const [isShowGamelist, setIsShowGamelist] = useState(false);
  const [mysteryBonusData, setMysteryBonusData] = useState(data);

  useEffect(() => {
    let _timer = null;
    if (mystreyBonusPopupShowStatus) {
      _timer = window.setTimeout(() => {
        window.clearTimeout(_timer);
        handleClose();
      }, 500);
    }
    return () => window.clearTimeout(_timer);
  }, [mystreyBonusPopupShowStatus]);

  const updateData = () => {
    const params = {
      data_type: JSON.stringify(['activity_mystery_bonus']),
    };
    ActivityAllReq(params).then((res) => {
      if (res.code == 0) {
        if (
          res.result.activity_mystery_bonus &&
          res.result.activity_mystery_bonus.list &&
          res.result.activity_mystery_bonus.list.length > 0
        ) {
          setMysteryBonusData(res.result.activity_mystery_bonus.list[0]);
        } else {
          handleClose();
        }
      }
    });
  };

  const handleShowLoginPopup = () => {
    setShowLogin(true);
  };

  const handleCloseGamelist = () => {
    setIsShowGamelist(false);
  };

  const handleGoGamelist = () => {
    setIsShowGamelist(true);
  };

  const handleClickWalletBtn = () => {
    if (isLogin()) {
      handleClickWallet();
    } else {
      handleShowLoginPopup();
    }
  };

  //充值是否达到最大挡位
  const rechargeToMaximum = () => {
    return mysteryBonusData.deposit_amount >= mysteryBonusData.deposit_amount_max;
  };

  return (
    <>
      {/* 登录弹窗  */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowLogin(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showLogin}
      >
        <LoginRegister
          loginOrSignUp={1}
          handleClose={() => {
            setShowLogin(false);
          }}
        />
      </DialogModal>

      {/* 游戏列表弹窗 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '52',
            borderRadius: '10px',
          },
        }}
        isOpen={isShowGamelist}
        handleClose={() => {
          setIsShowGamelist(false);
        }}
      >
        <MysteryBonusGameList
          handleClose={handleCloseGamelist}
          handleBack={handleCloseGamelist}
          activity_id={mysteryBonusData.id}
        />
      </DialogModal>

      <div className="w-[6.86rem] p-[.4rem_.24rem] rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-52)]">
        <div className="text-[var(--colour-48)] mb-[.4rem] text-[.32rem] font-[600] leading-[.38rem] text-center">
          {t('mystery_bonus.mystery_bonus_popup_title')}
        </div>
        <div className="rounded-[.16rem] bg-[var(--colour-35)] mb-[.4rem] overflow-hidden">
          <div className="mb-[.24rem]">
            <ImageLoad src={cmsImgUrl(mysteryBonusData?.rule_explain?.activity_info_img || '')} />
          </div>
          {userObj == null || !mysteryBonusData.sign_up_status ? (
            <div className="px-[.16rem] pb-[.2rem]">
              <div className="mb-[.24rem] text-[var(--colour-36)] text-[.36rem] font-[500] leading-[.44rem] text-center">
                {t('mystery_bonus.mystery_bonus_popup_tip1')}
              </div>

              <div className="mb-[.36rem] p-[.2rem_.2rem_.12rem_.2rem] rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-68)] flex flex-col items-center">
                <div
                  className="min-w-[2.12rem] h-[.64rem] px-[.1rem] rounded-[.1rem] bg-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none text-[.28rem] font-[500] leading-[.64rem] text-[var(--colour-2)] text-center mb-[.12rem]"
                  onClick={() => {
                    handleClickWalletBtn();
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Window_Deposit1);
                  }}
                >
                  {t('deposit-withdraw.deposit')}
                </div>
                <div className="flex items-center">
                  <div className="mr-[.04rem] text-[.28rem] font-[500] leading-[.44rem] text-[var(--colour-40)]">
                    {t('mystery_bonus.mystery_bonus_popup_restante')}
                  </div>
                  <div className="text-[.36rem] font-[700] leading-[.44rem] text-[var(--colour-38)]">
                    <Countdown
                      date={mysteryBonusData?.can_sign_up_end_second * 1000 || 0}
                      onComplete={() => {
                        handleClose();
                      }}
                      renderer={({ days, hours, minutes, seconds, completed }) => {
                        if (days > 0) {
                          hours += days * 24;
                        }
                        return !completed ? (
                          <span className="">
                            <span>{String(hours).padStart(2, '0')}:</span>
                            <span>{String(minutes).padStart(2, '0')}:</span>
                            <span>{String(seconds).padStart(2, '0')}</span>
                          </span>
                        ) : (
                          <span className="">
                            <span>00:00:00</span>
                          </span>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mb-[.06rem]">
                <div className="text-[.32rem] font-[500] leading-[.38rem] text-[var(--colour-40)]">
                  {t('mystery_bonus.mystery_bonus_popup_tip2')}
                </div>
                <div className="text-[.48rem] font-[700] text-[var(--colour-36)] leading-[.58rem]">
                  {currency_symbol}
                  {getShowMoney(mysteryBonusData.reward_amount || 0, 2, 2)}
                </div>
              </div>
            </div>
          ) : mysteryBonusData.is_last_settle_status ? (
            <div className="px-[.16rem] pb-[.42rem] min-h-[4.5rem] flex flex-col justify-center">
              <div className="mb-[.36rem] text-[var(--colour-36)] text-[.36rem] font-[500] leading-[.44rem] text-center">
                {t('mystery_bonus.mystery_bonus_popup_tip3')}
              </div>

              <div className="p-[.22rem_.34rem_.14rem_.34rem] text-center rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-68)] leading-[.38rem]">
                <span className="text-[.28rem] font-[500] text-[var(--colour-40)]">
                  {t('mystery_bonus.mystery_bonus_popup_tip4')}
                </span>
              </div>
            </div>
          ) : mysteryBonusData.sign_up_status ? (
            <div className="px-[.16rem] pb-[.24rem]">
              {mysteryBonusData.reward_amount_exists ? (
                <div className="mb-[.24rem] text-[.36rem] font-[500] leading-[.44rem] text-[var(--colour-36)] text-center">
                  {t('mystery_bonus.mystery_bonus_popup_tip5')}
                </div>
              ) : null}

              <div className="mb-[.3rem] p-[.22rem_.2rem_.22rem_.12rem] rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-68)]">
                <div
                  className={`${rechargeToMaximum() ? 'text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-40)] mb-[.12rem] text-center' : 'text-[.2rem] font-[500] leading-[.24rem] text-[var(--colour-40)] mb-[.12rem]'}`}
                >
                  {rechargeToMaximum() ? (
                    t('mystery_bonus.mystery_bonus_popup_tip6')
                  ) : (
                    <Trans
                      t={t}
                      i18nKey="mystery_bonus.mystery_bonus_popup_tip7"
                      components={[
                        <span className="text-[var(--colour-36)]" key={0}></span>,
                        <span className="text-[var(--colour-36)]" key={1}></span>,
                      ]}
                      values={{
                        val: `${currency_symbol} ${getShowMoney(
                          BigNumber(mysteryBonusData.deposit_amount_upgrade || 0)
                            .minus(mysteryBonusData.deposit_amount || 0)
                            .toFixed(),
                        )}`,
                        val2: `${currency_symbol} ${getShowMoney(mysteryBonusData.max_display_amount || 0)}`,
                      }}
                    />
                  )}
                </div>
                <div className="flex">
                  <div className="flex-1 pt-[.04rem] min-h-[.48rem]">
                    <div className="h-[.18rem] rounded-[.1rem] bg-[var(--colour-34)] relative overflow-hidden mb-[.02rem]">
                      <div
                        className="absolute left-[0rem] h-full rounded-[.1rem] bg-[var(--colour-29)]"
                        style={{
                          width: rechargeToMaximum()
                            ? '100%'
                            : BigNumber(
                                BigNumber(mysteryBonusData.deposit_amount || 0)
                                  .div(mysteryBonusData.deposit_amount_upgrade || 0)
                                  .toFixed(),
                              )
                                .times(100)
                                .toFixed() + '%',
                        }}
                      ></div>
                      <div className="absolute inset-0 text-[.2rem] font-[500] leading-[.18rem] text-[var(--colour-56)] text-center mysteryBonusPopup_text">
                        {rechargeToMaximum() ? (
                          t('mystery_bonus.mystery_bonus_popup_max')
                        ) : (
                          <>
                            {currency_symbol}
                            {getShowMoney(mysteryBonusData.deposit_amount || 0)}/{currency_symbol}
                            {getShowMoney(mysteryBonusData.deposit_amount_upgrade || 0)}
                          </>
                        )}
                      </div>
                    </div>
                    {Number(
                      BigNumber(mysteryBonusData.deposit_amount || 0)
                        .div(mysteryBonusData.deposit_amount_upgrade || 0)
                        .toFixed(),
                    ) >= 0.9 && !rechargeToMaximum() ? (
                      <div className="text-[.2rem] font-[500] leading-[.24rem] text-[var(--colour-36)] text-center">
                        {t('mystery_bonus.mystery_bonus_popup_tip8')}
                      </div>
                    ) : null}
                  </div>
                  {rechargeToMaximum() ? null : (
                    <div
                      className="shrink-0 ml-[.12rem] min-w-[1.7rem] h-[.48rem] px-[.1rem] rounded-[.1rem] bg-[var(--colour-1)] text-[.24rem] font-[500] leading-[.48rem] text-[var(--colour-2)] text-center cursor-pointer active:scale-[.95] transition-all select-none"
                      onClick={() => {
                        handleClickWalletBtn();
                        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Window_Deposit2);
                      }}
                    >
                      {t('deposit-withdraw.deposit')}
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-[.16rem] flex justify-center">
                <div
                  className="min-w-[2.64rem] min-h-[.64rem] px-[.26rem] rounded-[.1rem] bg-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none text-[.28rem] font-[500] leading-[.64rem] text-[var(--colour-2)] flex items-center justify-center"
                  onClick={() => {
                    handleGoGamelist();
                  }}
                >
                  {t('mystery_bonus.mystery_bonus_popup_gotoplay')}
                </div>
              </div>
              <div className="text-center leading-[.48rem]">
                <span className="text-[.24rem] font-[500] text-[var(--colour-40)] mr-[.04rem]">
                  {t('mystery_bonus.mystery_bonus_popup_tip9')}
                </span>
                <span className="text-[.36rem] font-[700] text-[var(--colour-38)]">
                  <Countdown
                    date={mysteryBonusData?.settle_second * 1000 || 0}
                    onComplete={() => {
                      updateData();
                    }}
                    renderer={({ days, hours, minutes, seconds, completed }) => {
                      if (days > 0) {
                        hours += days * 24;
                      }
                      return !completed ? (
                        <span className="">
                          <span>{String(hours).padStart(2, '0')}:</span>
                          <span>{String(minutes).padStart(2, '0')}:</span>
                          <span>{String(seconds).padStart(2, '0')}</span>
                        </span>
                      ) : (
                        <span className="">
                          <span>00:00:00</span>
                        </span>
                      );
                    }}
                  />
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className="h-[.92rem] rounded-[.16rem] bg-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none text-[.44rem] font-[600] leading-[.92rem] text-[var(--colour-2)] text-center"
          onClick={() => {
            if (isLogin()) {
              navigate(toLocalePath('/mysterybonus'));
            } else {
              handleShowLoginPopup();
            }
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Window_Details);
          }}
        >
          {t('mystery_bonus.mystery_bonus_popup_details')}
        </div>
        <div
          className="active:scale-[.95] transition-all select-none w-[.6rem] h-[.6rem] rounded-[50%] flex justify-center items-center absolute left-[50%] bottom-[-0.92rem] translate-x-[-50%] cursor-pointer"
          onClick={handleClose}
        >
          <Icon name={IconName.SignInClose} />
        </div>
      </div>
    </>
  );
};
export default MysteryBonus;
