import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Icon, { IconName } from '../../../icon';
import Button from '../../../button';
import { setNeedLogout } from '../../../../redux/reducers/appSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';

export default function Logout({ onClick = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.SM_Click_LogoutButton);
    dispatch(setNeedLogout());
    onClick();
  };
  return (
    <Button className="flex items-center justify-center space-x-[.08rem] mr-[.1rem]" onClick={handleLogout}>
      <span className="w-[.4rem] h-[.4rem]">
        <span className="text-[var(--colour-12)]">
          <Icon name={IconName.Logout}></Icon>
        </span>
      </span>
      <span className="font-[500] text-[.2rem] leading-[.24rem] text-[var(--colour-14)]">
        {t('home.userInfo_logout')}
      </span>
    </Button>
  );
}
