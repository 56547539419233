import React, { useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const Button = forwardRef(({ index, className, onClick, children, disabled = false, style = {} }, ref) => {
  const dispatch = useDispatch();

  return (
    <button ref={ref} key={index} disabled={disabled} className={className} onClick={onClick} style={style}>
      {children}
    </button>
  );
});

export default Button;
