import { serviceStat as request } from '../utils/request';

// 统计接口
export function analyticsReq(params = null) {
  return request({
    url: '/stat',
    method: 'post',
    data: {
      ...params,
    },
  });
}
