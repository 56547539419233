import { useEffect, useState } from 'react';
import RegisterPage1 from './registerPage1';
import RegisterPage2 from './registerPage2';
import { shallowEqual, useSelector } from 'react-redux';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { getRegisterConfig, getTemplateId } from '../../../utils/helper';
import RegisterPage3 from './registerPage3/index';

export default function Index({ handleClose, changeType = () => {}, isShowTitle = true }) {
  const templateId = getTemplateId();
  console.log('tid', templateId);

  return templateId == 'd-template003' ? (
    <RegisterPage3
      handleClose={() => {
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseRegLogButton);
        handleClose();
      }}
      changeType={changeType}
    />
  ) : getRegisterConfig(2) != null ? (
    <RegisterPage2
      isShowTitle={isShowTitle}
      handleClose={() => {
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseRegLogButton);
        handleClose();
      }}
      changeType={changeType}
    />
  ) : (
    <RegisterPage1
      handleClose={() => {
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseRegLogButton);
        handleClose();
      }}
      changeType={changeType}
    />
  );
}
