import DialogModal from '../dialogModal/';
export default function FullPagePopup({
  shouldCloseOnOverlayClick = true,
  overlayClassName = '',
  handleOverlayClick = () => {},
  isOpen = true,
  handleAfterClose = () => {},
  handleClose = () => {},
  children,
}) {
  return (
    <DialogModal
      turnOnAnimation={true}
      handleOverlayClick={handleOverlayClick}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={overlayClassName}
      noentered={true}
      style={{
        overlay: {
          zIndex: 9998,
        },
        content: {
          display: 'inline-block',
          textAlign: 'left',
          top: '0px',
          maxWidth: '100%',
          cursor: 'default',
          outline: '0px',
          verticalAlign: 'bottom',
          zIndex: 50,
          borderRadius: '.16rem',
        },
      }}
      isOpen={isOpen}
      afterClose={handleAfterClose}
      handleClose={handleClose}
    >
      <div className="adaptive-width h-[var(--app-height)] bg-[var(--colour-35)]">{children}</div>
    </DialogModal>
  );
}
