import { GetWalletSignStrReq, LoginbywalletReq } from '../api/index-auth';

import store from '../redux/store';
import { getWalletAddress, sign } from './chainUtils';

export function walletLogin() {
  const state = store.getState();
  const invite_code = state.user.inviteCode || '';
  const osType = state.user.osType || '';
  const advertisingId = state.user.advertisingId || '';

  return new Promise(async (resolve, reject) => {
    try {
      const resp = await GetWalletSignStrReq();

      if (resp.code !== 0) {
        throw resp.msg;
      }

      const signPassword = resp.result.signPassword;

      const [walletaddress] = await getWalletAddress();
      if (!walletaddress) {
        throw 'get wallet address fail';
      }

      const wallSignResult = await sign(signPassword, walletaddress);
      if (wallSignResult === null) {
        throw 'wallet sign fail';
      }

      const params = {
        wallet: walletaddress,
        signStr: signPassword,
        sign: wallSignResult,
        invite_code,
        osType,
        advertisingId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      console.log('params', params);
      resolve(await LoginbywalletReq(params));
    } catch (e) {
      reject(e);
    }
  });
}
