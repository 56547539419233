import NTemplate001 from './normal/template001';
import DTemplate001 from './design/template001';
import DTemplate002 from './design/template002';
import DTemplate003 from './design/template003';
import { getTemplateId } from '../../utils/helper';

const RenderTemplte = () => {
  switch (getTemplateId()) {
    case 'n-template001':
    case 'n-template002':
      return <NTemplate001 />;
    case 'd-template001':
      return <DTemplate001 />;
    case 'd-template002':
      return <DTemplate002 />;
    case 'd-template003':
      return <DTemplate003 />;
    default:
      return null;
  }
};

export default function () {
  return <RenderTemplte />;
}
