import { useTranslation } from 'react-i18next';
import { isLogin, langIconName, getShowMoney, isLightTheme } from '../../utils/helper';
import useHandleLogic from '../../hooks/useHandleLogic';
import useRouterHelper from '../../hooks/useRouterHelper';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Icon, { IconName } from '../icon';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import cn from 'classnames';
import LimitClickWrapper from '../limitClickWrapper';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../utils/localePath';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../redux/reducers/appSlice';
import MenuBtn from './MenuBtn';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const UserDeposit = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();
  const { userObj } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
    }),
    shallowEqual,
  );

  const { handleGoLogin, handleGoRegister, hanldeRefurbishBalance, balanceLoad } = useHandleLogic();
  const { handleClickWallet } = useRouterHelper();

  const clickDeposit = () => {
    if (isLogin()) {
      handleClickWallet();
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };
  const clickWithDraw = () => {
    if (isLogin()) {
      handleClickWallet(2);
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };
  const clickVip = () => {
    if (isLogin()) {
      navigate(toLocalePath('/promotion/vip'));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };

  return (
    <div className="flex items-center justify-between">
      {userObj != null ? (
        <div className="flex flex-col items-start">
          <div
            className={cn('flex items-center', {
              'mb-[.05rem]': isLightTheme(),
              'mb-[.24rem]': !isLightTheme(),
            })}
          >
            {isLightTheme() ? (
              <div
                className="min-w-[.56rem] h-[.22rem] rounded-[.04rem] bg-[var(--colour-59)] px-[.08rem] mr-[.1rem] account_vip text-[.16rem] font-[600] leading-[.2rem]"
                data-text={`VIP ${userObj.vip_level}`}
              >
                VIP {userObj.vip_level}
              </div>
            ) : (
              <div className="h-[.24rem] bg-[var(--colour-1)] rounded-[.04rem] text-[.16rem] leading-[.24rem] font-[500] px-[.06rem] mr-[.08rem] text-[var(--colour-2)]">
                <div>
                  {t('home.drawer_vip2')} {userObj.vip_level}
                </div>
              </div>
            )}

            <div className="text-[var(--colour-10)] text-[.2rem] font-[500] leading-[.24rem] mr-[.08rem]">
              {userObj?.username}
            </div>
            <div
              className={cn('text-[var(--colour-1)]', {
                'w-[.28rem] h-[.28rem]': isLightTheme(),
                'w-[.24rem] h-[.24rem]': !isLightTheme(),
              })}
              onClick={() => {
                copy(`${userObj?.username || ''}`);
                toast.success(t('home.toast006'), {
                  containerId: 'global',
                });
              }}
            >
              <Icon name={IconName.Copy4} />
            </div>
          </div>
          {isLightTheme() ? (
            <div className="min-w-[1.46rem] h-[.4rem] rounded-[.5rem] bg-[var(--colour-22)] border border-solid border-[var(--colour-1)] flex items-center pl-[.08rem] pr-[.06rem]">
              <div className="w-[.32rem] h-[.32rem] mr-[.08rem] block">{<Icon name={IconName.HeaderCountry} />}</div>
              <div className="text-[.26rem] font-[400] leading-[.32rem] text-[#FF7A00] underline mr-[.08rem]">
                {balanceLoad ? t('home.loading') : getShowMoney(userObj?.balance, 2, 2)}
              </div>
              <LimitClickWrapper
                className="w-[.24rem] h-[.24rem] ml-[.04rem] text-[var(--colour-1)]"
                onClick={() => {
                  hanldeRefurbishBalance();
                }}
              >
                <Icon name={IconName.Frshen} />
              </LimitClickWrapper>
            </div>
          ) : (
            <div className="h-[.44rem] rounded-[.5rem] bg-[var(--colour-22)] border border-solid border-[var(--colour-1)] flex justify-between items-center px-[.14rem]">
              <div className="w-[.24rem] h-[.24rem] mr-[.04rem] block">{<Icon name={langIconName('pt')} />}</div>
              <div className="text-[.24rem] font-[400] leading-[.3rem] text-[var(--colour-23)]">
                {balanceLoad ? t('home.loading') : getShowMoney(userObj?.balance, 2, 2)}
              </div>
              <LimitClickWrapper
                className="w-[.24rem] h-[.24rem] ml-[.04rem] text-[var(--colour-1)]"
                onClick={() => {
                  hanldeRefurbishBalance();
                }}
              >
                <Icon name={IconName.Refresh} />
              </LimitClickWrapper>
            </div>
          )}
        </div>
      ) : (
        <div className="text-[.2rem] font-[500] leading-[.24rem] flex items-center">
          <div className="text-[var(--colour-1)] mr-[.08rem]" onClick={handleGoLogin}>
            {t('home.login')}
          </div>
          <div className="text-[var(--colour-10)] mr-[.08rem]">{t('home.or')}</div>
          <div className="text-[var(--colour-1)]" onClick={handleGoRegister}>
            {t('home.register_now')}
          </div>
        </div>
      )}
      {isLightTheme() ? (
        <div className="flex items-center">
          <div className="pt-[.18rem] pb-[.1rem] flex flex-col items-center min-w-[1.08rem]" onClick={clickDeposit}>
            <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
              <Icon name={IconName.Deposit2} />
            </div>
            <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)]">
              {t('deposit-withdraw.deposit')}
            </div>
          </div>

          <div className="pt-[.18rem] pb-[.1rem] flex flex-col items-center min-w-[1.08rem]" onClick={clickWithDraw}>
            <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
              <Icon name={IconName.Withdraw2} />
            </div>
            <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)]">
              {t('deposit-withdraw.withdraw')}
            </div>
          </div>

          <div className="pt-[.18rem] pb-[.1rem] flex flex-col items-center min-w-[1.08rem]" onClick={clickVip}>
            <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
              <Icon name={IconName.Vip3} />
            </div>
            <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)]">
              {t('home.drawer_vip2')}
            </div>
          </div>
          <MenuBtn />
        </div>
      ) : (
        <div className="flex items-center">
          <div className="pt-[.13rem] pb-[.05rem] flex flex-col items-center min-w-[1.08rem]" onClick={clickDeposit}>
            <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
              <Icon name={IconName.Deposit} />
            </div>
            <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)]">
              {t('deposit-withdraw.deposit')}
            </div>
          </div>

          <div className="pt-[.13rem] pb-[.05rem] flex flex-col items-center min-w-[1.08rem]" onClick={clickWithDraw}>
            <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
              <Icon name={IconName.Withdraw} />
            </div>
            <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)]">
              {t('deposit-withdraw.withdraw')}
            </div>
          </div>

          <div className="pt-[.13rem] pb-[.05rem] flex flex-col items-center min-w-[1.08rem]" onClick={clickVip}>
            <div className="w-[.52rem] h-[.52rem] text-[var(--colour-1)]">
              <Icon name={IconName.Vip2} />
            </div>
            <div className="text-[.16rem] font-[500] leading-[.2rem] text-[var(--colour-10)]">
              {t('home.drawer_vip2')}
            </div>
          </div>
          <MenuBtn />
        </div>
      )}
    </div>
  );
};
export default UserDeposit;
