import { serviceNew as request } from '../utils/requestNew';

// 充值记录
export function PaymentDeposit(params = null) {
  return request({
    url: '/payment/deposit/index',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// 充值记录详情
export function PaymentDepositView(params = null) {
  return request({
    url: '/payment/deposit/view',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//提现配置接口
export function PaymentAccWithdrawConfig(params = null) {
  return request({
    url: '/payment/acc/withdraw/config',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//设置提现密码
export function PaymentWithdrawSetPassword(params = null) {
  return request({
    url: '/payment/withdraw/setPassword',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//提现账户列表
export function PaymentAccWithdrawBrlAccountList(params = null) {
  return request({
    url: '/payment/acc/withdraw/brl/accountList',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//验证提现密码
export function PaymentWithdrawVerifyPassword(params = null) {
  return request({
    url: '/payment/withdraw/verifyPassword',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//提现方式列表
export function PaymentWithdrawMethodList(params = null) {
  return request({
    url: '/payment/withdraw/methodList',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//提现账户添加
export function PaymentAccWithdrawAddAccount(params = null) {
  return request({
    url: '/payment/acc/withdraw/brl/addAccount',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//设置默认提现账户
export function PaymentAccWithdrawSetDefaultAccount(params = null) {
  return request({
    url: '/payment/acc/withdraw/brl/setAccountDefault',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//提现提交
export function PaymentAccWithdrawSubmit(params = null) {
  return request({
    url: '/payment/acc/withdraw/brl/submitByAmount',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//提现记录
export function PaymentWithdrawIndex(params = null) {
  return request({
    url: '/payment/withdraw/index',
    method: 'post',
    data: {
      ...params,
    },
  });
}

//提现记录详情
export function PaymentWithdrawView(params = null) {
  return request({
    url: '/payment/withdraw/view',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// BRL提现配置
export function PaymentDirectWithdrawConfig(params = null) {
  return request({
    url: '/payment/direct/withdraw/config',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// BRL提现提交(已废弃)
export function PaymentDirectWithdrawBrlSubmit(params = null) {
  return request({
    url: '/payment/direct/withdraw/brl/submit',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// BRL提现提交
export function PaymentDirectWithdrawBrlSubmitByAmount(params = null) {
  return request({
    url: '/payment/direct/withdraw/brl/submitByAmount',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// BRL提现配置(返回密码配置信息)
export function PaymentDirectWithdrawConfigWithPassword(params = null) {
  return request({
    url: '/payment/direct/withdraw/configWithPassword',
    method: 'post',
    data: {
      ...params,
    },
  });
}

// BRL提现提交
export function PaymentDirectWithdrawBrlSubmitWithPassword(params = null) {
  return request({
    url: '/payment/direct/withdraw/brl/submitWithPassword',
    method: 'post',
    data: {
      ...params,
    },
  });
}
