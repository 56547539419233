import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { List } from 'react-vant';
import DialogModal from '../../../../components/dialogModal';
import Close from '../../../../components/close';
import Icon, { IconName } from '../../../../components/icon';
import { GetDirectFinanceList } from '../../../../api';
import Loading3 from '../../../../components/loading3';
import NoData from '../../../../components/noData';
import { getDateFormatStr5, getShowMoney } from '../../../../utils/helper';

function DirectFinanceDetails({
  setShowVerify,
  showVerify,
  id = '',
  paramStartDate = '',
  paramEndDate = '',
  handleClickModel = () => {},
}) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
      >
        <DetailsInner
          handleClose={() => setShowVerify(false)}
          id={id}
          paramStartDate={paramStartDate}
          paramEndDate={paramEndDate}
          handleClickModel={handleClickModel}
        />
      </DialogModal>
    </>
  );
}
function DetailsInner({ handleClose, id, paramStartDate, paramEndDate, handleClickModel = () => {} }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    startDate: paramStartDate,
    endDate: paramEndDate,
    uid: id,
    mode: 2,
  });

  const [list, setList] = useState([]);
  const [finished, setFinished] = useState(false);
  const [uid, setUid] = useState('');

  useEffect(() => {
    setUid(id);
  }, [id]);
  useEffect(() => {
    if (uid) {
      setLoading(true);
      const newParams = {
        page: 1,
        page_size: params.pageSize,
        start_date: paramStartDate,
        end_date: paramEndDate,
        uid: uid,
        mode: 2,
      };

      GetDirectFinanceList(newParams)
        .then((res) => {
          if (res.code == 0) {
            setList(res.data.list);
            if (res.data.list.length < params.pageSize) {
              setFinished(true);
            } else {
              setFinished(false);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const loadData = async () => {
    try {
      setParams({ ...params, page: params.page + 1 });
      const currentPage = params.page + 1;
      const newParams = {
        page: currentPage,
        page_size: params.pageSize,
        start_date: paramStartDate,
        end_date: paramEndDate,
        uid: uid,
        mode: 2,
      };
      let res = await GetDirectFinanceList(newParams);
      if (res.code == 0) {
        if (res.data && res.data.list.length > 0) {
          setList([...list, ...res.data.list]);
        }

        if (res.data && res.data.list.length < params.pageSize) {
          setFinished(true);
        } else {
          setFinished(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleClick = (uid) => {
    handleClickModel(uid);
  };

  return (
    <div className="bg-[var(--colour-35)] border border-solid border-[var(--colour-6)] shadow-[0_.12rem_.12rem_0_rgba(0,0,0,0.1)] w-[6.7rem] h-[10.1rem] rounded-normal p-[.2rem_.26rem_.6rem_.2rem] relative flex flex-col">
      <div
        className="w-[.62rem] h-[.62rem] rounded-[50%] border border-[var(--colour-56)] flex items-center justify-center absolute bottom-[-0.86rem] left-[50%] translate-x-[-50%]"
        onClick={() => handleClose()}
      >
        <Close className="!w-[.62rem] !h-[.62rem] !text-[var(--colour-56)]" />
      </div>
      <div className="text-[.28rem] font-[600] leading-[.34rem] text-[var(--colour-10)] text-center mb-[.24rem]">
        {t('refer.subordinados_de')} {uid}
      </div>
      <div className="flex items-center text-[.2rem] font-[500] leading-[.24rem] mb-[.22rem]">
        <div className="text-[var(--colour-44)] mr-[.12rem]">{t('refer.data')}</div>
        <div className="text-[var(--colour-38)]">
          {paramStartDate.slice(6)}/{paramStartDate.slice(4, 6)}/{paramStartDate.slice(0, 4)}~{paramEndDate.slice(6)}/
          {paramEndDate.slice(4, 6)}/{paramEndDate.slice(0, 4)}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto scrollbar-none">
        {loading ? (
          <Loading3 className1="w-full h-full flex items-center justify-center"></Loading3>
        ) : (
          <>
            {list && list.length > 0 ? (
              <List finished={finished} onLoad={loadData} offset={0} loadingText={<Loading3 className1="w-full" />}>
                {list.map((item, index) => {
                  return <DetailsItem key={index} showBg={index % 2 == 0} data={item} handleClick={handleClick} />;
                })}
              </List>
            ) : (
              <NoData className="w-full h-full" />
            )}
          </>
        )}
      </div>
      {/* <div>
        <DetailsItem showBg={true} />
        <DetailsItem />
        <DetailsItem showBg={true} />
        <DetailsItem />
      </div> */}
    </div>
  );
}
export default DirectFinanceDetails;

const DetailsItem = ({ showBg = false, data, handleClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn('rounded-normal p-[.24rem_.2rem_.26rem_.2rem] text-[.2rem] font-[500] leading-[.24rem] relative', {
        'bg-[var(--colour-68)] shadow-[0_.04rem_.04rem_0_rgba(0,0,0,0.02)]': showBg,
      })}
    >
      <div className="flex items-center mb-[.08rem]">
        <div className="flex items-center w-[2.8rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-38)] mr-[.2rem]">{data?.uid || ''}</div>
          <div
            className="w-[.28rem] h-[.28rem] text-[var(--colour-1)]"
            onClick={() => {
              copy(`${data?.uid || ''}`);
              toast.success(t('home.toast006'), {
                containerId: 'global',
              });
            }}
          >
            <Icon name={IconName.Copy4} />
          </div>
        </div>
        <div
          className="flex items-center flex-1"
          onClick={() => {
            if (Number(data?.total_direct_members) > 0) {
              handleClick(data.uid);
            }
          }}
        >
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_data_subordinados_dele')}</div>
          {Number(data?.total_direct_members) > 0 ? (
            <div className="text-[var(--colour-1)] underline cursor-pointer">{data?.total_direct_members || 0}</div>
          ) : (
            <div className="text-[var(--colour-38)]">-</div>
          )}
        </div>
      </div>
      <div className="flex items-center mb-[.1rem]">
        <div className="flex items-center w-[2.8rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-44)] mr-[.12rem]">{t('refer.direct_data_deposito')}</div>
          <div className="text-[var(--colour-38)]">
            {getShowMoney(data?.deposit_amount || 0, 2, 2)}
            {t('refer.direct_betting_vezes', { val: data?.deposit_count || 0 })}
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_finance_saques')}</div>
          <div className="text-[var(--colour-26)]">
            {getShowMoney(data?.withdraw_amount || 0, 2, 2)}
            {t('refer.direct_betting_vezes', { val: data?.withdraw_count || 0 })}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex items-center w-[2.8rem] shrink-0 mr-[.2rem]">
          <div className="text-[var(--colour-44)] mr-[.12rem]">{t('refer.direct_finance_diferenca')}</div>
          {Number(data?.difference) > 0 ? (
            <div className="text-[var(--colour-25)]">+{getShowMoney(data?.difference || 0, 2, 2)}</div>
          ) : (
            <div className="text-[var(--colour-24)]">{getShowMoney(data?.difference || 0, 2, 2)}</div>
          )}
        </div>
        <div className="flex items-center">
          <div className="text-[var(--colour-44)] mr-[.08rem]">{t('refer.direct_finance_saldo')}</div>
          <div className="text-[var(--colour-38)]">{getShowMoney(data?.balance || 0, 2, 2)}</div>
        </div>
      </div>

      <div className="absolute top-[0rem] left-[0rem] bg-[var(--colour-25)] rounded-[.04rem_0] text-[.16rem] font-[600] leading-[.2rem] text-[var(--colour-2)] px-[.08rem]">
        V{data?.vip_level || 0}
      </div>
    </div>
  );
};
