import React from 'react';

import { useTranslation } from 'react-i18next';
import Close from '../../close';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center  mt-[.3rem] ${className}`}>
        <div className=" text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>

        <div className="  w-[.72rem] h-[.72rem] flex justify-center items-center rounded-[.08rem] bg-[var(--colour-45)]">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full bg-[var(--colour-6)] h-[1px]  mt-[.28rem]"></div>
    </>
  );
};

const payLogDialog = ({ handleClose = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div className=" my-[.3rem] flex flex-col justify-between text-center relative    p-[0_.48rem_.4rem_.48rem] w-[7.2rem] h-[auto] bg-[var(--colour-34)]">
      <div className="">
        <div className="px-[.32rem] ">
          <FormHeader title={t('deposit-withdraw.payment_message')} handleClose={handleClose} />
        </div>

        <div className=" p-[1rem_.32rem] text-center text-[var(--colour-44)]">{t('deposit-withdraw.patiently')}</div>
      </div>
    </div>
  );
};
export default payLogDialog;
