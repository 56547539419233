import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  setRefetchActivityData,
  setNeedUpdateBalance,
  setIsShowPromotionBarGiftMoneyCoin,
  setDialogModalVisible,
  DialogModalChildrenKey,
} from '../../../redux/reducers/appSlice';
import { getShowMoney, getRedPacketObj, openHeaderGiftMoney, isPromotionPath } from '../../../utils/helper';
import { useTranslation, Trans } from 'react-i18next';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { toast } from 'react-toastify';
import { ClaimCashRainReq } from '../../../api';
import { toNoLocalePath } from '../../../utils/localePath';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';
import NoData from '../../noData';
import { getCenterPosition } from '../../../hooks/useMinimizeAnimation';
import LimitClickWrapper from '../../limitClickWrapper';
import GSAPUtils from '../../../utils/gsapUtils';
import PopBg2 from '../components/popBg2';

export default function MysteryMining({ handleClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { websiteInfo, activityData, currency_symbol } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      activityData: state.app.activityData,
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
    }),
    shallowEqual,
  );

  const { pathname } = useLocation();
  const [isShowResult, setIsShowResult] = useState(false);
  const [cashRainObj, setCashRainObj] = useState(null);

  const handleOpenCliamBox = (money = 0) => {
    const getLimitShowMoney = (moeny) => {
      const m = getShowMoney(moeny).replace(',', '.');
      if (m.indexOf('.') > -1 && m.substring(0, m.indexOf('.')).length > 4) {
        return m.substring(0, m.indexOf('.'));
      }

      return m;
    };

    const content =
      money == 0 ? (
        t('cashRain.tips2')
      ) : (
        <span className="text-[var(--colour-33)] text-[.28rem] font-[600]">
          <Trans
            t={t}
            i18nKey={'cashRain.tip5'}
            components={[<span className="text-[var(--colour-25)] text-[.4rem]" key={0}></span>]}
            values={{
              val: `${currency_symbol}${getLimitShowMoney(money)}`,
            }}
          />
        </span>
      );

    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.ClaimBonusResultTip,
        content,
      }),
    );
  };

  const setZeroResult = () => {
    setIsShowResult(true);
    handleOpenCliamBox(0);
    dispatch(setRefetchActivityData());
  };

  const getDate = (timestamp = '') => {
    if (timestamp) {
      const date = new Date(timestamp * 1000);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    } else {
      return '';
    }
  };
  const handleGrab = (element) => {
    if (cashRainObj != null) {
      if (cashRainObj.red_packet_End_time * 1000 - new Date().getTime() <= 0) {
        //如果红包已经过期
        setZeroResult();
      } else {
        ClaimCashRainReq({
          activity_id: cashRainObj.id,
        }).then((res) => {
          if (res.code == 0) {
            setIsShowResult(true);
            const result = res.result;
            handleOpenCliamBox(Number(result.amount) || 0);
            dispatch(setRefetchActivityData());
            dispatch(setNeedUpdateBalance({ need: true }));

            const currency = res?.result?.currency;
            const amount = res?.result?.amount || 0;

            if (element && currency == 'gift_money') {
              const fromPoints = getCenterPosition(element, true);
              if (isPromotionPath(toNoLocalePath(pathname)) && activityData?.gift_money) {
                const giftMoneyLabel = document.querySelector('#promotion_giftMoney');

                if (giftMoneyLabel) {
                  const toPoint = getCenterPosition(giftMoneyLabel, true);
                  GSAPUtils.flyCoin(fromPoints, toPoint, GSAPUtils.COIN_COUNT, () => {
                    dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                  });
                }
              } else {
                openHeaderGiftMoney(amount, [fromPoints]);
              }
            }
          } else if (res.code == 1008108) {
            setZeroResult();
          } else {
            toast.error(res.msg, {
              containerId: 'global',
            });
          }
        });
      }
    }
  };

  let interval = -1;
  useEffect(() => {
    if (interval > 0) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      if (cashRainObj != null) {
        if (cashRainObj.red_packet_start_time * 1000 - new Date().getTime() < 0 && !cashRainObj.started) {
          const newCashRainObj = cloneDeep(cashRainObj);
          newCashRainObj.started = true;
          setCashRainObj(newCashRainObj);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cashRainObj]);

  useEffect(() => {
    if (activityData && activityData.activity_red_packet) {
      const list = activityData.activity_red_packet.list;
      setCashRainObj(getRedPacketObj(list));
    }
  }, [activityData]);

  return (
    <PopBg2
      handleClose={() => {
        handleClose();
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseCashRainButton);
      }}
    >
      {cashRainObj ? (
        <div className="w-full h-full">
          <div className="w-full h-[4.4rem] bg-[url(../assets/img/cash-rain/mysteryMining.png)] bg-no-repeat bg-[length:100%_100%]"></div>

          <div className="absolute abs-x-center top-[.11rem] text-[var(--colour-76)] text-[.36rem] font-[600] cashRain_title">
            {cashRainObj?.rule_explain?.activity_title}
          </div>

          <ClaimButton isShowResult={isShowResult} cashRainObj={cashRainObj} handleGrab={handleGrab} />
          <div className="mt-[.56rem] text-[var(--colour-26)] text-[.26rem] font-[500] text-center">
            {websiteInfo.currency_symbol || ''}&nbsp;{getShowMoney(cashRainObj?.activity_claim_ceiling_amount)}
            &nbsp;{t('cashRain.maxPrize')}
          </div>
          {cashRainObj?.daily_delivery_time && cashRainObj?.daily_delivery_time.length > 0 ? (
            <div className="mt-[.22rem] w-[5.38rem] mx-auto rounded-[.2rem] border border-[var(--colour-60)] px-[.26rem] py-[.08rem] grid grid-cols-3 max-h-[.96rem] overflow-y-auto hidden-scroll-y">
              {cashRainObj?.daily_delivery_time.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`text-[.2rem] font-[500] ${item.end * 1000 - new Date().getTime() <= 0 ? 'text-[var(--colour-14)]' : 'text-[var(--colour-26)]'}`}
                  >
                    {getDate(item.start)}-{getDate(item.end)}
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="text-[.2rem] max-h-[1.12rem] overflow-y-auto hidden-scroll-y text-[var(--colour-14)] font-[400] mx-auto px-[.38rem] break-all mt-[.2rem]">
            <div dangerouslySetInnerHTML={{ __html: cashRainObj.rule_explain.activity_rule_explain }}></div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-[6.14rem] h-[8.1rem]">
          <NoData showTextLangkey="promotion.eventend" textClassName="!text-[#fff]" className1="!text-[#fff]" />
        </div>
      )}
    </PopBg2>
  );
}

const ClaimButton = ({ cashRainObj, handleGrab }) => {
  const { t } = useTranslation();

  return cashRainObj ? (
    cashRainObj.started && cashRainObj.is_grad == 1 ? (
      <LimitClickWrapper
        className="w-[5.38rem] h-[.68rem] flex items-center justify-center rounded-[.12rem] colour-79 cursor-pointer absolute abs-x-center top-[4.06rem] text-[var(--colour-2)] text-[.28rem] font-[600]"
        onClick={(event) => {
          handleGrab(event.currentTarget);
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CashRainButton);
        }}
      >
        {t('cashRain.GRAB')}
      </LimitClickWrapper>
    ) : (
      <LimitClickWrapper className="w-[5.38rem] h-[.68rem] flex items-center justify-center rounded-[.12rem] bg-[var(--colour-42)]  absolute abs-x-center top-[4.06rem] text-[var(--colour-77)] text-[.26rem] font-[600] text-center leading-[.24rem]">
        {cashRainObj.started && cashRainObj.is_grad == 0 ? (
          <>{t('cashRain.tip4')}</>
        ) : (
          <>{t('cashRain.startIn') + ' ' + cashRainObj.showTime}</>
        )}
      </LimitClickWrapper>
    )
  ) : (
    <></>
  );
};
