import Close from '../close';
import { useState, useEffect } from 'react';
import Icon, { IconName } from '../icon';
import { getShowMoney } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../utils/localePath';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const VipUpgrade = ({ handleClose, data: vipData }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const [list, setList] = useState([
    {
      title: t('vip.Bouns'),
      num: '10.00',
    },
    {
      title: t('vip.weellyBouns'),
      num: '3.00',
    },
    {
      title: t('vip.monthlyBouns'),
      num: '5.00',
    },
  ]);
  const [mydata, setMyData] = useState([
    {
      title: 'vip',
      num: 'VIP0',
    },
    {
      title: 'gole1',
      num: '100.00',
    },
    {
      title1: 'gole12',
      num: '1500.00',
    },
  ]);
  const [vip, setVip] = useState(0);
  useEffect(() => {
    // let result = JSON.parse(JSON.stringify(children.res.data));
    let result = JSON.parse(JSON.stringify(vipData));
    const maxVipLevel = Math.max(...(result?.list || []).map((item) => Number(item?.vip_level_config?.vip || 0)));

    setVip(result.vipLevel);

    let data = [
      { title: 'vip', num: 0 },
      { title: 'title', num: 0 },
      { title: 'title1', num: 0 },
    ];

    if (result.vipLevel >= maxVipLevel) {
      setMyData(null);
    } else {
      let to1 = result.list[Number(result.vipLevel)].vip_level_config.total_deposit - result.totalDeposit;
      let to2 = result.list[Number(result.vipLevel)].vip_level_config.total_wager - result.totalWager;
      if (to1 < 0) {
        to1 = 0;
      }
      if (to2 < 0) {
        to2 = 0;
      }
      data[0].num = Number(result.vipLevel) + 1;
      let deposit = to1;
      let wager = to2;
      data[1].num = getShowMoney(deposit);
      data[2].num = getShowMoney(wager);
      setMyData(data);
    }

    let data1 = [
      { title: t('vip.bouns'), num: 0 },
      { title: t('vip.weelyBonus'), num: 0 },
      { title: t('vip.monthlyBonus'), num: 0 },
    ];

    data1[0].num = getShowMoney(result.list[Number(result.vipLevel)].vip_bonus_info[0].money);
    data1[1].num = getShowMoney(result.list[Number(result.vipLevel)].vip_bonus_info[1].money);
    data1[2].num = getShowMoney(result.list[Number(result.vipLevel)].vip_bonus_info[2].money);
    setList(data1);
  }, [vipData]);

  const handleGoPrivileges = () => {
    handleClose();
    navigate(toLocalePath('/promotion/vip'));
  };

  return (
    <>
      <div className="w-[6.86rem] h-[6.96rem] bg-[var(--colour-35)] rounded-normal overflow-hidden">
        <div className="w-[.72rem] h-[.72rem] flex justify-center items-center absolute top-[.24rem] right-[.32rem] bg-[var(--colour-45)] rounded-[.16rem]">
          <Close onClick={handleClose} />
        </div>
        <div className="w-full h-full">
          <div className="w-full h-full px-[.32rem]">
            <div className="w-full h-[1.1rem] flex items-center">
              <div className="font-[700] text-[.32rem] text-[var(--colour-48)]">{t('vip.upgrade')}</div>
            </div>
            <div className="w-full h-[.02rem] bg-[var(--colour-6)]"></div>
            <div className="flex items-center justify-between w-full mt-[.58rem]">
              <div className="flex flex-col justify-between relative items-center mx-[auto] w-[2.02rem] h-[2.1rem]">
                <img src="/static/img/Vipclub7.png" className="w-[1.7rem]" alt="" />
                <img src="/static/img/Vipclub8.png" className="w-[2.02rem] absolute bottom-0 left-0" alt="" />
                <div className="flex justify-center text-center w-[1.84rem] h-[1.4rem]">
                  <div className="flex text-[.44rem] -mt-[1.14rem] font-[600] vip_upgrade" data-text={vip}>
                    {vip}
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse items-end mt-[.1rem]">
                {list &&
                  list.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="px-[.1rem] flex justify-between items-center font-[400] w-[3.7rem] text-[.24rem] h-[.48rem] mb-[.2rem] bg-[var(--colour-34)] border border-[var(--colour-6)] text-[var(--colour-38)] rounded-[.16rem]"
                      >
                        <div>{item.title}</div>
                        <div className="flex items-center text-[var(--colour-24)]">
                          <span className="mr-[.08rem]">{item.num}</span>
                          <span className="block w-[.24rem] h-[.28rem]">
                            <Icon name={IconName.Vipupgrade}></Icon>
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {mydata == null ? (
              <></>
            ) : (
              <>
                <div className="font-[400] mt-[.74rem] text-[.24rem] text-center text-[var(--colour-44)]">
                  <span>{t('vip.toUpgradeTo')}</span> <span className="text-[var(--colour-26)]">{mydata[0].num}</span>,{' '}
                  <span>{t('vip.needToRecharge')}</span>
                  <span>{t('vip.recharge1')}</span> <span className="text-[var(--colour-26)]">{mydata[1].num}</span>{' '}
                  <span>{t('vip.and')}</span> <span className="text-[var(--colour-26)]">{mydata[2].num}</span>{' '}
                  <span>{t('vip.betamount')}</span>
                  {/* <span>{t('vip.tpUpgrade',{num1:mydata[0].num,num1:mydata[1].num},{num1:mydata[2].num})}</span> */}
                </div>
                <div
                  className="w-[6.06rem] h-[.72rem] mt-[.2rem] text-[.32rem] font-[500] bg-[var(--colour-1)] text-[var(--colour-2)] flex justify-center items-center cursor-pointer rounded-normal"
                  onClick={handleGoPrivileges}
                >
                  <span>{t('vip.privileges')}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default VipUpgrade;
