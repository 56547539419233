import { cmsImgUrl } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import Close from '../close2';

const PwaguideQRcodeModel = ({ handleClose = () => {} }) => {
  const { t } = useTranslation();

  const { download } = useSelector(
    (state) => ({
      download: state.app.websiteInitConfig?.website_info?.download,
    }),
    shallowEqual,
  );

  return (
    <>
      <div
        className={`scale-[1.3] w-[4.8rem] border border-[var(--colour-6)] rounded-normal bg-[var(--colour-34)] cursor-default `}
        style={{ boxShadow: '0rem .12rem .2rem rgba(0,0,0,0.6)' }}
      >
        <div className="w-full flex justify-end items-center pr-[.2rem]">
          <Close handleClose={handleClose} />
        </div>
        <div className="relative z-[2]">
          <div className="text-[var(--colour-8)] w-[4.8rem] text-center">{t('home.drawer_download_text1')}</div>
          <div className="w-[4.8rem] flex justify-center">
            <div className="w-[2.72rem] mb-[.2rem] flex justify-center items-center">
              <img src={cmsImgUrl(download?.apk.qr_code)} />
            </div>
          </div>
          <div className="pb-[.34rem] w-[4.8rem] text-center text-[var(--colour-49)]">
            {t('home.drawer_download_text2')}
          </div>
        </div>
      </div>
    </>
  );
};

export default PwaguideQRcodeModel;
