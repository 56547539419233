import DTemplate003 from './design/template003';
import NTemplate001 from './normal/template001';

const Index = ({ list, showStyle = 1 }) => {
  console.log('showStyle', showStyle, list);
  // showStyle = 2
  // showStyle 1: 老样式 2: 新样式
  return showStyle == 1 ? <NTemplate001 list={list} /> : <DTemplate003 list={list} />;
};

export default Index;
