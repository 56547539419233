import { useState, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { setShowHeaderGiftMoney } from '../../../redux/reducers/appSlice';
import Icon, { IconName } from '../../icon';
import useNumberTransition from '../../../hooks/useNumberTransition';
import GSAPUtils from '../../../utils/gsapUtils';
import { getCenterPosition } from '../../../hooks/useMinimizeAnimation';
import { openGiftMoneyDetail, getShowMoney } from '../../../utils/helper';
import useGameInfo from '../../../hooks/useGameInfo';
import { toNoLocalePath } from '../../../utils/localePath';

const GiftMoneyPopUp = ({ handleClose = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { needUpdateGiftMoney, showHeaderGiftMoney, headerGiftMoneyBalance } = useSelector(
    (state) => ({
      needUpdateGiftMoney: get(state, 'app.needUpdateGiftMoney'),
      showHeaderGiftMoney: get(state, 'app.showHeaderGiftMoney'),
      headerGiftMoneyBalance: get(state, 'app.headerGiftMoneyBalance'),
    }),
    shallowEqual,
  );
  const coinRef = useRef(null);
  const plusRef = useRef(null);
  const isFirstLoad = useRef(true);
  const { pathname } = useLocation();
  const [money, setMoney] = useState(0);
  const [flyingCoins, setFlyingCoins] = useState([]);
  const [duration, setDuration] = useState(300); //金额设置为0的时候，不需要动画

  const { isGameOpen } = useGameInfo();

  useEffect(() => {
    if (needUpdateGiftMoney.key !== 0) {
      if (coinRef.current && needUpdateGiftMoney.fromPoint && Array.isArray(needUpdateGiftMoney.fromPoint)) {
        setTimeout(() => {
          const coins = [];
          const toPoint = getCenterPosition(coinRef.current, true);

          needUpdateGiftMoney.fromPoint.forEach((item) => {
            const coin = GSAPUtils.flyCoin(item, toPoint);
            coins.push(...coin);
          });

          setFlyingCoins(coins);
        }, 350);
      }
    }
  }, [needUpdateGiftMoney, coinRef]);

  // //更新显示金额
  // useEffect(() => {
  //   if (needUpdateGiftMoney.key !== 0) {
  //     setTimeout(() => {
  //       setMoney(needUpdateGiftMoney.data);
  //     }, GSAPUtils.COIN_FLY_DURATION * 1000);
  //   }
  // }, [needUpdateGiftMoney]);

  const handleAnimationEnd = () => {
    if (plusRef.current) {
      plusRef.current.classList.remove('breathe-effect');
    }
  };

  //更新显示金额
  useEffect(() => {
    let timer = null;
    setDuration(0);
    setMoney(0);
    timer = setTimeout(() => {
      setDuration(300);
      setMoney(headerGiftMoneyBalance);

      if (plusRef.current) {
        plusRef.current.classList.add('breathe-effect');
        plusRef.current.addEventListener('animationend', handleAnimationEnd);
      }
    }, GSAPUtils.COIN_FLY_DURATION * 1000);

    return () => {
      if (plusRef.current) {
        plusRef.current.removeEventListener('animationend', handleAnimationEnd);
      }
      clearTimeout(timer);
    };
  }, [needUpdateGiftMoney, headerGiftMoneyBalance]);

  const { currentValue } = useNumberTransition({
    number: Number(money),
    duration: duration,
    firstNeedAnimation: true,
  });

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    } else {
      dispatch(setShowHeaderGiftMoney(false));
    }

    if (isGameOpen) {
      dispatch(setShowHeaderGiftMoney(false));
    }
  }, [pathname, isGameOpen]);

  useEffect(() => {
    if (!showHeaderGiftMoney) {
      GSAPUtils.stopFlyCoin(flyingCoins);
    }

    return () => {
      GSAPUtils.stopFlyCoin(flyingCoins);
    };
  }, [showHeaderGiftMoney]);

  return (
    <div className="rounded-[0_0_.16rem_.16rem] border border-[var(--colour-6)] bg-[var(--colour-4)] shadow-[0_.08rem_.08rem_0_rgba(0,0,0,0.25)] flex items-center justify-between p-[.22rem_.18rem_.22rem_.22rem]">
      <div className="shrink-0 w-[.52rem] h-[.52rem] text-[var(--colour-1)] mr-[.08rem]">
        <Icon name={IconName.MenuGiftMoney2} />
      </div>
      <div className="shrink-0 h-[.4rem] pl-[.08rem] pr-[.14rem] border border-[var(--colour-1)] bg-[var(--colour-22)] rounded-full flex">
        <div className="mr-[.08rem] pt-[.02rem]">
          <img ref={coinRef} className="w-[.32rem] h-[.32rem]" src="/static/img/starCoin.png" alt="" />
        </div>

        <div ref={plusRef} className="flex items-center">
          <div className="flex items-center text-[var(--colour-26)] text-[.26rem] font-[600]">+</div>
          <div className="text-[var(--colour-26)] text-[.26rem] font-[600]">{getShowMoney(currentValue)}</div>
        </div>
      </div>
      <div className="px-[.2rem] text-[var(--colour-1)] text-[.2rem] font-[500] leading-[.24rem]">
        {t('giftMoney.headerPopupText')}
      </div>
      <div
        className="shrink-0 h-[.64rem] mr-[.2rem] rounded-[.16rem] bg-[var(--colour-34)] pl-[.24rem] pr-[.22rem] text-[var(--colour-1)] text-[.24rem] font-[700] leading-[.64rem] cursor-pointer"
        onClick={() => {
          handleClose();
          openGiftMoneyDetail(isGameOpen, toNoLocalePath(pathname));
          // dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
          //   dispatch(setNeedUpdateGiftMoney({ need: true, data: new Date().getTime().toString().slice(6, -3) })); // 更新余额
        }}
      >
        {t('giftMoney.details')}
      </div>
      <div className="shrink-0 w-[.24rem] h-[.24rem] text-[var(--colour-46)] cursor-pointer" onClick={handleClose}>
        <Icon name={IconName.Close2} />
      </div>
    </div>
  );
};
export default GiftMoneyPopUp;
