import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import copy from 'copy-to-clipboard';
import cn from 'classnames';
import Icon, { IconName } from '../../../../../../components/icon';
import EnterPassword from '../../../../../../components/dialogModal/enterPassword';
import AddPix from '../../../../../../components/dialogModal/addPix';
import DialogModal from '../../../../../../components/dialogModal';
import {
  PaymentAccWithdrawBrlAccountList,
  PaymentWithdrawVerifyPassword,
  PaymentAccWithdrawSetDefaultAccount,
  PaymentWithdrawMethodList,
} from '../../../../../../api/newDepositWithdraw';
import Loading from '../../../../../../components/loading2';
import ScrollWrapper from '../../../../../../components/scrollWrapper';
import { formatCpf } from '../../../../../../utils/helper';
import ToastTip from '../../../../../../components/toastTip';

export default function AccountForReceipt({ configData = null, setNeedUpdate }) {
  const { t } = useTranslation();
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const [showEye, setShowEye] = useState(false); //小眼睛状态

  const [showEnterPassword, setShowEnterPassword] = useState(false); //enterPassword 弹窗是否展示
  const [showAddPix, setShowAddPix] = useState(false); //addPix 弹窗是否展示

  const [requesting, setRequesting] = useState(true); //列表请求状态
  const defaulting = useRef(false); //设置默认提现账户请求状态
  const [isConfirmPassword, setIsConfirmPassword] = useState(false); //验证密码状态
  const [limit, setLimit] = useState(0); //限制数量
  const [count, setCount] = useState(0); //当前账户列表数量
  const [accountList, setAccountList] = useState([]); //账户列表
  const [verifyUuid, setVerifyUuid] = useState(''); //验证密码成功后的uuid
  const [defaultName, setDefaultName] = useState(''); //添加提现账户的name
  const [defaultCpf, setDefaultCpf] = useState('');
  const [accountLimit, setAccountLimit] = useState(null); //账户最大限制数量
  const [addFlag, setAddFlag] = useState(false); //添加账户开关
  const [methodList, setMethodList] = useState([]); //提现方式列表
  const toastErrorId = useRef(null);
  const toastSuccessId = useRef(null);
  const toastSuccessId2 = useRef(null);

  useEffect(() => {
    if (websiteInfo?.payment?.withdraw_account_limit) {
      setAccountLimit(websiteInfo?.payment?.withdraw_account_limit);
    }
  }, [websiteInfo]);

  useEffect(() => {
    if (accountLimit) {
      if (accountList && accountList.length > 0) {
        const occurrenceMap = {};
        accountList.forEach((item) => {
          let key = item?.method;
          if (occurrenceMap[key]) {
            occurrenceMap[key]++;
          } else {
            occurrenceMap[key] = 1;
          }
        });
        let flag = false; // 添加提现账户的开关
        for (let key in accountLimit) {
          if (accountLimit[key] > 0) {
            if (!occurrenceMap[key]) {
              flag = true;
            } else if (occurrenceMap[key] < accountLimit[key]) {
              flag = true;
            }
          }
        }
        setAddFlag(flag);
      } else {
        let flag2 = false; // 添加提现账户的开关
        for (let key in accountLimit) {
          if (accountLimit[key] > 0) {
            flag2 = true;
          }
        }

        setAddFlag(flag2);
      }
    }
  }, [accountLimit, accountList]);

  useEffect(() => {
    PaymentWithdrawMethodList().then((res) => {
      if (res.code == 0) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          setMethodList(res.data.list);
        }
      }
    });
  }, []);

  useEffect(() => {
    requestAccountList();
  }, []);

  const requestAccountList = (isRequest = true) => {
    if (isRequest) {
      setRequesting(true);
    }
    PaymentAccWithdrawBrlAccountList()
      .then((res) => {
        if (res.code == 0) {
          const data = res.data;
          setLimit(data.limit);
          setCount(data.count);
          setAccountList(data.list);
          if (data && data.list && data.list.length > 0) {
            let defaultNameItem = data.list.find((item) => {
              return item.name != '';
            });
            if (defaultNameItem) {
              setDefaultName(defaultNameItem.name);
            }

            let defaultCpfItem = data.list.find((item) => {
              return item.cpf != '';
            });
            if (defaultCpfItem) {
              setDefaultCpf(formatCpf(defaultCpfItem.cpf));
            }
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const clickVerifyPassword = (password) => {
    setIsConfirmPassword(true);
    PaymentWithdrawVerifyPassword({ password })
      .then((res) => {
        if (res.code == 0) {
          setVerifyUuid(res.data.verify_uuid);
          setShowEnterPassword(false);
          setShowAddPix(true);
        } else {
          toastErrorId.current = toast.error(<ToastTip type="err" content={res.msg} />, {
            containerId: 'tip',
            icon: false,
          });
        }
      })
      .finally(() => {
        setIsConfirmPassword(false);
      });
  };

  const successCallback = () => {
    setShowAddPix(false);
    requestAccountList();
    setNeedUpdate(true);
    toast.dismiss(toastErrorId.current);
    toastSuccessId.current = toast.error(<ToastTip type="success" content={t('deposit-withdraw-wg.add_success')} />, {
      containerId: 'tip',
      icon: false,
    });
  };

  const setDefaultAccount = (id) => {
    if (defaulting.current == true) return;
    defaulting.current = true;
    PaymentAccWithdrawSetDefaultAccount({ id })
      .then((res) => {
        if (res.code == 0) {
          toast.dismiss(toastSuccessId2.current);
          toastSuccessId2.current = toast.error(
            <ToastTip type="success" content={t('deposit-withdraw-wg.default_successful')} />,
            {
              containerId: 'tip',
              icon: false,
            },
          );

          requestAccountList(false);
          setNeedUpdate(true);
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        defaulting.current = false;
      });
  };

  useEffect(() => {
    return () => {
      toast.dismiss(toastErrorId.current);
      toast.dismiss(toastSuccessId.current);
      toast.dismiss(toastSuccessId2.current);
    };
  }, []);

  return (
    <div className="flex flex-col justify-between">
      <div
        className="pt-[.28rem] px-[.3rem] m-[.2rem] bg-[var(--colour-35)] rounded-[.12rem]"
        style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)' }}
      >
        <div className="flex items-center justify-between mb-[.2rem]">
          <div className="flex items-center text-[.24rem] font-[500] leading-[.3rem]">
            <div className="text-[var(--colour-48)] mr-[.1rem]">{t('deposit-withdraw-wg.withdraw_account_title')}</div>
            <div className="text-[var(--colour-37)] mr-[.16rem]">
              ({count}/{limit})
            </div>
            <div
              className="w-[.36rem] h-[.36rem] text-[var(--colour-13)]"
              style={requesting ? { animation: 'frashenTurn 0.5s linear infinite' } : {}}
              onClick={() => {
                if (requesting) return;
                requestAccountList();
              }}
            >
              <Icon name={IconName.Refresh2} />
            </div>
          </div>
          <div
            className="w-[.48rem] h-[.48rem] flex items-center justify-center"
            onClick={() => {
              setShowEye(!showEye);
            }}
          >
            {showEye ? (
              <div className="text-[var(--colour-1)] w-[.48rem] h-[.48rem]">
                <Icon name={IconName.NewShowEye} />
              </div>
            ) : (
              <div className="text-[var(--colour-6)] w-[.48rem] h-[.48rem]">
                <Icon name={IconName.NewhideEye} />
              </div>
            )}
          </div>
        </div>
        {accountList && accountList.length > 0
          ? accountList.map((item, index) => {
              return <PixItem key={item.id} data={item} setDefaultAccount={setDefaultAccount} showEye={showEye} />;
            })
          : null}
      </div>

      {addFlag && !requesting ? (
        <>
          <div className="h-[1.28rem]"></div>
          <div
            className="fixed bottom-[0rem] left-[0rem] w-full h-[1.28rem] bg-[var(--colour-4)] px-[.3rem] flex items-center"
            style={{ boxShadow: '0rem -0.04rem .08rem 0rem rgba(0, 0, 0, 0.10)' }}
          >
            <div
              className="active:scale-[.95] transition-all select-none w-full h-[.8rem] rounded-normal bg-[var(--colour-35)] border border-[var(--colour-6)] cursor-pointer flex items-center justify-between px-[.2rem]"
              onClick={() => {
                if (methodList && methodList.length > 0) {
                  if (configData?.payment.switch_withdraw_password == '1') {
                    //开启提现密码
                    setShowEnterPassword(true);
                  } else {
                    setShowAddPix(true);
                  }
                } else {
                  toast.info(t('home.toast031'), {
                    containerId: 'global',
                  });
                }
              }}
            >
              <div className="flex flex-row items-center">
                <div className="w-[.44rem] h-[.44rem] mr-[.28rem]">
                  <Icon name={IconName.PIXLogo} />
                </div>
                <span className="text-[.22rem] font-[400] text-[var(--colour-48)]">PIX</span>
              </div>
              <div className="flex items-center">
                <div className="text-[.22rem] font-[500] leading-[.26rem] text-[var(--colour-13)] mr-[.24rem]">
                  {t('deposit-withdraw-wg.withdraw_account_to_add')}
                </div>
                <div className="w-[.16rem] h-[.22rem] text-[var(--colour-11)]">
                  <Icon name={IconName.BannerNext2} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* 输入密码 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowEnterPassword(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={showEnterPassword}
      >
        <EnterPassword
          handleClose={() => {
            setShowEnterPassword(false);
          }}
          clickVerifyPassword={clickVerifyPassword}
          isConfirmPassword={isConfirmPassword}
        />
      </DialogModal>
      {/* AddPix */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowAddPix(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={showAddPix}
      >
        <AddPix
          handleClose={() => {
            setShowAddPix(false);
          }}
          verifyUuid={verifyUuid}
          defaultName={defaultName}
          defaultCpf={defaultCpf}
          successCallback={successCallback}
          methodList={methodList}
        />
      </DialogModal>
    </div>
  );
}

const PixItem = ({ data = null, setDefaultAccount = () => {}, showEye = false }) => {
  const { t } = useTranslation();
  const showCpf = (str) => {
    return showEye ? str : `****${str.slice(-4)}`;
  };
  return (
    <div
      className={cn(
        'h-[.8rem] bg-[var(--colour-35)] border px-[.16rem] flex justify-between items-center rounded-normal mb-[.2rem] relative',
        {
          'border-[var(--colour-1)]': data?.is_default == 1,
          'border-[var(--colour-6)]': data?.is_default != 1,
        },
      )}
    >
      <div className="flex flex-col justify-center">
        <div className="flex items-center mb-[.1rem]">
          <div className="w-[.44rem] h-[.44rem] mr-[.28rem]">
            <Icon name={IconName.PIXLogo} />
          </div>
          <div className="flex items-start flex-col space-y-[.02rem]">
            <div className="text-[.22rem] font-[400] leading-[.34rem] text-[var(--colour-41)] uppercase">
              <span className="text-[var(--colour-48)]">PIX</span>({data?.method || ''})
            </div>
            <div
              className={`text-[.22rem] font-[500] leading-[.24rem] flex items-center  ${showEye ? 'text-[var(--colour-10)]' : 'text-[var(--colour-41)]'}`}
            >
              <div>
                {showEye
                  ? data?.method == 'cpf'
                    ? formatCpf(data['cpf'])
                    : data[data?.method || 'cpf']
                  : showCpf(data[data?.method || 'cpf'])}
              </div>
              {showEye ? (
                <div
                  className="w-[.24rem] h-[.24rem] text-[var(--colour-13)] ml-[.14rem]"
                  onClick={() => {
                    copy(`${(data?.method == 'cpf' ? formatCpf(data['cpf']) : data[data?.method || 'cpf']) || ''}`);
                    toast.success(t('home.toast006'), {
                      containerId: 'global',
                    });
                  }}
                >
                  <Icon name={IconName.Copy4} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {data?.is_default == 1 ? null : (
        <div
          className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-13)]"
          onClick={() => {
            setDefaultAccount(data.id);
          }}
        >
          {t('deposit-withdraw-wg.withdraw_account_set_as_default')}
        </div>
      )}
      {data?.is_default == 1 ? (
        <>
          <div className="absolute bottom-[-0.02rem] right-[-0.02rem] w-[0rem] h-[0rem] border-b-[.44rem] border-b-[var(--colour-1)] border-l-[.48rem] border-l-[transparent] rounded-br-[var(--rounded-normal)]"></div>
          <div className="absolute right-[.05rem] bottom-[.04rem] w-[.2rem] h-[.16rem] text-[var(--colour-2)]">
            <Icon name={IconName.Complete} />
          </div>
        </>
      ) : null}
    </div>
  );
};
