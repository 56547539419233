import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Rules = () => {
  const { t } = useTranslation();

  const { website_vip_rules, defaultLang } = useSelector((state) => ({
    website_vip_rules: state?.app?.websiteInitConfig?.website_info?.website_vip_rules,
    defaultLang: state?.user?.defaultLang,
  }));

  const getRules = () => {
    const _rule = website_vip_rules[defaultLang] || website_vip_rules['en'];
    return _rule;
  };

  return (
    // <div>
    //   <div className="text-[var(--colour-41)] text-[.28rem] font-[500] leading-[1.4]">{t('vip.rules')}</div>
    //   <div
    //     className="text-[var(--colour-41)] text-[.24rem] font-[500] leading-[1.4] mt-[.32rem]"
    //     dangerouslySetInnerHTML={{ __html: getRules() }}
    //   ></div>
    // </div>
    <div
      className="text-[var(--colour-41)] text-[.24rem] font-[500] leading-[1.4] mt-[.32rem]"
      dangerouslySetInnerHTML={{ __html: getRules() }}
    ></div>
  );
};

export default Rules;
