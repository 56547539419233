import { gsap, MotionPathPlugin } from 'gsap/all';
import { remToPx } from './helper';

gsap.registerPlugin(MotionPathPlugin);

class GSAPUtils {
  static COIN_FLY_DURATION = 0.7;
  static COIN_SIZE = 1;
  static COIN_COUNT = 5;

  /***给定起点和终点坐标，计算出抛物线坐标数组 */
  static calculateTrajectory(startX, startY, endX, endY) {
    const points = [];

    // 添加起始点
    points.push({ x: startX, y: startY });

    // 计算中间点，向左上方偏移
    const midX = startX - (Math.abs(startX) - Math.abs(endX)) * 0.2;
    const midY = startY - (Math.abs(startY) - Math.abs(endY)) * 0.1;
    points.push({ x: midX, y: midY });

    // 添加终点
    points.push({ x: endX, y: endY });
    return points;
  }

  // 缩放效果
  static scaleEffect(target, isActive = true) {
    gsap.killTweensOf(target);
    if (!isActive) {
      gsap.set(target, { scale: 1 });
    } else {
      gsap.to(target, {
        keyframes: [
          { scaleX: 1.5, scaleY: 0.8 },
          { scaleX: 1.35, scaleY: 1.5 },
          { scaleX: 1, scaleY: 1 },
        ],
        ease: 'sine.inOut',
        duration: 0.65,
        repeatDelay: 1,
        repeat: -1,
      });
    }
  }

  // 飞行效果
  static flyCoin(fromPoint, toPoint, coinCount = this.COIN_COUNT, callback = () => {}) {
    const paths = this.calculateTrajectory(fromPoint.x, fromPoint.y, toPoint.x, toPoint.y);
    const flyCoins = [];
    for (let i = 0; i < coinCount; i++) {
      const coin = document.createElement('div');
      coin.className =
        'bg-[url(/static/img/goldTurn.png)] gold_coin_rotation bg-[length:1rem_8rem] fixed z-[99999999999999] opacity-[1] scale-[.5]';

      const size = remToPx(this.COIN_SIZE);
      coin.style.width = ` ${this.COIN_SIZE}rem`;
      coin.style.height = ` ${this.COIN_SIZE}rem`;
      coin.style.top = `${fromPoint.y - size}px`;
      coin.style.left = ` ${fromPoint.x - size}px`;
      document.body.appendChild(coin);
      flyCoins.push(coin);
    }

    gsap.set(flyCoins, {
      left: 0,
      top: 0,
      x: fromPoint.x,
      y: fromPoint.y,
      opacity: 1,
    });

    gsap.to(flyCoins, {
      duration: this.COIN_FLY_DURATION,
      motionPath: {
        path: paths,
        autoRotate: false,
        alignOrigin: [0.5, 0.5],
      },
      ease: 'power1.out',
      stagger: {
        amount: 0.5,
        from: 'start',
        each: 0.1,
        onComplete: () => {
          flyCoins.forEach((coin, index) => {
            gsap.to(coin, {
              duration: 0.1,
              y: '-=5',
              opacity: 0,
              delay: index * 0.1,
              onComplete: () => {
                coin.remove(); // 销毁div
                if (index == coinCount - 1) {
                  callback();
                }
              },
            });
          });
        },
      },
    });

    return flyCoins; // 返回飞行的硬币数组，以便后续停止动画
  }

  // 停止飞行效果
  static stopFlyCoin(flyCoins) {
    gsap.killTweensOf(flyCoins); // 停止所有动画
    flyCoins.forEach((coin) => {
      coin.remove(); // 移除已创建的div
    });
  }
}

export default GSAPUtils;
