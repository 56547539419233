import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import QRCode from '../../../../../../components/qrCode';
import copy from 'copy-to-clipboard';
import Header from './header';
import ScrollWrapper from '../../../../../scrollWrapper';
import Icon, { IconName } from '../../../../../icon';
import { pointToComma } from '../../../../../../utils/helper';
import usePay from '../../../../../../hooks/usePay';

export default function DepositDetail({ handleBack = () => {}, amount = '', qcURL = '', network = '' }) {
  const { t } = useTranslation();

  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const { handleCopyWithErrorReport } = usePay();

  return (
    <>
      <Header
        handleBack={handleBack}
        title="Deposit"
        withBottomBorder={true}
        className=""
        titleClassName="!text-[.32rem] !font-[400]"
      />
      <ScrollWrapper
        mr={false}
        noRelative={true}
        className="h-[calc(var(--app-height)-0.9rem)] p-[.32rem_.32rem_.54rem_.32rem]"
      >
        <div className=" rounded-normal px-[.2rem] pb-[.44rem]">
          {network.toLowerCase() == 'pix' ? (
            <div className="h-[1.32rem] flex items-center justify-center border-b border-dashed border-[var(--colour-6)]">
              <span className="w-[2.32rem] h-[.92rem]">
                <Icon name={IconName.PIXLogo} />
              </span>
            </div>
          ) : null}

          <div className="pt-[.32rem]">
            <div className="text-[.24rem] font-[400] leading-[.3rem] mb-[.4rem]">
              <div className="text-[var(--colour-38)] mb-[.3rem] flex">
                <div className="shrink-0 h-[.3rem] flex items-center mr-[.1rem]">
                  <span className="w-[.04rem] h-[.04rem] rounded-[50%] bg-[var(--colour-38)]"></span>
                </div>
                <span>{t('deposit-withdraw-wg.msg1')}</span>
              </div>
              <div className="text-[var(--colour-38)] mb-[.3rem] flex">
                <div className="shrink-0 h-[.3rem] flex items-center mr-[.1rem]">
                  <span className="w-[.04rem] h-[.04rem] rounded-[50%] bg-[var(--colour-38)]"></span>
                </div>
                <span>{t('deposit-withdraw-wg.msg2')}</span>
              </div>
              <div className="text-[var(--colour-26)] flex">
                <div className="shrink-0 h-[.3rem] flex items-center mr-[.1rem]">
                  <span className="w-[.04rem] h-[.04rem] rounded-[50%] bg-[var(--colour-38)]"></span>
                </div>
                <span>{t('deposit-withdraw-wg.msg3')}</span>
              </div>
            </div>
            <div className="flex flex-col items-center mb-[.86rem]">
              <div className="flex items-center justify-center mb-[.12rem]">
                <div className="text-[.44rem] font-[500] leading-[.54rem] text-[var(--colour-10)] mr-[.16rem]">
                  {websiteInfo.currency_symbol || ''} {pointToComma(amount)}
                </div>
                <div
                  className="w-[.36rem] h-[.36rem] text-[var(--colour-1)]"
                  onClick={() => {
                    copy(`${websiteInfo.currency_symbol || ''} ${pointToComma(amount)}`);
                    toast.success(t('home.toast006'), {
                      containerId: 'global',
                    });
                  }}
                >
                  <Icon name={IconName.Copy4} />
                </div>
              </div>
              <div
                className="w-[2.44rem] h-[2.44rem] mb-[.48rem] flex items-center justify-center"
                style={{ background: 'white', padding: '.16rem' }}
              >
                <QRCode value={qcURL} remSize={2.2} />
              </div>
              {/* <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-38)] mb-[.24rem]">
                Effective time 30:00s
              </div> */}
              <div
                className="min-w-[3.74rem] h-[.7rem] bg-[var(--colour-1)] flex items-center justify-center px-[.2rem] rounded-normal mb-[.16rem]"
                onClick={() => {
                  // copy(`${qcURL}`);
                  // toast.success(t('home.toast006'), {
                  //   containerId: 'global',
                  // });
                  handleCopyWithErrorReport(qcURL);
                }}
              >
                <div className="w-[.36rem] h-[.36rem] text-[var(--colour-2)] mr-[.16rem]">
                  <Icon name={IconName.Copy4} />
                </div>
                <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-2)]">
                  {t('deposit-withdraw-wg.copy1')}
                </div>
              </div>
              {/* <div className="flex items-center justify-center">
                <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-38)] mr-[.1rem]">
                  Endereço do código QR ... **6304ADA0
                </div>
                <div className="w-[.36rem] h-[.36rem] text-[var(--colour-1)]">
                  <Icon name={IconName.Copy4} />
                </div>
              </div> */}
            </div>
            {/* <div className="text-[.24rem] font-[400] leading-[.3rem]">
              <div className="flex items-center justify-between mb-[.12rem]">
                <div className="text-[var(--colour-18)]">Order status</div>
                <div className="text-[var(--colour-26)] flex items-center">
                  <div className="mr-[.16rem]">Audit Records</div>
                  <div className="w-[.36rem] h-[.36rem]">
                    <Icon name={IconName.Refresh2} />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between mb-[.14rem]">
                <div className="text-[var(--colour-18)]">Creation Time</div>
                <div className="text-[var(--colour-10)]">29/07/2024 08:59:26</div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-[var(--colour-18)]">Request number</div>
                <div className="flex items-center">
                  <div className="text-[var(--colour-10)] mr-[.16rem]">2116710220198486</div>
                  <div className="w-[.36rem] h-[.36rem] text-[var(--colour-26)]">
                    <Icon name={IconName.Copy4} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </ScrollWrapper>
    </>
  );
}
