import { getVipImg } from '../utils/helper';

const VipLevelPicture = ({ vipLevel }) => {
  return (
    <div className="w-[1.04rem] h-[1.04rem] relative">
      <img src={getVipImg(Number(vipLevel || 0))} alt="" className="not-drag" />

      <div
        className={`absolute text-[var(--colour-41)] text-[.2rem] font-[600] top-[.49rem] ${Number(vipLevel || 0) >= 10 ? 'right-[.02rem] ' : 'right-[.08rem] '}`}
      >
        V{Number(vipLevel || 0)}
      </div>
    </div>
  );
};

export default VipLevelPicture;
