import React, { useState, memo } from 'react';

const RedDot = ({ className = '' }) => {
  return (
    <span
      className={`w-[.28rem] h-[.28rem] rounded-full bg-[var(--colour-25)] border border-[var(--colour-50)] ${className}`}
    ></span>
  );
};

export default memo(RedDot);
