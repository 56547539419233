import { useState, useEffect } from 'react';
import { pxToRem } from '../utils/helper';
import { eventEmitter, EmitterEventTypes } from '../utils/eventEmitter';

/**
 * 根据窗口高度计算调整后的高度，并监听窗口大小变化
 *
 * 此函数用于获取当前浏览器窗口的高度，并根据一个偏移量进行调整，以pxToRem为单位返回高度值
 * 它会监听浏览器窗口的大小变化事件，以动态更新计算出的高度值
 *
 * @param {number} offset - 调整高度的偏移量，默认为1.6rem
 * @returns {object} - 包含调整后的高度的对象，其中的属性为h
 */

function useWindowHeight(offset = 1.6) {
  const [h, setH] = useState(pxToRem(window.innerHeight) - offset);

  useEffect(() => {
    const handleResize = () => {
      setH(pxToRem(window.innerHeight) - offset);
    };

    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resizeDelay, () => {
      handleResize();
    });
    return () => {
      unsubscribe();
    };
  }, [offset]);

  return { h };
}

export default useWindowHeight;
