import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Close from '../../close';
import Icon, { IconName } from '../../icon';
import { getShowMoney, isLogin } from '../../../utils/helper';
import { useGlobalNavigate } from '../../../contexts/navigateContext';
import { toLocalePath } from '../../../utils/localePath';
import useRouterHelper from '../../../hooks/useRouterHelper';
import LoginRegister from '../../login/entrance/index';
import DialogModal from '../index';

const PlayerBenefits = ({ handleClose = () => {}, missionList = [] }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const [check, setCheck] = useState(false);

  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if (check) {
      localStorage.setItem(
        'mission_popup_today',
        moment(new Date()).add(1, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x'),
      );
    } else {
      localStorage.removeItem('mission_popup_today');
    }
  }, [check]);

  const handleShowLoginPopup = () => {
    setShowLogin(true);
  };

  return (
    <>
      {/* 登录弹窗  */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowLogin(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showLogin}
      >
        <LoginRegister
          loginOrSignUp={1}
          handleClose={() => {
            setShowLogin(false);
          }}
        />
      </DialogModal>
      <div className="w-[6.86rem] rounded-[.16rem] bg-[var(--colour-35)] p-[.28rem_.32rem_.48rem_.32rem] relative">
        <div className="mb-[.4rem]">
          <div className="text-[var(--colour-48)] text-[.32rem] font-[700] leading-[.38rem] text-center mb-[.28rem]">
            {t('home.benefits_for_new_players')}
          </div>
          <div className="h-[1px] bg-[var(--colour-6)]"></div>
        </div>
        <div className="max-h-[6rem] scrollbar-none overflow-y-auto">
          {missionList && missionList.length > 0
            ? missionList.map((item) => {
                return item.mission_type == 1 ? (
                  <NameCpfItem key={item.id} item={item} handleShowLoginPopup={handleShowLoginPopup} />
                ) : item.mission_type == 2 ? (
                  <PhoneItem key={item.id} item={item} handleShowLoginPopup={handleShowLoginPopup} />
                ) : item.mission_type == 3 ? (
                  <EmailItem key={item.id} item={item} handleShowLoginPopup={handleShowLoginPopup} />
                ) : null;
              })
            : null}
        </div>
        <div
          className="h-[.92rem] mt-[.48rem] rounded-[.16rem] bg-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none text-center  text-[var(--colour-2)] text-[.4rem] font-[500] leading-[.92rem]"
          onClick={() => {
            if (isLogin()) {
              navigate(toLocalePath('/promotion/mission'));
            } else {
              handleShowLoginPopup();
            }
          }}
        >
          {t('home.continue')}
        </div>
        <div className="ml-[-0.32rem] absolute bottom-[-0.92rem]  w-full flex items-center justify-center">
          <div
            className="w-[.52rem] h-[.52rem] mr-[.18rem] shrink-0 cursor-pointer"
            onClick={() => {
              setCheck(!check);
            }}
          >
            {check ? <Icon name={IconName.CheckedBox} /> : <Icon name={IconName.Checkbox} />}
          </div>
          <div className="text-[var(--colour-43)] text-[.28rem] font-[400] leading-[.34rem]">
            {t('home.dont_popup_again_todayDon_missing')}
          </div>
        </div>
        <div className="absolute bottom-[-1.84rem] abs-x-center  select-none w-[.6rem] h-[.6rem] border border-[var(--colour-56)] flex justify-center items-center rounded-[50%]">
          <Close onClick={handleClose} className="!w-[.6rem] !h-[.6rem] text-[var(--colour-56)]" />
        </div>
      </div>
    </>
  );
};
export default PlayerBenefits;

const DefaultItem = ({ icon = '', text = '', click = () => {}, bonus = 0 }) => {
  const { t } = useTranslation();
  return (
    <div
      className="min-h-[1.64rem] mb-[.24rem] last-of-type:!mb-[0rem] flex flex-col justify-between rounded-[.16rem] border border-[var(--colour-6)] bg-[var(--colour-68)] shadow-[0_.04rem_.08rem_0rem_rgba(0,0,0,0.1)] p-[.22rem_.4rem_.22rem_.26rem] cursor-pointer"
      onClick={() => {
        click();
      }}
    >
      <div className="flex items-center mb-[.08rem]">
        <div className="w-[.48rem] h-[.48rem] mr-[.16rem] shrink-0 text-[var(--colour-37)]">
          <Icon name={icon} />
        </div>
        <div className="text-[var(--colour-48)] text-[.24rem] font-[500] leading-[.34rem]">{text}</div>
      </div>
      <div className="pl-[.64rem] text-[var(--colour-26)] text-[.28rem] font-[500] leading-[.4rem]">
        {t('home.bonus')} {getShowMoney(bonus)}
      </div>
    </div>
  );
};

const PhoneItem = ({ item = null, handleShowLoginPopup = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const { identity } = useSelector(
    (state) => ({
      identity: state.user.identity,
    }),
    shallowEqual,
  );

  const bonus = () => {
    return !identity
      ? item.reward_amount
      : [10, 11].includes(Number(item?.user_progress?.status))
        ? item.reward_amount
        : item.user_pending.reward_amount;
  };

  return (
    <DefaultItem
      icon={IconName.Phone3}
      text={t('home.phone_text')}
      click={() => {
        if (isLogin()) {
          if (item?.user_progress?.status == 10) {
            localStorage.setItem('data_show_popup', 'phone');
            navigate(toLocalePath('/myaccountdata'));
          } else {
            navigate(toLocalePath('/promotion/mission'));
          }
        } else {
          handleShowLoginPopup();
        }
      }}
      bonus={bonus()}
    />
  );
};

const NameCpfItem = ({ item = null, handleShowLoginPopup = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { handleClickWallet } = useRouterHelper();

  const { identity } = useSelector(
    (state) => ({
      identity: state.user.identity,
    }),
    shallowEqual,
  );

  const bonus = () => {
    return !identity
      ? item.reward_amount
      : [10, 11].includes(Number(item?.user_progress?.status))
        ? item.reward_amount
        : item.user_pending.reward_amount;
  };

  return (
    <DefaultItem
      icon={IconName.Cpf2}
      text={t('home.cpf_text')}
      click={() => {
        if (isLogin()) {
          if (item?.user_progress?.status == 10) {
            if (
              item?.user_progress?.behaviours &&
              item?.user_progress?.behaviours.includes('bind_cpf') &&
              item?.require_deposit == 1
            ) {
              handleClickWallet();
            } else {
              localStorage.setItem('data_show_popup', 'cpf');
              navigate(toLocalePath('/myaccountdata'));
            }
          } else {
            navigate(toLocalePath('/promotion/mission'));
          }
        } else {
          handleShowLoginPopup();
        }
      }}
      bonus={bonus()}
    />
  );
};

const EmailItem = ({ item = null, handleShowLoginPopup = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const { identity } = useSelector(
    (state) => ({
      identity: state.user.identity,
    }),
    shallowEqual,
  );

  const bonus = () => {
    return !identity
      ? item.reward_amount
      : [10, 11].includes(Number(item?.user_progress?.status))
        ? item.reward_amount
        : item.user_pending.reward_amount;
  };
  return (
    <DefaultItem
      icon={IconName.Vector}
      text={t('home.email_text')}
      click={() => {
        if (isLogin()) {
          if (item?.user_progress?.status == 10) {
            localStorage.setItem('data_show_popup', 'email');
            navigate(toLocalePath('/myaccountdata'));
          } else {
            navigate(toLocalePath('/promotion/mission'));
          }
        } else {
          handleShowLoginPopup();
        }
      }}
      bonus={bonus()}
    />
  );
};
