import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { get } from 'lodash';

const usePayConfig = () => {
  const {
    gift_money_menu_show_switch,
    currency_default_location,
    deposit_fiat_bouns_open_switch,
    deposit_crypto_bouns_open_switch,
    currency_decimal_point_precision,
    wallet_crypto_switch,
    switch_wallet_crypto_deposit,
  } = useSelector(
    (state) => ({
      gift_money_menu_show_switch: +get(state, 'app.websiteInitConfig.website_info.switch_gift_money_menu_show', 0),
      currency_default_location: +get(state, 'app.websiteInitConfig.website_info.currency_default_location', 0),
      deposit_fiat_bouns_open_switch: +get(
        state,
        'app.websiteInitConfig.system_config.deposit_fiat_bouns_open_switch',
        0,
      ),
      deposit_crypto_bouns_open_switch: +get(
        state,
        'app.websiteInitConfig.system_config.deposit_crypto_bouns_open_switch',
        0,
      ),
      currency_decimal_point_precision: +get(
        state,
        'app.websiteInitConfig.system_config.currency_decimal_point_precision',
        2,
      ),
      wallet_crypto_switch: +get(state, 'app.websiteInitConfig.website_info.switch_wallet_crypto_withdraw', 0),
      switch_wallet_crypto_deposit: +get(state, 'app.websiteInitConfig.website_info.switch_wallet_crypto_deposit', 0),
    }),
    shallowEqual,
  );

  return {
    gift_money_menu_show_switch,
    currency_default_location,
    deposit_fiat_bouns_open_switch,
    deposit_crypto_bouns_open_switch,
    currency_decimal_point_precision,
    wallet_crypto_switch,
    switch_wallet_crypto_deposit,
  };
};

export default usePayConfig;
