import Icon, { IconName } from '../icon';
import { useTranslation } from 'react-i18next';

export default function InputErrorTips({ className = '', showTips = false, tipsType = 0, className2 = '' }) {
  const { t } = useTranslation();
  return (
    <div
      className={`${className} my-[.1rem] h-[.2rem] text-[.16rem] space-x-[.1rem] text-[var(--colour-25)] flex flex-row items-center justify-start font-[500]`}
    >
      {showTips ? (
        <>
          <div className="flex items-center justify-center">
            <span className={`inline-block w-[.18rem] h-[.18rem] ${className2}`}>
              <Icon name={IconName.ErrorTip} />
            </span>
          </div>
          <span>
            {t(
              `login-signup.${['inputError', 'inputEmptyError', 'inputPwdError', 'inputPwdAgainError', 'inputNameErrow', 'inputCpfError', 'inputCpfnull', 'inputPhoneNull', 'inputPhoneError'][tipsType]}`,
            )}
          </span>
        </>
      ) : null}
    </div>
  );
}
