import LoginPage1 from './loginPage1';
import LoginPage2 from './loginPage2';
import LoginPage3 from './loginPage3/index';
import { getRegisterConfig, getTemplateId } from '../../../utils/helper';
import { useEffect } from 'react';

// getRegisterConfig(1) 注册配置方式区分登录
export default function Index({ handleClose = () => {}, changeType = () => {}, isShowTitle = true }) {
  const templateId = getTemplateId();

  return templateId == 'd-template003' ? (
    <LoginPage3 handleClose={handleClose} changeType={changeType} />
  ) : (
    <>
      {getRegisterConfig(1) != null ? (
        <>
          <LoginPage1 handleClose={handleClose} changeType={changeType} isShowTitle={isShowTitle} />
        </>
      ) : (
        <>
          <LoginPage2 handleClose={handleClose} changeType={changeType} isShowTitle={isShowTitle} />
        </>
      )}
    </>
  );
}
