/*
 * @Author: milo
 * @Date: 2023-07-20 10:42:16
 * @LastEditors: milo
 * @LastEditTime: 2024-07-03 15:03:16
 */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Icon, { IconName } from './icon';
import cn from 'classnames';
import { floor } from 'lodash';
import { getPercentage } from '../utils/helper';

const ProgressBar = ({
  num = 0,
  totalNum = 0,
  level = 0,
  isCurrency,
  mobileHorizontalScreenStyle = false,
  boxClassName = '',
  NoClassName = '',
  SelectClassName = 'bg-attention',
  overrideNoClassName = '',
  overrideSelectClassName = '',
}) => {
  const getFormatVal = (val, isFixed = false) => {
    let returnVal = 0;
    if (isCurrency == 1) {
      returnVal = val / 100000000;
    } else {
      returnVal = val;
    }
    if (isFixed) {
      returnVal = floor(returnVal, 2);
      const str = String(returnVal);
      if (str.indexOf('.') > -1) {
        const strArr = str.split('.');
        if (strArr[1] == '00') {
          returnVal = strArr[0];
        }
      }
    }
    return returnVal;
  };
  return (
    <div className={`flex flex-row items-center justify-start w-full ${boxClassName}`}>
      <div
        className={cn(
          overrideNoClassName != ''
            ? overrideNoClassName
            : `relative h-[.12rem]     bg-[var(--colour-51)] bg-opacity-40 rounded-normal ${NoClassName}`,
          {
            'w-[2.9rem]': !mobileHorizontalScreenStyle,
            'w-[6.8rem]': mobileHorizontalScreenStyle,
          },
        )}
      >
        <span
          className={
            overrideSelectClassName != ''
              ? overrideSelectClassName
              : `absolute left-[0] top-[0] h-[.12rem]  rounded-normal ${SelectClassName}`
          }
          style={{ width: `${getPercentage(num, totalNum) > 100 ? 100 : getPercentage(num, totalNum)}%` }}
        ></span>
        {/* <span className="absolute abs-center text-[12px]">
          {getFormatVal(num, true)}/{getFormatVal(totalNum)}
        </span> */}
      </div>
      {/* {mobileHorizontalScreenStyle ? (
        <span
          className={cn('ml-[5px]', {
            'text-[4DADF3]': num == totalNum,
          })}
        >
          {getPercentage()}%
        </span>
      ) : (
        <span
          className={cn('ml-[5px] hidden ', {
            'text-[4DADF3]': num == totalNum,
          })}
        >
          {getPercentage()}%
        </span>
      )} */}
    </div>
  );
};

export default ProgressBar;
