import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import { get } from 'lodash';
import useRegister from '../../../../hooks/useRegister';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import { useEffect, useState } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { getRegisterMethodsOption, getRegisterMethodsSort, TextPlacesName, UserCache } from '../../../../utils/helper';
import {
  deleteCPF,
  formatCPF,
  isValidCPF,
  phoneVerify,
  phoneVerifyRegex,
  phoneVerifyRegex2,
  removeSpecialSymbols,
} from '../../../../utils/inputHelper';
import moment from 'moment';
import Icon, { IconName } from '../../../icon';
import Input from '../../../input';
import InputErrorTips from '../../../inputErrorTips';
import LimitClickWrapper from '../../../limitClickWrapper';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import CaptchaInput from '../../loginPages/captchaInput';
import { getBorderColor } from '../../helper/helper';

const RegisterButton = ({ requesting, handleCheckForm, handleSubmit2, registerWithPhone }) => {
  const { t } = useTranslation();

  return (
    <>
      <LimitClickWrapper
        className={cn(
          'bg-[var(--colour-1)] flex flex-row mt-[.48rem] px-[.34rem] w-full h-[.88rem] items-center justify-center rounded-[.08rem] text-[var(--colour-2)]',
          {
            'opacity-[.7] pointer-events-none': requesting,
            'active:scale-[.95]  transition-all select-none cursor-pointer ': !requesting,
          },
        )}
        onClick={() => {
          if (!handleCheckForm()) {
            return;
          }
          if (!requesting) {
            handleSubmit2(registerWithPhone);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_RegPage_CreateAccount);
          }
        }}
      >
        {requesting ? (
          <div className="w-[.3rem] h-[.42rem] mr-[.2rem]  text-[var(--colour-2)] anticon">
            <Icon name={IconName.loadingMp3} />
          </div>
        ) : null}
        <div className="font-[600] text-[.28rem]">{t('login-signup.register_t003')}</div>
      </LimitClickWrapper>
    </>
  );
};

const CpfInput = ({
  handleInputCpfFocus,
  handleInputCpfBlur,
  formData,
  handleCpfChange,
  inputFocusedCpf,
  showInputCpfError,
  cpfErrorType,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        onFocus={handleInputCpfFocus}
        onBlur={handleInputCpfBlur}
        leftInner={
          <div className="flex items-center ">
            <span className={`block text-[var(--colour-37)] m-[0_.16rem_0_.32rem] w-[.32rem] h-[.32rem]`}>
              <Icon name={IconName.Pwd2} />
            </span>

            {/* <div className={`font-[400] text-[var(--colour-25)] mr-[.04rem] text-[.2rem] w-[.1rem]`}>
              <>*</>
            </div> */}
          </div>
        }
        value={formData.cpf}
        onChange={handleCpfChange}
        type="text"
        placeholder={t('login-signup.fill_in_the_cpf')}
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData.cpf, showInputCpfError)}`}
      />
      {showInputCpfError ? (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          className2="!w-[.24rem] !h-[.24rem]"
          showTips={showInputCpfError}
          tipsType={cpfErrorType}
        />
      ) : (
        <></>
      )}
      {!showInputCpfError ? <div className="w-full h-[.48rem]"></div> : <></>}
    </>
  );
};

const RealNameInput = ({
  handleInputNameFocus,
  handleInputNameBlur,
  formData,
  handleNameChange,
  inputFocusedName,
  showInputNameError,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        onFocus={handleInputNameFocus}
        onBlur={handleInputNameBlur}
        leftInner={
          <div className="flex items-center ">
            <span className={`block text-[var(--colour-37)] m-[0_.16rem_0_.32rem] w-[.32rem] h-[.32rem]`}>
              <Icon name={IconName.Registeruser} />
            </span>

            {/* <div className={`font-[400] text-[var(--colour-25)] mr-[.04rem] text-[.2rem] w-[.1rem]`}>
              <>*</>
            </div> */}
          </div>
        }
        value={formData.name}
        onChange={handleNameChange}
        type="text"
        placeholder={t('login-signup.fill_in_the_name')}
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData.name, showInputNameError)}`}
      />

      {showInputNameError ? (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          className2="!w-[.24rem] !h-[.24rem]"
          showTips={showInputNameError}
          tipsType={4}
        />
      ) : (
        <></>
      )}
      {!showInputNameError ? <div className="w-full h-[.48rem]"></div> : <></>}
    </>
  );
};

const ConfirmPasswordInput = ({
  handleInput4Focus,
  setInputFocused4,
  formData2,
  handleConfirmePasswordChange,
  inputFocused4,
  setFormData2,
  handleMouseDown,
  showInput4Error,
  formData,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        btnClassName="!ml-[.08rem]"
        hideEyeIconName={IconName.OpenEye}
        showEyeIconName={IconName.CloseEye}
        islightrightIcon={true}
        rightIconClassName={'!w-[.32rem] !h-[.32rem] !text-[var(--colour-37)]'}
        onFocus={handleInput4Focus}
        onBlur={() => {
          setInputFocused4(false);
        }}
        leftInner={
          <div className="flex items-center ">
            <span className={`block text-[var(--colour-37)] m-[0_.16rem_0_.32rem] w-[.32rem] h-[.32rem]`}>
              <Icon name={IconName.Registerlock} />
            </span>

            {/* <div className={`font-[400] text-[var(--colour-25)] mr-[.04rem] text-[.2rem] w-[.1rem]`}>
              {formData2.confirmPassword.length <= 0 ? <>*</> : ''}
            </div> */}
          </div>
        }
        value={formData2.confirmPassword}
        onChange={handleConfirmePasswordChange}
        type="password"
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        placeholder={t('login-signup.confirm_password')}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData2.confirmPassword, showInput4Error)}`}
        passWordBeforeInner={
          formData2.confirmPassword.length > 0 && inputFocused4 ? (
            <>
              <div
                className="text-[var(--colour-37)] w-[.32rem] h-[.32rem]"
                onClick={() => {
                  setFormData2({ ...formData2, confirmPassword: '' });
                }}
                onMouseDown={handleMouseDown}
              >
                <Icon name={IconName.InputClear}></Icon>
              </div>
            </>
          ) : null
        }
        isShowClearIcon={false}
      />
      {showInput4Error ? (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          className2="!w-[.24rem] !h-[.24rem]"
          showTips={showInput4Error}
          tipsType={
            formData2.confirmPassword != '' && formData.password != '' && formData2.confirmPassword != formData.password
              ? 3
              : 2
          }
        />
      ) : (
        <></>
      )}
      {!showInput4Error ? <div className="w-full h-[.48rem]"></div> : <></>}
    </>
  );
};

const PasswordInput = ({
  handleInput3Focus,
  setInputFocused3,
  formData,
  handlePasswordChange,
  showInput3Error,
  inputFocused3,
  handleMouseDown,
  setFormData,
  indicators,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        btnClassName="!ml-[.08rem]"
        hideEyeIconName={IconName.OpenEye}
        showEyeIconName={IconName.CloseEye}
        islightrightIcon={true}
        rightIconClassName={'!w-[.32rem] !h-[.32rem] !text-[var(--colour-37)]'}
        onFocus={handleInput3Focus}
        onBlur={() => {
          setInputFocused3(false);
        }}
        leftInner={
          <div className="flex items-center ">
            {/* <span className="block text-[var(--colour-37)] w-[.192rem] h-[.24rem] mr-[.088rem]"> */}
            <span className={`block text-[var(--colour-37)] m-[0_.16rem_0_.32rem] w-[.32rem] h-[.32rem]`}>
              <Icon name={IconName.Registerlock} />
            </span>

            {/* <div className={`font-[400] text-[var(--colour-25)] mr-[.04rem] text-[.2rem] w-[.1rem]`}>
              {formData.password.length <= 0 ? <>*</> : ''}
            </div> */}
          </div>
        }
        value={formData.password}
        onChange={handlePasswordChange}
        type="password"
        placeholder={t('login-signup.fill_in_the_password')}
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData.password, showInput3Error)}`}
        passWordBeforeInner={
          formData.password.length > 0 && inputFocused3 ? (
            <>
              <div
                onMouseDown={handleMouseDown}
                className="w-[.32rem] h-[.32rem] text-[var(--colour-37)]"
                onClick={() => {
                  setFormData({ ...formData, password: '' });
                }}
              >
                <Icon name={IconName.InputClear}></Icon>
              </div>
            </>
          ) : null
        }
        isShowClearIcon={false}
      />
      {showInput3Error ? (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          showTips={showInput3Error}
          tipsType={2}
          className2="!w-[.24rem] !h-[.24rem]"
        />
      ) : (
        <>
          <div className="w-full h-[.48rem]"></div>
        </>
      )}
      {/* <div className="flex items-center justify-start h-[.3rem]">
        <div className="text-[var(--colour-48)] font-[500] mr-[.12rem] text-[.22rem]">{t('login-signup.strength')}</div>

        {formData.password.length >= 1 ? (
          <>
            <div className="flex items-center gap-[.1rem]">
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem]`} style={{ background: indicators[0] }}></div>
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem]`} style={{ background: indicators[1] }}></div>
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem]`} style={{ background: indicators[2] }}></div>
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem]`} style={{ background: indicators[3] }}></div>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center gap-[.1rem]">
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem] bg-[#777A7D]`}></div>
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem] bg-[#777A7D]`}></div>
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem] bg-[#777A7D]`}></div>
              <div className={`w-[.8rem] h-[.12rem] rounded-[.06rem] bg-[#777A7D]`}></div>
            </div>
          </>
        )}
      </div> */}
    </>
  );
};

const UserNameInput = ({
  handleInput2Focus,
  formData2,
  setFormData2,
  handleCheckUserName,
  setInputFocused2,
  inputFocused2,
  handleMouseDown,
  showInput2Error,
  sendCodeformData,
  setSendCodeformData,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        onFocus={handleInput2Focus}
        onBlur={() => {
          setInputFocused2(false);
        }}
        leftInner={
          <div className="flex items-center ">
            {/* <span className="block mr-[.056rem] w-[.24rem] h-[.24rem] text-[var(--colour-37)]"> */}
            <span className={`block text-[var(--colour-37)] m-[0_.16rem_0_.32rem] w-[.32rem] h-[.32rem]`}>
              <Icon name={IconName.Registeruser} />
            </span>

            {/* <div className={`font-[400] text-[.2rem] mr-[.04rem] w-[.1rem] text-[var(--colour-25)]`}>
              {formData2.userName.length <= 0 ? <>*</> : ''}
            </div> */}
          </div>
        }
        value={formData2.userName}
        onChange={(ev) => {
          const val = ev.target.value;
          setFormData2({ ...formData2, userName: val });
          setSendCodeformData({ ...sendCodeformData, mobile: val });
          handleCheckUserName(val);
        }}
        placeholder={t('login-signup.fillin-username')}
        type="text"
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData2.userName, showInput2Error)}`}
        rightInner={
          formData2.userName.length > 0 && inputFocused2 ? (
            <>
              <div
                onMouseDown={handleMouseDown}
                className="text-[var(--colour-37)] w-[.32rem] h-[.32rem]"
                onClick={() => {
                  setFormData2({ ...formData2, userName: '' });
                }}
              >
                <Icon name={IconName.InputClear}></Icon>
              </div>
            </>
          ) : null
        }
        isShowClearIcon={false}
      />
      {showInput2Error ? (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          showTips={showInput2Error}
          tipsType={0}
          className2="!w-[.24rem] !h-[.24rem]"
        />
      ) : (
        <></>
      )}
      {!showInput2Error ? <div className="w-full h-[.48rem]"></div> : <></>}
    </>
  );
};

const PhoneNumberInput = ({
  handleInput1Blur,
  websiteInitConfig,
  formData,
  setFormData,
  sendCodeformData,
  setSendCodeformData,
  handleCheckEmailOrPhone,
  handleCheckEmailOrPhone2,
  setInputFocused1,
  inputFocused1,
  handleMouseDown,
  showInput1Error,
  phoneErrorType,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        onBlur={handleInput1Blur}
        leftInner={
          <div className="flex items-center ">
            {/* <span className="w-[.38rem] h-[.26rem] mr-[.11rem] block"> */}
            <span
              className={`block text-[var(--colour-37)] m-[0_0_0_.32rem] w-[.4rem] h-[.4rem] rounded-full overflow-hidden shrink-0`}
            >
              {<Icon name={IconName.Registercountry} />}
            </span>
            <div className="text-[var(--colour-44)] font-[600] text-[.24rem] ml-[.04rem]">
              +{websiteInitConfig.website_info.country_code || ''}
            </div>

            <div className="w-[1px] h-[.32rem] m-[0_.2rem_0_.18rem] bg-[var(--colour-41)]"></div>

            {/* <div className={`font-[400] text-[var(--colour-25)] text-[.2rem] w-[.1rem] mr-[.04rem]`}>
                {formData.mobile.length <= 0 ? <>*</> : ''}
              </div> */}
          </div>
        }
        value={formData.mobile}
        onChange={(ev) => {
          const val = ev.target.value;
          let regx = websiteInitConfig?.website_info?.country_code == '55' ? phoneVerifyRegex : phoneVerifyRegex2;
          if (regx.test(val)) {
            setFormData({ ...formData, mobile: val });
            setSendCodeformData({ ...sendCodeformData, mobile: val });
            if (websiteInitConfig?.website_info?.country_code == '55') {
              handleCheckEmailOrPhone(val);
            } else {
              handleCheckEmailOrPhone2(val);
            }
          }
        }}
        placeholder={t('login-signup.phonenumber')}
        type="text"
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        // topClassName={`rounded-[.16rem] overflow-hidden bg-[transparent] border ${inputFocused1 ? '!border-[var(--colour-1)]' : '!border-[var(--colour-6)]'} h-[.7rem]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData.mobile, showInput1Error)}`}
        onFocus={() => {
          setInputFocused1(true);
        }}
        rightInner={
          formData.mobile.length > 0 && inputFocused1 ? (
            <>
              <div
                onMouseDown={handleMouseDown}
                className="text-[var(--colour-37)] w-[.32rem] h-[.32rem]"
                onClick={() => {
                  setFormData({ ...formData, mobile: '' });
                }}
              >
                <Icon name={IconName.InputClear}></Icon>
              </div>
            </>
          ) : null
        }
        isShowClearIcon={false}
      />
      {showInput1Error ? (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          className2="!w-[.24rem] !h-[.24rem]"
          showTips={showInput1Error}
          tipsType={websiteInitConfig?.website_info?.country_code == '55' ? phoneErrorType : 1}
        />
      ) : (
        <></>
      )}

      {!showInput1Error ? <div className="w-full h-[.48rem]"></div> : <></>}
    </>
  );
};

const RegisterForm = ({ changeLogin, handleClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const {
    inviteCode,
    activityId,
    osType,
    advertisingId,
    loginStatus,
    websiteInitConfig,
    dialogModalProp,
    defaultLang,
  } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.inviteCode', ''),
      activityId: get(state, 'user.activityId', ''),
      osType: get(state, 'user.osType', ''),
      advertisingId: get(state, 'user.advertisingId', ''),
      loginStatus: state.app.loginStatus,
      websiteInitConfig: state.app.websiteInitConfig,
      dialogModalProp: state.app.dialogModalProp,
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const {
    hasBeenSent,
    requesting,
    inputType,
    sendCodeformData,
    formData,
    captchaImg,
    setInputType,
    setHasBeenSent,
    setSendCodeformData,
    handleSendCode,
    handleSubmit2,
    handleReReqCaptcha,
    setFormData,
    formData2,
    setFormData2,
    captchaInitConfig,
  } = useRegister();

  const { handleClickManMade } = useHandleLogic();

  const [inputFocused1, setInputFocused1] = useState(false);
  const [inputFocused2, setInputFocused2] = useState(false);
  const [inputFocused3, setInputFocused3] = useState(false);
  const [inputFocused4, setInputFocused4] = useState(false);
  const [inputFocused5, setInputFocused5] = useState(false);
  const [inputFocusedName, setInputFocusedName] = useState(false);
  const [inputFocusedCpf, setInputFocusedCpf] = useState(false);

  const [showInput1Error, setShowInput1Error] = useState(false);
  const [showInput2Error, setShowInput2Error] = useState(false);
  const [showInput3Error, setShowInput3Error] = useState(false);
  const [showInput4Error, setShowInput4Error] = useState(false);
  const [showInputNameError, setShowInputNameError] = useState(false);
  const [showInputCpfError, setShowInputCpfError] = useState(false);
  const [showInviteCode, setShowInviteCode] = useState(false);
  const [inputTypeList, setInputTypeList] = useStateIfMounted([]);
  const emailRegisterEnable = false;
  const [showNameCpf, setShowNameCpf] = useState(false);
  const [cpfErrorType, setCpfErrorType] = useState(6);
  const [phoneErrorType, setPhoneErrorType] = useState(7); //手机号红色提示

  const readCache = false;
  const [phoneValue, setPhoneValue] = useState(readCache ? UserCache.getPhone() : '');
  const [emailValue, setEmailValue] = useState(readCache ? UserCache.getEmail() : '');
  const [userNameValue, setUserNameValue] = useState(readCache ? UserCache.getUserName() : '');
  const registerWithPhone = getRegisterMethodsOption('2', 'with_phone') == '1';

  useEffect(() => {
    if (inputType == UserCache.LoginMethodValue.Email) {
      setEmailValue(formData.mobile);
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      setPhoneValue(formData.mobile);
    } else if (inputType == UserCache.LoginMethodValue.UserName) {
      setUserNameValue(formData.mobile);
    }
  }, [formData.mobile]);

  const onChangeRegText = (val) => {
    setFormData({ ...formData, mobile: val });
    setSendCodeformData({ ...sendCodeformData, mobile: val });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (inputType == UserCache.LoginMethodValue.Email) {
      onChangeRegText(emailValue);
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      onChangeRegText(phoneValue);
    } else if (inputType == UserCache.LoginMethodValue.UserName) {
      onChangeRegText(userNameValue);
    }
  }, [inputType]);

  const [indicators, setIndicators] = useState(['#777A7D', '#777A7D', '#777A7D', '#777A7D']);
  const handlePasswordChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/[^0-9a-zA-Z]/g, '').slice(0, 24);
    setFormData({ ...formData, password: v2 });
    handleCheckInput3(v2);

    let newStrength = 0;

    const hasMinLength = v2.length >= 8;
    const hasUpperCase = /[A-Z]/.test(v2);
    const hasLowerCase = /[a-z]/.test(v2);
    const hasNumber = /\d/.test(v2);

    // 强度1检查
    if (hasLowerCase || hasUpperCase || hasNumber || !hasMinLength) {
      newStrength = 1;
    }

    // 强度2检查
    if ((hasLowerCase && hasUpperCase) || (hasLowerCase && hasNumber) || (hasUpperCase && hasNumber)) {
      newStrength = 2;
    }

    // 强度3检查
    if (hasLowerCase && hasUpperCase && hasNumber && v2.length <= 12) {
      newStrength = 3;
    }

    // 强度4检查
    if (hasLowerCase && hasUpperCase && hasNumber && v2.length > 12) {
      newStrength = 4;
    }

    let newIndicators = ['#777A7D', '#777A7D', '#777A7D', '#777A7D'];

    switch (newStrength) {
      case 0:
        newIndicators = ['#E84C67', '#777A7D', '#777A7D', '#777A7D'];
        break;
      case 1:
        newIndicators = ['#E84C67', '#777A7D', '#777A7D', '#777A7D'];
        break;
      case 2:
        newIndicators = ['#FFD600', '#FFD600', '#777A7D', '#777A7D'];
        break;
      case 3:
        newIndicators = ['#FFD600', '#FFD600', '#FFD600', '#777A7D'];
        break;
      case 4:
        newIndicators = ['#62E94C', '#62E94C', '#62E94C', '#62E94C'];
        break;
    }

    setIndicators(newIndicators);
  };
  const handleConfirmePasswordChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/[^0-9a-zA-Z]/g, '').slice(0, 24);
    setFormData2({ ...formData2, confirmPassword: v2 });
    handleCheckInput4(v2);
  };

  const handleNameChange = (ev) => {
    const v = ev.target.value;
    let v2 = removeSpecialSymbols(TextPlacesName(v, 100));
    setFormData({ ...formData, name: v2 });
    handleCheckInputName(v2);
  };

  const handleCpfChange = (event) => {
    const cpfAllowedCharacters = /[^0-9.-]+/g;
    const value = event.target.value.replace(cpfAllowedCharacters, '');
    const lastValue = formData.cpf;
    if (String(value).length < String(lastValue).length) {
      //如果是删除
      // const cpf = deleteCPF(lastValue);
      const cpf = String(value) != '' ? deleteCPF(lastValue) : '';
      setFormData({ ...formData, cpf: cpf });
      handleCheckInputCpf(cpf.replace(/[\.-]/g, ''));
    } else {
      let cpf = formatCPF(value.replace(/[\.-]/g, ''));
      const maxLength = 11 + 3;
      if (cpf.length > maxLength) {
        // 截断输入的值
        cpf = cpf.slice(0, maxLength);
      }
      setFormData({ ...formData, cpf: cpf });
      handleCheckInputCpf(cpf.replace(/[\.-]/g, ''));
    }
  };

  const handleSetInputType = (t) => {
    setInputType(t);
  };

  const onChangeCryCode = (ev) => {
    setSendCodeformData({ ...sendCodeformData, cryCode: ev.target.value });
  };

  // useEffect(() => {
  //   if (inputType == 'phone' && websiteInitConfig.system_config.registerCode === 1) {
  //     handleReReqCaptcha();
  //   }
  // }, [inputType, websiteInitConfig.system_config.registerCode]);

  const handleBirthdayChange = (date) => {
    setFormData({
      ...formData,
      birthDate: moment(date).format('YYYY/MM/DD'),
    });
  };
  const handleFirstNameChange = (event) => {
    const firstName = event.target.value;
    setFormData({
      ...formData,
      firstName,
    });
  };
  const handleLastNameChange = (event) => {
    const lastName = event.target.value;
    setFormData({
      ...formData,
      lastName,
    });
  };

  useEffect(() => {
    const defaultInputTypes = getRegisterMethodsSort();
    const registerMethodsSort =
      defaultInputTypes.length > 0 ? defaultInputTypes : [`${UserCache.LoginMethodValue.UserName}`];
    const showTypeList = registerMethodsSort.map((method) => {
      return {
        key: method,
        icon: (() => {
          switch (method) {
            case UserCache.LoginMethodValue.Phone:
              return IconName.Phone;
            case UserCache.LoginMethodValue.Email:
              return IconName.Email;
            case UserCache.LoginMethodValue.UserName:
              return IconName.LoginAccount;
          }
        })(),
        show: (() => {
          return true;
        })(),
        text: (() => {
          switch (method) {
            case UserCache.LoginMethodValue.Phone:
              return t('login-signup.phone');
            case UserCache.LoginMethodValue.Email:
              return t('login-signup.email');
            case UserCache.LoginMethodValue.UserName:
              return t('login-signup.Username');
          }
        })(),
      };
    });

    setInputTypeList(showTypeList);

    // if (!UserCache.getLoginMethod()) {
    const firstShow = showTypeList[0];
    setInputType(firstShow.key);
    // }

    if (
      websiteInitConfig?.system_config?.register_config?.platform &&
      websiteInitConfig?.system_config?.register_config?.platform.length > 0
    ) {
      let findPlatform = websiteInitConfig?.system_config?.register_config?.platform.find((item) => {
        return item.method == 2;
      });
      if (findPlatform && findPlatform.required_cpf_name == 1) {
        setShowNameCpf(true);
      }
    }
  }, [websiteInitConfig?.system_config?.register_config?.platform]);

  const handleCheckUserName = (value) => {
    const regex = /^[a-zA-Z0-9]{6,24}$/;
    if (!regex.test(value)) {
      setShowInput2Error(true);
    } else {
      setShowInput2Error(false);
    }
  };

  const handleCheckInput3 = (value) => {
    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(value)) {
      setShowInput3Error(true);
    } else {
      setShowInput3Error(false);
    }
  };

  const handleCheckInput4 = (value) => {
    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(value) || (value != formData.password && formData.password != '')) {
      setShowInput4Error(true);
    } else {
      setShowInput4Error(false);
    }
  };

  const handleCheckInputName = (value) => {
    const regex = /^(?!.* {2,})[^'"\n\r\t\\]*[\s]?[^'"\n\r\t\\]*$/;
    if (!regex.test(value) || value == '') {
      setShowInputNameError(true);
    } else {
      setShowInputNameError(false);
    }
  };

  const handleCheckInputCpf = (value) => {
    if (value == '') {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else if (value.length < 11 || !/^\d+$/.test(value)) {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else {
      setShowInputCpfError(false);
    }
  };

  const handleCheckEmailOrPhone = (value) => {
    //邮箱手机号注册才做失去焦点的判断。
    if (value == '') {
      setPhoneErrorType(7);
      setShowInput1Error(true);
    } else if (!phoneVerify(value)) {
      setPhoneErrorType(8);
      setShowInput1Error(true);
    } else {
      setShowInput1Error(false);
    }
  };

  const handleCheckEmailOrPhone2 = (value) => {
    //邮箱手机号注册才做失去焦点的判断。
    if (value == '') {
      setShowInput1Error(true);
    } else {
      setShowInput1Error(false);
    }
  };

  const handleInput1Blur = (evt) => {
    if (websiteInitConfig?.website_info?.country_code == '55') {
      handleCheckEmailOrPhone(evt.target.value);
    } else {
      handleCheckEmailOrPhone2(evt.target.value);
    }

    setInputFocused1(false);
  };

  const handleInput2Focus = (evt) => {
    handleCheckUserName(evt.target.value);
    setInputFocused2(true);
  };

  const handleInput3Focus = (evt) => {
    handleCheckInput3(evt.target.value);
    setInputFocused3(true);
  };

  const handleInput4Focus = (evt) => {
    handleCheckInput4(evt.target.value);
    setInputFocused4(true);
  };

  const handleInputNameFocus = (evt) => {
    handleCheckInputName(evt.target.value);
    setInputFocusedName(true);
  };
  const handleInputCpfFocus = (evt) => {
    handleCheckInputCpf(evt.target.value.replace(/[\.-]/g, ''));
    setInputFocusedCpf(true);
  };

  const handleInputNameBlur = (evt) => {
    setInputFocusedName(false);
    let v = evt.target.value;
    v = v.trim();
    setFormData({ ...formData, name: v });
    handleCheckInputName(v);
  };

  const handleInputCpfBlur = (evt) => {
    setInputFocusedCpf(false);
    let v = evt.target.value;
    const isTrueCPF = isValidCPF(v);
    if (v.replace(/[\.-]/g, '') == '') {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else if (v.replace(/[\.-]/g, '').length < 11 || !/^\d+$/.test(v.replace(/[\.-]/g, ''))) {
      setCpfErrorType(6);
      setShowInputCpfError(true);
    } else if (!isTrueCPF) {
      setCpfErrorType(5);
      setShowInputCpfError(true);
    } else {
      setShowInputCpfError(false);
    }
  };

  const handleCheckForm = () => {
    let returnValue = true;
    if (registerWithPhone) {
      if (websiteInitConfig?.website_info?.country_code == '55') {
        if (formData.mobile == '') {
          setPhoneErrorType(7);
          setShowInput1Error(true);
          returnValue = false;
        } else if (!phoneVerify(formData.mobile)) {
          setPhoneErrorType(8);
          setShowInput1Error(true);
          returnValue = false;
        } else {
          setShowInput1Error(false);
        }
      } else {
        if (formData.mobile == '') {
          setShowInput1Error(true);
          returnValue = false;
        } else {
          setShowInput1Error(false);
        }
      }
    }

    const regex = /^[a-zA-Z0-9]{6,24}$/;
    if (!regex.test(formData2.userName)) {
      setShowInput2Error(true);
      returnValue = false;
    } else {
      setShowInput2Error(false);
    }

    const regex2 = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex2.test(formData.password)) {
      setShowInput3Error(true);
      returnValue = false;
    } else {
      setShowInput3Error(false);
    }

    if (
      !regex2.test(formData2.confirmPassword) ||
      (formData2.confirmPassword != '' && formData.password != '' && formData2.confirmPassword != formData.password)
    ) {
      setShowInput4Error(true);
      returnValue = false;
    } else {
      setShowInput4Error(false);
    }

    if (showNameCpf) {
      const regex3 = /^(?!.* {2,})[^'"\n\r\t\\]*[\s]?[^'"\n\r\t\\]*$/;
      if (!regex3.test(formData.name) || formData.name == '') {
        setShowInputNameError(true);
        returnValue = false;
      } else {
        setShowInputNameError(false);
      }

      if (formData.cpf == '') {
        setCpfErrorType(6);
        setShowInputCpfError(true);
        returnValue = false;
      } else if (formData.cpf.replace(/[\.-]/g, '').length < 11 || !/^\d+$/.test(formData.cpf.replace(/[\.-]/g, ''))) {
        setCpfErrorType(6);
        setShowInputCpfError(true);
        returnValue = false;
      } else if (!isValidCPF(formData.cpf)) {
        setCpfErrorType(5);
        setShowInputCpfError(true);
        returnValue = false;
      } else {
        setShowInputCpfError(false);
      }
    }

    if (
      showInput1Error ||
      showInput2Error ||
      showInput3Error ||
      showInput4Error ||
      showInputNameError ||
      showInputCpfError
    ) {
      returnValue = false;
    }

    return returnValue;
  };

  useEffect(() => {
    handleReReqCaptcha();
  }, []);

  return (
    <div className="flex flex-col mt-[.44rem]">
      {registerWithPhone && (
        // {true && (
        <PhoneNumberInput
          handleInput1Blur={handleInput1Blur}
          websiteInitConfig={websiteInitConfig}
          formData={formData}
          setFormData={setFormData}
          sendCodeformData={sendCodeformData}
          setSendCodeformData={setSendCodeformData}
          handleCheckEmailOrPhone={handleCheckEmailOrPhone}
          handleCheckEmailOrPhone2={handleCheckEmailOrPhone2}
          setInputFocused1={setInputFocused1}
          inputFocused1={inputFocused1}
          handleMouseDown={handleMouseDown}
          showInput1Error={showInput1Error}
          phoneErrorType={phoneErrorType}
        />
      )}

      <UserNameInput
        handleInput2Focus={handleInput2Focus}
        formData2={formData2}
        setFormData2={setFormData2}
        handleCheckUserName={handleCheckUserName}
        setInputFocused2={setInputFocused2}
        inputFocused2={inputFocused2}
        handleMouseDown={handleMouseDown}
        showInput2Error={showInput2Error}
        sendCodeformData={sendCodeformData}
        setSendCodeformData={setSendCodeformData}
      />

      <PasswordInput
        handleInput3Focus={handleInput3Focus}
        setInputFocused3={setInputFocused3}
        formData={formData}
        handlePasswordChange={handlePasswordChange}
        showInput3Error={showInput3Error}
        inputFocused3={inputFocused3}
        handleMouseDown={handleMouseDown}
        setFormData={setFormData}
        indicators={indicators}
      />

      <ConfirmPasswordInput
        handleInput4Focus={handleInput4Focus}
        setInputFocused4={setInputFocused4}
        formData2={formData2}
        handleConfirmePasswordChange={handleConfirmePasswordChange}
        inputFocused4={inputFocused4}
        setFormData2={setFormData2}
        handleMouseDown={handleMouseDown}
        showInput4Error={showInput4Error}
        formData={formData}
      />

      {showNameCpf && (
        // {true && (
        <>
          <RealNameInput
            handleInputNameFocus={handleInputNameFocus}
            handleInputNameBlur={handleInputNameBlur}
            formData={formData}
            handleNameChange={handleNameChange}
            inputFocusedName={inputFocusedName}
            showInputNameError={showInputNameError}
          />

          <CpfInput
            handleInputCpfFocus={handleInputCpfFocus}
            handleInputCpfBlur={handleInputCpfBlur}
            formData={formData}
            handleCpfChange={handleCpfChange}
            inputFocusedCpf={inputFocusedCpf}
            showInputCpfError={showInputCpfError}
            cpfErrorType={cpfErrorType}
          />
        </>
      )}

      {captchaInitConfig?.register_user_validation_mode ==
      'geetest' ? null : captchaInitConfig?.register_user_validation_mode == 'image' ? (
        <div className="mt-[.06rem]">
          <CaptchaInput
            onFocus={() => {
              setInputFocused5(true);
            }}
            onBlur={() => {
              setInputFocused5(false);
            }}
            className="mt-[.16rem]"
            iconClassName={`!w-[.44rem] !h-[.44rem] !m-[0_.12rem_0_.24rem] !text-[var(--colour-37)]`}
            inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
            innerClassName={`!h-[.88rem] !p-[0_.08rem_0_0]`}
            topClassName={`!rounded-[.08rem] !bg-[var(--colour-22)] border !border-[var(--colour-6)]`}
            imgClassName="w-[1.7rem] ml-[.08rem]"
            captchaInfo={captchaInitConfig}
            value={formData.cryCode}
            onChange={(ev) => {
              setFormData({ ...formData, cryCode: ev.target.value });
            }}
            handleReReqCaptcha={() => {
              handleReReqCaptcha();
            }}
          />
        </div>
      ) : null}

      <RegisterButton
        requesting={requesting}
        handleCheckForm={handleCheckForm}
        handleSubmit2={handleSubmit2}
        registerWithPhone={registerWithPhone}
      />
    </div>
  );
};

export default RegisterForm;
