import { useState, useEffect, useRef, createRef } from 'react';
import {
  removeLoadingPage,
  isLogin,
  GetLayoutChildrenKeysByServer,
  isLightTheme,
  cmsImgUrl,
} from '../../../../utils/helper';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';
import { get, cloneDeep } from 'lodash';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setTagIndex,
  setAutoScrollToTagGameKey,
  ResponsiveMode,
  setDrawerMenuVisible,
  setReloadWebsiteInitData,
} from '../../../../redux/reducers/appSlice';
import Loading from '../../../../components/loading2';
import HomeSwiperBanner from '../../components/homeBanner';
import ContainerWrapper from '../components/containerWrapper';
import RecentlyListAll from '../../../../components/recentlyListAll';
import HomeGameList from '../../../../components/homeGameList';
import CategoryGame from '../../../../components/categoryGame';
import SkeletonBox from '../../../../components/skeletonBox';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../components/icon';
import { createPortal } from 'react-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { toLocalePath, toNoLocalePath } from '../../../../utils/localePath';
import DHomeGameList from './components/dHomeGameList';
import Jackpot from '../../../../components/jackpot';
import scrollIntoViewS from 'scroll-into-view';
import Broadcast from '../../../../components/home/broadcast';
import Sticky from 'react-sticky-el';
import HomeModuleHeader from '../../../../components/homeModuleHeader';
import { useInView } from 'react-intersection-observer';
import HomeLinkBox from '../../../../components/homeLinkBox';
import useLangServerConfig from '../../../../hooks/useLangServerConfig';
import DTournament from '../../../../components/tournament/DTournament';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const viewAllList = ['1003', '1015', '003', '001']; //游戏列表显示viewAll按钮

const HeaderHeight = 45; //header高度
const MenuBarHeight = 62; //menubar高度
const TagSearchHeight = 53; //tagsearch高度

export default function DTemplate001() {
  const modules = ['HomeAd', 'Jackpot', 'Marquee', 'Tournament'];
  const [modulesSortdata, setModulesSortdata] = useState([]);
  const { getServerLangText } = useLangServerConfig();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: '-35% 0px 0px 0px',
  });
  const [isNearBottom, setIsNearBottom] = useState(false);
  const isFirstLoadRef = useRef(true);
  const isFirstLoadData = useRef(true); //是否是首次数据加载，(websiteInitData数据更新后，会设置选中的index)

  const {
    responsiveMode,
    defaultLang,
    identity,
    tourRedDot,
    tagIndex,
    websiteInitData,
    isSportEnabled,
    websiteInitConfig,
  } = useSelector(
    (state) => ({
      responsiveMode: state.app.responsiveMode,
      defaultLang: state.user.defaultLang,
      identity: state.user.identity,
      tourRedDot: get(state, 'app.activityStatus.activity_tournament'),
      tagIndex: state.app.tagIndex,
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
      isSportEnabled: state.app.isSportEnabled,
    }),
    shallowEqual,
  );

  const [loading, setLoading] = useStateIfMounted(true);
  const [serverConfig, setServerConfig] = useStateIfMounted([]);
  const [isTagSearchAutoScroll, setIsTagSearchAutoScroll] = useState(false); //是否需要tagSearch滚动到选中的item
  const [isProvider, setIsProvider] = useState(false); //是否根据provider筛选游戏
  const [providerSelect, setProviderSelect] = useState(''); //当前选中的游戏厂商
  const [isSearch, setIsSearch] = useState(false); //是否展示搜索结果
  const [searchVal, setSearchVal] = useState(''); //搜索的value值
  // const [viewAllList, setViewAllList] = useState([]); //显示view按钮的类别

  const [moduleConfigList, setModuleConfigList] = useState([]); //处理完之后的排序列表
  const [bannerList, setBannerList] = useState([]); //banner数据列表
  const [tagsArr, setTagsArr] = useState([]); //游戏类型标签列表
  const [tagsArrOpacity, setTagsArrOpacity] = useState(false);

  const [notClickList] = useState([
    {
      game_type_id: '001',
      icon: IconName.Favorite,
      needLogin: true,
      isSwitch: true,
      isMask: false,
      isName: 'home.category_001',
      isPrivate: true,
    },
    {
      game_type_id: '003',
      icon: IconName.Rencent,
      needLogin: true,
      isSwitch: true,
      isMask: false,
      isName: 'home.category_003',
      isPrivate: true,
    },
    // {
    //   game_type_id: '-3',
    //   needLogin: false,
    //   isSwitch: false,
    //   isMask: false,
    //   isName: 'home.drawer_jackpot',
    //   isPrivate: true,
    // },
  ]); //前端固定要加上的三条数据，要和侧导航栏保持数据一致

  const [data, setData] = useState([]);

  useEffect(() => {
    if (tourRedDot == '1') {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.TourClaim, zIndex: 10000 }));
    }
  }, [tourRedDot]);

  useEffect(() => {
    if (websiteInitData && websiteInitData.template) {
      let data = cloneDeep(websiteInitData.template);
      const found = data.find((item) => item.module === 'HomeTagSearch');

      const arr = [];
      for (let i = 0; i < data.length; i++) {
        if (modules.includes(data[i].module)) {
          arr.push(data[i]);
        }
      }
      setModulesSortdata(arr);

      data = [
        ...data,
        {
          module: 'Broadcast',
          sort: 99987,
        },
        ...(found ? [] : [{ module: 'HomeTagSearch', sort: 99989 }]),
        {
          module: 'RecentlyListAll',
          sort: 99990,
        },
        {
          module: 'HomeGameList',
          sort: 99991,
        },
        {
          module: 'DownLoadBtn',
          sort: 99992,
        },
      ];

      setModuleConfigList(data);

      const arrData = websiteInitData.template.find((item) => {
        return item.module == 'ColumnData';
      });
      if (arrData) {
        let arr = arrData.config.map((item) => {
          return { config: item, data: item.data };
        });

        setData(arr);
        let resData = arrData.config.map((item) => {
          return { ...item, isSwitch: true, isMask: false };
        });
        resData = resData.concat(notClickList);
        setTagsArr(resData);
        //第一次数据加载的时候设置默认选中第一个标签
        if (isFirstLoadData.current == true) {
          dispatch(setTagIndex(resData[0].game_type_id));
        }
        isFirstLoadData.current = false;
      }
    }
    if (websiteInitData && websiteInitData.home_banner) {
      setBannerList(websiteInitData.home_banner);
    }
  }, [websiteInitData]);

  const updateList = () => {};

  useEffect(updateList, [defaultLang, identity, tagsArr]);

  const tagsResIndex = (res) => {
    setProviderSelect('');
    setIsProvider(false);
    setSearchVal('');
    setIsSearch(false);
    if (isLogin() && res === tagsArr[0].game_type_id && isFirstLoadRef.current == false) {
      updateList();
    }
    isFirstLoadRef.current = false;
  };

  const homeProviderRes = (res) => {
    setTagsIndex('002');
    setSearchVal('');
    setIsSearch(false);
    setProviderSelect(res);
    setIsProvider(true);
  };

  const tagsSearchRes = (res) => {
    setTagsIndex('002');
    setProviderSelect('');
    setIsProvider(false);
    setSearchVal(res);
    setIsSearch(true);
  };

  const setTagsIndex = (index) => {
    dispatch(setTagIndex(index));
  };

  useEffect(() => {
    if (!identity) {
      if (tagIndex == '001' || tagIndex == '003') {
        if (tagsArr.length > 0) {
          setTagsIndex(tagsArr[0].game_type_id);
          tagsResIndex(tagsArr[0].game_type_id);
        }
      }
    }
  }, [identity]);

  // useEffect(() => {
  //   if (needCheckedLobby.key != 0) {
  //     tagsResIndex('000');
  //     setTagsIndex('000');
  //     window.scrollTo({
  //       top: 0,
  //       behavior: 'smooth',
  //     });
  //   }
  // }, [needCheckedLobby]);

  const handleCategoryGameViewAll = (id) => {
    dispatch(setAutoScrollToTagGameKey(id));
  };

  useEffect(() => {
    if (isTagSearchAutoScroll) {
      setIsTagSearchAutoScroll(false);
      dispatch(setAutoScrollToTagGameKey(tagIndex));
    }
  }, [tagIndex, isTagSearchAutoScroll]);

  useEffect(() => {
    if (moduleConfigList.length > 0) {
      removeLoadingPage();
    }
  }, [moduleConfigList]);

  const handleClickGameCategory = (catetoryId) => {
    setTagsIndex(catetoryId);
    tagsResIndex(catetoryId);
  };

  const handleProviderClick = (item, dataItem) => {
    //是体育
    let url = `/gamelist?id=${item.game_type_id}&source=${dataItem.source}&sourceName=${getServerLangText(item.lang_key)}&img=${toBtoa(item)}`;
    if (item.game_type_id == '999') {
      if (isSportEnabled) {
        url = '/sport';
      } else {
        return;
      }
    }
    navigate(toLocalePath(url));
  };

  const handleProviderClickHeader = (item) => {
    //是体育
    let url = `/gamelist?id=${item.game_type_id}&source='999'&sourceName=${getServerLangText(item.lang_key)}&img=${toBtoa(item)}`;
    if (item.game_type_id == '999') {
      if (isSportEnabled) {
        url = '/sport';
      } else {
        return;
      }
    }
    navigate(toLocalePath(url));
  };

  // useEffect(() => {
  //   setTagsArrOpacity(inView ? false : true);
  // }, [inView]);

  useEffect(() => {
    setTagsArrOpacity(isNearBottom ? true : false);
  }, [isNearBottom]);

  useEffect(() => {
    const handleScroll = (e) => {
      const { viewport } = e.detail.elements();
      const { scrollTop, scrollHeight } = viewport; // Get scroll offset
      const scrollPosition = scrollTop + window.innerHeight;
      if (scrollPosition >= scrollHeight - 100) {
        setIsNearBottom(true);
      } else {
        setIsNearBottom(false);
      }
    };

    document.addEventListener('overlayScrollerCustomScroll', handleScroll);
    return () => {
      document.removeEventListener('overlayScrollerCustomScroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div ref={ref} className="flex flex-col space-y-[.24rem] px-[.16rem] pt-[.2rem] pb-[.4rem]">
        <div>
          {bannerList.length > 0 ? (
            websiteInitConfig?.website_info?.website_banner_type == '3' ? (
              <div className="flex flex-col space-y-[.24rem]">
                <HomeSwiperBanner bannerType="3" swiperList={bannerList} />
              </div>
            ) : (
              <ContainerWrapper ispb={false} isborderBottom={false}>
                <HomeSwiperBanner
                  bannerType={websiteInitConfig?.website_info?.website_banner_type}
                  swiperList={bannerList}
                />
              </ContainerWrapper>
            )
          ) : (
            <></>
          )}
          {moduleConfigList.length > 0 ? (
            <div className="flex flex-col space-y-[0px] py-[.18rem] w-full m-[0_auto] relative px-[.08rem] !pb-[0px]">
              {modulesSortdata.findIndex((item) => {
                return item.module == 'Marquee';
              }) > -1 ? (
                <Broadcast isAnimation={true} />
              ) : null}

              {modulesSortdata.map((item, index) => {
                switch (item.module) {
                  case 'HomeAd': {
                    return (
                      <div className="pt-[.18rem]" key={index}>
                        <HomeLinkBox />
                      </div>
                    );
                  }

                  case 'Jackpot': {
                    return (
                      <div className="flex justify-center pt-[.18rem]" key={index}>
                        <Jackpot isDebug={false} handleClickGameCategory={handleClickGameCategory} />
                      </div>
                    );
                  }
                  case 'Tournament': {
                    return (
                      <div key={index} className="pt-[.18rem]">
                        <DTournament />
                      </div>
                    );
                  }
                }
              })}

              {moduleConfigList.map((item, index) => {
                switch (item.module) {
                  case 'HomeTagSearch':
                    return (
                      tagsArr.length > 0 && (
                        <ContainerWrapper
                          key={index}
                          ispb={false}
                          isborderBottom={false}
                          className="!mt-[.36rem] !w-[calc(100%+.48rem)] !ml-[-0.24rem]"
                        >
                          <TagSearch
                            tagsArr={tagsArr}
                            tagsIndex={tagIndex}
                            setTagsIndex={setTagsIndex}
                            tagsResIndex={tagsResIndex}
                            tagsSearchRes={tagsSearchRes}
                            handleCategoryGameViewAll={handleCategoryGameViewAll}
                            tagsArrOpacity={tagsArrOpacity}
                          />
                        </ContainerWrapper>
                      )
                    );

                  case 'RecentlyListAll':
                    return tagIndex === '003' ? (
                      <ContainerWrapper key={index} className={`dom_003`} id="003" ispb={false} ispt={true}>
                        <div className="flex flex-col">
                          <HomeModuleHeader title={t('home.category_003')} img={`/static/layout10002-img/003_1.png`} />
                          <RecentlyListAll
                            gameListGridClassName="!gap-x-[14px] !gap-y-[14px]"
                            loadMoreWithText={responsiveMode == ResponsiveMode.mobile ? true : false}
                            lang_key_title={t('home.category_003')}
                          />
                        </div>
                      </ContainerWrapper>
                    ) : null;

                  case 'HomeGameList':
                    // 收藏
                    return tagIndex === '001' ? (
                      <ContainerWrapper key={index} className={`dom_001`} id="001" ispb={false} ispt={true}>
                        <div className="flex flex-col">
                          <HomeModuleHeader title={t('home.category_001')} img={`/static/layout10002-img/001_1.png`} />
                          <HomeGameList
                            tagsIndex={tagIndex}
                            providerSelect={providerSelect}
                            isProvider={isProvider}
                            searchVal={searchVal}
                            isSearch={isSearch}
                            setTagsIndex={setTagsIndex}
                            gameListGridClassName="!gap-x-[14px] !gap-y-[14px]"
                            loadMoreWithText={responsiveMode == ResponsiveMode.mobile ? true : false}
                            lang_key_title={t('home.category_001')}
                          />
                        </div>
                      </ContainerWrapper>
                    ) : null;
                }
              })}

              {tagIndex !== '003' && tagIndex !== '001' && data.length > 0 ? (
                // 游戏列表
                data.map((item, index) => {
                  return (
                    <ContainerWrapper
                      key={`${index}-${item.config.game_type_id}`}
                      isborderBottom={false}
                      className={`dom_${item.config.game_type_id} px-[.08rem]`}
                      id={`${item.config.game_type_id}`}
                      ispb={false}
                      ispt={true}
                      ptNum={`${index == 0 ? '!pt-[14px]' : '!pt-[20px]'} `}
                    >
                      <CategoryGame
                        boxclassName="!mb-[.2rem]"
                        homeModuleHeaderClassName="!h-[.44rem]"
                        homeModuleHeaderIconClassName="!w-[.44rem] !h-[.44rem] !mr-[.12rem]"
                        homeModuleHeaderTextClassName={`!font-[500] !leading-[.34rem] ${isLightTheme() ? '!text-[var(--colour-10)]' : ''}`}
                        staticIcon={true}
                        loadMoreWithText={true}
                        viewAllWithText={true}
                        gameListGridClassName="!gap-x-[14px] !gap-y-[14px]"
                        config={{ ...item.config, max_rows: item.config.category_id == '1011' ? 5 : 2 }}
                        list={item.data || []}
                        viewAll={item.config.category_id !== '1011'}
                        setTagsIndex={setTagsIndex}
                        withTour={false}
                        tourCardData={null}
                        handleViewAllCallback={() => {
                          handleCategoryGameViewAll(item.config.game_type_id);
                          const dom = document.querySelector(`.dom_tagsearch_item_${item.config.game_type_id}`);
                          if (dom) {
                            scrollIntoViewS(dom, {
                              time: 500,
                            });
                          }
                        }}
                        updateTour={updateList}
                        homeProviderRes={homeProviderRes}
                        clickViewAllUseId="game_type_id"
                        isClickProvider={item.config.type == 'provider_list'}
                        clickViewAllJumpPage={true}
                        clickProviderItem={(model) => {
                          if (model.open_status == 1) {
                            handleProviderClick(item.config, model);
                          }
                        }}
                        handleProviderClickHeader={() => {
                          handleProviderClickHeader(item.config);
                        }}
                      />
                    </ContainerWrapper>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

const TagSearch = ({ tagsArr, tagsIndex, setTagsIndex, tagsResIndex, tagsArrOpacity = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);
  const [showArr, setShowArr] = useState([]); //tagsearch展示用，筛选掉jackpot

  const [elRefs, setElRefs] = useState([]);
  const { getServerLangText } = useLangServerConfig();
  useEffect(() => {
    if (tagsArr.length > 0) {
      let resArr = tagsArr.filter((item) => {
        return item.game_type_id != '-3';
      });
      setShowArr(resArr);
    }
  }, [tagsArr]);

  //判断左右箭头是否显示
  const checkScrollPosition = () => {
    const container = document.querySelector('.scrollBox');
    if (container) {
      const nodeWidth = container.clientWidth;
      const scrollLeft = container.scrollLeft;
      const scrollWidth = container.scrollWidth;
      const hasScrollbar = scrollWidth > nodeWidth;

      if (hasScrollbar) {
        const isScrolledToLeft = scrollLeft === 0;
        const isScrolledToRight = scrollLeft + nodeWidth >= scrollWidth - 5;

        if (isScrolledToLeft) {
          setLeftArrow(false);
        } else {
          setLeftArrow(true);
        }
        if (isScrolledToRight) {
          setRightArrow(false);
        } else {
          setRightArrow(true);
        }
      } else {
        setLeftArrow(false);
        setRightArrow(false);
      }
    }
  };

  //默认调用一次来判断是否显示箭头
  useEffect(() => {
    if (showArr.length > 0) {
      checkScrollPosition();
    }
  }, [showArr]);

  //滑动标签判断是否显示箭头
  useEffect(() => {
    const dom = document.querySelector('.scrollBox');
    if (dom) {
      dom.addEventListener('scroll', checkScrollPosition);
    }
    checkScrollPosition();
    return () => {
      if (dom) {
        dom.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //点击标签，滑动当前关联dom到视口顶部
  const findingDom = async (game_type_id) => {
    let dom = document.querySelector(`.dom_${game_type_id}`);
    if (dom) {
      let topNum = dom.getBoundingClientRect().top;
      let scrollNum = getScrollPosition().y;
      await delay(10);
      const myEvent = new CustomEvent('overlayScrollerScrollTo', {
        detail: {
          offsetTop: topNum + scrollNum - HeaderHeight - TagSearchHeight - 10,
          isOffset: true,
        },
      });
      document.dispatchEvent(myEvent);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100));
      findingDom(game_type_id);
    }
  };

  const handleScroll = () => {
    let domList = [];
    domList = showArr.map((item) => {
      return document.querySelector(`.dom_${item.game_type_id}`);
    });

    let viewDom = domList.find((item) => {
      return isElementPartiallyInViewport(item);
    });
    if (viewDom) {
      setTagsIndex(viewDom.id);
      tagsResIndex(viewDom.id);
    }
  };

  useEffect(() => {
    const handleScrollWrapper = () => {
      handleScroll();
    };
    window.addEventListener('scroll', handleScrollWrapper);
    return () => window.removeEventListener('scroll', handleScrollWrapper);
  }, [showArr]);

  useEffect(() => {
    if (showArr && showArr.length > 0) {
      setElRefs((elRefs) =>
        Array(showArr.length)
          .fill('')
          .map((_, i) => elRefs[i] || createRef()),
      );
    }
  }, [showArr]);

  useEffect(() => {
    if (showArr && showArr.length > 0) {
      const gameTabIndex = Array.from(showArr).findIndex((item) => {
        return item.game_type_id == tagsIndex;
      });
      if (gameTabIndex > -1) {
        if (elRefs[gameTabIndex] && elRefs[gameTabIndex].current) {
          let differenceNum = isElementInHorizontalCenter(elRefs[gameTabIndex].current)[1];
          const container = document.querySelector('.scrollBox');
          // container.scrollLeft = container.scrollLeft + differenceNum;
          myScrollTo(container, container.scrollLeft + differenceNum, 500);
        }
      }
    }
  }, [tagsIndex, showArr.length, elRefs.length]);

  return (
    <Sticky
      stickyClassName="tags-list-sticky transition-tagsarr"
      topOffset={-45}
      positionRecheckInterval={200}
      stickyStyle={tagsArrOpacity ? { opacity: 0, pointerEvents: 'none' } : {}}
    >
      <div className="relative w-full border-b border-solid border-[var(--colour-6)] dom_tagSearch bg-[var(--colour-4)]">
        <div className="overflow-x-auto flex scrollbar-none scrollBox">
          {showArr.length > 0 &&
            showArr.map((item, index) => (
              <SkeletonBox
                props={{
                  containerClassName: 'grow-[1] flex h-[1.06rem] relative mr-[.16rem]',
                  className: 'w-[1.84rem]',
                }}
                key={index}
                loading={item == null}
              >
                <span
                  ref={elRefs[index]}
                  className={`grow-[1] min-w-[1.52rem] h-[1.04rem] pt-[.12rem] relative dom_tagsearch_item_${item.game_type_id} ${index == 0 ? 'ml-[.32rem]' : ''} ${index == showArr.length - 1 ? 'mr-[.32rem]' : 'mr-[.3rem]'}`}
                  key={index}
                >
                  <div
                    onClick={() => {
                      if (item.needLogin && !isLogin()) {
                        dispatch(
                          setDialogModalVisible({
                            show: DialogModalChildrenKey.LoginBox,
                          }),
                        );
                      } else {
                        AnalyticsManager.FirebaseLogEvent(getAnalyticsManagerName(item.game_type_id));
                        setTagsIndex(item.game_type_id);
                        tagsResIndex(item.game_type_id);
                        findingDom(item.game_type_id); //滚动到当前dom显示

                        if (item.category_id == '1011') {
                          dispatch(setReloadWebsiteInitData());
                        }
                      }
                    }}
                    className={`font-[500] text-[.24rem] leading-[.3rem] flex items-center flex-col whitespace-nowrap px-[.24rem] ${
                      tagsIndex === item.game_type_id ? 'text-[var(--colour-13)]' : 'text-[var(--colour-14)]'
                    } `}
                  >
                    <span className="h-[.44rem] mb-[.04rem]">
                      {tagsIndex == item.game_type_id ? (
                        <img
                          className="h-full"
                          src={
                            item.isPrivate
                              ? `/static/layout10002-img/${item.game_type_id}_${tagsIndex == item.game_type_id ? '1' : isLightTheme() ? '2_grey' : '2'}.png`
                              : tagsIndex == item.game_type_id
                                ? cmsImgUrl(item.icon_selected)
                                : cmsImgUrl(item.icon_unselected)
                          }
                        />
                      ) : item.isPrivate ? (
                        <Icon name={item.icon} />
                      ) : (
                        <img
                          className="h-full"
                          src={
                            item.isPrivate
                              ? `/static/layout10002-img/${item.game_type_id}_${tagsIndex == item.game_type_id ? '1' : isLightTheme() ? '2_grey' : '2'}.png`
                              : tagsIndex == item.game_type_id
                                ? cmsImgUrl(item.icon_selected)
                                : cmsImgUrl(item.icon_unselected)
                          }
                        />
                      )}
                    </span>
                    <span className="text-center">
                      {item.isPrivate ? t(item.isName) : getServerLangText(item.lang_key)}
                    </span>
                  </div>
                  {tagsIndex == item.game_type_id ? (
                    <div
                      className={`absolute w-full bg-[var(--colour-1)] h-[.04rem] left-[0rem] bottom-[0rem] rounded-normal`}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </SkeletonBox>
            ))}
        </div>
        {leftArrow ? (
          <div
            className="absolute abs-y-center right-[0rem] w-[.4rem] h-[1.04rem] flex justify-center items-center left-[0rem]"
            onClick={() => {
              document.querySelector('.scrollBox').scrollLeft = 0;
            }}
          >
            <span className="w-[.36rem] h-[.36rem] border border-solid border-[var(--colour-6)] rounded-[50%] flex items-center justify-center">
              <span className="w-[.08rem] h-[.14rem] rotate-[180deg] text-[var(--colour-11)]">
                <Icon name={IconName.RightArrow} />
              </span>
            </span>
          </div>
        ) : (
          <></>
        )}
        {rightArrow ? (
          <div
            className="absolute abs-y-center right-[0rem] w-[.4rem] h-[1.04rem] flex justify-center items-center"
            onClick={() => {
              const container = document.querySelector('.scrollBox');
              container.scrollLeft = container.scrollWidth - container.clientWidth;
            }}
          >
            <span className="w-[.36rem] h-[.36rem] border border-solid border-[var(--colour-6)] rounded-[50%] flex items-center justify-center">
              <span className="w-[.08rem] h-[.14rem] text-[var(--colour-11)]">
                <Icon name={IconName.RightArrow} />
              </span>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Sticky>
  );
};

const getAnalyticsManagerName = (id) => {
  if (id == '000') {
    return FirebaseEvents.MP_Click_Lobby;
  } else if (id == '001') {
    return FirebaseEvents.MP_Click_Favorite;
  } else if (id == '002') {
    return FirebaseEvents.MP_Click_All;
  } else if (id == '003') {
    return FirebaseEvents.MP_Click_Recently;
  } else {
    return FirebaseEvents.MP_Click_ + id;
  }
};

function toBtoa(obj) {
  return btoa(JSON.stringify({ icon_selected: obj.icon_selected, icon_unselected: obj.icon_unselected }));
}

// 获取 当前 滚动条的长度， 水平 && 垂直方向
function getScrollPosition() {
  let x, y;
  if (!!window.pageXOffset) {
    x = window.pageXOffset;
    y = window.pageYOffset;
  } else {
    x = (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    y = (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }
  return { x, y };
}

//是否在可视区域
function isElementPartiallyInViewport(element) {
  if (element) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    const topVisible = rect.top >= HeaderHeight + TagSearchHeight && rect.top <= windowHeight - MenuBarHeight;
    const bottomVisible =
      rect.bottom - (HeaderHeight + TagSearchHeight) >= 0 && rect.bottom <= windowHeight - MenuBarHeight;
    const otherVisible =
      rect.top - (HeaderHeight + TagSearchHeight) <= 0 && rect.bottom + MenuBarHeight >= windowHeight;
    return topVisible || bottomVisible || otherVisible;
  }
}

//是否在视口正中间
function isElementInHorizontalCenter(element) {
  const viewportWidth = window.innerWidth; // 获取当前视口宽度
  const rect = element.getBoundingClientRect();
  const elementWidth = rect.width;
  const left = rect.left;
  const centerPoint = viewportWidth / 2;

  // 计算元素的中间位置
  const elementMiddle = left + elementWidth / 2;

  // 计算元素中间位置与视口中间位置的差值
  let difference = elementMiddle - centerPoint;
  difference = Number(difference);
  if (isNaN(difference)) {
    difference = 0;
  }

  // 判断元素是否在视口正中间
  const isInCenter = Math.abs(difference) < elementWidth / 2;

  // 返回结果数组
  return [isInCenter, difference];
}

function myScrollTo(element, to, duration) {
  const start = element.scrollLeft;
  const change = to - start;
  const startTime = performance.now();

  function animateScroll(currentTime) {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const easeProgress = easeInOutQuad(progress);

    element.scrollLeft = start + change * easeProgress;

    if (progress < 1) {
      requestAnimationFrame(animateScroll);
    }
  }

  requestAnimationFrame(animateScroll);
}

function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}
