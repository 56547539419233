import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../icon';
import { isControllableSite } from '../../utils/helper';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
import LimitClickWrapper from '../limitClickWrapper';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import Close from '../close';
import useRouterHelper from '../../hooks/useRouterHelper';

export default function Index({ handleClose = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleClickWallet } = useRouterHelper();
  const handleGo = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Click_RegToDepositButton);
    handleClickWallet();
  };
  return (
    <div className={`flex flex-col w-[6.86rem] h-[4.8rem] rounded-normal bg-[var(--colour-35)]`}>
      <div className="flex flew-row mt-[.4rem] ml-[.4rem]  w-[6.22rem] h-[.72rem] justify-center border-b-[.02rem] border-[var(--colour-6)]">
        <div className="flex flew-row w-[6.22rem]  justify-center ">
          <div className="flex w-[6.22rem] text-[.32rem] font-[700] text-[var(--colour-48)]">
            {t('login-signup.signup_success_title')}
          </div>
          <div
            className="flex items-center justify-center w-[.72rem] h-[.72rem] mt-[-0.2rem] rounded-normal bg-[var(--colour-45)]"
            onClick={() => handleClose()}
          >
            <Close />
          </div>
        </div>
      </div>

      <div className="flex w-[6.22rem] mt-[.4rem] h-[1.02rem] justify-center items-center ml-[.2rem]">
        <div className="flex w-[6.22rem] justify-center items-center text-[.28rem] ml-[.2rem] text-primary-content">
          {t('login-signup.signup_success_prompt')}
        </div>
      </div>

      <div className="flex flex-row w-[5.66rem] mt-[.7rem] justify-center ml-[.56rem]">
        <LimitClickWrapper
          className="flex w-[2.6rem] h-[.8rem] justify-center items-center text-center text-[.28rem] cursor-pointer select-none bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal "
          onClick={handleGo}
        >
          {t('login-signup.signup_success_btn')}
        </LimitClickWrapper>
      </div>
    </div>
  );
}
