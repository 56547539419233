const Claimbtn = ({ txtColor = '', children, className = '' }) => {
  return (
    <>
      <div className={`w-[2.4rem] h-[.64rem] flex items-center justify-center ${className}`}>
        <span className={`text-[.36rem] font-[700] ${txtColor}`} data-text={children}>
          {children}
        </span>
      </div>
    </>
  );
};

export default Claimbtn;
