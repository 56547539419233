import cn from 'classnames';
import Icon, { IconName } from '../../../../../components/icon';

const DepositTip = ({ text, isShowDepositButton = false, handleClick }) => {
  return (
    <div
      className={cn(
        'bg-[var(--colour-34)] p-[.16rem_.2rem] text-[.24rem] font-[500] flex items-center space-x-[.16rem] rounded-[.1rem] m-[.2rem_.2rem_.16rem_.2rem]',
        {
          'justify-between': isShowDepositButton,
          'justify-center': !isShowDepositButton,
        },
      )}
    >
      <div className="leading-[1.2]">{text}</div>

      {isShowDepositButton && (
        <div className="shrink-0 w-[.48rem] h-[.48rem] bg-[var(--colour-1)] rounded-[.08rem] flex items-center justify-center">
          <div className="w-[.26rem] h-[.22rem] text-[var(--colour-2)] cursor-pointer" onClick={handleClick}>
            <Icon name={IconName.Wallet} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DepositTip;
