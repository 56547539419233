import Loading2 from '../components/loading2';
import { useAsync } from './useAsync';

/**
 * 异步加载node (带loading)
 * @param fn 异步方法
 * @param deps 依赖
 * @param loadingNode 加载node，默认值Loading
 * @param errorNode   错误node，默认值空
 * @returns node节点
 */
export function useAsyncNode(fn, deps, { loadingNode = <Loading2 />, errorNode = <></> } = {}) {
  const { loading, error, data } = useAsync(fn, deps);
  if (error) {
    return errorNode;
  }
  if (loading) {
    return loadingNode;
  }
  return data;
}
