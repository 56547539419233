import { useRef, useState, useEffect } from 'react';
import Close from '../../close';
import Icon, { IconName } from '../../icon';
import useAudioplayer from '../../../hooks/useAudioplayer.js';
import { shallowEqual, useSelector } from 'react-redux';
import MusicMenu from './musicConfig.json';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { pxToRem } from '../../../utils/helper.js';

const PlayerMusic = ({ handleClose }) => {
  const maxWidth = 3.3;
  const oCurBtn = useRef(null);
  const scrollContainerRef = useRef(null);
  const oProcessCur = useRef(null);
  const currentTime = useRef(null);
  const { t } = useTranslation();
  const [songsList, setsongsList] = useState([]);
  const [list, setList] = [MusicMenu.data];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tabList, settabList] = useState([
    {
      title: t('audio.resource'),
    },
    {
      title: t('audio.mymusic'),
    },
  ]);
  const { handlePlay, hadnlePrev, handleNext, setCurrentTime, formatTime, handleChange, playSong, songsobj } =
    useAudioplayer();

  const { audioPlayerStatus } = useSelector(
    (state) => ({
      audioPlayerStatus: state.app.audioPlayerStatus,
    }),
    shallowEqual,
  );

  const getCMSMusicIds = () => {
    const cmsMusicIds = localStorage.getItem('CMS-MUSIC-IDS');
    if (cmsMusicIds) {
      const parsedIds = JSON.parse(cmsMusicIds);
      return parsedIds.value ? parsedIds.value.split(',').map(String) : [];
    }
    return [];
  };

  const updateList = () => {
    const ids = getCMSMusicIds();

    const arr = list.filter((item) => {
      return ids.includes(item.id);
    });

    setsongsList(arr);
  };

  const removeIdFromLocalStorage = (idToRemove) => {
    const existingData = localStorage.getItem('CMS-MUSIC-IDS');
    let idsObject = {};

    if (existingData) {
      idsObject = JSON.parse(existingData);
      const idsArray = idsObject.value ? idsObject.value.split(',') : [];
      const index = idsArray.indexOf(idToRemove);
      if (index > -1) {
        idsArray.splice(index, 1);
        idsObject.value = idsArray.join(',');
      }
      localStorage.setItem('CMS-MUSIC-IDS', JSON.stringify(idsObject));
    }

    updateList();
  };

  useEffect(() => {
    updateList();
  }, [list]);

  function toTwo(n) {
    return n < 10 ? '0' + n : '' + n;
  }

  function sToM(sec, obj) {
    let M = Math.floor(sec / 60);
    let S = Math.floor(sec % 60);
    obj.innerHTML = toTwo(M) + ':' + toTwo(S);
  }

  function curBtnMoveByMouse(sX, styleLeft, objOffsetLeft) {
    let tar = sX - styleLeft + objOffsetLeft;
    tar = pxToRem(tar);
    if (tar > maxWidth) tar = maxWidth;
    if (tar < 0) tar = 0;
    if (oCurBtn.current && oProcessCur.current) {
      oCurBtn.current.style.left = tar + 'rem';
      oProcessCur.current.style.width = tar + 'rem';
    }
    setCurrentTime((tar / maxWidth) * audioPlayerStatus.timeCount);
  }

  const handleClick = (e) => {
    if (audioPlayerStatus.musicName == '') return;
    if (oCurBtn.current) {
      let objBCLeft = oCurBtn.current.getBoundingClientRect().left;
      let sX = e.clientX;
      let objOffsetLeft = oCurBtn.current.offsetLeft;
      console.error(objOffsetLeft);
      curBtnMoveByMouse(sX, objBCLeft, objOffsetLeft);
    }
  };

  const handleTouchStart = (e) => {
    e.stopPropagation();
    if (audioPlayerStatus.musicName == '') return;
    if (oCurBtn.current) {
      let L = oCurBtn.current.offsetLeft + 10;
      let DisX = L;
      let t = e.touches[0];
      let X = t.clientX;
      document.addEventListener('touchmove', (touchMoveEvent) => TouchMove(touchMoveEvent, X, L, DisX));
    }
  };

  const TouchMove = (e, X, L, DisX) => {
    e.stopPropagation();
    let t = e.touches[0];
    DisX = t.clientX - X + L;
    DisX = pxToRem(DisX);
    if (DisX > maxWidth) DisX = maxWidth;
    if (DisX < 0) DisX = 0;
    if (oCurBtn.current && oProcessCur.current && DisX != L) {
      oCurBtn.current.style.left = DisX + 'rem';
      oProcessCur.current.style.width = DisX + 'rem';
      if (currentTime.current) {
        sToM((DisX / maxWidth) * audioPlayerStatus.timeCount, currentTime.current);
      }
      setCurrentTime((DisX / maxWidth) * audioPlayerStatus.timeCount);
      document.addEventListener('touchend', TouchEnd);
    }
  };

  const TouchEnd = () => {
    document.removeEventListener('mousemove', TouchMove);
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('touchstart', handleScrollContainerTouchStart);
      scrollContainerRef.current.addEventListener('touchmove', handleScrollContainerTouchMove);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('touchstart', handleScrollContainerTouchStart);
        scrollContainerRef.current.removeEventListener('touchmove', handleScrollContainerTouchMove);
      }
    };
  });

  const handleScrollContainerTouchStart = (e) => {
    e.stopPropagation();
  };

  const handleScrollContainerTouchMove = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <div className="w-[6.74rem] h-[9.84rem] bg-[var(--colour-35)] rounded-[.24rem] flex flex-col overflow-hidden box-border relative">
        <div
          className={cn(
            'h-[.94rem] w-full flex items-center justify-center text-[.32rem] font-[700] text-[var(--colour-48)]',
            {},
          )}
        >
          {t('audio.music')}
        </div>

        <div className="absolute right-[.3rem] top-[.12rem] w-[.52rem] h-[.52rem] bg-[var(--colour-45)] flex justify-center items-center rounded-[.16rem]">
          <Close onClick={handleClose} />
        </div>

        <div className={cn('flex-1 text-[.28rem] font-[700] p-[0_.5rem_.5rem_.5rem] flex flex-col text-[#fff]', {})}>
          <div className="bg-[var(--bg-[var(--colour-51)])] flex flex-col h-auto items-center justify-between p-[.2rem_0_.3rem_0] rounded-[.24rem] font-[500] text-[.24rem]">
            <p className="scale-[.85] text-[var(--colour-1)]"> {audioPlayerStatus.musicName || songsobj.name || ''}</p>
            <div className="flex flex-1 items-center justify-between mt-[.1rem] w-full p-[0_.3rem] relative">
              <span className="scale-[.85] min-w-[0.8rem] text-[var(--colour-48)]" ref={currentTime}>
                {formatTime(audioPlayerStatus.timeTick)}
              </span>

              <div id="process-bar" className="absolute top-0" onClick={handleClick}>
                <div id="process-all"></div>
                <div id="process-cur" ref={oProcessCur} style={{ width: audioPlayerStatus.range || 0 }}>
                  <span
                    onTouchStart={handleTouchStart}
                    id="cur-btn"
                    style={{ left: audioPlayerStatus.range || 0 }}
                    ref={oCurBtn}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  ></span>
                </div>
              </div>
              <span className="scale-[.85] min-w-[0.8rem] text-[var(--colour-48)]">
                {formatTime(audioPlayerStatus.timeCount)}
              </span>
            </div>

            <div className="items-center text-[#fff] text-[.28rem] font-[700] mt-[.14rem] w-full flex justify-between relative">
              <div
                className="cursor-pointer h-full text-center w-[1.2rem] flex flex-col items-center text-[.24rem] font-[500]"
                onClick={handleChange}
              >
                <div className="w-[.32rem] h-[.32rem] text-[var(--colour-1)]">
                  <Icon
                    name={
                      audioPlayerStatus.playMode == 'loop'
                        ? IconName.Playorder
                        : audioPlayerStatus.playMode == 'random'
                          ? IconName.ShufflePlay
                          : IconName.PlayBack
                    }
                  />
                </div>

                <p className={cn('text-[.2rem] text-[var(--colour-48)]', {})}>
                  {audioPlayerStatus.playMode == 'loop'
                    ? `${t('audio.playorder')}`
                    : audioPlayerStatus.playMode == 'random'
                      ? `${t('audio.randomplay')}`
                      : `${t('audio.singleplay')}`}
                </p>
              </div>

              <div className="absolute abs-x-center flex items-center top-0 justify-between">
                <div
                  className="w-[.6rem] h-[.6rem] flex items-center justify-center rounded-[50%] bg-[rgba(0,0,0,.2)] cursor-pointer"
                  onClick={(e) => {
                    hadnlePrev();
                    updateList();
                    e.stopPropagation();
                  }}
                >
                  <div className="w-[.32rem] h-[.32rem] text-[var(--colour-1)]">
                    <Icon name={IconName.PrevMusic} />
                  </div>
                </div>

                <div
                  className="w-[.8rem] h-[.8rem] flex items-center justify-center rounded-[50%] bg-[rgba(0,0,0,.2)] cursor-pointer m-[0_.3rem]"
                  onClick={() => {
                    handlePlay();
                    updateList();
                  }}
                >
                  <div className="w-[.5rem] h-[.5rem] text-[var(--colour-1)]">
                    {audioPlayerStatus.isPlaying ? (
                      <Icon name={IconName.PlayMusic} />
                    ) : (
                      <Icon name={IconName.PauseMusic} />
                    )}
                  </div>
                </div>

                <div
                  className="w-[.6rem] h-[.6rem] flex items-center justify-center rounded-[50%] bg-[rgba(0,0,0,.2)] cursor-pointer"
                  onClick={() => {
                    handleNext();
                    updateList();
                  }}
                >
                  <div className="w-[.32rem] h-[.32rem] text-[var(--colour-1)]">
                    <Icon name={IconName.NextMusic} />
                  </div>
                </div>
              </div>

              <div
                className={cn(
                  'w-[1.2rem] flex flex-col items-center text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-48)]',
                )}
              >
                <div className="text-[.3rem]">{songsList.length}</div>
                <p className="text-[.2rem] text-center">{t('audio.baixado')}</p>
              </div>
            </div>
          </div>

          <div className="mt-[.2rem] rounded-[.24rem] bg-[var(--bg-[var(--colour-51)])] h-[6rem]">
            <div className="w-full h-[.8rem] flex items-center relative cursor-pointer">
              {tabList &&
                tabList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-[50%] flex justify-center items-center ${currentIndex == index ? 'text-[var(--colour-1)] font-[700]' : 'text-[var(--colour-48)] font-[500]'}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentIndex(index);
                      }}
                    >
                      {item.title}
                    </div>
                  );
                })}

              <div className="absolute bottom-0 left-0 w-full h-[.02rem] bg-[var(--colour-6)]"></div>
              <div
                className={`absolute bottom-0 w-[50%] h-[.02rem] bg-[var(--colour-1)] text-[var(--colour-2)] ${currentIndex == 0 ? 'left-0' : 'right-0'}`}
              ></div>
            </div>

            <div className="w-full min-h-[.8rem] h-[5.2rem] overflow-y-auto hidden-scroll-y" ref={scrollContainerRef}>
              {currentIndex == 0 ? (
                <>
                  {list &&
                    list.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="h-[.8rem] flex items-center justify-between p-[0_.1rem_0_.1rem] relative"
                          onClick={() => {
                            if (audioPlayerStatus.currentIndex == index) return;
                            playSong(index);
                            updateList();
                          }}
                        >
                          <div className="space-x-[.1rem] text-[.24rem] flex items-center">
                            <div>
                              {audioPlayerStatus.currentIndex == index ? (
                                <>
                                  <div className="w-[.32rem] h-[.32rem] text-[var(--colour-1)]">
                                    <Icon name={IconName.Listening} />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="text-[var(--colour-48)] w-[.32rem] h-[.32rem] flex justify-center scale-[.9] font-[600]">
                                    {index + 1}
                                  </div>
                                </>
                              )}
                            </div>

                            <span
                              className={`scale-[.9] font-[600] w-[3.28rem] truncate ${audioPlayerStatus.currentIndex == index ? 'text-[var(--colour-1)]' : 'text-[var(--colour-48)]'}`}
                            >
                              {item.name}
                            </span>
                            <span className="text-[var(--colour-48)] scale-[.9] font-[600]">{item.size}</span>
                          </div>

                          {audioPlayerStatus.loading && audioPlayerStatus.currentIndex == index ? (
                            <div className="w-[.3rem] h-[.42rem] text-[var(--colour-1)] anticon">
                              <Icon name={IconName.loadingMp3} />
                            </div>
                          ) : (
                            <div className="w-[.3rem] h-[.3rem] text-[var(--colour-1)]">
                              <Icon name={IconName.Audiodownload} />
                            </div>
                          )}

                          {index + 1 != list.length ? (
                            <div className="w-[5.54rem] h-[.02rem] bg-[var(--colour-6)] absolute abs-x-center bottom-0"></div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {songsList &&
                    songsList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="h-[.8rem] flex items-center justify-between p-[0_.1rem_0_.1rem] relative"
                        >
                          {index + 1 != songsList.length ? (
                            <div className="w-[5.54rem] h-[.02rem] bg-[var(--colour-6)] absolute abs-x-center bottom-0"></div>
                          ) : (
                            <></>
                          )}
                          <div className="space-x-[.1rem] text-[.24rem] flex items-center">
                            <div className="text-[var(--colour-48)] scale-[.9] font-[600] w-[.32rem] h-[.32rem] flex justify-center">
                              {index + 1}
                            </div>
                            <div className=" scale-[.9] font-[600] w-[3.28rem] truncate text-[var(--colour-48)]">
                              {item.name}
                            </div>
                            <div className="text-[var(--colour-48)]  scale-[.9] font-[600]">{item.size}</div>
                          </div>
                          <div className="flex items-center space-x-[.1rem]">
                            <div className="w-[.3rem] h-[.3rem] text-[var(--colour-1)]">
                              <Icon name={IconName.Audiocheck} />
                            </div>
                            <div
                              className="w-[.3rem] h-[.3rem] text-[var(--colour-1)]"
                              onClick={() => {
                                removeIdFromLocalStorage(item.id);
                              }}
                            >
                              <Icon name={IconName.Audiodel} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerMusic;
