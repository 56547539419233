import copy from 'copy-to-clipboard';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../../../redux/reducers/appSlice';
import Icon, { IconName } from '../../../../../../components/icon';
import { get } from 'lodash';
import {
  isLogin,
  getReferralLink,
  isControllableSite,
  isShareString,
  getShareIconName,
} from '../../../../../../utils/helper';
import QRCode from '../../../../../../components/qrCode';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import cn from 'classnames';
import AnalyticsManager, { FirebaseEvents } from '../../../../../../manager/analyticsManager';
import ShareManager from '../../../../../../manager/shareManager';
import { useDeepCompareEffect } from 'ahooks';

const LinkBox = ({ link = '', onClick }) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between rounded-normal mt-[.1rem] h-[.88rem] border border-[var(--colour-6)] bg-[var(--colour-35)]',
        {},
      )}
    >
      <div
        className={cn(
          'flex text-[var(--colour-38)] justify-start items-center overflow-x-auto overflow-y-hidden scroll_none leading-[1.1em] mx-[.2rem] w-[3.4rem] flex-1',
        )}
      >
        {link}
      </div>
      <div
        className={cn(
          'flex justify-center items-center active:scale-[.95] transition-all w-[.64rem] h-[.64rem] cursor-pointer select-none shrink-0 rounded-normal',
        )}
        onClick={onClick}
      >
        <div className={cn('w-[.36rem] h-[.36rem] text-[var(--colour-1)]', {})}>
          <Icon name={IconName.Copy5} />
        </div>
      </div>
    </div>
  );
};

const QRCodeBox = ({ qrCodeValue }) => {
  const { t } = useTranslation();

  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: state.app.responsiveMode,
    }),
    shallowEqual,
  );

  const qrCodeRef = useRef(null);

  const handleClick = () => {
    try {
      domtoimage.toPng(qrCodeRef.current).then((imgUrl) => {
        saveAs(imgUrl, 'qr-code.png');
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={cn(
        'rounded-[.16rem] cursor-pointer bg-[var(--colour-35)] border-[var(--colour-6)] w-[1.54rem] h-[2.32rem] border-[1px] flex flex-col items-center overflow-hidden',
        {},
      )}
      onClick={handleClick}
    >
      <div className={cn('w-full h-[1.3rem] py-[.12rem] shrink-0', {})} ref={qrCodeRef}>
        <QRCode value={qrCodeValue} remSize={1.1} />
      </div>
      <div
        className={cn(
          'font-[500] break-word text-[var(--colour-2)] text-center bg-[var(--colour-1)] flex-1 flex items-center justify-center text-[.24rem] px-[.08rem] leading-[1.3333] w-full',
          {},
        )}
      >
        {t('referral.click')}
      </div>
    </div>
  );
};

function ReferLink({ directReport = 0 }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { inviteCode, defaultLang, referral_url, websiteInitConfig, userObj } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.userObj.invite_code', ''),
      defaultLang: state.user.defaultLang,
      referral_url: get(state, 'user.userObj.referral_url', ''),
      websiteInitConfig: state.app.websiteInitConfig,
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  const [shareList, setShareList] = useState([]);
  const [shareLink, setShareLink] = useState('');

  useEffect(() => {
    if (websiteInitConfig?.website_info?.share_method) {
      setShareList(websiteInitConfig?.website_info?.share_method);
    }
  }, [websiteInitConfig]);

  const onCopyLink = () => {
    copy(shareLink);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  const onCopyInviteCode = () => {
    copy(inviteCode);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  const handleShare = (res) => {
    let str = res.toLowerCase();
    return ShareManager.share(str, shareLink, websiteInitConfig?.website_info?.website_desc);
  };

  const showLogin = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
  };

  const getText = (str) => {
    if (str == 'share') {
      return t('referral.more_share');
    } else {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    }
  };

  useDeepCompareEffect(() => {
    setShareLink(getReferralLink());
  }, [userObj]);

  return (
    <div className={cn('text-[.24rem] font-[500] text-[var(--colour-44)] leading-[1]')}>
      <div className="flex space-x-[.2rem] w-full">
        <div className="shrink-0">
          <QRCodeBox qrCodeValue={shareLink} />
        </div>
        <div className="flex-1">
          <div className={cn('text-[.24rem] font-[500] text-[var(--colour-44)]')}>
            <div className="">{t('referral.yourLink')}</div>
          </div>

          <LinkBox
            link={shareLink}
            onClick={() => {
              onCopyLink();
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonu_Copy);
            }}
          />

          <div className="pt-[.2rem] text-[var(--colour-44)]">
            {t('referral.direct_report')} {directReport}
          </div>

          {userObj != null && (
            <div className="flex items-center pt-[.08rem]" onClick={onCopyInviteCode}>
              <div className={cn('text-[var(--colour-44)]')}>{t('referral.invitation_code')}:</div>
              <div className="ml-[.08rem] text-[var(--colour-38)]">{inviteCode}</div>
              <div className={cn('ml-[.12rem] flex justify-center items-center cursor-pointer select-none shrink-0')}>
                <div className={cn('w-[.28rem] h-[.28rem] text-[var(--colour-1)]', {})}>
                  <Icon name={IconName.Copy5} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="pt-[.26rem] flex space-x-[.36rem]">
        {shareList.map((item, index) => {
          return isShareString(item) && !ShareManager.showMoreShare ? null : (
            <div key={index} className="flex flex-col items-center">
              <div
                className={cn(
                  'flex justify-center items-center cursor-pointer active:scale-[.95] w-[.7rem] h-[.7rem]',
                  {},
                )}
                onClick={() => handleShare(item)}
              >
                <Icon name={getShareIconName(item)} />
              </div>
              <div className="mt-[.06rem] text-[var(--colour-38)] text-[.2rem]">{getText(item)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ReferLink;
