import { get, cloneDeep } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import HomeSwiperBanner from '../../components/homeBanner';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ContainerWrapper from '../components/containerWrapper';
import HomeLinkBox from '../../../../components/homeLinkBox';
import Broadcast from '../../../../components/home/broadcast';
import Jackpot from '../../../../components/jackpot';
import DTournament from '../../../../components/tournament/DTournament';
import HomeTagSearch from './components/homeTagSearch';
import HomeGameList from './components/homeGameList';
import {
  DialogModalChildrenKey,
  setAutoScrollToTagGameKey,
  setDialogModalVisible,
  setReloadWebsiteInitData,
} from '../../../../redux/reducers/appSlice';
import { isLogin } from '../../../../utils/helper';
import CategoryGame from './components/categoryGame';
import { useUpdateEffect } from 'ahooks';

const DTemplate003 = () => {
  const modules = ['Jackpot', 'Tournament'];

  const dispatch = useDispatch();
  const isFirstLoadRef = useRef(true);
  const ref = useRef(null);
  const [bannerList, setBannerList] = useState([]);
  const [modulesSortdata, setModulesSortdata] = useState([]);
  const [moduleConfigList, setModuleConfigList] = useState([]);
  const [isTagSearchAutoScroll, setIsTagSearchAutoScroll] = useState(false); //  是否需要tagSearch滚动到选中的item
  const lobbytagsIndex = '000'; // 大厅category_id
  const [tagsIndex, setTagsIndex] = useState(lobbytagsIndex); // 当前游戏类型
  const [isShowLobby, setIsShowLobby] = useState(false); // 是否配置了大厅

  const { identity, websiteInitData, websiteInitConfig, needCheckedLobby, tourRedDot, mobileTagIndex } = useSelector(
    (state) => ({
      identity: state.user.identity,
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
      needCheckedLobby: state.app.needCheckedLobby,
      mobileTagIndex: state.app.mobileTagIndex,
      tourRedDot: get(state, 'app.activityStatus.activity_tournament'),
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (tourRedDot == '1') {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.TourClaim, zIndex: 10000 }));
    }
  }, [tourRedDot]);

  useEffect(() => {
    if (websiteInitData && websiteInitData.template) {
      let data = cloneDeep(websiteInitData.template);
      const arr = [];
      for (let i = 0; i < data.length; i++) {
        if (modules.includes(data[i].module)) {
          arr.push(data[i]);
        }
      }

      const homeTagSearchModule = data.find((item) => item.module === 'HomeTagSearch');
      if (homeTagSearchModule && Array.isArray(homeTagSearchModule.config) && homeTagSearchModule.config.length > 0) {
        const isLobbyConfigured = homeTagSearchModule.config.some((item) => item.category_id == lobbytagsIndex);

        if (isLobbyConfigured) {
          setIsShowLobby(true);
        } else {
          const defaultId = homeTagSearchModule.config[0]?.category_id;
          if (defaultId) {
            setTagsIndex(defaultId);
            setIsShowLobby(false);
          }
        }
      }

      setModulesSortdata(arr);
      setModuleConfigList(data);
    }

    if (websiteInitData && websiteInitData.home_banner) {
      setBannerList(websiteInitData.home_banner);
    }
  }, [websiteInitData, websiteInitConfig, identity]);

  const updateList = () => {
    dispatch(setReloadWebsiteInitData());
  };

  const tagsResIndex = (res) => {
    if (isLogin() && res === '000' && isFirstLoadRef.current == false) {
      updateList();
    }

    isFirstLoadRef.current = false;
  };

  useEffect(() => {
    if (isTagSearchAutoScroll) {
      setIsTagSearchAutoScroll(false);
      dispatch(setAutoScrollToTagGameKey(tagsIndex));
    }
  }, [tagsIndex, isTagSearchAutoScroll]);

  const handleClickGameCategory = (catetoryId) => {
    setTagsIndex(catetoryId);
    tagsResIndex(catetoryId);
  };

  useUpdateEffect(() => {
    setTagsIndex(mobileTagIndex);
    dispatch(setAutoScrollToTagGameKey(mobileTagIndex));
  }, [mobileTagIndex]);

  return (
    <div className="flex flex-col pt-[.3rem]">
      <div>
        {bannerList.length > 0 ? (
          websiteInitConfig?.website_info?.website_banner_type == '3' ? (
            <div className="flex flex-col space-y-[.24rem] px-[.26rem]">
              <HomeSwiperBanner bannerType="3" swiperList={bannerList} />
            </div>
          ) : (
            <ContainerWrapper ispb={false} isborderBottom={false}>
              <div className="!px-[.26rem] w-full">
                <HomeSwiperBanner
                  bannerType={websiteInitConfig?.website_info?.website_banner_type}
                  swiperList={bannerList}
                />
              </div>
            </ContainerWrapper>
          )
        ) : (
          <></>
        )}

        {moduleConfigList.length > 0 ? (
          <div className="flex flex-col m-auto">
            {moduleConfigList.findIndex((item) => {
              return item.module == 'HomeAd';
            }) > -1 ? (
              <div className="pt-[.18rem] px-[.26rem]">
                <HomeLinkBox />
              </div>
            ) : null}

            {moduleConfigList.findIndex((item) => {
              return item.module == 'Marquee';
            }) > -1 ? (
              <div className="pt-[.24rem] px-[.26rem]">
                <Broadcast
                  isAnimation={true}
                  className="bg-[var(--colour-35)] pr-[.12rem] pl-[.16rem] rounded-[.08rem] h-[.46rem]"
                />
              </div>
            ) : null}

            {modulesSortdata.map((item, index) => {
              switch (item.module) {
                case 'Jackpot': {
                  return (
                    <div className="pt-[.26rem] flex justify-center px-[.26rem]" key={index}>
                      <Jackpot isDebug={false} handleClickGameCategory={handleClickGameCategory} />
                    </div>
                  );
                }

                case 'Tournament': {
                  return (
                    <div className="pt-[.26rem] px-[.26rem]" key={index}>
                      <DTournament />
                    </div>
                  );
                }
              }
            })}

            {moduleConfigList.map((item, index) => {
              switch (item.module) {
                case 'HomeTagSearch':
                  return item.config && item.config.length > 0 ? (
                    <ContainerWrapper key={index} ispb={false} isborderBottom={false} className="tags_sticky">
                      <div ref={ref}>
                        <HomeTagSearch
                          tagsArr={item.config}
                          tagsIndex={tagsIndex}
                          setTagsIndex={setTagsIndex}
                          tagsResIndex={tagsResIndex}
                        />
                      </div>
                    </ContainerWrapper>
                  ) : null;
              }
            })}

            {moduleConfigList.map((item, index) => {
              switch (item.module) {
                case 'CategoryGameList':
                  return tagsIndex === '000' && item.data.length > 0 && isShowLobby ? (
                    <ContainerWrapper key={`${index}-${item.config.category_id}`} isborderBottom={false} ispb={false}>
                      <HomeGameList
                        row={4}
                        id={item.config.category_id}
                        className="mt-[.32rem]"
                        icon={item?.config?.icon}
                        title={item?.config?.lang_key}
                        list={item.data || []}
                        setTagsIndex={setTagsIndex}
                        tourCardData={null}
                      />
                    </ContainerWrapper>
                  ) : null;
              }
            })}

            {tagsIndex !== '000' ? (
              <ContainerWrapper>
                <CategoryGame tagsIndex={tagsIndex} />
              </ContainerWrapper>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DTemplate003;
