import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  initState,
  setInviteCode,
  setKwaiPixelId,
  setPixelId,
  setDefaultLang,
  setTiktokPixelId,
  setBigoPixelId,
  setActivityId,
  setLoginInfo,
  setLoginType,
} from './redux/reducers/userSlice';
import { conforms, get } from 'lodash';
import { toast } from 'react-toastify';
import AppRouter from './components/appRouter';
import { changeLang, getLangFromPath, toLocalePath } from './utils/localePath';
import {
  InboxList,
  GetWebsiteInit,
  GetWebsiteInitLang,
  LangConfigReq,
  GetWebsitePreviewInitLang,
  ModifyUserReq,
} from './api';
import {
  setUnReadCount,
  setSportEnabled,
  setFbBtn,
  setWebsiteInitConfig,
  setWebsiteInitData,
  setLangServerData,
  setAndroidApkInfo,
  setDialogModalVisible,
  DialogModalChildrenKey,
  setDrawerMenuVisible,
} from './redux/reducers/appSlice';
import Loading from './components/loading2';
import {
  getQueryString,
  getUrlQueryString,
  isEnvDevelopment,
  isControllableSite,
  getTemplateId,
  isInIframe,
  replaceWithMobile,
  isLogin,
  getIdentity,
  removeUrlParameter,
} from './utils/helper';
import useLoginThirdParty from './hooks/useLoginThirdParty';
import useActivityStatus from './hooks/useActivityStatus';
import FacebookManager from './manager/facebookManager';
import { getAppTag, isAndroidNative, isAnyMobile, isIPhone, isPWA } from './utils/deviceUtils';
import { changeInitLangKey, changeSupportLangList, langsConfig } from './constants/langs';
import MetaPixel from './components/thirdparty/metaPixel';
import SevenBetLicense from './components/thirdparty/sevenBetLicense';
import GTAG from './components/thirdparty/gtag';
import GlobalWebSocket from './components/globalWebSocket';
import Message from './components/message/message';
import KwaiPixel from './components/thirdparty/kwaiPixel';
import initI18n from './i18n';
import i18n from 'i18next';
import GoogleManager from './manager/googleManager';
import TiktokPixel from './components/thirdparty/TiktokPixel';
import BigoPixel from './components/thirdparty/bigoPixel';
import useGameInfo from './hooks/useGameInfo';
import GTM from './components/thirdparty/gtm';
import AnalyticsManager, { FirebaseEvents } from './manager/analyticsManager';
import { useDeepCompareEffect, useUpdateEffect } from 'ahooks';
import { toNoLocalePath } from './utils/localePath';
import Activity from './components/activity';
import useAudioplayer from './hooks/useAudioplayer';
import Forbidden from './views/forbidden';
import Maintain from './views/maintain';
import { reloadBrowserAddress, updateBrowserAddress, setBrowserTheme } from './manager/iframeManager';
import configData, { isLocalServer } from './constants/config';
import useHandleLogic from './hooks/useHandleLogic';
import initializeFirebaseMessaging from './utils/firebaseMessaging';
import OAuthRedirecting from './utils/oauthRedirecting';
import usePromotionClaimedCount from './hooks/usePromotionClaimedCount';
import { autoRefresh } from './utils/autoUpdate';
import { initFirebaseAnalytics } from './utils/firebaseHelp';

const ThirdPartyJavascript = () => {
  return (
    <>
      <SevenBetLicense />
      <MetaPixel />
      <KwaiPixel />
      <TiktokPixel />
      <BigoPixel />
      <GTAG />
      <GTM />
    </>
  );
};

/**
 * 初始化配置 初始化接口返回之后
 */
const AppInit = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { InitPromotionClaimedCount } = usePromotionClaimedCount();
  const { InitActivityStatus } = useActivityStatus();
  const { handleTimeUpdate, handleCanPlayThrough, handleEnd, handleloadStart } = useAudioplayer();
  const { LoginByFacebook, LoginByGoogle } = useLoginThirdParty();

  const { identity, defaultLang, facebookId, googleId, sportBetbySwitch, drawerMenuVisible } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      defaultLang: get(state, 'user.defaultLang'),
      facebookId: get(state, 'app.websiteInitConfig.website_info.facebook_id', ''),
      googleId: get(state, 'app.websiteInitConfig.website_info.google_id', ''),
      sportBetbySwitch: get(state, 'app.websiteInitConfig.system_config.switch_sport_betby', ''),
      drawerMenuVisible: state?.app?.drawerMenuVisible,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const paramsIdentity = getQueryString('identity');
    if (paramsIdentity) {
      dispatch(
        setLoginInfo({
          identity: paramsIdentity,
        }),
      );
      OAuthRedirecting.ReplaceUrlParams(removeUrlParameter(window.location.href, 'identity'));
    }
    dispatch(initState());
    // 获取深度链接
    if (isAndroidNative()) {
      window.onDeepLinkUrlCallback = (deepLinkUrl) => {
        if (deepLinkUrl) {
          let inviteCode = getUrlQueryString(deepLinkUrl, 'id') || getUrlQueryString(deepLinkUrl, 'inviteCode');
          if (inviteCode) {
            console.log('inviteCode:', inviteCode);
            dispatch(setInviteCode(inviteCode));
          }
        }
      };

      window.native.getDeepLinkUrl();

      // 获取apk版本信息
      window.nativeCallback = {
        getAppInfo: (appinfo) => {
          if (appinfo) {
            dispatch(setAndroidApkInfo(JSON.parse(appinfo)));
          }
        },
      };

      if (window.native && typeof window.native.getAppInfo === 'function') {
        window.native.getAppInfo();
      }
    }
    //注册pwa
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js', { scope: '/' });
      });
    }
  }, []);

  //facebook初始化
  useEffect(() => {
    if (isAndroidNative()) return;
    async function fetchData() {
      if (facebookId) {
        await FacebookManager.init(facebookId);
        if (!identity) {
          FacebookManager.getLoginStatus((token) => {
            if (token) {
              LoginByFacebook(token);
            }
          });
        }
      }
    }
    fetchData();
  }, [facebookId]);

  // google初始化
  useEffect(() => {
    async function fetchData() {
      if (googleId) {
        await GoogleManager.init(googleId, (response) => {
          LoginByGoogle(response.credential);
        });
      }
    }
    fetchData();
  }, [googleId]);

  // 体育开关
  useEffect(() => {
    dispatch(setSportEnabled(sportBetbySwitch == '1'));
  }, [sportBetbySwitch]);

  useEffect(() => {
    // 邀请码
    const inviteCode = searchParams.get('id') || searchParams.get('inviteCode');
    if (inviteCode) {
      dispatch(setInviteCode(inviteCode));
    }
    // 活动id
    const activityId = searchParams.get('a_id');
    if (activityId) {
      dispatch(setActivityId(activityId));
    }
    // 获取fb的pixelId
    const pixelId = searchParams.get('fbPixelId');
    if (pixelId) {
      dispatch(setPixelId(pixelId));
    }
    // fb按钮，透传cpay用
    const fbBtn = searchParams.get('fb_btn');
    if (fbBtn) {
      dispatch(setFbBtn(fbBtn));
    }
    // 获取kwai的pixelId
    const kwaiPixelId = searchParams.get('kwaiPixelId');
    if (kwaiPixelId) {
      dispatch(setKwaiPixelId(kwaiPixelId));
    }
    // 获取tiktok的pixelId
    const tiktokPixelId = searchParams.get('tiktokPixelId');
    if (tiktokPixelId) {
      dispatch(setTiktokPixelId(tiktokPixelId));
    }
    // 获取bigo的pixelId
    const bigoPixelId = searchParams.get('bigoPixelId');
    if (bigoPixelId) {
      dispatch(setBigoPixelId(bigoPixelId));
    }

    updateBrowserAddress();
  }, [searchParams]);

  useEffect(() => {
    let intervalId = null;

    if (!identity) {
      clearInterval(intervalId);

      if (isAndroidNative()) {
        //如果原生有第三方登录需要解除登录状态
        window.native.logout_facebook();
      }
    } else {
      if (!toNoLocalePath(pathname).includes('/promotion')) {
        InitPromotionClaimedCount();
      }

      intervalId = setInterval(() => {
        InitPromotionClaimedCount(1);
      }, 63 * 1000);

      if (!(toNoLocalePath(pathname) === '/email' || toNoLocalePath(pathname) === '/supportmessage/2')) {
        // read_status 0:未读；1：已读；其他：所有数据（注：消息最多返回50条）
        InboxList().then((res) => {
          if (res.code == 0) {
            const n = res.result.list.filter((item) => item.read_mark == 0);
            dispatch(setUnReadCount(n.length));
          }
        });
      }
      if (window.__fcmToken) {
        ModifyUserReq({
          push_message_token: window.__fcmToken,
        });
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [identity]);

  useEffect(() => {
    // pathname改变时，设置对应语言
    const { langKey } = getLangFromPath(pathname);
    const target = langKey !== langsConfig.initLangKey ? langKey : defaultLang;
    changeLang(target);

    // const bodyElements = document.getElementsByTagName('body');
    // if (bodyElements.length > 0) {
    //   const bodyElement = bodyElements[0];
    //   if (toNoLocalePath(pathname) === '/') {
    //     bodyElement.classList.add('disableUserSelection');
    //   } else {
    //     bodyElement.classList.remove('disableUserSelection');
    //   }
    // }
    updateBrowserAddress();

    if (toNoLocalePath(pathname) == '/installPwa' && isPWA()) {
      navigate(toLocalePath('/'), { replace: true });
    }

    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  }, [pathname]);

  useEffect(() => {
    InitActivityStatus();
  }, [identity, pathname]);

  return (
    <>
      {toNoLocalePath(pathname) != '/download' ? <GlobalWebSocket /> : null}
      <Message />
      <Activity />
      <audio
        id="Audio"
        onTimeUpdate={handleTimeUpdate}
        onCanPlayThrough={handleCanPlayThrough}
        onEnded={handleEnd}
        onLoadStart={handleloadStart}
      />
    </>
  );
};

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { hanldeRefurbishBalance } = useHandleLogic();
  const loginTypeRef = useRef('');
  const hasExecutedRef = useRef(false);
  const t = i18n.t;
  const [initLoading, setInitLoading] = useState(true); //请求网站配置loading
  // const { t } = useTranslation();
  const {
    forbidden,
    isMaintain,
    identity,
    defaultLang,
    websiteInitConfig,
    userObj,
    reloadWebsiteInitData,
    needUpdateBalance,
    loginType,
    uid,
    isGameOpen,
    depositSuccessPwaGuideData,
    exitGameTime,
  } = useSelector(
    (state) => ({
      forbidden: get(state, 'app.forbidden'),
      isMaintain: get(state, 'app.isMaintain'),
      identity: get(state, 'user.identity'),
      defaultLang: get(state, 'user.defaultLang'),
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
      userObj: get(state, 'user.userObj'),
      uid: get(state, 'user.userObj.uid', ''),
      loginType: get(state, 'user.loginType'),
      reloadWebsiteInitData: get(state, 'app.reloadWebsiteInitData'),
      needUpdateBalance: get(state, 'app.needUpdateBalance'),
      depositSuccessPwaGuideData: get(state, 'app.depositSuccessPwaGuideData', null),
      exitGameTime: get(state, 'app.exitGameTime', 0),
      isGameOpen: get(state, 'app.isGameOpen', false),
    }),
    shallowEqual,
  );

  // 测试通知按钮 （iOS由于用户交互才能触发通知权限,可以在任意用户交互后请求权限）
  const initNotificationConfig = (firebaseConfig) => {
    if (isIPhone()) {
      document.addEventListener('touchstart', () => {
        initializeFirebaseMessaging(firebaseConfig);
      });
    } else {
      initializeFirebaseMessaging(firebaseConfig);
    }
  };

  useEffect(() => {
    if (isPWA()) {
      const cleanup = autoRefresh();
      return () => cleanup();
    }
  }, []);

  //网站初始化配置
  useEffect(() => {
    LangConfigReq().then((res) => {
      if (res.code == 0) {
        dispatch(setLangServerData(res.result.data));
      }
    });

    GetWebsiteInit().then((res) => {
      const result = res.result;
      // result.website_info = {
      //   ...result.website_info,
      //   website_template_id: 'br-d-template003',
      //   website_theme: 'theme-18',
      //   website_lang: ['en', 'id', 'pt'],
      //   switch_wallet_crypto_withdraw: '1',
      //   currency_default_location: '1',
      //   website_license_switch: '1',
      //   gtm_id: 'GTM-T7KB8MZW',
      // };

      const isPreview = getQueryString('preview');

      if (isEnvDevelopment() || isPreview) {
        const website_template_id = getQueryString('website_template_id');
        if (website_template_id) {
          result.website_info = {
            ...result.website_info,
            website_template_id: website_template_id,
          };
        }

        const theme = getQueryString('theme');
        if (theme) {
          result.website_info = {
            ...result.website_info,
            website_theme: theme,
          };
        }
      }
      //set template and theme
      const websiteInfo = result?.website_info;
      const channelConfig = result?.channel_config;
      const initIdentity = getIdentity();
      OAuthRedirecting.Init(initIdentity, websiteInfo).then(async () => {
        if (isPWA() && !localStorage.getItem('unofficialpwaTag')) {
          const from = getQueryString('_from');
          // from 1:官方pwa 2:非官方pwa
          if (from) {
            localStorage.setItem('unofficialpwaTag', '1');
          } else {
            localStorage.setItem('unofficialpwaTag', '2');
          }
        }

        if (websiteInfo) {
          setBrowserTheme(websiteInfo.website_theme);
          document.documentElement.setAttribute(
            'class',
            `${getTemplateId(result)} ${websiteInfo.website_theme} ${!isAnyMobile() ? 'device-desktop' : ''}`,
          );
          if (websiteInfo.website_firebase_push_config) {
            initNotificationConfig(websiteInfo.website_firebase_push_config);
          }
          if (websiteInfo.website_firebase_statistics) {
            const firebaseStatisticsConfig = websiteInfo.website_firebase_statistics;
            if (+firebaseStatisticsConfig.switch == 1) {
              delete firebaseStatisticsConfig.switch;
              initFirebaseAnalytics(firebaseStatisticsConfig);
            }
          }
        }
        //load lang files
        const env_langs = result?.website_info?.website_lang;
        if (Array.isArray(env_langs) && env_langs.length > 0) {
          const targetLang = env_langs[0];
          changeInitLangKey(targetLang);
          changeSupportLangList(env_langs);
          console.log('[remote] supportLangList', env_langs);
          if (!env_langs.includes(defaultLang)) {
            dispatch(setDefaultLang(targetLang));
          }
        }
        dispatch(setWebsiteInitConfig(result));

        initI18n(websiteInfo.website_lang)
          .then(() => {
            setInitLoading(false);

            if (!hasExecutedRef.current && isAndroidNative() && channelConfig) {
              const isexistUpgrade = window.native && typeof window.native.appUpgrade === 'function';
              if (isexistUpgrade) {
                const android_download_link = configData.downloadUrl + channelConfig?.android_download_link;
                const data = JSON.stringify({
                  ...channelConfig,
                  android_download_link,
                  tip: t('home.appUpgradeTip'),
                });

                window.native.appUpgrade(data);
              }

              hasExecutedRef.current = true;
            }
          })
          .catch((err) => {
            console.log('initI18n err:', err);
            toast.error('init lang error', {
              containerId: 'global',
            });
          });
      });
    });
  }, []);

  const fetchWebsitInitData = () => {
    const isPreview = getQueryString('preview');
    const website_template_id = getQueryString('website_template_id');
    if (isPreview && website_template_id) {
      GetWebsitePreviewInitLang({
        template_id: website_template_id,
      }).then((res) => {
        if (res.code == 0) {
          const result = res.result;
          dispatch(setWebsiteInitData(result));
        }
      });
    } else {
      GetWebsiteInitLang().then((res) => {
        if (res.code == 0) {
          const result = res.result;
          dispatch(setWebsiteInitData(result));
        }
      });
    }
  };

  //网站初始化数据
  useEffect(() => {
    fetchWebsitInitData();
  }, [defaultLang, identity]);

  useEffect(() => {
    updateBrowserAddress();
  }, [defaultLang]);

  useEffect(() => {
    if (reloadWebsiteInitData > 0) {
      fetchWebsitInitData();
    }
  }, [reloadWebsiteInitData]);

  useEffect(() => {
    if (needUpdateBalance.key !== 0) {
      hanldeRefurbishBalance();
    }
  }, [needUpdateBalance]);

  useDeepCompareEffect(() => {
    if (websiteInitConfig && websiteInitConfig.system_config) {
      AnalyticsManager.FirebaseSetUserInfo(userObj);
    }
  }, [userObj, websiteInitConfig]);

  useEffect(() => {
    loginTypeRef.current = loginType;
  }, [loginType]);

  useUpdateEffect(() => {
    //登录或者注册成功打点上报，且有配置中间页跳转登录
    if (uid && OAuthRedirecting.URL != null && loginTypeRef.current) {
      if (loginTypeRef.current.toLowerCase() == 'signup'.toLowerCase()) {
        console.error('注册打点上报');
        //注册打点上报
        AnalyticsManager.registerSuccess(userObj);
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Reg_Success);
        dispatch(setLoginType(''));
      } else if (loginTypeRef.current.toLowerCase() == 'login'.toLowerCase()) {
        console.error('登录打点上报');
        //登录打点上报
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Log_Success);
        AnalyticsManager.loginSuccess();
        dispatch(setLoginType(''));
      }
    }
  }, [uid]);

  useEffect(() => {
    if (!isGameOpen && exitGameTime != 0 && depositSuccessPwaGuideData) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.FirstDepositDownload,
          depositData: depositSuccessPwaGuideData.depositData,
          content: depositSuccessPwaGuideData.content,
        }),
      );
    }
  }, [isGameOpen, exitGameTime, depositSuccessPwaGuideData]);

  return (
    <>
      <ThirdPartyJavascript />
      {forbidden ? (
        <Forbidden />
      ) : isMaintain ? (
        <Maintain />
      ) : (
        <>
          {!initLoading ? (
            <>
              <AppInit />
              <AppRouter />
            </>
          ) : null}
        </>
      )}
    </>
  );
}

export default App;
