import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Countdown, { zeroPad } from 'react-countdown';

const TwoNumber = ({ item = '', index, length, className2 }) => {
  const { t } = useTranslation();
  const _txtClass =
    'min-w-[.48rem] min-h-[.68rem] rounded-small flex items-center justify-center mr-[.06rem] bg-[var(--colour-7)] text-[var(--colour-2)]';

  const SplitTime = (time) => {
    const timeValue = time ?? '00';
    if (timeValue.length === 1) {
      return (
        <div key={`${item.text}-single`} className={`${_txtClass} `}>
          {time}
        </div>
      );
    } else {
      let arr = (timeValue ?? '00').split('');
      if (arr.length > 2) {
        arr = ['9', '9'];
      }
      return (
        arr &&
        arr.map((item, idx) => {
          const uniqueKey = `${item}-${idx}`;
          return (
            <Fragment key={uniqueKey}>
              <div className={`${_txtClass}`}>{item}</div>
            </Fragment>
          );
        })
      );
    }
  };

  return (
    <>
      <div className={`${className2} ${item.text == t('guideWheel.day') ? 'mr-[.18rem]' : ''} relative`}>
        {SplitTime(item.time)}

        <div className="text-[var(--colour-7)]">
          {index < length - 1 && item.text != t('guideWheel.day') ? ':' : ''}
        </div>

        <div
          className={`absolute text-[.24rem] scale-[.9] font-[400] whitespace-nowrap top-[.76rem] left-[50%] text-[var(--colour-48)] ${item.text == t('guideWheel.day') ? 'translate-x-[-80%]' : ' translate-x-[-62%]'}`}
        >
          {item.text}
        </div>
      </div>
    </>
  );
};

const TimeCountdown = ({ date = Date.now(), className = '', className2 = '', onComplete = () => {} }) => {
  const { t } = useTranslation();
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const list = completed
      ? ['00', '00', '00', '00']
      : [
          {
            text: t('guideWheel.day'),
            time: zeroPad(days, 2),
          },
          {
            text: t('guideWheel.hours'),
            time: zeroPad(hours, 2),
          },
          {
            text: t('guideWheel.minutes'),
            time: zeroPad(minutes, 2),
          },
          {
            text: t('guideWheel.seconds'),
            time: zeroPad(seconds, 2),
          },
        ];
    return list.map((item, index) => (
      <TwoNumber key={index} item={item} index={index} length={list.length} className2={className2} />
    ));
  };

  return (
    <>
      <div className={`flex items-center justify-center select-none ${className}`}>
        <Countdown date={date} onComplete={onComplete} renderer={renderer} />
      </div>
    </>
  );
};

const expirationTime = ({ startTime = '', endTime = '', handleClose = () => {} }) => {
  const getCountdown = () => {
    const now = Date.now();
    const start = startTime * 1000;
    const end = endTime * 1000;

    if (now < start) {
      return {
        date: start,
      };
    } else if (now >= start && now < end) {
      return {
        date: end,
      };
    } else {
      return {
        date: Date.now(),
      };
    }
  };

  const getOnComplete = () => {
    const now = Date.now();
    const end = endTime * 1000;

    if (now >= end) {
      return handleClose;
    } else {
      return () => {
        handleClose();
      };
    }
  };

  return (
    <>
      {!startTime || !endTime ? (
        <div className="min-h-[.68rem]"></div>
      ) : (
        <TimeCountdown
          date={getCountdown().date}
          className="text-[.44rem] font-[700] p-[0_0_.4rem_.2rem]"
          className2="flex w-[1.24rem] min-w-[1.24rem] max-w-[1.24rem]"
          onComplete={handleClose}
        />
      )}
    </>
  );
};
export default expirationTime;
