import React, { useState, useEffect } from 'react';
import Close from '../close';
import Icon, { IconName } from '../icon';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { setFairCheckData } from '../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { updateSeed } from '../../manager/iframeManager';

export default function FairCheck({ handleClose }) {
  const dispatch = useDispatch();
  const { fairCheckData } = useSelector(
    (state) => ({
      fairCheckData: state.app.fairCheckData,
    }),
    shallowEqual,
  );
  const { t, i18n } = useTranslation();

  const cutSeedNum = () => {
    const { seed } = fairCheckData[0];
    if (seed == 0) {
      setSeed(fairCheckData[0].maxSeed);
    } else {
      let res = seed - 1;
      setSeed(res);
    }
  };
  const addSeedNum = () => {
    const { seed } = fairCheckData[0];
    if (seed == fairCheckData[0].maxSeed) {
      setSeed(0);
    } else {
      let res = seed + 1;
      setSeed(res);
    }
  };

  const setSeed = (m) => {
    let obj1 = { ...fairCheckData[0], seed: m };
    let arr = [...fairCheckData];
    arr[0] = obj1;
    dispatch(setFairCheckData(arr));
    updateSeed(m);
  };

  return (
    <div className="bg-[#192028] border border-[#2D3947] rounded-[.2rem] box-shadow-4 relative px-[.64rem] pt-[.02rem] pb-[.6rem] text-center min-h-[7.3rem] w-[7.18rem]">
      <div className="absolute bg-[url(/static/img/bgclose.png)] bg-no-repeat bg-[length:100%_100%] right-[.48rem] top-[.26rem]">
        <Close onClick={handleClose} />
      </div>
      <div className={`flex flex-row justify-between items-center mt-[.4rem]`}>
        <div className="text-[.36rem] font-[600]">{t('game.game_fairCheck_title')}</div>
      </div>
      <div className="pb-[.4rem]">
        <div className="w-full bg-[#2D3947] h-[.02rem] my-[.28rem]"></div>
        <div className="text-[.28rem] text-[#6e6e6e] mb-[.6rem]">{t('game.game_fairCheck_next_game')}</div>
        {fairCheckData[0] && fairCheckData[0].seed != undefined && (
          <>
            <div className="text-[white] whitespace-pre-line mb-[.04rem]">{t('game.game_fairCheck_client_seed')}</div>
            <div className="flex justify-center mb-[.4rem]">
              <button
                className="w-[.4rem] h-[.5rem] bg-[#595959] cursor-pointer flex justify-center items-center"
                onClick={cutSeedNum}
              >
                <div className="rotate-[180deg] w-[.18rem] h-[.32rem]">
                  <Icon name={IconName.ArrowsRight}></Icon>
                </div>
              </button>
              <div className="w-[1.28rem] h-[.5rem] bg-[#cccccc] text-[#323c5a] leading-[.5rem]">
                {fairCheckData[0].seed}
              </div>
              <button
                className="w-[.4rem] h-[.5rem] bg-[#595959] cursor-pointer  flex justify-center items-center"
                onClick={addSeedNum}
              >
                <div className="rotate-[0deg] w-[.18rem] h-[.32rem]">
                  <Icon name={IconName.ArrowsRight}></Icon>
                </div>
              </button>
            </div>
          </>
        )}
        <div className="text-[.24rem] text-[#6e6e6e] mb-[.04rem]">{t('game.game_fairCheck_hash')}</div>
        <div className="h-[.5rem] rounded-[.1rem] flex overflow-hidden">
          <input
            type="text"
            className="bg-[#cccccc] flex-1 pl-[.3rem] text-[#323c5a] border-none"
            value={fairCheckData[0]?.hashS ? fairCheckData[0].hashS : ''}
            disabled
            onChange={() => {}}
          ></input>
          <div
            onClick={() => {
              if (!fairCheckData[0]?.hashS) return;
              copy(fairCheckData[0]?.hashS);
              toast.success(t('home.toast006'), {
                containerId: 'global',
              });
            }}
            className="w-[.6rem] bg-[#595959] flex justify-center items-center cursor-pointer"
          >
            <span className="w-[.3rem] h-[.3rem]">
              <Icon name={IconName.Copy2}></Icon>
            </span>
          </div>
        </div>
      </div>
      {fairCheckData[1] && (
        <div className="pt-[.3rem] text-center" style={{ borderTop: '.06rem solid #6c6c6c' }}>
          <div className="text-[.28rem] text-[#6e6e6e] mb-[.2rem]">{t('game.game_fairCheck_last_game')}</div>
          {fairCheckData[1].secret && (
            <>
              {' '}
              <div className="text-[.24rem] text-[#6e6e6e] mb-[.04rem]">{t('game.game_fairCheck_secret')}</div>
              <div className="h-[.5rem] rounded-[.1rem] flex overflow-hidden mb-[.26rem]">
                <input
                  type="text"
                  className="bg-[#cccccc] flex-1 pl-[.3rem] text-[#323c5a] border-none"
                  value={fairCheckData[1].secret}
                  onChange={() => {}}
                ></input>
                <div
                  onClick={() => {
                    copy(fairCheckData[1].secret);
                    toast.success(t('home.toast006'), {
                      containerId: 'global',
                    });
                  }}
                  className="w-[.6rem] bg-[#595959] flex justify-center items-center cursor-pointer"
                >
                  <span className="w-[.3rem] h-[.3rem]">
                    <Icon name={IconName.Copy2}></Icon>
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="">
            {fairCheckData[1].client_seed != undefined && (
              <div className="w-full">
                <div className="text-[.24rem] text-[#6e6e6e] mb-[.04rem]">{t('game.game_fairCheck_client_seed')}</div>
                <div className="h-[.5rem] rounded-[.1rem] flex overflow-hidden mb-[.26rem]">
                  <input
                    type="text"
                    className="bg-[#cccccc] flex-1 pl-[.3rem] w-full text-[#323c5a] border-none"
                    value={fairCheckData[1].client_seed}
                    onChange={() => {}}
                  ></input>
                </div>
              </div>
            )}

            {fairCheckData[1].hash && (
              <>
                <div className="flex-1">
                  <div className="text-[.24rem] text-[#6e6e6e] mb-[.04rem]">{t('game.game_fairCheck_hash')}</div>
                  <div className="h-[.5rem] rounded-[.1rem] flex overflow-hidden mb-[.26rem]">
                    <input
                      type="text"
                      className="bg-[#cccccc] flex-1 pl-[.3rem] w-full text-[#323c5a] border-none"
                      value={fairCheckData[1].hash}
                      onChange={() => {}}
                    ></input>
                    <div
                      onClick={() => {
                        copy(fairCheckData[1].hash);
                        toast.success(t('home.toast006'), {
                          containerId: 'global',
                        });
                      }}
                      className="w-[.6rem] bg-[#595959] flex justify-center items-center cursor-pointer"
                    >
                      <span className="w-[.3rem] h-[.3rem]">
                        <Icon name={IconName.Copy2}></Icon>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {fairCheckData[1].result && (
            <>
              <div className="text-[.24rem] text-[#6e6e6e] mb-[.04rem]">{t('game.game_fairCheck_result')}</div>
              <div className="h-[.5rem] rounded-[.1rem] flex overflow-hidden mb-[.26rem]">
                <input
                  type="text"
                  className="bg-[#ffcc00] flex-1 pl-[.3rem] text-[#323c5a] border-none"
                  value={fairCheckData[1].result}
                  onChange={() => {}}
                ></input>
                <div
                  onClick={() => {
                    copy(fairCheckData[1].result);
                    toast.success(t('home.toast006'), {
                      containerId: 'global',
                    });
                  }}
                  className="w-[.6rem] bg-[#595959] flex justify-center items-center cursor-pointer"
                >
                  <span className="w-[.3rem] h-[.3rem]">
                    <Icon name={IconName.Copy2}></Icon>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
