import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import config from '../../constants/config';
import { langsConfig } from '../../constants/langs';

// /api/login_by_username ， login_method = 1
// /api/login_by_mobile， login_method = 2
// /api/login_by_sns_id， login_method = 3
// /api/login_by_wallet_address， login_method = 4
// 2，3，4，就不显示邮箱激活，短信绑定，修改密码按钮了
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loginType: '',
    identity: null,
    userObj: null,
    loginMethod: null,
    inviteCode: '',
    activityId: '',
    osType: '',
    advertisingId: '',
    defaultCurrency: config.defaultDigitalCurrency,
    defaultCurrencyType: config.defaultCurrencyType,
    defaultFiat: config.defaultFiatCurrency,
    sportCurrency: config.defaultSportCurrency,
    defaultLang: langsConfig.initLangKey,
    searchHistory: [],
    guestUid: 'guest_' + uuidv4(), // 游客uid，客户端生成
    loginChange: false,
    withdrawChange: false,
    pixelId: '', // fb的pixelId
    kwaiPixelId: '', // kwai的pixelId
    tiktokPixelId: '', // tiktok的pixelId
    bigoPixelId: '', // bigo的pixelId
    fissionTipsNotShowToday: '0', //裂变弹窗今日不再弹出，0为继续弹出，1为不弹出
    fissionTipsLoggedNotShowToday: '0', //裂变弹窗今日不再弹出，0为继续弹出，1为不弹出
    fissionTipsNextTime: 0, //下一次提示裂变弹窗的时间
    fissionTipsLoggedNextTime: 0, //下一次提示裂变弹窗的时间
    announcementhidePopup: 0, // 公告今日不再弹出 0为继续弹出 1为不弹出
    announcementhidePopupNextTime: '0', // 公告弹窗下一次弹出时间 '0'继续弹出 时间戳下次弹出时间
    SpinguideWheelStatus: '0', // 未登录情况下用户是否已经旋转轮盘   0 为弹出  1位不弹出,
    SpinguideWheelID: '', // 记录轮盘活动ID
    pwaRoundID: '',
  },
  reducers: {
    initState: (state) => {
      !state.defaultLang && (state.defaultLang = langsConfig.initLangKey);
      !state.defaultFiat && (state.defaultFiat = config.defaultFiatCurrency);
      !state.guestUid && (state.guestUid = 'guest_' + uuidv4());
    },
    setLoginChange: (state, { payload }) => {
      state.loginChange = get(payload, 'loginChange', null);
    },
    setWithDrawChange: (state, { payload }) => {
      state.withdrawChange = get(payload, 'withdrawChange', null);
    },
    setLoginInfo: (state, { payload }) => {
      state.identity = get(payload, 'identity', null);
    },
    setUserObj: (state, { payload }) => {
      state.userObj = payload;
    },
    setDefaultCurrency: (state, { payload }) => {
      state.defaultCurrency = payload;
    },
    setDefaultCurrencyType: (state, { payload }) => {
      state.defaultCurrencyType = payload;
    },
    setDefaultFiat: (state, { payload }) => {
      state.defaultFiat = payload;
    },
    setDefaultLang: (state, { payload }) => {
      state.defaultLang = payload;
    },
    setLoginMethod: (state, { payload }) => {
      state.loginMethod = payload;
    },
    setLoginType: (state, { payload }) => {
      state.loginType = payload;
    },
    setInviteCode: (state, { payload }) => {
      state.inviteCode = payload;
    },
    setActivityId: (state, { payload }) => {
      state.activityId = payload;
    },
    setAdvertisingId: (state, { payload }) => {
      state.advertisingId = payload;
    },
    setOsType: (state, { payload }) => {
      state.osType = payload;
    },
    setSportCurrency: (state, { payload }) => {
      state.sportCurrency = payload;
    },
    setSearchHistory: (state, { payload }) => {
      state.searchHistory = payload;
    },
    setGuestUid: (state, { payload }) => {
      state.guestUid = payload;
    },
    setPixelId: (state, { payload }) => {
      state.pixelId = payload;
    },
    setKwaiPixelId: (state, { payload }) => {
      state.kwaiPixelId = payload;
    },
    setTiktokPixelId: (state, { payload }) => {
      state.tiktokPixelId = payload;
    },
    setBigoPixelId: (state, { payload }) => {
      state.bigoPixelId = payload;
    },
    setFissionTipsNotShowToday: (state, { payload }) => {
      state.fissionTipsNotShowToday = payload;
    },
    setFissionTipsNextTime: (state, { payload }) => {
      state.fissionTipsNextTime = payload;
    },
    setFissionTipsLoggedNotShowToday: (state, { payload }) => {
      state.fissionTipsLoggedNotShowToday = payload;
    },
    setFissionTipsLoggedNextTime: (state, { payload }) => {
      state.fissionTipsLoggedNextTime = payload;
    },
    setAnnouncementhidePopup: (state, { payload }) => {
      state.announcementhidePopup = payload;
    },
    setAnnouncementhidePopupNextTime: (state, { payload }) => {
      state.announcementhidePopupNextTime = payload;
    },
    setSpinguideWheelStatus: (state, { payload }) => {
      state.SpinguideWheelStatus = payload;
    },
    setSpinguideWheelID: (state, { payload }) => {
      state.SpinguideWheelID = payload;
    },
    setpwaRoundID: (state, { payload }) => {
      state.pwaRoundID = payload;
    },
  },
});

export const {
  initState,
  setLoginInfo,
  setUserObj,
  setWithDrawChange,
  setDefaultCurrency,
  setDefaultCurrencyType,
  setDefaultFiat,
  setDefaultLang,
  setLoginMethod,
  setLoginType,
  setInviteCode,
  setActivityId,
  setAdvertisingId,
  setOsType,
  setSportCurrency,
  setSearchHistory,
  setGuestUid,
  setLoginChange,
  setPixelId,
  setKwaiPixelId,
  setTiktokPixelId,
  setBigoPixelId,
  setFissionTipsNotShowToday,
  setFissionTipsNextTime,
  setFissionTipsLoggedNotShowToday,
  setFissionTipsLoggedNextTime,
  setAnnouncementhidePopup,
  setAnnouncementhidePopupNextTime,
  setSpinguideWheelStatus,
  setSpinguideWheelID,
  setpwaRoundID,
} = userSlice.actions;

export default userSlice.reducer;
