import { DialogModalChildrenKey, setDialogModalVisible } from '../../redux/reducers/appSlice';
import Icon, { IconName } from '../icon';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import useAudioplayer from '../../hooks/useAudioplayer.js';
import cn from 'classnames';

const Music = ({ handleLinkClick }) => {
  const dispatch = useDispatch();
  const { handlePlay, hadnlePrev, handleNext, handleChange, songsobj } = useAudioplayer();
  const { audioPlayerStatus, websiteInitConfig } = useSelector(
    (state) => ({
      audioPlayerStatus: state.app.audioPlayerStatus,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  return (
    <>
      <div
        className={`w-full p-[.34rem_.36rem_.12rem_.36rem] mb-[.16rem] text-[var(--colour-10)] ${websiteInitConfig?.website_info.website_theme == 'theme-15' ? 'bg-menu-default' : ' bg-[var(--colour-16)] rounded-normal '}`}
      >
        <div className="w-full flex items-centet justify-between mb-[.16rem]">
          <div className="flex-1 flex justify-around items-center">
            <i
              className="w-[.28rem] h-[.28rem] cursor-pointer text-[var(--colour-1)]"
              onClick={() => {
                hadnlePrev();
                handleLinkClick();
              }}
            >
              <Icon name={IconName.PrevMusic} />
            </i>

            <i
              className="w-[.28rem] h-[.28rem] cursor-pointer text-[var(--colour-1)]"
              onClick={() => {
                handlePlay();
                handleLinkClick();
              }}
            >
              {audioPlayerStatus.isPlaying ? <Icon name={IconName.PlayMusic} /> : <Icon name={IconName.PauseMusic} />}
            </i>

            <i
              className="w-[.28rem] h-[.28rem] cursor-pointer text-[var(--colour-1)]"
              onClick={() => {
                handleNext();
                handleLinkClick();
              }}
            >
              <Icon name={IconName.NextMusic} />
            </i>

            <i className="w-[.28rem] h-[.28rem] cursor-pointer text-[var(--colour-1)]" onClick={handleChange}>
              <Icon
                name={
                  audioPlayerStatus.playMode == 'loop'
                    ? IconName.Playorder
                    : audioPlayerStatus.playMode == 'random'
                      ? IconName.ShufflePlay
                      : IconName.PlayBack
                }
              />
            </i>

            <div className="relative w-[.28rem] h-[.28rem]">
              <i
                className="cursor-pointer text-[var(--colour-1)] mt-[.04rem]"
                onClick={() => {
                  dispatch(
                    setDialogModalVisible({
                      show: DialogModalChildrenKey.PlayerMusic,
                    }),
                  );
                  handleLinkClick();
                }}
              >
                <Icon name={IconName.MusicList} />
              </i>
              <div className="absolute       text-[.2rem] font-[700] leading-[.24rem] rounded-[.1rem] top-[-0.16rem] right-[.06rem] pl-[.06rem] pr-[.08rem] bg-[var(--colour-22)] border border-[var(--colour-6)] user-none">
                <div className="text-[var(--colour-10)]">{songsobj.total || 0}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-[.24rem] font-[500] leading-[.3rem] w-full text-center truncate text-[var(--colour-10)]">
          {audioPlayerStatus.musicName || songsobj.name || ''}
        </div>
      </div>
    </>
  );
};

export default Music;
