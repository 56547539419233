import { Trans, useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../components/icon';
import Badge from './components/badge';

import {
  getNationalFlagIconNameFromCurrencyCode,
  getPercentage,
  getShowMoney,
  getUserPhoto,
  getVipLevelLogin,
  langIconName,
  setVipLevelLogin,
} from '../../../../utils/helper';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import {
  DialogModalChildrenKey,
  ResponsiveMode,
  setDialogModalVisible,
  setNeedLogout,
  setRefetchActivityData,
} from '../../../../redux/reducers/appSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import useRouterHelper from '../../../../hooks/useRouterHelper';
import { useEffect, useState, Fragment } from 'react';
import { GetPromotionVipList } from '../../../../api';
import DialogModal from '../../../../components/dialogModal';
import UserInfoDialog from '../../../../components/dialogModal/userinfoDialog';
import BigNumber from 'bignumber.js';
import Report from '../../../report';
import Security from '../../../security';
import MineBonus from '../../../myBonus';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import VipUpgrade from '../../../../components/dialogModal/vipUpgrade';

const Arrow = ({ className = 'text-[var(--colour-11)]' }) => {
  return (
    <div className={`w-[.24rem] h-[.24rem] shrink-0 ${className}`}>
      <Icon name={IconName.RightArrow3} />
    </div>
  );
};

const NavItem = ({ icon, title, text, onClick }) => {
  return (
    <div className="flex items-center justify-between px-[12px]" onClick={onClick}>
      <div className="flex items-center space-x-[12px]">
        {icon}
        {title}
      </div>
      <div className="flex items-center space-x-[8px]">
        {text}
        <Arrow />
      </div>
    </div>
  );
};

const VipInfo = ({ myLevel, maxLevel, myWager, totalWager, myDeposit, totalDeposit }) => {
  // console.log('myLevel:', myLevel, 'maxLevel:', maxLevel, 'myWager:', myWager, 'totalWager:', totalWager, 'myDeposit:', myDeposit, 'totalDeposit:', totalDeposit);

  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: state?.app?.responsiveMode,
    }),
    shallowEqual,
  );

  const onClick = () => {
    navigate(toLocalePath('/promotion/vip'), { state: { from: '/mine' } });
  };

  const getNumberDiffirence = (firstNumber, secondNumber, decimal = 2) => {
    if (Number(firstNumber) >= Number(secondNumber) || firstNumber === undefined || secondNumber === undefined) {
      return 0;
    } else {
      // const val = BigNumber(secondNumber)
      //   .minus(firstNumber)
      //   .toFixed(decimal + 1);
      // return Number.parseFloat(val.slice(0, val.length));
      const val = BigNumber(secondNumber).minus(firstNumber);
      return getShowMoney(val, 3);
    }
  };

  return (
    <div
      className={cn(
        'h-[2.36rem] mx-[.24rem] rounded-[8px] cursor-pointer bg-[var(--colour-63)] border border-[transparent]',
      )}
      onClick={onClick}
    >
      <div className="p-[0_.24rem] h-[.48rem] flex items-center space-x-[.12rem] mt-[.16rem]">
        <div className="shrink-0 px-[.04rem] h-[.32rem] rounded-[.16rem_0_.16rem_0] bg-[var(--colour-59)] flex items-center justify-center">
          <span
            className="text-[.16rem] font-[600] italic whitespace-nowrap scale-[1] text-[var(--colour-58)] account_vip"
            data-text={`VIP ${myLevel}`}
          >
            VIP {myLevel}
          </span>
        </div>
        <div className="flex-1 text-[.24rem] font-[400] text-[var(--colour-65)] leading-[1em]">
          <Trans
            t={t}
            i18nKey="account.vip_tip"
            components={[
              <span className="text-[var(--colour-74)]" key={0}></span>,
              <span className="text-[var(--colour-74)]" key={1}></span>,
              <span className="text-[var(--colour-74)]" key={2}></span>,
            ]}
            values={{
              val: `VIP${Number(myLevel + 1) > Number(maxLevel) ? maxLevel : Number(myLevel + 1)}`,
              val2: getNumberDiffirence(myDeposit, totalDeposit),
              val3: getNumberDiffirence(myWager, totalWager),
            }}
          />
        </div>
        <Arrow className="text-[var(--colour-65)]" />
      </div>

      <div className="h-[.02rem] mx-[.24rem] bg-[var(--colour-64)] mt-[.18rem]"></div>
      <div className="h-[.02rem] mx-[.24rem] bg-[var(--colour-67)]"></div>

      <div className="p-[.2rem_.24rem_0] flex space-x-[.24rem]">
        <div className="shrink-0 w-[.92rem] h-[.92rem] bg-[length:100%_100%] bg-[url(/static/img/mine/bg-vip.png)] flex items-center justify-center">
          <span className="text-[.28rem] font-[700] text-[var(--colour-56)]">{myLevel}</span>
        </div>
        <div className="flex-1 flex flex-col justify-around space-y-[.08rem]">
          <div className="flex space-x-[.02rem]">
            <div className="text-[var(--colour-65)] text-[.2rem] font-[400] w-[1.48rem] leading-[1.2em] shrink-0">
              {t('account.deposit_for_promotion')}
            </div>
            <div
              className="flex-1 h-[.18rem] rounded-[.1rem] relative bg-[var(--colour-66)] overflow-hidden"
              style={{ boxShadow: '0px -1px 0px 0px var(--colour-65) inset' }}
            >
              <div
                className="absolute left-0 top-0 bottom-0 bg-[var(--colour-29)] rounded-[.1rem] flex items-center justify-center"
                style={{
                  width: getPercentage(Number(myDeposit) || 0, Number(totalDeposit) || 1) + '%',
                }}
              ></div>
              <div className="absolute inset-0 flex items-center justify-center text-[.2rem] font-[500] text-[var(--colour-56)]">
                {[myDeposit, totalDeposit].every((val) => val != undefined) &&
                  `${isNaN(myDeposit) ? '0' : getShowMoney(myDeposit)}/${isNaN(totalDeposit) ? '0' : getShowMoney(totalDeposit)}`}
              </div>
            </div>
          </div>
          <div className="flex space-x-[1px]">
            <div className="text-[var(--colour-65)] text-[.2rem] font-[400] w-[1.48rem] leading-[1.2em] shrink-0">
              {t('account.bet_for_promotion')}
            </div>
            <div
              className="flex-1 h-[.18rem] rounded-[.1rem] relative bg-[var(--colour-66)] overflow-hidden"
              style={{ boxShadow: '0px -1px 0px 0px var(--colour-65) inset' }}
            >
              <div
                className="absolute left-0 top-0 bottom-0 bg-[var(--colour-29)] rounded-[.1rem] flex items-center justify-center"
                style={{
                  width: getPercentage(Number(myWager) || 0, Number(totalWager) || 1) + '%',
                }}
              ></div>
              <div className="absolute inset-0 flex items-center justify-center text-[.2rem] font-[500] text-[var(--colour-56)]">
                {[myWager, totalWager].every((val) => val != undefined) &&
                  `${isNaN(myWager) ? '0' : getShowMoney(myWager)}/${isNaN(totalWager) ? '0' : getShowMoney(totalWager)}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserInfo = () => {
  const { t } = useTranslation();

  const { userObj, websiteInitConfig, needUpdateBalance, defaultLang, currency_code } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      websiteInitConfig: state?.app?.websiteInitConfig,
      needUpdateBalance: state?.app?.needUpdateBalance,
      defaultLang: state?.user?.defaultLang,
      currency_code: state?.app?.websiteInitConfig?.website_info.currency_code,
    }),
    shallowEqual,
  );

  const { hanldeRefurbishBalance, balanceLoad } = useHandleLogic();

  const [isOpenHead, setIsOpenHead] = useState(false);

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsOpenHead(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={isOpenHead}
      >
        <UserInfoDialog
          handleClose={() => {
            setIsOpenHead(false);
          }}
          isShowNickname={false}
        />
      </DialogModal>
      <div className="flex items-center space-x-[.08rem]">
        <div
          className="shrink-0"
          onClick={() => {
            setIsOpenHead(true);
          }}
        >
          <img
            src={getUserPhoto(userObj?.icon || 1)}
            className="rounded-full w-[.88rem] h-[.88rem] active:scale-[.95] transition-all select-none cursor-pointer"
          />
        </div>
        <div className="flex flex-col justify-between py-[.08rem]">
          <div className="font-[500] text-[.24rem] space-x-[.08rem] flex items-center">
            <span className="text-[var(--colour-41)]">{t('account.account')}:</span>
            <span className="w-[auto] text-[var(--colour-40)]">{userObj?.username}</span>
          </div>

          <div className="flex items-center text-[.32rem] font-[500]">
            <span
              className="flex items-center"
              onClick={() => {
                copy(`${userObj?.uid || ''}`);
                toast.success(t('home.toast006'), {
                  containerId: 'global',
                });
              }}
            >
              <span className="text-[var(--colour-41)]">ID:</span>
              <span className="ml-[.04rem] text-[var(--colour-40)]">{userObj?.uid}</span>
              <span className="block w-[.28rem] h-[.28rem] ml-[.08rem] text-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none">
                <Icon name={IconName.Copy4}></Icon>
              </span>
            </span>
            <span className="w-[1px] h-[.28rem] shrink-0 bg-[var(--colour-6)] mx-[.16rem]"></span>
            <div className="flex items-center space-x-[.08rem]" onClick={hanldeRefurbishBalance}>
              <span className="w-[.32rem] h-[.32rem]">
                <Icon name={getNationalFlagIconNameFromCurrencyCode(currency_code)} />
              </span>
              <span className="ml-[.04rem] text-[var(--colour-40)]">{getShowMoney(userObj?.balance, 2, 2)}</span>

              <div
                className="w-[.28rem] h-[.28rem] ml-[.08rem] cursor-pointer text-[var(--colour-1)]"
                style={balanceLoad ? { animation: 'frashenTurn2 0.5s linear infinite' } : {}}
              >
                <Icon name={IconName.RefreshBalance}></Icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TopItem = ({ iconEl, title, badgeEl, onClick = () => {}, isLarger = false }) => {
  return (
    <div className="flex flex-col items-center" onClick={onClick}>
      <div className="relative">
        {iconEl}
        {badgeEl}
      </div>
      <div
        className={cn('text-center leading-[1em] flex items-center', {
          'mt-[0px]': !isLarger,
          'mt-[.12rem]': isLarger,
        })}
      >
        {title}
      </div>
    </div>
  );
};

const NTemplate001 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { handleClickManMadeInPage, faqData, handleClickFaq } = useHandleLogic();
  const { handleClickWallet } = useRouterHelper();

  const {
    userObj,
    responsiveMode,
    footerData,
    unReadCount,
    giftMoneyData,
    refetchVipData,
    defaultLang,
    websiteInitConfig,
    activityStatus,
  } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      responsiveMode: state?.app?.responsiveMode,
      footerData: state?.app?.footerData,
      unReadCount: get(state, 'app.unReadCount'),
      giftMoneyData: state?.app?.activityData?.gift_money,
      refetchVipData: state?.app?.refetchVipData,
      defaultLang: state?.user?.defaultLang,
      websiteInitConfig: state.app.websiteInitConfig,
      activityStatus: state.app.activityStatus,
    }),
    shallowEqual,
  );

  const [isShowVipupgradeData, setisShowVipupgradeData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [vipData, setVipData] = useState(null);

  const updateVipData = () => {
    GetPromotionVipList().then((res) => {
      if (res.code === 0) {
        const data = res.data;

        setVipData(data);

        const vipLevel = data.vipLevel;
        const uid = data.uid;

        if (vipLevel != getVipLevelLogin(uid)) {
          setisShowVipupgradeData(data);
          setVipLevelLogin(vipLevel, uid);
        }
      }
    });
  };

  useEffect(() => {
    updateVipData();
  }, [refetchVipData]);

  const onClickGoOut = () => {
    const confirmCb = () => {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.SM_Click_LogoutButton);
      dispatch(setNeedLogout());
    };

    const confirmCancelCb = () => {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
    };

    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Confirm,
        confirmCb,
        confirmCancelCb,
        title: t('account.are_you_sure'),
        content: <span className="">{t('account.want_to_quit')}</span>,
        cancelText: t('game.cancel'),
        confirmText: t('game.ok'),
        isConfirmBtnPrimary: false,
      }),
    );
  };

  const showComingSoon = () => {
    const confirmCb = () => {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
    };

    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Confirm,
        confirmCb,
        content: <span className="">{t('account.coming_soon')}</span>,
        cancelText: t('game.cancel'),
        confirmText: t('game.ok'),
        isConfirmBtnPrimary: false,
      }),
    );
  };

  const showRedeem = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Redeem,
      }),
    );
  };

  const getMaxLevel = (list) => {
    const maxLevel = Number(list[list.length - 1]?.vip_level_config?.vip || 1);
    return maxLevel;
  };

  const getLevelConfig = (myLevel, list = []) => {
    const found = list.find((item) => {
      return Number(item?.vip_level_config?.vip) == Number(myLevel);
    });

    return found
      ? {
          totalDeposit: found?.vip_level_config?.total_deposit || 0,
          totalWager: found?.vip_level_config?.total_wager || 0,
        }
      : null;
  };

  const onClickGiftMoney = () => {
    if (giftMoneyData) {
      // dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney }));
      navigate(toLocalePath('/promotion/giftmoney'), { state: { from: '/mine' } });
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_GiftMoney_Floating);
    } else {
      showComingSoon();
    }
  };

  useEffect(() => {
    if (activityStatus != null) {
      dispatch(setRefetchActivityData());
    }
  }, [activityStatus]);

  return (
    <>
      {isShowVipupgradeData ? (
        <DialogModal
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              display: 'inline-block',
              textAlign: 'left',
              top: '0px',
              maxWidth: '100%',
              cursor: 'default',
              outline: '0px',
              verticalAlign: 'middle',
              zIndex: '51',
              borderRadius: '10px',
            },
          }}
          isOpen={isShowVipupgradeData != null}
        >
          <VipUpgrade
            handleClose={() => {
              setisShowVipupgradeData(null);
            }}
            data={isShowVipupgradeData}
          />
        </DialogModal>
      ) : (
        <></>
      )}

      <div
        className={cn('mt-[-.14rem] min-h-[8rem] bg-repeat-x pb-[.6rem] mx-auto', {
          'bg-[url(/static/img/mine/bg1.png)]': websiteInitConfig?.website_info.website_theme != 'theme-15',
          'bg-[url(/static/img/mine/bg-theme-15.png)]': websiteInitConfig?.website_info.website_theme == 'theme-15',
        })}
      >
        <div className="flex items-center justify-end space-x-[.22rem] p-[.35rem_.46rem_0_0]">
          <TopItem
            title={<span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('account.support')}</span>}
            iconEl={
              <span className="w-[.44rem] h-[.44rem] block text-[var(--colour-1)]">
                <Icon name={IconName.Support} />
              </span>
            }
            onClick={() => {
              handleClickManMadeInPage();
            }}
          />
          <TopItem
            title={<span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('account.message')}</span>}
            iconEl={
              <span className="w-[.44rem] h-[.44rem] block text-[var(--colour-1)]">
                <Icon name={IconName.Message} />
              </span>
            }
            badgeEl={unReadCount > 0 && <Badge text={unReadCount} className="absolute top-[-7px] right-[-22px]" />}
            onClick={() => {
              navigate(toLocalePath(`/supportmessage/2`));
            }}
          />
          <TopItem
            title={<span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('account.data')}</span>}
            iconEl={
              <span className="w-[.44rem] h-[.44rem] block text-[var(--colour-1)]">
                <Icon name={IconName.Data} />
              </span>
            }
            onClick={() => {
              navigate(toLocalePath(`/myaccountdata`));
            }}
          />
        </div>

        <div>
          <div className="m-[.24rem_0_0_.24rem]">
            <UserInfo />
          </div>
          <div className="flex justify-between mt-[.32rem] px-[.24rem]">
            <div
              className={cn('w-[1.6rem] flex items-start justify-center cursor-pointer')}
              onClick={() => {
                handleClickWallet(2);
              }}
            >
              <TopItem
                title={<span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('account.withdraw')}</span>}
                iconEl={
                  <span className="w-[.56rem] h-[.56rem] block text-[var(--colour-1)]">
                    <Icon name={IconName.Withdraw} />
                  </span>
                }
                isLarger={true}
              />
            </div>
            <div
              className={cn('w-[1.6rem] flex items-start justify-center cursor-pointer')}
              onClick={() => {
                handleClickWallet();
              }}
            >
              <TopItem
                title={<span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('account.deposit')}</span>}
                iconEl={
                  <span className="w-[.56rem] h-[.56rem] block text-[var(--colour-1)]">
                    <Icon name={IconName.Deposit} />
                  </span>
                }
                isLarger={true}
              />
            </div>

            {activityStatus?.redeem_code_switch == '1' ? (
              <div className={cn('w-[1.6rem] flex items-start justify-center cursor-pointer')} onClick={showRedeem}>
                <TopItem
                  title={
                    <span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('redeem.redeem_code')}</span>
                  }
                  iconEl={
                    <span className="w-[.56rem] h-[.56rem] block text-[var(--colour-1)]">
                      <Icon name={IconName.Redeem} />
                    </span>
                  }
                  isLarger={true}
                />
              </div>
            ) : (
              <div
                className={cn('w-[1.6rem] flex items-center justify-center cursor-pointer')}
                onClick={showComingSoon}
              >
                <TopItem
                  title={<span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('account.fee')}</span>}
                  iconEl={
                    <span className="w-[.56rem] h-[.56rem] block text-[var(--colour-1)]">
                      <Icon name={IconName.Fee} />
                    </span>
                  }
                  isLarger={true}
                />
              </div>
            )}

            <div className={cn('w-[1.6rem] flex items-start justify-center cursor-pointer')} onClick={onClickGiftMoney}>
              <TopItem
                title={<span className="font-[400] text-[.2rem] text-[var(--colour-10)]">{t('account.fund')}</span>}
                iconEl={
                  <span className="w-[.56rem] h-[.56rem] block text-[var(--colour-1)]">
                    <Icon name={IconName.MenuGiftMoney2} />
                  </span>
                }
                isLarger={true}
              />
            </div>
          </div>
        </div>

        <div className="mt-[.26rem]">
          <VipInfo
            myLevel={Number(vipData?.vipLevel || 0)}
            maxLevel={getMaxLevel(vipData?.list || [])}
            myDeposit={Number(vipData?.totalDeposit || 0)}
            totalDeposit={getLevelConfig(vipData?.vipLevel, vipData?.list)?.totalDeposit}
            myWager={Number(vipData?.totalWager || 0)}
            totalWager={getLevelConfig(vipData?.vipLevel, vipData?.list)?.totalWager}
          />
        </div>

        <div className="w-full h-full">
          <div className="space-y-[.6rem] px-[.24rem] mt-[.3rem]">
            <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                  <Icon name={IconName.Account} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.account')}</span>}
              onClick={() => {
                navigate(toLocalePath('/report/0'));
              }}
            />
            <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-24)]">
                  <Icon name={IconName.Bets} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.bets')}</span>}
              onClick={() => {
                navigate(toLocalePath('/report/1'));
              }}
            />
            <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                  <Icon name={IconName.Account} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('report.title')}</span>}
              onClick={() => {
                navigate(toLocalePath('/report/2'));
              }}
            />
          </div>

          <div className="h-[.2rem] bg-[var(--colour-6)] mt-[.32rem]"></div>

          <div className="space-y-[.6rem] px-[.24rem] mt-[.32rem]">
            {websiteInitConfig?.website_info?.switch_referral == '1' && (
              <NavItem
                icon={
                  <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                    <Icon name={IconName.ToInvite} />
                  </div>
                }
                title={
                  <span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.to_invite')}</span>
                }
                text={
                  <span className="font-[500] text-[.24rem] text-[var(--colour-13)]">
                    {t('account.million_monthly')}
                  </span>
                }
                onClick={() => {
                  navigate(toLocalePath('/referral'));
                }}
              />
            )}
            <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                  <Icon name={IconName.Data2} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.data')}</span>}
              onClick={() => {
                navigate(toLocalePath(`/myaccountdata`));
              }}
            />
            <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                  <Icon name={IconName.Security} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.security')}</span>}
              onClick={() => {
                navigate(toLocalePath(`/security`));
              }}
            />
            <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                  <Icon name={IconName.Music} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.music')}</span>}
              onClick={() => {
                dispatch(
                  setDialogModalVisible({
                    show: DialogModalChildrenKey.PlayerMusic,
                  }),
                );
              }}
            />
            {faqData && (
              <NavItem
                icon={
                  <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                    <Icon name={IconName.Faq} />
                  </div>
                }
                title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.faq')}</span>}
                onClick={handleClickFaq}
              />
            )}
            {/* <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                  <Icon name={IconName.MineBonus} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('referral.bonus')}</span>}
              onClick={() => {
                navigate(toLocalePath(`/mybonus`));
              }}
            /> */}
            <NavItem
              icon={
                <div className="w-[.4rem] h-[.4rem] text-[var(--colour-1)]">
                  <Icon name={IconName.GoOut} />
                </div>
              }
              title={<span className="font-[500] text-[.24rem] text-[var(--colour-38)]">{t('account.go_out')}</span>}
              onClick={onClickGoOut}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NTemplate001;
