import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { getShowMoney } from '../../../../utils/helper';
import { useSelector, shallowEqual } from 'react-redux';
import Loading2 from '../../../loading2';
import { ActivityGuideLog } from '../../../../api';

function WheelRecord({ Loglist }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]); //抽奖记录

  const [isScrolle, setIsScrolle] = useState(true);

  // 滚动速度，值越小，滚动越快
  const speed = 20;
  const warper = useRef();
  const childDom1 = useRef();
  const childDom2 = useRef();

  const {} = useSelector((state) => ({}), shallowEqual);

  useEffect(() => {
    setList(Loglist);
  }, [list]);

  // 开始滚动
  useEffect(() => {
    const count = 4;
    if (list && Array.isArray(list) && list.length > count) {
      // 多拷贝一层，让它无缝滚动
      childDom2.current.innerHTML = childDom1.current.innerHTML;
    } else {
      childDom2.current.innerHTML = '';
    }

    let timer;
    if (isScrolle && warper.current && childDom1.current && list && Array.isArray(list) && list.length > count) {
      timer = setInterval(() => {
        if (warper.current.scrollTop >= childDom1.current.scrollHeight) {
          // 滚动到底部时，重置滚动条到顶部
          warper.current.scrollTop = 0;
        } else {
          warper.current.scrollTop++;
        }
      }, speed);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isScrolle, list]);

  const hoverHandler = (flag) => setIsScrolle(flag);

  return (
    <>
      <div className="w-full flex items-center justify-between mt-[4.8rem]">
        <div className="w-[1.82rem] h-[1px] bg-[var(--colour-1)]"></div>
        <div className="font-[500] text-[.24rem] text-[var(--colour-1)]">{t('guideWheel.great_txt')}</div>
        <div className="w-[1.82rem] h-[1px] bg-[var(--colour-1)]"></div>
      </div>

      <div className="mt-[.12rem] bg-[var(--colour-35)] rounded-[0_0_.16rem_.16rem] overflow-hidden relative">
        <div className="h-[.52rem] w-full grid grid-cols-3 items-center font-[500] text-[.24rem] text-[var(--colour-44)]">
          <div className="flex justify-center">{t('guideWheel.time')}</div>
          <div className="flex justify-center">{t('guideWheel.surname')}</div>
          <div className="flex justify-center">{t('guideWheel.winning')}</div>
        </div>

        <div className="h-[1px] w-full bg-[var(--colour-6)]"></div>

        {isLoading ? (
          <Loading2 className="h-[1.6rem]"></Loading2>
        ) : (
          <>
            <div
              className="h-[1.75rem] hidden-scroll-y overflow-y-auto"
              ref={warper}
              onMouseMove={() => hoverHandler(false)}
              onMouseLeave={() => hoverHandler(true)}
            >
              <div className="child h-auto" ref={childDom1}>
                {list &&
                  list.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-3 items-center font-[500] text-[.24rem] text-[var(--colour-38)]"
                    >
                      <div className="flex justify-center">
                        {moment(item.create_time * 1000).format(`MM-DD HH:mm`) || ''}
                      </div>
                      <div className="flex justify-center">{item.nickname || ''}</div>
                      <div className="flex justify-center text-[var(--colour-24)]">{getShowMoney(item.amount)}</div>
                    </div>
                  ))}
              </div>
              <div className="h-auto" ref={childDom2}></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default WheelRecord;
