import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Icon, { IconName } from './icon';
import Button from './button';
import { AddFavouritesReq, DelFavouritesReq } from '../api/';
import useOnclickOutside from 'react-cool-onclickoutside';
import Image, { Effect } from './image';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setFavoriteReload,
  setReloadWebsiteInitData,
} from '../redux/reducers/appSlice';
import { cmsImgUrl, getTemplateId, isControllableSite } from '../utils/helper';
import cn from 'classnames';
import useGameInfo from '../hooks/useGameInfo';
import FavoritesImg from '../assets/img/favorites.png';
import Favorites2Img from '../assets/img/favorites2.png';
import FavoritesMobileImg from '../assets/img/favoritesMobile.png';
import Favorites2MobileImg from '../assets/img/favoritesMobile2.png';
import FavoritesMobileHomeImg from '../assets/img/favoritesMobileHome.png';
import Favorites2MobileHomeImg from '../assets/img/favoritesMobileHome2.png';
import { get } from 'lodash';

const FavoriteButtonDefault = ({ handleFavorite, isFavourites }) => {
  return (
    <Button
      className={cn('absolute right-[.08rem] top-[.08rem] z-[100]  w-[.64rem] h-[.64rem]')}
      onClick={handleFavorite}
    >
      <img className="w-full" src={isFavourites == 1 ? FavoritesImg : Favorites2Img} alt="" />
    </Button>
  );
};
const FavoriteButtonD1 = ({ handleFavorite, isFavourites }) => {
  return (
    <Button
      className={cn('absolute right-[.08rem] top-[.08rem] z-[100] w-[.44rem] h-[.44rem]')}
      onClick={handleFavorite}
    >
      <img className="w-full" src={isFavourites == 1 ? FavoritesMobileImg : Favorites2MobileImg} alt="" />
    </Button>
  );
};
const FavoriteButtonD2 = ({ handleFavorite, isFavourites }) => {
  return (
    <Button
      className={cn('absolute right-[.08rem] top-[.08rem] z-[100] w-[.32rem] h-[.32rem]')}
      onClick={handleFavorite}
    >
      <img className="w-full" src={isFavourites == 1 ? FavoritesMobileHomeImg : Favorites2MobileHomeImg} alt="" />
    </Button>
  );
};

const Item = forwardRef(
  (
    {
      model,
      handleFavoriteCallback,
      handleFavoriteNeedCallback = false,
      onClick = () => {},
      iconSize = '',
      innerClassName = '',
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const imageRef = useRef(null);

    const { handleClickGameItem } = useGameInfo();

    const { userObj } = useSelector(
      (state) => ({
        userObj: get(state, 'user.userObj'),
      }),
      shallowEqual,
    );

    const [isFavourites, setIsFavourites] = useState(model.is_favourites);
    const [hadClick, setHadClick] = useState(false);

    useEffect(() => {
      setIsFavourites(model.is_favourites);
    }, [model]);

    const handleFavorite = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (userObj) {
        isFavourites == 1 ? handleDelFavouritesReq() : handleAddFavouritesReq();
      } else {
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.LoginBox,
          }),
        );
      }
    };

    const handleAddFavouritesReq = () => {
      AddFavouritesReq({
        game_uuid: model.uuid,
      }).then((res) => {
        if (res.code == 0) {
          dispatch(setFavoriteReload());
          dispatch(setReloadWebsiteInitData());
          handleFavoriteNeedCallback ? handleFavoriteCallback() : setIsFavourites(isFavourites == 0 ? 1 : 0);
        }
      });
    };

    const handleDelFavouritesReq = () => {
      DelFavouritesReq({
        game_uuid: model.uuid,
      }).then((res) => {
        if (res.code == 0) {
          dispatch(setFavoriteReload());
          dispatch(setReloadWebsiteInitData());
          handleFavoriteNeedCallback ? handleFavoriteCallback() : setIsFavourites(isFavourites == 0 ? 1 : 0);
        }
      });
    };

    const handleOnClick = () => {
      handleClickGameItem(model.uuid, onClick);
    };

    const gamePreviewRef = useOnclickOutside(() => {
      setHadClick(false);
    });

    useImperativeHandle(ref, () => ({
      handleOnClick: handleOnClick,
    }));

    return (
      <div className={`game-preview ${iconSize}`} onClick={handleOnClick} ref={gamePreviewRef}>
        <div className="game-preview__inner-overflow">
          <div
            className={cn(`game-preview__inner ${innerClassName}`, {
              'blur-[.06rem]': hadClick,
            })}
          >
            <div
              ref={imageRef}
              className={cn('game-preview__image', {
                'scale-[1.05]': hadClick,
              })}
            >
              <Image
                className="w-[100%] h-[100%] object-cover"
                src={cmsImgUrl(model.game_icon_1x)}
                effect={Effect.blur}
                fallbackSrc={model.img}
                onError={(currentTarget) => {
                  currentTarget.src = model.img;
                }}
              />
            </div>
          </div>
          {userObj ? (
            ['d-template001'].includes(getTemplateId()) ? (
              <FavoriteButtonD1 handleFavorite={handleFavorite} isFavourites={isFavourites} />
            ) : ['d-template002'].includes(getTemplateId()) ? (
              <FavoriteButtonD2 handleFavorite={handleFavorite} isFavourites={isFavourites} />
            ) : (
              <FavoriteButtonDefault handleFavorite={handleFavorite} isFavourites={isFavourites} />
            )
          ) : (
            <></>
          )}
          {model.is_hot == 1 && isControllableSite() ? (
            <span className="absolute left-[0] top-[0]   w-[.32rem] h-[.24rem]">
              <Icon name={IconName.Hot} />
            </span>
          ) : null}
        </div>
      </div>
    );
  },
);

const EmptyItem = ({ iconSize = '', innerClassName = '' }) => {
  return (
    <div className={`game-preview opacity-[0] ${iconSize}`}>
      <div className="game-preview__inner-overflow">
        <div className={`game-preview__inner ${innerClassName}`}></div>
      </div>
    </div>
  );
};

const GameItem = forwardRef(
  (
    {
      model,
      handleFavoriteCallback,
      handleFavoriteNeedCallback = false,
      onClick = () => {},
      slidesPerView = 1,
      iconSize = '',
      innerClassName = '',
    },
    ref,
  ) => {
    return model == '' ? (
      <EmptyItem iconSize={iconSize} innerClassName={innerClassName} />
    ) : (
      <Item
        ref={ref}
        model={model}
        onClick={onClick}
        handleFavoriteCallback={handleFavoriteCallback}
        handleFavoriteNeedCallback={handleFavoriteNeedCallback}
        iconSize={iconSize}
        innerClassName={innerClassName}
      />
    );
  },
);

export default GameItem;
