import { useState } from 'react';
import store from '../redux/store';
import { remToPx, isPCLayout } from '../utils/helper';
import { setActivitySwiperSlideKey } from '../redux/reducers/appSlice';
import GSAPUtils from '../utils/gsapUtils';

export const getCenterPosition = (container, isFlyCoin = false) => {
  if (container) {
    const rect = container.getBoundingClientRect();
    const htmlRect = document.documentElement.getBoundingClientRect();
    let size = 0;
    if (isFlyCoin) {
      size = remToPx(GSAPUtils.COIN_SIZE) / 2;

      return {
        x: isPCLayout() ? rect.left - htmlRect.left + rect.width / 2 - size : rect.left + rect.width / 2 - size,
        y: rect.top + rect.height / 2 - size,
      };
    } else {
      return {
        x: rect.left + rect.width / 2 - size,
        y: rect.top + rect.height / 2 - size,
      };
    }
  }
};
export const useMinimizeAnimation = (targetClass, onMinimizeComplete = () => {}) => {
  const [style, setStyle] = useState({});

  const startMinimize = () => {
    const targetEls = document.querySelector(`.${targetClass}`);

    if (targetEls) {
      // 元素中心点
      const targetPosition = getCenterPosition(targetEls);

      // 视口中心点
      const viewportCenterX = window.innerWidth / 2;
      const viewportCenterY = window.innerHeight / 2;

      // 计算位移
      const translateX = targetPosition.x - viewportCenterX;
      const translateY = targetPosition.y - viewportCenterY;

      setStyle({
        transform: `translate(calc(${translateX}px), calc(${translateY}px)) scale(${0.1})`,
        opacity: 0.5,
        transition: 'all .5s cubic-bezier(0.4, 0, 0.2, 1)',
      });

      setTimeout(() => {
        onMinimizeComplete && onMinimizeComplete();
        store.dispatch(setActivitySwiperSlideKey(''));
      }, 450);
    } else {
      onMinimizeComplete && onMinimizeComplete();
      store.dispatch(setActivitySwiperSlideKey(''));
    }
  };

  return { style, startMinimize };
};
