import { useNavigate } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import layout3VipImg from '../../../../assets/layout3-img/VIP.png';
import { toLocalePath } from '../../../../utils/localePath';
import Tips from '../tips';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import { isLogin } from '../../../../utils/helper';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../redux/reducers/appSlice';
import Badge from '../../../../views/mine/normal/template001/components/badge';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const Layout5 = ({ clickVip, onlyIcon = false }) => {
  const { t } = useTranslation();

  const { promotionClaimedCount } = useSelector(
    (state) => ({
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );

  return onlyIcon ? (
    <>
      <Tips anchorId="overVip" className="text-[var(--colour-48)]">
        {t('home.drawer_vip1')}
      </Tips>
      <div
        onClick={clickVip}
        id="overVip"
        style={{ background: 'linear-gradient(180deg, #F166F8 0%, #D1348D 50.04%, #B30328 101.1%)' }}
        className={`   w-[.8rem] h-[.8rem] mb-[.16rem] rounded-normal cursor-pointer flex justify-center items-center relative`}
      >
        <img src={layout3VipImg} className="not-drag w-[.5rem]" />
        {(promotionClaimedCount?.data?.vip || 0) > 0 ? (
          <Badge
            textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
            className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.15rem] !right-[-.04rem] flex items-center"
            text={promotionClaimedCount?.data?.vip || 0}
          ></Badge>
        ) : null}
      </div>
    </>
  ) : (
    <div
      onClick={clickVip}
      className="active:scale-[.95] transition-all w-[1.26rem] h-[.82rem] mb-[.08rem] rounded-normal cursor-pointer bg-[url(../assets/img/drawerMenuVipBg.png)] bg-no-repeat bg-[length:100%_100%] p-[.08rem] relative"
    >
      <div className="font-[600] text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow">
        <div>{t('home.vip')}</div>
      </div>
      {(promotionClaimedCount?.data?.vip || 0) > 0 ? (
        <Badge
          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
          className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.15rem] !right-[-0.04rem] flex items-center"
          text={promotionClaimedCount?.data?.vip || 0}
        ></Badge>
      ) : null}
    </div>
  );
};

const VipBonus = ({ onlyIcon = false, handleLinkClick = () => {} }) => {
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const clickVip = () => {
    if (isLogin()) {
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.SM_Click_VipBonusButton);
      navigate(toLocalePath('/promotion/vip'));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }

    handleLinkClick();
  };

  return <Layout5 clickVip={clickVip} onlyIcon={onlyIcon} />;
};

export default VipBonus;
