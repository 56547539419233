import Button from './button';
import { useTranslation, Trans } from 'react-i18next';
import Icon, { IconName } from './icon';

const ShareBtn = ({ className = 'w-[6.86rem] h-[.86rem]', onClick, textKey = 1 }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      className={`${className}  rounded-normal flex items-center justify-center space-x-[.32rem] px-[.5rem] bg-[var(--colour-1)]`}
    >
      <span className="text-[.28rem] font-[700] text-[var(--colour-2)]">
        {textKey == 1 ? t('fission.inviteTips') : t('fission.ruletips')}
      </span>
      <span className="w-[.48rem] h-[.48rem] text-[var(--colour-2)]">
        <Icon name={IconName.Share} />
      </span>
    </Button>
  );
};

export default ShareBtn;
