import { useEffect, useState } from 'react';
import { GetReferralInviteInfo, ReferralInviteClaim } from '../../../../../api';
import InviteInfo from './components/inviteInfo';
import ReferLink from './components/referLink';
import ReferPicture from './components/referPicture';
import ReferRate from './components/referRate';
import { getShowMoney, isLogin } from '../../../../../utils/helper';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
} from '../../../../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../../utils/localePath';
import { toast } from 'react-toastify';
import ToastTip from '../../../../../components/toastTip';
import BigNumber from 'bignumber.js';
import ScrollbarContent from '../../../../../components/scrollbarContent';
import { useGlobalNavigate } from '../../../../../contexts/navigateContext';

const NTemplate001 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { currency_offset, userObj } = useSelector(
    (state) => ({
      currency_offset: state?.app?.websiteInitConfig?.system_config?.currency_offset,
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  const [inviteData, setInviteData] = useState(null);
  const [isDisableClaim, setIsDisableClaim] = useState(true);

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    if (isLogin() && inviteData?.amount && currency_offset) {
      const limit = BigNumber(currency_offset).times(0.01).toNumber();
      setIsDisableClaim(Number(inviteData?.amount) < limit);
    } else {
      setIsDisableClaim(true);
    }
  }, [inviteData, currency_offset]);

  const updateData = () => {
    if (isLogin()) {
      GetReferralInviteInfo().then((res) => {
        if (res.code == 0) {
          setInviteData(res.data);
        }
      });
    }
  };

  const showComingSoon = () => {
    const confirmCb = () => {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
    };

    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Confirm,
        confirmCb,
        content: <span className="">{t('account.coming_soon')}</span>,
        cancelText: t('game.cancel'),
        confirmText: t('game.ok'),
        isConfirmBtnPrimary: false,
      }),
    );
  };

  const onClickHistory = () => {
    if (isLogin()) {
      navigate(toLocalePath('/referral/history'));
    }
  };

  const onClickClaim = () => {
    if (isLogin() && !isDisableClaim) {
      ReferralInviteClaim().then((res) => {
        if (res.code == 0) {
          updateData();
          dispatch(setNeedUpdateBalance({ need: true }));

          // const amount = res?.data?.amount;
          // if (Number(amount) > 0) {
          //   dispatch(
          //     setDialogModalVisible({
          //       show: DialogModalChildrenKey.BonusBox,
          //       content: { showAmount: getShowMoney(amount) },
          //     }),
          //   );
          // }

          toast.error(<ToastTip type="success" content={t('referral.receive_success')} />, {
            containerId: 'tip',
            icon: false,
          });
        } else if (res.code == 1008131) {
          toast.error(
            <ToastTip
              type="err"
              content={t('referral.not_in_time', {
                val: res?.data?.settlement_time?.start,
                val2: res?.data?.settlement_time?.end,
              })}
            />,
            {
              containerId: 'tip',
              icon: false,
            },
          );
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      });
    }
  };

  return (
    <ScrollbarContent className="bg-[var(--colour-68)] h-full p-[.2rem_.32rem_.4rem]">
      <div className="bg-[var(--colour-69)] rounded-normal h-[5.88rem]">
        <InviteInfo
          money={userObj != null ? getShowMoney(inviteData?.amount || 0, 2, 2) : '-'}
          pid={inviteData?.pid ? inviteData?.pid : 'nenhum'}
          onClickMedal={showComingSoon}
          onClickHistory={onClickHistory}
          onClickClaim={onClickClaim}
          isDisableClaim={isDisableClaim}
          isDisableHistory={userObj != null ? false : true}
        />
        <div className="h-[1px] bg-[var(--colour-6)]"></div>
        <div className="p-[.2rem_.2rem_0]">
          <ReferLink directReport={userObj != null ? inviteData?.total_direct_members || 0 : '-'} />
        </div>
      </div>
      <div className="mt-[.3rem] ">
        <ReferPicture type={inviteData?.referral_config?.type} />
      </div>
      <div className="mt-[.3rem] ">
        <ReferRate ruleHtmlStr={inviteData?.referral_config?.referral_rules} />
      </div>
    </ScrollbarContent>
  );
};

export default NTemplate001;
