// import en from '../lang/en.json';
// import pt from '../lang/pt.json';
// import fil from '../lang/fil.json';
// import ja from '../lang/ja.json';
// import ms from '../lang/ms.json';
// import vi from '../lang/vi.json';
// import th from '../lang/th.json';
// import hi from '../lang/hi.json';
// import es from '../lang/es.json';
// import id from '../lang/id.json';

export const defaultInitLangKey = '';

const langs = [
  {
    key: 'en',
    key2: 'eng',
    value: 'English',
    name: 'English',
    // translation: en,
  },
  // {
  //   key: 'zh-CN',
  //   key2: 'chi',
  //   value: 'Chinese (S)',
  //   name: '中文简体',
  //   translation: zh_cn,
  // },
  // {
  //   key: 'zh-TW',
  //   key2: 'zho',
  //   value: 'Chinese (T)',
  //   name: '中文繁體',
  //   translation: zh_tw,
  // },
  {
    key: 'ja',
    key2: 'jpn',
    value: 'Japanese',
    name: '日本語',
    // translation: ja,
  },
  // {
  //   key: 'ru',
  //   key2: 'rus',
  //   value: 'Russian',
  //   name: 'русский',
  //   translation: ru,
  // },
  {
    key: 'fil',
    key2: 'tgl',
    value: 'Filipino',
    name: 'Filipino',
    // translation: fil,
  },
  {
    key: 'th',
    key2: 'tha',
    value: 'Thai',
    name: 'ไทย',
    // translation: th,
  },
  {
    key: 'vi',
    key2: 'vie',
    value: 'Vietnamese',
    name: 'Tiếng Việt',
    // translation: vi,
  },
  {
    key: 'es',
    key2: 'spa',
    value: 'Spanish',
    name: 'Español',
    // translation: es,
  },
  // {
  //   key: 'ko',
  //   key2: 'kor',
  //   value: 'Korean',
  //   name: '한국어',
  //   translation: ko,
  // },
  {
    key: 'pt',
    key2: 'por',
    value: 'Portuguese',
    name: 'Português',
    // translation: pt,
  },
  {
    key: 'ms',
    key2: 'may',
    value: 'Malay',
    name: 'Melayu',
    // translation: ms,
  },
  {
    key: 'hi',
    key2: 'hin',
    value: 'Hindi',
    name: 'हिंदी',
    // translation: hi,
  },
  {
    key: 'id',
    key2: 'ind',
    value: 'Indonesian',
    name: 'Indonesia',
    // translation: id,
  },
];

export const langsConfig = {
  initLangKey: defaultInitLangKey,
  supportLangList: langs.filter((item) => item.key === defaultInitLangKey),
};

export function changeSupportLangList(keyList = [defaultInitLangKey]) {
  langsConfig.supportLangList = keyList
    .filter((key) => langs.some((item) => item.key == key))
    .map((key) => langs.find((item) => item.key == key));
  console.log('changeSupportLangList', langsConfig.supportLangList);
}

export function changeInitLangKey(key) {
  langsConfig.initLangKey = key;
  console.log('changeInitLangKey:', langsConfig.initLangKey);
}
