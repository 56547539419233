import { useEffect, useState, useRef, useCallback } from 'react';
import DialogModal from '../dialogModal';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PilotWheel from '../dialogModal/pilotwheel';
import WelcomeTips from '../../components/welcomeBonus/tips';
import BulletinComponent from '../dialogModal/bulletin/index';
import { useUpdateEffect } from 'ahooks';
import { isLogin, isControllableSite, isBrNTemplateId, PopupFilterMissionData } from '../../utils/helper';
import LoginRegister from '../login/entrance/index';
import {
  DialogModalChildrenKey,
  setActivityData,
  setDialogModalVisible,
  setRefetchActivityData,
} from '../../redux/reducers/appSlice';
import useHandleLogic from '../../hooks/useHandleLogic';
import ReferralCashback from '../dialogModal/referralCashback';
// import PromoteAchievements from '../dialogModal/promoteAchievements';
import PromoteAchievements from '../dialogModal/referralAchievements/index';
import RegisterSuccess from '../dialogModal/registerSuccess';
import { setBottomShowDownloadBoxPrompt } from '../../redux/reducers/appSlice';
import { get, cloneDeep } from 'lodash';
import PopupManager from '../../utils/popupManager';
import BottomDownloadPrompt from '../../components/dialogModal/downLoadBtn';
import DownloadManager from '../../manager/downloadManager';
import PlayerBenefits from '../dialogModal/playerBenefits';
import MysteryBonus from '../dialogModal/mysteryBonus';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';

export default function Index({ handleAllPopupComplet = () => {}, websiteInitConfig = null }) {
  const dispatch = useDispatch();
  const [dialogAttr, setDialogAttr] = useState({
    key: undefined,
    isOpen: false,
    shouldCloseOnOverlayClick: true,
    com: null,
    config: null,
    handleOverlayClick: () => {},
  });
  const switchRegisterRechargeTips = websiteInitConfig?.system_config?.switchRegisterRechargeTips || 0;
  const popUpComponentListRef = useRef([]); //待弹出组件列表(一个一个弹)
  const [popUpComponentList, setPopUpComponentList] = useState([]); //全部一起弹出
  const popupSwiperRef = useRef(null);
  const isPopupShowAllRef = useRef(false); //默认一个一个弹
  const { activityData, announcementhidePopupNextTime } = useSelector(
    (state) => ({
      activityData: state.app.activityData,
      announcementhidePopupNextTime: state.user.announcementhidePopupNextTime,
    }),
    shallowEqual,
  );
  const handleResetDialogAttr = () => {
    setDialogAttr({
      id: 0,
      key: undefined,
      isOpen: false,
      shouldCloseOnOverlayClick: true,
      com: null,
      handleOverlayClick: () => {},
    });
  };

  //弹窗请求关闭
  const handleClose = (execNext = false) => {
    handleResetDialogAttr();
    if (execNext) {
      handlePopupDialogSortListExec();
    }
  };

  //一次性显示所有弹窗的条件下请求关闭
  const handleAllPopupClose = (key) => {
    const updatedItems = popUpComponentListRef.current.map((item) =>
      item.key === key ? { ...item, isOpen: false } : item,
    );
    setPopUpComponentList(updatedItems);
  };

  //弹窗关闭之后
  const handleDialogAfterClose = () => {
    handlePopupDialogSortListExec();
  };

  //执行弹窗操作
  const handlePopupDialogSortListExec = () => {
    if (popUpComponentListRef.current.length > 0) {
      const execCom = popUpComponentListRef.current.shift();
      const commonAttr = {
        handleOverlayClick: execCom.handleOverlayClick ? execCom.handleOverlayClick : () => {},
        shouldCloseOnOverlayClick: execCom.shouldCloseOnOverlayClick,
        config: execCom.config,
        id: execCom.id,
      };
      setDialogAttr({
        ...commonAttr,
        isOpen: true,
        com: execCom.com,
        key: execCom.key,
      });
    } else {
      handleAllPopupCompletCallback();
    }
  };

  const handleSetIsFullPage = (isIn) => {
    if (popupSwiperRef && popupSwiperRef.current) {
      popupSwiperRef.current.handleSetIsFullPage(isIn);
    }
  };

  useEffect(() => {
    if (activityData != null && activityData.isSetAutoPopup && activityData.popup_config) {
      popUpComponentListRef.current = [];
      setPopUpComponentList([]);
      const popupConfig = activityData.popup_config;
      const popups = popupConfig.popups;
      isPopupShowAllRef.current = popupConfig.popup_mode == 'all'; //是否一次性全部显示，sequence｜all
      //"index": "0", //活动类型： -1：gift money；0：活动九宫格;1:task活动；2:救济金Relief money；3:锦标赛；4:轮盘抽奖；5:红包；6:新人活动；7:兑换码；8:裂变；9:新人引导轮盘抽奖；10:推广成就；11:推广返现
      const _popUpComponentList = [];
      //如果是注册且配置注册成功弹提示
      if (PopupManager.getIsRegister() && +switchRegisterRechargeTips == 1) {
        PopupManager.removeIsRegister();
        _popUpComponentList.push({
          id: 0,
          key: PopupManager.Keys.registerSuccess,
          config: null,
          com: (
            <RegisterSuccess
              handleClose={() => {
                isPopupShowAllRef.current ? handleAllPopupClose(PopupManager.Keys.registerSuccess) : handleClose(false);
              }}
            />
          ),
          order: Number.MAX_SAFE_INTEGER,
          shouldCloseOnOverlayClick: true,
          isOpen: true,
        });
      }

      //如果有引导轮盘
      const activityNoviceGuideTournament = activityData.activity_novice_guide_tournament;
      if (
        activityNoviceGuideTournament &&
        activityNoviceGuideTournament.list &&
        activityNoviceGuideTournament.list.length > 0 &&
        activityNoviceGuideTournament.list[0].novice_pilot_switch != 0 &&
        PopupManager.getConfig(popups, PopupManager.Keys.activity_novice_guide_tournament) != null
      ) {
        const id = activityNoviceGuideTournament.list[0].id;
        const config = PopupManager.getConfig(popups, PopupManager.Keys.activity_novice_guide_tournament);
        if (PopupManager.getIsAllowPopup(PopupManager.Keys.activity_novice_guide_tournament, config.mode, id)) {
          _popUpComponentList.push({
            id: id,
            key: PopupManager.Keys.activity_novice_guide_tournament,
            config: config,
            com: (
              <PilotWheel
                handleClose={() => {
                  isPopupShowAllRef.current
                    ? handleAllPopupClose(PopupManager.Keys.activity_novice_guide_tournament)
                    : handleClose(false);
                }}
                content={activityNoviceGuideTournament}
              />
            ),
            order: +PopupManager.getConfig(popups, PopupManager.Keys.activity_novice_guide_tournament).sort,
            shouldCloseOnOverlayClick: true,
            isOpen: true,
          });
        }
      }
      // 底边栏下载弹窗
      if (
        DownloadManager.isSatisfyBottomDownloadBoxPrompt(true) &&
        PopupManager.getConfig(popups, PopupManager.Keys.bottom_bar_download_popup) != null
      ) {
        const config = PopupManager.getConfig(popups, PopupManager.Keys.bottom_bar_download_popup);
        const id = 0;
        if (PopupManager.getIsAllowPopup(PopupManager.Keys.bottom_bar_download_popup, config.mode, id)) {
          _popUpComponentList.push({
            id: id,
            key: PopupManager.Keys.bottom_bar_download_popup,
            config: config,
            com: (
              <BottomDownloadPrompt
                bottomDownloadBoxPromptSwitch={{ visible: true, isForce: true, downloadText: '' }}
                handleClose={() => {
                  isPopupShowAllRef.current
                    ? handleAllPopupClose(PopupManager.Keys.bottom_bar_download_popup)
                    : handleClose(false);
                }}
              />
            ),
            order: +PopupManager.getConfig(popups, PopupManager.Keys.bottom_bar_download_popup).sort,
            shouldCloseOnOverlayClick: true,
            isOpen: true,
          });
        }
      }

      //如果有推广成就
      const achievementsData = get(activityData, 'activity_promotional_achievements.list[0]') || null;
      if (
        achievementsData &&
        PopupManager.getConfig(popups, PopupManager.Keys.activity_promotional_achievements) != null
      ) {
        const id = achievementsData.id;
        const config = PopupManager.getConfig(popups, PopupManager.Keys.activity_promotional_achievements);
        if (PopupManager.getIsAllowPopup(PopupManager.Keys.activity_promotional_achievements, config.mode, id)) {
          _popUpComponentList.push({
            id: id,
            key: PopupManager.Keys.activity_promotional_achievements,
            config: config,
            com: (
              <PromoteAchievements
                handleClose={() => {
                  isPopupShowAllRef.current
                    ? handleAllPopupClose(PopupManager.Keys.activity_promotional_achievements)
                    : handleClose(false);
                }}
                data={achievementsData}
                isShowCloseBtn={true}
                isSinglePopup={false}
                handleSetIsFullPage={handleSetIsFullPage}
              />
            ),
            order: +PopupManager.getConfig(popups, PopupManager.Keys.activity_promotional_achievements).sort,
            shouldCloseOnOverlayClick: true,
            isOpen: true,
          });
        }
      }

      //如果有推广返现
      const activityPromotionalCashback = activityData.activity_promotional_cashback;
      if (
        activityPromotionalCashback &&
        activityPromotionalCashback.list &&
        activityPromotionalCashback.list.length > 0 &&
        PopupManager.getConfig(popups, PopupManager.Keys.activity_promotional_cashback) != null
      ) {
        const config = PopupManager.getConfig(popups, PopupManager.Keys.activity_promotional_cashback);
        const activityPromotionalCashbackData = activityPromotionalCashback.list[0];
        const id = activityPromotionalCashbackData.id;
        if (PopupManager.getIsAllowPopup(PopupManager.Keys.activity_promotional_cashback, config.mode, id)) {
          _popUpComponentList.push({
            id: id,
            key: PopupManager.Keys.activity_promotional_cashback,
            config: config,
            com: (
              <ReferralCashback
                handleClose={() => {
                  isPopupShowAllRef.current
                    ? handleAllPopupClose(PopupManager.Keys.activity_promotional_cashback)
                    : handleClose(false);
                }}
                data={activityPromotionalCashbackData}
                isShowCloseBtn={true}
                updateList={() => {
                  dispatch(setRefetchActivityData());
                }}
                isSinglePopup={false}
              />
            ),
            order: +PopupManager.getConfig(popups, PopupManager.Keys.activity_promotional_cashback).sort,
            shouldCloseOnOverlayClick: true,
            isOpen: true,
          });
        }
      }

      //如果开启强制弹窗且没有登录
      if (!isLogin() && PopupManager.getConfig(popups, PopupManager.Keys.register) != null) {
        const config = PopupManager.getConfig(popups, PopupManager.Keys.register);
        const id = 0;
        if (PopupManager.getIsAllowPopup(PopupManager.Keys.register, config.mode, id)) {
          _popUpComponentList.push({
            id: id,
            key: PopupManager.Keys.register,
            config: config,
            com: (
              <LoginRegister
                loginOrSignUp={2}
                handleClose={() => {
                  isPopupShowAllRef.current ? handleAllPopupClose(PopupManager.Keys.register) : handleClose(false);
                }}
              />
            ),
            order: +PopupManager.getConfig(popups, PopupManager.Keys.register).sort,
            isOpen: true,
            shouldCloseOnOverlayClick: true,
          });
        }
      }

      //有有效的迎新奖励数据
      if (activityData.activity_novice) {
        const activityNovice = activityData.activity_novice.list;
        if (activityNovice.length > 0 && PopupManager.getConfig(popups, PopupManager.Keys.activity_novice) != null) {
          const activityNoviceData = activityNovice[0];
          let money = 0;
          if (activityNoviceData.activity_rewards) {
            activityNoviceData.activity_rewards.forEach((item) => {
              if (item.reward_type === '4') {
                money += Number(item.max_amount);
              } else {
                money += Number(item.amount);
              }
            });
          }

          const id = activityNoviceData.id;
          const config = PopupManager.getConfig(popups, PopupManager.Keys.activity_novice);
          if (PopupManager.getIsAllowPopup(PopupManager.Keys.activity_novice, config.mode, id)) {
            _popUpComponentList.push({
              id: id,
              key: PopupManager.Keys.activity_novice,
              config: config,
              com: (
                <WelcomeTips
                  style={2}
                  money={money}
                  endTime={activityNoviceData.activity_end_time * 1000}
                  handleClose={() => {
                    isPopupShowAllRef.current
                      ? handleAllPopupClose(PopupManager.Keys.activity_novice)
                      : handleClose(false);
                  }}
                />
              ),
              shouldCloseOnOverlayClick: true,
              order: +PopupManager.getConfig(popups, PopupManager.Keys.activity_novice).sort,
              isOpen: true,
            });
          }
        }
      }
      //如果有公告数据
      if (
        activityData.notification &&
        new Date().getTime() >= (announcementhidePopupNextTime || 0) &&
        activityData.notification.list &&
        activityData.notification.list.length > 0 &&
        PopupManager.getConfig(popups, PopupManager.Keys.notification) != null
      ) {
        const notification = activityData.notification.list;
        const config = PopupManager.getConfig(popups, PopupManager.Keys.notification);
        const id = Array.from(notification || []).reduce((a, b) => {
          return a + Number(b.id);
        }, 0);
        if (PopupManager.getIsAllowPopup(PopupManager.Keys.notification, config.mode, id)) {
          _popUpComponentList.push({
            id: id,
            key: PopupManager.Keys.notification,
            config: config,
            com: (
              <BulletinComponent
                isCloseAnnouncement={false}
                notificationData={notification}
                handleClose={() => {
                  isPopupShowAllRef.current ? handleAllPopupClose(PopupManager.Keys.notification) : handleClose(false);
                }}
              />
            ),
            shouldCloseOnOverlayClick: true,
            order: +PopupManager.getConfig(popups, PopupManager.Keys.notification).sort,
            isOpen: true,
          });
        }
      }

      // 任务弹窗
      const mission_popup_today = localStorage.getItem('mission_popup_today');
      if (
        PopupManager.getConfig(popups, PopupManager.Keys.mission) != null &&
        new Date().getTime() > Number(mission_popup_today) &&
        activityData?.mission &&
        activityData?.mission?.list &&
        activityData?.mission?.list.length > 0 &&
        PopupFilterMissionData(activityData?.mission?.list).length > 0
      ) {
        const config = PopupManager.getConfig(popups, PopupManager.Keys.mission);
        const id = 0;
        if (PopupManager.getIsAllowPopup(PopupManager.Keys.mission, config.mode, id)) {
          _popUpComponentList.push({
            id: id,
            key: PopupManager.Keys.mission,
            config: config,
            com: (
              <PlayerBenefits
                missionList={PopupFilterMissionData(activityData?.mission?.list)}
                handleClose={() => {
                  isPopupShowAllRef.current ? handleAllPopupClose(PopupManager.Keys.mission) : handleClose(false);
                }}
              />
            ),
            order: +PopupManager.getConfig(popups, PopupManager.Keys.mission).sort,
            shouldCloseOnOverlayClick: true,
            isOpen: true,
          });
        }
      }

      //神秘彩金活动
      if (activityData.activity_mystery_bonus) {
        const activityMysteryBonus = activityData.activity_mystery_bonus.list;
        if (
          activityMysteryBonus &&
          activityMysteryBonus.length > 0 &&
          (activityMysteryBonus[0].sign_up_status || activityMysteryBonus[0].can_sign_up) &&
          PopupManager.getConfig(popups, PopupManager.Keys.activity_mystery_bonus) != null
        ) {
          const activityMysteryBonusData = activityMysteryBonus[0];
          const id = activityMysteryBonusData.id;
          const config = PopupManager.getConfig(popups, PopupManager.Keys.activity_mystery_bonus);

          if (PopupManager.getIsAllowPopup(PopupManager.Keys.activity_mystery_bonus, config.mode, id)) {
            _popUpComponentList.push({
              id: id,
              key: PopupManager.Keys.activity_mystery_bonus,
              config: config,
              com: (
                <MysteryBonus
                  data={activityMysteryBonusData}
                  handleClose={() => {
                    isPopupShowAllRef.current
                      ? handleAllPopupClose(PopupManager.Keys.activity_mystery_bonus)
                      : handleClose(false);

                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Window_Close);
                  }}
                />
              ),
              order: +PopupManager.getConfig(popups, PopupManager.Keys.activity_mystery_bonus).sort,
              shouldCloseOnOverlayClick: true,
              isOpen: true,
            });
          }
        }
      }

      if (_popUpComponentList.length > 0) {
        PopupManager.lowFrequencyPopup = true;
        if (isPopupShowAllRef.current) {
          _popUpComponentList.sort((a, b) => {
            return a.order - b.order;
          });
          setPopUpComponentList(_popUpComponentList);
        } else {
          _popUpComponentList.sort((a, b) => {
            return b.order - a.order;
          });
          popUpComponentListRef.current = _popUpComponentList;
          if (dialogAttr.isOpen) {
            handleClose();
          } else {
            handlePopupDialogSortListExec();
          }
        }
      }
    }
  }, [activityData]);

  const handleAllPopupCompletCallback = () => {
    dispatch(setActivityData({ ...activityData, isSetAutoPopup: false }));
    handleAllPopupComplet();
  };

  //保存弹窗配置
  const savePopupConfig = (item) => {
    if (item.config) {
      PopupManager.setIsAllowPopup(item.key, item.config.mode, item.id);
    }
  };

  useEffect(() => {
    if (dialogAttr.isOpen) {
      savePopupConfig(dialogAttr);
    }
  }, [dialogAttr.isOpen]);

  useEffect(() => {
    if (isPopupShowAllRef.current && popUpComponentList.length > 0) {
      popUpComponentListRef.current = popUpComponentList;
      if (
        popUpComponentList.findIndex((item) => {
          return item.isOpen == true;
        }) == -1
      ) {
        handleAllPopupCompletCallback();
        setPopUpComponentList([]);
        popUpComponentListRef.current = [];
      }
    }
    for (let i = 0; i < popUpComponentList.length; i++) {
      savePopupConfig(popUpComponentList[i]);
    }
  }, [popUpComponentList]);

  return popUpComponentList.length > 0 ? (
    popUpComponentList.map((item, index) => (
      <DialogModal
        key={index}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            backgroundColor: index == 0 ? `rgba(3, 11, 21, 0.4)` : `rgba(3, 11, 21, 0.2)`,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '49',
            borderRadius: '.2rem',
          },
        }}
        handleClose={() => {
          handleAllPopupClose(item.key);
        }}
        handleOverlayClick={() => {}}
        afterClose={() => {}}
        isOpen={item.isOpen}
      >
        {item.com}
      </DialogModal>
    ))
  ) : (
    <DialogModal
      shouldCloseOnOverlayClick={dialogAttr.shouldCloseOnOverlayClick}
      noentered={true}
      style={{
        content: {
          display: 'inline-block',
          textAlign: 'left',
          top: '0px',
          maxWidth: '100%',
          cursor: 'default',
          outline: '0px',
          verticalAlign: 'middle',
          zIndex: '49',
          borderRadius: '.2rem',
        },
      }}
      handleOverlayClick={dialogAttr.handleOverlayClick}
      afterClose={handleDialogAfterClose}
      handleClose={() => {
        setDialogAttr({ ...dialogAttr, isOpen: false });
      }}
      isOpen={dialogAttr.isOpen}
    >
      {dialogAttr.com}
    </DialogModal>
  );
}
