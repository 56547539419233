import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { getCurrentLang, isEnvDevelopment } from './utils/helper';
import { langsConfig } from './constants/langs';

function getLoadPath(lang) {
  if (import.meta.env.PROD) {
    // 获取所有带 hash 的翻译文件
    const translationPaths = import.meta.glob('../locales/*.json', {
      as: 'url', // 关键修改：获取资源 URL 而非文件内容
      eager: true, // 立即解析路径
    });

    const rawPath = `../locales/${lang}.json`;
    return translationPaths[rawPath];
  }
  // 开发环境使用固定路径
  return `/locales/${lang}.json`;
}

function initI18n(languages = []) {
  const lng = getCurrentLang();

  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: (lngs) => getLoadPath(lngs[0]),
      },
      preload: languages,
      debug: isEnvDevelopment(),
      lng,
      fallbackLng: langsConfig.initLangKey,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    });
}

export default initI18n;
