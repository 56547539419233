import React from 'react';
import Close from '../close';
import { useState, useEffect } from 'react';
import ScrollWrapper from '../scrollWrapper';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';
export default function GamehelpMes({ handleClose }) {
  const { t, i18n } = useTranslation();
  const [isHtml, setIsHtml] = useState(false);
  const { updateGameHelprules } = useSelector(
    (state) => ({
      updateGameHelprules: state.app.updateGameHelprules,
    }),
    shallowEqual,
  );
  useEffect(() => {
    if (updateGameHelprules.content.includes('<!DOCTYPE html>')) {
      setIsHtml(true);
    } else {
      setIsHtml(false);
    }
  }, [updateGameHelprules]);
  return (
    <>
      <div className="w-[7.5rem] bg-[#192028] relative">
        <div className="absolute bg-[url(/static/img/bgclose.png)] bg-no-repeat bg-[length:100%_100%] right-[.48rem] top-[.26rem]">
          <Close onClick={handleClose} />
        </div>
        <div className="m-[0_auto] w-[6.86rem] h-[1.16rem] border-b border-[#2D3947] flex items-center font-[600] text-[.36rem]">
          {t('game.rules')}
        </div>
        {isHtml ? (
          <>
            <div className="pt-[.38rem] font-[300] text-[.36rem] leading-[.6rem] whitespace-pre-line">
              <div className="px-[.32rem]">
                <iframe className="h-[10.3rem]" srcDoc={`${updateGameHelprules.content}`} width="100%"></iframe>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="pt-[.38rem] font-[300] text-[.36rem] leading-[.54rem] whitespace-pre-line pb-[.38rem]">
              <ScrollWrapper className={''} mr={false}>
                <div className="px-[.32rem]">{updateGameHelprules.content}</div>
              </ScrollWrapper>
            </div>
          </>
        )}
      </div>
    </>
  );
}
