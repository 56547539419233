import cn from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { cmsImgUrl, getCurrentLang, getQueryString, isLogin } from '../../../../../utils/helper.js';
import { toLocalePath } from '../../../../../utils/localePath.js';
import NoData from '../../../../../components/noData';
import Loading from '../../../../../components/loading3';
import { GetPromotionEventsList } from '../../../../../api/index.js';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig.js';
import useLink from '../../../../../hooks/useLink.js';
import Icon, { IconName } from '../../../../../components/icon.jsx';
import moment from 'moment';
import Badge from '../../../../mine/normal/template001/components/badge.jsx';
import { useGlobalNavigate } from '../../../../../contexts/navigateContext.jsx';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../../redux/reducers/appSlice.js';

const NTemplate001 = () => {
  const ref = useRef(null);
  const { handleCmsLink } = useLink();
  const { getServerLangText } = useLangServerConfig();
  const { onScroll } = useOutletContext();
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [TabContent, setTabContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVal, setCurrentVal] = useState(0);
  const [currentKey, setCurrentKey] = useState(null);
  const [langKeyIcon, setLangKeyIcon] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(getQueryString('scrollTop') || 0);

  const { websiteInfo, promotionClaimedCount } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );

  const handleClick = (index, data) => {
    setScrollPosition(0);
    setCurrentVal(index);
    setCurrentKey(data);
    navigate(toLocalePath(`/promotion/events?current=${index}`));
  };

  const handleGoDetail = (item) => {
    const scrollTop = ref.current?.scrollTop || 0;

    switch (item.jump_mode) {
      case 1:
      case 3: {
        navigate(toLocalePath(`/promotion/${item.id}?fromIndex=${currentVal}&scrollTop=${scrollTop}`));
        break;
      }
      case 2: {
        handleCmsLink(item.open_mode, item.new_window, item.link);
        break;
      }
    }
  };

  const getEventsList = async () => {
    setIsLoading(true);
    try {
      const res = await GetPromotionEventsList({
        lang: getCurrentLang(),
      });

      if (res.code == 0) {
        const current = getQueryString('current');
        const index = current != null ? current : currentVal;

        setList(res.data.data);
        setLangKeyIcon(res.data.langkey_icon);
        setCurrentVal(index);
        setCurrentKey(res.data.langkey_icon[index]?.language_key);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: scrollPosition,
      });
    }
  }, [ref, scrollPosition, TabContent]);

  useEffect(() => {
    getEventsList();
  }, []);

  useEffect(() => {
    if (list) {
      const data = [list][0];

      for (const key in data) {
        if (currentKey && key == currentKey) {
          setTabContent(data[key]);
        }
      }
    }
  }, [currentKey]);

  const getTotalEventCount = (item) => {
    let count = 0;

    if (list && promotionClaimedCount?.data?.events) {
      const data = [list][0];
      const eventsdata = promotionClaimedCount.data.events;
      for (const key in data) {
        if (item && key == item) {
          const arr = data[key];
          for (let i = 0; i < arr.length; i++) {
            if (eventsdata[arr[i].id] != undefined) {
              count += eventsdata[arr[i].id];
            }
          }
        }
      }
    }

    return count;
  };

  const getEventClaimCount = (id) => {
    let count = 0;

    if (promotionClaimedCount?.data?.events) {
      const eventsdata = promotionClaimedCount.data.events;
      count = eventsdata[id] ? eventsdata[id] : 0;
    }

    return count;
  };

  const LocalIcon = ({ iconKey }) => {
    console.log('iconKey', iconKey);

    switch (iconKey) {
      case 'GameType_Fixed_1': {
        return <Icon name={IconName.Gametype_1} />;
      }
      case 'GameType_Fixed_2': {
        return <Icon name={IconName.Gametype_2} />;
      }
      case 'GameType_Fixed_3': {
        return <Icon name={IconName.Gametype_3} />;
      }
      case 'GameType_Fixed_4': {
        return <Icon name={IconName.Gametype_4} />;
      }
      case 'GameType_Fixed_5': {
        return <Icon name={IconName.Gametype_5} />;
      }
      case 'GameType_Fixed_6': {
        return <Icon name={IconName.Gametype_6} />;
      }
      case 'GameType_Fixed_9999': {
        return <Icon name={IconName.Gametype_9999} />;
      }
      default:
        return <></>;
    }
  };

  const isLocalIcon = (key) => {
    const localKeys = [
      'GameType_Fixed_1',
      'GameType_Fixed_2',
      'GameType_Fixed_3',
      'GameType_Fixed_4',
      'GameType_Fixed_5',
      'GameType_Fixed_6',
      'GameType_Fixed_9999',
    ];
    return localKeys.includes(key);
  };

  const EventIcon = ({ iconKey, isActive, remoteImgSrcActive, remoteImgSrcInactive }) => {
    const isLocal = isLocalIcon(iconKey);

    return isLocal ? (
      <span
        className={cn('w-[.32rem] h-[.32rem] not-drag block shrink-0', {
          'text-[var(--colour-2)]': isActive,
          'text-[var(--colour-33)]': !isActive,
        })}
      >
        <LocalIcon iconKey={iconKey} />
      </span>
    ) : (
      <img className="w-[.32rem] not-drag shrink-0" src={isActive ? remoteImgSrcActive : remoteImgSrcInactive} alt="" />
    );
  };

  const handleClickHistory = () => {
    if (!isLogin()) {
      return dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
    onScroll('/promotion/history');
  };

  return (
    <>
      <div className="flex w-full h-full pb-[.2rem] bg-[var(--colour-9)]">
        {isLoading ? (
          <Loading className="w-full h-full"></Loading>
        ) : langKeyIcon == null || langKeyIcon?.length == 0 ? (
          <NoData className="w-full h-full"></NoData>
        ) : (
          <>
            <div>
              <div className="flex-1">
                <div className="w-[1.9rem] pt-[.2rem] max-h-[calc(var(--app-height)-4.2rem)] overflow-y-auto overflow-x-hidden  hidden-scroll-y">
                  {langKeyIcon.length > 0 && (
                    <>
                      {langKeyIcon.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              handleClick(index, item.language_key);
                            }}
                            className={cn(
                              `relative mx-auto w-[1.5rem] h-[.7rem] p-[.15rem_.04rem_.15rem_.12rem] flex items-center justify-center gap-[.08rem] cursor-pointer active:scale-[.95] transition-all select-none ${index != 0 ? ' mt-[.18rem]' : ''}`,
                              websiteInfo.website_theme === 'theme-15'
                                ? currentVal == index
                                  ? 'bg-active text-[var(--colour-2)] rounded-[.12rem]'
                                  : 'bg-menu-default !rounded-[.12rem] text-[var(--colour-33)]'
                                : currentVal == index
                                  ? 'text-[var(--colour-2)] bg-[var(--colour-1)] rounded-[.12rem]'
                                  : 'bg-[var(--colour-16)] !rounded-[.12rem] text-[var(--colour-33)]',
                            )}
                          >
                            {getTotalEventCount(item.language_key) > 0 ? (
                              <Badge
                                textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
                                className="!min-w-[.3rem] h-[.3rem] !top-[-0.15rem] left-[80%] flex items-center"
                                text={
                                  getTotalEventCount(item.language_key) > 99
                                    ? '99+'
                                    : getTotalEventCount(item.language_key)
                                }
                              ></Badge>
                            ) : null}

                            <EventIcon
                              iconKey={item.language_key}
                              isActive={currentVal == index}
                              remoteImgSrcActive={cmsImgUrl(item.icon_selected)}
                              remoteImgSrcInactive={cmsImgUrl(item.icon_unselected)}
                            />

                            <div className="text-[.16rem] font-[500] w-[.8rem] text-center text-wrap">
                              {getServerLangText(item.language_key)}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>

              <div
                className={`${websiteInfo.website_theme === 'theme-15' ? 'bg-active' : 'bg-[var(--colour-1)]'}  text-[.2rem] font-[500] h-[.5rem] text-[var(--colour-2)] rounded-[.12rem] mt-[.32rem] w-[1.2rem] mx-auto flex items-center justify-center active:scale-[.95] transition-all select-none`}
                onClick={handleClickHistory}
              >
                {t('promotion.history')}
              </div>
            </div>
            <div
              className="mt-[.02rem] w-[5.6rem] pt-[.2rem] flex flex-col gap-[.2rem] h-full overflow-y-auto overflow-x-hidden  hidden-scroll-y"
              ref={ref}
            >
              {TabContent &&
                TabContent.length > 0 &&
                TabContent.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        handleGoDetail(item);
                      }}
                      key={index}
                      className="cursor-pointer w-[5.4rem] min-h-[2.6rem] rounded-[.08rem] bg-[var(--colour-35)]  box-shadow-event-1 flex items-center justify-center relative"
                    >
                      {getEventClaimCount(item.id) > 0 ? (
                        <Badge
                          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
                          className="!min-w-[.3rem] h-[.3rem] left-[95%] top-[0px] flex items-center"
                          text={getEventClaimCount(item.id) > 99 ? '99+' : getEventClaimCount(item.id)}
                        ></Badge>
                      ) : null}
                      <div
                        className={`w-[5.2rem] h-[2.36rem] rounded-[.16rem] flex items-center justify-center bg-no-repeat bg-[length:100%_100%]`}
                        style={{ backgroundImage: `url(${cmsImgUrl(item.TitleImg)})` }}
                      ></div>

                      {item.image_time_switch == 1 ? (
                        <div className="w-full h-[.56rem] rounded-[0_0_.08rem_.08rem] bg-[var(--colour-35)] box-shadow-event-3 absolute bottom-0 left-0 flex items-center justify-center gap-[.06rem]">
                          <div className="text-[var(--colour-44)] w-[.28rem] h-[.28rem]">
                            <Icon name={IconName.Eventstime}></Icon>
                          </div>
                          <div className="text-[var(--colour-44)] text-[.2rem] font-[400]">
                            {moment(item.start_timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}
                            <span className="mx-[.1rem]">-</span>
                            {moment(item.end_timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NTemplate001;
