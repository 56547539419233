const isWeb3Browser = () => {
  if (window && typeof window.ethereum !== 'undefined') {
    return true;
  } else {
    return false;
  }
};

const getWalletAddress = () => {
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
        const account = accounts[0];
        if (account) {
          resolve(account);
        } else {
          reject(new Error('getWalletAddress fail'));
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getWalletSign = (strToSign, addressToSign) => {
  return new Promise((resolve, reject) => {
    const signature = unescape(encodeURIComponent(strToSign))
      .split('')
      .map((v) => {
        return v.charCodeAt(0).toString(16);
      })
      .join('');

    const msg = `0x${signature}`;

    window.ethereum
      .request({
        method: 'personal_sign',
        params: [msg, addressToSign],
      })
      .then((signResult) => {
        console.log('signResult', signResult, typeof signResult);
        resolve(signResult);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { isWeb3Browser, getWalletAddress, getWalletSign };
