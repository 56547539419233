import React from 'react';
import cn from 'classnames';
import Icon, { IconName } from '../icon';
import { useTranslation } from 'react-i18next';
import { setdisconnect } from '../../redux/reducers/appSlice';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Loading2 from '../loading2';
import { isControllableSite } from '../../utils/helper';

const TopTips = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { disconnect, websiteInitConfig } = useSelector(
    (state) => ({
      disconnect: state.app.disconnect,
    }),
    shallowEqual,
  );
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <>
      <div
        className={cn(
          'w-full bg-[var(--colour-1)] overflow-hidden flex  flex-col items-center justify-center relative transition-[height]',
          {
            'h-[var(--disconnect-height)]': disconnect,
            'h-[0]': !disconnect,
          },
        )}
      >
        <div
          className={cn(
            'text-[var(--colour-2)] font-[400] text-[.24rem] leading-[.24rem] text-center pl-[.1rem]  w-[71%] cursor-pointer',
          )}
          onClick={handleReload}
        >
          {t('home.disconnect')}
        </div>
        <Loading2 dotColor="!bg-[var(--colour-2)]" />
        <div
          className={cn(
            'text-[var(--colour-2)] w-[.76rem] h-[.76rem] absolute abs-y-center cursor-pointer right-[.1rem]',
          )}
          onClick={() => dispatch(setdisconnect(false))}
        >
          <Icon name={IconName.Close} />
        </div>
      </div>
    </>
  );
};

export default TopTips;
