import { useDispatch } from 'react-redux';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setShowSupportNew,
  setShowInbox,
  setShowWGDeposit,
  setShowPandaDeposit,
  setShowPandaWithdraw,
} from '../redux/reducers/appSlice';
import { getTemplateId, isControllableSite } from '../utils/helper';
import { toLocalePath, getLangFromPath } from '../utils/localePath';
import { useGlobalNavigate } from '../contexts/navigateContext';
import AnalyticsManager, { FirebaseEvents } from '../manager/analyticsManager';

const useRouterHelper = () => {
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();

  //根据不同的模板打开相应的充值/提现
  const handleClickWallet = (type = 1, withdraw_enable = 1, zIndex = 9999, walletType = 2) => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_DepositButton);
    dispatch(setShowSupportNew(false));
    dispatch(setShowInbox(false));

    if (type == 1) {
      //打开充值
      if (['d-template003'].includes(getTemplateId())) {
        //熊猫模板
        dispatch(setShowPandaDeposit(true));
      } else if (isControllableSite()) {
        //wg模板
        dispatch(setShowWGDeposit(true));
      } else {
        //7b模板
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.WithdrawDeposit,
            closeWithBack: false,
            walletType: 1,
            withdraw_enable,
            zIndex,
          }),
        );
      }
    } else if (type == 2) {
      //打开提现
      if (['d-template003'].includes(getTemplateId())) {
        //熊猫模板
        dispatch(setShowPandaWithdraw(true));
      } else if (isControllableSite()) {
        //wg模板
        const { path } = getLangFromPath(window.location.pathname);
        localStorage.setItem('prev_path', path);
        navigate(toLocalePath(`/pay/withdrawtabpage/1`));
      } else {
        //7b模板
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.WithdrawDeposit,
            closeWithBack: false,
            walletType,
            withdraw_enable,
            zIndex,
          }),
        );
      }
    }
  };

  return { handleClickWallet };
};
export default useRouterHelper;
