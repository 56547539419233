import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { toLocalePath, toNoLocalePath } from '../../../../utils/localePath';
import Icon, { IconName } from '../../../../components/icon';
import { useEffect, useRef, useState } from 'react';
import { Tabs } from 'react-vant';
import useDraggableScroll from '../../../../hooks/useDraggableScroll';
import { isInIframe, getRootCssVariable, getIdentity, pageFilterMissionData } from '../../../../utils/helper';
import { useBodyOverflow } from '../../../../hooks/useBodyOverflow';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Badge from '../../../mine/normal/template001/components/badge';
import usePromotionClaimedCount from '../../../../hooks/usePromotionClaimedCount';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setRefetchActivityData,
} from '../../../../redux/reducers/appSlice';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import useGoBack from '../../../../hooks/useGoBack';
import GoldRotation from '../../../../components/goldRotation';

const PromotionNavBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const goBack = useGoBack();
  const { InitPromotionClaimedCount } = usePromotionClaimedCount();
  const {
    identity,
    promotionClaimedCount,
    giftMoneyData,
    switch_rakeback,
    activityStatus,
    activityData,
    isShowPromotionBarGiftMoneyCoin,
  } = useSelector(
    (state) => ({
      identity: state.user.identity,
      giftMoneyData: state?.app?.activityData?.gift_money,
      promotionClaimedCount: state.app.promotionClaimedCount,
      switch_rakeback: state.app.websiteInitConfig?.system_config?.switch_rakeback,
      activityStatus: state.app.activityStatus,
      activityData: state.app.activityData,
      isShowPromotionBarGiftMoneyCoin: state.app.isShowPromotionBarGiftMoneyCoin,
    }),
    shallowEqual,
  );

  useBodyOverflow(true);
  const excessHeight = getRootCssVariable('--menubar-height') + 1.08;
  const { h } = useWindowHeight(excessHeight);
  const tabsRef = useRef(null);
  const checkboxRef = useRef(null);
  const location = useLocation();
  const { pathname, state } = location;
  // const [backlink, setBackLink] = useState(state?.from ? state.from : '/');
  const navigate = useGlobalNavigate();
  const [lineWidth, setLineWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getTabsList = () => {
    const baseList = [
      {
        count: promotionClaimedCount?.events || 0,
        name: t('promotion.events'),
        routeName: '/promotion/events',
        isLogin: false,
      },

      ...(activityData?.mission &&
      activityData?.mission?.list &&
      activityData?.mission?.list.length > 0 &&
      pageFilterMissionData(activityData?.mission?.list).length > 0
        ? [
            {
              count: promotionClaimedCount?.data?.mission || 0,
              name: t('promotion.mission'),
              routeName: '/promotion/mission',
              isLogin: true,
            },
          ]
        : []),

      {
        count: promotionClaimedCount?.data?.vip || 0,
        name: t('promotion.vip'),
        routeName: '/promotion/vip',
        isLogin: true,
      },
      {
        name: t('promotion.redeem'),
        routeName: '/promotion/redeem',
        isLogin: true,
      },
      {
        count: promotionClaimedCount?.data?.pending || 0,
        name: t('promotion.pending'),
        routeName: '/promotion/pending',
        isLogin: true,
      },
      ...(giftMoneyData
        ? [
            {
              count: promotionClaimedCount?.data?.giftmoney || 0,
              name: t('promotion.giftmoney'),
              routeName: '/promotion/giftmoney',
              isLogin: true,
              id: 'promotion_giftMoney',
            },
          ]
        : []),
      ...(switch_rakeback == 1
        ? [
            {
              count: promotionClaimedCount?.data?.rakeback || 0,
              name: t('promotion.rakeback'),
              routeName: '/promotion/rakeback',
              isLogin: true,
            },
          ]
        : []),
      {
        name: t('promotion.history'),
        routeName: '/promotion/history',
        isLogin: true,
      },
    ];

    return baseList;
  };

  // const getTabsListOld = () => {
  //   const baseList = [
  //     {
  //       count: promotionClaimedCount?.events || 0,
  //       name: t('promotion.events'),
  //       routeName: '/promotion/events',
  //       isLogin: false,
  //     },
  //     {
  //       count: promotionClaimedCount?.data?.vip || 0,
  //       name: t('promotion.vip'),
  //       routeName: '/promotion/vip',
  //       isLogin: true,
  //     },
  //     {
  //       count: promotionClaimedCount?.data?.pending || 0,
  //       name: t('promotion.pending'),
  //       routeName: '/promotion/pending',
  //       isLogin: true,
  //     },
  //     {
  //       name: t('promotion.history'),
  //       routeName: '/promotion/history',
  //       isLogin: true,
  //     },
  //   ];

  //   if (switch_rakeback == 1) {
  //     baseList.push({
  //       count: promotionClaimedCount?.data?.rakeback || 0,
  //       name: t('promotion.rakeback'),
  //       routeName: '/promotion/rakeback',
  //       isLogin: true,
  //     });
  //   }

  //   if (giftMoneyData) {
  //     baseList.push({
  //       count: promotionClaimedCount?.data?.giftmoney || 0,
  //       name: t('promotion.giftmoney'),
  //       routeName: '/promotion/giftmoney',
  //       isLogin: true,
  //     });
  //   }

  //   return baseList;
  // };

  const [list, setList] = useState(getTabsList());

  useEffect(() => {
    if (activityStatus != null) {
      dispatch(setRefetchActivityData());
    }
  }, [activityStatus]);

  useEffect(() => {
    if (identity) {
      InitPromotionClaimedCount();
    }
  }, [identity]);

  useEffect(() => {
    setList(getTabsList());
  }, [promotionClaimedCount, switch_rakeback, giftMoneyData, activityData]);

  const [defaultActive, setDefaultActive] = useState(() => {
    if (pathname) {
      const _index = list.findIndex((item) => {
        return item.routeName === toNoLocalePath(pathname);
      });
      return _index >= 0 ? _index : 0;
    }
    return 0;
  });

  useEffect(() => {
    if (pathname) {
      if (checkboxRef.current) {
        const _index = list.findIndex((item) => {
          return item.routeName === toNoLocalePath(pathname);
        });

        if (_index !== -1) {
          setCurrentIndex(_index);
          checkboxRef.current.scrollTo(_index);
        } else {
          setCurrentIndex(0);
          navigate(toLocalePath(`/promotion/events`), { state: { from: null } }, false);
          checkboxRef.current.scrollTo(0);
        }
      }
    }
  }, [list]);
  const updateLineWidth = () => {
    const tabsElement = tabsRef.current;
    if (!tabsElement) return;

    const activeTab = tabsElement.querySelector('.rv-tab--active .rv-tab__text');
    if (!activeTab) return;

    const lineWidth = activeTab.offsetWidth;
    setLineWidth(lineWidth);
  };

  useDraggableScroll(document.querySelector('.rv-tabs__nav'), [], false);

  useEffect(() => {
    updateLineWidth();
  }, [currentIndex]);

  const handleClick = (e) => {
    const _index = e.index;
    setCurrentIndex(_index);

    if (list[_index].isLogin && !getIdentity()) {
      return dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }

    navigate(toLocalePath(`${list[_index].routeName}`), { state: { from: null } }, false);
  };

  const handleScroll = (routeName = '') => {
    if (checkboxRef.current) {
      const index = list.findIndex((item) => item.routeName === routeName);
      if (index !== -1) {
        setCurrentIndex(index);
        navigate(toLocalePath(`${routeName}`), { state: { from: null } }, false);
        checkboxRef.current.scrollTo(index);
      }
    }
  };

  return (
    <>
      <div className="promotion-tabs_box promotion-tabs_box_panda flex w-full" ref={tabsRef}>
        <div className="flex-1 overflow-x-auto hidden-scroll-y">
          <Tabs
            defaultActive={defaultActive}
            ref={checkboxRef}
            sticky
            swipeable
            swipeThreshold={4}
            onClickTab={handleClick}
            background="var(--colour-86)"
            titleActiveColor="var(--colour-13)"
            titleInactiveColor="var(--colour-14)"
            lineWidth={lineWidth}
            lineHeight={'.04rem'}
            color="var(--colour-1)"
          >
            {list.map((item, index) => (
              <Tabs.TabPane
                key={index}
                title={
                  <div id={item.id}>
                    {item.count > 0 ? (
                      <Badge
                        textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
                        className="!min-w-[.3rem] !min-h-[.3rem] !top-[0.05rem] !left-[calc(100%-0.4rem)] flex items-center"
                        text={item.count}
                      ></Badge>
                    ) : null}
                    <div className="relative">
                      {item.name}
                      {item.routeName == '/promotion/giftmoney' && isShowPromotionBarGiftMoneyCoin && (
                        // {true && (
                        <div className="absolute top-[0] left-[50%] translate-x-[-50%] translate-y-[calc(-100%+.08rem)]">
                          <GoldRotation className="scale-[0.28] origin-bottom" />
                        </div>
                      )}
                    </div>
                  </div>
                }
                titleClass="!text-[.28rem] font-[500] !h-[1.08rem]"
              />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="w-full pb-[var(--menubar-extra-height)] bg-[var(--colour-68)]" style={{ height: `${h}rem` }}>
        <Outlet context={{ onScroll: handleScroll }} />
      </div>
    </>
  );
};

export default PromotionNavBar;
