import { useTranslation } from 'react-i18next';

const MoreBtn = ({ onClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div
      className="cursor-pointer flex items-center justify-center bg-[var(--colour-5)] text-[var(--colour-3)] border border-[var(--colour-1)] rounded-normal font-[600] w-[1.9rem] h-[.84rem] text-[.28rem]"
      onClick={onClick}
    >
      {t('promo.more')}
    </div>
  );
};

export default MoreBtn;
