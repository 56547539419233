export function isFunction(val) {
  return typeof val === 'function';
}

function trimExtraChar(value, char, regExp) {
  const index = value.indexOf(char);

  if (index === -1) {
    return value;
  }

  if (char === '-' && index !== 0) {
    return value.slice(0, index);
  }

  return value.slice(0, index + 1) + value.slice(index).replace(regExp, '');
}

export function formatNumber(value, allowDot = true, allowMinus = true) {
  if (allowDot) {
    value = trimExtraChar(value, '.', /\./g);
  } else {
    // eslint-disable-next-line prefer-destructuring
    value = value.split('.')[0];
  }

  if (allowMinus) {
    value = trimExtraChar(value, '-', /-/g);
  } else {
    value = value.replace(/-/g, '');
  }

  const regExp = allowDot ? /[^-0-9.]/g : /[^-0-9]/g;

  return value.replace(regExp, '');
}

export function isDef(val) {
  return val !== undefined && val !== null;
}

export function isNumeric(val) {
  return /^\d+(\.\d+)?$/.test(val);
}

export function addUnit(value) {
  if (!isDef(value)) {
    return undefined;
  }

  // eslint-disable-next-line no-param-reassign
  value = String(value);
  return isNumeric(value) ? `${value}px` : value;
}

function gen(name, mods) {
  if (!mods) {
    return '';
  }

  if (typeof mods === 'string') {
    return ` ${name}--${mods}`;
  }

  if (Array.isArray(mods)) {
    return mods.reduce((ret, item) => ret + gen(name, item), '');
  }
  return Object.keys(mods).reduce((ret, key) => ret + (mods[key] ? gen(name, key) : ''), '');
}

export function createBEM(name) {
  return (el, mods) => {
    if (el && typeof el !== 'string') {
      mods = el;
      el = '';
    }

    el = el ? `${name}__${el}` : name;
    return `${el}${gen(el, mods)}`;
  };
}

// 实现react的defaultProps和props合并
export function mergeProps(...items) {
  const result = { ...items[0] };
  items.forEach((item) => {
    for (const key in item) {
      const val = item[key];
      // eslint-disable-next-line no-prototype-builtins
      result[key] = result.hasOwnProperty(key) ? result[key] : val;
    }
  });
  return result;
}

export function stopPropagation(event) {
  event.stopPropagation();
}

export function preventDefault(event, isStopPropagation) {
  /* istanbul ignore else */
  if (typeof event.cancelable !== 'boolean' || event.cancelable) {
    event.preventDefault();
  }

  if (isStopPropagation) {
    stopPropagation(event);
  }
}
