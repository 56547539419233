import cn from 'classnames';
import Icon, { IconName } from '../../../../../components/icon';
import { useTranslation } from 'react-i18next';
import CssTriangle from '../../../../../components/cssTriangle';

const DayItem = ({ isSelected = false, isChecked = false, isRedDot = false, onClick, text = '', dayNum }) => {
  return (
    <div
      className={cn('flex flex-col items-center', {
        'cursor-pointer': !isSelected,
      })}
      onClick={onClick}
    >
      <div className={cn('relative w-[.96rem] h-[.96rem]', {})}>
        <div
          className={cn(
            'w-[.72rem] h-[.72rem] rounded-[.08rem] rotate-[45deg] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]',
            {
              'bg-[var(--colour-1)] text-[var(--colour-1)]': isSelected,
              'bg-[var(--colour-5)] text-[var(--colour-14)] border border-[var(--colour-1)]': !isSelected,
            },
          )}
        ></div>
        <div
          className={cn(
            'absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[.58rem] h-[.58rem] inline-block',
            {
              'text-[var(--colour-2)]': isSelected,
              'text-[var(--colour-3)]': !isSelected,
            },
          )}
        >
          <Icon name={IconName.DateDay} />
        </div>

        <div
          className={cn(
            'absolute left-[50%] translate-x-[-50%] top-[.45rem] text-[.2rem] font-[700] h-[.2rem] leading-[.2rem]',
            {
              'text-[var(--colour-2)]': isSelected,
              'text-[var(--colour-3)]': !isSelected,
            },
          )}
        >
          {dayNum}
        </div>

        {isRedDot && (
          <span className="absolute right-[.06rem] top-[.06rem] w-[.24rem] h-[.24rem]">
            <Icon name={IconName.RedDot} />
          </span>
        )}

        {isChecked && (
          <span className="absolute right-[.06rem] top-[.06rem] w-[.28rem] h-[.28rem]">
            <Icon name={IconName.Checked3} />
          </span>
        )}
      </div>

      <div
        className={cn('text-[.28rem] font-[600] leading-[1]', {
          'text-[var(--colour-1)]': isSelected,
          'text-[var(--colour-44)]': !isSelected,
        })}
      >
        {text}
      </div>

      {isSelected && (
        <div className="pt-[.12rem] leading-[0]">
          <CssTriangle
            direction="down"
            color={'var(--colour-1)'}
            borderRightWidth=".32rem"
            borderTopWidth=".16rem"
            borderBottomWidth=".16rem"
          />
        </div>
      )}
    </div>
  );
};

const DayList = ({ list, currentDayIndex = 0, handleDayItemClick }) => {
  console.log('list', list);
  const { t } = useTranslation();

  return (
    <>
      <div className="flex space-x-[.48rem] justify-center">
        {list.map((item, index) => {
          // 任务状态(5:不可参与,10:未完成,11:结算中,20:未到领奖时间,21:可领取,22:已领取,30:奖励过期)
          const status = item?.user_progress?.status;

          return (
            <DayItem
              key={index}
              text={t('mystery_bonus.day_n', { val: item?.settle_day_offset })}
              isSelected={index == currentDayIndex}
              onClick={() => handleDayItemClick(index)}
              dayNum={item?.settle_day_offset}
              isRedDot={[21].includes(status)}
              isChecked={[22, 30].includes(status)}
            />
          );
        })}
      </div>
    </>
  );
};

export default DayList;
