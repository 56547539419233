import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Close from '../../close';
import LimitClickWrapper from '../../limitClickWrapper';
import ElementWithLoading from '../../elementWithLoading';
import QRCode from '../../../components/qrCode';
// import globalConfig from '../../../constants/config';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Icon, { IconName } from '../../icon';
import PixPng from '../../../assets/img/pix.png';
import { pointToComma, isPCLayout } from '../../../utils/helper';
import { get } from 'lodash';
import cn from 'classnames';
import usePay from '../../../hooks/usePay';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>
        <div className=" w-[.72rem] h-[.72rem] flex justify-center items-center rounded-[.08rem] bg-[var(--colour-45)]">
          <Close onClick={handleClose} />
        </div>
      </div>
      <div className="w-full h-[1px] mt-[.28rem] bg-[var(--colour-6)]"></div>
    </>
  );
};

const depositQR = ({ handleClose, amount, qcURL, network }) => {
  const { t } = useTranslation();

  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
    }),
    shallowEqual,
  );
  const { handleCopyWithErrorReport } = usePay();
  return (
    <div
      className={cn(
        'my-[.3rem] flex flex-col justify-between text-center relative pb-[.4rem] mx-[.16rem] h-[auto] rounded-normal  bg-[var(--colour-35)]',
        {
          'w-[auto]': !isPCLayout(),
          'w-[var(--theme-max-width)]': isPCLayout(),
        },
      )}
    >
      <div className="">
        <div className="px-[.32rem] ">
          <FormHeader title={t('deposit-withdraw.deposit')} handleClose={handleClose} />
        </div>
        <div className="px-[.32rem] ">
          {network.toLowerCase() == 'pix' && (
            <div className="mt-[.4rem] flex justify-center	">
              <img src={PixPng} className="w-[2.8rem]" />
            </div>
          )}

          <div className="mt-[.4rem] text-[.28rem]">{t('deposit-withdraw.msg1')}</div>
          <div className="text-[.4rem] mt-[.44rem]" style={{ fontWeight: 600 }}>
            {t('deposit-withdraw.Total')}: {websiteInitConfig?.website_info.currency_symbol || ''}{' '}
            {pointToComma(amount)}
          </div>
          <div className="flex justify-center w-[full] mt-[.3rem]">
            <div
              className="w-[4.4rem] h-[4.4rem] flex items-center justify-center"
              style={{ background: 'white', padding: '.26rem' }}
            >
              <QRCode value={qcURL} remSize={4} />
            </div>
          </div>

          <LimitClickWrapper
            className="mt-[.6rem] mb-[.4rem] active:scale-[.95]  transition-all select-none  flex  px-[.5rem] h-[.92rem] items-center justify-center cursor-auto rounded-btn bg-[var(--colour-1)]"
            onClick={() => {
              // copy(`${qcURL}`);
              // toast.success(t('home.toast006'), {
              //   containerId: 'global',
              // });
              handleCopyWithErrorReport(qcURL);
            }}
          >
            <ElementWithLoading
              normalEl={
                <div className="font-[700] text-[.32rem] flex items-center h-[.92rem] text-[var(--colour-2)]">
                  {t('deposit-withdraw.copy1')}
                  <span
                    className="!w-[.3rem] !h-[.4rem]  ml-[.2rem] text-[var(--colour-2)]"
                    style={{ display: 'inline-block' }}
                  >
                    <Icon name={IconName.Copys}></Icon>
                  </span>
                </div>
              }
            />
          </LimitClickWrapper>
        </div>
      </div>
    </div>
  );
};
export default depositQR;
