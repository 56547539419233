import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBack from '../../../../components/headerBack';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DialogModalChildrenKey,
  ResponsiveMode,
  setDialogModalVisible,
  setNeedUpdateBalance,
} from '../../../../redux/reducers/appSlice';
import Tips from '../../../../components/tips';
import { useNavigate } from 'react-router-dom';
import { getShowMoneyWithConfigDecimal, isLogin } from '../../../../utils/helper';
import { GetReferralTransfer } from '../../../../api';
import { setUserObj } from '../../../../redux/reducers/userSlice';
import { get } from 'lodash';
import Tips2 from '../../../../components/tips2';
import { toLocalePath } from '../../../../utils/localePath';
import { toast } from 'react-toastify';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import useRouterHelper from '../../../../hooks/useRouterHelper';

const NTemplate001 = ({ onClick = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const [showTips, setShowTips] = useState(false);
  const [showTips2, setShowTips2] = useState(false);
  const [transerAmount, setTranserAmount] = useState(0);

  const { bonusBalance, identity, userObj, websiteInitConfig, responsiveMode } = useSelector(
    (state) => ({
      bonusBalance: get(state, 'user.userObj.bonus_balance') || 0,
      identity: state.user.identity,
      userObj: state.user.userObj,
      websiteInitConfig: get(state, 'app.websiteInitConfig') || null,
      responsiveMode: state.app.responsiveMode,
    }),
    shallowEqual,
  );

  const { handleClickWallet } = useRouterHelper();

  const list = [
    {
      name: t('referral.withdraw'),
      onClick: () => {
        if (isLogin()) {
          handleClickWallet(2, 1, 9999, 3);
        } else {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.LoginBox,
            }),
          );
        }
      },
    },
    {
      name: t('referral.transfer'),
      onClick: () => {
        if (isLogin()) {
          setShowTips(true);
        } else {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.LoginBox,
            }),
          );
        }
      },
    },
    {
      name: t('beting_bonus.record'),
      onClick: () => {
        if (isLogin()) {
          navigate(toLocalePath('/report/0'));
        } else {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.LoginBox,
            }),
          );
        }
      },
    },
  ];
  const transferSend = () => {
    if (Number(bonusBalance) <= 0) {
      return;
    }

    GetReferralTransfer({ identity }).then((res) => {
      if (res.code === 0) {
        dispatch(
          setUserObj({
            ...userObj,
            bonus_balance: res.result.bonus_balance,
          }),
        );
        dispatch(setNeedUpdateBalance({ need: true }));

        setTranserAmount(res?.result?.claimed_bonus_balance);
        setShowTips2(true);
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };
  return (
    <div className="!px-[0px] container bg-[var(--colour-34)] h-full">
      <Tips
        setShowVerify={setShowTips}
        showVerify={showTips}
        contTxt={t('referral.transferred', { val: get(websiteInitConfig, 'system_config.bonus_transfer') || '' })}
        headTxt={t('referral.notice')}
        btn1={t('referral.okay')}
        btn2={t('referral.cancel')}
        cb1={() => {
          transferSend(), setShowTips(false);
        }}
        cb2={() => {
          setShowTips(false);
        }}
      />

      <Tips2
        setShowVerify={setShowTips2}
        showVerify={showTips2}
        transerAmount={getShowMoneyWithConfigDecimal(transerAmount)}
        headTxt={t('vip.bouns')}
        cb1={() => {
          setShowTips(false);
        }}
        cb2={() => {
          setShowTips(false);
        }}
      />

      <HeaderBack
        title={t('referral.bonus')}
        onClick={() => {
          navigate(toLocalePath('/mine'));
        }}
      ></HeaderBack>
      <div
        className={cn(
          'min-h-[2.22rem] px-[.1852rem] pt-[.32rem] rounded-normal bg-[var(--colour-35)] w-[7.02rem] m-[.24rem_auto]',
        )}
      >
        <div className="flex flex-col gap-[.14rem]">
          <div className="font-[500]">
            <span className="text-[.24rem] text-[var(--colour-41)]">{t('referral.bonus')}</span>
            <span className="text-[.24rem] ml-[10px] text-[var(--colour-40)]">
              {getShowMoneyWithConfigDecimal(bonusBalance)}
            </span>
          </div>
          <div className="gap-[.14rem] flex items-centrer">
            {list &&
              list.map((item, index) => {
                return (
                  <div
                    onClick={item.onClick}
                    key={index}
                    className={`w-[2.12rem] h-[.64rem] rounded-normal flex items-center justify-center active:scale-[.95] transition-all select-none cursor-pointer font-[700] text-[.24rem] ${index == 0 ? 'bg-[var(--colour-1)] text-[var(--colour-2)]' : 'border border-[var(--colour-1)] bg-[var(--colour-5)] text-[var(--colour-3)]'}`}
                  >
                    {item.name}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="text-[.2rem] mt-[.22rem] text-[var(--colour-41)]">{t('referral.commissionPatient')}</div>
      </div>
    </div>
  );
};

export default NTemplate001;
