import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import scrollIntoView from 'scroll-into-view';

const ScrollWrapper = ({ className, children, mr = true, style, noRelative = false, isShowOnBottom = false }) => {
  const endRef = useRef(null);
  const hadScrollToBottom = useRef(false);
  let timer = null;
  useEffect(() => {
    if (isShowOnBottom && endRef && !hadScrollToBottom.current) {
      timer = window.setTimeout(() => {
        if (timer != null) {
          clearTimeout(timer);
          timer = null;
        }
        scrollIntoView(endRef.current, {
          time: 500,
          validTarget: function (target, parentsScrolled) {
            return target !== window && ~target.className.indexOf('auto-scrollable');
          },
        });
      }, 300);
      hadScrollToBottom.current = true;
    }
  }, [isShowOnBottom]);
  return (
    <div className={`scrollbar-box-container ${className}`} style={style}>
      <div className="scrollbar-box-outer hidden-scroll-y auto-scrollable">
        <div className={`${noRelative ? 'scrollbar-box-inner-no-relative' : 'scrollbar-box-inner'}`}>
          {children}
          <div ref={endRef} />
        </div>
      </div>
    </div>
  );
};

export default ScrollWrapper;
