import { useEffect } from 'react';
import forbiddenImg from '../../assets/img/forbidden.png';
import { removeLoadingPage } from '../../utils/helper';
import { removeParentIfmLoading } from '../../manager/iframeManager';

const Forbidden = () => {
  useEffect(() => {
    removeLoadingPage();
    removeParentIfmLoading();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div>
        <img src={forbiddenImg} />
      </div>
      <div className="font-[400] text-[#1E84F0] max-w-[14rem] text-center mt-[.4rem] text-[.36rem] px-[.3rem]">
        It is forbidden to access our website from your location.we regret any inconvenience caused.
      </div>
    </div>
  );
};

export default Forbidden;
