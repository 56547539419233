import { useEffect, useState } from 'react';
import { Web3 } from 'web3';

export function isWeb3Browser() {
  return typeof window.ethereum !== 'undefined';
}
export function isMetaMaskBrowser() {
  return isMetaMaskBrowser() && window.ethereum.isMetaMask === true;
}

export async function connectWallet() {
  if (!isWeb3Browser()) {
    return new Error('notWeb3Browser');
  }

  try {
    return await window.ethereum.request({ method: 'eth_requestAccounts' });
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function sign(strToSign, addressToSign) {
  if (!isWeb3Browser()) return null;

  const web3 = new Web3(window.ethereum);

  try {
    return await web3.eth.personal.sign(strToSign, addressToSign);
  } catch (err) {
    return null;
  }
}

export async function getWalletAddress() {
  try {
    const web3 = new Web3(window.ethereum);
    return await web3.eth.getAccounts();
  } catch (e) {
    return [];
  }
}

export function useWallet() {
  const [walletAddress, setWalletAddress] = useState([]);
  // console.log('Web3 address:', walletAddress);

  useEffect(() => {
    if (isWeb3Browser()) {
      const web3 = new Web3(window.ethereum);

      (async () => {
        try {
          const address = await web3.eth.getAccounts();
          setWalletAddress(address);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (isWeb3Browser()) {
      const ethereum = window.ethereum;

      const onAccountsChanged = async (walletAddress) => {
        console.log(walletAddress);
        const web3 = new Web3(window.ethereum);
        // setWalletAddress(walletAddress);
        const addrs = await web3.eth.getAccounts();
        setWalletAddress(addrs);
      };

      ethereum.on('accountsChanged', onAccountsChanged);

      return () => {
        ethereum.removeListener('accountsChanged', onAccountsChanged);
      };
    }
  }, []);

  return walletAddress;
}
