import React, { useEffect } from 'react';
import Icon, { IconName } from '../../../../icon';
import { useSpring, animated } from 'react-spring';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setvisibleBackTopBtn } from '../../../../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { getRootCssVariable } from '../../../../../utils/helper';

const BackToTopBtn = () => {
  const bottom = getRootCssVariable('--menubar-height') + 0.16;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visibleBackTopBtn } = useSelector(
    (state) => ({
      visibleBackTopBtn: state.app.visibleBackTopBtn,
    }),
    shallowEqual,
  );

  const animation = useSpring({
    opacity: visibleBackTopBtn ? 1 : 0,
    config: { duration: 200 },
    bottom: `${bottom}rem`,
  });

  useEffect(() => {
    const handleScroll = (e) => {
      const { viewport } = e.detail.elements();
      const { scrollTop } = viewport;
      if (scrollTop > 220) {
        dispatch(setvisibleBackTopBtn(true));
      } else {
        dispatch(setvisibleBackTopBtn(false));
      }
    };

    document.addEventListener('overlayScrollerCustomScroll', handleScroll);
    return () => {
      dispatch(setvisibleBackTopBtn(false));
      document.removeEventListener('overlayScrollerCustomScroll', handleScroll);
    };
  }, []);

  const backToTopHandle = () => {
    const myEvent = new CustomEvent('overlayScrollerScrollTo', {
      detail: {
        offsetTop: 0,
        behavior: 'smooth',
      },
    });
    document.dispatchEvent(myEvent);
  };

  return (
    <animated.div
      className={`w-[.8rem] right-[.38rem] bg-[var(--colour-59)] rounded-[50%] scrollTop_box fixed z-[999] h-[var(--scroll-top-height)] flex flex-col cursor-pointer items-center justify-center leading-[15px]`}
      style={animation}
      onClick={backToTopHandle}
    >
      <div className="w-[.4rem] h-[.3rem] flex items-center justify-center">
        <div className="w-[.34rem] h-[.22rem] text-[var(--colour-2)]">
          <Icon name={IconName.BacktoTop1}></Icon>
        </div>
      </div>

      <div className="text-[var(--colour-2)] text-[.24rem] font-[500]">{t('home.scrollTop')}</div>
    </animated.div>
  );
};

export default BackToTopBtn;
