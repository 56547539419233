import React, { createContext, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// 创建上下文
const NavigateContext = createContext();

export const NavigateProvider = ({ defaultState = {}, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPathName = location.state?.from;
  const fullPath = `${location.pathname}${location.search}${location.hash}`;
  const navigateWithState = (to, options = {}, isSetNewestPath = true) => {
    const mergedState = {
      ...defaultState,
      from: isSetNewestPath ? fullPath : fromPathName ? fromPathName : fullPath,
      ...(options.state || {}),
    };
    navigate(to, { ...options, state: mergedState });
  };

  return <NavigateContext.Provider value={navigateWithState}>{children}</NavigateContext.Provider>;
};

export const useGlobalNavigate = () => {
  return useContext(NavigateContext);
};
