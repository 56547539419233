const __initZIndex = 10000;
let __dialogZIndexArr = [__initZIndex];
const useDialogModalZIndex = () => {
  const initZIndex = __initZIndex;
  const getTopZIndex = () => {
    let topZIndex = __dialogZIndexArr.length > 0 ? __dialogZIndexArr[__dialogZIndexArr.length - 1] : initZIndex;
    let newTopZIndex = topZIndex + 1;
    __dialogZIndexArr.push(newTopZIndex);
    return newTopZIndex;
  };
  const removeTopZIndex = () => {
    if (__dialogZIndexArr.length > 1) {
      __dialogZIndexArr.pop();
    } else {
      __dialogZIndexArr = [initZIndex];
    }
  };
  return {
    getTopZIndex,
    removeTopZIndex,
    initZIndex,
  };
};

export default useDialogModalZIndex;
