import { useTranslation } from 'react-i18next';
import { btnTypes } from '../../constants/tourBtnTypes';
import ButtonWithMask from '../buttonWithMask';

const TourBtn = ({ type, handleJoin = () => {}, handleClaim = () => {} }) => {
  const { t } = useTranslation();

  const bg = ['end', 'joined', 'settling'].includes(type)
    ? 'bg-[var(--colour-21)]'
    : 'text-[var(--colour-2)] bg-[var(--colour-1)]';
  const cursor = ['end', 'joined', 'settling'].includes(type) ? 'cursor-not-allowed' : 'cursor-pointer';

  const onClick = (event) => {
    if (type === 'claim') {
      handleClaim(event.currentTarget);
    } else if (type === 'join') {
      handleJoin();
    }
  };

  return (
    <ButtonWithMask
      disable={['end', 'joined', 'settling'].includes(type)}
      className="flex items-center justify-center w-full rounded-normal font-[700] h-[1.04rem] text-[.36rem]"
      onClick={onClick}
      style={2}
    >
      {t(`tour.${type}`)}
    </ButtonWithMask>
  );
};

export default TourBtn;
