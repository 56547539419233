// import React, { FC, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Close from '../close';

import { getDateFormatStr2, getShowMoney, putChangeText } from '../../utils/helper';

const FormHeader = ({ title, handleClose, className = '', showType }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>
        <div className="w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full h-[.02rem] mt-[.28rem] bg-[var(--colour-6)]"></div>
    </>
  );
};

const widthdrawRecordDetail = ({ handleClose, Data = null }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let DetailInfo = {};
  if (Data && Data.detail_info) {
    try {
      DetailInfo = JSON.parse(Data.detail_info);
    } catch {}
  }

  return (
    <div className="my-[.3rem] flex flex-col justify-between  text-center relative h-[auto] px-[.48rem] pb-[.4rem] w-[7.5rem] rounded-normal bg-[var(--colour-35)] text-primary-content">
      <div className="">
        <div className="">
          <FormHeader title={t('deposit-withdraw.details')} handleClose={handleClose} />
        </div>
        <div className="px-[.16rem] text-left bg-[var(--colour-34)] rounded-normal">
          <div className="text-[.24rem] mt-[.24rem]">
            <span className="text-[var(--colour-14)] mr-[.1rem]">{t('account.transaction_iD')}:</span>
            <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">{Data?.transaction_id || '--'}</span>
          </div>
          <div className="text-[.24rem] mt-[.24rem]">
            <span className="text-[var(--colour-14)] mr-[.1rem]">{t('deposit-withdraw.date')}:</span>
            <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">
              {Data.create_time && getDateFormatStr2(Data.create_time)}
            </span>
          </div>
          <div className="text-[.24rem] mt-[.24rem]">
            <span className="text-[var(--colour-14)] mr-[.1rem]">{t('login-signup.phone')}:</span>
            <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">{DetailInfo?.acc_remark || '--'}</span>
          </div>

          <div className="text-[.24rem] mt-[.24rem]">
            <span className="text-[var(--colour-14)] mr-[.1rem]">{t('deposit-withdraw.amount')}:</span>
            <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">
              {Data?.amount ? getShowMoney(Data.amount) : '--'}
            </span>
          </div>
          <div className="text-[.24rem] mt-[.24rem]">
            <span className="text-[var(--colour-14)] mr-[.1rem]">{t('account.fee')}:</span>
            <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">
              {Data?.fee ? getShowMoney(Data.fee) : '0'}
            </span>
          </div>
          <div className="text-[.24rem] mt-[.24rem]">
            <span className="text-[var(--colour-14)] mr-[.1rem]">{t('deposit-withdraw.account_account')}:</span>
            <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">
              {DetailInfo?.acc ? DetailInfo.acc : DetailInfo?.cpf ? DetailInfo?.cpf : '--'}
            </span>
          </div>

          {Data?.reason ? (
            <div className="text-[.24rem] mt-[.24rem]">
              <span className="text-[var(--colour-14)] mr-[.1rem]">{t('deposit-withdraw.reason')}:</span>
              <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">{Data?.reason}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default widthdrawRecordDetail;
