import HeaderSidebarSwitch from '../../headerSidebarSwitch';
import HeaderSportSwitch from '../../headerSportSwitch';
import cn from 'classnames';
import LogoBox from '../../../logoBox';
import { isControllableSite, isLogin } from '../../../../utils/helper';
// import HeaderWallet from '../../headerWallet';
import DHeaderWallet from './components/headerWallet';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import useRouterHelper from '../../../../hooks/useRouterHelper';
// import HeaderUser from '../../headerUser';
// import PrivateLette from '../../headerRight/privateLetter';
import LoginBtn from '../../loginBtn';
import RegisterBtn from '../../registerBtn';
import { useSelector, shallowEqual } from 'react-redux';
import Search from '../../search';

export default function DTemplate001() {
  const { handleClickWallet } = useRouterHelper();
  const { hanldeRefurbishBalance, balanceLoad } = useHandleLogic();

  const { userObj } = useSelector(
    (state) => ({
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  return (
    <>
      <div className={cn('flex flex-col items-center header-box transition-[top]')}>
        <div
          className={`w-full flex flex-row items-center justify-between relative bg-[var(--colour-4)] h-[var(--header-box-height)] px-[.16rem]`}
        >
          <div className={cn('flex items-center space-x-[.2rem]')}>
            <>
              <HeaderSidebarSwitch className="!w-[.36rem] !h-[.36rem]" iconClassName="!w-[.36rem] !h-[.36rem]" />
              <LogoBox className="!h-[.64rem]" />
              <HeaderSportSwitch />
            </>
          </div>
          <div className="flex items-center">
            {userObj != null ? (
              <div className="flex space-x-[.12rem] items-center">
                <>
                  <DHeaderWallet
                    clickFreshen={hanldeRefurbishBalance}
                    balanceLoad={balanceLoad}
                    clickWallet={() => {
                      handleClickWallet();
                    }}
                    handleGoToWithdraw={() => {
                      handleClickWallet(2);
                    }}
                  />
                  <Search className="!w-[.24rem] !h-[.24rem] !ml-[.16rem]" />
                </>
              </div>
            ) : (
              <div
                className={cn('flex items-center', {
                  'space-x-[.1rem]': isControllableSite(),
                })}
              >
                <>
                  <LoginBtn className="min-w-[1.2rem] !h-[.52rem] !rounded-[.12rem]" />
                  <RegisterBtn className="min-w-[1.48rem] !h-[.52rem] !rounded-[.12rem] !bg-transparent" />
                  <Search className="!w-[.24rem] !h-[.24rem] !ml-[.16rem]" />
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
