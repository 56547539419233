import Countdown, { zeroPad } from 'react-countdown';

const TwoNumber = ({ text = '', index, length, style }) => {
  const units = ['h', 'm', 's'];
  return index == 0 && text == '00' ? (
    <></>
  ) : (
    <span>
      {text}
      {style == 1 ? <></> : units[index]}
      {index < length - 1 ? ':' : ''}
    </span>
  );
};

const rendererProp = ({ days, hours, minutes, seconds, completed }, props) => {
  const list = completed
    ? ['00', '00', '00']
    : [zeroPad(days * 24 + hours, 2), zeroPad(minutes, 2), zeroPad(seconds, 2)];
  return list.map((item, index) => (
    <TwoNumber key={index} text={item} index={index} length={list.length} style={props.style} />
  ));
};

export default function BonusCountdown({ style = 1, onComplete, date }) {
  return <Countdown onComplete={onComplete} renderer={(props) => rendererProp(props, { style: style })} date={date} />;
}
