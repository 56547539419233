import Icon, { IconName } from '../../icon';

const Closer = ({ handleClose }) => {
  return (
    <div onClick={handleClose} className="absolute top-[.18rem] right-[.18rem] w-[.3rem] h-[.3rem] cursor-pointer">
      <Icon name={IconName.Close2} />
    </div>
  );
};

const PopBg2 = ({ children, handleClose, isShowClose = true, className = '' }) => {
  return (
    <div
      className={`w-[6.14rem] h-[8.1rem] rounded-[.16rem] bg-[var(--colour-35)] relative overflow-hidden ${className}`}
      style={{
        backgroundImage: 'url(/static/img/diamond1.png), url(/static/img/diamond2.png)',
        backgroundPosition: 'left bottom, right bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '.62rem .46rem, .86rem .6rem',
      }}
    >
      {children}
      {isShowClose && <Closer handleClose={handleClose} />}
    </div>
  );
};

export default PopBg2;
