import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { get } from 'lodash';
import cn from 'classnames';
import {
  setMobileTagIndex,
  setPpopularIndex,
  setDrawerMenuVisible,
  setDialogModalVisible,
  DialogModalChildrenKey,
} from '../../../../../../redux/reducers/appSlice';
import { toLocalePath, toNoLocalePath } from '../../../../../../utils/localePath';
import { isLogin, cmsImgUrl, isLightTheme } from '../../../../../../utils/helper';
import useLangServerConfig from '../../../../../../hooks/useLangServerConfig';
import LocalSideIcon from '../../template001/components/localSideIcon';
import { IconName } from '../../../../../icon';
import { useGlobalNavigate } from '../../../../../../contexts/navigateContext';

const HeaderHeight = 43; //header高度

const DrawermenuTagSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { pathname } = useLocation();

  const { drawerMenuVisible, mobileTagIndex, popularIndex, identity, websiteInitData, websiteInitConfig } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      mobileTagIndex: state.app.mobileTagIndex,
      popularIndex: state.app.popularIndex,
      identity: get(state, 'user.identity'),
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const [list, setList] = useState([]); //处理完之后的数据列表
  const { getServerLangText } = useLangServerConfig();
  const [notClickList] = useState([
    { game_type_id: '001', isSwitch: true, isMask: false, isName: 'home.category_001', isPrivate: true },
    { game_type_id: '003', isSwitch: true, isMask: false, isName: 'home.category_003', isPrivate: true },
    // { game_type_id: '-3', isSwitch: false, isMask: false, isName: 'home.drawer_jackpot', isPrivate: true },
  ]); //前端固定要加上的三条数据

  useEffect(() => {
    if (websiteInitData && websiteInitData.template) {
      const arrData = websiteInitData.template.find((item) => {
        return item.module == 'ColumnData';
      });
      if (arrData) {
        let resData = arrData?.config.map((item) => {
          return { ...item, isSwitch: true, isMask: false };
        });

        resData = resData.concat(notClickList);

        setList(resData);
        dispatch(setMobileTagIndex(resData[0].game_type_id));
      }
    }
  }, [websiteInitData]);

  useEffect(() => {
    setList((list) => {
      return list.map((listItem) => {
        return { ...listItem, isMask: false };
      });
    });
  }, [identity, drawerMenuVisible]);

  const clickItem = (game_type_id) => {
    if (['003', '001'].includes(game_type_id)) {
      dispatch(setMobileTagIndex(list[0].game_type_id));
      dispatch(setPpopularIndex(game_type_id));
      myScrollTo(list[0].game_type_id);
    } else if (game_type_id == list[0].game_type_id) {
      dispatch(setMobileTagIndex(list[0].game_type_id));
      dispatch(setPpopularIndex('1011'));
      myScrollTo(list[0].game_type_id);
    } else {
      dispatch(setMobileTagIndex(game_type_id));
      myScrollTo(game_type_id);
    }
  };

  const findingDom = async (game_type_id) => {
    let dom = document.querySelector(`.check_dom_loading_completed`);
    if (dom) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      clickItem(game_type_id);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100));
      findingDom(game_type_id);
    }
  };

  const clickItemCheckedHome = (game_type_id) => {
    navigate(toLocalePath('/'));
    findingDom(game_type_id);
  };

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  return (
    <>
      {document.querySelector('.tag_search_portal_target') ? (
        createPortal(
          <div className="flex flex-wrap justify-between">
            {list.slice(0, 10).map((item, index) => {
              return (
                <div
                  key={index}
                  className={cn(
                    'w-[1.38rem] h-[1.06rem] rounded-normal cursor-pointer active:scale-[.95] transition-all select-none p-[.19rem] flex flex-col items-center text-[.2rem] font-[500] leading-[.24rem] mb-[.16rem] relative',
                    {
                      'background-colour1 !text-[var(--colour-2)]':
                        mobileTagIndex == list[0].game_type_id
                          ? popularIndex == '1011'
                            ? item.game_type_id == list[0].game_type_id
                            : item.game_type_id == popularIndex
                          : mobileTagIndex == item.game_type_id,
                      'bg-[var(--colour-16)] text-[var(--colour-14)]':
                        (mobileTagIndex != item.game_type_id || item.game_type_id == list[0].game_type_id) &&
                        websiteInitConfig?.website_info.website_theme != 'theme-15',
                      'bg-menu-default text-[var(--colour-14)]':
                        (mobileTagIndex != item.game_type_id || item.game_type_id == list[0].game_type_id) &&
                        websiteInitConfig?.website_info.website_theme == 'theme-15',
                    },
                  )}
                  onClick={() => {
                    if (item.isSwitch) {
                      if (!item.needLogin || (item.needLogin && isLogin())) {
                        handleLinkClick();
                        if (toNoLocalePath(pathname) === '/') {
                          clickItem(item.game_type_id);
                        } else {
                          clickItemCheckedHome(item.game_type_id);
                        }
                      } else {
                        handleLinkClick();
                        dispatch(
                          setDialogModalVisible({
                            show: DialogModalChildrenKey.LoginBox,
                          }),
                        );
                      }
                    } else {
                      setList(
                        list.map((listItem) => {
                          return item.game_type_id == listItem.game_type_id ? { ...listItem, isMask: true } : listItem;
                        }),
                      );
                    }
                  }}
                >
                  {item.isMask ? (
                    <div className="absolute top-[0rem] left-[0rem] right-[0rem] bottom-[0rem] bg-[var(--colour-21)] rounded-normal text-[var(--colour-56)] text-[.16rem] font-[600] leading-[.2rem] flex items-center justify-center text-center">
                      <div>
                        <div>{t('home.drawer_coming')}</div>
                        <div>{t('home.drawer_soon')}</div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {item.isPrivate ? (
                    <LocalSideIcon
                      isShowSvg={!(item.game_type_id == popularIndex && mobileTagIndex == list[0].game_type_id)}
                      svgIconName={item.game_type_id == '001' ? IconName.Favorite : IconName.Rencent}
                      imgSrc={item.game_type_id == '001' ? '/static/img/favorite.png' : '/static/img/rencent.png'}
                    />
                  ) : (
                    <div className="w-[.4rem] h-[.4rem] mb-[.04rem]">
                      <img
                        className="w-full"
                        src={
                          item.game_type_id != list[0].game_type_id
                            ? mobileTagIndex == item.game_type_id
                              ? cmsImgUrl(item.icon_selected)
                              : cmsImgUrl(item.icon_unselected)
                            : popularIndex == '1011' &&
                                item.game_type_id == list[0].game_type_id &&
                                mobileTagIndex == list[0].game_type_id
                              ? cmsImgUrl(item.icon_selected)
                              : cmsImgUrl(item.icon_unselected)
                        }
                      />
                    </div>
                  )}

                  <div className="text-center">
                    {item.isPrivate ? t(item.isName) : getServerLangText(item.lang_key)}
                  </div>
                </div>
              );
            })}
          </div>,

          document.querySelector('.tag_search_portal_target'),
        )
      ) : (
        <></>
      )}
    </>
  );
};
export default DrawermenuTagSearch;

function myScrollTo(id) {
  let dom = document.querySelector(`.dom_${id}`);
  let topNum = dom.getBoundingClientRect().top;
  let scrollNum = getScrollPosition().y;
  window.scrollTo({
    top: topNum + scrollNum - HeaderHeight + 2,
    // behavior: 'smooth',
  });
}

// 获取 当前 滚动条的长度， 水平 && 垂直方向
function getScrollPosition() {
  let x, y;
  if (!!window.pageXOffset) {
    x = window.pageXOffset;
    y = window.pageYOffset;
  } else {
    x = (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    y = (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }
  return { x, y };
}
