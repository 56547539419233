import React, { forwardRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Icon, { IconName } from './icon';
import cn from 'classnames';

const DropDownInput = forwardRef(
  (
    {
      children,
      onClick,
      className = ' px-[.2rem] mr-[.14rem] h-[1.12rem] ',
      iconRotate = false,
      iconCanRotate = false,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    return (
      <div
        className={`flex flex-row cursor-pointer items-center justify-between ${className}`}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

export default DropDownInput;
