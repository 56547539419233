import LimitClickWrapper from '../limitClickWrapper';
import cn from 'classnames';

/***带遮罩状态的按钮 */
export default function ButtonWithMask({
  disable = true,
  limitTime = 1,
  children,
  onClick = () => {},
  className = '',
  style = 1,
}) {
  return disable ? (
    <div className={cn(` cursor-not-allowed text-[var(--colour-2)] bg-[var(--colour-7)] ${className}`, {})}>
      {children}
    </div>
  ) : (
    <LimitClickWrapper
      limitTime={limitTime}
      onClick={onClick}
      className={cn(`${className}`, {
        'text-[var(--colour-2)] bg-[var(--colour-29)]': style == 1,
        'text-[var(--colour-2)] bg-[var(--colour-1)]': style == 2,
      })}
    >
      {children}
    </LimitClickWrapper>
  );
}
