import DialogModal from './dialogModal/index';
import Confirm from './dialogModal/confirm';

const PopConfirm = ({
  children,
  title,
  cancelText,
  visible,
  confirmText,
  handleClose,
  handleCancel,
  handleConfirm,
  zIndex = 9999,
}) => {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: zIndex,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '52',
          },
        }}
        isOpen={visible}
        handleClose={handleClose}
      >
        <Confirm
          children={children}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          disableClose={false}
          title={title}
          cancelText={cancelText}
          confirmText={confirmText}
        />
      </DialogModal>
    </>
  );
};

export default PopConfirm;
