import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import WithdrawPasswordPanda from './withdrawPasswordPanda';
import WithdrawMainPanda from './withdrawMainPanda';
import Loading from '../../../../loading2';
import { PaymentDirectWithdrawConfigWithPassword } from '../../../../../api/newDepositWithdraw';

export default function WithdrawPanda({ handleClose = () => {} }) {
  const [loading, setLoading] = useState(true);
  const [needSwitchPassword, setNeedSwitchPassword] = useState(true); //是否进入设置提现密码界面
  const [configData, setConfigData] = useState(null);

  const upDataConfig = () => {
    PaymentDirectWithdrawConfigWithPassword()
      .then((res) => {
        if (res.code == 0) {
          setConfigData(res.data);
          setNeedSwitchPassword(
            res.data.switch_withdraw_password == '0' ? false : res.data.is_set_withdraw_password == '1' ? false : true,
          );
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    upDataConfig();
  }, []);

  return (
    <div className="h-full bg-[var(--colour-54)]">
      {loading ? (
        <Loading className="h-full" />
      ) : needSwitchPassword ? (
        <WithdrawPasswordPanda handleClose={handleClose} setNeedSwitchPassword={setNeedSwitchPassword} />
      ) : (
        <WithdrawMainPanda handleClose={handleClose} withdrawConfig={configData} upDateWithdrawConfig={upDataConfig} />
      )}
    </div>
  );
}
