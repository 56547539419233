import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DialogModal from '../../../../../components/dialogModal';
import Icon, { IconName } from '../../../../../components/icon';
import Close from '../../../../../components/close';
import { PaymentDepositView } from '../../../../../api/newDepositWithdraw';
import Loading from '../../../../../components/loading2';
import { getShowMoney, getDateFormatStr4 } from '../../../../../utils/helper';

function DepositDetailsPop({ setShowVerify, showVerify, id = '' }) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
      >
        <TipInner handleClose={() => setShowVerify(false)} id={id}></TipInner>
      </DialogModal>
    </>
  );
}
function TipInner({ handleClose, id = '' }) {
  const { t } = useTranslation();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state?.app?.websiteInitConfig,
    }),
    shallowEqual,
  );

  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  useEffect(() => {
    setLoading(true);
    PaymentDepositView({ id })
      .then((res) => {
        if (res.code == 0) {
          setInfo(res.data);
        } else {
          setInfo(null);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="bg-[var(--colour-35)] border border-solid border-[var(--colour-6)] overflow-hidden w-[6.86rem] rounded-[.16rem] p-[.41rem_.2rem_.42rem_.2rem] relative">
      <div className="text-[.32rem] font-[400] leading-[.38rem] text-[var(--colour-48)] text-center">
        {t('deposit-withdraw-wg.deposit_details')}
      </div>
      <div
        className="flex w-[.72rem] h-[.72rem] rounded-normal absolute right-[.32rem] top-[.24rem] items-center justify-center"
        onClick={() => handleClose()}
      >
        <Close className="text-[var(--colour-48)]" />
      </div>
      {loading ? (
        <Loading height="7.24rem" />
      ) : (
        <div className="pt-[.45rem]">
          <div className="flex flex-col items-center mb-[.32rem] px-[.12rem]">
            {info?.status == 0 ? (
              <>
                <div className="w-[1.28rem] h-[1.28rem] text-[var(--colour-26)] mb-[.4rem]">
                  <Icon name={IconName.DetailsLoading} />
                </div>
                <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-26)] mb-[.24rem]">
                  {t('deposit-withdraw-wg.deposit_record_pending')}
                </div>
                <div className="flex items-center justify-center">
                  <div className="text-[.44rem] font-[500] leading-[.54rem] text-[var(--colour-10)] mr-[.14rem]">
                    <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>{' '}
                    <span>{getShowMoney(info?.amount, 2, 2)}</span>
                  </div>
                  <div
                    className="w-[.36rem] h-[.36rem] text-[var(--colour-13)]"
                    onClick={() => {
                      copy(`${getShowMoney(info?.amount, 2, 2)}`);
                      toast.success(t('home.toast006'), {
                        containerId: 'global',
                      });
                    }}
                  >
                    <Icon name={IconName.Copy4} />
                  </div>
                </div>
              </>
            ) : info?.status == 1 ? (
              <>
                <div className="w-[1.28rem] h-[1.28rem] text-[var(--colour-24)] mb-[.4rem]">
                  <Icon name={IconName.SuccessTip} />
                </div>
                <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-24)] mb-[.24rem]">
                  {t('deposit-withdraw-wg.deposit_record_successful')}
                </div>
                <div className="text-[.44rem] font-[500] leading-[.54rem] text-[var(--colour-10)]">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>{' '}
                  <span>{getShowMoney(info?.amount, 2, 2)}</span>
                </div>
              </>
            ) : (
              <>
                <div className="w-[1.28rem] h-[1.28rem] text-[var(--colour-25)] mb-[.4rem]">
                  <Icon name={IconName.ErrorTip2} />
                </div>
                <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-25)] mb-[.24rem]">
                  {t('deposit-withdraw-wg.deposit_record_expired')}
                </div>
                <div className="text-[.44rem] font-[500] leading-[.54rem] text-[var(--colour-10)]">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>{' '}
                  <span>{getShowMoney(info?.amount || 0, 2, 2)}</span>
                </div>
              </>
            )}
          </div>
          <div className="pt-[.4rem] text-[.24rem] font-[500] leading-[.3rem] border-t border-dashed border-[var(--colour-6)]">
            <div className="flex items-center justify-between mb-[.18rem]">
              <div className="text-[var(--colour-14)]">{t('deposit-withdraw-wg.deposit_details_transaction_type')}</div>
              <div className="text-[var(--colour-38)]">{t('deposit-withdraw-wg.deposit_details_deposit')}</div>
            </div>
            <div className="flex items-center justify-between mb-[.18rem]">
              <div className="text-[var(--colour-14)]">{t('deposit-withdraw-wg.deposit_details_deposit_method')}</div>
              <div className="text-[var(--colour-38)]">{info?.pay_type}</div>
            </div>
            <div className="flex items-center justify-between mb-[.18rem]">
              <div className="text-[var(--colour-14)]">{t('deposit-withdraw-wg.deposit_details_recharge_channel')}</div>
              <div className="text-[var(--colour-38)]">{info?.provider}</div>
            </div>
            <div className="flex items-center justify-between mb-[.18rem]">
              <div className="text-[var(--colour-14)]">{t('deposit-withdraw-wg.deposit_details_creation_time')}</div>
              <div className="text-[var(--colour-38)]">{getDateFormatStr4(info?.create_time)}</div>
            </div>
            {info?.status == 1 ? (
              <div className="flex items-center justify-between mb-[.18rem]">
                <div className="text-[var(--colour-14)]">
                  {t('deposit-withdraw-wg.deposit_details_hora_de_transação')}
                </div>
                <div className="text-[var(--colour-38)]">{getDateFormatStr4(info?.update_time)}</div>
              </div>
            ) : null}
            <div className="flex items-center justify-between">
              <div className="text-[var(--colour-14)]">{t('deposit-withdraw-wg.deposit_details_request_number')}</div>
              <div className="flex items-center">
                <div className="text-[var(--colour-38)] mr-[.08rem] w-[2.22rem] truncate">{info?.order_id}</div>
                <div
                  className="w-[.32rem] h-[.32rem] text-[var(--colour-13)]"
                  onClick={() => {
                    copy(`${info?.order_id}`);
                    toast.success(t('home.toast006'), {
                      containerId: 'global',
                    });
                  }}
                >
                  <Icon name={IconName.Copy4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default DepositDetailsPop;
