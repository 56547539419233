import Close from '../close';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import RedDot from '../redDot';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../redux/reducers/appSlice';
import eventBonusTaskPng from '../../assets/img/event-bonus/eventBonus-task.png';
import eventBonusRelieMoneyPng from '../../assets/img/event-bonus/eventBonus-reliefMoney.png';
import eventBonusTournamentPng from '../../assets/img/event-bonus/eventBonus-tournament.png';
import eventBonusRedeemPng from '../../assets/img/event-bonus/eventBonus-redeem.png';
import eventBonusInvitePng from '../../assets/img/event-bonus/eventBonus-invite.png';
import eventBonusGuideWheelPng from '../../assets/img/event-bonus/eventBonus-luckyWheel.png';
import NoData from '../../components/noData';
import Loading from '../../components/loading2';
import useActivityStatus from '../../hooks/useActivityStatus';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { isLogin, isUseLayoutMobile } from '../../utils/helper';

const BonusItem = ({ unUsed = true, img, title, onClick }) => {
  return (
    <div
      className={cn(
        'my-[.1rem] w-[2.2rem] h-[2.2rem] relative rounded-normal flex flex-col items-center justify-center cursor-pointer bg-[var(--colour-54)] border border-[var(--colour-6)]',
      )}
      onClick={onClick}
    >
      {unUsed ? <RedDot className="absolute right-[-0.04rem] top-[-0.04rem]" /> : <></>}
      <div className="w-[1.1rem] h-[1.1rem] flex items-center justify-center">
        <img src={img} className="w-full not-drag" />
      </div>
      <p className="text-[var(--colour-1)] font-[700] mt-[.2rem] text-[.26rem]">{title}</p>
    </div>
  );
};

const EventBonus = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    InitActivityStatus,
    handleShowTask,
    handleShowReliefMoney,
    handleShowTournament,
    handleShowRedeem,
    handleShowFission,
    handleShowguideWheel,
  } = useActivityStatus();
  const { activityStatus, SpinguideWheelStatus, guideWheelSwitch, userObj } = useSelector(
    (state) => ({
      activityStatus: state.app.activityStatus,
      guideWheelSwitch: state.app.guideWheelSwitch,
      SpinguideWheelStatus: state.user.SpinguideWheelStatus,
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );
  const [loading, setLoading] = useState(true);

  const handleTaskClick = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Default,
      }),
    );
    handleShowTask();
  };

  const handleReliefMoneyClick = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Default,
      }),
    );
    handleShowReliefMoney();
  };

  const handleShowTournamentClick = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Default,
      }),
    );
    handleShowTournament();
  };

  const handleShowRedeemClick = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Default,
      }),
    );
    handleShowRedeem();
  };

  const handleShowInvitClick = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Default,
      }),
    );
    handleShowFission();
  };

  useEffect(() => {
    InitActivityStatus().then(() => {
      setLoading(false);
    });
  }, []);
  return (
    <>
      <div
        className={cn(
          'my-[.3rem] flex flex-col justify-between text-center relative h-[auto] px-[.32rem] pb-[.6rem] w-[7.4rem] rounded-normal bg-[var(--colour-35)]',
        )}
      >
        <div className={`flex flex-row justify-between items-center mt-[.3rem]`}>
          <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">
            {t('task.event')} {t('task.bonus')}
          </div>
          <div className="w-[.72rem] h-[.72rem] bg-[var(--colour-45)] text-[var(--colour-46)] flex justify-center items-center rounded-normal">
            <Close onClick={handleClose} />
          </div>
        </div>
        <div className="w-full bg-[var(--colour-6)] h-[1px] mt-[.28rem] mb-[.6rem]"></div>
        {loading || activityStatus == null ? (
          <div className="flex items-center justify-center">
            <div className="w-[3.32rem] h-[3.32rem] flex items-center justify-center">
              <Loading />
            </div>
          </div>
        ) : (
          <div className="grid my-[-0.1rem] justify-between grid-cols-event-bonus-item-smaller">
            {activityStatus.activity_novice_guide_tournament_switch == '1' ? (
              <BonusItem
                unUsed={userObj != null && guideWheelSwitch}
                img={eventBonusGuideWheelPng}
                title={t('task.luckyWheel')}
                onClick={handleShowguideWheel}
              />
            ) : (
              <></>
            )}

            {activityStatus.activity_task_switch == '1' ? (
              <BonusItem
                unUsed={userObj != null && activityStatus.activity_task == '1'}
                img={eventBonusTaskPng}
                title={t('task.task')}
                onClick={handleTaskClick}
              />
            ) : (
              <></>
            )}
            {activityStatus.activity_relief_money_switch == '1' ? (
              <BonusItem
                unUsed={userObj != null && activityStatus.activity_relief_money == '1'}
                img={eventBonusRelieMoneyPng}
                title={t('task.reliefMoney')}
                onClick={handleReliefMoneyClick}
              />
            ) : (
              <></>
            )}
            {activityStatus.activity_tournament_switch == '1' ? (
              <BonusItem
                unUsed={userObj != null && activityStatus.activity_tournament == '1'}
                img={eventBonusTournamentPng}
                title={t('tour.tour')}
                onClick={handleShowTournamentClick}
              />
            ) : (
              <></>
            )}
            {activityStatus.redeem_code_switch == '1' ? (
              <BonusItem
                unUsed={false}
                img={eventBonusRedeemPng}
                title={t('redeem.redeem_code')}
                onClick={handleShowRedeemClick}
              />
            ) : (
              <> </>
            )}
            {activityStatus.activity_contract_switch == '1' ? (
              <BonusItem
                unUsed={false}
                img={eventBonusInvitePng}
                title={t('fission.fission')}
                onClick={handleShowInvitClick}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EventBonus;
