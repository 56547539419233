import Home from './com/home';
import LogIn from './com/logIn';
import Message from './com/message';
import MyAccount from './com/myAccount';
import Promotion from './com/promotion';
import Referral from './com/referral';
import Sports from './com/sports';
import Support from './com/support';
import Withdraw from './com/withdraw';
import Deposit from './com/deposit';
import { toNoLocalePath, toLocalePath } from '../../../utils/localePath';
import { useLocation, useNavigate } from 'react-router-dom';
import useHandleLogic from '../../../hooks/useHandleLogic';
import useRouterHelper from '../../../hooks/useRouterHelper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useEffect, useState } from 'react';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { getTemplateId, isLogin, getRootCssVariableTrimmed } from '../../../utils/helper';
import {
  setNeedCheckedLobby,
  setAllowBodyScroll,
  setShowHeaderUserinfo,
  setDrawerMenuVisible,
  setDialogModalVisible,
  DialogModalChildrenKey,
} from '../../../redux/reducers/appSlice';
import { get } from 'lodash';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { useGlobalNavigate } from '../../../contexts/navigateContext';

//home,logIn,message,myAccount,promotion,referral,sports,support,withdraw,deposit
export default function Index() {
  const [color, setColor] = useState(getRootCssVariableTrimmed('--colour-4'));

  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { handleClickManMadeInPage } = useHandleLogic();
  const { handleClickWallet } = useRouterHelper();

  const {
    drawerMenuVisible,
    showNotification,
    showHeaderUserinfo,
    websiteInitData,
    isSportEnabled,
    websiteInitConfig,
  } = useSelector(
    (state) => ({
      drawerMenuVisible: get(state, 'app.drawerMenuVisible'),
      showNotification: get(state, 'app.showNotification'),
      showHeaderUserinfo: get(state, 'app.showHeaderUserinfo'),
      websiteInitData: get(state, 'app.websiteInitData'),
      isSportEnabled: get(state, 'app.isSportEnabled'),
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
    }),
    shallowEqual,
  );

  const { defaultLang, identity } = useSelector((state) => state.user);

  const svgUrl = `url("data:image/svg+xml,%3Csvg width='390' height='57' viewBox='0 0 390 57' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0213623 0.399902H134.833C138.253 0.399902 141.435 2.14755 143.271 5.03322L156.515 25.8565C159.451 30.4735 164.543 33.2698 170.015 33.2698H195.011H220.006C225.478 33.2698 230.57 30.4735 233.507 25.8565L246.751 5.03323C248.586 2.14756 251.769 0.399902 255.189 0.399902H390V57H0.0213623V0.399902Z' fill='${encodeURIComponent(color)}'/%3E%3Cpath d='M180.012 32.9519H210.01' stroke='url(%23paint0_linear_153_925)' stroke-width='0.6'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_153_925' x1='181.006' y1='32.4517' x2='210.181' y2='32.4518' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%232173C0'/%3E%3Cstop offset='0.517345' stop-color='%2375FFFA'/%3E%3Cstop offset='1' stop-color='%232173C0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`;

  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    if (drawerMenuVisible.visible) {
      dispatch(setAllowBodyScroll(false));
    } else {
      dispatch(setAllowBodyScroll(true));
    }
  }, [drawerMenuVisible.visible]);

  useEffect(() => {
    if (showNotification) {
      dispatch(setAllowBodyScroll(false));
    } else {
      dispatch(setAllowBodyScroll(true));
    }
  }, [showNotification]);

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
    if (showHeaderUserinfo) {
      dispatch(setShowHeaderUserinfo(false));
    }
  };

  const clickHome = () => {
    if (toNoLocalePath(pathname) === '/') {
      dispatch(setNeedCheckedLobby({ need: true }));
    }
    handleLinkClick();
    navigate(toLocalePath('/'));
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Buttom_Home);
  };

  const clickPromo = () => {
    handleLinkClick();
    navigate(toLocalePath('/promotion/events'));
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Buttom_Promo);
  };

  const clickReferral = () => {
    handleLinkClick();
    if (websiteInitConfig?.website_info?.switch_referral == '1') {
      navigate(toLocalePath('/referral'));
    } else {
      toast.info(t('home.toast032'), {
        containerId: 'global',
      });
    }
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Buttom_Referral);
  };

  const clickSports = () => {
    handleLinkClick();
    navigate(toLocalePath('/sport'));
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Button_Sport);
  };

  const clickDeposit = () => {
    handleLinkClick();
    if (isLogin()) {
      handleClickWallet();
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Button_Wallet);
  };

  const clickWithdraw = () => {
    handleLinkClick();
    if (isLogin()) {
      handleClickWallet(2);
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Button_Wallet);
  };

  const clickSupport = () => {
    handleLinkClick();
    handleClickManMadeInPage();
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Button_Service);
  };

  const clickMine = () => {
    handleLinkClick();
    if (isLogin()) {
      navigate(toLocalePath('/mine'));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Buttom_Profile);
  };

  const clickLogin = () => {
    if (!isLogin()) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_LogPage);
  };

  const clickMessage = () => {
    handleLinkClick();
    if (isLogin()) {
      navigate(toLocalePath(`/supportmessage/2`));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Buttom_Email);
  };

  useEffect(() => {
    if (websiteInitData && websiteInitData.website_info.mobile_navigation_bar_config) {
      const config = websiteInitData.website_info.mobile_navigation_bar_config;
      setMenuList(
        Array.from(config).filter((item) => {
          return identity ? item.login_after == '1' : item.login_before == '1';
        }),
      );
    }
  }, [websiteInitData, identity]);

  return (
    <>
      <div className="h-[var(--menubar-height)] bg-[transparent]"></div>

      {getTemplateId() == 'd-template003' ? <div className="blue-default"></div> : null}

      <div
        style={{
          backgroundImage: getTemplateId() == 'd-template003' ? svgUrl : '',
        }}
        className={cn(
          'h-[var(--menubar-height)] bottom-menu flex flex-row justify-between items-center font-[500] text-[.24rem] leading-[.3rem] realative',
          {
            'bg-bottom-menu': ['theme-15', 'theme-16'].includes(websiteInitConfig?.website_info.website_theme),
            'bg-no-repeat bg-cover bg-center': getTemplateId() == 'd-template003',
            'bg-[var(--colour-4)]': getTemplateId() != 'd-template003',
          },
        )}
      >
        {menuList.map((item, index) => {
          const ismagnifyImg = index == 2 && getTemplateId() == 'd-template003';

          switch (item.module) {
            case 'Home':
              return (
                <Home
                  data={item}
                  key={index}
                  isChoose={toNoLocalePath(pathname) === '/'}
                  clickHome={clickHome}
                  ismagnifyImg={ismagnifyImg}
                />
              );
            case 'Login':
              return (
                <LogIn data={item} key={index} isChoose={false} clickLogin={clickLogin} ismagnifyImg={ismagnifyImg} />
              );
            case 'Message':
              return (
                <Message
                  data={item}
                  key={index}
                  isChoose={toNoLocalePath(pathname) === '/email'}
                  clickMessage={clickMessage}
                  ismagnifyImg={ismagnifyImg}
                />
              );
            case 'Profile':
              return (
                <MyAccount
                  data={item}
                  key={index}
                  isChoose={toNoLocalePath(pathname) === '/mine'}
                  clickMine={clickMine}
                  ismagnifyImg={ismagnifyImg}
                />
              );
            case 'Promotion':
              return (
                <Promotion
                  data={item}
                  key={index}
                  isChoose={toNoLocalePath(pathname).indexOf('/promotion') > -1}
                  clickPromo={clickPromo}
                  ismagnifyImg={ismagnifyImg}
                />
              );
            case 'Referral':
              return (
                <Referral
                  data={item}
                  key={index}
                  isChoose={toNoLocalePath(pathname) === '/referral'}
                  clickReferral={clickReferral}
                  ismagnifyImg={ismagnifyImg}
                />
              );
            case 'Sports':
              return isSportEnabled ? (
                <Sports
                  data={item}
                  key={index}
                  isChoose={toNoLocalePath(pathname).indexOf('/sport') > -1}
                  clickSports={clickSports}
                  ismagnifyImg={ismagnifyImg}
                />
              ) : null;
            case 'Service':
              return (
                <Support
                  data={item}
                  key={index}
                  isChoose={false}
                  clickSupport={clickSupport}
                  ismagnifyImg={ismagnifyImg}
                />
              );
            case 'Withdraw':
              return (
                <Withdraw
                  data={item}
                  key={index}
                  isChoose={false}
                  clickWithdraw={clickWithdraw}
                  ismagnifyImg={ismagnifyImg}
                />
              );
            case 'Deposit':
              return (
                <Deposit
                  data={item}
                  key={index}
                  isChoose={false}
                  clickDeposit={clickDeposit}
                  ismagnifyImg={ismagnifyImg}
                />
              );
          }
        })}
      </div>
    </>
  );
}
