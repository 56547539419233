import { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import cn from 'classnames';
import Button from '../../button';
import Icon, { IconName } from '../../icon';

const PagingButton = ({ isLeft = true, isCanClick = false }) => {
  // console.log('button:', isLeft ? 'left' : 'right', 'isCanClick:', isCanClick, );

  return (
    <Button
      className={cn(
        'w-[.48rem] h-[.48rem] rounded-[.08rem] flex items-center justify-center bg-[var(--colour-5)] border border-solid border-[var(--colour-6)]',
        {
          'ml-[.07rem]': !isLeft,
          'mr-[.07rem]': isLeft,
        },
      )}
    >
      <span
        className={cn('w-[.2rem] h-[.28rem] inline-block', {
          'rotate-[180deg]': !isLeft,
          'text-[var(--colour-1)]': isCanClick,
          'text-[var(--colour-14)]': !isCanClick,
        })}
      >
        <Icon name={IconName.NewBack} />
      </span>
    </Button>
  );
};

export default function RcPagination({ currentPage = 1, pageSize = 10, pageTotal, onChange = () => {} }) {
  const totalPages = Math.ceil(pageTotal / pageSize);

  return (
    <Pagination
      prevIcon={<PagingButton isLeft={true} isCanClick={currentPage > 1} />}
      nextIcon={<PagingButton isLeft={false} isCanClick={currentPage < totalPages} />}
      className="pagination-wrapper"
      current={currentPage}
      total={pageTotal}
      pageSize={pageSize}
      onChange={onChange}
      showLessItems={true}
    />
  );
}
