import Icon, { IconName } from './icon';

const Close2 = ({ handleClose = () => {}, className = '', className2 = '' }) => {
  return (
    <div
      className={`flex items-center justify-center w-[.72rem] h-[.72rem] cursor-pointer active:scale-[.95] transition-all select-none ${className}`}
      onClick={handleClose}
    >
      <div className={`w-[.3rem] h-[.3rem] text-[var(--colour-46)] ${className2}`}>
        <Icon name={IconName.Close2} />
      </div>
    </div>
  );
};

export default Close2;
