class ResizeObserver {
  /**
   * 创建一个新的 ResizeObserver 实例。
   * @param {Function} resizeHandler - 当事件触发时要调用的回调函数。
   * @param {number} [delay=0] - 处理事件前的延迟时间（以毫秒为单位）。
   * @param {string} [eventType='resize'] - 要监听的事件类型。
   */
  constructor(resizeHandler, delay = 0, eventType = 'resize') {
    this.delay = delay;
    this.resizeTimeout = null;
    this.resizeHandler = resizeHandler;
    this.eventType = eventType;
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener(this.eventType, this.handleResize);
    this.handleResize();
  }

  /**
   * 处理事件的回调函数。
   * 如果设置了延迟，则在延迟后调用 resizeHandler 和所有监听回调。
   * 否则，立即调用 resizeHandler 和所有监听回调。
   */
  handleResize() {
    if (this.delay > 0) {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.resizeHandler();
      }, this.delay);
    } else {
      this.resizeHandler();
    }
  }

  /**
   * 销毁 ResizeObserver 实例，移除事件监听器并清除定时器。
   */
  destroy() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }

    window.removeEventListener(this.eventType, this.handleResize);
  }

  /**
   * 创建一个立即响应的 ResizeObserver 实例。
   * @param {Function} resizeHandler - 当事件触发时要调用的回调函数。
   * @param {string} [eventType='resize'] - 要监听的事件类型。
   * @returns {ResizeObserver} - 新的 ResizeObserver 实例。
   */
  static createImmediate(resizeHandler, eventType = 'resize') {
    return new ResizeObserver(resizeHandler, 0, eventType);
  }

  /**
   * 创建一个延时响应的 ResizeObserver 实例。
   * @param {Function} resizeHandler - 当事件触发时要调用的回调函数。
   * @param {number} [delay=300] - 处理事件前的延迟时间（以毫秒为单位）。
   * @param {string} [eventType='resize'] - 要监听的事件类型。
   * @returns {ResizeObserver} - 新的 ResizeObserver 实例。
   */
  static createDelayed(resizeHandler, delay = 300, eventType = 'resize') {
    return new ResizeObserver(resizeHandler, delay, eventType);
  }
}

export default ResizeObserver;
