import { useTranslation } from 'react-i18next';
import EventBonus from '../eventBonus';
import VipBonus from '../vipBonus';
import GiftMoney from '../giftMoney';
import RackbackBonus from '../rackback';
import PendingBonus from '../pending';
import HistoryBonus from '../history';

const ActivityBtnList = ({ handleLinkClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full rounded-normal p-[.14rem] mb-[.16rem] bg-[var(--colour-28)] border border-[var(--colour-6)]">
      <div className="text-[.24rem] leading-[.3rem] font-[500] text-[var(--colour-14)] text-center mb-[.16rem]">
        {t('home.drawer_promo_default')}
      </div>
      <div className="flex flex-wrap justify-between ">
        <EventBonus handleLinkClick={handleLinkClick} />
        <VipBonus handleLinkClick={handleLinkClick} />
        <RackbackBonus handleLinkClick={handleLinkClick} />
        <PendingBonus handleLinkClick={handleLinkClick} />
        <HistoryBonus handleLinkClick={handleLinkClick} />
        <GiftMoney handleLinkClick={handleLinkClick} />
      </div>
    </div>
  );
};

export default ActivityBtnList;
