import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { ClaimTournamentReq, JoinTourReq } from '../../api';
import { toast } from 'react-toastify';
import { DialogModalChildrenKey, setDialogModalVisible, setNeedUpdateBalance } from '../../redux/reducers/appSlice';
import TournamentCard from './tournamentCard';
import TourBtn from './tourBtn';
import MyData from './myData';
import useActivityStatus from '../../hooks/useActivityStatus';
import { btnTypes } from '../../constants/tourBtnTypes';
import { getCenterPosition } from '../../hooks/useMinimizeAnimation';
import { getShowMoney, openHeaderGiftMoney } from '../../utils/helper';

const TournamentItem = ({
  data = null,
  showMyData = false,
  needClick = true,
  showRedDot = true,
  myData = null,
  update = () => {},
}) => {
  // status 活动状态 0：非进行中；1：进行中
  // user_entername_status 用户锦标赛是否报名  0：未报名；1：结算中；2：已报名;3:可领取;4:已领取;5:不可领奖
  // activity_end_time 活动结束时间

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { InitActivityStatus } = useActivityStatus();

  const { identity, currency_symbol } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
    }),
    shallowEqual,
  );

  const joinedAndMatching = get(data, 'status') == 1 && get(data, 'user_entername_status') == 2;

  const [btnType, setBtnType] = useState(undefined);

  useEffect(() => {
    if (data) {
      const userStatus = get(data, 'user_entername_status');
      const isEnd = Date.now() >= get(data, 'activity_end_time', 0) * 1000;

      if (!isEnd) {
        if (userStatus == 0) {
          setBtnType(btnTypes[0]);
        } else if (userStatus == 2) {
          setBtnType(btnTypes[1]);
        }
      } else {
        if (userStatus == 1) {
          setBtnType(btnTypes[4]);
        } else if (userStatus == 3) {
          setBtnType(btnTypes[3]);
        } else {
          setBtnType(btnTypes[2]);
        }
      }
    }
  }, [data]);

  const handleJoin = () => {
    if (identity) {
      JoinTourReq({ activity_id: data.id }).then((res) => {
        if (res.code == 0) {
          toast.success(t('tour.success'), {
            containerId: 'global',
          });
          setBtnType(btnTypes[1]);
          update();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      });
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };

  const handleClaim = (element = null) => {
    ClaimTournamentReq({
      activity_id: data.id,
    }).then((res) => {
      // res = {
      //   ...res,
      //   code: 0,
      //   result: {
      //     currency: 'gift_money',
      //     amount: 1234567,
      //   }
      // }

      if (res.code == 0) {
        setBtnType(btnTypes[2]);
        dispatch(setNeedUpdateBalance({ need: true }));
        InitActivityStatus();

        const currency = res?.result?.currency;
        const amount = res?.result?.amount || 0;

        if (currency == 'gift_money') {
          if (element) {
            const fromPoints = [getCenterPosition(element, true)];
            openHeaderGiftMoney(amount, fromPoints);
          }
        } else if (currency == 'balance') {
          toast.success(
            t('tour.claim_success', {
              val: currency_symbol,
              val1: getShowMoney(amount),
            }),
            {
              containerId: 'global',
            },
          );
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  return (
    <div>
      <TournamentCard data={data} needClick={needClick} showRedDot={showRedDot} update={update} />
      <div className="mt-[.24rem]">
        {showMyData ? (
          joinedAndMatching ? (
            <MyData myData={myData} rewardList={get(data, 'activity_rewards', [])} />
          ) : (
            <TourBtn type={btnType} handleJoin={handleJoin} handleClaim={handleClaim} />
          )
        ) : (
          <TourBtn type={btnType} handleJoin={handleJoin} handleClaim={handleClaim} />
        )}
      </div>
    </div>
  );
};

export default TournamentItem;
