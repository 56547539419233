import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import GatewayManager from '../../api/gateway/GatewayManager';
import { format } from 'date-fns';
import { get } from 'lodash';
import {
  setIsIpLimit,
  setIsKickout,
  setIsSubscribed,
  setDepositMsg,
  setSubscribeError,
  setGiftMoneyMsg,
} from '../../redux/reducers/wsSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getShowMoney, getIdentity } from '../../utils/helper';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setRefetchActivityData,
} from '../../redux/reducers/appSlice';
import OAuthRedirecting from '../../utils/oauthRedirecting';
import { PwaGuideReward } from '../../api';

/**
 * 接收消息hook
 * @param serverType
 * @param sendHeartbeat  发送心跳
 */
export function useReceiveMessage({ lastMessage, sendHeartbeat }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { giftMoneyMsg, websiteInitConfig, activityStatus, isGameOpen } = useSelector(
    (state) => ({
      giftMoneyMsg: state.ws.giftMoneyMsg,
      websiteInitConfig: state.app.websiteInitConfig,
      activityStatus: state.app.activityStatus,
      isGameOpen: state.app.isGameOpen,
    }),
    shallowEqual,
  );
  useEffect(() => {
    if (lastMessage) {
      GatewayManager.onReceiveMessage(lastMessage.data, ({ cmd, data, subscribeSuccess, kickoutSuccess }) => {
        if (cmd == 4) {
          // 互踢
          if (kickoutSuccess) {
            console.log('[ws] kickout');
            dispatch(setIsKickout(true));
            OAuthRedirecting.RemoveNotLoggedInLsKey();
          }
        } else if (cmd == 6) {
          // 订阅
          if (subscribeSuccess) {
            console.log('[ws] subscribe: ok');
            dispatch(setIsSubscribed(true));
          } else {
            dispatch(setSubscribeError(true));
          }
        } else if (cmd == 14) {
          // 订阅失败
          dispatch(setSubscribeError(true));
        } else if (cmd == 16) {
          // ip限制
          console.log('[ws] ip limit');
          dispatch(setIsIpLimit(true));
        } else if (cmd == 100) {
          // 透传消息
          try {
            processMessage(JSON.parse(data));
          } catch (err) {
            console.error('parse 100 data error');
          }
        }
      });
    }
  }, [lastMessage]);

  // 处理消息
  const processMessage = (data) => {
    if (data) {
      switch (data.code) {
        case 1001: {
          // 心跳消息
          // console.log('[ws] pong');
          sendHeartbeat();
          break;
        }
        case 2001: {
          // 登录成功
          console.log('[ws] login: ok');
          break;
        }
        case 2088: {
          // 充值成功消息
          dispatch(setDepositMsg(data));
          break;
        }
        case 2089: {
          if (activityStatus && activityStatus.gift_money_menu_show_switch == '1') {
            // gift money
            const giftMoneyObj = JSON.parse(data.result);
            if (giftMoneyObj.id != giftMoneyMsg.id) {
              dispatch(setRefetchActivityData());
            }
            dispatch(setGiftMoneyMsg(giftMoneyObj));
          }
          break;
        }
        case 2090: {
          if (activityStatus && activityStatus.gift_money_menu_show_switch == '1') {
            console.error(`收到2090消息。时间:${format(new Date(), 'yyyy-MM-dd HH:mm:ss')};消息内容:${data.result}`);
            // gift money
            const giftMoneyObj = JSON.parse(data.result);
            // toast.success(
            //   t('home.toast023', {
            //     val: `${websiteInitConfig.website_info.currency_symbol || ''}${getShowMoney(giftMoneyObj.gift_money_amount)}`,
            //   }),
            //   {
            //     containerId: 'global',
            //   }
            // );
            toast.success(
              <span
                className="cursor-pointer"
                onClick={() => {
                  dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney }));
                }}
              >
                {t('home.toast023', {
                  val: `${websiteInitConfig.website_info.currency_symbol || ''}${getShowMoney(giftMoneyObj.gift_money_amount)}`,
                })}
              </span>,
              {
                containerId: 'global',
              },
            );
            dispatch(setRefetchActivityData());
          }
          break;
        }
        case 2091: {
          // 成功报名神秘彩金活动
          PwaGuideReward().then((res) => {
            if (res.code == 0) {
              const data = res.data;
              const showPopup = data.show_popup;
              //没开启pwa引导 && 没打开游戏
              if (!(showPopup != null && showPopup == '1') && !isGameOpen && getIdentity()) {
                dispatch(
                  setDialogModalVisible({
                    show: DialogModalChildrenKey.MysteryBonusTips,
                  }),
                );
              }
            }
          });

          break;
        }
        case 3000: {
          // 通知钱变化
          dispatch(setNeedUpdateBalance({ need: true }));
          break;
        }
        default:
          break;
      }
    }
  };
}
