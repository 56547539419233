import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import { isLogin } from '../../../../utils/helper';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../redux/reducers/appSlice';
import Badge from '../../../../views/mine/normal/template001/components/badge';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const RackbackBonus = ({ handleLinkClick = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const { switch_rakeback, promotionClaimedCount } = useSelector(
    (state) => ({
      switch_rakeback: state.app.websiteInitConfig?.system_config?.switch_rakeback,
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );

  const [showMask, setShowMask] = useState(false);
  const clickRackback = () => {
    if (switch_rakeback === '1') {
      if (isLogin()) {
        navigate(toLocalePath('/promotion/rakeback'));
      } else {
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.LoginBox,
          }),
        );
      }
      handleLinkClick();
      setShowMask(false);
    } else {
      setShowMask(true);
    }
  };

  return (
    <div
      onClick={clickRackback}
      className="active:scale-[.95] transition-all    w-[1.26rem] h-[.82rem] mb-[.08rem] rounded-normal cursor-pointer bg-[url(../assets/img/drawerMenuRackBackBg.png)] bg-no-repeat bg-[length:100%_100%]  p-[.08rem] relative"
    >
      <div className="font-[600] text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow">
        <div>{t('home.rackback')}</div>
      </div>
      {showMask ? (
        <div className="absolute inset-0 bg-[var(--colour-21)] rounded-normal  font-[600]  text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow flex flex-col justify-center text-center">
          <div>{t('home.drawer_coming')}</div>
          <div>{t('home.drawer_soon')}</div>
        </div>
      ) : null}
      {switch_rakeback === '1' && (promotionClaimedCount?.data?.rakeback || 0) > 0 ? (
        <Badge
          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
          className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.15rem] !right-[-0.04rem] flex items-center"
          text={promotionClaimedCount?.data?.rakeback || 0}
        ></Badge>
      ) : null}
    </div>
  );
};

export default RackbackBonus;
