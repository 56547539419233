import { useEffect, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import clsx from 'classnames';
import { isFunction, formatNumber, isDef, addUnit, createBEM, mergeProps } from './utils';
import { useSetState, useUpdateEffect } from './hooks';
import { BORDER_LEFT, BORDER_SURROUND } from './constant';

const bem = createBEM('rv-password-input');

const PasswordInput = forwardRef((p, ref) => {
  const props = mergeProps(p, {
    length: 6,
    gutter: 0,
    mask: true,
    type: 'text',
  });
  const innerEffect = useRef(false);
  const inputRef = useRef(null);
  const [state, updateState] = useSetState({
    code: props.value || '',
    focused: props.autoFocus,
    inputType: props.type,
    inputMode: 'text',
    pattern: '.*',
  });
  const codeArr = useMemo(() => state.code?.toString().split(''), [state.code]);
  const cursorIndex = useMemo(() => codeArr.length, [codeArr.length]);

  const { length, onSubmit } = props;

  const focus = () => {
    inputRef.current?.focus();
    updateState({ focused: true });
  };

  const blur = () => {
    inputRef.current?.blur();
    updateState({ focused: false });
  };

  const clear = () => {
    updateState({ code: '' });
  };

  const formatValue = (val, callback) => {
    if (isDef(length) && val?.length > +length) {
      val = val.slice(0, length);
    }

    if (props.type === 'number') {
      val = formatNumber(val, false, false);
    }

    if (isFunction(props.validator)) {
      if (props.validator(val)) {
        updateState({ code: val });
        if (isFunction(callback)) callback(val);
      }
    } else {
      updateState({ code: val });
      if (isFunction(callback)) callback(val);
    }
  };

  const handleChange = (e) => {
    const val = e.target.value || '';
    innerEffect.current = true;
    formatValue(val, props.onChange);
  };

  const renderPoints = () => {
    const Points = [];
    const { mask, gutter } = props;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      const char = codeArr[i];
      const showBorder = i !== 0 && !gutter;
      const showCursor = state.focused && cursorIndex === i && !char;
      let style;
      if (i !== 0 && gutter) {
        style = { marginLeft: addUnit(gutter) };
      }

      Points.push(
        <li
          key={i}
          className={clsx(
            {
              [BORDER_LEFT]: showBorder,
              [props.highlightClass]: props.highlightClass && char && !props.mask,
            },
            bem('item', { focus: showCursor }),
          )}
          style={style}
        >
          {mask ? <i style={{ visibility: char ? 'visible' : 'hidden' }} /> : char}
          {showCursor && <div className={clsx(bem('cursor'))} />}
        </li>,
      );
    }

    return Points;
  };

  useUpdateEffect(() => {
    if (innerEffect.current) {
      innerEffect.current = false;
      return;
    }
    formatValue(props.value ?? '');
  }, [props.value]);

  //输入的长度  == 设定长度
  useEffect(() => {
    if (state.code.length >= length) {
      inputRef?.current?.blur?.();
      onSubmit?.(state.code);
    }
  }, [length, state.code]);

  useEffect(() => {
    if (props.type === 'number') {
      updateState({ inputType: 'tel', inputMode: 'numeric', pattern: '[0-9]*' });
    } else {
      updateState({ inputType: 'text', inputMode: 'text', pattern: '.*' });
    }
  }, [props.type]);

  useImperativeHandle(ref, () => ({
    focus,
    blur,
    clear,
  }));

  return (
    <div
      className={bem()}
      tabIndex="-1"
      onFocus={(e) => {
        inputRef.current?.focus();
        updateState({ focused: true });
        props.onFocus?.(e);
      }}
      onBlur={(e) => {
        updateState({ focused: false });
        props.onBlur?.(e);
      }}
    >
      <input
        className="!z-[-1]"
        ref={inputRef}
        type={state.inputType}
        inputMode={state.inputMode}
        pattern={state.pattern}
        maxLength={props.length}
        value={state.code}
        autoComplete="false"
        autoCorrect="off"
        autoCapitalize="off"
        autoFocus={props.autoFocus}
        spellCheck="false"
        onChange={handleChange}
      />
      <ul
        className={clsx(bem('security'), {
          [BORDER_SURROUND]: !props.gutter,
        })}
      >
        {renderPoints()}
      </ul>
    </div>
  );
});

export default PasswordInput;
