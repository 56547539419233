import PopBg from '../../../components/popBg';
import ExpirationTime from '../../../components/expirationTime';
import BannerTitle from '../../../components/bannerTitle';
import RewardStage from '../../../components/rewardStage';
import LimitClickWrapper from '../../../../limitClickWrapper';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import ProgressBar from '../../../components/progressBar';
import { ActivityPromotionalAchievementsClaim } from '../../../../../api';
import { getPercentage, getShowMoney, isLogin } from '../../../../../utils/helper';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setPromotionPendingUpdateTimestamp,
  setRefetchActivityData,
} from '../../../../../redux/reducers/appSlice';
import { toast } from 'react-toastify';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import WheelBonus from '../../../wheelBonus';
import DialogModal from '../../..';
import DownloadManager from '../../../../../manager/downloadManager';
import BottomDownload from '../../../downLoadBtn';
import { isRunningTaskExit } from './helper';

const GuideRedbag = () => {
  return (
    <div className="w-[3.56rem] h-[3.7rem] bg-[url(/static/img/refer/referralAchievement_pc.png)] referral_achievement_guide origin-center scale-x-[0.67415] scale-y-[0.67415] bg-[length:14.24rem_14.8rem]"></div>
  );
};

export default function Index({
  data,
  handleClose = () => {},
  isShowCloseBtn,
  showMain = () => {},
  updateDataAfterClaim = () => {},
  onExpired,
  updateList,
  isSinglePopup,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  const [currentTask, setCurrentTask] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showedReward, setShowedReward] = useState(0);
  const [isShowMoneyPopup, setIsShowMoneyPopup] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [claimedMoney, setClaimedMoney] = useState(0);
  const [isContentVisible, setIsContentVisible] = useState(true);

  useEffect(() => {
    // taskStatus 10 未完成； 11 结算中; 20 未到领奖时间; 21 可领取; 22 已领取; 30 奖励过期

    if (data) {
      const taskList = data?.task_config || [];

      const claimableTaskList = taskList.filter((item) => Number(item.task_status) == 21);
      const unclaimableTaskList = taskList.filter((item) => [10, 11].includes(Number(item.task_status)));

      if (claimableTaskList[0]) {
        setCurrentTask(claimableTaskList[0]);
        setShowProgressBar(false);

        const amount = claimableTaskList.reduce((acc, curr) => {
          return acc + Number(curr?.reward_amount || 0);
        }, 0);
        setShowedReward(amount);
      } else if (unclaimableTaskList[0]) {
        setCurrentTask(unclaimableTaskList[0]);
        setShowProgressBar(true);

        const amount = unclaimableTaskList.reduce((acc, curr) => {
          return acc + Number(curr?.reward_amount || 0);
        }, 0);
        setShowedReward(amount);
      }
    }
  }, [data]);

  const handleClaim = (activity_id, tap_position = '') => {
    if (isRequesting) {
      return;
    }

    setIsRequesting(true);

    const params = {
      activity_id,
      tap_position,
    };

    ActivityPromotionalAchievementsClaim(params)
      .then((res) => {
        if (res.code == 0) {
          updateDataAfterClaim(res?.result?.activity_info);
          dispatch(setRefetchActivityData());
          dispatch(setNeedUpdateBalance({ need: true }));

          setClaimedMoney(res?.result?.amount || 0);
          setIsShowMoneyPopup(true);
          setIsContentVisible(false);

          dispatch(setPromotionPendingUpdateTimestamp());
        } else {
          if (res.code == 1008127) {
            if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
              setBottomDownloadBoxPromptSwitch({
                visible: true,
                isForce: true,
                downloadText: t('guideWheel.downloadtips'),
              });
            }
          } else {
            toast.error(t(`${res.code}`), {
              containerId: 'global',
            });
          }
        }
      })
      .finally(() => {
        setIsRequesting(false);
      });
  };

  // const handleClaim = (claimId) => {
  //   if (isRequesting) {
  //     return;
  //   }

  //   setIsRequesting(true);

  //   PromotionPendingClaim({
  //     id: claimId,
  //   })
  //     .then((res) => {
  //       if (res.code == 0) {
  //         updateDataAfterClaim();
  //         dispatch(setRefetchActivityData());
  //         dispatch(setNeedUpdateBalance({ need: true }));

  //         setClaimedMoney(res?.data?.amount || 0);

  //         setIsShowMoneyPopup(true);
  //       } else {
  //         if (res.code == 1008127) {
  //           if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
  //             setBottomDownloadBoxPromptSwitch({
  //               visible: true,
  //               isForce: true,
  //               downloadText: t('guideWheel.downloadtips'),
  //             });
  //           }
  //         } else {
  //           toast.error(t(`${res.code}`), {
  //             containerId: 'global',
  //           });
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       setIsRequesting(false);
  //     });
  // };

  const getAfterCloseMoneyCallback = () => {
    const isAllClaimed = (data?.task_config || []).every((item) => Number(item.task_status) == 22);

    return isAllClaimed ? (isSinglePopup ? handleClose : updateList) : () => {};
  };

  const onClickClaim = () => {
    // task_status: 11:结算中, 21: 可领取,
    if (isLogin()) {
      if (currentTask?.task_status == 21) {
        handleClaim(data?.id);
      } else {
        showMain();
      }
    } else {
      handleClose();
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
  };

  const handleCloseMoneyPopup = () => {
    setIsShowMoneyPopup(false);
    setIsContentVisible(true);
  };

  useEffect(() => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonusWindow_Popup);
  }, []);

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
          },
          overlay: {
            zIndex: 999999,
          },
        }}
        isOpen={isShowMoneyPopup}
        handleClose={handleCloseMoneyPopup}
        overlayClassName="wheelBonusMoney"
        afterClose={getAfterCloseMoneyCallback()}
      >
        <WheelBonus handleClose={handleCloseMoneyPopup} content={claimedMoney} />
      </DialogModal>

      <PopBg
        isCenter={true}
        handleClose={handleClose}
        isShowCloseBtn={isShowCloseBtn}
        isContentVisible={isContentVisible}
      >
        <div className={cn('flex flex-col w-full items-center', {})}>
          <ExpirationTime
            startTime={data?.activity_start_time ? data.activity_start_time : Math.floor(Date.now() / 1000)}
            endTime={data?.activity_end_time ? data.activity_end_time : Math.floor(Date.now() / 1000)}
            handleClose={onExpired}
          />
          <BannerTitle title={t('referral.referral_bonus')} handleShowExplain={() => {}} />
          <p className="max-w-[4.32rem] text-center text-[.24rem] font-[500] text-[var(--colour-48)]">
            {t('referral.cashback_tip')}
          </p>
          <RewardStage reward={`${getShowMoney(showedReward)}`}>
            <GuideRedbag />
          </RewardStage>
          {showProgressBar ? (
            <div className="mt-[.1rem]">
              <ProgressBar
                percent={getPercentage(
                  Number(currentTask?.finish_people_num || 0),
                  Number(currentTask?.people_num || 1),
                )}
              />
            </div>
          ) : null}
          <LimitClickWrapper
            className="w-[3.24rem] h-[.68rem] mt-[.3rem] flex items-center font-[600] text-[.28rem] justify-center rounded-normal cursor-pointer active:scale-[.95] transition-all select-none bg-[var(--colour-1)] text-[var(--colour-2)]"
            onClick={() => {
              onClickClaim();
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonus_Claim);
            }}
          >
            {/* {currentTask?.task_status == 11 ? t('promotion.pending') : t('referral.claim')} */}
            {isRunningTaskExit(data?.task_config || [])
              ? currentTask?.task_status == 11
                ? t('promotion.pending')
                : t('referral.claim')
              : t('promo.details')}
          </LimitClickWrapper>
        </div>
      </PopBg>
    </>
  );
}
