import Icon, { IconName } from '../icon';
import { getTemplateId } from '../../utils/helper';

const HeaderBack = ({
  children,
  className = '',
  titleClassName = '',
  isShowbackIcon = true,
  title = '',
  onClick = () => {},
}) => {
  return (
    <>
      <header
        className={`w-full ${getTemplateId() == 'd-template003' ? 'min-h-[.96rem] h-[.96rem]' : 'min-h-[.9rem] h-[.9rem]'}`}
      >
        <div className="header-box">
          <div
            className={`relative ${getTemplateId() == 'd-template003' ? 'h-[.96rem]' : 'h-[.9rem]'} w-full border-b border-[var(--colour-6)] flex items-center justify-center bg-[var(--colour-35)] ${className}`}
          >
            {isShowbackIcon ? (
              <>
                {getTemplateId() == 'd-template003' ? (
                  <>
                    <div
                      className="w-[.6rem] h-[.3rem] absolute left-[.24rem] abs-y-center z-[20] flex items-center cursor-pointer justify-center"
                      onClick={onClick}
                    >
                      <div className="w-[.18rem] h-[.18rem] text-[var(--colour-11)]">
                        <Icon name={IconName.BtnBack} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="w-[.8rem] h-[.8rem] absolute left-[.24rem] abs-y-center z-[20] flex items-center cursor-pointer"
                      onClick={onClick}
                    >
                      <div className="w-[.32rem] h-[.32rem] text-[var(--colour-11)] rotate-180">
                        <Icon name={IconName.BannerNext2} />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            <div className={`text-[.28rem] font-[700] text-[var(--colour-10)] ${titleClassName}`}>{title}</div>

            {children}
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderBack;
