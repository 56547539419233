import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
import { getShowMoney } from '../../../../../../utils/helper';
import { banfenb, getImg } from './helper';

function Privilege({ vipLevel, data }) {
  const { t } = useTranslation();
  const [vipLevelList, setVipLevelList] = useState(data);
  const [fee, setFee] = useState(0);
  const [daily, setDaily] = useState(0);
  const [single, setSingle] = useState(0);
  const [dailyLimit, setDailyLimit] = useState(0);
  const [bouns, setBouns] = useState(0);
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
    }),
    shallowEqual,
  );

  useEffect(() => {
    setVipLevelList(data);
    let myData = JSON.parse(JSON.stringify(data.vip_level_config));
    setFee(myData.withdraw_fee);
    setDaily(myData.daily_withdraw_frequency);
    setSingle(getShowMoney(myData.each_withdraw_max));
    setDailyLimit(getShowMoney(myData.daily_withdraw_max));

    let v = Object.values(myData.rakeback);
    v.sort((a, b) => {
      return b - a;
    });
    setBouns(v[0]);
  }, [vipLevel]);

  return (
    //下面的手机宽度99% 是因为手机模式下要显示边框
    <div className="items-center flex mt-[.2rem]  flex-col bg-[var(--colour-34)] border-2 border-[var(--colour-6)] rounded-normal   pb-[.6rem]  mb-[.6rem] w-[99%]">
      <div className="flex justify-center">
        <div className="flex mt-[.2rem]  relative">
          <img src={getImg(vipLevel)} alt="" className=" w-[1.2rem]" />
        </div>
      </div>

      <div
        className={`flex font-[500] flex-col text-[.32rem]  text-[var(--colour-26)] justify-center items-center text-center  my-[.3rem] `}
      >
        {t('vip.memberpri')}
      </div>

      <div className="flex flex-row w-[6.86rem] justify-center mt-[.1rem]  ">
        <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-41)] `}>{t('vip.withdrawFee')}&nbsp;</div>
        <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-40)] `}>{banfenb(fee)}%</div>
      </div>
      <div className="flex flex-row w-[6.86rem] justify-center mt-[.2rem]  ">
        <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-41)] `}>{t('vip.dailywith')}&nbsp;</div>
        <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-40)] `}>{daily}</div>
      </div>
      <div className="flex flex-row w-[6.86rem] justify-center mt-[.2rem]  ">
        <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-41)] `}>{t('vip.single')}&nbsp;</div>
        <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-40)] `}>{single}</div>
      </div>

      <div className="flex flex-row w-[6.86rem] justify-center mt-[.2rem]  ">
        <div className={`flex font-[400] text-[.28rem]  text-[var(--colour-41)] `}>{t('vip.daily')}&nbsp;</div>
        <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-40)] `}>{dailyLimit}</div>
      </div>

      {/* {websiteInitConfig?.system_config?.switch_rakeback == '1' ? (
        <div className="flex flex-row w-[6.86rem] justify-center mt-[.2rem]  ">
          <div className={`flex font-[400] text-[.32rem]  text-[var(--colour-41)] `}>{t('vip.betBouns')}&nbsp;</div>
          <div className={`flex font-[400]  text-[.32rem]  text-[var(--colour-40)] `}>{banfenb(bouns) || 0}%</div>
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
}

export default Privilege;
