import Icon, { IconName } from '../../../icon';
import { getShowMoney, openHeaderGiftMoney } from '../../../../utils/helper';
import Close from '../../../close2';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAllowBodyScroll } from '../../../../redux/reducers/appSlice';
import { useEffect, useRef, useState } from 'react';
import { getCenterPosition } from '../../../../hooks/useMinimizeAnimation';
import Backdrop from '../../../backdrop';

const PwaReward = ({ handleClose = () => {}, content = null }) => {
  const ref = useRef(null);
  const [hasExecuted, setHasExecuted] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hanldeRefurbishBalance } = useHandleLogic();

  const close = () => {
    hanldeRefurbishBalance();
    handleClose();
    dispatch(setAllowBodyScroll(true));
  };

  const handleCloseReward = () => {
    if (hasExecuted) return;

    const target = ref.current;
    if (target && content?.currency == 'gift_money') {
      const PointData = getCenterPosition(target, true);
      openHeaderGiftMoney(content?.amount, [PointData], close);
    } else {
      close();
    }

    setHasExecuted(true);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Backdrop id="pwa-reward_OutSide" onClick={handleCloseReward}>
      <div className="w-[6.86rem] h-[3.88rem] rounded-[.16rem] bg-[--colour-35] px-[.32rem] relative">
        <Close
          handleClose={handleCloseReward}
          className="!w-[.4rem] !h-[.4rem] !rounded-[.16rem] bg-[var(--colour-45)] absolute right-[.32rem] top-[.16rem]"
          className2="!w-[.16rem] !h-[.16rem]"
        />

        <div className="h-[.74rem] w-full border-b border-[var(--colour-6)] flex items-center text-[.32rem] font-[700] text-[var(--colour-48)]">
          {t('home.pwareward')}
        </div>

        <div className="w-full h-[.84rem] mt-[.64rem] rounded-[.12rem] border-[.01rem] border-[var(--colour-6)] bg-[var(--colour-34)] flex items-center justify-center gap-[.16rem]">
          <div className="w-[.78rem] h-[.7548rem]">
            <Icon name={IconName.Refer} />
          </div>

          <div className="text-[.32rem] font-[500] text-[var(--colour-38)]">{getShowMoney(content?.amount)}</div>
        </div>

        <div
          className="w-[3.02rem] h-[.68rem] mx-auto rounded-[.16rem] bg-[var(--colour-1)] mt-[.56rem] flex items-center justify-center active:scale-[.95] transition-all select-none text-[.32rem] font-[600] text-[var(--colour-2)]"
          onClick={handleCloseReward}
          ref={ref}
        >
          {t('home.gotit')}
        </div>
      </div>
    </Backdrop>
  );
};

export default PwaReward;
