import { initializeApp } from 'firebase/app';

// Initialize Firebase
window.$analytics = null;

export const initFirebaseAnalytics = (firebaseConfig) => {
  if (window.$analytics) {
    return window.$analytics;
  } else {
    window.$analytics = initializeApp(firebaseConfig);
    // $analytics = getAnalytics(app);
    return window.$analytics;
  }
};
