import { toast } from 'react-toastify';
import { getGeetestLang } from '../utils/helper';

export default function handleInitGeetest4(geetestCaptchaId, geetestRiskType, setRequesting = () => {}) {
  return new Promise((resolve) => {
    if (geetestCaptchaId) {
      window.initGeetest4(
        {
          captchaId: geetestCaptchaId,
          product: 'bind',
          language: getGeetestLang(),
          riskType: geetestRiskType,
        },
        function (captchaObj) {
          captchaObj
            .onReady(function () {
              captchaObj.showCaptcha(); //显示验证码
              //验证码ready之后才能调用showCaptcha方法显示验证码
            })
            .onSuccess(function () {
              const result = captchaObj.getValidate();
              //your code,结合您的业务逻辑重置验证码
              captchaObj.reset();
              delete result.captcha_id;
              resolve(result);
            })
            .onError(function (error) {
              setRequesting(false);
              toast.error(error, {
                containerId: 'global',
              });
              resolve({});
            })
            .onClose(function () {
              setRequesting(false);
            })
            .onFail(function (failObj) {
              //  对错误信息做一些统计
              setRequesting(false);
            });
        },
      );
    } else {
      resolve({});
    }
  });
}
