import { useTranslation } from 'react-i18next';
import { getLangFromPath } from '../../../../../../utils/localePath';

const BounsTitle = ({ linebg, textColor }) => {
  const { t } = useTranslation();
  const { langKey } = getLangFromPath(window.location.pathname);

  return (
    <>
      <div className="w-full flex  my-[.32rem]">
        <div className={`  mt-[.2rem]  w-[2.14rem] h-[.02rem] `} style={{ background: linebg }}></div>
        {langKey == 'en' ? (
          <div className={`px-[14px] font-[500]  text-[.28rem] ${textColor}`}>{t('vip.VIPBONUS')}</div>
        ) : (
          <div className={`px-[4px] font-[500]  text-[.28rem] ${textColor}`}>{t('vip.VIPBONUS')}</div>
        )}

        <div className={`  mt-[.2rem]  w-[2.14rem] h-[.02rem] `} style={{ background: linebg }}></div>
      </div>
    </>
  );
};

export default BounsTitle;
