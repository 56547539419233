import { useEffect, useRef } from 'react';
import Tips from '../../assets/img/tips.png';
import PtTips from '../../assets/img/ptdownloadTips.jpg';
import { getLangFromPath } from '../../utils/localePath';
import { useSpring, animated } from 'react-spring';
import { eventEmitter, EmitterEventTypes } from '../../utils/eventEmitter';

export default function IOSSafariGuide({ handleClose = () => {} }) {
  const ref = useRef(null);
  const initialPosition = { transform: 'translateY(100%)' };
  const { langKey } = getLangFromPath(window.location.pathname);

  const [styles, setSpring] = useSpring(() => ({
    from: initialPosition,
    to: { transform: 'translateY(0%)' },
    config: { duration: 150 },
  }));

  const handleAnimationEnd = () => {
    handleClose();
  };

  const startClosingAnimation = () => {
    setSpring({
      from: { transform: 'translateY(0%)' },
      to: initialPosition,
      onRest: handleAnimationEnd,
      config: { duration: 150 },
    });
  };

  const resize = () => {
    if (ref.current) {
      ref.current.style.height = `${window.innerHeight}px`;
    }
  };

  useEffect(() => {
    resize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      resize();
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const TipsImg = (lang) => {
    const imgSrc = {
      en: Tips,
      pt: PtTips,
      default: Tips,
    };

    const src = imgSrc[lang] || imgSrc.default;

    return <img src={src} className="w-full" />;
  };

  const handleInnerClick = (event) => {
    event.stopPropagation();
  };
  return (
    <>
      <div className="adaptive-width relative overflow-hidden z-[999999]" onClick={startClosingAnimation} ref={ref}>
        <animated.div
          style={{ ...styles, position: 'fixed', bottom: '0', left: '50%', translateX: '-50%' }}
          onClick={handleInnerClick}
          className="w-[7.5rem]"
        >
          {TipsImg(langKey)}
        </animated.div>
      </div>
    </>
  );
}
