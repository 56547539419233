import NTemplate001 from './normal/template001';
import DTemplate003 from './design/template003';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

export default function Index({
  handleClosePagingButton = () => {},
  showContent: _showContent = 'guide',
  handleClose = () => {},
  data: _data = null,
  isShowCloseBtn = true,
  isSinglePopup = true,
  updateList = () => {},
}) {
  const { showStyle } = useSelector(
    (state) => ({
      showStyle: get(state, 'app.activityData.activity_promotional_cashback.list[0].show_style'),
    }),
    shallowEqual,
  );
  return showStyle == 2 ? (
    <DTemplate003
      handleClosePagingButton={handleClosePagingButton}
      showContent={_showContent}
      handleClose={handleClose}
      data={_data}
      isShowCloseBtn={isShowCloseBtn}
      isSinglePopup={isSinglePopup}
      updateList={updateList}
    />
  ) : (
    <NTemplate001
      handleClosePagingButton={handleClosePagingButton}
      showContent={_showContent}
      handleClose={handleClose}
      data={_data}
      isShowCloseBtn={isShowCloseBtn}
      isSinglePopup={isSinglePopup}
      updateList={updateList}
    />
  );
}
