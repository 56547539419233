import Close from '../../close';
import LimitClickWrapper from '../../limitClickWrapper';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const Confirm = ({
  handleClose,
  handleCancel,
  handleConfirm,
  children,
  disableClose,
  title = 'Tips',
  cancelText = 'Cancel',
  confirmText = 'OK',
  isConfirmBtnPrimary = true, //确定按钮是否首要按钮
}) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-[var(--colour-35)] text-center relative overflow-hidden w-[6.86rem] rounded-normal border border-[var(--colour-6)]">
        <div className="p-[.32rem] pb-[.6rem] h-[100%]">
          <div className="flex flex-row justify-between items-center">
            <div className={cn('text-[.4rem] font-semibold text-[var(--colour-48)]')}>{title}</div>
            {disableClose ? <></> : <Close onClick={handleClose} className="bg-[var(--colour-45)] rounded-normal" />}
          </div>
          <div className="w-full bg-[var(--colour-6)] h-[1px] mt-[.32rem] mb-[.6rem]"></div>
          <div className="text-[var(--colour-40)] text-[.32rem]">{children}</div>
          <div className="flex flex-row justify-center text-[var(--colour-2)]">
            {handleCancel ? (
              <LimitClickWrapper
                className={`mt-[.64rem] flex flex-row w-[2.88rem] h-[.88rem] items-center cursor-pointer justify-center mx-[.1rem] active:scale-[.95] transition-all select-none rounded-normal border border-1 border-[var(--colour-1)] ${
                  isConfirmBtnPrimary
                    ? 'bg-[var(--colour-5)] text-[var(--colour-3)]'
                    : 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                }`}
                onClick={handleCancel}
              >
                <div className="font-[700] text-[.32rem]">{cancelText}</div>
              </LimitClickWrapper>
            ) : (
              <></>
            )}

            {handleConfirm ? (
              <LimitClickWrapper
                className={`mt-[.64rem] flex flex-row w-[2.88rem] h-[.88rem] items-center text-[var(--colour-2)] cursor-pointer justify-center mx-[.1rem] active:scale-[.95] transition-all select-none rounded-normal  border border-1 border-[var(--colour-1)] ${
                  isConfirmBtnPrimary
                    ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                    : 'bg-[var(--colour-5)] text-[var(--colour-3)]'
                }`}
                onClick={handleConfirm}
              >
                <div className="font-[700] text-[.32rem]">{confirmText}</div>
              </LimitClickWrapper>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
