import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import DatePicker from 'react-datepicker';
import Icon, { IconName } from './icon';
import moment from 'moment';
import cn from 'classnames';
import useOnclickOutside from 'react-cool-onclickoutside';

const MonthYearPicker = ({
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  increaseYear,
  decreaseYear,
  date,
  changeMonth,
  monthYearPickerClassName = 'bg-[var(--colour-35)]',
}) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return (
    <div
      className={`month-year-picker scrollbar-none px-[.8rem] pt-[.4rem] font-normal text-[.32rem] border border-[var(--colour-6)] ${monthYearPickerClassName}`}
    >
      <div className="flex flex-row items-center text-[var(--colour-40)] justify-between">
        <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
          <span className="w-[.12rem] h-[.2rem] block rotate-180 text-[var(--colour-11)]">
            <Icon name={IconName.RightArrow} />
          </span>
        </button>
        <div>{moment(date).year()}</div>
        <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
          <span className="w-[.12rem] h-[.2rem] block text-[var(--colour-11)]">
            <Icon name={IconName.RightArrow} />
          </span>
        </button>
      </div>
      <div className="flex flex-wrap justify-between">
        {months.map((item, index) => (
          <div
            onClick={() => {
              changeMonth(index);
            }}
            className={cn('cursor-pointer rounded-normal month-box', {
              selected: moment(date).month() == index,
            })}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

const DatePickerBox = ({
  selected,
  onChange,
  customInput,
  onCalendarClose,
  onCalendarOpen,
  filterDate = () => true,
}) => {
  const dispatch = useDispatch();

  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const ref = useOnclickOutside(() => {
    setShowMonthYearPicker(false);
  });

  return (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      popperClassName={`date-picker-box border-[var(--colour-6)] select-none bg-[var(--colour-35)]`}
      selected={selected}
      onChange={onChange}
      customInput={customInput}
      onCalendarClose={onCalendarClose}
      onCalendarOpen={onCalendarOpen}
      filterDate={filterDate}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
        increaseYear,
        decreaseYear,
      }) => (
        <div className="flex flex-row items-center justify-between text-[var(--colour-40)]   text-[.36rem] px-[.36rem] relative">
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <span className={`w-[.12rem] h-[.2rem] block rotate-180 text-[var(--colour-11)]`}>
              <Icon name={IconName.RightArrow} />
            </span>
          </button>
          <div
            className={`cursor-pointer text-[var(--colour-40)]`}
            onClick={() => {
              setShowMonthYearPicker(showMonthYearPicker ? false : true);
            }}
          >
            {months[moment(date).month()]}
            &nbsp;
            {moment(date).year()}
          </div>
          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <span className={`w-[.12rem] h-[.2rem] block text-[var(--colour-11)]`}>
              <Icon name={IconName.RightArrow} />
            </span>
          </button>
          {showMonthYearPicker ? (
            <div className="absolute abs-x-center top-[.3rem] transition z-[10]" ref={ref}>
              <MonthYearPicker
                prevYearButtonDisabled={prevYearButtonDisabled}
                nextYearButtonDisabled={nextYearButtonDisabled}
                increaseYear={increaseYear}
                decreaseYear={decreaseYear}
                date={date}
                changeMonth={(month) => {
                  changeMonth(month);
                  setShowMonthYearPicker(false);
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    />
  );
};

export default DatePickerBox;
