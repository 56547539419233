import cn from 'classnames';
import Close from '../close';
import { useState } from 'react';
import Deposit from './component/deposit';
import WithdrawBRL from './component/withdrawBRL';
import WithdrawJPY from './component/withdrawJPY';
import WithdrawCOP from './component/withdrawCOP';
import WithdrawPHP from './component/withdrawPHP';
import SwitchWrapper from '../switchWrapper';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import Icon, { IconName } from '../icon';
import { get } from 'lodash';

const WithdrawComponent = ({ ...otherProps }) => {
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
    }),
    shallowEqual,
  );
  const currencyCode = websiteInitConfig.website_info.currency_code;

  const WithdrawComponents = {
    BRL: <WithdrawBRL {...otherProps} />,
    PHP: <WithdrawPHP {...otherProps} />,
    JPY: <WithdrawJPY {...otherProps} />,
    COP: <WithdrawCOP {...otherProps} />,
  };

  // return WithdrawComponents['BRL'];
  return WithdrawComponents[currencyCode] || null;
};

const Wallet = ({ params = {}, handleClose = () => {}, OpenGiftMoneyDialog = () => {}, isFullscreen = false }) => {
  const { t } = useTranslation();
  const [giftShow, setGiftShow] = useState(false);
  const [pageType, setPageType] = useState(params.withdraw_enable != 0 ? params.type : '1');

  const {} = useSelector((state) => ({}), shallowEqual);

  const handleGiftMoneyChange = (num) => {
    if (num == 1) {
      setGiftShow(true);
    } else {
      setGiftShow(false);
    }
  };

  return (
    <>
      <div
        className={cn('px-[.32rem] flex justify-between text-center relative', {
          '': params.currency_code != 'BRL',
          'w-[7.2rem] h-[auto] min-h-[90vh] max-h-[90vh] bg-[var(--colour-35)]': !isFullscreen,
          'h-full': isFullscreen,
        })}
      >
        <div className={`w-full flex flex-col`}>
          <div
            className={`flex flex-row justify-between items-center border-b border-[var(--colour-6)] min-w-[6.56rem]`}
          >
            <div className="flex justify-center  px-[0] space-x-[.06rem] flex-1">
              <SwitchWrapper
                className="h-[1.12rem]  flex-1 flex items-center justify-center cursor-pointer w-auto font-[700] relative text-[0.32rem]"
                chooseClassName="text-[var(--colour-13)]"
                notChooseClassName="text-[var(--colour-14)]"
                choose={pageType == '1'}
                onClick={() => {
                  setPageType('1');
                  AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_Depositpage);
                }}
              >
                {t('deposit-withdraw.deposit')}
                {pageType == '1' ? (
                  <div className="absolute left-[0] bottom-[0] w-full  h-[.06rem] bg-[var(--colour-1)]"></div>
                ) : (
                  <></>
                )}
              </SwitchWrapper>
              {params.withdraw_enable != 0 ? (
                <>
                  <div className="h-[.48rem]  w-[.024rem]  m-[.32rem_.12rem_0_.12rem] bg-[var(--colour-6)]"></div>
                  <SwitchWrapper
                    className="h-[1.12rem] flex-1 flex items-center  justify-center cursor-pointer w-auto font-[700] relative"
                    chooseClassName="text-[var(--colour-13)]"
                    notChooseClassName="text-[var(--colour-14)]"
                    choose={['2', '3'].includes(String(pageType))}
                    onClick={() => {
                      const type = params.type;
                      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_WithdrawPage);
                      setPageType(['1', '2'].includes(String(type)) ? '2' : type == '3' ? '3' : '');
                    }}
                  >
                    {t('deposit-withdraw.withdraw')}
                    {['2', '3'].includes(String(pageType)) ? (
                      <div className="absolute left-[0] bottom-[0] w-full  h-[.06rem] bg-[var(--colour-1)]"></div>
                    ) : (
                      <></>
                    )}
                  </SwitchWrapper>
                  <div className="flex items-center ">
                    {giftShow && (
                      <div
                        className="  w-[.64rem] h-[.64rem] rounded-normal bg-[var(--colour-25)] bubble flex items-center justify-center"
                        onClick={() => {
                          OpenGiftMoneyDialog();
                        }}
                      >
                        <div className="  w-[.4rem] h-[.44rem] z-[10] text-[var(--colour-43)]">
                          <Icon name={IconName.GiftBox}></Icon>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex-1"></div>
              )}
            </div>
            <div className=" w-[.6rem]"></div>
            <div className="  w-[.72rem] h-[.72rem] flex justify-center items-center rounded-[.08rem] bg-[var(--colour-45)]">
              <Close
                className="text-[var(--colour-46)]"
                onClick={() => {
                  if (pageType == '1') {
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_DepositPage_CloseButton);
                  }
                  handleClose();
                }}
              />
            </div>
          </div>
          <div className="flex-1 overflow-y-auto scrollbar-none auto-scrollable ">
            {pageType == '1' ? (
              <Deposit handleGiftMoneyChange={handleGiftMoneyChange} params={params} isFullscreen={isFullscreen} />
            ) : (
              <WithdrawComponent
                withdrawConfigParams={params}
                OpenGiftMoneyDialog={OpenGiftMoneyDialog}
                handleGiftMoneyChange={handleGiftMoneyChange}
                currency={pageType == '2' ? '1' : pageType == '3' ? '2' : ''}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
