import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { setAutoScrollToTagGameKey } from '../../redux/reducers/appSlice';
import { useEffect, useRef, useState } from 'react';
import useNumberTransition from '../../hooks/useNumberTransition';
import BigNumber from 'bignumber.js';
import { GetJackpotInfo } from '../../api';
import { cmsImgUrl, getTemplateId, hasNestedProperty, isEnvDevelopment } from '../../utils/helper';
import useGameInfo from '../../hooks/useGameInfo';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../utils/localePath';
import { useVisibilityChange } from '../../hooks/useVisibilityChange';
import useLangServerConfig from '../../hooks/useLangServerConfig';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const SingleMoneyText = ({ char, numberStyle, isNumberDefaultStyle = true, customFontStyle = null }) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  const getCharNum = (char) => {
    switch (char) {
      case '1':
        return 1;
      case '2':
        return 2;
      case '3':
        return 3;
      case '4':
        return 4;
      case '5':
        return 5;
      case '6':
        return 6;
      case '7':
        return 7;
      case '8':
        return 8;
      case '9':
        return 9;
      case '0':
        return 10;
      case ',':
        return 11;
      case '.':
        return 12;
      default:
        return '';
    }
  };

  return (
    <div
      className={cn('h-[.8rem] flex items-center justify-center', {
        [`jackpot-number-mobile-style_${numberStyle}-char_${getCharNum(char)} bg-[length:4rem_6.4rem]`]:
          isNumberDefaultStyle,
        'w-[.2rem]': ['.', ','].includes(char),
        'w-[.36rem]': !['.', ','].includes(char),
      })}
    >
      <span className={cn('')} style={isNumberDefaultStyle ? { display: 'none' } : customFontStyle}>
        {char}
      </span>
    </div>
  );
};

const Jackpot = ({ isDebug = false, handleClickGameCategory = () => {} }) => {
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const { currency_offset, websiteInitData, isSportEnabled, currency_code } = useSelector(
    (state) => ({
      currency_offset: state?.app?.websiteInitConfig?.system_config?.currency_offset,
      currency_code: state?.app?.websiteInitConfig?.website_info?.currency_code,
      websiteInitData: state.app.websiteInitData,
      isSportEnabled: state.app.isSportEnabled,
    }),
    shallowEqual,
  );

  const { handleClickGameItem } = useGameInfo();
  const { visible } = useVisibilityChange();

  const amouuntInterval = useRef(null);

  const defaultAmountRefreshDelay = 4000;

  const [jackpotConfig, setJackpotConfig] = useState(null);
  const [isBgDefaultStyle, setIsBgDefaultStyle] = useState(true);
  const [isNumberDefaultStyle, setIsNumberDefaultStyle] = useState(true);
  const [customBgImgUrl, setCustomBgImgUrl] = useState('');
  const [customNumberConfig, setCustomNumberConfig] = useState(null);
  const [moneyAmount, setMoneyAmount] = useState(0);
  const [moneyAmouuntList, setMoneyAmouuntList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [amountRefreshDelay, setAmountRefreshDelay] = useState(defaultAmountRefreshDelay);
  const [linkConfig, setLinkConfig] = useState(null);
  const [isCanClick, setIsCanClick] = useState(false);

  const [defaultBgStyle, setDefaultBgStyle] = useState('');
  const [defaultNumberStyle, setDefaultNumberStyle] = useState(1);
  const [customFontStyle, setCustomFontStyle] = useState(null);
  const [decimalPlace, setDecimalPlace] = useState(2);
  const [homeTagSearchConfig, setHomeTagSearchConfig] = useState([]);
  const { getServerLangText } = useLangServerConfig();
  useEffect(() => {
    const list = websiteInitData?.template || [];

    if (list.length > 0) {
      const _data = list.find((item) => item.module == 'Jackpot');

      if (_data) {
        setJackpotConfig(_data.config);
      }

      const _data2 = list.find((item) => item.module == 'HomeTagSearch');
      if (_data2) {
        setHomeTagSearchConfig(_data2.config);
      }
    }
  }, [websiteInitData]);

  useEffect(() => {
    if (jackpotConfig) {
      console.log('jackpotConfig', jackpotConfig);

      setIsBgDefaultStyle(jackpotConfig.background_img_type == 'default');
      setIsNumberDefaultStyle(jackpotConfig.amount_type == 'default');

      if (jackpotConfig.background_img_type == 'default') {
        const found = jackpotConfig.default_background_img_style.find((item) => item.selected == 1);
        if (found) {
          setDefaultBgStyle(found.style_id);
        }
      } else {
        setCustomBgImgUrl(cmsImgUrl(jackpotConfig.custom_background_img_style.img_url));
      }

      if (jackpotConfig.amount_type == 'default') {
        const found = jackpotConfig.default_amount_style.find((item) => item.selected == 1);
        setDefaultNumberStyle(found ? found.style_id : 1);
      } else {
        setCustomNumberConfig(jackpotConfig.custom_amount_style);
      }

      // setLinkConfig(_testConfig);
      setLinkConfig(jackpotConfig?.link || null);
    }
  }, [jackpotConfig]);

  const updateList = () => {
    GetJackpotInfo().then((res) => {
      if (res.code == 0) {
        if (hasNestedProperty(res, 'data.number_list')) {
          setMoneyAmouuntList(res.data.number_list);
        }

        if (hasNestedProperty(res, 'data.speed')) {
          setAmountRefreshDelay(res.data.speed);
        }

        if (hasNestedProperty(res, 'data.decimal_places')) {
          setDecimalPlace(res.data.decimal_places);
        }
      }
    });
  };

  useEffect(() => {
    if (visible) {
      updateList();
    } else {
      clearInterval(amouuntInterval.current);
    }
  }, [visible]);

  const findClosestTimestamp = (timestamps = []) => {
    const now = Date.now();

    let closestIndex = -1;
    let timeDifference = Infinity;
    let showingIndex = -1;

    for (let i = 0; i < timestamps.length; i++) {
      const timestamp = timestamps[i];

      if (timestamp.time_stamp * 1000 > now) {
        const diff = timestamp.time_stamp * 1000 - now;

        closestIndex = i;
        timeDifference = diff;

        if (timestamps[i - 1]) {
          showingIndex = i - 1;
        }
        break;
      }
    }

    return {
      closestIndex,
      timeDifference,
      showingIndex,
    };
  };

  useEffect(() => {
    const { closestIndex, timeDifference, showingIndex } = findClosestTimestamp(moneyAmouuntList);
    // console.log('closestIndex:', closestIndex, 'showingIndex:', showingIndex, 'timeDifference:', timeDifference);

    const updateAmount = () => {
      setCurrentIndex((prev) => {
        if (moneyAmouuntList[prev + 1]) {
          return prev + 1;
        } else {
          clearInterval(amouuntInterval.current);
          updateList();
          return prev;
        }
      });
    };

    const startShow = () => {
      if (showingIndex >= 0) {
        setCurrentIndex(showingIndex);
      }

      amouuntInterval.current = setTimeout(() => {
        setCurrentIndex(closestIndex);

        amouuntInterval.current = setInterval(() => {
          updateAmount();
        }, amountRefreshDelay);
      }, timeDifference);
    };

    if (closestIndex >= 0) {
      startShow();
    } else {
      console.log('not found');
    }

    return () => {
      if (amouuntInterval.current) {
        clearInterval(amouuntInterval.current);
        amouuntInterval.current = null;
      }
    };
  }, [moneyAmouuntList]);

  useEffect(() => {
    if (moneyAmouuntList[currentIndex]) {
      setMoneyAmount(moneyAmouuntList[currentIndex].value);
    }
  }, [currentIndex]);

  const { currentValue } = useNumberTransition({
    number: Number(moneyAmount),
    duration: Number(amountRefreshDelay) - 1500,
    decimalPlace,
    // isShowNegative: true,
  });

  useEffect(() => {
    if (customNumberConfig) {
      // const fontFamily = 'font-jackpot-1';
      const fontFamily = customNumberConfig?.font || '';
      const color = customNumberConfig?.font_colour || '';
      const fontSize = customNumberConfig?.font_size || '';
      const strokeThickness = customNumberConfig?.stroke_thickness || '';
      const strokeColor = customNumberConfig?.stroke_colour || '';

      const _customFontStyle = {
        fontFamily: fontFamily,
        color: color,
        fontSize: fontSize,
        textShadow: `
          -${strokeThickness} -${strokeThickness} 0 ${strokeColor},
          ${strokeThickness} -${strokeThickness} 0 ${strokeColor},
          -${strokeThickness}  ${strokeThickness} 0 ${strokeColor},
          ${strokeThickness}  ${strokeThickness} 0 ${strokeColor}
        `,
      };

      console.log(_customFontStyle);

      setCustomFontStyle(_customFontStyle);
    }
  }, [customNumberConfig]);

  useEffect(() => {
    if (linkConfig) {
      const templateId = getTemplateId();

      if (linkConfig.link_type == 'game') {
        setIsCanClick(true);
      } else if (linkConfig.link_type == 'game_category') {
        // 长盘可点
        if (templateId.startsWith('n-')) {
          const id = linkConfig?.game_category?.category_id;
          const found = homeTagSearchConfig.find((item) => item.category_id == id);

          console.log('found', found, 'id', id, 'homeTagSearchConfig', homeTagSearchConfig);

          setIsCanClick(found ? true : false);
        } else {
          setIsCanClick(templateId == 'd-template003' ? true : false);
        }
      } else if (linkConfig.link_type == 'game_type') {
        // 短盘1,2可点
        setIsCanClick(['d-template001', 'd-template002'].includes(templateId));
      } else {
        setIsCanClick(false);
      }
    }
  }, [linkConfig]);

  const onClickGame = (uuid) => {
    console.log('onClickGame1', uuid);
    handleClickGameItem(uuid);
  };

  const onClickGameCategory = (categoryId) => {
    console.log('onClickGameCategory', categoryId);
    handleClickGameCategory(categoryId);
    dispatch(setAutoScrollToTagGameKey(categoryId));
  };

  const onClickGameType = (typeId, source) => {
    console.log('onClickGameType', typeId, 'source', source);
    console.log('websiteInitData', websiteInitData);

    let sourceName = '';
    let img = '';

    if (websiteInitData?.template) {
      const columnData = websiteInitData?.template?.find((item) => item.module === 'ColumnData');
      if (columnData) {
        const found = columnData.config.find((item) => item.game_type_id === typeId);
        if (found) {
          sourceName = getServerLangText(found.lang_key);
          img = btoa(JSON.stringify({ icon_selected: found.icon_selected, icon_unselected: found.icon_unselected }));
          console.log('found', found);
        }
      } else {
        console.log('not found columnData', columnData);
      }
    }

    let url = `/gamelist?id=${typeId}&source=${source}&sourceName=${sourceName}&img=${img}`;

    if (typeId == '999') {
      if (isSportEnabled) {
        url = '/sport';
      } else {
        return;
      }
    }
    navigate(toLocalePath(url));
  };

  const handleClick = (linkConfig) => {
    if (linkConfig) {
      switch (linkConfig?.link_type) {
        case 'game': {
          onClickGame(linkConfig?.game?.uuid);
          break;
        }
        case 'game_category': {
          onClickGameCategory(linkConfig?.game_category?.category_id);
          break;
        }
        case 'game_type': {
          onClickGameType(linkConfig?.game_type?.type_id, linkConfig?.game_type?.source);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const _testConfig = {
    game: {
      uuid: '1005',
    },
    game_category: {
      category_id: '1001',
    },
    game_type: {
      source: '13',
      type_id: '1',
    },
    link_type: 'game_category',
  };

  const testStyle = {
    fontFamily: 'font-jackpot-2',
    color: '#999',
    fontSize: '90px',
    textShadow: `
      -3px -3px 0 green,
      3px -3px 0 green,
      -3px  3px 0 green,
      3px  3px 0 green
    `,
  };

  function formatNumberSpecial(numStr) {
    const dotIndex = numStr.indexOf('.');
    if (dotIndex === -1) {
      return numStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    const integerPart = numStr.slice(0, dotIndex);
    const decimalPart = numStr.slice(dotIndex + 1);

    const formattedIntegerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    return `${formattedIntegerPart},${decimalPart}`;
  }

  const formatNumberStr = (numberStr) => {
    if (currency_code == 'BRL') {
      return formatNumberSpecial(numberStr);
    } else {
      return numberStr;
    }
  };

  return (
    jackpotConfig &&
    moneyAmouuntList.length > 0 && (
      <div>
        <div
          className={cn('w-[7.18rem] h-[1.7rem] flex justify-center items-end bg-[length:100%_100%] pb-[.16rem]', {
            'cursor-pointer': isCanClick,
          })}
          style={{
            backgroundImage: isBgDefaultStyle
              ? defaultBgStyle == ''
                ? ''
                : `url(/static/img/jackpot/bg-${defaultBgStyle}.png)`
              : `url(${customBgImgUrl})`,
          }}
          onClick={isCanClick ? () => handleClick(linkConfig) : null}
        >
          <div className="flex items-center justify-center">
            <div className="h-[.9rem] w-full flex items-center justify-center ">
              {Number(currentValue) > 0 &&
                formatNumberStr(BigNumber(currentValue).toFixed(decimalPlace, BigNumber.ROUND_DOWN))
                  .split('')
                  .map((item, index) => {
                    return (
                      <SingleMoneyText
                        key={index}
                        char={item}
                        numberStyle={defaultNumberStyle}
                        isNumberDefaultStyle={isNumberDefaultStyle}
                        customFontStyle={customFontStyle}
                      />
                    );
                  })}
            </div>
          </div>
        </div>

        {isDebug && (
          <>
            <div>currentIndex: {currentIndex}</div>
            <div>moneyAmount: {moneyAmount}</div>
            <div>currentValue: {currentValue}</div>
          </>
        )}
      </div>
    )
  );
};

export default Jackpot;
