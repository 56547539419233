import { useTranslation } from 'react-i18next';
import useHandleLogic from '../../hooks/useHandleLogic';

const OnlineService = ({ click = () => {}, className = '' }) => {
  const { t } = useTranslation();
  const { handleClickManMade } = useHandleLogic();
  return (
    <div
      className={`font-[700] mt-[.24rem] text-[.28rem] leading-[.64rem] text-center text-[var(--colour-41)] cursor-pointer active:scale-[.95] transition-all select-none ${className}`}
      onClick={() => {
        handleClickManMade();
        click();
      }}
    >
      {t('home.service')}
    </div>
  );
};

export default OnlineService;
