import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useLogin from '../../../../hooks/useLogin';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import { TextPlaces, UserCache } from '../../../../utils/helper';
import { toast } from 'react-toastify';
import cn from 'classnames';
import Input from '../../../input';
import Icon, { IconName } from '../../../icon';
import InputErrorTips from '../../../inputErrorTips';
import CaptchaInput from '../captchaInput';
import LimitClickWrapper from '../../../limitClickWrapper';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import DialogModal from '../../../dialogModal';
import ForgotPassword from '../../forgotPassword';
import Enter2FACode from '../../../enter2FACode';
import { getBorderColor } from '../../helper/helper';

const ForgetPassword = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <span
      className={`cursor-pointer active:scale-[.95] transition-all select-none text-[var(--colour-41)] text-[.22rem] font-[600]`}
      onClick={onClick}
    >
      {t('login-signup.forgot')}?
    </span>
  );
};

const LoginButton = ({ requesting, onClick }) => {
  const { t } = useTranslation();

  return (
    <LimitClickWrapper
      className={cn(
        'text-[var(--colour-2)] bg-[var(--colour-1)] flex flex-row items-center justify-center w-full !h-[.88rem] mt-[.68rem] rounded-[.08rem]',
        {
          'opacity-[.7] pointer-events-none': requesting,
          'active:scale-[.95]  transition-all select-none cursor-pointer ': !requesting,
        },
      )}
      onClick={onClick}
    >
      {requesting ? (
        <div className="w-[.3rem] h-[.42rem] mr-[.2rem] text-[var(--colour-2)] anticon">
          <Icon name={IconName.loadingMp3} />
        </div>
      ) : null}
      <div className={`font-[600] text-[.28rem]`}>{t('login-signup.login_t003')}</div>
    </LimitClickWrapper>
  );
};

const RememberMe = ({ isRememberPwd, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <div
        className={`active:scale-[.95] transition-all select-none w-[.32rem] h-[.32rem] rounded-[.08rem] flex items-center justify-center border border-[var(--colour-6)] cursor-pointer ${
          isRememberPwd == '2' ? 'colour-79' : 'bg-[var(--colour-42)]'
        }`}
        onClick={onClick}
      >
        {isRememberPwd == '2' ? (
          <span className="text-[var(--colour-2)] w-[.24rem] h-[.16rem]">
            <Icon name={IconName.Checked2} />
          </span>
        ) : (
          <></>
        )}
      </div>
      <div className={`flex-1 text-left font-[600] text-[var(--colour-41)] ml-[.08rem] text-[.22rem]`}>
        {t('login-signup.remember_me')}
      </div>
    </div>
  );
};

const PasswordInput = ({
  handleInput2Focus,
  formData,
  setFormData,
  handleCheckInput2,
  setInputFocused1,
  inputFocused1,
  handleMouseDown,
  showInput2Error,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        btnClassName="!ml-[.08rem] !text-[var(--colour-37)]"
        hideEyeIconName={IconName.OpenEye}
        showEyeIconName={IconName.CloseEye}
        rightIconClassName={'!w-[.32rem] !h-[.32rem] !text-[var(--colour-37)]'}
        islightrightIcon={true}
        onFocus={handleInput2Focus}
        leftOuter={
          <div className="flex items-center">
            <span className={`block text-[var(--colour-37)] m-[0_.16rem_0_.32rem] w-[.32rem] h-[.32rem]`}>
              <span>
                <Icon name={IconName.Registerlock} />
              </span>
            </span>

            {/* <div className={`mr-[.04rem] text-[.2rem] w-[.1rem] font-[400] text-[var(--colour-25)]`}>
              {formData.password.length <= 0 ? <>*</> : ''}
            </div> */}
          </div>
        }
        type="password"
        value={formData.password}
        onChange={(ev) => {
          let val = TextPlaces(ev.target.value, 24);
          setFormData({ ...formData, password: val });
          handleCheckInput2(val);
        }}
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        placeholder={t('login-signup.fill_in_the_password')}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData.password, showInput2Error)}`}
        onBlur={() => {
          setInputFocused1(false);
        }}
        passWordBeforeInner={
          formData.password.length > 0 && inputFocused1 ? (
            <>
              <div
                onMouseDown={handleMouseDown}
                className="text-[var(--colour-37)] w-[.32rem] h-[.32rem]"
                onClick={() => {
                  setFormData({ ...formData, password: '' });
                }}
              >
                <Icon name={IconName.InputClear}></Icon>
              </div>
            </>
          ) : null
        }
        isShowClearIcon={false}
      />

      {showInput2Error && (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          showTips={showInput2Error}
          tipsType={2}
          className2="!w-[.24rem] !h-[.24rem]"
        />
      )}

      {!showInput2Error && <div className="w-full h-[.48rem]"></div>}
    </>
  );
};

const UserNameInput = ({
  handleInput1Blur,
  formData,
  setFormData,
  handleCheckEmailOrPhone,
  setInputFocused,
  inputFocused,
  handleMouseDown,
  showInput1Error,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        onBlur={handleInput1Blur}
        leftOuter={
          <div className="flex items-center">
            <span className={`block text-[var(--colour-37)] m-[0_.16rem_0_.32rem] w-[.32rem] h-[.32rem]`}>
              <span>
                <Icon name={IconName.Registeruser} />
              </span>
            </span>

            {/* <div className={`mr-[2px] text-[.2rem] w-[.1rem] font-[400] text-[var(--colour-25)]`}>
              {formData.account.length <= 0 ? <>*</> : ''}
            </div> */}
          </div>
        }
        type="text"
        value={formData.account}
        onChange={(ev) => {
          const val = ev.target.value;
          setFormData({ ...formData, account: val });
          handleCheckEmailOrPhone(val);
        }}
        inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
        placeholder={`${t('login-signup.phone')}/${t('login-signup.email')}/${t('login-signup.Username')}`}
        innerClassName={`!h-[.88rem] !p-[0_.24rem_0_0]`}
        topClassName={`overflow-hidden rounded-[.08rem] !bg-[var(--colour-22)] border ${getBorderColor(formData.account, showInput1Error)}`}
        onFocus={() => {
          setInputFocused(true);
        }}
        rightInner={
          formData.account.length > 0 && inputFocused ? (
            <>
              <div
                onMouseDown={handleMouseDown}
                className="w-[.32rem] h-[.32rem] text-[var(--colour-37)]"
                onClick={() => {
                  setFormData({ ...formData, account: '' });
                }}
              >
                <Icon name={IconName.InputClear}></Icon>
              </div>
            </>
          ) : null
        }
        isShowClearIcon={false}
      />

      {showInput1Error && (
        <InputErrorTips
          className={`!h-[.48rem] !my-[0] pt-[.04rem]!space-x-[.06rem] !text-[.24rem]`}
          className2="!w-[.24rem] !h-[.24rem]"
          showTips={showInput1Error}
          tipsType={1}
        />
      )}

      {!showInput1Error && <div className="w-full h-[.48rem]"></div>}
    </>
  );
};

const LoginForm = ({ handleClose, changeType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inputFocused, setInputFocused] = useState(false);
  const [inputFocused1, setInputFocused1] = useState(false);
  const { inviteCode, osType, advertisingId, loginStatus, dialogModalProp } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.inviteCode', ''),
      osType: get(state, 'user.osType', ''),
      advertisingId: get(state, 'user.advertisingId', ''),
      loginStatus: state.app.loginStatus,
      dialogModalProp: state.app.dialogModalProp,
    }),
    shallowEqual,
  );

  const {
    requesting,
    uuIdentity,
    isRememberPwd,
    loginType,
    captchaInitConfig,
    setRequesting,
    formData,
    is2FaOpen,
    setIs2FaOpen,
    isForgotPasswordOpen,
    setIsForgotPasswordOpen,
    handleLoginReq,
    setLoginType,
    handleAnyLoginReq,
    setFormData,
    setIsRememberPwd,
    handleReReqCaptcha,
    handleSubmit2FaCode,
  } = useLogin();

  const { handleClickManMade } = useHandleLogic();

  useEffect(() => {
    handleReReqCaptcha();
    setLoginType(UserCache.LoginMethodValue.Any);
  }, []);

  const [showInput1Error, setShowInput1Error] = useState(false);
  const [showInput2Error, setShowInput2Error] = useState(false);

  // 登录按钮事件
  const handleSubmit = () => {
    if (!formData.account) {
      return toast.info(t('1008008'), {
        containerId: 'global',
      });
    }
    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }

    if (captchaInitConfig?.user_login_validation_mode == 'image') {
      if (formData.cryCode === '') {
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    try {
      setRequesting(true);
      handleAnyLoginReq();
    } catch (error) {
      console.log('eerror', error);
      setRequesting(false);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleCheckInput2 = (value) => {
    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(value)) {
      setShowInput2Error(true);
    } else {
      setShowInput2Error(false);
    }
  };

  const handleCheckEmailOrPhone = (value) => {
    if (value == '') {
      setShowInput1Error(true);
    } else {
      setShowInput1Error(false);
    }
  };

  const handleInput1Blur = (evt) => {
    setInputFocused(false);
    handleCheckEmailOrPhone(evt.target.value);
  };

  const handleCheckForm = () => {
    let returnValue = true;
    if (formData.account == '') {
      setShowInput1Error(true);
      returnValue = false;
    } else {
      setShowInput1Error(false);
    }

    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(formData.password)) {
      setShowInput2Error(true);
      returnValue = false;
    } else {
      setShowInput2Error(false);
    }

    if (showInput1Error || showInput2Error) {
      returnValue = false;
    }

    return returnValue;
  };

  const handleInput2Focus = (evt) => {
    setInputFocused1(true);
    handleCheckInput2(evt.target.value);
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isForgotPasswordOpen}
        handleClose={() => {
          setIsForgotPasswordOpen(false);
        }}
      >
        <ForgotPassword
          handleClose={() => {
            setIsForgotPasswordOpen(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIs2FaOpen(false);
        }}
        style={{
          overlay: {
            zIndex: 999999,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={is2FaOpen}
      >
        <Enter2FACode
          handleClose={() => setIs2FaOpen(false)}
          onClickButton={handleSubmit2FaCode}
          uuIdentity={uuIdentity}
        />
      </DialogModal>

      <div className="mt-[.96rem]">
        <UserNameInput
          handleInput1Blur={handleInput1Blur}
          formData={formData}
          setFormData={setFormData}
          handleCheckEmailOrPhone={handleCheckEmailOrPhone}
          setInputFocused={setInputFocused}
          inputFocused={inputFocused}
          handleMouseDown={handleMouseDown}
          showInput1Error={showInput1Error}
        />

        <PasswordInput
          handleInput2Focus={handleInput2Focus}
          formData={formData}
          setFormData={setFormData}
          handleCheckInput2={handleCheckInput2}
          setInputFocused1={setInputFocused1}
          inputFocused1={inputFocused1}
          handleMouseDown={handleMouseDown}
          showInput2Error={showInput2Error}
        />

        {captchaInitConfig?.user_login_validation_mode ==
        'geetest' ? null : captchaInitConfig?.user_login_validation_mode == 'image' ? (
          <CaptchaInput
            iconClassName={`!w-[.44rem] !h-[.44rem] !m-[0_.12rem_0_.24rem] !text-[var(--colour-37)]`}
            innerClassName={`!h-[.88rem] !p-[0_.08rem_0_0]`}
            inputClassName={`!text-[.24rem] !font-[600] placeholder:!text-[.24rem] placeholder:!font-[600]`}
            topClassName={`!rounded-[.08rem] !bg-[var(--colour-22)] border !border-[var(--colour-6)]`}
            imgClassName="w-[1.7rem] ml-[.08rem]"
            captchaInfo={captchaInitConfig}
            value={formData.cryCode}
            onChange={(ev) => {
              setFormData({ ...formData, cryCode: ev.target.value });
            }}
            handleReReqCaptcha={handleReReqCaptcha}
          />
        ) : null}

        <div
          className={`flex items-center ${!showInput2Error && !captchaInitConfig ? 'mt-[.2rem]' : 'mt-[.28rem]'} justify-between`}
        >
          <RememberMe
            isRememberPwd={isRememberPwd}
            setIsRememberPwd={setIsRememberPwd}
            onClick={() => setIsRememberPwd(isRememberPwd == '1' ? '2' : '1')}
          />

          <ForgetPassword
            onClick={() => {
              setIsForgotPasswordOpen(true);
            }}
          />
        </div>

        <LoginButton
          requesting={requesting}
          onClick={() => {
            if (!handleCheckForm()) {
              return;
            }
            if (!requesting) {
              handleSubmit();
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_LogPage_Login);
            }
          }}
        />
      </div>
    </>
  );
};

export default LoginForm;
