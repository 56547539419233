import moment from 'moment';
import { get } from 'lodash';
import { Autoplay } from 'swiper/modules';
import Loading2 from '../../../loading2';
import Icon, { IconName } from '../../../icon';
import ScrollWrapper from '../../../scrollWrapper';
import { useTranslation } from 'react-i18next';
import TabBox, { makeTabKeys } from '../../../tabBox';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import LimitClickWrapper from '../../../limitClickWrapper';
import { useEffect, useState, useRef } from 'react';
import { isControllableSite } from '../../../../utils/helper';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setAnnouncementhidePopup, setAnnouncementhidePopupNextTime } from '../../../../redux/reducers/userSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';
import cn from 'classnames';
import NoData from '../../../noData';
import useLink from '../../../../hooks/useLink';

const Ntemplate001 = ({ handleClose, notificationData, isCloseAnnouncement }) => {
  let tabKeys = makeTabKeys(0);
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const { handleCmsLink } = useLink();
  const ActiveClassName =
    'relative w-[5.16rem] h-[1.36rem] bg-[var(--colour-52)] flex items-center pl-[.32rem] rounded-[.24rem_0_0_.24rem] text-[.32rem] font-[500] text-[var(--colour-1)] overflow-hidden cursor-pointer';

  const NoneactiveClassName =
    'relative w-[4.16rem] h-[1.36rem] rounded-[.24rem] text-[.32rem] font-[500] bg-[var(--colour-52)] pl-[.32rem] flex items-center cursor-pointer text-[var(--colour-14)]';

  const bgFillClassName = '';

  const _txtClassName = 'w-[3.2rem] whitespace-nowrap overflow-hidden text-ellipsis';

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  const activeIcon = (
    <div className="absolute right-[1.2rem] top-[50%] translate-y-[-50%] w-[.34rem] h-[.34rem]">
      <Icon name={IconName.Announcementarrow1} />
    </div>
  );

  const NoneActiveIcon = (
    <div className="absolute right-[.2rem] top-[50%] translate-y-[-50%] w-[.34rem] h-[.34rem]">
      <Icon name={IconName.Announcementarrow} />
    </div>
  );

  const dispatch = useDispatch();
  const { responsiveMode, announcementhidePopup, announcementhidePopupNextTime } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
      announcementhidePopup: state.user.announcementhidePopup || 0,
      announcementhidePopupNextTime: state.user.announcementhidePopupNextTime || '0',
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (notificationData && notificationData.length > 0) {
      tabKeys = makeTabKeys(notificationData.length);
      const data = notificationData.map((item, index) => {
        return {
          ...item,
          key: tabKeys[index],
          isShow: true,
          name: item.title || '',
          onClick: () => {
            onSwitchTab(index);
          },
          title: item.title || '',
          tabActiveEl: (
            <div className={ActiveClassName}>
              <div className={_txtClassName}>{item.title || ''}</div>
              <div className={bgFillClassName}></div>
              {activeIcon}
            </div>
          ),
          unusedActiveEl: (
            <div className={NoneactiveClassName}>
              <div className={_txtClassName}>{item.title || ''}</div>
              {NoneActiveIcon}
            </div>
          ),
          content: (
            <div>
              <ScrollWrapper
                className={`overflow-y-auto ${isControllableSite() ? 'h-[7.94rem]' : `bg-[var(--colour-35)] ${item.link && item.is_show_button == 1 ? 'h-[6.74rem]' : 'h-[8.06rem]'}`} rounded-[.24rem] px-[.24rem] select-none wrap-break-word`}
                mr={false}
              >
                <div ref={containerRef}>
                  <div dangerouslySetInnerHTML={{ __html: item.content || '' }}></div>
                </div>
              </ScrollWrapper>
            </div>
          ),
          mobileClick: () => {
            if (isCloseAnnouncement) {
              handleClose && handleClose();
            }
            handleCmsLink(item.open_mode, item.new_window, item.link);

            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_AnnouncementButton + (index + 1));
          },
          link: item.link || '',
        };
      });

      setNotificationList(data);
    }
  }, [notificationData]);

  useEffect(() => {
    if (notificationList.length > 0) {
      setPage(notificationList[0]?.key);
    }
  }, [notificationList]);

  const [page, setPage] = useState();

  const onSwipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
      scrollToTop();
    }
  };

  const onSwipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
      scrollToTop();
    }
  };

  const onSwitchTab = (n) => {
    scrollToTop();
    if (n == 0) {
      setPage(tabKeys[0]);
    } else {
      setPage(tabKeys[n]);
    }
  };

  const handleNoReminderToday = () => {
    const newChecked = announcementhidePopup === 0 ? 1 : 0;
    dispatch(setAnnouncementhidePopup(newChecked));
    if (newChecked === 1) {
      const endTime = moment(new Date()).add(1, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x');
      dispatch(setAnnouncementhidePopupNextTime(endTime));
    } else {
      dispatch(setAnnouncementhidePopupNextTime('0'));
    }
  };

  useEffect(() => {
    if (new Date().getTime() >= announcementhidePopupNextTime) {
      dispatch(setAnnouncementhidePopup(0));
      dispatch(setAnnouncementhidePopupNextTime('0'));
    }
  }, []);

  return (
    <>
      <div>
        <>
          <div
            className={cn('w-[6.86rem] rounded-[.24rem] relative select-none', {
              'bg-[var(--colour-52)] border border-[var(--colour-6)] h-[9.7rem] p-[.4rem_.24rem]':
                !isControllableSite(),
              'p-[0rem_.24rem]': isControllableSite(),
            })}
          >
            {notificationList && notificationList.length > 1 && !isControllableSite() ? (
              <>
                <div
                  className="w-[.76rem] h-[.76rem] bg-[var(--colour-1)] text-[var(--colour-2)] rounded-[50%] absolute top-[4.12rem] left-[-0.2rem] z-[10] flex items-center justify-center active:scale-[.95] transition-all"
                  onClick={() => {
                    onSwipePrev();
                  }}
                >
                  <div className="w-[.32rem] h-[.32rem] rotate-180">
                    <Icon name={IconName.BannerNext2} />
                  </div>
                </div>

                <div
                  className="w-[.76rem] h-[.76rem] bg-[var(--colour-1)] text-[var(--colour-2)] rounded-[50%]  absolute top-[4.12rem] right-[-0.2rem] z-[10] flex items-center justify-center active:scale-[.95] transition-all"
                  onClick={() => {
                    onSwipeNext();
                  }}
                >
                  <div className="w-[.32rem] h-[.32rem]">
                    <Icon name={IconName.BannerNext2} />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {notificationList && notificationList.length > 0 ? (
              <>
                {page ? (
                  <Swiper
                    slidesPerView={1}
                    ref={swiperRef}
                    loop={notificationList.length > 1}
                    spaceBetween={12}
                    autoplay={
                      isControllableSite()
                        ? {
                            delay: 3000,
                            disableOnInteraction: false,
                          }
                        : {}
                    }
                    modules={isControllableSite() ? [Autoplay] : []}
                    speed={2000}
                    onSlideChange={(data) => {
                      setCurrentIndex(data.realIndex);
                    }}
                  >
                    {notificationList &&
                      notificationList.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            {isControllableSite() ? (
                              <></>
                            ) : (
                              <div className="mb-[.4rem] text-[.32rem] font-[600] w-full whitespace-nowrap overflow-hidden text-ellipsis text-center text-[var(--colour-48)]">
                                {item.title}
                              </div>
                            )}
                            <div
                              className={cn({
                                'max-h-[7.94rem]': isControllableSite(),
                              })}
                            >
                              {item.content}
                            </div>

                            {item.link ? (
                              isControllableSite() ? (
                                <></>
                              ) : (
                                <>
                                  {item.is_show_button == 1 ? (
                                    <LimitClickWrapper
                                      className="mt-[.4rem] w-[6.38rem] h-[.92rem] rounded-normal flex items-center justify-center bg-[var(--colour-1)] text-[var(--colour-2)] font-[700] text-[.48rem] active:scale-[.95] transition-all cursor-pointer"
                                      onClick={() => {
                                        item.mobileClick();
                                      }}
                                    >
                                      <span className="w-[5rem] truncate text-center">{item?.button_text || ''}</span>
                                    </LimitClickWrapper>
                                  ) : null}
                                </>
                              )
                            ) : (
                              <></>
                            )}
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                ) : (
                  <>
                    <div className="w-full h-full flex items-center justify-center mt-[-0.8rem]">
                      <Loading2 />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <NoData className="w-full h-full"></NoData>
              </>
            )}
          </div>
        </>

        {isControllableSite() ? (
          <div
            className={cn(
              'active:scale-[.95] transition-all select-none w-[.6rem] h-[.6rem] border border-solid border-[#ffffff] rounded-[50%] flex justify-center items-center absolute left-[50%] bottom-[-0.8rem] translate-x-[-50%]',
            )}
            onClick={() => {
              handleClose();
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseAnnouncementButton);
            }}
          >
            <Icon name={IconName.Close} />
          </div>
        ) : (
          <div className="mt-[.4rem] flex items-center justify-center gap-[.18rem]">
            <div
              className={cn(
                'w-[.52rem] text-[var(--colour-2)] h-[.52rem] rounded-[.08rem] border border-[var(--colour-6)] cursor-pointer flex items-center justify-center',
                {
                  'bg-[var(--colour-1)]': announcementhidePopup !== 0,
                  'bg-[var(--colour-42)]': announcementhidePopup === 0,
                },
              )}
              onClick={handleNoReminderToday}
            >
              {announcementhidePopup === 0 ? (
                <></>
              ) : (
                <>
                  <div className="w-[.32rem] h-[.32rem]">
                    <Icon name={IconName.AnnouncementChecked}></Icon>
                  </div>
                </>
              )}
            </div>
            <div className="text-[.28rem] font-[400] select-none text-[var(--colour-43)]">{t('home.warnTxt')}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Ntemplate001;
