//显示充值界面
export const showRecharge = () => {
  if (window.parent) {
    var data = {
      action: 'showRecharge',
    };
    window.parent.postMessage(data, '*');
  }
};

//更新余额
export const updateBalance = () => {
  if (window.parent) {
    var data = {
      action: 'updateBalance',
      data: {
        currency: 'USDT',
        balance: 0,
      },
    };
    window.parent.postMessage(data, '*');
  }
};

//返回
export const goBack = () => {
  if (window.parent) {
    var data = {
      action: 'goBack',
    };
    window.parent.postMessage(data, '*');
  }
};

export const updateSeed = (seed) => {
  let childWindow = document.getElementsByClassName('game-iframe')[0];
  if (childWindow) {
    let window = childWindow.contentWindow;
    window.postMessage(
      {
        action: 'updateSeed',
        data: {
          seed: seed,
        },
      },
      '*',
    );
  }
};

//打点上报
export const logEvent = () => {
  if (window.parent) {
    var data = {
      action: 'analytics',
      key: 'FG_Click_SideMenu', //事件名称为已定义的事件名称，可选值见打点说明。
      json: JSON.stringify({
        gameId: '游戏id',
        //更多统计字段可以加在这里
      }),
    };
    window.parent.postMessage(data, '*');
  }
};

//更新地址栏
export const updateBrowserAddress = () => {
  if (window.parent) {
    var data = {
      action: 'updateBrowserAddress',
      data: JSON.stringify(window.location),
    };
    window.parent.postMessage(data, '*');
  }
};

//更新地址栏
export const reloadBrowserAddress = () => {
  if (window.parent) {
    var data = {
      action: 'reloadBrowserAddress',
      data: JSON.stringify(window.location),
    };
    window.parent.postMessage(data, '*');
  }
};

//设置parent theme
export const setBrowserTheme = (theme) => {
  if (window.parent) {
    var data = {
      action: 'setBrowserTheme',
      data: theme,
    };
    window.parent.postMessage(data, '*');
  }
};

//remove parent iframe loading
export const removeParentIfmLoading = () => {
  if (window.parent) {
    var data = {
      action: 'removeLoading',
    };
    window.parent.postMessage(data, '*');
  }
};

export const windowOpen = (url, target = '_blank') => {
  if (window.parent) {
    var data = {
      action: 'windowOpen',
      data: {
        url: url,
        target: target,
      },
    };
    window.parent.postMessage(data, '*');
  }
};

export const parentLocationReplace = (url) => {
  if (window.parent) {
    var data = {
      action: 'locationReplace',
      data: {
        url: url,
      },
    };
    window.parent.postMessage(data, '*');
  }
};

export const parentLocationReload = () => {
  if (window.parent) {
    var data = {
      action: 'locationReload',
    };
    window.parent.postMessage(data, '*');
  }
};
