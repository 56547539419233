import cn from 'classnames';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetWebsiteInit } from '../../../api';
import Skeleton from '../../skeleton/index';
import TopFixed from '../../topFixed';
import { toNoLocalePath } from '../../../utils/localePath';
import { setDrawerMenuVisible, setInSport } from '../../../redux/reducers/appSlice';
import Header from '../../../components/header';
import { getTemplateId, isControllableSite } from '../../../utils/helper';
import MenuBar from '../../../components/menu/menuBar/index';
import ScrollbarContent from '../../scrollbarContent';

//所有模版的基础模版
export default function BaseTemplate({ children, showDownloadPrompt = true, className = '' }) {
  const {
    disconnect,
    downloadBoxPromptSwitch,
    drawerMenuVisible,
    websiteInitConfig,
    showHeaderAndFooter,
    showMobileMenuBar,
  } = useSelector(
    (state) => ({
      disconnect: state.app.disconnect,
      downloadBoxPromptSwitch: state.app.downloadBoxPromptSwitch,
      drawerMenuVisible: state.app.drawerMenuVisible,
      websiteInitConfig: state.app.websiteInitConfig,
      showHeaderAndFooter: state.app.showHeaderAndFooter,
      showMobileMenuBar: state.app.showMobileMenuBar,
    }),
    shallowEqual,
  );
  const { pathname } = useLocation();

  return (
    <Skeleton
      className={cn('flex flex-col', {
        disconnect: disconnect,
        downloadBoxPromptSwitch: downloadBoxPromptSwitch,
      })}
    >
      <TopFixed showDownloadPrompt={toNoLocalePath(pathname) === '/' && showDownloadPrompt} />
      {disconnect ? <div className="h-[var(--disconnect-height)]"></div> : null}
      {/* {downloadBoxPromptSwitch ? <div className="h-[var(--top-download-box-prompt-height)]"></div> : null} */}
      {showHeaderAndFooter ? <Header /> : null}
      <div className={cn('app overflow-hidden flex flex-col')}>
        <ScrollbarContent className={`Zs9lLgIF37PgvIOojtWA bg-[var(--colour-9)] ${className}`}>
          {children}
        </ScrollbarContent>
      </div>
      {showMobileMenuBar ? <MenuBar /> : <></>}
    </Skeleton>
  );
}
