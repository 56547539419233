import React from 'react';

import { useTranslation } from 'react-i18next';
// import Close from '../close';
import Close from '../../close';

import { getShowMoneyNumber, pointToComma, getDateFormatStr2 } from '../../../utils/helper';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center  mt-[.3rem] ${className}`}>
        <div className=" text-[.36rem] font-[600] text-[var(--colour-38)]">{title}</div>

        <div className="  w-[.72rem] h-[.72rem] flex justify-center items-center rounded-small bg-[var(--colour-45)]">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full bg-[var(--colour-6)] h-[1px]  mt-[.28rem]"></div>
    </>
  );
};

const widthdrawRecordDetail = ({ handleClose = () => {}, Data = null }) => {
  const { t } = useTranslation();
  let DetailInfo = {};
  if (Data && Data.detail_info) {
    try {
      DetailInfo = JSON.parse(Data.detail_info);
    } catch {}
  }

  return (
    <div className=" my-[.3rem] flex flex-col justify-between text-center relative    p-[0_.48rem_.4rem_.48rem] w-[7rem] h-[auto] rounded-normal bg-[var(--colour-35)]">
      <FormHeader title={t('deposit-withdraw.details')} handleClose={handleClose} />

      <div className="text-left mt-[.28rem]">
        <div className="bg-[var(--colour-34)]  p-[.24rem] rounded-normal">
          <div className="text-[.24rem] mt-[.24rem] text-[var(--colour-14)] wrap-break-word">
            <span className="text_nowrap">{t('account.transaction_iD')}:</span>
            <span className="font-[700] text-[.26rem] text-[var(--colour-38)]">{Data?.transaction_id || '--'}</span>
          </div>
          <div className="text-[.24rem]   mt-[.24rem] text-[var(--colour-14)]">
            {t('deposit-withdraw.date')}:
            <span className="font-[700] text-[.26rem]  text-[var(--colour-38)]">
              {Data.create_time && getDateFormatStr2(Data.create_time)}
            </span>
          </div>

          <div className="text-[.24rem]   mt-[.24rem] text-[var(--colour-14)]">
            {t('login-signup.phone')}:
            <span className="font-[700] text-[.26rem]  text-[var(--colour-38)]">{DetailInfo?.acc_remark || '--'}</span>
          </div>

          <div className="text-[.24rem]   mt-[.24rem] text-[var(--colour-14)]">
            {t('deposit-withdraw.amount')}:
            <span className="font-[700] text-[.26rem]  text-[var(--colour-38)]">
              {Data?.amount ? pointToComma(String(getShowMoneyNumber(Data.amount)).slice(1)) : '--'}
            </span>
          </div>
          <div className="text-[.24rem]   mt-[.24rem] text-[var(--colour-14)]">
            {t('account.fee')}:
            <span className="font-[700] text-[.26rem]  text-[var(--colour-38)]">
              {Data?.fee ? pointToComma(Number(getShowMoneyNumber(Data.fee))) : '0'}
            </span>
          </div>

          {Data?.reason ? (
            <div className="text-[.24rem]   mt-[.24rem] text-[var(--colour-14)]">
              {t('deposit-withdraw.reason')}:
              <span className="font-[700] text-[.26rem]  text-[var(--colour-38)]">{Data?.reason}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default widthdrawRecordDetail;
