import { getTemplateId } from '../../../../utils/helper';

export default function Index({ className = '', isChoose = false, onClick = () => {}, children, ismagnifyImg }) {
  const chooseColor =
    getTemplateId() == 'd-template003'
      ? ismagnifyImg
        ? 'text-[var(--colour-83)] text-[.2rem] font-[500]'
        : 'text-[var(--colour-13)]  text-[.2rem] font-[500]'
      : 'text-[var(--colour-1)]';

  const NochooseColor =
    getTemplateId() == 'd-template003'
      ? ismagnifyImg
        ? 'text-[var(--colour-83)] text-[.2rem] font-[500]'
        : 'text-[var(--colour-14)] text-[.2rem] font-[500]'
      : 'text-[var(--colour-8)]';

  return (
    <div onClick={onClick} className={`relative ${className} ${isChoose ? chooseColor : NochooseColor}`}>
      {children}

      {isChoose && getTemplateId() == 'd-template003' ? (
        <div className="absolute abs-x-center bottom-[-.04rem] w-[.16rem] h-[.04rem] bg-[var(--colour-81)]"></div>
      ) : null}
    </div>
  );
}
