import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector, shallowEqual } from 'react-redux';
import { Pagination, Autoplay } from 'swiper/modules';
import BannerItem from './bannerItem';
import BannerPage from './bannerPagination';
import { isUseLayoutMobile } from '../../utils/helper';

export default function BannerPc1({ swiperList, bannerStyle = '1' }) {
  const { drawerMenuVisible, zoomNum } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      zoomNum: state.app.zoomNum,
    }),
    shallowEqual,
  );

  return (
    <div style={{}}>
      {swiperList && swiperList.length > 0 && (
        <>
          <div className="relative">
            <Swiper
              className={`homeBanner-${bannerStyle}-mobile`}
              spaceBetween={5}
              loop={true} //循环切换
              speed={1000}
              // loopedSlides={3} //可视slide有3个，loopedSlides可设为5个或以上
              slidesPerView={1}
              pagination={{ clickable: true, el: `.swiper-pagination-home-0` }} //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换。
              navigation={{
                nextEl: `.swiper-button-next-0`,
                prevEl: `.swiper-button-prev-0`,
              }} //前后进退按纽
              centeredSlides={false} //设定为true时，active slide会居中，而不是默认状态下的居左。
              // initialSlide={Math.floor(swiperList.length / 2)} //与centeredSlide结合使用，指定初始化的索引
              modules={[Autoplay, Pagination]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {swiperList.map((item, index) => (
                <SwiperSlide key={index} className="">
                  <BannerItem model={item} index={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <BannerPage className={isUseLayoutMobile() ? 'absolute z-[1] space-x-[.08rem]' : ''} />
        </>
      )}
    </div>
  );
}
