import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DownloadManager from '../../../../../manager/downloadManager';
import { cmsImgUrl, isInIframe, isPCLayout } from '../../../../../utils/helper';
import cn from 'classnames';
import { useStateIfMounted } from 'use-state-if-mounted';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig';
import DialogModal from '../../..';
import IOSSafariGuide from '../../../iosSafariGuide';
import Icon, { IconName } from '../../../../icon';
import PwaguideQRcodeModel from '../../../pwaguideQRcode';
import Loading from '../../../../loading2';
import { isMobileSafari } from '../../../../../utils/deviceUtils';

export default function DTemplate001({ handleClose, downloaddata }) {
  const { t } = useTranslation();
  const { download } = useSelector(
    (state) => ({
      download: state.app.websiteInitConfig?.website_info?.download,
      disconnect: state.app.disconnect,
    }),
    shallowEqual,
  );

  const [bottomDownloadBoxPromptSwitch, setbottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  const { getServerLangText } = useLangServerConfig();

  const [fileSize, setFileSize] = useStateIfMounted(0);
  const [isshowLoading, setisshowLoading] = useState(true);
  const [isShowIOSguide, setisShowIOSguide] = useState(false);
  const [isShowGuideQrcode, setisShowGuideQrcode] = useState(false);
  const [iosDownloadLink, setIosDownloadLink] = useStateIfMounted('');

  useEffect(() => {
    setbottomDownloadBoxPromptSwitch(downloaddata);
  }, [downloaddata]);

  const handleCloseModel = () => {
    handleClose && handleClose();

    setbottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });

    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_CloseDownloadButton);
  };

  useEffect(() => {
    if (DownloadManager.showAndroid) {
      DownloadManager.getDownloadAppSize()
        .then((fileSize) => {
          setFileSize(fileSize);
        })
        .catch((e) => {
          console.error('Failed to get download app size:', e);
        })
        .finally(() => {
          setisshowLoading(false);
        });
    }

    if (DownloadManager.showIOS) {
      DownloadManager.getDownloadLink().then((res) => {
        if (res.code == 0) {
          const { ios_download_link } = res.result;
          setIosDownloadLink(ios_download_link);
        }

        setisshowLoading(false);
      });
    }
  }, []);

  const handleClick = () => {
    DownloadManager.handleInstallAction();
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setisShowIOSguide(false);
        }}
        isOpen={isShowIOSguide}
      >
        <IOSSafariGuide
          handleClose={() => {
            setisShowIOSguide(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={() => {
          setisShowGuideQrcode(false);
        }}
        isOpen={isShowGuideQrcode}
      >
        <PwaguideQRcodeModel
          handleClose={() => {
            setisShowGuideQrcode(false);
          }}
        />
      </DialogModal>

      <div className="w-[5.96rem] h-[3.22rem] bg-[var(--colour-35)] rounded-[.16rem] p-[.4rem_.2rem_.2rem_.2rem] user-none">
        <div
          className={cn(
            'active:scale-[.95] transition-all select-none w-[.6rem] h-[.6rem] rounded-[50%] flex justify-center items-center absolute left-[50%] bottom-[-0.86rem] translate-x-[-50%]',
          )}
          onClick={handleCloseModel}
        >
          <Icon name={IconName.SignInClose} />
        </div>

        <div className="flex items-center gap-[.2rem]">
          <img className="w-[1.2rem] h-[1.2rem]" src={cmsImgUrl(download['icon_192x192'])} />

          <div className="text-[.28rem] font-[500] text-[var(--colour-10)] leading-[.33rem] break-words line-clamp-4">
            {bottomDownloadBoxPromptSwitch?.downloadText
              ? bottomDownloadBoxPromptSwitch.downloadText
              : download?.top?.tip?.bottom_title
                ? getServerLangText(download?.top?.tip?.bottom_title)
                : ''}
          </div>
        </div>

        <div className="mt-[.7rem]">
          {/* iframe */}
          {(isPCLayout() && !DownloadManager.showIOS & !DownloadManager.showAndroid) || DownloadManager.isNotMobile ? (
            <>
              <div
                className="w-[5.56rem] h-[.7rem] rounded-[.1rem] border border-[var(--colour-1)] flex items-center justify-center cursor-pointer active:scale-[.95] transition-all select-none text-[.22rem] font-[500] text-[var(--colour-1)]"
                onClick={() => {
                  setisShowGuideQrcode(true);
                }}
              >
                {t('home.add_to_home_screen')}
              </div>
            </>
          ) : null}

          {/* IOS */}
          {!isPCLayout() && DownloadManager.showIOS && !DownloadManager.isNotMobile ? (
            <>
              {isshowLoading ? (
                <Loading className="h-[.7rem]" />
              ) : (
                <>
                  {iosDownloadLink == '' ? (
                    <div
                      className="w-[5.56rem] h-[.7rem] rounded-[.1rem] border border-[var(--colour-1)] flex items-center justify-center cursor-pointer active:scale-[.95] transition-all select-none text-[.22rem] font-[500] gap-[.09rem] text-[var(--colour-1)]"
                      onClick={() => {
                        setisShowIOSguide(true);
                      }}
                    >
                      {t('home.add_to_home_screen')}
                    </div>
                  ) : (
                    <>
                      <div className="flex items-center gap-[.2rem]">
                        <div
                          className="w-[2.68rem] h-[.7rem] rounded-[.1rem] border border-[var(--colour-1)] flex items-center justify-center text-[.2rem] font-[500] text-[var(--colour-1)] active:scale-[.95] transition-all select-none"
                          onClick={() => {
                            DownloadManager.gotoDownloadLink();
                          }}
                        >
                          {t('home.drawer_download_text2')}
                        </div>
                        <div
                          className="w-[2.68rem] h-[.7rem] rounded-[.1rem] bg-[var(--colour-1)] flex items-center justify-center text-[.2rem] font-[500] text-[var(--colour-2)] active:scale-[.95] transition-all select-none"
                          onClick={() => {
                            if (!isMobileSafari()) {
                              DownloadManager.gotoPWATip();
                            } else {
                              setisShowIOSguide(true);
                            }
                          }}
                        >
                          {t('home.add_to_home_screen')}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : null}

          {/* Android */}
          {!isPCLayout() && DownloadManager.showAndroid && !DownloadManager.isNotMobile ? (
            <>
              <div className="flex items-center gap-[.2rem]">
                <div
                  className="w-[2.68rem] h-[.7rem] rounded-[.1rem] border border-[var(--colour-1)] flex items-center justify-center text-[.2rem] font-[500] text-[var(--colour-1)] active:scale-[.95] transition-all select-none"
                  onClick={handleCloseModel}
                >
                  {t('home.cancelar')}
                </div>
                <div
                  className="w-[2.68rem] h-[.7rem] rounded-[.1rem] bg-[var(--colour-1)] flex items-center justify-center text-[.2rem] font-[500] text-[var(--colour-2)] active:scale-[.95] transition-all select-none"
                  onClick={handleClick}
                >
                  {t('home.instalar')}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
