import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import GameItem from './gameItem';
import Loading from './loading2';
import NoData from './noData';
import { GetRecentListReq } from '../api';
import { isUseLayoutMobileHome } from '../utils/helper';
import cn from 'classnames';
import LoadMore from './loadMore';
import { page_size } from './homeGameList';
import LoadMoreText from './loadMoreText';
import { get } from 'lodash';

export default function RecentlyListAll({
  gameListGridClassName = '',
  useNewNoData = false,
  loadMoreWithText = false,
  lang_key_title = '',
}) {
  const { identity, needUpdateRecently, uid } = useSelector(
    (state) => ({
      identity: state.user.identity,
      needUpdateRecently: state.app.needUpdateRecently,
      uid: get(state, 'user.userObj.uid', ''),
    }),
    shallowEqual,
  );

  const [paramsRecently, setParamsRecently] = useState({
    page: 1,
    page_size: page_size,
    refreshNow: false,
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentNum, setCurrentNum] = useState(0);

  const updateList = () => {
    setLoading(true);

    GetRecentListReq(uid).then((res) => {
      setLoading(false);
      if (res.code === 0) {
        const result = res.result;
        setList(result.list);
        setTotal(result.pages.count);
      } else {
        setList([]);
      }
    });
  };

  useEffect(() => {
    setCurrentNum(list.length);
  }, [list]);

  useEffect(() => {
    updateList();
  }, [identity, needUpdateRecently, paramsRecently]);

  const onClickMore = () => {
    setParamsRecently({
      ...paramsRecently,
      page: paramsRecently.page + 1,
    });
  };

  return loading ? (
    <Loading height="4rem" />
  ) : (
    <>
      {list && list.length > 0 ? (
        <div className={cn(`games__row ${gameListGridClassName}`, {})}>
          {list && list.length > 0 && list.map((item, index) => <GameItem model={item} key={item.id} />)}
        </div>
      ) : (
        <NoData className="py-[.4rem]" />
      )}

      {showLoadMore ? (
        <div className={cn('flex items-center justify-center', {})}>
          {loadMoreWithText ? (
            <LoadMoreText
              className={`mt-[.32rem]`}
              onClick={onClickMore}
              showLoading={loadingMore && paramsRecently.page > 1}
              currentNum={currentNum}
              total={total}
              lang_key_title={lang_key_title}
            />
          ) : (
            <LoadMore
              onClick={onClickMore}
              showLoading={loadingMore && paramsRecently.page > 1}
              className={`'!cursor-pointer opacity-[1]' ${isUseLayoutMobileHome() ? 'mt-[.16rem]' : 'mt-[.8rem]'}`}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
