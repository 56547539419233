import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Icon, { IconName } from '../../icon';
import Button from '../../button';
import ScrollWrapper from '../../scrollWrapper';
import { CashRainAnimation } from '../../../utils/cashRain';
import {
  setRefetchActivityData,
  setAutoScrollToTagGameKey,
  setNeedUpdateBalance,
  setIsShowPromotionBarGiftMoneyCoin,
} from '../../../redux/reducers/appSlice';
import { getShowMoney, getRedPacketObj, openHeaderGiftMoney, isPromotionPath } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { toast } from 'react-toastify';
import { ClaimCashRainReq } from '../../../api';
import { toLocalePath, toNoLocalePath } from '../../../utils/localePath';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';
import useRouterHelper from '../../../hooks/useRouterHelper';
import cn from 'classnames';
import { useGlobalNavigate } from '../../../contexts/navigateContext';
import NoData from '../../noData';
import { getCenterPosition } from '../../../hooks/useMinimizeAnimation';
import LimitClickWrapper from '../../limitClickWrapper';
import GSAPUtils from '../../../utils/gsapUtils';

const types = {
  unstarted: 'unstarted',
  started: 'started',
  result: 'result',
};

const GrabButton = ({ onClick, name = '' }) => {
  return (
    <LimitClickWrapper
      className={cn(
        'mx-[auto] bg-[url(../assets/img/cash-rain/red/bg-sprite.png)] text-[#F2321F] font-[700] flex items-center justify-center text-center w-[2.24rem] h-[2.36rem] bg-[-0.2rem_-12.92rem] text-[.54rem] bg-[length:21.78rem_15.48rem]',
        {
          'cursor-pointer': !!onClick,
        },
      )}
      onClick={onClick}
    >
      {name}
    </LimitClickWrapper>
  );
};

const MyButton = ({ onClick, name = '' }) => {
  return (
    <div
      className=" border-[#FFF044] bg-[#FFD338] text-[#F2321F] font-[600] flex items-center justify-center text-center cursor-pointer w-[2.52rem] h-[.8rem] border-[.08rem] rounded-[.16rem] text-[.24rem]"
      onClick={onClick}
      style={{
        boxShadow: '0px 6px .08rem 0px rgba(102, 0, 0, 0.20)',
      }}
    >
      {name}
    </div>
  );
};

const TextBox = ({ children }) => {
  return (
    <div className="w-[5.52rem] pt-[.14rem]">
      <div className="w-[5.52rem] h-[2.16rem] relative bg-no-repeat bg-[url(../assets/img/cash-rain/red/bg-sprite.png)] bg-[length:21.78rem_15.48rem] bg-[-0.2rem_-10.36rem]">
        {children}
      </div>
    </div>
  );
};

const CashRainBox = ({ children, handleClose, className = '', type }) => {
  return (
    <div
      className={cn(
        `relative w-[6.86rem] h-[9.76rem] text-[#FFFFFF] bg-[url(../assets/img/cash-rain/red/bg-sprite.png)] bg-[length:21.78rem_15.48rem] bg-no-repeat  ${className}`,
        {
          'bg-[-0.2rem_-0.2rem]': type === types.unstarted,
          'bg-[-7.46rem_-0.2rem]': type === types.started,
          'bg-[-14.72rem_-0.2rem]': type === types.result,
        },
      )}
    >
      <Button
        onClick={() => {
          handleClose();
          AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CloseCashRainButton);
        }}
        className="absolute rounded-[.06rem] w-[.4rem] h-[.4rem] top-[.04rem] right-[.16rem] cursor-pointer"
      >
        <Icon name={IconName.Close} />
      </Button>
      {children}
    </div>
  );
};

const CashRainTitleBox = ({ className, children }) => {
  return (
    <div className={`absolute abs-x-center top-[.12rem] w-[5.3rem] h-[1.88rem] text-center ${className}`}>
      {children}
    </div>
  );
};

const CashRainUnstarted = ({ data, handleClose, type }) => {
  const { t } = useTranslation();
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  return (
    <CashRainBox handleClose={handleClose} type={type}>
      <div className="flex justify-center pt-[.52rem]">
        <TextBox>
          <span className="absolute abs-x-center font-[600] text-[#FFF044] text-[.28rem] top-[-0.14rem]">
            {data.rule_explain.activity_title}
          </span>
          <CashRainTitleBox className="flex flex-col items-center justify-center font-[900] text-[.38rem] space-y-[.2rem]">
            <div>
              <span className="text-[#fff]">
                {websiteInfo.currency_symbol || ''}&nbsp;{getShowMoney(data.activity_claim_ceiling_amount)}
              </span>
              &nbsp;{t('cashRain.maxPrize')}
            </div>
          </CashRainTitleBox>
        </TextBox>
      </div>
      <ScrollWrapper
        mr={false}
        className="rounded-[.16rem] mx-[auto] break-all mt-[.22rem] w-[5.48rem] h-[5.48rem] px-[.2rem] py-[.24rem]"
        style={{
          background: 'linear-gradient(135deg, rgba(116, 0, 0, 0.13) 0%, rgba(116, 0, 0, 0.13) 100.74%)',
          boxShadow: '0px 1px 0px 0px rgba(255, 127, 124, 0.50), 0px 4px 4px 0px rgba(0, 0, 0, 0.06) inset',
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: data.rule_explain.activity_rule_explain }}></div>
      </ScrollWrapper>

      {data.started && data.is_grad == 0 ? (
        <p className="font-[500] text-center w-full leading-[1] text-[.32rem] mt-[.32rem]">{t('cashRain.tip4')}</p>
      ) : (
        <p className="font-[500] text-center w-full leading-[1] text-[.32rem] mt-[.32rem]">
          {t('cashRain.startIn') + ' ' + data.showTime}
        </p>
      )}
    </CashRainBox>
  );
};

const CashRainStarted = ({ data, handleClose, handleGoRecharge, handleGrab, handleGoToGame, type }) => {
  const { t } = useTranslation();
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  return (
    <CashRainBox handleClose={handleClose} type={type}>
      <div className="flex justify-center pt-[.52rem]">
        <TextBox>
          <span className="absolute abs-x-center font-[600] text-[#FFF044] text-[.28rem] top-[-0.14rem]">
            {data.rule_explain.activity_title}
          </span>
          <CashRainTitleBox className="flex flex-col items-center justify-center font-[900] text-[.38rem] space-y-[.2rem]">
            <div>
              <span className="text-[#fff]">
                {websiteInfo.currency_symbol || ''}&nbsp;{getShowMoney(data.activity_claim_ceiling_amount)}
              </span>
              &nbsp;{t('cashRain.maxPrize')}
            </div>
          </CashRainTitleBox>
        </TextBox>
      </div>

      <div
        className="wrap-break-word mx-[auto] flex items-center justify-center text-center font-[500] leading-[1.2] mt-[.22rem] w-[5.48rem] h-[2rem] rounded-[.16rem] px-[.2rem] py-[.24rem] text-[.24rem]"
        style={{
          background: 'linear-gradient(135deg, rgba(116, 0, 0, 0.13) 0%, rgba(116, 0, 0, 0.13) 100.74%)',
          boxShadow: '0px 1px 0px 0px rgba(255, 127, 124, 0.50), 0px 4px 4px 0px rgba(0, 0, 0, 0.06) inset',
        }}
      >
        <span>{t('cashRain.tips')}</span>
      </div>

      <div className="flex flex-col items-center pt-[.2rem] space-y-[.72rem]">
        <GrabButton
          onClick={(event) => {
            handleGrab(event.currentTarget);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_CashRainButton);
          }}
          name={t('cashRain.GRAB')}
        />

        <div className="flex flex-row items-center justify-between space-x-[.4rem]">
          <MyButton name={t('cashRain.goToRecharge')} onClick={handleGoRecharge} />
          <MyButton name={t('cashRain.goToGame')} onClick={handleGoToGame} />
        </div>
      </div>
    </CashRainBox>
  );
};

const CashRainResult = ({ handleClose, handleGoRecharge, showMoney, handleGoToGame, type }) => {
  const { t } = useTranslation();
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const getLimitShowMoney = (moeny) => {
    const m = getShowMoney(moeny).replace(',', '.');
    if (m.indexOf('.') > -1 && m.substring(0, m.indexOf('.')).length > 4) {
      return m.substring(0, m.indexOf('.'));
    }
    return m;
  };

  const cashRainAnimationRef = useRef(new CashRainAnimation('cash-rain-win-result', 100, 30));
  useEffect(() => {
    if (showMoney != 0) {
      cashRainAnimationRef.current.initAnimation();
    }
    return () => cashRainAnimationRef.current.endAnimation();
  }, [showMoney]);

  return (
    <CashRainBox handleClose={handleClose} type={type} className="flex flex-col items-center justify-center">
      <canvas
        className="absolute bottom-[0] abs-x-center pointer-events-none"
        width={'6.8rem'}
        height={'9.3rem'}
        id="cash-rain-win-result"
      ></canvas>

      <div className="mx-[auto] w-full break-all flex flex-col items-center text-center h-[4.6rem]">
        <div className="items-center justify-center pt-[1.28rem]">
          <span className="font-[600] text-[#F8774E] text-[1.12rem]">{getLimitShowMoney(showMoney)}</span>
        </div>
        <div className="wrap-break-word font-[500] text-center text-[#D9854E] leading-[1.2] w-[3.96rem] text-[.24rem] pt-[.8rem]">
          {t(`cashRain.${showMoney == 0 ? 'tips2' : 'tips3'}`)}
        </div>
      </div>

      <div className="flex flex-col items-center pt-[.2rem] space-y-[.72rem]">
        <GrabButton />
        <div className="flex flex-row items-center justify-between space-x-[.4rem]">
          <MyButton name={t('cashRain.goToRecharge')} onClick={handleGoRecharge} />
          <MyButton name={t('cashRain.goToGame')} onClick={handleGoToGame} />
        </div>
      </div>
    </CashRainBox>
  );
};

export default function CashRain({ handleClose }) {
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { responsiveMode, activityData } = useSelector(
    (state) => ({
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );
  const { handleClickWallet } = useRouterHelper();

  const { pathname } = useLocation();
  const [isShowResult, setIsShowResult] = useState(false);
  const [showMoney, setShowMoney] = useState(false);
  const [cashRainObj, setCashRainObj] = useState(null);

  const handleGoToGame = () => {
    handleClose();
    if (toNoLocalePath(pathname) == '/') {
      dispatch(setAutoScrollToTagGameKey('002'));
    } else {
      navigate(toLocalePath('/'));
      dispatch(setAutoScrollToTagGameKey('002'));
    }
  };

  const setZeroResult = () => {
    setIsShowResult(true);
    setShowMoney(0);
    dispatch(setRefetchActivityData());
  };

  const handleGrab = (element) => {
    if (cashRainObj != null) {
      if (cashRainObj.red_packet_End_time * 1000 - new Date().getTime() <= 0) {
        //如果红包已经过期
        setZeroResult();
      } else {
        ClaimCashRainReq({
          activity_id: cashRainObj.id,
        }).then((res) => {
          // res = {
          //   ...res,
          //   code: 0,
          //   result: {
          //     amount: 120,
          //     currency: 'gift_money',
          //   },
          // }

          if (res.code == 0) {
            setIsShowResult(true);
            const result = res.result;
            setShowMoney(Number(result.amount) || 0);
            dispatch(setRefetchActivityData());
            dispatch(setNeedUpdateBalance({ need: true }));

            const currency = res?.result?.currency;
            const amount = res?.result?.amount || 0;

            if (element && currency == 'gift_money') {
              const fromPoints = getCenterPosition(element, true);
              if (isPromotionPath(toNoLocalePath(pathname)) && activityData?.gift_money) {
                const giftMoneyLabel = document.querySelector('#promotion_giftMoney');

                if (giftMoneyLabel) {
                  const toPoint = getCenterPosition(giftMoneyLabel, true);
                  GSAPUtils.flyCoin(fromPoints, toPoint, GSAPUtils.COIN_COUNT, () => {
                    dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                  });
                }
              } else {
                openHeaderGiftMoney(amount, [fromPoints]);
              }
            }
          } else if (res.code == 1008108) {
            setZeroResult();
          } else {
            toast.error(res.msg, {
              containerId: 'global',
            });
          }
        });
      }
    }
  };
  const cashRainAnimationRef = useRef(new CashRainAnimation());

  useEffect(() => {
    cashRainAnimationRef.current.initAnimation();
    return () => cashRainAnimationRef.current.endAnimation();
  }, []);

  const handleGoRecharge = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashRain_Deposit);
    handleClose();
    handleClickWallet();
  };

  let interval = -1;
  useEffect(() => {
    if (interval > 0) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      if (cashRainObj != null) {
        if (cashRainObj.red_packet_start_time * 1000 - new Date().getTime() < 0 && !cashRainObj.started) {
          const newCashRainObj = cloneDeep(cashRainObj);
          newCashRainObj.started = true;
          setCashRainObj(newCashRainObj);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cashRainObj]);

  useEffect(() => {
    if (activityData && activityData.activity_red_packet) {
      const list = activityData.activity_red_packet.list;
      setCashRainObj(getRedPacketObj(list));
    }
  }, [activityData]);

  // return isShowResult ? (
  //   <CashRainResult
  //     handleClose={handleClose}
  //     handleGoRecharge={handleGoRecharge}
  //     showMoney={showMoney}
  //     handleGoToGame={handleGoToGame}
  //   />
  // ) : cashRainObj ? (
  //   cashRainObj.started && cashRainObj.is_grad == 1 ? (
  //     <CashRainStarted
  //       handleClose={handleClose}
  //       data={cashRainObj}
  //       handleGoRecharge={handleGoRecharge}
  //       handleGrab={handleGrab}
  //       handleGoToGame={handleGoToGame}
  //     />
  //   ) : (
  //     <CashRainUnstarted handleClose={handleClose} data={cashRainObj} />
  //   )
  // ) : null;

  return isShowResult ? (
    <CashRainResult
      handleClose={handleClose}
      handleGoRecharge={handleGoRecharge}
      showMoney={showMoney}
      handleGoToGame={handleGoToGame}
      type={types.result}
    />
  ) : cashRainObj ? (
    cashRainObj.started && cashRainObj.is_grad == 1 ? (
      <CashRainStarted
        handleClose={handleClose}
        data={cashRainObj}
        handleGoRecharge={handleGoRecharge}
        handleGrab={handleGrab}
        handleGoToGame={handleGoToGame}
        type={types.started}
      />
    ) : (
      <CashRainUnstarted handleClose={handleClose} data={cashRainObj} type={types.unstarted} />
    )
  ) : (
    <div className="relative w-[6.86rem] h-[9.76rem] text-[#FFFFFF] bg-[url(../assets/img/cash-rain/red/bg-sprite.png)] bg-[length:20.78rem_14.7rem] bg-no-repeat flex items-center justify-center">
      <NoData showTextLangkey="promotion.eventend" textClassName="!text-[#fff]" className1="!text-[#fff]" />
    </div>
  );
}
