import { useState, useRef, memo } from 'react';
import cn from 'classnames';
import Loading from '../loading2';

/**
 * 游戏iframe 支持url和html两种方式加载
 * @param {string} iframeUrl
 * @param {string} iframeHtml
 */
const GameIfm = ({ iframeUrl, iframeHtml }) => {
  const [isFinish, setisFinish] = useState(false);
  const iframeRef = useRef(null);

  return (
    <>
      <iframe
        ref={iframeRef}
        className={cn('game-iframe', {})}
        src={iframeUrl || undefined}
        srcDoc={iframeHtml || undefined}
        onLoad={() => {
          setisFinish(true);
        }}
        allow="clipboard-read; clipboard-write"
      />
      {!isFinish && (
        <div className="game-iframe bg-[#0c1014] ">
          <Loading isAbsolute={false} className="w-full h-full flex justify-center items-center" />
        </div>
      )}
    </>
  );
};

export default memo(GameIfm);
