import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import GameItem from '../gameItem';
import ProviderItem from '../providerItem';
import { useEffect, useRef } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { isControllableSite, isUseLayoutMobileHome } from '../../utils/helper';

const ColumnSwiper = ({
  rows = 1,
  column = 1,
  list = [],
  gap,
  currentPage,
  isClickProvider = false,
  disableSwiping = true,
  swiperAnimation = true,
  clickProviderItem = () => {},
  setCurrentPage = () => {},
}) => {
  const swiperRef = useRef(null);
  const [columnList, setColumnList] = useStateIfMounted([]);

  useEffect(() => {
    const pageTotal = rows * column;
    const maxSlides = Math.ceil(list.length / pageTotal);
    let columnList = [];
    for (let i = 0; i < maxSlides; i++) {
      const pageArr = Array.from(list).slice(pageTotal * i, pageTotal * i + pageTotal);
      if (i > 0) {
        if (pageArr.length < pageTotal) {
          for (let j = 0; i <= pageTotal - pageArr.length; j++) {
            pageArr.push('');
          }
        }
      }
      if (pageArr.length > 0) {
        columnList.push(pageArr);
      }
    }
    setColumnList(columnList);
  }, [rows, column, list.length, list]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(currentPage, swiperAnimation ? 500 : 0, false);
    }
  }, [currentPage, swiperAnimation]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.allowTouchMove = disableSwiping;
    }
  }, [disableSwiping]);

  return (
    <Swiper
      virtual
      ref={swiperRef}
      allowTouchMove={disableSwiping}
      spaceBetween={gap}
      modules={[Virtual]}
      slidesPerView={columnList.length > 1 && !isControllableSite() ? 1.1 : 1}
      onSlideChange={(data) => {
        setCurrentPage(data.realIndex);
      }}
    >
      {columnList.map((columnItem, columnIndex) => (
        <SwiperSlide key={columnIndex}>
          <div className="grid h-full" style={{ gap: gap, gridTemplateColumns: `repeat(${column}, 1fr)` }}>
            {columnItem.map((item, index) =>
              isClickProvider ? (
                <ProviderItem
                  key={`${item.id}-${index}-${columnIndex}`}
                  model={item}
                  clickProviderItem={clickProviderItem}
                  innerClassName={isUseLayoutMobileHome() ? '!pt-[100%]' : ''}
                />
              ) : (
                <GameItem
                  key={`${item.id}-${index}-${columnIndex}`}
                  model={item}
                  slidesPerView={columnList.length > 1 ? 1.1 : 1}
                  innerClassName={''}
                />
              ),
            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const MultipleRowsSwiper = ({
  rows,
  column,
  list = [],
  gap,
  disableSwiping,
  currentPage,
  isClickProvider = false,
  clickProviderItem = () => {},
  setCurrentPage = () => {},
  swiperAnimation = true,
}) => {
  return (
    <ColumnSwiper
      disableSwiping={disableSwiping}
      currentPage={currentPage}
      rows={rows}
      column={column}
      list={list}
      gap={gap}
      isClickProvider={isClickProvider}
      clickProviderItem={clickProviderItem}
      swiperAnimation={swiperAnimation}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default MultipleRowsSwiper;
