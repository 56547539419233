import isMobile from 'ismobilejs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetDownloadLink } from '../../api';
import { isPWA } from '../../utils/deviceUtils';
import { toLocalePath } from '../../utils/localePath';
import { openUrl } from '../../utils/openUrl';
import config from '../../constants/config';
import { getInviteCode, removeLoadingPage } from '../../utils/helper';
import { removeParentIfmLoading } from '../../manager/iframeManager';
import { useGlobalNavigate } from '../../contexts/navigateContext';

/**
 * 下载界面
 */
const delayTime = 300; // 延时时间

const Download = () => {
  const navigate = useGlobalNavigate();

  useEffect(() => {
    removeLoadingPage();
    removeParentIfmLoading();
    // pwa直接回首页
    if (isPWA()) {
      navigate(toLocalePath('/'));
      return;
    }
    // 只要不是苹果设备都跳转。
    if (!isMobile().apple.device) {
      setTimeout(() => {
        gotoDownloadLink();
      }, delayTime);
    }
  }, []);

  // 跳转下载链接
  const gotoDownloadLink = () => {
    const params = { invite_code: getInviteCode() };
    GetDownloadLink(params).then((res) => {
      if (res.code == 0) {
        const { android_download_link } = res.result;
        const fullPath = config.downloadUrl + android_download_link;
        openUrl(fullPath, '_self');
      }
    });
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="px-[.08rem]">Download Page</div>
    </div>
  );
};

export default Download;
