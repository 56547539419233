import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

const HistoryItem = ({ name, money, date, source, isHasBg, typeName, handleSourceClick = () => {} }) => {
  const { t } = useTranslation();

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
    }),
    shallowEqual,
  );

  return (
    <div
      className={cn(
        'h-[1.44rem] rounded-[.12rem] flex flex-row justify-between items-center p-[.2rem_.2rem_.18rem] leading-[1] text-[.2rem] font-[500] text-[var(--colour-41)]',
        {
          'bg-[var(--colour-69)]': isHasBg,
        },
      )}
    >
      <div className="flex-1 h-full flex flex-col justify-between">
        <div className="text-[.22rem]">{name}</div>
        <div className="flex items-center space-x-[.2rem]">
          <span className="text-[var(--colour-26)]">
            {currency_symbol} {money}
          </span>
          <span className="">{date}</span>
          <span className="">
            <span className="text-[var(--colour-41)]">{t('promotion.source')}:</span>{' '}
            <span className="text-[var(--colour-1)]" onClick={handleSourceClick}>
              {source}
            </span>
          </span>
        </div>
      </div>
      <div className="shrink-0">
        <span className="text-[var(--colour-24)] ">{typeName}</span>
      </div>
    </div>
  );
};

export default HistoryItem;
