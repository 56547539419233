import NTemplate001 from './normal/template001';
import DTemplate003 from './design/template003';
import { getTemplateId } from '../../../utils/helper';

export default function Index() {
  switch (getTemplateId()) {
    case 'd-template003':
      return <DTemplate003 />;
    default:
      return <NTemplate001 />;
  }
}
