import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { get } from 'lodash';
import { getUserPhoto, omitStr, isControllableSite } from '../../utils/helper';
import Icon, { IconName } from '../icon';
import {
  setShowHeaderUserinfo,
  setShowSupportNew,
  setShowInbox,
  setDrawerMenuVisible,
} from '../../redux/reducers/appSlice';
import HeaderUserinfo from './headerUserinfo';
import HeaderUserinfoMask from './headerUserinfoMask';
import cn from 'classnames';

export default function HeaderUser() {
  const dispatch = useDispatch();
  const { userObj, showHeaderUserinfo, unReadCount } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      showHeaderUserinfo: state.app.showHeaderUserinfo,
      unReadCount: get(state, 'app.unReadCount'),
    }),
    shallowEqual,
  );

  const clearNALL = () => {
    dispatch(setShowSupportNew(false));
    dispatch(setShowInbox(false));
  };

  const mouseEnter = () => {
    clearNALL();
    dispatch(setShowHeaderUserinfo(true));
  };

  const mouseLeave = () => {
    dispatch(setShowHeaderUserinfo(false));
  };
  const clickUserinfo = () => {
    dispatch(
      setDrawerMenuVisible({
        visible: false,
        isAnimation: true,
      }),
    );
    dispatch(setShowHeaderUserinfo(true));
  };
  return userObj ? (
    <>
      <div
        className={cn(
          'w-[.8rem] h-[.8rem] flex items-center justify-between pr-[.14rem] cursor-pointer relative rounded-normal !rounded-l-[0] pl-[.4rem] !ml-[.52rem] bg-[var(--colour-34)] text-[var(--colour-40)]',
          {
            'pr-[.24rem]': isControllableSite(),
          },
        )}
        onClick={clickUserinfo}
      >
        <div className="flex items-center">
          <div
            className={cn('w-[.8rem] h-[.8rem] absolute left-[-0.4rem]', {
              'rounded-[50%]': !isControllableSite(),
            })}
          >
            <img
              src={getUserPhoto(userObj.icon)}
              className={cn({
                'rounded-[.12rem]': isControllableSite(),
              })}
              alt=""
            />
            <div
              className="absolute bottom-[0] right-[0] font-[700] text-[.24rem] leading-[.3rem] text-[var(--colour-35)]"
              style={{ WebkitTextStrokeWidth: '.08rem', WebkitTextStrokeColor: 'var(--colour-35)' }}
            >
              V{userObj.vip_level}
            </div>
            <div className="absolute bottom-[0] right-[0] font-[700] text-[.24rem] leading-[.3rem] text-[var(--colour-26)]">
              V{userObj.vip_level}
            </div>
            {!isControllableSite() && unReadCount > 0 ? (
              <div className="w-[.2rem] h-[.2rem] rounded-full bg-red-dot flex justify-center items-center font-[600] absolute right-[0] top-[0]"></div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="text-[var(--colour-11)] w-[.12rem] h-[.2rem]">
          <Icon name={IconName.WalletRight} />
        </div>
        <div
          className={`absolute w-full bottom-[-0.3rem] cursor-default ${showHeaderUserinfo ? 'h-[.3rem]' : 'h-[0]'}`}
        ></div>
        <HeaderUserinfo />
      </div>
      {showHeaderUserinfo ? (
        <HeaderUserinfoMask
          onClick={() => {
            dispatch(setShowHeaderUserinfo(false));
          }}
        />
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
}
