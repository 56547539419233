import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getShowMoney } from '../../utils/helper';
import { shallowEqual, useSelector } from 'react-redux';
import cn from 'classnames';

const MyDataItem = ({ title, val, className, isRightBorder = true }) => {
  return (
    <>
      <div className="flex items-center justify-center bg-[var(--colour-47)] h-[.68rem]">{title}</div>
      <div
        className={cn('h-[.84rem] flex items-center justify-center', {
          'border-r border-[var(--colour-6)]': isRightBorder,
        })}
      >
        <span className={`font-[600] text-[.36rem] ${className}`}>{val}</span>
      </div>
    </>
  );
};

const MyData = ({ myData = null, rewardList = [] }) => {
  const { t } = useTranslation();
  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: get(state, 'app.websiteInitConfig.website_info.currency_symbol', ''),
    }),
    shallowEqual,
  );

  const getShowRank = (myData) => {
    const num = get(myData, 'ranking_num', 0);
    if (num < 0) {
      return '--';
    } else if (num == 0) {
      const positionList = rewardList.map((item) => Number(item.end_tap_position));
      const position = Math.max(...positionList);
      return `${position}+`;
    } else {
      return num;
    }

    // if (num === 0) {
    //   return '--';
    // } else if (num >= 1000) {
    //   return '999+';
    // } else {
    //   return num;
    // }
  };

  const getShowPrize = (myData, rewardList) => {
    const rank = get(myData, 'ranking_num', 0);
    if (rank < 0) {
      return '--';
    } else {
      const found = rewardList.find(
        (item) => Number(rank) >= Number(item.tap_position) && Number(rank) <= Number(item.end_tap_position),
      );
      return found ? `${currency_symbol}${getShowMoney(found.balance)}` : '--';
    }

    // const p = getTourPrize(get(myData, 'ranking_num', ''), rewardList);
    // return `${p == '--' ? '' : currency_symbol}${p}`;
  };

  const getShowScore = (myData) => {
    const rank = get(myData, 'ranking_num', 0);

    if (rank < 0) {
      return '--';
    } else {
      return getShowMoney(myData?.total_bet_amount);
    }

    // if (rank === 0) {
    //   return '--';
    // } else {
    //   return `${getShowMoney(get(myData, 'total_bet_amount', 0))}`;
    // }
  };

  return (
    <div className="flex bg-[var(--colour-34)] rounded-normal overflow-hidden font-[500] text-[.28rem]">
      <div className="w-[33.33%]">
        <MyDataItem className="text-[var(--colour-38)]" title={t('tour.ranking')} val={getShowRank(myData)} />
      </div>
      <div className="w-[33.33%]">
        <MyDataItem title={t('tour.score')} val={getShowScore(myData)} className="text-[var(--colour-26)]" />
      </div>
      <div className="w-[33.33%]">
        <MyDataItem
          isRightBorder={false}
          title={t('tour.prize')}
          val={getShowPrize(myData, rewardList)}
          className={'text-[var(--colour-24)]'}
        />
      </div>
    </div>
  );
};

export default MyData;
