import DesignConfirm from './design/index';
import NormalConfirm from './normal/index';
import { isControllableSite } from '../../utils/helper';

export default function Index({
  handleClose,
  handleCancel,
  handleConfirm,
  children,
  disableClose,
  title = 'Tips',
  cancelText = 'Cancel',
  confirmText = 'OK',
  isConfirmBtnPrimary = true,
}) {
  return isControllableSite() ? (
    <DesignConfirm
      handleClose={handleClose}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      children={children}
      disableClose={disableClose}
      title={title}
      cancelText={cancelText}
      confirmText={confirmText}
      isConfirmBtnPrimary={isConfirmBtnPrimary}
    />
  ) : (
    <NormalConfirm
      handleClose={handleClose}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      children={children}
      disableClose={disableClose}
      title={title}
      cancelText={cancelText}
      confirmText={confirmText}
      isConfirmBtnPrimary={isConfirmBtnPrimary}
    />
  );
}
