import { useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setSoundFlag } from '../../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import useHandleLogic from '../../../hooks/useHandleLogic';
import useRouterHelper from '../../../hooks/useRouterHelper';
import HeaderWallet from '../headerWallet';
import HeaderUser from '../headerUser';
import Icon, { IconName } from '../../icon';
import LoginBtn from '../loginBtn';
import RegisterBtn from '../registerBtn';
import cn from 'classnames';
import PrivateLette from './privateLetter';
import { GetLayoutChildrenKeys, isControllableSite, getTemplateId } from '../../../utils/helper';
import LayoutConfig from './layoutConfig.json';
import Search from '../search';

const ManMade = () => {
  const { handleClickManMadeInPage } = useHandleLogic();
  return (
    <div
      className="hidden w-[.96rem] h-[.96rem] justify-center items-center cursor-pointer active:scale-[.95] transition-all select-none rounded-normal bg-[var(--colour-34)] text-[var(--colour-37)]"
      onClick={handleClickManMadeInPage}
    >
      <div className="w-[.48rem] h-[.44rem] ">
        <Icon name={IconName.ManMade} />
      </div>
    </div>
  );
};

export default function Index() {
  const { userObj, unReadCount, defaultLang, needUpdateBalance, identity, showSupportNew } = useSelector(
    (state) => ({
      unReadCount: get(state, 'app.unReadCount'),
      userObj: get(state, 'user.userObj'),
      defaultLang: get(state, 'user.defaultLang'),
      needUpdateBalance: state.app.needUpdateBalance,
      showSupportNew: state.app.showSupportNew,
      identity: state.user.identity,
    }),
    shallowEqual,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleClickWallet } = useRouterHelper();
  const { hanldeRefurbishBalance, balanceLoad } = useHandleLogic();

  useEffect(() => {
    if (!identity) {
      dispatch(setSoundFlag(false)); //退出登录后清除保存的sound按钮
    }
  }, [identity]);

  return (
    <div className="flex items-center">
      {userObj ? (
        <div className="flex space-x-[.12rem] items-center">
          {GetLayoutChildrenKeys(LayoutConfig, 'logged', getTemplateId()).map((item, index) => {
            switch (item) {
              case 'HeaderWallet':
                return (
                  <HeaderWallet
                    clickFreshen={hanldeRefurbishBalance}
                    balanceLoad={balanceLoad}
                    clickWallet={() => {
                      handleClickWallet();
                    }}
                    key={index}
                  />
                );
              case 'HeaderUser':
                return <HeaderUser key={index} />;
              case 'PrivateLette':
                return <PrivateLette key={index} />;
              case 'Search':
                return <Search key={index} />;
            }
          })}
        </div>
      ) : (
        <div
          className={cn('flex items-center', {
            'space-x-[.16rem]': isControllableSite(),
            'space-x-[.08rem]': !isControllableSite(),
          })}
        >
          {GetLayoutChildrenKeys(LayoutConfig, 'notLogged', getTemplateId()).map((item, index) => {
            switch (item) {
              case 'LoginBtn':
                return <LoginBtn key={index} />;
              case 'RegisterBtn':
                return <RegisterBtn key={index} />;
              case 'Search':
                return <Search key={index} />;
            }
          })}
        </div>
      )}
    </div>
  );
}
