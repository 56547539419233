import { Trans, useTranslation } from 'react-i18next';
import HeaderBack from '../../../../components/headerBack';
import useGoBack from '../../../../hooks/useGoBack';
import CardBox from './components/cardBox';
// import Picture from './components/picture';
import ActivityInfo from './components/activityInfo';
import DepositTip from './components/depositTip';
// import WagerTip from './components/wagerTip';
// import DayList from './components/dayList';
// import BonusConfigList from './components/bonusConfigList';
import SettleInfos from './components/settleInfos';
import DialogModal from '../../../../components/dialogModal';
import { useEffect, useState } from 'react';
import MysteryBonusGameList from './components/mysteryBonusGameList';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import { settleButtonType, depositLevelType } from '../../helper/typeList';
import { GetActivityMysteryList, PromotionPendingClaim } from '../../../../api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setRefetchActivityData,
} from '../../../../redux/reducers/appSlice';
import { toast } from 'react-toastify';
import { cmsImgUrl, getIdentity, getShowMoney, openHeaderGiftMoney } from '../../../../utils/helper';
import { getCenterPosition } from '../../../../hooks/useMinimizeAnimation';
import { getMaxIndex } from '../../helper/getMaxIndex';
import Days from './components/days';
import BonusLevelList from './components/bonusLevelList';
import PlayButton from './components/playButton';
// import useActivityStatus from '../../../../hooks/useActivityStatus';
import moment from 'moment';
import useRouterHelper from '../../../../hooks/useRouterHelper';
import Loading3 from '../../../../components/loading3';
import cn from 'classnames';
import ImageLoad from '../../../../components/imageLoad';
import DownloadManager from '../../../../manager/downloadManager';
import PwafooterModal from '../../../../components/dialogModal/downLoadBtn';
import { findIndex } from 'lodash';
import AnalyticsManager, { FirebaseEvents } from '../../../../manager/analyticsManager';

export default function NTemplate001() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goBack = useGoBack();
  const { h } = useWindowHeight(1.1);
  const { handleClickWallet } = useRouterHelper();

  const { currency_symbol, gift_money_menu_show_switch, identity, defaultLang, activityStatus } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      promotionPendingUpdateTimestamp: state?.app?.promotionPendingUpdateTimestamp,
      gift_money_menu_show_switch: state?.app?.activityStatus?.gift_money_menu_show_switch,
      identity: state?.user?.identity,
      defaultLang: state?.user?.defaultLang,
      activityStatus: state.app.activityStatus,
    }),
    shallowEqual,
  );

  const getFakeDayList = () => {
    const getFakeBonusList = () =>
      Array(7)
        .fill()
        .map((item, index) => {
          return {
            index: index,
            id: index,
            depositNum: (index + 1) * 10,
            showedBonus: (index + 1) * 10,
            list: Array(2)
              .fill()
              .map((item, index2) => {
                return {
                  index: index2,
                  id: index2,
                  wagerNum: (index2 + 1) * 10,
                  bonusNum: (index + 1) * 10 + (index2 + 1),
                };
              }),
          };
        });

    const getIsDayChecked = (item, index) => {
      return [1, 4].includes(index);
    };

    const getIsDayRedDot = (item, index) => {
      return [2, 6].includes(index);
    };

    return Array(5)
      .fill()
      .map((item, index) => {
        return {
          index: index,
          dayNum: index + 1,
          isChecked: getIsDayChecked(item, index),
          isRedDot: getIsDayRedDot(item, index),
          bonusConfigList: getFakeBonusList(),
          userDepositNum: 3,
          userWagerNum: 1,
          liquidTime: '2025-01-02 04:12:40',
          joinTime: '2025-01-02 04:12:40',
          ruleText: `${index + 1} day description day description day description day description day description day description day description.`,
          isOngoing: index < 1,
        };
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isShowGamelist, setIsShowGamelist] = useState(false);
  const [activityId, setActivityId] = useState('');
  const [claimId, setClaimId] = useState('');
  const [dayDataList, setDayDataList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentData, setCurrentData] = useState(null);

  // const [isShowWagerTip, setIsShowWagerTip] = useState(false);
  const [firstPageSize, setFirstPageSize] = useState(3);
  const [currentDepositIndex, setCurrentDepositIndex] = useState(-1);
  // const [currentWagerIndex, setCurrentWagerIndex] = useState(-1);
  // const [totalConfig, setTotalConfig] = useState(0);
  const [depositStatusType, setDepositStatusType] = useState(depositLevelType.level_no);
  const [maxBonus, setMaxBonus] = useState(0);
  const [depositMoreNum, setDepositMoreNum] = useState('');
  const [depositMoreNum2, setDepositMoreNum2] = useState('');
  // const [isMaxWagerlevel, setIsMaxWagerlevel] = useState(false);
  // const [userDepositNum, setUserDepositNum] = useState(0);
  // const [userWagerNum, setUserWagerNum] = useState(0);
  // const [nextWagerNum, setNextWagerNum] = useState(0);
  const [ruleText, setRuleText] = useState('');
  const [imgSrc, setImgSrc] = useState('');

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  console.log('dayDataList', dayDataList);
  console.log('currentDayData', currentData);
  console.log('currentDepositIndex', currentDepositIndex);
  // console.log('currentWagerIndex', currentWagerIndex);
  // console.log('totalConfig', totalConfig);
  console.log('depositStatusType', depositStatusType);
  console.log('depositMoreNum', depositMoreNum);
  console.log('depositMoreNum2', depositMoreNum2);
  // console.log('isMaxWagerlevel', isMaxWagerlevel);
  // console.log('nextWagerNum', nextWagerNum);

  useEffect(() => {
    updateData(true);
  }, [identity]);

  useEffect(() => {
    if (dayDataList[currentIndex]) {
      setCurrentData(dayDataList[currentIndex]);
    } else {
      setCurrentData(null);
    }
  }, [dayDataList, currentIndex]);

  const getSettleType = (status) => {
    // 11:结算中,20:未到领奖时间,21:可领取,22:已领取,30:奖励过期, 1:未报名

    // return settleButtonType.claimable;

    let result = '';

    switch (status) {
      case 11:
        result = settleButtonType.pending;
        break;

      case 20:
        result = settleButtonType.notYet;
        break;

      case 21:
        result = settleButtonType.claimable;
        break;

      case 22:
        result = settleButtonType.received;
        break;

      case 30:
        result = settleButtonType.expired;
        break;

      case 1: {
        result = settleButtonType.notAttend;
        break;
      }

      default:
        break;
    }

    // console.log('getSettleType', 'status', status, 'result', result);

    return result;
  };

  useEffect(() => {
    if (currentData) {
      const _userDepositNum = currentData?.user_progress?.deposit_amount;
      // setUserDepositNum(_userDepositNum);

      // const _userWagerNum = currentData?.userWagerNum;
      // setUserWagerNum(_userWagerNum);

      // const getFirstPageSize = (index) => {
      //   const size = index + 1 > 3 ? index + 1 : 3;
      //   return size;
      // };

      const getDepositStatusType = (_currentDepositIndex, lastIndex) => {
        if (_currentDepositIndex < 0) {
          return depositLevelType.level_no;
        } else if (_currentDepositIndex == lastIndex) {
          return depositLevelType.level_max;
        } else {
          return depositLevelType.level_other;
        }
      };

      // const getDepositStatusType = (userDepositNum, minDepositNum, maxDepositNum) => {
      //   if (userDepositNum < minDepositNum) {
      //     return depositLevelType.level_no;
      //   } else if (userDepositNum >= maxDepositNum) {
      //     return depositLevelType.level_max;
      //   } else {
      //     return depositLevelType.level_other;
      //   }

      // };

      // const getCurrentWagerIndex = (depositIndex) => {
      //   const list = (currentData?.bonusConfigList[depositIndex]?.list || []).map((item) => item?.wagerNum);
      //   return getMaxIndex(currentData?.userWagerNum, list);
      // };

      const configList = currentData?.reward_tier || [];

      if (configList.length > 0) {
        const _currentDepositIndex = getMaxIndex(
          _userDepositNum,
          configList.map((item) => item?.deposit_amount),
        );

        // setFirstPageSize(getFirstPageSize(_currentDepositIndex));
        setCurrentDepositIndex(_currentDepositIndex);
        // const _currentWagerIndex = getCurrentWagerIndex(_currentDepositIndex);
        // setCurrentWagerIndex(_currentWagerIndex);
        setDepositStatusType(getDepositStatusType(_currentDepositIndex, configList.length - 1));
        // setTotalConfig(configList.length);

        if (_currentDepositIndex < configList.length - 1) {
          const nextConfig = configList[_currentDepositIndex + 1];
          if (nextConfig) {
            setDepositMoreNum(nextConfig?.deposit_amount - _userDepositNum);

            // const wagerList = nextConfig?.list || [];
            // if (wagerList.length > 0) {
            //   setDepositMoreNum2(wagerList[wagerList.length - 1]?.bonusNum);
            // }

            setDepositMoreNum2(nextConfig?.max_display_amount);
          }
        }

        // const getIsMaxWagerlevel = () => {
        //   if (_currentDepositIndex >= 0) {
        //     const wagerList = configList[_currentDepositIndex]?.list || [];
        //     console.log('wagerList', wagerList);

        //     const _isMax = wagerList.every((item, index) => {
        //       return _userWagerNum >= item?.wagerNum;
        //     });

        //     return _isMax;
        //   }
        // };
        // setIsMaxWagerlevel(getIsMaxWagerlevel());

        // const getNextWagerNum = () => {
        //   if (_currentDepositIndex >= 0) {
        //     const wagerList = configList[_currentDepositIndex]?.list || [];
        //     if (_currentWagerIndex < wagerList.length - 1) {
        //       return wagerList[_currentWagerIndex + 1]?.wagerNum;
        //     }
        //   }
        // };

        // setNextWagerNum(getNextWagerNum());
        setClaimId(currentData?.user_pending?.id);
        setMaxBonus(currentData?.reward_tier[currentData?.reward_tier.length - 1]?.max_display_amount || 0);
      }
    }
  }, [currentData]);

  // useEffect(() => {
  //   let max = 0;

  //   if (dayDataList.length > 0) {
  //     dayDataList.forEach((item) => {
  //       (item?.bonusConfigList || []).forEach((item2) => {
  //         (item2?.list || []).forEach((item3) => {
  //           if (item3?.bonusNum > max) {
  //             max = item3.bonusNum;
  //           }
  //         });
  //       });
  //     });

  //     setMaxBonus(max);
  //   }
  // }, [dayDataList]);

  const getDefaultCurrentIndex = (list) => {
    if (list.length > 0) {
      // 是否已经报名
      const isNotJoined = list[0]?.user_progress?.status == 1;

      if (isNotJoined) {
        return 0;
      } else {
        const now = Date.now();

        const _index = list.findIndex((item, index) => {
          const prevItem = list[index - 1];
          const startTime = prevItem
            ? prevItem?.user_progress?.settle_time * 1000
            : item?.user_progress?.signup_time * 1000;
          const endTime = item?.user_progress?.settle_time * 1000;
          return now >= startTime && now < endTime;
        });

        if (_index >= 0) {
          return _index;
        } else {
          return list.length - 1;
        }
      }
    }
  };

  const updateData = (isShowLoading = false) => {
    // console.log('updateData');
    // setDayDataList(getFakeDayList());

    isShowLoading && setIsLoading(true);

    GetActivityMysteryList({ lang: defaultLang })
      .then((res) => {
        if (res.code == 0) {
          const data = res?.data;
          console.log('data', data);
          setActivityId(data?.activity_id);
          setDayDataList(data?.list || []);
          setRuleText(data?.rule_explain?.activity_rule_explain || '');
          setImgSrc(cmsImgUrl(data?.rule_explain?.activity_img || ''));
          // console.log('getDefaultCurrentIndex', getDefaultCurrentIndex(data?.list || []));
          setCurrentIndex(getDefaultCurrentIndex(data?.list || []));
        }
      })
      .finally(() => {
        isShowLoading && setIsLoading(false);
      });
  };

  const handleDayItemClick = (index) => {
    setCurrentIndex(index);
  };

  const handleCloseGamelist = () => {
    setIsShowGamelist(false);
  };

  const handleGoGamelist = () => {
    setIsShowGamelist(true);
  };

  const handleGetBonus = () => {
    PromotionPendingClaim({
      id: claimId,
    }).then((res) => {
      // res = {
      //   ...res,
      //   code: 0,
      //   data: {
      //     currency: 'gift_money',
      //     // currency: 'balance',
      //     amount: 12345678,
      //   },
      // };

      if (res.code == 0) {
        // if (true) {
        const data = res.data;
        const currency = data.currency;
        const amount = data.amount;

        dispatch(setNeedUpdateBalance({ need: true }));
        dispatch(setRefetchActivityData());
        updateData();

        const balanceTip = () => {
          toast.success(
            t('tour.claim_success', {
              val: currency_symbol,
              val1: getShowMoney(amount, 2, 2),
            }),
            {
              containerId: 'global',
            },
          );
        };

        const giftMoneyTip = () => {
          const element = document.querySelector('#mystery_bonus_claim_button');
          if (element) {
            const fromPoints = [getCenterPosition(element, true)];
            openHeaderGiftMoney(amount, fromPoints);
          }
        };

        if (currency == 'gift_money') {
          if (gift_money_menu_show_switch == '1') {
            giftMoneyTip();
          } else {
            balanceTip();
          }
        } else if (currency == 'balance') {
          balanceTip();
        }
      } else {
        if (res.code == 1008127) {
          if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
            setBottomDownloadBoxPromptSwitch({
              visible: true,
              isForce: true,
              downloadText: t('guideWheel.downloadtips'),
            });
          }
        } else {
          toast.error(t(`${res.code}`), {
            containerId: 'global',
          });
        }
      }
    });
  };

  const getSettleBtnHandler = (key) => {
    switch (key) {
      case settleButtonType.claimable: {
        return () => {
          handleGetBonus();
        };
      }

      case settleButtonType.received:
      case settleButtonType.expired:
      case settleButtonType.pending:
      case settleButtonType.notYet:
      case settleButtonType.notAttend: {
        return () => {};
      }

      default:
        return () => {};
    }
  };

  const showLogin = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
  };

  useEffect(() => {
    if (activityStatus != null) {
      dispatch(setRefetchActivityData());
    }
  }, [activityStatus]);

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  const getSettleTypeByCurrentData = (data) => {
    return getSettleType(data?.user_progress?.status);
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '52',
            borderRadius: '10px',
          },
        }}
        isOpen={isShowGamelist}
        handleClose={() => {
          setIsShowGamelist(false);
        }}
      >
        <MysteryBonusGameList
          handleClose={handleCloseGamelist}
          handleBack={handleCloseGamelist}
          activity_id={activityId}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <PwafooterModal
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <div
        className={cn('bg-[var(--colour-68)]', {
          // 'w-[7.5rem]': isFromGame,
        })}
      >
        <HeaderBack
          title={t('mystery_bonus.mystery_bonus')}
          onClick={() => {
            goBack(true);
          }}
        />

        <div className="flex m-[.2rem_0rem_0_0rem] ">
          {/* <div className="shrink-0 w-[1.9rem]">
            <DayList list={dayDataList} currentDayIndex={currentIndex} handleDayItemClick={handleDayItemClick} />
          </div> */}

          <div className="flex-1 overflow-y-auto scrollbar-none space-y-[.2rem]" style={{ height: `${h}rem` }}>
            {/* <div onClick={() => setIsShowGamelist(true)}>showGameList {currentData?.index}</div> */}

            {isLoading ? (
              <Loading3 className1="h-full" />
            ) : (
              currentData && (
                <>
                  <CardBox>
                    <ImageLoad src={imgSrc} />

                    <div className="pt-[.2rem]">
                      <Days list={dayDataList} currentDayIndex={currentIndex} handleDayItemClick={handleDayItemClick} />
                    </div>

                    <ActivityInfo
                      val1={
                        currentData?.user_progress?.signup_time == 0
                          ? '--'
                          : moment(currentData?.user_progress?.signup_time * 1000).format('YYYY-MM-DD HH:mm:ss')
                      }
                      val2={`${currency_symbol} ${getShowMoney(currentData?.user_progress?.deposit_amount || 0, 2, 2)}`}
                      val3={
                        currentData?.user_progress?.settle_time == 0
                          ? '--'
                          : moment(currentData?.user_progress?.settle_time * 1000).format('YYYY-MM-DD HH:mm:ss')
                      }
                      val4={`${currency_symbol} ${getShowMoney(maxBonus, 2, 2)}`}
                      dayNum={currentData?.settle_day_offset}
                    />

                    {Date.now() < currentData?.user_progress?.settle_time * 1000 ||
                    currentData?.user_progress?.signup_time == 0 ? (
                      // {true ? (
                      // 结算时间未到 或 未报名
                      <>
                        <DepositTip
                          isShowDepositButton={[depositLevelType.level_no, depositLevelType.level_other].includes(
                            depositStatusType,
                          )}
                          text={
                            currentData?.user_progress?.signup_time == 0 ? (
                              t('mystery_bonus.depositTip4')
                            ) : depositStatusType == depositLevelType.level_no ? (
                              <Trans
                                t={t}
                                i18nKey={'mystery_bonus.depositTip1'}
                                components={[<span className="text-[var(--colour-26)]" key={0}></span>]}
                                values={{
                                  val: `${currency_symbol} ${getShowMoney(depositMoreNum, 2, 2)}`,
                                  val2: `${currency_symbol} ${getShowMoney(depositMoreNum2, 2, 2)}`,
                                }}
                              />
                            ) : depositStatusType == depositLevelType.level_other ? (
                              <>
                                <Trans
                                  t={t}
                                  i18nKey={'mystery_bonus.depositTip2'}
                                  components={[<span className="text-[var(--colour-26)]" key={0}></span>]}
                                  values={{
                                    val: `${currency_symbol} ${getShowMoney(depositMoreNum, 2, 2)}`,
                                    val2: `${currency_symbol} ${getShowMoney(depositMoreNum2, 2, 2)}`,
                                  }}
                                />
                              </>
                            ) : depositStatusType == depositLevelType.level_max ? (
                              <>{t('mystery_bonus.depositTip3')}</>
                            ) : (
                              <></>
                            )
                          }
                          handleClick={() => {
                            if (getIdentity()) {
                              handleClickWallet();
                            } else {
                              showLogin();
                            }
                            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Mb_Click_Details_Deposit);
                          }}
                        />

                        {currentData?.user_progress?.signup_time != 0 && (
                          <PlayButton onClick={getIdentity() ? handleGoGamelist : showLogin} />
                        )}

                        {/* {isShowWagerTip && (
                            <>
                              <WagerTip
                                tipText={t(isMaxWagerlevel ? 'mystery_bonus.wagerTip2' : 'mystery_bonus.wagerTip1')}
                                onClick={handleGoGamelist}
                                isShowBtn={!isMaxWagerlevel}
                                num={userWagerNum}
                                totalNum={nextWagerNum}
                              />
                            </>
                          )} */}
                      </>
                    ) : Date.now() >= currentData?.user_progress?.settle_time * 1000 ? (
                      // 结算时间已到
                      <>
                        <SettleInfos
                          type={getSettleTypeByCurrentData(currentData)}
                          onClick={getSettleBtnHandler(getSettleTypeByCurrentData(currentData))}
                          claimStartTime={currentData?.user_pending?.claim_start_time}
                          // claimStartTime={Date.now() + 1000 * 10}
                          onComplete={() => {
                            console.error('onComplete:' + JSON.stringify(currentData));
                            updateData(false);
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </CardBox>

                  <CardBox>
                    <div className="p-[.2rem_.2rem_.26rem_.2rem]">
                      <div className="text-[var(--colour-48)] font-[500] text-[.24rem] border-b border-[var(--colour-6)] pb-[.2rem]">
                        {t('mystery_bonus.title1')}
                      </div>

                      {/* <BonusConfigList
                          list={currentData?.bonusConfigList || []}
                          firstPageSize={firstPageSize}
                          currentDepositIndex={currentDepositIndex}
                          currentWagerIndex={currentWagerIndex}
                        /> */}

                      <BonusLevelList list={currentData?.reward_tier || []} firstPageSize={firstPageSize} />
                    </div>
                  </CardBox>

                  <CardBox>
                    <div className="p-[.2rem]">
                      <div className="text-[var(--colour-48)] font-[500] text-[.24rem]">
                        {t('mystery_bonus.title2')}
                      </div>
                      <div className="h-[1px] my-[.2rem] bg-[var(--colour-6)]"></div>
                      <div
                        className="text-[var(--colour-44)] font-[500] text-[.2rem]"
                        dangerouslySetInnerHTML={{ __html: ruleText }}
                      ></div>
                    </div>
                  </CardBox>

                  <div className="h-[1px]"></div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}
