import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { DialogModalChildrenKey, setDialogModalVisible } from '../redux/reducers/appSlice';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../utils/localePath';
import { isControllableSite } from '../utils/helper';
import { useGlobalNavigate } from '../contexts/navigateContext';

/**
 * 全局引导
 */
const GlobalGuide = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { guideToBind } = useSelector(
    (state) => ({
      guideToBind: get(state, 'app.guideToBind', ''),
    }),
    shallowEqual,
  );

  // 监听绑定引导
  useEffect(() => {
    if (guideToBind.timestamp !== 0) {
      guideBindAction(guideToBind?.showGuideTips);
    }
  }, [guideToBind]);

  /**
   * 绑定引导行为
   * @param {boolean} showGuideTips
   */
  const guideBindAction = (showGuideTips) => {
    const clickConfirmCb = () => {
      if (isControllableSite()) {
        navigate(toLocalePath(`/security`));
      } else {
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.AccountSetting,
          }),
        );
      }
    };
    const confirmCancelCb = () => {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.Default,
        }),
      );
    };
    if (showGuideTips) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.Confirm,
          content: t('account.guide_bind_tips'),
          confirmCb: clickConfirmCb,
          confirmCancelCb: confirmCancelCb,
          title: t('account.guide_bind_title'),
          cancelText: t('account.guide_bind_cancel'),
          confirmText: t('account.guide_bind_ok'),
          shouldCloseOnOverlayClick: false,
        }),
      );
    } else {
      clickConfirmCb();
    }
  };

  return null;
};

export default GlobalGuide;
