import React, { useState, useEffect, memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { GameListReq, GetFavouritesListReq } from '../api';
import GameItem from '../components/gameItem';
import NoData from '../components/noData';
import { useStateIfMounted } from 'use-state-if-mounted';
import Loading from '../components/loading2';
import LoadMore from '../components/loadMore';
import { toast } from 'react-toastify';
import { isUseLayoutMobileHome } from '../utils/helper';
import cn from 'classnames';
import { get } from 'lodash';
import LoadMoreText from './loadMoreText';

const specialList = ['001'];
const pageSize = window.innerWidth <= 576 ? 12 : 28;
const layoutMobilePageSize = 9;
export const page_size = isUseLayoutMobileHome() ? layoutMobilePageSize : pageSize;

const HomeGameList = ({
  tagsIndex,
  providerSelect,
  isProvider,
  searchVal,
  isSearch,
  setTagsIndex,
  gameListGridClassName = '',
  useNewNoData = false,
  loadMoreWithText = false,
  lang_key_title = '',
}) => {
  // const { userObj } = useSelector((state) => ({
  //   userObj: state.user.userObj,
  // }));
  const {} = useSelector((state) => ({}), shallowEqual);

  const [list, setList] = useStateIfMounted([]);
  const [reRequest, setreRequest] = useStateIfMounted('');
  const [loading, setLoading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [total, setTotal] = useStateIfMounted(0);
  const [currentNum, setCurrentNum] = useStateIfMounted(0);

  const [params, setParams] = useState({
    name: undefined,
    category: '',
    uuid: undefined,
    page: 1,
    page_size: page_size,
    sort: 0,
    provider: undefined,
  });

  useEffect(() => {
    if (providerSelect === '' && !isSearch) {
      let category = '';
      if (tagsIndex !== '001') {
        category = tagsIndex;
      }
      if (tagsIndex === '002') {
        category = '';
      }
      setParams({
        ...params,
        category,
        page_size: page_size,
        provider: undefined,
        search: undefined,
        limit: undefined,
        page: 1,
      });
    }
  }, [tagsIndex, providerSelect, isSearch]);

  useEffect(() => {
    if (providerSelect !== '') {
      setParams({
        name: undefined,
        category: '',
        uuid: undefined,
        page: 1,
        page_size: page_size,
        sort: 0,
        provider: providerSelect,
      });
    }
  }, [isProvider, providerSelect]);

  useEffect(() => {
    if (isSearch) {
      setParams({
        name: undefined,
        category: '',
        uuid: undefined,
        page: 1,
        page_size: 100,
        sort: 0,
        provider: undefined,
        search: searchVal,
        limit: 100,
      });
    }
  }, [isSearch, searchVal]);

  useEffect(() => {
    if (params.page == 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    const getGameList = () => {
      const actionList = [
        {
          // 游戏分类
          test: () => !specialList.includes(tagsIndex) && !isProvider && !isSearch && tagsIndex !== '002',
          action: () => {
            if (params.category) {
              GameListReq(params).then((res) => {
                if (params.page == 1) {
                  setLoading(false);
                } else {
                  setLoadingMore(false);
                }
                if (res.code == 0) {
                  const result = res.result;
                  if (params.page == 1) {
                    setList(result.list);
                  } else {
                    const list2 = list.concat(result.list);
                    setList(list2);
                  }

                  const _pageCount = Math.ceil(result.pages.count / page_size);
                  setTotal(result.pages.count);

                  if (params.page < _pageCount) {
                    setShowLoadingMore(true);
                  } else {
                    setShowLoadingMore(false);
                  }
                }
              });
            }
          },
        },
        {
          // 收藏
          test: () => tagsIndex === '001' && !isProvider && !isSearch && tagsIndex !== '002',
          action: () => {
            GetFavouritesListReq(params).then((res) => {
              if (params.page == 1) {
                setLoading(false);
              } else {
                setLoadingMore(false);
              }
              if (res.code == 0) {
                const result = res.result;
                if (params.page == 1) {
                  setList(result.list);
                } else {
                  const list2 = list.concat(result.list);
                  setList(list2);
                }

                const _pageCount = Math.ceil(result.pages.count / page_size);
                setTotal(result.pages.count);
                if (params.page < _pageCount) {
                  setShowLoadingMore(true);
                } else {
                  setShowLoadingMore(false);
                }
              } else {
                // toast.info(res.msg, {
                //   containerId: 'global',
                // });
                setTagsIndex('000');
                setList([]);
                setShowLoadingMore(false);
              }
            });
          },
        },
        {
          // provider
          test: () => isProvider && !isSearch && tagsIndex === '002',
          action: () => {
            GameListReq(params).then((res) => {
              if (params.page == 1) {
                setLoading(false);
              } else {
                setLoadingMore(false);
              }
              if (res.code == 0) {
                const result = res.result;
                if (params.page == 1) {
                  setList(result.list);
                } else {
                  const list2 = list.concat(result.list);
                  setList(list2);
                }

                const _pageCount = Math.ceil(result.pages.count / page_size);
                setTotal(result.pages.count);

                if (params.page < _pageCount) {
                  setShowLoadingMore(true);
                } else {
                  setShowLoadingMore(false);
                }
              }
            });
          },
        },
        {
          // 搜索
          test: () => isSearch && !isProvider && tagsIndex === '002',
          action: () => {
            GameListReq(params).then((res) => {
              if (params.page == 1) {
                setLoading(false);
              } else {
                setLoadingMore(false);
              }
              if (res.code == 0) {
                const result = res.result;
                if (params.page == 1) {
                  setList(result.list);
                } else {
                  const list2 = list.concat(result.list);
                  setList(list2);
                }

                const _pageCount = Math.ceil(result.pages.count / page_size);
                setTotal(result.pages.count);

                setShowLoadingMore(false);
              }
            });
          },
        },
        {
          // all
          test: () => tagsIndex === '002' && !isProvider && !isSearch,
          action: () => {
            GameListReq(params).then((res) => {
              if (params.page == 1) {
                setLoading(false);
              } else {
                setLoadingMore(false);
              }
              if (res.code == 0) {
                const result = res.result;
                if (params.page == 1) {
                  setList(result.list);
                } else {
                  const list2 = list.concat(result.list);
                  setList(list2);
                }

                const _pageCount = Math.ceil(result.pages.count / page_size);
                setTotal(result.pages.count);

                if (params.page < _pageCount) {
                  setShowLoadingMore(true);
                } else {
                  setShowLoadingMore(false);
                }
              }
            });
          },
        },
      ];

      const found = actionList.find((item) => item.test());
      found && found.action();
    };

    const timeId = setTimeout(getGameList, 1000);

    return () => {
      clearTimeout(timeId);
    };
  }, [params, reRequest]);

  const handleFavoriteCallback = () => {
    setreRequest(new Date().getTime());
  };

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  useEffect(() => {
    setCurrentNum(list.length);
  }, [list]);

  return (
    <>
      <div>
        {loading ? (
          <Loading height="4rem" />
        ) : (
          <>
            {list && list.length > 0 ? (
              <div className={cn(`games__row ${gameListGridClassName}`, {})}>
                {list &&
                  list.length > 0 &&
                  list.map((item, index) => (
                    <GameItem
                      model={item}
                      key={item.id}
                      handleFavoriteCallback={handleFavoriteCallback}
                      handleFavoriteNeedCallback={tagsIndex == '001'}
                    />
                  ))}
              </div>
            ) : (
              <NoData className="py-[.4rem]" />
            )}
            {showLoadingMore ? (
              loadMoreWithText ? (
                <LoadMoreText
                  className={`mt-[.32rem]`}
                  onClick={handleLoadMore}
                  showLoading={loadingMore && params.page > 1}
                  currentNum={currentNum}
                  total={total}
                  lang_key_title={lang_key_title}
                />
              ) : (
                <LoadMore
                  className={'mt-[.16rem]'}
                  onClick={handleLoadMore}
                  showLoading={loadingMore && params.page > 1}
                />
              )
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(HomeGameList);
