// import HeaderSidebarSwitch from '../../headerSidebarSwitch';
import HeaderSportSwitch from '../../headerSportSwitch';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LogoBox from '../../../logoBox';
import { isControllableSite, isLogin, isLightTheme } from '../../../../utils/helper';
// import HeaderWallet from '../../headerWallet';
import useHandleLogic from '../../../../hooks/useHandleLogic';
// import HeaderUser from '../../headerUser';
// import PrivateLette from '../../headerRight/privateLetter';
// import LoginBtn from '../../loginBtn';
// import RegisterBtn from '../../registerBtn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  setDrawerMenuVisible,
  setDialogModalVisible,
  DialogModalChildrenKey,
} from '../../../../redux/reducers/appSlice';
// import Search from '../../search';
import Icon, { IconName } from '../../../icon';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

export default function DTemplate002() {
  const { hanldeRefurbishBalance, balanceLoad } = useHandleLogic();

  const { userObj } = useSelector(
    (state) => ({
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );

  return (
    <>
      <div className={cn('flex flex-col items-center header-box transition-[top]')}>
        <div
          className={`w-full flex flex-row items-center justify-between relative bg-[var(--colour-4)] h-[var(--header-box-height)] pl-[.16rem] pr-[.2rem] border-b-[1px] border-[var(--colour-6)]`}
        >
          <div className={cn('flex items-center space-x-[.16rem]')}>
            <>
              {/* <HeaderSidebarSwitch className="!w-[.4rem] !h-[.4rem]" iconClassName="!w-[.4rem] !h-[.4rem]" /> */}
              <LogoBox className="!h-[.64rem]" />
              <HeaderSportSwitch />
            </>
          </div>
          <div className="flex items-center">
            {userObj != null ? (
              <div className="flex space-x-[.12rem] items-center">
                <HeaderRight />
              </div>
            ) : (
              <div
                className={cn('flex items-center', {
                  'space-x-[.16rem]': isControllableSite(),
                  'space-x-[.08rem]': !isControllableSite(),
                })}
              >
                <HeaderRight />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const HeaderRight = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const { drawerMenuVisible, redeem_code_switch } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      redeem_code_switch: state?.app?.activityStatus?.redeem_code_switch,
    }),
    shallowEqual,
  );
  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  return (
    <div className="flex items-center">
      {redeem_code_switch == '1' && (
        <div
          className={cn('flex flex-col items-center justify-center mr-[.28rem]', {})}
          onClick={() => {
            handleLinkClick();
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.Redeem,
              }),
            );
          }}
        >
          <div className="w-[.46rem] h-[.46rem] text-[var(--colour-1)] mb-[.02rem]">
            {<Icon name={isLightTheme() ? IconName.RedeemLight : IconName.Redeem} />}
          </div>
          <div className="text-[.18rem] font-[400] leading-[.22rem] text-[var(--colour-48)]">
            {t('redeem.redeem_code')}
          </div>
        </div>
      )}
      <div
        className={cn('flex flex-col items-center justify-center', {
          'mr-[.47rem]': isLightTheme(),
          'mr-[.44rem]': !isLightTheme(),
        })}
        onClick={() => {
          handleLinkClick();
          navigate(toLocalePath(`/gamesearch?source=&sourceName=ALL`));
        }}
      >
        {isLightTheme() ? (
          <div className="w-[.44rem] h-[.44rem] text-[var(--colour-1)] mb-[.04rem]">
            <Icon name={IconName.HeaderSearch2} />
          </div>
        ) : (
          <div className="w-[.46rem] h-[.46rem] text-[var(--colour-1)] mb-[.02rem]">
            <Icon name={IconName.HeaderSearch} />
          </div>
        )}
        <div className="text-[.18rem] font-[400] leading-[.22rem] text-[var(--colour-48)]">{t('home.search')}</div>
      </div>
      <div
        className="flex flex-col items-center justify-center"
        onClick={() => {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.PlayerMusic,
            }),
          );
          handleLinkClick();
        }}
      >
        {isLightTheme() ? (
          <div className="w-[.44rem] h-[.44rem] text-[var(--colour-1)] mb-[.04rem]">
            <Icon name={IconName.HeaderMusic2} />
          </div>
        ) : (
          <div className="w-[.46rem] h-[.46rem] text-[var(--colour-1)] mb-[.02rem]">
            <Icon name={IconName.HeaderMusic} />
          </div>
        )}

        <div className="text-[.18rem] font-[400] leading-[.22rem] text-[var(--colour-48)]">{t('audio.music')}</div>
      </div>
    </div>
  );
};
