import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setGameExitTimestamp,
  setGamePopData,
  setGlobalLoadingFlag,
  setNeedUpdateRecently,
  setReloadCashRain,
  ResponsiveMode,
} from '../redux/reducers/appSlice';
import { get } from 'lodash';
import { GameInitReq } from '../api';
import { toLocalePath } from '../utils/localePath';
import { toast } from 'react-toastify';
import { isLogin, isPCLayout } from '../utils/helper';

const useGameInfo = () => {
  const dispatch = useDispatch();
  // 本地存储的最大的最近游戏数量
  const maxRecentGameCount = 30;
  const { gameUrl, gameHtml, defaultLang, responsiveMode, uid } = useSelector(
    (state) => ({
      gameUrl: get(state, 'app.gamePopData.gameUrl', ''),
      gameHtml: get(state, 'app.gamePopData.gameHtml', ''),
      defaultLang: get(state, 'user.defaultLang'),
      responsiveMode: get(state, 'app.responsiveMode'),
      uid: get(state, 'user.userObj.uid', ''),
    }),
    shallowEqual,
  );

  const gameInit = (uuid) => {
    dispatch(setGlobalLoadingFlag(true));
    GameInitReq({
      is_mobile: isPCLayout() ? '0' : '1',
      uuid,
      return_url: `${window.location.origin}${toLocalePath('/')}`,
    }).then((res) => {
      dispatch(setGlobalLoadingFlag(false));
      if (res.code === 0) {
        if (res.result.source === 0) {
          //自研游戏
          const initData = res.result;
          let urlStr = `${initData.game_url}?identity=${encodeURIComponent(
            initData.b9o_identity,
          )}&uid=${encodeURIComponent(initData.b9o_uid)}&currency=${encodeURIComponent(initData.currency)}&is_guest=${
            initData.is_guest
          }&gw=${encodeURIComponent(initData.gw)}&lang=${defaultLang}`;
          dispatch(
            setGamePopData({
              gameUrl: urlStr,
              source: res.result.source,
              gameHtml: '',
              gameUuid: uuid,
            }),
          );
        } else if (res.result.source === 13) {
          //自研游戏HappyGame
          const initData = res.result;
          let urlStr = `${initData.game_url}?identity=${encodeURIComponent(
            initData.happygame_identity,
          )}&uid=${encodeURIComponent(initData.happygame_uid)}&currency=${encodeURIComponent(
            initData.currency,
          )}&is_guest=${initData.is_guest}&gw=${encodeURIComponent(initData.gw)}&lang=${defaultLang}`;
          dispatch(
            setGamePopData({
              gameUrl: urlStr,
              source: res.result.source,
              gameHtml: '',
              gameUuid: uuid,
            }),
          );
        } else if (res.result.source === 27) {
          dispatch(
            setGamePopData({
              gameUrl: res.result.game_url,
              source: res.result.source,
              gameHtml: '',
              gameUuid: uuid,
            }),
          );
        } else {
          // 三方游戏
          dispatch(
            setGamePopData({
              gameUrl: res.result.url,
              source: res.result.source,
              gameHtml: res.result.html,
              gameUuid: uuid,
            }),
          );
        }
        const currentUUID = res.result.uuid;
        const recentGameListIds = localStorage.getItem(`recentGameList_${uid}`);
        if (recentGameListIds) {
          const recentGameListArr = Array.from(JSON.parse(recentGameListIds));
          if (!recentGameListArr.includes(currentUUID)) {
            recentGameListArr.unshift(currentUUID);
            const maxRecentGameListArr = recentGameListArr.slice(0, maxRecentGameCount);
            localStorage.setItem(`recentGameList_${uid}`, JSON.stringify(maxRecentGameListArr));
          }
        } else {
          localStorage.setItem(`recentGameList_${uid}`, JSON.stringify([currentUUID]));
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });

        if ([1000011, 1000014].includes(res.code)) {
          dispatch(setGameExitTimestamp(Date.now()));
        }
      }
    });
  };

  const handleClickGameItem = (uuid, handleClick = () => {}) => {
    if (!isLogin()) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
      return;
    }
    handleClick();
    gameInit(uuid);
  };

  // 确定关闭游戏的回调
  const ConfirmCloseGame = () => {
    dispatch(setGamePopData({ gameUrl: '', gameHtml: '', source: 0, gameUuid: '' })); // 关闭游戏
    dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default })); // 关闭弹窗
    dispatch(setNeedUpdateRecently({ need: true })); // 更新recently列表
    dispatch(setReloadCashRain()); //重新请求红包接口
  };

  return {
    ConfirmCloseGame,
    isGameOpen: gameUrl !== '' || gameHtml !== '',
    handleClickGameItem,
  };
};

export default useGameInfo;
