import { useTranslation } from 'react-i18next';
import Close2 from './close2';
import AuthCode from 'react-auth-code-input';
import { useState } from 'react';
import TwoFaButton from './twoFaButton';

const Enter2FACode = ({ handleClose, onClickButton = () => {}, uuIdentity = '' }) => {
  const { t } = useTranslation();

  const [code, setCode] = useState('');

  const handleOnChange = (val) => {
    setCode(val);
  };

  return (
    <>
      <div className="h-[7.54rem] p-[0_.32rem] bg-[var(--colour-35)] rounded-normal w-[6.86rem]">
        <div className="h-[1.12rem] w-full flex items-center justify-between">
          <div className="text-[.32rem] font-[700] leading-[.38rem]">{t('account.2fa_enter_code')}</div>
          <Close2 handleClose={handleClose} />
        </div>
        <div className="h-[1px] w-full bg-[var(--colour-6)]" />
        <div className="mt-[.36rem] leading-[110%] text-[#849eb7]">{t('account.2fa_desc1')}</div>
        <div className="mt-[.6rem] text-center text-[.32rem]">{t('account.verifacation_code')}</div>
        <div>
          <AuthCode
            autoFocus={false}
            placeholder="0"
            containerClassName="flex flex-row items-center justify-between mt-[.4rem]"
            inputClassName="w-[.88rem] h-[1.2rem] leading-[1.2rem] text-center border-0 border-b-[.08rem] font-[600] text-[1rem] authCode-input"
            onChange={handleOnChange}
            allowedCharacters="numeric"
          />
        </div>

        <TwoFaButton
          text={t('account.submint')}
          className="w-[6.22rem] h-[.96rem] m-[1.08rem_auto_0_auto] text-[.32rem] font-[700]"
          onClick={() => onClickButton(code, uuIdentity)}
        />
      </div>
    </>
  );
};

export default Enter2FACode;
