import { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import Close from '../close';
import Icon, { IconName } from '../icon';
import TippyPopBox, { Placement } from '../tippyPopBox';
import PopChoose, { PopChooseItem } from '../popChoose';
import Input from '../input';
import { PaymentAccWithdrawAddAccount } from '../../api/newDepositWithdraw';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';
import { setInputNum } from '../../utils/helper';
import { deleteCPF, formatCPF, phoneVerify, phoneVerifyRegex, phoneVerifyRegex2 } from '../../utils/inputHelper';

const maxPhoneLength = 11;
const maxCpfLength = 11;
const maxCnpjLength = 14;
const maxEmailLength = 100;
const maxEVPLength = 36;
const regex = /^(?=.{3,50}$)[^\s@]+@[^\s@]+\.[^\s@]+$/;
const regexNum = /^0/;
const AddPix = ({
  handleClose = () => {},
  verifyUuid = '',
  defaultName = '',
  defaultCpf = '',
  successCallback = () => {},
  methodList = [],
}) => {
  const { t } = useTranslation();

  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const [typeValue, setTypeValue] = useState(''); //根据当前选中的method填的值
  const [requesting, setRequesting] = useState(false);
  const [errorTip1, setErrorTip1] = useState(false);
  const [errorText1, setErrorText1] = useState('addpix_name');
  const [errorTipphone, setErrorTipphone] = useState(false);
  const [errorTextphone, setErrorTextphone] = useState('');

  const [errorTipcpf, setErrorTipcpf] = useState(false);
  const [errorTextcpf, setErrorTextcpf] = useState('');

  const [errorTipcnpj, setErrorTipcnpj] = useState(false);
  const [errorTextcnpj, setErrorTextcnpj] = useState('');
  const [errorTipemail, setErrorTipemail] = useState(false);
  const [errorTextemail, setErrorTextemail] = useState('');

  const [errorTipEVP, setErrorTipEVP] = useState(false);
  const [errorTextEVP, setErrorTextEVP] = useState('');

  const [params, setParams] = useState({
    verify_uuid: verifyUuid,
    config_id: '',
    method: '',
    name: defaultName ? defaultName : '',
    cpf: defaultCpf ? defaultCpf : '',
  });

  useEffect(() => {
    if (methodList && methodList.length > 0) {
      setParams({ ...params, method: methodList[0].method, config_id: methodList[0].config_id });
    }
  }, [methodList]);

  const [isShow, setIsShow] = useState(false); //下拉框是否展开

  const getFilterTypeStr = () => {
    let res = methodList.find((item) => {
      return item.method == params.method;
    });
    return res ? res.show_name : '';
  };

  const handleClickConfirm = () => {
    let requestParams;
    if (params.method == 'cpf') {
      requestParams = params;
    } else {
      requestParams = { ...params, [params.method]: typeValue };
    }
    requestParams = {
      ...requestParams,
      cpf: requestParams.cpf.replace(/[\.-]/g, ''),
      name: requestParams.name.trim(),
    };

    if (requestParams.name == '') {
      return setErrorTip1(true);
    }
    if (websiteInfo?.country_code == '55') {
      if (requestParams.method == 'phone' && (typeValue == '' || !phoneVerify(typeValue))) {
        return changeErrorPhone(typeValue);
      }
    } else {
      if (
        requestParams.method == 'phone' &&
        (typeValue == '' || regexNum.test(typeValue) || typeValue.length < maxPhoneLength)
      ) {
        return changeErrorPhone2(typeValue);
      }
    }

    if (requestParams.method == 'cnpj' && (typeValue == '' || typeValue.length < maxCnpjLength)) {
      return changeErrorCnpj(typeValue);
    }

    if (requestParams.method == 'email' && (typeValue == '' || !regex.test(typeValue))) {
      return changeErrorEmail(typeValue);
    }

    if (requestParams.method == 'evp' && (typeValue == '' || typeValue.length < maxEVPLength)) {
      return changeErrorEVP(typeValue);
    }

    if (requestParams.cpf == '' || requestParams.cpf.length < maxCpfLength || !/^\d+$/.test(requestParams.cpf)) {
      return changeErrorCpf(requestParams.cpf);
    }
    setRequesting(true);
    PaymentAccWithdrawAddAccount(requestParams)
      .then((res) => {
        if (res.code == 0) {
          successCallback();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const changeError1 = (val) => {
    if (val == '' || val.trim() == '') {
      setErrorTip1(true);
    } else {
      setErrorTip1(false);
    }
  };

  const changeErrorPhone = (val) => {
    if (val == '') {
      setErrorTipphone(true);
      setErrorTextphone('inputPhoneNull');
    } else if (!phoneVerify(val)) {
      setErrorTipphone(true);
      setErrorTextphone('inputPhoneError');
    } else {
      setErrorTipphone(false);
    }
  };

  const changeErrorPhone2 = (val) => {
    if (val == '') {
      setErrorTipphone(true);
      setErrorTextphone('addpix_phone1');
    } else if (regexNum.test(val)) {
      setErrorTipphone(true);
      setErrorTextphone('addpix_phone2');
    } else if (val.length < maxPhoneLength) {
      setErrorTipphone(true);
      setErrorTextphone('addpix_phone2');
    } else {
      setErrorTipphone(false);
    }
  };

  const changeErrorCnpj = (val) => {
    if (val == '') {
      setErrorTipcnpj(true);
      setErrorTextcnpj('addpix_cnpj1');
    } else if (val.length < maxCnpjLength) {
      setErrorTipcnpj(true);
      setErrorTextcnpj('addpix_cnpj2');
    } else {
      setErrorTipcnpj(false);
    }
  };

  const changeErrorEmail = (val) => {
    if (val == '') {
      setErrorTipemail(true);
      setErrorTextemail('addpix_email1');
    } else if (!regex.test(typeValue)) {
      setErrorTipemail(true);
      setErrorTextemail('addpix_email2');
    } else {
      setErrorTipemail(false);
    }
  };

  const changeErrorEmailBlur = (val) => {
    val = val.replace(/[\u4e00-\u9fa5]/g, '');
    setTypeValue(val);
    changeErrorEmail(val);
  };

  const changeErrorCpf = (val) => {
    if (val == '') {
      setErrorTipcpf(true);
      setErrorTextcpf('addpix_cpf1');
    } else if (val.length < maxCpfLength || !/^\d+$/.test(val)) {
      setErrorTipcpf(true);
      setErrorTextcpf('addpix_cpf2');
    } else {
      setErrorTipcpf(false);
    }
  };

  const changeErrorEVP = (val) => {
    if (val == '') {
      setErrorTipEVP(true);
      setErrorTextEVP('addpix_evp1');
    } else if (val.length < maxEVPLength) {
      setErrorTipEVP(true);
      setErrorTextEVP('addpix_evp2');
    } else {
      setErrorTipEVP(false);
    }
  };

  const handleCpfChange = (ev) => {
    setParams((params) => {
      const value = ev.target.value;
      if (String(value).length < String(params.cpf).length) {
        //如果是删除
        // const cpf = deleteCPF(params.cpf);
        const cpf = String(value) != '' ? deleteCPF(params.cpf) : '';
        changeErrorCpf(cpf.replace(/[\.-]/g, ''));
        return { ...params, cpf: cpf };
      } else {
        let cpf = formatCPF(value.replace(/[\.-]/g, ''));
        const maxLength = maxCpfLength + 3;
        if (cpf.length > maxLength) {
          // 截断输入的值
          cpf = cpf.slice(0, maxLength);
        }
        changeErrorCpf(cpf.replace(/[\.-]/g, ''));
        return { ...params, cpf: cpf };
      }
    });
  };

  const handleCPFKeyDown = (event) => {
    // 禁止输入 . 和 -
    if (event.key === '.' || event.key === '-') {
      event.preventDefault(); // 阻止默认行为
    }
  };

  return (
    <div className="relative p-[.35rem_.32rem_.38rem_.32rem] w-[6.86rem] rounded-normal bg-[var(--colour-35)] border border-[var(--colour-6)]">
      <div className="text-[.32rem] font-[400] leading-[.38rem] text-[var(--colour-48)] text-center mb-[.23rem]">
        {t('deposit-withdraw-wg.withdraw_add_pix_title')}
      </div>

      <div className="active:scale-[.95] transition-all select-none w-[.8rem] h-[.8rem] absolute right-[.28rem] top-[.12rem]">
        <Close onClick={handleClose} className="!w-[.8rem] !h-[.8rem] !text-[var(--colour-48)]" />
      </div>

      <div className="">
        <div className="relative mb-[.54rem]">
          <Input
            //   onBlur={handleInput1Blur}
            leftOuter={
              <div className="flex items-center">
                <span className="w-[.32rem] h-[.32rem] block mr-[.2rem] ml-[.24rem] text-[var(--colour-37)]">
                  <Icon name={IconName.AccountCard} />
                </span>
              </div>
            }
            disabled={defaultName ? true : false}
            type="text"
            value={params.name}
            maxlength={100}
            onChange={(ev) => {
              if (defaultName) return;
              let val = ev.target.value.replace(/[^a-zA-Z .,']+/g, '');
              val = val.toUpperCase();
              setParams({ ...params, name: val });
              changeError1(val);
            }}
            onFocus={(ev) => {
              if (defaultName) return;
              let dom = ev.target.parentNode.parentNode.parentNode;
              dom.style.borderColor = 'var(--colour-1)';
            }}
            onBlur={(ev) => {
              changeError1(ev.target.value);
              let dom = ev.target.parentNode.parentNode.parentNode;
              dom.style.borderColor = 'var(--colour-6)';
            }}
            placeholder={t('deposit-withdraw-wg.withdraw_add_pix_placeholder_account')}
            innerClassName="!h-[.72rem] !border-transparent !pl-[0rem]"
            topClassName={`rounded-normal overflow-hidden bg-[var(--colour-35)]`}
            inputClassName="!text-[.24rem] !font-[500] placeholder:!text-[var(--colour-61)]"
          />
          {errorTip1 ? <ErrorTip text={errorText1} /> : null}
        </div>

        {methodList && methodList.length > 0 ? (
          <TippyPopBox
            placement={Placement.bottomEnd}
            popChildren={
              <PopChoose
                scrollBarWrapperClassName=""
                className={`w-[6.22rem] overflow-hidden bg-[var(--colour-35)] border border-[var(--colour-6)]`}
                children={methodList.map((item, index) => (
                  <PopChooseItem
                    className={`text-[.24rem] font-[500] leading-[.3rem] pl-[.2rem] py-[.18rem]`}
                    key={index}
                    data={item}
                    choose={params.method == item.method}
                    chooseClassName="!text-[var(--colour-13)]"
                    notChooseClassName="!text-[var(--colour-41)]"
                    onClick={() => {
                      if (defaultCpf) {
                        setParams({ ...params, method: item.method, config_id: item.config_id });
                      } else {
                        setParams({ ...params, method: item.method, config_id: item.config_id, cpf: '' });
                      }

                      setTypeValue('');
                      setErrorTipphone(false);
                      setErrorTipcnpj(false);
                      setErrorTipemail(false);
                      setErrorTipEVP(false);
                      setIsShow(false);
                    }}
                  >
                    <span className="uppercase">{item.show_name}</span>
                  </PopChooseItem>
                ))}
              />
            }
            visible={isShow}
            onClickOutside={() => {
              setIsShow(false);
            }}
            offset={[0, 10]}
          >
            <div
              className={cn(
                'h-[.72rem] rounded-normal bg-[var(--colour-35)] border  pl-[.24rem] pr-[.36rem] mb-[.54rem] flex items-center justify-between',
                {
                  'border-[var(--colour-1)]': isShow,
                  'border-[var(--colour-6)]': !isShow,
                },
              )}
              onClick={() => {
                setIsShow(isShow ? false : true);
              }}
            >
              <div className="flex items-center">
                <div className="w-[.32rem] h-[.32rem] text-[var(--colour-37)] mr-[.2rem]">
                  <Icon name={IconName.Account2} />
                </div>
                <div className="text-[.24rem] font-[500] text-[var(--colour-38)] leading-[.32rem] uppercase">
                  {getFilterTypeStr()}
                </div>
              </div>
              <div
                className={cn('w-[.14rem] h-[.24rem] text-[var(--colour-11)]', {
                  'rotate-[-90deg]': isShow,
                  'rotate-[90deg]': !isShow,
                })}
              >
                <Icon name={IconName.BannerNext2} />
              </div>
            </div>
          </TippyPopBox>
        ) : null}

        {params.method != 'cpf' ? (
          <div className="relative mb-[.54rem]">
            <Input
              //   onBlur={handleInput1Blur}
              leftOuter={
                <div className="flex items-center">
                  <span className="w-[.32rem] h-[.32rem] block mr-[.2rem] ml-[.24rem]">
                    <Icon name={IconName.Pix2} />
                  </span>
                </div>
              }
              type="text"
              value={typeValue}
              maxlength={
                params.method == 'phone'
                  ? maxPhoneLength
                  : params.method == 'cnpj'
                    ? maxCnpjLength
                    : params.method == 'email'
                      ? maxEmailLength
                      : params.method == 'evp'
                        ? maxEVPLength
                        : 1000000
              }
              onChange={(ev) => {
                let val;
                if (['phone'].includes(params.method)) {
                  val = ev.target.value;
                  let regx = websiteInfo?.country_code == '55' ? phoneVerifyRegex : phoneVerifyRegex2;
                  if (regx.test(val)) {
                    setTypeValue(val);
                  }
                } else if (!['email', 'evp'].includes(params.method)) {
                  val = setInputNum(ev.target.value);
                  setTypeValue(val);
                } else {
                  val = ev.target.value;
                  setTypeValue(val);
                }

                if (params.method == 'phone') {
                  if (websiteInfo?.country_code == '55') {
                    changeErrorPhone(val);
                  } else {
                    changeErrorPhone2(val);
                  }
                } else if (params.method == 'cnpj') {
                  changeErrorCnpj(val);
                } else if (params.method == 'email') {
                  changeErrorEmail(val);
                } else if (params.method == 'evp') {
                  changeErrorEVP(val);
                }
              }}
              onFocus={(ev) => {
                console.error(ev.target);
                let dom = ev.target.parentNode.parentNode.parentNode;
                dom.style.borderColor = 'var(--colour-1)';
              }}
              onBlur={(ev) => {
                let dom = ev.target.parentNode.parentNode.parentNode;
                dom.style.borderColor = 'var(--colour-6)';
                if (params.method == 'phone') {
                  if (websiteInfo?.country_code == '55') {
                    changeErrorPhone(ev.target.value);
                  } else {
                    changeErrorPhone2(ev.target.value);
                  }
                } else if (params.method == 'cnpj') {
                  changeErrorCnpj(ev.target.value);
                } else if (params.method == 'email') {
                  changeErrorEmailBlur(ev.target.value);
                } else if (params.method == 'evp') {
                  changeErrorEVP(ev.target.value);
                }
              }}
              placeholder={t('deposit-withdraw-wg.withdraw_add_pix_placeholder_pixkey')}
              innerClassName="!h-[.72rem] !border-transparent !pl-[0rem]"
              topClassName={`rounded-normal overflow-hidden bg-[var(--colour-35)]`}
              inputClassName="!text-[.24rem] !font-[500] placeholder:!text-[var(--colour-61)]"
            />

            {errorTipphone ? (
              <ErrorTip text={errorTextphone} isPhoneKey={websiteInfo?.country_code == '55' ? true : false} />
            ) : null}
            {errorTipcnpj ? <ErrorTip text={errorTextcnpj} /> : null}
            {errorTipemail ? <ErrorTip text={errorTextemail} /> : null}
            {errorTipEVP ? <ErrorTip text={errorTextEVP} /> : null}
          </div>
        ) : null}

        <div className={`relative mb-[.66rem]`}>
          <Input
            onKeyDown={handleCPFKeyDown}
            //   onBlur={handleInput1Blur}
            leftOuter={
              <div className="flex items-center">
                <span className="w-[.32rem] h-[.32rem] block mr-[.2rem] ml-[.24rem] text-[var(--colour-37)]">
                  <Icon name={IconName.AccountCard2} />
                </span>
              </div>
            }
            // rightOuter={
            //   defaultCpf || params.cpf == '' ? null : (
            //     <div
            //       className="flex items-center"
            //       onClick={() => {
            //         setParams({ ...params, cpf: '' });
            //       }}
            //     >
            //       <span className="w-[.16rem] h-[.16rem] block mr-[.34rem] ml-[.02rem] text-[var(--colour-46)]">
            //         <Icon name={IconName.Close2} />
            //       </span>
            //     </div>
            //   )
            // }
            disabled={defaultCpf ? true : false}
            type="text"
            value={params.cpf}
            maxlength={maxCpfLength + 3}
            // onChange={(ev) => {
            //   if (defaultCpf) return;
            //   if (ev.nativeEvent.data) {
            //     //输入状态
            //     setParams((params) => {
            //       let val = params.cpf;
            //       if (val.length <= 13) {
            //         val = val + ev.nativeEvent.data; // val + setInputNum(ev.nativeEvent.data);
            //       }

            //       if (val.length == 3 || val.length == 7) {
            //         val += '.';
            //       } else if (val.length == 11) {
            //         val += '-';
            //       }
            //       changeErrorCpf(val.replace(/[\.-]/g, ''));
            //       return { ...params, cpf: val };
            //     });
            //   } else {
            //     //删除状态
            //     setParams((params) => {
            //       let val = params.cpf;
            //       if (val.length == 12 || val.length == 8 || val.length == 4) {
            //         val = val.slice(0, -2);
            //       } else {
            //         val = val.slice(0, -1);
            //       }
            //       changeErrorCpf(val.replace(/[\.-]/g, ''));
            //       return { ...params, cpf: val };
            //     });
            //   }
            // }}
            onChange={handleCpfChange}
            onFocus={(ev) => {
              if (defaultCpf) return;
              let dom = ev.target.parentNode.parentNode.parentNode;
              dom.style.borderColor = 'var(--colour-1)';
            }}
            onBlur={(ev) => {
              changeErrorCpf(ev.target.value.replace(/[\.-]/g, ''));
              let dom = ev.target.parentNode.parentNode.parentNode;
              dom.style.borderColor = 'var(--colour-6)';
            }}
            placeholder={`Enter your CPF`}
            innerClassName="!h-[.72rem] !border-transparent !pl-[0rem]"
            topClassName={`rounded-normal overflow-hidden bg-[var(--colour-35)]`}
            inputClassName="!text-[.24rem] !font-[500] placeholder:!text-[var(--colour-61)]"
          />
          {errorTipcpf ? <ErrorTip text={errorTextcpf} /> : null}
        </div>

        <div className="text-[.24rem] font-[500] text-[var(--colour-41)] text-center leading-[.3rem] mb-[.58rem]">
          {t('deposit-withdraw-wg.withdraw_add_pix_text')}
        </div>

        <LimitClickWrapper
          className="active:scale-[.95] transition-all select-none w-full h-[.7rem] rounded-normal bg-[var(--colour-1)] text-[.24rem] font-[500] text-[var(--colour-2)] cursor-pointer flex items-center justify-center"
          onClick={() => {
            if (!requesting) {
              handleClickConfirm();
            }
          }}
        >
          <ElementWithLoading isLoading={requesting} normalEl={<div className="">{t('home.confirm')}</div>} />
        </LimitClickWrapper>
      </div>
    </div>
  );
};
export default AddPix;

const ErrorTip = ({ text = '', isPhoneKey = false }) => {
  const { t } = useTranslation();
  return (
    <div className={`flex items-center text-[var(--colour-25)] h-[.4rem] absolute bottom-[-0.42rem]`}>
      <span className="w-[.2rem] h-[.2rem] inline-block mr-[.04rem] shrink-0">
        <Icon name={IconName.ErrorTip} />
      </span>
      <div className="text-[.22rem] font-[500] leading-[.3rem]">
        {isPhoneKey ? t(`login-signup.${text}`) : t(`deposit-withdraw-wg.${text}`)}
      </div>
    </div>
  );
};
