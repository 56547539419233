import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';
import useHandleLogic from '../../../../hooks/useHandleLogic';

const LayoutMobile = ({ click }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center justify-start cursor-pointer   px-[.14rem] mb-[.2rem]" onClick={click}>
      <span className="  block  w-[.4rem] h-[.4rem] mr-[.08rem] text-[var(--colour-12)]">
        <Icon name={IconName.MenuBarService2} />
      </span>
      <span className="text-[var(--colour-14)]  text-[.2rem] relative  leading-[.24rem] font-[500]">
        {t('home.service')}
      </span>
    </div>
  );
};

const Service = ({ onlyIcon, handleLinkClick = () => {} }) => {
  const { handleClickManMadeInPage } = useHandleLogic();

  const click = () => {
    handleClickManMadeInPage();
    handleLinkClick();
  };

  return <LayoutMobile click={click} onlyIcon={onlyIcon} />;
};

export default Service;
