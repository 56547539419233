import { Tooltip as ReactTooltip } from 'react-tooltip';

const Tips = ({ anchorId, children, className = 'z-[10]' }) => {
  return (
    <ReactTooltip anchorId={anchorId} delayShow={0} place={'right'} style={{ background: 'transparent' }}>
      <div
        className={`bg-[var(--colour-35)] absolute left-[0]   top-[-0.2rem] h-[.8rem] flex justify-center items-center  p-[.2rem] btn_allowed whitespace-nowrap rounded-normal ${className}`}
      >
        {children}
      </div>
    </ReactTooltip>
  );
};

export default Tips;
