import cn from 'classnames';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import DialogModal from '../../../../components/dialogModal';
import Icon, { IconName } from '../../../../components/icon';
import BindPassword from '../../../../components/dialogModal/bindPassword';
import BindEmail from '../../../../components/dialogModal/bindEmail';
import EditPassword from '../../../../components/dialogModal/editPassword';
import { setUserObj } from '../../../../redux/reducers/userSlice';
import { UnbindGoogleSecretReq, GetProfileData } from '../../../../api';
import Enter2FACode from '../../../../components/enter2FACode';
import GoogleAuthenticator from '../../../../components/dialogModal/googleAuthenticator';
import HeaderBack from '../../../../components/headerBack';
import { toLocalePath } from '../../../../utils/localePath';
import { isLogin, getShowMoney, getTemplateId } from '../../../../utils/helper';
import { ResponsiveMode } from '../../../../redux/reducers/appSlice';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import Badge from '../../../mine/normal/template001/components/badge';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();

  const { userObj, websiteInitConfig, is2FaActive, twofa_switch, responsiveMode } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      websiteInitConfig: state.app.websiteInitConfig,
      is2FaActive: get(state, 'user.userObj.google_auth') == '1',
      twofa_switch: get(state, 'app.websiteInitConfig.system_config.switch_2fa_bind_login') == '1',
    }),
    shallowEqual,
  );

  const [showBindPhone, setShowBindPhone] = useState(false); //手机号绑定
  const [showBindEmail, setShowBindEmail] = useState(false); //邮箱绑定
  const [showEditPassword, setShowEditPassword] = useState(false); //密码修改

  const [isActiveOpen, setIsActiveOpen] = useState(false);
  const [isDeactiveOpen, setIsDeactiveOpen] = useState(false);
  const [bindData, setBindData] = useState(null); //手机、name、cpf、邮箱的绑定信息

  const handleDeactive = (code = '') => {
    UnbindGoogleSecretReq({ code }).then((res) => {
      if (res.code == 0) {
        res?.result?.user_obj && dispatch(setUserObj(res.result.user_obj));
        setIsDeactiveOpen(false);
        toast.success(t('account.2fa_deactive_success'), {
          containerId: 'global',
        });
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const upDataList = () => {
    GetProfileData().then((res) => {
      if (res.code == 0) {
        setBindData(res.data);
      }
    });
  };

  useEffect(() => {
    upDataList();
  }, []);

  const clickPhoneCondition = () => {
    return bindData?.phone?.status == 10;
  };

  const clickEmailCondition = () => {
    return bindData?.email?.status == 10;
  };

  return (
    <div className={`!px-[0px] container bg-[var(--colour-34)] h-full min-h-[var(--app-height)]`}>
      {/* 绑定手机 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowBindPhone(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showBindPhone}
      >
        <BindPassword
          handleClose={() => {
            setShowBindPhone(false);
          }}
          bindSuccessCallback={() => {
            upDataList();
          }}
        />
      </DialogModal>

      {/* 绑定邮箱  */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowBindEmail(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showBindEmail}
      >
        <BindEmail
          handleClose={() => {
            setShowBindEmail(false);
          }}
          bindSuccessCallback={() => {
            upDataList();
          }}
        />
      </DialogModal>

      {/* 修改密码 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowEditPassword(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={showEditPassword}
      >
        <EditPassword
          handleClose={() => {
            setShowEditPassword(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsDeactiveOpen(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={isDeactiveOpen}
      >
        <Enter2FACode handleClose={() => setIsDeactiveOpen(false)} onClickButton={handleDeactive} />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsActiveOpen(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={isActiveOpen}
      >
        <GoogleAuthenticator
          handleClose={() => {
            setIsActiveOpen(false);
          }}
        />
      </DialogModal>

      <HeaderBack
        title={t('account.security')}
        onClick={() => navigate(toLocalePath('/mine'))}
        className={`${getTemplateId() == 'd-template003' ? '!bg-[--colour-86]' : ''}`}
      />

      <div className="p-[.2rem_.24rem_.2rem_.24rem]">
        <div className={cn('rounded-normal p-[0rem_.2rem_0rem_.2rem] mb-[.2rem] bg-[var(--colour-35)]')}>
          <div className="flex items-center justify-between py-[.25rem]">
            <div className="flex items-center">
              <div className="w-[.28rem] h-[.28rem] text-[var(--colour-37)] mr-[.16rem]">
                <Icon name={IconName.Useraccount} />
              </div>
              <div className="text-[.2rem] font-[400] text-[var(--colour-44)] leading-[.24rem]">
                {t('account.user_name')}
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-[.2rem] font-[400] leading-[.24rem] text-[var(--colour-38)]">
                {userObj ? userObj.username : ''}
              </div>
              <div></div>
            </div>
          </div>
          <div className="bg-[var(--colour-6)] w-full h-[1px]"></div>

          {/* phone */}
          <div
            className="flex items-center justify-between py-[.25rem] cursor-pointer"
            onClick={() => {
              if (clickPhoneCondition()) {
                setShowBindPhone(true);
              }
            }}
          >
            <div className="flex items-center">
              <div className="w-[.28rem] h-[.28rem] text-[var(--colour-1)] mr-[.16rem]">
                <Icon name={IconName.Phone3} />
              </div>
              <div className="text-[.2rem] font-[400] leading-[.24rem] text-[var(--colour-44)]">
                {t('account.telephone_no')}
              </div>
            </div>
            <div>
              <UnboundBtn
                condition={clickPhoneCondition()}
                showBadge={bindData?.phone?.show_tips == 1}
                num={bindData?.phone?.reward_amount}
                value={
                  bindData?.phone?.bind_value
                    ? '+' +
                      String(
                        bindData?.phone?.bind_value.slice(
                          0,
                          websiteInitConfig?.website_info?.country_code
                            ? websiteInitConfig?.website_info?.country_code?.length
                            : 0,
                        ),
                      ) +
                      '-' +
                      String(
                        bindData?.phone?.bind_value.slice(
                          websiteInitConfig?.website_info?.country_code
                            ? websiteInitConfig?.website_info?.country_code?.length
                            : 0,
                          bindData?.phone?.bind_value.length,
                        ),
                      )
                    : ''
                }
              />
            </div>
          </div>
          <div className="bg-[var(--colour-6)] w-full h-[1px]"></div>

          {/* email */}
          <div
            className="flex items-center justify-between py-[.25rem] cursor-pointer"
            onClick={() => {
              if (clickEmailCondition()) {
                setShowBindEmail(true);
              }
            }}
          >
            <div className="flex items-center">
              <div className="w-[.26rem] h-[.26rem] text-[var(--colour-1)] mr-[.18rem] ml-[.02rem]">
                <Icon name={IconName.MenuBarEmail} />
              </div>
              <div className="text-[.2rem] font-[400] leading-[.24rem] text-[var(--colour-44)]">
                {t('account.email_address')}
              </div>
            </div>
            <div>
              <UnboundBtn
                condition={clickEmailCondition()}
                showBadge={bindData?.email?.show_tips == 1}
                num={bindData?.email?.reward_amount}
                value={bindData?.email?.bind_value || ''}
              />
            </div>
          </div>
        </div>
        <div
          className="rounded-normal p-[.25rem_.2rem_.25rem_.2rem] mb-[.2rem] cursor-pointer bg-[var(--colour-35)]"
          onClick={() => {
            if (isLogin()) {
              setShowEditPassword(true);
            }
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-[.28rem] h-[.28rem] text-[var(--colour-29)] mr-[.16rem]">
                <Icon name={IconName.Pwd2} />
              </div>
              <div className="text-[.2rem] font-[400] text-[var(--colour-44)] leading-[.24rem]">
                {t('account.login_password')}
              </div>
            </div>
            <div className="w-[.12rem] h-[.22rem] text-[var(--colour-11)] ml-[.16rem] rotate-[180deg]">
              <Icon name={IconName.BackArrow} />
            </div>
          </div>
        </div>

        {twofa_switch ? (
          <div
            className="rounded-normal p-[.25rem_.2rem_.25rem_.2rem] mb-[.2rem] cursor-pointer bg-[var(--colour-35)]"
            onClick={() => {
              if (isLogin()) {
                if (is2FaActive) {
                  setIsDeactiveOpen(true);
                } else {
                  setIsActiveOpen(true);
                }
              }
            }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-[.28rem] h-[.28rem] text-[var(--text-[var(--colour-24)])] mr-[.16rem]">
                  <Icon name={IconName.Fa} />
                </div>
                <div className="text-[.2rem] font-[400] text-[var(--colour-44)] leading-[.24rem]">
                  {t('account.2_factor_authenticator')}
                </div>
              </div>

              <div className="flex items-center">
                <div className="text-[.26rem] font-[400] text-[var(--colour-38)] leading-[.26rem]">
                  {is2FaActive ? t('account.2fa_button2') : t('account.2fa_button1')}
                </div>
                <div className="w-[.12rem] h-[.22rem] text-[var(--colour-11)] ml-[.16rem]">
                  <Icon name={IconName.RightArrow3} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default NTemplate001;

const UnboundBtn = ({ condition = false, value = '', showBadge = false, num = 0 }) => {
  const { t } = useTranslation();
  return (
    <div className="text-[.2rem] font-[400] leading-[.24rem] relative">
      {condition && value ? (
        <div className="flex items-center">
          <div className="flex items-center">
            <div className="text-[var(--colour-38)]">{value}</div>
            <div className="text-[var(--colour-1)]">({t('account.unverified')})</div>
          </div>
          <div className="w-[.12rem] h-[.22rem] text-[var(--colour-11)] ml-[.16rem] rotate-[180deg]">
            <Icon name={IconName.BackArrow} />
          </div>
        </div>
      ) : condition ? (
        <div className="flex items-center">
          <div className="text-[var(--colour-1)]">{t('account.unbound')}</div>
          <div className="w-[.12rem] h-[.22rem] text-[var(--colour-11)] ml-[.16rem] rotate-[180deg]">
            <Icon name={IconName.BackArrow} />
          </div>
        </div>
      ) : value ? (
        <div className="text-[var(--colour-38)]">{value}</div>
      ) : null}
      {showBadge ? (
        <Badge
          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
          className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.34rem] !right-[-0.26rem] flex items-center"
          text={getShowMoney(num || 0)}
        ></Badge>
      ) : null}
    </div>
  );
};
