import cn from 'classnames';

const CssTriangle = ({
  className = '',
  direction = 'left',
  color = 'var(--colour-1)',
  borderRightWidth = '.16rem',
  borderTopWidth = '.1rem',
  borderBottomWidth = '.1rem',
  // borderLeftWidth = '0px',
}) => {
  return (
    <span
      className={cn(`inline-block w-[0] h-[0] ${className}`, {
        '': direction == 'left',
        'rotate-[180deg] ': direction == 'right',
        'rotate-[90deg] ': direction == 'up',
        'rotate-[270deg]': direction == 'down',
      })}
      style={{
        borderRightColor: color,
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftWidth: '0px',
        borderRightWidth: borderRightWidth,
        borderTopWidth: borderTopWidth,
        borderBottomWidth: borderBottomWidth,
      }}
    ></span>
  );
};

export default CssTriangle;
