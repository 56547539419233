import { memo } from 'react';
import Helmet from 'react-helmet';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
import { is777BetIO } from '../../utils/helper';

/**
 * bigo的Pixel统计
 * 支持从url获取pixelId
 */
const SevenBetLicense = () => {
  const { websiteLicenseSwitch } = useSelector(
    (state) => ({
      websiteLicenseSwitch: get(state, 'app.websiteInitConfig.website_info.website_license_switch', '0'),
    }),
    shallowEqual,
  );

  return websiteLicenseSwitch == '1' && is777BetIO() ? (
    <Helmet>
      <script
        type="text/javascript"
        src="https://642c95b7-58fc-45e3-8343-3f2514c6bbab.snippet.anjouangaming.org/anj-seal.js"
      ></script>
    </Helmet>
  ) : (
    <></>
  );
};

export default memo(SevenBetLicense);
