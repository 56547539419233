import DialogModal from './dialogModal';
import Close from './close';
function TextTips({ setShowVerify, showVerify, headerText = '', contentText = '', contentText2 = '' }) {
  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showVerify}
        handleClose={() => {
          setShowVerify(false);
        }}
      >
        <TipInner
          handleClose={() => setShowVerify(false)}
          headerText={headerText}
          contentText={contentText}
          contentText2={contentText2}
        ></TipInner>
      </DialogModal>
    </>
  );
}
function TipInner({ handleClose, headerText, contentText, contentText2 }) {
  return (
    <div className="bg-[var(--colour-35)] border border-solid border-[var(--colour-6)] overflow-hidden w-[6.86rem] rounded-normal px-[.32rem] pb-[.9rem]">
      <div className="h-[1.12rem] flex items-center justify-between border-b border-[var(--colour-6)]">
        <div className="text-[.32rem] font-[400] text-[var(--colour-48)]">{headerText}</div>
        <div
          className="flex w-[.72rem] h-[.72rem] rounded-normal bg-[var(--colour-45)] text-[var(--colour-46)] items-center justify-center"
          onClick={() => handleClose()}
        >
          <Close />
        </div>
      </div>
      <div className="pt-[.4rem] text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-41)]">
        <div>{contentText}</div>
        {contentText2 ? <div className="mt-[.3rem]">{contentText2}</div> : null}
      </div>
    </div>
  );
}
export default TextTips;
