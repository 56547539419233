import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../../../components/icon';

export default function InputErrorTips({ className = 'mt-[.08rem] h-[.3rem]', showTips = false, text = '' }) {
  const { t } = useTranslation();
  return (
    <div
      className={`${className} font-[500] text-[.22rem] text-[var(--colour-25)] flex flex-row items-center justify-start space-x-[.02rem]`}
    >
      {showTips ? (
        <>
          <div className="flex items-center justify-center">
            <span className="w-[.2rem] h-[.2rem] inline-block">
              <Icon name={IconName.ErrorTip} />
            </span>
          </div>
          <span>{text}</span>
        </>
      ) : null}
    </div>
  );
}
