import React, { FC, useState, useEffect, useRef } from 'react';
import DragBtnRight from '../../dragBtnRight';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setRefetchActivityData,
} from '../../../redux/reducers/appSlice';

import { useTranslation } from 'react-i18next';
import { cloneDeep, isUndefined } from 'lodash';
import RedDot from '../../redDot';
import BonusCountdown from '../../welcomeBonus/bonusCountdown';
import { find } from 'lodash';
import cn from 'classnames';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import welcomeBonusPng from '../../../assets/img/welcome-bonus/welcomeBonus.png';
import ActivityCountDown from '../../dialogModal/components/activityCountDown';
import Claimbtn from '../components/claimbtn';

export default function Index({ isdrag = true, index = null, imgpath = '', EntranceDisplayLocation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isShowRedDot, setIsShowRedDot] = useState(false);
  const [welcomeBonusData, setWelcomeBonusData] = useState(null);
  const { defaultLang, activityData } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );

  const handleClick = () => {
    dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.WelcomeBonus }));
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_WB_Floating);
  };

  useEffect(() => {
    if (welcomeBonusData != null) {
      const activityRewards = welcomeBonusData.activity_rewards;
      if (activityRewards && activityRewards.length > 0) {
        if (find(activityRewards, ['status', 1])) {
          setIsShowRedDot(true);
        } else {
          setIsShowRedDot(false);
        }
      } else {
        setIsShowRedDot(false);
      }
    }
  }, [welcomeBonusData]);

  const onComplete = () => {
    dispatch(setRefetchActivityData());
  };

  useEffect(() => {
    if (activityData.activity_novice) {
      const list = activityData.activity_novice.list;
      if (list.length > 0) {
        setWelcomeBonusData(list[0]);
      } else {
        setWelcomeBonusData(null);
      }
    } else {
      setWelcomeBonusData(null);
    }
  }, [activityData]);

  return welcomeBonusData != null ? (
    <>
      <DragBtnRight
        className={`activty-absolute-bottom-${index + 1} } ${index != null && EntranceDisplayLocation == '0' ? 'activity-item' : ''} ${EntranceDisplayLocation == '0' ? 'right-[.08rem]' : 'left-[.08rem]'}`}
        onClick={handleClick}
        className1="w-[1.5rem] h-[1.5rem]"
        isLimitedInParentHeight={false}
        isdrag={isdrag}
      >
        <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
          <div className="scale-[.5]">
            <div className="flex flex-col w-[3rem] h-[3rem] items-center justify-between relative">
              <img src={imgpath} alt="" className="not-drag absolute left-0 top-0 w-full" />

              {isShowRedDot ? (
                <Claimbtn
                  txtColor="event_bonus_title1"
                  className="rounded-[.08rem] bg-claim_btn flex items-center justify-center absolute abs-x-center bottom-[0]"
                >
                  {t('guideWheel.claim')}
                </Claimbtn>
              ) : (
                <>
                  <div
                    className={cn(
                      'h-[.52rem] w-[2.56rem] z-[1] flex items-center justify-center text-[var(--colour-48)] text-[.32rem] font-[700] absolute abs-x-center bottom-[0]',
                    )}
                  >
                    <ActivityCountDown onComplete={onComplete} endTime={welcomeBonusData.activity_end_time} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </DragBtnRight>
    </>
  ) : (
    <></>
  );
}
