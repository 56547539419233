import BigNumber from 'bignumber.js';
import { useEffect, useRef, useState } from 'react';

const replaceTrailingZerosWithOnes = (str) => {
  const decimalIndex = str.indexOf('.');
  if (decimalIndex === -1) {
    return str;
  }

  const [integerPart, decimalPart] = str.split('.');

  const trailingOnesPart = decimalPart.replace(/0+$/, '1'.repeat(decimalPart.match(/0+$/)?.[0].length || 0));

  return integerPart + '.' + trailingOnesPart;
};

const getStepAmount = (from, to, duration, changeInterval) => {
  let bn = BigNumber(from)
    .minus(to)
    .dividedBy(Number(duration) / Number(changeInterval));
  bn = bn.isNegative() ? bn.times(-1) : bn;

  const str = bn.toFixed(2, BigNumber.ROUND_DOWN);

  const trailOnes = replaceTrailingZerosWithOnes(str);

  return Number(trailOnes);
};

const add = (num, toAdd, decimalPlace = 2) => {
  const str = BigNumber(num).plus(toAdd).toFixed(decimalPlace, BigNumber.ROUND_DOWN);
  return Number(str);
};

const useNumberTransition = ({
  number = 0,
  animateInterval = 30,
  isShowNegative = false,
  duration = 3000,
  decimalPlace = 2,
  firstNeedAnimation = false,
} = {}) => {
  // console.log('decimalPlace:', decimalPlace);

  const [currentValue, setCurrentValue] = useState(Number(number));
  const [targetValue, setTargetValue] = useState(Number(number));
  const intervalRef = useRef(null);

  const [stepAmount, setStepAmount] = useState(0.01);

  useEffect(() => {
    setTargetValue(Number(number));

    if (!firstNeedAnimation) {
      // 第一个数字，直接设置,不需要动画
      if (Number(number) != 0 && targetValue == 0) {
        setCurrentValue(Number(number));
      }
    }
  }, [number]);

  useEffect(() => {
    if (number !== targetValue) {
      const _stepDistance = getStepAmount(targetValue, number, duration, animateInterval);
      // console.log('_stepDistance:', _stepDistance);

      setStepAmount(_stepDistance);
    }
  }, [number, targetValue]);

  useEffect(() => {
    if (currentValue !== targetValue) {
      const updateValue = () => {
        if (
          (currentValue < targetValue && add(currentValue, stepAmount, decimalPlace) < targetValue) ||
          (currentValue > targetValue && add(currentValue, -stepAmount, decimalPlace) > targetValue)
        ) {
          setCurrentValue((prev) => {
            const result = add(prev, currentValue < targetValue ? stepAmount : -stepAmount, decimalPlace);
            return result >= 0 ? result : isShowNegative ? result : 0;
          });
        } else {
          clearInterval(intervalRef.current);
          setCurrentValue(targetValue);
        }
      };

      intervalRef.current = setInterval(updateValue, animateInterval);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [currentValue, targetValue, animateInterval, stepAmount, isShowNegative, decimalPlace]);

  return {
    currentValue,
  };
};

export default useNumberTransition;
