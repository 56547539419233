import LimitClickWrapper from '../../../../limitClickWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { ActivityPromotionalAchievementsClaim } from '../../../../../api';
import { getPercentage, getShowMoney, isLogin } from '../../../../../utils/helper';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setPromotionPendingUpdateTimestamp,
  setRefetchActivityData,
} from '../../../../../redux/reducers/appSlice';
import { toast } from 'react-toastify';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import DialogModal from '../../..';
import DownloadManager from '../../../../../manager/downloadManager';
import BottomDownload from '../../../downLoadBtn';
import { isRunningTaskExit } from './helper';
import PopBg2 from '../../../components/popBg2';

const ClaimButton = ({ handleClick = () => {}, buttonText }) => {
  return (
    <LimitClickWrapper
      className="w-[5.38rem] h-[.76rem] flex items-center justify-center rounded-[.12rem] cursor-pointer active:scale-[.95] transition-all select-none colour-79 text-[var(--colour-2)] text-[.28rem] font-[600]"
      onClick={handleClick}
    >
      {buttonText}
    </LimitClickWrapper>
  );
};

const ProgressBar = ({ percent = 0 }) => {
  const getShowPercent = (p) => {
    return Number(p) > 100 ? 100 : Number(p);
  };
  return (
    <div className="flex flex-row items-center justify-center space-x-[.1rem]">
      <div className="relative w-[4.56rem] h-[.24rem] px-[.02rem] rounded-full flex items-center bg-[var(--colour-34)]">
        <div
          className="rounded-full h-[.2rem] colour-91"
          style={{
            width: `${getShowPercent(percent)}%`,
          }}
        ></div>
      </div>
      <span className="font-[500] text-[.28rem] text-[var(--colour-48)]">{getShowPercent(percent)}%</span>
    </div>
  );
};

const Money = ({ text }) => {
  return (
    <div className="w-[5.36rem] h-[1.16rem] rounded-[.2rem] border border-[var(--colour-60)] flex items-center justify-center text-center text-[var(--colour-26)] text-[.4rem] font-[600]">
      {text}
    </div>
  );
};

const Tip = ({ text }) => {
  return (
    <div
      className="w-[4.68rem] h-[.6rem] flex items-center justify-center text-center leading-[1.25] text-[.24rem] font-[500] referral_achievement_text"
      data-text={text}
    >
      {text}
    </div>
  );
};

const Title = ({ text }) => {
  return (
    <div
      className="h-[.44rem] text-[var(--colour-76)] text-[.36rem] font-[600]"
      style={{
        textShadow: '0px 5px 4px rgba(179, 73, 10, 0.80), 2px 2px 0px #B3490A',
      }}
    >
      {text}
    </div>
  );
};

export default function Index({
  data,
  handleClose = () => {},
  isShowCloseBtn,
  showMain = () => {},
  updateDataAfterClaim = () => {},
  onExpired,
  updateList,
  isSinglePopup,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
    }),
    shallowEqual,
  );

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  const [currentTask, setCurrentTask] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showedReward, setShowedReward] = useState(0);
  const [isShowMoneyPopup, setIsShowMoneyPopup] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [claimedMoney, setClaimedMoney] = useState(0);
  const [isContentVisible, setIsContentVisible] = useState(true);

  useEffect(() => {
    // taskStatus 10 未完成； 11 结算中; 20 未到领奖时间; 21 可领取; 22 已领取; 30 奖励过期

    if (data) {
      const taskList = data?.task_config || [];

      const claimableTaskList = taskList.filter((item) => Number(item.task_status) == 21);
      const unclaimableTaskList = taskList.filter((item) => [10, 11].includes(Number(item.task_status)));

      if (claimableTaskList[0]) {
        setCurrentTask(claimableTaskList[0]);
        setShowProgressBar(false);

        const amount = claimableTaskList.reduce((acc, curr) => {
          return acc + Number(curr?.reward_amount || 0);
        }, 0);
        setShowedReward(amount);
      } else if (unclaimableTaskList[0]) {
        setCurrentTask(unclaimableTaskList[0]);
        setShowProgressBar(true);

        const amount = unclaimableTaskList.reduce((acc, curr) => {
          return acc + Number(curr?.reward_amount || 0);
        }, 0);
        setShowedReward(amount);
      }
    }
  }, [data]);

  const showClaimResult = (val = '') => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.ClaimBonusResultTip,
        content: (
          <span className="text-[var(--colour-18)] text-[.28rem] font-[600]">
            <Trans
              t={t}
              i18nKey={'promo.claim_bonus_result'}
              components={[<span className="text-[var(--colour-25)] text-[.4rem]" key={0}></span>]}
              values={{
                val,
              }}
            />
          </span>
        ),
        closeCb: getAfterCloseMoneyCallback(),
      }),
    );
  };

  const handleClaim = (activity_id, tap_position = '') => {
    if (isRequesting) {
      return;
    }

    setIsRequesting(true);

    const params = {
      activity_id,
      tap_position,
    };

    ActivityPromotionalAchievementsClaim(params)
      .then((res) => {
        if (res.code == 0) {
          updateDataAfterClaim(res?.result?.activity_info);
          dispatch(setRefetchActivityData());
          dispatch(setNeedUpdateBalance({ need: true }));

          setIsContentVisible(false);

          // setClaimedMoney(res?.result?.amount || 0);
          // setIsShowMoneyPopup(true);

          showClaimResult(`${currency_symbol}${getShowMoney(res?.result?.amount || 0)}`);

          dispatch(setPromotionPendingUpdateTimestamp());
        } else {
          if (res.code == 1008127) {
            if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
              setBottomDownloadBoxPromptSwitch({
                visible: true,
                isForce: true,
                downloadText: t('guideWheel.downloadtips'),
              });
            }
          } else {
            toast.error(t(`${res.code}`), {
              containerId: 'global',
            });
          }
        }
      })
      .finally(() => {
        setIsRequesting(false);
      });
  };

  // const handleClaim = (claimId) => {
  //   if (isRequesting) {
  //     return;
  //   }

  //   setIsRequesting(true);

  //   PromotionPendingClaim({
  //     id: claimId,
  //   })
  //     .then((res) => {
  //       if (res.code == 0) {
  //         updateDataAfterClaim();
  //         dispatch(setRefetchActivityData());
  //         dispatch(setNeedUpdateBalance({ need: true }));

  //         setClaimedMoney(res?.data?.amount || 0);

  //         setIsShowMoneyPopup(true);
  //       } else {
  //         if (res.code == 1008127) {
  //           if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
  //             setBottomDownloadBoxPromptSwitch({
  //               visible: true,
  //               isForce: true,
  //               downloadText: t('guideWheel.downloadtips'),
  //             });
  //           }
  //         } else {
  //           toast.error(t(`${res.code}`), {
  //             containerId: 'global',
  //           });
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       setIsRequesting(false);
  //     });
  // };

  const getAfterCloseMoneyCallback = () => {
    const isAllClaimed = (data?.task_config || []).every((item) => Number(item.task_status) == 22);

    return isAllClaimed ? (isSinglePopup ? handleClose : updateList) : () => {};
  };

  const onClickClaim = () => {
    // task_status: 11:结算中, 21: 可领取,
    if (isLogin()) {
      if (currentTask?.task_status == 21) {
        handleClaim(data?.id);
      } else {
        showMain();
      }
    } else {
      handleClose();
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
  };

  // const handleCloseMoneyPopup = () => {
  //   setIsShowMoneyPopup(false);
  //   setIsContentVisible(true);
  // };

  useEffect(() => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonusWindow_Popup);
  }, []);

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      {/* <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
          },
          overlay: {
            zIndex: 999999,
          },
        }}
        isOpen={isShowMoneyPopup}
        handleClose={handleCloseMoneyPopup}
        overlayClassName="wheelBonusMoney"
        afterClose={getAfterCloseMoneyCallback()}
      >
        <WheelBonus handleClose={handleCloseMoneyPopup} content={claimedMoney} />
      </DialogModal> */}

      <PopBg2 handleClose={handleClose} isShowClose={isShowCloseBtn}>
        <div className={cn('flex flex-col w-full items-center', {})}>
          <div className="w-full h-[4.4rem] bg-[url(/static/img/referral_bg_1.png)] bg-no-repeat bg-[length:6.14rem_4.4rem] flex flex-col items-center">
            <div className="mt-[.11rem] ">
              <Title text={t('referral.referral_bonus')} />
            </div>
            <div className="mt-[3.13rem]">
              <Tip text={t('referral.cashback_tip')} />
            </div>
          </div>

          <div className="mt-[.24rem]">
            <Money text={`${currency_symbol} ${getShowMoney(showedReward)}`} />
          </div>

          {showProgressBar ? (
            <div className="mt-[.24rem]">
              <ProgressBar
                percent={getPercentage(
                  Number(currentTask?.finish_people_num || 0),
                  Number(currentTask?.people_num || 1),
                )}
              />
            </div>
          ) : null}

          <div className="mt-[.48rem]">
            <ClaimButton
              buttonText={
                isRunningTaskExit(data?.task_config || [])
                  ? currentTask?.task_status == 11
                    ? t('promotion.pending')
                    : t('referral.claim')
                  : t('promo.details')
              }
              handleClick={() => {
                onClickClaim();
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonus_Claim);
              }}
            />
          </div>
        </div>
      </PopBg2>
    </>
  );
}
