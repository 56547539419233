import cn from 'classnames';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'react-redux';

const ClaimableRedbag = ({ doneStyle = 1 }) => {
  return doneStyle == 1 ? (
    <div className="absolute abs-x-center bottom-[0] w-[1.36rem] h-[1.02rem] bg-[url(../assets/img/promote-achieve/opened.png)] bg-no-repeat bg-[length:100%_100%]"></div>
  ) : (
    <div className="absolute left-0 top-0 w-[2rem] h-[1.2rem] bg-[url(/static/img/refer/referralAchievement_redbag.png)] referral_achievement_redbag origin-top-left scale-x-[0.67] scale-y-[0.6833]"></div>
  );
};

const StepItem = ({
  mode = 'default',
  text1 = '',
  text2 = '',
  progressPercent = 0,
  onClick = () => {},
  doneStyle,
  isShowProgress = false,
}) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  return (
    <div
      className={cn('relative w-[1.36rem] h-[.84rem] grow-0', {
        'bg-promote-achieve-default': ['default', 'comingSoon'].includes(mode),
        soon: mode == 'comingSoon',
        // 'bg-[url(../assets/img/promote-achieve/done.png)] bg-no-repeat bg-[length:100%_100%] cursor-pointer': mode == 'done',
        'cursor-pointer': mode == 'done' && doneStyle != 1,
      })}
      onClick={progressPercent > 0 && progressPercent < 100 ? onClick : null}
    >
      {mode == 'done' ? <ClaimableRedbag doneStyle={doneStyle} /> : null}
      {mode == 'opened' ? (
        <div className="absolute abs-x-center bottom-[0] w-[1.36rem] h-[1.02rem] bg-[url(../assets/img/promote-achieve/opened.png)] bg-no-repeat bg-[length:100%_100%]"></div>
      ) : null}
      {mode == 'default' && (
        <div
          className="absolute left-[0] bottom-[0] w-[.82rem] h-[.84rem] bg-[url(../assets/img/promote-achieve/done.png)] bg-no-repeat bg-[length:1.34rem_100%]"
          style={{
            width: isShowProgress ? `${progressPercent}%` : '0',
          }}
        ></div>
      )}
      <div className="absolute w-full h-full flex flex-col items-center justify-end">
        <div
          className={cn('absolute abs-x-center flex flex-col itmes-center w-full text-[.2rem]', {
            'top-[.48rem] space-y-[.1rem]': mode != 'comingSoon',
            'abs-center': mode == 'comingSoon',
            // 'opacity-[0.5]': mode == 'opened',
          })}
        >
          <div
            className={`text-center font-[400] ${mode == 'comingSoon' ? 'text-[var(--colour-44)] ' : 'text-[var(--colour-49)] w-max mx-[auto]'}`}
          >
            {text1}
          </div>
          <div
            className={`text-center leading-[1em] font-[500] ${mode == 'comingSoon' ? 'text-[var(--colour-44)]' : 'text-[var(--colour-10)]'}`}
          >
            {text2}
          </div>
        </div>
      </div>
    </div>
  );
};

const ArrowItem = ({ direction = 'default', arrowStyle = 1 }) => {
  return (
    <div
      className={cn('absolute bg-no-repeat bg-[length:100%_100%] w-[.18rem] h-[.32rem]', {
        'right-[-.2rem] top-[50%] translate-y-[-.16rem]': direction == 'default',
        'rotate-[90deg] bottom-[-.56rem]': direction == 'bottom',
        'rotate-[180deg] left-[-.2rem] top-[50%] translate-y-[-.16rem]': direction == 'left',
        'bg-[url(../assets/img/promote-achieve/arrow.png)]': arrowStyle == 1,
        'bg-[url(../assets/img/promote-achieve/arrow2.png)]': arrowStyle == 2,
      })}
    ></div>
  );
};

const StepBox = ({
  index = 0,
  text1 = '',
  text2 = '',
  mode,
  isLast = false,
  lineSize = 4,
  lineIndex = 0,
  isLastLine = false,
  progressPercent = 0,
  onClick,
  doneStyle,
}) => {
  const arrowDirection = (() => {
    const isLastCol = index % lineSize == lineSize - 1;
    const isOddLine = lineIndex % 2 == 0;

    const isArrowRight = !isLast && !isLastCol && isOddLine;
    const isArrowLeft = !isLast && !isLastCol && !isOddLine;
    const isArrowBottom = isLastCol && !isLastLine;

    const arrowDirection = isArrowRight ? 'default' : isArrowLeft ? 'left' : isArrowBottom ? 'bottom' : '';

    return arrowDirection;
  })();

  return (
    <div className={cn('relative flex justify-center w-[1.36rem]', {})}>
      <StepItem
        mode={mode}
        text1={text1}
        text2={text2}
        isLastItem={isLastLine && isLast}
        progressPercent={progressPercent}
        onClick={onClick}
        doneStyle={doneStyle}
      />
      {arrowDirection != '' && <ArrowItem direction={arrowDirection} arrowStyle={mode == 'opened' ? 2 : 1} />}
    </div>
  );
};

const StepBoxLine = ({ list, isLastLine, index = 0, lineSize, doneStyle }) => {
  console.log('list', list, 'isLastLine', isLastLine, 'index', index, 'lineSize', lineSize);
  return (
    <div
      className={cn('w-full pb-[.72rem] flex flex-row items-start justify-start', {
        'flex-row-reverse': index % 2 == 1,
      })}
    >
      {list.map((data, boxIndex) => (
        <div key={boxIndex} className={cn('grow-0 w-[25%] max-w-[25%] flex justify-center', {})}>
          <StepBox
            key={boxIndex}
            index={boxIndex}
            text1={data.text1}
            text2={data.text2}
            mode={data?.mode}
            isLast={boxIndex == list.length - 1}
            lineSize={lineSize}
            lineIndex={index}
            isLastLine={isLastLine}
            progressPercent={data?.progressPercent}
            onClick={data?.onClick}
            doneStyle={doneStyle}
          />
        </div>
      ))}
    </div>
  );
};

const NTemplate001 = ({ list = [], lineSize = 4, doneStyle = 1 }) => {
  const result = (() => {
    const groupedList = [];
    for (let i = 0; i < list.length; i += lineSize) {
      const slicedList = list.slice(i, i + lineSize);
      groupedList.push(slicedList);
    }
    return groupedList;
  })();

  return (
    <div>
      {result.map((lineList, index) => (
        <StepBoxLine
          key={index}
          list={lineList}
          index={index}
          isLastLine={index == result.length - 1}
          lineSize={lineSize}
          doneStyle={doneStyle}
        />
      ))}
    </div>
  );
};

export default NTemplate001;
