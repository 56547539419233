import { useTranslation } from 'react-i18next';
import PopBg from '../../../../components/popBg';
import DataTable from '../../../../../dataTable';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import { getShowMoney } from '../../../../../../utils/helper';
import Pager from '../../../../../pager';
import { ActivityPromotionalCashbackLog } from '../../../../../../api';

// const fakeList = Array(10)
//   .fill()
//   .map(() => ({
//     time: Date.now() / 1000,
//     invitation_bonus: '10',
//     bet_bonus: '30',
//     deposit_bonus: '40',
//   }));

const RewardRecord = ({ handleClose = () => {}, activity_id = '', isShowCloseBtn = true }) => {
  const { t } = useTranslation();

  const { defaultLang } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const initParams = {
    page: 1,
    page_size: 10,
    refreshNow: true,
    activity_id,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [list, setList] = useState([]);
  const [totalSize, setTotalSize] = useState(0); // 接口结果列表总条数
  const [params, setParams] = useState(initParams);

  const gridLineCssPc = 'w-full grid grid-cols-4 items-center justify-center gap-x-[.2rem] p-[.08rem_0]';

  useEffect(() => {
    setTableHeaderList([
      {
        content: t('referral.time'),
        className: 'text-center leading-[1.05em]',
      },
      {
        content: t('referral.invitation_bonus'),
        className: 'text-center leading-[1.05em]',
      },
      {
        content: t('referral.bet_bonus'),
        className: 'text-center leading-[1.05em]',
      },
      {
        content: t('referral.deposit_bonus'),
        className: 'text-center leading-[1.05em]',
      },
    ]);
  }, [defaultLang]);

  const updateList = () => {
    setIsLoading(true);

    ActivityPromotionalCashbackLog(params).then((res) => {
      setIsLoading(false);

      if (res.code === 0) {
        setTotalSize(res?.result?.pages?.count || 0);

        const list2 = (res?.result?.list || []).map((item) => ({
          date: moment(new Date(item?.claim_date)).format('MM-DD'),
          time: moment((item?.claim_time || 0) * 1000).format('HH:mm:ss'),
          invitation_bonus: getShowMoney(item?.claimed_amount_for_deposit || 0),
          bet_bonus: getShowMoney(item?.claimed_amount_for_wager || 0),
          deposit_bonus: getShowMoney(item?.claimed_amount_for_deposit_ratio || 0),
        }));

        setList([...list, ...list2]);
      }
    });
  };

  useEffect(() => {
    if (params.refreshNow) {
      updateList();
    }
  }, [params]);

  const tableBodyList = list.map((item, index) => ({
    className: cn(`${gridLineCssPc} text-center`, {
      'bg-[var(--colour-35)] rounded-normal': index % 2 == 0,
    }),
    list: [
      {
        content: (
          <div>
            <div>{item?.date}</div>
            <div>{item?.time}</div>
          </div>
        ),
        className: '',
      },
      {
        content: item?.invitation_bonus,
        className: '',
      },
      {
        content: item?.bet_bonus,
        className: '',
      },
      {
        content: item?.deposit_bonus,
        className: '',
      },
    ],
  }));

  const handleClickPrev = () => {
    if (params.page > 1) {
      setParams({
        ...params,
        page: params.page - 1,
        refreshNow: true,
      });
    }
  };

  const handleClickNext = () => {
    if (params.page < Math.ceil(totalSize / params.page_size)) {
      setParams({
        ...params,
        page: params.page + 1,
        refreshNow: true,
      });
    }
  };

  return (
    <>
      <PopBg handleClose={handleClose} isShowCloseBtn={isShowCloseBtn} className="!bg-[var(--colour-34)]">
        <div className="flex flex-col p-[.4rem_.32rem] w-full h-full">
          <div className="text-[.36rem] font-[500] text-center text-[var(--colour-48)]">
            {t('referral.reward_record')}
          </div>
          <div className="h-[1px] bg-[var(--colour-6)] shrink-0 mt-[.28rem]"></div>
          <div className="flex-1 pt-[.2rem] overflow-y-auto scrollbar-none h-[6.6rem]">
            <DataTable
              isLoading={isLoading}
              tableClassName=""
              head={{
                className: `${gridLineCssPc} w-full font-[500] text-[.24rem] text-[var(--colour-44)] py-[.2rem]`,
                list: tableHeaderList,
              }}
              body={{
                className: 'font-[500] text-[.24rem] text-[var(--colour-44)]',
                list: tableBodyList,
              }}
            />
          </div>
          {Math.ceil(totalSize / params.page_size) > 1 && (
            <Pager
              page={params.page}
              handleClickPrev={handleClickPrev}
              handleClickNext={handleClickNext}
              isTurnPage={false}
              handleClickMore={handleClickNext}
              maxPage={Math.ceil(totalSize / params.page_size)}
            />
          )}
        </div>
      </PopBg>
    </>
  );
};

export default RewardRecord;
