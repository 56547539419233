import useLangServerConfig from '../../../../../hooks/useLangServerConfig';
import { useTranslation } from 'react-i18next';
import { cmsImgUrl } from '../../../../../utils/helper';
import Icon, { IconName } from '../../../../../components/icon';

const CategoryGameHeader = ({
  id,
  icon = '',
  title = '',
  total = 0,
  setCurrentPage = () => {},
  setSwiperAnimation = () => {},
  maxPage,
  currentPage,
  isShowLoadMore = false,
}) => {
  const { t } = useTranslation();
  const { getServerLangText } = useLangServerConfig();

  const handleNext = () => {
    if (currentPage < maxPage - 1) {
      setSwiperAnimation(true);
      setCurrentPage((page) => {
        return page + 1;
      });
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setSwiperAnimation(true);
      setCurrentPage((page) => {
        return page - 1;
      });
    }
  };

  return (
    <div className="w-full h-[.92rem] flex items-center justify-between mb-[.28rem]">
      <div className="w-[4.92rem] h-full bg-[url(../assets/img/categoryGameHeader.png)] bg-[length:100%_100%] bg-no-repeat pl-[.24rem] flex items-center">
        <div className="w-[.56rem] h-[.56rem]">
          <img src={cmsImgUrl(icon)} alt="" />
        </div>

        <div className="ml-[.18rem] text-[var(--colour-48)] text-[.28rem] font-[700]">{getServerLangText(title)}</div>

        <div className="w-[1px] h-[.56rem] bg-[var(--colour-66)] rotate-[30deg] ml-[.3rem]"></div>

        <div className="flex flex-col items-center leading-[.26rem] ml-[.3rem]">
          <div className="text-[.24rem] font-[600] text-[var(--colour-40)]">{total || 0}</div>
          <div className="text-[.22rem] font-[600] text-[var(--colour-14)]">{t('home.gameTotal')}</div>
        </div>
      </div>
      <div className="flex-1 h-full flex items-center justify-end pr-[.3rem] gap-x-[.1rem]">
        <div
          className={`w-[.5rem] h-[.5rem] bg-[var(--colour-61)] rounded-[50%] flex items-center justify-center cursor-pointer ${currentPage == 0 || isShowLoadMore ? 'pointer-events-none' : ''}`}
          onClick={handlePrev}
        >
          <div
            className={`w-[.16rem] h-[.16rem] ${currentPage == 0 || isShowLoadMore ? 'text-[var(--colour-14)]' : 'text-[var(--colour-64)]'}`}
          >
            <Icon name={IconName.CategoryGameHeaderArrow}></Icon>
          </div>
        </div>

        <div
          className={`w-[.5rem] h-[.5rem] bg-[var(--colour-61)] rounded-[50%] flex items-center cursor-pointer justify-center ${currentPage == maxPage - 1 || isShowLoadMore ? 'pointer-events-none' : ''}`}
          onClick={handleNext}
        >
          <div
            className={`w-[.16rem] h-[.16rem] rotate-[180deg] ${currentPage == maxPage - 1 || isShowLoadMore ? 'text-[var(--colour-14)]' : 'text-[var(--colour-64)]'}`}
          >
            <Icon name={IconName.CategoryGameHeaderArrow}></Icon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryGameHeader;
