import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  setActivityData,
  setActivityItemData,
  setUserClaimTotalMoney,
  DialogModalChildrenKey,
} from '../../redux/reducers/appSlice';
import { setGiftMoneyMsg } from '../../redux/reducers/wsSlice';
import { useDeepCompareEffect, useUpdateEffect } from 'ahooks';
import { ActivityAllReq, GetMissionList } from '../../api';
import Entrance from './entrance';
import AutoPopup from './autoPopup';
import { getQueryString } from '../../utils/helper';
import { toNoLocalePath } from '../../utils/localePath';
import PwaReceiveAward from './pwaReceiveAward';
import TopPopup from './topPopup';

const Activity = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { refetchActivityData, identity, activityStatus, websiteInitConfig } = useSelector(
    (state) => ({
      activityStatus: state.app.activityStatus,
      identity: state.user.identity,
      refetchActivityData: state.app.refetchActivityData,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const paramsRef = useRef([]);
  const [params, setParams] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true); //判断是否是首次加载
  const [isAllPopupComplet, setIsAllPopupComplet] = useState(false); //判断是否自动弹窗全部完成。

  const fetchActivityData = (isSetAutoPopup, isPolling = false) => {
    if (isSetAutoPopup) {
      setIsAllPopupComplet(false);
    }

    const requestMissionConditions = !isPolling && !toNoLocalePath(pathname).includes('/promotion/mission');

    let activityAllReq = ActivityAllReq({
      data_type: JSON.stringify(paramsRef.current),
    });

    let getMissionList = requestMissionConditions ? GetMissionList() : Promise.resolve();

    Promise.all([activityAllReq, getMissionList]).then((result) => {
      const [activityAllResult, missionListResult] = result;

      if (activityAllResult !== undefined) {
        if (activityAllResult.code == 0) {
          let result = activityAllResult.result;
          //特殊处理一下giftMoney
          const giftMoneyData = result.gift_money;
          if (giftMoneyData) {
            if (giftMoneyData.info.id > 0) {
              dispatch(setGiftMoneyMsg(giftMoneyData.info));
            } else {
              dispatch(setGiftMoneyMsg(null));
            }
            dispatch(setUserClaimTotalMoney(giftMoneyData.user_claim_money.total_money));
          }
        }
      }

      let data = null;
      if (activityAllResult !== undefined) {
        if (activityAllResult.code == 0) {
          let result = activityAllResult.result;
          data = { ...data, ...result, isSetAutoPopup: isSetAutoPopup, activityStatus: activityStatus };
        }
      }

      if (missionListResult !== undefined) {
        if (missionListResult.code == 0) {
          let result = missionListResult.data;
          data = { ...data, mission: result, isSetAutoPopup: isSetAutoPopup, activityStatus: activityStatus };
        }
      }

      if (!requestMissionConditions) {
        //不更新mission数据
        dispatch(setActivityItemData(data));
      } else {
        dispatch(setActivityData(data));
      }
    });
  };

  useDeepCompareEffect(() => {
    const noLocalPathName = toNoLocalePath(pathname);
    if (params.length > 0 && noLocalPathName == '/') {
      fetchActivityData(identity || isFirstLoad || noLocalPathName == '/' ? true : false);
      setIsFirstLoad(false);
    }
  }, [identity, params, pathname]);

  useUpdateEffect(() => {
    fetchActivityData(false);
  }, [refetchActivityData]);

  useDeepCompareEffect(() => {
    if (activityStatus != null) {
      const paramsArr = ['notification'];
      if (activityStatus.gift_money_menu_show_switch == '1') {
        paramsArr.push('gift_money');
      }
      //红包雨
      if (activityStatus.red_packet_switch == '1') {
        paramsArr.push('activity_red_packet');
      }
      //裂变
      if (activityStatus.activity_contract_switch == '1') {
        paramsArr.push('activity_contract');
      }
      //新手奖励
      if (activityStatus.novice_switch == '1') {
        paramsArr.push('activity_novice');
      }
      //推广成就
      if (activityStatus.activity_promotional_achievements_switch == '1') {
        paramsArr.push('activity_promotional_achievements');
      }
      //推广返现
      if (activityStatus.activity_promotional_cashback_switch == '1') {
        paramsArr.push('activity_promotional_cashback');
      }
      //引导轮盘
      if (activityStatus.activity_novice_guide_tournament_switch == '1') {
        paramsArr.push('activity_novice_guide_tournament');
      }
      //神秘彩金
      if (activityStatus.activity_mystery_bonus_switch == '1') {
        paramsArr.push('activity_mystery_bonus');
      }
      paramsRef.current = paramsArr;
      setParams(paramsArr);
    }
  }, [activityStatus]);

  const handleAllPopupComplet = () => {
    setIsAllPopupComplet(true);
  };

  let autoRefetchDataId = useRef(-1);

  useEffect(() => {
    if (isAllPopupComplet) {
      autoRefetchDataId.current = window.setInterval(() => {
        fetchActivityData(false, true);
      }, 59 * 1000);
    } else {
      if (autoRefetchDataId.current > -1) {
        clearInterval(autoRefetchDataId.current);
      }
    }
  }, [isAllPopupComplet]);

  return (
    <>
      {toNoLocalePath(pathname) == '/' ? (
        <>
          <AutoPopup handleAllPopupComplet={handleAllPopupComplet} websiteInitConfig={websiteInitConfig} />
          <Entrance />
          <PwaReceiveAward />
          <TopPopup />
        </>
      ) : null}
    </>
  );
};

export default Activity;
