import React from 'react';
import useGameInfo from '../../hooks/useGameInfo';
import { isPCLayout } from '../../utils/helper';

const Backdrop = ({ onClick = () => {}, id, children }) => {
  const { isGameOpen } = useGameInfo();
  const handleClick = (e) => {
    if (e.target.id === id) {
      onClick();
    }
  };

  return (
    <div
      id={id}
      className={`bg-[transparent] h-[var(--app-height)] flex items-center justify-center ${isPCLayout() ? `${isGameOpen ? 'w-full' : 'w-[var(--theme-max-width)]'}` : 'w-[100vw]'}`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default Backdrop;
