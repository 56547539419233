import { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import Icon, { IconName } from '../../../../icon';
import TippyPopBox, { Placement } from '../../../../tippyPopBox';
import PopChoose, { PopChooseItem } from '../../../../popChoose';
import FullPagePopup from '../../../../fullpagePopup';
import DropDownInput from '../../../../dropDownInput';
import ConfirmTips from '../../../../confirmTips';
import Header from '../components/header';
import ScrollWrapper from '../../../../scrollWrapper';
import ItemBox1 from '../components/itemBox1';
import ItemBox2 from '../components/itemBox2';
import Line from '../../../../line';
import Input from '../../../../input';
import InputHighLight from '../components/inputHighlight';
import LimitClickWrapper from '../../../../limitClickWrapper';
import ElementWithLoading from '../../../../elementWithLoading';
import DialogModal from '../../../../dialogModal';
import ConfirmWithdrawPassword from '../components/confirmWithdrawPassword';
import WidthdrawQuestion from '../../../../wallet/component/widthdrawQuestion';
import PayLogDialog from '../../../../wallet/component/payLogDialog';
import WithdrawRecordPanda from './withdrawRecordPanda';
import {
  putChangeText,
  pointToComma,
  getShowMoneyNumber,
  openGiftMoneyDetail,
  cutDecimal,
  hasNestedProperty,
  commaToPoint,
  putShowMoney,
} from '../../../../../utils/helper';
import { setUserObj } from '../../../../../redux/reducers/userSlice';
import useGameInfo from '../../../../../hooks/useGameInfo';
import { toNoLocalePath } from '../../../../../utils/localePath';
import { ExchangeRate } from '../../../../../api';
import { PaymentDirectWithdrawBrlSubmitWithPassword } from '../../../../../api/newDepositWithdraw';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';

export default function WithdrawMainPanda({ handleClose = () => {}, withdrawConfig, upDateWithdrawConfig = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { websiteInitConfig, websiteInfo, currency_symbol, userObj } = useSelector(
    (state) => ({
      websiteInitConfig: state?.app?.websiteInitConfig,
      websiteInfo: state.app.websiteInitConfig?.website_info,
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );
  const { pathname } = useLocation();
  const { isGameOpen } = useGameInfo();
  const passwordValueRef = useRef('');

  const [showConfirmPassword, setShowConfirmPassword] = useState(false); //confirmPassword 弹窗是否展示
  const [showRecord, setShowRecord] = useState(false); //提现记录弹窗

  const [submitForm, setSubmitForm] = useState({
    // 新提交字段
    method: '', // 支付方式
    name: '', // 姓名
    cpf: '', // 巴西个人身份识别号码
    phone: '', // 手机号(method=phone时此字段必传)
    email: '', // 邮箱(method=email时此字段必传)
    amount: '', // 提现金额
    config_id: '', // 配置id
    cnpj: '', // 巴西企业身份识别号码(method=cnpj时此字段必传)
    evp: '', // 随机密钥（PIX 系统为帐户生成的数字）或虚拟支付地址(method=evp时此字段必传)
  });

  const [requesting, setRequesting] = useState(false);
  const [payLog, setPayLog] = useState(false);

  const [currentHistory, setCurrentHistory] = useState(null);
  const [decimal, setDecimal] = useState(2);
  const [isEnableCryptoWithdraw, setIsEnableCryptoWithdraw] = useState(false);
  // 提现消息
  const [isOpen, setIsOpen] = useState(false);

  const [withdraw_fee, setWithdraw_fee] = useState('');
  const [withdrawMethodList, setWithdrawMethodList] = useState([]);
  const [currentWithdrawMethod, setCurrentWithdrawMethod] = useState(null);
  const [historyList, setHistoryList] = useState([]);
  const [crypto, setCrypto] = useState(null);
  const [cryptoList, setCryptoList] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [curHistory, setCurHistory] = useState([]);
  const [nameVisible, setNameVisible] = useState(false);

  const cpfRegx = /^[0-9]{11}$/;
  const cpfInputRegx = /^[0-9]{0,11}$/;
  const emailRegx = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  const phoneRegx = /^[0-9]{11}$/;
  const phoneInputRegx = /^[0-9]{0,11}$/;
  const cnpjRegx = /^[0-9]{14}$/;
  const cnpjInputRegx = /^[0-9]{0,14}$/;
  const evpRegx = /^.{36}$/;
  const evpInputRegx = /^.{0,36}$/;

  useEffect(() => {
    if (websiteInitConfig) {
      if (hasNestedProperty(websiteInitConfig, 'system_config.currency_decimal_point_precision')) {
        setDecimal(websiteInitConfig.system_config.currency_decimal_point_precision);
      }
      setIsEnableCryptoWithdraw(websiteInitConfig?.website_info?.switch_wallet_crypto_withdraw == '1');
    }
  }, [websiteInitConfig]);

  useEffect(() => {
    if (withdrawConfig) {
      dispatch(
        setUserObj({
          ...userObj,
          balance: withdrawConfig.user_obj.balance,
          locked_balance: withdrawConfig.user_obj.locked_balance,
        }),
      );

      setWithdraw_fee(withdrawConfig?.withdraw_fee);

      const _withdrawMethodList = withdrawConfig?.withdraw_amount_limit;
      if (_withdrawMethodList.length > 0) {
        setWithdrawMethodList(_withdrawMethodList);
        const _currentWithdrawMethod = _withdrawMethodList[0];
        setCurrentWithdrawMethod(_currentWithdrawMethod);
        setSubmitForm({
          ...submitForm,
          method: _currentWithdrawMethod.method,
          config_id: 0,
        });
      }

      if (isEnableCryptoWithdraw) {
        if (withdrawConfig?.crypto_configs && withdrawConfig?.crypto_configs.length > 0) {
          const mergedData = withdrawConfig.crypto_configs.reduce((acc, item) => {
            const existingItem = acc.find((accItem) => accItem.currency === item.currency);
            if (existingItem) {
              existingItem.networks.push(item);
            } else {
              item.networks = [item];
              acc.push(item);
            }
            return acc;
          }, []);

          setCryptoList(mergedData);

          getExchangeRateData();

          setCrypto(mergedData[0]);
        }
      }

      if (withdrawConfig.withdraw_account && withdrawConfig.withdraw_account.length > 0) {
        setHistoryList(withdrawConfig.withdraw_account);
      }
    }
  }, [withdrawConfig]);

  useEffect(() => {
    if (historyList.length > 0) {
      if (currentWithdrawMethod) {
        const _curHistory = historyList.filter((item) => item.method == currentWithdrawMethod.method);
        if (_curHistory.length > 0) {
          const firstHistory = _curHistory[0];
          setCurHistory(_curHistory);
          setCurrentHistory(firstHistory);
          setSubmitForm({
            ...submitForm,
            name: firstHistory.name,
            cpf: firstHistory.cpf,
            email: firstHistory.email,
            phone: firstHistory.phone,
            cnpj: firstHistory.cnpj,
            evp: firstHistory.evp,
          });
        } else {
          setCurHistory([]);
          setCurrentHistory(null);
          setSubmitForm({
            ...submitForm,
            name: '',
            cpf: '',
            email: '',
            phone: '',
            cnpj: '',
            evp: '',
          });
        }
      }
    }
  }, [historyList, currentWithdrawMethod]);

  const OpenGiftMoneyDialog = () => {
    // dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
    openGiftMoneyDetail(isGameOpen, toNoLocalePath(pathname));
  };

  const getSubmitFormFields = (obj) => {
    //后面这个是必须字段，其他都不需要，如果带了，就会提示参数错误
    //method=cpf   method,name,cpf,amount
    //method=phone   method,name,cpf,amount,phone
    //method=email   method,name,cpf,amount,email
    //method=cnpj   method,name,cpf,amount,cnpj
    //method=evp    method,name,cpf,amount,evp
    // method: '', // 支付方式
    // name: '', // 姓名
    // cpf: '', // 巴西个人身份识别号码
    // phone: '', // 手机号(method=phone时此字段必传)
    // email: '', // 邮箱(method=email时此字段必传)
    // amount: '', // 提现金额
    // config_id: '', // 配置id
    // cnpj: '', // 巴西企业身份识别号码(method=cnpj时此字段必传)
    // evp: '', // 随机密钥（PIX 系统为帐户生成的数字）或虚拟支付地址(method=evp时此字段必传)
    const commonObj = {
      method: obj.method,
      name: String(obj.name).trim(),
      cpf: obj.cpf,
      amount: putShowMoney(commaToPoint(obj.amount || 0)),
    };
    switch (String(obj.method).toLowerCase()) {
      case 'cpf':
        return commonObj;
      case 'phone':
        return { ...commonObj, phone: obj.phone };
      case 'email':
        return { ...commonObj, email: obj.email };
      case 'cnpj':
        return { ...commonObj, cnpj: obj.cnpj };
      case 'evp':
        return { ...commonObj, evp: obj.evp };
    }
  };

  const handleWithdraw = () => {
    let banlace = moneyReturn();

    if (!commaToPoint(submitForm.amount)) {
      toast.info(t('deposit-withdraw.enter_amount'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(commaToPoint(submitForm.amount)) > Number(banlace)) {
      toast.info(t('deposit-withdraw.amount_exceeds'), {
        containerId: 'global',
      });
      return;
    }

    // if (Number(commaToPoint(submitForm.amount)) < Number(getShowMoneyNumber(channel?.withdraw_min || 0))) {
    if (Number(commaToPoint(submitForm.amount)) < Number(getShowMoneyNumber(currentWithdrawMethod?.min || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit1', {
          min_amount: getShowMoneyNumber(currentWithdrawMethod?.min || 0),
          currency_symbol: currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    // if (Number(commaToPoint(submitForm.amount)) > Number(getShowMoneyNumber(channel?.withdraw_max || 0))) {
    if (Number(commaToPoint(submitForm.amount)) > Number(getShowMoneyNumber(currentWithdrawMethod?.max || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit2', {
          max_amount: getShowMoneyNumber(currentWithdrawMethod?.max || 0),
          currency_symbol: currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    if (!submitForm.name || submitForm.name.trim() == '') {
      toast.info(t('deposit-withdraw.enter_username'), {
        containerId: 'global',
      });
      return;
    }

    // if (/^[a-zA-Z ,.]{1,100}$/.test(submitForm.name) === false) {
    //   toast.info(t('deposit-withdraw.enter_username'), {
    //     containerId: 'global',
    //   });
    //   return;
    // }

    if (!cpfRegx.test(submitForm.cpf)) {
      toast.info(t('1008033'), {
        containerId: 'global',
      });
      return;
    }

    if (currentWithdrawMethod.method == 'email') {
      if (!emailRegx.test(submitForm.email)) {
        toast.info(t('1008013'), {
          containerId: 'global',
        });
        return;
      }
    }

    if (currentWithdrawMethod.method == 'phone') {
      if (!phoneRegx.test(submitForm.phone)) {
        toast.info(t('1008016'), {
          containerId: 'global',
        });
        return;
      }
    }

    if (currentWithdrawMethod.method == 'cnpj') {
      if (!cnpjRegx.test(submitForm.cnpj)) {
        toast.info(t('1008034'), {
          containerId: 'global',
        });
        return;
      }
    }

    if (currentWithdrawMethod.method == 'evp') {
      if (!evpRegx.test(submitForm.evp)) {
        toast.info(t('deposit-withdraw.evp_format_error'), {
          // toast.info('evp format error', {
          containerId: 'global',
        });
        return;
      }
    }

    //需要输入密码
    if (withdrawConfig?.switch_withdraw_password == '1') {
      setShowConfirmPassword(true);
    } else {
      requestWithdrawSubmit();
    }
  };

  const requestWithdrawSubmit = () => {
    console.log('submitForm', submitForm);

    setRequesting(true);

    let requestSubmitForm = {
      ...getSubmitFormFields(submitForm),
    };

    //需要输入密码
    if (withdrawConfig?.switch_withdraw_password == '1') {
      requestSubmitForm = { ...requestSubmitForm, password: passwordValueRef.current };
    }

    // WithdrawAction({
    PaymentDirectWithdrawBrlSubmitWithPassword(requestSubmitForm)
      .then((res) => {
        if (res.code == 0) {
          setPayLog(true);
          // getInitData();
          upDateWithdrawConfig();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
        passwordValueRef.current = '';
      });
  };

  const passwordSubmitCallback = (str) => {
    passwordValueRef.current = str;
    setShowConfirmPassword(false);
    requestWithdrawSubmit();
  };

  const moneyReturn = () => {
    const balance = get(userObj, 'balance');
    const locked_balance = get(userObj, 'locked_balance');

    if (balance) {
      const val2 = getShowMoneyNumber(BigNumber(balance).minus(locked_balance).toFixed());
      return val2 <= 0 ? 0 : Number(cutDecimal(val2, decimal));
    } else {
      return 0;
    }
  };
  const getFee = () => {
    // if (channel) {
    //   let fee;
    //   if (currency == 1) {
    //     fee = withdraw_fee;
    //   } else {
    //     fee = bonus_withdraw_fee;
    //   }

    //   if (commaToPoint(submitForm.amount)) {
    //     let valFee = String(Number(commaToPoint(commaToPoint(submitForm.amount))) * Number(fee));
    //     return Math.floor(Number(valFee) * 100) / 100;
    //   } else {
    //     return '0';
    //   }
    // } else {
    //   return '0';
    // }

    // let fee;
    // if (currency == 1) {
    //   fee = withdraw_fee;
    // } else {
    //   fee = bonus_withdraw_fee;
    // }

    let fee = withdraw_fee;

    if (commaToPoint(submitForm.amount)) {
      let valFee = String(Number(commaToPoint(commaToPoint(submitForm.amount))) * Number(fee));
      return Math.floor(Number(valFee) * 100) / 100;
    } else {
      return '0';
    }
  };
  const getExchangeRateData = () => {
    ExchangeRate().then((res) => {
      if (res.code == 0) {
        if (res.result) {
          setExchangeRate(res.result.list);
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const handlePixTypeChange = (item) => {
    setCurrentWithdrawMethod(item);
    setSubmitForm({
      ...submitForm,
      method: item.method,
      config_id: 0,
    });
  };

  const handleChangeAmount = (ev) => {
    setSubmitForm({
      ...submitForm,
      amount: putChangeText(ev.target.value),
    });
  };

  const handleNameChange = (ev) => {
    let val = ev.target.value;

    // val = val.replace(/[^a-zA-Z ,.]+/g, '');
    // val = val.slice(0, 100);
    val = val.slice(0, 40);

    setSubmitForm({
      ...submitForm,
      name: val,
    });
  };
  const handleEmailChange = (ev) => {
    setSubmitForm({
      ...submitForm,
      email: ev.target.value,
    });
  };

  const handlePhoneChange = (ev) => {
    const val = ev.target.value;

    if (phoneInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        phone: val,
      });
    }
  };

  const handleCpfChange = (ev) => {
    const val = ev.target.value;

    if (cpfInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        cpf: val,
      });
    }
  };

  const handleCnpjChange = (ev) => {
    const val = ev.target.value;

    if (cnpjInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        cnpj: val,
      });
    }
  };

  const handleEvpChange = (ev) => {
    const val = ev.target.value;

    if (evpInputRegx.test(val)) {
      setSubmitForm({
        ...submitForm,
        evp: val,
      });
    }
  };

  const handleSelectHistory = (item) => {
    setSubmitForm({
      ...submitForm,
      name: item.name,
      cpf: item.cpf,
      email: item.email,
      phone: item.phone,
      cnpj: item.cnpj,
      evp: item.evp,
    });

    setNameVisible(false);

    setCurrentHistory(item);
  };

  const handleClickBackBtn = () => {
    handleClose();
  };

  const handleClickRecordBtn = () => {
    setShowRecord(true);
  };

  return (
    <div className="h-full flex flex-col">
      <Header
        title={t('deposit-withdraw.withdraw')}
        clickBackBtn={handleClickBackBtn}
        showRecordBtn={true}
        clickRecordBtn={handleClickRecordBtn}
      />

      <div className="flex-1 p-[.48rem_.36rem]">
        <ScrollWrapper
          noRelative={true}
          mr={false}
          className="h-[calc(var(--app-height)-2rem)]"
          // isShowOnBottom={isScrollToBottom}
        >
          <div className="text-[.28rem] font-[500] leading-[.34rem] mb-[.24rem]">
            <div className="flex items-center mb-[.2rem]">
              <div className="text-[var(--colour-18)] mr-[.12rem]">{t('deposit-withdraw.withdrawable')}</div>
              <div className="text-[var(--colour-26)] mr-[.24rem]">
                {websiteInitConfig?.website_info?.currency_symbol || ''}
                {pointToComma(moneyReturn())}
              </div>
              <div
                className="w-[.32rem] h-[.32rem] text-[var(--colour-7)] cursor-pointer"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <Icon name={IconName.WidthdrawQuestion} />
              </div>
            </div>
            {websiteInitConfig?.website_info?.switch_gift_money_menu_show == '1' ? (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  OpenGiftMoneyDialog();
                }}
              >
                <div className="text-[var(--colour-18)] mr-[.12rem]">{t('account.fund')}</div>
                <div className="text-[var(--colour-13)] underline underline-offset-2">
                  {websiteInitConfig?.website_info?.currency_symbol || ''}
                  {pointToComma(getShowMoneyNumber(withdrawConfig?.user_claim_money?.total_money || 0))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="mb-[.22rem]">
            <ItemBox1 selected={true}>PIX</ItemBox1>
          </div>
          <Line />
          <div className="text-[.28rem] font-[500] leading-[.34rem] text-[var(--colour-13)] mt-[.12rem] mb-[.24rem]">
            {t('deposit-withdraw.amount')}
          </div>
          <div className="flex items-center mb-[.26rem]">
            <div className="w-[.24rem] h-[.24rem] text-[var(--colour-1)] mr-[.06rem]">
              <Icon name={IconName.Tip} />
            </div>
            <div className="text-[.22rem] font-[500] leading-[.26rem] text-[var(--colour-1)]">
              {t('deposit-withdraw.withdrawal_fee')}: {currency_symbol || ''} {pointToComma(getFee())}
            </div>
          </div>
          <div className="mb-[.38rem]">
            <Input
              leftOuter={
                <div className="flex items-center">
                  <div className="block text-[.28rem] font-[600] m-[0_.32rem_0_.48rem] text-[var(--colour-2)]">
                    {websiteInfo.currency_symbol || ''}
                  </div>
                </div>
              }
              type="number"
              onChange={handleChangeAmount}
              value={submitForm.amount}
              placeholder={t('deposit-withdraw.input_amount', {
                min: pointToComma(getShowMoneyNumber(currentWithdrawMethod?.min || 0)),
                max: pointToComma(getShowMoneyNumber(currentWithdrawMethod?.max || 0)),
              })}
              innerClassName=" !h-[.76rem] !border-transparent"
              topClassName="bg-[var(--colour-22)] rounded-[.12rem] overflow-hidden border border-transparent"
              inputClassName="!text-[.28rem] !font-[500] !text-[var(--colour-2)] placeholder:!text-[var(--colour-14)]"
            />
          </div>
          <Line />
          {withdrawMethodList && withdrawMethodList.length > 0 ? (
            <>
              <div className="text-[.28rem] font-[500] leading-[.34rem] text-[var(--colour-13)] mt-[.28rem] mb-[.3rem]">
                {t('deposit-withdraw.type')}
              </div>

              <div className="grid grid-cols-4 gap-y-[.2rem] mb-[.46rem]">
                {withdrawMethodList.map((item, index) => {
                  return (
                    <ItemBox2
                      key={index}
                      className="!w-[1.52rem] !h-[.64rem] !text-[.24rem] cursor-pointer"
                      selected={currentWithdrawMethod?.method == item.method}
                      click={() => handlePixTypeChange(item)}
                    >
                      {item.method.toUpperCase()}
                    </ItemBox2>
                  );
                })}
              </div>
            </>
          ) : null}
          <div className="mb-[.58rem]">
            <Title text={t('deposit-withdraw.beneficiary_name')} />

            <div className="flex items-center">
              <InputHighLight
                value={submitForm.name}
                onChange={handleNameChange}
                placeholder={t('deposit-withdraw.enter_username')}
                className="flex-1"
              />
              {curHistory.length > 0 && (
                <div>
                  <TippyPopBox
                    placement={Placement.bottomEnd}
                    popChildren={
                      <PopChoose
                        className={`rounded-[.16rem] w-[6.78rem] max-h-[5rem] overflow-y-auto scrollbar-none border border-[var(--colour-6)] bg-[var(--colour-35)] aotuWdith`}
                        children={curHistory.map((item, index) => (
                          <PopChooseItem
                            className="!h-[.72rem] flex cursor-pointer items-center text-[.24rem] font-[500]"
                            chooseClassName="text-[var(--colour-13)]"
                            key={index}
                            data={item}
                            // choose={submitForm.dict_key == item.dict_key}
                            choose={item.id == currentHistory.id}
                            // onClick={() => {
                            //   setSubmitForm({
                            //     ...submitForm,
                            //     name: item.name,
                            //     cpf: item.cpf,
                            //     dict_key: item.cpf,
                            //   });
                            //   setNameVisible(false);
                            // }}
                            onClick={() => handleSelectHistory(item)}
                          >
                            <span
                              className={`pl-[.24rem] !h-[.72rem]  w-full flex items-center justify-start hover:bg-[var(--colour-68)] ${item.id == currentHistory.id ? 'text-[var(--colour-13)]' : 'text-[var(--colour-41)]'}`}
                            >
                              {item.name}
                            </span>
                          </PopChooseItem>
                        ))}
                      />
                    }
                    visible={nameVisible}
                    onClickOutside={() => {
                      setNameVisible(false);
                    }}
                    offset={[0, 10]}
                  >
                    <div className="px-[0.28rem]">
                      <DropDownInput
                        iconCanRotate={true}
                        iconRotate={nameVisible}
                        className={`w-full  !h-[.92rem]  text-[.24rem] font-[500] cursor-pointer !justify-center rounded-normal !rounded-l-none`}
                        onClick={() => {
                          setNameVisible(!nameVisible);
                        }}
                      >
                        <div className="flex flex-row items-center justify-right">
                          <span
                            className={cn('w-[.36rem] h-[.18rem] block transition text-[var(--colour-11)]', {
                              'rotate-[180deg]': nameVisible,
                            })}
                          >
                            <Icon name={IconName.SingleArrow} />
                          </span>
                        </div>
                      </DropDownInput>
                    </div>
                  </TippyPopBox>
                </div>
              )}
            </div>
          </div>

          {currentWithdrawMethod?.method == 'email' && (
            <div className="mb-[.58rem]">
              <Title text={t('deposit-withdraw.email')} />

              <InputHighLight
                value={submitForm.email}
                onChange={handleEmailChange}
                placeholder={t('deposit-withdraw.enter_email')}
              />
            </div>
          )}

          {currentWithdrawMethod?.method == 'phone' && (
            <div className="mb-[.58rem]">
              <Title text={t('deposit-withdraw.phone')} />

              <InputHighLight
                value={submitForm.phone}
                onChange={handlePhoneChange}
                placeholder={t('deposit-withdraw.enter_phone')}
              />
            </div>
          )}

          {currentWithdrawMethod?.method == 'cnpj' && (
            <div className="mb-[.58rem]">
              <Title text={'CNPJ'} />

              <InputHighLight
                value={submitForm.cnpj}
                onChange={handleCnpjChange}
                placeholder={t('deposit-withdraw.enter_cnpj')}
              />
            </div>
          )}

          {currentWithdrawMethod?.method == 'evp' && (
            <div className="mb-[.58rem]">
              <Title text={'EVP'} />

              <InputHighLight
                value={submitForm.evp}
                onChange={handleEvpChange}
                placeholder={t('deposit-withdraw.enter_evp')}
              />
            </div>
          )}

          <div className="mb-[.58rem]">
            <Title text={'CPF'} />

            <InputHighLight
              value={submitForm.cpf}
              onChange={handleCpfChange}
              placeholder={t('deposit-withdraw.enter_cpf')}
            />
          </div>
          <div className="flex items-center justify-center">
            <LimitClickWrapper
              className="cursor-pointer active:scale-[.95] transition-all select-none flex items-center justify-center rounded-[.2rem] colour-79 min-w-[5.38rem] h-[.76rem]"
              onClick={() => {
                if (!requesting) {
                  AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_WithdrawButton);
                  handleWithdraw();
                }
              }}
            >
              <ElementWithLoading
                isLoading={requesting}
                normalEl={
                  <div className="text-[.3rem] font-[600] text-[var(--colour-2)]">{t('deposit-withdraw.withdraw')}</div>
                }
              />
            </LimitClickWrapper>
          </div>
        </ScrollWrapper>
      </div>

      <ConfirmTips type="withdraw" />
      <FullPagePopup isOpen={showRecord}>
        <WithdrawRecordPanda
          handleClose={() => {
            setShowRecord(false);
          }}
        />
      </FullPagePopup>

      {/* 输入密码 */}
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        handleClose={() => {
          setShowConfirmPassword(false);
        }}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0rem',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0rem',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={showConfirmPassword}
      >
        <ConfirmWithdrawPassword
          handleClose={() => {
            setShowConfirmPassword(false);
          }}
          submitCallback={(str) => {
            passwordSubmitCallback(str);
          }}
          amount={submitForm.amount}
          fee={pointToComma(getFee())}
        />
      </DialogModal>

      <DialogModal
        onRequestClose={() => {
          setIsOpen(false);
        }}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '50',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isOpen}
      >
        <WidthdrawQuestion
          decimalPoit={decimal}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          handleClose();
          setPayLog(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={payLog}
      >
        <PayLogDialog
          handleClose={() => {
            handleClose();
            setPayLog(false);
          }}
        />
      </DialogModal>
    </div>
  );
}

const Title = ({ text = '' }) => {
  return <div className="text-[.24rem] font-[500] leading-[.3rem] text-[var(--colour-13)] mb-[.06rem]">{text}</div>;
};
