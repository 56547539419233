import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { getShowMoney, getPercentage } from '../../../../../../utils/helper';
import cn from 'classnames';
import { getImg } from './helper';

const VipBadgeBox = ({ vipLevel = 0, children }) => {
  const list = [
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.76rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
    'top-[.52rem] right-[.48rem]  ',
  ];
  return <div className={`absolute z-[20] ${list[vipLevel]}`}>{children}</div>;
};

const VipBadge = ({ vipLevel = 0 }) => {
  const list = [
    ' w-[1.16rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.24rem]',
    ' w-[1.26rem]',
    ' w-[1.78rem]',
    ' w-[1.76rem]',
    ' w-[1.76rem]',
    ' w-[1.98rem]',
    ' w-[1.96rem]',
    ' w-[2.14rem]',
    ' w-[2.14rem]',
    ' w-[2.14rem]',
    ' w-[2.14rem]',
  ];

  return <img src={getImg(vipLevel)} alt="" className={list[vipLevel]} />;
};

function VipLevelCard({ data, myVip, totalWager, totalDeposit, maxVipLevel = 20 }) {
  console.log('VipLevelCard maxVipLevel', maxVipLevel);
  const { t } = useTranslation();
  const [vipLevelList, setVipLevelList] = useState(data);
  const [vipLevel, setVipLeve] = useState(0);
  const [recharge, setRecharge] = useState(0);
  const [amount, setAmount] = useState(0);
  const [ispass, setIsPass] = useState(false);

  const [vipTitle, setVipTitle] = useState(t('vip.uncompleted'));
  const { defaultLang, currency_code } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
      currency_code: get(state, 'app.websiteInitConfig.website_info.currency_code') || '',
    }),
    shallowEqual,
  );

  const getJindu = (lw, mw, w) => {
    return getPercentage(lw, mw) + '%';
    // let jindu = (lw / mw) * w;
    // if (jindu > w) {
    //   jindu = w - 2;
    // }
    // return jindu + 'px';
  };

  useEffect(() => {
    setVipLevelList(data);
    let myData = JSON.parse(JSON.stringify(data.vip_level_config));
    setVipLeve(myData.vip);

    // console.log('vipLevelListvipLevelList:', myData.vip_level_config.vip);
    setAmount(myData.total_wager);
    setRecharge(myData.total_deposit);
    if (myVip == myData.vip) {
      setVipTitle(t('vip.currentLevel'));
      setIsPass(false);
    } else {
      if (totalWager >= Number(myData.total_wager) && totalDeposit >= Number(myData.total_deposit)) {
        setVipTitle(t(''));
        setIsPass(true);
      } else {
        setVipTitle(t('vip.uncompleted'));
        setIsPass(false);
      }
    }
  }, [data, totalDeposit, totalWager, myVip, defaultLang]);

  return (
    <div className="w-[6.34rem] cursor-grab active:cursor-grabbing h-[3.6rem] rounded-normal relative overflow-hidden  ">
      <>
        <div className="bg-[url(/static/img/vipLevelbg.png)] bg-[length:100%_100%] bg-no-repeat absolute w-[12.86rem] h-[3.6rem] left-[.74rem] top-0 z-[1]"></div>
        <div className="bg-[url(/static/img/vipLevelbg1.png)] bg-[length:100%_100%] bg-no-repeat absolute w-[12.86rem] h-[3.6rem] left-0 top-0 z-[1]"></div>
      </>

      <VipBadgeBox vipLevel={vipLevel}>
        <VipBadge vipLevel={vipLevel} />
      </VipBadgeBox>

      <div className={`w-full h-full    px-[.4rem] pt-[.58rem] pb-[.4rem] bg-vip-${vipLevel}`}>
        <div className="flex   mt-[-.12rem]">
          <div className={`font-[600] text-[.6rem]  text-vip-${vipLevel} z-[2]`}>V{vipLevel}</div>
          <div
            className={`font-[500] text-[#FFF] text-[.32rem]    ml-[.4rem]  z-[2] mt-[.1rem]  ${
              vipTitle == t('vip.currentLevel') ? `text-vip-${vipLevel}` : ''
            }`}
          >
            {vipLevel >= maxVipLevel ? '' : vipTitle}
          </div>
        </div>

        <>
          {!ispass && vipLevel < maxVipLevel ? (
            <>
              <div className="flex gap-[.16rem] text-[.24rem]    ">
                <div className={`text-vip-${vipLevel}  z-[2] font-[400] `}>{t('vip.recharge')}</div>
                <div
                  className={cn(`z-[2] font-[600] text-[.24rem] vip-recharge-${vipLevel}`, {
                    // 'text-[.24rem]': currency_code == 'COP',
                    // 'text-[.24rem]': currency_code != 'COP',
                  })}
                >
                  {totalDeposit > recharge ? getShowMoney(recharge) : getShowMoney(totalDeposit)}/
                  {getShowMoney(recharge)}
                </div>
              </div>

              <div
                className={`h-[.25rem]   w-[2.8rem]  mt-1  bg-progress-bar${vipLevel} ${'rounded-normal'}  overflow-hidden relative z-[20] border border-[rgba(0,0,0,0.3)] flex items-center`}
              >
                <div
                  className={`h-[.2rem]   vip-schedule-bg z-[20] ${'rounded-normal'}`}
                  style={{ width: getJindu(totalDeposit, recharge, 140) }}
                ></div>
              </div>

              <div className="flex  gap-[.16rem]   text-[.24rem] mt-[.24rem]  ">
                <div className={`text-vip-${vipLevel} z-[2]  font-[400]  `}>{t('vip.betAmount')}</div>
                <div className={`z-[2] font-[600] vip-recharge-${vipLevel}   `}>
                  {totalWager > amount ? getShowMoney(amount) : getShowMoney(totalWager)}/{getShowMoney(amount)}
                </div>
              </div>

              <div
                className={`h-[.16rem]   mt-[.04rem] w-[5.2rem]   bg-progress-bar${vipLevel}  ${'rounded-normal'}  overflow-hidden relative z-[20]  border border-[rgba(0,0,0,0.3)] flex items-center`}
              >
                <div
                  className={`h-[.12rem]  vip-schedule-bg z-[20]  ${'rounded-normal'}`}
                  style={{ width: getJindu(totalWager, amount, 260) }}
                ></div>
              </div>

              <div className="w-[5.2rem]     mt-[.04rem] flex justify-between">
                <div className="flex text-[.24rem]  text-[#44010A] z-[2]">V{0}</div>
                <div className="flex text-[.24rem]  text-[#44010A] z-[2]">V{vipLevel + 1}</div>
              </div>
            </>
          ) : (
            <>
              {vipLevel >= maxVipLevel ? (
                <div className="flex justify-center items-center text-center   mt-[1rem]">
                  <div className="flex text-[.4rem]  text-[#240032] font-[500] z-[20] ">{t('vip.highestLevel')}</div>
                </div>
              ) : (
                <>
                  <div className="flex justify-center items-center text-center  font-[500]   mt-[1rem]">
                    <div className="flex text-[.4rem]  text-[#240032] font-[500] z-[20]">{t('vip.youPassedLevel')}</div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default VipLevelCard;
