import { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import HeaderBack from '../../../../components/headerBack';
import cn from 'classnames';
import Icon, { IconName } from '../../../../components/icon';
import { GetPromotionEventsList } from '../../../../api';
import { cmsImgUrl, getCurrentLang, getQueryString, isLogin } from '../../../../utils/helper';
import Loading from '../../../../components/loading2';
import NoData from '../../../../components/noData';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import { useBodyOverflow } from '../../../../hooks/useBodyOverflow';
import { toLocalePath } from '../../../../utils/localePath';
import useLink from '../../../../hooks/useLink';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import { useDispatch } from 'react-redux';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setShowHeaderGiftMoney,
} from '../../../../redux/reducers/appSlice';
import useDraggableScroll from '../../../../hooks/useDraggableScroll';
import { toast } from 'react-toastify';

const EventsDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(getQueryString('scrollTop') || 0);
  const [fromIndex, setFromIndex] = useState(getQueryString('fromIndex') || '/');
  const { handleCmsLink } = useLink();
  const { h } = useWindowHeight(0.9);
  const [isShowLoading, setisShowLoading] = useState(true);
  const [list, setList] = useState([]);
  const { promotionId } = useParams();
  const [currentID, setCurrentID] = useState(promotionId);
  const [content, setContent] = useState(null);
  const ref = useRef(null);
  const containerRef = useRef(null);

  useBodyOverflow(true);

  const scrollToNthItem = (targetIndex) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const targetElement = container.querySelectorAll('.eventsItem')[targetIndex];

      if (targetElement) {
        const targetRect = targetElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const scrollLeft = container.scrollLeft;

        const newScrollLeft =
          scrollLeft + (targetRect.left - containerRect.left - container.clientWidth / 2 + targetRect.width / 2);

        container.scrollTo({
          left: newScrollLeft,
          behavior: 'smooth',
        });
      }
    }
  };

  useDraggableScroll(containerRef, [list]);

  useEffect(() => {
    if (list && list.length > 0) {
      const timeoutId = setTimeout(() => {
        const _findIndex = list.findIndex((v) => {
          return v.id == currentID;
        });

        scrollToNthItem(_findIndex);
      }, 0);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [list, content]);

  const getDetaildata = async () => {
    setisShowLoading(true);
    try {
      const res = await GetPromotionEventsList({
        lang: getCurrentLang(),
      });
      if (res.code == 0) {
        const data = res.data.all;
        const _find = data.find((v) => {
          return v.id == currentID;
        });

        setList(data);
        setContent(_find);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisShowLoading(false);
    }
  };

  useEffect(() => {
    getDetaildata();
  }, []);

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
      });
    }
  };

  useEffect(() => {
    if (list) {
      const _find = list.find((v) => {
        return v.id == currentID;
      });

      setContent(_find);
    }
  }, [currentID]);

  useEffect(() => {
    scrollToTop();
  }, [content]);

  const handleGoHistory = () => {
    if (!isLogin()) {
      return dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
    navigate(toLocalePath(`/promotion/history`));
  };

  const handleHideHeaderGiftMoney = () => {
    dispatch(setShowHeaderGiftMoney(false));
  };

  return (
    <div className="bg-[var(--colour-34)] min-h-[var(--app-height)]">
      {isShowLoading ? (
        <Loading className="w-full h-[var(--app-height)]"></Loading>
      ) : (
        <>
          <HeaderBack
            titleClassName="w-[4.58rem] line-clamp-2 text-center leading-[.34rem]"
            title={content?.Title || ''}
            onClick={() => {
              if (fromIndex == '/') {
                navigate(toLocalePath(`/`));
              } else {
                navigate(
                  toLocalePath(`/promotion/events?current=${fromIndex}&scrollTop=${scrollPosition}`),
                  { state: { from: null } },
                  false,
                );
              }
            }}
          ></HeaderBack>

          {list && list.length > 0 && content ? (
            <>
              <div
                className="w-full overflow-y-auto hidden-scroll-y"
                style={{ height: `${content?.ButtonText && content?.jump_mode != 1 ? h - 1.12 : h}rem` }}
                ref={ref}
              >
                <div className="rounded-[.16rem] bg-[var(--colour-35)] box-shadow-events p-[.22rem_.08rem] my-[.2rem] select-none">
                  {content.detail_time_switch == 1 ? (
                    <div className="text-[.24rem] text-[var(--colour-38)] mb-[.08rem]">
                      <div className="font-[600]">{t('promotion.eventsTime')}</div>
                      <div className="font-[400]">
                        {content.start_timestamp
                          ? moment(content.start_timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')
                          : ''}
                        <span className="mx-[.1rem]">-</span>
                        {content.end_timestamp
                          ? moment(content.end_timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')
                          : ''}
                      </div>
                    </div>
                  ) : null}

                  <div className="w-full rounded-normal overflow-hidden mb-[.2rem]">
                    <img src={cmsImgUrl(content.Img)} alt="" />
                  </div>

                  <div
                    className="text-[var(--colour-48)] text-[.24rem] font-[500] rich-text"
                    dangerouslySetInnerHTML={{ __html: content.Content || '' }}
                  ></div>
                </div>
              </div>

              {content?.ButtonText && content?.jump_mode != 1 ? (
                <div className="bottom-menu h-[1.12rem] w-full flex items-center justify-center bg-[var(--colour-4)] box-shadow-event-3">
                  <div
                    className="w-[7.1rem] h-[.7rem] rounded-[.12rem] bg-[var(--colour-1)] flex items-center justify-center text-[.24rem] font-[500] text-[var(--colour-2)] active:scale-[.95] transition-all select-none cursor-pointer"
                    onClick={() => {
                      handleHideHeaderGiftMoney();
                      handleCmsLink(content.open_mode, content.new_window, content.link);
                    }}
                  >
                    {content.ButtonText}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <NoData className="w-full h-full absolute abs-center"></NoData>
          )}
        </>
      )}
    </div>
  );
};

export default EventsDetails;
