import HeaderSidebarSwitch from '../../headerSidebarSwitch';
import HeaderRight from '../../headerRight';
import cn from 'classnames';
import LogoBox from '../../../logoBox';

const NTemplate001 = () => {
  return (
    <>
      <div className={cn('flex flex-col items-center header-box transition-[top]')}>
        <div
          className={`w-full flex flex-row items-center justify-between relative px-[.32rem] bg-[var(--colour-4)] border-b border-solid border-[var(--colour-6)] h-[var(--header-box-height)]`}
        >
          <div className={cn('flex items-center space-x-[.16rem]')}>
            <HeaderSidebarSwitch />
            <LogoBox />
          </div>
          <HeaderRight />
        </div>
      </div>
    </>
  );
};

export default NTemplate001;
