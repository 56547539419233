import LimitClickWrapper from '../../limitClickWrapper';
import Close from '../../../assets/img/bulletinClose.png';
import Lights from '../../../assets/img/pliotwheel/lights.png';
import BonusWebp from '../../../assets/img/pliotwheel/jiesuan.webp';
import Rectangle from '../../../assets/img/pliotwheel/Rectangle.png';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { isAndroidOrPWA } from '../../../utils/deviceUtils';
import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getCenterPosition } from '../../../hooks/useMinimizeAnimation';
import { openHeaderGiftMoney, getShowMoney, isPromotionPath } from '../../../utils/helper';
import Backdrop from '../../backdrop';
import { useLocation } from 'react-router-dom';
import { toNoLocalePath } from '../../../utils/localePath';
import GSAPUtils from '../../../utils/gsapUtils';
import { setIsShowPromotionBarGiftMoneyCoin } from '../../../redux/reducers/appSlice';

const WheelBonus = ({ handleClose = () => {}, content = '', modalType = null }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const coinRef = useRef(null);
  const { pathname } = useLocation();
  const [hasExecuted, setHasExecuted] = useState(false);

  const { websiteInitConfig, activityData } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );

  const handleCloseBonus = () => {
    if (hasExecuted) return;

    if (modalType == 'wheel') {
      if (coinRef.current) {
        const data = getCenterPosition(coinRef.current, true);
        if (isPromotionPath(toNoLocalePath(pathname)) && activityData?.gift_money) {
          const giftMoneyLabel = document.querySelector('#promotion_giftMoney');

          if (giftMoneyLabel) {
            const toPoint = getCenterPosition(giftMoneyLabel, true);
            GSAPUtils.flyCoin(data, toPoint, GSAPUtils.COIN_COUNT, () => {
              dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
            });

            setTimeout(() => {
              handleClose();
            }, GSAPUtils.COIN_FLY_DURATION * 1000);
          }
        } else {
          openHeaderGiftMoney(content, [data], handleClose);
        }
      }
    } else {
      handleClose && handleClose();
    }

    setHasExecuted(true);
  };
  return (
    <Backdrop id="Bonus_outSide" onClick={handleCloseBonus}>
      <div className="w-[5.74rem] relative flex flex-col items-center">
        <LimitClickWrapper
          className="w-[.52rem] h-[.48rem] cursor-pointer absolute right-[-0.52rem] top-[-1.44rem]"
          onClick={handleCloseBonus}
        >
          <img src={Close} alt="" className="not-drag" />
        </LimitClickWrapper>

        <div className="w-[4.8rem] relative z-[10] h-[1.4rem]">
          <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[4.8rem] h-[1.18rem] bg-[-0.2rem_-0.2rem] bg-[length:7.2rem_31.04rem]"></div>

          <div className="relative text-[.6rem] font-[800] top-[-1.2rem] left-[50%] translate-x-[-50%] text-center text-[var(--colour-43)]">
            {t('guideWheel.hooray')}
          </div>
        </div>

        <div className="w-[5.74rem] h-[7rem] mt-[-1.4rem] bg_BonusWheel relative">
          <div className="w-[3.6rem] absolute z-[-1] left-[50%] translate-x-[-50%] top-[.68rem] opacity-[.3]">
            <img src={Lights} alt="" className="not-drag" />
          </div>

          <i className="absolute text-[.44rem] font-[800] left-[50%] translate-x-[-50%] top-[1.7rem] z-[10] text-[var(--colour-43)]">
            {t('guideWheel.reward')}
          </i>

          <div className="w-[6.06rem] absolute z-[5] left-[50%] translate-x-[-50%] top-[1.94rem]">
            <img src={Rectangle} alt="" className="not-drag" />
          </div>

          <div className="w-[4.44rem] absolute z-[7] left-[48%] translate-x-[-50%] top-[2.1rem]" ref={coinRef}>
            <img src={BonusWebp} alt="" className="not-drag w-full" />
          </div>

          <div className="absolute z-[6] left-[50%] translate-x-[-50%] top-[3.9rem] bg-no-repeat bg-[url(../assets/img/pliotwheel/guideSprites/MobileguideSprites.png)] w-[3.32rem] h-[1.56rem] bg-[-0.2rem_-1.78rem] bg-[length:6.04rem_9rem]"></div>

          <div className="w-[5.12rem] z-[7] absolute left-[50%] translate-x-[-50%] bottom-[.56rem] user-none">
            <div className="bg-no-repeat bg-[url(../assets/img/pliotwheel/wheelSprites/mobileSprites.png)] w-[5.12rem] h-[1.6rem] bg-[-0.2rem_-1.78rem] bg-[length:7.2rem_31.04rem]"></div>

            <div className="absolute z-[8] text-[.44rem] font-[700] min-w-[5.08rem] flex items-center justify-center left-[50%] translate-x-[-50%] bottom-[.38rem] user-none">
              <span className="text-[var(--colour-43)]">
                {websiteInitConfig?.website_info?.currency_symbol || ''}
                <span className="">{getShowMoney(content || 0)}</span>
              </span>
            </div>
          </div>
        </div>

        <LimitClickWrapper
          className="w-[3.24rem] h-[.68rem] rounded-normal flex items-center justify-center text-[var(--colour-2)] bg-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none font-[600] text-[.28rem] mt-[-0.32rem]"
          onClick={() => {
            handleCloseBonus();
            if (isAndroidOrPWA()) {
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_InAppClaimRewards);
            } else {
              AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_ClaimRewards);
            }
          }}
        >
          {t('home.confirm')}
        </LimitClickWrapper>
      </div>
    </Backdrop>
  );
};

export default WheelBonus;
