import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CopyRight from './components/copyRight';
import FooterText from './components/footerText';
import LicenseList from './components/licenseList';
import Line from './components/line';
import LinkList from './components/linkList';
import SocialList from './components/socialList';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { openUrl } from '../../../../utils/openUrl';
import { cmsImgUrl, isLightTheme, isLogin } from '../../../../utils/helper';
import { useTranslation } from 'react-i18next';
import LinkTitle from './components/linkTitle';
import LinkItem from './components/linkItem';
import useActivityStatus from '../../../../hooks/useActivityStatus';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import Pay1Img from '../../../../assets/img/footer/pay1.png';
import Pay2Img from '../../../../assets/img/footer/pay2.png';
import Pay3Img from '../../../../assets/img/footer/pay3.png';
import Pay3_2Img from '../../../../assets/img/footer/pay3_2.png';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../../../redux/reducers/appSlice';
import useFooterData from '../../../../hooks/useFooterData';

const DTemplate001 = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  const { footerData } = useSelector(
    (state) => ({
      footerData: get(state, 'app.websiteInitData.footer'),
    }),
    shallowEqual,
  );

  const { followList, licenseList, text } = useFooterData(footerData);

  const { handleEntranceOnClick, isShowEntrance } = useActivityStatus();
  const { handleClickManMadeInPage, faqData, handleClickFaq } = useHandleLogic();

  const linkList = [
    {
      title: <LinkTitle title={t('footer.cassino')} />,
      list: [
        ...(isShowEntrance ? [<LinkItem key={0} name={t('footer.event')} onClick={handleEntranceOnClick} />] : []),
        <LinkItem key={1} name={t('footer.missao')} />,
        <LinkItem
          key={2}
          name={t('footer.rakeback')}
          onClick={() => {
            if (isLogin()) {
              navigate(toLocalePath('/promotion/rakeback'));
            } else {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.LoginBox,
                }),
              );
            }
          }}
        />,
        <LinkItem
          key={3}
          name={t('footer.pendente')}
          onClick={() => {
            if (isLogin()) {
              navigate(toLocalePath('/promotion/pending'));
            } else {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.LoginBox,
                }),
              );
            }
          }}
        />,
        <LinkItem
          key={4}
          name={t('footer.vip')}
          onClick={() => {
            if (!isLogin()) {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.LoginBox,
                }),
              );
            } else {
              navigate(toLocalePath(`/promotion/vip`));
            }
          }}
        />,
        <LinkItem
          key={5}
          name={t('footer.referral')}
          onClick={() => {
            navigate(toLocalePath(`/referral`));
          }}
        />,
      ],
    },
    {
      title: <LinkTitle title={t('footer.jogos')} />,
      list: [
        <LinkItem key={0} name={t('footer.slots')} />,
        <LinkItem key={1} name={t('footer.pescaria')} />,
        <LinkItem key={2} name={t('footer.blockchain')} />,
        <LinkItem key={3} name={t('footer.aovivo')} />,
        <LinkItem key={4} name={t('footer.cartas')} />,
        <LinkItem key={5} name={t('footer.esporte')} />,
      ],
    },
    {
      title: <LinkTitle title={t('footer.support')} />,
      list: [
        <LinkItem key={0} name={t('footer.support_online')} onClick={handleClickManMadeInPage} />,
        <LinkItem key={1} name={t('footer.bonus_de')} />,
        ...(faqData ? [<LinkItem key={2} name={t('footer.faq')} onClick={handleClickFaq} />] : []),
      ],
    },
  ];

  return (
    <div className={`w-full max-w-[10rem] mx-auto px-[.24rem] pt-[.4rem] footer_dom bg-[var(--colour-17)] pb-[.32rem]`}>
      <div>
        <LinkList list={linkList} />
      </div>

      <Line className="mt-[.4rem]" />

      {licenseList.length > 0 && (
        <div className="mt-[.34rem]">
          <LicenseList
            list={licenseList.map((item, index) => (
              <span
                key={index}
                className={cn({
                  'cursor-pointer': item.url != '',
                })}
                onClick={
                  item?.url
                    ? () => {
                        openUrl(item.url);
                      }
                    : null
                }
              >
                <img src={cmsImgUrl(item.img)} className="h-[.7rem]" alt="" />
              </span>
            ))}
            title={
              <div className="text-[var(--colour-48)] text-[.24rem] font-[500] leading-[150%]">
                {t('home.licenses')}
              </div>
            }
          />
        </div>
      )}

      {followList.length > 0 && (
        <div className="mt-[.36rem]">
          <SocialList
            list={followList.map((item, index) => (
              <>
                <div key={index} className={`cursor-pointer`}>
                  {item.url === '' ? (
                    <img className="h-[.7rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                  ) : (
                    <a
                      onClick={() => {
                        openUrl(item.url, '_blank');
                      }}
                    >
                      <img className="h-[.7rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                    </a>
                  )}
                </div>
              </>
            ))}
            title={
              <div className="text-[var(--colour-48)] text-[.24rem] font-[500] leading-[150%]">
                {t('home.follow_us')}
              </div>
            }
          />
        </div>
      )}

      <div className="mt-[.32rem]">
        <FooterText text={text} />
      </div>

      <Line className="mt-[.44rem]" />
      <div className="flex items-cente justify-center space-x-[.16rem] mt-[.18rem]">
        <div className="w-[1.04rem] h-[.44rem] flex items-center justify-center">
          <img className="h-[.44rem] not-drag" src={Pay2Img} />
        </div>
        <div className="w-[1.04rem] h-[.44rem] flex items-center justify-center">
          <img className="h-[.44rem] not-drag" src={Pay1Img} />
        </div>
        <div className="w-[1.04rem] h-[.44rem] flex items-center justify-center">
          <img className="h-[.44rem] not-drag" src={isLightTheme() ? Pay3Img : Pay3_2Img} />
        </div>
      </div>
      <div className="mt-[.52rem]">
        <CopyRight />
      </div>
    </div>
  );
};

export default DTemplate001;
