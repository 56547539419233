import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import {
  getShowMoney,
  getThemeUserPhoto,
  getVipLevelLogin,
  setVipLevelLogin,
  getPercentage,
  getVipImg,
  getTemplateId,
} from '../../../../utils/helper';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import Icon, { IconName } from '../../../../components/icon';
import useHandleLogic from '../../../../hooks/useHandleLogic';
import useRouterHelper from '../../../../hooks/useRouterHelper';
import LimitClickWrapper from '../../../../components/limitClickWrapper';
import DialogModal from '../../../../components/dialogModal';
import { useEffect, useState } from 'react';
import UserInfoDialog from '../../../../components/dialogModal/userinfoDialog';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import { toLocalePath } from '../../../../utils/localePath';
import { GetPromotionVipList } from '../../../../api';
import VipUpgrade from '../../../../components/dialogModal/vipUpgrade';
import BigNumber from 'bignumber.js';
import AccountLink from './components/accountLink';
import VipLevelPicture from '../../../../components/vipLevelPicture';
import Loading2 from '../../../../components/loading2';

const DTemplate003 = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const [visible, setVisible] = useState(false);

  const { hanldeRefurbishBalance, balanceLoad } = useHandleLogic();
  const { handleClickWallet } = useRouterHelper();
  const { userObj, currency_symbol, unReadCount } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
      unReadCount: state?.app?.unReadCount,
    }),
    shallowEqual,
  );

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setVisible(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '10px',
          },
        }}
        isOpen={visible}
      >
        <UserInfoDialog
          handleClose={() => {
            setVisible(false);
          }}
          isShowNickname={false}
        />
      </DialogModal>

      <div className="px-[.3rem] w-full pt-[.58rem] pb-[.8rem] min-h-full bg-[var(--colour-54)]">
        <div className="w-full h-[3.34rem] rounded-[.12rem] colour-87 pl-[.2rem] pt-[.26rem]">
          <div className="flex">
            <div
              className="w-[1.46rem] h-[1.46rem] mr-[.2rem] cursor-pointer active:scale-[.95] transition-all select-none"
              onClick={() => {
                setVisible(true);
              }}
            >
              <img src={getThemeUserPhoto(userObj?.icon || 1)} alt="" className="rounded-[.12rem] not-drag" />
            </div>
            <div className="flex flex-col justify-between py-[.04rem] text-[var(--colour-40)] text-[.24rem] font-[500] flex-1">
              <div className="flex items-center flex-1 pr-[.26rem]">
                ID:{userObj?.uid}
                <div
                  className="w-[.32rem] h-[.32rem] text-[var(--colour-2)] cursor-pointer ml-[.16rem]"
                  onClick={() => {
                    copy(`${userObj?.uid || ''}`);
                    toast.success(t('home.toast006'), {
                      containerId: 'global',
                    });
                  }}
                >
                  <Icon name={IconName.AccountCopy}></Icon>
                </div>
                <div className="w-full flex flex-1 items-center justify-end">
                  <div
                    className="w-[.44rem] h-[.44rem] text-[var(--colour-2)] cursor-pointer relative"
                    onClick={() => {
                      navigate(toLocalePath(`/supportmessage/2`));
                    }}
                  >
                    <Icon name={IconName.AccountEmail}></Icon>

                    {unReadCount > 0 ? (
                      <div className="absolute right-[-.08rem] top-[-0.04rem] min-w-[.24rem] min-h-[.24rem] bg-[var(--colour-25)] rounded-[50%] text-[var(--colour-2)] text-[.16rem] font-[500] flex items-center justify-center">
                        {unReadCount >= 99 ? '99+' : unReadCount}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                {t('account.account')}:{userObj?.username}
              </div>
              <div className="flex items-center text-[.36rem]">
                <span>{getShowMoney(userObj?.balance, 2, 2)}</span>
                <span className="ml-[.34rem]">{currency_symbol}</span>
                <LimitClickWrapper
                  className="ml-[.2rem] w-[.48rem] h-[.48rem] text-[var(--colour-40)] cursor-pointer"
                  onClick={hanldeRefurbishBalance}
                  style={balanceLoad ? { animation: 'frashenTurn2 0.5s linear infinite' } : {}}
                >
                  <Icon name={IconName.MineRefresh}></Icon>
                </LimitClickWrapper>
              </div>
            </div>
          </div>

          <div className="mt-[.5rem] ml-[.1rem] flex gap-x-[.18rem]">
            <div
              className="w-[3.06rem] h-[.76rem] bg-[var(--colour-93)] rounded-[.12rem] active:scale-[.95] transition-all select-none cursor-pointer flex items-center justify-center text-[var(--colour-2)] text-[.24rem] font-[600] gap-x-[.12rem]"
              onClick={() => {
                handleClickWallet(2);
              }}
            >
              <div className="w-[.56rem] h-[.56rem] text-[var(--colour-2)]">
                <Icon name={IconName.AccountDeposit}></Icon>
              </div>
              {t('account.withdraw')}
            </div>
            <div
              className="w-[3.06rem] h-[.76rem] colour-80 rounded-[.12rem] active:scale-[.95] transition-all select-none cursor-pointer flex items-center justify-center text-[var(--colour-2)] text-[.24rem] font-[600] gap-x-[.12rem]"
              onClick={() => {
                handleClickWallet();
              }}
            >
              <div className="w-[.56rem] h-[.56rem] text-[var(--colour-2)]">
                <Icon name={IconName.AccountWithdraw}></Icon>
              </div>
              {t('account.deposit')}
            </div>
          </div>
        </div>

        <Vip />

        <AccountLink />
      </div>
    </>
  );
};

const Vip = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const [myLevel, setMylevel] = useState(0);
  const [maxLevel, setMaxLevel] = useState(0);
  const [mydeposit, setMydeposit] = useState(0);
  const [myWager, setMyWager] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWager, setTotalWager] = useState(0);
  const [vipData, setVipData] = useState(null);
  const [isShowVipupgradeData, setisShowVipupgradeData] = useState(null);

  const { refetchVipData } = useSelector(
    (state) => ({
      refetchVipData: state?.app?.refetchVipData,
    }),
    shallowEqual,
  );

  const getMaxLevel = (list) => {
    const maxLevel = Number(list[list.length - 1]?.vip_level_config?.vip || 1);
    return maxLevel;
  };

  const onClick = () => {
    navigate(toLocalePath('/promotion/vip'), { state: { from: '/mine' } });
  };

  const updateVipData = () => {
    GetPromotionVipList().then((res) => {
      if (res.code === 0) {
        const data = res.data;

        setVipData(data);
        setMylevel(Number(data?.vipLevel || 0));
        setMaxLevel(getMaxLevel(data?.list || []));
        setMydeposit(Number(data?.totalDeposit || 0));
        setMyWager(Number(data?.totalWager || 0));
        setTotalDeposit(getLevelConfig(data?.vipLevel, data?.list)?.totalDeposit);
        setTotalWager(getLevelConfig(data?.vipLevel, data?.list)?.totalWager);

        const vipLevel = data.vipLevel;
        const uid = data.uid;

        if (vipLevel != getVipLevelLogin(uid)) {
          setisShowVipupgradeData(data);
          setVipLevelLogin(vipLevel, uid);
        }
      }
    });
  };

  const getLevelConfig = (myLevel, list = []) => {
    const found = list.find((item) => {
      return Number(item?.vip_level_config?.vip) == Number(myLevel);
    });

    return found
      ? {
          totalDeposit: found?.vip_level_config?.total_deposit || 0,
          totalWager: found?.vip_level_config?.total_wager || 0,
        }
      : null;
  };

  const getNumberDiffirence = (firstNumber, secondNumber) => {
    if (Number(firstNumber) >= Number(secondNumber) || firstNumber === undefined || secondNumber === undefined) {
      return 0;
    } else {
      const val = BigNumber(secondNumber).minus(firstNumber);
      return getShowMoney(val, 3);
    }
  };

  useEffect(() => {
    updateVipData();
  }, [refetchVipData]);

  return (
    <>
      {isShowVipupgradeData ? (
        <DialogModal
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              display: 'inline-block',
              textAlign: 'left',
              top: '0px',
              maxWidth: '100%',
              cursor: 'default',
              outline: '0px',
              verticalAlign: 'middle',
              zIndex: '51',
              borderRadius: '10px',
            },
          }}
          isOpen={isShowVipupgradeData != null}
        >
          <VipUpgrade
            handleClose={() => {
              setisShowVipupgradeData(null);
            }}
            data={isShowVipupgradeData}
          />
        </DialogModal>
      ) : (
        <></>
      )}

      <div
        className="w-full h-[2.7rem] colour-87 rounded-[.12rem] mt-[.28rem] pl-[.4rem] pt-[.06rem] cursor-pointer"
        onClick={onClick}
      >
        {vipData ? (
          <>
            {' '}
            <div className="flex items-center">
              {/* <div className="w-[1.04rem] h-[1.04rem] relative">
        <img src={getVipImg(Number(vipData?.vipLevel || 0))} alt="" className="not-drag" />

        <div
          className={`absolute text-[var(--colour-41)] text-[.2rem] font-[600] top-[.49rem] ${Number(vipData?.vipLevel || 0) >= 10 ? 'right-[.02rem] ' : 'right-[.08rem] '}`}
        >
          V{Number(vipData?.vipLevel || 0)}
        </div>
      </div> */}
              <VipLevelPicture vipLevel={Number(vipData?.vipLevel || 0)} />

              <div className="pl-[.3rem] mt-[.2rem] pr-[.31rem] flex-1 flex items-center justify-between">
                <div className="text-[.2rem] text-[var(--colour-74)] font-[500] text-center">
                  <Trans
                    t={t}
                    i18nKey="account.vip_tip"
                    components={[
                      <span className="text-[var(--colour-23)]" key={0}></span>,
                      <span
                        className={getTemplateId() == 'd-template003' ? '' : 'text-[var(--colour-23)]'}
                        key={1}
                      ></span>,
                      <span
                        className={getTemplateId() == 'd-template003' ? '' : 'text-[var(--colour-23)]'}
                        key={2}
                      ></span>,
                    ]}
                    values={{
                      val: `VIP${Number(myLevel + 1) > Number(maxLevel) ? maxLevel : Number(myLevel + 1)}`,
                      val2: getNumberDiffirence(mydeposit, totalDeposit),
                      val3: getNumberDiffirence(myWager, totalWager),
                    }}
                  />
                </div>

                <div className="w-[.18rem] h-[.18rem] text-[var(--colour-7)] rotate-[180deg]">
                  <Icon name={IconName.BtnBack}></Icon>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-center w-[.98rem] min-h-[.64rem] flex flex-col items-center justify-center text-[.2rem] text-[var(--colour-74)] font-[500]">
                <div>{t('account.deposit_for_promotion')}</div>
              </div>

              <div className="ml-[.14rem] w-[5.2rem] relative h-[.3rem] rounded-[1.8rem] bg-[var(--colour-42)] overflow-hidden">
                <div
                  className="absolute h-[.3rem] left-0 top-0 rounded-[1.8rem] colour-91"
                  style={{
                    width: getPercentage(Number(mydeposit) || 0, Number(totalDeposit) || 1) + '%',
                  }}
                ></div>

                <div className="absolute abs-center text-[.18rem] text-[var(--colour-56)] font-[500]">
                  {[mydeposit, totalDeposit].every((val) => val != undefined) &&
                    `${isNaN(mydeposit) ? '0' : getShowMoney(mydeposit)}/${isNaN(totalDeposit) ? '0' : getShowMoney(totalDeposit)}`}
                </div>
              </div>
            </div>
            <div className="flex items-center mt-[.12rem]">
              <div className="text-center w-[.98rem] min-h-[.64rem] flex flex-col items-center justify-center text-[.2rem] text-[var(--colour-74)] font-[500]">
                <div>{t('account.bet_for_promotion')}</div>
              </div>

              <div className="ml-[.14rem] w-[5.2rem] relative h-[.3rem] rounded-[1.8rem] bg-[var(--colour-42)] overflow-hidden">
                <div
                  className="absolute h-[.3rem] left-0 top-0 overflow-hidden rounded-[1.8rem] colour-91"
                  style={{
                    width: getPercentage(Number(myWager) || 0, Number(totalWager) || 1) + '%',
                  }}
                ></div>

                <div className="absolute abs-center text-[.18rem] text-[var(--colour-56)] font-[500]">
                  {[myWager, totalWager].every((val) => val != undefined) &&
                    `${isNaN(myWager) ? '0' : getShowMoney(myWager)}/${isNaN(totalWager) ? '0' : getShowMoney(totalWager)}`}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading2 className="w-full h-full"></Loading2>
        )}
      </div>
    </>
  );
};

export default DTemplate003;
