import { useEffect } from 'react';

/**
 * 控制页面滚动的自定义钩子
 *
 * 此钩子用于在特定条件下隐藏页面的滚动功能，通常用于展示模态框或弹出层时
 * 它通过向body元素添加或移除一个特定的CSS类来控制页面的滚动行为
 *
 * @param {boolean} shouldHideScroll - 控制是否隐藏滚动的标志
 * 如果为true，则添加'no-scroll'类以禁止滚动；如果为false，则移除该类以允许滚动
 */

export function useBodyOverflow(shouldHideScroll) {
  useEffect(() => {
    if (shouldHideScroll) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [shouldHideScroll]);
}
