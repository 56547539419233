import { useEffect, useState } from 'react';
import { getConfigTimeZoneTimestamp, getShowMoney, isLogin } from '../../../../../utils/helper';
import BonusAmount from './com/bonusAmount';
import NoData from '../../../../../components/noData';
import PendingItem from './com/pendingItem';
import PendingCountdown from './com/pendingCountdown';
import ReceiveButton from './com/receiveButton';
import Loading3 from '../../../../../components/loading3';
import { GetPromotionPendingList, PromotionActivitySettle, PromotionPendingClaim } from '../../../../../api';
import useActivityJump from '../../../../../hooks/useActivityJump';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setIsShowPromotionBarGiftMoneyCoin,
  setNeedUpdateBalance,
  setPromotionPendingUpdateTimestamp,
} from '../../../../../redux/reducers/appSlice';
import { toast } from 'react-toastify';
import usePromotionClaimedCount from '../../../../../hooks/usePromotionClaimedCount';
import colorTypes from './helper/colorTypes';
import DownloadManager from '../../../../../manager/downloadManager';
import GSAPUtils from '../../../../../utils/gsapUtils';
import { getCenterPosition } from '../../../../../hooks/useMinimizeAnimation';
import { useLocation } from 'react-router-dom';
import { toNoLocalePath } from '../../../../../utils/localePath';

const fakeList = Array(10)
  .fill()
  .map(() => {
    return {
      name: 'Daily Betting Reward',
      money: 200,
      date: '01/07/24',
      source: (
        <span
          className="text-[var(--colour-1)] text-[.2rem] font-[500] cursor-pointer"
          onClick={() => {
            alert('click');
          }}
        >
          Activity award
        </span>
      ),
      countDown: (
        <PendingCountdown
          date={Date.now() * 1.0001}
          onComplete={() => {
            // alert('complete');
          }}
        />
      ),

      button: <ReceiveButton type={1} onClick={() => alert('receive')} />,
    };
  });

const NTemplate001 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { currency_symbol, promotionPendingUpdateTimestamp, gift_money_menu_show_switch } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      promotionPendingUpdateTimestamp: state?.app?.promotionPendingUpdateTimestamp,
      gift_money_menu_show_switch: state?.app?.activityStatus?.gift_money_menu_show_switch,
    }),
    shallowEqual,
  );

  const { InitPromotionClaimedCount } = usePromotionClaimedCount();

  const {
    getActivityName,
    getJumpHandler,
    activityKeyList,
    getActivitySource,
    claimableActivityKeyList,
    jumpActivityKeyList,
  } = useActivityJump();

  const [bonus, setBonus] = useState(0);
  const [list, setList] = useState([]);
  const [sortList, setSortList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [claimingIdList, setClaimingIdList] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      updateList();
    }

    if (isLogin()) {
      PromotionActivitySettle();
    }
  }, []);

  useEffect(() => {
    if (promotionPendingUpdateTimestamp > 0) {
      updateList();
      dispatch(setPromotionPendingUpdateTimestamp(0));
    }
  }, [promotionPendingUpdateTimestamp]);

  useEffect(() => {
    const greenList = list.filter((item) => item.colorType == colorTypes.green);
    const blueList = list.filter((item) => item.colorType == colorTypes.blue);
    const greyList = list.filter((item) => item.colorType == colorTypes.grey);
    setSortList([...greenList, ...blueList, ...greyList]);
  }, [list]);

  const updateList = (isShowLoading = true) => {
    const request = (isShowLoading) => {
      if (isShowLoading) {
        setIsLoading(true);
      }

      // setTimeout(() => {
      //   setList(fakeList);
      //   setIsLoading(false);
      // }, 1000);

      GetPromotionPendingList()
        .then((res) => {
          if (res.code == 0) {
            const data = res.data;

            // const _list = data?.list.concat({
            //   ...data?.list[data?.list.length - 1],
            //   behaviour: activityKeyList.rank_reward,
            // });
            const _list = (data?.list || []).map((item) => {
              return {
                ...item,
                colorType: getColorType(item.behaviour, item.currency, item.claim_start_time * 1000),
              };
            });
            setList(_list);

            const totalBonus = _list.reduce((total, item) => {
              return total + item.amount;
            }, 0);
            setBonus(totalBonus);
          }
        })
        .finally(() => {
          if (isShowLoading) {
            setIsLoading(false);
          }
        });
    };

    request(isShowLoading);
  };

  const getIsStarted = (startTimeStamp) => {
    return Date.now() >= startTimeStamp;
  };

  const getColorType = (activityKey, currency, startTimeStamp) => {
    // 绿色 直接领取, 蓝色 跳转领取, 灰色 不可点击

    const isStarted = getIsStarted(startTimeStamp);

    if (claimableActivityKeyList.includes(activityKey)) {
      if (!isStarted) {
        return colorTypes.grey;
      } else {
        if (currency == 'balance') {
          return colorTypes.green;
        } else if (currency == 'gift_money') {
          return colorTypes.green;
        } else {
          return colorTypes.grey;
        }
      }
    } else if (jumpActivityKeyList.includes(activityKey)) {
      if (
        [
          activityKeyList.weekly_reward,
          activityKeyList.monthly_reward,
          activityKeyList.rakeback,
          activityKeyList.mission_binding_cpf,
          activityKeyList.mission_binding_phone,
          activityKeyList.mission_binding_mail,
        ].includes(activityKey)
      ) {
        if (!isStarted) {
          return colorTypes.grey;
        } else {
          return colorTypes.blue;
        }
      } else {
        return colorTypes.blue;
      }
    } else {
      return colorTypes.grey;
    }
  };

  const claimPendingItem = (claimId, element = null) => {
    // console.log('claimPendingItem', claimId, element, currency, amount);
    // currency = 'gift_money'

    if (!claimingIdList.includes(claimId)) {
      const _list = [...claimingIdList, claimId];
      setClaimingIdList(_list);
      console.log('claimPendingItem _list:', _list);

      PromotionPendingClaim({
        id: claimId,
      })
        .then((res) => {
          if (res.code == 0) {
            // if (true) {
            const data = res.data;
            const currency = data.currency;
            const amount = data.amount;

            const commonCallback = () => {
              updateList(false);
              dispatch(setNeedUpdateBalance({ need: true }));
              InitPromotionClaimedCount();
            };

            const balanceCallback = () => {
              toast.success(
                t('promotion.claim_ok_tip', {
                  val: currency_symbol,
                  val1: getShowMoney(amount),
                }),
                {
                  containerId: 'global',
                },
              );
            };

            const giftMoneyCallback = () => {
              const giftMoneyLabel = document.querySelector('#promotion_giftMoney');

              if (giftMoneyLabel && element) {
                dispatch(setIsShowPromotionBarGiftMoneyCoin(false));
                GSAPUtils.flyCoin(
                  getCenterPosition(element, true),
                  getCenterPosition(giftMoneyLabel, true),
                  GSAPUtils.COIN_COUNT,
                  () => {
                    if (toNoLocalePath(pathname) != '/promotion/giftmoney') {
                      dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                    }
                  },
                );
              }
            };

            commonCallback();
            if (currency == 'balance') {
              balanceCallback();
            } else if (currency == 'gift_money') {
              if (gift_money_menu_show_switch == '1') {
                giftMoneyCallback();
              } else {
                balanceCallback();
              }
            }
          } else if (res.code == 1008127) {
            if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.BottomDownloadBoxPrompt,
                  downloaddata: {
                    visible: true,
                    isForce: true,
                    downloadText: t('guideWheel.downloadtips'),
                  },
                }),
              );
            } else {
              toast.error(res.msg, {
                containerId: 'global',
              });
            }
          } else {
            toast.error(res.msg, {
              containerId: 'global',
            });
          }
        })
        .finally(() => {
          setClaimingIdList(claimingIdList.filter((item) => item != claimId));
        });
    }
  };

  const handleClickReceive = (colorType, claimId, behaviour, isExpired, element) => {
    // 绿色 直接领取, 蓝色 跳转领取, 灰色 不可点击
    // currency = 'gift_money'
    // console.log('handleClickReceive', colorType, element, currency, amount);

    if (colorType == colorTypes.green) {
      // if (true) {
      claimPendingItem(claimId, element);
    } else if (colorType == colorTypes.blue) {
      getJumpHandler(behaviour, isExpired)();
    }
  };

  const getIsExpired = (expirationTime) => {
    // expiration_time: 活动过期时间戳（Source 跳转）0:无过期时间 -1:活动已结束（点击提示“活动已结束”） 1730688408:活动过期时间
    if (expirationTime == 0) {
      return false;
    } else if (expirationTime == -1) {
      return true;
    } else {
      const endTimeStamp = expirationTime * 1000;
      return Date.now() > endTimeStamp;
    }
  };

  const handleClickSource = (behaviour, isExpired) => {
    console.log('handleClickSource', behaviour, isExpired);
    getJumpHandler(behaviour, isExpired)();
  };

  return (
    <div className="bg-[var(--colour-68)] h-full px-[.24rem] flex flex-col">
      <div className="shrink-0 flex justify-end p-[.32rem_0_.28rem]">
        {isLoading ? null : <BonusAmount amount={getShowMoney(bonus)} />}
      </div>
      <div className="h-[0] grow shrink-0 overflow-y-auto scrollbar-none">
        {isLoading ? (
          // {false ? (
          <Loading3 className1="w-full h-full" />
        ) : sortList.length == 0 ? (
          // ) : false ? (
          <NoData className="h-full" />
        ) : (
          <>
            <div className="space-y-[.2rem]">
              {sortList.map((item, index) => (
                // {fakeList.map((item, index) => (
                <PendingItem
                  key={index}
                  name={`${getActivityName(item.behaviour)}${item.currency == 'gift_money' ? `(${t('promotion.giftmoney')})` : ''}`}
                  money={getShowMoney(item.amount)}
                  date={getConfigTimeZoneTimestamp(item.created_at * 1000).format('YYYY-MM-DD')}
                  source={
                    <span
                      className="text-[var(--colour-1)] text-[.2rem] font-[500] cursor-pointer"
                      onClick={() => {
                        // handleClickSource(activityKeyList.activity_promotional_achievements, false,);
                        // handleClickSource(activityKeyList.activity_novice, false,);
                        handleClickSource(item.behaviour, getIsExpired(item.expiration_time));
                      }}
                    >
                      {getActivitySource(item.behaviour)}
                    </span>
                  }
                  isShowCountDown={item.behaviour !== activityKeyList.rank_reward}
                  countDown={
                    <PendingCountdown
                      date={
                        getIsStarted(item.claim_start_time * 1000)
                          ? item.claim_end_time * 1000
                          : item.claim_start_time * 1000
                      }
                      onComplete={() => updateList(false)}
                      isHideCanceded={item.colorType == colorTypes.grey}
                    />
                  }
                  button={
                    <ReceiveButton
                      name={t('promotion.to_receive')}
                      colorType={item.colorType}
                      onClick={(e) => {
                        handleClickReceive(
                          item.colorType,
                          item.id,
                          item.behaviour,
                          getIsExpired(item.expiration_time),
                          e?.currentTarget,
                        );
                      }}
                    />
                  }
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NTemplate001;
