import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import HeaderBack from '../../../../../components/headerBack';
import useGoBack from '../../../../../hooks/useGoBack';
import TippyPopBox, { Placement } from '../../../../../components/tippyPopBox';
import { useState, useRef, useEffect } from 'react';
import PopChoose, { PopChooseItem } from '../../../../../components/popChoose';
import DropDownInput from '../../../../../components/dropDownInput';
import Icon, { IconName } from '../../../../../components/icon';
import useDivSize from '../../../../../hooks/useDivSize';
import moment from 'moment';
import { getShowMoney, isLogin, getWeekRange, getConfigTimeZoneTimestamp } from '../../../../../utils/helper';
import { GetclaimHistory } from '../../../../../api';
import { List } from 'react-vant';
import Loading3 from '../../../../../components/loading3';
import NoData from '../../../../../components/noData';
import useWindowHeight from '../../../../../hooks/useWindowHeight';

const NTemplate001 = () => {
  const { h } = useWindowHeight(0);
  const [finished, setFinished] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();
  const goBack = useGoBack();
  const divRef = useRef(null);
  const [isonloadList, setisOnloadList] = useState(false);
  const [totalCommission, setTotalCommission] = useState(0);
  const { width } = useDivSize(divRef);
  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    page_size: 10,
    start_date: moment().startOf('day'),
    end_date: moment().startOf('day'),
    isClick: true,
  });

  const scrollContainerRef = useRef(null);

  const handleScrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  const list = [
    {
      name: t('refer.all'),
      key: 0,
      onClick: () => {
        setisOnloadList(false);
        handleScrollToTop();
        setCurrentDate(0);
        setFinished(false);
        setParams({
          ...params,
          page: 1,
          start_date: 0,
          end_date: 0,
          isClick: true,
        });
      },
    },
    {
      name: t('refer.today'),
      key: 1,
      onClick: () => {
        setisOnloadList(false);
        handleScrollToTop();
        setCurrentDate(1);
        setFinished(false);
        setParams({
          ...params,
          page: 1,
          start_date: moment().startOf('day'),
          end_date: moment().startOf('day'),
          isClick: true,
        });
      },
    },
    {
      name: t('refer.yesterday'),
      key: 2,
      onClick: () => {
        setisOnloadList(false);
        handleScrollToTop();
        setCurrentDate(2);
        setFinished(false);
        setParams({
          ...params,
          page: 1,
          start_date: moment().subtract(1, 'days').startOf('day'),
          end_date: moment().subtract(1, 'days').startOf('day'),
          isClick: true,
        });
      },
    },
    {
      name: t('refer.thisweek'),
      key: 3,
      onClick: () => {
        setisOnloadList(false);
        handleScrollToTop();
        const { startDate, endDate } = getWeekRange(false, true);
        setCurrentDate(3);
        setFinished(false);
        setParams({
          ...params,
          page: 1,
          start_date: startDate,
          end_date: endDate,
          isClick: true,
        });
      },
    },
    {
      name: t('refer.lastweek'),
      key: 4,
      onClick: () => {
        setisOnloadList(false);
        handleScrollToTop();
        const { startDate, endDate } = getWeekRange(true, true);
        setCurrentDate(4);
        setFinished(false);
        setParams({
          ...params,
          page: 1,
          start_date: startDate,
          end_date: endDate,
          isClick: true,
        });
      },
    },
    {
      name: t('refer.thismonth'),
      key: 5,
      onClick: () => {
        setisOnloadList(false);
        handleScrollToTop();
        setCurrentDate(5);
        setFinished(false);
        setParams({
          ...params,
          page: 1,
          start_date: moment().startOf('month').startOf('day'),
          end_date: moment().endOf('month').startOf('day'),
          isClick: true,
        });
      },
    },
  ];

  const [data, setData] = useState([]);

  const [currentDate, setCurrentDate] = useState(list[1].key);

  const getHistorydata = async () => {
    setisLoading(true);
    try {
      let newParams;
      delete params.isClick;
      if (params.start_date == 0 && params.end_date == 0) {
        newParams = {
          ...params,
          start_date: 0,
          end_date: 0,
        };
      } else {
        newParams = {
          ...params,
          start_date: Math.floor(new Date(params.start_date).getTime() / 1000),
          end_date: Math.floor(new Date(params.end_date).getTime() / 1000),
        };
      }

      const res = await GetclaimHistory(newParams);
      if (res.code == 0) {
        setData(res.data.list);
        setTotalCommission(res.data.total_commission);

        if (res.data.list.length < params.page_size) {
          setFinished(true);
          setisOnloadList(false);
        } else {
          setisOnloadList(true);
          setFinished(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (params.isClick && isLogin()) {
      getHistorydata();
    }
  }, [params]);

  const onLoad = async () => {
    if (!isonloadList) return;
    setParams({ ...params, page: params.page + 1, isClick: false });
    const currentPage = params.page + 1;
    delete params.isClick;
    let newParams;
    if (params.start_date == 0 && params.end_date == 0) {
      newParams = {
        ...params,
        page: currentPage,
        start_date: 0,
        end_date: 0,
      };
    } else {
      newParams = {
        ...params,
        page: currentPage,
        start_date: Math.floor(new Date(params.start_date).getTime() / 1000),
        end_date: Math.floor(new Date(params.end_date).getTime() / 1000),
      };
    }

    const res = await GetclaimHistory(newParams);
    if (res.code == 0) {
      setTotalCommission(res.data.total_commission);
      if (res.data.list.length < params.page_size) {
        setFinished(true);
      } else {
        setData([...data, ...res.data.list]);
      }
    }
  };

  return (
    <div className="flex flex-col bg-[var(--colour-68)] w-full" style={{ height: `${h}rem` }}>
      <HeaderBack
        title={t('referral.history')}
        onClick={() => {
          goBack();
        }}
      />

      <div className="my-[.2rem] px-[.2rem] flex items-center justify-between">
        <div>
          <TippyPopBox
            visible={visible}
            offset={[0, 4]}
            onClickOutside={() => setVisible(false)}
            placement={Placement.bottomEnd}
            zIndex={18}
            popChildren={
              <PopChoose
                style={{ width: `${width}px` }}
                className="bg-[var(--colour-35)] border border-[var(--colour-6)] py-[.2rem] max-h-[6rem] overflow-y-auto scrollbar-none"
                children={
                  list &&
                  list.map((item, index) => (
                    <PopChooseItem
                      className="flex items-center px-[.2rem] py-[.1rem]"
                      key={index}
                      data={item}
                      choose={item.key == currentDate}
                      chooseClassName="bg-none !text-[var(--colour-1)]"
                      widthNumber={width}
                      onClick={() => {
                        if (visible) {
                          item.onClick();
                          setVisible(false);
                        }
                      }}
                    >
                      <span className={`text-[.2rem] font-[400] wrap-break-word`}>{item.name}</span>
                    </PopChooseItem>
                  ))
                }
              ></PopChoose>
            }
          >
            <div>
              <DropDownInput
                ref={divRef}
                iconCanRotate={false}
                className="h-[.5rem] min-w-[1.6rem] text-[.2rem] font-[400] w-[auto] rounded-normal relative px-[.2rem] bg-[var(--colour-35)] border border-[var(--colour-6)]"
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                <div className="text-[var(--colour-44)]">{list.find((item) => item.key == currentDate)?.name}</div>
                <span
                  className={cn('w-[.18rem] h-[.18rem] block text-[var(--colour-11)] transition', {
                    'rotate-[-90deg]': visible,
                    'rotate-[90deg]': !visible,
                  })}
                >
                  <Icon name={IconName.RightArrow3} />
                </span>
              </DropDownInput>
            </div>
          </TippyPopBox>
        </div>

        <div className="flex items-center space-x-[.1rem] text-[.2rem] font-[500]">
          <div className="text-[var(--colour-44)]">{t('refer.commissionReceived')}</div>
          <div className="text-[var(--colour-26)]">{getShowMoney(totalCommission)}</div>
        </div>
      </div>

      <div className="w-full flexAuto overflow-y-auto hidden-scroll-y" ref={scrollContainerRef}>
        {isLoading ? (
          <Loading3 className1="w-full h-full"></Loading3>
        ) : (
          <>
            {data && data.length > 0 ? (
              <List finished={finished} onLoad={onLoad} offset={0} loadingText={<Loading3 className1="w-full" />}>
                {data.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={cn('w-full h-[1.44rem] pl-[.2rem] pt-[.2rem] rounded-[.12rem]', {
                        'bg-[var(--colour-35)]': index % 2 == 0,
                      })}
                    >
                      <div className="flex space-x-[1.92rem] text-[.24rem] font-[500]">
                        <div className="max-w-[1.88rem] flex space-x-[.1rem] text-[var(--colour-41)]">
                          {t('refer.members')} <span>{item.members}</span>
                        </div>
                        <div className="flex font-[500] items-start">
                          <div className="flex items-center space-x-[.1rem] ">
                            <div className="text-[var(--colour-41)] text-[.24rem]">{t('refer.quantia')}</div>
                            <div className="text-[var(--colour-26)] text-[.2rem]">
                              {getShowMoney(item.amount, 2, 2)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-[.2rem] font-[500] mt-[.2rem] text-[var(--colour-41)]">
                        {getConfigTimeZoneTimestamp(item.claimed_time * 1000).format('DD/MM/YYYY HH:mm:ss')}
                      </div>
                    </div>
                  );
                })}
              </List>
            ) : (
              <>
                <NoData className="w-full h-full"></NoData>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NTemplate001;
