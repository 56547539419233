import { useEffect, useState } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';

import Close from '../close';
import Input from '../input';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';
import { TextPlaces, setInputNum, checkEmail, UserCache, isControllableSite } from '../../utils/helper';

import { toast } from 'react-toastify';

import Icon, { IconName } from '../icon';
import { SendCodeByMobileReq, FindPasswdByMobileReq, SendCodeByEmailReq, FindPasswdByEmailReq } from '../../api';
import { CaptchaInitReq, SmsSend } from '../../api/index-auth';
import handleInitGeetest4 from '../../hooks/useGeetestInit';

import InputBySendCode from '../inputBySendCode3';
import TippyPopBox, { Placement } from '../tippyPopBox';
import DropDownInput from '../dropDownInput';
import { get } from 'lodash';

import { setLoginInfo, setUserObj } from '../../redux/reducers/userSlice';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../redux/reducers/appSlice';
import InputByPhoneOrEmail, { useCaseList } from './inputByPhoneOrEmail';
import PopConfirm from '../popConfirm';
import cn from 'classnames';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem]  font-[600] text-[var(--colour-48)]">{title}</div>
        <div className="bg-[var(--colour-45)] flex justify-center items-center rounded-normal w-[.72rem] h-[.72rem]">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full bg-[var(--colour-6)] h-[1px] mt-[.28rem]"></div>
    </>
  );
};

const ForgotPassword = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [hasBeenSent, setHasBeenSent] = useState(false);

  const { osType, websiteInitConfig } = useSelector(
    (state) => ({
      osType: get(state, 'user.osType', ''),
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  const [inputType, setInputType] = useState(UserCache.LoginMethodValue.Phone); //email || phone
  const [chooseCountryCode, setChooseCountryCode] = useState(63);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);

  const [codeLoading, setCodeLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState('');

  const handleChooseCode = (item) => {
    setShowCountryCodeList(false);
    setChooseCountryCode(String(item.code).replace('+', ''));
  };
  const [sendCodeformData, setSendCodeformData] = useStateIfMounted({
    mobile: '',
    captcha: '', // 接口返回的验证码
    cryCode: '', // 用户输入的验证码
  });

  const [formData, setFormData] = useStateIfMounted({
    mobile: '',
    password: '',
    code: '',
    osType,
  });

  const [requesting, setRequesting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [captchaInitConfig, setCaptchaInitConfig] = useState(null);

  const updateCaptcha = () => {
    setCaptchaImg('');
    CaptchaInitReq().then((res) => {
      if (res.code == 0) {
        setSendCodeformData({ ...sendCodeformData, captcha: res.result.captcha, cryCode: '' });
        setCaptchaImg(res.result.data);
        setCaptchaInitConfig(res.result);
      }
    });
  };

  useEffect(() => {
    updateCaptcha();
  }, []);

  const handlePasswordChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/[^0-9a-zA-Z]/g, '').slice(0, 24);
    setFormData({ ...formData, password: v2 });
  };

  const onChangeCryCode = (ev) => {
    setSendCodeformData({ ...sendCodeformData, cryCode: ev.target.value });
  };

  const handleSetInputType = (t) => {
    setInputType(t);
  };

  const sendCodeRequestType = async (params) => {
    if (captchaInitConfig?.change_password_validation_mode == 'geetest') {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        captchaInitConfig?.change_password_risk_type,
        setCodeLoading,
      );

      return SmsSend({
        ...params,
        mode: 'change_password',
        mobile: `${websiteInitConfig.website_info.country_code || ''}${params.mobile}`,
        ...captchaObj,
      });
    } else {
      return SmsSend({
        ...params,
        mode: 'change_password',
        mobile: `${websiteInitConfig.website_info.country_code || ''}${params.mobile}`,
      });
    }
  };

  const handleSendEmailOrPhoneCode = (params) => {
    if (inputType == 'phone') {
      return SendCodeByMobileReq({
        ...params,
        mobile: `${websiteInitConfig.website_info.country_code || ''}${params.mobile}`,
      });
    } else {
      return SendCodeByEmailReq({
        ...params,
        email: params.mobile,
      });
    }
  };

  //发送消息
  const handleSendCode = async () => {
    if (inputType == 'phone') {
      if (!formData.mobile) {
        return toast.info(t('1008015'), {
          containerId: 'global',
        });
      }
    } else if (inputType == 'email') {
      if (!checkEmail(formData.mobile)) {
        return toast.info(t('1008013'), {
          containerId: 'global',
        });
      }
    }

    if (captchaInitConfig?.change_password_validation_mode == 'image') {
      if (sendCodeformData.cryCode === '') {
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    if (!codeLoading) {
      setCodeLoading(true);

      try {
        sendCodeRequestType({
          ...sendCodeformData,
        }).then((res) => {
          if (res.code == 0) {
            setHasBeenSent(true);
            toast.success(t('home.toast033'), {
              containerId: 'global',
            });
            if (inputType == 'email') {
              setShowConfirm(true);
            }
          } else {
            updateCaptcha();
            toast.error(res.msg, {
              containerId: 'global',
            });
            setHasBeenSent(false);
          }

          setCodeLoading(false);
        });
      } catch (err) {
        return setCodeLoading(false);
      }
    }
  };

  const handleFindPwdRequest = (params) => {
    if (inputType == 'phone') {
      return FindPasswdByMobileReq({
        ...params,
        mobile: `${websiteInitConfig.website_info.country_code || ''}${params.mobile}`,
      });
    } else {
      return FindPasswdByEmailReq({
        ...params,
        email: params.mobile,
      });
    }
  };

  const handleSubmit = async () => {
    // if (formData.username.length < 6) {
    //   return toast.info(t('1008010'));
    // }

    // const emailOk = /\S+@\S+\.\S+/.test(formData.email);

    if (inputType == 'phone') {
      if (!formData.mobile) {
        return toast.info(t('1008015'), {
          containerId: 'global',
        });
      }
    } else if (inputType == 'email') {
      if (!checkEmail(formData.mobile)) {
        return toast.info(t('1008013'), {
          containerId: 'global',
        });
      }
    }

    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }
    if (!formData.code) {
      return toast.info(t('login-signup.vericication_code'), {
        containerId: 'global',
      });
    }

    setRequesting(true);
    handleFindPwdRequest({ ...formData })
      .then((res) => {
        if (res.code == 0) {
          toast.success(t('home.toast014'), {
            containerId: 'global',
          });
          handleClose();
        } else {
          // handleGetCaptcha();
          toast.error(res.msg, {
            containerId: 'global',
          });
          updateCaptcha();
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <div className="flex flex-col justify-between text-center relative w-[7rem] h-[auto] my-[.3rem] pb-[.4rem]  rounded-normal bg-[var(--colour-35)]">
        <div className="">
          <div className="px-[.32rem] ">
            <FormHeader title={t('login-signup.forgot')} handleClose={handleClose} />
          </div>
          <div className="px-[.32rem] ">
            <div className="flex flex-col  mt-[.2rem] ">
              <InputByPhoneOrEmail
                readCache={false}
                mobileWidth="w-[6.86rem]"
                inputType={inputType}
                handleSetInputType={handleSetInputType}
                allowSelection={true}
                value={formData.mobile}
                onChange={(val) => {
                  setFormData({ ...formData, mobile: val });
                  setSendCodeformData({ ...sendCodeformData, mobile: val });
                }}
                useCase={useCaseList.forgetPassword}
              />

              <div className="mt-[.2rem]">
                <Input
                  leftInner={
                    <div className="flex items-center">
                      <span className="w-[.48rem] h-[.48rem] mr-[.2rem] block text-[var(--colour-38)]">
                        <Icon name={IconName.LoginPwd2} />
                      </span>
                    </div>
                  }
                  value={formData.password}
                  onChange={handlePasswordChange}
                  type="password"
                  placeholder={t('login-signup.fill_in_the_password')}
                  innerClassName="!border-transparent !h-[.96rem]"
                  topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border-none"
                />
              </div>

              {captchaInitConfig?.change_password_validation_mode == 'geetest' ? (
                <>
                  <div className="mt-[.2rem]">
                    <InputBySendCode
                      placeholder={t('login-signup.enter_verification_code')}
                      value={formData.code}
                      leftInner={
                        <div className="flex items-center">
                          <span className="block w-[.48rem] h-[.48rem] mr-[.2rem] select-none">
                            <div className="w-full h-full text-[var(--colour-38)]">
                              <Icon name={IconName.RegisterCode2} />
                            </div>
                          </span>
                        </div>
                      }
                      onChange={(ev) => {
                        setFormData({ ...formData, code: ev.target.value });
                      }}
                      hasBeenSent={hasBeenSent}
                      handleSendCode={handleSendCode}
                      countdownDone={() => setHasBeenSent(false)}
                      requesting={codeLoading}
                      propoClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border-none"
                    />
                  </div>
                </>
              ) : captchaInitConfig?.change_password_validation_mode == 'image' ? (
                <>
                  <div className="mt-[.2rem]">
                    <Input
                      leftInner={
                        <div className="flex items-center">
                          <span className="block w-[.48rem] h-[.48rem] mr-[.2rem]">
                            <div className="w-full h-full text-[var(--colour-38)]">
                              <Icon name={IconName.RegisterCode2} />
                            </div>
                          </span>
                        </div>
                      }
                      value={sendCodeformData.cryCode}
                      onChange={onChangeCryCode}
                      type="text"
                      placeholder={t('login-signup.fill_captcha')}
                      innerClassName="!border-transparent !h-[.96rem]"
                      topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border-none"
                      rightInner={
                        <div>
                          <img
                            src={captchaImg}
                            alt="captcha"
                            onClick={updateCaptcha}
                            className="cursor-pointer w-[2rem] ml-[.08rem]"
                          />
                        </div>
                      }
                    />
                  </div>

                  <div className="mt-[.2rem]">
                    <InputBySendCode
                      placeholder={t('login-signup.enter_verification_code')}
                      value={formData.code}
                      leftInner={
                        <div className="flex items-center">
                          <span className="block w-[.48rem] h-[.48rem] mr-[.2rem] select-none">
                            <div className="w-full h-full text-[var(--colour-38)]">
                              <Icon name={IconName.RegisterCode2} />
                            </div>
                          </span>
                        </div>
                      }
                      onChange={(ev) => {
                        setFormData({ ...formData, code: ev.target.value });
                      }}
                      hasBeenSent={hasBeenSent}
                      handleSendCode={handleSendCode}
                      countdownDone={() => setHasBeenSent(false)}
                      requesting={codeLoading}
                      propoClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border-none"
                    />
                  </div>
                </>
              ) : (
                <div className="mt-[.2rem]">
                  <InputBySendCode
                    placeholder={t('login-signup.enter_verification_code')}
                    value={formData.code}
                    leftInner={
                      <div className="flex items-center">
                        <span className="block w-[.48rem] h-[.48rem] mr-[.2rem] select-none">
                          <div className="w-full h-full text-[var(--colour-38)]">
                            <Icon name={IconName.RegisterCode2} />
                          </div>
                        </span>
                      </div>
                    }
                    onChange={(ev) => {
                      setFormData({ ...formData, code: ev.target.value });
                    }}
                    hasBeenSent={hasBeenSent}
                    handleSendCode={handleSendCode}
                    countdownDone={() => setHasBeenSent(false)}
                    requesting={codeLoading}
                    propoClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border-none"
                  />
                </div>
              )}

              <LimitClickWrapper
                className="active:scale-[.95] transition-all select-none mt-[.4rem]  flex flex-row px-[.34rem] h-[.92rem]  items-center justify-center bg-[var(--colour-1)] rounded-normal text-[var(--colour-2)]"
                onClick={() => {
                  if (!requesting) {
                    handleSubmit();
                  }
                }}
              >
                <ElementWithLoading
                  isLoading={requesting}
                  normalEl={
                    <>
                      <div className="font-semibold">{t('account.submint')}</div>
                    </>
                  }
                />
              </LimitClickWrapper>
            </div>
          </div>
        </div>
      </div>

      <PopConfirm
        zIndex={100001}
        visible={showConfirm}
        handleClose={handleConfirmClose}
        handleConfirm={handleConfirmClose}
      >
        {t('account.email_bind_tips')}
      </PopConfirm>
    </>
  );
};

export default ForgotPassword;
