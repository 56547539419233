import Icon, { IconName } from '../../../icon';
import { useTranslation } from 'react-i18next';

const LayoutMobile = ({ click }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center justify-start cursor-pointer px-[.14rem] mb-[.2rem]" onClick={click}>
      <span className="block text-[var(--colour-12)] w-[.4rem] h-[.4rem] mr-[.08rem] ">
        <Icon name={IconName.DrawerMenuFaq2} />
      </span>
      <span className="text-[var(--colour-14)] text-[.2rem] leading-[.24rem] relative font-[500]">{t('faq.faq')}</span>
    </div>
  );
};

const DrawerMenuFaq = ({ onlyIcon, handleLinkClick = () => {}, handleClickFaq = () => {} }) => {
  const click = () => {
    handleClickFaq();
    handleLinkClick();
  };

  return <LayoutMobile click={click} onlyIcon={onlyIcon} />;
};

export default DrawerMenuFaq;
