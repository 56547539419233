import { useState, useRef, useEffect } from 'react';
import LimitClickWrapper from './limitClickWrapper';
import { useSelector, shallowEqual } from 'react-redux';
import { pxToRem, getRootCssVariable } from '../utils/helper';
import { isAnyMobile } from '../utils/deviceUtils';

export default function DragBtnRight({
  children,
  onClick = () => {},
  right,
  top,
  isLimitedInParentHeight = true,
  isdrag = true,
  draggableAreaID = '',
  className = '',
  className1 = '',
  position,
  isFromGame = false,
}) {
  const btnRef = useRef(null);
  const [disTop, setDisTop] = useState(0);
  const [disRight, setDisRight] = useState(0);
  const isClickRef = useRef(false); // 是否为点击
  const [isDragAcross, setIsDragAcross] = useState(false); // 是否拖拽过

  const {} = useSelector((state) => ({}), shallowEqual);

  const touchStart = (e) => {
    isClickRef.current = true;
    const touch = e.touches[0];
    const leftOffset = isLimitedInParentHeight ? btnRef.current.parentNode.getBoundingClientRect().left : 0;
    setPosition(touch.clientX - leftOffset, touch.clientY);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    isClickRef.current = false;
    const touch = e.touches[0];
    const btnBack = btnRef.current;
    let viewportW =
      getRootCssVariable('--theme-max-width', true) != '100%'
        ? getRootCssVariable('--theme-max-width')
        : window.innerWidth;

    if (isFromGame) {
      viewportW = window.innerWidth;
    }
    const clientWidth = isLimitedInParentHeight ? btnBack.parentNode.clientWidth : viewportW;
    let draggableArea = null;
    if (draggableAreaID) {
      draggableArea = document.querySelector(`#${draggableAreaID}`);
    }
    if (draggableArea && !draggableArea.contains(e.target)) return;
    const dynamicWidth = btnRef.current.offsetWidth;
    let newX = viewportW - touch.clientX + disRight - dynamicWidth;
    let newY = touch.clientY - disTop;

    if (!isDragAcross) setIsDragAcross(true);

    if (draggableArea) {
      const dreaWidth = draggableArea.offsetWidth;
      if (position == '0') {
        const maxWidth = clientWidth - (dreaWidth + dynamicWidth);
        if (newX >= maxWidth) {
          newX = maxWidth;
        }
      } else {
        if (newX <= dreaWidth) {
          newX = dreaWidth;
        }
      }
    }

    onMoveEnd(newY, newX);
  };

  const touchEnd = () => {
    if (isClickRef.current) {
      const _timer = setTimeout(() => {
        onClick();
        clearTimeout(_timer);
      }, 500);
    }
  };

  const setPosition = (x, y) => {
    const btnBack = btnRef.current;
    setDisRight(x - btnBack.offsetLeft);
    setDisTop(y - btnBack.offsetTop);
  };

  const onMoveEnd = (y, x) => {
    const btnBack = btnRef.current;
    const clientHeight = window.innerHeight;
    let finalX = x,
      finalY = y;

    if (y < 0) {
      finalY = 0;
    } else if (y > clientHeight - btnBack.offsetHeight) {
      finalY = clientHeight - btnBack.offsetHeight;
    }

    if (isFromGame) {
      if (x < 0) {
        finalX = 0;
      } else if (x > window.innerWidth - btnBack.offsetWidth) {
        finalX = window.innerWidth - btnBack.offsetWidth;
      }
    } else {
      if (x < 0) {
        finalX = 0;
      } else if (x > document.body.clientWidth - btnBack.offsetWidth) {
        finalX = document.body.clientWidth - btnBack.offsetWidth;
      }
    }

    btnBack.style.right = `${pxToRem(finalX)}rem`;
    btnBack.style.top = `${pxToRem(finalY)}rem`;
  };

  const handleOnDragStart = (event) => {
    event.preventDefault();
  };

  const mouseDown = (e) => {
    let draggableArea = null;
    if (draggableAreaID) {
      draggableArea = document.querySelector(`#${draggableAreaID}`);
    }
    if (draggableArea && !draggableArea.contains(e.target)) return;
    isClickRef.current = true;
    setPosition(e.clientX, e.clientY);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', mouseUp);
  };

  const handleMouseMove = (e) => {
    e.preventDefault && e.preventDefault();
    document.body.style.userSelect = 'none'; //设置其他元素不可选中
    if (document.querySelector('.game-iframe')) {
      document.querySelector('.game-iframe').style.zIndex = '-1'; //设置底部iframe的层级样式为-1；滑动更加流畅
    }
    isClickRef.current = false;
    let viewportW =
      getRootCssVariable('--theme-max-width', true) != '100%'
        ? getRootCssVariable('--theme-max-width')
        : window.innerWidth;

    if (isFromGame) {
      viewportW = window.innerWidth;
    }

    let draggableArea = null;
    if (draggableAreaID) {
      draggableArea = document.querySelector(`#${draggableAreaID}`);
    }
    if (draggableArea && !draggableArea.contains(e.target)) return;
    const dynamicWidth = btnRef.current.offsetWidth;

    let newX = viewportW - e.clientX + disRight - dynamicWidth;
    let newY = e.clientY - disTop;

    if (!isDragAcross) setIsDragAcross(true);

    if (draggableArea) {
      const dreaWidth = draggableArea.offsetWidth;
      if (position == '0') {
        const maxWidth = viewportW - (dreaWidth + dynamicWidth);
        if (newX >= maxWidth) {
          newX = maxWidth;
        }
      } else {
        if (newX <= dreaWidth) {
          newX = dreaWidth;
        }
      }
    }

    onMoveEnd(newY, newX);
  };

  const mouseUp = () => {
    document.body.style.userSelect = 'auto'; //恢复其他元素的选中
    if (document.querySelector('.game-iframe')) {
      document.querySelector('.game-iframe').style.zIndex = ''; //清空底部iframe的层级样式
    }
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', mouseUp);
    if (isClickRef.current) {
      setTimeout(() => onClick(), 0);
    }
  };

  useEffect(() => {
    const element = btnRef.current;
    if (element) {
      element.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      if (element) {
        element.removeEventListener('touchmove', handleTouchMove, { passive: false });
      }
    };
  }, [btnRef, disRight, disTop, isDragAcross]);

  return (
    <>
      {isdrag ? (
        <div
          className={`fixed cursor-pointer z-[9999] touch-none ${!isDragAcross ? className : ''} ${className1}`}
          style={{ right, top }}
          ref={btnRef}
          onTouchStart={touchStart}
          onTouchEnd={touchEnd}
          onMouseDown={mouseDown}
          onDragStart={handleOnDragStart}
        >
          {children}
        </div>
      ) : (
        <LimitClickWrapper className={`cursor-pointer ${className}`} onClick={onClick}>
          {children}
        </LimitClickWrapper>
      )}
    </>
  );
}
