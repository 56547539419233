import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Icon, { IconName } from '../../../../../../components/icon';
import ButtonWithMask from '../../../../../../components/buttonWithMask';

function InviteInfo({
  money = 0,
  onClickClaim,
  onClickHistory,
  onClickMedal,
  pid = '',
  isDisableClaim = true,
  isDisableHistory = true,
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className={cn('h-[1.84rem] flex')}>
        <div className="w-[1.88rem] pl-[.04rem]">
          <div className="relative w-[1.84rem] h-[1.84rem]">
            <img src="/static/img/refer/medal.png" className="w-[1.84rem] h-[1.84rem]" />
            <div
              className="w-[.28rem] h-[.28rem] absolute top-[.18rem] right-[.24rem] cursor-pointer"
              onClick={onClickMedal}
            >
              <div className="w-full h-full text-[var(--colour-7)]">
                <Icon name={IconName.WidthdrawQuestion2} />
              </div>
            </div>
          </div>
        </div>
        <div className="font-[500] text-[.2rem] flex-1">
          <div className="flex justify-between">
            <div className="leading-[1em] pt-[.26rem]">
              <div className={cn('text-[.2rem] font-[500] text-[var(--colour-44)] whitespace-nowrap')}>
                {t('referral.available_now')}
              </div>
              <div className="text-[.2rem] font-[500] text-[var(--colour-26)] pt-[.04rem]">{money}</div>
            </div>
            <div className="flex p-[.3rem_.2rem_0_0]">
              <div className="">
                <ButtonWithMask
                  className="text-[.2rem] font-[500] rounded-normal flex items-center justify-center w-[1.36rem] h-[.52rem]"
                  disable={isDisableClaim}
                  onClick={onClickClaim}
                >
                  {t('referral.receive')}
                </ButtonWithMask>
              </div>
              <div className="ml-[.1rem]">
                {/* <ClaimBtn className="w-[1.36rem] h-[.52rem]" onClick={onClickHistory}>
                  {t('referral.history')}
                </ClaimBtn> */}

                <ButtonWithMask
                  className="text-[.2rem] font-[500] rounded-normal flex items-center justify-center w-[1.36rem] h-[.52rem]"
                  disable={isDisableHistory}
                  onClick={onClickHistory}
                  style={2}
                >
                  {t('referral.history')}
                </ButtonWithMask>
              </div>
            </div>
          </div>
          <div className="pt-[8px] font-[500] leading-[1em] space-y-[.04rem]">
            <div className="flex space-x-[.06rem]">
              <span className={cn('text-[var(--colour-44)]')}>{t('referral.superior')}</span>
              <span className="text-[var(--colour-38)]">{pid}</span>
            </div>
            <div className="flex space-x-[.06rem]">
              <span className={cn('text-[var(--colour-44)]')}>{t('referral.mode')}</span>
              <span className="text-[var(--colour-38)]">{t('referral.mode_name')}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InviteInfo;
