import gwdata from '../../libs/gwdata';
import { SendMessage } from 'react-use-websocket';

/**
 *   网关数据收发
 */
class GatewayManager {
  private static defaultServerType = 2000;
  public static sendMessage: SendMessage;

  /**
   * 发送透传数据 cmd=100
   */
  public static sendGameData(
    gameDataByteArray: gwdata.ByteArray,
    serverType: number = GatewayManager.defaultServerType,
  ) {
    if (GatewayManager.sendMessage) {
      var byteArray = new gwdata.ByteArray();
      var len = 8; // 4 + 2 + 2;
      var cmd = 100;
      len += gameDataByteArray.length;

      byteArray.writeInt(len);
      byteArray.writeShort(cmd);
      byteArray.writeShort(serverType);
      byteArray.writeBytes(gameDataByteArray);

      GatewayManager.sendMessage(byteArray.buffer);
    }
  }

  /**
   *  直接发送json数据到server的方案
   * @param cmd  协议号
   * @param jsonObj json对象（非json字符串）
   */
  public static sendGameData4JsonObj(
    cmd: number,
    jsonObj: Object,
    serverType: number = GatewayManager.defaultServerType,
  ) {
    let byteArray = new gwdata.ByteArray();
    let len = 8; // 4 + 4;  len + cmd

    let contentByteArr = new gwdata.ByteArray();
    contentByteArr.writeUTFBytes(JSON.stringify(jsonObj));

    len += contentByteArr.length;

    byteArray.writeInt(len);
    byteArray.writeInt(cmd);
    byteArray.writeBytes(contentByteArr);
    GatewayManager.sendGameData(byteArray, serverType);

    // console.log("send", serverType,cmd)
  }

  /**
   * 登录网关
   */
  public static loginGateway(identity: string) {
    if (GatewayManager.sendMessage) {
      let byteArray = new gwdata.ByteArray();
      let len = 8; // 4 + 2 + 2;
      let cmd = 3;
      let dataObj = { identity: identity };

      let contentByteArr = new gwdata.ByteArray();
      contentByteArr.writeUTFBytes(JSON.stringify(dataObj));

      len += contentByteArr.length;

      byteArray.writeInt(len);
      byteArray.writeShort(cmd);

      // 发给网站，占位置 server_type
      byteArray.writeShort(0);
      byteArray.writeBytes(contentByteArr);

      GatewayManager.sendMessage(byteArray.buffer);
    }
  }

  /**
   * 订阅
   */
  public static subscribe(serverType: number = GatewayManager.defaultServerType) {
    if (GatewayManager.sendMessage) {
      let byteArray = new gwdata.ByteArray();
      let len = 8; // 4 + 2 + 2;
      let cmd = 5;
      let dataObj = { serverType: serverType };

      let contentByteArr = new gwdata.ByteArray();
      contentByteArr.writeUTFBytes(JSON.stringify(dataObj));

      len += contentByteArr.length;

      byteArray.writeInt(len);
      byteArray.writeShort(cmd);

      // 发给网站，占位置 server_type
      byteArray.writeShort(serverType);
      byteArray.writeBytes(contentByteArr);

      GatewayManager.sendMessage(byteArray.buffer);

      // console.log("subscribe", serverType)
    }
  }

  /**
   * 取消订阅
   */
  public static unsubscribe(serverType: number = GatewayManager.defaultServerType) {
    if (GatewayManager.sendMessage) {
      let byteArray = new gwdata.ByteArray();
      let len = 8; // 4 + 2 + 2;
      let cmd = 13;
      let dataObj = { serverType: serverType };

      let contentByteArr = new gwdata.ByteArray();
      contentByteArr.writeUTFBytes(JSON.stringify(dataObj));

      len += contentByteArr.length;

      byteArray.writeInt(len);
      byteArray.writeShort(cmd);

      // 发给网站，占位置 server_type
      byteArray.writeShort(serverType);
      byteArray.writeBytes(contentByteArr);

      GatewayManager.sendMessage(byteArray.buffer);

      // console.log("subscribe", serverType)
    }
  }

  // 解析结果回调
  public static onReceiveMessage(msg: Blob, callback: Function) {
    if (window.FileReader) {
      var reader = new window.FileReader();
      reader.onload = function () {
        const arrBuff = reader.result as ArrayBuffer;
        let dataByteArray = new gwdata.ByteArray(arrBuff);
        if (dataByteArray && dataByteArray.length >= 8) {
          let len = dataByteArray.readInt();
          let cmd = dataByteArray.readShort();
          let serverType = dataByteArray.readShort();

          // console.warn("Receive CMD : " + cmd,serverType,len);

          if (len == dataByteArray.length && len > 8) {
            let byteArray = new gwdata.ByteArray();
            dataByteArray.readBytes(byteArray);
            let data: string = byteArray.readUTFBytes(len - 8);

            if (cmd == 4) {
              //登录成功
              let resultObj = JSON.parse(data);
              callback &&
                callback({
                  serverType,
                  cmd,
                  data,
                  kickoutSuccess: resultObj.login_reply == 2,
                });
            } else if (cmd == 6) {
              //订阅成功
              let resultObj = JSON.parse(data);
              callback &&
                callback({
                  serverType,
                  cmd,
                  data,
                  subscribeSuccess: resultObj.sub_reply == 0,
                });
            } else if (cmd == 100) {
              //透传数据
              callback &&
                callback({
                  serverType,
                  cmd,
                  data,
                });
              // console.log(serverType,cmd,data)
            } else if (cmd == 12 || cmd == 14 || cmd == 16) {
              // cmd:12 心跳返回 14 订阅失效 16 ip限制
              callback &&
                callback({
                  serverType,
                  cmd,
                  data,
                });
            }
          }
        } else {
          console.warn('onReceiveMessage->', 'null');
        }
      };

      reader.readAsArrayBuffer(msg);
    }
  }

  public static sendPingData() {
    if (GatewayManager.sendMessage) {
      let byteArray = new gwdata.ByteArray();
      let len = 8; // 4 + 2;
      let cmd = 11;

      let contentByteArr = new gwdata.ByteArray();
      contentByteArr.writeUTFBytes(JSON.stringify(null));
      len += contentByteArr.length;

      byteArray.writeInt(len);
      byteArray.writeShort(cmd);
      byteArray.writeShort(0);
      byteArray.writeBytes(contentByteArr);

      GatewayManager.sendMessage(byteArray.buffer);
    }
  }
}

export default GatewayManager;
