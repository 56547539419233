import baseImg from '../../../assets/img/base-stage.png';
import Reward from '../components/reward';

export default function Index({ children, reward = '0' }) {
  return (
    <div className="relative w-[5.2rem] h-[2.6rem] bg-[url(../assets/img/stageBg.png)] bg-no-repeat bg-[length:100%_100%] mb-[1.6rem]">
      <img className="w-[3.3rem] not-drag absolute abs-x-center-bottom bottom-[.7rem]" src={baseImg} />
      <div className="absolute abs-center flex items-center justify-center">{children}</div>
      <div className="flex items-center justify-center absolute abs-x-center-bottom bottom-[0]">
        <Reward>{reward}</Reward>
      </div>
    </div>
  );
}
