import { useTranslation } from 'react-i18next';

const JoinBtn = ({ onClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div
      className="cursor-pointer flex items-center text-[var(--colour-2)] justify-center bg-[var(--colour-1)] rounded-normal font-[600] w-[4.2rem] h-[.84rem] text-[.28rem]"
      onClick={onClick}
    >
      {t('tour.join')}
    </div>
  );
};

export default JoinBtn;
