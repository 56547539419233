import CountDown from '../../dialogModal/components/activityCountDown';
import DragBtnRight from '../../dragBtnRight';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setRefetchActivityData,
} from '../../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import ClaimBtn from '../components/claimbtn';
import { isLogin } from '../../../utils/helper';

const Index = ({ isdrag = false, index = null, imgpath = '', EntranceDisplayLocation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data } = useSelector(
    (state) => ({
      data: get(state, 'app.activityData.activity_promotional_cashback.list[0]'),
    }),
    shallowEqual,
  );

  const onComplete = () => {
    dispatch(setRefetchActivityData());
  };

  const handleClick = () => {
    dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.ReferralCashback }));
  };

  const isClaimable = (() => {
    const list = data?.task_config || [];
    const sumAmount = list.reduce((acc, curr) => {
      return acc + Number(curr.claim_reward || 0);
    }, 0);

    return sumAmount > 0 && isLogin();
  })();

  return (
    <DragBtnRight
      onClick={handleClick}
      className={`activty-absolute-bottom-${index + 1} ${index != null && EntranceDisplayLocation == '0' ? 'activity-item' : ''} ${EntranceDisplayLocation == '0' ? 'right-[.08rem]' : 'left-[.08rem]'}`}
      className1="w-[1.5rem] h-[1.5rem]"
      isdrag={isdrag}
    >
      <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
        <div className="scale-[.5]">
          <div className="w-[3rem] h-[3rem] relative flex flex-col justify-between">
            <img src={imgpath} alt="" className="not-drag absolute left-0 top-0 w-full" />

            {isClaimable ? (
              <>
                <ClaimBtn
                  txtColor="event_bonus_title1"
                  className="rounded-[.08rem] bg-EntranceClaim_btn flex items-center justify-center absolute abs-x-center bottom-[0]"
                >
                  {t('referral.claim')}
                </ClaimBtn>
              </>
            ) : (
              <>
                <div className="flex justify-center items-center  absolute abs-x-center bottom-[0]">
                  <CountDown
                    onComplete={onComplete}
                    startTime={data?.activity_start_time}
                    endTime={data?.activity_end_time}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </DragBtnRight>
  );
};
export default Index;
