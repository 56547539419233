import { get } from 'lodash';
import Close from '../close';
import { useTranslation } from 'react-i18next';
import ScrollWrapper from '../scrollWrapper';

export default function LuckyWheelRule({ handleClose, lotteryHelp = null }) {
  const { t } = useTranslation();

  const html = lotteryHelp;

  return (
    <div className="overflow-hidden rounded-normal relative bg-[var(--colour-35)] w-[6.86rem] min-h-[5.48rem] pb-[.4rem] px-[.32rem]">
      <div className="flex justify-between items-center h-[.72rem]">
        <div className="text-[var(--colour-48)] font-[700] text-[.24rem]">{t('lucky-wheel.rules')}</div>
        <div className="ml-[.24rem] flex justify-center items-center absolute border border-[var(--colour-6)] rounded-small w-[.4rem] h-[.4rem] top-[.16rem] right-[.32rem] bg-[var(--colour-45)]">
          <Close onClick={handleClose} />
        </div>
      </div>
      <div className="h-[.02rem] bg-[var(--colour-6)]"></div>

      <div className="py-[.4rem] font-[400] text-[.24rem] leading-[.3rem]">
        {html && (
          <ScrollWrapper className="h-[8rem]" mr={false}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </ScrollWrapper>
        )}
      </div>
    </div>
  );
}
