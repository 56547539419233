import convidarPng from '../../../../../assets/img/mine/convidar.png';
import convidar1Png from '../../../../../assets/img/mine/convidar1.png';
import GiftPng from '../../../../../assets/img/mine/gift.png';
import LogoutPng from '../../../../../assets/img/mine/logout.png';
import RelatoriosPng from '../../../../../assets/img/mine/relatorios.png';
import SecurityPng from '../../../../../assets/img/mine/security.png';
import SuportePng from '../../../../../assets/img/mine/suporte.png';
import DadosPng from '../../../../../assets/img/mine/dados.png';
import Icon, { IconName } from '../../../../../components/icon';
import { useTranslation } from 'react-i18next';
import { DialogModalChildrenKey, setDialogModalVisible, setNeedLogout } from '../../../../../redux/reducers/appSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import useHandleLogic from '../../../../../hooks/useHandleLogic';
import { useGlobalNavigate } from '../../../../../contexts/navigateContext';
import { toLocalePath } from '../../../../../utils/localePath';
import { useEffect, useState } from 'react';

const AccountLink = () => {
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleClickManMadeInPage } = useHandleLogic();

  const { giftMoneyData, websiteInitConfig, activityStatus, unReadCount } = useSelector(
    (state) => ({
      giftMoneyData: state?.app?.activityData?.gift_money,
      websiteInitConfig: state.app.websiteInitConfig,
      activityStatus: state.app.activityStatus,
      unReadCount: state?.app?.unReadCount || 0,
    }),
    shallowEqual,
  );

  const onClickGoOut = () => {
    const confirmCb = () => {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.SM_Click_LogoutButton);
      dispatch(setNeedLogout());
    };

    const confirmCancelCb = () => {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
    };

    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.Confirm,
        confirmCb,
        confirmCancelCb,
        title: t('account.are_you_sure'),
        content: <span className="">{t('account.want_to_quit')}</span>,
        cancelText: t('game.cancel'),
        confirmText: t('game.ok'),
        isConfirmBtnPrimary: false,
      }),
    );
  };

  const onClickGiftMoney = () => {
    navigate(toLocalePath('/promotion/giftmoney'), { state: { from: '/mine' } });
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_GiftMoney_Floating);
  };

  const getTabsList = () => {
    const baseList = [
      {
        img: RelatoriosPng,
        name: t('account.account'),
        onClick: () => {
          navigate(toLocalePath('/report/0'));
        },
      },
      ...(websiteInitConfig?.website_info?.switch_referral == '1'
        ? [
            {
              img: convidarPng,
              name: t('account.to_invite'),
              onClick: () => {
                navigate(toLocalePath('/referral'));
              },
              text: t('account.million_monthly'),
            },
          ]
        : []),
      {
        img: DadosPng,
        name: t('account.data'),
        onClick: () => {
          navigate(toLocalePath(`/myaccountdata`));
        },
      },
      ...(giftMoneyData
        ? [
            {
              img: GiftPng,
              name: t('account.fund'),
              onClick: onClickGiftMoney,
            },
          ]
        : []),
      ...(activityStatus?.redeem_code_switch == '1'
        ? [
            {
              img: convidar1Png,
              name: t('redeem.redeem_code'),
              onClick: () => {
                navigate(toLocalePath(`/promotion/redeem`));
              },
            },
          ]
        : []),
      {
        img: SuportePng,
        name: t('account.support'),
        onClick: handleClickManMadeInPage,
        text:
          unReadCount > 0 ? (
            <div className="min-w-[.3rem] min-h-[.3rem] bg-[var(--colour-25)] text-[var(--colour-2)] text-[.2rem] font-[500] rounded-[50%] flex items-center justify-center">
              {unReadCount >= 99 ? '99+' : unReadCount}
            </div>
          ) : null,
      },
      {
        img: SecurityPng,
        name: t('account.security'),
        onClick: () => {
          navigate(toLocalePath(`/security`));
        },
      },
      {
        img: LogoutPng,
        name: t('account.go_out'),
        onClick: onClickGoOut,
      },
    ];

    return baseList;
  };

  const [list, setList] = useState(getTabsList());

  useEffect(() => {
    setList(getTabsList());
  }, [giftMoneyData, websiteInitConfig, activityStatus]);

  return (
    <>
      <div className="w-full rounded-[.12rem] py-[.24rem] colour-87 mt-[.28rem] space-y-[.48rem]">
        {list.map((item, index) => {
          return (
            <div className="flex items-center pl-[.34rem] cursor-pointer relative" key={index} onClick={item.onClick}>
              <img className="w-[.56rem] not-drag" src={item.img} alt="" />
              <div className="flex-1 pl-[.2rem] pr-[.56rem] flex justify-between items-center">
                <div className="text-[var(--colour-13)] text-[.24rem] font-[500]">{item.name}</div>
                <div className="text-[.2rem] text-[var(--colour-38)] font-[500]">{item.text}</div>
              </div>

              <div className="absolute rotate-[180deg] top-[50%] translate-y-[-50%] right-[.31rem] w-[.18rem] h-[.18rem]  text-[var(--colour-38)]">
                <Icon name={IconName.BtnBack}></Icon>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AccountLink;
