const CardBox = ({ children, className = '' }) => {
  return (
    <div
      className={`rounded-[.08rem] bg-[var(--colour-35)] overflow-hidden mx-[.2rem] ${className}`}
      style={{
        boxShadow: '0px .04rem .08rem 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      {children}
    </div>
  );
};

export default CardBox;
