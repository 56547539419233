import { useTranslation } from 'react-i18next';
import Close from '../close';
import { useNavigate } from 'react-router-dom';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const TourClaim = ({ handleClose }) => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const onClick = () => {
    navigate('/tournament');
    handleClose();
  };

  return (
    <div className="relative rounded-normal bg-[var(--colour-35)] w-[7.5rem] h-[5rem]">
      <div className="pt-[1.02rem] absolute inset-0 bg-[url(/static/img/tour/sui.webp)] bg-[length:100%_100%]">
        <Close
          className="absolute right-[0rem] top-[0rem] bg-[var(--colour-45)] rounded-normal"
          onClick={handleClose}
        />
        <img src="/static/img/tour/congra.png" className="w-[5.88rem] mx-auto" />
        <div className="mx-[1.1rem] text-center font-[500] mt-[.24rem] text-[.28rem] text-[var(--colour-49)]">
          {t('tour.congra')}
        </div>
        <div
          className="mx-auto mt-[.58rem] flex items-center justify-center font-[700] rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)] cursor-pointer w-[3.2rem] h-[.64rem] text-[.24rem]"
          onClick={onClick}
        >
          {t('tour.claimNow')}
        </div>
      </div>
    </div>
  );
};

export default TourClaim;
