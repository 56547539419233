//弹窗等全站通用功能逻辑放在此处
import { useState, useEffect, memo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import DialogModal from '../dialogModal';
import ResetPwd from '../login/forgotPassword';
import Confirm from '../confirm';
import LoginRegister from '../login/entrance/index';
import GlobalLoadingMask from '../globalLoadingMask';
import useActivityStatus from '../../hooks/useActivityStatus';
import SupportNew from '../supportNew3';
import { resetDepositWithdrawFrequencyConfig } from '../confirmTips';
import { useGlobalNavigate } from '../../contexts/navigateContext';

import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setDrawerMenuVisible,
  setWindowInnerWidth,
  setAllowBodyScroll,
  setContentMask,
  // setZoomNum,
  DialogModalConfigList,
  setInSport,
  setUpdateBalance,
  setFissionDialog,
  FissionDialog,
  FissionDialogShowFrom,
  setRefetchActivityData,
  setShowHeaderAndFooter,
  setShowMobileMenuBar,
  setShowWGDeposit,
  setPromotionClaimedCount,
  setShowHeaderGiftMoney,
  setShowPandaDeposit,
  setShowPandaWithdraw,
} from '../../redux/reducers/appSlice';
import FairCheck from '../dialogModal/fairCheck';
import GamehelpMes from '../dialogModal/gamehelpmes';
import GamePop from '../dialogModal/gamePop';
import config from '../../constants/config';
import { toLocalePath, toNoLocalePath } from '../../utils/localePath';
import VipUpgrade from '../dialogModal/vipUpgrade';
import BonusBox from '../dialogModal/bounsbox';
import EventBonus from '../dialogModal/eventBonus';
import {
  isLogin,
  getQueryString,
  isUseLayoutMobile,
  isRouteWithHiddenElements,
  getIsShowMobileMenu,
  isControllableSite,
  getIsFullPageShowDisconnectInfo,
} from '../../utils/helper';
import WithdrawDeposit from '../dialogModal/withdrawDeposit';
import GiftMoney from '../giftMoney/giftMoney';
import { get } from 'lodash';
import TourClaim from '../dialogModal/tourClaim';
import FacebookManager from '../../manager/facebookManager';
import { setLoginInfo, setLoginType, setUserObj } from '../../redux/reducers/userSlice';
import { useStateIfMounted } from 'use-state-if-mounted';
import AccountSetting from '../dialogModal/accountSetting';
import ClaimRewards from '../welcomeBonus/claimRewards';
import WelcomeTips from '../welcomeBonus/tips';
import Redeem from '../redeem/redeem';
import GlobalGuide from '../globalGuide';
import DepositSuccess from '../dialogModal/depositSuccess';
import GoogleAuthenticator from '../dialogModal/googleAuthenticator';
import useHandleLogic from '../../hooks/useHandleLogic';
// import BulletinComponent from '../dialogModal/bulletin';
import BulletinComponent from '../dialogModal/bulletin/index';
import PilotWheel from '../dialogModal/pilotwheel';
import WheelBonus from '../dialogModal/wheelBonus';
import ReferralCashback from '../dialogModal/referralCashback';
// import ReferralAchievement from '../dialogModal/promoteAchievements';
import ReferralAchievement from '../dialogModal/referralAchievements/index';
import CashRain from '../dialogModal/cashRain';
import PlayerMusic from '../dialogModal/playermusic';
import FastDownload from '../dialogModal/fastDownLoad';
import FirstDepositDownload from '../dialogModal/firstDepositDownload';
import IosSafariGuide from '../dialogModal/iosSafariGuide';
import PwaReward from '../dialogModal/downLoadBtn/components/reward';
import PwaguideQRcodeModel from '../dialogModal/pwaguideQRcode';
import DrawerPopup from '../drawerPopup';
import BrDesignDeposit from '../pay/br/design/deposit';
// import Fission from '../fission';
import BottomDownloadBox from '../dialogModal/downLoadBtn';
import PopupManager from '../../utils/popupManager';
import { Logout } from '../../api/index-auth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import OAuthRedirecting from '../../utils/oauthRedirecting';
import { Popup } from 'react-vant';
import GiftMoneyPopUp from '../header/giftMoney';
import { eventEmitter, EmitterEventTypes } from '../../utils/eventEmitter';
import ResizeObserver from '../../utils/resizeObserve';
import MysteryBonusTips from '../dialogModal/mysteryBonusTips';
import FullPagePopup from '../fullpagePopup';
import DepositPanda from '../pay/br/design003/deposit';
import WithdrawPanda from '../pay/br/design003/withdraw';
import ClaimBonusResult from '../dialogModal/components/claimBonusResult';

const Skeleton = (props) => {
  const dispatch = useDispatch();
  const { children, className = '' } = props;
  const location = useLocation();
  const { pathname } = location;
  const [dimensions, setDimensions] = useStateIfMounted({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const {
    dialogModalProp,
    allowBodyScroll,
    isInSport,
    contentMask,
    dialogModalCount,
    showInbox,
    websiteInitConfig,
    needLogout,
    showSupportNew,
    showWGDeposit,
    showHeaderGiftMoney,
    showPandaDeposit,
    showPandaWithdraw,
    uid,
  } = useSelector(
    (state) => ({
      dialogModalProp: state.app.dialogModalProp,
      allowBodyScroll: state.app.allowBodyScroll,
      isInSport: state.app.isInSport,
      contentMask: state.app.contentMask,
      dialogModalCount: state.app.dialogModalCount,
      showInbox: state.app.showInbox,
      websiteInitConfig: state.app.websiteInitConfig,
      needLogout: state.app.needLogout,
      showSupportNew: state.app.showSupportNew,
      showWGDeposit: state.app.showWGDeposit,
      showHeaderGiftMoney: state.app.showHeaderGiftMoney,
      showPandaDeposit: state.app.showPandaDeposit,
      showPandaWithdraw: state.app.showPandaWithdraw,
      uid: get(state, 'user.userObj.uid', ''),
    }),
    shallowEqual,
  );
  const noRedirectToHomeList = ['/installPwa'];
  const resizeEmitterRef = useRef(null);
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const handleGoBack = () => {
    const search = window.location.search;
    navigate(toLocalePath(`/${search.length > 1 ? search : ''}`));
  };

  const handleClientLogout = (showLoginBox) => {
    const exitUid = uid;
    PopupManager.lowFrequencyPopup = false;
    dispatch(setUserObj(null));
    dispatch(setLoginInfo(null));
    dispatch(setPromotionClaimedCount(null));
    dispatch(setLoginType(''));
    // dispatch(setSpinguideWheelStatus('0'));
    dispatch(setUpdateBalance({ currency: '', balance: 0 }));
    // 退出facebook
    FacebookManager.logout();
    // 返回首页
    if (!noRedirectToHomeList.includes(pathname)) {
      handleGoBack();
    }
    // 显示登录界面
    if (showLoginBox) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
    console.warn('我退出了');
    PopupManager.resetAllowPopupConfig();
    resetDepositWithdrawFrequencyConfig();
    localStorage.removeItem(`recentGameList_${exitUid}`);
  };

  /**
   * 处理退出登录
   * @param {boolean} showLoginBox 是否显示登录界面 默认：false
   */
  const handleLogout = (showLoginBox = false) => {
    OAuthRedirecting.RemoveNotLoggedInLsKey();
    if (isLogin()) {
      Logout().then((res) => {
        handleClientLogout(showLoginBox);
        if (res.code == 0) {
          OAuthRedirecting.Logout();
        }
      });
    } else {
      handleClientLogout(showLoginBox);
    }
  };

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    if (window.LOBBY_UTILS) {
      window.LOBBY_UTILS.mediaScreen();
    }
  };

  const handleResizeEmitter = () => {
    handleResize();
    eventEmitter.emit(EmitterEventTypes.resize);
  };

  const clearResizeEmitterTimer = () => {
    if (resizeEmitterRef.current != null) {
      window.clearTimeout(resizeEmitterRef.current);
      resizeEmitterRef.current = null;
    }
  };

  const handleResizeDelayEmitter = () => {
    handleResize();
    clearResizeEmitterTimer();
    resizeEmitterRef.current = window.setTimeout(() => {
      eventEmitter.emit(EmitterEventTypes.resizeDelay);
      clearResizeEmitterTimer();
    }, 300);
  };

  useEffect(() => {
    const resizeObserver = ResizeObserver.createImmediate(handleResizeEmitter);
    const resizeDelayObserver = ResizeObserver.createDelayed(handleResizeDelayEmitter, 300);
    const orientationChangeDelayObserver = ResizeObserver.createDelayed(
      handleResizeDelayEmitter,
      300,
      'orientationchange',
    );

    return () => {
      resizeObserver.destroy();
      resizeDelayObserver.destroy();
      orientationChangeDelayObserver.destroy();
    };
  }, []);

  // 处理退出登录逻辑
  useEffect(() => {
    if (needLogout.timestamp !== 0) {
      handleLogout(needLogout.showLoginBox);
    }
  }, [needLogout]);

  useEffect(() => {
    dispatch(setWindowInnerWidth(dimensions.width));
  }, [dimensions]);

  useEffect(() => {
    if (isInSport) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: false,
        }),
      );
    }
  }, [isInSport]);

  useEffect(() => {
    // if (allowBodyScroll) {
    //   window.scrollTo(0, bodyScrollTop);
    // }
    // 设置body是否可以滑动
    let body = document.body.style;
    body.overflow = allowBodyScroll ? '' : 'hidden';
  }, [allowBodyScroll]);

  // 弹窗数量检测
  useEffect(() => {
    // console.log('[dialogModalCount]', dialogModalCount);
    if (dialogModalCount > 0) {
      dispatch(setAllowBodyScroll(false));
    } else {
      dispatch(setAllowBodyScroll(true));
    }
  }, [dialogModalCount]);

  useEffect(() => {
    if (showSupportNew) {
      dispatch(setAllowBodyScroll(false));
    } else {
      dispatch(setAllowBodyScroll(true));
    }
  }, [showSupportNew]);

  const selectDialogModalChild = () => {
    const { InitActivityStatus } = useActivityStatus();
    const list = [
      // {
      //   key: DialogModalChildrenKey.Fission,
      //   component: (
      //     <Fission
      //       content={null}
      //       callback={() => {
      //         dispatch(
      //           setDialogModalVisible({
      //             show: DialogModalChildrenKey.Default,
      //           }),
      //         );
      //         if (dialogModalProp.closeWithBack) {
      //           handleGoBack();
      //         }
      //       }}
      //     />
      //   ),
      // },
      {
        key: DialogModalChildrenKey.RedPacket,
        component: (
          <CashRain
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
              if (dialogModalProp.closeWithBack) {
                handleGoBack();
              }
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.LoginBox,
        component: (
          <LoginRegister
            loginOrSignUp={1}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  ...dialogModalProp,
                  show: DialogModalChildrenKey.Default,
                }),
              );
              if (dialogModalProp.closeWithBack) {
                handleGoBack();
              }
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.SignUp,
        component: (
          <LoginRegister
            loginOrSignUp={2}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  ...dialogModalProp,
                  show: DialogModalChildrenKey.Default,
                }),
              );
              if (dialogModalProp.closeWithBack) {
                handleGoBack();
              }
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.ResetPwd,
        component: (
          <ResetPwd
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
              if (dialogModalProp.closeWithBack) {
                handleGoBack();
              }
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.WithdrawDeposit,
        component: (
          <WithdrawDeposit
            walletType={dialogModalProp.walletType}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
            withdraw_enable={dialogModalProp.withdraw_enable}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.Confirm,
        component: (
          <Confirm
            children={dialogModalProp.content}
            handleCancel={dialogModalProp.confirmCancelCb}
            handleConfirm={dialogModalProp.confirmCb}
            handleClose={() => {
              if (!dialogModalProp.disableClose) {
                dispatch(
                  setDialogModalVisible({
                    show: DialogModalChildrenKey.Default,
                  }),
                );
              }
            }}
            disableClose={dialogModalProp.disableClose}
            title={dialogModalProp.title}
            cancelText={dialogModalProp.cancelText}
            confirmText={dialogModalProp.confirmText}
            isConfirmBtnPrimary={dialogModalProp.isConfirmBtnPrimary}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.FairCheck,
        component: (
          <FairCheck
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.GamehelpMes,
        component: (
          <GamehelpMes
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.GamePop,
        component: (
          <GamePop
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.VipUpgrade,
        component: (
          <VipUpgrade
            data={dialogModalProp.data}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.BonusBox,
        component: (
          <BonusBox
            children={dialogModalProp.content}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.EventBonus,
        component: (
          <EventBonus
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
              InitActivityStatus();
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.TourClaim,
        component: (
          <TourClaim
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.AccountSetting,
        component: (
          <AccountSetting
            showBindStyle={dialogModalProp.showBindStyle || 0}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.WelcomeBonus,
        component: (
          <ClaimRewards
            data={dialogModalProp.data}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  ...dialogModalProp,
                  show: DialogModalChildrenKey.Default,
                }),
              );
              dispatch(setRefetchActivityData());
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.Redeem,
        component: (
          <Redeem
            style={dialogModalProp.style}
            money={dialogModalProp.money}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.RegisterBonus,
        component: (
          <WelcomeTips
            style={dialogModalProp.style}
            money={dialogModalProp.money}
            endTime={dialogModalProp.endTime}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  ...dialogModalProp,
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.GiftMoney,
        component: (
          <GiftMoney
            isFromGame={dialogModalProp.isFromGame}
            giftMoneyMoveTargetClass={dialogModalProp.giftMoneyMoveTargetClass}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.DepositSuccess,
        component: (
          <DepositSuccess
            closeCb={dialogModalProp.closeCb}
            depositData={dialogModalProp.depositData}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.GoogleAuthenticator,
        component: (
          <GoogleAuthenticator
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.Bulletin,
        component: (
          <BulletinComponent
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.PilotWheel,
        component: (
          <PilotWheel
            content={dialogModalProp.content}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.WheelBonus,
        component: (
          <WheelBonus
            modalType={dialogModalProp.modalType}
            content={dialogModalProp.content}
            closeCb={dialogModalProp.closeCb}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.ClaimBonusResultTip,
        component: (
          <ClaimBonusResult
            content={dialogModalProp.content}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
            closeCb={dialogModalProp.closeCb}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.PlayerMusic,
        component: (
          <PlayerMusic
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.ReferralCashback,
        component: (
          <ReferralCashback
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.ReferralAchievement,
        component: (
          <ReferralAchievement
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.FastDownload,
        component: (
          <FastDownload
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.FirstDepositDownload,
        component: (
          <FirstDepositDownload
            content={dialogModalProp.content}
            depositData={dialogModalProp.depositData}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.IosSafariGuide,
        component: (
          <IosSafariGuide
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.RewardPwa,
        component: (
          <PwaReward
            content={dialogModalProp.content}
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.PwaguideQRcodeModel,
        component: (
          <PwaguideQRcodeModel
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.BottomDownloadBoxPrompt,
        component: (
          <BottomDownloadBox
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
            bottomDownloadBoxPromptSwitch={dialogModalProp.downloaddata}
          />
        ),
      },
      {
        key: DialogModalChildrenKey.MysteryBonusTips,
        component: (
          <MysteryBonusTips
            handleClose={() => {
              dispatch(
                setDialogModalVisible({
                  show: DialogModalChildrenKey.Default,
                }),
              );
            }}
          />
        ),
      },
    ];

    // console.log('[DialogModal key]:', list.map((item) => item.key));

    // return list[12].component

    let key = dialogModalProp.show;
    const config = DialogModalConfigList.find((item) => item.key === key);
    if (config && config.needLogin && !isLogin()) {
      key = DialogModalChildrenKey.LoginBox;
    }

    // const found = list.find((item) => item.key === dialogModalProp.show);
    const found = list.find((item) => item.key === key);
    return found ? found.component : null;
  };

  useEffect(() => {
    if (window.location.href !== contentMask.href && contentMask.show === true) {
      contentMask.handleClose();
    }
  }, [location]);

  useEffect(() => {
    // 是否在体育中
    dispatch(setInSport(pathname.includes('/sport')));
    dispatch(setShowHeaderAndFooter(!isRouteWithHiddenElements(toNoLocalePath(pathname))));
    dispatch(setShowMobileMenuBar(getIsShowMobileMenu(toNoLocalePath(pathname))));
  }, [pathname]);

  return (
    <div className={`__layout ${className}`}>
      {children}
      {showSupportNew ? <SupportNew /> : <></>}
      <GamePop />
      <DialogModal
        isZoom20={dialogModalProp.show == DialogModalChildrenKey.Fission}
        handleOverlayClick={dialogModalProp.handleOverlayClick}
        shouldCloseOnOverlayClick={dialogModalProp.shouldCloseOnOverlayClick == false ? false : true}
        overlayClassName={dialogModalProp.overlayClassName ? dialogModalProp.overlayClassName : ''}
        noentered={true}
        style={{
          overlay: {
            zIndex: dialogModalProp.zIndex ? dialogModalProp.zIndex : 9999,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: 50,
            borderRadius: '.2rem',
          },
        }}
        isOpen={dialogModalProp.show != DialogModalChildrenKey.Default}
        afterClose={() => {
          if (dialogModalProp.closeCb) {
            dialogModalProp.closeCb();
          }
        }}
        handleClose={() => {
          if (dialogModalProp.disableClose) {
            return;
          }
          dispatch(
            setDialogModalVisible({
              ...dialogModalProp,
              show: DialogModalChildrenKey.Default,
            }),
          );
          if (dialogModalProp.closeWithBack) {
            handleGoBack();
          }
        }}
      >
        {selectDialogModalChild()}
      </DialogModal>
      <GlobalLoadingMask />
      <GlobalGuide />
      <DrawerPopup handleClose={() => {}} isOpen={showWGDeposit} overlayClassName="w-[100%]">
        <BrDesignDeposit
          handleClose={() => {
            dispatch(setShowWGDeposit(false));
          }}
        />
      </DrawerPopup>
      <FullPagePopup isOpen={showPandaDeposit}>
        <DepositPanda
          handleClose={() => {
            dispatch(setShowPandaDeposit(false));
          }}
        />
      </FullPagePopup>
      <FullPagePopup isOpen={showPandaWithdraw}>
        <WithdrawPanda
          handleClose={() => {
            dispatch(setShowPandaWithdraw(false));
          }}
        />
      </FullPagePopup>
      <Popup
        destroyOnClose={true} //关闭时销毁 Popup 里的子元素
        lockScroll={false} // 是否锁定背景滚动
        overlay={false} //是否显示遮罩层
        visible={showHeaderGiftMoney}
        position="top"
        className="!z-[99999999999999] !bg-transparent !overflow-y-visible"
      >
        <GiftMoneyPopUp
          handleClose={() => {
            dispatch(setShowHeaderGiftMoney(false));
          }}
        />
      </Popup>
    </div>
  );
};

export default memo(Skeleton);
