import { useEffect, useRef, useState, forwardRef, createRef } from 'react';
import Icon, { IconName } from '../../../../../components/icon';
// import NextBtn from './nextBtn';
// import PrevBtn from './prevBtn';
import cn from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import { cmsImgUrl } from '../../../../../utils/helper';
import scrollIntoView from 'scroll-into-view';
import useWindowHeight from '../../../../../hooks/useWindowHeight';

const ProviderItem = forwardRef(({ isActive = false, onClick, img, name, isAll = false }, ref) => {
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  return (
    <div
      ref={ref}
      className={cn(
        'flex items-center justify-center rounded-normal overflow-hidden cursor-pointer shrink-0 w-[1.36rem]',
        {
          'bg-[var(--colour-16)] text-[var(--colour-14)]':
            !isActive && websiteInitConfig?.website_info.website_theme != 'theme-15',
          'bg-[var(--colour-1)]': isActive && websiteInitConfig?.website_info.website_theme != 'theme-15',
          'bg-menu-default text-[var(--colour-14)]':
            !isActive && websiteInitConfig?.website_info.website_theme == 'theme-15',
          'bg-menu-selected ': isActive && websiteInitConfig?.website_info.website_theme == 'theme-15',
        },
      )}
      onClick={onClick}
    >
      <div className="flex flex-col items-center max-w-[100%] p-[.12rem_0_0rem]">
        <div
          className={cn('', {
            'px-[.36rem]': isAll,
          })}
        >
          {img}
        </div>

        <div
          className={cn('text-center', {
            'text-[var(--colour-2)]': isActive,
            'text-[var(--colour-14)]': !isActive,
          })}
        >
          {name}
        </div>
      </div>
    </div>
  );
});

const ProviderList = ({
  activeIndex,
  list = [],
  onProviderClick,
  activeProviderId,
  allProviderSource,
  categoryName = '',
  allImg,
}) => {
  const [elRefs, setElRefs] = useState([]);
  // const [isShowTurnBtn, setIsShowTurnBtn] = useState(false);
  const [isShowMobileTurn, setIsShowMobileTurn] = useState(false);
  const isFirstLoad = useRef(true);
  // const listRef = useRef(null);
  // const listRefMobile = useRef(null);
  const listRefMobile2 = useRef(null);
  const { h } = useWindowHeight(3.04);
  useEffect(() => {
    if (list.length > 0) {
      setElRefs((elRefs) =>
        Array(list.length)
          .fill('')
          .map((_, i) => elRefs[i] || createRef()),
      );

      // if (listRefMobile.current) {
      //   const rect = listRefMobile.current.getBoundingClientRect();
      //   listRefMobile.current.style.height = `calc(100vh - ${rect.top}px - 57px)`;
      // }

      const handleScroll = () => {
        const scrollContainer = listRefMobile2.current;

        if (scrollContainer) {
          const scrollHeight = scrollContainer.scrollHeight;
          const scrollTop = scrollContainer.scrollTop;
          const clientHeight = scrollContainer.clientHeight;

          if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
            setIsShowMobileTurn(false);
          } else {
            setIsShowMobileTurn(true);
          }
        }
      };

      handleScroll();

      if (listRefMobile2.current) {
        listRefMobile2.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (listRefMobile2.current) {
          listRefMobile2.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [list]);

  let _timer = null;

  useEffect(() => {
    if (list && list.length > 0 && elRefs && elRefs.length > 0) {
      _timer = window.setTimeout(
        () => {
          isFirstLoad.current = false;
          const gameTabIndex = Array.from(list).findIndex((item) => {
            return activeProviderId == item.source;
          });
          if (gameTabIndex > -1) {
            if (elRefs[gameTabIndex] && elRefs[gameTabIndex].current) {
              scrollIntoView(elRefs[gameTabIndex].current, {
                time: 500,
                validTarget: function (target, parentsScrolled) {
                  return target !== window && ~target.className.indexOf('gamelist-provider-list-scrollable');
                },
              });
            }
          } else {
            if (elRefs[0] && elRefs[0].current) {
              scrollIntoView(elRefs[0].current, {
                time: 500,
                validTarget: function (target, parentsScrolled) {
                  return target !== window && ~target.className.indexOf('gamelist-provider-list-scrollable');
                },
              });
            }
          }
        },
        isFirstLoad.current ? 500 : 0,
      );
      return () => {
        window.clearTimeout(_timer);
      };
    }
  }, [activeProviderId, list, elRefs, activeIndex]);

  return (
    <div>
      <div style={{ height: `${h}rem` }}>
        <div
          className="h-full space-y-[.16rem] overflow-y-auto scrollbar-none gamelist-provider-list-scrollable"
          ref={listRefMobile2}
        >
          {list.map((item, index) => {
            return item == 'all' ? (
              <ProviderItem
                ref={elRefs[index]}
                key={index}
                isActive={activeProviderId == allProviderSource}
                name={categoryName}
                img={allImg}
                onClick={() => {
                  onProviderClick(allProviderSource);
                }}
                isAll={true}
              />
            ) : (
              <ProviderItem
                ref={elRefs[index]}
                key={index}
                isActive={activeProviderId == item.source}
                name={item.name}
                img={<img src={cmsImgUrl(item.icon)} />}
                onClick={() => {
                  onProviderClick(item?.source);
                }}
              />
            );
          })}
        </div>
      </div>
      {isShowMobileTurn && (
        <div className="fixed w-[1.84rem] left-[0px] bottom-[0px] h-[1rem] flex justify-center z-[2] bg-[var(--colour-9)]">
          <div className="flex flex-col items-center space-y-[-0.06rem]">
            <div className="w-[.28rem] h-[.16rem] cursor-pointer text-[var(--colour-65)]">
              <Icon name={IconName.SingleArrow} />
            </div>
            <div className="w-[.28rem] h-[.16rem] cursor-pointer  text-[var(--colour-1)]">
              <Icon name={IconName.SingleArrow} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProviderList;
