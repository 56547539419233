import { useState } from 'react';
import LoginPages from '../loginPages/index';
import RegisterPages from '../registerPages/index';
import LoginRegisterWrapper from '../components/loginRegisterWrapper';

const Entrance3 = ({ handleClose, loginOrSignUp = 1 }) => {
  const [pageType, setPageType] = useState(loginOrSignUp);

  return (
    <>
      <LoginRegisterWrapper handleClose={handleClose}>
        {pageType == 1 ? (
          <LoginPages handleClose={handleClose} changeType={() => setPageType(2)} />
        ) : pageType == 2 ? (
          <RegisterPages changeType={() => setPageType(1)} handleClose={handleClose} />
        ) : (
          <></>
        )}
      </LoginRegisterWrapper>
    </>
  );
};

export default Entrance3;
