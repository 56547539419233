import React, { useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFlip, EffectCreative } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-creative';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../icon';
import { toLocalePath } from '../../utils/localePath';
import { cmsImgUrl, isControllableSite } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

export default function CarouselLogo({ domainList }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const { allowBodyScroll } = useSelector(
    (state) => ({
      allowBodyScroll: state.app.allowBodyScroll,
    }),
    shallowEqual,
  );
  const copyDomain = (item) => {
    copy(item.domain);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });
  };

  useEffect(() => {
    if (swiperRef.current.swiper) {
      swiperRef.current.swiper.update();
    }
  }, [allowBodyScroll]);

  return (
    <div className="w-[1.76rem] h-[.88rem]">
      <Swiper
        ref={swiperRef}
        className="h-full"
        spaceBetween={1}
        slidesPerView={1}
        allowTouchMove={false}
        speed={3000}
        loop={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectFlip, EffectCreative]}
        direction={'vertical'}
      >
        {domainList.map((item, index) => (
          <SwiperSlide key={index} className="h-full flex items-center">
            <div className="flex items-center">
              <div
                className="w-[1.2rem] mr-[.16rem] cursor-pointer"
                onClick={() => {
                  navigate(toLocalePath('/'));
                }}
              >
                <img src={cmsImgUrl(item.app_logo_path)} className="not-drag" />
              </div>
              {isControllableSite() ? (
                <></>
              ) : (
                <div className="w-[.4rem] h-[.4rem] flex items-center justify-center cursor-pointer active:scale-[.95] transition-all select-none border border-solid border-[var(--colour-6)]">
                  <div className="w-[.2rem] h-[.2rem] text-[var(--colour-46)]" onClick={() => copyDomain(item)}>
                    <Icon name={IconName.Copy}></Icon>
                  </div>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
