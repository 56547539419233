import { useState, useEffect, memo, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { setDrawerMenuVisible } from '../../../../../redux/reducers/appSlice';
import { isControllableSite, isLogin } from '../../../../../utils/helper';
import { useTranslation } from 'react-i18next';
import 'react-tooltip/dist/react-tooltip.css';
import EventBonus from '../../../items/eventBonus';
import VipBonus from '../../../items/vipBonus';
import ReferralEarn from '../../../items/referralEarn';
import BettingBonus from '../../../items/bettingBonus';
import Promotions from '../../../items/promotions';
import AppDownloadCode from '../../../items/appDownloadCode';
import Language from '../../../items/language';
import Service from '../../../items/service';
import DrawerMenuFaq from '../../../items/faq';
import useHandleLogic from '../../../../../hooks/useHandleLogic';
import LeftMenu from '../../../leftMenu/leftMenu';
import DrawermenuTagSearch from './components/drawerMenuTagSearch';
import Music from '../../../../music';
import BetRecords from '../../../items/betRecords';
import Agent from '../../../items/agent';
import ActivityBtnList from '../../../items/activityBtnList';
import Network from '../../../items/network';
import DrawerSportSwitch from '../../../items/drawerSportSwitch';
import Message from '../../../items/message';
import Logout from '../../../items/logout/logout';
import DrawerMenuBlog from '../../../items/blog';
import { Popup } from 'react-vant';
import RedeemEntrance from '../../../items/redeem';
import { eventEmitter, EmitterEventTypes } from '../../../../../utils/eventEmitter';

const DTemplate001 = ({ drawerLeftMenuVisible = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    drawerMenuVisible,
    windowInnerWidth,
    defaultLang,
    disconnect,
    downloadBoxPromptSwitch,
    websiteInitConfig,
    userObj,
    redeem_code_switch,
  } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      windowInnerWidth: state.app.windowInnerWidth,
      defaultLang: state.user.defaultLang,
      disconnect: state.app.disconnect,
      downloadBoxPromptSwitch: state.app.downloadBoxPromptSwitch,
      websiteInitConfig: state.app.websiteInitConfig,
      userObj: state.user.userObj,
      redeem_code_switch: state?.app?.activityStatus?.redeem_code_switch,
    }),
    shallowEqual,
  );

  const { faqData, handleClickFaq } = useHandleLogic();

  const handleLinkClick = () => {
    if (drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  const refLeftMenu = useRef(null);
  const refInnerLeftMenu = useRef(null);
  const [hasInnerScroll, setHasInnerScroll] = useState(false);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const handleResize = () => {
    if (refLeftMenu.current) {
      const { scrollHeight, clientHeight } = refLeftMenu.current;
      setHasInnerScroll(scrollHeight > clientHeight);
    }
  };

  useEffect(() => {
    handleResize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      handleResize();
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleSetScrollbarWidth = () => {
    if (refLeftMenu.current && refInnerLeftMenu.current) {
      const _scrollbarWidth = refLeftMenu.current.offsetWidth - refInnerLeftMenu.current.offsetWidth;
      setScrollbarWidth(_scrollbarWidth);
    }
  };

  useEffect(() => {
    handleSetScrollbarWidth();
  }, [hasInnerScroll]);

  return (
    <>
      <Popup
        overlayStyle={{ zIndex: 19 }}
        teleport={document.getElementById('root')}
        style={{ height: '100%', zIndex: 103 }}
        visible={drawerMenuVisible.visible}
        position="left"
        onClose={handleLinkClick}
      >
        <aside
          className={cn(
            'left-menu-container w-[var(--left-menu-width)] pt-[var(--header-box-height)] bg-[var(--colour-27)] box-shadow-8',
          )}
        >
          <div
            ref={refLeftMenu}
            className={cn('left-menu-container__outer1 w-full scrollbar-none p-[.14rem_.14rem_.24rem_.14rem]')}
            style={{ scrollbarColor: 'auto' }}
          >
            <div
              ref={refInnerLeftMenu}
              className={cn('left-menu-container__inner !static', {
                'pt-[var(--disconnect-height)]': disconnect,
                'pt-[var(--top-download-box-prompt-height)]': downloadBoxPromptSwitch,
              })}
            >
              <div
                className={cn('flex flex-col font-[600] mb-[1rem] text-[.2rem] min-h-[100%] text-[var(--colour-3)]')}
              >
                <div className="tag_search_portal_target"></div>
                <Music handleLinkClick={handleLinkClick} />
                {redeem_code_switch == '1' && <RedeemEntrance />}
                <BetRecords handleLinkClick={handleLinkClick} />
                {websiteInitConfig?.website_info?.switch_referral == '1' && <Agent handleLinkClick={handleLinkClick} />}
                <ActivityBtnList handleLinkClick={handleLinkClick} />
                <Network handleLinkClick={handleLinkClick} />
                <DrawerSportSwitch handleLinkClick={handleLinkClick} />
                <Language layout="d-template001" handleLinkClick={handleLinkClick} />
                <Service handleLinkClick={handleLinkClick} />
                {faqData && <DrawerMenuFaq handleLinkClick={handleLinkClick} handleClickFaq={handleClickFaq} />}
                <Message handleLinkClick={handleLinkClick} />
                <DrawerMenuBlog layout="d-template001" handleLinkClick={handleLinkClick} />
                {userObj != null ? (
                  <div className="w-full flex flex-row items-end justify-end mt-[.48rem]">
                    <Logout onClick={handleLinkClick} />
                  </div>
                ) : null}
                <DrawermenuTagSearch />
              </div>
            </div>
          </div>
        </aside>
      </Popup>
    </>
  );
};

export default memo(DTemplate001);
