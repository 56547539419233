import { useDispatch } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { get } from 'lodash';

const useLangServerConfig = () => {
  const { langServerData, defaultLang } = useSelector(
    (state) => ({
      langServerData: get(state, 'app.langServerData'),
      defaultLang: get(state, 'user.defaultLang'),
    }),
    shallowEqual,
  );

  const [currentLangData, setCurrentLangData] = useStateIfMounted([]);

  useEffect(() => {
    if (langServerData && langServerData.length > 0) {
      let langArr = [];
      for (let i = 0; i < langServerData.length; i++) {
        if (langServerData[i].country_code == defaultLang) {
          langArr.push(langServerData[i]);
        }
      }
      setCurrentLangData(langArr);
    }
  }, [defaultLang, langServerData]);

  const getServerLangText = (key) => {
    const val = currentLangData.find((item) => {
      return item.language_key == key;
    });

    return val ? val.content : '';
  };

  return {
    getServerLangText,
  };
};

export default useLangServerConfig;
