import { useState, useEffect, memo, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toNoLocalePath, toLocalePath } from '../utils/localePath';
import {
  setNeedCheckedLobby,
  setShowSupportNew,
  setShowInbox,
  setDialogModalVisible,
  DialogModalChildrenKey,
  ResponsiveMode,
  setLoginStatus,
} from '../redux/reducers/appSlice';
import { get, cloneDeep } from 'lodash';
import { setUserObj, setLoginInfo, setLoginMethod, setLoginType } from '../redux/reducers/userSlice';
import {
  LoginGoogleAuthReq,
  RegisterReqNew,
  LoginReq,
  LogininitReq,
  RegisterReq,
  RegisterReqBind,
  GetCaptchaReq,
  CaptchaGeetestReq,
  CaptchaInitReq,
  SmsSend,
} from '../api/index-auth';
import {
  getQueryString,
  UserCache,
  setVipLevelLogin,
  TextPlaces,
  checkEmail,
  isControllableSite,
  getRegisterConfig,
  getGeetestLang,
} from '../utils/helper';
import { SendCodeByMobileReq } from '../api';
import { useStateIfMounted } from 'use-state-if-mounted';
import { toast } from 'react-toastify';
import useInitActivity from './useInitActivity';
import { useTranslation } from 'react-i18next';
import AnalyticsManager, { FirebaseEvents } from '../manager/analyticsManager';
import PopupManager from '../utils/popupManager';
import OAuthRedirecting from '../utils/oauthRedirecting';
import handleInitGeetest4 from './useGeetestInit';
import { isValidCPF } from '../utils/inputHelper';

const useRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { InitActivity } = useInitActivity();
  const { pathname } = useLocation();
  const isUseLayoutMobile = getRegisterConfig(2) != null;
  const { inviteCode, osType, advertisingId, loginStatus, websiteInitConfig, dialogModalProp, activityId } =
    useSelector(
      (state) => ({
        inviteCode: get(state, 'user.inviteCode', ''),
        osType: get(state, 'user.osType', ''),
        activityId: get(state, 'user.activityId', ''),
        advertisingId: get(state, 'user.advertisingId', ''),
        loginStatus: state.app.loginStatus,
        websiteInitConfig: state.app.websiteInitConfig,
        dialogModalProp: state.app.dialogModalProp,
      }),
      shallowEqual,
    );

  // 接口配置开了用户名注册使用userName否则使用phone
  const [inputType, setInputType] = useState(UserCache.LoginMethodValue.UserName);
  const [hasBeenSent, setHasBeenSent] = useStateIfMounted(false);
  const [codeLoading, setCodeLoading] = useState(false);
  //是否请求中
  const [requesting, setRequesting] = useStateIfMounted(false);
  const [sendCodeformData, setSendCodeformData] = useStateIfMounted({
    mobile: '',
    cryCode: '',
    captcha: '',
  });
  const [captchaInitConfig, setCaptchaInitConfig] = useState(null);
  const [formData, setFormData] = useStateIfMounted({
    mobile: '',
    password: '',
    code: '',
    invite_code: inviteCode,
    a_id: activityId,
    osType,
    advertisingId,
    fast_track_is_send: '1', // 是否接受fasttrack消息 '0' 不接受 '1' 接受
    loginOrSignup: '2', // '1'登录 '2'注册
    firstName: '',
    lastName: '',
    birthDate: null,
    cryCode: '',
    name: '',
    cpf: '',
  });

  //用于layout10002注册，同时需要username和mobie
  const [formData2, setFormData2] = useStateIfMounted({
    userName: '',
    confirmPassword: '',
  });

  const registerReqBindType = async (registerType, params) => {
    if (
      (registerType == UserCache.LoginMethodValue.UserName &&
        captchaInitConfig?.register_user_validation_mode == 'geetest') ||
      (registerType == UserCache.LoginMethodValue.Phone &&
        websiteInitConfig.system_config.registerCode != 1 &&
        captchaInitConfig?.register_phone_validation_mode == 'geetest')
    ) {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        registerType == UserCache.LoginMethodValue.UserName
          ? captchaInitConfig?.register_user_risk_type
          : captchaInitConfig?.register_phone_risk_type,
        setRequesting,
      );
      return RegisterReqBind({
        ...params,
        ...captchaObj,
      });
    } else {
      return RegisterReqBind({
        ...params,
      });
    }
  };

  const RegisterReqNewType = async (registerType, params) => {
    if (
      (registerType == UserCache.LoginMethodValue.UserName &&
        captchaInitConfig?.register_user_validation_mode == 'geetest') ||
      (registerType == UserCache.LoginMethodValue.Phone &&
        websiteInitConfig.system_config.registerCode != 1 &&
        captchaInitConfig?.register_phone_validation_mode == 'geetest')
    ) {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        registerType == UserCache.LoginMethodValue.UserName
          ? captchaInitConfig?.register_user_risk_type
          : captchaInitConfig?.register_phone_risk_type,
        setRequesting,
      );
      return RegisterReqNew({
        ...params,
        ...captchaObj,
      });
    } else {
      return RegisterReqNew({
        ...params,
      });
    }
  };

  const registerReqType = async (registerType, params) => {
    if (
      (registerType == UserCache.LoginMethodValue.UserName &&
        captchaInitConfig?.register_user_validation_mode == 'geetest') ||
      (registerType == UserCache.LoginMethodValue.Phone &&
        websiteInitConfig.system_config.registerCode != 1 &&
        captchaInitConfig?.register_phone_validation_mode == 'geetest')
    ) {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        registerType == UserCache.LoginMethodValue.UserName
          ? captchaInitConfig?.register_user_risk_type
          : captchaInitConfig?.register_phone_risk_type,
        setRequesting,
      );
      return RegisterReq({
        ...params,
        ...captchaObj,
      });
    } else {
      return RegisterReq({
        ...params,
      });
    }
  };

  const registerReqEmailType = async (registerType, params) => {
    if (
      (registerType == UserCache.LoginMethodValue.UserName &&
        captchaInitConfig?.register_user_validation_mode == 'geetest') ||
      (registerType == UserCache.LoginMethodValue.Phone &&
        websiteInitConfig.system_config.registerCode != 1 &&
        captchaInitConfig?.register_phone_validation_mode == 'geetest')
    ) {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        registerType == UserCache.LoginMethodValue.UserName
          ? captchaInitConfig?.register_user_risk_type
          : captchaInitConfig?.register_phone_risk_type,
        setRequesting,
      );
      return RegisterReq({
        ...params,
        ...captchaObj,
      });
    } else {
      return RegisterReq({
        ...params,
      });
    }
  };

  const registerRequest = async (
    params = null,
    registerWithPhone = false,
    registerType = UserCache.LoginMethodValue.UserName,
  ) => {
    const submitFormData = {
      ...cloneDeep(formData),
      captcha: captchaInitConfig?.captcha,
      cpf: formData.cpf.replace(/[\.-]/g, ''),
    };
    if (isUseLayoutMobile) {
      if (registerWithPhone) {
        return registerReqBindType(registerType, {
          ...submitFormData,
          mobile: `${websiteInitConfig.website_info.country_code || ''}${submitFormData.mobile}`,
          username: formData2.userName,
        });
      } else {
        params.mobile = formData2.userName;
      }
    }

    if (inputType == UserCache.LoginMethodValue.Phone) {
      return RegisterReqNewType(registerType, {
        ...submitFormData,
        mobile: `${websiteInitConfig.website_info.country_code || ''}${submitFormData.mobile}`,
      });
    } else if (inputType == UserCache.LoginMethodValue.UserName) {
      return registerReqType(registerType, {
        ...submitFormData,
        username: params.mobile,
      });
    } else if (inputType == UserCache.LoginMethodValue.Email) {
      return registerReqEmailType(registerType, {
        ...submitFormData,
        email: params.mobile,
      });
    }
  };

  //registerType 注册方式2默认使用username方式，注册方式1判断注册方式username/phone
  const handleRequest = (registerWithPhone = false, registerType = UserCache.LoginMethodValue.UserName) => {
    registerRequest(
      {
        ...formData,
      },
      registerWithPhone,
      registerType,
    ).then((res) => {
      setRequesting(false);

      if (res.code == 0) {
        const result = res.result;

        if (inputType == UserCache.LoginMethodValue.Phone) {
          UserCache.setPhone(formData.mobile);
        } else if (inputType == UserCache.LoginMethodValue.UserName) {
          UserCache.setUserName(formData.mobile);
        }
        UserCache.setLoginMethod(inputType);

        // 优化2需求开始
        UserCache.setAnyAccount(formData.mobile);
        // 优化2需求结束

        PopupManager.setIsRegister();
        localStorage.removeItem('showNotice');
        dispatch(setLoginMethod(result.login_method));
        dispatch(setLoginType(result.type));
        dispatch(
          setDialogModalVisible({
            ...dialogModalProp,
            show: DialogModalChildrenKey.Default,
          }),
        );

        if (result && result.user_obj) {
          if (result.user_obj.vip_level) {
            setVipLevelLogin(result.user_obj.vip_level, result.user_obj.uid);
          }
        }

        if (OAuthRedirecting.URL == null) {
          if (result.type == 'signup') {
            AnalyticsManager.registerSuccess(result.user_obj);
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Reg_Success);
          }
          toast.success(t('home.toast015'), {
            containerId: 'global',
          });
          dispatch(setLoginStatus(!loginStatus));
          dispatch(setUserObj(result.user_obj));
          dispatch(
            setLoginInfo({
              identity: result.identity,
            }),
          );
        } else {
          OAuthRedirecting.Login(OAuthRedirecting.URL, result.identity);
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
        let formDataJson = JSON.stringify({
          errCode: res.code,
          inputType: inputType,
          ...formData,
        });
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Reg_Fail, formDataJson);
        handleReReqCaptcha();
      }
    });
  };

  const handleSendCodeType = async (params) => {
    if (
      inputType == UserCache.LoginMethodValue.Phone &&
      websiteInitConfig.system_config.registerCode == 1 &&
      captchaInitConfig?.register_phone_validation_mode == 'geetest'
    ) {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        captchaInitConfig?.register_phone_risk_type,
        setCodeLoading,
      );
      return SmsSend({
        ...params,
        mode: 'register_phone',
        ...captchaObj,
      });
    } else {
      return SmsSend({
        ...params,
        mode: 'register_phone',
      });
    }
  };

  //发送验证码
  const handleSendCode = async () => {
    if (!formData.mobile) {
      return toast.info(t('1008015'), {
        containerId: 'global',
      });
    }
    if (
      ([UserCache.LoginMethodValue.UserName].includes(inputType) &&
        captchaInitConfig?.register_user_validation_mode == 'image') ||
      ([UserCache.LoginMethodValue.Phone].includes(inputType) &&
        captchaInitConfig?.register_phone_validation_mode == 'image')
    ) {
      if (sendCodeformData.cryCode === '') {
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    if (codeLoading) {
      return;
    }

    setCodeLoading(true);

    try {
      handleSendCodeType({
        ...sendCodeformData,
        mobile: `${websiteInitConfig.website_info.country_code || ''}${sendCodeformData.mobile}`,
      }).then((res) => {
        if (res.code == 0) {
          setHasBeenSent(true);
          toast.success(t('home.toast033'), {
            containerId: 'global',
          });
        } else {
          handleReReqCaptcha();
          toast.error(res.msg, {
            containerId: 'global',
          });
          setHasBeenSent(false);
        }

        setCodeLoading(false);
      });
    } catch (err) {
      return setCodeLoading(false);
    }
  };

  const handleSubmit = async (registerType = UserCache.LoginMethodValue.UserName) => {
    if (inputType == UserCache.LoginMethodValue.UserName) {
      if (!formData.mobile) {
        return toast.info(t('1008008'), {
          containerId: 'global',
        });
      }
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      if (!formData.mobile) {
        return toast.info(t('1008015'), {
          containerId: 'global',
        });
      }
    } else if (inputType == UserCache.LoginMethodValue.Email) {
      if (!checkEmail(formData.mobile)) {
        return toast.info(t('1008013'), {
          containerId: 'global',
        });
      }
    }
    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }

    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }
    if (
      (inputType == UserCache.LoginMethodValue.UserName &&
        captchaInitConfig?.register_user_validation_mode == 'image') ||
      (inputType == UserCache.LoginMethodValue.Phone &&
        websiteInitConfig.system_config.registerCode != 1 &&
        captchaInitConfig?.register_phone_validation_mode == 'image')
    ) {
      if (sendCodeformData.cryCode === '') {
        handleReReqCaptcha();
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    if (
      inputType == UserCache.LoginMethodValue.Phone &&
      websiteInitConfig.system_config.registerCode === 1 &&
      (captchaInitConfig?.register_phone_validation_mode == 'geetest' ||
        captchaInitConfig?.register_phone_validation_mode == 'image') &&
      !formData.code
    ) {
      return toast.info(t('login-signup.vericication_code'), {
        containerId: 'global',
      });
    }

    if (
      websiteInitConfig?.system_config?.register_config?.platform &&
      websiteInitConfig?.system_config?.register_config?.platform.length > 0
    ) {
      let findPlatform = websiteInitConfig?.system_config?.register_config?.platform.find((item) => {
        return item.method == 1;
      });
      if (findPlatform && findPlatform.required_cpf_name == 1) {
        //开启name和cpf验证

        if (formData.name == '' || !/^(?!.* {2,})[^'"\n\r\t\\]*[\s]?[^'"\n\r\t\\]*$/.test(formData.name)) {
          return toast.info(t('home.toast34'), {
            containerId: 'global',
          });
        }
        if (
          formData.cpf == '' ||
          formData.cpf.replace(/[\.-]/g, '').length < 11 ||
          !/^\d+$/.test(formData.cpf.replace(/[\.-]/g, '') || !isValidCPF(formData.cpf))
        ) {
          return toast.info(t('home.toast35'), {
            containerId: 'global',
          });
        }
      }
    }

    try {
      setRequesting(true);
      handleRequest(false, registerType);
    } catch (error) {
      setRequesting(false);
    }
  };

  const handleSubmit2 = async (registerWithPhone) => {
    if (registerWithPhone) {
      if (!formData.mobile) {
        return toast.info(t('1008015'), {
          containerId: 'global',
        });
      }
    }

    if (isUseLayoutMobile) {
      if (!formData2.userName) {
        return toast.info(t('1008008'), {
          containerId: 'global',
        });
      }
    }
    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }

    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }

    if (formData.password !== formData2.confirmPassword) {
      return toast.error(t('1008012'), {
        containerId: 'global',
      });
    }
    if (captchaInitConfig?.register_user_validation_mode == 'image') {
      if (formData.cryCode === '') {
        handleReReqCaptcha();
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    if (
      websiteInitConfig?.system_config?.register_config?.platform &&
      websiteInitConfig?.system_config?.register_config?.platform.length > 0
    ) {
      let findPlatform = websiteInitConfig?.system_config?.register_config?.platform.find((item) => {
        return item.method == 2;
      });
      if (findPlatform && findPlatform.required_cpf_name == 1) {
        //开启name和cpf验证

        if (formData.name == '' || !/^(?!.* {2,})[^'"\n\r\t\\]*[\s]?[^'"\n\r\t\\]*$/.test(formData.name)) {
          return toast.info(t('home.toast34'), {
            containerId: 'global',
          });
        }
        if (
          formData.cpf == '' ||
          formData.cpf.replace(/[\.-]/g, '').length < 11 ||
          !/^\d+$/.test(formData.cpf.replace(/[\.-]/g, '') || !isValidCPF(formData.cpf))
        ) {
          return toast.info(t('home.toast35'), {
            containerId: 'global',
          });
        }
      }
    }

    try {
      setRequesting(true);
      handleRequest(registerWithPhone);
    } catch (error) {
      setRequesting(false);
    }
  };

  const handleReReqCaptcha = () => {
    CaptchaInitReq().then((res) => {
      if (res.code == 0) {
        setCaptchaInitConfig(res.result);
        setSendCodeformData((sendCodeformData) => {
          return { ...sendCodeformData, captcha: res.result.captcha, cryCode: '' };
        });
      }
    });
  };

  return {
    captchaInitConfig,
    requesting,
    hasBeenSent,
    inputType,
    sendCodeformData,
    formData,
    codeLoading,
    setRequesting,
    setCodeLoading,
    setInputType,
    setHasBeenSent,
    setSendCodeformData,
    handleSendCode,
    handleSubmit,
    setFormData,
    formData2,
    setFormData2,
    handleSubmit2,
    handleReReqCaptcha,
  };
};

export default useRegister;
