import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cmsImgUrl } from '../../../../utils/helper';
import { shallowEqual, useSelector } from 'react-redux';
import useLangServerConfig from '../../../../hooks/useLangServerConfig';
import useLink from '../../../../hooks/useLink';
import { isControllableSite } from '../../../../utils/helper';

const NTemplate001 = () => {
  const [list, setList] = useState([]);

  const navigate = useNavigate();
  const { getServerLangText } = useLangServerConfig();
  const { handleCmsLink } = useLink();
  const { websiteInitData, activityStatus, activityData, websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitData: state.app.websiteInitData,
      activityStatus: state.app.activityStatus,
      activityData: state.app.activityData,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (websiteInitData && websiteInitData.template) {
      const data = websiteInitData.template.find((item) => {
        return item.module == 'HomeAd';
      });

      if (data) {
        const list = data.config.filter((item) => {
          return item.status == 1;
        });

        list.sort((a, b) => {
          return Number(b.sort) - Number(a.sort);
        });

        setList(list);
      }
    }
  }, [websiteInitData]);

  return (
    <>
      {list && list.length > 0 ? (
        <div
          className={`max-w-[7.5rem] w-full mx-auto grid-container item-${list.length} ${isControllableSite() ? '' : 'px-[.16rem]'}`}
        >
          {list.map((item, index) => {
            return (
              <div
                key={index}
                className={`item p-[.12rem] h-[.92rem] overflow-hidden wrap-break-word active:scale-[.95] transition-all select-none cursor-pointer relative bg-no-repeat bg-[length:100%_100%] text-[.2rem] font-[500] leading-[.2rem]`}
                style={{
                  backgroundImage: `url(${list.length == 3 && index == 2 ? cmsImgUrl(item.bg_img_url_long) : cmsImgUrl(item.bg_img_url)})`,
                }}
                onClick={() => {
                  handleCmsLink(item.open_mode, item.new_window, item.link);
                }}
              >
                <div
                  className="link_text text-[#fff]"
                  dangerouslySetInnerHTML={{ __html: getServerLangText(item.lang_key) || '' }}
                ></div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default NTemplate001;
