import { useTranslation } from 'react-i18next';
import { cmsImgUrl } from '../../../../../utils/helper';
import { useSelector, shallowEqual } from 'react-redux';
import InstallButton from './installButton';
import Icon, { IconName } from '../../../../../components/icon';

const CompletedRecharge = ({ uid = '', amount = 0, fromApk = '' }) => {
  const { t } = useTranslation();
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      installStatus: state.app.installStatus,
    }),
    shallowEqual,
  );

  return (
    <div className="px-[.34rem]">
      <div className="mt-[.24rem] text-[.36rem] font-[700] text-center text-[var(--colour-31)]">
        {t('home.discover')}
      </div>

      <div className="mt-[.24rem] mx-auto text-[var(--colour-18)] text-[.28rem] font-[500]">
        <span
          dangerouslySetInnerHTML={{
            __html: t('home.deposit_first', {
              currency: `${websiteInfo?.currency_symbol} ${amount}`,
              uid: uid,
            }),
          }}
        ></span>
      </div>

      <div className="mt-[.4rem] h-[1.08rem] bg-[#F5F6F4] rounded-[.16rem] flex items-center gap-[.22rem] pl-[.22rem]">
        <img className="w-[.88rem] h-[.88rem]" src={cmsImgUrl(websiteInfo['android_chrome_192x192'])} />
        <div className="text-[var(--colour-18)] text-[.28rem] font-[500]">{websiteInfo.website_name || ''}</div>
      </div>

      <div className="mt-[.56rem]">
        <InstallButton fromApk={fromApk} />
      </div>

      <div className="mt-[.56rem] text-[var(--colour-31)] text-[.32rem] font-[700]">{t('home.toinstall')}</div>

      <div className="mt-[.2rem] text-[.28rem] font-[500] text-[var(--colour-18)]">
        <div>1. {t('home.downloadpwaTip1')}</div>
        <div>2. {t('home.downloadpwaTip2')}</div>
        <div>3. {t('home.downloadpwaTip3')}</div>
        <div>4. {t('home.downloadpwaTip4')}</div>
      </div>

      <div className="mt-[.52rem] mx-auto rounded-[.16rem] bg-[#F5F6F4] p-[.06rem_.16rem_.12rem_.16rem] mb-[.22rem]">
        <div className="text-[.28rem] font-[600] text-[var(--colour-31)] flex items-center gap-[.1rem]">
          <div className="w-[.28rem] h-[.28rem] text-[var(--colour-25)]">
            <Icon name={IconName.PwaWarning} />
          </div>
          <div>{t('home.friendly')}</div>
        </div>
        <div className="text-[.2rem] font-[500] text-[var(--colour-18)]">{t('home.installit')}</div>
      </div>
    </div>
  );
};

export default CompletedRecharge;
