import { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toNoLocalePath, toLocalePath, getLangFromPath } from '../utils/localePath';
import {
  setNeedCheckedLobby,
  setShowSupportNew,
  setShowInbox,
  setDialogModalVisible,
  DialogModalChildrenKey,
  ResponsiveMode,
  setShowTipsBeforeLeaveRegister,
  setShowWGDeposit,
} from '../redux/reducers/appSlice';
import { get } from 'lodash';
import { UserinfoReq } from '../api';
import { setUserObj } from '../redux/reducers/userSlice';
import { getQueryString, isControllableSite, isLogin } from '../utils/helper';
import AnalyticsManager, { FirebaseEvents } from '../manager/analyticsManager';
import { useGlobalNavigate } from '../contexts/navigateContext';

const useHandleLogic = () => {
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { pathname } = useLocation();
  const [balanceLoad, setBalanceLoad] = useState(false); //用户余额接口是否在请求中
  const [faqData, setFaqData] = useState(null); // 账户中心faq链接使用的数据

  const { gameUrl, gameHtml, defaultLang, responsiveMode, siteLiveChatInfo, dialogModalProp, websiteInitData } =
    useSelector(
      (state) => ({
        gameUrl: get(state, 'app.gamePopData.gameUrl', ''),
        gameHtml: get(state, 'app.gamePopData.gameHtml', ''),
        defaultLang: get(state, 'user.defaultLang'),
        responsiveMode: get(state, 'app.responsiveMode'),
        siteLiveChatInfo: get(state, 'app.websiteInitConfig.website_info.site_live_chat_info', null),
        dialogModalProp: state.app.dialogModalProp,
        websiteInitData: state?.app?.websiteInitData,
      }),
      shallowEqual,
    );

  useEffect(() => {
    const footer = websiteInitData?.footer?.footer_help || [];

    const footer_help_id = Array.from(footer).find((item) => {
      return item.type == 'faq' && item.pid == 0;
    });

    if (footer_help_id) {
      setFaqData(footer_help_id);
    } else {
      setFaqData(null);
    }
  }, [websiteInitData, defaultLang]);

  const handleClickFaq = () => {
    if (faqData) {
      navigate(
        toLocalePath(`/helpInfo?id=${faqData.id}&type=${faqData.type}&name=${encodeURIComponent(faqData.title)}`),
      );
    }
  };

  // 点击返回首页的逻辑
  const hanldeGoHome = () => {
    if (toNoLocalePath(pathname) === '/') {
      dispatch(setNeedCheckedLobby({ need: true }));
    }
    navigate(toLocalePath('/'));
  };

  //点击登录按钮
  const handleGoLogin = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_LoginButton);
    dispatch(setShowSupportNew(false));
    dispatch(setShowInbox(false));
    dispatch(
      setDialogModalVisible({
        ...dialogModalProp,
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
  };

  const handleShowTipsBeforeLeaveRegister = (visible) => {
    dispatch(setShowTipsBeforeLeaveRegister(visible));
  };

  //点击注册按钮
  const handleGoRegister = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_RegisterButton);
    dispatch(setShowSupportNew(false));
    dispatch(setShowInbox(false));
    dispatch(
      setDialogModalVisible({
        ...dialogModalProp,
        shouldCloseOnOverlayClick: false,
        show: DialogModalChildrenKey.SignUp,
        handleOverlayClick: () => {
          handleShowTipsBeforeLeaveRegister(true);
        },
      }),
    );
  };

  //刷新余额
  const hanldeRefurbishBalance = () => {
    setBalanceLoad(true);
    UserinfoReq().then((res) => {
      setBalanceLoad(false);
      if (res.code == 0) {
        dispatch(setUserObj(res.result.user_obj));
      }
    });
  };

  const handleClickVector = () => {
    // if (responsiveMode == ResponsiveMode.mobile) {
    //   navigate(toLocalePath('/email'));
    // } else {
    //   dispatch(setShowSupportNew(false));
    //   dispatch(setShowInbox(true));
    // }
    // navigate(toLocalePath('/email'));

    if (isLogin()) {
      navigate(toLocalePath(`/supportmessage/2`));
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };

  const handleClickManMade = () => {
    dispatch(setShowInbox(false));
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('maximize');
    } else {
      dispatch(setShowSupportNew(true));
    }
  };

  const handleClickManMadeInPage = () => {
    dispatch(setShowInbox(false));
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('maximize');
    } else {
      navigate(toLocalePath(`/supportmessage/1`));
    }
  };

  /**仿wg打开钱包，根据参数跳转充值页面或者提现页面。参数保持和7b一致,walletType=1为充值等于2为提现 */
  const handleFollowWGGoToWallect = (walletType = 1) => {
    if (walletType == 1) {
      dispatch(setShowWGDeposit(true));
    } else {
      const { path } = getLangFromPath(window.location.pathname);
      localStorage.setItem('prev_path', path);
      navigate(toLocalePath(`/pay/withdrawtabpage/1`));
    }
  };

  const handleClickWallet = (withdraw_enable = 1, zIndex = 9999) => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_DepositButton);
    dispatch(setShowSupportNew(false));
    dispatch(setShowInbox(false));

    if (isControllableSite()) {
      handleFollowWGGoToWallect(1);
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.WithdrawDeposit,
          closeWithBack: false,
          walletType: 1,
          withdraw_enable,
          zIndex,
        }),
      );
    }
  };

  const handleGoToWithdraw = (withdraw_enable = 1, zIndex = 9999, walletType = 2) => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_DepositButton);
    dispatch(setShowSupportNew(false));
    dispatch(setShowInbox(false));
    if (isControllableSite()) {
      handleFollowWGGoToWallect(walletType);
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.WithdrawDeposit,
          closeWithBack: false,
          walletType,
          withdraw_enable,
          zIndex,
        }),
      );
    }
  };

  return {
    hanldeGoHome,
    handleGoLogin,
    handleGoRegister,
    handleClickVector,
    handleClickManMade,
    handleClickManMadeInPage,
    handleClickWallet,
    hanldeRefurbishBalance,
    balanceLoad,
    setBalanceLoad,
    handleShowTipsBeforeLeaveRegister,
    handleGoToWithdraw,
    faqData,
    handleClickFaq,
    handleFollowWGGoToWallect,
  };
};

export default useHandleLogic;
