import { useSelector, shallowEqual } from 'react-redux';
import { toLocalePath } from '../../utils/localePath';
import { Link } from 'react-router-dom';
import CarouselLogo from './carouselLogo';
import { cmsImgUrl } from '../../utils/helper';

const RenderContext = ({ className = '', isShowFirstLogo = false }) => {
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  return websiteInitConfig.website_info.website_logo_list &&
    websiteInitConfig.website_info.website_logo_list.length > 0 ? (
    websiteInitConfig.website_info.website_logo_list.length > 1 && isShowFirstLogo == false ? (
      <CarouselLogo domainList={websiteInitConfig.website_info.website_logo_list} />
    ) : (
      <Link to={toLocalePath('/')}>
        <img
          className={`h-[.88rem] ${className}`}
          src={cmsImgUrl(websiteInitConfig.website_info.website_logo_list[0].app_logo_path)}
          alt=""
        />
      </Link>
    )
  ) : null;
};

export default function Index({ className = '', isShowFirstLogo = false }) {
  return <RenderContext className={className} isShowFirstLogo={isShowFirstLogo} />;
}
