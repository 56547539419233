import { random, range } from 'lodash';
import img1 from '../assets/img/cash-rain/dollar/m1.png';
import img2 from '../assets/img/cash-rain/dollar/m2.png';
import img3 from '../assets/img/cash-rain/dollar/m3.png';
import img4 from '../assets/img/cash-rain/dollar/m4.png';
import img5 from '../assets/img/cash-rain/dollar/m5.png';
import img6 from '../assets/img/cash-rain/dollar/m6.png';
import img7 from '../assets/img/cash-rain/dollar/m7.png';
import img8 from '../assets/img/cash-rain/dollar/m8.png';
import img9 from '../assets/img/cash-rain/dollar/m9.png';

export class CashRainAnimation {
  #canvasID = 'cash-rain-canvas';
  #imgs = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
  #imgDWidth = 65;
  constructor(
    canvasID = 'cash-rain-canvas',
    numMoney = 150,
    imgDWidth = 65,
    speedOffset = 10,
    speedRange = 20,
    numImages = 8,
    frameRate = 1000 / 30,
    animationLength = 10 * 1000,
    imageHeight = 100,
  ) {
    this.#canvasID = canvasID;
    this.imageHeight = imageHeight;
    this.#imgDWidth = imgDWidth;
    this.width = null;
    this.height = null;
    this.fallingMoney = [];
    this.interval = null;
    this.numMoney = numMoney;
    this.speedOffset = speedOffset;
    this.speedRange = speedRange;
    this.numImages = numImages;
    this.frameRate = frameRate;
    this.animationLength = animationLength;
  }

  resize() {
    this.width = Math.max(document.body.scrollWidth, document.body.offsetWidth);
    this.height = Math.max(document.body.scrollHeight, document.body.offsetHeight);
  }

  initCanvas() {
    const canvas = document.getElementById(this.#canvasID) || document.createElement('canvas');
    canvas.id = this.#canvasID;
    canvas.setAttribute('width', this.width);
    canvas.setAttribute('height', this.height);
    if (!document.getElementById(this.#canvasID)) {
      canvas.className = 'cash-rain';
      document.body.appendChild(canvas);
    }
    this.canvas = canvas;
  }

  init() {
    this.resize();
    this.initCanvas();
  }

  initAnimation() {
    if (!document.getElementById(this.#canvasID)) {
      this.init();
    }
    this.canvasContext = document.getElementById(this.#canvasID).getContext('2d');
    range(this.numMoney).forEach((index) => {
      const isOdd = index % 2 == 1;
      let direction = 0;
      if (isOdd) direction = 1;
      else direction = -1;

      // let money = {
      //   image: new Image(),
      //   x: random(this.width),
      //   y: random(-this.height * 1, -this.imageHeight),
      //   angle: random(2 * Math.PI),
      //   speed: this.speedOffset + random(this.speedRange),
      //   currentFrame: 0,
      //   direction: direction,
      // };

      let money = {
        image: new Image(),
        x: random(this.canvasContext.canvas.offsetWidth),
        y: random(-this.canvasContext.canvas.offsetHeight * 1, -this.imageHeight),
        angle: random(2 * Math.PI),
        speed: this.speedOffset + random(this.speedRange),
        currentFrame: 0,
        direction: direction,
      };

      let imageIndex = random(this.numImages);
      money.image.src = this.#imgs[imageIndex];
      this.fallingMoney.push(money);
    });

    this.interval = setInterval(() => {
      this.draw();
    }, this.frameRate);

    // setTimeout(() => {
    //     this.endAnimation()
    // }, this.animationLength)
  }

  clearWindow() {
    // this.canvasContext.clearRect(0, 0, this.width, this.height);
    this.canvasContext.clearRect(0, 0, this.canvasContext.canvas.offsetWidth, this.canvasContext.canvas.offsetHeight);
  }

  draw() {
    this.clearWindow();
    this.fallingMoney.forEach((money, index) => {
      this.drawRotatedImage(money);
      money.currentFrame += 1;
      money.y += money.speed;
      money.angle += money.direction * 0.1;
      //const radius = money.direction * (10 + (index % 6));
      //money.x += Math.sin((money.currentFrame + index) / (2 * Math.PI)) * radius;
    });
  }

  drawRotatedImage(money) {
    this.canvasContext.save();
    this.canvasContext.translate(money.x, money.y);
    this.canvasContext.rotate(money.angle);
    this.canvasContext.drawImage(
      money.image,
      0,
      0,
      this.#imgDWidth,
      (this.#imgDWidth * money.image.height) / money.image.width,
    );
    this.canvasContext.restore();
  }

  endAnimation() {
    window.clearInterval(this.interval);
    this.fallingMoney = [];
    this.canvas && this.canvas.remove();
  }
}
