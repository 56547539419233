import { useTranslation } from 'react-i18next';
import Input from '../../../input';
import Icon, { IconName } from '../../../icon';
import { isUseLayoutMobile, isControllableSite } from '../../../../utils/helper';

export default function Index({
  className,
  iconClassName,
  innerClassName,
  topClassName,
  imgClassName,
  captchaInfo,
  onChange,
  handleReReqCaptcha,
  value,
  inputClassName,
  onBlur = () => {},
  onFocus = () => {},
}) {
  const { t } = useTranslation();
  return captchaInfo ? (
    <div className={className}>
      <Input
        onBlur={onBlur}
        onFocus={onFocus}
        leftInner={
          <div className="flex items-center">
            <span className={`w-[.48rem] h-[.48rem] block mr-[.2rem] text-[var(--colour-38)] ${iconClassName}`}>
              {isUseLayoutMobile() ? (
                <span>
                  <Icon name={IconName.RegisterCode2} />
                </span>
              ) : (
                <Icon name={IconName.RegisterCode} />
              )}
            </span>
          </div>
        }
        value={value}
        onChange={onChange}
        type="text"
        placeholder={t('login-signup.fill_captcha')}
        innerClassName={`!border-transparent ${innerClassName}`}
        inputClassName={inputClassName}
        topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)] ${topClassName}`}
        rightInner={
          <div>
            <img
              src={captchaInfo.data}
              alt="captcha"
              onClick={handleReReqCaptcha}
              className={`cursor-pointer w-[2rem] ${imgClassName}`}
            />
          </div>
        }
      />
    </div>
  ) : null;
}
