// {
//   "type": "rank_reward", // VIP 升级
//   "status": 1, // 0:无效 1:未领取 2:已领取
//   "time": "", // 可领取奖励时间
//   "id": 41478, // 可领取奖励记录ID
//   "money": 10100000 // 可领取奖励额度
// },
// {
//   "type": "weekly_reward", // 周俸
//   "status": 0, // 0:无效 1:未领取 2:已领取
//   "time": 1728382583, // 可领取时间
//   "id": 0, // 可领取奖励记录ID
//   "money": 1000000 // 可领取奖励额度
// },
// {
//   "type": "monthly_reward", // 月俸
//   "status": 1, // 0:无效 1:未领取 2:已领取
//   "time": 1729530000, // 可领取奖励时间
//   "id": 44140, // 可领取奖励记录ID
//   "money": 6400000 // 可领取奖励额度
// }

import { useTranslation } from 'react-i18next';
import ButtonBox from './buttonBox';

const ButtonClaimLevel = ({ data, handleClaimLevel, elementId }) => {
  const { t } = useTranslation();

  // data = { status: 1 }
  const status = data?.status;

  const getButtonData = () => {
    const blank = {
      isEnable: false,
      text: '',
      onClick: () => {},
      elementId: '',
    };

    if (data) {
      switch (status) {
        case 0: {
          return {
            ...blank,
            text: t('vip.Level_too_low'),
          };
        }

        case 1: {
          return {
            isEnable: true,
            text: <span className="text-[var(--colour-2)]">{t('vip.claim_level_bonus')}</span>,
            onClick: handleClaimLevel,
            elementId,
          };
        }

        case 2: {
          return {
            isEnable: false,
            text: <span className="text-[var(--colour-2)]">{t('vip.received')}</span>,
            onClick: () => {},
            elementId,
          };
        }

        default: {
          return blank;
        }
      }
    } else {
      return {
        ...blank,
        text: t('vip.Level_too_low'),
      };
    }
  };

  console.log(data, getButtonData());

  return (
    <ButtonBox
      isEnable={getButtonData().isEnable}
      text={getButtonData().text}
      onClick={getButtonData().onClick}
      elementId={getButtonData().elementId}
    />
  );
};

export default ButtonClaimLevel;
