import cn from 'classnames';
import { settleButtonType } from '../../../helper/typeList';
import CountdownBox from '../../../../../components/countdownBox';
import LimitClickWrapper from '../../../../../components/limitClickWrapper';
import { useTranslation } from 'react-i18next';
import { isValidMillisecondTimestamp } from '../../../../../utils/helper';

const SettleButton = ({ type, onClick, claimStartTime, onComplete = () => {} }) => {
  const { t } = useTranslation();

  const getText = () => {
    switch (type) {
      case settleButtonType.claimable:
      case settleButtonType.notYet:
        return t('mystery_bonus.get');
      case settleButtonType.received:
        return t('mystery_bonus.received');
      case settleButtonType.expired:
      case settleButtonType.notAttend:
        return t('mystery_bonus.expired');
      case settleButtonType.pending:
        return t('mystery_bonus.pending');
      default:
        return '';
    }
  };

  const renderer = ({ formatted, completed }) => {
    // console.log('completed', completed);

    return (
      <div>
        {Number(formatted.days) > 0 && <span>{formatted.days} Days </span>}
        <span>{formatted.hours}:</span>
        <span>{formatted.minutes}:</span>
        <span>{formatted.seconds}</span>
      </div>
    );
  };

  const isBlue = [settleButtonType.claimable, settleButtonType.pending, settleButtonType.notYet].includes(type);
  const isPointer = [settleButtonType.claimable].includes(type);
  return (
    <LimitClickWrapper
      className={cn(
        'w-[2.68rem] h-[.7rem] rounded-[.1rem] flex items-center justify-center text-[.28rem] font-[500] text-[var(--colour-2)]',
        {
          'bg-[var(--colour-1)]': isBlue,
          'bg-[var(--colour-42)]': !isBlue,
          'cursor-pointer': isPointer,
          'cursor-not-allowed': !isPointer,
        },
      )}
      onClick={onClick}
      id="mystery_bonus_claim_button"
    >
      {type == settleButtonType.notYet ? (
        <div className="leading-[1] text-center">
          <div className="">{getText(type)}</div>
          {isValidMillisecondTimestamp(claimStartTime * 1000) && (
            <div>
              <CountdownBox date={claimStartTime * 1000} onComplete={onComplete} renderer={renderer} />
            </div>
          )}
        </div>
      ) : (
        getText(type)
      )}
    </LimitClickWrapper>
  );
};

const SettleInfos = ({ type, onClick, claimStartTime, onComplete = () => {} }) => {
  return (
    // <div className="p-[.32rem_0] flex justify-center border-t border-t-[var(--colour-6)] mx-[.2rem]">
    <div className="p-[.32rem_0] flex justify-center mx-[.2rem]">
      <SettleButton type={type} onClick={onClick} claimStartTime={claimStartTime} onComplete={onComplete} />
    </div>
  );
};

export default SettleInfos;
