import { useTranslation } from 'react-i18next';
import DataTable from '../dataTable';
import cn from 'classnames';
import { getShowMoney, getUserPhoto } from '../../utils/helper';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
import ScrollWrapper from '../scrollWrapper';
import NoData from '../noData';

const Money = ({ money = 0 }) => {
  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: get(state, 'app.websiteInitConfig.website_info.currency_symbol') || '',
    }),
    shallowEqual,
  );

  return (
    <div className={cn('flex items-center text-[.24rem]', {})}>
      <span>{currency_symbol}</span>
      <span>{getShowMoney(money)}</span>
    </div>
  );
};

const UserName = ({ nickname, iconNum = 1 }) => {
  return nickname ? (
    <div className={cn('flex items-center space-x-[.16rem]', {})}>
      <img src={getUserPhoto(iconNum)} className="w-[.4rem] h-[.4rem]" />
      <span>{nickname}</span>
    </div>
  ) : (
    '--'
  );
};

const Rank = ({ rankNum, isTop3 = false }) => {
  const imgList = ['/static/img/tour/cup1.png', '/static/img/tour/cup2.png', '/static/img/tour/cup3.png'];
  return isTop3 ? (
    <>
      <img src={imgList[Number(rankNum) - 1]} className="w-auto" />
    </>
  ) : (
    <span className={cn('', {})}>{rankNum}</span>
  );
};

const Board = ({ list = [] }) => {
  const { t } = useTranslation();

  const gridLineCss =
    'w-full grid items-center justify-center grid-cols-[.64rem_minmax(2.6rem,_1fr)_minmax(1.8rem,_1fr)]';

  return (
    <ScrollWrapper className={'p-[.1rem] bg-[var(--colour-34)] rounded-[.12rem] h-[4rem]'} mr={false}>
      <DataTable
        tableClassName=""
        body={{
          className: 'font-[400]',
          list: list.map((item, index) => ({
            className: cn(`${gridLineCss} h-[.8rem]`, {
              'border-t border-t-[var(--colour-6)]': index > 0,
              'text-[var(--colour-26)]': [1, 2, 3].includes(Number(item?.rank)),
              'text-[var(--colour-38)]': ![1, 2, 3].includes(Number(item?.rank)),
            }),
            list: [
              {
                content: <Rank rankNum={item?.rank} isTop3={[1, 2, 3].includes(Number(item?.rank))} />,
                className: 'justify-self-center',
              },
              {
                content: <UserName nickname={item?.nickname} iconNum={item?.icon} />,
                className: '',
              },
              {
                content: <Money money={item?.prize} />,
                className: 'justify-self-end',
              },
            ],
          })),
        }}
        noDataContent={<NoData className="py-[1.16rem]" />}
      />
    </ScrollWrapper>
  );
};

export default Board;
