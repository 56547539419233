import MultipleRowsSwiper from '../../../../../components/multipleRowsSwiper';
import GameItem from '../../../../../components/gameItem';
import { useBatchLoading } from '../../../../../hooks/useBatchLoading';

const GameList = ({
  list,
  gap,
  row,
  column,
  currentPage,
  isShowLoadMore = false,
  paginatedList = [],
  swiperAnimation = true,
  setCurrentPage = () => {},
}) => {
  return (
    <div className="relative">
      {list.length === 0 ? null : (
        <>
          <MultipleRowsSwiper
            list={list}
            gap={gap}
            rows={row}
            column={column}
            currentPage={currentPage}
            disableSwiping={!isShowLoadMore}
            swiperAnimation={swiperAnimation}
            setCurrentPage={setCurrentPage}
          />

          {isShowLoadMore ? <CategoryGamelistWithLoadMore list={paginatedList} /> : null}
        </>
      )}
    </div>
  );
};

const CategoryGamelistWithLoadMore = ({ list = [] }) => {
  const { displayedItems } = useBatchLoading(list, { batchSize: 50, interval: 2000 });

  return (
    <div className="grid grid-cols-4 gap-[.22rem] mt-[.22rem]">
      {displayedItems && displayedItems.length
        ? displayedItems.map((item, index) => {
            return (
              <div className="content-visibility-card" key={index}>
                <GameItem key={index} model={item} />
              </div>
            );
          })
        : null}
    </div>
  );
};

export default GameList;
