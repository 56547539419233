import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ElementWithLoading from '../../elementWithLoading';
import Icon, { IconName } from '../../icon';
import { useNavigate } from 'react-router-dom';
import DialogModal from '../../dialogModal/index';
import ForgotPassword from '../forgotPassword';
import cn from 'classnames';
import Input from '../../input';
import LimitClickWrapper from '../../limitClickWrapper';
import LoginThirdParty from '../components/loginThirdParty';
import InputByPhoneOrEmail, { useCaseList } from '../inputByPhoneOrEmail';
import TocTip from '../tocTip';
import { TwoFa } from '../../dialogModal/TwoFa';
import ExtraUserInfo from '../extraUserInfo';
import Enter2FACode from '../../enter2FACode';
import useLogin from '../../../hooks/useLogin';
import CaptchaInput from './captchaInput';
import { TextPlaces, UserCache, checkEmail, isUseLayoutMobile, isControllableSite } from '../../../utils/helper';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import useHandleLogic from '../../../hooks/useHandleLogic';
import OnlineService from '../onlineService';
import InputErrorTips from '../../inputErrorTips';

const LoginMobilePart = ({ handleClose, changeType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { inviteCode, osType, advertisingId, loginStatus, dialogModalProp } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.inviteCode', ''),
      osType: get(state, 'user.osType', ''),
      advertisingId: get(state, 'user.advertisingId', ''),
      loginStatus: state.app.loginStatus,
      dialogModalProp: state.app.dialogModalProp,
    }),
    shallowEqual,
  );

  const {
    requesting,
    uuIdentity,
    isRememberPwd,
    loginType,
    captchaInitConfig,
    setRequesting,
    formData,
    is2FaOpen,
    setIs2FaOpen,
    isForgotPasswordOpen,
    setIsForgotPasswordOpen,
    handleLoginReq,
    setLoginType,
    handleAnyLoginReq,
    setFormData,
    setIsRememberPwd,
    handleReReqCaptcha,
    handleSubmit2FaCode,
  } = useLogin();
  const { handleClickManMade } = useHandleLogic();

  useEffect(() => {
    handleReReqCaptcha();
    setLoginType(UserCache.LoginMethodValue.Any);
  }, []);

  const [showInput1Error, setShowInput1Error] = useState(false);
  const [showInput2Error, setShowInput2Error] = useState(false);

  // 登录按钮事件
  const handleSubmit = () => {
    if (!formData.account) {
      return toast.info(t('1008008'), {
        containerId: 'global',
      });
    }
    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }
    if (captchaInitConfig?.user_login_validation_mode == 'image') {
      if (formData.cryCode === '') {
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    try {
      setRequesting(true);
      handleAnyLoginReq();
    } catch (error) {
      console.log('eerror', error);
      setRequesting(false);
    }
  };

  const handleCheckInput2 = (value) => {
    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(value)) {
      setShowInput2Error(true);
    } else {
      setShowInput2Error(false);
    }
  };

  const handleCheckEmailOrPhone = (value) => {
    if (value == '') {
      setShowInput1Error(true);
    } else {
      setShowInput1Error(false);
    }
  };

  const handleInput1Blur = (evt) => {
    handleCheckEmailOrPhone(evt.target.value);
  };

  const handleCheckForm = () => {
    let returnValue = true;
    if (formData.account == '') {
      setShowInput1Error(true);
      returnValue = false;
    } else {
      setShowInput1Error(false);
    }

    const regex = /^[a-zA-Z0-9]{8,24}$/;
    if (!regex.test(formData.password)) {
      setShowInput2Error(true);
      returnValue = false;
    } else {
      setShowInput2Error(false);
    }

    if (showInput1Error || showInput2Error) {
      returnValue = false;
    }

    return returnValue;
  };

  const handleInput2Focus = (evt) => {
    handleCheckInput2(evt.target.value);
  };

  return (
    <div className="flex flex-col">
      <div className={cn('font-[700] text-[var(--colour-48)] text-left text-[.32rem] leading-[.64rem]')}>
        {t('home.login')}
      </div>

      <div className="mt-[.4rem]">
        <Input
          onBlur={handleInput1Blur}
          leftOuter={
            <div className="flex items-center">
              <span className="w-[.48rem] h-[.48rem] mr-[.24rem] ml-[.28rem] block text-[var(--colour-37)]">
                {/* {isUseLayoutMobile() ? (
                  <span>
                    <Icon name={IconName.Phone2} />
                  </span>
                ) : (
                  <Icon name={IconName.LoginAccount} />
                )} */}
                <Icon name={IconName.LoginAccount} />
              </span>
            </div>
          }
          type="text"
          value={formData.account}
          onChange={(ev) => {
            const val = ev.target.value;
            setFormData({ ...formData, account: val });
            handleCheckEmailOrPhone(val);
          }}
          placeholder={`${t('login-signup.phone')}/${t('login-signup.email')}/${t('login-signup.Username')}`}
          innerClassName="!border-transparent !pl-[0] !h-[.7rem]"
          topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
        />
        <InputErrorTips showTips={showInput1Error} tipsType={1} />
      </div>
      <div className="mt-[.1rem]">
        <Input
          onFocus={handleInput2Focus}
          leftOuter={
            <div className="flex items-center">
              <span className="block mr-[.24rem] w-[.48rem] h-[.48rem] ml-[.28rem] text-[var(--colour-37)]">
                <Icon name={IconName.LoginPwd} />
              </span>
            </div>
          }
          type="password"
          value={formData.password}
          onChange={(ev) => {
            let val = TextPlaces(ev.target.value, 24);
            setFormData({ ...formData, password: val });
            handleCheckInput2(val);
          }}
          placeholder={t('login-signup.fill_in_the_password')}
          innerClassName="!border-transparent !pl-[0] !h-[.7rem]"
          topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
        />
        <InputErrorTips showTips={showInput2Error} tipsType={2} />
      </div>

      {captchaInitConfig?.user_login_validation_mode ==
      'geetest' ? null : captchaInitConfig?.user_login_validation_mode == 'image' ? (
        <CaptchaInput
          className="mt-[.1rem]"
          iconClassName="mr-[.26rem]"
          innerClassName="!h-[.7rem]"
          topClassName="!border-solid"
          imgClassName="w-[1.7rem] ml-[.08rem]"
          captchaInfo={captchaInitConfig}
          value={formData.cryCode}
          onChange={(ev) => {
            setFormData({ ...formData, cryCode: ev.target.value });
          }}
          handleReReqCaptcha={handleReReqCaptcha}
        />
      ) : null}

      <div className="flex items-center justify-between mt-[.1rem]">
        <div className="flex items-center">
          <div
            className={`active:scale-[.95] transition-all select-none w-[.36rem] h-[.36rem] flex items-center justify-center border border-[var(--colour-6)] cursor-pointer rounded-[.08rem] ${
              isRememberPwd == '2' ? 'bg-[var(--colour-1)]' : 'bg-[var(--colour-42)]'
            }`}
            onClick={() => setIsRememberPwd(isRememberPwd == '1' ? '2' : '1')}
          >
            {isRememberPwd == '2' ? (
              <span className="w-[.24rem] h-[.16rem] text-[var(--colour-2)]">
                <Icon name={IconName.Checked2} />
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="flex-1 text-left font-[700] text-[var(--colour-40)] ml-[.24rem] text-[.28rem]">
            {t('login-signup.remember_me')}
          </div>
        </div>
        <div className="text-right">
          <div
            className={`font-[700] text-[.28rem] cursor-pointer active:scale-[.95] transition-all select-none text-[var(--colour-41)]`}
            onClick={() => {
              setIsForgotPasswordOpen(true);
            }}
          >
            {t('login-signup.forgot')}?
          </div>
        </div>
      </div>

      <LimitClickWrapper
        className={cn(
          'active:scale-[.95] text-[var(--colour-2)] bg-[var(--colour-1)] transition-all select-none flex flex-row px-[.34rem] h-[.8rem] mt-[.24rem] items-center justify-center rounded-normal',
        )}
        onClick={() => {
          if (!handleCheckForm()) {
            return;
          }
          if (!requesting) {
            handleSubmit();
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_LogPage_Login);
          }
        }}
      >
        <ElementWithLoading
          isLoading={requesting}
          normalEl={<div className="font-[700] text-[.32rem]">{t('home.login')}</div>}
        />
      </LimitClickWrapper>

      <div className="flex items-center mt-[.24rem] mb-[.04rem]">
        <div className="font-[500] text-[var(--colour-41)] leading-[.34rem] mr-[.24rem] text-[.24rem]">
          {t("login-signup.don't_have")}
        </div>
        <div
          onClick={() => {
            changeType();
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Popup_Click_LogPage_Register);
          }}
          className={`cursor-pointer text-[var(--colour-41)] font-[700] text-[.28rem] leading-[.34rem]`}
        >
          {t('login-signup.create')}
        </div>
      </div>
      <LoginThirdParty />

      <OnlineService click={handleClose} />

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            zIndex: 100000,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isForgotPasswordOpen}
        handleClose={() => {
          setIsForgotPasswordOpen(false);
        }}
      >
        <ForgotPassword
          handleClose={() => {
            setIsForgotPasswordOpen(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIs2FaOpen(false);
        }}
        style={{
          overlay: {
            zIndex: 999999,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={is2FaOpen}
      >
        <Enter2FACode
          handleClose={() => setIs2FaOpen(false)}
          onClickButton={handleSubmit2FaCode}
          uuIdentity={uuIdentity}
        />
      </DialogModal>
    </div>
  );
};

const LoginPage1 = ({ handleClose, changeType }) => {
  return (
    <div className="flex flex-col">
      <LoginMobilePart handleClose={handleClose} changeType={changeType} />
    </div>
  );
};

export default LoginPage1;
