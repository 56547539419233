import BigWin from '../../../assets/img/reward-bg.png';
import { useSelector, shallowEqual } from 'react-redux';

export default function Index({ children }) {
  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );
  return (
    <div className="w-[5.08rem] select-none relative">
      <img src={BigWin} alt="" className="not-drag" />
      <div className="text-[.44rem] font-[700] min-w-[6.62rem] flex items-center justify-center select-none absolute abs-center mt-[.06rem]">
        <span className="text-[var(--colour-43)]">{websiteInfo.currency_symbol || ''}</span>
        <span className="text-[var(--colour-43)]">{children}</span>
      </div>
    </div>
  );
}
