import { useTranslation } from 'react-i18next';

const BonusAmount = ({ amount }) => {
  const { t } = useTranslation();

  return (
    <div className="text-[var(--colour-14)] text-[.28rem] font-[500]">
      {t('promotion.bonus')} <span className="text-[var(--colour-26)]">{amount}</span>
    </div>
  );
};

export default BonusAmount;
