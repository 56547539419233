import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDivSize from '../../hooks/useDivSize';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';

const PrizePool = ({ bgImgUrl = '', money = '', onClick = () => {} }) => {
  const { t } = useTranslation();

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: get(state, 'app.websiteInitConfig.website_info.currency_symbol') || '',
    }),
    shallowEqual,
  );

  const ref = useRef(null);

  const { width } = useDivSize(ref);

  return (
    <div
      className={`w-full bg-[length:100%_100%] bg-no-repeat cursor-pointer`}
      style={{
        backgroundImage: `url(${bgImgUrl})`,
        height: `${width / 4.02}px`,
      }}
      onClick={onClick}
      ref={ref}
    >
      <div className="flex justify-end w-full p-[.2rem_.16rem_0_0]">
        <div className="flex flex-col items-center font-[800] min-w-[3.2rem] text-[.44rem]">
          <div className="text-[rgba(225,24,27,1)] flex items-center h-[.54rem] whitespace-nowrap ">
            {t('tour.pricePool')}
          </div>
          <div
            className="text-[#000] w-full flex items-center justify-centernh-[.56rem]"
            style={{
              background:
                'linear-gradient(90deg, rgba(255, 223, 37, 0) 0%, #FFDF25 30.45%, #FFDF25 69.56%, rgba(255, 223, 37, 0) 100%)',
            }}
          >
            {currency_symbol}
            {money}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrizePool;
