import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const [count, setCount] = useState(3);

  let intv;

  const onClick = () => {
    navigate(toLocalePath('/'), { replace: true });
  };

  useEffect(() => {
    intv = setInterval(() => {
      setCount((prev) => {
        return prev - 1;
      });
    }, 1000);

    return () => {
      window.clearInterval(intv);
    };
  }, []);

  return (
    <div className="container">
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-[var(--colour-48)] text-[.32rem] font-[700] text-center mt-[-5rem]">
          {t('home.not_exist')}
        </div>

        <div
          className="flex items-center justify-center rounded-normal cursor-pointer text-[.32rem] font-[700] bg-[var(--colour-1)] mt-[.68rem] text-[var(--colour-2)] w-[6.3rem] h-[.92rem] mx-auto"
          onClick={onClick}
        >
          {t('home.confirm')}
          {count > 0 ? <span className="ml-[.32rem]">{count}</span> : ''}
        </div>
      </div>
    </div>
  );
};

export default NTemplate001;
