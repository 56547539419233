import cn from 'classnames';

export default function ItemBox2({
  selected = false,
  click = () => {},
  bubbleBoxInner = null,
  children,
  className = '',
}) {
  return (
    <div
      onClick={click}
      className={cn(
        `w-[2.12rem] h-[.76rem] rounded-[.12rem] flex items-center justify-center text-[.28rem] font-[500] relative ${className}`,
        {
          'bg-[var(--colour-51)] text-[var(--colour-38)]': !selected,
          'colour-87 border border-[var(--colour-67)] text-[var(--colour-2)]': selected,
        },
      )}
    >
      {children}
      {bubbleBoxInner ? (
        <div className="absolute right-[-0.01rem] top-[-0.12rem] rounded-[.06rem] bg-[var(--colour-36)] min-w-[1.14rem] px-[.06rem] text-[.2rem] font-[500] leading-[.26rem] text-[var(--colour-10)] text-center whitespace-nowrap">
          {bubbleBoxInner}
        </div>
      ) : null}
    </div>
  );
}
