const CONFIG = {
  CHECK_DURATION: 10000, // 检查间隔时间，10秒
  MAX_RETRY_COUNT: 3, // 最大重试次数
  REQUEST_TIMEOUT: 5000, // 请求超时时间，5秒
};

// 存储上一次获取到的script地址
let lastSrcs = null;

// 匹配script标签的正则
const scriptReg = /<script.*src=["'](?<src>[^"']+)/gm;

/**
 * 获取HTML中的所有脚本地址
 * @returns {Promise<string[]>} 脚本地址数组
 */
const extractNewScripts = async () => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), CONFIG.REQUEST_TIMEOUT);

  try {
    const response = await fetch(`/?_timestamp=${Date.now()}`, {
      signal: controller.signal,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const html = await response.text();
    scriptReg.lastIndex = 0;
    const result = [];
    let match;

    while ((match = scriptReg.exec(html))) {
      result.push(match.groups?.src);
    }

    return result;
  } finally {
    clearTimeout(timeoutId);
  }
};

/**
 * 检查是否需要更新
 * @returns {Promise<boolean>} 是否需要更新
 */
const needUpdate = async () => {
  const newScripts = await extractNewScripts();
  // 首次运行，保存当前脚本列表
  if (!lastSrcs) {
    lastSrcs = newScripts;
    return false;
  }

  // 比较脚本列表是否有变化
  const hasChanged = lastSrcs.length !== newScripts.length || lastSrcs.some((src, index) => src !== newScripts[index]);

  // 更新lastSrcs为最新的脚本列表
  lastSrcs = newScripts;

  return hasChanged;
};

/**
 * 自动刷新功能
 * @returns {Function} 清理函数
 */
// export const autoRefresh = () => {
//   let timer = null;
//   let retryCount = 0;

//   const check = async () => {
//     try {
//       const willUpdate = await needUpdate();

//       if (willUpdate) {
//         return window.location.reload();
//       }

//       // 重置重试次数
//       retryCount = 0;
//     } catch (error) {
//       console.error('[自动更新检查失败]:', error);

//       // 如果失败次数超过最大重试次数，停止检查
//       if (++retryCount >= CONFIG.MAX_RETRY_COUNT) {
//         return console.warn('[自动更新]检查失败次数过多，停止检查');
//       }
//     }

//     // 继续下一次检查
//     timer = setTimeout(check, CONFIG.CHECK_DURATION);
//   };

//   check();

//   return () => {
//     if (timer) {
//       clearTimeout(timer);
//       timer = null;
//     }
//   };
// };

export const autoRefresh = () => {
  const check = async () => {
    try {
      const willUpdate = await needUpdate();
      if (willUpdate) {
        return window.location.reload();
      }
    } catch (error) {
      console.error('[自动更新检查失败]:', error);
    }
  };

  check();
};

/**
 * 手动检查更新
 * @returns {Promise<boolean>} 是否有更新
 */
export const checkUpdate = async () => {
  try {
    return await needUpdate();
  } catch (error) {
    console.error('[手动检查更新失败]:', error);
    return false;
  }
};
