// import PopBg from '../../../components/popBg';
// import PopBgFullScreen from '../../../components/popBgFullScreen';
import PopBgFullScreen2 from '../../../components/popBgFullScreen2';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState, useRef, forwardRef } from 'react';
import cn from 'classnames';
// import InviteLink from './inviteLink';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setRefetchActivityData,
} from '../../../../../redux/reducers/appSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getPercentage,
  getReferralLink,
  getShareIconName,
  getShowMoney,
  isShareString,
} from '../../../../../utils/helper';
// import StepBoxListResponsive from '../../../../stepBoxListResponsive';
import StepBoxList from '../../../../stepBoxList/index';
// import BottomBox from './bottomBox';
import { getCurrentTaskFinishPeopleNum, getFinishedPeopleTotalNum, isRunningTaskExit } from './helper';
import DialogModal from '../../../index';
import ScrollWrapper from '../../../../scrollWrapper';
import Close from '../../../../close2';
import Icon, { IconName } from '../../../../icon';
// import Sticky from 'react-sticky-el';
// import DownloadManager from '../../../manager/downloadManager';
import BottomDownload from '../../../downLoadBtn';
import { useDeepCompareEffect } from 'ahooks';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import ShareManager from '../../../../../manager/shareManager';

const fakeStepList = Array(16)
  .fill()
  .map((_, index) => ({
    text1: `${index + 1}people`,
    text2: '2000',
    mode: ['default', 'opened', 'done'][Math.floor(Math.random() * 3)],
    progressPercent: Math.floor(Math.random() * 100),
    onClick: () => {
      alert(index);
    },
  }))
  .concat([
    {
      text1: 'Coming Soon',
      text2: '',
      mode: 'default',
      progressPercent: 0,
      onClick: null,
    },
  ]);

// const Popup = ({ children, handleClose, allowScroll, isShowCloseBtn, className = '', viewportClassName = '' }) => {
//   return (
//     <PopBgFullScreen2
//       viewportClassName={viewportClassName}
//       handleClose={handleClose}
//       allowScroll={allowScroll}
//       isShowCloseBtn={isShowCloseBtn}
//       className={className}
//     >
//       {children}
//     </PopBgFullScreen2>
//   );
// };

const RuleDescription = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <u
      onClick={onClick}
      className="flex items-center justify-end space-x-[.12rem] text-[.24rem] font-[500] cursor-pointer text-[var(--colour-26)] "
    >
      <div>{t('referral.rule_description')}</div>
      <div className="w-[.32rem] h-[.32rem] text-[var(--colour-7)]">
        <Icon name={IconName.Explain} />
      </div>
    </u>
  );
};

const ConditionList = ({ list = [] }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-[.24rem] text-[var(--colour-38)] font-[500] leading-[.3rem]">
        <p>{t('referral.what_is_number1')}</p>
        <p>{t('referral.what_is_number2')}</p>
      </div>

      {list.length > 0 && (
        <div className="mt-[.2rem] grid grid-cols-2 gap-[.1rem]">
          {list.map((item, index) => (
            <div
              key={index}
              className="w-[3.06rem] h-[.54rem] bg-[var(--colour-54)] rounded-[.16rem] border border-[var(--colour-86)] flex items-center justify-center text-center"
            >
              {item.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ValidPeople = ({ isAnyRunningTask, peopleNum }) => {
  console.log('isAnyRunningTask, peopleNum', isAnyRunningTask, peopleNum);
  const { t } = useTranslation();

  return (
    <div className="text-[.24rem] font-[500] text-[var(--colour-38)]">
      {isAnyRunningTask ? (
        <Trans
          t={t}
          i18nKey="referral.valid_invititation"
          components={[<span className="text-[var(--colour-26)]" key={0}></span>]}
          values={{
            val: peopleNum,
          }}
        />
      ) : (
        t('referral.all_complete')
      )}
    </div>
  );
};

const SocialShare = ({ title, list = [] }) => {
  return (
    <div className="space-y-[.22rem]">
      <div className="h-[.3rem] flex items-center text-[var(--colour-48) text-[.24rem] font-[500]">{title}</div>
      <div className="flex space-x-[.16rem]">{list.map((item) => item)}</div>
    </div>
  );
};

const MyLink = ({ url, onClick }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="text-[var(--colour-38)] text-[.24rem] font-[500]">{url}</div>
      <div className="w-[.32rem] h-[.32rem] cursor-pointer text-[var(--colour-38)]" onClick={onClick}>
        <Icon name={IconName.Copy} className="cursor-pointer" />
      </div>
    </div>
  );
};

export default function Index({ data, handleClose = () => {}, isShowCloseBtn, showStyle }) {
  console.log('data', data, data?.task_config, isRunningTaskExit(data?.task_config));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currency_symbol, defaultLang, userObj, websiteInitConfig } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      defaultLang: state.user.defaultLang,
      userObj: state.user.userObj,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const handleShare = (res) => {
    let str = res.toLowerCase();
    return ShareManager.share(str, shareLink, websiteInitConfig?.website_info?.website_desc);
  };

  const shareNames = {
    WhatsappShare: 'WhatsApp',
    FacebookShare: 'Facebook',
    TelegramShare: 'Telegram',
    TwitterShare: 'Twitter',
    MoreShare: 'More',
  };

  useEffect(() => {
    const methodList = websiteInitConfig?.website_info?.share_method;
    if (methodList && methodList.length > 0) {
      const _list = methodList.map((item, index) => {
        return isShareString(item) && !ShareManager.showMoreShare ? null : (
          <div
            key={index}
            onClick={() => handleShare(item)}
            className="flex flex-col items-center space-y-[.1rem] cursor-pointer"
          >
            <div className={cn('flex justify-center items-center active:scale-[.95] w-[.68rem] h-[.68rem]')}>
              <Icon name={getShareIconName(item)} />
            </div>
          </div>
        );
      });

      setShareList(_list);
    }
  }, [websiteInitConfig]);

  useDeepCompareEffect(() => {
    setShareLink(getReferralLink());
  }, [userObj]);

  // const { inviteCode, defaultLang, referral_url, websiteInitConfig, userObj } = useSelector(
  //   (state) => ({
  //     inviteCode: get(state, 'user.userObj.invite_code', ''),
  //     defaultLang: state.user.defaultLang,
  //     referral_url: get(state, 'user.userObj.referral_url', ''),
  //     websiteInitConfig: state.app.websiteInitConfig,
  //     userObj: state.user.userObj,
  //   }),
  //   shallowEqual,
  // );

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });
  const [uptoAmount, setUptoAmount] = useState(0);
  const [textList, setTextList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [peopleNum, setPeopleNum] = useState(0);
  const [directReportNum, setDirectReportNum] = useState(0);
  const [isShowRule, setIsShowRule] = useState(false);

  const [shareList, setShareList] = useState([]);
  const [shareLink, setShareLink] = useState('');

  // const updateData = () => {
  //   GetReferralInviteList().then((res) => {
  //     if (res.code === 0) {
  //       const result = res.result;
  //       const _directReport = result.bonus.finished_num || 0;
  //       setDirectReport(_directReport);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   updateData();
  // }, []);

  const getPeopleText = (isExpired, peopleNum) => {
    return isExpired ? (
      <div className="text-[var(--colour-38)] text-[.2rem] font-[500]">{t('giftMoney_filterStatus.expired')}</div>
    ) : (
      <div className="text-[var(--colour-38)] text-[.2rem] font-[500] leading-[1]">
        <span className="text-[var(--colour-26)]">{peopleNum}</span> {t('referral.people')}
      </div>
    );
  };

  useEffect(() => {
    if (data) {
      const taskList = data?.task_config || [];

      setPeopleNum(getCurrentTaskFinishPeopleNum(taskList));
      setDirectReportNum(getFinishedPeopleTotalNum(taskList));
      setUptoAmount(
        taskList.reduce((acc, curr) => {
          return acc + Number(curr?.reward_amount || 0);
        }, 0),
      );

      // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,
      const currentTask = taskList.find((item) => [10, 11].includes(Number(item.task_status)));

      const text1Amount = Number(currentTask?.deposit_amount) || 0;
      const text2Amount = Number(currentTask?.wager_amount) || 0;
      const text3Amount = Number(currentTask?.games_num) || 0;
      const text4Amount = Number(currentTask?.vip_level) || 0;

      const _textList = [
        {
          amount: text1Amount,
          text: (
            <span className="text-[var(--colour-14)] text-[.24rem] font-[500]">
              <Trans
                t={t}
                i18nKey="referral.current_task_total_deposit"
                components={[<span className="text-[var(--colour-26)]" key={0}></span>]}
                values={{
                  val: `${currency_symbol}${getShowMoney(text1Amount)}`,
                }}
              />
            </span>
          ),
        },
        {
          amount: text2Amount,
          text: (
            <span className="text-[var(--colour-14)] text-[.24rem] font-[500]">
              <Trans
                t={t}
                i18nKey="referral.current_task_total_bet"
                components={[<span className="text-[var(--colour-26)]" key={0}></span>]}
                values={{
                  val: `${getShowMoney(text2Amount)}`,
                }}
                key={1}
              />
            </span>
          ),
        },
        {
          amount: text3Amount,
          text: (
            <span className="text-[var(--colour-14)] text-[.24rem] font-[500]">
              <Trans
                t={t}
                i18nKey="referral.current_task_complete_games"
                components={[<span className="text-[var(--colour-26)]" key={0}></span>]}
                values={{
                  val: text3Amount,
                }}
                key={2}
              />
            </span>
          ),
        },
        {
          amount: text4Amount,
          text: (
            <span className="text-[var(--colour-14)] text-[.24rem] font-[500]">
              <Trans
                t={t}
                i18nKey="referral.current_task_reach_vip"
                components={[<span className="text-[var(--colour-26)]" key={0}></span>]}
                values={{
                  val: text4Amount,
                }}
                key={3}
              />
            </span>
          ),
        },
      ].filter((item) => Number(item.amount) > 0);

      setTextList(_textList);

      const _taskList = taskList.map((item, index) => {
        // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,
        const isExpired = item?.task_status == 30;

        const _moneyText = getShowMoney(item?.reward_amount || 0, 2, 2);

        return {
          peopleText: getPeopleText(isExpired, item?.people_num),
          moneyText: (
            <div
              className="text-[.2rem] font-[600] text-[var(--colour-76)] leading-[1] referral_achievement_text_2 break-all text-center w-full"
              data-text={_moneyText}
            >
              {_moneyText}
            </div>
          ),
          mode: getMode(item?.task_status),
          // mode: index % 2 == 1 ? 'opened' : 'default',
          progressPercent:
            currentTask?.tap_position == item?.tap_position
              ? getPercentage(Number(item?.finish_people_num || 0), Number(item?.people_num || 1))
              : 0,
          onClick: null,
        };
      });

      // const comingList = (() => {
      //   const comingCount = _taskList.length % 4 == 0 ? 0 : 4 - (_taskList.length % 4);
      //   return Array(comingCount)
      //     .fill()
      //     .map(() => {
      //       return {
      //         text1: t('referral.coming_soon'),
      //         text2: '',
      //         mode: 'comingSoon',
      //         progressPercent: 0,
      //         onClick: null,
      //       };
      //     });
      // })();

      // setTaskList(_taskList.concat(comingList));
      setTaskList(_taskList);
    }
  }, [data, defaultLang]);

  // const getMode_old = (is_claimed) => {
  //   // 状态 0：不可领奖；1：可领奖；2：已领奖
  //   switch (is_claimed) {
  //     case '0':
  //       return 'default';
  //     case '1':
  //       return 'done';
  //     case '2':
  //       return 'opened';
  //     default:
  //       return 'default';
  //   }
  // };

  const getMode = (taskStatus) => {
    // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,
    switch (taskStatus) {
      case 10:
      case 11:
      case 20:
      case 30:
      case 21:
        return 'default';
      // case 21:
      //   return 'done';
      case 22:
        return 'opened';
      default:
        return 'default';
    }
  };

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  const onCopyLink = () => {
    copy(shareLink);
    toast.success(t('home.toast006'), {
      containerId: 'global',
    });

    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.ReferralBonu_Copy);
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
          },
        }}
        isOpen={isShowRule}
        handleClose={() => setIsShowRule(false)}
      >
        <div className="bg-[var(--colour-35)] w-[6.86rem] h-[9.84rem] px-[.32rem] rounded-normal">
          <div className="flex h-[1.12rem] items-center justify-between">
            <div className="flex items-center space-x-[.1rem]">
              <div
                className="text-[var(--colour-11)] w-[.2rem] h-[.28rem] mr-[.1rem]"
                onClick={() => {
                  setIsShowRule(false);
                }}
              >
                <Icon name={IconName.BackArrow} />
              </div>
              <span className="text-[.32rem] text-[var(--colour-48)]"> {t('referral.rule_description')}</span>
            </div>
            <div className="w-[.72rem] h-[.72rem] flex justify-center items-center rounded-small">
              <Close
                handleClose={() => {
                  setIsShowRule(false);
                }}
              />
            </div>
          </div>
          <div className="w-[6.22rem] h-[1px] bg-[var(--colour-6)]"></div>
          <ScrollWrapper className="mt-[.36rem] w-full h-[8rem] wrap-break-word text-[.28rem] wrap-break-word text-[var(--colour-44)]">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.rule_explain?.activity_rule_explain,
              }}
            ></div>
          </ScrollWrapper>
        </div>
      </DialogModal>

      <PopBgFullScreen2 handleClose={handleClose} title={t('promo.invite_friend_earn')}>
        <div className={cn('w-full p-[.32rem]')}>
          <div className="p-[.32rem] bg-[var(--colour-34)] rounded-[.2rem]">
            <MyLink url={shareLink} onClick={onCopyLink} />
            <div className="w-full h-[1px] mt-[.32rem] bg-[var(--colour-6)]"></div>
            <div className="mt-[.12rem]">
              <SocialShare title={t('promo.quick_share')} list={shareList} />
            </div>
            <div className="w-full h-[1px] mt-[.32rem] bg-[var(--colour-6)]"></div>
            <div className="mt-[.38rem]">
              <ValidPeople peopleNum={peopleNum} isAnyRunningTask={isRunningTaskExit(data?.task_config)} />
            </div>
            <div className="mt-[.28rem]">{<ConditionList list={textList} />}</div>
            <div className="mt-[.32rem] ">
              <RuleDescription
                onClick={() => {
                  setIsShowRule(true);
                }}
              />
            </div>
          </div>

          <div className="mt-[.24rem] p-[.136rem_.26rem_.4rem_.26rem] bg-[var(--colour-34)] rounded-[.2rem]">
            {/* <StepBoxListResponsive list={taskList} /> */}
            <StepBoxList list={taskList} showStyle={showStyle} />
          </div>

          {/* <InviteLink
            money={`${currency_symbol}${getShowMoney(uptoAmount)}`}
            peopleNum={peopleNum}
            directReportNum={directReportNum}
            isAnyRunningTask={isRunningTaskExit(data?.task_config)}
          /> */}

          {/* <Sticky
            scrollElement=".promote-achievement-scroll-box"
            stickyClassName="bg-[var(--colour-35)] abs-x-center-with-translateZ z-[2] px-[.1rem]"
            topOffset={-30}
          >
            <div className="">
              {isRunningTaskExit(data?.task_config) && (
                <>
                  <div className="text-[.24rem] text-[var(--colour-48)] text-center my-[.16rem]">
                    <p>{t('referral.what_is_number1')}</p>
                    <p>{t('referral.what_is_number2')}</p>
                  </div>

                  {textList.length > 0 && (
                    <div className="grid grid-cols-2 gap-[.1rem] text-[.24rem]">
                      {textList.map((item, index) => (
                        <div
                          key={index}
                          className="p-[.1rem] bg-[var(--colour-34)] text-[var(--colour-44)] rounded-normal flex items-center justify-center wrap-break-word"
                        >
                          {item.text}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}

              <p
                onClick={() => {
                  setIsShowRule(true);
                }}
                className="text-[.28rem] cursor-pointer text-[var(--colour-24)] underline underline-offset-[.12rem] text-center m-[.2rem_0_.16rem_0]"
              >
                {t('referral.rule_description')}
              </p>
            </div>
          </Sticky> */}

          {/* <p className="h-[1px] bg-[var(--colour-6)]"></p> */}

          {/* <div className="p-[.4rem_0_.2rem]">
            <StepBoxListResponsive list={taskList} />
          </div> */}
        </div>
      </PopBgFullScreen2>
    </>
  );
}
