import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cn from 'classnames';
import { setDrawerMenuVisible } from '../../redux/reducers/appSlice';
import Icon, { IconName } from '../icon';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import { isControllableSite } from '../../utils/helper';
import { getTemplateId } from '../../utils/helper';

export default function HeaderSidebarSwitch({ className = '', iconClassName = '' }) {
  const dispatch = useDispatch();

  const { drawerMenuVisible } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
    }),
    shallowEqual,
  );

  return (
    <>
      {getTemplateId() == 'd-template003' ? (
        <div
          className={`w-[.38rem] h-[.32rem] cursor-pointer ${className}`}
          onClick={() => {
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_SideMenuButton);
            dispatch(
              setDrawerMenuVisible({
                visible: drawerMenuVisible.visible ? false : true,
                isAnimation: true,
              }),
            );
          }}
        >
          <Icon name={IconName.Menu3}></Icon>
        </div>
      ) : (
        <div
          className={cn(
            `cursor-pointer rounded-normal w-[.48rem] h-[.48rem] flex justify-center items-center ${className}`,
            {
              'bg-[var(--colour-45)]': !isControllableSite(),
            },
          )}
          onClick={() => {
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_SideMenuButton);
            dispatch(
              setDrawerMenuVisible({
                visible: drawerMenuVisible.visible ? false : true,
                isAnimation: true,
              }),
            );
          }}
        >
          {isControllableSite() ? (
            <span
              className={cn(`w-[.4rem] h-[.4rem] text-[var(--colour-70)] ${iconClassName}`, {
                'rotate-180': drawerMenuVisible.visible,
              })}
            >
              {/* <Icon name={IconName.DrawerFlag2} /> */}
              <Icon name={IconName.DrawerFlag3} />
            </span>
          ) : (
            <span
              className={cn('w-[.16rem] h-[.24rem] block transition text-[var(--colour-46)]', {
                'rotate-180': drawerMenuVisible.visible,
              })}
            >
              <Icon name={IconName.DrawerFlag} />
            </span>
          )}
        </div>
      )}
    </>
  );
}
