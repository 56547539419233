import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { GetReVipMyLevelClaim } from '../../../../../../api';
import usePromotionClaimedCount from '../../../../../../hooks/usePromotionClaimedCount';
import { setNeedUpdateBalance } from '../../../../../../redux/reducers/appSlice';
import { toast } from 'react-toastify';
import LimitClickWrapper from '../../../../../../components/limitClickWrapper';
import SvgIcon from '../../../../../../components/svgIcon';
import feiji from '../../../../../../assets/vip1/feiji.svg';
import jiqiren from '../../../../../../assets/vip1/jiqiren.svg';
import lock from '../../../../../../assets/vip1/lock.svg';
import { getShowMoney } from '../../../../../../utils/helper';
import Icon, { IconName } from '../../../../../../components/icon';
import cn from 'classnames';
import { getHourMinute } from './helper';
import RedDot from '../../../../../../components/redDot';

function BonusBtn({ myStatus, day, index2, vipLevel }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-row">
        {vipLevel == 0 && index2 == 0 ? (
          <>
            <div className="flex w-full justify-center items-center z-[2]  mt-[.35rem]">
              <div className="flex w-[.2rem] h-[.24rem]  ">
                <SvgIcon iconId={lock} />
              </div>
              <div className={`flex  ml-[.1rem] font-[500] text-[.24rem] text-[#FFC278]  mt-[.06rem]`}>
                <div className={`text-[#FFC278] `}> {t('vip.locking')}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            {myStatus == 0 || myStatus == -1 ? (
              <>
                <div className="flex w-full justify-center items-center z-[2]  mt-[.35rem]">
                  <div className="flex w-[.2rem] h-[.24rem]  ">
                    <SvgIcon iconId={lock} />
                  </div>
                  <div className={`flex  ml-[.1rem] font-[500]  text-[.24rem] text-[#FFC278]  mt-[.06rem]`}>
                    <div className={`text-[#FFC278] `}> {t('vip.locking')}</div>
                  </div>
                </div>
              </>
            ) : myStatus == 1 ? (
              <>
                <div className="flex  mt-[.2rem] z-[0]">
                  <div className="   w-[2.14rem] h-[.76rem] bg-[var(--colour-1)] absolute  rounded-[0rem_0rem_.24rem_.24rem] z-[0]"></div>
                </div>

                <div className="flex justify-center items-center text-center   mt-[.2rem]  w-full h-[.76rem] z-[10]">
                  <div className={`flex font-[500] text-[.24rem] text-[var(--colour-2)]  `}>{t('vip.receive')}</div>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-center items-center text-center   mt-[.2rem]  w-full h-[.76rem] z-[10]">
                  <div className={`flex font-[500] text-[.24rem] text-[#fff]  `}>
                    {day == '' ? t('vip.received') : day}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

function BonusList({ index, vipLevel, data2, callback, tname, myVip, index2, identity }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currency_code } = useSelector(
    (state) => ({
      currency_code: get(state, 'app.websiteInitConfig.website_info.currency_code') || '',
    }),
    shallowEqual,
  );
  const getTextColor = (vipLevel) => {
    return `w-[2.14rem] h-[.24rem] font-[500] text-[.28rem] justify-center items-center text-center    text-vip-${vipLevel}`;
  };

  const [data, setData] = useState(null);
  const [day1, setDay1] = useState('');
  const [ishongdian1, setIshongdian1] = useState(false);
  const [bouns1, setBouns1] = useState(data2.money);
  const [myStatus, setMyStatus] = useState(data2.status);
  const [showLoading2, setShowLoading2] = useState(true);
  const { InitPromotionClaimedCount } = usePromotionClaimedCount();

  const updataMydata = (datamy) => {
    // datamy.money = 0//@@@@@@@@@@@@
    if (identity == null) {
      setMyStatus(0);
      setDay1('');
      let myData = datamy;
      setBouns1(myData.money);
    } else {
      let myData = datamy;
      setBouns1(myData.money);
      setMyStatus(myData.status);
      //前端伪代码=================

      if (myData.money == 0) {
        setMyStatus(0);
      } else if (vipLevel > myVip) {
        setMyStatus(0);
      } else if (index2 == 0 && vipLevel == 0) {
        setMyStatus(0);
      } else if (myData.status == 1) {
        setMyStatus(1);
      } else {
        // if (myData.status == 0) {
        if (index2 != 0) {
          if (myData.time == '') {
            setMyStatus(0);
          } else {
            setMyStatus(2);
          }
        } else {
          if (myData.status == 2) {
            setMyStatus(2);
          } else {
            if (myData.time == '') {
              setMyStatus(2);
            } else {
              setMyStatus(2);
            }
          }
        }
        if (myData.status == 2) {
          setMyStatus(2);
        }
      }
      // console.log('status:', myStatus, myData.time);
      //前端伪代码====⬆====⬆=====⬆====
      if (myStatus == 0) {
        setIshongdian1(false);
        setDay1('');
      } else if (myStatus == 1) {
        setIshongdian1(true);
        setDay1('');
      } else if (myStatus == 2) {
        setIshongdian1(false);
        if (myData.time != '') {
          if (index2 != 2) {
            if (index2 > 0) {
              let days = Math.floor(myData.time / 86400);
              // console.log('******************************************:', days, index2, myStatus);
              if (days >= 0) {
                if (days == 0) {
                  let d = getHourMinute(myData.time);
                  if (d[0] >= 1) {
                    setDay1('' + d[0] + 'H');
                  } else {
                    if (d[1] <= 1) d[1] = 1;
                    setDay1('' + d[1] + 'M');
                  }
                } else if (days == 1) {
                  setDay1('1 Day');
                } else {
                  setDay1(days + ' D');
                }
              } else if (index2 == 2) {
                setDay1(myData.time);
              }
            } else {
              setDay1(myData.time);
            }
          } else {
            let date = moment.unix(Math.floor(Date.now() / 1000) + myData.time);
            setDay1(date.format('YYYY/MM/DD'));
          }
        } else {
          setDay1('');
        }
      }
    }
  };

  useEffect(() => {
    updataMydata(data2);
  }, [data2, myVip, myStatus, day1, vipLevel]);

  useEffect(() => {
    if (data != null) {
      updataMydata(data);
    }
  }, [data, myVip, myStatus, day1, vipLevel]);

  const lingquFun = (ishongdian, id, e) => {
    if (ishongdian === false) return;
    let params = {
      id: id,
      bonus_type: data2.type,
      index2: index2,
      money: bouns1,
    };
    setShowLoading2(true);

    GetReVipMyLevelClaim(params).then((res) => {
      setShowLoading2(false);
      if (res.code === 0) {
        //前端伪代码
        if (myVip != vipLevel) {
          let d = {
            ...data2,
            status: 0,
            money: bouns1,
            time: '',
          };
          setData(d);
        } else {
          let d = {
            ...data2,
            status: 2,
            money: bouns1,
            time: 0,
          };
          setData(d);
        }
        callback(res, params, e);
        dispatch(setNeedUpdateBalance({ need: true }));
        InitPromotionClaimedCount();
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };
  // 0处理中 1可领取（当为1的时候可以调用领取接口） 2
  return (
    <div className={`flex flex-col justify-center ${myStatus == 1 && 'cursor-pointer'}`}>
      <>
        <div className={`flex flex-row ml-0`}>
          <LimitClickWrapper
            className={` flex w-[2.14rem] h-[3.08rem]    rounded-[.24rem]  ${
              myVip == vipLevel ? 'opacity-[1]' : myStatus == 0 || myStatus == 2 ? 'opacity-[0.5]' : 'opacity-[1]'
            } bg-vip-${vipLevel}`}
            onClick={(e) => {
              lingquFun(ishongdian1, data2.id, e);
            }}
          >
            <div className={`flex flex-col`}>
              {myStatus == 1 ? (
                // setTimeout(() => {
                <RedDot className="flex w-[.32rem] h-[.32rem] absolute ml-[1.92rem] mt-[-.1rem]     z-[1000]" />
              ) : (
                // }, 100)
                <></>
              )}
              <div className=""></div>

              <div className={`flex mt-[.24rem]  ${getTextColor(vipLevel)}`}>{tname}</div>
              <div className={`flex  mt-[.24rem] ${getTextColor(vipLevel)}`}>{t('vip.bonus1')}</div>

              {index2 == 0 ? (
                <>
                  <div className="flex justify-center  mt-[.2rem]">
                    <div className="flex w-[.4rem] h-[.52rem]   ">
                      <SvgIcon iconId={feiji} />
                    </div>
                  </div>
                  {day1 == '' && myStatus != 1 && vipLevel != 0 && vipLevel <= myVip ? (
                    <>
                      <div className="flex absolute   mt-[.72rem] ml-[.56rem]">
                        <div
                          className={cn(
                            'rounded-[50%] h-[1.04rem] w-[1.04rem]    flex items-center justify-center bg-[var(--colour-29)]',
                          )}
                        >
                          <div className="w-[.52rem] h-[.44rem]   text-[var(--colour-2)]">
                            <Icon name={IconName.VipChecked} />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <div className="flex justify-center  mt-[.2rem]">
                  <div className="flex w-[.52rem] h-[.52rem]     ">
                    <SvgIcon iconId={jiqiren} />
                  </div>
                  <div className="flex absolute text-[.24rem] mt-[.18rem]   text-[#FFF]">{index}</div>
                </div>
              )}

              <div
                className={cn(`flex ${getTextColor(vipLevel)}  mt-[.2rem]  `, {
                  'text-[.24rem] ': currency_code == 'COP',
                  'text-[.4rem] ': currency_code != 'COP',
                })}
              >
                <div className={`text-vip-${vipLevel}`}> {getShowMoney(bouns1)}</div>
              </div>

              <div className="flex absolute mt-[2.16rem] z-[2]">
                <div className="bg-[url(/static/img/vip1/hengLine_s.png)] bg-no-repeat bg-[length:100%_100%] h-[.24rem] w-[2.14rem]"></div>
              </div>

              <BonusBtn myStatus={myStatus} day={day1} index2={index2} vipLevel={vipLevel} />
            </div>
          </LimitClickWrapper>
        </div>
      </>
    </div>
  );
}

export default BonusList;
