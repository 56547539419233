import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RedDot from '../../../redDot';
import { isLogin } from '../../../../utils/helper';
import Badge from '../../../../views/mine/normal/template001/components/badge';

const GiftMoneyEntrance = ({ handleClick = () => {}, handleLinkClick = () => {} }) => {
  const { t } = useTranslation();

  const { giftMoneyMsg, activityStatus, userObj, promotionClaimedCount } = useSelector(
    (state) => ({
      giftMoneyMsg: state.ws.giftMoneyMsg,
      activityStatus: state.app.activityStatus,
      userObj: state.user.userObj,
      promotionClaimedCount: state.app.promotionClaimedCount,
    }),
    shallowEqual,
  );
  const [isShowRedDot, setIsShowRedDot] = useState(false);
  const [showMask, setShowMask] = useState(false);

  useEffect(() => {
    if (giftMoneyMsg != null && giftMoneyMsg.status == 2) {
      setIsShowRedDot(true);
    } else {
      setIsShowRedDot(false);
    }
  }, [giftMoneyMsg]);

  const clickGiftMoney = () => {
    if (activityStatus && activityStatus.gift_money_menu_show_switch == '1') {
      handleClick();
      setShowMask(false);
      handleLinkClick();
    } else {
      setShowMask(true);
    }
  };

  return (
    <div
      onClick={clickGiftMoney}
      className="active:scale-[.95] transition-all w-[1.26rem] h-[.82rem] rounded-normal cursor-pointer bg-[url(../assets/img/drawerMenuGiftMoneyBg.png)] bg-no-repeat bg-[length:100%_100%] p-[.08rem] relative"
    >
      <div className="font-[600] text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow">
        <div>{t('giftMoney.name')}</div>
      </div>
      {userObj != null && isShowRedDot ? <RedDot className="absolute right-[0] top-[0]" /> : <></>}
      {showMask ? (
        <div className="absolute inset-0 bg-[var(--colour-21)] rounded-normal font-[600] text-[.16rem] leading-[.2rem] text-[var(--colour-56)] drawer_menu_text_shadow flex flex-col justify-center text-center">
          <div>{t('home.drawer_coming')}</div>
          <div>{t('home.drawer_soon')}</div>
        </div>
      ) : null}
      {activityStatus &&
      activityStatus.gift_money_menu_show_switch == '1' &&
      (promotionClaimedCount?.data?.giftmoney || 0) > 0 ? (
        <Badge
          textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
          className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.15rem] !right-[-0.04rem] flex items-center"
          text={promotionClaimedCount?.data?.giftmoney || 0}
        ></Badge>
      ) : null}
    </div>
  );
};

export default GiftMoneyEntrance;
