import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Nodata from '../assets/img/newnoData.png';
import Nodata2 from '../assets/img/newnoData2.png';
import Icon, { IconName } from './icon';
import { isControllableSite, isLightTheme } from '../utils/helper';

import { useTranslation } from 'react-i18next';
const NoData = ({
  className = '',
  imgPath = '',
  imgStyle = '',
  className1 = '',
  showTextLangkey = 'home.nodata',
  textClassName = '',
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className={`flex flex-row items-center justify-center ${className}`}>
      <div className="flex-col flex justify-center items-center">
        {false ? (
          <>
            <div className={`w-[.72rem] h-[.72rem] text-[var(--colour-14)] ${imgStyle}`}>
              <Icon name={IconName.NoData} />
            </div>
            <div className={`mt-[.12rem] font-[700] text-[.28rem] text-[var(--colour-14)] ${className1}`}>
              {t(showTextLangkey)}
            </div>
          </>
        ) : (
          <>
            <img className="not-drag w-[2.1rem]" src={isLightTheme() ? Nodata : Nodata2} alt="" />
            <div
              className={`mt-[.28rem] text-[.22rem] font-[500] leading-[.26rem] text-[var(--colour-15)] ${textClassName}`}
            >
              {t(showTextLangkey)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NoData;
