import { useState, useEffect } from 'react';
import Input from '../../input';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../icon';
import LimitClickWrapper from '../../limitClickWrapper';
import ElementWithLoading from '../../elementWithLoading';
import { setDialogModalVisible, DialogModalChildrenKey, setWithDrawChange } from '../../../redux/reducers/appSlice';
// import DialogModal from '../index2';
import DialogModal from '../../dialogModal';
import WidthdrawQuestion from './widthdrawQuestion';
import WidthdrawRecord from './widthdrawRecord';
import TippyPopBox, { Placement } from '../../tippyPopBox';
import PopChoose, { PopChooseItem } from '../../popChoose';
import { useStateIfMounted } from 'use-state-if-mounted';
import DropDownInput from '../../dropDownInput';
import cn from 'classnames';

import Loading2 from '../../loading2';
import { toast } from 'react-toastify';
import PayLogDialog from './payLogDialog';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import CheckBox from '../../checkBox';
import usePayConfig from '../../../hooks/usePayConfig';

import { setUserObj } from '../../../redux/reducers/userSlice';
// import globalConfig from '../../../constants/config';

import { WithdrawInfo, WithdrawAction, ExchangeRate, WithdrawCrypto } from '../../../api/index';
import Down from '../../../assets/img/down1.png';

import {
  getShowMoneyNumber,
  putChangeText,
  putShowMoney,
  TextPlaces,
  isValidERCAddress,
  isValidTRXAddress,
  cutDecimal,
  currentTimeToEndTimeDiff,
} from '../../../utils/helper';

import BigNumber from 'bignumber.js';
import Countdown from 'react-countdown';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { get } from 'lodash';
import ConfirmTips from '../../confirmTips';

var dataCount = 0;
var timer = null;
const WithdrawPHP = ({
  currency = 1,
  handleGiftMoneyChange = () => {},
  OpenGiftMoneyDialog = () => {},
  withdrawConfigParams,
}) => {
  // 多语言
  const { t } = useTranslation();
  const [withBonus, setWithBonus] = useState('0');

  const [loadingShow, setLoadingShow] = useState(true);

  const [dateTime, setDateTime] = useState(0);

  const {} = useSelector((state) => ({}), shallowEqual);

  // tab 设置 fait 还是 数字货币
  const [faitSet, setFaitSet] = useState(0);

  const [giftShow, setGiftShow] = useState(false);

  const CountDowns = () => <Countdown zeroPadTime={2} date={dateTime} renderer={renderer} />;

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, []);
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state

      if (dataCount == 0) {
        setTimeout(() => {
          if (!refresh) {
            setTimeout(() => {
              setRefrensh(false);
            }, 1500);

            setRefrensh(true);
          }
          getExchangeRateData();
        }, 150);

        dataCount++;
      } else {
        if (!timer) {
          dataCount++;
          timer = setInterval(() => {
            if (!refresh) {
              setTimeout(() => {
                setRefrensh(false);
              }, 1500);
              setRefrensh(true);
            }

            getExchangeRateData();
          }, 6000);
        }
      }

      return <span className="text-[var(--colour-41)]">(00:00)</span>;
    } else {
      // Render a countdown
      dataCount = 0;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }

      return (
        <span className="text-[var(--colour-41)]">
          ({minutes < 10 ? String(0) + String(minutes) : minutes}:{seconds < 10 ? String(0) + String(seconds) : seconds}
          )
        </span>
      );
    }
  };
  const { withdrawChange } = useSelector(
    (state) => ({
      withdrawChange: state.app.withDrawChange,
    }),
    shallowEqual,
  );

  const [BankCard, setBankCard] = useState(false);

  const [filterBehaviourVisible, setFilterBehaviourVisible] = useStateIfMounted(false);

  const [requesting, setRequesting] = useState(false);
  // 提现消息
  const [isOpen, setIsOpen] = useState(false);
  // 提现record
  const [isOpenRecord, setIsOpenRecord] = useState(false);

  //提现消息msg
  const [payLog, setPayLog] = useState(false);

  // const [userObj, setUserObj] = useState({});

  //name下拉框开关
  const [nameVisible, setNameVisible] = useState(false);

  //nameParams
  const [nameParams, setNameParams] = useState({
    acc: '',
    acc_name: '',
    acc_remark: '',
    bank_code: '',
    network: '',
  });

  //根据code筛选的数据
  const [codeRes, setCodeRes] = useState([]);

  const [formData, setFormData] = useState({
    amount: '',
    acc: '',
    acc_name: '',
    acc_remark: '',
    bank_code: '',
    currency: currency,
  });

  const dispatch = useDispatch();

  const [result, setResult] = useState(null);

  const [params, setParams] = useState({
    bank_code: '',
    bank_name: '',
    id: 1,
  });

  const getBank = (key) => {
    if (result) {
      const found = bankList.find((item) => item.bank_code === key.bank_code);

      if (found) {
        return found.bank_name;
      } else {
        return t('deposit-withdraw.select_bank');
      }
    } else {
      return t('deposit-withdraw.select_bank');
    }
  };

  const [rel, setRel] = useState(false);
  const [requeCount, setReqeustCount] = useState(false);

  // 银行地址
  const [bankList, setBankList] = useState([]);

  const {
    gift_money_menu_show_switch,
    currency_default_location,
    currency_decimal_point_precision,
    wallet_crypto_switch,
  } = usePayConfig();

  useEffect(() => {
    handleGiftMoneyChange(gift_money_menu_show_switch);
    if (gift_money_menu_show_switch == 1) {
      setGiftShow(true);
    } else {
      setGiftShow(false);
    }
  }, [gift_money_menu_show_switch]);

  useEffect(() => {
    if (!requeCount) {
      setLoadingShow(true);
    }

    WithdrawInfo()
      .then((res) => {
        if (res.code == 0) {
          setReqeustCount(true);
          setResult(res.result);
          dispatch(setWithDrawChange(!withdrawChange));
          dispatch(
            setUserObj({
              ...res.result.user_obj,
            }),
          );
          //初始数据
          if (res.result && res.result.bank_list && res.result.bank_list.length > 0) {
            var bankList1 = res.result.bank_list;

            let filteredData = [];
            let uniqueNames = new Set();

            bankList1.forEach((item) => {
              if (!uniqueNames.has(item.bank_name)) {
                filteredData.push(item);
                uniqueNames.add(item.bank_name);
              } else {
                let existingIndex = filteredData.findIndex((existingItem) => existingItem.bank_name === item.bank_name);
                if (existingIndex !== -1) {
                  let existingItem = filteredData[existingIndex];
                  if (item.extension && JSON.parse(item.extension).provider) {
                    filteredData[existingIndex] = item;
                  } else if (!existingItem.extension) {
                    filteredData[existingIndex] = item;
                  }
                }
              }
            });
            filteredData.sort((a, b) => b.sort - a.sort);

            // console.log("bankList 数据", filteredData)
            setBankList(filteredData);
            setParams(filteredData[0]);
            // setParams(res.result.bank_list[0]);
          }
          ChangeDataArray(res.result);
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setLoadingShow(false);
      });
  }, [rel]);

  const WithdrawSubmit = () => {
    let banlace = MoneyReturn();
    let obj = {};
    if (!formData.amount) {
      toast.info(t('deposit-withdraw.enter_amount'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(formData.amount) > Number(banlace)) {
      toast.info(t('deposit-withdraw.amount_exceeds'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(formData.amount) < Number(getShowMoneyNumber(channelList[withBonus]?.withdraw_min || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit1', {
          min_amount: getShowMoneyNumber(channelList[withBonus]?.withdraw_min || 0),
        }),
        {
          containerId: 'global',
        },
      );
    }

    if (Number(formData.amount) > Number(getShowMoneyNumber(channelList[withBonus]?.withdraw_max || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit2', {
          max_amount: getShowMoneyNumber(channelList[withBonus]?.withdraw_max || 0),
        }),
        {
          containerId: 'global',
        },
      );
    }
    if (channelList[withBonus].network != 'BankCard') {
      // 手机卡提现
      obj = {
        amount: formData.amount,
        acc: formData.acc,
        acc_name: formData.acc_name,
        acc_remark: formData.acc_remark,
        bank_code: formData.bank_code,
        currency: currency,
      };
      obj.bank_code = 'gcash';
      obj.acc = obj.acc_remark;
    } else {
      // 银行卡提现
      if (!formData.bank_code) {
        toast.info(t('deposit-withdraw.select_bank'), {
          containerId: 'global',
        });
        return;
      }

      if (!formData.acc) {
        toast.info(t('deposit-withdraw.input_number'), {
          containerId: 'global',
        });
        return;
      }

      if (!formData.bank_code) {
        toast.info(t('deposit-withdraw.select_bank'), {
          containerId: 'global',
        });
        return;
      }

      obj = formData;
      if (obj.bank_code === 'gcash') {
        obj.acc_remark = obj.acc;
      } else {
        obj.acc_remark = 'no';
      }
    }
    if (!formData.acc_name) {
      toast.info(t('deposit-withdraw.enter_username'), {
        containerId: 'global',
      });
      return;
    }

    if (!formData.acc_remark) {
      toast.info(t('deposit-withdraw.enter_number'), {
        containerId: 'global',
      });
      return;
    }
    setRequesting(true);

    WithdrawAction({
      id: channelList[withBonus]?.id || 1,
      amount: putShowMoney(obj.amount),
      acc: obj.acc,
      acc_name: String(obj.acc_name).trim(),
      acc_remark: obj.acc_remark,
      bank_code: obj.bank_code,
      currency: obj.currency,
    })
      .then((res) => {
        if (res.code == 0) {
          setRel(!rel);
          setPayLog(true);
          // window.open(res.result.redirect_url)
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const MoneyReturn = () => {
    if (currency == 1) {
      if (result && result.user_obj && result.user_obj.balance) {
        const balance = result.user_obj.balance;
        const locked_balance = result.user_obj.locked_balance;

        const val2 = getShowMoneyNumber(BigNumber(balance).minus(locked_balance).toFixed());
        return val2 <= 0 ? 0 : Number(cutDecimal(val2, currency_decimal_point_precision || 2));
      } else {
        return '0';
      }
    } else {
      if (result && result.user_obj && result.user_obj && result.user_obj.bonus_balance) {
        return Number(getShowMoneyNumber(result.user_obj.bonus_balance));
      } else {
        return '0';
      }
    }
  };

  const FeeChange = () => {
    if (result) {
      let fee;
      if (currency == 1) {
        fee = result.withdraw_fee;
      } else {
        fee = result.bonus_withdraw_fee;
      }
      if (formData.amount) {
        let valFee = String(Number(formData.amount) * Number(fee));
        return Math.floor(Number(valFee) * 100) / 100;
      } else {
        return '0';
      }
    } else {
      return '0';
    }
  };

  //   const [channelList, setChannelList] = useState([]);
  const [channelList, setChannelList] = useState([]);

  useEffect(() => {
    if (result != null) {
      //初始数据

      if (result && channelList[withBonus]?.network.toLowerCase() == 'gcash') {
        if (
          result?.history[channelList[withBonus]?.network.toLowerCase()] &&
          result?.history[channelList[withBonus]?.network.toLowerCase()].length > 0
        ) {
          setCodeRes(result?.history[channelList[withBonus]?.network.toLowerCase()]);
          let acc_name_val = TextPlaces(result?.history[channelList[withBonus]?.network.toLowerCase()][0].acc_name, 25);

          let acc_remark_val = putChangeText(
            result?.history[channelList[withBonus]?.network.toLowerCase()][0].acc_remark,
          );
          setNameParams(result?.history[channelList[withBonus]?.network.toLowerCase()][0]);
          setFormData({
            ...formData,
            acc_name: acc_name_val,
            acc_remark: acc_remark_val,
            bank_code: bankList.length > 0 ? bankList[0].bank_code : '',
          });
        } else {
          setCodeRes([]);
          setFormData({
            ...formData,
            acc_name: '',
            acc_remark: '',
            acc: '',
            bank_code: bankList.length > 0 ? bankList[0].bank_code : '',
          });
        }
      } else if (result && channelList[withBonus]?.network.toLowerCase() == 'bankcard') {
        if (
          result?.history[channelList[withBonus]?.network.toLowerCase()] &&
          result?.history[channelList[withBonus]?.network.toLowerCase()].length > 0
        ) {
          let res = result?.history[channelList[withBonus]?.network.toLowerCase()].filter((item) => {
            return item.bank_code === params.bank_code;
          });
          setCodeRes(res);
          if (res.length > 0) {
            let acc_name_val = TextPlaces(res[0].acc_name, 25);
            let acc_remark_val = putChangeText(res[0].acc_remark);
            let acc_val = TextPlaces(res[0].acc, 20);
            setNameParams(res[0]);
            setFormData({
              ...formData,
              acc_name: acc_name_val,
              acc_remark: acc_remark_val,
              acc: acc_val,
              bank_code: params.bank_code ? params.bank_code : '',
            });
          } else {
            setFormData({
              ...formData,
              acc_name: '',
              acc_remark: '',
              acc: '',
              bank_code: params.bank_code ? params.bank_code : '',
            });
          }
        } else {
          setCodeRes([]);
          setFormData({
            ...formData,
            acc_name: '',
            acc_remark: '',
            acc: '',
            bank_code: params.bank_code ? params.bank_code : '',
          });
        }
      }
    }
  }, [result, withBonus, params, channelList, bankList]);

  // 新加区块
  const initConfig = withdrawConfigParams;

  const [channel, setChannel] = useState(null);
  const [cryptoAddress, setCryptoAddress] = useState('');
  // 区块地址
  const handleWithdrawCyorp = () => {
    let banlace = MoneyReturn();

    var rate1 = getExchangeRate();
    if (rate1 <= 0) {
      toast.info(t('deposit-withdraw.incorrect_amount'), {
        containerId: 'global',
      });
      return;
    }

    if (!formData.amount) {
      toast.info(t('deposit-withdraw.enter_amount'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(formData.amount) > Number(banlace)) {
      toast.info(t('deposit-withdraw.amount_exceeds'), {
        containerId: 'global',
      });
      return;
    }

    if (Number(formData.amount) < Number(getShowMoneyNumber(network?.withdraw_min || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit1', {
          min_amount: getShowMoneyNumber(network?.withdraw_min || 0),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }

    if (Number(formData.amount) > Number(getShowMoneyNumber(network?.withdraw_max || 0))) {
      return toast.info(
        t('deposit-withdraw.deposit_submit2', {
          max_amount: getShowMoneyNumber(network?.withdraw_max || 0),
          currency_symbol: initConfig.currency_symbol || '',
        }),
        {
          containerId: 'global',
        },
      );
    }
    if (!cryptoAddress) {
      toast.info(t('deposit-withdraw.enter_wallet'), {
        containerId: 'global',
      });
      return;
    }

    if (network.network.toLowerCase().indexOf('trc') != -1) {
      if (!isValidTRXAddress(cryptoAddress)) {
        toast.info(t('deposit-withdraw.end_address123'), {
          containerId: 'global',
        });
        return;
      }
    } else if (network.network.toLowerCase().indexOf('erc') != -1) {
      if (!isValidERCAddress(cryptoAddress)) {
        toast.info(t('deposit-withdraw.end_address123'), {
          containerId: 'global',
        });
        return;
      }
    }

    setRequesting(true);

    WithdrawCrypto({
      network: network.network,
      pay_id: network.id,
      crypto_currency: crypto?.currency,
      crypto_address: cryptoAddress,
      crypto_network: network.network,
      amount: putShowMoney(formData.amount),
      currency: formData.currency,
    })
      .then((res) => {
        if (res.code == 0) {
          setPayLog(true);
          // getInitData();
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };
  // 区块类型
  const [network, setNetWork] = useState(null);
  const [crypto, setCrypto] = useState(null);
  //  内容
  //  const [fiatList, setFiatList] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);

  // 费率
  const [exchangeRate, setExchangeRate] = useState([]);
  // 获取相等的到汇率
  const getExchangeRate = () => {
    if (exchangeRate && exchangeRate.length > 0) {
      const result = exchangeRate.find(
        (item) => item.crypto === crypto?.currency && item.fiat === initConfig.currency_code,
      );
      return result ? Number(Number(result.withdraw_rate).toFixed(4)) : 0;
    } else {
      return 0;
    }
  };
  const getExchangeRateData = () => {
    ExchangeRate().then((res) => {
      if (res.code == 0) {
        if (res.result) {
          setExchangeRate(res.result.list);
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };
  //动画
  const [refresh, setRefrensh] = useState(false);

  // 处理数组
  const ChangeDataArray = (data) => {
    var arrayFiat = [];
    var arrayCrypto = [];
    if (data && data.withdraw_list && data.withdraw_list.length > 0) {
      // 重新组建数组
      data.withdraw_list.map((item) => {
        if (item.type == 'crypto') {
          arrayCrypto.push(item);
        } else {
          arrayFiat.push(item);
        }
      });

      if (arrayFiat && arrayFiat.length > 0) {
        const firstChannel = arrayFiat[0];
        if (firstChannel) {
          setChannel(firstChannel);
        }
        setChannelList(arrayFiat);
      }

      //  setFiatList(arrayFiat);

      // 如果数组有就开始遍历
      if (arrayCrypto && arrayCrypto.length > 0) {
        // 创建一个空对象，用于存储相同"currency"的对象
        if (wallet_crypto_switch != 1) {
          setCryptoList([]);
        } else {
          const mergedData = arrayCrypto.reduce((acc, item) => {
            const existingItem = acc.find((accItem) => accItem.currency === item.currency);
            if (existingItem) {
              existingItem.networks.push(item);
            } else {
              item.networks = [item];

              acc.push(item);
            }
            return acc;
          }, []);
          getExchangeRateData();
          // 新数据
          if (currency_default_location == 1) {
            setWidthdrawType(2);
            setFaitSet(currency_default_location);
          }
          setCryptoList(mergedData);
          setCrypto(mergedData[0]);
          if (mergedData[0] && mergedData[0].networks && mergedData[0].networks.length > 0) {
            setNetWork(mergedData[0].networks[0]);
          }
        }
      }
    }
  };
  // 选择1还是选择2 1正常充值 2区块链充值
  const [widthdrawType, setWidthdrawType] = useState(1);

  const getAmountFee = () => {
    var gasPrice = Number(getShowMoneyNumber(network?.fee || 0, 6)) * getExchangeRate();

    if (Number(gasPrice) + Number(FeeChange()) >= Number(formData?.amount || 0)) {
      return 0;
    } else {
      // 手续费内容 汇率 1usdt = 多少代币
      var rate = Number(1 / getExchangeRate());
      var amountFee = Number(Number(formData?.amount || 0) - gasPrice - Number(FeeChange()));
      return Number(Number(rate * amountFee).toFixed(6));
    }
  };

  useEffect(() => {
    if (exchangeRate && exchangeRate.length > 0 && cryptoList && cryptoList.length > 0) {
      const result = exchangeRate.find(
        (item) => item.crypto === crypto?.currency && item.fiat === initConfig.currency_code,
      );

      if (result) {
        var time = currentTimeToEndTimeDiff('', result.expire_timestamp);
        time = time * 1000;
        setDateTime(Date.now() + time);
      }
    }
  }, [exchangeRate, cryptoList]);
  return (
    <>
      {loadingShow ? (
        <Loading2 className="h-full" />
      ) : (
        result &&
        ((channelList && channelList.length > 0) || (cryptoList && cryptoList.length > 0)) && (
          <div className="  mt-[.4rem] pb-[.4rem]">
            <div className="w-full max-w-full overflow-x-auto scrollbar-none">
              <div className=" mt-[.2rem] flex justify-between items-center ">
                <div className="flex justify-between items-center flex-1   h-[.92rem] px-[.3rem] text-[.28rem]   border border-[var(--colour-6)] bg-[var(--colour-34)] rounded-normal">
                  <div className=" text-[.28rem] text-[var(--colour-44)]">{t('deposit-withdraw.withdrawable')}</div>
                  <div className=" text-[.28rem] text-[var(--colour-38)]">{MoneyReturn()}</div>
                </div>

                {currency == 1 && (
                  <span
                    className="active:scale-[.95] transition-all select-none cursor-pointer    ml-[.3rem] w-[.4rem] h-[.4rem] text-[var(--colour-7)]"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <Icon name={IconName.WidthdrawQuestion} />
                  </span>
                )}
              </div>
              {giftShow && (
                <>
                  <div
                    className={`cursor-pointer   text-[.26rem] mt-[.32rem] text-left font-[500] underline underline-offset-4 text-[var(--colour-41)]`}
                    onClick={() => {
                      OpenGiftMoneyDialog();
                    }}
                  >
                    {t('account.fund')}
                  </div>
                  <div className=" mt-[.2rem] text-[var(--colour-3)]">
                    <Input
                      leftOuter={
                        <div className="flex items-center">
                          <div className="block font-[700]  text-[.32rem]  m-[0_.2rem_0_.28rem]">₱</div>
                        </div>
                      }
                      type="text"
                      disabled
                      value={getShowMoneyNumber(result?.user_claim_money?.total_money || 0)}
                      placeholder={t('deposit-withdraw.enter_amount')}
                      innerClassName="!h-[.92rem] !border-transparent"
                      topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                    />
                  </div>
                </>
              )}

              {cryptoList && cryptoList.length > 0 && channelList && channelList.length > 0 && (
                <div className=" mt-[.4rem]">
                  <div className=" text-[.26rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.currency_type')}
                  </div>
                  <div className={`flex gap-[.1rem]  flex-wrap  mt-[.2rem] ${faitSet == 1 && 'flex-row-reverse'}`}>
                    <div
                      onClick={() => {
                        setWidthdrawType(1);
                      }}
                      className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center flex-1  h-[.8rem] rounded-normal ${
                        widthdrawType == 1
                          ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                          : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                      } `}
                    >
                      <span className={`font-[700]   ml-[.04rem] text-[.24rem]`}>{t('deposit-withdraw.fiat')}</span>
                    </div>
                    <div
                      onClick={() => {
                        setWidthdrawType(2);
                      }}
                      className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center flex-1  h-[.8rem] rounded-normal ${
                        widthdrawType == 2
                          ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                          : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                      } `}
                    >
                      <span className={`font-[700]   ml-[.04rem] text-[.24rem]`}>{t('deposit-withdraw.crypto')}</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="  text-[.26rem] mt-[.4rem] text-left font-[500] text-[var(--colour-41)]">
                {t('deposit-withdraw.amount')}
              </div>
              <div className="mt-[.2rem]">
                <Input
                  leftOuter={
                    <div className="flex items-center">
                      <div className="  text-[.28rem] m-[0_.2rem_0_.28rem] block text-[var(--colour-3)]">₱</div>
                    </div>
                  }
                  type="number"
                  onChange={(ev) => {
                    let val = putChangeText(ev.target.value);
                    setFormData({ ...formData, amount: val });
                  }}
                  value={formData.amount}
                  placeholder={
                    widthdrawType != 2
                      ? t('deposit-withdraw.input_amount', {
                          min: getShowMoneyNumber(channelList[withBonus]?.withdraw_min || 0),
                          max: getShowMoneyNumber(channelList[withBonus]?.withdraw_max || 0),
                        })
                      : t('deposit-withdraw.input_amount', {
                          min: getShowMoneyNumber(network?.withdraw_min || 0),
                          max: getShowMoneyNumber(network?.withdraw_max || 0),
                        })
                  }
                  innerClassName=" !h-[.92rem] !border-transparent"
                  topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                />
                <span className="  w-[.4rem] h-[.4rem]"></span>
              </div>
              <div className=" mt-[.2rem] text-left font-[500]">
                <div className=" text-[.24rem] text-[var(--colour-1)]">
                  {t('deposit-withdraw.withdrawal_fee')}: ₱ {FeeChange()}
                </div>
                {widthdrawType != 2 && (
                  <div className="  text-[.26rem] mt-[.1rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.bank_gcash')}
                  </div>
                )}
              </div>
              {widthdrawType != 2 && (
                <div>
                  <div className=" mt-[.2rem] flex flex-row items-center border border-[var(--colour-6)] bg-[var(--colour-34)] rounded-normal">
                    <div className="flex items-center flex-wrap-wrap  mx-[.36rem] w-full overflow-x-scroll hidden-scroll-y">
                      {channelList.length > 0 &&
                        channelList.map((item, index) => (
                          <div
                            key={index}
                            className={cn(
                              `flex items-center justify-start   h-[.92rem] text-[.24rem] font-[400] min-w-[33.1%]`,
                              {
                                'text-[var(--colour-44)]': index == Number(withBonus),
                                'text-[var(--colour-2)]': index != Number(withBonus),
                              },
                            )}
                          >
                            <CheckBox
                              checked={withBonus == index}
                              className="  w-[.48rem] h-[.48rem] cursor-pointer"
                              handleClick={() => setWithBonus(index)}
                            />

                            <div
                              className={`  text-[.24rem] ml-[.16rem] font-[500] text-left ${withBonus == index ? 'text-[var(--colour-3)]' : 'text-[var(--colour-44)]'}`}
                            >
                              {item.withdraw_name}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  {channelList?.length > 0 && channelList[withBonus].network != 'BankCard' ? (
                    <></>
                  ) : (
                    <>
                      <div>
                        <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                          {t('deposit-withdraw.bank_code')}
                          <span className="text-[var(--colour-25)]"> *</span>
                        </div>
                        <div className="flex flex-row justify-start  mt-[.2rem]">
                          <TippyPopBox
                            placement={Placement.bottomEnd}
                            popChildren={
                              <PopChoose
                                className={`w-[6.56rem]  overflow-y-auto  max-h-[5rem] scrollbar-none border border-[var(--colour-6)] bg-[var(--colour-35)]  py-[.14rem] aotuWdith`}
                                children={
                                  bankList.length > 0 &&
                                  bankList.map((item, index) => (
                                    <PopChooseItem
                                      className="active:scale-[.95] transition-all select-none !h-[.92rem]  flex cursor-pointer items-center  text-[.24rem] font-[500]"
                                      chooseClassName="text-[var(--colour-44)]"
                                      key={index}
                                      data={item}
                                      choose={params.id == item.id}
                                      onClick={() => {
                                        setParams(item);
                                        setFormData({ ...formData, bank_code: item.bank_code });
                                        setFilterBehaviourVisible(false);
                                      }}
                                    >
                                      <span
                                        className={` pl-[.2rem] !h-[.92rem]  w-full flex items-center justify-start rounded-normal ${params.id == item.id ? 'text-[var(--colour-2)] bg-[var(--colour-1)]' : 'text-[var(--colour-44)]'}`}
                                      >
                                        {item.bank_name}
                                      </span>
                                    </PopChooseItem>
                                  ))
                                }
                              />
                            }
                            visible={filterBehaviourVisible}
                            onClickOutside={() => {
                              setFilterBehaviourVisible(false);
                            }}
                            offset={[0, 10]}
                          >
                            <div className="w-full">
                              <DropDownInput
                                iconCanRotate={true}
                                iconRotate={filterBehaviourVisible}
                                className={`w-full    !h-[.92rem] text-[.24rem] px-[.28rem] font-[500] cursor-pointer !justify-center border border-[var(--colour-6)] bg-[var(--colour-34)] rounded-normal`}
                                onClick={() => {
                                  setFilterBehaviourVisible(filterBehaviourVisible ? false : true);
                                }}
                              >
                                <div className={cn('flex-1 text-left flex items-center font-[600]  text-[.28rem]')}>
                                  {getBank(params)}
                                </div>
                                <div className="flex flex-row items-center justify-right">
                                  <span
                                    className={cn('  w-[.16rem] h-[.32rem] block transition', {
                                      'rotate-[90deg]': filterBehaviourVisible,
                                    })}
                                  >
                                    <Icon name={IconName.RightArrow} />
                                  </span>
                                </div>
                              </DropDownInput>
                            </div>
                          </TippyPopBox>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                      {t('deposit-withdraw.beneficiary_name')} <span className="text-[var(--colour-25)]">*</span>
                    </div>

                    <div className=" mt-[.2rem]">
                      <div className="flex  bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-normal">
                        <div className="flex-1">
                          <Input
                            type="text"
                            value={formData.acc_name}
                            onChange={(ev) => {
                              let val = TextPlaces(ev.target.value, 40);
                              setFormData({ ...formData, acc_name: val });
                            }}
                            onBlur={(ev) => {
                              setFormData({ ...formData, acc_name: ev.target.value.trim() });
                            }}
                            placeholder={t('deposit-withdraw.enter_username')}
                            innerClassName=" !h-[.92rem] !border-transparent"
                            topClassName={cn(`border-none overflow-hidden`, {
                              '!rounded-r-none': result != null && codeRes.length > 0,
                            })}
                          />
                        </div>
                        {result != null && codeRes.length > 0 && (
                          <div className="w-[1rem]">
                            <TippyPopBox
                              placement={Placement.bottomEnd}
                              popChildren={
                                <PopChoose
                                  className={`w-[6.56rem]   max-h-[5rem] border border-[var(--colour-6)] bg-[var(--colour-35)] overflow-y-auto withdraw_scroll  rounded-normal  py-[.14rem] aotuWdith`}
                                  children={codeRes.map((item, index) => (
                                    <PopChooseItem
                                      className={`w-[6.56rem]  !h-[.92rem]  overflow-y-auto  max-h-[5rem] scrollbar-none aotuWdith`}
                                      chooseClassName="text-[var(--colour-44)]"
                                      key={index}
                                      data={item}
                                      choose={nameParams.acc == item.acc}
                                      onClick={() => {
                                        setNameParams(item);
                                        setFormData({
                                          ...formData,
                                          acc_name: item.acc_name,
                                          acc:
                                            result && channelList[withBonus]?.network.toLowerCase() == 'gcash'
                                              ? ''
                                              : item.acc,
                                          acc_remark: item.acc_remark,
                                        });
                                        setNameVisible(false);
                                      }}
                                    >
                                      <span
                                        className={` pl-[.2rem] !h-[.92rem]  w-full flex items-center justify-start rounded-normal ${nameParams.acc == item.acc ? 'text-[var(--colour-2)] bg-[var(--colour-1)]' : 'text-[var(--colour-44)]'}`}
                                      >
                                        {item.acc_name}
                                      </span>
                                    </PopChooseItem>
                                  ))}
                                />
                              }
                              visible={nameVisible}
                              onClickOutside={() => {
                                setNameVisible(false);
                              }}
                              offset={[0, 10]}
                            >
                              <div>
                                <DropDownInput
                                  iconCanRotate={true}
                                  iconRotate={nameVisible}
                                  className={`w-full  !h-[.92rem]  text-[.24rem] font-[500] cursor-pointer !justify-center rounded-normal !rounded-l-none`}
                                  onClick={() => {
                                    setNameVisible(!nameVisible);
                                  }}
                                >
                                  <div className="flex flex-row items-center justify-right">
                                    <span
                                      className={cn('  w-[.16rem] h-[.32rem] block transition', {
                                        'rotate-[90deg]': nameVisible,
                                      })}
                                    >
                                      <Icon name={IconName.RightArrow} />
                                    </span>
                                  </div>
                                </DropDownInput>
                              </div>
                            </TippyPopBox>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {channelList?.length > 0 && channelList[withBonus].network != 'BankCard' ? (
                    <></>
                  ) : (
                    <>
                      {/* cc */}
                      <div>
                        <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                          {t('deposit-withdraw.gcash_acc')}
                          <span className="text-[var(--colour-25)]"> *</span>
                        </div>

                        <div className=" mt-[.2rem]">
                          <Input
                            type="text"
                            value={formData.acc}
                            onChange={(ev) => {
                              let val = TextPlaces(ev.target.value, 20);
                              setFormData({ ...formData, acc: val });
                            }}
                            placeholder={t('deposit-withdraw.input_number')}
                            innerClassName=" !h-[.92rem] !border-transparent"
                            topClassName={cn(
                              `bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]`,
                            )}
                          />
                          <span className="  w-[.4rem] h-[.4rem]"></span>
                        </div>
                      </div>
                    </>
                  )}
                  {channelList?.length > 0 && channelList[withBonus].network != 'BankCard' ? (
                    <>
                      {/* phone */}
                      <div>
                        <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                          {t('deposit-withdraw.acc')}
                          <span className="text-[var(--colour-25)]"> *</span>
                        </div>

                        <div className=" mt-[.2rem]">
                          <Input
                            type="number"
                            value={formData.acc_remark}
                            onChange={(ev) => {
                              let val = putChangeText(ev.target.value);
                              setFormData({ ...formData, acc_remark: val });
                            }}
                            placeholder={t('deposit-withdraw.enter_number')}
                            innerClassName=" !h-[.92rem] !border-transparent"
                            topClassName={cn(
                              `bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]`,
                            )}
                          />
                          <span className="  w-[.4rem] h-[.4rem]"></span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="  text-[.24rem] mt-[.2rem] font-[500] text-left text-[var(--colour-44)]">
                    {t('deposit-withdraw.make_sure_account')}
                  </div>
                </div>
              )}

              {/* 区块链 */}
              {widthdrawType == 2 && (
                <div className=" mt-[-.36rem]">
                  <div className="flex justify-center  mt-[.26rem]">
                    <div className="  w-[.66rem] h-[.66rem] text-[var(--colour-14)]">
                      <Icon name={IconName.WithdrawArrow}></Icon>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="flex gap-[.1rem]  flex-wrap  mt-[.2rem]">
                      {cryptoList && cryptoList.length > 0 ? (
                        cryptoList.map((item, index) => (
                          <div key={index}>
                            <div
                              key={item.id}
                              onClick={() => {
                                // setChannel(index);
                                setCrypto(item);
                                if (item && item.networks && item.networks.length > 0) {
                                  setNetWork(item.networks[0]);
                                }
                              }}
                              className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center w-[1.56rem]   h-[.8rem] rounded-normal ${
                                crypto.id == item.id
                                  ? 'border border-[var(--colour-1)] bg-[var(--colour-5)] text-[var(--colour-3)]'
                                  : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                              }`}
                            >
                              <span className="w-[.32rem] h-[.32rem] block">
                                {item.currency == 'USDT' && <Icon name={IconName.USDT} />}
                                {item.currency == 'BTC' && <Icon name={IconName.BTC} />}
                                {item.currency == 'ETH' && <Icon name={IconName.ETH} />}
                                {item.currency == 'TRX' && <Icon name={IconName.TRX} />}
                                {item.currency == 'USDC' && <Icon name={IconName.USDC} />}
                                {/* <Icon name={IconName.USDT} /> */}
                              </span>

                              <span className={`font-[700]   ml-[.04rem] text-[.24rem]`}>{item.currency}</span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="text-left   mt-[.32rem] text-[.26rem] font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.withdraw_rate')}:
                  </div>

                  <div className="flex justify-between items-center">
                    <div className=" text-[.26rem] flex items-center">
                      <span
                        //  ${
                        //   initConfig.gradient.includes(initConfig.theme) && 'text-icon'
                        // }
                        className={`  w-[.36rem] h-[.36rem] text-ref text-[var(--colour-1)] font-[500] cursor-pointer ${refresh == true && 'runHi'}`}
                        onClick={() => {
                          if (refresh) {
                            return;
                          }
                          setRefrensh(true);
                          getExchangeRateData();
                          setTimeout(() => {
                            setRefrensh(false);
                          }, 1500);
                        }}
                      >
                        <Icon name={IconName.Refresh} />
                      </span>
                      <span className=" m-[0_.06rem_0_.04rem] text-left text-[var(--colour-41)] font-[500]">
                        1 {crypto?.currency} ≈ {initConfig.currency_symbol || ''} {cutDecimal(getExchangeRate())}{' '}
                        <CountDowns />
                      </span>
                    </div>
                    <div className=" text-[.26rem] text-left font-[500] text-[var(--colour-41)]">
                      -{Number(getShowMoneyNumber(network?.fee || 0, 6))} {crypto?.currency || ''} (Gas Fee)
                    </div>
                  </div>
                  {/* input兑换后的金额 */}
                  <div className="flex justify-between   mt-[.16rem] px-[.3rem] items-center border border-[var(--colour-6)] bg-[var(--colour-34)] overflow-hidden rounded-normal  h-[.92rem]">
                    <div className="flex items-center">
                      <div className=" text-[.28rem] opacity-50 font-[700] block  text-[var(--colour-41)] ">
                        {t('deposit-withdraw.converted_crypto_amount')}
                      </div>
                    </div>

                    <div className={` text-[.24rem] text-[var(--colour-38)]`}>
                      {getExchangeRate() == 0 ? 0 : cutDecimal(getAmountFee(), 5)} {crypto?.currency}
                    </div>
                  </div>
                  {/* network */}
                  <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.network')}
                  </div>
                  <div className="relative  mb-[.6rem]">
                    <div className="flex gap-[.1rem]  flex-wrap mt-[.2rem]">
                      {crypto && crypto.networks && crypto.networks.length > 0 ? (
                        crypto.networks.map((item, index) => (
                          <div key={index}>
                            <div
                              key={item.id}
                              onClick={() => {
                                setNetWork(item);
                              }}
                              className={`flex cursor-pointer active:scale-[.95] select-none items-center justify-center w-[2.1rem]   h-[.8rem] rounded-normal ${
                                item.network == network.network
                                  ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                                  : 'bg-[var(--colour-34)] text-[var(--colour-44)]'
                              } `}
                            >
                              <span className={` font-[700] text-[.26rem] font-[700]`}>{item.withdraw_name}</span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* address */}
                  <div className="  text-[.26rem] mt-[.2rem] text-left font-[500] text-[var(--colour-41)]">
                    {t('deposit-withdraw.enter_wallet')} <span className="text-[var(--colour-25)]"> *</span>
                  </div>
                  <div className=" mt-[.2rem]">
                    <Input
                      type="text"
                      onChange={(ev) => {
                        setCryptoAddress(TextPlaces(ev.target.value, 80));
                      }}
                      value={cryptoAddress}
                      placeholder={t('deposit-withdraw.enter_wallet')}
                      innerClassName=" !h-[.92rem] !border-transparent"
                      topClassName="bg-[var(--colour-34)] rounded-normal overflow-hidden border border-[var(--colour-6)]"
                    />
                    <span className="  w-[.4rem] h-[.4rem]"></span>
                  </div>
                  <div className="  text-[.24rem] mt-[.2rem] font-[500] text-left text-[var(--colour-44)]">
                    {t('deposit-withdraw.wallet_msg1')}
                  </div>
                </div>
              )}

              <div
                onClick={() => {
                  setIsOpenRecord(true);
                }}
                className="  text-[.3rem] mt-[.1rem] font-[700] text-left cursor-pointer "
              >
                <span className="text-[var(--colour-49)]">{t('deposit-withdraw.record')}</span>
              </div>

              <LimitClickWrapper
                className="active:scale-[.95] transition-all select-none flex flex-row    px-[.34rem] h-[.92rem] mt-[.3rem] items-center cursor-pointer justify-center cursor-auto rounded-normal bg-[var(--colour-1)]"
                onClick={() => {
                  if (!requesting) {
                    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.WB_Click_WithdrawButton);
                    // wbClick('WB_Click_WithdrawButton');
                    if (widthdrawType == 1) {
                      WithdrawSubmit();
                    } else {
                      handleWithdrawCyorp();
                    }
                  }
                }}
              >
                <ElementWithLoading
                  isLoading={requesting}
                  normalEl={<div className="font-[700] text-[var(--colour-2)]">{t('deposit-withdraw.withdraw')}</div>}
                />
              </LimitClickWrapper>
            </div>
          </div>
        )
      )}

      <DialogModal
        onRequestClose={() => {
          setIsOpen(false);
        }}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '50',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isOpen}
      >
        <WidthdrawQuestion
          decimalPoit={currency_decimal_point_precision || 2}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          setIsOpenRecord(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '50',
            borderRadius: '.2rem',
          },
        }}
        isOpen={isOpenRecord}
      >
        <WidthdrawRecord
          handleClose={() => {
            setIsOpenRecord(false);
          }}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        onRequestClose={() => {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.Default,
            }),
          );
          setPayLog(false);
        }}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
            borderRadius: '.2rem',
          },
        }}
        isOpen={payLog}
      >
        <PayLogDialog
          handleClose={() => {
            dispatch(
              setDialogModalVisible({
                show: DialogModalChildrenKey.Default,
              }),
            );
            setPayLog(false);
          }}
        />
      </DialogModal>
      <ConfirmTips type="withdraw" />
    </>
  );
};

export default WithdrawPHP;
