import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { removeLoadingPage } from '../utils/helper';
import { removeParentIfmLoading } from '../manager/iframeManager';

export default function ErrorFallback({ error, resetErrorBoundary }) {
  console.log('error', error);
  console.log('resetErrorBoundary', resetErrorBoundary);
  const { t } = useTranslation();

  useEffect(() => {
    removeLoadingPage();
    removeParentIfmLoading();
  }, []);

  return (
    <div className="text-[.36rem] flex flex-col items-center space-y-[.4rem] px-[.2rem] h-[var(--app-height)] overflow-auto">
      <div
        onClick={() => window.location.reload()}
        className="mt-[.4rem] flex justify-center items-center text-[.36rem] w-[3.5rem] py-[.2rem] font-[600] cursor-pointer rounded-normal bg-[var(--colour-1)] box-shadow-7 text-[var(--colour-2)]"
      >
        {t('home.refresh')}
      </div>
      <div className="flex flex-col items-center wrap-break-word">
        <p>{t('home.somthingwrong')}</p>
        <p style={{ color: 'red' }}>{error?.message}</p>
        <p>{error?.stack}</p>
      </div>
    </div>
  );
}
