import { loadScriptAsync } from '../utils/helper';

/**
 * google管理器
 */

const googleApi = 'https://accounts.google.com/gsi/client';

class GoogleManager {
  static isInited = false;
  static fakeGoogleWrapper = null;

  /**
   * 谷歌初始化 async
   * @param {string} googleId
   * @param {function} callback
   */
  static async init(googleId, callback) {
    if (this.isInited) return;
    // 加载api
    await loadScriptAsync(googleApi);
    // 初始化
    window.google.accounts.id.initialize({
      client_id: googleId,
      callback: (response) => {
        console.log('Encoded JWT ID token: ' + response.credential);
        callback && callback(response);
      },
    });
    // 加载谷歌容器
    this.fakeGoogleWrapper = createFakeGoogleWrapper();
    this.isInited = true;
  }

  /**
   * 谷歌登录
   */
  static login() {
    if (this.fakeGoogleWrapper) {
      this.fakeGoogleWrapper.click();
    }
  }
}

// 创建隐藏谷歌容器
const createFakeGoogleWrapper = () => {
  const googleLoginWrapper = document.createElement('div');
  googleLoginWrapper.style.display = 'none';
  googleLoginWrapper.classList.add('custom-google-button');
  document.body.appendChild(googleLoginWrapper);

  window.google.accounts.id.renderButton(googleLoginWrapper, {
    type: 'icon',
    width: '200',
  });

  const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');

  return {
    click: () => {
      googleLoginWrapperButton.click();
    },
  };
};

export default GoogleManager;
