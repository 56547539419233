import { getIdentity } from '../../../utils/helper';
import NTemplate001 from './normal/template001/index';
import DTemplate003 from './design/template003/index';
import { ActivityAllReq, PromotionActivitySettle } from '../../../api';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

const Index = function ({
  handleClosePagingButton = () => {},
  showContent = 'guide',
  handleClose = () => {},
  data: _data = null,
  isShowCloseBtn = true,
  updateList = () => {},
  isSinglePopup = true,
  handleSetIsFullPage = () => {},
}) {
  const { showStyle } = useSelector(
    (state) => ({
      // showStyle: 2,
      showStyle: get(state, 'app.activityData.activity_promotional_achievements.list[0].show_style'),
    }),
    shallowEqual,
  );

  const [data, setData] = useState(_data);
  const [isLoading, setIsLoading] = useState(false);

  const requestActivitySettle = (id) => {
    if (id && getIdentity()) {
      PromotionActivitySettle({
        activity_id: id,
      });
    }
  };

  const updateData = () => {
    const params = {
      data_type: JSON.stringify(['activity_promotional_achievements']),
    };

    setIsLoading(true);

    ActivityAllReq(params)
      .then((res) => {
        if (res.code == 0) {
          const _data = get(res, 'result.activity_promotional_achievements.list[0]');
          if (_data) {
            requestActivitySettle(_data?.id);
            setData(_data);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (_data) {
      requestActivitySettle(_data?.id);
    } else {
      updateData();
    }
  }, []);

  const handleSetData = (data) => setData(data);

  return showStyle == 2 ? (
    <>
      <DTemplate003
        handleClosePagingButton={handleClosePagingButton}
        showContent:_showContent={showContent}
        handleClose={handleClose}
        data={data}
        isShowCloseBtn={isShowCloseBtn}
        updateList={updateList}
        isSinglePopup={isSinglePopup}
        handleSetIsFullPage={handleSetIsFullPage}
        isLoading={isLoading}
        showStyle={showStyle}
        handleSetData={handleSetData}
      />
    </>
  ) : showStyle == 1 ? (
    <>
      <NTemplate001
        handleClosePagingButton={handleClosePagingButton}
        showContent:_showContent={showContent}
        handleClose={handleClose}
        data={data}
        isShowCloseBtn={isShowCloseBtn}
        updateList={updateList}
        isSinglePopup={isSinglePopup}
        handleSetIsFullPage={handleSetIsFullPage}
        isLoading={isLoading}
        showStyle={showStyle}
        handleSetData={handleSetData}
      />
    </>
  ) : (
    <></>
  );
};

export default Index;
