export const getBorderColor = (
  str = '',
  isShowError = false,
  border1 = '!border-[var(--colour-6)]',
  border2 = '!border-[var(--colour-25)]',
  border3 = '!border-[var(--colour-24)]',
) => {
  if (str.length == 0) {
    if (isShowError) {
      return border2;
    } else {
      return border1;
    }
  } else {
    if (isShowError) {
      return border2;
    } else {
      return border3;
    }
  }
};
