import Close from '../../../assets/img/bulletinClose.png';
import cn from 'classnames';
import { forwardRef } from 'react';
import ScrollbarContent from '../../scrollbarContent';

const Index = forwardRef(
  (
    {
      isCenter = false,
      children,
      allowScroll = false,
      handleClose = () => {},
      isShowCloseBtn = true,
      className = '',
      isContentVisible = true,
    },
    ref,
  ) => {
    return (
      <div
        className={cn('', {
          visible: isContentVisible,
          invisible: !isContentVisible,
        })}
      >
        {allowScroll ? (
          <div
            className={cn(
              `py-[.56rem] w-[6.86rem] h-[9.84rem] bg-[var(--colour-35)] rounded-normal relative select-none ${className}`,
            )}
          >
            {isShowCloseBtn && (
              <div className="w-[.52rem] h-[.48rem] cursor-pointer absolute right-0 top-[-.6rem]" onClick={handleClose}>
                <img src={Close} alt="" className="not-drag" />
              </div>
            )}
            <ScrollbarContent className="h-[100%] mx-[.12rem] flex justify-center">{children}</ScrollbarContent>
          </div>
        ) : (
          <div
            ref={ref}
            className={cn(
              `w-[6.86rem] h-[9.84rem] bg-[var(--colour-35)] rounded-normal relative select-none ${className}`,
              {
                'flex items-center justify-center': isCenter,
              },
            )}
          >
            {isShowCloseBtn && (
              <div
                className={`w-[.52rem] h-[.48rem] cursor-pointer absolute right-0 top-[-.6rem] ${className}`}
                onClick={handleClose}
              >
                <img src={Close} alt="" className="not-drag" />
              </div>
            )}

            {children}
          </div>
        )}
      </div>
    );
  },
);

export default Index;
