import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Icon, { IconName } from '../icon';
import { getUserPhoto, omitStr } from '../../utils/helper';
import { useSpring, animated } from 'react-spring';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import {
  setNeedLogout,
  setShowHeaderUserinfo,
  setShowSupportNew,
  setSoundFlag,
  setDrawerMenuVisible,
} from '../../redux/reducers/appSlice';
import { toLocalePath } from '../../utils/localePath';
import cn from 'classnames';
import useHandleLogic from '../../hooks/useHandleLogic';
import { useGlobalNavigate } from '../../contexts/navigateContext';

export default function HeaderUserinfo() {
  const customTime = 300;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useGlobalNavigate();
  const { userObj, showHeaderUserinfo, soundFlag, drawerMenuVisible, windowInnerWidth } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      showHeaderUserinfo: state.app.showHeaderUserinfo,
      soundFlag: state.app.soundFlag,
      drawerMenuVisible: state.app.drawerMenuVisible,
      windowInnerWidth: state.app.windowInnerWidth,
    }),
    shallowEqual,
  );

  const { handleClickManMadeInPage, handleClickVector } = useHandleLogic();

  const clickUseraccount = () => {
    navigate(toLocalePath('/mine'));
    dispatch(setShowHeaderUserinfo(false));
    if (windowInnerWidth < 1280 && drawerMenuVisible.visible) {
      dispatch(
        setDrawerMenuVisible({
          visible: false,
          isAnimation: true,
        }),
      );
    }
  };

  const clickUserPhone = () => {
    handleClickManMadeInPage();
    dispatch(setShowHeaderUserinfo(false));
  };

  const clickEmail = () => {
    handleClickVector();
    dispatch(setShowHeaderUserinfo(false));
  };

  const clickUserSound = () => {
    dispatch(setSoundFlag(!soundFlag));
    dispatch(setShowHeaderUserinfo(false));
  };
  const clickUserLogout = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.SM_Click_LogoutButton);
    dispatch(setShowHeaderUserinfo(false));
    dispatch(setNeedLogout());
  };

  const drawerAnimation = useSpring({
    maxHeight: showHeaderUserinfo ? '20rem' : '0',
    opacity: showHeaderUserinfo ? 1 : 0,
    config: { duration: customTime },
  });

  const userList = [
    {
      type: 1,
      icon: <Icon name={IconName.UserAccount}></Icon>,
      name: t('home.userInfo_account'),
      click: clickUseraccount,
    },
    {
      type: 1,
      icon: <Icon name={IconName.ManMade}></Icon>,
      name: t('home.userInfo_support'),
      click: clickUserPhone,
    },
    // {
    //   type: 2,
    //   icon: <Icon name={IconName.UserSound}></Icon>,
    //   name: t('home.userInfo_sound'),
    //   click: clickUserSound,
    // },
    {
      type: 3, //3是  layout1、layout2状态下 && 手机模式 才会显示
      icon: <Icon name={IconName.MenuBarEmail} />,
      name: t('home.menu_email'),
      click: clickEmail,
    },
    {
      type: 1,
      icon: <Icon name={IconName.UserLogout}></Icon>,
      name: t('home.userInfo_logout'),
      click: clickUserLogout,
    },
  ];
  return userObj ? (
    <animated.div
      className={cn(
        'absolute rounded-br-[var(--rounded-normal)] rounded-bl-[var(--rounded-normal)] w-[4.96rem] px-[.32rem] flex flex-col items-center top-[1.04rem] right-[0] overflow-hidden z-[99] cursor-default bg-[var(--colour-54)] border-l border-r border-b border-[var(--colour-6)]',
      )}
      style={drawerAnimation}
    >
      <HeaderUserinfoImg />
      <HeaderUserinfomsg />
      {userList.map((item, index) => {
        return (
          <div key={index}>
            {item.type === 1 ? (
              <HeaderUserinfoItem1 item={item} index={index} len={userList.length} click={item.click} />
            ) : item.type === 2 ? (
              <HeaderUserinfoItem2 item={item} soundFlag={soundFlag} click={item.click} />
            ) : (
              <HeaderUserinfoItem3 item={item} index={index} len={userList.length} click={item.click} />
            )}
          </div>
        );
      })}
    </animated.div>
  ) : (
    <></>
  );
}

const HeaderUserinfoImg = () => {
  const { userObj, websiteInitConfig } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
    }),
    shallowEqual,
  );
  return userObj ? (
    <div className="w-[1.28rem] h-[1.28rem]  overflow-hidden mt-[.3rem] mb-[.34rem] relative">
      <img src={getUserPhoto(userObj.icon)} alt="" className={cn({})} />
      <div
        className="absolute bottom-[0] right-[0] font-[700] text-[.32rem] leading-[.4rem] text-[var(--colour-26)]"
        style={{ WebkitTextStrokeWidth: '.08rem', WebkitTextStrokeColor: 'var(--colour-35)' }}
      >
        V{userObj.vip_level}
      </div>
      <div className="absolute bottom-[0] right-[0] font-[700] text-[.32rem] leading-[.4rem] text-[var(--colour-26)]">
        V{userObj.vip_level}
      </div>
    </div>
  ) : (
    <></>
  );
};
const HeaderUserinfomsg = () => {
  const { t } = useTranslation();
  const { userObj } = useSelector(
    (state) => ({
      userObj: get(state, 'user.userObj'),
    }),
    shallowEqual,
  );

  return userObj ? (
    <>
      <div className="font-[500] text-[.24rem] leading-[.3rem] mb-[.17rem] text-[var(--colour-40)]">
        {omitStr(userObj.nickname)}
      </div>
      <div className="font-[500] text-[.24rem] leading-[.3rem] flex items-center space-x-[.16rem] mb-[.37rem] text-[var(--colour-37)]">
        <div>
          {t('home.userInfo_id')}:{omitStr(userObj.uid)}
        </div>
        <div
          className="w-[.4rem] h-[.4rem] flex items-center justify-center cursor-pointer bg-[var(--colour-34)] rounded-normal"
          onClick={() => {
            copy(`${userObj.uid}`);
            toast.success(t('home.toast006'), {
              containerId: 'global',
            });
          }}
        >
          <div className="w-[.2rem] h-[.2rem] text-[var(--colour-38)]">
            <Icon name={IconName.Copy}></Icon>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

const HeaderUserinfoItem1 = ({ item, click, index, len }) => {
  const {} = useSelector((state) => ({}), shallowEqual);
  return (
    <div
      className={cn(
        'w-[4.32rem] h-[.8rem] flex items-center space-x-[.32rem] pl-[.3rem] font-[700] text-[.24rem]  active:scale-[.95] transition-all select-none cursor-pointer bg-[var(--colour-34)] rounded-normal text-[var(--colour-38)]',
        {
          'mb-[.3rem]': len - 1 === index,
          'mb-[.12rem]': len - 1 !== index,
        },
      )}
      onClick={(e) => {
        click();
        e.stopPropagation();
      }}
    >
      <div className="w-[.32rem] h-[.32rem] text-[var(--colour-37)]">{item.icon}</div>
      <div>{item.name}</div>
    </div>
  );
};

const HeaderUserinfoItem2 = ({ item, soundFlag, click }) => {
  const {} = useSelector((state) => ({}), shallowEqual);
  return (
    <div className="w-[4.32rem] h-[.8rem] flex items-center justify-between pl-[.3rem] pr-[.3rem] font-[700] text-[.24rem]  mb-[.12rem] bg-[var(--colour-34)] rounded-normal text-[var(--colour-38)]">
      <div className="flex items-center space-x-[.32rem]">
        <div className="w-[.32rem] h-[.32rem] text-[var(--colour-37)]">{item.icon}</div>
        <div className="">{item.name}</div>
      </div>
      <div
        className="w-[.48rem] h-[.24rem] rounded-[.2rem] relative cursor-pointer bg-[var(--colour-51)]"
        onClick={(e) => {
          click();
          e.stopPropagation();
        }}
      >
        <div
          className={`absolute w-[.24rem] h-[.24rem] rounded-[50%] bg-[#ffffff] ${
            soundFlag ? 'right-[0rem]' : 'left-[0rem]'
          }`}
        ></div>
      </div>
    </div>
  );
};

const HeaderUserinfoItem3 = ({ item, click, index, len }) => {
  const { websiteInitConfig, unReadCount } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      unReadCount: get(state, 'app.unReadCount'),
    }),
    shallowEqual,
  );

  return (
    <div
      className={cn(
        'w-[4.32rem] h-[.8rem] flex items-center space-x-[.32rem] pl-[.3rem] font-[700] text-[.24rem] active:scale-[.95] transition-all select-none cursor-pointer bg-[var(--colour-34)] rounded-normal text-[var(--colour-38)]',
        {
          'mb-[.3rem]': len - 1 === index,
          'mb-[.12rem]': len - 1 !== index,
        },
      )}
      onClick={(e) => {
        click();
        e.stopPropagation();
      }}
    >
      <div className="relative">
        <div className="w-[.32rem] h-[.32rem] text-[var(--colour-37)]">{item.icon}</div>
        {unReadCount > 0 ? (
          <div className="w-[.2rem] h-[.2rem] rounded-full bg-[var(--colour-25)] border border-[var(--colour-50)] flex justify-center items-center font-[600] absolute right-[-0.1rem] top-[-0.1rem]"></div>
        ) : (
          <></>
        )}
      </div>

      <div>{item.name}</div>
    </div>
  );
};
