// import PopBg from '../../../components/popBg';
import PopBgFullScreen from '../../../components/popBgFullScreen';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState, useRef, forwardRef } from 'react';
import cn from 'classnames';
import InviteLink from './inviteLink';
import { get } from 'lodash';
import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setRefetchActivityData,
} from '../../../../../redux/reducers/appSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPercentage, getShowMoney } from '../../../../../utils/helper';
// import StepBoxListResponsive from '../../../../stepBoxListResponsive';
// import BottomBox from './bottomBox';
import { getCurrentTaskFinishPeopleNum, getFinishedPeopleTotalNum, isRunningTaskExit } from './helper';
import DialogModal from '../../../index';
import ScrollWrapper from '../../../../scrollWrapper';
import Close from '../../../../close2';
import Icon, { IconName } from '../../../../icon';
import Sticky from 'react-sticky-el';
// import DownloadManager from '../../../manager/downloadManager';
import BottomDownload from '../../../downLoadBtn';
import StepBoxList from '../../../../stepBoxList/index';

const fakeStepList = Array(16)
  .fill()
  .map((_, index) => ({
    text1: `${index + 1}people`,
    text2: '2000',
    mode: ['default', 'opened', 'done'][Math.floor(Math.random() * 3)],
    progressPercent: Math.floor(Math.random() * 100),
    onClick: () => {
      alert(index);
    },
  }))
  .concat([
    {
      text1: 'Coming Soon',
      text2: '',
      mode: 'default',
      progressPercent: 0,
      onClick: null,
    },
  ]);

const Popup = ({ children, handleClose, allowScroll, isShowCloseBtn, className = '', viewportClassName = '' }) => {
  return (
    <PopBgFullScreen
      viewportClassName={viewportClassName}
      handleClose={handleClose}
      allowScroll={allowScroll}
      isShowCloseBtn={isShowCloseBtn}
      className={className}
    >
      {children}
    </PopBgFullScreen>
  );
};

export default function Index({ data, handleClose = () => {}, isShowCloseBtn, showStyle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currency_symbol, defaultLang } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });
  const [uptoAmount, setUptoAmount] = useState(0);
  const [textList, setTextList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [peopleNum, setPeopleNum] = useState(0);
  const [directReportNum, setDirectReportNum] = useState(0);
  const [isShowRule, setIsShowRule] = useState(false);

  // const updateData = () => {
  //   GetReferralInviteList().then((res) => {
  //     if (res.code === 0) {
  //       const result = res.result;
  //       const _directReport = result.bonus.finished_num || 0;
  //       setDirectReport(_directReport);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   updateData();
  // }, []);

  useEffect(() => {
    if (data) {
      const taskList = data?.task_config || [];

      setPeopleNum(getCurrentTaskFinishPeopleNum(taskList));
      setDirectReportNum(getFinishedPeopleTotalNum(taskList));
      setUptoAmount(
        taskList.reduce((acc, curr) => {
          return acc + Number(curr?.reward_amount || 0);
        }, 0),
      );

      // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,
      const currentTask = taskList.find((item) => [10, 11].includes(Number(item.task_status)));

      const text1Amount = Number(currentTask?.deposit_amount) || 0;
      const text2Amount = Number(currentTask?.wager_amount) || 0;
      const text3Amount = Number(currentTask?.games_num) || 0;
      const text4Amount = Number(currentTask?.vip_level) || 0;

      const _textList = [
        {
          amount: text1Amount,
          text: (
            <Trans
              t={t}
              i18nKey="referral.current_task_total_deposit"
              components={[<span className="text-[var(--colour-24)] ml-[.1rem]" key={0}></span>]}
              values={{
                val: `${currency_symbol}${getShowMoney(text1Amount)}`,
              }}
            />
          ),
        },
        {
          amount: text2Amount,
          text: (
            <Trans
              t={t}
              i18nKey="referral.current_task_total_bet"
              components={[<span className="text-[var(--colour-24)] ml-[.1rem]" key={0}></span>]}
              values={{
                val: `${getShowMoney(text2Amount)}`,
              }}
              key={1}
            />
          ),
        },
        {
          amount: text3Amount,
          text: (
            <Trans
              t={t}
              i18nKey="referral.current_task_complete_games"
              components={[<span className="text-[var(--colour-24)] mx-[.04rem]" key={0}></span>]}
              values={{
                val: text3Amount,
              }}
              key={2}
            />
          ),
        },
        {
          amount: text4Amount,
          text: (
            <Trans
              t={t}
              i18nKey="referral.current_task_reach_vip"
              components={[<span className="text-[var(--colour-24)] mx-[.1rem]" key={0}></span>]}
              values={{
                val: text4Amount,
              }}
              key={3}
            />
          ),
        },
      ].filter((item) => Number(item.amount) > 0);

      setTextList(_textList);

      const _taskList = taskList.map((item) => {
        // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,
        const isExpired = item?.task_status == 30;

        return {
          text1: isExpired ? t('giftMoney_filterStatus.expired') : `${item?.people_num} ${t('referral.people')}`,
          text2: getShowMoney(item?.reward_amount || 0),
          mode: getMode(item?.task_status),
          progressPercent:
            currentTask?.tap_position == item?.tap_position
              ? getPercentage(Number(item?.finish_people_num || 0), Number(item?.people_num || 1))
              : 0,
          onClick: null,
        };
      });

      const comingList = (() => {
        const comingCount = _taskList.length % 4 == 0 ? 0 : 4 - (_taskList.length % 4);
        return Array(comingCount)
          .fill()
          .map(() => {
            return {
              text1: t('referral.coming_soon'),
              text2: '',
              mode: 'comingSoon',
              progressPercent: 0,
              onClick: null,
            };
          });
      })();

      setTaskList(_taskList.concat(comingList));
    }
  }, [data, defaultLang]);

  // const getMode_old = (is_claimed) => {
  //   // 状态 0：不可领奖；1：可领奖；2：已领奖
  //   switch (is_claimed) {
  //     case '0':
  //       return 'default';
  //     case '1':
  //       return 'done';
  //     case '2':
  //       return 'opened';
  //     default:
  //       return 'default';
  //   }
  // };

  const getMode = (taskStatus) => {
    // task_status: 10 未完成, 11 结算中, 20 未到领奖时间, 21 可领取, 22 已领取, 30 奖励过期,
    switch (taskStatus) {
      case 10:
      case 11:
      case 20:
      case 30:
        return 'default';
      case 21:
        return 'done';
      case 22:
        return 'opened';
      default:
        return 'default';
    }
  };

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '51',
          },
        }}
        isOpen={isShowRule}
        handleClose={() => setIsShowRule(false)}
      >
        <div className="bg-[var(--colour-35)] w-[6.86rem] h-[9.84rem] px-[.32rem] rounded-normal">
          <div className="flex h-[1.12rem] items-center justify-between">
            <div className="flex items-center space-x-[.1rem]">
              <div
                className="text-[var(--colour-11)] w-[.2rem] h-[.28rem] mr-[.1rem]"
                onClick={() => {
                  setIsShowRule(false);
                }}
              >
                <Icon name={IconName.BackArrow} />
              </div>
              <span className="text-[.32rem] text-[var(--colour-48)]"> {t('referral.rule_description')}</span>
            </div>
            <div className="w-[.72rem] h-[.72rem] flex justify-center items-center rounded-small">
              <Close
                handleClose={() => {
                  setIsShowRule(false);
                }}
              />
            </div>
          </div>
          <ScrollWrapper className="mt-[.36rem] w-full h-[8rem] wrap-break-word text-[.28rem] wrap-break-word text-[var(--colour-44)]">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.rule_explain?.activity_rule_explain,
              }}
            ></div>
          </ScrollWrapper>
        </div>
      </DialogModal>

      <Popup
        handleClose={handleClose}
        allowScroll={true}
        isShowCloseBtn={isShowCloseBtn}
        className="relative"
        viewportClassName="promote-achievement-scroll-box"
      >
        <div className={cn('w-full')}>
          <div className="m-[.2rem_.32rem_0_.32rem]">
            <InviteLink
              money={`${currency_symbol}${getShowMoney(uptoAmount)}`}
              peopleNum={peopleNum}
              directReportNum={directReportNum}
              isAnyRunningTask={isRunningTaskExit(data?.task_config)}
            />
            <Sticky
              scrollElement=".promote-achievement-scroll-box"
              stickyClassName="bg-[var(--colour-35)] abs-x-center-with-translateZ z-[2] px-[.1rem]"
              topOffset={-30}
            >
              <div className="">
                {isRunningTaskExit(data?.task_config) && (
                  <>
                    <div className="text-[.24rem] text-[var(--colour-48)] text-center my-[.16rem]">
                      <p>{t('referral.what_is_number1')}</p>
                      <p>{t('referral.what_is_number2')}</p>
                    </div>

                    {textList.length > 0 && (
                      <div className="grid grid-cols-2 gap-[.1rem] text-[.24rem]">
                        {textList.map((item, index) => (
                          <div
                            key={index}
                            className="p-[.1rem] bg-[var(--colour-34)] text-[var(--colour-44)] rounded-normal flex items-center justify-center wrap-break-word"
                          >
                            {item.text}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}

                <p
                  onClick={() => {
                    setIsShowRule(true);
                  }}
                  className="text-[.28rem] cursor-pointer text-[var(--colour-24)] underline underline-offset-[.12rem] text-center m-[.2rem_0_.16rem_0]"
                >
                  {t('referral.rule_description')}
                </p>
              </div>
            </Sticky>
            <p className="h-[1px] bg-[var(--colour-6)]"></p>
            <div className="p-[.4rem_0_.2rem]">
              {/* <StepBoxListResponsive list={taskList} showStyle={showStyle} /> */}
              <StepBoxList list={taskList} showStyle={showStyle} />
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}
