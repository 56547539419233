import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useEffect, useRef, useState, createRef } from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import Sticky from 'react-sticky-el';
import { useStateIfMounted } from 'use-state-if-mounted';
import { setAutoScrollToTagGameKey } from '../../../../../redux/reducers/appSlice';
import { cmsImgUrl } from '../../../../../utils/helper';
import SkeletonBox from '../../../../../components/skeletonBox';
import AnalyticsManager, { FirebaseEvents } from '../../../../../manager/analyticsManager';
import scrollIntoView from 'scroll-into-view';
import { useInView } from 'react-intersection-observer';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig';
import useDraggableScroll from '../../../../../hooks/useDraggableScroll';

const getAnalyticsManagerName = (id) => {
  if (id == '000') {
    return FirebaseEvents.MP_Click_Lobby;
  } else if (id == '001') {
    return FirebaseEvents.MP_Click_Favorite;
  } else if (id == '002') {
    return FirebaseEvents.MP_Click_All;
  } else if (id == '003') {
    return FirebaseEvents.MP_Click_Recently;
  } else {
    return FirebaseEvents.MP_Click_ + id;
  }
};

const TagsList = ({ tagsArr, tagsIndex, setTagsIndex, tagsResIndex }) => {
  const dispatch = useDispatch();
  const refScroller = useRef(null);
  const [elRefs, setElRefs] = useState([]);
  const { getServerLangText } = useLangServerConfig();
  const { autoScrollToTagGameKey } = useSelector(
    (state) => ({
      autoScrollToTagGameKey: get(state, 'app.autoScrollToTagGameKey'),
    }),
    shallowEqual,
  );
  const { ref } = useInView({
    threshold: 0,
  });

  useDraggableScroll(refScroller);

  useEffect(() => {
    let _timer = null;
    if (autoScrollToTagGameKey != '0') {
      _timer = window.setTimeout(() => {
        scrollToItem(autoScrollToTagGameKey);
      }, 0);
    }
    return () => {
      window.clearTimeout(_timer);
    };
  }, [autoScrollToTagGameKey, tagsArr.length, elRefs.length]);

  const scrollToItem = (gameKey) => {
    if (tagsArr && tagsArr.length > 0) {
      const gameTabIndex = Array.from(tagsArr).findIndex((item) => {
        return item.category_id == gameKey;
      });
      if (gameTabIndex > -1) {
        const elm = document.querySelector('.tags_sticky');
        if (elm) {
          scrollIntoView(elm, {
            time: 500,
            align: {
              top: 0,
              left: 0,
            },
          });
        }

        if (elRefs[gameTabIndex] && elRefs[gameTabIndex].current) {
          scrollIntoView(elRefs[gameTabIndex].current, {
            time: 500,
            validTarget: (target) => {
              return target === refScroller.current;
            },
          });
          if (tagsIndex != gameKey) {
            setTagsIndex(gameKey);
            tagsResIndex(gameKey);
          }
          dispatch(setAutoScrollToTagGameKey('0'));
        }
      }
    }
  };

  const handleClickTag = (category_id) => {
    setTagsIndex(category_id);
    tagsResIndex(category_id);
    AnalyticsManager.FirebaseLogEvent(getAnalyticsManagerName(category_id));
    dispatch(setAutoScrollToTagGameKey(category_id));
  };

  useEffect(() => {
    if (tagsArr) {
      setElRefs((elRefs) =>
        Array(tagsArr.length)
          .fill('')
          .map((_, i) => elRefs[i] || createRef()),
      );
    }
  }, [tagsArr]);

  return (
    <div ref={ref} className={cn('flex w-full px-[.28rem] bg-[var(--colour-9)]')}>
      <div
        className={cn(
          'overflow-x-auto flex w-full scrollbar-none select-none bg-[url(../assets/img/tagsearch.png)] bg-[length:100%_100%] bg-no-repeat',
        )}
        ref={refScroller}
      >
        {tagsArr &&
          tagsArr.map((item, index) =>
            item.category_id != '001' && item.category_id != '003' ? (
              <SkeletonBox
                props={{ containerClassName: 'flex h-[1.38rem] relative', className: '' }}
                key={index}
                loading={item == null}
              >
                <span
                  ref={elRefs[index]}
                  className={`grow-[1] flex h-[1.38rem] justify-center ${tagsIndex == item.category_id ? `tagsearch_${tagsIndex}` : ''}`}
                  key={index}
                >
                  <div
                    onClick={() => {
                      handleClickTag(item.category_id);
                    }}
                    className={`flex flex-col items-center whitespace-nowrap min-w-[1.4rem] relative ${
                      tagsIndex === item.category_id ? 'text-[var(--colour-13)]' : 'text-[var(--colour-14)]'
                    }`}
                  >
                    <span
                      className={cn(
                        `w-[.72rem] h-[.72rem] mt-[.26rem] ${tagsIndex == item.category_id ? 'ionImgActive' : ''}`,
                      )}
                    >
                      <img
                        className={`h-full not-drag ${tagsIndex === item.category_id ? '' : 'opacity-[.4]'}`}
                        src={cmsImgUrl(item.icon)}
                      />
                    </span>
                    <span className="text-center text-[.2rem] font-[500] mt-[-.04rem]">
                      {getServerLangText(item.lang_key)}
                    </span>
                    {tagsIndex == item.category_id ? (
                      <div
                        className={`absolute w-[.16rem] bg-[var(--colour-81)] h-[.04rem] abs-x-center bottom-[.02rem]`}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                </span>
              </SkeletonBox>
            ) : null,
          )}
      </div>
    </div>
  );
};

export default function Index({ tagsArr, tagsIndex, setTagsIndex, tagsResIndex }) {
  const [isFixedToggle, setIsFixedToggle] = useStateIfMounted(false);
  const [positionRefTop, setPositionRefTop] = useStateIfMounted(0);

  const positionRef = useRef(null);
  const handleOnFixedToggle = (isFixed) => {
    setIsFixedToggle(isFixed);
  };

  useEffect(() => {
    if (isFixedToggle) {
      window.scrollTo({
        top: positionRefTop,
        behavior: 'smooth',
      });
    }
  }, [tagsIndex]);

  useEffect(() => {
    if (positionRef && positionRef.current) {
      setPositionRefTop(positionRef.current.getBoundingClientRect().top);
    }
  }, []);

  return (
    <Sticky
      positionRecheckInterval={200}
      onFixedToggle={handleOnFixedToggle}
      stickyClassName="tags-list-sticky"
      topOffset={-110}
    >
      <div ref={positionRef}></div>
      <div className={cn('w-full relative')}>
        <TagsList tagsArr={tagsArr} tagsIndex={tagsIndex} setTagsIndex={setTagsIndex} tagsResIndex={tagsResIndex} />
      </div>
    </Sticky>
  );
}
