import { useEffect, useState, useRef, forwardRef, useImperativeHandle, createRef, Fragment } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { unionWith, isEqual, cloneDeep } from 'lodash';
import Back from '../../../../components/back';
import Countdown, { zeroPad } from 'react-countdown';
import {
  setNeedUpdateBalance,
  DialogModalChildrenKey,
  setDialogModalVisible,
  setShowHeaderGiftMoney,
  setNeedUpdateGiftMoney,
} from '../../../../redux/reducers/appSlice';
import BottomDownload from '../../../../components/dialogModal/downLoadBtn';
import DialogModal from '../../../../components/dialogModal';

import Icon, { IconName } from '../../../../components/icon';
import ProgressBar from '../../../../components/progressBar2';
import { Navigation, Pagination } from 'swiper/modules';
import {
  cmsImgUrl,
  getPercentage,
  isElementVisibleInParent,
  isLogin,
  openHeaderGiftMoney,
} from '../../../../utils/helper';
import Loading from '../../../../components/loading2';
import GameItem from '../../../../components/gameItem';
import LoadMore from '../../../../components/loadMore';
import LoadingMoreWithText from '../../../../components/loadingMoreWithText';
import NoData from '../../../../components/noData';
import { toast } from 'react-toastify';
import { getShowMoney } from '../../../../utils/helper';
import {
  ClaimActivityReq,
  ClaimAllActivityReq,
  GetActivityListReq,
  GetActivityGamesReq,
  ClaimReliefReq,
  ActivityAllReq,
  PromotionActivitySettle,
} from '../../../../api';
import Custom404 from '../../../custom404';
import LimitClickWrapper from '../../../../components/limitClickWrapper';
import useActivityStatus from '../../../../hooks/useActivityStatus';
import { get } from 'lodash';
import DownloadManager from '../../../../manager/downloadManager';
import { getCenterPosition } from '../../../../hooks/useMinimizeAnimation';

const getRefreshTimeStr = (offsetTime) => {
  const oneMinute = 60 * 1;
  const oneHour = 60 * oneMinute;
  const oneDay = 24 * oneHour;
  if (offsetTime < oneMinute) {
    return '1m';
  } else if (offsetTime < oneHour) {
    return `${Math.floor(offsetTime / oneMinute)}m`;
  } else if (offsetTime < oneDay) {
    return `${Math.floor(offsetTime / oneHour)}h`;
  } else {
    return `${Math.floor(offsetTime / oneDay)}d`;
  }
};

const Banner = ({ list, handleChangeCallback }) => {
  const handleOnRealIndexChange = (evt) => {
    const id = evt.slides[evt.activeIndex].dataset.id;
    handleChangeCallback(id);
  };

  return (
    <div className={`relative mb-[.12rem]`}>
      {list && list.length > 0 && (
        <div>
          <Swiper
            onAfterInit={handleOnRealIndexChange}
            onRealIndexChange={handleOnRealIndexChange}
            spaceBetween={0}
            slidesPerView={1}
            speed={1000}
            loop={true}
            grabCursor={true}
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{
              clickable: true,
              el: '.swiper-pagination-task',
            }}
            // onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {list.map((item, index) => (
              <SwiperSlide key={item.id} data-id={item.id}>
                <div className="cursor-pointer overflow-hidden rounded-small">
                  <img src={cmsImgUrl(item.img)} className="w-full h-auto" alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination-task left-[0px] w-full" style={{ bottom: `-0.48rem` }}></div>
          <div className="swiper-button-prev content-none" style={{ zIndex: '0' }}>
            <div className="swiper-button-prev-icon">
              <Icon name={IconName.BannerNext2}></Icon>
            </div>
          </div>

          <div className="swiper-button-next content-none">
            <div className="swiper-button-next-icon">
              <Icon name={IconName.BannerNext2}></Icon>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const TaskRules = ({ actInfo }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const handleShowAll = () => {
    setShowAll(showAll ? false : true);
  };
  return (
    <div className="w-full flex flex-col rounded-normal text-[var(--colour-38)] bg-[var(--colour-34)] overflow-hidden mb-[.2rem]">
      <div
        className="flex flex-row items-center justify-between h-[.6rem] bg-[var(--colour-47)] px-[.24rem] rounded-normal cursor-pointer"
        onClick={handleShowAll}
      >
        <span className="text-[.24rem]">
          {['', t('task.taskRules'), t('task.reliefMoneyRules')][(actInfo && actInfo.activity_type_id) || 0]}
        </span>
        <span
          className={cn('cursor-pointer w-[.16rem] h-[.32rem] transition-chat', {
            'rotate-180': showAll,
          })}
        >
          <Icon name={IconName.ChatArraw} />
        </span>
      </div>
      {showAll && actInfo != null ? (
        <div
          className="font-[400] py-[.3rem] text-[.24rem] px-[.32rem]"
          dangerouslySetInnerHTML={{ __html: actInfo.rule_explain.activity_rule_explain }}
        ></div>
      ) : (
        <></>
      )}
    </div>
  );
};

const TaskOptBtn = ({ type, handleClick }) => {
  const { t } = useTranslation();

  return type == 21 ? (
    // return true ? (
    <LimitClickWrapper
      onClick={handleClick}
      className="text-[var(--colour-2)] uppercase bg-[var(--colour-1)] w-[1.44rem] h-[.52rem] rounded-normal font-[700] text-[.24rem] flex items-center justify-center cursor-pointer task_claim_btn_claimable"
    >
      {t('task.claim')}
    </LimitClickWrapper>
  ) : type == 10 ? (
    <LimitClickWrapper
      onClick={handleClick}
      className="text-[var(--colour-3)] uppercase border border-[var(--colour-1)] bg-[var(--colour-5)] w-[1.44rem] h-[.52rem] rounded-normal font-[700] text-[.24rem] flex items-center justify-center cursor-pointer"
    >
      {t('task.go')}
    </LimitClickWrapper>
  ) : type == 22 ? (
    <div className="w-[1.44rem] flex items-center justify-center">
      <span className="w-[.44rem] h-[.34rem] text-[var(--colour-24)] mx-auto">
        <Icon name={IconName.Complete} />
      </span>
    </div>
  ) : type == 30 ? (
    <div className="w-[1.44rem] flex items-center justify-center">
      <span className="w-[auto] h-[auto] text-[var(--colour-49)] mx-auto white-space-nowrap">
        {t('promotion.award_expiration')}
      </span>
    </div>
  ) : type == 11 ? (
    <div className="w-[1.44rem] flex items-center justify-center">
      <span className="w-[auto] h-[auto] text-[var(--colour-1)] mx-auto white-space-nowrap text-[.24rem] font-[700]">
        {t('promotion.calculating_result')}
      </span>
    </div>
  ) : (
    <></>
  );
};

const TwoNumber = ({ item = '', index, length, className2 }) => {
  const { t } = useTranslation();
  const _txtClass = 'text-center w-[.16rem]';
  const _dayClass = 'text-center w-[.56rem]';
  const _singleDayClass = 'text-center w-[.44rem]';

  const SplitTime = (time) => {
    const timeValue = String(time ?? '00');
    if (timeValue.length === 1) {
      return (
        <div
          key={`${item.text}-single`}
          className={`${item.text != '' ? (String(time).length > 1 ? _dayClass : _singleDayClass) : _txtClass}`}
        >
          {time}
          {item.text != '' ? <span className="mx-[.1rem]">{item.text}</span> : null}
        </div>
      );
    } else {
      let arr = item.text != 'd' ? (timeValue ?? '00').split('') : [timeValue];
      if (item.text == 'd' && arr[0].length > 2) {
        arr = ['99'];
      }
      return (
        arr &&
        arr.map((it, idx) => {
          const uniqueKey = `${it}-${idx}`;
          return (
            <Fragment key={uniqueKey}>
              <div className={`${String(it).length > 1 ? _dayClass : _txtClass}`}>
                {it}
                {item.text != '' ? <span className="mx-[.1rem]">{item.text}</span> : null}
              </div>
            </Fragment>
          );
        })
      );
    }
  };

  return (
    <>
      <div
        className={`${className2} ${item.text == 'd' ? '' : ''} relative w-full flex flex-row items-center justify-center`}
      >
        {SplitTime(item.time)}
        <div className="relative h-[.2rem] w-[.06rem]">
          {index < length - 1 && item.text != 'd' ? <span className="absolute abs-x-center top-[-0.1rem]">:</span> : ''}
        </div>
      </div>
    </>
  );
};

const TimeCountdown = ({ date = Date.now(), className = '', className2 = '', onComplete = () => {} }) => {
  const { t } = useTranslation();
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const list = completed
      ? ['00', '00', '00', '00']
      : [
          {
            text: 'd',
            time: days,
          },
          {
            text: '',
            time: zeroPad(hours, 2),
          },
          {
            text: '',
            time: zeroPad(minutes, 2),
          },
          {
            text: '',
            time: zeroPad(seconds, 2),
          },
        ];
    return list.map((item, index) => (
      <TwoNumber key={index} item={item} index={index} length={list.length} className2={className2} />
    ));
  };

  return (
    <>
      <div className={`flex items-center justify-center select-none ${className}`}>
        <Countdown date={date} onComplete={onComplete} renderer={renderer} />
      </div>
    </>
  );
};

const ReliefFundTaskOptBtn = ({
  type,
  handleClick,
  endTime = 0,
  handleTimeComplete = () => {},
  activityEndTime = 0,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [timerComplete, setTimerComplete] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [timerId, setTimerId] = useState(null);
  const { userObj } = useSelector(
    (state) => ({
      userObj: state.user.userObj,
    }),
    shallowEqual,
  );
  const handleGo = () => {
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.LoginBox,
      }),
    );
  };

  const handleComplete = () => {
    setTimerComplete(true);
    handleTimeComplete();
  };

  useEffect(() => {
    setTimerComplete(false);
  }, [type, endTime]);

  useEffect(() => {
    if (activityEndTime > 0) {
      const id = setInterval(() => {
        setCurrentTimestamp(new Date().getTime());
      }, 1000);
      setTimerId(id);
      return () => {
        clearInterval(timerId); // 组件卸载时清除定时器
      };
    }
  }, [activityEndTime]);

  return userObj != null ? (
    activityEndTime * 1000 < currentTimestamp ? (
      <span className="w-[2.16rem] h-[.76rem] font-[400] text-[var(--colour-41)] text-[.24rem] flex flex-col items-center justify-center">
        {t('task.ended')}
      </span>
    ) : (
      <>
        {type ? (
          <>
            {type == 21 ? (
              // {true ? (
              <LimitClickWrapper
                onClick={handleClick}
                className="bg-[var(--colour-1)] w-[2.16rem] h-[.76rem] rounded-normal font-[700] text-[.24rem] flex items-center justify-center cursor-pointer text-[var(--colour-2)]"
              >
                {t('promotion.claim')}
              </LimitClickWrapper>
            ) : type == 11 ? (
              <div className="w-[2.16rem] h-[.76rem] font-[700] text-[.24rem] flex items-center justify-center text-[var(--colour-1)]">
                {t('promotion.calculating_result')}
              </div>
            ) : (
              <div className="bg-[var(--colour-61)] w-[2.16rem] h-[.76rem] font-[700] text-[.24rem] flex flex-col items-center justify-center cursor-pointer rounded-[.16rem]">
                <span className="text-[var(--colour-41)] font-[400] text-[.24rem]">{t('task.claimTime')}</span>
                {!timerComplete ? (
                  <span className={cn('font-[700] text-[.24rem] text-[var(--colour-38)]')}>
                    <TimeCountdown date={endTime * 1000} onComplete={handleComplete} />
                  </span>
                ) : null}
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    )
  ) : (
    <LimitClickWrapper
      onClick={handleGo}
      className="bg-[var(--colour-1)] w-[2.16rem] h-[.76rem] rounded-normal font-[700] text-[.24rem] flex items-center justify-center cursor-pointer text-[var(--colour-2)]"
    >
      {t('task.go')}
    </LimitClickWrapper>
  );
};

const ReliefFundShowMoney = ({ type, money = 0 }) => {
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  return (
    <span
      className={cn('leading-[.28rem] font-[700] text-[.24rem]', {
        // 'text-[var(--colour-26)]': type && type == 1,
        'text-[var(--colour-24)]': type && type != 20,
        'text-[var(--colour-24)] line-through': type && type == 20,
      })}
    >
      {websiteInitConfig?.website_info?.currency_symbol || ''}&nbsp;
      {getShowMoney(money, 2, 2)}
    </span>
  );
};

// 闯关任务
const TaskItem = ({ itemData, handleGo, handleClaim, actInfo, actType = 'task' }) => {
  const { t } = useTranslation();
  const num = actInfo.user_total_bet_amount || 0;
  const userTapPosition = actInfo.user_tap_position;

  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  // type-> 0:领取;1:go;2:完成
  // const getType = () => {
  //   let type = 1;
  //   if (Number(num) >= Number(itemData.total_bet_amount)) {
  //     if (userTapPosition.includes(itemData.tap_position)) {
  //       type = 2;
  //     } else {
  //       type = 0;
  //     }
  //   }
  //   return type;
  // };

  // const handleClick = () => {
  //   if (getType() == 1) {
  //     handleGo();
  //   } else {
  //     handleClaim(itemData.tap_position, getShowMoney(itemData.balance, 2, 2), actType);
  //   }
  // };

  const handleClick = (event) => {
    if (itemData.task_status == 10) {
      // if (false) {
      handleGo();
    } else if (itemData.task_status == 21) {
      // } else if (true) {
      handleClaim(itemData.tap_position, getShowMoney(itemData.balance, 2, 2), actType, event?.currentTarget);
    }
  };

  return (
    <div className="rounded-normal bg-[var(--colour-35)] w-full flex flex-row items-center justify-between py-[.12rem] px-[.4rem]">
      <div className="flex flex-col items-start justify-between space-y-[.08rem]">
        <span className="text-[.24rem] w-[2.9rem] text-[var(--colour-40)]">
          {t('task.awardTitle', {
            val: getShowMoney(itemData.total_bet_amount),
          })}
        </span>
        <div className="flex items-center justify-center">
          <div className="flex-1 relative">
            <ProgressBar
              num={num}
              totalNum={itemData.total_bet_amount}
              overrideNoClassName="relative h-[.32rem] bg-[var(--colour-30)] rounded-normal border border-[var(--colour-6)]"
              overrideSelectClassName="absolute left-[0px] top-[0px] bottom-[0px] rounded-normal bg-[var(--colour-29)]"
            ></ProgressBar>
            <div className={cn('font-[500] absolute abs-center z-[1] text-[.24rem] text-[var(--colour-56)]')}>
              {Number(num) > Number(itemData.total_bet_amount)
                ? getShowMoney(itemData.total_bet_amount)
                : getShowMoney(num)}
              /{getShowMoney(itemData.total_bet_amount)}
            </div>
          </div>
          <span className="text-[.24rem] ml-[.08rem] text-[var(--colour-40)]">
            {getPercentage(num, itemData.total_bet_amount) > 100 ? 100 : getPercentage(num, itemData.total_bet_amount)}%
          </span>
        </div>
      </div>
      <div className="flex flex-col justify-center items-start space-y-[.1rem]">
        <div className="text-[var(--colour-26)] font-[600] text-[.24rem] text-left relative z-[1]">
          {websiteInitConfig?.website_info?.currency_symbol || ''}&nbsp;
          {getShowMoney(itemData.balance, 2, 2)}
        </div>
        {/* <TaskOptBtn handleClick={handleClick} type={getType()} /> */}
        <TaskOptBtn handleClick={handleClick} type={itemData.task_status} />
      </div>
    </div>
  );
};

// 救援金
const ReliefMoneyItem = ({
  itemData,
  handleGo,
  handleClaim,
  actInfo,
  actType = 'relief',
  handleTimeComplete = () => {},
}) => {
  const { t } = useTranslation();
  const num = actInfo.user_total_loss_amount || 0;
  const userTapPosition = actInfo.user_tap_position;
  const {} = useSelector((state) => ({}), shallowEqual);

  const RefreshPromotionActivitySettle = async () => {
    if (isLogin()) {
      await PromotionActivitySettle();
    }
  };

  useEffect(() => {
    RefreshPromotionActivitySettle();
  }, []);

  // // type-> 0:领取;1:go;2:完成
  // const getType = () => {
  //   let type = 1;
  //   if (Number(num) >= Number(itemData.total_loss_amount) && Number(actInfo.user_total_loss_amount) > 0) {
  //     if (userTapPosition.includes(itemData.tap_position)) {
  //       type = 2;
  //     } else {
  //       type = 0;
  //     }
  //   }
  //   return type;
  // };

  // const getMaxBalance = (money) => {
  //   money = Number(money) <= 0 ? 0 : Number(money);
  //   return Number(money) >= Number(actInfo.activity_claim_ceiling_amount)
  //     ? Number(actInfo.activity_claim_ceiling_amount)
  //     : Number(money);
  // };

  // const getShowBalance = () => {
  //   //活动奖励方式 1：balance （奖励金额）2：balance_ratio
  //   let showBalance = 0;
  //   if (actInfo.activity_reward_mode == 1) {
  //     showBalance = Number(itemData.balance);
  //   } else if (actInfo.activity_reward_mode == 2) {
  //     showBalance = Number(actInfo.user_total_loss_amount) * Number(itemData.balance_ratio);
  //   }
  //   return getMaxBalance(showBalance);
  // };

  const handleClick = (event) => {
    handleClaim(itemData.tap_position, getShowMoney(itemData.claim_amount, 2, 2), actType, event?.currentTarget);
  };

  return (
    <div className="rounded-normal bg-[var(--colour-35)] w-full flex flex-row items-center justify-between py-[.12rem] px-[.2rem]">
      <div className="flex-1 flex-col space-y-[.16rem]">
        <div className="flex flex-col items-start justify-center ">
          <span className="font-[400] text-[var(--colour-41)] text-[.24rem]">{t('task.lastLossAmount')}</span>
          <ReliefFundShowMoney type={1} money={itemData.total_loss_amount} />
        </div>
        <div className=" flex flex-col items-start justify-center ">
          <span className="font-[400] text-[var(--colour-41)] text-[.24rem]">{t('task.availableNowAmount')}</span>
          <ReliefFundShowMoney type={itemData.task_status} money={itemData.claim_amount} />
        </div>
      </div>
      <ReliefFundTaskOptBtn
        activityEndTime={itemData.activity_end_time}
        handleTimeComplete={handleTimeComplete}
        endTime={itemData.claimed_end_time}
        handleClick={handleClick}
        type={itemData.task_status}
      />
    </div>
  );
};

const TaskList = ({
  actInfo,
  handleGo,
  handleClaimCallback,
  handleClaimReliefCallback,
  handleTimeComplete = () => {},
}) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [canClaimed, setCanClaimed] = useState(false);
  useEffect(() => {
    if (actInfo != null) {
      if (actInfo.activity_type_id == 1) {
        setList(actInfo.activity_rewards);
      } else if (actInfo.activity_type_id == 2) {
        //救济金逻辑
        if (actInfo.activity_reward) {
          let showReward = cloneDeep(actInfo.activity_reward);
          showReward.activity_end_time = actInfo.activity_end_time;
          setList([showReward]);
        }
      }
    }
  }, [actInfo]);

  useEffect(() => {
    if (actInfo && actInfo.activity_type_id == 1) {
      if (list && list.length > 0) {
        let canClaimedTotal = 0;
        for (let i = 0; i < list.length; i++) {
          if (
            list[i].task_status == 21 &&
            Number(actInfo.user_total_bet_amount) >= Number(list[i].total_bet_amount) &&
            !Array.from(actInfo.user_tap_position).includes(list[i].tap_position)
          ) {
            canClaimedTotal++;
          }
        }
        if (canClaimedTotal > 0) {
          setCanClaimed(true);
        } else {
          setCanClaimed(false);
        }
      }
    }
  }, [list, actInfo]);

  const handleClaimAll = () => {
    const elementList = [...document.querySelectorAll('.task_claim_btn_claimable')];
    const visibleElementList = elementList.filter((item) => {
      return isElementVisibleInParent(item, document.querySelector('.app'));
    });

    ClaimAllActivityReq({
      activity_id: actInfo.id,
    }).then((res) => {
      // res = {
      //   ...res,
      //   code: 0,
      //   result: {
      //     tapPosition: [1, 2, 3],
      //     currency: 'gift_money',
      //     amount: 100,
      //   }
      // }

      const currency = res?.result?.currency;
      const amount = res?.result?.amount || 0;
      const activityRewards = res?.result?.activityRewards || [];
      const activityId = res?.result?.activityId;

      if (res.code == 0) {
        const tapPosition = res.result.tapPosition;
        let showMoney = 0;
        for (let i = 0; i < list.length; i++) {
          if (Array.from(tapPosition).includes(list[i].tap_position)) {
            showMoney += Number(list[i].balance);
          }
        }
        handleClaimCallback(
          tapPosition,
          getShowMoney(showMoney, 2, 2),
          visibleElementList,
          currency,
          amount,
          activityRewards,
        );
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };
  const handleClaim = (tapPosition, showMoney, type, element) => {
    const req = type == 'task' ? ClaimActivityReq : type == 'relief' ? ClaimReliefReq : null;
    if (req) {
      req({
        activity_id: actInfo.id,
        tap_position: tapPosition,
      }).then((res) => {
        // res = {
        //   ...res,
        //   code: 0,
        //   result: {
        //     currency: 'gift_money',
        //     amount: 100,
        //     tapPosition: [1, 2, 3],
        //   }
        // }

        const currency = res?.result?.currency;
        const amount = res?.result?.amount || 0;
        const activityRewards = res?.result?.activityRewards || [];
        const activityId = res?.result?.activityId;

        if (res.code == 0 || res.code == 1008127) {
          if (type == 'task') {
            const tapPosition = res.result.tapPosition;
            handleClaimCallback(tapPosition, showMoney, [element], currency, amount, activityRewards);
          } else {
            handleClaimReliefCallback(res, element);
          }
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      });
    }
  };

  return (
    <>
      <div className="w-full rounded-normal bg-[var(--colour-34)] p-[.2rem]">
        <div
          className="w-full bg-color-secondary-btn-2 py-[.2rem] rounded-normal flex flex-col items-center space-y-[.12rem]"
          style={{ borderBottomRightRadius: '0' }}
        >
          {actInfo &&
            list.map((item, index) =>
              actInfo.activity_type_id == 1 ? (
                <TaskItem handleGo={handleGo} itemData={item} handleClaim={handleClaim} key={index} actInfo={actInfo} />
              ) : actInfo.activity_type_id == 2 ? (
                <ReliefMoneyItem
                  handleGo={handleGo}
                  itemData={item}
                  handleClaim={handleClaim}
                  key={index}
                  actInfo={actInfo}
                  handleTimeComplete={handleTimeComplete}
                />
              ) : (
                <></>
              ),
            )}
        </div>
        {canClaimed ? (
          // {true ? (
          <div
            className="bg-task-claim-all w-full py-[.46rem] flex justify-end pr-[.4rem]"
            style={{ borderBottomRightRadius: 'var(--rounded-normal)' }}
          >
            <LimitClickWrapper
              onClick={handleClaimAll}
              className="uppercase bg-[var(--colour-1)] text-[var(--colour-2)] w-[1.44rem] h-[.68rem] rounded-normal font-[700] text-[.24rem] flex items-center justify-center flex-col cursor-pointer"
            >
              <span>{t('task.claim')}</span>
              <span>{t('task.all')}</span>
            </LimitClickWrapper>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const GameList = forwardRef(({ id }, ref) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [elRefs, setElRefs] = useStateIfMounted([]);
  const [list, setList] = useStateIfMounted([]);
  const [total, setTotal] = useStateIfMounted(0);
  const pageSize = 30;
  const [params, setParams] = useStateIfMounted({
    activity_id: undefined,
    page: 1,
    page_size: pageSize,
  });
  useEffect(() => {
    if (params.activity_id) {
      if (params.page == 1) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }
      GetActivityGamesReq(params).then((res) => {
        if (params.page == 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
        if (res.code == 0) {
          const result = res.result;
          if (params.page == 1) {
            setList(result.list);
          } else {
            const list2 = list.concat(result.list);
            setList(list2);
          }
          const _pageCount = Math.ceil(result.pages.count / pageSize);
          setTotal(result.pages.count);
          if (params.page < _pageCount) {
            setShowLoadingMore(true);
          } else {
            setShowLoadingMore(false);
          }
        }
      });
    }
  }, [params]);

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };
  useEffect(() => {
    if (id != 0) {
      setParams({ ...params, page: 1, activity_id: id });
    }
  }, [id]);

  const goToFirstGame = () => {
    if (elRefs.length > 0) {
      elRefs[0].current && elRefs[0].current.handleOnClick();
    }
  };

  useImperativeHandle(ref, () => ({
    goToFirstGame: goToFirstGame,
  }));

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(list.length)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [list.length]);

  return (
    <>
      <p className="text-left w-full font-[400] text-[.24rem] text-[var(--colour-38)]">{t('task.gamesTitle')}</p>
      {loading ? (
        <Loading height="4rem" />
      ) : (
        <>
          {list && list.length > 0 ? (
            <div className="my-[.3rem] games__row w-full py-[.2rem]">
              {list.map((item, index) => (
                <GameItem ref={elRefs[index]} model={item} key={item.id} />
              ))}
            </div>
          ) : (
            <NoData className="py-[2rem]" showTextLangkey="promotion.eventend" />
          )}
          {showLoadingMore ? (
            <LoadingMoreWithText
              currentNum={list.length}
              total={total}
              className="mb-[.2rem]"
              showLoading={loadingMore && params.page > 1}
              handleLoadMore={handleLoadMore}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
});

export default function NTemplate001() {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  const { identity, gift_money_menu_show_switch } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      gift_money_menu_show_switch: get(state, 'app.activityStatus.gift_money_menu_show_switch'),
    }),
    shallowEqual,
  );

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });
  const ref = useRef(null);
  const [loading, setLoading] = useStateIfMounted(true);
  const [activitySwitch, setActivitySwitch] = useStateIfMounted('0');
  const [list, setList] = useStateIfMounted([]);
  const [bannerList, setBannerList] = useStateIfMounted([]);
  const [currentAct, setCurrentAct] = useStateIfMounted(null);
  const { InitActivityStatus, handleActivityDetailGoBack } = useActivityStatus();

  const GetReliefListReq = () => {
    return ActivityAllReq({
      data_type: JSON.stringify(['activity_relief_money']),
    });
  };

  const fetchServerTaskData = (id, showLoading = true) => {
    const req = id == '1' ? GetActivityListReq : GetReliefListReq;
    if (showLoading) {
      setLoading(true);
    }
    req({ activity_type_id: params.id }).then((res) => {
      if (showLoading) {
        setLoading(false);
      }
      if (res.code == 0) {
        const result = res.result;
        if (id == '1') {
          setList(result.list);
          setActivitySwitch(result.activity_switch);
        } else {
          if (result.activity_relief_money && result.activity_relief_money.list) {
            setList(result.activity_relief_money.list);
            setActivitySwitch('1');
          }
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const fetchTaskData = (id) => {
    fetchServerTaskData(id);
  };

  useEffect(() => {
    setList([]);
    setCurrentAct(null);
    setBannerList([]);
    fetchTaskData(params.id);
  }, [identity, params.id]);

  const handleTimeComplete = () => {
    fetchServerTaskData(params.id, false);
  };

  useEffect(() => {
    if (list && list.length > 0) {
      let banners = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        banners.push({
          id: item.id,
          img: item.rule_explain.activity_img,
        });
      }
      setBannerList(banners);
    }
    if (currentAct != null) {
      setCurrentAct(findCurrentAct(currentAct.id));
    }
  }, [list]);

  const findCurrentAct = (id) => {
    let act = null;
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        act = list[i];
      }
    }
    return act;
  };

  const handleChangeCallback = (id) => {
    setCurrentAct(findCurrentAct(id));
  };

  const handleClaimCallback = (
    tapPosition,
    showMoney,
    elementList = [],
    currency,
    amount = 0,
    activityRewards = [],
  ) => {
    // console.log('handleClaimCallback', tapPosition, showMoney, elementList, currency, amount);
    const newTapPosition = unionWith(currentAct.user_tap_position, tapPosition, isEqual);
    const newCurrentAct = {
      ...currentAct,
      user_tap_position: newTapPosition,
      activity_rewards: currentAct.activity_rewards.map((item) => {
        const found = activityRewards.find((i) => i.tap_position == item.tap_position);
        return found ? found : item;
      }),
    };
    setCurrentAct(newCurrentAct);

    const newList = cloneDeep(list);
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].id == currentAct.id) {
        newList[i] = newCurrentAct;
      }
    }
    setList(newList);

    const commonCallback = () => {
      dispatch(setNeedUpdateBalance({ need: true }));
      InitActivityStatus();
    };

    const balanceCallback = () => {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.BonusBox,
          content: { showAmount: showMoney },
        }),
      );
    };

    const giftMoneyCallback = () => {
      if (elementList.length > 0) {
        const fromPoints = elementList.map((item) => getCenterPosition(item, true));
        openHeaderGiftMoney(amount, fromPoints);
      } else {
        dispatch(setShowHeaderGiftMoney(true));
        dispatch(
          setNeedUpdateGiftMoney({
            need: true,
            data: amount,
          }),
        );
      }
    };

    commonCallback();

    if (currency == 'balance') {
      balanceCallback();
    } else if (currency == 'gift_money') {
      if (gift_money_menu_show_switch == '1') {
        giftMoneyCallback();
      } else {
        balanceCallback();
      }
    }
  };

  const handleClaimReliefCallback = (res, element = null) => {
    if (res.code == 0) {
      const result = res.result;
      const activityInfo = result.activity_info;
      setCurrentAct(activityInfo);

      const newList = cloneDeep(list);
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].id == activityInfo.id) {
          newList[i] = activityInfo;
        }
      }
      setList(newList);

      const commonCallback = () => {
        dispatch(setNeedUpdateBalance({ need: true }));
        InitActivityStatus();
      };

      const balanceCallback = () => {
        dispatch(
          setDialogModalVisible({
            show: DialogModalChildrenKey.BonusBox,
            content: { showAmount: getShowMoney(res?.result?.amount, 2, 2) },
          }),
        );
      };

      const giftMoneyCallback = () => {
        if (element) {
          const fromPoints = [getCenterPosition(element, true)];
          openHeaderGiftMoney(res?.result?.amount || 0, fromPoints);
        }
      };

      commonCallback();

      const currency = res?.result?.currency;

      if (currency == 'balance') {
        balanceCallback();
      } else if (currency == 'gift_money') {
        if (gift_money_menu_show_switch == '1') {
          giftMoneyCallback();
        } else {
          balanceCallback();
        }
      }
    } else if (res.code == 1008127) {
      if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
        setBottomDownloadBoxPromptSwitch({
          visible: true,
          isForce: true,
          downloadText: t('guideWheel.downloadtips'),
        });
      }
    }
  };

  const handleGo = () => {
    ref.current && ref.current.goToFirstGame();
  };

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <div className="container px-[.32rem]">
        <div className="mx-auto mt-[.5rem]">
          {loading ? (
            <Loading height="4rem" />
          ) : activitySwitch == '1' ? (
            <>
              <div className="text-center text-[var(--colour-48)] uppercase relative font-[600] text-[.24rem]">
                <Back
                  onClick={handleActivityDetailGoBack}
                  className="absolute abs-y-center left-[0px]"
                  text={`←${t('task.back')}`}
                />
                {['', t('task.task'), t('task.reliefMoney')][(currentAct && currentAct.activity_type_id) || 0]}
              </div>
              <div className="flex flex-col justify-center items-center mt-[.12rem] text-[var(--colour-48)]">
                <div className='flex items-center space-x-[.1rem] justify-center w-[3.6rem] h-[.4rem] text-center font-[400] text-[.24rem] bg-[url("/static/img/task/refresh-in-bg.png")] bg-no-repeat bg-[length:100%_100%]'>
                  <span className="w-[.28rem] h-[.28rem]">
                    <Icon name={IconName.RefreshTime} />
                  </span>
                  <span>
                    {t('task.refreshIn')}&nbsp;{currentAct && getRefreshTimeStr(currentAct.activity_end_second)}
                  </span>
                </div>
                <div className="m-auto w-[90%]">
                  <Banner list={bannerList} handleChangeCallback={handleChangeCallback} />
                </div>
                <TaskRules actInfo={currentAct} />
                <TaskList
                  handleClaimReliefCallback={handleClaimReliefCallback}
                  handleClaimCallback={handleClaimCallback}
                  actInfo={currentAct}
                  handleGo={handleGo}
                  handleTimeComplete={handleTimeComplete}
                />
                <div className="w-full h-[1px] border-t border-[var(--colour-6)] my-[.3rem]"></div>
                <GameList ref={ref} id={currentAct ? currentAct.id : 0} />
              </div>
            </>
          ) : (
            <Custom404 />
          )}
        </div>
      </div>
    </>
  );
}
