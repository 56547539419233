import config from '../../constants/config';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import GatewayManager from '../../api/gateway/GatewayManager';
import { get } from 'lodash';
import { useServerType } from './useServerType';
import { useTranslation } from 'react-i18next';
import { showNoCloseConfirm } from '../../utils/stateHelper';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../redux/reducers/appSlice';
import { PageReload } from '../../utils/helper';

const MaxRetryCount = 12; // 最大重连次数
const RetryInterval = 5900; // 重连间隔
const MaxPingLostCount = 10; // ping最大失败次数
const PingInterval = 5000; // ping间隔ms
const MaxErrorCount = 3; // 最大失败次数

const WS_CLOSE_DIRECT = 4000; // ws直接关闭code

const wssUrl = config.WSSURL;

export function useGlobalWebSocket() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [pingLostCount, setPingLostCount] = useState(0); // 心跳失败次数
  const [errorCount, setErrorCount] = useState(0); // 重连失败次数

  const { identity, isKickout, isIpLimit, subscribeError } = useSelector(
    (state) => ({
      identity: get(state, 'user.identity'),
      isKickout: get(state, 'ws.isKickout'),
      isIpLimit: get(state, 'ws.isIpLimit'),
      subscribeError: get(state, 'ws.subscribeError'),
    }),
    shallowEqual,
  );

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(wssUrl, {
    share: false,
    retryOnError: false,
    onOpen: (ev) => {
      console.log('[ws] onOpen', ev);
      setErrorCount(0);
      showNoCloseConfirm({ show: false });
    },
    onClose: (ev) => {
      console.log('[ws] onClose', ev);
      setErrorCount((preCount) => {
        return preCount + 1;
      });
    },
    onMessage: () => {
      setPingLostCount(0);
    },
    onError: (ev) => {
      console.log('[ws] onError', ev);
    },
    onReconnectStop: (num) => {
      console.log('[ws] onReconnectStop', num);
      showWSDialog(t('common.network_error'));
    },
    shouldReconnect: (closeEv) => {
      console.log('[ws] closeEv', closeEv);
      // ip限制或被踢,不重连
      return !(isIpLimit || isKickout);
    },
    reconnectAttempts: MaxRetryCount,
    reconnectInterval: RetryInterval,
  });

  useServerType({ lastMessage, readyState });

  // 初始化
  useEffect(() => {
    console.log('[ws]', '初始化');
    GatewayManager.sendMessage = sendMessage;
  }, []);

  // 发送网关心跳
  useEffect(() => {
    if (readyState == ReadyState.OPEN) {
      const heartInterval = setInterval(() => {
        // console.log('[ws] heartInterval ws');
        setPingLostCount((preCount) => {
          return preCount + 1;
        });
        GatewayManager.sendPingData();
      }, PingInterval);
      return () => {
        clearInterval(heartInterval);
      };
    }
  }, [readyState]);

  // 断开ws 网关心跳丢失超过最大次数
  useEffect(() => {
    if (pingLostCount > MaxPingLostCount) {
      closeWebsocket('pingMaxLost');
    }
  }, [pingLostCount]);

  // 断开ws 注册用户订阅出错
  useEffect(() => {
    if (subscribeError && identity) {
      closeWebsocket('subscribleError');
    }
  }, [subscribeError]);

  // 重连达到最大失败次数，弹提示
  useEffect(() => {
    if (errorCount >= MaxErrorCount) {
      showNoCloseConfirm({ show: true });
    }
  }, [errorCount]);

  useEffect(() => {
    if (isKickout) {
      showWSDialog(t('common.kickout'));
    } else if (isIpLimit) {
      showWSDialog(t('common.ip_count_restict'));
    }
  }, [isKickout, isIpLimit]);

  // 显示对话框
  const showWSDialog = (content) => {
    if (content) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.Confirm,
          confirmCb: () => {
            PageReload();
          },
          content: content,
          disableClose: true,
        }),
      );
    }
  };

  // 关闭websocket
  const closeWebsocket = (reason) => {
    if (readyState == ReadyState.OPEN) {
      console.log('[ws] close ws:', reason);
      getWebSocket().close(WS_CLOSE_DIRECT);
      setPingLostCount(0);
    }
  };
}
