import { useEffect, useState } from 'react';
import GameItem from '../../../../../components/gameItem';
import NoData from '../../../../../components/noData';
import { useSelector, shallowEqual } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';
import LoadingMoreWithText from '../../../../../components/loadingMoreWithText';
import Loading from '../../../../../components/loading2';
import { GetFavouritesListReq } from '../../../../../api';
import { isLogin } from '../../../../../utils/helper';

const FavoriteGame = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [total, setTotal] = useStateIfMounted(0);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const pageSize = 12;
  const [reRequest, setreRequest] = useStateIfMounted('');
  const [params, setParams] = useStateIfMounted({
    page: 1,
    page_size: pageSize,
  });

  const handleFavoriteCallback = () => {
    setreRequest(new Date().getTime());
  };

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  const getfavoriteList = async () => {
    if (params.page == 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    try {
      const res = await GetFavouritesListReq(params);
      const result = res.result;
      if (params.page == 1) {
        setList(result.list);
      } else {
        const list2 = list.concat(result.list);
        setList(list2);
      }
      const _pageCount = Math.ceil(result.pages.count / pageSize);
      setTotal(result.pages.count);
      if (params.page < _pageCount) {
        setShowLoadingMore(true);
      } else {
        setShowLoadingMore(false);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      if (params.page == 1) {
        setLoading(false);
      } else {
        setLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    if (isLogin()) {
      getfavoriteList();
    } else {
      setLoading(false);
    }
  }, [params]);

  return (
    <>
      {loading ? (
        <Loading className="h-[8rem]" />
      ) : (
        <>
          {list && list.length > 0 ? (
            <div className="games__row gap-[.16rem] pb-[.3rem]">
              {list &&
                list.length > 0 &&
                list.map((item) => (
                  <GameItem model={item} key={item.id} handleFavoriteCallback={handleFavoriteCallback} />
                ))}
            </div>
          ) : (
            <NoData className="py-[3rem]" />
          )}
        </>
      )}

      {showLoadingMore ? (
        <LoadingMoreWithText
          className={`my-[.4rem]`}
          currentNum={list.length}
          total={total}
          handleLoadMore={handleLoadMore}
          showLoading={loadingMore && params.page > 1}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default FavoriteGame;
