import { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { GetPromotionVipList } from '../../../../../api';
import { toast } from 'react-toastify';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setIsShowPromotionBarGiftMoneyCoin,
} from '../../../../../redux/reducers/appSlice';
import { getShowMoney, getVipLevelLogin, setVipLevelLogin } from '../../../../../utils/helper';
import Loading from '../../../../../components/loading3';
import Tips2 from './com/tips2';
import BounsTitle from './com/bonusTitle';
import BonusList from './com/bonusList';
import Privilege from './com/privilege';
import { getFengeFu } from './com/helper';
import VipLevelCard from './com/vipCard';
import Icon, { IconName } from '../../../../../components/icon';
import GSAPUtils from '../../../../../utils/gsapUtils';
import { getCenterPosition } from '../../../../../hooks/useMinimizeAnimation';

const NTemplate001 = function () {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const { t } = useTranslation();
  const [specifiedItem, setSpecifiedItem] = useState(0);
  const swiperRef = useRef(null);
  const { identity, refetchVipData, activityStatus } = useSelector(
    (state) => ({
      identity: state.user.identity,
      refetchVipData: state?.app?.refetchVipData,
      activityStatus: state.app.activityStatus,
    }),
    shallowEqual,
  );
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [vipLevelList, setVipLevelList] = useState([]);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWager, setTotalWagert] = useState(0);
  const [myVip, setMyVip] = useState(0);
  const [vipLevel, setVipLevel] = useState(0);
  const [showTips, setShowTips] = useState(false);
  const [lingquBonus, setLingquBonus] = useState(0);
  const [maxVipLevel, setMaxVipLevel] = useState(0);

  useEffect(() => {
    setShowLoading(true);
    getRefData();
  }, [identity, refetchVipData]);

  // const getRefDataOld = () => {
  //   GetReVipLevelList().then((res) => {
  //     setShowLoading(false);
  //     if (res.code === 0) {
  //       let saveVipLevel = getVipLevelLogin(res.result.uid);

  //       const resultList = res.result.list;
  //       const list = resultList.map((item) => {
  //         return {
  //           ...item,
  //           vip_bonus_info: item.vip_bonus_info.map((item2) => {
  //             const secondsDistance =
  //               item2.time == '' ? item2.time : Number(item2.time) - Math.floor(Date.now() / 1000);
  //             console.log('secondsDistance', secondsDistance, item2.type);
  //             return {
  //               ...item2,
  //               time: secondsDistance > 0 ? secondsDistance : '',
  //             };
  //           }),
  //         };
  //       });

  //       setVipLevelList(list);

  //       setTotalDeposit(res.result.totalDeposit);
  //       setTotalWagert(res.result.totalWager);
  //       setMyVip(res.result.vipLevel);
  //       setSpecifiedItem(res.result.vipLevel);

  //       const _maxVipLevel = Math.max(...resultList.map((item) => item?.vip_level_config?.vip || 0));
  //       setMaxVipLevel(_maxVipLevel);

  //       if (res.result.vipLevel != saveVipLevel) {
  //         dispatch(
  //           setDialogModalVisible({
  //             content: { res },
  //             show: DialogModalChildrenKey.VipUpgrade,
  //           }),
  //         );
  //         setVipLevelLogin(res.result.vipLevel, res.result.uid);
  //       }
  //     } else {
  //       toast.error(res.msg, {
  //         containerId: 'global',
  //       });
  //     }
  //   });
  // };

  const getRefData = () => {
    GetPromotionVipList().then((res) => {
      setShowLoading(false);
      if (res.code === 0) {
        const data = res.data;
        let saveVipLevel = getVipLevelLogin(data.uid);

        const resultList = data.list;
        const list = resultList.map((item) => {
          return {
            ...item,
            vip_bonus_info: item.vip_bonus_info.map((item2) => {
              const secondsDistance =
                item2.time == '' ? item2.time : Number(item2.time) - Math.floor(Date.now() / 1000);
              console.log('secondsDistance', secondsDistance, item2.type);
              return {
                ...item2,
                time: secondsDistance > 0 ? secondsDistance : '',
              };
            }),
          };
        });

        setVipLevelList(list);

        setTotalDeposit(data.totalDeposit);
        setTotalWagert(data.totalWager);
        setMyVip(data.vipLevel);
        setSpecifiedItem(data.vipLevel);

        const _maxVipLevel = Math.max(...resultList.map((item) => item?.vip_level_config?.vip || 0));
        setMaxVipLevel(_maxVipLevel);

        if (data.vipLevel != saveVipLevel) {
          dispatch(
            setDialogModalVisible({
              data: res.data,
              show: DialogModalChildrenKey.VipUpgrade,
            }),
          );
          setVipLevelLogin(data.vipLevel, data.uid);
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const onSwipePrev = () => {
    if (vipLevel <= 0) {
      return;
    }
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const onSwipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  function returnHead() {
    return [t('vip.promotion'), t('vip.weekly'), t('vip.monthly')];
  }

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(specifiedItem, 500, false);
    }
  }, [specifiedItem]);

  return (
    <div className="p-[0_.32rem_0_.32rem] h-full overflow-y-auto scrollbar-none">
      <Tips2
        setShowVerify={setShowTips}
        showVerify={showTips}
        transerAmount={getShowMoney(lingquBonus)}
        headTxt={t('vip.bouns')}
        cb1={() => {
          setShowTips(false);
        }}
        cb2={() => {
          setShowTips(false);
        }}
      />

      {showLoading ? (
        <>{<Loading className1="h-full" />}</>
      ) : (
        <>
          {vipLevelList && vipLevelList.length > 0 && (
            <>
              <div className="  mx-auto">
                <div className="">
                  <div className="flex items-center justify-center  pt-[.3rem]">
                    <div className=" w-[6.34rem] relative">
                      <div className="flex absolute  mt-[1.64rem] z-[1] cursor-pointer ">
                        {
                          <>
                            {vipLevel > 0 ? (
                              <div
                                className="flex w-[.44rem] h-[.64rem]    scale-[-1]  ml-[-.4rem] cursor-pointer active:-scale-[.95] transition-all select-none items-center justify-center swiper-button-prev-vip"
                                onClick={() => {
                                  onSwipePrev();
                                }}
                              >
                                <div className="swiper-button-prev-icon w-[.16rem] h-[.24rem]  ">
                                  <Icon name={IconName.BannerNext2}></Icon>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {vipLevel < vipLevelList.length - 1 ? (
                              <div
                                className="flex w-[.44rem] h-[.64rem]    scale-[1]  ml-[6.3rem] cursor-pointer active:scale-[.95] transition-all select-none items-center justify-center swiper-button-next-vip"
                                onClick={() => {
                                  onSwipeNext();
                                }}
                              >
                                <div className="swiper-button-next-icon w-[.16rem] h-[.24rem]  ">
                                  <Icon name={IconName.BannerNext2}></Icon>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      </div>

                      <Swiper
                        modules={[Controller]}
                        spaceBetween={12}
                        slidesPerView={1}
                        slidesPerGroup={1}
                        resistanceRatio={0}
                        onSlideChange={(data) => setVipLevel(data.realIndex)}
                        // onSwiper={(swiper) => {
                        //   console.log(swiper)
                        // }}
                        ref={swiperRef}
                        rewind={true}
                        controller={{ control: controlledSwiper }}
                      >
                        {vipLevelList &&
                          vipLevelList.map((item, index) => {
                            return (
                              <SwiperSlide key={index}>
                                {
                                  <>
                                    <VipLevelCard
                                      data={item}
                                      myVip={myVip}
                                      totalWager={totalWager}
                                      totalDeposit={totalDeposit}
                                      maxVipLevel={maxVipLevel}
                                    />
                                  </>
                                }
                              </SwiperSlide>
                            );
                          })}
                      </Swiper>
                    </div>
                  </div>
                </div>

                <div className=" mx-auto">
                  <Swiper
                    noSwiping={true}
                    modules={[Controller]}
                    spaceBetween={12}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    resistanceRatio={0}
                    onSwiper={setControlledSwiper}
                    // onSlideChange={(data) => setVipLevel(data.realIndex)}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // ref={swiperRef}
                    rewind={true}
                  >
                    {vipLevelList &&
                      vipLevelList.map((item, index) => {
                        const _level = item?.vip_level_config?.vip || 0;
                        const vip_bonus_info = item?.vip_bonus_info;

                        return (
                          <SwiperSlide key={index}>
                            {
                              <>
                                <div className="flex flex-col items-center justify-center swiper-no-swiping select-none">
                                  <div className="flex flex-col justify-center">
                                    <BounsTitle linebg={getFengeFu(_level)[0]} textColor={getFengeFu(_level)[1]} />
                                  </div>

                                  <div className="flex flex-row justify-between  space-x-[.1rem]">
                                    {[0, 7, 30].map((item, index) => {
                                      return (
                                        <BonusList
                                          index2={index}
                                          key={index}
                                          index={item}
                                          myVip={myVip}
                                          vipLevel={_level}
                                          data2={vip_bonus_info[index]}
                                          tname={returnHead(index)[index]}
                                          identity={identity}
                                          callback={(res, params, e) => {
                                            setLingquBonus(params.money);
                                            if (res.data?.currency == 'gift_money') {
                                              if (activityStatus && activityStatus.gift_money_menu_show_switch == '1') {
                                                const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
                                                const element = e.target;
                                                if (element && giftMoneyLabel) {
                                                  dispatch(setIsShowPromotionBarGiftMoneyCoin(false));
                                                  GSAPUtils.flyCoin(
                                                    getCenterPosition(element, true),
                                                    getCenterPosition(giftMoneyLabel, true),
                                                    GSAPUtils.COIN_COUNT,
                                                    () => {
                                                      dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                                                    },
                                                  );
                                                }
                                              } else {
                                                setShowTips(true);
                                              }
                                            } else {
                                              setShowTips(true);
                                            }
                                          }}
                                        />
                                      );
                                    })}
                                  </div>

                                  <Privilege vipLevel={_level} data={item} />
                                </div>
                              </>
                            }
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default NTemplate001;
