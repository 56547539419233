import React, { FC, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DialogModal from '../dialogModal/index';
import Icon, { IconName } from '../icon';
import { useStateIfMounted } from 'use-state-if-mounted';
import Button from '../button';
import ScrollWrapper from '../scrollWrapper';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  ResponsiveMode,
  setRefetchActivityData,
  setNeedUpdateBalance,
  setUserClaimTotalMoney,
  setIsShowPromotionBarGiftMoneyCoin,
  setShowHeaderGiftMoney,
} from '../../redux/reducers/appSlice';
import { getShowMoney, isControllableSite, isPCLayout } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import Close from '../close';
import LimitClickWrapper from '../limitClickWrapper';
import TippyPopBox, { Placement } from '../tippyPopBox';
import PopChoose, { PopChooseItem } from '../popChoose';
import DropDownInput from '../dropDownInput';
import cn from 'classnames';
import moment from 'moment';
import LoadMore from '../loadMore';
import LoadingMoreWithText from '../loadingMoreWithText';
import GameItem from '../gameItem';
import Loading from '../loading3';
import NoData from '../noData';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import {
  GiftMoneyListReq,
  GiftMoneyGameListReq,
  GiftMoneyCancelReq,
  GiftMoneyClaimReq,
  GiftMoneyClaimAllReq,
} from '../../api';
import { getPercentage } from '../../utils/helper';
import useGameInfo from '../../hooks/useGameInfo';
import PopConfirm from '../popConfirm';
import { get } from 'lodash';
import SuccessTips from '../../components/successTip';
import usePromotionClaimedCount from '../../hooks/usePromotionClaimedCount';
import { useMinimizeAnimation } from '../../hooks/useMinimizeAnimation';
import Confirm from '../dialogModal/confirm';
import { toLocalePath, toNoLocalePath } from '../../utils/localePath';
import { useGlobalNavigate } from '../../contexts/navigateContext';
import { useLocation, useOutletContext } from 'react-router-dom';
import Backdrop from '../backdrop';

const Header = ({
  title,
  handleClose,
  showBack = false,
  handleBack,
  showQuestion = false,
  handleShowQuestion,
  isDialog,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-row items-center justify-between py-[.2rem]">
        <div className="flex flex-row items-center justify-start space-x-[.26rem]">
          {showBack ? (
            <span className="w-[.2rem] h-[.28rem] cursor-pointer text-[var(--colour-11)]" onClick={handleBack}>
              <Icon name={IconName.NewBack}></Icon>
            </span>
          ) : (
            <></>
          )}
          <span className={cn('font-[700] text-[.32rem] text-[var(--colour-48)]')}>{title}</span>
          {showQuestion ? (
            <span
              className="w-[.4rem] h-[.4rem] cursor-pointer active:scale-[.95] transition-all select-none text-[var(--colour-7)]"
              onClick={handleShowQuestion}
            >
              <Icon name={IconName.WidthdrawQuestion2} />
            </span>
          ) : (
            <></>
          )}
        </div>
        {isDialog && (
          <div className="active:scale-[.95] transition-all select-none w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
            <Close onClick={handleClose} />
          </div>
        )}
      </div>
      <div className="w-full bg-[var(--colour-6)] bg-opacity-20 h-[1px] mb-[.22rem]"></div>
    </>
  );
};

const StatusStr = ({ status, handleClaim }) => {
  const { t } = useTranslation();
  switch (status) {
    case 0:
      return <span className="text-center text-[var(--colour-41)]">{t('giftMoney_filterStatus.notStarted')}</span>;
    case 1:
      return <span className="text-center text-[var(--colour-26)]">{t('giftMoney_filterStatus.ongoing')}</span>;
    case 2:
      return (
        <LimitClickWrapper
          className="text-center text-[var(--colour-24)] underline cursor-pointer"
          onClick={handleClaim}
        >
          {t('giftMoney.claim')}
        </LimitClickWrapper>
      );
    case 3:
      return <span className="text-center text-[var(--colour-24)]">{t('giftMoney_filterStatus.received')}</span>;
    case 4:
      return <span className="text-center text-[var(--colour-25)]">{t('giftMoney_filterStatus.cancelled')}</span>;
    case 5:
      return <span className="text-center text-[var(--colour-25)]">{t('giftMoney_filterStatus.expired')}</span>;
    default:
      return <></>;
  }
};

const DetailStatusStr = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case 0:
      return <span className="text-center text-[var(--colour-41)]">{t('giftMoney_filterStatus.notStarted')}</span>;
    case 1:
      return <span className="text-center text-[var(--colour-26)]">{t('giftMoney_filterStatus.ongoing')}</span>;
    case 2:
      return <span className="text-center text-[var(--colour-24)]">{t('giftMoney_filterStatus.done')}</span>;
    case 3:
      return <span className="text-center text-[var(--colour-24)]">{t('giftMoney_filterStatus.received')}</span>;
    case 4:
      return <span className="text-center text-[var(--colour-25)]">{t('giftMoney_filterStatus.cancelled')}</span>;
    case 5:
      return <span className="text-center text-[var(--colour-25)]">{t('giftMoney_filterStatus.expired')}</span>;
    default:
      return <></>;
  }
};

const ItemStatus = ({ status, handleClaim }) => {
  return (
    <div className="w-[25%] flex flex-row items-center justify-center">
      <div className="flex-1 text-center">
        <StatusStr status={status} handleClaim={handleClaim} />
      </div>
      <span
        className={cn('block w-[.16rem] h-[.32rem] ml-[.2rem]', {
          'text-[var(--colour-11)]': true,
        })}
      >
        <Icon name={IconName.RightArrow2} />
      </span>
    </div>
  );
};

const Item = ({ index, data, handleShowDetail, handleClaim }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'flex flex-row items-center justify-around h-[.8rem] rounded-normal px-[.16rem] text-[.24rem] cursor-pointer',
        {
          'bg-[var(--colour-34)]': index % 2 == 0,
        },
      )}
      onClick={handleShowDetail}
    >
      <div className="w-[25%] text-clamp-2-with-break-word text-center text-[var(--colour-41)]">
        {t(`giftMoney_filterType.${data.behaviour}`)}
      </div>
      <div className="w-[25%] text-center text-[var(--colour-38)]">{getShowMoney(data.gift_money_amount)}</div>
      <div className="w-[25%] text-center text-[var(--colour-41)]">
        {moment(data.created_time * 1000).format('YYYY/MM/DD')}
      </div>
      <ItemStatus status={data.status} handleClaim={handleClaim} />
    </div>
  );
};

const List = forwardRef(
  ({ handleClose, handleShowDetail, handleShowSuccess, handleShowQuestion, isDialog, handleShowOnGoingGames }, ref) => {
    const { pathname } = useLocation();
    const navigate = useGlobalNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useStateIfMounted(true);
    const [configLoading, setConfigLoading] = useStateIfMounted(false);
    const [loadingMore, setLoadingMore] = useStateIfMounted(false);
    const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
    const [list, setList] = useStateIfMounted([]);
    const [userClaimMoney, setUserClaimMoney] = useStateIfMounted({ claim_money: 0, total_money: 0 });
    const [typeList, setTypeList] = useStateIfMounted([]);
    const { InitPromotionClaimedCount } = usePromotionClaimedCount();

    const [isShowTip1, setIsShowTip1] = useStateIfMounted(false);
    const [isShowTip2, setIsShowTip2] = useStateIfMounted(false);
    const outletObj = useOutletContext();

    const statusList = [
      {
        key: undefined,
        value: t('giftMoney_filterStatus.allStatus'),
      },
      {
        key: 0,
        value: t('giftMoney_filterStatus.notStarted'),
      },
      {
        key: 1,
        value: t('giftMoney_filterStatus.ongoing'),
      },
      {
        key: 2,
        value: t('giftMoney_filterStatus.done'),
      },
      {
        key: 3,
        value: t('giftMoney_filterStatus.received'),
      },
      {
        key: 4,
        value: t('giftMoney_filterStatus.cancelled'),
      },
      {
        key: 5,
        value: t('giftMoney_filterStatus.expired'),
      },
    ];

    const { websiteInitConfig } = useSelector(
      (state) => ({
        websiteInitConfig: get(state, 'app.websiteInitConfig'),
      }),
      shallowEqual,
    );
    const [filterTypeVisible, setFilterTypeVisible] = useStateIfMounted(false);
    const [filterStatusVisible, setFilterStatusVisible] = useStateIfMounted(false);
    const pageSize = 10;
    const [params, setParams] = useStateIfMounted({
      type: undefined,
      status: undefined,
      page: 1,
      page_size: pageSize,
    });

    useEffect(() => {
      const translations = i18n.getDataByLanguage(i18n.resolvedLanguage).translation;
      let _typeList = [];
      if (translations.giftMoney_filterType) {
        const keys = Object.keys(translations.giftMoney_filterType);
        for (let i = 0; i < keys.length; i++) {
          let item = {};
          if (i == 0) {
            item = {
              key: undefined,
              value: t(`giftMoney_filterType.${keys[0]}`),
            };
          } else {
            item = {
              key: keys[i],
              value: t(`giftMoney_filterType.${keys[i]}`),
            };
          }
          _typeList.push(item);
        }
      }
      setTypeList(_typeList);
    }, []);

    const getFilterTypeStr = () => {
      for (let i = 0; i < typeList.length; i++) {
        if (typeList[i].key == params.type) {
          return typeList[i].value;
        }
      }
      return typeList.length > 0 ? typeList[0].value : '';
    };

    const getFilterStatusStr = () => {
      for (let i = 0; i < statusList.length; i++) {
        if (statusList[i].key == params.status) {
          return statusList[i].value;
        }
      }
      return statusList.length > 0 ? statusList[0].value : '';
    };

    const handleFilterType = (item) => {
      setParams({ ...params, type: item.key, page: 1 });
      setFilterTypeVisible(false);
    };

    const handleFilterStatus = (item) => {
      setParams({ ...params, status: item.key, page: 1 });
      setFilterStatusVisible(false);
    };

    const handleLoadMore = () => {
      setParams({ ...params, page: params.page + 1 });
    };

    const handleReload = () => {
      setParams({ ...params, page: 1 });
    };

    const handleClaimAll = () => {
      const requestClaim = () => {
        GiftMoneyClaimAllReq().then((res) => {
          if (res.code == 0) {
            handleShowSuccess(res?.result?.amount);
            if (params.page == 1) {
              fetchData();
            } else {
              setParams({ ...params, page: 1 });
            }
            dispatch(setNeedUpdateBalance({ need: true }));
            InitPromotionClaimedCount();
            const _timer = window.setTimeout(() => {
              dispatch(setRefetchActivityData());
              window.clearTimeout(_timer);
            }, 1500);
          } else {
            toast.error(res.msg, {
              containerId: 'global',
            });
          }
        });
      };

      const showTip1 = () => {
        setIsShowTip1(true);
      };

      const showTip2 = () => {
        setIsShowTip2(true);
      };

      if (userClaimMoney.total_money == 0) {
        showTip1();
      } else if (userClaimMoney.claim_money == 0) {
        // toast.error(t('giftMoney.withdrawTips'), {
        //   containerId: 'global',
        // });
        showTip2();
      } else {
        requestClaim();
      }
    };

    const fetchData = () => {
      if (params.page == 1) {
        setLoading(true);
        setShowLoadingMore(false);
      } else {
        setLoadingMore(true);
      }
      GiftMoneyListReq(params).then((res) => {
        if (params.page == 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
        if (res.code == 0) {
          const result = res.result;
          if (params.page == 1) {
            setList(result.list);
          } else {
            const list2 = list.concat(result.list);
            setList(list2);
          }
          setUserClaimMoney(result.user_claim_money);
          dispatch(setUserClaimTotalMoney(result.user_claim_money.total_money));
          const _pageCount = Math.ceil(result.pages.count / pageSize);
          if (params.page < _pageCount) {
            setShowLoadingMore(true);
          } else {
            setShowLoadingMore(false);
          }
        }
      });
    };

    const handleClaim = (obj) => {
      GiftMoneyClaimReq({ gift_money_log_id: obj.id }).then((res) => {
        if (res.code == 0) {
          handleShowSuccess(res?.result?.amount);
          handleReload();
          dispatch(setNeedUpdateBalance({ need: true }));
          InitPromotionClaimedCount();
          const _timer = window.setTimeout(() => {
            dispatch(setRefetchActivityData());
            window.clearTimeout(_timer);
          }, 1500);
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      });
    };

    useEffect(() => {
      fetchData();
    }, [params]);

    return (
      <>
        <DialogModal
          shouldCloseOnOverlayClick={true}
          noentered={true}
          style={{
            content: {
              display: 'inline-block',
              textAlign: 'left',
              top: '0px',
              maxWidth: '100%',
              cursor: 'default',
              outline: '0px',
              verticalAlign: 'middle',
              zIndex: 'auto',
            },
          }}
          handleClose={() => {
            setIsShowTip1(false);
          }}
          isOpen={isShowTip1}
        >
          <Confirm
            handleConfirm={() => {
              setIsShowTip1(false);
              handleClose();

              if (toNoLocalePath(pathname) == '/promotion/giftmoney') {
                if (outletObj.onScroll && typeof outletObj.onScroll == 'function') {
                  outletObj.onScroll('/promotion/events');
                }
              } else {
                navigate(toLocalePath('/promotion/events'));
              }
            }}
            handleClose={() => {
              setIsShowTip1(false);
            }}
            title={t('game.tips')}
            confirmText={t('home.goTxt')}
            isConfirmBtnPrimary={true}
          >
            {t('giftMoney.tip1')}
          </Confirm>
        </DialogModal>

        <DialogModal
          shouldCloseOnOverlayClick={true}
          noentered={true}
          style={{
            content: {
              display: 'inline-block',
              textAlign: 'left',
              top: '0px',
              maxWidth: '100%',
              cursor: 'default',
              outline: '0px',
              verticalAlign: 'middle',
              zIndex: 'auto',
            },
          }}
          handleClose={() => {
            setIsShowTip2(false);
          }}
          isOpen={isShowTip2}
        >
          <Confirm
            handleConfirm={() => {
              setIsShowTip2(false);

              const onGoingList = list.filter((item) => {
                return item.status == 1;
              });
              handleShowOnGoingGames(onGoingList[0]);
            }}
            handleClose={() => {
              setIsShowTip2(false);
            }}
            title={t('game.tips')}
            confirmText={t('giftMoney.unlock_bonus')}
            isConfirmBtnPrimary={true}
          >
            {t('giftMoney.tip2')}
          </Confirm>
        </DialogModal>

        <Header
          title={t('giftMoney.name')}
          showQuestion={true}
          handleShowQuestion={handleShowQuestion}
          handleClose={handleClose}
          isDialog={isDialog}
        />
        {configLoading ? (
          <Loading className1="h-[4rem]" />
        ) : (
          <>
            <div className="flex flex-col pb-[.3rem]">
              <div className="flex flex-row text-[.24rem] font-[500] text-[var(--colour-41)]">
                {t('giftMoney.total')}
              </div>
              <div className="flex flex-row items-center justify-between mt-[.16rem] h-[.8rem]">
                <div className="flex-1 h-full flex flex-row items-center justify-start bg-[var(--colour-34)] text-[var(--colour-38)] space-x-[.3rem] rounded-normal px-[.32rem] text-[.32rem] font-[700] border border-[var(--colour-6)]">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>
                  <span>{getShowMoney(userClaimMoney.total_money)}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row text-[.24rem] font-[500] text-[var(--colour-41)]">
                {t('giftMoney.claimabel')}
              </div>
              <div className="flex flex-row items-center justify-between mt-[.16rem] h-[.8rem]">
                <div className="flex-1 h-full flex flex-row items-center justify-start bg-[var(--colour-34)] text-[var(--colour-38)] space-x-[.3rem] rounded-normal px-[.32rem] text-[.32rem] font-[700] border border-[var(--colour-6)]">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>
                  <span>{getShowMoney(userClaimMoney.claim_money)}</span>
                </div>
                <LimitClickWrapper
                  className="flex flex-row items-center text-[var(--colour-2)] bg-[var(--colour-1)] rounded-normal cursor-pointer justify-center text-center w-[2.16rem] h-[.8rem] ml-[.08rem]"
                  onClick={handleClaimAll}
                >
                  <div className="font-semibold text-[.24rem]">{t('giftMoney.claimAll')}</div>
                </LimitClickWrapper>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row justify-start mt-[.2rem]">
                <TippyPopBox
                  placement={Placement.bottomEnd}
                  popChildren={
                    <PopChoose
                      scrollBarWrapperClassName="h-[4.8rem]"
                      className={`w-[3.06rem] py-[.2rem] bg-[var(--colour-34)] border border-[var(--colour-6)]`}
                      children={typeList.map((item, index) => (
                        <PopChooseItem
                          className="h-[.8rem] flex items-center text-[.24rem] font-[500] pl-[.2rem]"
                          key={index}
                          data={item}
                          choose={params.type == item.key}
                          onClick={() => {
                            handleFilterType(item);
                          }}
                        >
                          <span className="ml-[.2rem]">{item.value}</span>
                        </PopChooseItem>
                      ))}
                    />
                  }
                  visible={filterTypeVisible}
                  onClickOutside={() => {
                    setFilterTypeVisible(false);
                  }}
                  offset={[0, 10]}
                >
                  <div>
                    <DropDownInput
                      iconCanRotate={false}
                      className={`p-[0_.4rem_0_.28rem] w-[3.06rem] h-[.8rem] text-[.24rem] font-[500] rounded-normal bg-[var(--colour-35)] border border-[var(--colour-6)] text-[var(--colour-38)]`}
                      onClick={() => {
                        setFilterTypeVisible(filterTypeVisible ? false : true);
                      }}
                    >
                      <div>{getFilterTypeStr()}</div>
                      <div className="flex flex-row items-center justify-right mx-[.1rem]">
                        <span
                          className={cn('w-[.16rem] h-[.32rem] block transition', {
                            'rotate-[90deg] text-[var(--colour-46)]': filterTypeVisible,
                            'text-[var(--colour-11)]': !filterTypeVisible,
                          })}
                        >
                          <Icon name={IconName.RightArrow2} />
                        </span>
                      </div>
                    </DropDownInput>
                  </div>
                </TippyPopBox>
              </div>
              <div className="flex flex-row justify-start mt-[.2rem]">
                <TippyPopBox
                  placement={Placement.bottomEnd}
                  popChildren={
                    <PopChoose
                      scrollBarWrapperClassName="h-[4.8rem]"
                      className={`w-[3.06rem] py-[.2rem] bg-[var(--colour-34)] border border-[var(--colour-6)]`}
                      children={statusList.map((item, index) => (
                        <PopChooseItem
                          className="h-[.8rem] flex items-center text-[.24rem] font-[500] pl-[.2rem]"
                          key={index}
                          data={item}
                          choose={params.status == item.key}
                          onClick={() => {
                            handleFilterStatus(item);
                          }}
                        >
                          <span className="ml-[.2rem]">{item.value}</span>
                        </PopChooseItem>
                      ))}
                    />
                  }
                  visible={filterStatusVisible}
                  onClickOutside={() => {
                    setFilterStatusVisible(false);
                  }}
                  offset={[0, 10]}
                >
                  <div>
                    <DropDownInput
                      iconCanRotate={false}
                      className={`p-[0_.4rem_0_.28rem] w-[3.06rem] h-[.8rem] text-[.24rem] font-[500] rounded-normal bg-[var(--colour-35)] border border-[var(--colour-6)] text-[var(--colour-38)]`}
                      onClick={() => {
                        setFilterStatusVisible(filterStatusVisible ? false : true);
                      }}
                    >
                      <div>{getFilterStatusStr()}</div>
                      <div className="flex flex-row items-center justify-right mx-[.1rem]">
                        <span
                          className={cn('w-[.16rem] h-[.32rem] block transition', {
                            'rotate-[90deg] text-[var(--colour-46)]': filterStatusVisible,
                            'text-[var(--colour-11)]': !filterStatusVisible,
                          })}
                        >
                          <Icon name={IconName.RightArrow2} />
                        </span>
                      </div>
                    </DropDownInput>
                  </div>
                </TippyPopBox>
              </div>
            </div>
            <div className="text-[var(--colour-41)] flex flex-row items-center justify-around text-[.24rem] font-[500] secondary-title t-[.2rem] mb-[.1rem] px-[.16rem] mr-[.2rem]">
              <div className="w-[25%] wrap-break-word text-center">{t('giftMoney.type')}</div>
              <div className="w-[25%] text-center">{t('giftMoney.amount')}</div>
              <div className="w-[25%] text-center">{t('giftMoney.time')}</div>
              <div className="w-[25%] text-center">{t('giftMoney.status')}</div>
            </div>
            <div className="w-full bg-[var(--colour-6)] bg-opacity-20 h-[1px] mb-[.2rem]"></div>
            <ScrollWrapper
              mr={true}
              className={cn('w-full flex flex-col', {
                'flex-1': !isDialog,
                'h-[5.6rem]': isDialog,
              })}
            >
              {loading ? (
                <Loading className1="h-[4rem]" />
              ) : (
                <>
                  {list && list.length > 0 ? (
                    list.map((item, index) => (
                      <Item
                        key={index}
                        index={index}
                        data={item}
                        handleShowDetail={() => {
                          handleShowDetail(item);
                        }}
                        handleClaim={(e) => {
                          e.stopPropagation();
                          handleClaim(item);
                        }}
                      />
                    ))
                  ) : (
                    <NoData className="py-[2rem]" />
                  )}
                </>
              )}
              {showLoadingMore ? (
                <LoadMore
                  className="mt-[.2rem]"
                  onClick={handleLoadMore}
                  showLoading={loadingMore && params.page > 1}
                />
              ) : (
                <></>
              )}
            </ScrollWrapper>
          </>
        )}
      </>
    );
  },
);

const DetailItemAttr = ({ left, right }) => {
  return (
    <div className="flex flex-row items-center justify-between px-[.36rem] font-[500] text-[.24rem]">
      <span className="text-[var(--colour-44)]">{left}</span>
      {right}
    </div>
  );
};

const Detail = forwardRef(
  ({ data, handleClose, handleBack, handleSeeGames, handleClaim, handleShowCancelPanel, isDialog }, ref) => {
    const { t } = useTranslation();
    const { websiteInitConfig } = useSelector(
      (state) => ({
        websiteInitConfig: get(state, 'app.websiteInitConfig'),
      }),
      shallowEqual,
    );
    const dispatch = useDispatch();
    const handleCancelReq = () => {
      GiftMoneyCancelReq({
        gift_money_log_id: data.id,
      }).then((res) => {
        if (res.code == 0) {
          handleBack();
          dispatch(setRefetchActivityData());
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      });
    };

    useImperativeHandle(ref, () => {
      return {
        handleCancelReq: handleCancelReq,
      };
    });
    return (
      <>
        <Header
          handleBack={handleBack}
          handleClose={handleClose}
          showBack={true}
          title={t('giftMoney.details')}
          isDialog={isDialog}
        />
        {data != null ? (
          <>
            <div className="flex flex-col pb-[.3rem]">
              <div className="flex flex-row text-[var(--colour-18)] text-[.24rem] font-[500]">
                {t('giftMoney.amount')}
              </div>
              <div className="flex flex-row items-center justify-between mt-[.16rem] h-[.96rem]">
                <div className="flex-1 h-full flex flex-row items-center justify-center bg-[var(--colour-34)] space-x-[.3rem] rounded-normal px-[.32rem] text-[.4rem] font-[700] text-[var(--colour-48)]">
                  <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>
                  <span>{getShowMoney(data.gift_money_amount)}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-[.24rem]">
              <DetailItemAttr left={t('giftMoney.status')} right={<DetailStatusStr status={data.status} />} />
              <DetailItemAttr
                left={t('giftMoney.type')}
                right={<span className="text-[var(--colour-44)]">{t(`giftMoney_filterType.${data.behaviour}`)}</span>}
              />
              <DetailItemAttr
                left={t('giftMoney.limitedToGame')}
                right={
                  <button className="underline text-[var(--colour-3)]" onClick={handleSeeGames}>
                    {t('giftMoney.seeGames')}
                  </button>
                }
              />
              <DetailItemAttr
                left={t('giftMoney.createdOn')}
                right={
                  <span className="text-[var(--colour-44)]">
                    {moment(data.created_time * 1000).format('YYYY/MM/DD')}
                  </span>
                }
              />
              <DetailItemAttr
                left={t('giftMoney.expiresIn')}
                right={
                  <span className="text-[var(--colour-44)]">
                    {moment(data.expires_time * 1000).format('YYYY/MM/DD')}
                  </span>
                }
              />
            </div>
            <div className="w-full bg-[var(--colour-6)] bg-opacity-20 h-[1px] my-[.32rem]"></div>
            <div className="flex flex-col space-y-[.24rem]">
              <DetailItemAttr
                left={t('giftMoney.wagerTimes')}
                right={<span className="text-[var(--colour-44)]">{data.unlock_multiple}x</span>}
              />
              <DetailItemAttr
                left={t('giftMoney.totalWagerRequired')}
                right={
                  <span className="text-[var(--colour-44)]">
                    {websiteInitConfig?.website_info?.currency_symbol || ''}&nbsp;{getShowMoney(data.total_wager_money)}
                  </span>
                }
              />
              <DetailItemAttr
                left={t('giftMoney.wagerCompleted')}
                right={
                  <span className="text-[var(--colour-44)]">
                    {websiteInitConfig?.website_info?.currency_symbol || ''}&nbsp;
                    {getShowMoney(
                      Number(data.user_wager_money) <= Number(data.total_wager_money)
                        ? Number(data.user_wager_money)
                        : Number(data.total_wager_money),
                    )}
                    ({getPercentage(data.user_wager_money, data.total_wager_money)}%)
                  </span>
                }
              />
              <DetailItemAttr
                left={t('giftMoney.pendingWagerRequired')}
                right={
                  <span className="text-[var(--colour-44)]">
                    {websiteInitConfig?.website_info?.currency_symbol || ''}&nbsp;
                    {getShowMoney(
                      Number(data.total_wager_money) - Number(data.user_wager_money) < 0
                        ? 0
                        : Number(data.total_wager_money) - Number(data.user_wager_money),
                    )}
                  </span>
                }
              />
              {data.status == 1 || data.status == 0 ? (
                <LimitClickWrapper
                  className="text-[var(--colour-13)] underline flex items-center justify-start px-[.36rem] cursor-pointer"
                  onClick={() => {
                    handleShowCancelPanel(data);
                  }}
                >
                  {t('giftMoney.cancelBonus')}
                </LimitClickWrapper>
              ) : (
                <></>
              )}
              {data.status == 2 ? (
                <LimitClickWrapper
                  className="text-[var(--colour-24)] underline flex items-center justify-start px-[.36rem] cursor-pointer"
                  onClick={() => {
                    handleClaim(data);
                  }}
                >
                  {t('giftMoney.claim')}
                </LimitClickWrapper>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  },
);

const GameList = ({ isFromGame, data, handleBack, handleClose, isDialog }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useStateIfMounted(true);
  const [loadingMore, setLoadingMore] = useStateIfMounted(false);
  const [total, setTotal] = useStateIfMounted(0);
  const [showLoadingMore, setShowLoadingMore] = useStateIfMounted(false);
  const [list, setList] = useStateIfMounted([]);
  const { ConfirmCloseGame } = useGameInfo();
  const pageSize = 12;

  const [params, setParams] = useStateIfMounted({
    gift_money_config_id: data?.gift_money_config_id,
    page: 1,
    page_size: pageSize,
  });

  const handleLoadMore = () => {
    setParams({ ...params, page: params.page + 1 });
  };
  useEffect(() => {
    if (data && data.gift_money_config_id) {
      if (params.page == 1) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }
      GiftMoneyGameListReq(params).then((res) => {
        if (params.page == 1) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
        if (res.code == 0) {
          const result = res.result;
          if (params.page == 1) {
            setList(result.list);
          } else {
            const list2 = list.concat(result.list);
            setList(list2);
          }
          const _pageCount = Math.ceil(result.pages.count / pageSize);
          setTotal(result.pages.count);
          if (params.page < _pageCount) {
            setShowLoadingMore(true);
          } else {
            setShowLoadingMore(false);
          }
        }
      });
    }
  }, [params]);

  const handleGameItemClick = () => {
    handleClose && handleClose();
    ConfirmCloseGame();
  };

  return (
    <>
      <Header
        handleBack={handleBack}
        handleClose={handleClose}
        showBack={true}
        title={t('giftMoney.gamesToUnlockBonuses')}
        isDialog={isDialog}
      />
      {loading ? (
        <Loading className1="h-[7.6rem]" />
      ) : (
        <>
          {list && list.length > 0 ? (
            <ScrollWrapper
              mr={true}
              className={cn('w-full flex flex-col rounded-normal p-[.2rem_.12rem]', {
                'flex-1': !isDialog,
                'h-[7.6rem]': isDialog,
              })}
            >
              <div className="gamess__row_3">
                {list.map((item, index) => (
                  <GameItem model={item} key={item.id} onClick={handleGameItemClick} />
                ))}
              </div>
              {showLoadingMore ? (
                // <LoadMore
                //   className={`my-[20px]`}
                //   onClick={handleLoadMore}
                //   showLoading={loadingMore && params.page > 1}
                // />
                <LoadingMoreWithText
                  className={`my-[.4rem]`}
                  currentNum={list.length}
                  total={total}
                  handleLoadMore={handleLoadMore}
                  showLoading={loadingMore && params.page > 1}
                />
              ) : (
                <></>
              )}
            </ScrollWrapper>
          ) : (
            <NoData className="py-[2rem]" />
          )}
        </>
      )}
    </>
  );
};

const Instructions = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[var(--colour-35)] rounded-normal px-[.34rem] w-[6.8rem] h-[9.6rem]">
      <Header handleBack={handleClose} handleClose={handleClose} showBack={true} title={t('giftMoney.instructions')} />
      <ScrollWrapper
        mr={false}
        className="w-full h-[7.8rem] wrap-break-word br-parent text-[var(--colour-44)] text-[.32rem] font-[500]"
      >
        <div dangerouslySetInnerHTML={{ __html: t('giftMoney.instructions2') }}></div>
      </ScrollWrapper>
    </div>
  );
};

export default function GiftMoney({
  isFromGame,
  handleClose = () => {},
  isDialog = true,
  giftMoneyMoveTargetClass = '',
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const detailRef = useRef(null);
  const listRef = useRef(null);
  const { InitPromotionClaimedCount } = usePromotionClaimedCount();
  const { style, startMinimize } = useMinimizeAnimation(giftMoneyMoveTargetClass, handleClose);

  const { currency_symbol } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol,
    }),
    shallowEqual,
  );

  const [showResult, setShowResult] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isShowQuestion, setIsShowQuestion] = useState(false);
  const [showPage, setShowPage] = useState(1);
  const [confirmData, setConfirmData] = useState({ gift_money_amount: 0 });
  const [detailData, setDetailData] = useState(null);
  const [moneyAmount, setMoneyAmount] = useState(0);

  useEffect(() => {
    dispatch(setIsShowPromotionBarGiftMoneyCoin(false));
    dispatch(setShowHeaderGiftMoney(false));
  }, []);

  // const handleClose = () => {
  //   dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
  // };

  const handleDetailBack = () => {
    setShowPage(1);
  };

  const handleShowDetail = (data) => {
    setDetailData(data);
    setShowPage(2);
  };

  const handleSeeGames = () => {
    setShowPage(3);
  };

  const handleShowOnGoingGames = (data) => {
    setDetailData(data);
    setShowPage(3);
  };

  const handleShowSuccess = (_moneyAmount = 0) => {
    setMoneyAmount(getShowMoney(_moneyAmount));
    setShowResult(true);
  };

  const handleGameListBack = () => {
    setShowPage(2);
  };

  const handleResultClose = () => {
    setShowResult(false);
  };

  const handleClaim = (obj) => {
    GiftMoneyClaimReq({ gift_money_log_id: obj.id }).then((res) => {
      if (res.code == 0) {
        handleShowSuccess(res?.result?.amount);
        setShowPage(1);
        dispatch(setNeedUpdateBalance({ need: true }));
        InitPromotionClaimedCount();
        const _timer = window.setTimeout(() => {
          dispatch(setRefetchActivityData());
          window.clearTimeout(_timer);
        }, 1500);
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    detailRef.current.handleCancelReq();
  };

  const handleShowCancelPanel = (item) => {
    setConfirmData(item);
    setShowConfirm(true);
  };

  const handleShowQuestion = () => {
    setIsShowQuestion(true);
  };

  const handleCloseQuestion = () => {
    setIsShowQuestion(false);
  };

  const handleCloneAndanimation = () => {
    if (giftMoneyMoveTargetClass != '') {
      startMinimize();
    } else {
      handleClose();
    }
  };

  return (
    <Backdrop id="giftMoney-outside" onClick={handleCloneAndanimation}>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '52',
            borderRadius: '10px',
          },
        }}
        isOpen={isShowQuestion}
        handleClose={handleCloseQuestion}
      >
        <Instructions handleClose={handleCloseQuestion} />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: '52',
          },
        }}
        isOpen={showResult}
        handleClose={handleResultClose}
      >
        <SuccessTips
          handleClose={handleResultClose}
          title={t('giftMoney.congratulagtions')}
          content={t('giftMoney.resultTips')}
          handleClaim={handleResultClose}
          moneyAmount={moneyAmount}
          moneySymbol={currency_symbol}
        />
      </DialogModal>

      <PopConfirm
        title={t('giftMoney.cancelBonus')}
        cancelText={t('giftMoney.cancelBonus')}
        confirmText={t('giftMoney.keepBonus')}
        visible={showConfirm}
        handleClose={handleConfirmClose}
        handleCancel={handleConfirm}
        handleConfirm={handleConfirmClose}
      >
        {t('giftMoney.cancelTips', { val: getShowMoney(confirmData.gift_money_amount) })}
      </PopConfirm>

      <div
        style={style}
        className={cn('giftmoney-container rounded-normal p-[0_.16rem_.64rem_.16rem]', {
          'w-[6.86rem] bg-[var(--colour-35)]': isDialog,
          'w-full h-full flex flex-col bg-[var(--colour-68)]': !isDialog,
        })}
      >
        {showPage == 1 ? (
          <List
            ref={listRef}
            handleShowSuccess={handleShowSuccess}
            handleClose={handleCloneAndanimation}
            handleShowDetail={handleShowDetail}
            handleShowQuestion={handleShowQuestion}
            isDialog={isDialog}
            handleShowOnGoingGames={handleShowOnGoingGames}
          />
        ) : showPage == 2 ? (
          <Detail
            ref={detailRef}
            data={detailData}
            handleClose={handleCloneAndanimation}
            handleBack={handleDetailBack}
            handleSeeGames={handleSeeGames}
            handleClaim={handleClaim}
            handleShowCancelPanel={handleShowCancelPanel}
            isDialog={isDialog}
          />
        ) : showPage == 3 ? (
          <GameList
            isFromGame={isFromGame}
            data={detailData}
            handleClose={handleCloneAndanimation}
            handleBack={handleGameListBack}
            isDialog={isDialog}
          />
        ) : (
          <></>
        )}
      </div>
    </Backdrop>
  );
}
