import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../icon';
import { isControllableSite } from '../../utils/helper';

export default function ViewAll({ onClick }) {
  const { t } = useTranslation();

  return isControllableSite() ? (
    <div
      className="flex flex-row items-center justify-center h-[.52rem] font-[500] text-[.24rem] space-x-[.16rem] text-[var(--colour-48)]"
      onClick={onClick}
    >
      <div>{t('home.seeall')}</div>
      <span className="w-[.16rem] h-[.32rem]">
        <Icon name={IconName.ChatArraw} layout="layout-3" />
      </span>
    </div>
  ) : (
    <div
      className="flex items-center px-[.32rem] h-[.52rem] font-[500] text-[.24rem] bg-[var(--colour-51)] rounded-normal text-[var(--colour-48)]"
      onClick={onClick}
    >
      <div>{t('home.seeall')}</div>
    </div>
  );
}
