import { useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DialogModal from '../dialogModal/index';
import Button from '../button';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setNeedUpdateBalance,
  setIsShowPromotionBarGiftMoneyCoin,
} from '../../redux/reducers/appSlice';
import { getShowMoney, isPromotionPath, isLogin, openHeaderGiftMoney } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import Close from '../close';
import cn from 'classnames';
import Input from '../input';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { RedeemCodeClaimReq } from '../../api';
import ScrollWrapper from '../scrollWrapper';
import SuccessTip from '../successTip';
import { getCenterPosition } from '../../hooks/useMinimizeAnimation';
import { useLocation } from 'react-router-dom';
import { toNoLocalePath } from '../../utils/localePath';
import GSAPUtils from '../../utils/gsapUtils';

export default function Redeem({ handleClose }) {
  const claimRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { websiteInitConfig, initData, activityData } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      activityData: state.app.activityData,
      initData: state.app.websiteInitData?.prompt,
    }),
    shallowEqual,
  );

  const [hasExecuted, setHasExecuted] = useState(false);
  const [showSuccessTip, setShowSuccessTip] = useState(false);
  const [formData, setFormData] = useState({
    redeem_code: '',
  });
  const [moneyAmount, setMoneyAmount] = useState('');
  const [currencyType, setCurrencyType] = useState('balance'); //领取奖励后是否飞金币
  const [amount, setAmount] = useState(0); //奖励金额

  const handleClaim = () => {
    if (isLogin()) {
      if (formData.redeem_code == '') {
        toast.error(t('redeem.please_input'), {
          containerId: 'global',
        });
        return;
      }

      RedeemCodeClaimReq(formData).then((res) => {
        if (res.code == 0) {
          setCurrencyType(res.result?.currency);
          setAmount(res.result?.amount);
          const amount = res.result?.amount || '0';
          setMoneyAmount(getShowMoney(amount));
          setShowSuccessTip(true);
          dispatch(setNeedUpdateBalance({ need: true }));
        } else {
          const id = get(res, 'result.activity_id');
          const msg = id ? `${res.msg}${t('home.toast024', { val: id })}` : res.msg;
          toast.error(msg, {
            containerId: 'global',
          });
        }
      });
    } else {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
  };

  const handleCodeChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 20);
    setFormData({
      ...formData,
      redeem_code: value,
    });
  };

  const handleCloseAnimation = () => {
    if (hasExecuted) return;

    if (currencyType == 'gift_money') {
      const element = claimRef.current.getInnerRef();
      if (element) {
        const positionData = getCenterPosition(element, true);
        if (isPromotionPath(toNoLocalePath(pathname)) && activityData?.gift_money) {
          const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
          if (giftMoneyLabel) {
            const toPoint = getCenterPosition(giftMoneyLabel, true);
            GSAPUtils.flyCoin(positionData, toPoint, GSAPUtils.COIN_COUNT, () => {
              dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
            });

            setTimeout(() => {
              handleClose();
            }, GSAPUtils.COIN_FLY_DURATION * 1000);
          }
        } else {
          openHeaderGiftMoney(amount, [positionData], handleClose);
        }
      }
    } else {
      handleClose();
    }

    setHasExecuted(true);
  };

  return (
    <>
      <DialogModal
        handleOverlayClick={handleCloseAnimation}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showSuccessTip}
        handleClose={() => {
          handleCloseAnimation();
        }}
      >
        <SuccessTip
          ref={claimRef}
          title={t('redeem.congratulations')}
          content={t('redeem.success_tip')}
          handleClose={handleCloseAnimation}
          handleClaim={handleCloseAnimation}
          moneySymbol={websiteInitConfig?.website_info?.currency_symbol}
          moneyAmount={moneyAmount}
        />
      </DialogModal>

      <div
        className={cn(
          'relative w-[6.86rem] h-[4.56rem]  bg-[var(--colour-35)] px-[.32rem] py-[.16rem] rounded-[.24rem]',
        )}
      >
        <div className="w-full flex items-center justify-between  pb-[.2rem]">
          <div className="font-[700]  text-[.36rem] text-[var(--colour-48)]">{t('redeem.redeem_code')}</div>

          <div className=" w-[.72rem]  h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center  rounded-[.08rem]">
            <Close onClick={handleClose} />
          </div>
        </div>
        <div className="w-full h-[1px] bg-[var(--colour-6)]"></div>
        <div className="flex items-center justify-center   py-[.28rem] mx-[.16rem]">
          <Input
            topClassName="flex-1  mr-[.08rem] bg-[var(--colour-51)]  rounded-[.12rem]"
            innerClassName="!h-[.64rem]"
            inputClassName="   !text-[.24rem] placeholder:!text-[.24rem] !h-[.4rem]"
            placeholder={t('redeem.please_input')}
            value={formData.redeem_code}
            onChange={handleCodeChange}
          />
          <Button
            onClick={handleClaim}
            className="block   w-[1.72rem] h-[.64rem] bg-[var(--colour-1)] mx-auto font-[700]   active:scale-[.95] transition-all select-none text-[.24rem] rounded-[.12rem] text-[var(--colour-2)]"
          >
            {t('redeem.redeem')}
          </Button>
        </div>
        <div className="w-full h-[1px] bg-[var(--colour-6)]"></div>
        <ScrollWrapper mr={false} className=" mt-[.2rem]  font-[400]  text-[.28rem] h-[1.6rem]">
          <div dangerouslySetInnerHTML={{ __html: get(initData, 'redeemCodeTips.title') || '' }}></div>
        </ScrollWrapper>
      </div>
    </>
  );
}
