import { useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import Input from '../../../../../components/input';
import DialogModal from '../../../../../components/dialogModal';
import SuccessTip from '../../../../../components/successTip';
import { isLogin, getShowMoney, isPromotionPath, openHeaderGiftMoney, cmsImgUrl } from '../../../../../utils/helper';
import { openUrl } from '../../../../../utils/openUrl';
import {
  setNeedUpdateBalance,
  setDialogModalVisible,
  DialogModalChildrenKey,
  setIsShowPromotionBarGiftMoneyCoin,
} from '../../../../../redux/reducers/appSlice';
import { RedeemCodeClaimReq } from '../../../../../api';
import { getCenterPosition } from '../../../../../hooks/useMinimizeAnimation';
import { toNoLocalePath } from '../../../../../utils/localePath';
import GSAPUtils from '../../../../../utils/gsapUtils';
import useFooterData from '../../../../../hooks/useFooterData';

export default function DTemplate003() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { websiteInitConfig, activityData, footerData, initData } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
      activityData: state.app.activityData,
      footerData: get(state, 'app.websiteInitData.footer'),
      initData: state.app.websiteInitData?.prompt,
    }),
    shallowEqual,
  );

  const { followList } = useFooterData(footerData);
  const claimRef = useRef(null);

  const [hasExecuted, setHasExecuted] = useState(false);
  const [showSuccessTip, setShowSuccessTip] = useState(false);
  const [formData, setFormData] = useState({
    redeem_code: '',
  });
  const [moneyAmount, setMoneyAmount] = useState('');
  const [currencyType, setCurrencyType] = useState('balance'); //领取奖励后是否飞金币
  const [amount, setAmount] = useState(0); //奖励金额

  const handleClaim = () => {
    if (isLogin()) {
      if (formData.redeem_code == '') {
        toast.error(t('redeem.please_input'), {
          containerId: 'global',
        });
        return;
      }

      RedeemCodeClaimReq(formData).then((res) => {
        if (res.code == 0) {
          setCurrencyType(res.result?.currency);
          setAmount(res.result?.amount);
          const amount = res.result?.amount || '0';
          setMoneyAmount(getShowMoney(amount));
          setShowSuccessTip(true);
          setHasExecuted(false);
          dispatch(setNeedUpdateBalance({ need: true }));
          setFormData({
            ...formData,
            redeem_code: '',
          });
        } else {
          const id = get(res, 'result.activity_id');
          const msg = id ? `${res.msg}${t('home.toast024', { val: id })}` : res.msg;
          toast.error(msg, {
            containerId: 'global',
          });
        }
      });
    } else {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
  };

  const handleCodeChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 20);
    setFormData({
      ...formData,
      redeem_code: value,
    });
  };

  const handleClose = () => {
    setShowSuccessTip(false);
  };

  const handleCloseAnimation = () => {
    if (hasExecuted) return;

    if (currencyType == 'gift_money') {
      const element = claimRef.current.getInnerRef();
      if (element) {
        const positionData = getCenterPosition(element, true);
        if (isPromotionPath(toNoLocalePath(pathname)) && activityData?.gift_money) {
          const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
          if (giftMoneyLabel) {
            const toPoint = getCenterPosition(giftMoneyLabel, true);
            GSAPUtils.flyCoin(positionData, toPoint, GSAPUtils.COIN_COUNT, () => {
              dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
            });

            setTimeout(() => {
              handleClose();
            }, GSAPUtils.COIN_FLY_DURATION * 1000);
          }
        } else {
          openHeaderGiftMoney(amount, [positionData], handleClose);
        }
      }
    } else {
      handleClose();
    }

    setHasExecuted(true);
  };

  return (
    <div className="bg-[var(--colour-68)] h-full p-[.4rem_.3rem]">
      <div className="h-full overflow-y-auto scrollbar-none">
        <div className="mb-[.62rem]">
          <img className="w-full" src={cmsImgUrl(get(initData, 'redeemCodeTips.banner') || '')} alt="" />
        </div>
        <div className="text-[.28rem] font-[600] leading-[.34rem] text-[var(--colour-46)] mb-[.46rem]">
          {t('promotion.redeem_text')}
        </div>
        <Input
          topClassName="bg-[var(--colour-54)] rounded-[.12rem] mb-[.48rem]"
          innerClassName="!h-[.92rem]"
          inputClassName="!text-[.24rem] placeholder:!text-[.24rem] placeholder:!text-[var(--colour-41)]"
          placeholder={t('redeem.please_input')}
          value={formData.redeem_code}
          onChange={handleCodeChange}
        />
        <div
          className="h-[.76rem] rounded-[.2rem] colour-79 text-[.26rem] font-[500] leading-[.76rem] text-[var(--colour-2)] text-center cursor-pointer mb-[.66rem]"
          onClick={handleClaim}
        >
          {t('redeem.redeem')}
        </div>
        <div className="flex items-center justify-center flex-wrap space-x-[.28rem] mb-[1rem]">
          {followList && followList.length > 0
            ? followList.map((item, index) => (
                <div key={index} className={`cursor-pointer`}>
                  {item.url === '' ? (
                    <img className="w-[.5rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                  ) : (
                    <a
                      onClick={() => {
                        openUrl(item.url, '_blank');
                      }}
                    >
                      <img className="w-[.5rem]" src={`${cmsImgUrl(item.img)}`} alt={item.title} />
                    </a>
                  )}
                </div>
              ))
            : null}
        </div>
        <div className="text-[.24rem] font-[600] leading-[.3rem] text-[var(--colour-38)]">
          <div dangerouslySetInnerHTML={{ __html: get(initData, 'redeemCodeTips.title') || '' }}></div>
        </div>
      </div>

      <DialogModal
        handleOverlayClick={handleCloseAnimation}
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        isOpen={showSuccessTip}
        handleClose={() => {
          handleCloseAnimation();
        }}
      >
        <SuccessTip
          ref={claimRef}
          title={t('redeem.congratulations')}
          content={t('redeem.success_tip')}
          handleClose={handleCloseAnimation}
          handleClaim={handleCloseAnimation}
          moneySymbol={websiteInitConfig?.website_info?.currency_symbol}
          moneyAmount={moneyAmount}
        />
      </DialogModal>
    </div>
  );
}
