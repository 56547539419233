const Badge = ({ text, className = '', textClassName = '' }) => {
  return (
    <div
      className={`min-w-[.4rem] h-[.24rem] whitespace-nowrap text-[.18rem] font-[500] leading-[.22rem] text-[var(--colour-43)] rounded-[.12rem_.12rem_.12rem_0rem] absolute bg-[var(--colour-25)] text-center px-[.1rem] top-[-0.04rem] ${className}`}
    >
      <div className={textClassName}>{text}</div>
      <div className="w-[0rem] h-[0rem] border-[.04rem] border-solod border-transparent border-t-2 border-t-[var(--colour-25)] border-l-2 border-l-[var(--colour-25)] absolute bottom-[-0.06rem] left-[0rem]"></div>
    </div>
  );
};

export default Badge;
