import cn from 'classnames';
import { useState } from 'react';
import Icon, { IconName } from './icon';
import { useSpring, animated } from 'react-spring';
import { getTemplateId } from '../utils/helper';

const DrawerStatistics = ({ children, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const styles = useSpring({
    transform: isOpen ? 'translateY(0%)' : 'translateY(calc(85%))',
    config: { duration: 200 },
  });

  return (
    <>
      <animated.div
        className={`w-full bg-[var(--colour-35)] box-shadow-drawer z-[9999] border-t border-[var(--colour-68)] ${className}`}
        style={{
          ...styles,
          position: `${getTemplateId() == 'd-template003' ? 'fixed' : 'absolute'}`,
          bottom: 0,
          left: 0,
        }}
      >
        <div
          className="w-[.7rem] h-[.35rem] rounded-[.5rem_.5rem_0_0] absolute abs-x-center top-[-.33rem] bg-[var(--colour-35)] box-shadow-drawer z-[99999] flex items-center justify-center"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div
            className={cn('text-[var(--colour-1)] w-[.18rem] h-[.18rem] transition-all', {
              'rotate-90': isOpen,
              'rotate-[-90deg]': !isOpen,
            })}
          >
            <Icon name={IconName.BannerNext2} />
          </div>
        </div>
        {children}
      </animated.div>
    </>
  );
};

export default DrawerStatistics;
