import DTemplate003 from './design/template003';
import NTemplate001 from './normal/template001';
import { getTemplateId } from '../../utils/helper';

export default function Index({ page = '', showPositionNum, setCurrentIndex }) {
  switch (getTemplateId()) {
    case 'n-template001':
    case 'n-template002':
    case 'd-template001':
    case 'd-template002':
      return <NTemplate001 page={page} showPositionNum={showPositionNum} setCurrentIndex={setCurrentIndex} />;
    case 'd-template003':
      return <DTemplate003 page={page} showPositionNum={showPositionNum} setCurrentIndex={setCurrentIndex} />;
  }
}
