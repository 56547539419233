import cn from 'classnames';
import Icon, { IconName } from '../../../../../icon';
import BubbleBox from './bubbleBox';

export default function ItemBox({
  className = 'w-[2.2rem] h-[.8rem] justify-center',
  children,
  selected = true,
  bubbleBoxInfo = '',
  click = () => {},
}) {
  return (
    <div
      onClick={click}
      className={cn(`relative flex items-center rounded-[.1rem] border text-[.24rem] font-[700] ${className}`, {
        'border-[var(--colour-6)] text-[var(--colour-44)]': !selected,
        'border-[var(--colour-1)] text-[var(--colour-13)]': selected,
      })}
    >
      {children}
      {bubbleBoxInfo ? (
        <div className="absolute top-[-.2rem] left-[20%]">
          <BubbleBox>{bubbleBoxInfo}</BubbleBox>
        </div>
      ) : null}
      {selected ? (
        <span className="w-[.38rem] h-[.36rem] absolute right-[-.02rem] bottom-[-.02rem]">
          <Icon name={IconName.SelectMask} />
        </span>
      ) : null}
    </div>
  );
}
