import { useState } from 'react';
import MusicMenu from '../components/dialogModal/playermusic/musicConfig.json';
import { setaudioPlayerStatus } from '../redux/reducers/appSlice';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

const AudioPlayer = () => {
  const dispatch = useDispatch();
  const { audioPlayerStatus } = useSelector(
    (state) => ({
      audioPlayerStatus: state.app.audioPlayerStatus,
    }),
    shallowEqual,
  );

  let audio = null;

  const songList = MusicMenu.data;
  const [songsobj, setsongsobj] = useState({
    name: songList[0].name,
    total: songList.length,
  });

  const handleSaveLocal = (newId) => {
    const existingData = localStorage.getItem('CMS-MUSIC-IDS');
    let idsObject = {};
    if (existingData) {
      idsObject = JSON.parse(existingData);
      const idsArray = idsObject.value ? idsObject.value.split(',') : [];
      if (!idsArray.includes(newId)) {
        idsArray.push(newId);
        idsObject.value = idsArray.join(',');
      }
    } else {
      idsObject.value = newId;
    }

    localStorage.setItem('CMS-MUSIC-IDS', JSON.stringify(idsObject));
  };

  const handlePlay = () => {
    if (audioPlayerStatus.isPlaying) {
      audio.pause();
      dispatch(setaudioPlayerStatus({ ...audioPlayerStatus, isPlaying: false }));
    } else {
      if (audio.src == '') {
        playSong(0);
      } else {
        dispatch(setaudioPlayerStatus({ ...audioPlayerStatus, isPlaying: true }));
      }
      audio.play();
    }
  };

  const playSong = (index) => {
    const song = songList[index];
    handleSaveLocal(song.id);
    audio.src = song.fileUrl;
    audio.play();

    dispatch(
      setaudioPlayerStatus({ ...audioPlayerStatus, musicName: song.name, isPlaying: true, currentIndex: index }),
    );
  };

  const hadnlePrev = () => {
    const count = audioPlayerStatus.currentIndex - 1;
    if (count < 0) {
      playSong(songList.length - 1);
    } else {
      playSong(audioPlayerStatus.currentIndex - 1);
    }
  };

  const handleNext = () => {
    const count = audioPlayerStatus.currentIndex + 1;
    if (count > songList.length - 1) {
      playSong(0);
    } else {
      playSong(audioPlayerStatus.currentIndex + 1);
    }
  };

  function formatTime(seconds) {
    const minutes = Number.isNaN(Math.floor(seconds / 60)) ? 0 : Math.floor(seconds / 60);
    const remainingSeconds = Number.isNaN(Math.floor(seconds % 60)) ? 0 : Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }

  const handleTimeUpdate = () => {
    if (audio) {
      const percentagePosition = `${(audio.currentTime / audio.duration) * 3.3}rem`;
      dispatch(
        setaudioPlayerStatus({
          ...audioPlayerStatus,
          timeTick: audio.currentTime,
          range: percentagePosition,
        }),
      );
    }
  };

  const handleChange = () => {
    switch (audioPlayerStatus.playMode) {
      case 'loop': {
        dispatch(setaudioPlayerStatus({ ...audioPlayerStatus, playMode: 'random' }));
        break;
      }
      case 'random': {
        dispatch(setaudioPlayerStatus({ ...audioPlayerStatus, playMode: 'single' }));
        break;
      }
      case 'single': {
        dispatch(setaudioPlayerStatus({ ...audioPlayerStatus, playMode: 'loop' }));
        break;
      }
    }
  };

  const setCurrentTime = (time) => {
    if (audio) {
      audio.currentTime = time;
    }
  };

  const handleCanPlayThrough = async () => {
    if (audio.duration) {
      dispatch(setaudioPlayerStatus({ ...audioPlayerStatus, timeCount: audio.duration, loading: false }));
    }
  };

  const handleEnd = () => {
    const NextIndex = getNextIndex();
    playSong(NextIndex);
  };

  const handleloadStart = () => {
    dispatch(setaudioPlayerStatus({ ...audioPlayerStatus, loading: true }));
  };

  function init() {
    audio = document.querySelector('#Audio');
  }

  init();

  const getNextIndex = () => {
    let nextIndex;
    switch (audioPlayerStatus.playMode) {
      case 'loop':
        nextIndex = audioPlayerStatus.currentIndex + 1;
        if (nextIndex >= songList.length) {
          nextIndex = 0;
        }
        break;
      case 'random':
        nextIndex = Math.floor(Math.random() * songList.length);
        break;
      case 'single':
        nextIndex = audioPlayerStatus.currentIndex;
        break;
    }
    return nextIndex;
  };

  return {
    handlePlay,
    playSong,
    hadnlePrev,
    handleNext,
    handleTimeUpdate,
    handleCanPlayThrough,
    handleChange,
    handleEnd,
    setCurrentTime,
    formatTime,
    songsobj,
    handleloadStart,
  };
};

export default AudioPlayer;
