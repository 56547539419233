import cn from 'classnames';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { ResponsiveMode } from '../../../../../../redux/reducers/appSlice';

const DataItem = ({ title, value, index, style, differentiateColors }) => {
  // 手机模式 style 1 手机左右布局，有整体背景，无单个背景，
  // 手机模式 style 2 手机上下布局，无整体背景，有单个背景
  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
    }),
    shallowEqual,
  );

  const bg2 = `flex-col items-center justify-between bg-[var(--colour-69)] h-[1.5rem] p-[.16rem_.24rem]`;

  return (
    <div
      className={cn(`flex rounded-normal`, {
        'flex-row items-center justify-between h-[.6rem]': style == 1,
        [bg2]: style == 2,
      })}
      style={{
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      <div
        className={cn('leading-[1em] text-[var(--colour-44)]', {
          'text-center break-words flex-1 flex items-center': style == 2,
        })}
      >
        {title}
      </div>
      <div
        className={cn('leading-[1em] ', {
          'text-[var(--colour-38)]': !differentiateColors,
          'text-[var(--colour-26)]': differentiateColors,
          'text-[.32rem]': style == 2,
        })}
      >
        {value}
      </div>
    </div>
  );
};

const DataItemList = ({ list = [], className = '', style = 1 }) => {
  const { responsiveMode } = useSelector(
    (state) => ({
      responsiveMode: get(state, 'app.responsiveMode'),
    }),
    shallowEqual,
  );

  const css1 = `grid-cols-2 gap-[.08rem_.24rem] rounded-normal p-[.12rem_.24rem] bg-[var(--colour-69)]`;

  return (
    <div
      className={cn(`grid font-[500] text-[.24rem] ${className}`, {
        [css1]: style == 1,
        'grid-cols-3 gap-[.2rem] ': style == 2,
      })}
    >
      {list.map((item, index) => (
        <DataItem
          key={index}
          title={item?.title}
          value={item?.value}
          index={index}
          style={style}
          differentiateColors={item?.differentiateColors}
        />
      ))}
    </div>
  );
};

export default DataItemList;
