import DialogModal from './dialogModal/index';
import { useState } from 'react';
import Icon, { IconName } from './icon';
import cn from 'classnames';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { setFissionDialog, FissionDialog, FissionDialogShowFrom } from '../redux/reducers/appSlice';
// import Close from './close';

export default function BgPopHigher({
  handleClose = () => {},
  isOpen = false,
  handleAfterClose = () => {},
  children,
  className,
  closeButton = '',
  showCloseButton = true,
  layout = 1,
  shouldCloseOnOverlayClick = true,
  zIndex = 10000,
}) {
  const {} = useSelector((state) => ({}), shallowEqual);
  const close = () => {
    handleClose();
  };
  return (
    <>
      <DialogModal
        afterClose={handleAfterClose}
        className=""
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        noentered={true}
        isZoom20={false}
        style={{
          overlay: {
            zIndex: zIndex,
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
          },
        }}
        isOpen={isOpen}
        handleClose={close}
      >
        <div
          className={cn(
            `flex items-center justify-center bg-[var(--colour-35)] rounded-normal w-[7.5rem] ${className}`,
          )}
        >
          <div
            className={cn(
              'w-full h-full relative bg-[url(../assets/img/fission/bg-bubble.png)] bg-no-repeat bg-[contain]',
              {
                // 'bg-[url(../assets/img/fission/bg-bubble.png)] bg-no-repeat bg-[contain]': layout == 1,
                // 'bg-[url(../assets/img/fission/bg-bubble.png)] bg-no-repeat bg-[contain]': layout == 2,
              },
            )}
          >
            {children}
            {showCloseButton ? (
              closeButton ? (
                <div className="absolute right-[.32rem] top-[.6rem] cursor-pointer" onClick={close}>
                  {closeButton}
                </div>
              ) : (
                <span
                  className="absolute right-[.32rem] top-[.3rem] w-[.4rem] h-[.4rem] cursor-pointer text-[var(--colour-46)]"
                  onClick={close}
                >
                  <Icon name={IconName.Close} />
                </span>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </DialogModal>
    </>
  );
}
