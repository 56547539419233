import Icon, { IconName } from '../../../icon';
import { isLogin, isControllableSite } from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../../../utils/localePath';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../../../redux/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';

const BetRecords = ({ onlyIcon, handleLinkClick = () => {} }) => {
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  const click = () => {
    handleLinkClick();
    if (isLogin()) {
      if (isControllableSite()) {
        navigate(toLocalePath('/report/1'));
      } else {
        navigate(toLocalePath('/mine'));
      }
      localStorage.setItem('isMyAccount', true);
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };

  return (
    <div
      className={`w-full h-[.86rem] rounded-normal mb-[.16rem] px-[.32rem] flex items-center text-[.24rem] font-[500] text-[var(--colour-14)] cursor-pointer ${websiteInitConfig?.website_info.website_theme == 'theme-15' ? 'bg-menu-default' : ' bg-[var(--colour-16)]'}`}
      onClick={click}
    >
      <div className="text-[var(--colour-14)] mr-[.32rem] w-[.48rem] h-[.48rem]">
        <Icon name={IconName.DrawerMenuRecords} />
      </div>
      <div>{t('home.drawer_betRecords')}</div>
    </div>
  );
};

export default BetRecords;
