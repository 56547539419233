const GoldRotation = ({ className = '', style = {} }) => {
  return (
    <div
      className={`w-[1rem] h-[1rem] bg-[url(/static/img/goldTurn.png)] gold_coin_rotation bg-[length:1rem_8rem] ${className}`}
      style={style}
    ></div>
  );
};

export default GoldRotation;
