import { useEffect, useState } from 'react';
import cn from 'classnames';
// import Button from './button';

export default function LimitClickWrapper({
  limitTime = 1.5,
  children,
  onClick = () => {},
  className = '',
  style,
  id,
}) {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disabled) {
      const timer = window.setTimeout(() => {
        setDisabled(false);
      }, limitTime * 1000);
      return () => clearTimeout(timer);
    }
  }, [disabled]);

  const handleOnClick = (e) => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    onClick(e);
  };

  return (
    <div
      className={cn(`${className}`, {
        // 'not-allowed': disabled
        'cursor-default': disabled,
      })}
      onClick={handleOnClick}
      style={style}
      id={id}
    >
      {children}
    </div>
  );
}
