import { useEffect, useState, useMemo } from 'react';

const generateUniqueId = () => {
  return Math.random().toString(36).substring(2, 10);
};

/**
 * 使用给定的div引用和可选的自定义ID，来获取并观察div的尺寸。
 *
 * 此钩子通过ResizeObserver来实时监测div的宽度变化，并提供了一个方式来获取这个宽度。
 * 如果提供了自定义ID，则返回的对象属性名将基于这个自定义ID，否则直接使用width属性。
 *
 * @param {Object} sourceDivRef - 一个div元素的引用，用于获取元素的宽度。
 * @param {string|null} customId - 可选的自定义ID，用于给返回的对象属性命名。
 * @returns {Object} - 包含div宽度的对象，属性名根据是否提供了customId来确定。
 */
const useDivSize = (sourceDivRef, customId = null) => {
  // 使用useMemo钩子来生成一个唯一的ID，避免不必要的重新计算。
  const generatedId = useMemo(generateUniqueId, []);
  // 根据是否有自定义ID，选择使用自定义ID或生成的ID。
  const uniqueId = customId || generatedId;

  // 使用useState来存储div的宽度。
  const [divWidth, setDivWidth] = useState(null);

  // 使用useEffect来观察div的尺寸变化。
  useEffect(() => {
    // 更新div宽度的函数，通过测量元素的offsetWidth来获取宽度。
    const updateDivWidth = () => {
      if (sourceDivRef.current) {
        const width = sourceDivRef.current.offsetWidth;
        setDivWidth(width);
      }
    };

    let resizeObserver;

    // 如果sourceDivRef存在，创建一个ResizeObserver来观察其尺寸变化。
    if (sourceDivRef?.current) {
      resizeObserver = new ResizeObserver(updateDivWidth);
      resizeObserver.observe(sourceDivRef.current);
    }

    // 返回一个清理函数，取消对div的观察。
    return () => {
      resizeObserver && resizeObserver.disconnect();
    };
  }, [sourceDivRef, uniqueId]);

  // 返回一个对象，根据是否有自定义ID，来决定属性名。
  return {
    ...(customId ? { [`${customId}Width`]: divWidth } : { width: divWidth }),
  };
};

export default useDivSize;
