export default function Loading2({
  isAbsolute = false,
  className = '',
  height = '',
  scale = '1',
  dotColor = '',
  innerClass = '',
}) {
  return (
    <div
      className={`${isAbsolute ? 'absolute abs-center' : ''} ${className} flex justify-center items-center`}
      style={{ height: height, transform: `scale(${scale})` }}
    >
      <div className={`lds-ellipsis ${innerClass}`}>
        <div className={`${dotColor}`}></div>
        <div className={`${dotColor}`}></div>
        <div className={`${dotColor}`}></div>
        <div className={`${dotColor}`}></div>
      </div>
    </div>
  );
}
