import Skeleton from 'react-loading-skeleton';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

const SkeletonBox = ({
  props,
  children,
  loading = true,
  handleShowCallback = () => {},
  baseColor = '#202020',
  highlightColor = '#444',
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      const _timer = window.setTimeout(() => {
        handleShowCallback();
        window.clearTimeout(_timer);
      }, 200);
    }
  }, [inView]);

  return loading ? (
    <div ref={ref}>
      <Skeleton baseColor={baseColor} highlightColor={highlightColor} {...props}></Skeleton>
    </div>
  ) : (
    children
  );
};

export default SkeletonBox;
