import { toNoLocalePath } from '../../../../utils/localePath';
import { useLocation } from 'react-router-dom';
import { isRouteWithHiddenElements, isControllableSite } from '../../../../utils/helper';

export default function ContainerWrapper({
  children,
  className,
  ispb = true,
  ispt = false,
  ptNum = '',
  isborderBottom = true,
  id,
}) {
  const { pathname } = useLocation();

  return (
    <div
      className={`container px-[.32rem] ${isRouteWithHiddenElements(toNoLocalePath(pathname)) ? '!px-[0px]' : `${isControllableSite() && toNoLocalePath(pathname) === '/' ? `!px-[0px] ${ispb ? '!pb-[.4rem]' : ''} ${ispt ? (ptNum ? `${ptNum}` : '!pt-[.4rem]') : ''} ${isborderBottom ? '!border-b !border-solid !border-[var(--colour-6)] last-of-type:!border-none' : ''}` : ''} ${className}`}
    `}
      id={id}
    >
      {children}
    </div>
  );
}
