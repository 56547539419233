import Entrance1 from './entrance1';
import Entrance2 from './entrance2';
import Entrance3 from './entrance3';
import { getRegisterConfig, getTemplateId } from '../../../utils/helper';

// loginOrSignUp 1 登录 2  注册
// getRegisterConfig(1) 注册配置 方式一
export default function Index({ loginOrSignUp = 1, handleClose = () => {} }) {
  const templateId = getTemplateId();
  console.log('tid', templateId);

  return templateId == 'd-template003' ? (
    <Entrance3 loginOrSignUp={loginOrSignUp} handleClose={handleClose} />
  ) : getRegisterConfig(1) != null ? (
    <Entrance1 loginOrSignUp={loginOrSignUp} handleClose={handleClose} />
  ) : getRegisterConfig(2) != null ? (
    <Entrance2 loginOrSignUp={loginOrSignUp} handleClose={handleClose} />
  ) : (
    <></>
  );
}
