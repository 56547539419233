import Close from '../close';
import Icon, { IconName } from '../icon';
import { getShowMoney } from '../../utils/helper';
import { useTranslation } from 'react-i18next';

const BonusBox = ({ handleClose, children }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-[6.86rem] h-[3.88rem] bg-[var(--colour-35)] overflow-hidden rounded-normal">
        <div className="ml-[.24rem]  flex justify-center items-center absolute top-[.16rem] right-[.32rem] rounded-normal bg-[var(--colour-45)] text-[var(--colour-46)] w-[.4rem] h-[.4rem]">
          <Close onClick={handleClose} />
        </div>
        <div className="w-full h-[.74rem] flex items-center px-[.4rem] ">
          <div className="font-[700] text-[.32rem] text-[var(--colour-48)]">
            {/* Bonus */}
            {t('account.bonus')}
          </div>
        </div>
        <div className="h-[1px] bg-[var(--colour-6)] mx-[.32rem]"></div>
        <div className="w-[6.22rem] h-[.84rem] flex items-center justify-center gap-[.12rem] !mt-[.64rem] bg-[var(--colour-34)] border border-[var(--colour-6)] rounded-normal m-[0_auto] text-[var(--colour-38)]">
          <div className="w-[.64rem] h-[.64rem]">
            <Icon name={IconName.Refer} />
          </div>
          <div className="font-[500] text-[.32rem] text-[var(--colour-38)]">x {children?.showAmount}</div>
        </div>
        <div className="mt-[.6rem] text-[.28rem] font-[400] flex justify-center items-center text-[var(--colour-49)]">
          {/* Click the screen to continue */}
          {t('vip.ClickContinue')}
        </div>
      </div>
    </>
  );
};

export default BonusBox;
