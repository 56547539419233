import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Close from '../close';
import { useRef, useState } from 'react';
import LimitClickWrapper from '../limitClickWrapper';
import useGameInfo from '../../hooks/useGameInfo';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Icon, { IconName } from '../icon';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getCenterPosition } from '../../hooks/useMinimizeAnimation';
import { isPromotionPath, openHeaderGiftMoney } from '../../utils/helper';
import { useLocation } from 'react-router-dom';
import { toNoLocalePath } from '../../utils/localePath';
import GSAPUtils from '../../utils/gsapUtils';
import { setExitGameTime, setIsShowPromotionBarGiftMoneyCoin } from '../../redux/reducers/appSlice';
import Backdrop from '../backdrop';

const DepositSuccess = ({ handleClose, depositData }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [hasExecuted, setHasExecuted] = useState(false);
  const { isGameOpen, ConfirmCloseGame, handleClickGameItem } = useGameInfo();

  const { websiteInfo, gameSource, activityData } = useSelector(
    (state) => ({
      websiteInfo: get(state, 'app.websiteInitConfig.website_info'),
      gameSource: get(state, 'app.gamePopData.source'),
      activityData: get(state, 'app.activityData'),
    }),
    shallowEqual,
  );

  const handleRestart = () => {
    ConfirmCloseGame();
    handleClickGameItem(depositData.gameUuid);
    dispatch(setExitGameTime(0));
  };

  const lineBoxCss = `flex items-center justify-between py-[.04rem] px-[.68rem]`;
  const titleCss = `shrink-0 text-left text-[.24rem] mr-[.08rem] text-[var(--colour-44)]`;

  const getTransactionId = () => {
    const id = get(depositData, 'transactionId');
    return `${id.slice(0, 8)}...${id.slice(-8)}`;
  };

  const handleCloseDepositSuccess = () => {
    if (hasExecuted) return;
    const target = ref.current;
    if (
      target &&
      depositData?.bonus_method == 0 &&
      !(isGameOpen && gameSource !== 0) &&
      Number(depositData?.bonus_amount) > 0 &&
      depositData?.bonus_currency == 'gift_money'
    ) {
      const PointData = getCenterPosition(target, true);
      if (isPromotionPath(toNoLocalePath(pathname)) && activityData?.gift_money) {
        const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
        if (giftMoneyLabel) {
          const toPoint = getCenterPosition(giftMoneyLabel, true);
          GSAPUtils.flyCoin(PointData, toPoint, GSAPUtils.COIN_COUNT, () => {
            dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
          });

          setTimeout(() => {
            handleClose();
          }, GSAPUtils.COIN_FLY_DURATION * 1000);
        }
      } else {
        openHeaderGiftMoney(depositData?.bonus_amount, [PointData], handleClose);
      }
    } else {
      handleClose && handleClose();
    }

    setHasExecuted(true);
  };

  return (
    <Backdrop id="depostSuccess_outSide" onClick={handleCloseDepositSuccess}>
      <div className="bg-[var(--colour-35)] text-center relative overflow-hidden w-[6.86rem] rounded-normal">
        <div className="h-[100%] text-[var(--colour-38)] text-[.24rem] p-[.24rem_.32rem_.4rem_.32rem]">
          <div className="flex flex-row justify-between items-center">
            <div className="text-[.4rem] text-[var(--colour-48)] font-semibold">
              {t('deposit_success.deposit_successful')}
            </div>
            <Close onClick={handleCloseDepositSuccess} className="bg-[var(--colour-45)] rounded-normal" />
          </div>
          <div className="w-full bg-[var(--colour-6)] h-[.02rem] mt-[.32rem] mb-[.32rem]"></div>

          <div className={lineBoxCss}>
            <div className={`${titleCss}`}>{t('deposit_success.transaction_id')}</div>
            <div
              className="flex-1 flex items-center justify-end cursor-pointer -mr-[.36rem]"
              onClick={() => {
                copy(get(depositData, 'transactionId'));
                toast.success(t('home.toast006'), {
                  containerId: 'global',
                });
              }}
            >
              {get(depositData, 'transactionId') ? (
                <>
                  <span className="text-left truncate">{getTransactionId()}</span>
                  <div className="w-[.24rem] h-[.4rem] flex items-center justify-center active:scale-[.95] transition-all select-none shrink-0 ml-[.12rem]">
                    <span className="w-[.24rem] h-[.24rem] text-[var(--colour-1)]">
                      <Icon name={IconName.Copys}></Icon>
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={lineBoxCss}>
            <div className={`${titleCss}`}>{t('deposit_success.amount')}</div>
            <div className="flex-1 text-right">
              {get(depositData, 'amount') ? `${websiteInfo?.currency_symbol || ''} ${get(depositData, 'amount')}` : ''}
            </div>
          </div>
          <div className={lineBoxCss}>
            <div className={`${titleCss}`}>{t('deposit_success.date')}</div>
            <div className="flex-1 text-right">{get(depositData, 'date') || ''}</div>
          </div>

          {isGameOpen && gameSource != 0 ? (
            <>
              <div className="w-full bg-[var(--colour-6)] h-[.02rem] mt-[.32rem]"></div>
              <div className="mt-[.4rem] text-tab text-[.24rem] px-[.2rem] text-[var(--colour-44)]">
                {t('deposit_success.restart_tip')}
              </div>
              <div className="mt-[.4rem] flex flex-row justify-center">
                <LimitClickWrapper
                  className={`flex flex-row w-[2.88rem] h-[.88rem] items-center cursor-pointer justify-center mx-[.1rem] active:scale-[.95] transition-all select-none rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)] border-[var(--colour-1)]`}
                  onClick={handleClose}
                >
                  <div className="font-[700] text-[.32rem]">{t('deposit_success.later')}</div>
                </LimitClickWrapper>

                <LimitClickWrapper
                  className={`flex flex-row w-[2.88rem] h-[.88rem] items-center  cursor-pointer justify-center mx-[.1rem] active:scale-[.95] transition-all select-none rounded-normal bg-[var(--colour-5)] border border-[var(--colour-1)] text-[var(--colour-3)]`}
                  onClick={handleRestart}
                >
                  <div className="font-[700] text-[.32rem]">{t('deposit_success.restart')}</div>
                </LimitClickWrapper>
              </div>
            </>
          ) : (
            <>
              <div
                ref={ref}
                className={`mt-[.64rem] mx-auto flex flex-row w-[2.88rem] h-[.88rem] items-center cursor-pointer justify-center active:scale-[.95] transition-all select-none rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)]`}
                onClick={handleCloseDepositSuccess}
              >
                <div className="font-[700] text-[.32rem]">{t('deposit_success.confirm')}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default DepositSuccess;
