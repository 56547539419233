import Icon, { IconName } from '../../../../../icon';

export default function BubbleBox({ children }) {
  return (
    <div className="relative">
      <span className="w-[.72rem] h-[.4rem] block text-[var(--colour-25)] absolute z-[1] left-[0rem] top-[0rem]">
        <Icon name={IconName.Bubble} />
      </span>
      <span className="absolute h-[.28rem] z-[2] text-[.2rem] left-[.2rem] top-[.01rem] text-[var(--colour-2)] bg-[var(--colour-25)] rounded-[.16rem] flex items-center justify-center pr-[.2rem]">
        {children}
      </span>
    </div>
  );
}
