import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function ReferRate({ ruleHtmlStr = '{}' }) {
  const { t } = useTranslation();

  const { defaultLang, websiteInitConfig } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const [ruleHtml, setRuleHtml] = useState('');

  useEffect(() => {
    try {
      const ruleJson = JSON.parse(ruleHtmlStr);
      if (ruleJson && ruleJson[defaultLang]) {
        setRuleHtml(ruleJson[defaultLang]);
      }
    } catch {}
  }, [ruleHtmlStr]);

  console.log('ruleHtml', ruleHtml);

  return (
    <>
      {ruleHtml.length > 0 && (
        <div
          className={cn(
            'p-[.48rem_.28rem] rounded-normal bg-[var(--colour-69)] text-[var(--colour-44)] text-[.2rem] font-[500]',
          )}
        >
          {/* <div className="flex justify-center text-center">{t('referral.invit6')}</div> */}

          {/* <div className="flex justify-center text-center mt-[.6rem]"> */}
          <div className="">
            <div
              // dangerouslySetInnerHTML={{
              //   __html: t('referral.invit7', {
              //     var1: level_ratio,
              //     var: referral_ratio,
              //   }),
              // }}
              dangerouslySetInnerHTML={{
                __html: ruleHtml,
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReferRate;
