import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { isLogin, getShowMoney } from '../../../../../utils/helper.js';
import NoData from '../../../../../components/noData';
import Loading from '../../../../../components/loading3';
import { GetMissionList, MissionClaim } from '../../../../../api/index.js';
import Icon, { IconName } from '../../../../../components/icon.jsx';
import Badge from '../../../../mine/normal/template001/components/badge.jsx';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setNeedUpdateBalance,
  setIsShowPromotionBarGiftMoneyCoin,
  setActivityItemData,
} from '../../../../../redux/reducers/appSlice.js';
import CountdownBox from '../../../../../components/countdownBox.jsx';
import DownloadManager from '../../../../../manager/downloadManager.js';
import DialogModal from '../../../../../components/dialogModal/index.jsx';
import BottomDownload from '../../../../../components/dialogModal/downLoadBtn/index.jsx';
import { useGlobalNavigate } from '../../../../../contexts/navigateContext.jsx';
import { toLocalePath } from '../../../../../utils/localePath.js';
import useLangServerConfig from '../../../../../hooks/useLangServerConfig.js';
import usePromotionClaimedCount from '../../../../../hooks/usePromotionClaimedCount.js';
import GSAPUtils from '../../../../../utils/gsapUtils.js';
import { getCenterPosition } from '../../../../../hooks/useMinimizeAnimation.js';
import { toNoLocalePath } from '../../../../../utils/localePath.js';
import useRouterHelper from '../../../../../hooks/useRouterHelper.js';

const NTemplate001 = () => {
  const { onScroll } = useOutletContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getServerLangText } = useLangServerConfig();

  const [isLoading, setIsLoading] = useState(true);

  const [filterData, setFilterData] = useState([]);

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });

  const { websiteInfo, promotionClaimedCount, needUpdateDepositSuccess } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      promotionClaimedCount: state.app.promotionClaimedCount,
      needUpdateDepositSuccess: get(state, 'app.needUpdateDepositSuccess'),
    }),
    shallowEqual,
  );

  const handleClickHistory = () => {
    if (!isLogin()) {
      return dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.LoginBox }));
    }
    onScroll('/promotion/history');
  };
  const updateList = (showLoading = true) => {
    if (showLoading) {
      setIsLoading(true);
    }

    GetMissionList()
      .then((res) => {
        if (res.code == 0) {
          let data = [];
          if (res.data.list) {
            data = res.data.list.filter((item) => {
              return [10, 11, 20, 21].includes(Number(item?.user_progress?.status));
            });
          }

          setFilterData(data);
          if (!res.data.list || (res.data.list && res.data.list.length == 0)) {
            dispatch(setActivityItemData({ mission: null }));
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateList();
  }, []);

  const claimCallback = () => {
    if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
      setBottomDownloadBoxPromptSwitch({
        visible: true,
        isForce: true,
        downloadText: t('guideWheel.downloadtips'),
      });
    }
  };

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  useEffect(() => {
    if (needUpdateDepositSuccess.key !== 0) {
      updateList(false);
    }
  }, [needUpdateDepositSuccess]);

  return (
    <>
      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0px',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0px',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>
      <div className="w-full h-full bg-[var(--colour-68)]">
        {isLoading ? (
          <Loading className1="w-full h-full"></Loading>
        ) : (
          <>
            <div className="p-[0_.2rem_.2rem_0] flex h-full">
              <div className="shrink-0 w-[1.9rem]">
                <div className="max-h-[calc(var(--app-height)-3.16rem)] overflow-y-auto scrollbar-none">
                  {/* 左侧列表 */}
                  <div
                    className={`w-[1.5rem] min-h-[.7rem] rounded-[.12rem] m-auto mt-[.18rem] mb-[.32rem] p-[.15rem_0rem_.15rem_.12rem] flex items-center cursor-pointer relative ${
                      websiteInfo.website_theme === 'theme-15'
                        ? 'bg-active text-[var(--colour-2)]'
                        : 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                    }`}
                  >
                    <div className="w-[.32rem] h-[.32rem] mr-[.08rem] shrink-0">
                      <Icon name={IconName.MissionTable1} />
                    </div>
                    <div className="text-[.16rem] font-[500] leading-[.2rem]">{t('promotion.new_player_benefits')}</div>
                    {(promotionClaimedCount?.data?.mission || 0) > 0 ? (
                      <Badge
                        textClassName="text-[var(--colour-2)] text-[.2rem] font-[500]"
                        className="!min-w-[.3rem] !min-h-[.3rem] !top-[-0.14rem] !right-[-0.12rem] flex items-center"
                        text={promotionClaimedCount?.data?.mission}
                      ></Badge>
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${websiteInfo.website_theme === 'theme-15' ? 'bg-active' : 'bg-[var(--colour-1)]'} w-[1.2rem] h-[.5rem] mx-auto rounded-[.12rem] text-[var(--colour-2)] text-[.2rem] font-[500] flex justify-center items-center active:scale-[.95] transition-all select-none cursor-pointer`}
                  onClick={handleClickHistory}
                >
                  {t('promotion.history')}
                </div>
              </div>
              <div className="pt-[0.18rem] flex-1">
                {filterData && filterData.length > 0 ? (
                  <div className="max-h-[calc(var(--app-height)-2.72rem)] scrollbar-none overflow-y-auto">
                    {filterData.map((item) => {
                      return (
                        <div key={item.id}>
                          <DataContent
                            type={item.mission_type}
                            item={item}
                            claimCallback={claimCallback}
                            upDataList={() => {
                              updateList(false);
                            }}
                          />
                        </div>
                      );
                    })}

                    <ContentText text={getServerLangText('misson_describe')} />
                  </div>
                ) : (
                  <NoData className="w-full h-full"></NoData>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NTemplate001;

const DefaultContent = ({
  icon = '',
  text = '',
  navigateDataPage = () => {},
  btnType = 10,
  rewardAmount = 0,
  rewardType = 'balance',
  time = 0,
  claimId = 0,
  claimCallback = () => {},
  upDataList = () => {},
}) => {
  // btnType  20:未到领奖时间  21:可领取  10:未完成  11:结算中

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { InitPromotionClaimedCount } = usePromotionClaimedCount();
  const { gift_money_menu_show_switch } = useSelector(
    (state) => ({
      gift_money_menu_show_switch: state?.app?.activityStatus?.gift_money_menu_show_switch,
    }),
    shallowEqual,
  );

  const handleClaim = (e) => {
    MissionClaim({ pending_id: claimId }).then((res) => {
      if (res.code == 0) {
        const balanceCallback = () => {
          toast.success(t('promotion.claim_success'), {
            containerId: 'global',
          });
        };

        const giftMoneyCallback = () => {
          const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
          const element = e.target;
          if (giftMoneyLabel && element) {
            dispatch(setIsShowPromotionBarGiftMoneyCoin(false));
            GSAPUtils.flyCoin(
              getCenterPosition(element, true),
              getCenterPosition(giftMoneyLabel, true),
              GSAPUtils.COIN_COUNT,
              () => {
                if (toNoLocalePath(pathname) != '/promotion/giftmoney') {
                  dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                }
              },
            );
          }
        };

        if (rewardType == 'gift_money') {
          if (gift_money_menu_show_switch == '1') {
            giftMoneyCallback();
          } else {
            balanceCallback();
          }
        } else {
          balanceCallback();
        }
        upDataList();
        dispatch(setNeedUpdateBalance({ need: true }));
        InitPromotionClaimedCount();
      } else if (res.code == 1008127) {
        claimCallback();
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };
  return (
    <div className="min-h-[1.9rem] rounded-[.08rem] mb-[.2rem] bg-[var(--colour-35)] p-[.2rem_.16rem_.14rem_.16rem] shadow-[0_.04rem_.08rem_0_rgba(0,0,0,0.1)]">
      <div className="min-h-[1.02rem] flex items-center mb-[.02rem]">
        <div className="w-[.48rem] h-[.48rem] mr-[.12rem] shrink-0 text-[var(--colour-37)]">
          <Icon name={icon} />
        </div>
        <div className="text-[var(--colour-48)] text-[.24rem] font-[500] leading-[.34rem]">{text}</div>
      </div>
      <div className="pl-[.6rem] flex items-center justify-between flex-wrap">
        <div className="text-[var(--colour-26)] text-[.28rem] font-[500] leading-[.4rem]">
          {t('home.bonus')} {getShowMoney(rewardAmount)}
        </div>
        {btnType == 20 ? (
          <div className="rounded-[.16rem] min-w-[2.16rem] h-[.52rem] px-[.1rem] text-[var(--colour-2)] text-[.2rem] font-[500] leading-[.2rem] flex flex-col items-center justify-center bg-[var(--colour-42)] ml-auto">
            <div className="mb-[.06rem]">{t('promotion.to_receive')}</div>
            <MissionCountdown
              date={time * 1000}
              onComplete={() => {
                upDataList();
              }}
              type={btnType}
            />
          </div>
        ) : btnType == 21 ? (
          <div
            className="rounded-[.16rem] min-w-[2.16rem] h-[.52rem] px-[.1rem] text-[var(--colour-2)] text-[.2rem] font-[500] leading-[.2rem] flex flex-col items-center justify-center bg-[var(--colour-29)] cursor-pointer active:scale-[.95] transition-all select-none ml-auto"
            onClick={handleClaim}
          >
            <div className="mb-[.06rem]">{t('promotion.to_receive')}</div>
            <MissionCountdown
              date={time * 1000}
              onComplete={() => {
                upDataList();
              }}
              type={btnType}
            />
          </div>
        ) : btnType == 10 ? (
          <div
            className="rounded-[.16rem] min-w-[2.16rem] h-[.52rem] px-[.1rem] text-[var(--colour-2)] text-[.2rem] font-[500] leading-[.2rem] flex flex-col items-center justify-center bg-[var(--colour-1)] cursor-pointer active:scale-[.95] transition-all select-none ml-auto"
            onClick={() => {
              navigateDataPage();
            }}
          >
            <div>{t('home.continue')}</div>
          </div>
        ) : btnType == 11 ? (
          <div className="rounded-[.16rem] min-w-[2.16rem] h-[.52rem] px-[.1rem] text-[var(--colour-1)] text-[.24rem] font-[700] leading-[.24rem] flex flex-col items-center justify-center ml-auto">
            <div>{t('promotion.calculating_result')}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const DataContent = ({ type = '', item = null, claimCallback = () => {}, upDataList = () => {} }) => {
  const navigate = useGlobalNavigate();
  const { t } = useTranslation();
  const { handleClickWallet } = useRouterHelper();
  const rewardAmount = () => {
    return [10, 11].includes(Number(item?.user_progress?.status))
      ? item.reward_amount
      : item.user_pending.reward_amount;
  };
  const rewardType = () => {
    return [10, 11].includes(Number(item?.user_progress?.status)) ? item.reward_type : item.user_pending.reward_type;
  };
  const time = () => {
    return item?.user_progress?.status == 20
      ? item.user_pending.claim_start_time
      : item?.user_progress?.status == 21
        ? item.user_pending.claim_end_time
        : 0;
  };
  const claimId = () => {
    return item?.user_progress?.status == 21 ? item.user_pending.id : 0;
  };
  return type == 1 ? (
    <DefaultContent
      icon={IconName.Cpf2}
      text={t('promotion.mission_cpf_text')}
      navigateDataPage={() => {
        if (
          item?.user_progress?.behaviours &&
          item?.user_progress?.behaviours.includes('bind_cpf') &&
          item?.require_deposit == 1
        ) {
          handleClickWallet();
        } else {
          localStorage.setItem('data_show_popup', 'cpf');
          navigate(toLocalePath('/myaccountdata'));
        }
      }}
      btnType={item?.user_progress?.status}
      rewardAmount={rewardAmount()}
      rewardType={rewardType()}
      time={time()}
      claimId={claimId()}
      claimCallback={claimCallback}
      upDataList={upDataList}
    />
  ) : type == 2 ? (
    <DefaultContent
      icon={IconName.Phone3}
      text={t('promotion.mission_phone_text')}
      navigateDataPage={() => {
        localStorage.setItem('data_show_popup', 'phone');
        navigate(toLocalePath('/myaccountdata'));
      }}
      btnType={item?.user_progress?.status}
      rewardAmount={rewardAmount()}
      rewardType={rewardType()}
      time={time()}
      claimId={claimId()}
      claimCallback={claimCallback}
      upDataList={upDataList}
    />
  ) : type == 3 ? (
    <DefaultContent
      icon={IconName.Vector}
      text={t('promotion.mission_email_text')}
      navigateDataPage={() => {
        localStorage.setItem('data_show_popup', 'email');
        navigate(toLocalePath('/myaccountdata'));
      }}
      btnType={item?.user_progress?.status}
      rewardAmount={rewardAmount()}
      rewardType={rewardType()}
      time={time()}
      claimId={claimId()}
      claimCallback={claimCallback}
      upDataList={upDataList}
    />
  ) : null;
};

const ContentText = ({ text = null }) => {
  return text ? (
    <div className="mb-[.2rem] rounded-[.08rem] bg-[var(--colour-35)] shadow-[0_.04rem_.08rem_0_rgba(0,0,0,0.1)] p-[.2rem_0.1rem_.2rem_.16rem] text-[var(--colour-48)] text-[.24rem] font-[400] leading-[140%]">
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  ) : null;
};

const MissionCountdown = ({ date, onComplete, btnType = 10 }) => {
  const { t } = useTranslation();

  const renderer = ({ completed, formatted }) => {
    return !completed ? (
      <div className="text-[.16rem] leading-[.16rem] flex items-center">
        {btnType == 20 && <div className="mr-[.04rem]">{t('promotion.after')}</div>}
        <div className="">
          <span className="">
            {Number(formatted.days) > 0 && <span className="mr-[.04rem]">{formatted.days}Days</span>}
            <span>{formatted.hours}:</span>
            <span>{formatted.minutes}:</span>
            <span>{formatted.seconds}</span>
          </span>
        </div>
        {btnType == 21 && <div className="ml-[.04rem]">{t('promotion.canceled')}</div>}
      </div>
    ) : (
      <div className="text-[.16rem] leading-[.16rem] flex items-center">
        {btnType == 20 && <div className="mr-[.04rem]">{t('promotion.after')}</div>}
        <div>00:00:00</div>
        {btnType == 21 && <div className="ml-[.04rem]">{t('promotion.canceled')}</div>}
      </div>
    );
  };

  return <CountdownBox date={date} onComplete={onComplete} renderer={renderer} />;
};
