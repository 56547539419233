import { useEffect } from 'react';

/**
 * 可拖动滚动钩子
 *
 * 此钩子用于在指定的容器内实现拖动滚动的效果它通过监听鼠标事件来控制容器的滚动行为
 *
 * @param {Object | string} containerRef - 容器的引用
 * @param {any} data - 数据变化时创建的dom，需要传入监听的数据
 * @param {boolean} isContainerRef - 指示containerRef参数是否为容器引用如果为true，则直接使用containerRef，否则通过document.querySelector获取容器
 */

const useDraggableScroll = (containerRef = null, data = [], isContainerRef = true) => {
  useEffect(() => {
    const scrollContainer = isContainerRef ? containerRef.current : containerRef;

    if (scrollContainer) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const handleMouseDown = (e) => {
        isDown = true;
        startX = e.pageX - scrollContainer.offsetLeft;
        scrollLeft = scrollContainer.scrollLeft;
        scrollContainer.style.cursor = 'grabbing';
      };

      const handleMouseLeaveOrUp = () => {
        isDown = false;
        scrollContainer.style.cursor = 'grab';
      };

      const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - scrollContainer.offsetLeft;
        const walk = (x - startX) * 2;
        scrollContainer.scrollLeft = scrollLeft - walk;
      };

      scrollContainer.addEventListener('mousedown', handleMouseDown);
      scrollContainer.addEventListener('mouseleave', handleMouseLeaveOrUp);
      scrollContainer.addEventListener('mouseup', handleMouseLeaveOrUp);
      scrollContainer.addEventListener('mousemove', handleMouseMove);

      return () => {
        scrollContainer.removeEventListener('mousedown', handleMouseDown);
        scrollContainer.removeEventListener('mouseleave', handleMouseLeaveOrUp);
        scrollContainer.removeEventListener('mouseup', handleMouseLeaveOrUp);
        scrollContainer.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, data);
};

export default useDraggableScroll;
