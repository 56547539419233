export default function Index({ percent = 0 }) {
  const getShowPercent = (p) => {
    return Number(p) > 100 ? 100 : Number(p);
  };
  return (
    <div className="flex flex-row items-center justify-center space-x-[.24rem]">
      <div
        className="relative w-[4.34rem] h-[.32rem] rounded-normal px-[.04rem] flex items-center"
        style={{
          background: 'linear-gradient(180deg, #333137 0%, #15141A 100%)',
        }}
      >
        <div
          className="rounded-normal h-[.24rem]"
          style={{
            width: `${getShowPercent(percent)}%`,
            background: 'linear-gradient(180deg, #FFE485 0%, #FFF59A 19.5%, #D97F2B 85%, #FFD66B 100%)',
          }}
        ></div>
      </div>
      <span className="font-[500] text-[.28rem] text-[var(--colour-48)]">{getShowPercent(percent)}%</span>
    </div>
  );
}
