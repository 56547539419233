import cn from 'classnames';
import Loading from '../../loading2';
import Wheel from './components/wheel';
import Guide from './components/guide';
import { Guidedraw, Guideturntable } from '../../../api';
import { useState, useEffect } from 'react';
import WheelRecord from './components/record';
import ExpirationTime from '../../dialogModal/components/expirationTime';
// import Close from '../../../assets/img/bulletinClose.png';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  setActivityItemData,
  setguideWheelSwitch,
  setRefetchActivityData,
  setwheelBonusAmount,
} from '../../../redux/reducers/appSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { setSpinguideWheelID, setSpinguideWheelStatus } from '../../../redux/reducers/userSlice';
import NoData from '../../noData';
import Close from '../../close';

const PilotWheel = ({ handleClose, content }) => {
  const [result, setresult] = useState(null);
  const [isloading, setisLoading] = useState(true);
  const [pendingLog, setPendingLog] = useState(null);
  const [fulfill, setfulfill] = useState(false); // 任务是否全部完成
  const [isfinish, setisFinish] = useState(false); // 记录轮盘旋转是否完成
  const [noviceTaskConfig, setnoviceTaskConfig] = useState([]); // 任务列表
  const [novicePilotSwitch, setnovicePilotSwitch] = useState(null); // 引导是否结束
  const [LogList, setLoglist] = useState([]);
  const [taskStatus, setTaskStatus] = useState(null); // 任务状态(10:未完成,11:结算中,20:未到领奖时间,21:可领取,22:已领取,30:奖励过期)

  const { SpinguideWheelStatus, identity, SpinguideWheelID } = useSelector(
    (state) => ({
      identity: state.user.identity,
      SpinguideWheelStatus: state.user.SpinguideWheelStatus || '0',
      SpinguideWheelID: state.user.SpinguideWheelID,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();

  const updateWheelActivityID = (id) => {
    if (SpinguideWheelID == '') {
      dispatch(setSpinguideWheelID(id || ''));
    } else {
      if (id != SpinguideWheelID) {
        dispatch(setSpinguideWheelStatus('0'));
        dispatch(setSpinguideWheelID(id || ''));
      }
    }
  };

  const updateisRotateWheelStatus = (status, num, id) => {
    if (SpinguideWheelStatus === '1' && identity && status == 1) {
      setisLoading(true);
      Guidedraw({ activity_id: id }).then((res) => {
        if (res.code === 0) {
          const data = res.result?.activity_info.novice_pilot_switch;
          setnovicePilotSwitch(data);
          setisLoading(false);
        }
      });
    } else {
      setnovicePilotSwitch(status);
    }
  };

  const InitData = (list) => {
    setisLoading(false);
    const ItemList = list[0] ?? {};
    setresult(ItemList);
    setTaskStatus(ItemList.task_status);
    setPendingLog(ItemList.pending_log);
    updateWheelActivityID(ItemList.id);
    setnoviceTaskConfig(ItemList.novice_task_config);
    if (ItemList.novice_pilot_switch != 0) {
      dispatch(setwheelBonusAmount(ItemList.novice_pilot_rewards.amount));
    }
    updateisRotateWheelStatus(ItemList.novice_pilot_switch, ItemList.draw_num, ItemList.id);
  };

  const getWheelInitinfo = async () => {
    setisLoading(true);
    try {
      const res = await Guideturntable();
      if (res.code == 0) {
        if (res.result?.list?.length > 0) {
          InitData(res.result?.list);
          setLoglist(res.result?.log_list);
          //dispatch(setActivityItemData({ activity_novice_guide_tournament: res.result }));
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (noviceTaskConfig && noviceTaskConfig.length > 0 && result && result.novice_pilot_switch != 0) {
      const total = noviceTaskConfig.length;
      const _len = noviceTaskConfig.filter((item) => {
        return item.status === '2';
      }).length;

      if (total == _len) {
        setfulfill(true);
        dispatch(setguideWheelSwitch(true));
      } else {
        setfulfill(false);
        dispatch(setguideWheelSwitch(false));
      }
    } else {
      if (result) {
        if (result.novice_pilot_switch == 0 && result.draw_num > 0) {
          dispatch(setguideWheelSwitch(true));
        } else {
          dispatch(setguideWheelSwitch(false));
        }
      }
    }
  }, [noviceTaskConfig, result]);

  useEffect(() => {
    if (content) {
      InitData(content.list);
      setLoglist(content.log_list);
    } else {
      getWheelInitinfo();
    }
  }, [content]);

  const onComplete = () => {
    dispatch(setRefetchActivityData());
  };
  return (
    <>
      {!isloading ? (
        <div
          className={cn('w-[6.86rem] h-[9.84rem] bg-[var(--colour-35)] rounded-[.16rem] relative select-none', {
            'pt-[1rem]': !identity || (identity && fulfill),
            'pt-[.56rem]': identity && !fulfill,
            '!bg-[var(--colour-34)]':
              (!identity && SpinguideWheelStatus != '1' && novicePilotSwitch == 0) ||
              (novicePilotSwitch == 0 && identity),
          })}
        >
          <div
            className="w-[.52rem] h-[.48rem] cursor-pointer absolute right-0 top-[-0.6rem] bg-[var(--colour-45)] rounded-small flex items-center justify-center"
            onClick={() => {
              handleClose();
              if (result == null) {
                dispatch(setRefetchActivityData());
              }
              if (
                (!identity && SpinguideWheelStatus == '1') ||
                (identity && (isfinish || (novicePilotSwitch && novicePilotSwitch == 2)))
              ) {
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_Close2);
              } else {
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Wheel_Close);
              }
            }}
          >
            <Close />
          </div>

          {result != null ? (
            <>
              {novicePilotSwitch &&
              novicePilotSwitch != 0 &&
              result?.activity_start_time &&
              result?.activity_end_time ? (
                <ExpirationTime
                  startTime={result?.activity_start_time}
                  endTime={result?.activity_end_time}
                  handleClose={handleClose}
                />
              ) : (
                <></>
              )}

              {(!identity && SpinguideWheelStatus == '1') ||
              (identity && (isfinish || (novicePilotSwitch && novicePilotSwitch == 2))) ? (
                <>
                  <Guide
                    pendingLog={pendingLog}
                    taskStatus={taskStatus}
                    wheelRule={result?.rule_explain}
                    activityID={result?.id}
                    handleClose={handleClose}
                    noviceTaskConfig={noviceTaskConfig}
                    taskConditionDisplaySwitch={result?.task_condition_display_switch}
                  />
                </>
              ) : (
                <></>
              )}

              {(!identity && SpinguideWheelStatus != '1' && novicePilotSwitch != 0) ||
              (novicePilotSwitch && novicePilotSwitch == 1 && !isfinish && identity) ? (
                <>
                  <Wheel
                    type={'Guide'}
                    isfinish={isfinish}
                    wheelRule={result?.rule_explain}
                    activityID={result?.id}
                    setisFinish={setisFinish}
                    wheelRewardList={result?.guide_turntable_lottery_config}
                    novicePilotSwitch={novicePilotSwitch}
                  />
                </>
              ) : (
                <></>
              )}

              {(!identity && SpinguideWheelStatus != '1' && novicePilotSwitch == 0) ||
              (novicePilotSwitch == 0 && identity) ? (
                <>
                  <Wheel
                    type={'Count'}
                    drawNum={result?.draw_num}
                    isfinish={isfinish}
                    wheelRule={result?.rule_explain}
                    activityID={result?.id}
                    setisFinish={setisFinish}
                    wheelRewardList={result?.turntable_lottery_config}
                    novicePilotSwitch={novicePilotSwitch}
                  />
                </>
              ) : (
                <></>
              )}
              {novicePilotSwitch == 0 ? <WheelRecord Loglist={LogList} /> : <></>}
            </>
          ) : (
            <>
              <NoData className="mt-[3.6rem]" showTextLangkey="promotion.eventend"></NoData>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="w-[6.86rem] h-[9.84rem] bg-[var(--colour-35)] rounded-[.16rem] relative select-none flex items-center justify-center">
            <Loading></Loading>
          </div>
        </>
      )}
    </>
  );
};

export default PilotWheel;
