import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../../components/icon';

const Title = ({ text, iconName }) => {
  return (
    <div className="flex items-center space-x-[.08rem]">
      <span className="block w-[.24rem] h-[.24rem] text-[var(--colour-1)]">
        <Icon name={iconName} />
      </span>
      <span className="text-[var(--colour-44)]">{text}</span>
    </div>
  );
};

const LineBox = ({ left, right }) => {
  return (
    <div className="flex items-center justify-between">
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
};

const ActivityInfo = ({ val1, val2, val3, val4, dayNum }) => {
  const { t } = useTranslation();

  return (
    <div className="p-[.2rem] m-[.2rem_.2rem_0_.2rem] space-y-[.16rem] text-[.2rem] font-[500] border border-[var(--colour-1)] rounded-[.1rem]">
      <LineBox
        left={<Title text={t('mystery_bonus.first_deposit')} iconName={IconName.FirstDeposit} />}
        right={<span className="text-[var(--colour-38)]">{val1}</span>}
      />
      <LineBox
        left={
          <Title
            text={t('mystery_bonus.total_deposit', {
              val: dayNum,
            })}
            iconName={IconName.TotalDeposit}
          />
        }
        right={<span className="text-[var(--colour-38)]">{val2}</span>}
      />
      <LineBox
        left={<Title text={t('mystery_bonus.liquidation_time')} iconName={IconName.LiquiTime} />}
        right={<span className="text-[var(--colour-1)]">{val3}</span>}
      />
      <LineBox
        left={<Title text={t('mystery_bonus.mystery_bonus')} iconName={IconName.MysteryBonus} />}
        right={<span className="text-[var(--colour-26)]">{val4}</span>}
      />
    </div>
  );
};

export default ActivityInfo;
