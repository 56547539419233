import { useState, useEffect } from 'react';
import useLink from '../../../hooks/useLink';
import Ntemplate001 from './nromal/template001';
import Dtemplate001 from './design/template001';
import Dtemplate003 from './design/template003';
import { useSelector, shallowEqual } from 'react-redux';
import { getTemplateId, isControllableSite } from '../../../utils/helper';

const BulletinComponent = ({ handleClose = () => {}, notificationData, isCloseAnnouncement = true }) => {
  const [list, setList] = useState([]);

  const { handleCmsLink } = useLink();
  const { activityData } = useSelector(
    (state) => ({
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );

  const handleImageClick = (openMode, newWindow, link) => {
    handleCmsLink(openMode, newWindow, link);
  };

  const addClickEventToImages = (content, openMode, newWindow, link) => {
    const imgRegex = /<img[^>]+>/gi;
    const matches = content.match(imgRegex);

    if (matches) {
      matches.forEach((match) => {
        const newImgTag = match.replace(
          /(<img[^>]+)>/,
          `$1 class="cursor-pointer" onclick="handleImageClick(${openMode}, ${newWindow}, '${link}')"`,
        );

        content = content.replace(match, newImgTag);
      });
    }

    window.handleImageClick = handleImageClick;

    return content;
  };

  useEffect(() => {
    let arr = [];
    if (notificationData && notificationData.length > 0) {
      arr = notificationData;
    } else {
      const data = activityData.notification.list;
      arr = data;
    }

    const newArr = arr.map((item) => ({
      ...item,
      content:
        item.is_image_redirect == 1
          ? addClickEventToImages(item.content, item.open_mode, item.new_window, item.link)
          : item.content,
    }));

    setList(newArr);

    return () => {
      delete window.handleImageClick;
    };
  }, [notificationData]);

  return (
    <>
      {list && list.length > 0 ? (
        <>
          {getTemplateId() == 'd-template003' ? (
            <Dtemplate003 handleClose={handleClose} notificationData={list} isCloseAnnouncement={isCloseAnnouncement} />
          ) : (
            <>
              {isControllableSite() ? (
                <Dtemplate001
                  handleClose={handleClose}
                  notificationData={list}
                  isCloseAnnouncement={isCloseAnnouncement}
                />
              ) : (
                <Ntemplate001
                  handleClose={handleClose}
                  notificationData={list}
                  isCloseAnnouncement={isCloseAnnouncement}
                />
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default BulletinComponent;
