import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import countryCode from '../constants/countryCode1.json';
import Icon, { IconName } from './icon';
import Input from './input';

countryCode.sort((a, b) => a.country.toLocaleLowerCase().localeCompare(b.country.toLocaleLowerCase()));

const ChooseCountryCode = ({ className, scrollBarWrapperClassName, onClick }) => {
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue) {
      let _value = searchValue.replace('+', '');
      let searchList = [];
      for (let i = 0; i < countryCode.length; i++) {
        if (
          String(countryCode[i].code).toLowerCase().indexOf(String(_value).toLowerCase()) > -1 ||
          String(countryCode[i].country).toLowerCase().indexOf(_value) > -1
        ) {
          searchList.push(countryCode[i]);
        }
      }
      setList(searchList);
    } else {
      setList(countryCode);
    }
  }, [searchValue]);
  return (
    <div
      className={`rounded-normal border border-iconTheme backdrop-filter-64 pt-[.4rem] px-[.36rem] scrollbar-box-container ${className}`}
    >
      <div className="flex flex-col">
        <Input
          value={searchValue}
          onChange={(ev) => {
            setSearchValue(ev.target.value);
          }}
          rightInner={
            <span className="w-[.4rem] h-[.4rem] block cursor-pointer">
              <Icon name={IconName.Search} />
            </span>
          }
        />
      </div>
      <div className="bg-[#222222] h-[1px] mt-[.4rem]"></div>
      <div className={scrollBarWrapperClassName}>
        <div className="scrollbar-box-outer">
          <ul className="scrollbar-box-inner ">
            {list.map((item) => (
              <li
                onClick={() => {
                  onClick(item);
                }}
                key={item.country}
                className="flex flex-row justify-between py-[.2rem] text-[#8194B0] text-[.24rem] font-[600] cursor-pointer pr-[.4rem]"
              >
                <span>{item.country}</span>
                <span>{item.code}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChooseCountryCode;
