import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../utils/localePath';
import Icon, { IconName } from '../icon';
import SwitchWrapper from '../switchWrapper';
import { get } from 'lodash';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const HeaderSportSwitch = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const { isInSport, isSportEnabled } = useSelector(
    (state) => ({
      isInSport: get(state, 'app.isInSport'),
      isSportEnabled: get(state, 'app.isSportEnabled'),
    }),
    shallowEqual,
  );

  const switchList = [
    {
      icon: IconName.MenuCasino,
      name: t('home.gaming'),
      link: '/',
      choose: !isInSport,
    },
    {
      icon: IconName.MenuSport,
      name: t('home.sport'),
      link: '/sport',
      choose: isInSport,
    },
  ];

  return isSportEnabled ? (
    <div className="items-center justify-around bg-[var(--colour-52)] rounded-normal ml-[1rem!important] hidden">
      {switchList.map((item, index) => (
        <SwitchWrapper
          key={index}
          className="w-[3rem] h-[.96rem] flex items-center justify-center text-[.32rem] font-[700] cursor-pointer"
          chooseClassName="text-[var(--colour-1)]"
          notChooseClassName="text-[var(--colour-14)]"
          onClick={() => {
            navigate(toLocalePath(item.link));
          }}
          choose={item.choose}
        >
          <span className="w-[.48rem] h-[.48rem] block">
            <Icon name={item.icon} />
          </span>
          <span className="ml-[.24rem]">{item.name}</span>
        </SwitchWrapper>
      ))}
      <div className="absolute w-[1px] h-[.48rem] bg-[var(--colour-6)] mt-[.04rem]"></div>
    </div>
  ) : (
    <></>
  );
};

export default HeaderSportSwitch;
