import { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { get, cloneDeep } from 'lodash';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setAutoScrollToTagGameKey,
  setMobileTagIndex,
  setPpopularIndex,
} from '../../../../redux/reducers/appSlice';
import { isLogin, removeLoadingPage } from '../../../../utils/helper';
import HomeSwiperBanner from '../../components/homeBanner';
import ContainerWrapper from '../components/containerWrapper';
import Broadcast from '../../../../components/home/broadcast';
import HomeLinkBox from '../../../../components/homeLinkBox';
import UserDeposit from '../../../../components/home/userDeposit';
import GameListProvider from '../../../../components/home/gameListProvider';
import FixedActivityMenu from '../../../../components/fixedActivityMenu';
import FixedManMade from '../../../../components/fixedManMade';
import Jackpot from '../../../../components/jackpot';
import DTournament from '../../../../components/tournament/DTournament';
import useLangServerConfig from '../../../../hooks/useLangServerConfig';

export default function DTemplate002() {
  const dispatch = useDispatch();
  const { getServerLangText } = useLangServerConfig();

  const modules = ['HomeAd', 'Jackpot', 'Marquee', 'Tournament'];
  const [modulesSortdata, setModulesSortdata] = useState([]);
  const { defaultLang, identity, needCheckedLobby, tourRedDot, websiteInitData, websiteInitConfig } = useSelector(
    (state) => ({
      defaultLang: state.user.defaultLang,
      identity: state.user.identity,
      needCheckedLobby: state.app.needCheckedLobby,
      tourRedDot: get(state, 'app.activityStatus.activity_tournament'),
      websiteInitData: state.app.websiteInitData,
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const [isTagSearchAutoScroll, setIsTagSearchAutoScroll] = useState(false); //是否需要tagSearch滚动到选中的item
  const [tagsIndex, setTagsIndex] = useState('000'); //当前游戏类型
  const [isProvider, setIsProvider] = useState(false); //是否根据provider筛选游戏
  const [providerSelect, setProviderSelect] = useState(''); //当前选中的游戏厂商
  const [isSearch, setIsSearch] = useState(false); //是否展示搜索结果
  const [searchVal, setSearchVal] = useState(''); //搜索的value值

  const [moduleConfigList, setModuleConfigList] = useState([]); //处理完之后的排序列表
  const [bannerList, setBannerList] = useState([]); //banner数据列表
  const [jackpotConfig, setJackpotConfig] = useState(null); //banner数据列表

  useEffect(() => {
    if (tourRedDot == '1') {
      dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.TourClaim, zIndex: 10000 }));
    }
  }, [tourRedDot]);

  useEffect(() => {
    if (websiteInitData && websiteInitData.home_banner) {
      setBannerList(websiteInitData.home_banner);
    }

    if (websiteInitData?.template) {
      let data = cloneDeep(websiteInitData.template);

      const arr = [];
      for (let i = 0; i < data.length; i++) {
        if (modules.includes(data[i].module)) {
          arr.push(data[i]);
        }
      }
      setModulesSortdata(arr);

      // const found = websiteInitData.template.find((item) => item.module === 'Jackpot');
      // if (found) {
      //   setJackpotConfig(found);
      // }
    }
  }, [websiteInitData]);

  const updateList = () => {};

  useEffect(updateList, [defaultLang, identity]);

  const tagsResIndex = (res) => {
    setProviderSelect('');
    setIsProvider(false);
    setSearchVal('');
    setIsSearch(false);
    if (isLogin() && res === '000') {
      updateList();
    }
  };

  useEffect(() => {
    if (!identity) {
      if (tagsIndex == '001' || tagsIndex == '003') {
        setTagsIndex('000');
        tagsResIndex('000');
      }
    }
  }, [identity]);

  useEffect(() => {
    if (needCheckedLobby.key != 0) {
      tagsResIndex('000');
      setTagsIndex('000');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [needCheckedLobby]);

  useEffect(() => {
    if (isTagSearchAutoScroll) {
      setIsTagSearchAutoScroll(false);
      dispatch(setAutoScrollToTagGameKey(tagsIndex));
    }
  }, [tagsIndex, isTagSearchAutoScroll]);

  useEffect(() => {
    return () => {
      dispatch(setMobileTagIndex('1011'));
      dispatch(setPpopularIndex('1011'));
    };
  }, []);

  useEffect(() => {
    if (moduleConfigList.length > 0) {
      removeLoadingPage();
    }
  }, [moduleConfigList]);

  const handleClickGameCategory = (catetoryId) => {
    setTagsIndex(catetoryId);
    tagsResIndex(catetoryId);
  };

  return (
    <>
      <div className="flex flex-col space-y-[.24rem] px-[.2rem] pt-[.2rem]">
        <div>
          {bannerList.length > 0 &&
            (websiteInitConfig?.website_info?.website_banner_type == '3' ? (
              <div className="flex flex-col space-y-[.24rem]">
                <HomeSwiperBanner bannerType="3" swiperList={bannerList} />
              </div>
            ) : (
              <ContainerWrapper ispb={false} isborderBottom={false}>
                <HomeSwiperBanner
                  bannerType={websiteInitConfig?.website_info?.website_banner_type}
                  swiperList={bannerList}
                />
              </ContainerWrapper>
            ))}

          <div className="flex flex-col space-y-[.24rem] py-[.18rem] rounded-normal w-full  m-[0_auto] relative px-[0px] pb-[0px]">
            {modulesSortdata.findIndex((item) => {
              return item.module == 'Marquee';
            }) > -1 ? (
              <Broadcast className="px-[.08rem]" isAnimation={true} />
            ) : null}
            <UserDeposit />

            {modulesSortdata.map((item, index) => {
              switch (item.module) {
                case 'HomeAd': {
                  return (
                    <div key={index}>
                      <HomeLinkBox />
                    </div>
                  );
                }

                case 'Jackpot': {
                  return (
                    <div className="flex justify-center" key={index}>
                      <Jackpot isDebug={false} handleClickGameCategory={handleClickGameCategory} />
                    </div>
                  );
                }
                case 'Tournament': {
                  return (
                    <div key={index}>
                      <DTournament />
                    </div>
                  );
                }
              }
            })}
            <GameListProvider setTagsIndex={setTagsIndex} />
          </div>
        </div>
      </div>
    </>
  );
}
