import PopBg from '../../../../components/popBg';
import ExpirationTime from '../../../../components/expirationTime';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../../../icon';
import DialogModal from '../../../..';
import { useEffect, useState } from 'react';
import InviteFriend from './inviteFriend';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  DialogModalChildrenKey,
  setDialogModalVisible,
  setNeedUpdateBalance,
  setPromotionPendingUpdateTimestamp,
  setRefetchActivityData,
} from '../../../../../../redux/reducers/appSlice';
import ClaimBtn from '../com/claimBtn';
import RewardRecord from './rewardRecord';
import { getShowMoney } from '../../../../../../utils/helper';
import { toast } from 'react-toastify';
import { ActivityPromotionalCashbackClaim } from '../../../../../../api';
import AnalyticsManager, { FirebaseEvents } from '../../../../../../manager/analyticsManager';
import Rule from '../../../../../rule';
import BgPopHigher from '../../../../../bgPopHigher';
import DownloadManager from '../../../../../../manager/downloadManager';
import BottomDownload from '../../../../downLoadBtn';

const TotalReward = ({ title, money }) => {
  const {} = useSelector((state) => ({}), shallowEqual);

  return (
    <div
      className={cn(
        'w-[6.14rem] h-[1.9rem] bg-[length:100%_100%] bg-no-repeat relative bg-[url(/static/img/cashback/reward-mobile.png)]',
      )}
    >
      <div className="w-full flex justify-center text-center absolute top-[0] text-[.28rem] font-[600] text-[var(--colour-48)]">
        {title}
      </div>
      <div className="w-full flex justify-center text-center absolute top-[.74rem]">{money}</div>
    </div>
  );
};

const InfoItem = ({ name, valueName, value, index }) => {
  return (
    <div
      className={cn('flex items-center justify-between h-[.96rem] rounded-normal px-[.2rem] font-[500] text-[.24rem]', {
        'bg-[var(--colour-34)]': index % 2 == 0,
        'bg-transparent': index % 2 == 1,
      })}
    >
      <div className="text-[var(--colour-44)]">{name}</div>
      <div className="">
        <div className="text-center whitespace-nowrap text-[var(--colour-44)]">{valueName}</div>
        <div className="text-[var(--colour-26)] text-center font-[600]">{value}</div>
      </div>
    </div>
  );
};

const InfoList = ({ list = [] }) => {
  return (
    <div>
      {list.map((item, index) => {
        return <InfoItem key={index} name={item.name} valueName={item.valueName} value={item.value} index={index} />;
      })}
    </div>
  );
};

const InviteBtn = ({ onClick = () => {}, name }) => {
  return (
    <div
      className="cursor-pointer text-[var(--colour-2)] flex items-center justify-center bg-[var(--colour-1)] w-[4.96rem] h-[.68rem] rounded-normal space-x-[.16rem] text-[.28rem] font-[500]"
      onClick={onClick}
    >
      <span>{name}</span>
      <span className="w-[.36rem] h-[.36rem]">
        <Icon name={IconName.Share} />
      </span>
    </div>
  );
};

const MainContent = ({ data, handleClose = () => {}, isShowCloseBtn, updateDataAfterClaim = () => {}, onExpired }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currency_symbol, defaultLang } = useSelector(
    (state) => ({
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      defaultLang: state.user.defaultLang,
    }),
    shallowEqual,
  );

  const [bottomDownloadBoxPromptSwitch, setBottomDownloadBoxPromptSwitch] = useState({
    visible: false,
    isForce: false,
    downloadText: '',
  });
  const [showRule, setShowRule] = useState(false);
  const [isShowInvite, setIsShowInvite] = useState(false);
  const [isShowRecord, setIsShowRecord] = useState(false);
  const [infoList, setInfoList] = useState([]);

  useEffect(() => {
    const nameList = {
      1: {
        name: t('referral.new_player_recharge_rewards'),
        valueName: t('referral.amount_available'),
      },
      2: {
        name: t('referral.Valid_betting_rewards'),
        valueName: t('referral.amount_available'),
      },
      3: {
        name: t('referral.directly_affiliated'),
        valueName: t('referral.amount_available'),
      },
    };

    if (data?.task_config && Array.isArray(data?.task_config)) {
      const list = data.task_config.map((item) => {
        return {
          name: nameList[item?.task_type]?.name,
          valueName: nameList[item?.task_type]?.valueName,
          value: getShowMoney(item?.claim_reward || 0),
        };
      });
      setInfoList(list);
    }
  }, [data, defaultLang]);

  const totalReward = (() => {
    const list = data?.task_config || [];
    const sumAmount = list.reduce((acc, curr) => {
      return acc + Number(curr.claim_reward || 0);
    }, 0);

    return sumAmount;
  })();

  const onClaim = () => {
    if (Number(totalReward) > 0) {
      ActivityPromotionalCashbackClaim({
        activity_id: data?.id,
      }).then((res) => {
        if (res.code == 0) {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.WheelBonus,
              content: res?.result?.amount || 0,
              zIndex: 999999,
              overlayClassName: 'wheelBonusMoney',
            }),
          );

          updateDataAfterClaim(res?.result?.activity_info || null);
          dispatch(setRefetchActivityData());
          dispatch(setNeedUpdateBalance({ need: true }));
          dispatch(setPromotionPendingUpdateTimestamp());
        } else {
          if (res.code == 1008127) {
            // dispatch(
            //   setBottomDownloadBoxPromptSwitch({
            //     visible: true,
            //     isAni: true,
            //     isForce: true,
            //     downloadText: t('guideWheel.downloadtips'),
            //   }),
            // );

            if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
              setBottomDownloadBoxPromptSwitch({
                visible: true,
                isForce: true,
                downloadText: t('guideWheel.downloadtips'),
              });
            }
          } else {
            toast.error(t(`${res.code}`), {
              containerId: 'global',
            });
          }
        }
      });
    } else {
      setShowRule(true);
    }
  };

  const onShowRecord = () => {
    setIsShowRecord(true);
  };

  const CloseBottomDownLoad = () => {
    setBottomDownloadBoxPromptSwitch({
      visible: false,
      isForce: false,
      downloadText: '',
    });
  };

  return (
    <>
      <BgPopHigher zIndex={9999} isOpen={showRule} handleClose={() => setShowRule(false)} className={'h-[11.5rem]'}>
        <Rule
          data={data}
          handleShowInviteFriends={() => {
            setShowRule(false);
            setIsShowInvite(true);
          }}
        />
      </BgPopHigher>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: 'auto',
          },
        }}
        handleClose={CloseBottomDownLoad}
        isOpen={bottomDownloadBoxPromptSwitch.visible}
      >
        <BottomDownload
          handleClose={CloseBottomDownLoad}
          bottomDownloadBoxPromptSwitch={bottomDownloadBoxPromptSwitch}
        />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: '51',
          },
        }}
        isOpen={isShowInvite}
        handleClose={() => setIsShowInvite(false)}
      >
        <InviteFriend handleClose={() => setIsShowInvite(false)} isShowCloseBtn={false} />
      </DialogModal>

      <DialogModal
        shouldCloseOnOverlayClick={true}
        noentered={true}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            display: 'inline-block',
            textAlign: 'left',
            top: '0',
            maxWidth: '100%',
            cursor: 'default',
            outline: '0',
            verticalAlign: 'middle',
            zIndex: '51',
          },
        }}
        isOpen={isShowRecord}
        handleClose={() => setIsShowRecord(false)}
      >
        <RewardRecord handleClose={() => setIsShowRecord(false)} activity_id={data?.id} isShowCloseBtn={false} />
      </DialogModal>

      <PopBg handleClose={handleClose} allowScroll={true} isShowCloseBtn={isShowCloseBtn}>
        <div className="border-t-[.6rem] border-t-[transparent] flex flex-col px-[.16rem] w-full">
          <ExpirationTime
            startTime={data?.activity_start_time ? data.activity_start_time : Math.floor(Date.now() / 1000)}
            endTime={data?.activity_end_time ? data.activity_end_time : Math.floor(Date.now() / 1000)}
            handleClose={onExpired}
          />

          <div className="pt-[.28rem] flex justify-center">
            <InviteBtn
              name={t('referral.invite_firend')}
              onClick={() => {
                setIsShowInvite(true);
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBack_Invite);
              }}
            />
          </div>

          <div className="pt-[.28rem]">
            <InfoList list={infoList} />
          </div>

          <div className="pt-[.28rem] flex justify-center">
            <TotalReward
              title={t('referral.total_reward')}
              money={
                <span className="font-[800] text-[.44rem] text-[var(--colour-43)]">
                  {currency_symbol}
                  <span className="">{getShowMoney(totalReward)}</span>
                </span>
              }
            />
          </div>

          <div className="flex justify-center">
            <ClaimBtn
              btnName={Number(totalReward) > 0 ? t('referral.claim') : t('referral.how_to_get')}
              onClick={() => {
                onClaim();
                AnalyticsManager.FirebaseLogEvent(FirebaseEvents.CashBack_Howtoget);
              }}
            />
          </div>

          <div className="flex justify-end p-[.24rem_0_.2rem_0]">
            <div
              onClick={onShowRecord}
              className="text-[var(--colour-24)] underline cursor-pointer font-[600] text-[.24rem]"
            >
              {t('referral.reward_record')}
            </div>
          </div>

          <div className="h-[1px] bg-[var(--colour-6)] shrink-0"></div>

          <div className="pt-[.28rem]">
            <div
              dangerouslySetInnerHTML={{ __html: data?.rule_explain?.activity_rule_explain }}
              className="text-[var(--colour-44)] wrap-break-word"
            ></div>
          </div>
        </div>
      </PopBg>
    </>
  );
};

export default MainContent;
