const LicenseList = ({ list = [], title = '', className = '' }) => {
  return (
    <div>
      <div>{title}</div>
      <div className={`mt-[.16rem] flex space-x-[.24rem] ${className}`}>
        {list.map((item, index) => {
          return <div key={index}>{item}</div>;
        })}
      </div>
    </div>
  );
};

export default LicenseList;
