import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocalePath } from '../../utils/localePath';
import { useDispatch } from 'react-redux';
import { DialogModalChildrenKey, setDialogModalVisible } from '../../redux/reducers/appSlice';
import { useGlobalNavigate } from '../../contexts/navigateContext';

function TocTip({ className }) {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();

  return (
    <div
      className={`text-[.24rem] text-[var(--colour-40)] font-[500] text-left active:scale-[.95] transition-all select-none ${className}`}
    >
      <Trans
        t={t}
        i18nKey="login-signup.accept"
        values={{
          val: t('login-signup.accept1'),
          val1: t('login-signup.accept2'),
        }}
        components={[
          <span
            className="text-[var(--colour-1)] cursor-pointer"
            key={0}
            onClick={() => {
              navigate(toLocalePath('/termsofservice'));
              dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
            }}
          ></span>,
          <span
            className="text-[var(--colour-1)] cursor-pointer"
            key={1}
            onClick={() => {
              navigate(toLocalePath('/termsofservice2'));
              dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.Default }));
            }}
          ></span>,
        ]}
      />
    </div>
  );
}

export default TocTip;
