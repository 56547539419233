import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';

import Close from '../close';
import Input from '../input';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';

import { toast } from 'react-toastify';

import Icon, { IconName } from '../icon';
import { ResetPassword } from '../../api';
import { setNeedLogout } from '../../redux/reducers/appSlice';
import { useDispatch } from 'react-redux';
import { isControllableSite } from '../../utils/helper';
import cn from 'classnames';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>
        <div className="active:scale-[.95] transition-all select-none w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full bg-[var(--colour-6)] h-[1px] mt-[.28rem]"></div>
    </>
  );
};

const editPassword = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [requesting, setRequesting] = useState(false);

  const [formData, setFormData] = useStateIfMounted({
    password: '',
    new_password: '',
    re_password: '',
  });

  const handleSubmit = async () => {
    if (!formData.password) {
      toast.error(t('account.enter_password'), {
        containerId: 'global',
      });
      return;
    }

    if (!formData.new_password) {
      toast.error(t('account.enter_new_password'), {
        containerId: 'global',
      });
      return;
    }

    if (!formData.re_password) {
      toast.error(t('account.confirm_password'), {
        containerId: 'global',
      });
      return;
    }

    if (formData.re_password != formData.new_password) {
      toast.error(t('account.not_match_password'), {
        containerId: 'global',
      });
      return;
    }

    setRequesting(true);
    ResetPassword({ ...formData })
      .then((res) => {
        if (res.code == 0) {
          toast.success(t('home.toast004'), {
            containerId: 'global',
          });
          dispatch(setNeedLogout({ showLoginBox: true }));
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  return (
    <div className="flex flex-col justify-between text-center relative pb-[.4rem] my-[.3rem] w-[7.4rem] h-[auto] rounded-normal bg-[var(--colour-35)]">
      <div className="">
        <div className="px-[.32rem]">
          <FormHeader title={t('account.edit_password')} handleClose={handleClose} />
        </div>
        {/* 列表 */}
        <div className="px-[.32rem]">
          <div className="mt-[.24rem]">
            <Input
              leftInner={
                <div className="flex items-center">
                  <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] text-[var(--colour-37)]">
                    {isControllableSite() ? (
                      <div className="w-full h-full">
                        <Icon name={IconName.LoginPwd2} />
                      </div>
                    ) : (
                      <Icon name={IconName.LoginPwd} />
                    )}
                  </span>
                </div>
              }
              value={formData.password}
              onChange={(val) => {
                setFormData({ ...formData, password: val.target.value });
              }}
              type="password"
              placeholder={t('account.current_password')}
              innerClassName="!h-[.96rem] !border-transparent"
              topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
            />
          </div>

          <div className="mt-[.24rem]">
            <Input
              leftInner={
                <div className="flex items-center">
                  <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] text-[var(--colour-37)]">
                    {isControllableSite() ? (
                      <div className="w-full h-full">
                        <Icon name={IconName.LoginPwd2} />
                      </div>
                    ) : (
                      <Icon name={IconName.LoginPwd} />
                    )}
                  </span>
                </div>
              }
              value={formData.new_password}
              onChange={(val) => {
                setFormData({ ...formData, new_password: val.target.value });
              }}
              type="password"
              placeholder={t('account.new_paaswrd')}
              innerClassName="!h-[.96rem] !border-transparent"
              topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
            />
          </div>

          <div className="mt-[.24rem]">
            <Input
              leftInner={
                <div className="flex items-center">
                  <span className="block mr-[.2rem] w-[.4rem] h-[.4rem] text-[var(--colour-37)]">
                    {isControllableSite() ? (
                      <div className="w-full h-full">
                        <Icon name={IconName.LoginPwd2} />
                      </div>
                    ) : (
                      <Icon name={IconName.LoginPwd} />
                    )}
                  </span>
                </div>
              }
              value={formData.re_password}
              onChange={(val) => {
                setFormData({ ...formData, re_password: val.target.value });
              }}
              type="password"
              placeholder={t('account.confirm_password_pls')}
              innerClassName="!h-[.96rem] !border-transparent"
              topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
            />
          </div>

          <LimitClickWrapper
            className="active:scale-[.95] transition-all select-none flex flex-row mt-[.4rem] px-[.34rem] h-[.92rem] items-center justify-center cursor-auto rounded-normal bg-[var(--colour-1)] text-[var(--colour-2)]"
            onClick={() => {
              if (!requesting) {
                handleSubmit();
              }
            }}
          >
            <ElementWithLoading
              isLoading={requesting}
              normalEl={
                <>
                  <div className="font-[700] text-[.32rem]">{t('account.change_account')}</div>
                </>
              }
            />
          </LimitClickWrapper>
        </div>
      </div>
    </div>
  );
};
export default editPassword;
