import { isAndroidNative, isFacebookApp, isMobileSafari } from './deviceUtils';
import isMobile from 'ismobilejs';
import { isInIframe } from './helper';
import DownloadManager from '../manager/downloadManager';
import { windowOpen } from '../manager/iframeManager';
/**
 * 打开url通用方法
 * @param  url 要打开的url完整路径
 * @param  target 默认打开新页面
 * @returns
 */
export function openUrl(url, target = '_blank') {
  if (isFacebookApp() && isMobile().android.device) {
    // 安卓facebook
    window.location.href = 'intent:' + url + '#Intent;end';
  } else if (isAndroidNative()) {
    // 安卓app
    window.native.openURL(url);
  } else {
    // 通用
    if (isMobileSafari()) {
      setTimeout(() => {
        window.open(url, target);
      }, 0);
    } else if (window.Android && window.Android.openAndroid) {
      window.Android.openAndroid(url);
    } else {
      if (DownloadManager.checkInIframe()) {
        windowOpen(url, target);
      } else {
        return window.open(url, target);
      }
    }
  }
}
