import DragBtnRight from '../../dragBtnRight';
import { getShowMoney, getRedPacketObj, isLogin, getTemplateId } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  setRefetchActivityData,
  DialogModalChildrenKey,
  setDialogModalVisible,
} from '../../../redux/reducers/appSlice';
import AnalyticsManager, { FirebaseEvents } from '../../../manager/analyticsManager';
import { cloneDeep } from 'lodash';
import ClaimBtn from '../components/claimbtn';

export default function Index({ isdrag = false, index = null, imgpath = '', EntranceDisplayLocation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cashRainObj, setCashRainObj] = useState(null);
  const { websiteInfo, activityData } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
      activityData: state.app.activityData,
    }),
    shallowEqual,
  );

  const handleClick = () => {
    AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_CashRain_Floating);
    if (!isLogin()) {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
      return;
    }
    dispatch(
      setDialogModalVisible({
        show: DialogModalChildrenKey.RedPacket,
        overlayClassName: `${getTemplateId() != 'd-template003' ? '!bg-[rgba(0,0,0,0)]' : ''}`,
      }),
    );
  };

  useEffect(() => {
    if (activityData && activityData.activity_red_packet) {
      const list = activityData.activity_red_packet.list;
      setCashRainObj(getRedPacketObj(list));
    }
  }, [activityData]);

  let interval = -1;
  useEffect(() => {
    if (interval > 0) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      if (cashRainObj != null) {
        if (cashRainObj.red_packet_start_time * 1000 - new Date().getTime() < 0 && !cashRainObj.started) {
          const newCashRainObj = cloneDeep(cashRainObj);
          newCashRainObj.started = true;
          setCashRainObj(newCashRainObj);
        }
        if (cashRainObj.started == true && cashRainObj.red_packet_End_time * 1000 - new Date().getTime() <= 0) {
          dispatch(setRefetchActivityData());
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cashRainObj]);

  return cashRainObj != null ? (
    <DragBtnRight
      className={`activty-absolute-bottom-${index + 1} } ${index != null && EntranceDisplayLocation == '0' ? 'activity-item' : ''} ${EntranceDisplayLocation == '0' ? 'right-[.08rem]' : 'left-[.08rem]'}`}
      className1="w-[1.5rem] h-[1.5rem]"
      onClick={handleClick}
      isLimitedInParentHeight={false}
      isdrag={isdrag}
    >
      <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
        <div className="scale-[.5]">
          <div className="relative w-[3rem] h-[3rem]">
            <img src={imgpath} alt="" className="not-drag absolute left-0 top-0 w-full" />

            {cashRainObj.started ? (
              <>
                <ClaimBtn
                  txtColor="grabNow"
                  className="absolute abs-x-center bottom-0 z-[1] rounded-[.08rem] bg-cashRain_btn "
                >
                  {t('cashRain.grabNow')}
                </ClaimBtn>

                <div className="flex flex-col items-center absolute abs-center">
                  <div
                    className="text-[.5rem] font-[700] startAt"
                    data-text={`${websiteInfo.currency_symbol || ''}${getShowMoney(cashRainObj.activity_claim_ceiling_amount)}`}
                  >
                    {`${websiteInfo.currency_symbol || ''}`}
                    {getShowMoney(cashRainObj.activity_claim_ceiling_amount)}
                  </div>
                  <div
                    className="text-[.24rem] font-[700] startAt mt-[-0.2rem]"
                    data-text={t('cashRain.max')}
                    style={{ WebkitTextStroke: '3px rgba(31, 19, 47, 0.8)' }}
                  >{`${t('cashRain.max')}`}</div>
                </div>
              </>
            ) : (
              <>
                <div className="absolute abs-center flex flex-col items-center">
                  <div
                    className="text-[.5rem] font-[700] startAt"
                    data-text={`${websiteInfo.currency_symbol || ''}${getShowMoney(cashRainObj.activity_claim_ceiling_amount)}`}
                  >
                    {`${websiteInfo.currency_symbol || ''}`}
                    {getShowMoney(cashRainObj.activity_claim_ceiling_amount)}
                  </div>
                  <div
                    className="text-[.24rem] font-[700] startAt mt-[-0.2rem]"
                    data-text={t('cashRain.max')}
                    style={{ WebkitTextStroke: '3px rgba(31, 19, 47, 0.8)' }}
                  >{`${t('cashRain.max')}`}</div>
                </div>
                <div className="w-[2.56rem] h-[.48rem] rounded-[.08rem] bg-[rgba(29,41,39,0.80)] p-[.08rem] text-[.24rem] font-[700] flex items-center justify-between absolute z-[10] abs-x-center bottom-0">
                  <div className="text-[#fff]">{t('cashRain.startIn')}</div>
                  <div className="text-[#93EB74]">{cashRainObj.showTime}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </DragBtnRight>
  ) : null;
}
