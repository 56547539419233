import { useEffect, useState, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetPromotionVipList, GetReVipMyLevelClaim, PromotionVipClaimAll } from '../../../../../api';
import { toast } from 'react-toastify';
import {
  setDialogModalVisible,
  DialogModalChildrenKey,
  setIsShowPromotionBarGiftMoneyCoin,
  setNeedUpdateBalance,
} from '../../../../../redux/reducers/appSlice';
import { getShowMoney } from '../../../../../utils/helper';
import Loading3 from '../../../../../components/loading3';
import GSAPUtils from '../../../../../utils/gsapUtils';
import { getCenterPosition } from '../../../../../hooks/useMinimizeAnimation';
import UserVipInfo from './components/userVipInfo';
import { LevelAmount, LevelBonus, ListTitle, MonthlyBonus, UserProgress, WeeklyBonus } from './components/vipLevelList';
import Rules from './components/rules';
import VipLevelPicture from '../../../../../components/vipLevelPicture';
import { TypeSwitcher } from './components/typeSwitcher';
import ButtonClaimLevel from './components/buttonClaimLevel';
import ButtonClaimWeekly from './components/buttonClaimWeekly';
import ButtonClaimMonthly from './components/buttonClaimMonthly';
import usePromotionClaimedCount from '../../../../../hooks/usePromotionClaimedCount';
import { toNoLocalePath } from '../../../../../utils/localePath';
import { useLocation } from 'react-router-dom';
import DownloadManager from '../../../../../manager/downloadManager';
import Sticky from 'react-sticky-el';

const DTemplate003 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { weekly_reward_flag, monthly_reward_flag, currency_symbol, gift_money_menu_show_switch } = useSelector(
    (state) => ({
      weekly_reward_flag: state?.app?.websiteInitConfig?.system_config?.weekly_reward_flag,
      monthly_reward_flag: state?.app?.websiteInitConfig?.system_config?.monthly_reward_flag,
      currency_symbol: state?.app?.websiteInitConfig?.website_info?.currency_symbol || '',
      gift_money_menu_show_switch: state?.app?.activityStatus?.gift_money_menu_show_switch,
    }),
    shallowEqual,
  );

  const { InitPromotionClaimedCount } = usePromotionClaimedCount();

  const [vipData, setVipData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleClaimLevel = (bonusType) => {
    const requestParams = {
      // bonus_type: '123',
      bonus_type: bonusType,
    };

    console.log('handleClaimLevel bonusType:', bonusType, requestParams);

    PromotionVipClaimAll(requestParams).then((res) => {
      // res.code = 1008127;

      // if (true) {
      if (res.code == 0) {
        const data = res.data;

        // const balanceAmount = 2345678;
        const balanceAmount = data.balance;

        // const giftMoneyAmount = 8765432;
        const giftMoneyAmount = data.gift_money;

        const commonCallback = () => {
          updateData(false);
          dispatch(setNeedUpdateBalance({ need: true }));
          InitPromotionClaimedCount();
        };

        const balanceCallback = (amount) => {
          toast.success(
            t('promotion.claim_ok_tip', {
              val: currency_symbol,
              val1: getShowMoney(amount),
            }),
            {
              containerId: 'global',
            },
          );
        };

        const giftMoneyCallback = () => {
          const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
          const element =
            bonusType == 'rank_reward'
              ? document.querySelector('#vip_button_claim_level')
              : bonusType == 'all'
                ? document.querySelector('#vip_claim_all_button_element_id')
                : null;

          if (giftMoneyLabel && element) {
            dispatch(setIsShowPromotionBarGiftMoneyCoin(false));
            GSAPUtils.flyCoin(
              getCenterPosition(element, true),
              getCenterPosition(giftMoneyLabel, true),
              GSAPUtils.COIN_COUNT,
              () => {
                if (toNoLocalePath(pathname) != '/promotion/giftmoney') {
                  dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                }
              },
            );
          }
        };

        commonCallback();

        if (balanceAmount > 0) {
          balanceCallback(balanceAmount);
        }

        if (giftMoneyAmount > 0) {
          if (gift_money_menu_show_switch == '1') {
            giftMoneyCallback();
          } else {
            balanceCallback(giftMoneyAmount);
          }
        }
      } else if (res.code == 1008127) {
        if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.BottomDownloadBoxPrompt,
              downloaddata: {
                visible: true,
                isForce: true,
                downloadText: t('guideWheel.downloadtips'),
              },
            }),
          );
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const unknowLevel = -1;
  const unkonwMoneyAmount = 0;

  const getUserLevel = (vipData) => {
    return vipData ? vipData?.vipLevel : unknowLevel;
  };

  const getUserDepositAmount = (vipData) => {
    return vipData ? vipData.totalDeposit : 0;
  };

  const getUserWagerAmount = (vipData) => {
    return vipData ? vipData.totalWager : 0;
  };

  const getNextUserLevel = (vipData) => {
    const unknowObj = {
      nextLevel: unknowLevel,
      userNeedDeposit: unkonwMoneyAmount,
      userNeedWager: unkonwMoneyAmount,
    };

    if (vipData) {
      const userLevel = vipData.vipLevel;
      const list = vipData.list;
      const index = list.findIndex((item) => {
        return String(item.vip_level_config.vip) == String(userLevel);
      });

      if (index == -1) {
        return unknowObj;
      } else {
        const currentItem = list[index];
        const nextItem = list[index + 1];

        return {
          nextLevel: nextItem ? nextItem.vip_level_config.vip : unknowLevel,
          userNeedDeposit: Number(currentItem.vip_level_config.total_deposit) - Number(getUserDepositAmount(vipData)),
          userNeedWager: Number(currentItem.vip_level_config.total_wager) - Number(getUserWagerAmount(vipData)),
        };
      }
    } else {
      return unknowObj;
    }
  };

  const getMaxVipLevel = (vipData) => {
    if (vipData) {
      const list = vipData.list;
      return list[list.length - 1]?.vip_level_config?.vip;
    } else {
      return unknowLevel;
    }
  };

  const getWeeklyList = (vipData) => {
    if (vipData) {
      const list = vipData.list.map((item) => {
        const config = item.vip_level_config;

        return [
          // 等级
          <VipLevelPicture vipLevel={config.vip} key={0} />,

          // 奖金
          <span key={1} className="text-[var(--colour-60)] text-[.2rem] font-[600]">
            {getShowMoney(config.weekly_reward.amount)}
          </span>,
        ];
      });
      return list;
    } else {
      return [];
    }
  };

  const getMonthlyList = (vipData) => {
    if (vipData) {
      const list = vipData.list.map((item) => {
        const config = item.vip_level_config;

        return [
          // 等级
          <VipLevelPicture vipLevel={config.vip} key={0} />,

          // 奖金
          <span key={1} className="text-[var(--colour-60)] text-[.2rem] font-[600]">
            {getShowMoney(config.monthly_reward.amount)}
          </span>,
        ];
      });
      return list;
    } else {
      return [];
    }
  };

  const getUserDistance = (vipData) => {
    const userLevel = getUserLevel(vipData);
    const userTotalDeposit = getUserDepositAmount(vipData);
    const userTotalWager = getUserWagerAmount(vipData);

    const config = (vipData?.list || []).find((item) => {
      return String(item.vip_level_config.vip) == String(userLevel);
    });

    console.log('userLevel', userLevel, userTotalDeposit, userTotalWager, config);

    if (config) {
      const depositDistance = config.vip_level_config.total_deposit - userTotalDeposit;
      const wagerDistance = config.vip_level_config.total_wager - userTotalWager;

      return {
        depositDistance,
        wagerDistance,
      };
    } else {
      return {
        depositDistance: NaN,
        wagerDistance: NaN,
      };
    }
  };

  const getLevelupList = (vipData) => {
    if (vipData) {
      const dataList = vipData.list;

      const list = dataList.map((item, index) => {
        const config = item.vip_level_config;
        const prevConfig = dataList[index - 1] ? dataList[index - 1].vip_level_config : null;
        const prevPrevConfig = dataList[index - 2] ? dataList[index - 2].vip_level_config : null;

        const getLevelAmountDeposit = () => {
          if (index == 0) {
            return 0;
          } else if (index == 1) {
            return prevConfig.total_deposit;
          } else {
            return prevConfig.total_deposit - prevPrevConfig.total_deposit;
          }
        };

        const getLevelAmountWager = () => {
          if (index == 0) {
            return 0;
          } else if (index == 1) {
            return prevConfig.total_wager;
          } else {
            return prevConfig.total_wager - prevPrevConfig.total_wager;
          }
        };

        const { depositDistance, wagerDistance } = getUserDistance(vipData);

        return [
          // 等级
          <VipLevelPicture vipLevel={config.vip} key={0} />,

          // 充值
          getNextUserLevel(vipData).nextLevel == config.vip ? (
            <UserProgress distanceAmount={depositDistance} levelAmount={getLevelAmountDeposit()} key={1} />
          ) : (
            <LevelAmount levelAmount={getLevelAmountDeposit()} key={1} />
          ),

          // 打码
          getNextUserLevel(vipData).nextLevel == config.vip ? (
            <UserProgress distanceAmount={wagerDistance} levelAmount={getLevelAmountWager()} key={1} />
          ) : (
            <LevelAmount levelAmount={getLevelAmountWager()} key={1} />
          ),

          // 奖金
          <span key={3} className="text-[var(--colour-60)] text-[.2rem] font-[600]">
            {getShowMoney(config.rank_bonus.amount)}
          </span>,
        ];
      });
      return list;
    } else {
      return [];
    }
  };

  const getIsShowRedDot = (vipData) => {
    if (vipData) {
      const list = vipData?.list || [];

      const isShowBonusRedDot = list.some((item) => {
        const _isShow = item.vip_bonus_info.some((item) => {
          return item.type == 'rank_reward' && item.status == 1;
        });
        return _isShow;
      });

      const list2 = vipData.vip_bonus || [];

      const isShowWeeklyRedDot = list2.some((item) => {
        const _isShow = item.type == 'weekly_reward' && item.status == 1 && item.amount > 0;
        return _isShow;
      });

      const isShowMonthlyRedDot = list2.some((item) => {
        const _isShow = item.type == 'monthly_reward' && item.status == 1 && item.amount > 0;
        return _isShow;
      });

      return {
        isShowBonusRedDot,
        isShowWeeklyRedDot,
        isShowMonthlyRedDot,
      };
    } else {
      return {
        isShowBonusRedDot: false,
        isShowWeeklyRedDot: false,
        isShowMonthlyRedDot: false,
      };
    }
  };

  const getButtonDataLevel = (vipData) => {
    if (vipData) {
      const _list = vipData.list.flatMap((item) => {
        return item.vip_bonus_info.filter((item2) => item2.type == 'rank_reward' && item2.status == 1);
      });

      console.log('getButtonData1', _list);

      return _list[0] || null;
    } else {
      return null;
    }
  };

  const getButtonDataWeekly = (vipData) => {
    if (vipData) {
      const found = vipData.vip_bonus.find((item) => item.type == 'weekly_reward');

      console.log('getButtonData2', found);
      return found ? found : null;
    } else {
      return null;
    }
  };

  const getButtonDataMontyly = (vipData) => {
    if (vipData) {
      const found = vipData.vip_bonus.find((item) => item.type == 'monthly_reward');

      console.log('getButtonData3', found);
      return found ? found : null;
    } else {
      return null;
    }
  };

  const handleClaimWeeklyMonthly = (bonusType) => {
    let paramsData = null;

    if (bonusType == 'weekly_reward') {
      paramsData = getButtonDataWeekly(vipData);
    } else if (bonusType == 'monthly_reward') {
      paramsData = getButtonDataMontyly(vipData);
    }

    const requestParams = {
      // id: paramsData?.id + 11,
      id: paramsData?.id,

      bonus_type: paramsData?.type,
    };

    console.log(bonusType, 'paramsData', paramsData, requestParams);

    GetReVipMyLevelClaim(requestParams).then((res) => {
      // res.code = 1008127;

      // if (true) {
      if (res.code == 0) {
        const data = res.data;

        // const currency = 'balance';
        // const currency = 'gift_money';
        const currency = data.currency;

        // const amount = 612345678;
        const amount = data.amount;

        const commonCallback = () => {
          updateData(false);
          dispatch(setNeedUpdateBalance({ need: true }));
          InitPromotionClaimedCount();
        };

        const balanceCallback = () => {
          toast.success(
            t('promotion.claim_ok_tip', {
              val: currency_symbol,
              val1: getShowMoney(amount),
            }),
            {
              containerId: 'global',
            },
          );
        };

        const giftMoneyCallback = () => {
          const giftMoneyLabel = document.querySelector('#promotion_giftMoney');
          const element =
            bonusType == 'weekly_reward'
              ? document.querySelector('#vip_button_claim_weekly')
              : bonusType == 'monthly_reward'
                ? document.querySelector('#vip_button_claim_monthly')
                : null;

          if (giftMoneyLabel && element) {
            dispatch(setIsShowPromotionBarGiftMoneyCoin(false));
            GSAPUtils.flyCoin(
              getCenterPosition(element, true),
              getCenterPosition(giftMoneyLabel, true),
              GSAPUtils.COIN_COUNT,
              () => {
                if (toNoLocalePath(pathname) != '/promotion/giftmoney') {
                  dispatch(setIsShowPromotionBarGiftMoneyCoin(true));
                }
              },
            );
          }
        };

        commonCallback();
        if (currency == 'balance') {
          balanceCallback();
        } else if (currency == 'gift_money') {
          if (gift_money_menu_show_switch == '1') {
            giftMoneyCallback();
          } else {
            balanceCallback();
          }
        }
      } else if (res.code == 1008127) {
        if (DownloadManager.isSatisfyBottomDownloadBoxPrompt()) {
          dispatch(
            setDialogModalVisible({
              show: DialogModalChildrenKey.BottomDownloadBoxPrompt,
              downloaddata: {
                visible: true,
                isForce: true,
                downloadText: t('guideWheel.downloadtips'),
              },
            }),
          );
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
        }
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const getPageDataList = (vipData) => {
    const _list = [
      {
        text: t('vip.bouns'),
        isRedDot: getIsShowRedDot(vipData).isShowBonusRedDot,
        bonusListHeader: <ListTitle list={[t('vip.level'), t('vip.deposit'), t('vip.wager'), t('vip.bouns')]} />,
        bonusList: <LevelBonus dataList={getLevelupList(vipData)} />,
        button: (
          <ButtonClaimLevel
            data={getButtonDataLevel(vipData)}
            handleClaimLevel={() => handleClaimLevel('rank_reward')}
            elementId={'vip_button_claim_level'}
          />
        ),
      },
      ...(weekly_reward_flag == '1'
        ? [
            {
              text: t('vip.weellyBouns'),
              isRedDot: getIsShowRedDot(vipData).isShowWeeklyRedDot,
              bonusListHeader: <ListTitle list={[t('vip.level'), t('vip.bouns')]} />,
              bonusList: <WeeklyBonus dataList={getWeeklyList(vipData)} />,
              button: (
                <ButtonClaimWeekly
                  data={getButtonDataWeekly(vipData)}
                  handleClaimWeekly={() => handleClaimWeeklyMonthly('weekly_reward')}
                  elementId={'vip_button_claim_weekly'}
                />
              ),
            },
          ]
        : []),
      ...(monthly_reward_flag == '1'
        ? [
            {
              text: t('vip.monthlyBouns'),
              isRedDot: getIsShowRedDot(vipData).isShowMonthlyRedDot,
              bonusListHeader: <ListTitle list={[t('vip.level'), t('vip.bouns')]} />,
              bonusList: <MonthlyBonus dataList={getMonthlyList(vipData)} />,
              button: (
                <ButtonClaimMonthly
                  data={getButtonDataMontyly(vipData)}
                  handleClaimMonthly={() => handleClaimWeeklyMonthly('monthly_reward')}
                  elementId={'vip_button_claim_monthly'}
                />
              ),
            },
          ]
        : []),
    ];

    return _list;
  };

  const {
    userLevel,
    userDepositAmount,
    userWagerAmount,
    userNextLevel,
    userNeedDeposit,
    userNeedWager,
    maxVipLevel,
    isShowBonusRedDot,
    isShowWeeklyRedDot,
    isShowMonthlyRedDot,
    pageDataList,
  } = useMemo(() => {
    return {
      userLevel: getUserLevel(vipData),
      userDepositAmount: getUserDepositAmount(vipData),
      userWagerAmount: getUserWagerAmount(vipData),
      userNextLevel: getNextUserLevel(vipData).nextLevel,
      userNeedDeposit: getNextUserLevel(vipData).userNeedDeposit,
      userNeedWager: getNextUserLevel(vipData).userNeedWager,
      maxVipLevel: getMaxVipLevel(vipData),
      isShowBonusRedDot: getIsShowRedDot(vipData).isShowBonusRedDot,
      isShowWeeklyRedDot: getIsShowRedDot(vipData).isShowWeeklyRedDot,
      isShowMonthlyRedDot: getIsShowRedDot(vipData).isShowMonthlyRedDot,
      pageDataList: getPageDataList(vipData),
    };
  }, [vipData]);

  console.log('vipData', vipData);
  console.log('userLevel', userLevel);
  console.log('userDepositAmount', userDepositAmount);
  console.log('userWagerAmount', userWagerAmount);
  console.log('userNeedDeposit', userNeedDeposit);
  console.log('userNeedWager', userNeedWager);
  console.log('userNextLevel', userNextLevel);
  console.log('maxVipLevel', maxVipLevel);
  console.log('pageDataList', pageDataList);

  const updateData = (isDisplayLoading = false) => {
    setIsLoading(isDisplayLoading ? true : false);

    GetPromotionVipList()
      .then((res) => {
        if (res.code == 0) {
          // res.data.vipLevel = '15';
          setVipData(res.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateData(true);
  }, []);

  const handleChangeType = (index) => {
    setCurrentIndex(index);
  };

  const handleClaimAllClick = () => {
    handleClaimLevel('all');
  };

  return (
    <>
      <div className="flex flex-col h-full bg-[var(--colour-68)]">
        {isLoading ? (
          <>
            <Loading3 className1="w-full h-full" />
          </>
        ) : (
          <>
            <div className="flex-1 p-[0_.3rem_0_.3rem] h-[0] overflow-y-auto scrollbar-none" id="vip-sticky-box">
              <div className="mt-[.26rem]">
                <UserVipInfo
                  currentVipNum={userLevel}
                  nextVipNum={userNextLevel}
                  depositAmount={userNeedDeposit >= 0 ? userNeedDeposit : 0}
                  wagerNum={userNeedWager >= 0 ? userNeedWager : 0}
                  isUserLevelMax={String(userLevel) == String(maxVipLevel)}
                  isCanClaimAll={isShowBonusRedDot || isShowWeeklyRedDot || isShowMonthlyRedDot}
                  handleClaimAllClick={handleClaimAllClick}
                  claimAllButtonElementId={'vip_claim_all_button_element_id'}
                />
              </div>

              <div className="text-[.28rem] font-[600] text-[var(--colour-46)] leading-[1.2] pt-[.34rem]">
                {t('vip.level_list')}
              </div>

              <Sticky scrollElement={'#vip-sticky-box'} stickyStyle={{ zIndex: 1 }}>
                <TypeSwitcher list={pageDataList} currentIndex={currentIndex} handleClick={handleChangeType} />
                {pageDataList[currentIndex].bonusListHeader}
              </Sticky>

              {pageDataList[currentIndex].bonusList}

              <div className="mt-[.4rem] h-[1px] bg-[var(--colour-93)]"></div>

              <div className="mt-[.32rem]">
                <Rules />
              </div>
            </div>

            <div className="shrink-0 h-[1.28rem] p-[.28rem_0_0_0] flex justify-center">
              {pageDataList[currentIndex].button}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DTemplate003;
