import { useCallback } from 'react';
import { isFunction } from '../utils';
import useRefState from './use-ref-state.js';
import useUnmountedRef from './use-unmounted-ref.js';

const useSetState = (initialState = {}) => {
  const unmountedRef = useUnmountedRef();
  const [state, setState, ref] = useRefState(initialState);

  const setMergeState = useCallback((patch) => {
    if (unmountedRef.current) return;
    setState((prevState) => ({
      ...prevState,
      ...(isFunction(patch) ? patch(prevState) : patch),
    }));
  }, []);

  return [state, setMergeState, ref];
};

export default useSetState;
