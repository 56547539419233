import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import TippyPopBox, { Placement } from '../../../../tippyPopBox';
import PopChoose, { PopChooseItem } from '../../../../popChoose';
import Icon, { IconName } from '../../../../icon';
import WidthdrawDetails from '../components/withdrawDetailsPop';
import { PaymentWithdrawIndex } from '../../../../../api/newDepositWithdraw';
import Loading from '../../../../loading2';
import NoData from '../../../../noData';
import { getShowMoney } from '../../../../../utils/helper';
import LimitClickWrapper from '../../../../limitClickWrapper';
import Header from '../components/header';

export default function WithdrawRecordPanda({ handleClose = () => {} }) {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false); //下拉框是否展开
  const [withdrawDetailVisible, setWithdrawDetailVisible] = useState(false);
  const [dataList] = useState([
    {
      id: 1,
      text: 'deposit_data_today',
    },
    {
      id: 2,
      text: 'deposit_data_yesterday',
    },
    {
      id: 3,
      text: 'deposit_data_last_7',
    },
    // {
    //   id: 4,
    //   text: 'deposit_data_last_15',
    // },
    // {
    //   id: 5,
    //   text: 'deposit_data_last_30',
    // },
  ]);

  const [params, setParams] = useState({
    start_date: '',
    end_date: '',
    page: 1,
    page_size: 10,
  });

  const [currentId, setCurrentId] = useState(1);

  const [loading, setLoading] = useState(true);
  const [recordList, setRecordList] = useState([]); //记录列表
  const [pageCount, setPageCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0); //总金额
  const [infoId, setInfoId] = useState(0); //详情弹框展示的id

  useEffect(() => {
    if (currentId == 1) {
      setParams({ ...params, end_date: getDateAgoDays(0), start_date: getDateAgoDays(0) });
    } else if (currentId == 2) {
      setParams({ ...params, end_date: getDateAgoDays(1), start_date: getDateAgoDays(1) });
    } else if (currentId == 3) {
      setParams({ ...params, end_date: getDateAgoDays(0), start_date: getDateAgoDays(6) });
    } else if (currentId == 4) {
      setParams({ ...params, end_date: getDateAgoDays(0), start_date: getDateAgoDays(14) });
    } else if (currentId == 5) {
      setParams({ ...params, end_date: getDateAgoDays(0), start_date: getDateAgoDays(29) });
    }
  }, [currentId]);

  const setPrevpage = () => {
    if (params.page <= 1 || pageCount == 0) return;
    setParams({ ...params, page: params.page - 1 });
  };

  const setNextpage = () => {
    if (params.page == pageCount || pageCount == 0) return;
    setParams({ ...params, page: params.page + 1 });
  };

  const getFilterTypeStr = () => {
    let res = dataList.find((item) => {
      return item.id == currentId;
    });
    return res ? t(`deposit-withdraw-wg.${res.text}`) : '';
  };

  const requestWithdrawRecord = () => {
    if (params.start_date == '' || params.end_date == '') return;
    setLoading(true);
    PaymentWithdrawIndex(params)
      .then((res) => {
        if (res.code == 0) {
          setRecordList(res.data.list);
          const _pageCount = Math.ceil(res.data.total / params.page_size);
          setPageCount(_pageCount);
          setTotalAmount(res.data.total_amount);
        } else {
          setRecordList([]);
          setPageCount(0);
          setTotalAmount(0);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    requestWithdrawRecord();
  }, [params]);

  const clickItem = (id) => {
    setInfoId(id);
    setWithdrawDetailVisible(true);
  };

  // 获取距离今天N天前的时间
  const getDateAgoDays = (n) => {
    const today = moment();
    const nDaysAgo = today.clone().subtract(n, 'days');
    const formattedDate = nDaysAgo.format('YYYY-MM-DD');

    return formattedDate;
  };

  return (
    <div className="h-full bg-[var(--colour-54)]">
      <Header title={'Record'} clickBackBtn={handleClose} className="!bg-[var(--colour-35)]" />
      <WidthdrawDetails setShowVerify={setWithdrawDetailVisible} showVerify={withdrawDetailVisible} id={infoId} />

      <div className="p-[.32rem]">
        <div className="flex items-center justify-between">
          <TippyPopBox
            placement={Placement.bottomStart}
            popChildren={
              <PopChoose
                scrollBarWrapperClassName=""
                className={`py-[.38rem] bg-[var(--colour-69)] border border-[var(--colour-6)]`}
                style={{ boxShadow: '0rem .04rem .08rem 0rem rgba(0, 0, 0, 0.10)' }}
                children={dataList.map((item, index) => (
                  <PopChooseItem
                    className={`text-[.24rem] font-[500] leading-[.3rem] px-[.2rem] py-[.2rem] text-[var(--colour-1)] hover:bg-[var(--colour-68)] hover:!text-[var(--colour-1)]`}
                    key={index}
                    data={item}
                    choose={currentId == item.id}
                    chooseClassName="bg-[var(--colour-68)] !text-[var(--colour-1)]"
                    onClick={() => {
                      setCurrentId(item.id);
                      setIsShow(false);
                    }}
                  >
                    <span className="">{t(`deposit-withdraw-wg.${item.text}`)}</span>
                  </PopChooseItem>
                ))}
              />
            }
            visible={isShow}
            onClickOutside={() => {
              setIsShow(false);
            }}
            offset={[0, 10]}
          >
            <div
              className={cn(
                'h-[.5rem] rounded-[.4rem] bg-[var(--colour-35)] border  px-[.18rem] flex items-center justify-between',
                {
                  'border-[var(--colour-1)]': isShow,
                  'border-[var(--colour-6)]': !isShow,
                },
              )}
              onClick={() => {
                setIsShow(isShow ? false : true);
              }}
            >
              <div className="text-[.2rem] font-[400] text-[var(--colour-44)] leading-[.3rem] mr-[.36rem]">
                {getFilterTypeStr()}
              </div>
              <div
                className={cn('w-[.14rem] h-[.24rem] text-[var(--colour-11)]', {
                  'rotate-[-90deg]': isShow,
                  'rotate-[90deg]': !isShow,
                })}
              >
                <Icon name={IconName.BannerNext2} />
              </div>
            </div>
          </TippyPopBox>
          <div className="flex items-center text-[.24rem] font-[500] leading-[.24rem]">
            <div className="text-[var(--colour-44)] mr-[.1rem]">
              {t('deposit-withdraw-wg.withdraw_service_record_total_deposit')}
            </div>
            <div className="text-[var(--colour-24)]">
              {/* <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>{' '} */}
              <span>{getShowMoney(totalAmount, 2, 2)}</span>
            </div>
          </div>
        </div>

        <div className="scrollbar-box-container h-[calc(var(--app-height)-3.4rem)] pt-[.18rem]">
          <div className="scrollbar-box-outer hidden-scroll-y">
            <div className="scrollbar-box-inner-no-relative">
              {loading ? (
                <Loading className="flex-1" />
              ) : recordList && recordList.length > 0 ? (
                recordList.map((item, index) => {
                  return <PixItem key={index} isShowBg={index % 2 == 0} data={item} click={clickItem} />;
                })
              ) : (
                <NoData className="gap-[.2rem]  flex-1 " imgStyle="!w-[2rem] !h-[2rem]" />
              )}
            </div>
          </div>
        </div>

        {recordList && recordList.length > 0 ? (
          <div className="w-full flex justify-center items-center mt-[.2rem]   ">
            <LimitClickWrapper
              onClick={setPrevpage}
              className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer   bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
            >
              <div className="w-[.24rem] h-[.24rem]   rotate-180">
                <Icon name={IconName.BannerNext2}></Icon>
              </div>
            </LimitClickWrapper>
            <div className="px-[.24rem]  text-[.24rem]  font-[500] text-[var(--colour-1)]">{params.page}</div>
            <LimitClickWrapper
              onClick={setNextpage}
              className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer   bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
            >
              <div className="w-[.24rem] h-[.24rem]  ">
                <Icon name={IconName.BannerNext2}></Icon>
              </div>
            </LimitClickWrapper>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const PixItem = ({ data = null, click = () => {}, isShowBg = false }) => {
  const { t } = useTranslation();

  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state?.app?.websiteInitConfig,
    }),
    shallowEqual,
  );

  const statusReturn = (status, approval_result) => {
    if (status == 3) {
      if (approval_result == -1) {
        return t('deposit-withdraw.reject');
      } else {
        return t('deposit-withdraw.success');
      }
    }
  };

  return (
    <div
      className={cn(
        'min-h-[2.08rem] p-[.2rem_.2rem_.32rem_.2rem] rounded-[.12rem] text-[.24rem] font-[500] leading-[.3rem]',
        {
          'bg-[var(--colour-51)]': isShowBg,
        },
      )}
      onClick={() => {
        click(data.transaction_id);
      }}
    >
      <div className="flex items-center justify-between mb-[.38rem] text-[var(--colour-44)]">
        <span>PIX</span>
        <div className="">
          <span>{websiteInitConfig?.website_info?.currency_symbol || ''}</span>
          {getShowMoney(data.amount, 2, 2)}
        </div>
      </div>

      <div className="text-[var(--colour-38)] mb-[.28rem]">
        {moment(new Date(data.create_time)).format('YYYY-MM-DD HH:mm:ss')}
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="text-[var(--colour-38)] mr-[.16rem] max-w-[3.3rem] truncate">{data.transaction_id || ''}</div>
          <div
            className="w-[.28rem] h-[.28rem] text-[var(--colour-41)]"
            onClick={(e) => {
              e.stopPropagation();
              copy(`${data.transaction_id || ''}`);
              toast.success(t('home.toast006'), {
                containerId: 'global',
              });
            }}
          >
            <Icon name={IconName.Copy6} />
          </div>
        </div>
        <div className={cn('whitespace-nowrap ml-[.06rem] h-full flex items-center', {})}>
          {[0, 4].includes(data.status) ? (
            <span className="text-[var(--colour-25)] whitespace-nowrap">{t('deposit-withdraw.fail')}</span>
          ) : (
            <></>
          )}
          {[1, 2].includes(data.status) ? (
            <span className="text-[var(--colour-26)] whitespace-nowrap">{t('account.pending')}</span>
          ) : (
            <></>
          )}
          {data.status == 3 ? (
            <span
              className={`whitespace-nowrap  ${
                data.approval_result == -1 ||
                data.behaviour == 'withdraw_rollback' ||
                data.behaviour == 'bonus_withdraw_rollback'
                  ? 'text-[var(--colour-25)]'
                  : 'text-[var(--colour-24)]'
              }`}
            >
              {data.behaviour == 'withdraw_rollback' || data.behaviour == 'bonus_withdraw_rollback' ? (
                <>{t('deposit-withdraw.rollback')}</>
              ) : (
                <>{statusReturn(data.status, data.approval_result)}</>
              )}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
