import { get } from 'lodash';
import store from '../redux/store';
import { isControllableSite } from '../utils/helper';

import SvgIcon from './svgIcon';
import Close from '../assets/icons/close.svg';
import Play from '../assets/icons/play.svg';
import Tg from '../assets/icons/tg.svg';
import BottomArrow from '../assets/icons/bottomArrow.svg';
import SignIn from '../assets/icons/signIn.svg';
import Search from '../assets/icons/search.svg';
import RightArrow from '../assets/icons/rightArrow.svg';
import Hide from '../assets/icons/hide.svg';
import Show from '../assets/icons/show.svg';
import Gmail from '../assets/icons/gmail.svg';
import MetaMask from '../assets/icons/metaMask.svg';
import Telegram from '../assets/icons/telegram.svg';
import FullScreen from '../assets/icons/fullscreen.svg';
import Email from '../assets/icons/email.svg';
import Email4 from '../assets/icons/email4.svg';
import Wallet from '../assets/icons/wallet.svg';
import Copy from '../assets/icons/copy.svg';
import Facebook from '../assets/icons/facebook.svg';
import Checked1 from '../assets/icons/checked1.svg';
import Checked2 from '../assets/icons/checked2.svg';
import Checked3 from '../assets/icons/checked3.svg';
import Copy2 from '../assets/icons/copy2.svg';
import Plus182 from '../assets/icons/18plus2.svg';
import Vip from '../assets/icons/vip.svg';
import User from '../assets/icons/user.svg';
import Pwd from '../assets/icons/pwd.svg';
import Close2 from '../assets/icons/close2.svg';
import Lock from '../assets/icons/lock.svg';
import Newuser from '../assets/icons/newUser.svg';
import NerPwd from '../assets/icons/newPwd.svg';
import Newandroid from '../assets/icons/googleplay.svg';
import Newappstoroe from '../assets/icons/appsoore.svg';
import Useraccount from '../assets/icons/useraccout.svg';
import Userconversion from '../assets/icons/userconversion.svg';
import Userwallet from '../assets/icons/userwallet.svg';
import Usertrabsactions from '../assets/icons/usertrabsactions.svg';
import TablebarRewards from '../assets/icons/tablebar-rewards.svg';
import Heart1 from '../assets/icons/heart1.svg';
import Heart2 from '../assets/icons/heart2.svg';
import Info from '../assets/icons/info.svg';
import BannerPrev from '../assets/icons/bannerPrev.svg';
import BannerNext from '../assets/icons/bannerNext.svg';
import BannerNext2 from '../assets/icons/bannerNext2.svg';
import TagsSearch from '../assets/icons/tagSearch.svg';
import ArrowsRight from '../assets/icons/arrowsRight.svg';
import ArrowsTop from '../assets/icons/arrowsTop.svg';
import ReferPictureArrow from '../assets/icons/referPictureArrow.svg';
import Brmenuback from '../assets/icons/brmenuback.svg';
import Refer from '../assets/icons/refer.svg';
import ChatArraw from '../assets/icons/chatArraw.svg';
import HelpRight from '../assets/icons/helpRight.svg';
import DrawerFlag from '../assets/icons/drawerFlag.svg';
import MenuBarHome from '../assets/icons/menuBarHome.svg';
import MenuBarRefer from '../assets/icons/menuBarRefer.svg';
import MenuBarPromo from '../assets/icons/menuBarPromo.svg';
import MenuBarEmail from '../assets/icons/menuBarEmail.svg';
import MenuBarMine from '../assets/icons/menuBarMine.svg';
import MenuBarDeposit from '../assets/icons/menuBarDeposit.svg';
import MenuBarService from '../assets/icons/menuBarService.svg';
import MenuBarService2 from '../assets/icons/menuBarService2.svg';
import DrawerMenuPromos from '../assets/icons/drawerMenuPromos.svg';
import DrawerMenuService from '../assets/icons/drawerMenuService.svg';
import LangDefault from '../assets/icons/langDefault.svg';
import LangEnglish from '../assets/icons/langEnglish.svg';
import LangFilipino from '../assets/icons/langFilipino.svg';
import LangProtugues from '../assets/icons/langProtugues.svg';
import LangJapanese from '../assets/icons/langJapanese.svg';
import LangVi from '../assets/icons/langVi.svg';
import LangTh from '../assets/icons/langTh.svg';
import LangMs from '../assets/icons/langMs.svg';
import LangHi from '../assets/icons/langHi.svg';
import LangEs from '../assets/icons/langEs.svg';
import LangId from '../assets/icons/langId.svg';
import Freshen from '../assets/icons/freshen.svg';
import WalletRight from '../assets/icons/walletRight.svg';
import Vector from '../assets/icons/vector.svg';
import ManMade from '../assets/icons/manMade.svg';
import UserAccount from '../assets/icons/userAccount.svg';
import UserSound from '../assets/icons/userSound.svg';
import UserInbox from '../assets/icons/userInbox.svg';
import UserPhone from '../assets/icons/userPhone.svg';
import UserLogout from '../assets/icons/userLogout.svg';
import SiteMailNoRead from '../assets/icons/mail-no-read.svg';
import SiteMailNoRead2 from '../assets/icons/mail-no-read2.svg';
import SiteMailReaded from '../assets/icons/mail-readed.svg';
import LoginAccount from '../assets/icons/login-account.svg';
import LoginPwd from '../assets/icons/login-pwd.svg';
import LoginPwd2 from '../assets/icons/login-pwd2.svg';
import RegisterCode from '../assets/icons/register-code.svg';
import RegisterCode2 from '../assets/icons/register-code2.svg';
import WidthdrawQuestion from '../assets/icons/widthdraw-question.svg';
import WidthdrawQuestion2 from '../assets/icons/widthdraw-question2.svg';
import WalltMsg from '../assets/icons/wallt_msg.svg';
import WalltMsg2 from '../assets/icons/wallt_msg2.svg';
import Phone from '../assets/icons/phone.svg';
import Phone4 from '../assets/icons/phone4.svg';
import Emails from '../assets/icons/emails.svg';
import NewBack from '../assets/icons/newBack.svg';
import ChangeName from '../assets/icons/changeName.svg';
import Copys from '../assets/icons/copys.svg';
import NewDate from '../assets/icons/newdate.svg';
import Goback from '../assets/icons/goback.svg';
import ShowEye from '../assets/icons/showEye.svg';
import HideEye from '../assets/icons/hideEye.svg';
import CurrentVip from '../assets/icons/currentVip.svg';
import Vipupgrade from '../assets/icons/Vipupgrade.svg';
import MessageNew from '../assets/icons/messageNew.svg';
import RightArrow3 from '../assets/icons/RightArrow3.svg';
import DownLoad from '../assets/icons/download.svg';
import DownLoad2 from '../assets/icons/download2.svg';
import Print2 from '../assets/icons/print2.svg';
import DrawDownLoad from '../assets/icons/drawDownLoad.svg';
import WagerCopy from '../assets/icons/wagerCopy.svg';
import FillCopy from '../assets/icons/fillCopy.svg';
import SearchArrow from '../assets/icons/searchArrow.svg';
import GameBack from '../assets/icons/gameBack.svg';
import HomeBtn from '../assets/icons/homeBtn.svg';
import WhatsappShare from '../assets/icons/share/whatsappShare.svg';
import FacebookShare from '../assets/icons/share/facebookShare.svg';
import TelegramShare from '../assets/icons/share/telegramShare.svg';
import TwitterShare from '../assets/icons/share/twitterShare.svg';
import MoreShare from '../assets/icons/share/moreShare.svg';
import MenuCasino from '../assets/icons/menuCasino.svg';
import MenuSport from '../assets/icons/menuSport.svg';
import Explain from '../assets/icons/explain.svg';
import Record from '../assets/icons/record.svg';
import Record2 from '../assets/icons/record2.svg';
import Copy6 from '../assets/icons/copy6.svg';
import LuckyWheel from '../assets/icons/luckyWheel.svg';
import RefreshTime from '../assets/icons/refreshTime.svg';
import Brl from '../assets/icons/brl.svg';
import Complete from '../assets/icons/complete.svg';
import Provider from '../assets/icons/provider.svg';
import GiftMoney from '../assets/icons/giftMoney.svg';
import DrawerSports from '../assets/icons/drawerSports.svg';
import DrawerPromotions from '../assets/icons/drawerPromotions.svg';
import DrawerLang from '../assets/icons/drawerLang.svg';
import DrawerDownload from '../assets/icons/drawerDownload.svg';
import DrawerDownload2 from '../assets/icons/drawerDownload2.svg';
import Bulletin from '../assets/icons/bulletin.svg';
import Countdown from '../assets/icons/countdown.svg';
import Announcementarrow from '../assets/icons/announcementArrow.svg';
import Announcementarrow1 from '../assets/icons/announcementArrow1.svg';
import AnnouncementarrowLeft from '../assets/icons/announcementLeft.svg';
import AnnouncementarrowRight from '../assets/icons/announcementRight.svg';
import AnnouncementChecked from '../assets/icons/announcementchecked.svg';
import PilotWheelCheck from '../assets/icons/check.svg';
import Email2 from '../assets/icons/email2.svg';
import Share from '../assets/icons/share.svg';
import DrawerFlag2 from '../assets/icons/drawerFlag2.svg';
import DrawerFlag3 from '../assets/icons/drawerFlag3.svg';
import DrawerMenuRecords from '../assets/icons/drawerMenuRecords.svg';
import DrawerMenuNetwork from '../assets/icons/drawerMenuNetwork.svg';
import DrawerMenuFaq from '../assets/icons/drawerMenuFaq.svg';
import DrawerMenuFaq2 from '../assets/icons/drawerMenuFaq2.svg';
import DrawerMenuAgent from '../assets/icons/drawerMenuAgent.svg';
import Service from '../assets/icons/service.svg';
import Sports from '../assets/icons/sports.svg';
import Logout from '../assets/icons/logout.svg';
import Phone2 from '../assets/icons/phone2.svg';
import Username from '../assets/icons/username.svg';
import Password from '../assets/icons/password.svg';
import ShowEye2 from '../assets/icons/showEye2.svg';
import HideEye2 from '../assets/icons/hideEye2.svg';
import MusicList from '../assets/icons/musiclist.svg';
import NextMusic from '../assets/icons/nextmusic.svg';
import PauseMusic from '../assets/icons/pausemusic.svg';
import PlayBack from '../assets/icons/playback.svg';
import PlayMusic from '../assets/icons/playmusic.svg';
import Playorder from '../assets/icons/playorder.svg';
import PrevMusic from '../assets/icons/prevmusic.svg';
import ShufflePlay from '../assets/icons/shuffleplay.svg';
import Audiodownload from '../assets/icons/audiodownload.svg';
import Listening from '../assets/icons/listening.svg';
import loadingMp3 from '../assets/icons/musicloading.svg';
import Audiocheck from '../assets/icons/audiocheck.svg';
import Audiodel from '../assets/icons/audiodel.svg';
import Sound from '../assets/icons/sound.svg';
import Email3 from '../assets/icons/email3.svg';
import Deposit from '../assets/icons/deposit.svg';
import Withdraw from '../assets/icons/withdraw.svg';
import Vip2 from '../assets/icons/vip2.svg';
import Copy3 from '../assets/icons/copy3.svg';
import Refresh from '../assets/icons/refresh.svg';
import GamesearchBack from '../assets/icons/gamesearchBack.svg';
import Blog from '../assets/icons/blog.svg';
import ErrorTip from '../assets/icons/errorTip.svg';
import CopyZ from '../assets/icons/copyZ.svg';
import USDT from '../assets/icons/USDT.svg';
import BTC from '../assets/icons/BTC.svg';
import ETH from '../assets/icons/ETH.svg';
import TRX from '../assets/icons/TRX.svg';
import USDC from '../assets/icons/USDC.svg';
import BRZ from '../assets/icons/BRZ.svg';
import GiftBox from '../assets/icons/giftBox.svg';
import WithdrawArrow from '../assets/icons/withdrawArrow.svg';
import Fast from '../assets/icons/fast.svg';
import Activated from '../assets/icons/activated.svg';
import PwaDownload from '../assets/icons/pwadownload.svg';
import PwaWarning from '../assets/icons/pwawarning.svg';
import Tip from '../assets/icons/tip.svg';
import SignInClose from '../assets/icons/signInclose.svg';
import Registeraccount from '../assets/icons/registeraccount.svg';
import Registercountry from '../assets/icons/registercountry.svg';
import Registeruser from '../assets/icons/registeruser.svg';
import Registerlock from '../assets/icons/registerlock.svg';
import Broadcast from '../assets/icons/broadcast.svg';
import Newlogin from '../assets/icons/newlogin.svg';
import HeaderWalletArrow from '../assets/icons/headerWalletArrow.svg';
import Sound2 from '../assets/icons/sound2.svg';
import Sound2_1 from '../assets/icons/sound2_1.svg';
import Sound2_2 from '../assets/icons/sound2_2.svg';
import Sound2_3 from '../assets/icons/sound2_3.svg';
import Sound2_2_opacity from '../assets/icons/sound2_2_opacity.svg';
import Sound2_3_opacity from '../assets/icons/sound2_3_opacity.svg';
// import HeaderSearch from '../assets/icons/headerSearch.svg';
import HeaderSearch from '../assets/icons/HeaderSearch.svg';
import HeaderMusic from '../assets/icons/headerMusic.svg';
import HeaderSearch2 from '../assets/icons/headerSearch2.svg';
import HeaderMusic2 from '../assets/icons/headerMusic2.svg';
import HeaderCountry from '../assets/icons/headerCountry.svg';
import BtntoTop from '../assets/icons/btntop.svg';
import Email5 from '../assets/icons/email5.svg';
import Deposit2 from '../assets/icons/deposit2.svg';
import Withdraw2 from '../assets/icons/withdraw2.svg';
import Vip3 from '../assets/icons/vip3.svg';
import NewhideEye from '../assets/icons/newhideEye.svg';
import NewShowEye from '../assets/icons/newshowEye.svg';
import FacebookWG from '../assets/icons/facebookWG.svg';
import GoogleWG from '../assets/icons/googleWG.svg';
import TelegramWG from '../assets/icons/telegramWG.svg';
import MetamaskWG from '../assets/icons/metamaskWG.svg';
import ClearStr from '../assets/icons/clearStr.svg';
import EventCheck from '../assets/icons/eventsCheck.svg';
import Eventstime from '../assets/icons/eventstime.svg';
import Finish from '../assets/icons/finish.svg';
import Cpf from '../assets/icons/cpf.svg';
import Cpf2 from '../assets/icons/cpf2.svg';
import MissionTable1 from '../assets/icons/mission_table_1.svg';
import Copy7 from '../assets/icons/copy7.svg';
import Menu3 from '../assets/icons/menu3.svg';
import HaderWallet from '../assets/icons/headerwallet.svg';
import Language from '../assets/icons/language.svg';
import Message1 from '../assets/icons/message1.svg';
import CategoryGameHeaderArrow from '../assets/icons/categoryGameHeaderArrow.svg';
import BacktoTop1 from '../assets/icons/backtoTop1.svg';
import Apple from '../assets/icons/apple.svg';
import Android from '../assets/icons/android.svg';
import BtnBack from '../assets/icons/btnback.svg';
import MineRefresh from '../assets/icons/mineRefresh.svg';
import AccountDeposit from '../assets/icons/accountDeposit.svg';
import AccountWithdraw from '../assets/icons/accountWithdraw.svg';
import AccountEmail from '../assets/icons/accountEmail.svg';
import AccountCopy from '../assets/icons/accountCopy.svg';
import ReferralCashbackInvite from '../assets/icons/referralCashbackInvite.svg';

import MenubarHome_1_layout_10002_theme_1 from '../assets/icons/menubarHome_1.svg';
import MenubarHome_2_layout_10002_theme_1 from '../assets/icons/menubarHome_2.svg';
import MenubarPromo_1_layout_10002_theme_1 from '../assets/icons/menubarPromo_1.svg';
import MenubarPromo_2_layout_10002_theme_1 from '../assets/icons/menubarPromo_2.svg';
import MenubarLogin_1_layout_10002_theme_1 from '../assets/icons/menubarLogin_1.svg';
import MenubarLogin_2_layout_10002_theme_1 from '../assets/icons/menubarLogin_2.svg';
import MenubarReferral_1_layout_10002_theme_1 from '../assets/icons/menubarReferral_1.svg';
import MenubarReferral_2_layout_10002_theme_1 from '../assets/icons/menubarReferral_2.svg';
import MenubarDeposit_1_layout_10002_theme_1 from '../assets/icons/menubarDeposit_1.svg';
import MenubarDeposit_2_layout_10002_theme_1 from '../assets/icons/menubarDeposit_2.svg';
import MenubarMine_1_layout_10002_theme_1 from '../assets/icons/menubarMine_1.svg';
import MenubarMine_2_layout_10002_theme_1 from '../assets/icons/menubarMine_2.svg';
import MenubarHome_1_layout_10002_theme_2 from '../assets/icons/menubarHome_1_layout10002_theme_2.svg';
import MenubarHome_2_layout_10002_theme_2 from '../assets/icons/menubarHome_2_layout10002_theme_2.svg';
import MenubarPromo_1_layout_10002_theme_2 from '../assets/icons/menubarPromo_1_layout10002_theme_2.svg';
import MenubarPromo_2_layout_10002_theme_2 from '../assets/icons/menubarPromo_2_layout10002_theme_2.svg';
import MenubarLogin_1_layout_10002_theme_2 from '../assets/icons/menubarLogin_1_layout10002_theme_2.svg';
import MenubarLogin_2_layout_10002_theme_2 from '../assets/icons/menubarLogin_2_layout10002_theme_2.svg';
import MenubarReferral_1_layout_10002_theme_2 from '../assets/icons/menubarReferral_1_layout10002_theme_2.svg';
import MenubarReferral_2_layout_10002_theme_2 from '../assets/icons/menubarReferral_2_layout10002_theme_2.svg';
import MenubarDeposit_1_layout_10002_theme_2 from '../assets/icons/menubarDeposit_1_layout10002_theme_2.svg';
import MenubarDeposit_2_layout_10002_theme_2 from '../assets/icons/menubarDeposit_2_layout10002_theme_2.svg';
import MenubarMine_1_layout_10002_theme_2 from '../assets/icons/menubarMine_1_layout10002_theme_2.svg';
import MenubarMine_2_layout_10002_theme_2 from '../assets/icons/menubarMine_2_layout10002_theme_2.svg';
import MenubarHome_1_layout_10002_theme_3 from '../assets/icons/menubarHome_1_layout10002_theme_3.svg';
import MenubarHome_2_layout_10002_theme_3 from '../assets/icons/menubarHome_2_layout10002_theme_3.svg';
import MenubarPromo_1_layout_10002_theme_3 from '../assets/icons/menubarPromo_1_layout10002_theme_3.svg';
import MenubarPromo_2_layout_10002_theme_3 from '../assets/icons/menubarPromo_2_layout10002_theme_3.svg';
import MenubarLogin_1_layout_10002_theme_3 from '../assets/icons/menubarLogin_1_layout10002_theme_3.svg';
import MenubarLogin_2_layout_10002_theme_3 from '../assets/icons/menubarLogin_2_layout10002_theme_3.svg';
import MenubarReferral_1_layout_10002_theme_3 from '../assets/icons/menubarReferral_1_layout10002_theme_3.svg';
import MenubarReferral_2_layout_10002_theme_3 from '../assets/icons/menubarReferral_2_layout10002_theme_3.svg';
import MenubarDeposit_1_layout_10002_theme_3 from '../assets/icons/menubarDeposit_1_layout10002_theme_3.svg';
import MenubarDeposit_2_layout_10002_theme_3 from '../assets/icons/menubarDeposit_2_layout10002_theme_3.svg';
import MenubarMine_1_layout_10002_theme_3 from '../assets/icons/menubarMine_1_layout10002_theme_3.svg';
import MenubarMine_2_layout_10002_theme_3 from '../assets/icons/menubarMine_2_layout10002_theme_3.svg';
import MenubarHome_1_layout_10002_theme_4 from '../assets/icons/menubarHome_1_layout10002_theme_4.svg';
import MenubarHome_2_layout_10002_theme_4 from '../assets/icons/menubarHome_2_layout10002_theme_4.svg';
import MenubarPromo_1_layout_10002_theme_4 from '../assets/icons/menubarPromo_1_layout10002_theme_4.svg';
import MenubarPromo_2_layout_10002_theme_4 from '../assets/icons/menubarPromo_2_layout10002_theme_4.svg';
import MenubarLogin_1_layout_10002_theme_4 from '../assets/icons/menubarLogin_1_layout10002_theme_4.svg';
import MenubarLogin_2_layout_10002_theme_4 from '../assets/icons/menubarLogin_2_layout10002_theme_4.svg';
import MenubarReferral_1_layout_10002_theme_4 from '../assets/icons/menubarReferral_1_layout10002_theme_4.svg';
import MenubarReferral_2_layout_10002_theme_4 from '../assets/icons/menubarReferral_2_layout10002_theme_4.svg';
import MenubarDeposit_1_layout_10002_theme_4 from '../assets/icons/menubarDeposit_1_layout10002_theme_4.svg';
import MenubarDeposit_2_layout_10002_theme_4 from '../assets/icons/menubarDeposit_2_layout10002_theme_4.svg';
import MenubarMine_1_layout_10002_theme_4 from '../assets/icons/menubarMine_1_layout10002_theme_4.svg';
import MenubarMine_2_layout_10002_theme_4 from '../assets/icons/menubarMine_2_layout10002_theme_4.svg';

import MenubarHome_1_layout_10002_theme_5 from '../assets/icons/menubarHome_1_layout10002_theme_5.svg';
import MenubarHome_2_layout_10002_theme_5 from '../assets/icons/menubarHome_2_layout10002_theme_5.svg';
import MenubarPromo_1_layout_10002_theme_5 from '../assets/icons/menubarPromo_1_layout10002_theme_5.svg';
import MenubarPromo_2_layout_10002_theme_5 from '../assets/icons/menubarPromo_2_layout10002_theme_5.svg';
import MenubarLogin_1_layout_10002_theme_5 from '../assets/icons/menubarLogin_1_layout10002_theme_5.svg';
import MenubarLogin_2_layout_10002_theme_5 from '../assets/icons/menubarLogin_2_layout10002_theme_5.svg';
import MenubarReferral_1_layout_10002_theme_5 from '../assets/icons/menubarReferral_1_layout10002_theme_5.svg';
import MenubarReferral_2_layout_10002_theme_5 from '../assets/icons/menubarReferral_2_layout10002_theme_5.svg';
import MenubarDeposit_1_layout_10002_theme_5 from '../assets/icons/menubarDeposit_1_layout10002_theme_5.svg';
import MenubarDeposit_2_layout_10002_theme_5 from '../assets/icons/menubarDeposit_2_layout10002_theme_5.svg';
import MenubarMine_1_layout_10002_theme_5 from '../assets/icons/menubarMine_1_layout10002_theme_5.svg';
import MenubarMine_2_layout_10002_theme_5 from '../assets/icons/menubarMine_2_layout10002_theme_5.svg';

import Support from '../assets/icons/support.svg';
import Message from '../assets/icons/message.svg';
import Data from '../assets/icons/data.svg';
import Copy4 from '../assets/icons/copy4.svg';
import Copy5 from '../assets/icons/copy5.svg';
import Fee from '../assets/icons/fee.svg';
import Fund from '../assets/icons/fund.svg';
import Account from '../assets/icons/account.svg';
import Bets from '../assets/icons/bets.svg';
import ToInvite from '../assets/icons/toInvite.svg';
import Data2 from '../assets/icons/data2.svg';
import Security from '../assets/icons/security.svg';
import Music from '../assets/icons/music.svg';
import Faq from '../assets/icons/faq.svg';
import GoOut from '../assets/icons/goOut.svg';
import BackArrow from '../assets/icons/backArrow.svg';
import Newdate1 from '../assets/icons/newdate1.svg';
import Phone3 from '../assets/icons/phone3.svg';
import Pwd2 from '../assets/icons/pwd2.svg';
import Fa from '../assets/icons/fa.svg';
import SortTop from '../assets/icons/sortTop.svg';
import SortBottom from '../assets/icons/sortBottom.svg';
import SortLightBottom from '../assets/icons/sortLightBottom.svg';
import SortLightTop from '../assets/icons/sortLightTop.svg';
import RefreshBalance from '../assets/icons/refreshBalance.svg';
import MineBonus from '../assets/icons/mineBonus.svg';
import Search2 from '../assets/icons/search2.svg';
import Frshen from '../assets/icons/frshen.svg';
import Report from '../assets/icons/report.svg';
import MaintainProvider from '../assets/icons/maintainProvider.svg';
import WhatsApp from '../assets/icons/whatsApp.svg';
import Sms from '../assets/icons/sms.svg';
import NoData from '../assets/icons/noData.svg';
import ArrowBottom from '../assets/icons/arrowBottom.svg';
import ErrorTip2 from '../assets/icons/errorTip2.svg';
import SuccessTip from '../assets/icons/successTip.svg';
import ErrorTip3 from '../assets/icons/errorTip3.svg';
import SuccessTip2 from '../assets/icons/successTip2.svg';
import ToastInfo from '../assets/icons/toastInfo.svg';
import Wallet2 from '../assets/icons/wallet2.svg';
import AccountCard from '../assets/icons/accountCard.svg';
import AccountCard2 from '../assets/icons/accountCard2.svg';
import Account2 from '../assets/icons/account2.svg';
import Pix2 from '../assets/icons/pix2.svg';
import DetailsLoading from '../assets/icons/detailsLoading.svg';
import DetailsError from '../assets/icons/detailsError.svg';
import Refresh2 from '../assets/icons/refresh2.svg';
import PixSetting from '../assets/icons/pixSetting.svg';
import Menu from '../assets/icons/menu.svg';
import MenuEvents from '../assets/icons/menu_events.svg';
import MenuVip from '../assets/icons/menu_vip.svg';
import MenuRakeBack from '../assets/icons/menu_rakeback.svg';
import MenuSecurity from '../assets/icons/menu_security.svg';
import MenuData from '../assets/icons/menu_data.svg';
import MenuAccount from '../assets/icons/menu_account.svg';
import MenuBets from '../assets/icons/menu_bets.svg';
import MenuReport from '../assets/icons/menu_report.svg';
import MenuPending from '../assets/icons/menu_pending.svg';
import MenuWithdraw from '../assets/icons/menu_withdraw.svg';
import MenuHistory from '../assets/icons/menu_history.svg';
import MenuGiftMoney from '../assets/icons/menu_giftmoney.svg';
import MenuGiftMoney2 from '../assets/icons/menu_giftmoney2.svg';
import Menu2 from '../assets/icons/menu2.svg';
import MenuEvents2 from '../assets/icons/menu_events2.svg';
import MenuVip2 from '../assets/icons/menu_vip2.svg';
import MenuRakeBack2 from '../assets/icons/menu_rakeback2.svg';
import MenuSecurity2 from '../assets/icons/menu_security2.svg';
import MenuData2 from '../assets/icons/menu_data2.svg';
import MenuAccount2 from '../assets/icons/menu_account2.svg';
import MenuBets2 from '../assets/icons/menu_bets2.svg';
import MenuReport2 from '../assets/icons/menu_report2.svg';
import MenuPending2 from '../assets/icons/menu_pending2.svg';
import MenuWithdraw2 from '../assets/icons/menu_withdraw2.svg';
import MenuHistory2 from '../assets/icons/menu_history2.svg';

import MenuBlog from '../assets/icons/menu_blog.svg';
import MenuMessage from '../assets/icons/menu_message.svg';
import MenuFaq from '../assets/icons/menu_faq.svg';
import MenuService from '../assets/icons/menu_service.svg';
import MenuFb from '../assets/icons/menu_fb.svg';
import MenuGg from '../assets/icons/menu_gg.svg';
import MenuTg from '../assets/icons/menu_tg.svg';
import ProportionCategory_1 from '../assets/icons/proportion_category_1.svg';
import ProportionCategory_2 from '../assets/icons/proportion_category_2.svg';
import ProportionCategory_3 from '../assets/icons/proportion_category_3.svg';
import ProportionCategory_4 from '../assets/icons/proportion_category_4.svg';
import ProportionCategory_5 from '../assets/icons/proportion_category_5.svg';
import ProportionCategory_6 from '../assets/icons/proportion_category_6.svg';
import ProportionCategory_999 from '../assets/icons/proportion_category_999.svg';
import SearchLoading from '../assets/icons/searchLoading.svg';
import Favorite from '../assets/icons/favorite.svg';
import Rencent from '../assets/icons/rencent.svg';
import Rocket from '../assets/icons/rocket.svg';
import Fire from '../assets/icons/fire.svg';
import TournamentTitleIcon from '../assets/icons/tournamentTitleIcon.svg';
import CurrentLevel from '../assets/icons/currentLevel.svg';

//menu-bar
import MenuBarHomeDefault from '../assets/icons/menu-bar/home_default.svg';
import MenuBarHomeChoose from '../assets/icons/menu-bar/home.svg';

import MenuBarDepositDefault from '../assets/icons/menu-bar/deposit_default.svg';
import MenuBarDepositChoose from '../assets/icons/menu-bar/deposit.svg';

import MenuBarLogInDefault from '../assets/icons/menu-bar/logIn_default.svg';
import MenuBarLogInChoose from '../assets/icons/menu-bar/logIn.svg';

import MenuBarMessageDefault from '../assets/icons/menu-bar/message_default.svg';
import MenuBarMessageChoose from '../assets/icons/menu-bar/message.svg';

import MenuBarMyAccountDefault from '../assets/icons/menu-bar/myAccount_default.svg';
import MenuBarMyAccountChoose from '../assets/icons/menu-bar/myAccount.svg';

import MenuBarPromotionDefault from '../assets/icons/menu-bar/promotion_default.svg';
import MenuBarPromotionChoose from '../assets/icons/menu-bar/promotion.svg';

import MenuBarReferralDefault from '../assets/icons/menu-bar/referral_default.svg';
import MenuBarReferralChoose from '../assets/icons/menu-bar/referral.svg';

import MenuBarSportsDefault from '../assets/icons/menu-bar/sports_default.svg';
import MenuBarSportsChoose from '../assets/icons/menu-bar/sports.svg';

import MenuBarSupportDefault from '../assets/icons/menu-bar/support_default.svg';
import MenuBarSupportChoose from '../assets/icons/menu-bar/support.svg';

import MenuBarWithdrawDefault from '../assets/icons/menu-bar/withdraw_default.svg';
import MenuBarWithdrawChoose from '../assets/icons/menu-bar/withdraw.svg';

import ButtonPrev from '../assets/icons/buttonPrev.svg';
import Union from '../assets/icons/union.svg';
import DubbleArrow from '../assets/icons/dubbleArrow.svg';
import pixSvg from '../assets/icons/pix.svg';
import phoneOnlineSvg from '../assets/icons/phoneOnline.svg';
import BubbleSvg from '../assets/icons/bubble.svg';
import SelectMaskSvg from '../assets/icons/selectMask.svg';
import PIXLogoSvg from '../assets/icons/PIXLogo.svg';
import VipCheckedSvg from '../assets/icons/vipChecked.svg';
import RightArrow2Svg from '../assets/icons/rightArrow2.svg';
import CheckboxSvg from '../assets/icons/checkbox.svg';
import CheckedBoxSvg from '../assets/icons/checkedBox.svg';
import HotSvg from '../assets/icons/hot.svg';
import SingleArrow from '../assets/icons/singleArrow.svg';
import Redeem from '../assets/icons/redeem.svg';
import RedeemLight from '../assets/icons/redeemLight.svg';
import InputClear from '../assets/icons/inputClear.svg';
import RedDot from '../assets/icons/redDot.svg';
import FirstDeposit from '../assets/icons/firstDeposit.svg';
import TotalDeposit from '../assets/icons/totalDeposit.svg';
import LiquiTime from '../assets/icons/liquiTime.svg';
import MysteryBonus from '../assets/icons/mysteryBonus.svg';
import DateDay from '../assets/icons/dateDay.svg';

// promotion events 前端图标
import Gametype_1 from '../assets/icons/gametype_1.svg';
import Gametype_2 from '../assets/icons/gametype_2.svg';
import Gametype_3 from '../assets/icons/gametype_3.svg';
import Gametype_4 from '../assets/icons/gametype_4.svg';
import Gametype_5 from '../assets/icons/gametype_5.svg';
import Gametype_6 from '../assets/icons/gametype_6.svg';
import Gametype_9999 from '../assets/icons/gametype_9999.svg';

import CloseEye from '../assets/icons/closeeye.svg';
import OpenEye from '../assets/icons/openeye.svg';

export const IconName = {
  Complete: 'Complete',
  Brl: 'Brl',
  RefreshTime: 'RefreshTime',
  MessageNew: 'MessageNew',
  RightArrow3: 'RightArrow3',
  WagerCopy: 'WagerCopy',
  HideEye: 'HideEye',
  ShowEye: 'ShowEye',
  Copys: 'Copys',
  CurrentVip: 'CurrentVip',
  Vipupgrade: 'Vipupgrade',
  NewDate: 'NewDate',
  ChangeName: 'ChangeName',
  NewBack: 'NewBack',
  Phone: 'Phone',
  Phone4: 'Phone4',
  WidthdrawQuestion: 'WidthdrawQuestion',
  WidthdrawQuestion2: 'WidthdrawQuestion2',
  WalltMsg: 'WalltMsg',
  WalltMsg2: 'WalltMsg2',
  RegisterCode: 'RegisterCode',
  RegisterCode2: 'RegisterCode2',
  LoginPwd: 'LoginPwd',
  LoginPwd2: 'LoginPwd2',
  LoginAccount: 'LoginAccount',
  WhatsappShare: 'WhatsappShare',
  ChatArraw: 'ChatArraw',
  Useraccount: 'Useraccount',
  Userconversion: 'Userconversion',
  Userwallet: 'Userwallet',
  Usertrabsactions: 'Usertrabsactions',
  Lock: 'Lock',
  Close2: 'Close2',
  Heart1: 'Heart1',
  Heart2: 'Heart2',
  User: 'User',
  Pwd: 'Pwd',
  Vip: 'Vip',
  Close: 'Close',
  Play: 'Play',
  Goback: 'Goback',
  TG: 'TG',
  BottomArrow: 'BottomArrow',
  SignIn: 'SignIn',
  Search: 'Search',
  RightArrow: 'RightArrow',
  Hide: 'Hide',
  Show: 'Show',
  Gmail: 'Gmail',
  MetaMask: 'MetaMask',
  Telegram: 'Telegram',
  FullScreen: 'FullScreen',
  Email: 'Email',
  Email4: 'Email4',
  Wallet: 'Wallet',
  Copy: 'Copy',
  Facebook: 'Facebook',
  Checked2: 'Checked2',
  Copy2: 'Copy2',
  Newuser: 'Newuser',
  NerPwd: 'NerPwd',
  Newandroid: 'Newandroid',
  Newappstoroe: 'Newappstoroe',
  Plus182: 'Plus182',
  TablebarRewards: 'TablebarRewards',
  Info: 'Info',
  BannerPrev: 'BannerPrev',
  BannerNext: 'BannerNext',
  BannerNext2: 'BannerNext2',
  TagsSearch: 'TagsSearch',
  SearchArrow: 'SearchArrow',
  ArrowsRight: 'ArrowsRight',
  ArrowsTop: 'ArrowsTop',
  ReferPictureArrow: 'ReferPictureArrow',
  SiteMailNoRead: 'SiteMailNoRead',
  SiteMailNoRead2: 'SiteMailNoRead2',
  SiteMailReaded: 'SiteMailReaded',
  Refer: 'Refer',
  HelpRight: 'HelpRight',
  DrawerFlag: 'DrawerFlag',
  MenuBarHome: 'MenuBarHome',
  MenuBarRefer: 'MenuBarRefer',
  MenuBarPromo: 'MenuBarPromo',
  MenuBarEmail: 'MenuBarEmail',
  MenuBarMine: 'MenuBarMine',
  MenuBarDeposit: 'MenuBarDeposit',
  MenuBarService: 'MenuBarService',
  MenuBarService2: 'MenuBarService2',
  DrawerMenuPromos: 'DrawerMenuPromos',
  DrawerMenuService: 'DrawerMenuService',
  LangDefault: 'LangDefault',
  LangEnglish: 'LangEnglish',
  LangFilipino: 'LangFilipino',
  LangProtugues: 'LangProtugues',
  LangJapanese: 'LangJapanese',
  LangVi: 'LangVi',
  LangTh: 'LangTh',
  LangMs: 'LangMs',
  LangHi: 'LangHi',
  LangEs: 'LangEs',
  LangId: 'LangId',
  Freshen: 'Freshen',
  WalletRight: 'WalletRight',
  Vector: 'Vector',
  ManMade: 'ManMade',
  UserAccount: 'UserAccount',
  UserSound: 'UserSound',
  UserInbox: 'UserInbox',
  UserPhone: 'UserPhone',
  UserLogout: 'UserLogout',
  DownLoad: 'DownLoad',
  DownLoad2: 'DownLoad2',
  Print2: 'Print2',
  DrawDownLoad: 'DrawDownLoad',
  FillCopy: 'FillCopy',
  GameBack: 'GameBack',
  HomeBtn: 'HomeBtn',
  FacebookShare: 'FacebookShare',
  TelegramShare: 'TelegramShare',
  TwitterShare: 'TwitterShare',
  MoreShare: 'MoreShare',
  MenuCasino: 'MenuCasino',
  MenuSport: 'MenuSport',
  Explain: 'Explain',
  Record: 'Record',
  Record2: 'Record2',
  Copy6: 'Copy6',
  LuckyWheel: 'LuckyWheel',
  Provider: 'Provider',
  GiftMoney: 'GiftMoney',
  DrawerSports: 'DrawerSports',
  DrawerPromotions: 'DrawerPromotions',
  DrawerLang: 'DrawerLang',
  DrawerDownload: 'DrawerDownload',
  DrawerDownload2: 'DrawerDownload2',
  Bulletin: 'Bulletin',
  Countdown: 'Countdown',
  Announcementarrow: 'Announcementarrow',
  Announcementarrow1: 'Announcementarrow1',
  AnnouncementarrowLeft: 'AnnouncementarrowLeft',
  AnnouncementarrowRight: 'AnnouncementarrowRight',
  AnnouncementChecked: 'AnnouncementChecked',
  PilotWheelCheck: 'PilotWheelCheck',
  Email2: 'Email2',
  Share: 'Share',
  DrawerFlag2: 'DrawerFlag2',
  DrawerFlag3: 'DrawerFlag3',
  MenubarHome_1_layout_10002_theme_1: 'MenubarHome_1_layout_10002_theme_1',
  MenubarHome_2_layout_10002_theme_1: 'MenubarHome_2_layout_10002_theme_1',
  MenubarPromo_1_layout_10002_theme_1: 'MenubarPromo_1_layout_10002_theme_1',
  MenubarPromo_2_layout_10002_theme_1: 'MenubarPromo_2_layout_10002_theme_1',
  MenubarLogin_1_layout_10002_theme_1: 'MenubarLogin_1_layout_10002_theme_1',
  MenubarLogin_2_layout_10002_theme_1: 'MenubarLogin_2_layout_10002_theme_1',
  MenubarReferral_1_layout_10002_theme_1: 'MenubarReferral_1_layout_10002_theme_1',
  MenubarReferral_2_layout_10002_theme_1: 'MenubarReferral_2_layout_10002_theme_1',
  MenubarDeposit_1_layout_10002_theme_1: 'MenubarDeposit_1_layout_10002_theme_1',
  MenubarDeposit_2_layout_10002_theme_1: 'MenubarDeposit_2_layout_10002_theme_1',
  MenubarMine_1_layout_10002_theme_1: 'MenubarMine_1_layout_10002_theme_1',
  MenubarMine_2_layout_10002_theme_1: 'MenubarMine_2_layout_10002_theme_1',
  DrawerMenuRecords: 'DrawerMenuRecords',
  DrawerMenuNetwork: 'DrawerMenuNetwork',
  DrawerMenuFaq: 'DrawerMenuFaq',
  DrawerMenuFaq2: 'DrawerMenuFaq2',
  DrawerMenuAgent: 'DrawerMenuAgent',
  Service: 'Service',
  Sports: 'Sports',
  Logout: 'Logout',
  Phone2: 'Phone2',
  Username: 'Username',
  Password: 'Password',
  ShowEye2: 'ShowEye2',
  HideEye2: 'HideEye2',
  MusicList: 'MusicList',
  NextMusic: 'NextMusic',
  PauseMusic: 'PauseMusic',
  PlayBack: 'PlayBack',
  PlayMusic: 'PlayMusic',
  Playorder: 'Playorder',
  PrevMusic: 'PrevMusic',
  ShufflePlay: 'ShufflePlay',
  Audiodownload: 'Audiodownload',
  Listening: 'Listening',
  loadingMp3: 'loadingMp3',
  Audiocheck: 'Audiocheck',
  Audiodel: 'Audiodel',
  Sound: 'Sound',
  Email3: 'Email3',
  Deposit: 'Deposit',
  Withdraw: 'Withdraw',
  Vip2: 'Vip2',
  Copy3: 'Copy3',
  Refresh: 'Refresh',
  GamesearchBack: 'GamesearchBack',
  Blog: 'Blog',
  ErrorTip: 'ErrorTip',
  MenubarHome_1_layout_10002_theme_2: 'MenubarHome_1_layout_10002_theme_2',
  MenubarHome_2_layout_10002_theme_2: 'MenubarHome_2_layout_10002_theme_2',
  MenubarPromo_1_layout_10002_theme_2: 'MenubarPromo_1_layout_10002_theme_2',
  MenubarPromo_2_layout_10002_theme_2: 'MenubarPromo_2_layout_10002_theme_2',
  MenubarLogin_1_layout_10002_theme_2: 'MenubarLogin_1_layout_10002_theme_2',
  MenubarLogin_2_layout_10002_theme_2: 'MenubarLogin_2_layout_10002_theme_2',
  MenubarReferral_1_layout_10002_theme_2: 'MenubarReferral_1_layout_10002_theme_2',
  MenubarReferral_2_layout_10002_theme_2: 'MenubarReferral_2_layout_10002_theme_2',
  MenubarDeposit_1_layout_10002_theme_2: 'MenubarDeposit_1_layout_10002_theme_2',
  MenubarDeposit_2_layout_10002_theme_2: 'MenubarDeposit_2_layout_10002_theme_2',
  MenubarMine_1_layout_10002_theme_2: 'MenubarMine_1_layout_10002_theme_2',
  MenubarMine_2_layout_10002_theme_2: 'MenubarMine_2_layout_10002_theme_2',
  Support: 'Support',
  Message: 'Message',
  Data: 'Data',
  Copy4: 'Copy4',
  Copy5: 'Copy5',
  Fee: 'Fee',
  Fund: 'Fund',
  Account: 'Account',
  Bets: 'Bets',
  ToInvite: 'ToInvite',
  Data2: 'Data2',
  Security: 'Security',
  Music: 'Music',
  Faq: 'Faq',
  GoOut: 'GoOut',
  BackArrow: 'BackArrow',
  Newdate1: 'Newdate1',
  Phone3: 'Phone3',
  Pwd2: 'Pwd2',
  Fa: 'Fa',
  SortTop: 'SortTop',
  SortBottom: 'SortBottom',
  SortLightBottom: 'SortLightBottom',
  SortLightTop: 'SortLightTop',
  RefreshBalance: 'RefreshBalance',
  MineBonus: 'MineBonus',
  Search2: 'Search2',
  Frshen: 'Frshen',
  Report: 'Report',
  // Sound2: 'Sound2',
  MenubarHome_1_layout_10002_theme_3: 'MenubarHome_1_layout_10002_theme_3',
  MenubarHome_2_layout_10002_theme_3: 'MenubarHome_2_layout_10002_theme_3',
  MenubarPromo_1_layout_10002_theme_3: 'MenubarPromo_1_layout_10002_theme_3',
  MenubarPromo_2_layout_10002_theme_3: 'MenubarPromo_2_layout_10002_theme_3',
  MenubarLogin_1_layout_10002_theme_3: 'MenubarLogin_1_layout_10002_theme_3',
  MenubarLogin_2_layout_10002_theme_3: 'MenubarLogin_2_layout_10002_theme_3',
  MenubarReferral_1_layout_10002_theme_3: 'MenubarReferral_1_layout_10002_theme_3',
  MenubarReferral_2_layout_10002_theme_3: 'MenubarReferral_2_layout_10002_theme_3',
  MenubarDeposit_1_layout_10002_theme_3: 'MenubarDeposit_1_layout_10002_theme_3',
  MenubarDeposit_2_layout_10002_theme_3: 'MenubarDeposit_2_layout_10002_theme_3',
  MenubarMine_1_layout_10002_theme_3: 'MenubarMine_1_layout_10002_theme_3',
  MenubarMine_2_layout_10002_theme_3: 'MenubarMine_2_layout_10002_theme_3',
  MenubarHome_1_layout_10002_theme_4: 'MenubarHome_1_layout_10002_theme_4',
  MenubarHome_2_layout_10002_theme_4: 'MenubarHome_2_layout_10002_theme_4',
  MenubarPromo_1_layout_10002_theme_4: 'MenubarPromo_1_layout_10002_theme_4',
  MenubarPromo_2_layout_10002_theme_4: 'MenubarPromo_2_layout_10002_theme_4',
  MenubarLogin_1_layout_10002_theme_4: 'MenubarLogin_1_layout_10002_theme_4',
  MenubarLogin_2_layout_10002_theme_4: 'MenubarLogin_2_layout_10002_theme_4',
  MenubarReferral_1_layout_10002_theme_4: 'MenubarReferral_1_layout_10002_theme_4',
  MenubarReferral_2_layout_10002_theme_4: 'MenubarReferral_2_layout_10002_theme_4',
  MenubarDeposit_1_layout_10002_theme_4: 'MenubarDeposit_1_layout_10002_theme_4',
  MenubarDeposit_2_layout_10002_theme_4: 'MenubarDeposit_2_layout_10002_theme_4',
  MenubarMine_1_layout_10002_theme_4: 'MenubarMine_1_layout_10002_theme_4',
  MenubarMine_2_layout_10002_theme_4: 'MenubarMine_2_layout_10002_theme_4',

  MenubarHome_1_layout_10002_theme_5: 'MenubarHome_1_layout_10002_theme_5',
  MenubarHome_2_layout_10002_theme_5: 'MenubarHome_2_layout_10002_theme_5',
  MenubarPromo_1_layout_10002_theme_5: 'MenubarPromo_1_layout_10002_theme_5',
  MenubarPromo_2_layout_10002_theme_5: 'MenubarPromo_2_layout_10002_theme_5',
  MenubarLogin_1_layout_10002_theme_5: 'MenubarLogin_1_layout_10002_theme_5',
  MenubarLogin_2_layout_10002_theme_5: 'MenubarLogin_2_layout_10002_theme_5',
  MenubarReferral_1_layout_10002_theme_5: 'MenubarReferral_1_layout_10002_theme_5',
  MenubarReferral_2_layout_10002_theme_5: 'MenubarReferral_2_layout_10002_theme_5',
  MenubarDeposit_1_layout_10002_theme_5: 'MenubarDeposit_1_layout_10002_theme_5',
  MenubarDeposit_2_layout_10002_theme_5: 'MenubarDeposit_2_layout_10002_theme_5',
  MenubarMine_1_layout_10002_theme_5: 'MenubarMine_1_layout_10002_theme_5',
  MenubarMine_2_layout_10002_theme_5: 'MenubarMine_2_layout_10002_theme_5',
  MenuBarHomeChoose: 'MenuBarHomeChoose',
  MenuBarHomeDefault: 'MenuBarHomeDefault',
  MenuBarLoginChoose: 'MenuBarLoginChoose',
  MenuBarLoginDefault: 'MenuBarLoginDefault',
  MenuBarDepositDefault: 'MenuBarDepositDefault',
  MenuBarDepositChoose: 'MenuBarDepositChoose',
  MenuBarMessageDefault: 'MenuBarMessageDefault',
  MenuBarMessageChoose: 'MenuBarMessageChoose',
  MenuBarMyAccountDefault: 'MenuBarMyAccountDefault',
  MenuBarMyAccountChoose: 'MenuBarMyAccountChoose',
  MenuBarPromotionDefault: 'MenuBarPromotionDefault',
  MenuBarPromotionChoose: 'MenuBarPromotionChoose',
  MenuBarReferralDefault: 'MenuBarReferralDefault',
  MenuBarReferralChoose: 'MenuBarReferralChoose',
  MenuBarSportsDefault: 'MenuBarSportsDefault',
  MenuBarSportsChoose: 'MenuBarSportsChoose',
  MenuBarSupportDefault: 'MenuBarSupportDefault',
  MenuBarSupportChoose: 'MenuBarSupportChoose',
  MenuBarWithdrawDefault: 'MenuBarWithdrawDefault',
  MenuBarWithdrawChoose: 'MenuBarWithdrawChoose',
  MaintainProvider: 'MaintainProvider',
  WhatsApp: 'WhatsApp',
  Sms: 'Sms',
  NoData: 'NoData',
  CopyZ: 'CopyZ',
  USDT: 'USDT',
  BTC: 'BTC',
  ETH: 'ETH',
  TRX: 'TRX',
  USDC: 'USDC',
  BRZ: 'BRZ',
  ArrowBottom: 'ArrowBottom',
  ErrorTip2: 'ErrorTip2',
  SuccessTip: 'SuccessTip',
  ErrorTip3: 'ErrorTip3',
  SuccessTip2: 'SuccessTip2',
  ToastInfo: 'ToastInfo',
  Wallet2: 'Wallet2',
  AccountCard: 'AccountCard',
  AccountCard2: 'AccountCard2',
  Account2: 'Account2',
  Pix2: 'Pix2',
  DetailsLoading: 'DetailsLoading',
  DetailsError: 'DetailsError',
  Refresh2: 'Refresh2',
  PixSetting: 'PixSetting',
  Menu: 'Menu',
  MenuEvents: 'MenuEvents',
  MenuVip: 'MenuVip',
  MenuRakeBack: 'MenuRakeBack',
  MenuSecurity: 'MenuSecurity',
  MenuData: 'MenuData',
  MenuAccount: 'MenuAccount',
  MenuBets: 'MenuBets',
  MenuReport: 'MenuReport',
  MenuPending: 'MenuPending',
  MenuWithdraw: 'MenuWithdraw',
  MenuHistory: 'MenuHistory',
  MenuGiftMoney: 'MenuGiftMoney',
  MenuGiftMoney2: 'MenuGiftMoney2',
  Menu2: 'Menu2',
  MenuEvents2: 'MenuEvents2',
  MenuVip2: 'MenuVip2',
  MenuRakeBack2: 'MenuRakeBack2',
  MenuSecurity2: 'MenuSecurity2',
  MenuData2: 'MenuData2',
  MenuAccount2: 'MenuAccount2',
  MenuBets2: 'MenuBets2',
  MenuReport2: 'MenuReport2',
  MenuPending2: 'MenuPending2',
  MenuWithdraw2: 'MenuWithdraw2',
  MenuHistory2: 'MenuHistory2',
  MenuBlog: 'MenuBlog',
  MenuMessage: 'MenuMessage',
  MenuFaq: 'MenuFaq',
  MenuService: 'MenuService',
  MenuFb: 'MenuFb',
  MenuGg: 'MenuGg',
  MenuTg: 'MenuTg',
  ProportionCategory_1: 'ProportionCategory_1',
  ProportionCategory_2: 'ProportionCategory_2',
  ProportionCategory_3: 'ProportionCategory_3',
  ProportionCategory_4: 'ProportionCategory_4',
  ProportionCategory_5: 'ProportionCategory_5',
  ProportionCategory_6: 'ProportionCategory_6',
  ProportionCategory_999: 'ProportionCategory_999',
  SearchLoading: 'SearchLoading',
  GiftBox: 'GiftBox',
  WithdrawArrow: 'WithdrawArrow',
  ButtonPrev: 'ButtonPrev',
  Union: 'Union',
  DubbleArrow: 'DubbleArrow',
  Fast: 'Fast',
  Activated: 'Activated',
  PwaDownload: 'PwaDownload',
  PwaWarning: 'PwaWarning',
  Tip: 'Tip',
  PIX: 'PIX',
  PhoneOnline: 'PhoneOnline',
  Bubble: 'Bubble',
  SelectMask: 'SelectMask',
  PIXLogo: 'PIXLogo',
  SignInClose: 'SignInClose',
  Registeraccount: 'Registeraccount',
  Registercountry: 'Registercountry',
  Registeruser: 'Registeruser',
  Registerlock: 'Registerlock',
  Broadcast: 'Broadcast',
  Newlogin: 'Newlogin',
  HeaderWalletArrow: 'HeaderWalletArrow',
  Sound2: 'Sound2',
  Sound2_1: 'Sound2_1',
  Sound2_2: 'Sound2_2',
  Sound2_3: 'Sound2_3',
  Sound2_2_opacity: 'Sound2_2_opacity',
  Sound2_3_opacity: 'Sound2_3_opacity',
  HeaderSearch: 'HeaderSearch',
  HeaderMusic: 'HeaderMusic',
  HeaderSearch2: 'HeaderSearch2',
  HeaderMusic2: 'HeaderMusic2',
  HeaderCountry: 'HeaderCountry',
  VipChecked: 'VipChecked',
  RightArrow2: 'RightArrow2',
  Checkbox: 'Checkbox',
  CheckedBox: 'CheckedBox',
  BtntoTop: 'BtntoTop',
  Email5: 'Email5',
  Deposit2: 'Deposit2',
  Withdraw2: 'Withdraw2',
  Vip3: 'Vip3',
  NewhideEye: 'NewhideEye',
  NewShowEye: 'NewShowEye',
  FacebookWG: 'FacebookWG',
  GoogleWG: 'GoogleWG',
  TelegramWG: 'TelegramWG',
  MetamaskWG: 'MetamaskWG',
  Hot: 'Hot',
  ClearStr: 'ClearStr',
  Cpf: 'Cpf',
  Cpf2: 'Cpf2',
  MissionTable1: 'MissionTable1',
  SingleArrow: 'SingleArrow',
  EventCheck: 'EventCheck',
  Favorite: 'Favorite',
  Rencent: 'Rencent',
  Rocket: 'Rocket',
  Fire: 'Fire',
  TournamentTitleIcon: 'TournamentTitleIcon',
  CurrentLevel: 'CurrentLevel',
  Eventstime: 'Eventstime',
  Finish: 'Finish',
  Gametype_1: 'Gametype_1',
  Gametype_2: 'Gametype_2',
  Gametype_3: 'Gametype_3',
  Gametype_4: 'Gametype_4',
  Gametype_5: 'Gametype_5',
  Gametype_6: 'Gametype_6',
  Gametype_9999: 'Gametype_9999',
  Redeem: 'Redeem',
  RedeemLight: 'RedeemLight',
  InputClear: 'InputClear',
  Checked1: 'Checked1',
  Checked3: 'Checked3',
  RedDot: 'RedDot',
  FirstDeposit: 'FirstDeposit',
  TotalDeposit: 'TotalDeposit',
  LiquiTime: 'LiquiTime',
  MysteryBonus: 'MysteryBonus',
  DateDay: 'DateDay',
  Copy7: 'Copy7',
  Menu3: 'Menu3',
  HaderWallet: 'HaderWallet',
  Language: 'Language',
  Message1: 'Message1',
  CategoryGameHeaderArrow: 'CategoryGameHeaderArrow',
  BacktoTop1: 'BacktoTop1',
  Apple: 'Apple',
  Android: 'Android',
  BtnBack: 'BtnBack',
  MineRefresh: 'MineRefresh',
  AccountDeposit: 'AccountDeposit',
  AccountWithdraw: 'AccountWithdraw',
  AccountEmail: 'AccountEmail',
  AccountCopy: 'AccountCopy',
  ReferralCashbackInvite: 'ReferralCashbackInvite',
  CloseEye: 'CloseEye',
  OpenEye: 'OpenEye',
};

const Icon = ({ name }) => {
  switch (name) {
    case IconName.CopyZ:
      return <SvgIcon iconId={CopyZ} />;
    case IconName.USDT:
      return <SvgIcon iconId={USDT} />;
    case IconName.BTC:
      return <SvgIcon iconId={BTC} />;
    case IconName.ETH:
      return <SvgIcon iconId={ETH} />;
    case IconName.TRX:
      return <SvgIcon iconId={TRX} />;
    case IconName.USDC:
      return <SvgIcon iconId={USDC} />;
    case IconName.BRZ:
      return <SvgIcon iconId={BRZ} />;
    case IconName.MessageNew:
      return <SvgIcon iconId={MessageNew} fill="currentColor" />;
    case IconName.EventCheck:
      return <SvgIcon iconId={EventCheck} fill="currentColor" />;
    case IconName.Eventstime:
      return <SvgIcon iconId={Eventstime} fill="currentColor" />;
    case IconName.Finish:
      return <SvgIcon iconId={Finish} fill="currentColor" />;
    case IconName.GiftBox:
      return <SvgIcon iconId={GiftBox} fill="currentColor" />;
    case IconName.WithdrawArrow:
      return <SvgIcon iconId={WithdrawArrow} fill="currentColor" />;
    case IconName.Fast:
      return <SvgIcon iconId={Fast} fill="currentColor" />;
    case IconName.Activated:
      return <SvgIcon iconId={Activated} fill="currentColor" />;
    case IconName.PwaDownload:
      return <SvgIcon iconId={PwaDownload} fill="currentColor" />;
    case IconName.PwaWarning:
      return <SvgIcon iconId={PwaWarning} fill="currentColor" />;
    case IconName.Tip:
      return <SvgIcon iconId={Tip} fill="currentColor" />;
    case IconName.HideEye:
      return <SvgIcon fill="currentColor" iconId={HideEye} />;
    case IconName.ShowEye:
      return <SvgIcon fill="currentColor" iconId={ShowEye} />;
    case IconName.Copys:
      return <SvgIcon fill="currentColor" iconId={Copys} />;
    case IconName.WagerCopy:
      return <SvgIcon iconId={WagerCopy} />;
    case IconName.ChangeName:
      return <SvgIcon iconId={ChangeName} />;
    case IconName.NewBack:
      return <SvgIcon stroke="currentColor" iconId={NewBack} />;
    case IconName.Emails:
      return <SvgIcon iconId={Emails} />;
    case IconName.NewDate:
      return <SvgIcon iconId={NewDate} />;
    case IconName.Phone:
      return <SvgIcon stroke="currentColor" fill="currentColor" iconId={Phone} />;
    case IconName.Phone4:
      return <SvgIcon iconId={Phone4} />;
    case IconName.WidthdrawQuestion:
      return <SvgIcon iconId={WidthdrawQuestion} />;
    case IconName.WidthdrawQuestion2:
      return <SvgIcon iconId={WidthdrawQuestion2} />;
    case IconName.WalltMsg:
      return <SvgIcon iconId={WalltMsg} />;
    case IconName.WalltMsg2:
      return <SvgIcon iconId={WalltMsg2} />;
    case IconName.RegisterCode:
      return <SvgIcon stroke="currentColor" iconId={RegisterCode} />;
    case IconName.RegisterCode2:
      return <SvgIcon iconId={RegisterCode2} />;
    case IconName.LoginPwd:
      return <SvgIcon fill="currentColor" iconId={LoginPwd} />;
    case IconName.LoginPwd2:
      return <SvgIcon iconId={LoginPwd2} />;
    case IconName.LoginAccount:
      return <SvgIcon iconId={LoginAccount} />;
    case IconName.Close:
      return <SvgIcon iconId={Close} stroke="currentColor" />;
    case IconName.Play:
      return <SvgIcon iconId={Play} />;
    case IconName.TG:
      return <SvgIcon iconId={Tg} fill="currentColor" />;
    case IconName.BottomArrow:
      return <SvgIcon iconId={BottomArrow} isFill={false} />;
    case IconName.SignIn:
      return <SvgIcon iconId={SignIn} />;
    case IconName.Search:
      return <SvgIcon iconId={Search} />;
    case IconName.RightArrow:
      return <SvgIcon iconId={RightArrow} isFill={false} />;
    case IconName.Hide:
      return <SvgIcon iconId={Hide} />;
    case IconName.Show:
      return <SvgIcon iconId={Show} />;
    case IconName.Gmail:
      return <SvgIcon iconId={Gmail} />;
    case IconName.MetaMask:
      return <SvgIcon iconId={MetaMask} />;
    case IconName.Telegram:
      return <SvgIcon iconId={Telegram} />;
    case IconName.FullScreen:
      return <SvgIcon iconId={FullScreen} />;
    case IconName.Email:
      return <SvgIcon stroke="currentColor" iconId={Email} />;
    case IconName.Email4:
      return <SvgIcon iconId={Email4} />;
    case IconName.Wallet:
      return <SvgIcon iconId={Wallet} />;
    case IconName.Copy:
      return <SvgIcon iconId={Copy} />;
    case IconName.Facebook:
      return <SvgIcon iconId={Facebook} />;
    case IconName.WhatsappShare:
      return <SvgIcon iconId={WhatsappShare} />;
    case IconName.Checked2:
      return <SvgIcon iconId={Checked2} stroke="currentColor" />;
    case IconName.Copy2:
      return <SvgIcon iconId={Copy2} fill="currentColor" />;
    case IconName.CurrentVip:
      return <SvgIcon iconId={CurrentVip} fill="currentColor" />;
    case IconName.Goback:
      return <SvgIcon iconId={Goback} />;
    case IconName.Vip:
      return <SvgIcon iconId={Vip} />;
    case IconName.Vipupgrade:
      return <SvgIcon iconId={Vipupgrade} fill="currentColor" />;
    case IconName.User:
      return <SvgIcon iconId={User} />;
    case IconName.Pwd:
      return <SvgIcon iconId={Pwd} />;
    case IconName.Heart1:
      return <SvgIcon iconId={Heart1} fill="currentColor" />;
    case IconName.Heart2:
      return <SvgIcon iconId={Heart2} fill="currentColor" />;
    case IconName.Close2:
      return <SvgIcon iconId={Close2} fill="currentColor" />;
    case IconName.Lock:
      return <SvgIcon iconId={Lock} />;
    case IconName.Newuser:
      return <SvgIcon iconId={Newuser} />;
    case IconName.NerPwd:
      return <SvgIcon iconId={NerPwd} />;
    case IconName.Newandroid:
      return <SvgIcon iconId={Newandroid} />;
    case IconName.Newappstoroe:
      return <SvgIcon iconId={Newappstoroe} />;
    case IconName.Useraccount:
      return <SvgIcon iconId={Useraccount} fill="currentColor" />;
    case IconName.Userconversion:
      return <SvgIcon iconId={Userconversion} />;
    case IconName.Userwallet:
      return <SvgIcon iconId={Userwallet} />;
    case IconName.Usertrabsactions:
      return <SvgIcon iconId={Usertrabsactions} />;
    case IconName.TablebarRewards:
      return <SvgIcon iconId={TablebarRewards} />;
    case IconName.Plus182:
      return <SvgIcon iconId={Plus182} fill="currentColor" />;
    case IconName.Info:
      return <SvgIcon iconId={Info} fill="currentColor" />;
    case IconName.BannerPrev:
      return <SvgIcon iconId={BannerPrev} />;
    case IconName.BannerNext:
      return <SvgIcon iconId={BannerNext} />;
    case IconName.BannerNext2:
      return <SvgIcon iconId={BannerNext2} stroke="currentColor" />;
    case IconName.TagsSearch:
      return <SvgIcon fill="currentColor" iconId={TagsSearch} />;
    case IconName.ArrowsRight:
      return <SvgIcon iconId={ArrowsRight} fill="currentColor" />;
    case IconName.ArrowsTop:
      return <SvgIcon iconId={ArrowsTop} fill="currentColor" />;
    case IconName.ReferPictureArrow:
      return <SvgIcon iconId={ReferPictureArrow} fill="currentColor" />;
    case IconName.Brmenuback:
      return <SvgIcon iconId={Brmenuback} />;
    case IconName.Refer:
      return <SvgIcon iconId={Refer} />;
    case IconName.ChatArraw:
      return <SvgIcon iconId={ChatArraw} stroke="currentColor" />;
    case IconName.SiteMailNoRead:
      return <SvgIcon iconId={SiteMailNoRead} fill="currentColor" />;
    case IconName.SiteMailNoRead2:
      return <SvgIcon iconId={SiteMailNoRead2} fill="currentColor" />;
    case IconName.SiteMailReaded:
      return <SvgIcon iconId={SiteMailReaded} fill="currentColor" />;
    case IconName.HelpRight:
      return <SvgIcon iconId={HelpRight} />;
    case IconName.DrawerFlag:
      return <SvgIcon iconId={DrawerFlag} />;
    case IconName.MenuBarHome:
      return <SvgIcon iconId={MenuBarHome} fill="currentColor" />;
    case IconName.MenuBarRefer:
      return <SvgIcon iconId={MenuBarRefer} fill="currentColor" />;
    case IconName.MenuBarPromo:
      return <SvgIcon iconId={MenuBarPromo} />;
    case IconName.MenuBarEmail:
      return <SvgIcon iconId={MenuBarEmail} fill="currentColor" />;
    case IconName.MenuBarMine:
      return <SvgIcon iconId={MenuBarMine} fill="currentColor" />;
    case IconName.MenuBarDeposit:
      return <SvgIcon iconId={MenuBarDeposit} />;
    case IconName.MenuBarService:
      return <SvgIcon iconId={MenuBarService} />;
    case IconName.MenuBarService2:
      return <SvgIcon iconId={MenuBarService2} fill="currentColor" />;
    case IconName.DrawerMenuPromos:
      return <SvgIcon iconId={DrawerMenuPromos} />;
    case IconName.DrawerMenuService:
      return <SvgIcon iconId={DrawerMenuService} />;
    case IconName.LangDefault:
      return <SvgIcon iconId={LangDefault} />;
    case IconName.LangEnglish:
      return <SvgIcon iconId={LangEnglish} />;
    case IconName.LangFilipino:
      return <SvgIcon iconId={LangFilipino} />;
    case IconName.LangProtugues:
      return <SvgIcon iconId={LangProtugues} />;
    case IconName.LangJapanese:
      return <SvgIcon iconId={LangJapanese} />;
    case IconName.LangVi:
      return <SvgIcon iconId={LangVi} />;
    case IconName.LangTh:
      return <SvgIcon iconId={LangTh} />;
    case IconName.LangMs:
      return <SvgIcon iconId={LangMs} />;
    case IconName.LangHi:
      return <SvgIcon iconId={LangHi} />;
    case IconName.LangEs:
      return <SvgIcon iconId={LangEs} />;
    case IconName.LangId:
      return <SvgIcon iconId={LangId} />;
    case IconName.Freshen:
      return <SvgIcon fill="currentColor" iconId={Freshen} />;
    case IconName.WalletRight:
      return <SvgIcon stroke="currentColor" iconId={WalletRight} />;
    case IconName.Vector:
      return <SvgIcon iconId={Vector} />;
    case IconName.ManMade:
      return <SvgIcon iconId={ManMade} />;
    case IconName.UserAccount:
      return <SvgIcon fill="currentColor" iconId={UserAccount} />;
    case IconName.UserSound:
      return <SvgIcon iconId={UserSound} />;
    case IconName.UserInbox:
      return <SvgIcon iconId={UserInbox} />;
    case IconName.UserPhone:
      return <SvgIcon fill="currentColor" iconId={UserPhone} />;
    case IconName.UserLogout:
      return <SvgIcon iconId={UserLogout} />;
    case IconName.RightArrow3:
      return <SvgIcon iconId={RightArrow3} fill="currentColor" stroke="currentColor" />;
    case IconName.DownLoad:
      return <SvgIcon iconId={DownLoad} fill="currentColor" stroke="currentColor" />;
    case IconName.DownLoad2:
      return <SvgIcon iconId={DownLoad2} fill="currentColor" stroke="currentColor" />;
    case IconName.Print2:
      return <SvgIcon iconId={Print2} fill="currentColor" stroke="currentColor" />;
    case IconName.DrawDownLoad:
      return <SvgIcon iconId={DrawDownLoad} />;
    case IconName.FillCopy:
      return <SvgIcon iconId={FillCopy} fill="currentColor" />;
    case IconName.GameBack:
      return <SvgIcon iconId={GameBack} fill="currentColor" />;
    case IconName.HomeBtn:
      return <SvgIcon iconId={HomeBtn} fill="currentColor" />;
    case IconName.FacebookShare:
      return <SvgIcon iconId={FacebookShare} fill="currentColor" />;
    case IconName.TelegramShare:
      return <SvgIcon iconId={TelegramShare} fill="currentColor" />;
    case IconName.TwitterShare:
      return <SvgIcon iconId={TwitterShare} fill="currentColor" />;
    case IconName.MoreShare:
      return <SvgIcon iconId={MoreShare} fill="currentColor" />;
    case IconName.MenuCasino: {
      return <SvgIcon iconId={MenuCasino} />;
    }
    case IconName.MenuSport:
      return <SvgIcon iconId={MenuSport} />;
    case IconName.Explain:
      return <SvgIcon iconId={Explain} fill="currentColor" />;
    case IconName.Record:
      return <SvgIcon iconId={Record} fill="currentColor" />;
    case IconName.Record2:
      return <SvgIcon iconId={Record2} fill="currentColor" />;
    case IconName.Copy6:
      return <SvgIcon iconId={Copy6} fill="currentColor" />;
    case IconName.LuckyWheel:
      return <SvgIcon iconId={LuckyWheel} fill="currentColor" />;
    case IconName.RefreshTime:
      return <SvgIcon iconId={RefreshTime} fill="currentColor" />;
    case IconName.Brl:
      return <SvgIcon iconId={Brl} fill="currentColor" />;
    case IconName.Complete:
      return <SvgIcon iconId={Complete} fill="currentColor" />;
    case IconName.Provider:
      return <SvgIcon iconId={Provider} fill="currentColor" />;
    case IconName.Announcementarrow:
      return <SvgIcon iconId={Announcementarrow} fill="currentColor" />;
    case IconName.Announcementarrow1:
      return <SvgIcon iconId={Announcementarrow1} fill="currentColor" />;
    case IconName.AnnouncementarrowLeft:
      return <SvgIcon iconId={AnnouncementarrowLeft} fill="currentColor" />;
    case IconName.AnnouncementarrowRight:
      return <SvgIcon iconId={AnnouncementarrowRight} fill="currentColor" />;
    case IconName.AnnouncementChecked:
      return <SvgIcon iconId={AnnouncementChecked} fill="currentColor" />;
    case IconName.GiftMoney:
      return <SvgIcon iconId={GiftMoney} />;
    case IconName.PilotWheelCheck:
      return <SvgIcon iconId={PilotWheelCheck} />;
    case IconName.Bulletin:
      return <SvgIcon iconId={Bulletin} />;
    case IconName.Countdown:
      return <SvgIcon iconId={Countdown} stroke="currentColor" />;
    case IconName.SearchArrow:
      return <SvgIcon iconId={SearchArrow} stroke="currentColor" />;
    case IconName.DrawerSports:
      return <SvgIcon iconId={DrawerSports} stroke="currentColor" />;
    case IconName.DrawerPromotions:
      return <SvgIcon iconId={DrawerPromotions} stroke="currentColor" />;
    case IconName.DrawerLang:
      return <SvgIcon iconId={DrawerLang} stroke="currentColor" />;
    case IconName.DrawerDownload:
      return <SvgIcon iconId={DrawerDownload} stroke="currentColor" />;
    case IconName.DrawerDownload2:
      return <SvgIcon iconId={DrawerDownload2} />;
    case IconName.Email2:
      return <SvgIcon iconId={Email2} />;
    case IconName.Share:
      return <SvgIcon iconId={Share} fill="currentColor" />;
    case IconName.DrawerFlag2:
      return <SvgIcon iconId={DrawerFlag2} stroke="currentColor" />;
    case IconName.DrawerFlag3:
      return <SvgIcon iconId={DrawerFlag3} fill="currentColor" />;
    case IconName.MenubarHome_1_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarHome_1_layout_10002_theme_1} />;
    case IconName.MenubarHome_2_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarHome_2_layout_10002_theme_1} />;
    case IconName.MenubarPromo_1_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarPromo_1_layout_10002_theme_1} />;
    case IconName.MenubarPromo_2_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarPromo_2_layout_10002_theme_1} />;
    case IconName.MenubarLogin_1_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarLogin_1_layout_10002_theme_1} />;
    case IconName.MenubarLogin_2_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarLogin_2_layout_10002_theme_1} />;
    case IconName.MenubarReferral_1_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarReferral_1_layout_10002_theme_1} />;
    case IconName.MenubarReferral_2_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarReferral_2_layout_10002_theme_1} />;
    case IconName.MenubarDeposit_1_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarDeposit_1_layout_10002_theme_1} />;
    case IconName.MenubarDeposit_2_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarDeposit_2_layout_10002_theme_1} />;
    case IconName.MenubarMine_1_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarMine_1_layout_10002_theme_1} />;
    case IconName.MenubarMine_2_layout_10002_theme_1:
      return <SvgIcon iconId={MenubarMine_2_layout_10002_theme_1} />;
    case IconName.DrawerMenuRecords:
      return <SvgIcon iconId={DrawerMenuRecords} />;
    case IconName.DrawerMenuNetwork:
      return <SvgIcon iconId={DrawerMenuNetwork} />;
    case IconName.DrawerMenuFaq:
      return <SvgIcon iconId={DrawerMenuFaq} />;
    case IconName.DrawerMenuFaq2:
      return <SvgIcon iconId={DrawerMenuFaq2} fill="currentColor" />;
    case IconName.DrawerMenuAgent:
      return <SvgIcon iconId={DrawerMenuAgent} fill="currentColor" />;
    case IconName.Service:
      return <SvgIcon iconId={Service} />;
    case IconName.Sports:
      return <SvgIcon iconId={Sports} />;
    case IconName.Logout:
      return <SvgIcon iconId={Logout} stroke="currentColor" />;
    case IconName.Phone2:
      return <SvgIcon iconId={Phone2} stroke="currentColor" />;
    case IconName.Username:
      return <SvgIcon iconId={Username} stroke="currentColor" />;
    case IconName.Password:
      return <SvgIcon iconId={Password} stroke="currentColor" />;
    case IconName.ShowEye2:
      return <SvgIcon iconId={ShowEye2} stroke="currentColor" />;
    case IconName.HideEye2:
      return <SvgIcon iconId={HideEye2} stroke="currentColor" />;
    case IconName.MusicList:
      return <SvgIcon iconId={MusicList} stroke="currentColor" />;
    case IconName.NextMusic:
      return <SvgIcon iconId={NextMusic} stroke="currentColor" />;
    case IconName.PauseMusic:
      return <SvgIcon iconId={PauseMusic} stroke="currentColor" />;
    case IconName.PlayBack:
      return <SvgIcon iconId={PlayBack} stroke="currentColor" />;
    case IconName.PlayMusic:
      return <SvgIcon iconId={PlayMusic} stroke="currentColor" />;
    case IconName.Playorder:
      return <SvgIcon iconId={Playorder} stroke="currentColor" />;
    case IconName.PrevMusic:
      return <SvgIcon iconId={PrevMusic} stroke="currentColor" />;
    case IconName.ShufflePlay:
      return <SvgIcon iconId={ShufflePlay} stroke="currentColor" />;
    case IconName.Audiodownload:
      return <SvgIcon iconId={Audiodownload} stroke="currentColor" />;
    case IconName.Listening:
      return <SvgIcon iconId={Listening} stroke="currentColor" />;
    case IconName.loadingMp3:
      return <SvgIcon iconId={loadingMp3} stroke="currentColor" />;
    case IconName.Audiocheck:
      return <SvgIcon iconId={Audiocheck} stroke="currentColor" />;
    case IconName.Audiodel:
      return <SvgIcon iconId={Audiodel} stroke="currentColor" />;
    case IconName.Sound:
      return <SvgIcon iconId={Sound} />;
    case IconName.Email3:
      return <SvgIcon iconId={Email3} />;
    case IconName.Deposit:
      return <SvgIcon iconId={Deposit} fill="currentColor" />;
    case IconName.Withdraw:
      return <SvgIcon iconId={Withdraw} fill="currentColor" />;
    case IconName.Vip2:
      return <SvgIcon iconId={Vip2} />;
    case IconName.Copy3:
      return <SvgIcon iconId={Copy3} />;
    case IconName.Refresh:
      return <SvgIcon iconId={Refresh} />;
    case IconName.Blog:
      return <SvgIcon iconId={Blog} />;
    case IconName.BackArrow:
      return <SvgIcon iconId={BackArrow} stroke="currentColor" />;
    case IconName.Newdate1:
      return <SvgIcon iconId={Newdate1} fill="currentColor" />;
    case IconName.SortBottom:
      return <SvgIcon iconId={SortBottom} fill="currentColor" />;
    case IconName.SortLightTop:
      return <SvgIcon iconId={SortLightTop} fill="currentColor" />;
    case IconName.RefreshBalance:
      return <SvgIcon iconId={RefreshBalance} fill="currentColor" />;
    case IconName.MineBonus:
      return <SvgIcon iconId={MineBonus} fill="currentColor" />;
    case IconName.SortLightBottom:
      return <SvgIcon iconId={SortLightBottom} fill="currentColor" />;
    case IconName.SortTop:
      return <SvgIcon iconId={SortTop} />;
    case IconName.GamesearchBack:
      return <SvgIcon iconId={GamesearchBack} stroke="currentColor" />;
    case IconName.ErrorTip:
      return <SvgIcon iconId={ErrorTip} fill="currentColor" />;
    case IconName.MenubarHome_1_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarHome_1_layout_10002_theme_2} />;
    case IconName.MenubarHome_2_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarHome_2_layout_10002_theme_2} />;
    case IconName.MenubarPromo_1_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarPromo_1_layout_10002_theme_2} />;
    case IconName.MenubarPromo_2_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarPromo_2_layout_10002_theme_2} />;
    case IconName.MenubarLogin_1_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarLogin_1_layout_10002_theme_2} />;
    case IconName.MenubarLogin_2_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarLogin_2_layout_10002_theme_2} />;
    case IconName.MenubarReferral_1_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarReferral_1_layout_10002_theme_2} />;
    case IconName.MenubarReferral_2_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarReferral_2_layout_10002_theme_2} />;
    case IconName.MenubarDeposit_1_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarDeposit_1_layout_10002_theme_2} />;
    case IconName.MenubarDeposit_2_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarDeposit_2_layout_10002_theme_2} />;
    case IconName.MenubarMine_1_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarMine_1_layout_10002_theme_2} />;
    case IconName.MenubarMine_2_layout_10002_theme_2:
      return <SvgIcon iconId={MenubarMine_2_layout_10002_theme_2} />;
    case IconName.Support:
      return <SvgIcon iconId={Support} fill="currentColor" />;
    case IconName.Message:
      return <SvgIcon iconId={Message} fill="currentColor" />;
    case IconName.Data:
      return <SvgIcon iconId={Data} fill="currentColor" />;
    case IconName.Copy4:
      return <SvgIcon iconId={Copy4} fill="currentColor" />;
    case IconName.Copy5:
      return <SvgIcon iconId={Copy5} fill="currentColor" />;
    case IconName.Fee:
      return <SvgIcon iconId={Fee} fill="currentColor" />;
    case IconName.Fund:
      return <SvgIcon iconId={Fund} fill="currentColor" />;
    case IconName.Account:
      return <SvgIcon iconId={Account} fill="currentColor" />;
    case IconName.Bets:
      return <SvgIcon iconId={Bets} fill="currentColor" />;
    case IconName.ToInvite:
      return <SvgIcon iconId={ToInvite} fill="currentColor" />;
    case IconName.Data2:
      return <SvgIcon iconId={Data2} fill="currentColor" />;
    case IconName.Security:
      return <SvgIcon iconId={Security} fill="currentColor" />;
    case IconName.Music:
      return <SvgIcon iconId={Music} fill="currentColor" />;
    case IconName.Faq:
      return <SvgIcon iconId={Faq} fill="currentColor" />;
    case IconName.GoOut:
      return <SvgIcon iconId={GoOut} fill="currentColor" />;
    case IconName.Phone3:
      return <SvgIcon iconId={Phone3} fill="currentColor" />;
    case IconName.Pwd2:
      return <SvgIcon iconId={Pwd2} fill="currentColor" />;
    case IconName.Fa:
      return <SvgIcon iconId={Fa} fill="currentColor" />;
    case IconName.Search2:
      return <SvgIcon iconId={Search2} fill="currentColor" />;
    case IconName.Frshen:
      return <SvgIcon iconId={Frshen} fill="currentColor" />;
    case IconName.Report:
      return <SvgIcon iconId={Report} fill="currentColor" />;
    // case IconName.Sound2:
    //   return <SvgIcon iconId={Sound2} fill="currentColor" />;
    case IconName.MenubarHome_1_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarHome_1_layout_10002_theme_3} />;
    case IconName.MenubarHome_2_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarHome_2_layout_10002_theme_3} />;
    case IconName.MenubarPromo_1_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarPromo_1_layout_10002_theme_3} />;
    case IconName.MenubarPromo_2_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarPromo_2_layout_10002_theme_3} />;
    case IconName.MenubarLogin_1_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarLogin_1_layout_10002_theme_3} />;
    case IconName.MenubarLogin_2_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarLogin_2_layout_10002_theme_3} />;
    case IconName.MenubarReferral_1_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarReferral_1_layout_10002_theme_3} />;
    case IconName.MenubarReferral_2_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarReferral_2_layout_10002_theme_3} />;
    case IconName.MenubarDeposit_1_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarDeposit_1_layout_10002_theme_3} />;
    case IconName.MenubarDeposit_2_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarDeposit_2_layout_10002_theme_3} />;
    case IconName.MenubarMine_1_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarMine_1_layout_10002_theme_3} />;
    case IconName.MenubarMine_2_layout_10002_theme_3:
      return <SvgIcon iconId={MenubarMine_2_layout_10002_theme_3} />;
    case IconName.MenubarHome_1_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarHome_1_layout_10002_theme_4} />;
    case IconName.MenubarHome_2_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarHome_2_layout_10002_theme_4} />;
    case IconName.MenubarPromo_1_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarPromo_1_layout_10002_theme_4} />;
    case IconName.MenubarPromo_2_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarPromo_2_layout_10002_theme_4} />;
    case IconName.MenubarLogin_1_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarLogin_1_layout_10002_theme_4} />;
    case IconName.MenubarLogin_2_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarLogin_2_layout_10002_theme_4} />;
    case IconName.MenubarReferral_1_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarReferral_1_layout_10002_theme_4} />;
    case IconName.MenubarReferral_2_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarReferral_2_layout_10002_theme_4} />;
    case IconName.MenubarDeposit_1_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarDeposit_1_layout_10002_theme_4} />;
    case IconName.MenubarDeposit_2_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarDeposit_2_layout_10002_theme_4} />;
    case IconName.MenubarMine_1_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarMine_1_layout_10002_theme_4} />;
    case IconName.MenubarMine_2_layout_10002_theme_4:
      return <SvgIcon iconId={MenubarMine_2_layout_10002_theme_4} />;
    case IconName.MenubarHome_1_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarHome_1_layout_10002_theme_5} />;
    case IconName.MenubarHome_2_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarHome_2_layout_10002_theme_5} />;
    case IconName.MenubarPromo_1_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarPromo_1_layout_10002_theme_5} />;
    case IconName.MenubarPromo_2_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarPromo_2_layout_10002_theme_5} />;
    case IconName.MenubarLogin_1_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarLogin_1_layout_10002_theme_5} />;
    case IconName.MenubarLogin_2_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarLogin_2_layout_10002_theme_5} />;
    case IconName.MenubarReferral_1_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarReferral_1_layout_10002_theme_5} />;
    case IconName.MenubarReferral_2_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarReferral_2_layout_10002_theme_5} />;
    case IconName.MenubarDeposit_1_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarDeposit_1_layout_10002_theme_5} />;
    case IconName.MenubarDeposit_2_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarDeposit_2_layout_10002_theme_5} />;
    case IconName.MenubarMine_1_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarMine_1_layout_10002_theme_5} />;
    case IconName.MenubarMine_2_layout_10002_theme_5:
      return <SvgIcon iconId={MenubarMine_2_layout_10002_theme_5} />;
    case IconName.MenuBarHomeDefault:
      return <SvgIcon iconId={MenuBarHomeDefault} />;
    case IconName.MenuBarHomeChoose:
      return <SvgIcon iconId={MenuBarHomeChoose} />;
    case IconName.MenuBarLoginDefault:
      return <SvgIcon iconId={MenuBarLogInDefault} />;
    case IconName.MenuBarLoginChoose:
      return <SvgIcon iconId={MenuBarLogInChoose} />;
    case IconName.MenuBarDepositDefault:
      return <SvgIcon iconId={MenuBarDepositDefault} />;
    case IconName.MenuBarDepositChoose:
      return <SvgIcon iconId={MenuBarDepositChoose} />;
    case IconName.MenuBarMessageDefault:
      return <SvgIcon iconId={MenuBarMessageDefault} />;
    case IconName.MenuBarMessageChoose:
      return <SvgIcon iconId={MenuBarMessageChoose} />;
    case IconName.MenuBarMyAccountDefault:
      return <SvgIcon iconId={MenuBarMyAccountDefault} />;
    case IconName.MenuBarMyAccountChoose:
      return <SvgIcon iconId={MenuBarMyAccountChoose} />;
    case IconName.MenuBarPromotionDefault:
      return <SvgIcon iconId={MenuBarPromotionDefault} />;
    case IconName.MenuBarPromotionChoose:
      return <SvgIcon iconId={MenuBarPromotionChoose} />;
    case IconName.MenuBarReferralDefault:
      return <SvgIcon iconId={MenuBarReferralDefault} />;
    case IconName.MenuBarReferralChoose:
      return <SvgIcon iconId={MenuBarReferralChoose} />;
    case IconName.MenuBarSportsDefault:
      return <SvgIcon iconId={MenuBarSportsDefault} />;
    case IconName.MenuBarSportsChoose:
      return <SvgIcon iconId={MenuBarSportsChoose} />;
    case IconName.MenuBarSupportDefault:
      return <SvgIcon iconId={MenuBarSupportDefault} />;
    case IconName.MenuBarSupportChoose:
      return <SvgIcon iconId={MenuBarSupportChoose} />;
    case IconName.MenuBarWithdrawDefault:
      return <SvgIcon iconId={MenuBarWithdrawDefault} />;
    case IconName.MenuBarWithdrawChoose:
      return <SvgIcon iconId={MenuBarWithdrawChoose} />;
    case IconName.MaintainProvider:
      return <SvgIcon iconId={MaintainProvider} />;
    case IconName.WhatsApp:
      return <SvgIcon iconId={WhatsApp} />;
    case IconName.Sms:
      return <SvgIcon iconId={Sms} />;
    case IconName.NoData:
      return <SvgIcon iconId={NoData} />;
    case IconName.ArrowBottom:
      return <SvgIcon iconId={ArrowBottom} />;
    case IconName.ErrorTip2:
      return <SvgIcon iconId={ErrorTip2} />;
    case IconName.SuccessTip:
      return <SvgIcon iconId={SuccessTip} />;
    case IconName.ErrorTip3:
      return <SvgIcon iconId={ErrorTip3} />;
    case IconName.SuccessTip2:
      return <SvgIcon iconId={SuccessTip2} />;
    case IconName.ToastInfo:
      return <SvgIcon iconId={ToastInfo} />;
    case IconName.Wallet2:
      return <SvgIcon iconId={Wallet2} />;
    case IconName.AccountCard:
      return <SvgIcon iconId={AccountCard} />;
    case IconName.AccountCard2:
      return <SvgIcon iconId={AccountCard2} />;
    case IconName.Account2:
      return <SvgIcon iconId={Account2} />;
    case IconName.Pix2:
      return <SvgIcon iconId={Pix2} />;
    case IconName.DetailsLoading:
      return <SvgIcon iconId={DetailsLoading} />;
    case IconName.DetailsError:
      return <SvgIcon iconId={DetailsError} />;
    case IconName.Refresh2:
      return <SvgIcon iconId={Refresh2} />;
    case IconName.PixSetting:
      return <SvgIcon iconId={PixSetting} />;
    case IconName.Menu:
      return <SvgIcon iconId={Menu} />;
    case IconName.MenuEvents:
      return <SvgIcon iconId={MenuEvents} />;
    case IconName.MenuVip:
      return <SvgIcon iconId={MenuVip} />;
    case IconName.MenuRakeBack:
      return <SvgIcon iconId={MenuRakeBack} />;
    case IconName.MenuSecurity:
      return <SvgIcon iconId={MenuSecurity} />;
    case IconName.MenuData:
      return <SvgIcon iconId={MenuData} />;
    case IconName.MenuAccount:
      return <SvgIcon iconId={MenuAccount} />;
    case IconName.MenuBets:
      return <SvgIcon iconId={MenuBets} />;
    case IconName.MenuReport:
      return <SvgIcon iconId={MenuReport} />;
    case IconName.MenuPending:
      return <SvgIcon iconId={MenuPending} />;
    case IconName.MenuWithdraw:
      return <SvgIcon iconId={MenuWithdraw} />;
    case IconName.MenuHistory:
      return <SvgIcon iconId={MenuHistory} />;
    case IconName.MenuGiftMoney:
      return <SvgIcon iconId={MenuGiftMoney} />;
    case IconName.MenuGiftMoney2:
      return <SvgIcon iconId={MenuGiftMoney2} />;
    case IconName.Menu2:
      return <SvgIcon iconId={Menu2} />;
    case IconName.MenuEvents2:
      return <SvgIcon iconId={MenuEvents2} />;
    case IconName.MenuVip2:
      return <SvgIcon iconId={MenuVip2} />;
    case IconName.MenuRakeBack2:
      return <SvgIcon iconId={MenuRakeBack2} />;
    case IconName.MenuSecurity2:
      return <SvgIcon iconId={MenuSecurity2} />;
    case IconName.MenuData2:
      return <SvgIcon iconId={MenuData2} />;
    case IconName.MenuAccount2:
      return <SvgIcon iconId={MenuAccount2} />;
    case IconName.MenuBets2:
      return <SvgIcon iconId={MenuBets2} />;
    case IconName.MenuReport2:
      return <SvgIcon iconId={MenuReport2} />;
    case IconName.MenuPending2:
      return <SvgIcon iconId={MenuPending2} />;
    case IconName.MenuWithdraw2:
      return <SvgIcon iconId={MenuWithdraw2} />;
    case IconName.MenuHistory2:
      return <SvgIcon iconId={MenuHistory2} />;
    case IconName.MenuBlog:
      return <SvgIcon iconId={MenuBlog} />;
    case IconName.MenuMessage:
      return <SvgIcon iconId={MenuMessage} />;
    case IconName.MenuFaq:
      return <SvgIcon iconId={MenuFaq} />;
    case IconName.MenuService:
      return <SvgIcon iconId={MenuService} />;
    case IconName.MenuFb:
      return <SvgIcon iconId={MenuFb} />;
    case IconName.MenuGg:
      return <SvgIcon iconId={MenuGg} />;
    case IconName.MenuTg:
      return <SvgIcon iconId={MenuTg} />;
    case IconName.ProportionCategory_1:
      return <SvgIcon iconId={ProportionCategory_1} />;
    case IconName.ProportionCategory_2:
      return <SvgIcon iconId={ProportionCategory_2} />;
    case IconName.ProportionCategory_3:
      return <SvgIcon iconId={ProportionCategory_3} />;
    case IconName.ProportionCategory_4:
      return <SvgIcon iconId={ProportionCategory_4} />;
    case IconName.ProportionCategory_5:
      return <SvgIcon iconId={ProportionCategory_5} />;
    case IconName.ProportionCategory_6:
      return <SvgIcon iconId={ProportionCategory_6} />;
    case IconName.ProportionCategory_999:
      return <SvgIcon iconId={ProportionCategory_999} />;
    case IconName.SearchLoading:
      return <SvgIcon iconId={SearchLoading} />;
    case IconName.ButtonPrev:
      return <SvgIcon iconId={ButtonPrev} />;
    case IconName.Union:
      return <SvgIcon iconId={Union} />;
    case IconName.DubbleArrow:
      return <SvgIcon iconId={DubbleArrow} stroke="currentColor" />;
    case IconName.PIX:
      return <SvgIcon iconId={pixSvg} fill="currentColor" />;
    case IconName.PhoneOnline:
      return <SvgIcon iconId={phoneOnlineSvg} fill="currentColor" />;
    case IconName.Bubble:
      return <SvgIcon iconId={BubbleSvg} fill="currentColor" />;
    case IconName.SelectMask:
      return <SvgIcon iconId={SelectMaskSvg} fill="currentColor" />;
    case IconName.PIXLogo:
      return <SvgIcon iconId={PIXLogoSvg} />;
    case IconName.SignInClose:
      return <SvgIcon iconId={SignInClose} />;
    case IconName.Registeraccount:
      return <SvgIcon iconId={Registeraccount} />;
    case IconName.Registercountry:
      return <SvgIcon iconId={Registercountry} />;
    case IconName.Registeruser:
      return <SvgIcon iconId={Registeruser} />;
    case IconName.Registerlock:
      return <SvgIcon iconId={Registerlock} />;
    case IconName.Broadcast:
      return <SvgIcon iconId={Broadcast} fill="currentColor" />;
    case IconName.Newlogin:
      return <SvgIcon iconId={Newlogin} />;
    case IconName.HeaderWalletArrow:
      return <SvgIcon iconId={HeaderWalletArrow} stroke="currentColor" />;
    case IconName.Sound2:
      return <SvgIcon iconId={Sound2} stroke="currentColor" />;
    case IconName.Sound2_1:
      return <SvgIcon iconId={Sound2_1} stroke="currentColor" />;
    case IconName.Sound2_2:
      return <SvgIcon iconId={Sound2_2} stroke="currentColor" />;
    case IconName.Sound2_3:
      return <SvgIcon iconId={Sound2_3} stroke="currentColor" />;
    case IconName.Sound2_2_opacity:
      return <SvgIcon iconId={Sound2_2_opacity} stroke="currentColor" />;
    case IconName.Sound2_3_opacity:
      return <SvgIcon iconId={Sound2_3_opacity} stroke="currentColor" />;
    case IconName.HeaderSearch:
      return <SvgIcon iconId={HeaderSearch} />;
    case IconName.HeaderMusic:
      return <SvgIcon iconId={HeaderMusic} />;
    case IconName.HeaderSearch2:
      return <SvgIcon iconId={HeaderSearch2} />;
    case IconName.HeaderMusic2:
      return <SvgIcon iconId={HeaderMusic2} />;
    case IconName.HeaderCountry:
      return <SvgIcon iconId={HeaderCountry} />;
    case IconName.VipChecked:
      return <SvgIcon iconId={VipCheckedSvg} fill="currentColor" />;
    case IconName.BtntoTop:
      return <SvgIcon iconId={BtntoTop} fill="currentColor" />;
    case IconName.Email5:
      return <SvgIcon iconId={Email5} fill="currentColor" />;
    case IconName.Deposit2:
      return <SvgIcon iconId={Deposit2} fill="currentColor" />;
    case IconName.Withdraw2:
      return <SvgIcon iconId={Withdraw2} fill="currentColor" />;
    case IconName.Vip3:
      return <SvgIcon iconId={Vip3} fill="currentColor" />;
    case IconName.NewhideEye:
      return <SvgIcon iconId={NewhideEye} fill="currentColor" />;
    case IconName.NewShowEye:
      return <SvgIcon iconId={NewShowEye} fill="currentColor" />;
    case IconName.ClearStr:
      return <SvgIcon iconId={ClearStr} fill="currentColor" />;
    case IconName.Cpf:
      return <SvgIcon iconId={Cpf} fill="currentColor" />;
    case IconName.Cpf2:
      return <SvgIcon iconId={Cpf2} fill="currentColor" />;
    case IconName.MissionTable1:
      return <SvgIcon iconId={MissionTable1} fill="currentColor" />;
    case IconName.RightArrow2:
      return <SvgIcon iconId={RightArrow2Svg} stroke="currentColor" />;
    case IconName.Checkbox:
      return <SvgIcon iconId={CheckboxSvg} />;
    case IconName.FacebookWG:
      return <SvgIcon iconId={FacebookWG} />;
    case IconName.GoogleWG:
      return <SvgIcon iconId={GoogleWG} />;
    case IconName.TelegramWG:
      return <SvgIcon iconId={TelegramWG} />;
    case IconName.MetamaskWG:
      return <SvgIcon iconId={MetamaskWG} />;
    case IconName.CheckedBox:
      return <SvgIcon iconId={CheckedBoxSvg} />;
    case IconName.Hot:
      return <SvgIcon iconId={HotSvg} />;
    case IconName.SingleArrow:
      return <SvgIcon iconId={SingleArrow} stroke="currentColor" />;
    case IconName.Favorite:
      return <SvgIcon iconId={Favorite} fill="currentColor" />;
    case IconName.Rencent:
      return <SvgIcon iconId={Rencent} fill="currentColor" />;
    case IconName.Rocket:
      return <SvgIcon iconId={Rocket} fill="currentColor" />;
    case IconName.Fire:
      return <SvgIcon iconId={Fire} fill="currentColor" />;
    case IconName.TournamentTitleIcon:
      return <SvgIcon iconId={TournamentTitleIcon} fill="currentColor" />;
    case IconName.CurrentLevel:
      return <SvgIcon iconId={CurrentLevel} fill="currentColor" />;
    case IconName.Gametype_1:
      return <SvgIcon iconId={Gametype_1} fill="currentColor" />;
    case IconName.Gametype_2:
      return <SvgIcon iconId={Gametype_2} fill="currentColor" />;
    case IconName.Gametype_3:
      return <SvgIcon iconId={Gametype_3} fill="currentColor" />;
    case IconName.Gametype_4:
      return <SvgIcon iconId={Gametype_4} fill="currentColor" />;
    case IconName.Gametype_5:
      return <SvgIcon iconId={Gametype_5} fill="currentColor" />;
    case IconName.Gametype_6:
      return <SvgIcon iconId={Gametype_6} fill="currentColor" />;
    case IconName.Gametype_9999:
      return <SvgIcon iconId={Gametype_9999} fill="currentColor" />;
    case IconName.Redeem:
      return <SvgIcon iconId={Redeem} fill="currentColor" />;
    case IconName.RedeemLight:
      return <SvgIcon iconId={RedeemLight} />;
    case IconName.InputClear:
      return <SvgIcon iconId={InputClear} fill="currentColor" />;
    case IconName.Checked1:
      return <SvgIcon iconId={Checked1} />;
    case IconName.RedDot:
      return <SvgIcon iconId={RedDot} />;
    case IconName.FirstDeposit:
      return <SvgIcon iconId={FirstDeposit} fill="currentColor" />;
    case IconName.TotalDeposit:
      return <SvgIcon iconId={TotalDeposit} fill="currentColor" />;
    case IconName.LiquiTime:
      return <SvgIcon iconId={LiquiTime} fill="currentColor" />;
    case IconName.MysteryBonus:
      return <SvgIcon iconId={MysteryBonus} fill="currentColor" />;
    case IconName.DateDay:
      return <SvgIcon iconId={DateDay} fill="currentColor" />;
    case IconName.Checked3:
      return <SvgIcon iconId={Checked3} />;
    case IconName.Copy7:
      return <SvgIcon iconId={Copy7} fill="currentColor" />;
    case IconName.Menu3:
      return <SvgIcon iconId={Menu3} />;
    case IconName.Language:
      return <SvgIcon iconId={Language} />;
    case IconName.Message1:
      return <SvgIcon iconId={Message1} />;
    case IconName.Apple:
      return <SvgIcon iconId={Apple} />;
    case IconName.Android:
      return <SvgIcon iconId={Android} />;
    case IconName.CategoryGameHeaderArrow:
      return <SvgIcon iconId={CategoryGameHeaderArrow} stroke="currentColor" />;
    case IconName.BtnBack:
      return <SvgIcon iconId={BtnBack} stroke="currentColor" />;
    case IconName.MineRefresh:
      return <SvgIcon iconId={MineRefresh} stroke="currentColor" />;
    case IconName.AccountCopy:
      return <SvgIcon iconId={AccountCopy} stroke="currentColor" />;
    case IconName.ReferralCashbackInvite:
      return <SvgIcon iconId={ReferralCashbackInvite} stroke="currentColor" />;
    case IconName.HaderWallet:
      return <SvgIcon iconId={HaderWallet} fill="currentColor" />;
    case IconName.BacktoTop1:
      return <SvgIcon iconId={BacktoTop1} fill="currentColor" />;
    case IconName.AccountDeposit:
      return <SvgIcon iconId={AccountDeposit} fill="currentColor" />;
    case IconName.AccountWithdraw:
      return <SvgIcon iconId={AccountWithdraw} fill="currentColor" />;
    case IconName.AccountEmail:
      return <SvgIcon iconId={AccountEmail} fill="currentColor" />;
    case IconName.CloseEye:
      return <SvgIcon iconId={CloseEye} fill="currentColor" />;
    case IconName.OpenEye:
      return <SvgIcon iconId={OpenEye} fill="currentColor" />;
    default:
      return <></>;
  }
};

export default Icon;
