import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../icon';

const LayoutMobile = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-start cursor-pointer px-[.14rem] mb-[.2rem]" onClick={onClick}>
      <span className="w-[.4rem] h-[.4rem] block mr-[.08rem] relative">
        <Icon name={IconName.Blog} />
      </span>
      <span className="text-[var(--colour-14)] text-[.2rem] relative leading-[.24rem] font-[500]">
        {t('home.blog')}
      </span>
    </div>
  );
};

const BlogEntrance = ({ handleClick }) => {
  return <LayoutMobile onClick={handleClick} />;
};

export default BlogEntrance;
