import CashRain from './cashRain';
import MysteryMining from './mysteryMining';
import { getTemplateId } from '../../../utils/helper';

const Index = ({ handleClose = () => {} }) => {
  switch (getTemplateId()) {
    case 'n-template001':
    case 'n-template002':
    case 'd-template001':
    case 'd-template002':
      return <CashRain handleClose={handleClose} />;

    case 'd-template003':
      return <MysteryMining handleClose={handleClose} />;
  }
};

export default Index;
