import LimitClickWrapper from '../../limitClickWrapper';
import cn from 'classnames';

const Confirm = ({
  handleCancel,
  handleConfirm,
  children,
  title = 'Tips',
  cancelText = 'Cancel',
  confirmText = 'OK',
  isConfirmBtnPrimary = true, //确定按钮是否首要按钮
}) => {
  return (
    <>
      <div className="bg-[var(--colour-35)] text-center relative overflow-hidden w-[5.96rem] rounded-normal border border-[var(--colour-6)]">
        <div className="h-[100%] p-[.3rem] pt-[.2rem]">
          <div className="flex flex-row justify-center items-center">
            <div className={cn('text-[.3rem] font-[700] text-[var(--colour-48)]')}>{title}</div>
          </div>
          <div className="text-[var(--colour-40)] mt-[.36rem]">{children}</div>
          <div className="flex flex-row justify-center text-[var(--colour-2)]">
            {handleConfirm ? (
              <LimitClickWrapper
                className={`flex flex-row mt-[.76rem] w-[2.52rem] h-[.7rem] mx-[.1rem] items-center text-[var(--colour-2)] cursor-pointer justify-center active:scale-[.95] transition-all select-none rounded-normal  border border-1 border-[var(--colour-1)] ${
                  isConfirmBtnPrimary
                    ? 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                    : 'bg-[var(--colour-5)] text-[var(--colour-3)]'
                }`}
                onClick={handleConfirm}
              >
                <div className="font-[700] text-[.2rem]">{confirmText}</div>
              </LimitClickWrapper>
            ) : (
              <></>
            )}
            {handleCancel ? (
              <LimitClickWrapper
                className={`flex flex-row mt-[.76rem] w-[2.52rem] h-[.7rem]  mx-[.1rem] items-center cursor-pointer justify-center active:scale-[.95] transition-all select-none rounded-normal border border-1 border-[var(--colour-1)] ${
                  isConfirmBtnPrimary
                    ? 'bg-[var(--colour-5)] text-[var(--colour-3)]'
                    : 'bg-[var(--colour-1)] text-[var(--colour-2)]'
                }`}
                onClick={handleCancel}
              >
                <div className="font-[700] text-[.2rem]">{cancelText}</div>
              </LimitClickWrapper>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
