import Countdown, { zeroPad } from 'react-countdown';
import { useTranslation } from 'react-i18next';

const NumberSpan = ({ children }) => {
  return (
    <span className="font-[700] text-[var(--colour-48)] text-[.56rem] w-[.64rem] h-[.9rem] bg-[var(--colour-47)] rounded-[.16rem] flex items-center justify-center">
      {children}
    </span>
  );
};

const TournamentCountdown = ({ date = Date.now(), onComplete = () => {} }) => {
  const { t } = useTranslation();
  const countDownTitles = [t('promo.day'), t('promo.hour'), t('promo.min'), t('promo.sec')];
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const list = completed
      ? ['00', '00', '00', '00']
      : [zeroPad(days, 2), zeroPad(hours, 2), zeroPad(minutes, 2), zeroPad(seconds, 2)];

    return list.map((item, index) => (
      <div key={index} className="relative">
        {[2, 3].includes(index) && (
          <span className="absolute left-[-.22rem] top-[.12rem] text-[var(--colour-48)] text-[.4rem] font-[600]">
            :
          </span>
        )}
        <div className="flex flex-col items-center space-y-[.13rem]" key={index}>
          <div className="flex flex-row items-center justify-center space-x-[.06rem]">
            {String(item)
              .split('')
              .map((item2, index2) => (
                <NumberSpan key={index2}>{item2}</NumberSpan>
              ))}
          </div>
          <span className="font-[400] text-[.36rem] text-[var(--colour-41)]">{countDownTitles[index]}</span>
        </div>
      </div>
    ));
  };
  return (
    <div className={`flex items-center justify-center space-x-[.32rem]`}>
      <Countdown
        date={date}
        onComplete={() => {
          setTimeout(() => {
            onComplete();
          }, 1000 * 2);
        }}
        renderer={renderer}
      />
    </div>
  );
};

export default TournamentCountdown;
