import { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Input from '../input';
import TippyPopBox, { Placement } from '../tippyPopBox';
import Icon, { IconName } from '../icon';
import ChooseCountryCode from '../chooseCountryCode';
import DropDownInput from '../dropDownInput';
import PopChoose, { PopChooseItem } from '../popChoose';
import { UserCache, getRegisterMethodsEnable } from '../../utils/helper';
import { get } from 'lodash';
import { useStateIfMounted } from 'use-state-if-mounted';

export const useCaseList = {
  login: 'login',
  register: 'register',
  forgetPassword: 'forgetPassword',
};

const OnlyPhoneComponent = () => {
  const [chooseCountryCode, setChooseCountryCode] = useState(63);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);
  const handleChooseCode = (item) => {
    setShowCountryCodeList(false);
    setChooseCountryCode(String(item.code).replace('+', ''));
  };
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );
  return (
    <div className="flex items-center ">
      <span className={`w-[.48rem] h-[.48rem] mr-[.2rem] ml-[.28rem] block text-[var(--colour-38)]`}>
        <Icon name={IconName.Phone4} />
      </span>
      <TippyPopBox
        visible={showCountryCodeList}
        popChildren={
          <ChooseCountryCode
            onClick={handleChooseCode}
            scrollBarWrapperClassName="h-[5.6rem]"
            className="!bg-[#1A1A1A] active:scale-[.95] transition-all select-none w-[6.3rem]"
          />
        }
        onClickOutside={() => setShowCountryCodeList(false)}
        placement={Placement.bottomStart}
      >
        <div>
          <DropDownInput
            // onClick={() => setShowCountryCodeList(!showCountryCodeList)}
            className="bg-[var(--colour-35)] rounded-normal px-[.4rem] mr-[.14rem] h-[.8rem]"
          >
            <div className="text-primary-content font-[500] text-[.28rem]">
              +{websiteInitConfig.website_info.country_code || ''}
            </div>
          </DropDownInput>
        </div>
      </TippyPopBox>
    </div>
  );
};

const InputByPhoneOrEmail = ({
  readCache = true,
  allowSelection = true,
  onChange,
  value,
  inputType = 'phone',
  handleSetInputType,
  useCase = 'login', // login,register 用于登录或注册
}) => {
  const { t } = useTranslation();
  const [phoneValue, setPhoneValue] = useState(readCache ? UserCache.getPhone() : '');
  const [emailValue, setEmailValue] = useState(readCache ? UserCache.getEmail() : '');
  const [userNameValue, setUserNameValue] = useState(readCache ? UserCache.getUserName() : '');

  useEffect(() => {
    if (inputType == UserCache.LoginMethodValue.Email) {
      setEmailValue(value);
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      setPhoneValue(value);
    } else if (inputType == UserCache.LoginMethodValue.UserName) {
      setUserNameValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (inputType == UserCache.LoginMethodValue.Email) {
      onChange(emailValue);
    } else if (inputType == UserCache.LoginMethodValue.Phone) {
      onChange(phoneValue);
    } else if (inputType == UserCache.LoginMethodValue.UserName) {
      onChange(userNameValue);
    }
  }, [inputType]);

  return (
    <div>
      <Input
        leftOuter={<OnlyPhoneComponent />}
        value={value}
        onChange={(ev) => {
          if (inputType == UserCache.LoginMethodValue.Phone) {
            const val = ev.target.value;
            const regx = /^[1-9]\d{0,17}$|^$/;
            if (regx.test(val)) {
              onChange(val);
            }
          }
          if (['email', 'userName'].includes(inputType)) {
            const val = ev.target.value;
            onChange(val);
          }
        }}
        placeholder={t(
          allowSelection && inputType == UserCache.LoginMethodValue.Email
            ? 'login-signup.Fill in your email'
            : inputType == UserCache.LoginMethodValue.Phone
              ? 'login-signup.phonenumber'
              : 'login-signup.fillin-username',
        )}
        innerClassName="!border-transparent !h-[.96rem]"
        topClassName="rounded-normal bg-[var(--colour-34)] overflow-hidden border-none"
      />
    </div>
  );
};

export default InputByPhoneOrEmail;
