import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tips from '../tips';
import Icon, { IconName } from '../../../icon';
import { isControllableSite } from '../../../../utils/helper';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import BlogEntrance from './blogEntrance';

const Blog = ({ layout = null, onlyIcon = false, isShow = true, handleLinkClick = () => {} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const handleClick = () => {
    window.open(websiteInitConfig.website_info.website_blog, '_blank');
    handleLinkClick();
  };

  return websiteInitConfig.website_info.website_blog && websiteInitConfig.website_info.website_blog != '' ? (
    onlyIcon ? (
      <>
        <Tips anchorId="overBlog" className="text-[var(--colour-48)]">
          {t('home.blog')}
        </Tips>
        <div
          onClick={handleClick}
          id="overBlog"
          className={`w-[.8rem] h-[.8rem] mb-[.16rem] rounded-normal cursor-pointer flex justify-center items-center bg-[var(--colour-35)] relative hover:bg-[var(--colour-35)]`}
        >
          {isControllableSite() ? (
            <div className="w-[.52rem] h-[.52rem]">
              <Icon name={IconName.Blog} />
            </div>
          ) : (
            <div className="w-[.44rem] h-[.44rem]">
              <Icon name={IconName.Blog} />
            </div>
          )}
        </div>
      </>
    ) : (
      <BlogEntrance layout={layout} isShow={isShow} handleClick={handleClick} />
    )
  ) : null;
};

export default Blog;
