import { useRef } from 'react';
// import PrevBtn from './prevBtn';
// import NextBtn from './nextBtn';
import cn from 'classnames';
import useDraggableScroll from '../../../../../hooks/useDraggableScroll';
import { isPCLayout } from '../../../../../utils/helper';

const TypeItem = ({ children, isActive = false, onClick }) => {
  return (
    <div
      className={cn(
        'rounded-[.16rem] min-w-[1.4rem] h-[.56rem] flex items-center justify-center cursor-pointer whitespace-nowrap text-[.24rem] font-[500] px-[.2rem]',
        {
          'text-[var(--colour-14)] bg-[var(--colour-5)] border border-[var(--colour-6)]': !isActive,
          'text-[var(--colour-2)] border border-[var(--colour-1)] bg-[var(--colour-1)]': isActive,
        },
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const TypeList = ({ list = [], activeIndex }) => {
  // console.log('list', list);

  // const [isShowTurnBtn, setIsShowTurnBtn] = useState(true);

  const listRef = useRef(null);
  const refScroller = useRef(null);

  // useEffect(() => {
  //   if (listRef.current) {
  //     if (responsiveMode == ResponsiveMode.pc) {
  //       if (listRef.current.scrollWidth > listRef.current.clientWidth) {
  //         setIsShowTurnBtn(true);
  //       } else {
  //         setIsShowTurnBtn(false);
  //       }
  //     } else {
  //       setIsShowTurnBtn(false);
  //     }
  //   }
  // }, [listRef, responsiveMode]);

  // const onClickPrev = () => {
  //   if (listRef.current) {
  //     listRef.current.scrollBy(-200, 0);
  //   }
  // };

  // const onClickNext = () => {
  //   if (listRef.current) {
  //     listRef.current.scrollBy(200, 0);
  //   }
  // };

  useDraggableScroll(refScroller);

  return (
    <div className={cn('flex items-center')}>
      {/* {isShowTurnBtn && (
        <div className="shrink-0">
          <PrevBtn onClick={onClickPrev} />
        </div>
      )} */}

      <div
        ref={refScroller}
        className={cn('overflow-x-auto scrollbar-none select-none', {
          'w-[calc(var(--theme-max-width)-2.28rem)]': isPCLayout(),
          'w-[calc(100vw-2.28rem)]': !isPCLayout(),
        })}
      >
        <div className="flex h-[.7rem] space-x-[.2rem]" ref={listRef}>
          {list.map((item, index) => {
            return (
              <TypeItem
                key={index}
                isActive={activeIndex == index}
                onClick={() => {
                  // setActiveIndex(index);
                  item?.onClick && item?.onClick();
                }}
              >
                {item?.name}
              </TypeItem>
            );
          })}
        </div>
      </div>

      {/* {isShowTurnBtn && (
        <div className="shrink-0">
          <NextBtn onClick={onClickNext} />
        </div>
      )} */}
    </div>
  );
};

export default TypeList;
