import { useEffect, useState } from 'react';
import GameList from './com/gameList';
import ProviderList from './com/providerList';
import SearchBox from './com/searchBox';
import TypeList from './com/typeList';
import { GameListGameTypeReq, GameListReq, GetFavouritesListReq, GetRecentListReq } from '../../../../api';
import LoadMore from '../../../../components/loadMore';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DialogModalChildrenKey, ResponsiveMode, setDialogModalVisible } from '../../../../redux/reducers/appSlice';
import HeaderBack from '../../../../components/headerBack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cmsImgUrl, isLogin } from '../../../../utils/helper';
import Pagination from '../../../../components/pagination';
import Icon, { IconName } from '../../../../components/icon';
import PageTitle from './com/pageTitle';
import ListTitle from './com/listTitle';
import cn from 'classnames';
import { toLocalePath } from '../../../../utils/localePath';
import { useGlobalNavigate } from '../../../../contexts/navigateContext';
import useWindowHeight from '../../../../hooks/useWindowHeight';
import { get } from 'lodash';

const NTemplate001 = () => {
  const { t } = useTranslation();
  const navigate = useGlobalNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { responsiveMode, uid } = useSelector(
    (state) => ({
      uid: get(state, 'user.userObj.uid', ''),
      responsiveMode: state?.app?.responsiveMode,
    }),
    shallowEqual,
  );

  const page_size = 27;

  const allProviderSource = -999;
  const initSource = searchParams.get('source');

  const listNames = ['gameList', 'popularList', 'recentList', 'favouriteList'];
  const [gameList, setGameList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [activeProviderId, setActiveProviderId] = useState(initSource);
  const [activeTypeIndex, setActiveTypeIndex] = useState(0);
  const [listKey, setListKey] = useState(listNames[0]);
  const { h } = useWindowHeight(4.24);
  let icon_selected = '';
  let icon_unselected = '';

  try {
    const obj = JSON.parse(atob(searchParams.get('img')));
    icon_selected = obj.icon_selected;
    icon_unselected = obj.icon_unselected;
  } catch (error) {
    console.log('parse img error');
  }

  // console.log('icon_selected', icon_selected)
  // console.log('icon_unselected', icon_unselected)

  const initParams = {
    game_type_id: searchParams.get('id'), //游戏有效分类id 必传
    source: '', // 厂商编号  可空
    with_source: 1, //  1表示返回对应game_type_id厂商列表, 0返回空列表 可空
    page: 1, // 页码  可空(默认1)
    page_size: page_size, // 每页条数 可空（默认10）
    search: '', // 搜索游戏名字, 搜索用全部厂商

    refreshNow: false, // 是否刷新
  };

  const [params, setParams] = useState(initParams);

  const [paramsPopular, setParamsPopular] = useState({
    category: '', // 类型id
    page: 1, // 页码
    page_size: page_size, // 每页条数
    refreshNow: false,
  });

  const [paramsRecently, setParamsRecently] = useState({
    page: 1,
    page_size: page_size, // 不起作用，服务端写死35条 ,服务端已支持
    refreshNow: false,
  });

  const [paramsFavourite, setParamsFavourite] = useState({
    page: 1,
    page_size: page_size,
    refreshNow: false,
  });

  const [totalNum, setTotalNum] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const updateList = () => {
    setIsLoading(true);

    GameListGameTypeReq(params).then((res) => {
      setIsLoading(false);

      if (res.code == 0) {
        setGameList(res?.result?.game_list || []);

        if (providerList.length == 0) {
          setProviderList(['all', ...(res?.result?.provider_list || [])]);
        }

        setTotalNum(res?.result?.pages?.total || 0);
      }
    });
  };

  const updateListPopular = () => {
    setIsLoading(true);

    GameListReq(paramsPopular).then((res) => {
      setIsLoading(false);

      if (res.code == 0) {
        setGameList(res?.result?.list || []);

        setTotalNum(res?.result?.pages?.count || 0);
      }
    });
  };

  const updateListRecently = () => {
    setIsLoading(true);

    GetRecentListReq(uid).then((res) => {
      setIsLoading(false);
      if (res.code == 0) {
        setGameList(res?.result?.list || []);

        setTotalNum(res?.result?.pages?.count || 0);
      }
    });
  };

  const updateListFavourite = () => {
    setIsLoading(true);

    GetFavouritesListReq(paramsFavourite).then((res) => {
      setIsLoading(false);

      if (res.code == 0) {
        setGameList(res?.result?.list || []);

        setTotalNum(res?.result?.pages?.count || 0);
      }
    });
  };

  const resetGameList = () => {
    setGameList([]);
    setTotalNum(0);
  };

  useEffect(() => {
    if (params.refreshNow) {
      updateList();
    }
  }, [params]);

  useEffect(() => {
    resetGameList();
    setProviderList([]);

    setParams({
      ...initParams,
      source: initSource,
      refreshNow: true,
    });
  }, [responsiveMode]);

  useEffect(() => {
    if (paramsPopular.refreshNow) {
      updateListPopular();
    }
  }, [paramsPopular]);

  useEffect(() => {
    if (paramsRecently.refreshNow) {
      updateListRecently();
    }
  }, [paramsRecently]);

  useEffect(() => {
    if (paramsFavourite.refreshNow) {
      updateListFavourite();
    }
  }, [paramsFavourite]);

  const onClickTypeAll = () => {
    setActiveTypeIndex(0);
    setActiveProviderId(allProviderSource);

    setListKey(listNames[0]);

    resetGameList();

    setParams({
      ...params,
      source: '',
      search: '',
      page: 1,
      refreshNow: true,
    });
  };

  const onClickTypePopular = () => {
    setActiveTypeIndex(1);
    setActiveProviderId(allProviderSource);

    setListKey(listNames[1]);

    setParams({
      ...params,
      search: '',
      refreshNow: false,
    });

    resetGameList();

    setParamsPopular({
      ...paramsPopular,
      category: '1011',
      page: 1,
      refreshNow: true,
    });
  };

  const onClickTypeRecently = () => {
    if (isLogin()) {
      setActiveTypeIndex(2);
      setActiveProviderId(allProviderSource);

      setListKey(listNames[2]);

      setParams({
        ...params,
        search: '',
        refreshNow: false,
      });

      resetGameList();

      setParamsRecently({
        ...paramsRecently,
        page: 1,
        refreshNow: true,
      });
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };

  const onClickTypeFavorites = () => {
    if (isLogin()) {
      setActiveTypeIndex(3);
      setActiveProviderId(allProviderSource);

      setListKey(listNames[3]);

      setParams({
        ...params,
        search: '',
        refreshNow: false,
      });

      resetGameList();

      setParamsFavourite({
        ...paramsFavourite,
        page: 1,
        refreshNow: true,
      });
    } else {
      dispatch(
        setDialogModalVisible({
          show: DialogModalChildrenKey.LoginBox,
        }),
      );
    }
  };

  const typeListData = [
    {
      name: t('gamelist.all'),
      onClick: onClickTypeAll,
    },
    {
      name: t('gamelist.popular'),
      onClick: onClickTypePopular,
    },
    {
      name: t('gamelist.recently'),
      onClick: onClickTypeRecently,
    },
    {
      name: t('gamelist.favorites'),
      onClick: onClickTypeFavorites,
    },
  ];

  const onProviderClick = (source) => {
    setActiveProviderId(source);

    setActiveTypeIndex(0);

    setListKey(listNames[0]);

    resetGameList();

    setParams({
      ...params,
      source: source == allProviderSource ? '' : source,
      with_source: 0,
      search: '',
      page: 1,
      refreshNow: true,
    });
  };

  const onSearchChange = (ev) => {
    setParams({
      ...params,
      search: ev.target.value,
      refreshNow: false,
    });
  };

  const onClickSearch = () => {
    setActiveProviderId(allProviderSource);
    setActiveTypeIndex(0);
    setListKey(listNames[0]);

    resetGameList();

    setParams({
      ...params,
      source: '',
      page: 1,
      refreshNow: true,
    });
  };

  const getCategoryName = () => {
    const title = searchParams.get('sourceName') || '';
    return title;
  };

  const getCategoryImg = () => {
    const img = searchParams.get('img') || '';
    return img;
  };

  const loadMoreGameList = () => {
    setParams({
      ...params,
      page: params.page + 1,
      refreshNow: true,
    });
  };

  const loadMorePopularList = () => {
    setParamsPopular({
      ...paramsPopular,
      page: paramsPopular.page + 1,
      refreshNow: true,
    });
  };

  const loadMoreRecentlyList = () => {
    setParamsRecently({
      ...paramsRecently,
      page: paramsRecently.page + 1,
      refreshNow: true,
    });
  };

  const loadMoreFavouriteList = () => {
    setParamsFavourite({
      ...paramsFavourite,
      page: paramsFavourite.page + 1,
      refreshNow: true,
    });
  };

  const isHasMorePage = () => {
    switch (listKey) {
      case listNames[0]: {
        const result = params.page < Math.ceil(totalNum / params.page_size);
        return result;
      }
      case listNames[1]: {
        const result = paramsPopular.page < Math.ceil(totalNum / paramsPopular.page_size);
        return result;
      }
      case listNames[2]: {
        const result = paramsRecently.page < Math.ceil(totalNum / paramsRecently.page_size);
        return result;
      }
      case listNames[3]: {
        const result = paramsFavourite.page < Math.ceil(totalNum / paramsFavourite.page_size);
        return result;
      }
      default: {
        return false;
      }
    }
  };

  const onClickMore = () => {
    switch (listKey) {
      case listNames[0]: {
        loadMoreGameList();
        break;
      }
      case listNames[1]: {
        loadMorePopularList();
        break;
      }
      case listNames[2]: {
        loadMoreRecentlyList();
        break;
      }
      case listNames[3]: {
        loadMoreFavouriteList();
        break;
      }
      default:
        break;
    }
  };

  const onPaginationChange = (page) => {
    // console.log('onPaginationChange', page);

    switch (listKey) {
      case listNames[0]: {
        setParams({
          ...params,
          page: page,
          refreshNow: true,
        });
        break;
      }
      case listNames[1]: {
        setParamsPopular({
          ...paramsPopular,
          page: page,
          refreshNow: true,
        });
        break;
      }
      case listNames[2]: {
        setParamsRecently({
          ...paramsRecently,
          page: page,
          refreshNow: true,
        });
        break;
      }
      case listNames[3]: {
        setParamsFavourite({
          ...paramsFavourite,
          page: page,
          refreshNow: true,
        });
        break;
      }
      default:
        break;
    }
  };

  const isLoadingFirstPage = () => {
    switch (listKey) {
      case listNames[0]: {
        return params.page == 1;
      }
      case listNames[1]: {
        return paramsPopular.page == 1;
      }
      case listNames[2]: {
        return paramsRecently.page == 1;
      }
      case listNames[3]: {
        return paramsFavourite.page == 1;
      }
      default: {
        return false;
      }
    }
  };

  useEffect(() => {
    if (providerList && providerList.length > 0 && activeProviderId != allProviderSource) {
      const index = providerList.findIndex((item) => {
        return item != 'all' && item.source == activeProviderId;
      });
      if (index == -1) {
        onProviderClick(allProviderSource);
      }
    }
  }, [activeProviderId, providerList]);

  return (
    <>
      <HeaderBack
        title={getCategoryName()}
        onClick={() => {
          navigate(toLocalePath('/'));
        }}
      />
      <div className="bg-[--colour-9] pt-[.4rem]">
        <div className="m-[0rem_.32rem_0]">
          <SearchBox
            placeholder={t('gamelist.gamename')}
            value={params.search}
            onClickSearch={onClickSearch}
            onSearchChange={onSearchChange}
          />
        </div>
        <div className="flex mx-[.32rem] space-x-[.24rem] mt-[.24rem]">
          <div className="w-[1.44rem]">
            <ProviderList
              activeIndex={activeTypeIndex}
              list={providerList}
              activeProviderId={activeProviderId}
              allProviderSource={allProviderSource}
              onProviderClick={onProviderClick}
              categoryName={getCategoryName()}
              allImg={<img src={cmsImgUrl(icon_selected)} />}
            />
          </div>
          <div className="flex-1 mx-[.32rem] space-x-[.24rem]">
            <div className="flex-1 overflow-hidden relative">
              <TypeList list={typeListData} activeIndex={activeTypeIndex} />
              <div className="mt-[.2rem] w-[5.22rem] overflow-y-auto scrollbar-none" style={{ height: `${h}rem` }}>
                <GameList list={gameList} isLoading={isLoading} />
              </div>
              <div className="w-[calc(100%-1.84rem)] h-[1.3rem] fixed bottom-[0px] right-[0px] bg-[var(--colour-9)] z-[2]">
                {totalNum > 0 && totalNum > page_size && (
                  <>
                    <div className="flex justify-center items-center h-full">
                      <Pagination
                        currentPage={
                          listKey == listNames[0]
                            ? params.page
                            : listKey == listNames[1]
                              ? paramsPopular.page
                              : listKey == listNames[2]
                                ? paramsRecently.page
                                : paramsFavourite.page
                        }
                        pageTotal={totalNum}
                        pageSize={page_size}
                        onChange={onPaginationChange}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="h-[1.3rem]"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NTemplate001;
