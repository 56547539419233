import { get } from 'lodash';
import { memo } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

/**
 * GTAG
 */
const GTAG = () => {
  const gtagId = useSelector((state) => get(state, 'app.websiteInitConfig.website_info.gtag_id', ''));

  return gtagId ? (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`} />
      <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtagId}');
        `}</script>
    </Helmet>
  ) : (
    <></>
  );
};

export default memo(GTAG);
