import cn from 'classnames';

const Line = ({ className = '', className2 = 'bg-[var(--colour-6)]' }) => {
  return (
    <div className={className}>
      <div className={cn(`w-full h-[1px] ${className2}`)}></div>
    </div>
  );
};

export default Line;
