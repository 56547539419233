import { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toNoLocalePath, toLocalePath } from '../utils/localePath';
import {
  setNeedCheckedLobby,
  setShowSupportNew,
  setShowInbox,
  setDialogModalVisible,
  DialogModalChildrenKey,
  ResponsiveMode,
  setLoginStatus,
} from '../redux/reducers/appSlice';
import { conforms, get } from 'lodash';
import {
  setUserObj,
  setLoginInfo,
  setLoginMethod,
  setLoginType as setUserLoginType,
} from '../redux/reducers/userSlice';
import {
  LoginGoogleAuthReq,
  RegisterReqNew,
  LoginReq,
  LogininitReq,
  GetCaptchaReq,
  CaptchaInitReq,
  LoginAnyReq,
} from '../api/index-auth';
import { getQueryString, UserCache, setVipLevelLogin, TextPlaces, isControllableSite } from '../utils/helper';
import { useStateIfMounted } from 'use-state-if-mounted';
import { toast } from 'react-toastify';
import useInitActivity from './useInitActivity';
import { useTranslation } from 'react-i18next';
import AnalyticsManager, { FirebaseEvents } from '../manager/analyticsManager';
import PopupManager from '../utils/popupManager';
import OAuthRedirecting from '../utils/oauthRedirecting';
import handleInitGeetest4 from './useGeetestInit';

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { InitActivity } = useInitActivity();
  const { pathname } = useLocation();
  //是否请求中
  const [requesting, setRequesting] = useStateIfMounted(false);
  //是否记住密码
  const [isRememberPwd, setIsRememberPwd] = useStateIfMounted(2);
  const [isAnyAccountLogin, setIsAnyAccountLogin] = useStateIfMounted(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useStateIfMounted(false);
  const [is2FaOpen, setIs2FaOpen] = useStateIfMounted(false);
  const [uuIdentity, setUUIdentity] = useStateIfMounted('');
  const [captchaInitConfig, setCaptchaInitConfig] = useStateIfMounted(null);
  // 有缓存使用缓存，没有缓存使用本地默认列表的第一个
  const [loginType, setLoginType] = useState(UserCache.getLoginMethod() || UserCache.LoginMethodList[0]);
  const { inviteCode, osType, advertisingId, loginStatus, websiteInitConfig, dialogModalProp } = useSelector(
    (state) => ({
      inviteCode: get(state, 'user.inviteCode', ''),
      osType: get(state, 'user.osType', ''),
      advertisingId: get(state, 'user.advertisingId', ''),
      loginStatus: state.app.loginStatus,
      websiteInitConfig: state.app.websiteInitConfig,
      dialogModalProp: state.app.dialogModalProp,
    }),
    shallowEqual,
  );
  const [formData, setFormData] = useStateIfMounted({
    account: UserCache.getAnyAccount(),
    mobile:
      loginType == UserCache.LoginMethodValue.Phone
        ? UserCache.getPhone()
        : loginType == UserCache.LoginMethodValue.Email
          ? UserCache.getEmail()
          : UserCache.getUserName(),
    password: '',
    cryCode: '',
    captcha: '',
    osType,
    advertisingId,
    inviteCode,
    loginOrSignup: '1', // '1'登录 '2'注册
    auth_code: '',
  });

  useEffect(() => {
    if (captchaInitConfig && captchaInitConfig.captcha) {
      setFormData({
        ...formData,
        captcha: captchaInitConfig.captcha,
      });
    }
  }, [captchaInitConfig]);

  const LoginRes = (res) => {
    return new Promise((resolve) => {
      const result = res.result;
      if (res.code == 0) {
        if (result.need_google_auth == '1') {
          setUUIdentity(result?.uu_identity);
          setIs2FaOpen(true);
          resolve();
        } else {
          LoginSuccess(result);
          resolve();
        }
      } else {
        LoginError(res);
        let formDataJson = JSON.stringify({
          errCode: res.code,
          ...formData,
        });
        AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Log_Fail, formDataJson);
        resolve();
      }
    });
  };

  const LoginError = (res) => {
    handleReReqCaptcha();
    if (res.code == 1008006) {
      if (res.result?.errorNum) {
        toast.error(t('login-signup.stiil_login', { errorNum: res.result.errorNum }), {
          containerId: 'global',
        });
      } else {
        toast.error(t('1008006'), {
          containerId: 'global',
        });
      }
    } else if (res.code == 1001005) {
      if (res.result?.lockExpirationMinute) {
        toast.error(t('login-signup.try_again', { minute: res.result.lockExpirationMinute }), {
          containerId: 'global',
        });
      } else {
        toast.error(t('1001005'), {
          containerId: 'global',
        });
      }
    } else {
      toast.error(res.msg, {
        containerId: 'global',
      });
    }
  };

  const LoginSuccess = (result) => {
    if (isRememberPwd == '2') {
      //记住密码
      if (loginType == UserCache.LoginMethodValue.Phone) {
        UserCache.setPhone(formData.mobile);
      } else if (loginType == UserCache.LoginMethodValue.Email) {
        UserCache.setEmail(formData.mobile);
      } else if (loginType == UserCache.LoginMethodValue.UserName) {
        UserCache.setUserName(formData.mobile);
      } else if (loginType == UserCache.LoginMethodValue.Any) {
        UserCache.setAnyAccount(formData.account);
      }
    } else {
      if (loginType == UserCache.LoginMethodValue.Phone) {
        UserCache.removePhone();
      } else if (loginType == UserCache.LoginMethodValue.Email) {
        UserCache.removeEmail();
      } else if (loginType == UserCache.LoginMethodValue.UserName) {
        UserCache.removeUserName();
      } else if (loginType == UserCache.LoginMethodValue.Any) {
        UserCache.removeAnyAccount();
      }
    }
    localStorage.removeItem('showNotice');
    dispatch(setLoginMethod(1));
    if (result && result.user_obj) {
      if (result.user_obj.vip_level) {
        setVipLevelLogin(result.user_obj.vip_level, result.user_obj.uid);
      }
    }
    if (loginType != UserCache.LoginMethodValue.Any) {
      UserCache.setLoginMethod(loginType);
    }
    dispatch(
      setDialogModalVisible({
        ...dialogModalProp,
        show: DialogModalChildrenKey.Default,
      }),
    );
    dispatch(setUserLoginType(result.type));
    if (result.type == 'signup') {
      PopupManager.setIsRegister();
    } else {
      PopupManager.removeIsRegister();
    }
    if (OAuthRedirecting.URL == null) {
      AnalyticsManager.FirebaseLogEvent(FirebaseEvents.Log_Success);
      AnalyticsManager.loginSuccess();
      toast.success(t('home.toast008'), {
        containerId: 'global',
      });
      dispatch(setLoginStatus(!loginStatus));
      dispatch(setUserObj(result.user_obj));
      dispatch(
        setLoginInfo({
          identity: result.identity,
        }),
      );
    } else {
      OAuthRedirecting.Login(OAuthRedirecting.URL, result.identity);
    }
  };

  const LoginRequest = (params = null) => {
    if (loginType == UserCache.LoginMethodValue.Phone) {
      return RegisterReqNew({
        ...params,
        mobile: `${websiteInitConfig.website_info.country_code || ''}${params.mobile}`,
      });
    } else {
      return LoginReq({
        ...params,
        username: params.mobile,
      });
    }
  };

  const handleLoginReq = (cb = () => {}) => {
    LoginRequest({
      ...formData,
    })
      .then((res) => {
        setRequesting(false);
        setIsAnyAccountLogin(false);
        LoginRes(res).then(() => {
          cb();
        });
      })
      .finally(() => {
        setRequesting(false);
        cb();
      });
  };

  const handleAnyLoginReqType = async (params) => {
    if (captchaInitConfig?.user_login_validation_mode == 'geetest') {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        captchaInitConfig?.user_login_risk_type,
        setRequesting,
      );
      return LoginAnyReq({ ...params, ...captchaObj });
    } else {
      return LoginAnyReq({ ...params });
    }
  };

  const handleAnyLoginReq = (cb = () => {}) => {
    handleAnyLoginReqType({
      ...formData,
    })
      .then((res) => {
        setRequesting(false);
        setIsAnyAccountLogin(true);
        LoginRes(res);
      })
      .finally(() => {
        setRequesting(false);
        cb();
      });
  };

  const handleSubmit2FaCode = (authCode = '', uu_identity = '') => {
    LoginGoogleAuthReq({
      username: isAnyAccountLogin ? formData.account : formData.mobile,
      auth_code: authCode,
      password: formData.password,
      invite_code: inviteCode,
      osType,
      advertisingId,
      uu_identity,
    }).then((res) => {
      if (res.code == 0) {
        LoginSuccess(res.result);
      } else {
        toast.error(res.msg, {
          containerId: 'global',
        });
      }
    });
  };

  const handleReReqCaptcha = () => {
    CaptchaInitReq().then((res) => {
      if (res.code == 0) {
        setCaptchaInitConfig(res.result);
        setFormData({
          ...formData,
          captcha: res.result.captcha,
        });
      }
    });
  };

  return {
    isRememberPwd,
    loginType,
    requesting,
    formData,
    is2FaOpen,
    captchaInitConfig,
    uuIdentity,
    isForgotPasswordOpen,
    setIsForgotPasswordOpen,
    handleAnyLoginReq,
    setRequesting,
    setIs2FaOpen,
    handleLoginReq,
    setFormData,
    setLoginType,
    setIsRememberPwd,
    handleReReqCaptcha,
    handleSubmit2FaCode,
  };
};

export default useLogin;
