import { useState, useEffect } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStateIfMounted } from 'use-state-if-mounted';

import Close from '../close';
import Input from '../input';
import LimitClickWrapper from '../limitClickWrapper';
import ElementWithLoading from '../elementWithLoading';

import { toast } from 'react-toastify';

import Icon, { IconName } from '../icon';
import { SendCodeByMobileReq, BindMobileReq } from '../../api';
import { CaptchaInitReq, SmsSend } from '../../api/index-auth';
import handleInitGeetest4 from '../../hooks/useGeetestInit';

import InputBySendCode from '../inputBySendCode3';
import TippyPopBox, { Placement } from '../tippyPopBox';
import ChooseCountryCode from '../chooseCountryCode';
import DropDownInput from '../dropDownInput';
import { setNeedLogout } from '../../redux/reducers/appSlice';
import { UserCache } from '../../utils/helper';
import { phoneVerify, phoneVerifyRegex, phoneVerifyRegex2 } from '../../utils/inputHelper';

const FormHeader = ({ title, handleClose, className = '' }) => {
  return (
    <>
      <div className={`flex flex-row justify-between items-center mt-[.3rem] ${className}`}>
        <div className="text-[.36rem] font-[600] text-[var(--colour-48)]">{title}</div>
        <div className="w-[.72rem] h-[.72rem] bg-[var(--colour-45)] flex justify-center items-center rounded-normal">
          <Close onClick={handleClose} />
        </div>
      </div>

      <div className="w-full h-[1px] mt-[.28rem] bg-[var(--colour-6)]"></div>
    </>
  );
};

const BindPassword = ({ handleClose = () => {}, bindSuccessCallback = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hasBeenSent, setHasBeenSent] = useState(false);

  const { websiteInfo } = useSelector(
    (state) => ({
      websiteInfo: state.app.websiteInitConfig?.website_info,
    }),
    shallowEqual,
  );

  const [chooseCountryCode, setChooseCountryCode] = useState(63);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);

  const [codeLoading, setCodeLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState('');
  const [captchaInitConfig, setCaptchaInitConfig] = useState(null);

  const [sendCodeformData, setSendCodeformData] = useStateIfMounted({
    mobile: '',
    captcha: '', // 接口返回的验证码
    cryCode: '', // 用户输入的验证码
  });

  const [formData, setFormData] = useStateIfMounted({
    mobile: '',
    password: '',
    code: '',
  });

  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    updateCaptcha();
  }, []);

  const handleChooseCode = (item) => {
    setShowCountryCodeList(false);
    setChooseCountryCode(String(item.code).replace('+', ''));
    console.log(chooseCountryCode);
  };

  const updateCaptcha = () => {
    setCaptchaImg('');
    CaptchaInitReq().then((res) => {
      if (res.code == 0) {
        setSendCodeformData({ ...sendCodeformData, captcha: res.result.captcha, cryCode: '' });
        setCaptchaImg(res.result.data);
        setCaptchaInitConfig(res.result);
      }
    });
  };

  const handlePasswordChange = (ev) => {
    const v = ev.target.value;
    const v2 = v.replace(/[^0-9a-zA-Z]/g, '').slice(0, 24);
    setFormData({ ...formData, password: v2 });
  };

  const onChangeCryCode = (ev) => {
    setSendCodeformData({ ...sendCodeformData, cryCode: ev.target.value });
  };

  const sendCodeRequestType = async (params) => {
    if (captchaInitConfig?.bind_phone_validation_mode == 'geetest') {
      const captchaObj = await handleInitGeetest4(
        captchaInitConfig?.geetest_captcha_id,
        captchaInitConfig?.bind_phone_risk_type,
        setCodeLoading,
      );
      return SmsSend({
        ...params,
        mode: 'bind_phone',
        mobile: `${websiteInfo.country_code || ''}${sendCodeformData.mobile}`,
        ...captchaObj,
      });
    } else {
      return SmsSend({
        ...params,
        mode: 'bind_phone',
        mobile: `${websiteInfo.country_code || ''}${sendCodeformData.mobile}`,
      });
    }
  };

  //发送消息
  const handleSendCode = async () => {
    if (!formData.mobile) {
      return toast.info(t('1008015'), {
        containerId: 'global',
      });
    }

    if (captchaInitConfig?.bind_phone_validation_mode == 'image') {
      if (sendCodeformData.cryCode === '') {
        return toast.info(t('1001007'), {
          containerId: 'global',
        });
      }
    }

    if (codeLoading) {
      return;
    }

    setCodeLoading(true);

    try {
      const token = null;
      console.log('token', token);

      sendCodeRequestType({
        ...sendCodeformData,
        mobile: `${websiteInfo.country_code || ''}${sendCodeformData.mobile}`,
        recaptcha: token,
      }).then((res) => {
        if (res.code == 0) {
          setHasBeenSent(true);
          toast.success(t('home.toast033'), {
            containerId: 'global',
          });
        } else {
          updateCaptcha();
          toast.error(res.msg, {
            containerId: 'global',
          });
          setHasBeenSent(false);
        }

        setCodeLoading(false);
      });
    } catch (err) {
      return setCodeLoading(false);
    }
  };
  const handleSubmit = async () => {
    if (websiteInfo?.country_code == '55') {
      if (!formData.mobile) {
        return toast.info(t('login-signup.inputPhoneNull'), {
          containerId: 'global',
        });
      }
      if (!phoneVerify(formData.mobile)) {
        return toast.info(t('login-signup.inputPhoneError'), {
          containerId: 'global',
        });
      }
    } else {
      if (!formData.mobile) {
        return toast.info(t('1008015'), {
          containerId: 'global',
        });
      }
    }

    if (formData.password.length < 8) {
      return toast.info(t('1008024'), {
        containerId: 'global',
      });
    }

    if (!formData.code) {
      return toast.info(t('login-signup.vericication_code'), {
        containerId: 'global',
      });
    }

    setRequesting(true);
    BindMobileReq({ ...formData, mobile: `${websiteInfo.country_code || ''}${formData.mobile}` })
      .then((res) => {
        if (res.code == 0) {
          toast.success(t('home.toast020'), {
            containerId: 'global',
          });
          bindSuccessCallback();
          UserCache.setPhone(formData.mobile, true);
          dispatch(setNeedLogout({ showLoginBox: true }));
        } else {
          toast.error(res.msg, {
            containerId: 'global',
          });
          updateCaptcha();
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  return (
    <div className="flex flex-col justify-between text-center relative my-[.3rem] pb-[.4rem] w-[7.4rem] h-[auto] rounded-normal bg-[var(--colour-35)]">
      <div className="">
        <div className="px-[.32rem]">
          <FormHeader title={t('account.bind')} handleClose={handleClose} />
        </div>
        {/* 列表 */}
        <div className="px-[.32rem]">
          <div className="flex flex-col mt-[.2rem]">
            <div className="overflow-hidden">
              <Input
                leftOuter={
                  <div className="flex items-center">
                    <span className="block w-[.4rem] h-[.4rem] mr-[.2rem] ml-[.28rem] text-[var(--colour-38)]">
                      <Icon name={IconName.Phone} />
                    </span>
                    <TippyPopBox
                      visible={showCountryCodeList}
                      popChildren={
                        <ChooseCountryCode
                          onClick={handleChooseCode}
                          scrollBarWrapperClassName="h-[5.6rem]"
                          className="!bg-[#1A1A1A] w-[6.3rem]"
                        />
                      }
                      onClickOutside={() => setShowCountryCodeList(false)}
                      placement={Placement.bottomStart}
                    >
                      <div>
                        <DropDownInput
                          // onClick={() => setShowCountryCodeList(!showCountryCodeList)}
                          className="!h-[.8rem] px-[.2rem] mr-[.14rem]"
                        >
                          <div className="font-[500] text-[.32rem] text-[var(--colour-38)]">
                            +{websiteInfo.country_code || ''}
                          </div>
                        </DropDownInput>
                      </div>
                    </TippyPopBox>
                  </div>
                }
                type="text"
                value={formData.mobile}
                onChange={(ev) => {
                  const val = ev.target.value;
                  let regx = websiteInfo?.country_code == '55' ? phoneVerifyRegex : phoneVerifyRegex2;
                  if (regx.test(val)) {
                    setFormData({ ...formData, mobile: val });
                    setSendCodeformData({ ...sendCodeformData, mobile: val });
                  }
                }}
                placeholder={t('login-signup.mobile_phone')}
                innerClassName="!border-transparent !h-[.96rem]"
                topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
              />
            </div>

            <div className="mt-[.2rem]">
              <Input
                leftInner={
                  <div className="flex items-center">
                    <span className="block w-[.4rem] h-[.4rem] mr-[.2rem] text-[var(--colour-38)]">
                      <Icon name={IconName.LoginPwd} />
                    </span>
                  </div>
                }
                value={formData.password}
                onChange={handlePasswordChange}
                type="password"
                placeholder={t('login-signup.fill_in_the_password')}
                innerClassName="!border-transparent !h-[.96rem]"
                topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
              />
            </div>

            {captchaInitConfig?.bind_phone_validation_mode == 'geetest' ? (
              <>
                <div className="mt-[.2rem]">
                  {/* inviteCode || showInviteCode ?  */}

                  <InputBySendCode
                    placeholder={t('login-signup.enter_verification_code')}
                    value={formData.code}
                    leftInner={
                      <div className="flex items-center">
                        <span className="text-[var(--colour-38)] block w-[.4rem] h-[.4rem] mr-[.2rem] active:scale-[.95] transition-all select-none">
                          <Icon name={IconName.RegisterCode} />
                        </span>
                      </div>
                    }
                    onChange={(ev) => {
                      setFormData({ ...formData, code: ev.target.value });
                    }}
                    hasBeenSent={hasBeenSent}
                    handleSendCode={handleSendCode}
                    countdownDone={() => setHasBeenSent(false)}
                    requesting={codeLoading}
                    propoClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                  />
                </div>
              </>
            ) : captchaInitConfig?.bind_phone_validation_mode == 'image' ? (
              <>
                {/* 图形验证码 */}
                <div className="mt-[.2rem]">
                  <Input
                    leftInner={
                      <div className="flex items-center">
                        <span className="block w-[.4rem] h-[.4rem] mr-[.2rem] text-[var(--colour-38)]">
                          <Icon name={IconName.RegisterCode} />
                        </span>
                      </div>
                    }
                    value={sendCodeformData.cryCode}
                    onChange={onChangeCryCode}
                    type="text"
                    placeholder={t('login-signup.fill_captcha')}
                    innerClassName="!border-transparent !h-[.96rem] "
                    topClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                    rightInner={
                      <div>
                        <img
                          src={captchaImg}
                          alt="captcha"
                          onClick={updateCaptcha}
                          className="cursor-pointer w-[2rem] ml-[.08rem]"
                        />
                      </div>
                    }
                  />
                </div>

                <div className="mt-[.2rem]">
                  {/* inviteCode || showInviteCode ?  */}

                  <InputBySendCode
                    placeholder={t('login-signup.enter_verification_code')}
                    value={formData.code}
                    leftInner={
                      <div className="flex items-center">
                        <span className="text-[var(--colour-38)] block w-[.4rem] h-[.4rem] mr-[.2rem] active:scale-[.95] transition-all select-none">
                          <Icon name={IconName.RegisterCode} />
                        </span>
                      </div>
                    }
                    onChange={(ev) => {
                      setFormData({ ...formData, code: ev.target.value });
                    }}
                    hasBeenSent={hasBeenSent}
                    handleSendCode={handleSendCode}
                    countdownDone={() => setHasBeenSent(false)}
                    requesting={codeLoading}
                    propoClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                  />
                </div>
              </>
            ) : (
              <div className="mt-[.2rem]">
                {/* inviteCode || showInviteCode ?  */}

                <InputBySendCode
                  placeholder={t('login-signup.enter_verification_code')}
                  value={formData.code}
                  leftInner={
                    <div className="flex items-center">
                      <span className="text-[var(--colour-38)] block w-[.4rem] h-[.4rem] mr-[.2rem] active:scale-[.95] transition-all select-none">
                        <Icon name={IconName.RegisterCode} />
                      </span>
                    </div>
                  }
                  onChange={(ev) => {
                    setFormData({ ...formData, code: ev.target.value });
                  }}
                  hasBeenSent={hasBeenSent}
                  handleSendCode={handleSendCode}
                  countdownDone={() => setHasBeenSent(false)}
                  requesting={codeLoading}
                  propoClassName={`rounded-normal overflow-hidden bg-[var(--colour-34)]`}
                />
              </div>
            )}

            <LimitClickWrapper
              className="active:scale-[.95] transition-all select-none mt-[.4rem] flex flex-row px-[.34rem] h-[.92rem] items-center  justify-center cursor-auto bg-[var(--colour-1)] rounded-normal text-[var(--colour-2)]"
              onClick={() => {
                if (!requesting) {
                  handleSubmit();
                }
              }}
            >
              <ElementWithLoading
                isLoading={requesting}
                normalEl={
                  <>
                    <div className="text-[.32rem] font-[700]">{t('account.submint')}</div>
                  </>
                }
              />
            </LimitClickWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BindPassword;
