import cn from 'classnames';
import LimitClickWrapper from '../../../../../../components/limitClickWrapper';

const ButtonBox = ({ isEnable = true, text, onClick, elementId }) => {
  return (
    <LimitClickWrapper
      className={cn(
        'w-[5.38rem] h-[.76rem] rounded-[.1rem] flex items-center justify-center text-center leading-[1] text-[.26rem] font-[500]',
        {
          'colour-80 cursor-pointer': isEnable,
          'bg-[var(--colour-42)] cursor-not-allowed': !isEnable,
        },
      )}
      onClick={onClick}
      id={elementId}
    >
      {text}
    </LimitClickWrapper>
  );
};

export default ButtonBox;
