import React, { useState, useEffect, memo } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { get } from 'lodash';
import configData from '../../constants/config';
import { urlWithQueryString, isControllableSite, openGiftMoneyDetail } from '../../utils/helper';
import Close from '../close';
import Loading2 from '../loading2';
import { setDialogModalVisible, DialogModalChildrenKey } from '../../redux/reducers/appSlice';
import AnalyticsManager from '../../manager/analyticsManager';
import cn from 'classnames';
import Wallet from '../wallet';
import { useLocation } from 'react-router-dom';
import useGameInfo from '../../hooks/useGameInfo';
import { toNoLocalePath } from '../../utils/localePath';

const WithdrawDeposit = ({ handleClose, walletType = 1, withdraw_enable = 1 }) => {
  const { identity, defaultLang, websiteInitConfig, pixelId, fbBtn, dialogModalProp, dialogModalProp2 } = useSelector(
    (state) => ({
      defaultLang: get(state, 'user.defaultLang'),
      identity: get(state, 'user.identity'),
      websiteInitConfig: get(state, 'app.websiteInitConfig'),
      pixelId: get(state, 'user.pixelId'),
      fbBtn: get(state, 'app.fbBtn'),
      dialogModalProp: state.app.dialogModalProp,
      dialogModalProp2: state.app.dialogModalProp2,
    }),
    shallowEqual,
  );

  const { isGameOpen } = useGameInfo();
  const [payUrl, setPayUrl] = useState('');
  const { pathname } = useLocation();
  const [walletParams, setWalletParams] = useState({});

  const dispatch = useDispatch();

  // useEffect(() => {
  //   window.addEventListener('message', iframeCallback);
  //   return () => {
  //     window.removeEventListener('message', iframeCallback);
  //   };
  // }, []);

  useEffect(() => {
    const { pay_channel_id, currency_offset } = websiteInitConfig.system_config;
    const { currency_code, currency_symbol, website_theme } = websiteInitConfig.website_info;
    const params = {
      type: walletType,
      lang: defaultLang,
      theme: website_theme,
      channel_id: pay_channel_id,
      identity,
      currency_code,
      currency_symbol,
      currency_offset,
      fbPixelId: pixelId,
      fb_btn: fbBtn,
      withdraw_enable: withdraw_enable,
    };
    setWalletParams(params);
    // setPayUrl(urlWithQueryString(configData.payURL, params));
  }, []);

  const OpenGiftMoneyDialog = () => {
    // dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
    openGiftMoneyDetail(isGameOpen, toNoLocalePath(pathname));
  };

  // const iframeCallback = (rs) => {
  //   if (rs.data) {
  //     switch (rs.data.action) {
  //       case 'closePay': {
  //         // pay通知关闭
  //         handleClose && handleClose();
  //         break;
  //       }
  //       case 'showGiftMoney': {
  //         // show giftmoney
  //         if (dialogModalProp.show == DialogModalChildrenKey.WithdrawDeposit) {
  //           dispatch(setDialogModalVisible({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
  //         } else {
  //           dispatch(setDialogModalVisible2({ show: DialogModalChildrenKey.GiftMoney, isFromGame: false }));
  //         }
  //         break;
  //       }
  //       case 'analytics':
  //         AnalyticsManager.FirebaseLogEvent(rs.data.data);
  //         break;
  //     }
  //   }
  // };
  const isFullscreen = () => {
    return isControllableSite();
  };

  const [fullscreen, setFullscreen] = useState(() => isFullscreen());

  useEffect(() => {
    setFullscreen(isFullscreen());
  }, []);

  return (
    <div
      className={cn('relative', {
        'w-[7.2rem] h-[90vh]': !fullscreen,
        'w-[var(--theme-max-width)] h-[var(--app-height)] bg-[var(--colour-35)]': fullscreen,
      })}
    >
      {/* iframe */}
      {/* {payUrl ? <PayIframe iframeUrl={payUrl} /> : <></>} */}
      {walletParams && Object.keys(walletParams).length != 0 ? (
        <Wallet
          params={walletParams}
          handleClose={handleClose}
          OpenGiftMoneyDialog={OpenGiftMoneyDialog}
          isFullscreen={fullscreen}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

// const PayIframe = memo(({ iframeUrl }) => {
// console.log('iframeUrl', iframeUrl);
//   const [isLoading, setLoading] = useState(true);
//   return (
//     <>
//       <iframe
//         className={cn('absolute inset-0 w-full h-full')}
//         src={iframeUrl}
//         onLoad={() => {
//           setLoading(false);
//         }}
//         allow="clipboard-read; clipboard-write"
//       />
//       {isLoading && (
//         <div className="rounded-normal game-iframe bg-[var(--colour-35)]">
//           <Loading2 isAbsolute={false} className="w-full h-full flex justify-center items-center" />
//         </div>
//       )}
//     </>
//   );
// });

export default WithdrawDeposit;
