import React from 'react';
import cn from 'classnames';
// import globalConfig from '../constants/config';

const Index = ({
  num = 0,
  totalNum = 0,
  level = 0,
  isCurrency,
  mobileHorizontalScreenStyle = false,
  boxClassName = '',
  NoClassName = '',
  SelectClassName = 'bg-attention',
  overrideNoClassName = '',
  overrideSelectClassName = '',
}) => {
  const getPercentage = () => {
    if (isNaN(Number(num) / Number(totalNum))) {
      return 0;
    } else {
      const remainder = (Number(num) / Number(totalNum)) * 100;
      if (remainder < 1) {
        if (remainder == 0) {
          return 0;
        } else {
          return remainder.toFixed(1);
        }
      } else {
        return Math.floor(remainder);
      }
    }
  };
  return (
    <div className={`flex flex-row items-center justify-start w-full bg-[var(--colour-30)]  ${boxClassName}`}>
      <div
        className={cn(overrideNoClassName != '' ? overrideNoClassName : `relative h-[.12rem] w-full ${NoClassName}`)}
      >
        <span
          className={
            // ${
            //     globalConfig.screen_laouyt.includes(globalConfig.theme) && 'bg-primary-btn1'
            //   }
            overrideSelectClassName != ''
              ? overrideSelectClassName
              : `absolute left-[0] top-[0] h-[.12rem] bg-[var(--colour-1)]  ${SelectClassName} `
          }
          style={{ width: `${getPercentage(num, totalNum) > 100 ? 100 : getPercentage(num, totalNum)}%` }}
        ></span>
      </div>
    </div>
  );
};

export default Index;
