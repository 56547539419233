import { useNavigate } from 'react-router-dom';
import { cmsImgUrl } from '../../utils/helper';
import { shallowEqual, useSelector } from 'react-redux';
import SkeletonBox from '../skeletonBox';
import AnalyticsManager, { FirebaseEvents } from '../../manager/analyticsManager';
import useLink from '../../hooks/useLink';

export default function BannerItem({ model, index }) {
  const { handleCmsLink } = useLink();

  return (
    <SkeletonBox props={{ className: 'pt-[50%]' }} loading={!model}>
      {model ? (
        <div
          onClick={() => {
            AnalyticsManager.FirebaseLogEvent(FirebaseEvents.MP_Click_Slide + (index + 1));
            handleCmsLink(model.open_mode, model.new_window, model.link);
          }}
          className="cursor-pointer overflow-hidden"
        >
          <img src={cmsImgUrl(model.banner)} className="w-full h-auto" alt="" />
          {/* <img src={model.banner2} className="w-full h-auto" alt="" /> */}
        </div>
      ) : null}
    </SkeletonBox>
  );
}
