import Icon, { IconName } from '../../../../../components/icon';

export default function Header({
  title = '',
  className = '',
  clickBackBtn = () => {},
  showRecordBtn = false,
  clickRecordBtn = () => {},
}) {
  return (
    <div className={`h-[.96rem] min-h-[.96rem] bg-[var(--colour-86)] flex items-center justify-between ${className}`}>
      <div className="h-full w-[1.12rem] flex items-center justify-center cursor-pointer" onClick={clickBackBtn}>
        <div className="rotate-180 w-[.24rem] h-[.24rem] text-[var(--colour-11)]">
          <Icon name={IconName.BannerNext2} />
        </div>
      </div>
      <div className="text-[.36rem] font-[500] leading-[.44rem] text-[var(--colour-48)]">{title}</div>

      <div className="h-full w-[1rem]">
        {showRecordBtn ? (
          <div className="w-full h-full flex items-center justify-center cursor-pointer" onClick={clickRecordBtn}>
            <div className="w-[.44rem] h-[.44rem] text-[var(--colour-2)]">
              <Icon name={IconName.Record2} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
