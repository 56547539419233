import BaseTemplate from '../../baseTemplate';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toNoLocalePath } from '../../../../utils/localePath';
import { useEffect, useState } from 'react';
import Footer from '../../../../components/footer';
import DrawerMenu from '../../../menu/drawerMenu/design/template001';
import BackToTopBtn from '../components/BackToTopBtn';
import cn from 'classnames';

export default function DTemplate001({ children }) {
  const { drawerMenuVisible, showHeaderAndFooter } = useSelector(
    (state) => ({
      drawerMenuVisible: state.app.drawerMenuVisible,
      showHeaderAndFooter: state.app.showHeaderAndFooter,
    }),
    shallowEqual,
  );
  const { pathname } = useLocation();
  const [drawerLeftMenuVisible, setDrawerLeftMenuVisible] = useState(false); //侧导航是否显示 ，默认关闭
  useEffect(() => {
    if (drawerMenuVisible.visible || toNoLocalePath(pathname).indexOf('/sport') > -1) {
      setDrawerLeftMenuVisible(false);
    } else {
      setDrawerLeftMenuVisible(true);
    }
  }, [drawerMenuVisible, pathname]);

  return (
    <BaseTemplate className={toNoLocalePath(pathname) === '/' ? 'home_bg_texture' : ''}>
      <DrawerMenu drawerLeftMenuVisible={drawerLeftMenuVisible} />
      <main className={cn('h-[100%]')}>
        {children}
        {showHeaderAndFooter ? <Footer /> : null}
      </main>
      {toNoLocalePath(pathname) == '/' ? <BackToTopBtn /> : null}
    </BaseTemplate>
  );
}
