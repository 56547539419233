import { get } from 'lodash';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { cmsImgUrl, getShowMoney } from '../../utils/helper';
import RedDot from '../redDot';
import TourCountdown from './tourCountdown';
import { useGlobalNavigate } from '../../contexts/navigateContext';

const TextItem = ({ title, className, children }) => {
  return (
    <div className="w-[3.46rem] h-[1.16rem] text-[.24rem] font-[500] drop-shadow-[0rem_.08rem_.08rem_rgba(0,0,0,0.25)] rounded-small">
      <div className="h-[.48rem] flex items-center pl-[.12rem] bg-[#000] rounded-small !rounded-b-none text-[#fff]">
        {title}
      </div>
      <div className={`h-[.68rem] flex items-center justify-center rounded-small !rounded-t-none ${className}`}>
        {children}
      </div>
    </div>
  );
};

const TournamentCard = ({ data, needClick, showRedDot = true, update = () => {} }) => {
  // status 活动状态 0：非进行中；1：进行中
  // user_entername_status 用户锦标赛是否报名  0：未报名；1：结算中；2：已报名;3:可领取;4:已领取;5:不可领奖
  // activity_end_time 活动结束时间
  // activity_start_time 活动开始时间

  const { t } = useTranslation();
  const navigate = useGlobalNavigate();

  const { websiteInitConfig } = useSelector(
    (state) => ({
      websiteInitConfig: state.app.websiteInitConfig,
    }),
    shallowEqual,
  );

  const canClaim = get(data, 'user_entername_status') == 3;

  const onClick = () => {
    if (needClick) {
      navigate(`/tournament/${data.id}`);
    }
  };

  const getCountdown = (data) => {
    const now = Date.now();
    const startTime = get(data, 'activity_start_time') ? get(data, 'activity_start_time') * 1000 : now;
    const endTime = get(data, 'activity_end_time') ? get(data, 'activity_end_time') * 1000 : now;

    if (now < startTime) {
      return {
        title: t('tour.startIn'),
        date: startTime,
      };
    } else if (now >= startTime && now < endTime) {
      return {
        title: t('tour.finishIn'),
        date: endTime,
      };
    } else {
      return {
        title: t('tour.end'),
        date: Date.now(),
      };
    }
  };

  const getOnComplete = () => {
    const now = Date.now();
    const endTime = get(data, 'activity_end_time') ? get(data, 'activity_end_time') * 1000 : now;

    if (now >= endTime) {
      return () => {};
    } else {
      return update;
    }
  };

  return data != null ? (
    <>
      <div className="w-full">
        <div
          onClick={onClick}
          className={`relative w-full h-[3.78rem] bg-[length:100%_100%] bg-no-repeat p-[.98rem_0_0_.32rem] rounded-normal ${
            needClick ? 'cursor-pointer' : ''
          } ${canClaim && showRedDot ? 'border-[.04rem] border-iconTheme' : ''}`}
          style={{
            backgroundImage: `url(${cmsImgUrl(get(data, 'rule_explain.activity_info_img'))})`,
          }}
        >
          <TextItem title={getCountdown(data).title} className={'bg-[rgba(0,0,0,0.2)]'}>
            <TourCountdown
              date={getCountdown(data).date}
              className="text-[.4rem] font-[600] space-x-[.2rem] text-[#fff]"
              className2="w-[.6rem] min-w-[.6rem] max-w-[.6rem]"
              onComplete={getOnComplete()}
            />
          </TextItem>
          <div className="mt-[.32rem]">
            <TextItem title={t('tour.pricePool')} className={'bg-[#FFDF25]'}>
              <span className="text-[#23013F] text-[.4rem] font-[600]">
                {websiteInitConfig.website_info.currency_symbol}{' '}
                {get(data, 'activity_claim_ceiling_amount')
                  ? getShowMoney(get(data, 'activity_claim_ceiling_amount'))
                  : ''}
              </span>
            </TextItem>
          </div>
          {canClaim && showRedDot ? <RedDot className="absolute -right-[.04rem] -top-[.04rem]" /> : <></>}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default TournamentCard;
