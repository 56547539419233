import moment from 'moment';
import { get } from 'lodash';
import store from '../redux/store';
import { isLogin } from './helper';

/**
 * 弹窗频率管理器
 */
class PopupManager {
  static lowFrequencyPopup = false; //低频率弹窗是否弹窗过，默认未弹
  static IsRegister = false;
  static IsRegisterStorageKey = 'IsRegisterStorageKey';
  static getTomorrowTS() {
    return moment(new Date()).add(1, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('x');
  }
  static Modes = {
    none: 'none', //不弹窗("none")
    high_frequency: 'high_frequency', //高频率弹窗("high_frequency")，每次返回首页都会弹
    refresh_page: 'refresh_page', //低频率弹窗，刷新一次就弹，从别的界面返回首页不弹，登录、退出登录视为刷新
    daily: 'daily', //每天一次弹窗("daily")
    once: 'once', //只弹一次("once")
  };
  static Keys = {
    register: 'register', // 注册强弹
    registerSuccess: 'registerSuccess', //注册成功
    activity_promotional_achievements: 'activity_promotional_achievements', //推广成就
    activity_promotional_cashback: 'activity_promotional_cashback', //推广返现
    activity_novice_guide_tournament: 'activity_novice_guide_tournament', //引导轮盘
    notification: 'notification', //公告
    activity_novice: 'activity_novice', //迎新活动
    bottom_bar_download_popup: 'bottom_bar_download_popup', //底边栏下载弹窗
    mission: 'mission', // 任务弹窗
    activity_mystery_bonus: 'activity_mystery_bonus', // 神秘彩金活动
  };

  static getIsRegister() {
    return this.get(this.IsRegisterStorageKey) || false;
  }

  static removeIsRegister() {
    localStorage.removeItem(this.IsRegisterStorageKey);
  }

  static setIsRegister() {
    this.set(this.IsRegisterStorageKey, true);
  }

  static get(key) {
    return localStorage.getItem(key);
  }

  static set(key, value) {
    return localStorage.setItem(key, value);
  }

  static getUID() {
    const userObj = get(store.getState(), 'user.userObj', null);
    return userObj ? userObj.uid : '';
  }

  //退出登录重置存储的值
  static resetAllowPopupConfig() {
    Object.entries(this.Keys).forEach(([key, value]) => {
      if (this.get(value)) {
        const config = JSON.parse(this.get(value));
        this.set(
          value,
          JSON.stringify({
            ...config,
            uid: '',
          }),
        );
      }
    });
  }

  //获取这个活动的配置
  static getConfig(popups, key) {
    if (popups) {
      return Array.from(popups).find((item) => item.key == key);
    } else {
      return null;
    }
  }
  /**获取是否允许弹窗(通过弹窗频率) */
  static getIsAllowPopup(key, mode, id) {
    //mode设置为"none"直接不显示。
    if (mode == this.Modes.none) {
      return false;
    }
    //未设置过则为第一次访问，只要mode不是设置为"none"就直接显示。
    else if (!this.get(key)) {
      return true;
    } else {
      const config = JSON.parse(this.get(key));
      if (id == config.id && ((isLogin() && config.uid == this.getUID()) || !isLogin())) {
        //以最新的配置为准
        if (mode == this.Modes.daily) {
          if (new Date().getTime() >= config.ts) {
            return true; //超过上次记录的明天的时间戳，直接显示。
          } else {
            return false; //不显示。
          }
        } else if (mode == this.Modes.once) {
          //只弹一次，直接返回false，因为能获取存储到的值肯定是有弹过。
          return false;
        } else if (mode == this.Modes.refresh_page) {
          //低频弹窗，只在刷新的时候且在首页或者回到首页弹一次。
          if (!this.lowFrequencyPopup) {
            return true;
          } else {
            return false;
          }
        } else if (mode == this.Modes.high_frequency) {
          //高频则直接弹
          return true;
        } else {
          return false;
        }
      } else {
        return true; //活动id有更新，弹窗逻辑重新存储。
      }
    }
  }

  /**设置弹窗频率属性
     * mode
     不弹窗("none")
     高频率弹窗("high_frequency")，进这个页面就弹
     每天一次弹窗("daily")，
     只弹一次("once") */
  static setIsAllowPopup(key, mode, id) {
    this.set(
      key,
      JSON.stringify({
        uid: this.getUID(),
        id: id,
        mode: mode,
        ts: mode == this.Modes.daily ? this.getTomorrowTS() : 0,
      }),
    );
  }
}

export default PopupManager;
