import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import colorTypes from '../helper/colorTypes';
import LimitClickWrapper from '../../../../../../components/limitClickWrapper';

const ReceiveButton = ({ onClick, colorType = colorTypes.grey, name = '' }) => {
  const { t } = useTranslation();

  return (
    <LimitClickWrapper
      className={cn(
        'w-[1.2rem] h-[.5rem] text-[var(--colour-2)] text-[.18rem] font-[500] cursor-pointer rounded-[.12rem] flex justify-center items-center',
        {
          'bg-[var(--colour-29)]': colorType === colorTypes.green,
          'bg-[var(--colour-1)]': colorType === colorTypes.blue,
          'bg-[var(--colour-42)]': colorType === colorTypes.grey,
        },
      )}
      onClick={onClick}
    >
      {name}
    </LimitClickWrapper>
  );
};

export default ReceiveButton;
