import cn from 'classnames';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import NoData from '../../../../../components/noData';
import Loading from '../../../../../components/loading2';
import DatePickerBox from '../../../../../components/datePickerBox';
import Icon, { IconName } from '../../../../../components/icon';
import TippyPopBox, { Placement } from '../../../../../components/tippyPopBox';
import PopChoose, { PopChooseItem } from '../../../../../components/popChoose';
import DropDownInput from '../../../../../components/dropDownInput';
import { useStateIfMounted } from 'use-state-if-mounted';
import useDivSize from '../../../../../hooks/useDivSize';
import { getShowMoney, isControllableSite, isDateInRangeLastMonth, pxToRem } from '../../../../../utils/helper';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getReportBetsdata } from '../../../../../api';
import LimitClickWrapper from '../../../../../components/limitClickWrapper';
import MoneyWithCurrencyAndSign from '../../../../../components/moneyWithCurrencyAndSign';
import { eventEmitter, EmitterEventTypes } from '../../../../../utils/eventEmitter';

const ReportBets = () => {
  const divRef = useRef(null);
  const divRef1 = useRef(null);
  const excessheight = 0.72 + 0.98;
  const { t } = useTranslation();
  const [isloading, setisLoading] = useState(true);
  const [pageCount, setPageCount] = useStateIfMounted(0);
  const { width } = useDivSize(divRef);
  const { ref1Width } = useDivSize(divRef1, 'ref1');
  const [providerList, setProviderList] = useState([]);
  const [gameTypeList, setGameTypeList] = useState([]);
  const [h, setH] = useState(pxToRem(window.innerHeight) - excessheight);
  const [filterTypeVisible, setFilterTypeVisible] = useStateIfMounted(false);
  const [filterProviderVisible, setFilterProviderVisible] = useStateIfMounted(false);

  const { responsiveMode, websiteInitConfig } = useSelector(
    (state) => ({
      responsiveMode: state?.app?.responsiveMode,
      websiteInitConfig: state?.app?.websiteInitConfig,
    }),
    shallowEqual,
  );

  const [currentType, setCurrentType] = useState();
  const [currentprovider, setCurrentProvider] = useState();
  const [data, setdata] = useState([]);

  const getReportBetsList = async () => {
    setisLoading(true);
    try {
      const res = await getReportBetsdata({
        ...params,
        date: moment(params.date).format(`YYYY-MM-DD`),
      });

      if (res) {
        if (res.code == 0) {
          setdata(res.result.list);
          const _pageCount = Math.ceil(res.result.pages.count / params.page_size);
          setPageCount(_pageCount);
        } else {
          setdata([]);
          setPageCount(0);
          toast.error(t(`${res.code}`), {
            containerId: 'global',
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setisLoading(false);
    }
  };

  const setPrevpage = () => {
    if (params.page <= 1 || pageCount == 0) return;
    setParams({ ...params, page: params.page - 1 });
  };

  const setNextpage = () => {
    if (params.page == pageCount || pageCount == 0) return;
    setParams({ ...params, page: params.page + 1 });
  };

  const onDateChange = (date) => {
    setParams({ ...params, date: date, page: 1 });
  };

  const handleResize = () => {
    setH(pxToRem(window.innerHeight) - excessheight);
  };

  useEffect(() => {
    handleResize();
    const unsubscribe = eventEmitter.subscribe(EmitterEventTypes.resize, () => {
      handleResize();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [params, setParams] = useState({
    page: 1,
    page_size: 10,
    date: new Date(),
    source: null,
    game_type: null,
  });

  useEffect(() => {
    if (websiteInitConfig) {
      let providerList = websiteInitConfig.provider_list;
      let gameTypeList = websiteInitConfig.game_type;

      let showProviderList = [{ name: 'All Providers', source: '' }];
      if (providerList && providerList.length > 0) {
        showProviderList = showProviderList.concat(providerList);
      }
      setCurrentProvider(String(showProviderList[0].source));
      showProviderList = showProviderList.map((item) => {
        return {
          ...item,
          onClick: () => {
            setCurrentProvider(String(item.source));
          },
        };
      });
      setProviderList(showProviderList);
      let showGameTypeList = [{ game_type: 'All Types', id: '' }];
      if (gameTypeList && gameTypeList.length > 0) {
        showGameTypeList = showGameTypeList.concat(gameTypeList);
      }
      setCurrentType(showGameTypeList[0].id);
      showGameTypeList = showGameTypeList.map((item) => {
        return {
          ...item,
          onClick: () => {
            setCurrentType(item.id);
          },
        };
      });
      setGameTypeList(showGameTypeList);
    }
  }, [websiteInitConfig]);

  useEffect(() => {
    if (currentType != undefined && currentprovider != undefined) {
      setParams({ ...params, source: currentprovider, game_type: currentType, page: 1 });
    }
  }, [currentType, currentprovider]);

  useEffect(() => {
    if (params.source != null && params.game_type != null) {
      getReportBetsList();
    }
  }, [params]);

  return (
    <>
      <div className={'w-full'}>
        <div style={{ height: `${h}rem` }} className="relative">
          {websiteInitConfig ? (
            <>
              <div className="w-full h-full py-[.2rem] px-[.24rem] flex flex-col">
                <div className="flex items-center gap-[.2rem]">
                  <div className="h-[.5rem] w-[1.98rem] text-[.2rem] font-[400] flex justify-between pl-[.2rem] rounded-normal relative bg-[var(--colour-35)] border border-[var(--colour-6)] text-[var(--colour-44)]">
                    <div className="text-[.2rem] flex items-center">
                      <DatePickerBox
                        filterDate={isDateInRangeLastMonth}
                        selected={params.date}
                        onChange={onDateChange}
                        onCalendarOpen={() => {
                          setFilterTypeVisible(false);
                          setFilterProviderVisible(false);
                        }}
                        customInput={
                          <div className="flex items-center cursor-pointer ">
                            <div className="">{moment(params.date).format('YYYY-MM-DD')}</div>

                            <div className="w-[.24rem] h-[.24rem] absolute abs-y-center right-[.2rem]">
                              <Icon name={IconName.Newdate1} />
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>

                  <div className="text-tab z-[1]">
                    <TippyPopBox
                      visible={filterTypeVisible}
                      onClickOutside={() => {
                        setFilterTypeVisible(false);
                      }}
                      offset={[0, 6]}
                      placement={Placement.bottomEnd}
                      zIndex={18}
                      popChildren={
                        <PopChoose
                          style={{ width: `${width}px` }}
                          className={`bg-[var(--colour-35)] border border-[var(--colour-6)] py-[10px] max-h-[6rem] overflow-y-auto scrollbar-none`}
                          children={providerList.map((item, index) => (
                            <PopChooseItem
                              className="flex items-center px-[.2rem] py-[.1rem]"
                              key={index}
                              data={item}
                              choose={String(item.source) == String(currentprovider)}
                              widthNumber={width}
                              onClick={() => {
                                setFilterTypeVisible(false);
                                item.onClick();
                              }}
                            >
                              <span className={`text-[.2rem] font-[400] wrap-break-word`}>{item.name}</span>
                            </PopChooseItem>
                          ))}
                        ></PopChoose>
                      }
                    >
                      <div>
                        <DropDownInput
                          ref={divRef}
                          iconCanRotate={false}
                          className="h-[.5rem] min-w-[2rem] text-[.2rem] font-[400] w-[auto] rounded-normal relative px-[.2rem] bg-[var(--colour-35)] border border-[var(--colour-6)]"
                          onClick={() => {
                            setFilterTypeVisible(!filterTypeVisible);
                          }}
                        >
                          <div className="text-[var(--colour-44)]">
                            {providerList.find((item) => item.source == currentprovider)?.name}
                          </div>
                          <span
                            className={cn('w-[.18rem] h-[.18rem] block text-[var(--colour-11)] transition', {
                              'rotate-[90deg]': filterTypeVisible,
                            })}
                          >
                            <Icon name={IconName.RightArrow3} />
                          </span>
                        </DropDownInput>
                      </div>
                    </TippyPopBox>
                  </div>

                  <div className="text-tab z-[1]">
                    <TippyPopBox
                      visible={filterProviderVisible}
                      onClickOutside={() => {
                        setFilterProviderVisible(false);
                      }}
                      offset={[0, 6]}
                      placement={Placement.bottomEnd}
                      zIndex={18}
                      popChildren={
                        <PopChoose
                          style={{ width: `${ref1Width}px` }}
                          className={`bg-[var(--colour-35)] border border-[var(--colour-6)] py-[10px] max-h-[6rem] overflow-y-auto scrollbar-none`}
                          children={gameTypeList.map((item, index) => (
                            <PopChooseItem
                              className="flex items-center px-[.2rem] py-[.1rem]"
                              key={index}
                              data={item}
                              choose={item.id == currentType}
                              widthNumber={ref1Width}
                              onClick={() => {
                                setFilterProviderVisible(false);
                                item.onClick();
                              }}
                            >
                              <span className={`text-[.2rem] font-[400] wrap-break-word`}>{item.game_type}</span>
                            </PopChooseItem>
                          ))}
                        ></PopChoose>
                      }
                    >
                      <div>
                        <DropDownInput
                          ref={divRef1}
                          iconCanRotate={false}
                          className="h-[.5rem] min-w-[2rem] text-[.2rem] font-[400] w-[auto] rounded-normal relative px-[.2rem] bg-[var(--colour-35)] border border-[var(--colour-6)]"
                          onClick={() => {
                            setFilterProviderVisible(!filterProviderVisible);
                          }}
                        >
                          <div className="text-[var(--colour-44)]">
                            {gameTypeList.find((item) => item.id == currentType)?.game_type}
                          </div>
                          <span
                            className={cn('w-[.18rem] h-[.18rem] block text-[var(--colour-11)] transition', {
                              'rotate-[90deg]': filterProviderVisible,
                            })}
                          >
                            <Icon name={IconName.RightArrow3} />
                          </span>
                        </DropDownInput>
                      </div>
                    </TippyPopBox>
                  </div>
                </div>

                {isloading ? (
                  <>
                    <Loading className="h-full flex-1" />
                  </>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <div
                          className={cn(
                            'mt-[.2rem] flex-1 flex flex-col items-center overflow-y-auto scrollbar-none',
                            {},
                          )}
                          style={{ height: `${h - 2}rem` }}
                        >
                          {data &&
                            data.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className={cn(
                                    'w-full min-h-[1.2rem] flex flex-col justify-center px-[.2rem] rounded-small',
                                    {
                                      'bg-[var(--colour-35)]': index % 2 == 0,
                                    },
                                  )}
                                >
                                  <div className="flex items-center gap-[.6rem]">
                                    <div className="text-[var(--colour-44)] text-[.18rem] font-[400] w-[1.8rem] truncate">
                                      {item.source_name} {item.game_type_name}/{item.game_name}
                                    </div>
                                    <div className="flex flex-1 justify-between text-[.18rem] font-[400]">
                                      <div className="flex gap-[.08rem] items-center">
                                        <div className="text-[var(--colour-44)]">{t('report.apostas')}</div>
                                        <div className="text-[var(--colour-38)]">
                                          <span>{websiteInitConfig?.website_info?.currency_symbol || ''} </span>
                                          {getShowMoney(item.wager)}
                                        </div>
                                        <div className="text-[var(--colour-44)]">{t('report.perdas')}</div>
                                      </div>
                                      <div
                                        className={`ml-[.1rem] ${Number(item.user_win) >= 0 ? 'text-[var(--colour-25)]' : 'text-[var(--colour-24)]'}`}
                                      >
                                        <MoneyWithCurrencyAndSign ammount={item.user_win} />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex gap-[.6rem] mt-[.12rem] items-center text-[.18rem] font-[400] text-[var(--colour-44)]">
                                    <div className="whitespace-nowra w-[90px]">
                                      {moment(item.create_timestamp).format('YYYY/MM/DD HH:mm:ss')}
                                    </div>
                                    <div className="flex flex-1 justify-between items-center">
                                      <div className="flex gap-[6px]">
                                        <div>{t('report.nota')}</div>
                                        <div>{item.round_id}</div>
                                      </div>
                                      <div
                                        className="w-[.28rem] h-[.28rem] cursor-pointer text-[var(--colour-1)]"
                                        onClick={() => {
                                          copy(item.round_id);
                                          toast.success(t('home.toast006'), {
                                            containerId: 'global',
                                          });
                                        }}
                                      >
                                        <Icon name={IconName.Copy4}></Icon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <>
                        <NoData
                          isShowIcon={isControllableSite() ? false : true}
                          className="h-full gap-[.2rem] flex-1"
                          imgStyle="!w-[2rem] !h-[2rem]"
                          className1={isControllableSite() ? '!mt-[14px]' : ''}
                        />
                      </>
                    )}
                  </>
                )}

                {data && data.length > 0 ? (
                  <div className="w-full flex justify-center items-center mt-[.2rem]">
                    <LimitClickWrapper
                      onClick={setPrevpage}
                      className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
                    >
                      <div className="w-[.24rem] h-[.24rem] rotate-180">
                        <Icon name={IconName.BannerNext2}></Icon>
                      </div>
                    </LimitClickWrapper>
                    <div className="px-[.24rem] text-[.24rem] font-[500] text-[var(--colour-1)]">{params.page}</div>
                    <LimitClickWrapper
                      onClick={setNextpage}
                      className="w-[.48rem] h-[.48rem] flex justify-center items-center cursor-pointer bg-[var(--colour-1)] text-[var(--colour-2)] rounded-normal"
                    >
                      <div className="w-[.24rem] h-[.24rem]">
                        <Icon name={IconName.BannerNext2}></Icon>
                      </div>
                    </LimitClickWrapper>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <>
              <Loading className="h-full flex-1" />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportBets;
